import React from 'react';

/**
 * @param {object} props
 * @param {boolean=} props.minimal
 * @param {boolean=} props.round
 * @param {any=} props.children
 */
function Tag(props) {
  return <div className={'notification-tag ' + (props.minimal ? 'notification-minimal ' : '') + (props.round ? 'notification-rounded ' : '')}>{props.children}</div>;
}

export default Tag;