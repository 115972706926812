import { useEffect, useState } from 'react';
import { format } from 'd3-format';
import { useIsMounted, useTypedSelector } from '../../hooks';
import { storage } from '../../utilities';
import { query, where, limit } from 'firebase/firestore';
import { makeRef } from '../../firebase_utilities';


export let commas = format(',.0f');
export let commas1 = format(',.1f');
export let percent = format(',.0%');


/**
 * @param {number} characters
 * 
 * @return {string}
 */
export function timeSaved(characters) {
  let hoursSaved = characters / 200 / 60;
  if (hoursSaved < 1) { 
    return commas(hoursSaved * 60) + ' minutes';
  } else if (hoursSaved < 10) {
    return commas1(hoursSaved) + ' hours';
  } else {
    return commas(hoursSaved) + ' hours';
  }
}


export let useUserDomain = () => {
  return useTypedSelector(store => store.userState.emailVerified && store.userState.email.split('@')[1]);
}; 

  

/**
 * @typedef {object} CompanyData
 * @property {boolean} is_live
 * @property {object} past_week
 * @property {object} all_time
 * @property {string[]} domains
 * @property {string} name
 * @property {object[]} orgs
 * @property {number} updated_at
 */


// We export this so we can reference it in tests.
/** @type {Object<string, CompanyData>} */
export let savedDomainData = {};


/**
 * @param {string} domain 
 * 
 * @return {{data: CompanyData, error: boolean}}
 */
export let usePlaceholderOrgData = (domain) => {
  let [data, setData] = useState(/** @type {CompanyData} */ (null));
  let [error, setError] = useState(null);
  let isMounted = useIsMounted();

  useEffect(() => {
    if (!domain) {
      return;
    }

    (async () => {
      if (!(domain in savedDomainData)) {
        let docs = await storage.getQuery(query(makeRef('company_pages'), where('domains', 'array-contains', domain), limit(1)));
        if (docs.docs.length === 0) {
          savedDomainData[domain] = null;
          setError(true);
          return;
        }
          
        let data = docs.docs[0].data();
    
        data.is_live = (data.is_live === 'TRUE');

        savedDomainData[domain] = /** @type {CompanyData} */ (data);
      }
      
      if (isMounted.current) {
        if (savedDomainData[domain] === null) {
          setError(true);
        } else {
          setData(savedDomainData[domain]);
        }
      }
    })();

    // eslint-disable-next-line
  }, [domain]);

  return { data, error };
};