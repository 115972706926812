import React, { useState, useEffect } from 'react';
import { checkExtensionInstalled } from '../../extension';
import { useTypedSelector } from '../../hooks';
import { isElectronApp } from '../../flags';
import StatelessFeedback from './StatelessFeedback';
import { storage } from '../../utilities';
import { makeRef } from '../../firebase_utilities';

let isDesktop = isElectronApp();

function FeedbackBase() {
  let [open, setOpen] = useState(false);
  
  useEffect(() => {
    setTimeout(() => {
      if (isDesktop) {
        setOpen(true);
      } else {
        checkExtensionInstalled().then((installed) => {
          if (installed) {
            // don't show it if we aren't installed as we'll 
            // be showing the install dialog instead
            setOpen(true);
          }
        });
      }
    }
    , 2000);
  }, []);

  let uid = useTypedSelector(state => state.userState && state.userState.uid);
  let usersSettingsLoaded = useTypedSelector(state => state.userState && state.userState.settingsLoaded);
  let createdTimestamp = useTypedSelector(state => state.userState && state.userState.createdTimestamp);
  let hasWebstoreRating =  useTypedSelector(state => !!(state.userState && state.userState.rating && state.userState.rating.chrome_webstore_rated));
  let hasMicrosoftStoreRating =  useTypedSelector(state => !!(state.userState && state.userState.rating && state.userState.rating.microsoft_store_rated));
  let lastShownTimestamp =  useTypedSelector(state => state.userState && state.userState.rating && state.userState.rating.shown_timestamp);


  if (!open) {
    return null;
  }

  if (!usersSettingsLoaded) {
    return null;
  }

  /**
   * @type {Parameters<typeof StatelessFeedback>[0]['updateRating']}
   */
  function updateRating(data) {
    let setterObj = {};
    for (let key in data) {
      setterObj['rating.' + key] = data[key];
    }
    storage.update(makeRef('users_settings', uid), setterObj, 'HIDE_AUTOSAVE');
  }


  return <StatelessFeedback updateRating={updateRating} hasWebstoreRating={hasWebstoreRating} hasMicrosoftStoreRating={hasMicrosoftStoreRating} uid={uid} createdTimestamp={createdTimestamp} lastShownTimestamp={lastShownTimestamp} onClose={() => {
    setOpen(false);
  }} />;
}


const Feedback = React.memo(FeedbackBase);
export default Feedback;
