import React, { useEffect } from 'react';
import InsertIcon from '@mui/icons-material/AssignmentReturnedOutlined';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ExpandIcon from '@mui/icons-material/KeyboardArrowRight';
import CollapseIcon from '@mui/icons-material/KeyboardArrowDown';
import AIIcon from '@mui/icons-material/AutoFixHigh';
import { Link, ListItemIcon, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Shortcut from '../Shortcut/Shortcut';
import { FOLDER_COLLAPSE_KEY, getIsWebpageEmbedded, isElectronApp } from '../../flags';
import GroupIcon from '../Group/GroupIcon';
import { iconStyleFn, itemStyleFn } from '../SnippetList/shared';
import AppLink from '../Utilities/AppLink';
import T from '@mui/material/Typography';
import { readFromStorage, writeToStorage } from '../AppMicro/load_data';
import { navigateToURL } from '../../desktop_utilities';

/**
 * 
 * @param {{ children: JSX.Element, shouldShow: boolean, }} props
 */
export function EndListItem({ children, shouldShow, }) {
  return <Box sx={{
    display: 'none',
    width: '34px',
    marginRight: 1,
    '.micro-list .MuiListItem-root:hover &': (shouldShow ? {
      display: 'block'
    } : undefined)
  }}>{children}</Box>;
}

/**
 * An Icon Button that is aligned to the right side of a list item
 * 
 * @param {{ shouldShow: boolean, title: string, onClick: React.MouseEventHandler<HTMLButtonElement> }} props
 * @returns 
 */
export function EndButtonItem({ shouldShow, title, onClick, }) {
  return <EndListItem shouldShow={shouldShow}>
    <IconButton
      edge="end"
      aria-label="Insert"
      size="small"
      title={title}

      onClick={onClick}
    >
      <InsertIcon />
    </IconButton>
  </EndListItem>;
}

/**
 * An Icon Button that is aligned to the right side of a list item
 * 
 * @param {{ shouldShow: boolean, title: string, link: string, text?: string, }} props
 * @returns 
 */
export function EndLinkItem({ shouldShow, title, link, text, }) {
  const toolTip = <Tooltip title={title} disableInteractive>
    {text ? <T variant="caption">{text}</T> : <EditIcon
      fontSize="small"
      sx={{
        opacity: .4,
        '&:hover': {
          opacity: 1
        }
      }}
    />}
  </Tooltip>;
  return <div onClick={(e) => {
    e.stopPropagation();
  }}>
    <EndListItem shouldShow={shouldShow}>
      {isElectronApp() ? 
        <Link onClick={() => {
          navigateToURL(link);
        }} style={{
          'color': 'black',
        }}>
          {toolTip}
        </Link> : 
        <AppLink to={link} appType="AI" target="_blank" style={{
          'color': 'black',
        }}>
          {toolTip}
        </AppLink>}
    </EndListItem>
  </div>;
}

const SNIPPET_HEIGHT = 34;

/**
 * 
 * @param {{ select: (arg: { id: string, globalIndex: number, groupId: string, }) => void, } & Object<string, any>} props  
 * @returns 
 */
function SnippetItem(props) {
  const { id, shortcut, select, name, group_id } = props;
  const selected = props.highlightedIndex === props.globalIndex;

  let style = itemStyleFn({
    selected: selected,
    depth: props.depth
  });

  delete style.borderRadius;

  style = Object.assign(style, {
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
  });

  function renderBody() {
    return <>
      <ListItem
        dense
        button
        disableGutters
        disableRipple
        onClick={() => select({ id, globalIndex: props.globalIndex, groupId: group_id, })}
        style={style}
      >
        <ListItemText 
          style={{ paddingRight: 8, margin: 0, paddingLeft: 12 }}
          primaryTypographyProps={{ 
            component: 'div'
          }}
          primary={
            <div style={Object.assign({
              whiteSpace: 'nowrap',
              display: 'flex',
              height: 22,
              fontWeight: style.color ? 500 : undefined,
              // have shortcut name & group name left aligned
              // and shortcut chip right aligned
              // with space in between them
              justifyContent: 'space-between',
              padding: '2px 2px 2px 2px',
            })}>
              {/* Element needed so Google Translate doesn't crash React */}
              <Box sx={{
                flex: 1,
                display: 'flex',
                overflow: 'hidden',
                alignItems: 'center',
                maxWidth: 'fit-content'
              }}>
                {props.options?.is_ai && !getIsWebpageEmbedded() ? <AIIcon
                  fontSize="small"
                  sx={{
                    opacity: .7,
                    marginRight: 1,
                    zoom: .9
                  }}
                /> : null}
                <div style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '16px'
                }}>{name || '...'}</div>
              </Box>
              <div style={{
                verticalAlign: 'middle',
                whiteSpace: 'nowrap',
                display: 'inline-flex',
                alignItems: 'center',
                position: 'relative',
                maxWidth: '50%'
              }}>
                <EndButtonItem shouldShow={props.insertable && !getIsWebpageEmbedded()} title={'Insert Snippet' + (selected ? ' (Enter)' : '')} onClick={() => {
                  props.insert(id, group_id);
                }} />
                <EndLinkItem shouldShow={getIsWebpageEmbedded()} link={'/snippet/' + id} title="Edit snippet" />
                <Shortcut
                  style={{
                    cursor: 'pointer',
                    color: style.color,

                    // using a slightly lighter color for the border looks better
                    borderColor: style.color && '#009faf' 
                  }}
                  shortcut={props.shortcut}
                  hasConflict={props.conflicts && props.conflicts.has(shortcut.toLocaleLowerCase())}
                />
              </div>
            </div>}/>
      </ListItem>
    </>;
  }

  return <div
    style={{
      position: 'absolute',
      left: 0,
      right: 0,
      top: props.index * SNIPPET_HEIGHT
    }}
    className={'list-item-' + props.globalIndex} /* the list-item is for scrolling to */
  >
    {renderBody()}
  </div>;
}

/**
 * @param {{ select: (arg: { id: string, globalIndex: number, groupId: string, }) => void, } & Object<string, any>} props 
 * @returns 
 */
export function GroupItem(props) {
  const { snippets, id, name, collapsed } = props;
  const selected = props.highlightedIndex === props.globalIndex;

  useEffect(() => {
    if (getIsWebpageEmbedded()) {
      readFromStorage(FOLDER_COLLAPSE_KEY).then(statesStored => {
        const states = statesStored ? JSON.parse(statesStored) : {};
        states[id] = collapsed;
        writeToStorage(FOLDER_COLLAPSE_KEY, JSON.stringify(states));
      });
    } else {
      const states = JSON.parse(localStorage.getItem(FOLDER_COLLAPSE_KEY)) || {};
      states[id] = collapsed;
      localStorage.setItem(FOLDER_COLLAPSE_KEY, JSON.stringify(states));
    }
  }, [collapsed, id]);

  function children() {
    if (!snippets) {
      return [];
    }
    if (snippets.length === 0) {
      return <ListItem
        dense
        key="empty"
        disabled
        style={{
          padding: 3,
          paddingBottom: 8
        }}
      >
        <ListItemText
          primary="0 snippets in the folder"
          style={{
            fontSize: 'small',
            color: '#777',
            textAlign: 'center'
          }}
        />
      </ListItem>;
    }

    function renderSnippets() {
      return (
        <List className="micro-list" dense onKeyDown={props.handleArrows}>
          {snippets.map((x, index) => {
            return <SnippetItem
              {...x}
              key={x.id}
              depth={1}
              index={index}
              conflicts={props.conflicts}
              globalIndex={props.globalIndex + index + 1}
              highlightedIndex={props.highlightedIndex}
              select={props.select}
              insertable={props.insertable}
              insert={props.insert}
            />;
          })}
        </List>
      );
    }

    let size = snippets.length;

    return <div style={{
      position: 'relative',
      height: size * SNIPPET_HEIGHT,
      transition: 'height 0.2s cubic-bezier(0.2, 0, 0, 1)'
    }}>
      {renderSnippets()}
    </div>;
  }

  function renderInner() {
    let iconStyle = iconStyleFn({
      selected: selected,
    });


    function renderHeader() {
      return <>
        <ListItem
          dense
          button
          disableGutters
          disableRipple
          style={Object.assign({
            opacity: 1
          }, itemStyleFn({
            selected: selected,
            depth: 0.5
          }))}
          className={'list-selectable list-item-' + props.globalIndex} /* the list-item is for scrolling to */
          onClick={(e) => {
            if (e.type === 'click') {
              props.select({ id: null, globalIndex: props.globalIndex, groupId: null, });
            }
          }}
          onDoubleClick={(e) => {
            if (e.type === 'dblclick') {
              props.setCollapsed(id, !collapsed);
            }
          }}
        >

          <ListItemIcon style={{
            minWidth: 44
          }}>
            <GroupIcon
              inactive={false}
              iconStyle={iconStyle}
              groupId={id}
              emojiContainerStyle={{
                marginLeft: 2
              }}
              size={28}
            />
          </ListItemIcon>
          <ListItemText
            style={{ paddingLeft: 0, margin: 0 }}
            primary={
              <div style={Object.assign({
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '4px 4px 4px 0px',
                userSelect: 'none',
                fontSize: '16px'
              })}>
                <div style={{
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 'bold'
                }}>
                  {name || '...'}
                </div>
              </div>
            } />
          {!collapsed ?
            <IconButton onClick={(e) => {
              props.setCollapsed(id, true);
              e.stopPropagation();
            }} size="small" style={{ marginRight: 3, marginLeft: 3 }}>
              <CollapseIcon fontSize="small"/>
            </IconButton> : <IconButton onClick={(e) => {
              props.setCollapsed(id, false);
              e.stopPropagation();
            }} size="small" style={{ marginRight: 3, marginLeft: 3 }} >
              <ExpandIcon fontSize="small" />
            </IconButton>
          }
        </ListItem>
      </>;
    }

    return <>
      {renderHeader()}
      {!collapsed &&
        (
          <div
            style={{
              height: ((snippets || []).length === 0) ? 32 : undefined
            }}
          >
            {children()}
          </div>
        )
      }
    </>;
  }

  return <div style={{
    opacity: 1
  }}
  >
    {renderInner()}
  </div>;
}
