import React from 'react';
import ToggleSetting from './ToggleSetting';
import { getConfettiDefault } from './ConfettiUtil';
import { Tooltip } from '@mui/material';

export default function ConfettiConfig({ disabled }) {
  return <Tooltip title={disabled ? 'Increase the snippet volume to enable confetti' : ''}><span><ToggleSetting
    propertyName="completion confetti"
    propertyKey="confetti_enabled"
    getDefaultFn={getConfettiDefault}
    label="View confetti when you complete a snippet 🎉"
    disabled={disabled}
  /></span></Tooltip>;
}