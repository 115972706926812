import { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import ViewportContext from './ViewportContext';



/**
 * The viewport item will be change after it appears in the displayed area of the viewport
 *
 * @param {any[]=} refreshByDeps Will calculate appeared components each time the dependencies change. (for example when loading)
 *
 * @returns {{
 *   appeared: boolean,
 *   ref: (node?: HTMLElement) => void
 * }}
 */
export function useViewportItem(refreshByDeps) {
  let [appeared, setAppeared] = useState(false);

  // To connect the React component as a viewport item
  let { itemFactory } = useContext(ViewportContext);
  let { ref, refresh, destroy } = useMemo(() => itemFactory(setAppeared), [itemFactory]);

  useEffect(() => destroy, [destroy]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(refresh, [refresh].concat(refreshByDeps || []));

  return {
    appeared,

    // You need to connect the dom node using the "ref"
    // to check its position inside the scrolled area in the future
    ref
  };
}

export function configureMockViewportController() {
  return {
    finishInitPhase: () => {},
    scrollHandler: () => {},
    itemFactory: (appearFn) => {
      appearFn(true);
      return {
        ref: () => {},
        refresh: () => {},
        destroy: () => {}
      };
    }
  };
}