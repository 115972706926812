const psa = (data) => {
  // For blogs and other places, there is no need of stage.
  return fetch('https://us-central1-blaze-today.cloudfunctions.net/psa', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export default psa;