import React from 'react';
import { toast, showConfirm } from '../../message';
import { log } from '../../logging/logging';
import { format } from 'd3-format';
import AsyncButton from '../AsyncButton/AsyncButton';
import T from '@mui/material/Typography';
import { updateCardDetails, useBillingInformation } from './billing_utils';
import { changeBillingFrequency } from '../../bapi';
import BillingOptions from './BillingOptions';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { billingIssue } from '../../flags';
import BillingIssueWithAction from './BillingIssueWithAction';


let currencyFormatter = format(',.2f');

/**
 * @param {object} props
 * @param {string} props.email
 * @param {object=} props.entity
 */
export default function BillingDetails(props) {
  const [billingDetails, getBilling] = useBillingInformation(props.entity);
  let billingIssueRes = useTypedSelectorShallowEquals((store) => billingIssue(store));
  const hasBillingIssue = !!billingIssueRes?.issue;

  const updateCard = () => {
    return updateCardDetails(props.entity, props.email, () => getBilling());
  };

  const quantityBillingDetails = () => {
    let details = billingDetails;
    if (details.product !== 'business') {
      return null;
    }

    return <T variant="body2" color="textSecondary" paragraph>
      {details.period === 'annually' ? 'Annual' : 'Monthly'} amount is calculated
      as <i>{currencyFormatter(details.amount)} {details.currency.toLocaleUpperCase()} / user
      × {details.quantity} users</i>. Users added mid-period are prorated and billed at the start of the following
      period. Taking into account prorations,
      your {(new Date(billingDetails.next_billing_date * 1000)).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })} bill is currently estimated to
      be <i>{currencyFormatter(details.next_amount)} {details.currency.toLocaleUpperCase()}</i>.
    </T>;
  };

  const couponDetails = () => {
    let details = billingDetails;
    if (!details.coupon) {
      return null;
    }

    return <T variant="body2" color="textSecondary" paragraph>
      The discount <i>{details.coupon}</i> is currently applied to your account. Without this discount, the amount would
      be {currencyFormatter(details.base_amount * details.quantity)} {details.currency.toLocaleUpperCase()} {billingDetails.period === 'annually' ? '/ Year' : '/ Month'}.
      {details.product === 'business' && ' Note that we are unable to apply the discount to the proration period for new users. Proration periods will be billed at the undiscounted rate.'}
    </T>;
  };

  return <>
    <AsyncButton
      style={{ display: billingDetails ? 'none' : 'inline-block' }}
      onClick={(done) => {
        getBilling().then(done, done);
      }}
    >Show billing details</AsyncButton>
    {billingDetails ?
      (<>
        <div style={{ marginBottom: 10 }}>
          <T paragraph>
            Amount: <b>{currencyFormatter(billingDetails.amount * billingDetails.quantity)} {billingDetails.currency.toLocaleUpperCase()} {billingDetails.period === 'annually' ? '/ Year' : '/ Month'}</b> (next
            billing date is {(new Date(billingDetails.next_billing_date * 1000)).toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })})
          </T>
          {couponDetails()}
          {quantityBillingDetails()}
          {billingDetails.last4 && (
            <T paragraph>
              Card: <b>{billingDetails.card_type}</b> XXXX-XXXX-XXXX-<b>{billingDetails.last4}</b>
            </T>
          )}
          {billingDetails.balance ? <T paragraph>
            Credit in your Account: <b>{(billingDetails.balance) / (-100)} {billingDetails.currency.toLocaleUpperCase()}</b>
          </T> : null}
          {billingDetails.team_size && billingDetails.team_size >= billingDetails.quantity && <T paragraph>
            Team size: <b>{billingDetails.team_size}</b>.
          </T>}
        </div>
        {hasBillingIssue && <BillingIssueWithAction onlyAction />}
        {!hasBillingIssue &&  <AsyncButton
          variant="outlined"
          size="small"
          onClick={(done) => updateCard().then(done).catch(done)}
          disabled={hasBillingIssue}
        >
          {billingDetails.last4 ? 'Update card details' : 'Add card details'}
        </AsyncButton>}
        {!hasBillingIssue && !!billingDetails.last4 && billingDetails.period === 'monthly' && <>&nbsp;&nbsp;<AsyncButton
          variant="outlined"
          size="small"
          onClick={(done) => showConfirm({
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Change billing period',
            contents: <>
              <T paragraph>Are you sure you want to change your billing period to yearly?</T>
              <T paragraph>If you change your billing period you will be immediately charged for the next year.</T>
            </>,
            onConfirm: () => {
              changeBillingFrequency(props.entity || {}).then(() => {
                getBilling().then(() => {
                  done();
                  toast('You have been changed to yearly billing.', {
                    duration: 4000,
                    intent: 'success'
                  });
                  log({
                    category: 'Billing',
                    action: 'Changed to yearly'
                  });
                }).catch(() => done());
              }).catch(err => {
                done();
                toast('Could not change billing to yearly. ' + ((err && err.message) || ''), {
                  duration: 8000,
                  intent: 'danger'
                });
                log({
                  category: 'Billing',
                  action: 'Could not change to yearly'
                });
              });
            },
            onCancel: () => done()
          })
          }
        >Change to yearly billing</AsyncButton></>}
        {billingDetails.last4 && <BillingOptions billingDetails={billingDetails} onUpdate={() => getBilling()} entity={props.entity} />}
      </>) : null}
  </>;
}