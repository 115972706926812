import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import KeyboardIcon from '@mui/icons-material/KeyboardOutlined';
import WarningIcon from '@mui/icons-material/ErrorOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { addBreadcrumb } from '@sentry/browser';
import ShortcutConflictDialog from './ShortcutConflictDialog';
import { getConflictingShortcuts, getShortcutsForConflicts } from '../../Sync/find_conflicts';
import { useTypedSelector } from '../../hooks';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
  Typography as T
} from '@mui/material';
import { hasUserInsertedAnySnippet, isAndroid, isElectronApp, isPartOfNewGuidedOnboardingExperiment } from '../../flags';
import Shortcut from '../Shortcut/Shortcut';
import { sendMessageToClient } from '../../desktop_utilities';
import { log } from '../../logging/logging';
import TryMeNew from '../Welcome/TryMeNew';
import './snippetMetadata.css';
import { useAnyAppInstalled, useDesktopClientSupportsFeature } from '../../desktop_hooks';
import { isAiBlaze } from '../../aiBlaze';

/**
 * @typedef {object} UIConflictType
 * @property {('NONE' | 'SUBSET'|'DUPLICATE')} type 
 * @property {string[]} [conflicts]
 * 
 * @param {UIConflictType} conflictType
 */
const getErrorMessage = ({ type, conflicts }) => {
  if (type === 'NONE') {
    return;
  }
  if (type === 'DUPLICATE') {
    return 'Duplicate shortcut. Please choose a unique shortcut.';
  }
  let conflictsWith = conflictWithError(conflicts);
  return `Conflicting shortcut with ${conflictsWith}. Please choose a unique shortcut.`;
};

/**
 * Gets the end part to be shown for conflicts.
 * @param {UIConflictType['conflicts']} conflicts
 */
const conflictWithError = (conflicts) => {
  if (!conflicts?.length) {
    return;
  }
  return conflicts[0] + conflictWithMoreText(conflicts);
};

/**
 * Gets the end part to be shown for conflicts.
 * @param {string[]} conflicts 
 */
const conflictWithMoreText = (conflicts) => {
  if (!conflicts?.length) {
    return '';
  }
  let conflictsWith = '';
  if (conflicts.length >= 3) {
    conflictsWith = ` and ${(conflicts.length - 1)} others`;
  } else if (conflicts.length === 2) {
    conflictsWith = ' and one other';
  };
  return conflictsWith;
};

/**
 * @param {object} props
 * @param {string=} props.snippetId
 * @param {string=} props.groupId
 * @param {string} props.shortcut
 * @param {string} props.name
 * @param {boolean} props.editable
 * @param {boolean=} props.isAddon
 * @param {SnippetTriggerType=} props.trigger
 * @param {any} props.handleName
 * @param {any} props.handleShortcut
 * @param {React.CSSProperties=} props.styleLabelContainer
 * @param {React.CSSProperties=} props.styleShortcutContainer
 * @param {React.MutableRefObject} [props.metadataRef]
 * @param {boolean} [props.isAI]
 * @param {boolean} [props.isPolishSnippet]
 */
function SnippetMetadataBase(props) {
  let [badShortcut, setBadShortcut] = useState(null);
  let [badName, setBadName] = useState(null);
  let [nameError, setNameError] = useState(null);
  let [shortcutError, setShortcutError] = useState(null);
  const [shortcuts, setShortCuts] = useState(/** @type {ReturnType<getShortcutsForConflicts>} */ (null));
  let [shortcutFocusedOnce, setShortcutFocusedOnce] = useState(false);
  let hasConflictInState = useTypedSelector(store => (
    props.shortcut && !!store.uiState?.conflicts?.has(props.shortcut.toLocaleLowerCase())
  ));
  const { hasApp } = useAnyAppInstalled();
  const hasInserted = useTypedSelector(store => hasUserInsertedAnySnippet(store));
  const isNewOnboardingApp = useDesktopClientSupportsFeature('new-try-it-onboarding');
  const isNewOnboarding = useTypedSelector(store => {
    if (hasApp) {
      const supportsOnboarding = isElectronApp() ? isNewOnboardingApp : true;
      return supportsOnboarding && isPartOfNewGuidedOnboardingExperiment(store);
    }
    return false;
  });
  const [showEmail, setShowEmail] = useState(false);
  const [conflictType, setConflictType] = useState(
    /** @type {UIConflictType} */ ({
      type: hasConflictInState ? 'SUBSET' : 'NONE'
    })
  );
  const conflictTimerRef = useRef(null);
  const shortcutRef = useRef(null);
  const [scratchpadInitialValue, setScratchInitialValue] = useState('');
  const [showOnboardingLastStep, setShowLastStep] = useState(false);

  let [scratchpadOpen, setScratchpadOpen] = useState(false);
  let tryNowRef = useRef(null);
  const divTryItOutRef = useRef(/** @type {HTMLDivElement} */ (null));

  /**
   * @param {string} [prompt] 
   */
  function openScratchPad(prompt) {
    setShowEmail(false); // reset by default, and show when needed
    setScratchpadOpen(true);
    if (props.isAI) {
      let scratchValue = '';

      if (prompt) {
        prompt = prompt.toLowerCase();
        if (prompt.includes('respond') && prompt.includes('email')) {
          setShowEmail(true);
        } else if (prompt.includes('friendly')) {
          scratchValue = 'I need this DONE quickly!\n\n';
        } else if (prompt.includes('grammar mistakes')) {
          scratchValue = 'how r u doin 2dya?\n\n';
        } else if (prompt.includes('these points')) {
          scratchValue = '- assignment due on thursday\n- 100 scorable points\n- results next monday\n\n';
        }
      } else {
        if (props.isPolishSnippet) {
          scratchValue = 'Example text to try out your polishing snippet. Type your shortcut after this text\n\n';
        }
      }
      setScratchInitialValue(scratchValue);
    }
  }
  
  useImperativeHandle(props.metadataRef || React.createRef(), () => ({
    openScratchPad,
  }));

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>}
   */
  function handleName(event) {
    addBreadcrumb({
      message: 'edit name'
    });

    let name = event.target.value;
    if (!name.trim().length) {
      setBadName(name);
      setNameError('Snippet label cannot be blank');
    } else {
      setBadName(null);
      setNameError(null);
      if (name !== props.name) {
        props.handleName(name);
      }
    }
  };

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>}
   */
  function handleShortcut(event) {
    setShortcutFocusedOnce(true);
    addBreadcrumb({
      message: 'edit shortcut'
    });

    let shortcut = event.target.value;
    if (props.isAddon) {
      if (!shortcut.match(/^[a-z]{1,25}$/i)) {
        setBadShortcut(shortcut);
        setShortcutError('Commands must consist of 1-25 letters');
      } else {
        setBadShortcut(null);
        setShortcutError(null);
        if (shortcut !== props.shortcut) {
          props.handleShortcut(shortcut);
        }
      }
    } else {
      if (shortcut.indexOf(' ') > -1) {
        setConflictType({
          type: 'NONE'
        });
        setBadShortcut(shortcut);
        setShortcutError('Shortcuts cannot include spaces');
      } else if (!shortcut.length) {
        setConflictType({
          type: 'NONE'
        });
        setBadShortcut(shortcut);
        setShortcutError('Shortcuts cannot be blank');
      } else {
        if (shortcut === '/') {
          const conflictResponse = processForConflictInner(shortcuts, shortcut);
          if (conflictResponse.type !== 'NONE') {
            setConflictType({
              type: 'NONE'
            });
            setBadShortcut(shortcut);
            setShortcutError('Shortcut cannot be "/"');
            return;
          }
        }
        setBadShortcut(null);
        setShortcutError(null);
        if (shortcut !== props.shortcut) {
          props.handleShortcut(shortcut);
        }
      }
      processForConflict(shortcuts, shortcut);
    }
  }
  const groupsLoaded = useTypedSelector((store) => {
    const groups = store.dataState?.groups;
    if (!groups || !Object.keys(groups).length) {
      return false;
    }
    for (const group in groups) {
      if (store.dataState.ignoreGroups?.includes(group)) {
        continue;
      }
      if (groups[group].loading) {
        return false;
      }
    }
    return true;
  });
  
  useEffect(() => {
    // on initial load, groups can be fetched asynchronously. Lets wait for them to load
    if (!groupsLoaded) {
      return;
    }
    let shortcuts = getShortcutsForConflicts(props.snippetId);
    if (!shortcuts?.all?.size) {
      return;
    }
    setShortCuts(shortcuts);
    processForConflict(shortcuts, props.shortcut);
    return () => clearTimeout(conflictTimerRef.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.snippetId, groupsLoaded]);

  
  /**
   * @param {ReturnType<typeof getShortcutsForConflicts>} shortcuts 
   * @param {string} shortcut 
   */
  const processForConflict = (shortcuts, shortcut) => {
    if (!shortcuts) {
      return;
    }
    clearTimeout(conflictTimerRef.current);
    conflictTimerRef.current = setTimeout(() => {
      setConflictType(processForConflictInner(shortcuts, shortcut));
    }, window['testing-timers'] || 200);
  };

  /**
   * @param {ReturnType<typeof getShortcutsForConflicts>} shortcuts 
   * @param {string} shortcut 
   * @returns {typeof conflictType}
   */
  const processForConflictInner = (shortcuts, shortcut) => {
    if (!shortcuts || props.isAddon || !shortcut) {
      return {
        type: 'NONE'
      };
    }
    shortcut = shortcut.toLowerCase();
    
    if (shortcuts.all.has(shortcut)) {
      return {
        type: 'DUPLICATE'
      };
    }
    const conflicts = getConflictingShortcuts(shortcuts, shortcut, props.trigger);
    if (conflicts.length) {
      return {
        type: 'SUBSET',
        conflicts
      };
    }
    return {
      type: 'NONE'
    };
  };

  const getShortcutHelperText = () => {
    if (conflictType.type === 'NONE') {
      return shortcutError;
    }
    if (!shortcutFocusedOnce) {
      return;
    }
    return getErrorMessage(conflictType);
  };

  const msgSend = (value) => {
    return () => {
      sendMessageToClient({
        type: 'try-me-box',
        data: value
      });
    };
  };
  const onFocus = msgSend(true);
  const onBlur = msgSend(false);

  /** @type {React.CSSProperties} */
  const scratchpadStyle = {
    height: 160,
    overflow: 'auto',
    marginTop: 10,
    marginBottom: 10,
    wordBreak: 'break-word',
    resize: 'vertical',
    width: '100%',
    padding: '10px',
  };
  
  return <div style={{ display: 'flex', marginBottom: 10, marginTop: 16, alignItems: 'top', width: '100%' }}>
    <Box
      sx={{
        flex: 1,
        paddingLeft: {
          xs: 2,
          sm: 2,
          md: 0,
          lg: 0,
          xl: 0
        },
        paddingRight: {
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 2
        }
      }}
      style={props.styleLabelContainer}
    >
      <SnippetTextField
        variant="outlined"
        label={props.isAddon ? 'Command label' : `Label (describes the ${isAiBlaze ? 'prompt' : 'snippet'})`}
        helperText={nameError}
        helperTextColor="error"
        // To disable browser autocomplete
        name="tb-snippet-name"
        value={badName !== null ? badName : (props.name || '')}
        onChange={handleName}
        style={{ width: '100%', minWidth: 100 }}
        disabled={!props.editable}
        color={nameError ? 'error' : 'primary'}
        error={!!nameError && conflictType.type !== 'NONE'}
        InputProps={{
          startAdornment: <InputAdornment position="start" style={{ opacity: .4 }}>
            <svg style={{
              width: 19,
              verticalAlign: 'middle',
              position: 'relative',
              top: 2
            }} aria-hidden="true" focusable="false" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a47.998 47.998 0 0 0 14.059 33.941l211.882 211.882c18.745 18.745 49.137 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM259.886 463.996L48 252.118V48h204.118L464 259.882 259.886 463.996zM192 144c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48z"></path></svg>
          </InputAdornment>,
        }}
      />
    </Box>
    <Box
      sx={{
        flex: 1,
        paddingLeft: {
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 2
        },
        paddingRight: {
          xs: 2,
          sm: 2,
          md: 0,
          lg: 0,
          xl: 0
        }
      }}
      style={props.styleShortcutContainer}
    >
      <SnippetTextField
        inputRef={shortcutRef}
        variant="outlined"
        label={(props.isAddon ? 'Command' : 'Shortcut (typed to insert)')}
        helperText={getShortcutHelperText()}
        helperTextColor={conflictType.type !== 'NONE' ? 'warning' : 'error'}
        // To disable browser autocomplete
        name="tb-snippet-shortcut"
        value={badShortcut !== null ? badShortcut : (props.shortcut || '')}
        onChange={handleShortcut}
        onFocus={() => setShortcutFocusedOnce(true)}
        style={{ width: '100%', minWidth: 190 }}
        disabled={!props.editable}
        color={conflictType.type !== 'NONE' ? 'warning' : 'primary'}
        sx={[conflictType.type !== 'NONE' && {
          'label': {
            color: 'warning.main',
          },
          'fieldset': {
            borderColor: 'warning.light'
          },
          '.MuiOutlinedInput-root:hover fieldset': {
            borderColor: 'warning.main'
          }
        }]}
        error={!!shortcutError}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ opacity: .4 }}>
              <KeyboardIcon/>
            </InputAdornment>
          ),
          endAdornment: !props.snippetId ? undefined : <InputAdornment position="end"><Button
            className={!hasInserted && isNewOnboarding && !scratchpadOpen && hasApp ? 'try-it-out-animation' : ''}
            variant="outlined"
            size="small"
            onClick={() => {
              log({
                action: 'Try it out opened',
              }, {
                snippet_id: props.snippetId,
                group_id: props.groupId,
              });
              setShowEmail(false); // reset by default, and show when needed
              openScratchPad();
            }}
            ref={tryNowRef}
            sx={{
              position: 'relative',
            }}
          >
            Try it out</Button></InputAdornment>,
        }}
      />
    </Box>
    {!!props.snippetId && shortcutFocusedOnce && conflictType.type !== 'NONE' && (
      <ShortcutConflictDialog
        shortcut={props.shortcut}
        conflicts={conflictType.conflicts}
        conflictsMoreText={conflictWithMoreText(conflictType.conflicts)}
        onClose={() => setTimeout(() => {
          shortcutRef.current.focus();
        }, 10)}
      />
    )}

    {scratchpadOpen && <Popover
      open
      onClose={() => setScratchpadOpen(false)}
      anchorEl={tryNowRef.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      TransitionProps={{
        onEntering: () => {
          let d = document.querySelector('#try-it-now-edit');
          if (d) {
            // @ts-ignore
            d.focus();
          }
        }
      }}>
      <Box sx={{
        padding: 3,
        width: {
          xs: '280px',
          sm: '420px'
        }
      }}>
        {showEmail && <><T>Example email thread for testing:</T>
          <Box sx={{
            border: '1px dotted grey',
            padding: '10px',
            mt: '10px',
            mb: '20px'
          }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: '10px' }}><T variant="body2" sx={{ fontWeight: 'bold' }}>From Me</T><T variant="caption">Thu, 17 Aug, 11:44 (5 days ago)</T></Box>
            <T variant="subtitle1">Hi All! I fixed the bug with 2+2=5. Please try it again.</T>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', my: '10px' }}><T variant="body2" sx={{ fontWeight: 'bold' }}>From Kevin Doe</T><T variant="caption">Sat, 19 Aug, 05:05 (3 days ago)</T></Box>
            <T variant="subtitle1">Hey! The fix does not work for me. Please suggest any debugging steps?</T>
          </Box>
        </>}
        <T paragraph>Type the shortcut <Shortcut shortcut={props.shortcut} />{props.trigger === 'standalone' ? ' , and then press the Space key,' : ''} in the text box{props.isPolishSnippet ? ' after the initial text:' : ':' }</T>
        {/* 
          Enable rich text editor for the AI snippets also 
          as the AI can potentially respond with styled text 
        */}
        {(isNewOnboarding ?
          <TryMeNew shortcut={props.shortcut} showOnboarding={!hasInserted || showOnboardingLastStep} containerId="try-it-now-edit" style={scratchpadStyle} isSnippetTry onShortcutTrigger={() => {
            setShowLastStep(true);
            setTimeout(() => {
              setShowLastStep(false);
            }, 2000);
            return true;
          }}/> :
          <div
            className="lpt3-input lpt3-large lpt3-fill allow-blaze"
            id="try-it-now-edit"
            data-enable-grammarly="false"
            contentEditable
            style={scratchpadStyle}
            ref={divTryItOutRef}
            onFocus={() => {
              onFocus();
              if (divTryItOutRef.current && scratchpadInitialValue) {
                divTryItOutRef.current.innerText = scratchpadInitialValue;
                window.getSelection().selectAllChildren(divTryItOutRef.current);
                window.getSelection().collapseToEnd();
                setScratchInitialValue('');
              }
            }}
            onBlur={onBlur}
          />)}
        {!props.isAI && <T variant="body2" color="textSecondary">When typed, your shortcut will insert the snippet. {isAndroid() || isElectronApp() ? 'This will work in any application.' : 'This will work on any website. You can also right-click on text boxes to select a snippet.'}</T>}
      </Box>  
    </Popover>}
  </div>;
}

const SnippetMetadata = React.memo(SnippetMetadataBase);
export default SnippetMetadata;

/**
 * @param {import('@mui/material').TextFieldProps & { helperTextColor?: ('info' | 'warning' | 'error') }} props
 */
const SnippetTextField = ({
  helperText,
  helperTextColor,
  onFocus,
  ...props
}) => {
  const ref = useRef(null);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const helperTextRef = useRef(/** @type {{text: typeof helperText, color: typeof helperTextColor }} */ ({}));


  // Save previous state so during animation, it does not collapse weirdly.
  if (helperText) {
    helperTextRef.current = {
      text: helperText,
      color: helperTextColor
    };
  }
  const {
    text,
    color
  } = helperTextRef.current;
    
  return <Tooltip

    title={<>
      <div style={{
        display: 'flex',
        overflow: 'hidden',
        gap: 16
      }}>
        {color === 'warning' && <WarningIcon color="warning" />}
        {color === 'error' && <HighlightOffOutlinedIcon color="error" />}
        <T fontSize={16} overflow="hidden">{text}</T>
      </div>
      <IconButton
        size="small"
        sx={{
          position: 'absolute',
          top: 12,
          right: 8
        }}
        onClick={() => setTooltipIsOpen(false)}
      >
        <CloseIcon
          fontSize="small"
        />
      </IconButton>
    </>}
    arrow
    open={!!helperText && tooltipIsOpen}
    componentsProps={{
      popper: {
        sx: {
          // So that "Try it out" or table selector popup overlays on top.
          zIndex: 10
        }
      },
      arrow: {
        sx: [
          {
            color: 'grey.400'
          }
        ]
      },
      tooltip: {
        sx: [
          {
            maxWidth: 400,
            color: '#000',
            backgroundColor: '#fff',
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 2,
            pr: 6,
            pl: 2,
            py: 2,
            borderColor: 'grey.400',
            boxShadow: 1
          }
        ]
      }
    }}
  >
    <TextField
      autoComplete="off"
      ref={ref}
      onFocus={((evt) => {
        setTooltipIsOpen(true);
        onFocus?.(evt);
      })}
      {...props}
    />
  </Tooltip>;
};