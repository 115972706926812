
export const COL_ATTRIBUTES = ['width'];
export const TABLE_ATTRIBUTES = ['align'];
export const COL_DEFAULT = {
  width: 100
};
export const CELL_IDENTITY_KEYS = ['row', 'cell'];
export const CELL_ATTRIBUTES = ['rowspan', 'colspan'];
export const CELL_STYLES = [
  'backgroundColor',
  'verticalAlign',
  'borderTopColor',
  'borderTopWidth',
  'borderTopStyle',
  'borderBottomColor',
  'borderBottomWidth',
  'borderBottomStyle',
  'borderLeftColor',
  'borderLeftWidth',
  'borderLeftStyle',
  'borderRightColor',
  'borderRightWidth',
  'borderRightStyle'
];

const DEFAULT_STYLES = {
  'borderColor': '#000',
  'borderStyle': 'solid',
  'borderWidth': '1px'
};

export const DEFAULT_CELL_STYLES = {
  'verticalAlign': 'center',
  'borderTopColor': DEFAULT_STYLES['borderColor'],
  'borderTopWidth': DEFAULT_STYLES['borderWidth'],
  'borderTopStyle': DEFAULT_STYLES['borderStyle'],
  'borderBottomColor': DEFAULT_STYLES['borderColor'],
  'borderBottomWidth': DEFAULT_STYLES['borderWidth'],
  'borderBottomStyle': DEFAULT_STYLES['borderStyle'],
  'borderLeftColor': DEFAULT_STYLES['borderColor'],
  'borderLeftWidth': DEFAULT_STYLES['borderWidth'],
  'borderLeftStyle': DEFAULT_STYLES['borderStyle'],
  'borderRightColor': DEFAULT_STYLES['borderColor'],
  'borderRightWidth': DEFAULT_STYLES['borderWidth'],
  'borderRightStyle': DEFAULT_STYLES['borderStyle']
};

export const CELL_STYLE_ATTRIBUTES = {
  backgroundColor: 'bgColor',
  verticalAlign: 'valign'
};

export const CELL_DEFAULT = {
  rowspan: 1,
  colspan: 1
};
export const ERROR_LIMIT = 5;

