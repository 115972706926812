import { init as Sentry_init, configureScope } from '@sentry/browser';
import Storage from './Storage/Storage';
import { isDev, isElectronApp, MY_WEB_VERSION } from './raw_flags';
import { browser } from './extension_utilities';


/** @returns {any} */
export function electron() {
  return window['electronAPI'];
}


/** @type {Storage} */
export const storage = new Storage({
  read: 30000,
  write: 10000,
  delete: 10000
});


if (isDev()) {
  if (typeof window !== 'undefined') {
    window['firestoreStorage'] = storage;
  // eslint-disable-next-line no-restricted-globals
  } else if (typeof self !== 'undefined') {
    // eslint-disable-next-line no-restricted-globals
    self['firestoreStorage'] = storage;
  }
}

export function getSentryReleaseID() {
  return '{{{SENTRY_RELEASE_ID}}}';
}

/**
 * To be used in frontend and in platform
 */
export function setupErrorLogging() {
  Sentry_init({
    dsn: 'https://cc8ab001f3a041a9a12b648032cebdf6@o233950.ingest.sentry.io/1397434',
    // Sentry release ID is replaced by the gitlab-ci.yml build script
    release: getSentryReleaseID(),
    beforeBreadcrumb: (breadcrumb) => {
      if (
        breadcrumb.category === 'fetch'
        && breadcrumb.data.status_code === 200
        && breadcrumb.data.url.startsWith('https://firestore.googleapis.com/google.firestore')
      ) {
        return null;
      }
      return breadcrumb;
    },
    beforeSend: (event, hint) => {
      const originalException = hint?.originalException;
      if (!!originalException) {
        if (typeof originalException !== 'string'
          && originalException.message?.includes('auth/network-request-failed')
          && originalException.name === 'FirebaseError') {
          // Happens when the user network is disconnected
          return null;
        }
        if (originalException.toString?.().includes('Cannot redefine property: googletag')) {
          // Seems to be an adblocker issue
          // https://stackoverflow.com/q/78103254/2181238
          return null;
        }
      }
      return event;
    },
    // We get some log spam from other people's broken extensions on the website,
    // so we ignore extension errors there.
    denyUrls: [
      /^chrome-extension:\/\//i,
      /^safari-extension:\/\//i
    ],
    normalizeDepth: 5,
  });
  configureScope(scope => {
    scope.setTag('web_version', '' + MY_WEB_VERSION);
    if (isElectronApp()) {
      scope.setTag('logger', 'desktop');
    } else {
      scope.setTag('logger', 'site');
    }
  });
}


/**
 * @param {string} data
 * 
 * @return {Promise<string>} 
 */
export async function Sha256Hash(data) {
  let hashed = await crypto.subtle.digest('SHA-256', (new TextEncoder()).encode(data));

  let view = new Uint8Array(hashed);

  let res = '';
  for (let i = 0; i < view.length; i++) {
    let byte = view[i].toString(16);
    if (byte.length < 2) {
      byte = byte.padStart(2, '0');
    }
    res += byte;
  }

  return res;
}


export async function fileToHash(file) {
  return new Promise((resolve, _reject) => {
    let reader = new FileReader();
    reader.onload = function(e) {
      let data = /** @type {string} */ (reader.result);

      let i = new Image(); 

      i.onload = async () => {
        resolve({
          hash: await Sha256Hash(data),
          width: i.naturalWidth,
          height: i.naturalHeight
        });
      };

      i.src = data; 
    };

    reader.readAsDataURL(file);
  });
}

/**
 * @param {string} commandInfo 
 * @returns {boolean}
 */
export function isAddonCommand(commandInfo) {
  return /^{\w+-\w+/.test(commandInfo);
}


/**
 * @param {string} text 
 */
export function copyToClipboard(text) {
  return navigator.clipboard.writeText(text);
}

/**
 * This wrapper for an importer function
 * has an eagerness of triggering the promise within the
 * first few seconds of page load, when the importer function
 * has not already been triggered been yet
 * 
 * @template ImportReturnType
 * @param {() => Promise<{ default: ImportReturnType }>} importFn
 * @param {number} [preloadTimeoutMs]
 * @returns {() => Promise<{ default: ImportReturnType }>}
 */
export function eagerlyLoadImport(importFn, preloadTimeoutMs = 20_000) {
  /** @type {ReturnType<typeof importFn>} */
  let currentPromise = null;
  function initializePromise() {
    if (!currentPromise) {
      currentPromise = importFn().catch(err => {
        const error = new Error('Component failed to load');
        error.name = 'ChunkLoadError';
        throw error;
      });
    }
  }

  // If timeout happens first, then we initialize the promise
  setTimeout(initializePromise, preloadTimeoutMs);

  return () => {
    // If this importer function gets triggered earlier
    // (for example: when the component gets mounted early),
    // then we load the promise earlier than the timeout
    initializePromise();
    return currentPromise;
  };
}

/**
 * Triggers every X ms, not on the leading edge
 * 
 * @param {() => void} fn
 * @param {number} intervalMs
 * @returns {() => void}
 */
export function throttle(fn, intervalMs) {
  let timeout = null;
  let latestTriggerTime = 0;

  function triggerFn() {
    latestTriggerTime = Date.now();
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    fn();
  }

  return () => {
    const nowTime = Date.now();

    if (!timeout) {
      const expectedTime = latestTriggerTime + intervalMs;
      const diffMs = expectedTime <= nowTime ? intervalMs : expectedTime - nowTime;
      timeout = setTimeout(() => {
        triggerFn();
      }, diffMs);
    } else {
      // If timeout already exists, then it will be triggered
      // and we need to do nothing about it
    }
  };
}

/**
 * @param {Function} fn 
 * @param {number} timeMs 
 */
export function debounce(fn, timeMs) {
  let timer = null;

  return function(...args) {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      timer = null;
      fn(...args);
    }, timeMs);
  };
}

export { browser };