import React from 'react';
import Callout from '../Callout/Callout';
import WarningIcon from '@mui/icons-material/WarningRounded';
import T from '@mui/material/Typography';
import { signout } from '../../signout';
import Button from '@mui/material/Button';

/**
 * @param {object} props
 * @param {string} props.expectedEmail
 * @param {string} props.actualEmail
 * @param {'folder'|'org'} props.type folder share or org invitation
 */
export default function EmailMismatchReminder(props) {
  const msg = props.type === 'folder' ? 'The folder was shared with' : 'The organization invitation was sent to';
  return <Callout
    icon={<WarningIcon />}
    warning
    style={{ marginBottom: 10 }}
    title="Incorrect email address"
  >
    <T paragraph>{msg} <b>{props.expectedEmail}</b>, but you are logged in as <b>{props.actualEmail}</b></T>
    
    <T paragraph>You can signout from this account, and then log back in with the correct account.</T>

    <div style={{ textAlign: 'right', marginTop: 8 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          signout(); 
        }}
      >Signout</Button>
    </div>
  </Callout>;
}