import { intersectPlatformSupport } from '../../Sync/AddAddonOptions';

/**
 * @param {import('../../Sync/Sync').Group|GroupObjectType} group
 */
export function getPackSupportedPlatforms(group) {
  if (!group || !group.snippets) {
    return null;
  }
  let supportedPlatforms = null;
  for (const addon of group.snippets) {
    if (addon.addonOptions?.platforms) {
      supportedPlatforms = intersectPlatformSupport(supportedPlatforms, addon.addonOptions?.platforms);
    }
  }

  return supportedPlatforms;
}
