import { createContext } from 'react';

/**
 * @typedef {{ onSubmitPrompt: Function, onStreamingEnd: Function }} ActionContextType
 * @type {React.Context<ActionContextType>}
 */
export const ActionContext = createContext({
  onSubmitPrompt: null,
  onStreamingEnd: null,
});

/**
 * @type {React.Context<{ name: string, id: string, shortcut: string, taskName: 'template'|'sidebarchat', }>}
 */
export const SnippetContext = createContext({
  id: '',
  shortcut: '',
  name: '',
  taskName: 'template',
});

/**
 * @type {React.Context<{ content: string, selection: string }>}
 */
export const SnippetContentContext = createContext({
  content: null, selection: null,
});

export const ChatDataContext = createContext({
  isPro: false,
  isOrg: false,
  userInfo: /** @type {import('../PermissionList/utilities').UserInfo} */ ({}),
  getConversation: /** @type {() => string} */ (null),
  userPrecedingText: '',
  isMarkdownEnabled: false,
});