import {
  MenuItem
} from '@mui/material';
import React, { useState } from 'react';
import TeamSizeDialog from './TeamSizeDialog';

/**
 * 
 * @param {object} props 
 * @param {number} props.teamSize
 * @param {() => any} props.onUpdate
 * @param {() => any} props.onClose
 * @param {React.Ref<any>} ref 
 * @returns 
 */
const ChangeTeamSizeButtonBase = ({
  teamSize,
  onUpdate,
  onClose
}, ref) => {
  const [showDialog, setShowDialog] = useState(false);
  const handleClose = () => {
    setShowDialog(false);
    onClose();
  };
  return (
    <>
      <MenuItem
        ref={ref}
        onClick={() => setShowDialog(true)}
      >
        Change team size
      </MenuItem>
      {showDialog && <TeamSizeDialog
        teamSize={teamSize}
        onClose={handleClose}
        onUpdate={onUpdate}
      />}
    </>
  );
};

const ChangeTeamSizeButton = React.forwardRef(ChangeTeamSizeButtonBase);
export default ChangeTeamSizeButton;
