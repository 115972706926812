
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTypedSelector } from '../../../hooks';
import { makeRef } from '../../../firebase_utilities';
import { storage } from '../../../utilities';
import { toast } from '../../../message';
import { limit, orderBy, startAfter } from 'firebase/firestore';
import TrashedDialogContent from '../../TrashedDialog/TrashedDialogContent';
import TrashedList from '../../TrashedDialog/TrashedList';
import {
  Box,
  Skeleton,
  Typography as T
} from '@mui/material';
import TrashedInfo from '../../TrashedDialog/TrashedInfo';
import TrashedEmptyState from '../../TrashedDialog/TrashedEmptyState';
import { TrashedDialogContext } from './TrashedTextBlazeDialog';
import {
  TRASH_EXPIRATION_LABEL,
  TRASH_LABELS,
  generateQuery,
  getFirebaseQuery,
  mapData
} from './trashed_utils';
import { transformData } from './TrashedSnippetUtils';
const PAGE_SIZE = 30;
// FIREBASE LIMIT for `in` query
const QUERY_LIMIT = 10;

const DISPLAY_LIMIT = 30;

const label = TRASH_LABELS['snippets'];
const {
  groupTypePlural
} = label;


/**
 * 
 * @param {object} props 
 * @param {string[]} props.groupIds 
 * @param {Parameters<import('./TrashedFirestore').default>['0']['onRestore']} props.onRestore 
 * @param {React.ReactElement=} props.header 
 * @returns 
 */
const TrashedAllSnippets = ({
  groupIds,
  onRestore,
  header
}) => {
  const [data, setData] = useState(/** @type {ReturnType<transformData>[]} */([]));
  let [error, setError] = useState(false);
  const { loading, setLoading } = useContext(TrashedDialogContext);
  const pagingRef = useRef({});
  const groups = useTypedSelector((state) => state.dataState.groups);
  const constants = {
    setLoading,
    groups
  };
  const constantRef = useRef(constants);
  constantRef.current = constants;


  const strAllGroupIds = JSON.stringify(groupIds);

  /**
     * 
     * @param {string[]} groupIds
     * @param {number} pageSize
     * @param {object} loadingRef
     * @param {boolean} loadingRef.cancelled
     * @param {{[pageRef: string] : boolean}} loadingRef.loading
     * @param {any=} startAfterRef
     */
  const getPage = async (groupIds, pageSize, loadingRef, startAfterRef) => {
    const pageRef = groupIds[0];
    /**
     * @param {boolean} val
     */
    const setLoading = (val) => {
      constantRef.current.setLoading(val);
    };
    try {
      setError(false);
      setLoading(true);
      /** @type {import('firebase/firestore').QueryConstraint[]} */
      const queries = [
        ...generateQuery([
          ['group_id', 'in', groupIds]
        ]),
        orderBy('deleted_at', 'desc'),
        limit(pageSize),
      ];
      if (startAfterRef) {
        queries.push(startAfter(startAfterRef));
      }

      const ref = getFirebaseQuery('snippets', queries);

      const snapshot = await storage.getQuery(ref);
      if (loadingRef.cancelled) {
        return;
      }

      let docs = snapshot.docs;

      /**
       * 
       * @param {string} id 
       * @param {Parameters<mapData>['1']} data 
       */
      const mapDataWrapper = (id, data) => {
        let res = mapData(id, data);
        return transformData(res, constantRef.current.groups, pageRef);
      };
      const newData = docs.map(x => mapDataWrapper(x.id, x.data()));
      const gotAdditional = newData.length >= PAGE_SIZE;
      if (gotAdditional) {
        pagingRef.current[pageRef] = newData[newData.length - 1].actual.deleted_at;
      } else if (pagingRef.current[pageRef]) {
        delete pagingRef.current[pageRef];
      }
      setData(d => mergeData(d, newData));
      delete loadingRef.loading[pageRef];
      if (!Object.keys(loadingRef.loading).length) {
        setLoading(false);
      }
    } catch (err) {
      if (!loadingRef.cancelled) {
        toast(`Error fetching trashed items - ${err.message}`, {
          intent: 'danger'
        });
        console.error(err);
        setError(true);
      }

      delete loadingRef.loading[groupIds[0]];
      setLoading(false);
    }
  };

  useEffect(() => {
    /**
     * @type {string[]}
     */
    const groupIds = JSON.parse(strAllGroupIds);

    constantRef.current.setLoading(true);
    let loadingRef = {
      cancelled: false,
      loading: {}
    };
    const timer = setTimeout(() => {
      pagingRef.current = {};
      setData([]);
      for (let index = 0; index < groupIds.length; index += QUERY_LIMIT) {
        const subSet = groupIds.slice(index, index + QUERY_LIMIT);
        loadingRef.loading[subSet[0]] = true;
        getPage(subSet, PAGE_SIZE, loadingRef);
      }
    }, 300);
    return () => {
      clearTimeout(timer);
      loadingRef.cancelled = true;
    };
  }, [strAllGroupIds]);
  /**
   * 
   * @param {ReturnType<transformData>} item 
   */
  const removeItem = (item) => {
    setData(currentData => currentData.filter(d => d.id !== item.id));
    const startAfterRef = pagingRef.current[item.pageRef];
    if (startAfterRef) {
      const groupIndex = groupIds.indexOf(item.pageRef);
      const subSet = groupIds.slice(groupIndex, groupIndex + QUERY_LIMIT);
      getPage(subSet, 1, {
        cancelled: false,
        loading: {}
      }, startAfterRef);
    }
  };
  return (
    <TrashedDialogContent
      loading={loading}
    >

      <TrashedInfo
        label={groupTypePlural}
        expiryLabel={TRASH_EXPIRATION_LABEL}
        extraContents={data.length >= DISPLAY_LIMIT && <T fontSize={14} variant="body2">
          Displaying the top {DISPLAY_LIMIT} snippets. Please select a folder to view all snippets if needed.
        </T>}
      />
      {header}
      {!loading && !data.length && <TrashedEmptyState
        label={groupTypePlural}
        expiryLabel={TRASH_EXPIRATION_LABEL}
      />}
      <TrashedList
        data={data.length > DISPLAY_LIMIT ? data.slice(0, DISPLAY_LIMIT) : data}
        labels={label}
        error={!!error}
        onDelete={async (item) => {
          await storage.delete(makeRef('trashed_snippets', item.id));
          removeItem(item);
        }}
        onRestore={async (item) => {
          await onRestore(item);
          removeItem(item);
        }}
        IconComponent={SnippetIcon}
      />
      {loading && (
        <ListSkelton />
      )}
    </TrashedDialogContent>
  );
};

export default TrashedAllSnippets;


/**
 * Merged full data with new data based on trashed_at timestamp.
 * Data is expected to be sorted.
 * @param {ReturnType<transformData>[]} fullData 
 * @param {ReturnType<transformData>[]} data 
 */
const mergeData = (fullData, data) => {
  // There is no existing data. So use new data as merged data directly.
  if (!fullData.length) {
    return data;
  }
  const newData = [];
  let fullIndex = 0;
  let dataIndex = 0;
  // Go through each item in the existing list.
  for (; fullIndex < fullData.length; fullIndex++) {
    const existingDoc = fullData[fullIndex];
    // Go through each item in the existing list.
    for (; dataIndex < data.length; dataIndex++) {
      const newDoc = data[dataIndex];
      if (newDoc.trashed_at <= existingDoc.trashed_at) {
        // Break it as newDoc is older.
        // This will make sure new docs index does not change.
        break;
      }
      // new doc is latest. So add before existing doc.
      newData.push(newDoc);
    }
    // Went through all new docs before data
    newData.push(existingDoc);
  }
  // Add remaining items from new data into the list.
  for (; dataIndex < data.length; dataIndex++) {
    const newDoc = data[dataIndex];
    newData.push(newDoc);
  }
  return newData;
};


/**
 * 
 * @param {object} props
 * @param {string=} props.icon
 */
export const SnippetIcon = ({
  icon
}) => {
  return <div
    style={{
      fontSize: 24
    }}
  >
    {icon || '📄'}
  </div>;
};

const ListSkelton = () => {
  return (
    <Box sx={{
      px: 2.4,
      py: .7,
      display: 'flex',
      gap: '12px',
      alignItems: 'center'
    }}>
      <Skeleton animation="wave" variant="circular" sx={{
        width: '1.5rem',
        height: '1.5rem',
        mr: 2
      }} />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
          <Skeleton animation="wave" variant="text" sx={{
            fontSize: '1rem',
            width: '8rem',
            display: 'inline-block',
            mr: 1.2
          }} />
          <Skeleton animation="wave" variant="rounded" sx={{
            width: '4rem',
            height: '1rem',
            borderRadius: '25px',
            display: 'inline-block'
          }} />
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
          <Skeleton animation="wave" variant="rounded" sx={{
            width: '6rem',
            height: '1rem',
            borderRadius: '25px',
            display: 'inline-block',
            mr: 1.2
          }} />
          <Skeleton animation="wave" variant="text" sx={{
            fontSize: '1rem',
            width: '8rem',
            display: 'inline-block'
          }} />
        </Box>
      </Box>

      <Skeleton animation="wave" variant="rounded" sx={{
        width: '5.6rem',
        height: '2rem',
        mr: 0.8
      }} />
      <Skeleton animation="wave" variant="rounded" sx={{
        width: '0.4rem',
        height: '1.5rem',
        mr: 1.2
      }} />
    </Box>
  );
};