import {
  Typography as T
} from '@mui/material';
import React from 'react';
import { useTypedSelector } from '../../hooks';
import ToggleSetting from './ToggleSetting';

export default function SnipppetEditingChips() {
  const oldKey = 'snippet_editing_chips_enabled';
  const newKey = 'snippet_editing_chips_enabled_2';
  let oldValue = useTypedSelector(store => store?.userState?.options?.[oldKey]);
  
  // only show if the old setting is changed
  if (typeof oldValue !== 'boolean') {
    return null;
  }

  return <>
    <T
      gutterBottom
      variant="h6"
      sx={{ mt: 2 }}
    >Snippet editing</T>
    <ToggleSetting
      propertyName="snippet editing chips"
      propertyKey={newKey}
      getDefaultFn={() => true}
      label="Use the modern snippet command editor (allows you to easily edit dynamic commands)."
    />
    <T
      gutterBottom
      variant="caption"
      sx={{ mt: 2 }}
      component="div"
    >If you have any issues at all with the modern experience, please let us know at <a href="mailto:support@blaze.today">support@blaze.today</a>.</T>
  </>;
} 