import React, { useContext, useState, } from 'react';
import { runBlock } from '../../snippet_processor/Equation';
import { loopIcon } from './shared';
import { ActionContext, ShowError, Chicklet } from './SharedComponents';
import useOnMount from '../../hooks/useOnMount';
import RunIcon from '@mui/icons-material/Terminal';

/**
 * @param {object} props
 * @param {import("../../snippet_processor/ParseNode").default} props.node
 * @param {import("../../snippet_processor/DataContainer").Environment} props.env
 */
export default function RunRenderer({ node, env, }) {
  let { onChange, } = useContext(ActionContext);
  const [error, setError] = useState('');
  const [isRunning, setIsRunning] = useState(false);

  useOnMount(async () => {
    const position = node.position();
    const existingResult = env.config.doCommandsRan[position];
    if (existingResult) {
      // Ran once, do not run again

      if (existingResult.status === 'ERROR') {
        // Reuse previous error message
        setError(existingResult.message);
      }
      return;
    }
    setIsRunning(true);

    // Never ran, so run this block once
    const myEnv = env.derivedLocation('run_command - -100');

    let tree;
    try {
      tree = await node.info.attributes.position[0].ast(myEnv, 'Block');
      await runBlock(tree, myEnv);

      env.config.doCommandsRan[position] = {
        status: 'SUCCESS'
      };
      setError('');
    } catch (e) {
      env.config.doCommandsRan[position] = {
        status: 'ERROR',
        message: e.message
      };

      setError(e.message);
    }

    setIsRunning(false);

    onChange();
  });

  if (error) {
    return <ShowError msg={error} blocking={false} nodeOrAddonId={node} />;
  }
  
  return Chicklet(<span><RunIcon /><span className="label run-chip">Run</span> {isRunning ? <span className="chick-loader blaze-loading">{loopIcon}</span> : null}</span>, {
    title: 'Running command',
  });
}