import { store, getShortcuts } from '@store';


let findConflictsTimer = null;


export default function findConflicts() {
  if (findConflictsTimer) {
    clearTimeout(findConflictsTimer);
  }
  findConflictsTimer = setTimeout(findConflictsInner, 100);
}


function findConflictsInner() {
  let shortcuts = getShortcuts();

  if (shortcuts) {
    let normal = new Set(), standalone = new Set(), anywhere = new Set();
    let conflictsPairs = [];

    for (let shortcut in shortcuts) {
      if (shortcut) {
        let snippets = shortcuts[shortcut];
        if (snippets) {
          for (let snippet of snippets) {
            let type = snippet.group && snippet.group.data && snippet.group.data.options && snippet.group.data.options.trigger;

            let set = normal;
            if (type === 'standalone') {
              set = standalone;
            } else if (type === 'anywhere') {
              set = anywhere;
            }

            if (set.has(shortcut)) {
              conflictsPairs.push([shortcut, shortcut]);
            } else {
              set.add(shortcut);
            }
          }
        }
      }
    }

    // standalone checks against standalone were done on import

    // normal against normal and standalone
    let base = Array.from(normal);
    let full = base.concat(Array.from(standalone));

    for (let i = 0; i < base.length; i++) {
      let key = base[i];
      if (!key) {
        continue;
      }
      for (let j = i + 1; j < full.length; j++) {
        let other = full[j];
        if (!other) {
          continue;
        }
        if (other.indexOf(key) === 0) {
          conflictsPairs.push([key, other]);
        } else if (j < base.length && key.indexOf(other) === 0) {
          conflictsPairs.push([key, other]);
        }
      }
    }

    // anywhere against all
    base = Array.from(anywhere);
    full = base.concat(Array.from(normal)).concat(Array.from(standalone));

    for (let i = 0; i < base.length; i++) {
      let key = base[i];
      if (!key) {
        continue;
      }
      for (let j = i + 1; j < full.length; j++) {
        let other = full[j];
        if (!other) {
          continue;
        }
        if (other.indexOf(key) > -1) {
          conflictsPairs.push([key, other]);
        } else if (j < base.length && key.indexOf(other) > -1) {
          conflictsPairs.push([key, other]);
        }
      }
    }


    let conflicts = new Map();

    for (let pair of conflictsPairs) {
      for (let shortcut of pair) {
        if (!conflicts.has(shortcut)) {
          conflicts.set(shortcut, new Set());
        }
      }
      conflicts.get(pair[0]).add(pair[1]);
      conflicts.get(pair[1]).add(pair[0]);
    }

    store.dispatch({
      type: 'CONFLICTS',
      conflicts
    });
  } else {
    store.dispatch({
      type: 'CONFLICTS'
    });
  }
}

/**
 * @param {string} [snippetId] snippet whose shortcut is skipped from the output. This is used when checking for conflicts of a shortcut of an existing snippet.
 */
export const getShortcutsForConflicts = (snippetId = null) => {
  let shortcuts = getShortcuts();
  if (!shortcuts) {
    return;
  }

  let normal = /** @type {Set<string>} */ (new Set()),
    standalone = /** @type {Set<string>} */ (new Set()),
    anywhere = /** @type {Set<string>} */ (new Set()),
    all = /** @type {Set<string>} */ (new Set());

  for (let eachShortcut in shortcuts) {
    if (!eachShortcut) {
      continue;
    }
    let snippets = shortcuts[eachShortcut];
    if (!snippets) {
      continue;
    }
    for (let snippet of snippets) {
      if (snippetId && snippet.id === snippetId) {
        continue;
      }
      let type = snippet.group?.data?.options?.trigger;
      let set = normal;
      if (type === 'standalone') {
        set = standalone;
      } else if (type === 'anywhere') {
        set = anywhere;
      }

      set.add(eachShortcut);
      all.add(eachShortcut);
    }
  }
  return {
    all,
    normal,
    standalone,
    anywhere,
  };
};

/**
  * @param {ReturnType<typeof getShortcutsForConflicts>} shortcuts 
  * @param {string} shortcut 
  * @param {SnippetTriggerType} triggerType
  * @returns {string[]}
  * @param {boolean} [onlyOne]
  */
export function getConflictingShortcuts(shortcuts, shortcut, triggerType, onlyOne = false) {
  const result = [];
  for (const other of shortcuts.normal) {
    if (triggerType === 'anywhere' && other.includes(shortcut)) {
      result.push(other);
      if (onlyOne) {
        return result;
      }
      continue;
    } else if (triggerType !== 'standalone' && other.startsWith(shortcut)) {
      result.push(other);
      if (onlyOne) {
        return result;
      }
      continue;
    }
    if (shortcut.startsWith(other)) {
      result.push(other);
      if (onlyOne) {
        return result;
      }
      continue;
    }
  }
  if (triggerType !== 'standalone') {
    for (const other of shortcuts.standalone) {
      if (triggerType === 'anywhere' && other.includes(shortcut)) {
        result.push(other);
        if (onlyOne) {
          return result;
        }
        continue;
      } else if (other.startsWith(shortcut)) {
        result.push(other);
        if (onlyOne) {
          return result;
        }
        continue;
      }
    }
  }
  for (const other of shortcuts.anywhere) {
    if (triggerType === 'anywhere' && other.includes(shortcut)) {
      result.push(other);
      if (onlyOne) {
        return result;
      }
      continue;
    } else if (triggerType !== 'standalone' && other.startsWith(shortcut)) {
      result.push(other);
      if (onlyOne) {
        return result;
      }
      continue;
    }

    if (shortcut.includes(other)) {
      result.push(other);
      if (onlyOne) {
        return result;
      }
      continue;
    }
  }

  return result;
}

/**
 * @param {string} shortcut 
 * @param {SnippetTriggerType} [triggerType]
 */
export function getConflictFreeShortcut(shortcut, triggerType = 'word') {
  if (!shortcut) {
    return shortcut;
  }

  const allShortcuts = getShortcutsForConflicts();
  const originalShortcut = shortcut;
  const hasConflict = () => !!getConflictingShortcuts(allShortcuts, shortcut, triggerType, true)[0];
  
  if (!hasConflict()) {
    return shortcut;
  }

  const startCharacter = originalShortcut[0],
    remainingShortcut = originalShortcut.substring(1),
    // Unicode character class escape, see
    // https://unicode.org/reports/tr18/#General_Category_Property
    // L is for letter, N is for number
    isStartNotLetterOrDigit = !/[\p{L}\p{N}]/u.test(startCharacter);

  for (let i = 2; i <= 20; i++) {
    if (isStartNotLetterOrDigit) {
      shortcut = startCharacter + i + remainingShortcut;
    } else {
      shortcut = i + originalShortcut;
    }
    if (!hasConflict()) {
      return shortcut;
    }
  }

  return originalShortcut;
}
