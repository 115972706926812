import Quill from 'quill';
import DeltaAttributes from 'quill-delta/dist/AttributeMap';
import clone from 'clone';
import { expandDeltaContents } from './editor_utilities';
import Block from 'quill/blots/block';

/**
 * WHITELISTED FORMATS
 * ----------------------
 * replacement
 * collapsedCommand
 * insert-image
 * background
 * bold
 * color
 * font
 * code
 * italic
 * link
 * size
 * strike
 * script
 * underline
 * blockquote
 * indent
 * list
 * align
 * direction
 */

const UNSUPPORTED_ATTRIBUTES = [
  'header',
  'code-block'
];
const UNSUPPORTED_INSERTS = [
  'formula',
  'video'
];


  
const FORMATS_TO_PERSIST = [
  'bold',
  'italic',
  'underline',
  'strike',
  'color',
  'background',
  'align',
  'direction',
  'font',
  'size',
  'script'
];

const FORMATS_WITH_PICKER = [
  'font',
  'size'
];


// taken from editor.js unmodified
function normalizeDelta(delta) {
  return delta.reduce(function(delta, op) {
    if (op.insert === 1) {
      let attributes = clone(op.attributes);
      delete attributes['image'];
      return delta.insert({ image: op.attributes.image }, attributes);
    }
    if (op.attributes != null && (op.attributes.list === true || op.attributes.bullet === true)) {
      op = clone(op);
      if (op.attributes.list) {
        op.attributes.list = 'ordered';
      } else {
        op.attributes.list = 'bullet';
        delete op.attributes.bullet;
      }
    }
    UNSUPPORTED_ATTRIBUTES.forEach(attr => {
      // Delete unsupported attributes if any.
      if (op.attributes?.[attr]) {
        delete op.attributes?.[attr];
      }
    });
    if (typeof op.insert === 'string') {
      let text = op.insert.replace(/\r\n/g, '\n').replace(/\r/g, '\n');
      return delta.insert(text, op.attributes);
    }
    for (const INSERT_KEY of UNSUPPORTED_INSERTS) {
      // Ignore unsupported inserts if any.
      if (op.insert?.[INSERT_KEY]) {
        return delta;
      }
    }
    return delta.push(op);
  }, new Delta());
}


const Delta = Quill.import('delta');

let Parchment = Quill.import('parchment');


// taken from block.js, extend() replaced with Object.assign
function bubbleFormats(blot, formats = {}) {
  if (blot == null) {
    return formats;
  }
  if (typeof blot.formats === 'function') {
    formats = Object.assign(formats, blot.formats());
  }
  if (blot.parent == null || blot.parent.blotName === 'scroll' || blot.parent.statics.scope !== blot.statics.scope) {
    return formats;
  }
  return bubbleFormats(blot.parent, formats);
}


/**
 * @param {object} props
 * @param {object} props.quillNodeRef
 * @param {object} props.quillContainerNodeRef
 * @param {object} props.quillModules
 * @param {string=} props.placeholder
 * @param {ReturnType<import('./quillConfig')['getFullRegistry']>} props.registry
 * @param {(Omit<Exclude<Parameters<import('quill/modules/keyboard').default['addBinding']>['0'], (string | number)>, 'key'> & { key: string })[]=} props.keyboardBindings
 */
export default function createQuillEditor(props) {
  const quill = new Quill(props.quillNodeRef, {
    theme: 'snow',
    placeholder: props.placeholder,
    bounds: props.quillContainerNodeRef,
    modules: props.quillModules,
    registry: props.registry
  });
  // @ts-ignore Used by quill-better-table
  quill.convertDeltaToTextDelta = expandDeltaContents;




  
  // works around bug with "<b>{time:XX}</b>{time:XX}" and collapsed commands"
  Object.getPrototypeOf(/** @type {any} */ (quill).editor).applyDelta = function applyDelta(delta) {
    let consumeNextNewline = false;
    this.scroll.update();
    let scrollLength = this.scroll.length();
    this.scroll.batchStart();
    delta = normalizeDelta(delta);
    delta.reduce((index, op) => {
      let length = op.retain || op.delete || op.insert.length || 1;
      let attributes = op.attributes || {};
      if (op.insert != null) {
        if (typeof op.insert === 'string') {
          let text = op.insert;
          if (text.endsWith('\n') && consumeNextNewline) {
            consumeNextNewline = false;
            text = text.slice(0, -1);
          }
          if (index >= scrollLength && !text.endsWith('\n')) {
            consumeNextNewline = true;
          }
          this.scroll.insertAt(index, text);
          let [line, offset] = this.scroll.line(index);
          let formats = Object.assign({}, bubbleFormats(line));
          if (line instanceof Block) {
            let [leaf, ] = line.descendant(Parchment.LeafBlot, offset);
            formats = Object.assign(formats, bubbleFormats(leaf));
          }
          attributes = DeltaAttributes.diff(formats, attributes) || {};
        } else if (typeof op.insert === 'object') {
          let key = Object.keys(op.insert)[0];  // There should only be one key
          if (key == null) {
            return index;
          }
          this.scroll.insertAt(index, key, op.insert[key]);
          if (key === 'collapsedCommand') {
            // workaround added
            // FIXME: remove me when quill is fixed
            this.scroll.batchEnd();
            this.scroll.batchStart();
          }
        }
        scrollLength += length;
      }
      Object.keys(attributes).forEach((name) => {
        this.scroll.formatAt(index, length, name, attributes[name]);
      });
      return index + length;
    }, 0);
    delta.reduce((index, op) => {
      if (typeof op.delete === 'number') {
        this.scroll.deleteAt(index, op.delete);
        return index;
      }
      return index + (op.retain || op.insert.length || 1);
    }, 0);
    this.scroll.batchEnd();
    return this.update(delta);
  };


  Object.getPrototypeOf(/** @type {any} */ (quill).editor).removeFormat = function removeFormat(index, length) {
    // Adapts quill clear format function to not strip out collasped commands.
    //
    // Would be good to remove this if/when quill has better hooks for this type of
    // thing.
    let text = this.getContents(index, length).filter(function(op) {
      return (typeof op.insert === 'string') || !!op.insert.collapsedCommand;
    });
    text.forEach(op => {
      delete op.attributes;
    });
    let [line, offset] = this.scroll.line(index + length);
    let suffixLength = 0, suffix = new Delta();
    if (line != null) {
      suffixLength = line.length() - offset;
      suffix = line.delta().slice(offset, offset + suffixLength - 1).insert('\n');
    }
    let contents = this.getContents(index, length + suffixLength);
    let diff = contents.diff(new Delta(text).concat(suffix));
    let delta = new Delta().retain(index).concat(diff);
    return this.applyDelta(delta);
  };


  const BLAZE_DRAG_KEY = Math.random().toString(32) + Math.random().toString(32);
  

  // @ts-ignore
  quill.scroll.handleDragStart = (e) => {
    // @ts-ignore
    const [range] = quill.selection.getRange();
    if (range == null) {
      return;
    }
    e.dataTransfer.setData('text/plain', quill.getText(range));
    // @ts-ignore
    e.dataTransfer.setData('text/html', quill.getSemanticHTML(range));
    e.dataTransfer.setData('blaze-drag-key', BLAZE_DRAG_KEY);
  };


  quill.root.addEventListener('drop', (e) => {
    if (e.dataTransfer.getData('command-data')) {
      e.preventDefault();

      let initEl = document.querySelector('.collapsed-command[data-dragging="true"]');
      if (!initEl) {
        // Can happen if doc changes while dragging.
        // e.g. if edited by another user.

        // TODO: explore if there is better way to handle this
        // rather than bailing... Maybe we can somehow identify
        // the original element correctly and continue the drag
        // and drop.
        return;
      }

      let original = Parchment.Registry.find(initEl);

      let origIndex = quill.getIndex(original);

      let range = getEventRange(e);
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      quill.focus();
      let { index } = quill.getSelection();
      let insertingDelta = (new Delta()).insert({
        'collapsedCommand': JSON.parse(e.dataTransfer.getData('command-data'))
      });
      sel.removeAllRanges();
    

      let delta;
      let newLoc;
      if (origIndex < index) {
        delta = (new Delta()).retain(origIndex).delete(1);
        delta = delta.retain(index - (origIndex + 1));
        delta = delta.concat(insertingDelta);
        newLoc = index - 1;
      } else {
        delta = (new Delta()).retain(index);
        delta = delta.concat(insertingDelta);
        delta = delta.retain(origIndex - index).delete(1);
        newLoc = index;
      }

      quill.updateContents(delta, 'user');
      quill.setSelection(newLoc + 1, 0);
      quill.focus();
    } else if (e.dataTransfer.getData('text/html')) {
      e.preventDefault();

      // Check if we are dragging within Quill
      // @ts-ignore
      if (e.dataTransfer.getData('blaze-drag-key') === BLAZE_DRAG_KEY) {
        // text blaze drag and drop, need up update embedded chips
        let html = e.dataTransfer.getData('text/html');
        let div = document.createElement('div');
        div.innerHTML = html;
        let chips = div.getElementsByClassName('embedded-chip-base');
        for (let i = 0; i < chips.length; i++) {
          chips[i].innerHTML = '';
        }
      
        html = div.innerHTML;
        // @ts-ignore
        let insertingDelta = quill.clipboard.convert({ html });

        // when dragging and dropping in quill, we need to
        // remove the original text
        let origSelection = quill.getSelection(true);
        let origIndex = origSelection.index;
        let origLength = origSelection.length;

        let range = getEventRange(e);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        quill.focus();
        let { index } = quill.getSelection();

        let delta;
        let newLoc;
        if (origIndex < index) {
          delta = (new Delta()).retain(origIndex).delete(origLength);
          delta = delta.retain(index - (origIndex + origLength));
          delta = delta.concat(insertingDelta);
          newLoc = index - origLength;
        } else {
          delta = (new Delta()).retain(index);
          delta = delta.concat(insertingDelta);
          delta = delta.retain(origIndex - index).delete(origLength);
          newLoc = index;
        }

        quill.updateContents(delta, 'user');
        quill.setSelection(newLoc, origLength);
        quill.focus();
      } else {
        // other site drag and drop (no need to remove original)
        let html = e.dataTransfer.getData('text/html');

        // @ts-ignore
        let insertingDelta = quill.clipboard.convert({ html });


        let range = getEventRange(e);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        quill.focus();
        let { index } = quill.getSelection();

        let delta;
        let newLoc;
        delta = (new Delta()).retain(index);
        delta = delta.concat(insertingDelta);
        newLoc = index;
        
        // get the length of the dropped text
        let length = 0;
        for (let op of insertingDelta.ops) {
          if (typeof op.insert === 'string') {
            length += op.insert.length;
          } else {
            length++; // embeds have length of one
          }
        }

        quill.updateContents(delta, 'user');
        quill.setSelection(newLoc, length);
        quill.focus();
      }

      
    }
  });
  


  function getEventRange(e) {
    if (document.caretRangeFromPoint) {
      // Chrome
      return document.caretRangeFromPoint(e.clientX, e.clientY);
    } else if (e.rangeParent) {
      // Firefox
      let range = document.createRange();
      range.setStart(e.rangeParent, e.rangeOffset);
      return range;
    }
  }
  
  const pickersUpdate = () => {
    // @ts-expect-error no types quill.theme
    quill.theme.pickers.forEach(picker => {
      picker.update();
    });
  };
  /**
   * @type {typeof props.keyboardBindings}
   */
  const keyboardBindings = [
    ...(props.keyboardBindings || []),
    {
      key: 'Enter',
      shiftKey: null,
      handler: function (range, context) {
        const offset = context.offset;
        const len = context.line.length();
        quill.keyboard.handleEnter.call(this, range, context);
        if (offset + range.length !== len - 1) {
          // break the implementation
          return false;
        }
        let updatePicker = false;
        for (const name in context.format) {
          if (!FORMATS_TO_PERSIST.includes(name)) {
            continue;
          }
          if (FORMATS_WITH_PICKER.includes(name)) {
            updatePicker = true;
          }
          quill.format(name, context.format[name]);
        }
        if (updatePicker) {
          pickersUpdate();
        }
        return false;
      }
    }
  ];
  const moduleBindings = quill.keyboard.bindings;
  for (const keyboardBinding of keyboardBindings) {
    const { handler, ...binding } = keyboardBinding;
    quill.keyboard.addBinding(binding, handler);

    // since only one matched bindings callback will excute.
    // expected my binding callback excute first
    // I changed the order of binding callbacks
    let thisBinding = moduleBindings[binding.key].pop();
    moduleBindings[binding.key].splice(0, 0, thisBinding);
  }
  

  return quill;
}

