import { useEffect, useState } from 'react';
import { verifyAuth } from '../cpapi';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

export default function useSession() {
  const [sessionLoading, setSessionLoading] = useState(false);
  useEffect(() => {
    let cancelled = false;
    let timer;
    const checkSessionAndLogin = async () => {
      timer = setTimeout(() => {
        setSessionLoading(false);
      }, 5000);
      try {

        setSessionLoading(true);
        const res = await verifyAuth();
        if (cancelled) {
          return;
        }

        if (!res) {
          return;
        }
        // Session exists. Signin
        await signInWithCustomToken(getAuth(), res.token);
      } catch {
        // Ignore
      } finally {
        clearTimeout(timer);
        setSessionLoading(false);
      }
    };
    checkSessionAndLogin();
    return () => {
      clearTimeout(timer);
      cancelled = true;
    };
  }, []);

  return sessionLoading;
}
