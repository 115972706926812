import { doSafeTableRequest } from '../../hooks/useTables';
import { PAGE_API_PREFIX } from '../../flags';
import { store } from '@store';

const CACHE_SECONDS = 5;

let promise;
let promiseCreatedAt;

/**
 * Fetch id/name map of the sites authorized for the current user and store them in the store.
 * @return {Promise<void>}
 */
export default async function fetchSitesSummary() {
  if (!promise || Date.now() - promiseCreatedAt >= CACHE_SECONDS * 1000) {
    promiseCreatedAt = Date.now();
    promise = fetchSitesSummaryRemote();
  }

  return promise;
}

async function fetchSitesSummaryRemote() {
  try {
    const sites = await doSafeTableRequest(`${PAGE_API_PREFIX}/sites/summary/`, 'GET');
    if (!sites || sites.error) {
      console.error(`Error fetching sites summary in Data Blaze, error: "${sites.error}", detail: "${sites.detail}".`);
      return;
    }

    store.dispatch({
      type: 'SET_SITE_NAMES',
      siteNamesMap: sites.reduce((map, site) => Object.assign(map, { [site.id]: site.name }), {}),
    });
  } catch {
    console.error('Error fetching sites summary in Data Blaze.');
  }
}