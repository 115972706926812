/**
 * @returns {{ getItem: typeof localStorage['getItem'], setItem: typeof localStorage['setItem'], removeItem: typeof localStorage['removeItem'], clear: typeof localStorage['clear'] }}
 */
function getSafeLocalStorage() {
  try {
    return window.localStorage;
  } catch (e) {
    /** @type {Object<string, string>} */
    let inMemoryStore = {};

    // Emulate an in memory store in this case
    return {
      getItem: (key) => inMemoryStore.hasOwnProperty(key) ? inMemoryStore[key] : null,
      setItem: (key, value) => {
        inMemoryStore[key] = value.toString();
      },
      removeItem: (key) => {
        delete inMemoryStore[key];
      },
      clear: () => {
        inMemoryStore = {};
      }
    };
  }
}

export const safeLocalStorage = getSafeLocalStorage();
