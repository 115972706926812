import { storage } from '../utilities';
import equals from 'fast-deep-equal';
import { useState, useEffect, useRef } from 'react';
import { useIsMounted } from '../hooks';
import { toast } from '../message';
import useCloseWarning from '../hooks/useCloseWarning';


/**
 * @param {import('firebase/firestore').DocumentReference} ref
 * @param {object=} seed
 */
export default function useFirestore(ref, seed = null) {
  // Note it's import that changes to these things be batch together
  // into a single state, otherwise one may update in render before the other
  // when sequential set*() calls are made in an async function.
  let [status, setStatus] = useState({
    loading: seed ? false : true,
    exists: seed ? true : false,
    error: null,
    data: seed ? seed : null
  });

  let statusRef = useRef(null);
  statusRef.current = status; // for use in function closures

  let isMounted = useIsMounted();

  let pending = useRef(null);
  let pendingSubmitTimer = useRef(null);
  let willWriteFn = useRef(null);
  const { warnOnExit, cancelWarningOnClose } = useCloseWarning();



  useEffect(() => {
    let unsubscribe = storage.onSnapshot(ref, (doc) => {
      const hasPending = pending.current && !!Object.keys(pending.current).length;

      if (!doc.exists()) {
        setStatus({
          loading: false,
          exists: false,
          error: null,
          data: null
        });
      } else {
        let dataToSet;
        if (!hasPending) {
          // if hasPending, we don't want to pull in a stale version of the object
          // and we want our changes to be applied first

          let newData = doc.data();
          // If the data is the same as the current state, don't push an update
          if (!equals(newData, statusRef.current.data)) {
            dataToSet = newData;
          }
        }
        
        // Don't do unnecessary updates
        if (dataToSet) {
          setStatus({
            loading: false,
            exists: true,
            error: null,
            data: dataToSet
          });
        } else if (!statusRef.current.exists) {
          // This can happen when we set pending data before we've loaded remotely.
          // We still want to update the exists and loading flags in this case.
          setStatus({
            loading: false,
            exists: true,
            error: null,
            data: statusRef.current.data
          });
        }
      }
      if (!hasPending) {
        cancelWarningOnClose();
        clearTimeout(pendingSubmitTimer.current);
      }
    }, (err) => {
      setStatus({
        loading: false,
        error: err,
        data: null,
        exists: statusRef.current.exists
      });
      if (!(err.code && err.code === 'IGNORE_TESTING')) {
        console.log('FirestoreLink Error: ', err);
      }
      clearTimeout(pendingSubmitTimer.current);

      if (willWriteFn.current) {
        willWriteFn.current();
        willWriteFn.current = null;
      }
    });

    return () => {
      clearTimeout(pendingSubmitTimer.current);
      if (pending.current && Object.keys(pending.current).length) {
        handleFailure(storage.update(ref, pending.current));

        if (willWriteFn.current) {
          willWriteFn.current();
          willWriteFn.current = null;
        }
      }
      unsubscribe();
      cancelWarningOnClose();
    };
    // eslint-disable-next-line
  }, [ref.id]);



  function updateStateObject(oldData, newData) {
    for (let key in newData) {
      if (!key.includes('.')) {
        oldData[key] = newData[key];
      } else {
        // Firestore keys can be a path like 'obj.obj2.obj3'
        let path = key.split('.');
        let obj = oldData;
        while (path.length > 1) {
          let part = path.shift();
          if (!obj[part]) {
            obj[part] = {};
          }
          obj = obj[part];
        }
        obj[path[0]] = newData[key];
      }
    }
    return oldData;
  }

  function handleFailure(updatePromise) {
    updatePromise.catch((err) => {
      console.warn(err);
      toast('An issue occurred while saving. If this issue persists, please report it to <support@blaze.today>.', {
        duration: 6000,
        intent: 'danger'
      });
    });
  }


  // TODO: should updateFn use useCallback?
  /**
   * @param {object} newData 
   * @param {number=} debounce 
   */
  function updateFn(newData, debounce = 400) {
    if (!isMounted.current) {
      // Don't accept any new writes after we unmount
      console.error('Attempted to update <' + ref.path + '> after unmounting with keys: ' + Object.keys(newData));
      return;
    }

    pending.current = Object.assign(pending.current || {}, newData);
    setStatus({
      loading: statusRef.current.loading,
      exists: statusRef.current.exists,
      error: statusRef.current.error,
      data: updateStateObject(Object.assign({}, statusRef.current.data), newData)
    });

    warnOnExit('There are pending changes to be saved. Are you sure you want to leave now?');
    
    if (!willWriteFn.current) {
      willWriteFn.current = storage.notifyWillWrite();
    }

    clearTimeout(pendingSubmitTimer.current);
    pendingSubmitTimer.current = setTimeout(() => {
      const updateData = pending.current;
      pending.current = {};
      handleFailure(storage.update(ref, updateData));

      if (willWriteFn.current) {
        willWriteFn.current();
        willWriteFn.current = null;
      }
    }, debounce);
  }

  return {
    loading: status.loading,
    exists: status.exists,
    data: status.data,
    error: status.error,
    updateFn
  };
}
