// Generated automatically by nearley, version 2.19.3
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d) { return d[0]; }
;
;
;
;
const grammar = {
    Lexer: undefined,
    ParserRules: [
        { "name": "MAIN", "symbols": ["TYPE"], "postprocess": id },
        { "name": "TYPE", "symbols": [{ "literal": "?" }, "NULLED_TYPE"], "postprocess": d => Object.assign(d[1], { nullable: true }) },
        { "name": "TYPE", "symbols": [{ "literal": "!" }, "NULLED_TYPE"], "postprocess": d => Object.assign(d[1], { nullable: false }) },
        { "name": "TYPE", "symbols": ["NULLED_TYPE"], "postprocess": d => Object.assign(d[0], { nullable: false }) },
        { "name": "NULLED_TYPE", "symbols": ["PRIMITIVE"], "postprocess": d => ({ kind: 'primitive', type: d[0] }) },
        { "name": "NULLED_TYPE", "symbols": ["ARRAY"], "postprocess": d => ({ kind: 'array', type: d[0] }) },
        { "name": "NULLED_TYPE", "symbols": ["OBJECT"], "postprocess": d => {
                let t = {};
                for (let entry of d[0]) {
                    t[entry.identifier] = entry.type;
                }
                return { kind: 'object', type: t };
            } },
        { "name": "NULLED_TYPE", "symbols": ["MAP"], "postprocess": d => ({ kind: 'map', type: d[0] }) },
        { "name": "PRIMITIVE$string$1", "symbols": [{ "literal": "s" }, { "literal": "t" }, { "literal": "r" }, { "literal": "i" }, { "literal": "n" }, { "literal": "g" }], "postprocess": (d) => d.join('') },
        { "name": "PRIMITIVE", "symbols": ["PRIMITIVE$string$1"], "postprocess": id },
        { "name": "PRIMITIVE$string$2", "symbols": [{ "literal": "n" }, { "literal": "u" }, { "literal": "m" }, { "literal": "b" }, { "literal": "e" }, { "literal": "r" }], "postprocess": (d) => d.join('') },
        { "name": "PRIMITIVE", "symbols": ["PRIMITIVE$string$2"], "postprocess": id },
        { "name": "PRIMITIVE$string$3", "symbols": [{ "literal": "b" }, { "literal": "o" }, { "literal": "o" }, { "literal": "l" }, { "literal": "e" }, { "literal": "a" }, { "literal": "n" }], "postprocess": (d) => d.join('') },
        { "name": "PRIMITIVE", "symbols": ["PRIMITIVE$string$3"], "postprocess": id },
        { "name": "ARRAY$string$1", "symbols": [{ "literal": "[" }, { "literal": "]" }], "postprocess": (d) => d.join('') },
        { "name": "ARRAY", "symbols": ["NULLED_TYPE", "ARRAY$string$1"], "postprocess": id },
        { "name": "OBJECT", "symbols": [{ "literal": "{" }, "_", "PROPERTY_LIST", "_", { "literal": "}" }], "postprocess": d => d[2] },
        { "name": "MAP$string$1", "symbols": [{ "literal": "O" }, { "literal": "b" }, { "literal": "j" }, { "literal": "e" }, { "literal": "c" }, { "literal": "t" }], "postprocess": (d) => d.join('') },
        { "name": "MAP$ebnf$1", "symbols": [{ "literal": "." }], "postprocess": id },
        { "name": "MAP$ebnf$1", "symbols": [], "postprocess": () => null },
        { "name": "MAP$string$2", "symbols": [{ "literal": "s" }, { "literal": "t" }, { "literal": "r" }, { "literal": "i" }, { "literal": "n" }, { "literal": "g" }], "postprocess": (d) => d.join('') },
        { "name": "MAP", "symbols": ["MAP$string$1", "MAP$ebnf$1", { "literal": "<" }, "_", "MAP$string$2", "_", { "literal": "," }, "_", "TYPE", "_", { "literal": ">" }], "postprocess": d => d[8] },
        { "name": "PROPERTY_LIST", "symbols": ["PROPERTY"], "postprocess": d => [d[0]] },
        { "name": "PROPERTY_LIST", "symbols": ["PROPERTY_LIST", "_", { "literal": "," }, "_", "PROPERTY"], "postprocess": d => d[0].concat(d[4]) },
        { "name": "PROPERTY", "symbols": ["PROPERTY_NAME", "_", { "literal": ":" }, "_", "TYPE"], "postprocess": d => ({ identifier: d[0], type: d[4] }) },
        { "name": "PROPERTY_NAME$ebnf$1", "symbols": [/[^:\s]/] },
        { "name": "PROPERTY_NAME$ebnf$1", "symbols": ["PROPERTY_NAME$ebnf$1", /[^:\s]/], "postprocess": (d) => d[0].concat([d[1]]) },
        { "name": "PROPERTY_NAME", "symbols": ["PROPERTY_NAME$ebnf$1"], "postprocess": d => d[0].join('') },
        { "name": "_$ebnf$1", "symbols": [] },
        { "name": "_$ebnf$1", "symbols": ["_$ebnf$1", /[\s]/], "postprocess": (d) => d[0].concat([d[1]]) },
        { "name": "_", "symbols": ["_$ebnf$1"], "postprocess": d => null }
    ],
    ParserStart: "MAIN",
};
export default grammar;
