import {
  Alert,
  Box,
  ClickAwayListener,
  Paper,
  Popper
} from '@mui/material';
import React, { useState } from 'react';
import { BetaChip, ProChip } from '../../Version/VersionChip';

const CELL_SIZE = 18;
const CELL_SPACING = 5;
const CELL_ABSOLUTE_SIZE = CELL_SIZE + CELL_SPACING;

const MAX_SIZE = 20;
const MIN_SIZE = 10;
/**
 * 
 * @param {object} props
 * @param {HTMLElement} props.anchorEl
 * @param {(rows: number, columns: number) => any} props.onSelect
 * @param {() => any} props.onClose
 */
const TableGridSelector = ({
  anchorEl,
  onSelect,
  onClose
}) => {
  const [selected, setSelected] = useState({
    row: 0,
    col: 0
  });
  const [rowsToShow, setRowsToShow] = useState(MIN_SIZE),
    [colsToShow, setColsToShow] = useState(MIN_SIZE);
  return (
    <Popper
      open
      anchorEl={anchorEl}
      placement="bottom-start"
      sx={{
        zIndex: (theme) => theme.zIndex.modal
      }}
    >
      <ClickAwayListener onClickAway={() => {
        onClose();
      }}>
        <Paper
          sx={{
            p: 2
          }}
          elevation={2}
        >
          <div
            onMouseUp={() => {
              onSelect(selected.row + 1, selected.col + 1);
              onClose();
            }}
            onContextMenu={(evt) => evt.preventDefault()}
          >
            {Array.from({ length: rowsToShow }).map((x, rowIndex) => (
              <Box display="flex" key={rowIndex}>
                {Array.from({ length: colsToShow }).map((x, colIndex) => (
                  <Box
                    key={rowIndex + '-' + colIndex}
                    onMouseOver={() => {
                      const colNum = colIndex + 1,
                        rowNum = rowIndex + 1;
                      if (
                        colNum + 2 > MIN_SIZE
                        && colNum + 2 <= MAX_SIZE
                        && (colNum + 4) * CELL_ABSOLUTE_SIZE <= anchorEl.clientWidth
                      ) {
                        setColsToShow(colNum + 2);
                      } else if (colNum + 2 <= MIN_SIZE) {
                        setColsToShow(MIN_SIZE);
                      }


                      if (
                        rowNum + 2 > MIN_SIZE
                        && rowNum + 2 <= MAX_SIZE
                        && (rowNum + 4) * CELL_ABSOLUTE_SIZE <= anchorEl.parentElement.clientHeight - anchorEl.clientHeight - 60
                      ) {
                        setRowsToShow(rowNum + 2);
                      } else if (rowNum + 2 <= MIN_SIZE) {
                        setRowsToShow(MIN_SIZE);
                      }

                      setSelected({
                        row: rowIndex,
                        col: colIndex
                      });
                    }}
                    onMouseOut={() => setSelected({
                      row: 0,
                      col: 0
                    })}
                    sx={{
                      pr: CELL_SPACING + 'px',
                      pb: CELL_SPACING + 'px'
                    }}
                  >
                    <Box
                      title={`Insert table ${rowIndex + 1} x ${colIndex + 1}`}
                      sx={[{
                        width: CELL_SIZE,
                        height: CELL_SIZE,
                        backgroundColor: '#fcfcfc',
                        border: '1px solid #ddd',
                        '&:hover': {
                          borderColor: 'primary.main'
                        }
                      }, (rowIndex <= selected.row && colIndex <= selected.col && {
                        borderColor: 'primary.main'
                      })]}
                    />
                  </Box>
                ))}
              </Box>

            ))}
          </div>
          <Box
            sx={{
              textAlign: 'center',
              color: 'info.light',
              display: 'flex',
              justifyContent: 'center',
              gap: '6px'
            }}
          >
            <span>{selected.col + 1}</span>
            <span>x</span>
            <span>{selected.row + 1}</span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              maxWidth: 240,
              my: 1.5,
              mx: 'auto'
            }}
          >
            <BetaChip />
            <ProChip
              zoom={0.8}
            />
          </Box>
          <Alert
            severity="info"
            sx={{
              mt: 0.4,
              mx: 'auto',
              maxWidth: 240
            }}
            icon={false}
          >
            Certain table features may not be compatible with all editors.
          </Alert>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default TableGridSelector;