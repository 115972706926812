import React, { useState } from 'react';
import { childrenOrder, moveSnippet } from '../../data';
import Popover from '@mui/material/Popover';
import T from '@mui/material/Typography'; 
import Select from '@mui/material/Select';
import { userPermission } from '../../auth';
import { limitationsState } from '../Version/limitations';
import AsyncButton from '../AsyncButton/AsyncButton';
import { useTypedSelectorShallowEquals } from '../../hooks';


export default function MoveSnippet(props) {
  let [groupId, setGroupId] = useState(null);

  let { userGroups, groups, maxSnippetsPerGroup, hasMaxSnippetsPerGroupUpgrade } = useTypedSelectorShallowEquals((state) => {
    let limits = limitationsState(state);
    return {
      userGroups: state.userState.groups,
      groups: state.dataState.groups,
      maxSnippetsPerGroup: limits.MAX_SNIPPETS_PER_GROUP,
      hasMaxSnippetsPerGroupUpgrade: limits.CAN_UPGRADE_MAX_SNIPPETS_PER_GROUP,
    };
  });


  let enabledGroups = [];
  let disabledGroups = [];

  let processGroup = (groupId) => {
    let group = groups[groupId];
    let error;
    
    if (!['owner', 'editor'].includes(userPermission(group))) {
      error = 'You do not have edit access to the folder';
    } else if (maxSnippetsPerGroup && group.snippets.length >= maxSnippetsPerGroup) {
      error = `You cannot have more than ${maxSnippetsPerGroup} snippets in a folder (${hasMaxSnippetsPerGroupUpgrade ? 'Upgrade for more or' : 'To add more snippets'} create a new folder)`;
    }
    let base = {
      id: groupId,
      name: group.name
    };
    if (error) {
      base.disabled = true;
      base.error = error; 
    }
    return base;
  };

  let idMapping = {};

  let orderedGroups = childrenOrder(userGroups);
  for (let id of orderedGroups) {
    if (!groups[id] || groups[id].stub) {
      continue;
    }

    idMapping[id] = processGroup(id);
    if (userGroups[id].disabled) {
      disabledGroups.push(idMapping[id]);
    } else {
      enabledGroups.push(idMapping[id]);
    }
  }

  // We also iterate through all the groups in case there are any teams that
  // aren't part of our userGroups
  // TODO: should we do the same thing for the new snippet dialog?
  for (let id in groups) {
    if (!groups[id] || groups[id].stub) {
      continue;
    }

    // we processed everything in ordered groups above
    if (!orderedGroups.includes(id)) {
      idMapping[id] = processGroup(id);
      // we put these at the end of all the other groups
      disabledGroups.push(idMapping[id]);
    }
  }

  let availableGroups = enabledGroups.concat(disabledGroups).filter(g => g.id !== props.currentGroupId);

  let validGroups = availableGroups.filter(g => !g.error);

  if (validGroups.length) {
    groupId = groupId || validGroups[0].id;
  }

  if (availableGroups.length) {
    groupId = groupId || availableGroups[0].id;
  }

  return (
    <Popover
      open
      onClose={() => {
        props.done();
      }}
      anchorEl={props.target.current}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <div style={{ padding: 15, width: 220 }}>
        {groupId ? <>
          <T variant="caption" style={{ textAlign: 'center' }}>Move to:</T>
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            <Select
              size="small"
              native
              fullWidth
              value={groupId}
              onChange={(e) => setGroupId(e.target.value)}
              variant="standard"
            >
              {availableGroups.map(group => <option key={group.id} value={group.id}>
                {group.name}
              </option>)}
            </Select>
          </div>
          {idMapping[groupId] && idMapping[groupId].error && <T variant="caption" color="error">{idMapping[groupId].error}</T>}
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            <AsyncButton
              variant="outlined"
              color="primary"
              size="small"
              disabled={idMapping[groupId] && !!idMapping[groupId].error}
              onClick={(done) => {
                // Put it at the end of the new group. Note 10,000 is the index, not the order.
                moveSnippet(props.snippetId, groupId, 10000);
                props.done(groupId);
                done();
              }}
            >Move snippet</AsyncButton>
          </div>
        </> : 
          <T variant="caption" style={{ textAlign: 'center' }}>No available folders.</T>
        }
      </div>
    </Popover>
  );
}

