import React, { useEffect, useState } from 'react';
import { useTypedSelector } from '../../hooks';
import { storage } from '../../utilities';
import { MY_WEB_VERSION } from '../../flags';
import Dialog from '@mui/material/Dialog';
import { EmptyState } from '../EmptyState/EmptyState';


/**
 * @param {object} props
 * @param {string} props.property
 * @param {string=} props.message
 */
function ReloadDialogBase(props) {
  let minVersion = useTypedSelector((store) => store.config && store.config[props.property]);
  let [expired, setExpired] = useState(false);

  useEffect(() => {
    if (minVersion && MY_WEB_VERSION < minVersion) {
      if (!storage.dead) {
        storage.kill(true);
      }
      if (!expired) {
        setExpired(true);
      }
    }

    // eslint-disable-next-line
  }, [minVersion]);


  if (expired) {
    return <Dialog
      open
      PaperProps={{
        style: {
          zIndex: 30,
          padding: '40px 70px 60px'
        }
      }}
    >
      <EmptyState
        icon="UPDATE"
        title="Updated Text Blaze available!"
        description={props.message || 'Please reload Text Blaze to enable it'}
      />
    </Dialog>;
  }

  return null;
}


const ReloadDialog = React.memo(ReloadDialogBase);
export default ReloadDialog;
