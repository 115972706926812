import Quill from 'quill/core';
import TableColumnTool from './modules/table-column-tool';
import TableSelection from './modules/table-selection';
import TableOperationMenu from './modules/table-operation-menu';
import TableOperationActions from './modules/table-operation-actions';

// import table node matchers
import {
  matchTableCell,
  matchTableHeader,
  matchTable
} from './utils/node-matchers';

import { getEventComposedPath } from './utils';

import {
  TableCol,
  TableColGroup,
  TableCellLine,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableViewWrapper,
  rowId,
  cellId
} from './formats/formats';
import { overrideModules } from './overrides';
import { generateCellMap } from './formats/util';

const USER = 'user';
const WHITELISTED_FORMATS = [
  'bold',
  'italic',
  'underline',
  'strike',
  'color',
  'background',
  'align',
  'direction',
  'font',
  'size',
  'clean'
];
/**
 * OVERRIDES
 * @type {{[key: string]: (quill: Quill, index: number, length: number, value: any) => any}}
 */
const FORMAT_OVERRIDES = {
  'clean': (quill, index, length) => {
    const formatsToset = {
      'background': null,
      'bold': null,
      'color': null,
      'font': null,
      'code': null,
      'italic': null,
      'link': null,
      'size': null,
      'strike': null,
      'script': null,
      'underline': null,
      'blockquote': null,
      'indent': null,
      'list': null,
      'align': null,
      'direction': null
    };
    quill.formatText(index, length, formatsToset);
  },
  'direction': (quill, index, length, value) => {
    const {
      align
    } = quill.getFormat(index);
    if (value === 'rtl' && align == null) {
      quill.formatText(index, length, 'align', 'right', USER);
    } else if (!value && align === 'right') {
      quill.formatText(index, length, 'align', false, USER);
    }

    quill.formatText(index, length, 'direction', value, USER);
  }
};

const Module = Quill.import('core/module');
const Delta = Quill.import('delta');
class BetterTable extends Module {
  static register(registry) {
    registry.register(TableCol);
    registry.register(TableColGroup);
    registry.register(TableCellLine);
    registry.register(TableCell);
    registry.register(TableRow);
    registry.register(TableBody);
    registry.register(TableContainer);
    registry.register(TableViewWrapper);
    // register customized Header，overwriting quill built-in Header
    // registry.register(Header);
  }
  
  static overrideModules() {
    overrideModules();
  }

  constructor(quill, options) {
    super(quill, options);

    /**
     * @param {MouseEvent} evt
     */
    function onScrollEdgeClick(evt) {
      if (evt.target !== evt.currentTarget) {
        return;
      }
      const scroll = quill.scroll;
      let newBlock;
      if (evt.offsetY <= 16) {
        const topBlot = scroll.children.head;
        if (topBlot.statics.blotName !== TableViewWrapper.blotName) {
          return;
        }
        newBlock = scroll.create('block');
        scroll.insertBefore(newBlock, topBlot);
      } else {
        const bottomBlot = scroll.children.tail;
        if (bottomBlot.statics.blotName !== TableViewWrapper.blotName) {
          return;
        }
        newBlock = scroll.create('block');
        scroll.appendChild(newBlock);
      }
      if (newBlock) {
        setTimeout(() => {
          quill.setSelection(quill.getIndex(newBlock));
        }, 100);
      }
    }

    /*
     * @param {MouseEvent} evt 
     */
    function doSelect (evt) {
      // bugfix: evt.path is undefined in Safari, FF, Micro Edge
      const path = getEventComposedPath(evt);
      if (!path || path.length <= 0) {
        return;
      }

      let tableNode = path.filter(node => {
        return node.tagName &&
          node.tagName.toUpperCase() === 'TABLE' &&
          node.classList.contains('quill-better-table');
      })[0];
      if (!tableNode) {
        // if clicked on table view wrapper (scroll)
        const tableView = path.filter(node => {
          return node.tagName &&
            node.classList.contains('quill-better-table-wrapper');
        })[0];
        if (tableView) {
          tableNode = tableView.querySelector('table');
        }
      }

      if (tableNode) {
        // current table clicked
        if (this.table === tableNode) {
          return;
        }
        // other table clicked
        if (this.table) {
          this.hideTableTools();
        }
        this.showTableTools(tableNode, quill, options);
      } else if (this.table) {
        // other clicked
        this.hideTableTools();
      }
    }
    /**
     * @type {HTMLElement}
     */
    const quillRoot = this.quill.root;

    // handle dblclick on quill-better-table
    quillRoot.addEventListener('dblclick', onScrollEdgeClick.bind(this), true);
    // handle click on quill-better-table
    quillRoot.addEventListener('mousedown', doSelect.bind(this), true);

    // handle right click on quill-better-table
    quillRoot.addEventListener('contextmenu', (evt) => {
      doSelect.call(this, evt);
      if (!this.table) {
        return true;
      }
      evt.preventDefault();

      // bugfix: evt.path is undefined in Safari, FF, Micro Edge
      const path = getEventComposedPath(evt);
      if (!path || path.length <= 0) {
        return;
      }

      const tableNode = path.filter(node => {
        return node.tagName &&
          node.tagName.toUpperCase() === 'TABLE' &&
          node.classList.contains('quill-better-table');
      })[0];

      const rowNode = path.filter(node => {
        return node.tagName &&
          node.tagName.toUpperCase() === 'TR' &&
          node.getAttribute('data-row');
      })[0];

      const cellNode = path.filter(node => {
        return node.tagName &&
          node.tagName.toUpperCase() === 'TD' &&
          node.getAttribute('data-row');
      })[0];

      let isTargetCellSelected = this.tableSelection.selectedTds
        .map(tableCell => tableCell.domNode)
        .includes(cellNode);

      if (this.tableSelection.selectedTds.length <= 0 ||
        !isTargetCellSelected) {
        this.tableSelection.setSelection(
          cellNode.getBoundingClientRect(),
          cellNode.getBoundingClientRect()
        );
      }

      if (this.tableOperationMenu) {
        this.tableOperationMenu = this.tableOperationMenu.destroy();
      }

      if (tableNode && !options.onMenu) {
        this.tableOperationMenu = new TableOperationMenu({
          table: tableNode,
          row: rowNode,
          cell: cellNode,
          evt,
        }, quill, options.operationMenu);
      } else if (tableNode && options.onMenu) {
        options.onMenu({
          table: tableNode,
          row: rowNode,
          cell: cellNode,
          evt,
          quill
        });
        
      }

      clearTimeout(this.balanceTimer);
      this.balanceTimer = setTimeout(() => {
        this.balanceTables();
      }, 500);
    }, false);
    if (keyboardBindings) {
      const moduleBindings = quill.keyboard.bindings;
      for (const bindingName in keyboardBindings) {
        let {
          key: keyBinding,
          handler,
          ...options
        } = keyboardBindings[bindingName];
        if (typeof keyBinding === 'string') {
          keyBinding = {
            key: keyBinding
          };
        }
        const key = keyBinding.key;
        quill.keyboard.addBinding(
          keyBinding,
          options,
          handler
        );
  
        // since only one matched bindings callback will excute.
        // expected my binding callback excute first
        // I changed the order of binding callbacks
        let thisBinding = moduleBindings[key].pop();
        moduleBindings[key].splice(0, 0, thisBinding);
      }
    }
    quill.on('selection-change', (range, oldRange, source) => {
      const self = this;
      if (!range || source === 'silent') {
        return;
      }
      const [start] = quill.getLine(range.index);

      const [end] = quill.getLine(range.index + range.length);
      if (
        (start !== end && start.parent !== end.parent)
        && (start.statics.blotName === TableCellLine.blotName
        || end.statics.blotName === TableCellLine.blotName)
      ) {
        quill.setSelection(range.index, 0, 'USER');

      } else if (range.length === 0
        && start.statics.blotName === TableCellLine.blotName
        && !self.tableSelection?.selectedTds?.includes(start.parent)
      ) {
        const cell = start.parent;
        if (!self.tableSelection || self.tableSelection?.destroyed) {
          self.showTableTools(cell.parent.parent.parent.domNode, self.quill, options);
        }
        self.tableSelection.selectCell(cell);
      } else if (start.statics.blotName !== TableCellLine.blotName && oldRange) {
        self.hideTableTools();
      }
    }, false);

    // add Matchers to match and render quill-better-table for initialization
    // or pasting
    BetterTable.clipboardMatchers.forEach((matcher) => {
      quill.clipboard.addMatcher(matcher.tag, matcher.fn);
    });

    // remove matcher for tr tag
    quill.clipboard.matchers = quill.clipboard.matchers.filter(matcher => {
      return matcher[0] !== 'tr';
    });
    this.listenBalanceCells();
    if (options.onMessage && typeof options.onMessage === 'function') {
      this.onMessage = options.onMessage;
    }
  }

  getTable(range = this.quill.getSelection()) {
    if (range == null) {
      return [null, null, null, -1];
    }
    const [cellLine, offset] = /** @type {[TableCellLine, number]} */ (this.quill.getLine(range.index));
    if (cellLine == null || cellLine.statics.blotName !== TableCellLine.blotName) {
      return [null, null, null, -1];
    }
    const cell = cellLine.tableCell();
    const row = cell.row();
    const table = row.table();
    return [table, row, cell, offset];
  }

  /**
   * 
   * @param {string} message 
   * @param {('info' | 'warning' | 'danger')} type 
   */
  onMessage (message, type) {
    alert(type + ' - ' + message);
  }

  insertTable(rows, columns) {
    const range = this.quill.getSelection(true);
    if (range == null) {
      return;
    }
    let currentBlot = this.quill.getLeaf(range.index)[0];
    let delta = new Delta().retain(range.index);

    if (isInTableCell(currentBlot)) {
      throw Error('Table inside table is not supported.');
    }

    delta.insert('\n');
    // insert table column
    delta = new Array(columns).fill('\n').reduce((memo, text) => {
      memo.insert(text, { 'tableCol': true });
      return memo;
    }, delta);
    // insert table cell line with empty line
    delta = new Array(rows).fill(0).reduce(memo => {
      let tableRowId = rowId();
      return new Array(columns).fill('\n').reduce((memo, text) => {
        memo.insert(text, { 'tableCellLine': { row: tableRowId, cell: cellId() } });
        return memo;
      }, memo);
    }, delta);
    this.quill.updateContents(delta, Quill.sources.USER);
    this.quill.setSelection(range.index + columns + 1, Quill.sources.API);
  }

  /**
   * @typedef {string|{ insert: any, attributes: import('quill/core').AttributeMap, cellAttributes?: import('quill/core').AttributeMap}} cellData
   * 
   * @param {(cellData | cellData[])[][]} data
   * @param {{ width: number }[]} columnConfig - Expectation is to have same number of columns as cellData
   */
  insertTableWithData(data, columnConfig = null) {
    const quill = this.quill;
    const range = quill.getSelection(true);
    if (range == null) {
      return;
    }
    let currentBlot = quill.getLeaf(range.index)[0];
    let delta = new Delta().retain(range.index);

    if (isInTableCell(currentBlot)) {
      throw Error('Table inside table is not supported.');
    }
    quill.deleteText(range.index, range.length, 'user');

    delta.insert('\n');
    // insert table column
    delta = (columnConfig || new Array(data[0].length).fill({ width: 200 })).reduce((memo, col) => {
      memo.insert('\n', {
        'tableCol': col
      });
      return memo;
    }, delta);
    // insert table cell line with empty line
    delta = data.reduce((memo, cells) => {
      let tableRowId = rowId();
      return cells.reduce((memo, cellData) => {
        if (!Array.isArray(cellData)) {
          cellData = [cellData];
        }
        let cellAttributes = {};
        cellData.forEach(cell => {
          if (typeof cell === 'string') {
            memo.insert(cell);
          } else {
            if (!cellAttributes && cell.cellAttributes) {
              cellAttributes = cell.cellAttributes;
            }
            memo.insert(cell.insert, cell.attributes || {});
          }
        });
        memo.insert('\n', { 'tableCellLine': { row: tableRowId, cell: cellId(), ...cellAttributes } });
        return memo;
      }, memo);
    }, delta);

    this.quill.updateContents(delta, Quill.sources.USER);
    this.quill.setSelection(range.index + 1, Quill.sources.API);
  }

  showTableTools (table, quill, options) {
    this.table = table;
    this.columnTool = new TableColumnTool(table, quill, options);
    this.tableSelection = new TableSelection(table, quill, options);
  }

  hideTableTools () {
    this.columnTool && this.columnTool.destroy();
    this.tableSelection && this.tableSelection.destroy();
    this.tableOperationMenu && this.tableOperationMenu.destroy();
    this.columnTool = null;
    this.tableSelection = null;
    this.tableOperationMenu = null;
    this.table = null;
  }

  format (format, value) {
    const selectedTds = this.tableSelection?.selectedTds,
      quill = this.quill;
    if (!selectedTds?.length) {
      if (format === 'clean') {
        return;
      }
      quill.format(format, value, USER);
      return;
    }
    const doFormat = (index, length) => {
      if (format in FORMAT_OVERRIDES) {
        FORMAT_OVERRIDES[format](quill, index, length, value);
      } else {
        quill.formatText(index, length, format, value, USER);
      }
    };
    
    let quillIndex = selectedTds[0].range(this.quill).index,
      length = 0,
      prev = selectedTds[0];
    selectedTds.forEach((td, index) => {
      let range;
      if (length && prev.parent !== td.parent && (prev.next || td.prev)) {
        doFormat(quillIndex, length);

        range = td.range(this.quill);
        quillIndex = range.index;
        length = 0;
      }
      if (!range) {
        range = td.range(this.quill);
      }
      length += range.length;
      prev = td;
    });

    doFormat(quillIndex, length);
    this.tableSelection.setSelection(
      selectedTds[0].domNode.getBoundingClientRect(),
      selectedTds[selectedTds.length - 1].domNode.getBoundingClientRect()
    );
  }



  listenBalanceCells() {
    this.quill.on(
      // @ts-ignore events is not in Quilll types
      Quill.events.SCROLL_OPTIMIZE, 
      mutations => {
        mutations.some(mutation => {
          if (['TD', 'TR', 'TBODY', 'TABLE', 'COLGROUP'].includes(mutation.target.tagName)) {
            this.quill.once(
              // @ts-ignore events is not in Quilll types
              Quill.events.TEXT_CHANGE, 
              (delta, old, source) => {
                if (source !== Quill.sources.USER) {
                  return;
                }
                this.balanceTables();
              }
            );
            return true;
          }
          return false;
        });
        if (!this.refreshingHelpLines) {
          this.refreshingHelpLines = true;
          // Optimize can be triggered multiple times when user is trying to type.
          // So to reduce multiple calculation of helplines
          // lets call it once, and make it sure the first refresh is triggered than debounce it call it at the last.
          setTimeout(
            () => {
              this.tableSelection?.refreshHelpLinesPosition();
              this.refreshingHelpLines = false;
            },
            200
          );
        }
      }
    );
  }


  balanceTables() {
    this.quill.scroll.descendants(TableContainer).forEach(table => {
      table.balanceCells();
    });
  }
}

const keyboardBindings = {
  'tableCellLine backspace': {
    key: {
      key: 'Backspace',
      metaKey: null,
      shortKey: null,
      ctrlKey: null,
      shiftKey: null,
      altKey: null,
    },
    format: [TableCellLine.blotName],
    offset: 0,
    collapsed: true,
    handler(range, context) {
      const [prev] = this.quill.getLine(range.index - 1);
      if (prev && prev.parent !== prev.next?.parent) {
        return false;
      }
      return true;
    },
  },

  'tableCellLine delete': {
    key: {
      key: 'Delete',
      metaKey: null,
      shortKey: null,
      ctrlKey: null,
      shiftKey: null,
      altKey: null,
    },
    format: [TableCellLine.blotName],
    collapsed: true,
    suffix: /^$/,
    handler(range) {
      const [line] = this.quill.getLine(range.index);
      if (line && line.parent !== line.next?.parent) {
        return false;
      }
      return true;
    },
  },

  'tableCellLine enter': {
    key: 'Enter',
    shiftKey: null,
    format: [TableCellLine.blotName],
    handler(range, context) {
      // bugfix: a unexpected new line inserted when user compositionend with hitting Enter
      if (this.quill.selection && this.quill.selection.composing) {
        return;
      }
      // @ts-ignore imports is not part of Quill types
      const Scope = Quill.imports.parchment.Scope;
      if (range.length > 0) {
        this.quill.scroll.deleteAt(range.index, range.length); // So we do not trigger text-change
      }
      const lineFormats = Object.keys(context.format).reduce((formats, format) => {
        if (
          this.quill.scroll.query(format, Scope.BLOCK) &&
          !Array.isArray(context.format[format])
        ) {
          formats[format] = context.format[format];
        }
        return formats;
      }, {});
      // insert new cellLine with lineFormats
      this.quill.insertText(range.index, '\n', lineFormats[TableCellLine.blotName], Quill.sources.USER);
      // Earlier scroll.deleteAt might have messed up our selection,
      // so insertText's built in selection preservation is not reliable
      this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
      this.quill.focus();
      Object.keys(context.format).forEach(name => {
        if (lineFormats[name] != null) {
          return;
        }
        if (Array.isArray(context.format[name])) {
          return;
        }
        if (name === 'link') {
          return;
        }
        this.quill.format(name, context.format[name], Quill.sources.USER);
      });
    },
  },

  'tableCellLine tab': makeTableTabHandler(false),
  'tableCellLine shift tab': makeTableTabHandler(true),

  'tableCellLine up': makeTableArrowHandler(true),
  'tableCellLine down': makeTableArrowHandler(false),
  'down-to-table': {
    key: 'ArrowDown',
    collapsed: true,
    handler(range, context) {
      const target = context.line.next;
      if (target && target.statics.blotName === 'table-view') {
        const targetCell = target.table().rows()[0].children.head;
        const targetLine = targetCell.children.head;
        
        this.quill.setSelection(
          targetLine.offset(this.quill.scroll),
          0,
          Quill.sources.USER
        );

        return false;
      }
      return true;
    }
  },
  'up-to-table': {
    key: 'ArrowUp',
    collapsed: true,
    handler(range, context) {
      const target = context.line.prev;
      if (target && target.statics.blotName === 'table-view') {
        const rows = target.table().rows();
        const targetCell = rows[rows.length - 1].children.head;
        const targetLine = targetCell.children.head;
        
        this.quill.setSelection(
          targetLine.offset(this.quill.scroll),
          0,
          Quill.sources.USER
        );

        return false;
      }
      return true;
    }
  },
  // Copied from quill/src/modules/keyboard.ts
  'list not autofill': {
    key: ' ',
    shiftKey: null,
    collapsed: true,
    format: {
      tableCellLine: true,
    },
    prefix: /^\s*?(\d+\.|-|\*|\[ ?\]|\[x\])$/,
    handler(range, context) {
      // Add space and leave it
      this.quill.insertText(range.index, ' ', Quill.sources.USER);
      this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
      return false;
    }
  },
};
BetterTable.OperationActions = TableOperationActions;
BetterTable.clipboardMatchers = [
  {
    tag: 'td',
    fn: matchTableCell
  },
  {
    tag: 'th',
    fn: matchTableHeader
  },
  {
    tag: 'table',
    fn: matchTable
  }
];

BetterTable.WHITELISTED_FORMATS = WHITELISTED_FORMATS;
BetterTable.FORMAT_OVERRIDES = Object.keys(FORMAT_OVERRIDES);

function makeTableTabHandler (shift) {
  return {
    key: 'Tab',
    shiftKey: shift,
    format: ['tableCellLine'],
    handler(range, context) {
      // TODO move to table module
      const key = shift ? 'prev' : 'next';

      const cell = context.line.parent;
      let cellToSelect = cell[key] || cell.parent[key]?.children[shift ? 'tail' : 'head'];
      if (cellToSelect) {
        const { index, length } = cellToSelect.range(this.quill);
        this.quill.setSelection(index + length - 1, 0, Quill.sources.USER);
      }
      // Disabled default functionality
      return false;
    },
  };
}

function makeTableArrowHandler (up) {
  return {
    key: up ? 'ArrowUp' : 'ArrowDown',
    collapsed: true,
    format: ['tableCellLine'],
    handler(range, context) {
      // TODO move to table module
      const key = up ? 'prev' : 'next';
      const targetLine = context.line[key];
      if (targetLine != null) {
        // Found another line in the cell. Lets allow default functionality
        return true;
      }

      const cell = context.line.parent;
      const rowOffset = cell.parent.rowOffset();
      const rowOffsetToSelect = rowOffset + (up  ? -1 : cell.rowspan());
      // if next row index to select is out of bounds.
      if (rowOffsetToSelect >= 0 && rowOffsetToSelect < cell.parent.parent.children.length) {
        const cellMap = generateCellMap(cell.parent.parent);
        const cellOffset = cellMap[rowOffset].indexOf(cell);
        const cellToSelect = cellMap[rowOffsetToSelect][cellOffset];
        const index = cellToSelect.offset(this.quill.scroll);
        this.quill.setSelection(index, 0, Quill.sources.USER);
      } else {
        const targetLine = cell.table().parent[key];
        if (targetLine != null) {
          if (up) {
            this.quill.setSelection(
              targetLine.offset(this.quill.scroll) + targetLine.length() - 1,
              0,
              Quill.sources.USER
            );
          } else {
            this.quill.setSelection(
              targetLine.offset(this.quill.scroll),
              0,
              Quill.sources.USER
            );
          }
        }
      }
      // Disabled default functionality
      return false;
    },
  };
}

function isTableCell (blot) {
  return blot.statics.blotName === TableCell.blotName;
}

function isInTableCell (current) {
  return current && current.parent
    ? isTableCell(current.parent)
      ? true
      : isInTableCell(current.parent)
    : false;
}

/**
 * 
 * @param {Function} blotClass 
 */
BetterTable.addDependentBlot = (blotClass) => {
  TableContainer.dependents.push(blotClass);
};

export default BetterTable;
