import React, { useEffect, useState } from 'react';
import T from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import PublicSnippetPreview from './PublicSnippetPreview';
import Box from '@mui/material/Box';
import AsyncButton from '../AsyncButton/AsyncButton';
import { copyPublicGroup } from '../../data';
import Alert from '@mui/material/Alert';
import UserAvatar from '../UserAvatar/UserAvatar';
import { log } from '../../logging/logging';
import { useIsMedium, useTypedSelector } from '../../hooks';
import { Button, Dialog } from '@mui/material';
import { DialogPaperStyles } from '../Auth/auth_utilities';
import AuthComponent from '../Auth/AuthComponent';
// @ts-ignore
import anim_form from '../../../images/anim_forms.webp';
// @ts-ignore
import TextBlazeLogo from '../../../images/icon_48.png';
import { installExtension } from '../../install_extension';

/**
 * @typedef {{
 * group: GroupObjectType,
 * snippets: SnippetObjectType[],
 * author: import('../PermissionList/utilities').UserInfo,
 * seo_title: string
 * template: TemplateData|null
 * }} PublicGalleryPageData
 */

/**
 * @param {object} props
 * @param {boolean} props.isAuth
 * @param {boolean} props.wasLoggedOut
 * @param {boolean} props.isExtensionInstalled
 * @param {PublicGalleryPageData} props.pageData
 */
function PublicPanelBase(props) {
  const group = props.pageData.group;
  const snippets = props.pageData.snippets;
  const authorInfo = props.pageData.author;
  const templateData = props.pageData.template;
  const groupSharedByBlaze = authorInfo?.emailVerified && authorInfo.email.endsWith('@blaze.today');

  if (groupSharedByBlaze) {
    authorInfo.name = 'Text Blaze Team';
    authorInfo.imageUrl = TextBlazeLogo;
  }

  const isNewSignUp = useTypedSelector(store => store.userState.newSignUp);

  let [isAuthenticating, setIsAuthenticating] = useState(false);
  let [isImporting, setIsImporting] = useState(false);

  const isPaidTemplate = templateData && templateData.paid;

  const isMedium = useIsMedium();

  const isLoading = props.isAuth === null;

  useEffect(() => {
    if (isPaidTemplate) {
      return;
    }
    
    // do the import when authenticating on this page
    if (props.wasLoggedOut && props.isAuth) {
      log(
        { action: 'New snippet auto imported',
          label: { name: group.name, id: group.id, source: 'PublicPanel' }
        }
      );

      // scroll to the top since we'll show the auto import loading
      window.scrollTo(0, 0);

      setIsImporting(true);
      copyPublicGroup({ group, snippets, withPreview: true, automaticCopy: isNewSignUp });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.wasLoggedOut, props.isAuth]);

  useEffect(() => {
    // it finished authenticating, hide auth dialog
    if (props.isAuth) {
      setIsAuthenticating(false);
    }

  }, [props.isAuth]);

  if (isImporting) {
    return <div style={{ height: '100vh' }}>
      <div style={{ paddingTop: '8vh', marginBottom: 30, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <T variant="h4" mb={10}>Copying folder to your account</T>
        <CircularProgress size={150} thickness={1.9} />
      </div>
    </div>;
  }

  if (isLoading) {
    return <div
      style={{
        paddingTop: '15vh',
        marginBottom: 30,
        justifyContent: 'center',
        display: 'flex',
        height: '100vh'
      }}
    >
      <CircularProgress size={150} thickness={1.9}/>
    </div>;
  }

  return (
    <Box padding="10px">
      <Dialog
        className="login-dialog"
        open={isAuthenticating}
        PaperProps={{
          style: DialogPaperStyles
        }}
        onClose={() => setIsAuthenticating(false)}
      >
        <AuthComponent/>
      </Dialog>

      {props.isAuth && <Alert severity={isPaidTemplate ? 'warning' : 'info'} style={{
        marginTop: 16
      }}>
        {isPaidTemplate
          ? <>
            This is a paid template folder. You can only preview its contents but not import it. You can get the template{' '}
            <a href={`/templates/${templateData.slug}`}>here</a>
          </>
          : 'This is a publicly shared folder, you can preview it and create an account to copy it.'}
      </Alert>}

      {!props.isAuth &&
        <div
          className="integrations__banner"
          style={{
            padding: '10px 0',
            margin: '20px 0'
          }}
        >
          <Box className="integrations__page-title">
            <T variant="h4" component="h1" color="primary">
              Eliminate repetitive typing and mistakes with Text Blaze
            </T>

            <T variant="h6" component="p" fontWeight="normal" mt={2}>
              {isPaidTemplate
                ? <>
                  This is a paid template folder. You can only preview its contents but not import it. You can get the template{' '}
                  <a href={`/templates/${templateData.slug}`}>here</a>
                </>
                : 'This is a template folder that you can preview, and copy to your account when signing up.'
              }
            </T>

            <Box className="integration__page-actions" mt={3}>
              {!props.isExtensionInstalled &&
                <Button variant="contained" onClick={installExtension} size="large">
                  <b>Download extension</b><span style={{ fontWeight: '400' }}>&nbsp;– It's free!</span>
                </Button>
              }

              {isPaidTemplate
                ? <Button
                  component="a"
                  href={`/templates/${templateData.slug}`}
                  size="large"
                  variant={props.isExtensionInstalled ? 'contained' : 'outlined'}
                  sx={{ minWidth: '130px', fontWeight: props.isExtensionInstalled ? '700' : '500' }}
                >
                  Go to template
                </Button>
                : <Button
                  size="large"
                  variant={props.isExtensionInstalled ? 'contained' : 'outlined'}
                  onClick={() => setIsAuthenticating(true)}
                  sx={{ minWidth: '130px', fontWeight: props.isExtensionInstalled ? '700' : '500' }}
                >
                  Sign up and copy to Text Blaze
                </Button>}
            </Box>
          </Box>
          <img
            className="integrations__banner-image"
            height={350}
            style={{ maxHeight: 350 }}
            src={anim_form}
            alt="Animation of Text Blaze works"
          />
        </div>
      }


      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '100%',
        flexWrap: {
          xs: 'wrap',
          lg: 'initial'
        }
      }}>
        <T component={props.isAuth ? 'h1' : 'h2'} variant={props.isAuth ? 'h3' : 'h4'} paragraph my={2}>{group.name}</T>
        {props.isAuth && <Box sx={{
          width: {
            xs: '100%',
            md: 'auto'
          }
        }}>
          {isPaidTemplate
            ? <Button
              component="a"
              href={`/templates/${templateData.slug}`}
              variant="contained"
              sx={{
                borderRadius: 36,
                whiteSpace: 'nowrap',
                fontSize: '110%',
                width: '100%'
              }}
            >
            Go to template
            </Button>
            : <AsyncButton color="primary" variant="contained" onClick={(done) => copyPublicGroup({ done, group, snippets })} style={{
              borderRadius: 36,
              whiteSpace: 'nowrap',
              fontSize: '110%',
              width: '100%'
            }}>
            Copy to Text Blaze
            </AsyncButton>}
        </Box>}
      </Box>

      {authorInfo && !isMedium && (
        <UserAvatar info={authorInfo}/>
      )}

      {group.info ? (
        <T
          variant="subtitle1"
          paragraph
          sx={{
            my: {
              md: 4,
              xs: 1
            },
            whiteSpace: 'pre-wrap'
          }}
        >
          {group.info}
        </T>
      ) : null}

      <Box sx={{
        my: {
          xs: 4,
          lg: 6
        }
      }}>
        <T component="h2" variant="h5" mb={3} fontWeight={500}>Snippets in this folder:</T>

        {renderSnippets()}
      </Box>
    </Box>
  );

  function renderSnippets() {
    if (!snippets.length) {
      return null;
    }

    return snippets.map((snippet) => {
      if (!snippet) {
        return null;
      }
      return <PublicSnippetPreview key={snippet.id} snippet={snippet}/>;
    });
  }
}

const PublicPanel = React.memo(PublicPanelBase);
export default PublicPanel;


