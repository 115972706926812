import { useRef } from 'react';

export default function useCloseWarning() {
  let closeWarning = useRef(null);

  function warnOnExit(message) {
    if (!closeWarning.current) {
      closeWarning.current = (event) => {
        event.returnValue = message;
      };
      window.addEventListener('beforeunload', closeWarning.current);
    }
  }

  function cancelWarningOnClose() {
    if (closeWarning.current) {
      window.removeEventListener('beforeunload', closeWarning.current);
      closeWarning.current = null;
    }
  }

  return {
    warnOnExit,
    cancelWarningOnClose,
  };
}