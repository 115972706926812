import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography as T
} from '@mui/material';

/**
 * @param {Object} props
 * @param {string} props.annualPriceDelta
 * @param {Function} props.onBillingChange
 * @param {import("./PaymentDialog").PaymentDialogConfig['billing']} props.billing
 */
function BillingCycleSelect({ annualPriceDelta, onBillingChange, billing }) {
  return <FormControl fullWidth sx={{ mb: 2 }}>
    <InputLabel id="billing-cycle">Billing cycle</InputLabel>

    <Select
      labelId="billing-cycle"
      id="billing"
      value={billing}
      label="Billing cycle"
      onChange={(e) => onBillingChange(e.target.value)}
      fullWidth
    >
      <MenuItem value="annually">
        Annual plan
        <b style={{ color: '#05acc0', fontWeight: '600', fontSize: '13px' }}>&nbsp;&nbsp;SAVE OVER 15%</b>
      </MenuItem>
      <MenuItem value="monthly">Monthly plan</MenuItem>
    </Select>

    {billing === 'monthly' && <T fontSize={13} mt="10px"><b>Save ${annualPriceDelta} USD/year</b> by switching to the annual plan </T>}
  </FormControl>;
}

export default BillingCycleSelect;