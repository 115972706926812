import React, { Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { store, isOrg, usersSettingsRef } from '@store';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { dialogs, toast } from '../../message';
import { dialog } from '../DataDialog/DataDialog';
import Feedback from '../Feedback/Feedback';
import InnerMain from './InnerMain';
import AuthDialog from '../Auth/AuthDialog';
import { fontStyle } from '../SnippetEditor/fontConfig';
import { Helmet } from 'react-helmet-async';
import { useIsPublicUrl } from '../../hooks/useIsPublicUrl';
import { databaseDialogs } from '../Database/database_utilities';
import { AI_APP_DOMAIN, deleteTokenCookie, getTokenCookie, isElectronApp } from '../../flags';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { DesktopOpenedDialog } from '../Desktop/DesktopOpenedDialog';
import InnerPublicSnippet from './InnerPublicSnippet';
import InnerPublicIntegration from './InnerPublicIntegration';
import { StatusNotification } from '../StatusNotification/StatusNotification';
import DesktopNavigation from './DesktopNavigation';
import { NewUserUpgrade } from '../BillingDetails/NewUserUpgrade';

import { generateAppRoute } from '../../hooks/useAppNavigate';
import { useTypedSelector } from '../../hooks';
import { eagerlyLoadImport, storage } from '../../utilities';
import LoadingProgressIndicator from '../Utilities/LoadingProgressIndicator.jsx';

const InnerPublicTemplateList = React.lazy(eagerlyLoadImport(() => import('../Templates/InnerPublicTemplateList')));
const InnerPublicTemplateView = React.lazy(eagerlyLoadImport(() => import('../Templates/InnerPublicTemplateView')));
const InnerPublicTemplateCategory = React.lazy(eagerlyLoadImport(() => import('../Templates/InnerPublicTemplateCategory')));
const InnerPublicTemplateUser = React.lazy(eagerlyLoadImport(() => import('../Templates/InnerPublicTemplateUser')));


function AppInner() {
  const isPublicUrl = useIsPublicUrl();
  const isUsingAiBlaze  = useTypedSelector(state => state.userState?.options?.is_using_ai_blaze);

  useEffect(() => {
    const token = getTokenCookie();
    if (!token) {
      return;
    }
    signInWithCustomToken(getAuth(), token)
      .then(deleteTokenCookie)
      .catch(err => toast('Failed to login. Please try again after sometime. ' + err.message, {
        intent: 'danger'
      }));
  }, []);

  // If the user was using AI blaze before the domain switch, keep redirecting them to AI Blaze. This is because:
  // 1) they may have saved "dashboard.blaze.today" as the AI Blaze domain
  // 2) until the AI Blaze extension doesn't update, the buttons will keep redirecting to TB.
  //
  // Can remove in a couple of weeks (make sure the AI Blaze extension was updated before)
  // 2024/05/22
  useEffect(() => {
    if (isUsingAiBlaze && !isElectronApp()) {
      // If the user came from AI Blaze disable the flag to allow them to use TB
      if (document.referrer.startsWith(AI_APP_DOMAIN)) {
        storage.update(usersSettingsRef, { 'options.is_using_ai_blaze': false }, 'HIDE_AUTOSAVE');
      } else {
        const path = window.location.href.replace(window.location.origin, '');
        // Keep the path, eg "/new?source=popup&text=New%20Snippet"
        window.location.href = generateAppRoute(path, 'AI').url;
      }
    }
  }, [isUsingAiBlaze]);

  return (
    <>
      <Helmet defer={false}>
        <title>Text Blaze Dashboard</title>
      </Helmet>
      <style>{fontStyle}</style>
      <Switch>
        <Route exact path="/gallery/:id" render={() => <InnerPublicSnippet />} />
        <Route exact path="/integration/:id" render={() => <InnerPublicIntegration />} />
        <Route exact path="/:upgrade(upgrade_business|upgrade_pro)" component={NewUserUpgrade}/>

        <Route exact path="/templates" component={() => <Suspense fallback={<LoadingProgressIndicator />}>
          <InnerPublicTemplateList />
        </Suspense>}/>
        <Route exact path="/templates/:slug" component={() => <Suspense fallback={<LoadingProgressIndicator />}>
          <InnerPublicTemplateView />
        </Suspense>}/>
        <Route exact path="/templates/get/:id" component={() => <Suspense fallback={<LoadingProgressIndicator />}>
          <InnerPublicTemplateView copyingPaidTemplate/>
        </Suspense>}/>
        <Route exact path="/templates/category/:category" component={() => <Suspense fallback={<LoadingProgressIndicator />}>
          <InnerPublicTemplateCategory />
        </Suspense>}/>
        <Route exact path="/templates/user/@:handle" component={() => <Suspense fallback={<LoadingProgressIndicator />}>
          <InnerPublicTemplateUser />
        </Suspense>}/>

        <Route component={InnerMain} />
      </Switch>

      {!isPublicUrl && <DesktopNavigation />}
      {!isPublicUrl && <AuthDialog />}
      {!isPublicUrl && <DesktopOpenedDialog />}
      <Feedback />
      {dialogs({ store, isOrg })}
      <StatusNotification />
      {databaseDialogs()}
      <ErrorBoundary dialog details="in the data dialog">{dialog}</ErrorBoundary>
    </>
  );
}


export default AppInner;
