import { log } from './logging/logging';
import { pingDesktopApp, sendToExtension } from './extension';
import { sendToAndroid, store } from '@store';
import { getAuth, signOut } from 'firebase/auth';
import { sendMessageToClient } from './desktop_utilities';
import { logout } from './cpapi';

export async function beforeSignout() {
  // To avoid sending tokens to the dashboard after sign-out
  // If the dashboard is destroyed, then 'signout' property 
  // on the window can not be true as the window is destroyed
  window['signout'] = true;
}

/**
 * 
 * @param {string} email 
 * @param {string} token 
 */
export async function signoutLocalApp(email, token) {
  // Sign-out from the desktop app as we are doing the operation in the browser
  await pingDesktopApp(email, token, '/signout');
}

export function sendSignoutMessages() {
  sendToAndroid({
    type: 'signout'
  });
  sendToExtension({
    type: 'signout'
  });
  sendMessageToClient({
    type: 'signout'
  });
}

/**
 * Will signout the user.
 */
export async function signout() {
  await beforeSignout();
  await logout();
  log({
    action: 'Logout'
  });
  await signOut(getAuth());
  store.dispatch({
    type: 'USER_LOGIN',
    user: {
      isLoaded: true
    }
  });
  sendSignoutMessages();
}
