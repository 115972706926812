import React, { Component } from 'react';
import { sanitizeText } from '../../engine_utilities';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import T from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import { toast } from '../../message';
import { AttributeEditor } from '../AttributeEditors/AttributeEditors';
import AsyncButton from '../AsyncButton/AsyncButton';
import SimpleSQLEditor from '../SimpleSQLEditor/LazySimpleSQLEditor';
import { astSimpleSQL } from '../../snippet_processor/SimpleSQL';
import { getDateTimeConfig } from '../../snippet_processor/Commands';
import DataMenuList from './DataMenuList';


/**
 * @typedef {object} DataDialogConfigDef
 * @property {string} title
 * @property {any[]} fields
 * @property {Function=} onSubmit
 * @property {Function=} onCancel
 * @property {Function=} onUpdated
 * @property {any[]=} list
 * @property {any=} info
 * @property {any=} footer
 * @property {any=} icon
 * @property {object=} context
 * @property {string=} applyButton
 * @property {any=} defaults
 * @property {string=} commandName
 * @property {React.ExoticComponent=} FieldsWrapper
 * @property {object=} fieldsWrapperProps
 */



class DataDialog extends Component {
  /**
   * @type {{open: boolean, list: any[], config: DataDialogConfigDef, values: any[], resolve: function, errors: Object<string, any>, openInfo: boolean}}
   */
  state = {
    open: false,
    errors: null,
    values: null,
    resolve: null,
    list: null,
    config: null,
    openInfo: false
  };

  ref = React.createRef();

  show = (config) => {
    return new Promise((resolve) => {
      config = Object.assign({}, config);

      config.fields = config.fields.map(f => {
        f = Object.assign({}, f);

        if (!f.attribute) {
          return f;
        }

        f.attribute = Object.assign({}, f.attribute, {
          name: f.attribute.nameLocked ? f.attribute.name : (f.attribute.name + (f.attribute.required ? '' : ' (optional)')),
        });

        /** @type {UserDefinedPropertyType} */
        let attr = f.attribute;
        return Object.assign(f, {
          required: attr.required,
          name: attr.name,
          placeholder: 'placeholder' in attr ? attr.placeholder : attr.default,
          helper: attr.description
        });
      });

      let values = config.fields.map(x => ({
        id: x.id,
        touched: false,
        value: config.defaults ? config.defaults[x.id] : undefined,
        disabled: x.disabled,
      }));

      this.setState({
        open: true,

        errors: null,

        list: config.list ? config.list.map(item => Object.assign({}, item)) : null,

        config,
        resolve,
        values,
      });
    });
  };

  hide = () => {
    this.setState({ open: false });
  };

  cancel = () => {
    if (this.state.config.onCancel) {
      this.state.config.onCancel();
    }

    this.hide();
  };

  submit = async () => {
    let shouldBail = false;
    /** @type {any} */
    let res = this.state.values.map(x => Object.assign({}, x)).slice();

    res.forEach((x, i) => {
      const field = this.state.config.fields[i];
      if (!x.touched && !field.clearUntouched) {
        x.value = undefined;
      } else {
        if (field.sanitize) {
          x.value = sanitizeText(x.value);
        }
      }
      if (field.required && 
        (x.value === undefined 
          || (x.value.trim && !x.value.trim())
          || (Array.isArray(x.value) && !x.value.length)
        )
      ) {
        if (field.attribute && field.attribute.name === 'query' && field.attribute.type === 'bsql') {
          // special case handling for BSQL queries, the editor will emit
          // other errors if the query is invalid and we don't want to show the
          // regular required message
          shouldBail = true;
          return;
        }
        toast(`"${field.name}" is required.`, {
          duration: 6000,
          intent: 'danger'
        });
        shouldBail = true;
      }
    });

    if (this.state.list) {
      if (this.state.list.length === 0) {
        toast('You must have at least one choice.', {
          duration: 6000,
          intent: 'danger'
        });
        shouldBail = true;
      } else if (!this.state.list.find(x => x.selected)) {
        toast('You must have a default choice.', {
          duration: 6000,
          intent: 'danger'
        });
        shouldBail = true;
      }
    }

    let errors = this.state.errors || {};
    if (Object.keys(errors).length) {
      toast('You must resolve the errors below first.', {
        duration: 6000,
        intent: 'danger'
      });
      shouldBail = true;
    }

    if (shouldBail) {
      return;
    }

    if (this.state.list) {
      res.list = this.state.list;
    }

    let positionalIndex = res.findIndex(x => x.id.startsWith('%positional'));
    if (positionalIndex > 0) {
      res.unshift(res.splice(positionalIndex, 1)[0]);
    }

    if (this.state.config.onSubmit) {
      await this.state.config.onSubmit(res);
    }

    this.state.resolve(res);
    this.hide();
  };

  list = () => {
    if (!this.state.list) {
      return;
    }
    return <DataMenuList 
      items={this.state.list}
      onChange={(newValues) => this.setState({
        list: newValues
      })}
    />;
  };

  getField = (id) => this.state.values.find(x => x.id === id);

  setValue = (id, value, dontPropagate = false) => {
    let item = this.getField(id);
    item.value = value;
    item.touched = true;
    if (this.state.config.onUpdated && !dontPropagate) {
      this.state.config.onUpdated.bind(this)(item.id, item.value);
    }
    this.setState({ values: this.state.values.slice() });
  };

  fieldInput = (field, i) => {
    if (field.attribute) {
      let context = {
        setHasError: (hasError) => {
          let newErrors = Object.assign({}, this.state.errors);
          let errorLabel = 'Item-' + i;
          if (hasError) {
            newErrors[errorLabel] = true;
          } else {
            delete newErrors[errorLabel];
          }
          this.setState({
            errors: newErrors
          });
        },
        activeTypes: this.state.config.context?.activeTypes
      };
      let legacyFormMenu = field.id === 'multiple'
        ? this.state.config.fields.find(f => f.attribute?.isLegacyMenu)
        : null;
      let onChange = (v) => {
        if (legacyFormMenu && v === 'no') {
          let formValue = this.state.values.find(v => v.id === 'values');
          let newValues = [],
            changed = false,
            foundDefault = false;
          for (const value of formValue?.value) {
            if (foundDefault && value.selected) {
              changed = true;
              newValues.push({
                ...value,
                selected: false
              });
            } else {
              newValues.push(value);
            }
            foundDefault = foundDefault || value.selected;
          }
          if (changed) {
            this.setValue(formValue.id, newValues);
          }
        }
        if (!field.attribute.required && v === '') {
          this.setValue(this.state.values[i].id, undefined);
        } else {
          this.setValue(this.state.values[i].id, v);
        }
      };

      if (field.attribute.type === 'bsql') {
        const databaseId = this.state.values[0].value;
        // When databaseId is undefined, don't show the sql editor
        // so that it does not trigger 404 on frontend
        if (!databaseId) {
          return null;
        }
        return <SimpleSQLEditor
          attribute={field.attribute}
          onChange={onChange}
          // @ts-ignore string type of commandName
          value={astSimpleSQL(this.state.values[i].value, this.state.config.commandName)}
          databaseId={databaseId}
          context={context}
        />;
      }

      if (field.attribute.type === 'datetime') {
        const format = this.state.values.find(x => x.id === '%positional');
        field.attribute.config = { ...field.attribute.config, datetime: getDateTimeConfig(format.value) };
        const { date, time } = field.attribute.config.datetime;
        if (date && time) {
          field.attribute.description = (field.attribute.description || '').replace(/(\s)(time|date|datetime)(\s)/g, ' datetime ');
        } else if (time) {
          field.attribute.description = (field.attribute.description || '').replace(/(\s)(time|date|datetime)(\s)/g, ' time ');
        } else {
          field.attribute.description = (field.attribute.description || '').replace(/(\s)(time|date|datetime)(\s)/g, ' date ');
        }
      }
      if (field.attribute?.isLegacyMenu) {
        if (!field.attribute.config) {
          field.attribute.config = {};
        }
        /**
         * Selector field does not go through this. So just setting for formmemnu
         */
        field.attribute.config.isMultiple = this.state.values.find(x => x.id === 'multiple')?.value === 'yes';
      }

      return <AttributeEditor
        suggestionBar={false}
        attribute={field.attribute}
        autoFocus={i === 0}
        rawText={field.rawText || null}
        value={this.state.values[i].value}
        context={context}
        readonly={this.state.values[i].disabled}
        onChange={onChange}
        onError={(e) => {
          let errors = Object.assign({}, this.state.errors);
          if (!e) {
            delete errors[i];
          } else {
            errors[i] = e;
          }
          this.setState({
            errors
          });
        }}
      />;
    }
    
    let id = 'data-dialog-input-' + i;
    switch (field.type) {
    case 'text':
      return (<TextField
        label={field.name}
        size="small"
        helperText={field.helper}
        variant="outlined"
        placeholder={field.placeholder}
        value={this.state.values[i].value || ''}
        onChange={(e) => this.setValue(this.state.values[i].id, field.maxLength ? e.target.value.slice(0, field.maxLength) : e.target.value)}
        fullWidth
        disabled={this.state.values[i].disabled}
        required={field.required}
        autoFocus={i === 0}
        sx={{
          my: 1
        }}
      />);
    case 'select':
      return (
        <FormControl style={{ width: '100%', marginBottom: 8 }} variant="standard">
          <InputLabel>{field.name}</InputLabel>
          <Select
            value={this.state.values[i].value || ''}
            fullWidth
            onChange={(e) =>this.setValue(this.state.values[i].id, e.target.value)}
            disabled={this.state.values[i].disabled}
            required={field.required}
            variant="standard"
          >
            {field.options.map((option, i) => <MenuItem key={i} disabled={option.disabled} dense value={option.value}>{option.label}</MenuItem>)}
          </Select>
          {field.helper && <FormHelperText>{field.helper}</FormHelperText>}
        </FormControl>
      );
    case 'password':
      return (<TextField
        label={field.name}
        size="small"
        helperText={field.helper}
        variant="outlined"
        id={id}
        type="password"
        placeholder={field.placeholder}
        value={this.state.values[i].value || ''}
        onChange={(e) => this.setValue(this.state.values[i].id, e.target.value)}
        fullWidth
        autoFocus={i === 0}
        disabled={this.state.values[i].disabled}
        required={field.required}
        sx={{
          my: 1
        }}
      />);
    case 'textarea':
      return (<TextField
        label={field.name}
        size="small"
        helperText={field.helper}
        variant="outlined"
        multiline
        rows={3}
        id={id}
        placeholder={field.placeholder}
        value={this.state.values[i].value || ''}
        onChange={(e) => this.setValue(this.state.values[i].id, e.target.value)}
        fullWidth
        disabled={this.state.values[i].disabled}
        required={field.required}
        autoFocus={i === 0}
      />);
    case 'html':
      return (<div
        id={id}
        className="lpt3-input"
        contentEditable
        onInput={(e) => this.setValue(this.state.values[i].id, /** @type {HTMLElement} */ (e.target).innerHTML)}
        style={{ width: '100%', height: 120, marginTop: 8, overflow: 'auto' }}
      ></div>);
    case 'number':
      return (<TextField
        label={field.name}
        size="small"
        helperText={field.helper}
        type="number"
        variant="outlined"
        id={id}
        placeholder={field.placeholder}
        value={this.state.values[i].value || ''}
        onChange={(e) => this.setValue(this.state.values[i].id, e.target.value)}
        fullWidth
        inputProps={{
          min: field.min,
          max: field.max,
          step: field.step
        }}
        disabled={this.state.values[i].disabled}
        required={field.required}
        autoFocus={i === 0}
        sx={{
          my: 1
        }}
      />);
    case 'boolean':
      return  <>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                id={id}
                checked={!!(this.state.values[i].value || false)}
                onChange={(_e) => {
                  this.setValue(this.state.values[i].id, !this.state.values[i].value);
                }}
                color="primary"
                disabled={this.state.values[i].disabled}
                required={field.required}        
              />
            }
            label={field.name}
          />
        </FormGroup>
        {field.helper ? <T variant="caption" color="textSecondary">{field.helper}</T> : null}
      </>;
    default:
      throw Error('Unknown field type: ' + field.type);
    }
  };

  render() {
    if (!this.state.open) {
      return null;
    }
    const FieldsWrapper = this.state.config.FieldsWrapper || React.Fragment;

    return (
      <Dialog
        open
        onClose={this.cancel}
        maxWidth="xs"
      >
        <DialogTitle
          ref={this.ref}
        >
          {this.state.config.icon ? React.cloneElement(this.state.config.icon, { style: { verticalAlign: 'middle', marginRight: 10, opacity: .8 } }) : null} <span style={{ paddingRight: 30 }}>{this.state.config.title}</span>
          {this.state.config.info && (
            <IconButton
              onClick={() => this.setState({ openInfo: true })}
              sx={{
                position: 'absolute',
                transform: 'translate(50%, -50%)',
                right: 28,
                top: 32
              }}
            >
              <InfoIcon />
            </IconButton>
          )}
          {this.state.config.info && (
            <Popover
              anchorEl={this.ref.current}
              open={this.state.openInfo}
              onClose={() => this.setState({ openInfo: false })}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              PaperProps={{
                sx: {
                  maxWidth: this.ref.current ? this.ref.current.offsetWidth : 'min(400px, calc(100vw - 200px))',
                  p: 2
                }
              }}
            >
              {this.state.config.info}
            </Popover>
          )}
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
            minWidth: {
              xs: 'min(calc(100% - 40px), 350px)',
              sm: '350px'
            }
          }}>
          <FieldsWrapper {...this.state.config.fieldsWrapperProps}>
            {this.state.config.fields.map((field, i) => {
              return (
                <div
                  key={i}
                  style={{
                    position: 'relative',
                    padding: '8px 24px'
                  }}
                >
                  {this.fieldInput(field, i)}
                </div>
              );
            })}
          </FieldsWrapper>
          {this.list()}
          {this.state.config.footer && (
            <div
              key="footer-wrapper"
              style={{
                marginBottom: '16px',
                padding: '5px 24px'
              }}
            >
              {this.state.config.footer}
            </div>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            px: '24px',
            pb: 2
          }}
        >
          <Button onClick={this.cancel} style={{ marginRight: 8 }}>Cancel</Button>
          {this.state.config.onSubmit ? <AsyncButton data-testid="apply-button" color="primary" onClick={async (done) => {
            await this.submit();
            done();
          }} variant="contained">{this.state.config.applyButton || 'Insert'}</AsyncButton> : <Button data-testid="apply-button" color="primary" onClick={() => this.submit()} variant="contained">{this.state.config.applyButton || 'Insert'}</Button>}
        </DialogActions>
      </Dialog>
    );
  }
}


let dialogCls;
export const dialog = <DataDialog
  ref={(cls) => dialogCls = cls}
/>;


/**
 * @param {DataDialogConfigDef} config
 */
export async function getData(config) {
  return dialogCls.show(Object.assign({}, config));
}