/* eslint-disable no-unused-expressions */
import Container from 'quill/blots/container';

/**
 * @template {import('parchment').Blot} TChild
 * @template {TypedContainer | import('parchment').Root} TParent
 */
class TypedContainer extends Container {
  static create(value) {
    return /** @type {HTMLElement} */ (super.create(value));
  }

  /**
   * 
   * @param {import('quill/blots/scroll').default} scroll 
   * @param {HTMLElement} domNode 
   */
  constructor(scroll, domNode) {
    super(scroll, domNode);

    /**
     * Override the types of the properties.
     */

    /**
     * @type {import('parchment').LinkedList<TChild>}
     */
    this.children;

    /**
     * @type {this}
     */
    this.prev;

    /**
     * @type {this}
     */
    this.next;

    /**
     * @type {TParent}
     */
    this.parent;
  };
}

export default TypedContainer;