import React, { useEffect, useState } from 'react';

import { Button, CircularProgress } from '@mui/material';
import T from '@mui/material/Typography';
import { captureException } from '@sentry/browser';
import RefreshIcon from '@mui/icons-material/Refresh';

export function DesktopAuthComponent() {
  const [isStuck, setIsStuck] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsStuck(true);
      captureException('Dashboard is stuck in the app');
    }, 20000); // 20 seconds: As we starts loading dashboard immediately after sending token to the checker 
    // so it accommodates both checker login and getting and sending token to the dashboard

    return () => clearTimeout(timeout);
  }, []);

  return (<div style={{
    width: 'fit-content',
    height: 'fit-content',
    margin: 'auto',
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  }}>
    <div
      style = {{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 20,
        maxWidth: 600,
        marginRight: 50
      }}
    >
      <CircularProgress /> 

      <T
        variant="h5"
        style={{
          textAlign: 'center',
          fontSize: 20,
          margin: 20
        }}>
        Logging you in... {isStuck && <>If this is stuck, please reload. If this issue persists, please email us at <a href="mailto:support@blaze.today">support@blaze.today</a>.</>}
      </T>
      {isStuck && <Button variant="contained" sx={{ padding: 1, flex: 1 }} onClick={() => {
        window.location.reload();
      }} startIcon={<RefreshIcon fontSize="small" />}>
        Reload
      </Button>}
    </div>
  </div>);
}