import React from 'react';
import { checkPro, checkOrg } from '../../flags';
import { useSelector, shallowEqual } from 'react-redux';
import { ProChipBase, BetaChipBase, ChromeChipBase, WindowsChipBase, MacChipBase } from './VersionChipBase';


/**
 * @param {object} props
 * @param {number=} props.zoom
 * @param {string=} props.tooltip
 * @param {string=} props.type
 * @param {object=} props.style
 */
export function ProChip(props) {
  // We use useSelector instead of useTypedSelector as we don't want to import store
  // in targets such as the docs target where we don't want the other app code.
  let { isActive, label } = useSelector((store) => {
    // @ts-ignore
    let isBusiness = checkOrg(store);
    let type = props.type;
    // Always show the business check if we have business
    if (isBusiness) {
      type = 'business';
    }
    let label = type || 'pro';

    return {
      // @ts-ignore
      isActive: type === 'business' ? isBusiness : checkPro(store),
      label
    };
  }, shallowEqual);

  return <ProChipBase {...props} isActive={isActive} label={label} />;
}


/**
 * @param {object} props
 * @param {number=} props.zoom
 * @param {React.CSSProperties=} props.style
 * @param {React.CSSProperties=} props.styleChip
 */
export function BetaChip(props) {
  return <BetaChipBase {...props} />;
}

/**
 * @param {object} props
 * @param {string=} props.title
 */
export function ChromeChip(props) {
  return <ChromeChipBase {...props} />;
}

export function WindowsChip(props) {
  return <WindowsChipBase {...props} />;
}

export function MacChip(props) {
  return <MacChipBase {...props} />;
}