import { useEffect, useState } from 'react';
import { uidToUserInfo } from '../components/PermissionList/utilities';
import { useIsMounted } from '../hooks';


/**
 * @param {string} uid
 * 
 * @return {import("../components/PermissionList/utilities").UserInfo}
 */
export const useUserInfo = (uid) => {
  const [userInfo, setUserInfo] = useState(null);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!uid) {
      return;
    }

    uidToUserInfo(uid).then((data) => {
      if (isMounted.current) {
        setUserInfo(data);
      }
    });
    // eslint-disable-next-line
  }, [uid]);

  return userInfo;
};