import { query, where } from 'firebase/firestore';
import { makeRef } from '../../../firebase_utilities';




export const TRASH_LABELS = {
  'groups': {
    groupType: 'folder',
    groupTypeCapitalized: 'Folder',
    groupTypePlural: 'folders'
  },
  'snippets': {
    groupType: 'snippet',
    groupTypeCapitalized: 'Snippet',
    groupTypePlural: 'snippets'
  }
};

export const TRASH_EXPIRATION_LABEL = '10 days';

/**
   * 
   * @param {Parameters<import('./TrashedFirestore').default>['0']['filters']} filters 
   */
export const generateQuery = (filters) => {
  let constraints = [];
  for (const filter of filters) {
    constraints.push(where(filter[0], filter[1], filter[2]));
  }
  return constraints;
};

/**
 * Gerated query ref.
 * @param {string} collection 
 * @param {import('firebase/firestore').QueryConstraint[]} constraints 
 */
export const getFirebaseQuery = (collection, constraints) => {
  return query(makeRef(`trashed_${collection}`), ...constraints);
};

/**
 * 
 * @param {string} id 
 * @param {object} data 
 * @returns {import('../../TrashedDialog/TrashedList').TrashedData & { actual: object, deleted_at: import('firebase/firestore').Timestamp }}
 */
export const mapData = (id, data) => {
  return {
    id: id,
    name: data.name,
    label: null,
    icon: data.options?.icon,
    deleted_at: data.deleted_at,
    trashed_at: data.deleted_at 
      ? data.deleted_at.seconds * 1000
      : null,
    actual: data
  };
};