import React from 'react';
import Menu from '@mui/material/Menu';

/**
 * 
 * @param {Object} param0 
 * @param {boolean} param0.open
 * @param {import('react').EventHandler<any>} param0.onClose
 * @param {Element} param0.anchorEl
 * @param {number | string} param0.width
 * @param {any=} param0.MenuListProps
 * @param {React.ReactNode} param0.children
 * @returns 
 */
export default function MenuDropDown({
  open,
  onClose,
  anchorEl,
  width,
  MenuListProps,
  children
}) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          width: width,
          '& .MuiAvatar-root': {
            width: 40,
            height: 40,
            ml: -0.5,
            mr: 2
          }
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      MenuListProps={MenuListProps}
    >
      {children}
    </Menu>
  );
}
