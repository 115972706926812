import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  Tooltip,
  Typography as T
} from '@mui/material';
import AsyncButton from '../AsyncButton/AsyncButton';
import { toast } from '../../message';
import VerificationReminder from '../VerificationReminder/VerificationReminder';
import { log } from '../../logging/logging';
import { validEmail, emailDomain, prettyList } from '../../standalone_utilities';
import { orgPref } from '../../flags';
import { useIsMounted, useTypedSelector } from '../../hooks';
import { listPermissions } from './utilities';
import TagInputRaw from '../TagInput/TagInputRaw';



/**
 * 
 * @callback validateUsersFn
 * @param {{type: ('email'|'org'|'team'), value: string}[]} proposedEntities 
 * @returns {boolean} `true` to continue
 */

/**
 * 
 * @callback onSave
 * @param {PermissionsType} permissions - new set of permissions
 * @returns {Promise}
 */

/**
 * @param {object} props 
 * @param {PermissionsType} props.permissions
 * @param {string} props.entityId
 * @param {'group'|'org'|'database'|'site'} props.type
 * @param {string} props.access
 * @param {{name: string, label?: string, description: string}[]} props.accesses
 * @param {string} props.shareButton
 * @param {JSX.Element | string} props.header
 * @param {validateUsersFn=} props.validateUsersFn
 * @param {function} props.usersAddedFn
 * @param {onSave} props.onSave
 * @param {boolean=} props.onlyNew
 * @param {boolean=} props.onOptionsLoaded
 * @param {boolean=} props.autoFocus
 */
function AddUsersBoxBase({
  permissions,
  entityId,
  type,
  access,
  accesses,
  shareButton,
  header,
  validateUsersFn,
  usersAddedFn,
  onSave,
  onlyNew,
  autoFocus,
}) {
  const [proposedEntities, setProposedEntities] = useState(/** @type {{type: ('email'|'org'|'team'), value: string}[]} */([]));
  const [pendingProposedUsers, setPendingProposedUsers] = useState('');
  const [teamShare, setTeamShare] = useState('team_select');
  const [proposedPermission, setProposedPermission] = useState(accesses[accesses.length - 1].name.toLowerCase());
  const [options, setOptions] = useState(/** @type {Awaited<ReturnType<getEmails>>} */ ([]));
  const [loading, setLoading] = useState(false);
  const [dropdownSize, setDropdownSize] = useState(0);

  const isMounted = useIsMounted();
  let selectRef = useRef(null);

  let {
    domain,
    emailVerified,
    org,
    teams,
    xSharingDisabled,
    domainWhitelist,
    myUid,
    myEmail
  } = useTypedSelector((store) => {
    return {
      domain: emailDomain(store.userState.email || ''),
      emailVerified: store.userState.emailVerified,
      myUid: store.userState.uid,
      myEmail: store.userState.email,
      org: store.orgState ? store.orgState.org : null,
      // owners and editors can share with all teams, members just the teams they are part of
      teams: (store.orgState && store.userState.org) ? (['editor', 'owner'].includes(store.userState.org.type) ? store.orgState.allTeams : store.orgState.teams) : null,
      xSharingDisabled: orgPref(store, 'xSharingDisabled'),
      domainWhitelist: orgPref(store, 'domainWhitelist'),
    };
  });
  useEffect(() => {
    if (!selectRef.current) {
      return;
    }
    setDropdownSize(selectRef.current.clientWidth);
  }, [selectRef, proposedPermission]);

  useEffect(() => {
    if (!emailVerified) {
      return;
    }
    if (!org) {
      return;
    }
    if (onlyNew) {
      return;
    }
    /**
     * 
     * @param {import("./utilities").Permission[]} currentShares 
     * @returns 
     */
    function getOrgOptions(currentShares) {
      const opts = [];
      if (org && org.name && !currentShares.some(s => s.entityType === 'org' && s.entityValue === org.id)) {
        opts.push({
          value: 'org',
          type: 'org',
          label: `All of ${org.name}`
        });
      }
      if (!teams) {
        return opts;
      }
      for (let teamId in teams) {
        if (currentShares.some(s => s.entityType === 'team' && s.entityValue === teamId)) {
          continue;
        }
        opts.push({
          value: teamId,
          type: 'team',
          label: teams[teamId].name
        });
      };
      return opts;
    }

    (async function () {
      setLoading(true);
      let currentShares = await listPermissions(permissions);
      const orgOptions = getOrgOptions(currentShares);
      const orgEmailsMapped = await getEmails(org.permissions, myEmail, currentShares);

      if (!isMounted.current) {
        setLoading(false);
        return;
      }
      setOptions(prevOptions => {
        // Does not trigger state change if its empty
        if (!prevOptions.length
          && !orgOptions.length
          && !orgEmailsMapped.length) {
          return prevOptions;
        }
        return [
          ...orgEmailsMapped,
          ...orgOptions
        ];
      });
      setLoading(false);
    })();
  }, [
    onlyNew,
    emailVerified,
    org,
    isMounted,
    myEmail,
    permissions,
    teams
  ]);



  function proposedUsersErrors() {
    let proposed = proposedEntities;
    if (pendingProposedUsers) {
      proposed = proposed.concat(convertRawText(pendingProposedUsers));
    }
    let users = Array.from(new Set(proposed.filter(p => p.type === 'email').map(x => x.value.trim())));
    let errors = [];
    users.forEach(email => {
      if (!validEmail(email) || !sameEmailDomain(email)) {
        errors.push(email);
      }
    });

    // Must be kept in sync with addShare() in general_util.js the backend
    // Note we put these as a little lower than the backend caps to add a bit of buffer
    // (this shouldn't be necessary though and can be removed at any time).
    //
    // Note the group share limit will also be applied at a higher level and it is lower than this limit
    // for non paying and pro users.
    let maxSends = 40;
    if (org) {
      maxSends = 1000;
    }

    if (proposed.length > maxSends) {
      return 'Too many emails added at a single time.';
    }
    return errors;
  }


  /**
   * Validates the email is the same email domain if x-domain sharing is disabled.
   * 
   * @param {string} email
   * 
   * @return {boolean}
   */
  function sameEmailDomain(email) {
    if (!xSharingDisabled) {
      return true;
    }
    let myDomain = emailDomain(email);

    if (domainWhitelist && domainWhitelist.length) {
      return domainWhitelist.includes(myDomain);
    }
    if (myDomain !== domain) {
      return false;
    }

    return true;
  }


  function logFn(data) {
    let customDimensions = {};

    if (type === 'group') {
      customDimensions[`${type}_id`] = entityId;
    }

    log(data, customDimensions);
  }

  /**
   * 
   * @param {string} rawText 
   * @return {{type: 'email', value: string, label: string}[]}
   */
  function convertRawText(rawText) {
    return rawText.split(',')
      .map(item => item.trim())
      .filter(item => !!item && !proposedEntities.some(entity => entity.value === item))
      .map(v => ({
        type: 'email',
        value: v,
        label: v
      }));
  }

  /**
   * 
   * @param {string} oldType 
   * @param {string} newType 
   * @returns {boolean} `true` if the new type change is allowed
   */
  function canChangePermission(oldType, newType) {
    if (oldType === 'owner') {
      return false;
    }
    if (oldType === 'editor' && proposedPermission !== 'owner') {
      return false;
    }
    if (oldType === 'viewer' && proposedPermission === 'viewer') {
      return false;
    }
    if (oldType === 'member' && proposedPermission === 'member') {
      return false;
    }
    return true;
  }
  /**
   * 
   * @param {PermissionsType} permissions 
   * @param {string[]} emails 
   * @param {string} email 
   * @returns {boolean} `true` if there is a change
   */
  function addEmail(permissions, emails, email) {
    // Do not add your own email, if you are already present with uid
    if (email === myEmail && permissions['u:' + myUid]) {
      return false;
    }
    if (permissions['e:' + email]
      && !canChangePermission(permissions['e:' + email].type, proposedPermission)) {
      return false;
    } else if (!emails.includes(email)) {
      emails.push(email);
    }
    permissions['e:' + email] = {
      type: proposedPermission
    };
    return true;
  }

  /**
   * 
   * @param {PermissionsType} permissions 
   * @param {string} key 
   * @returns {boolean} `true` if there is a change
   */
  function addOrgTeam(permissions, key) {

    if (permissions[key]
      && permissions[key].type === proposedPermission) {
      return false;
    }
    permissions[key] = {
      type: proposedPermission
    };
    return true;
  }


  async function addUsers() {
    // For group and org, they are part of AUDIT LOGS
    if (type !== 'group' && type !== 'org') {
      logFn({ category: 'Sharing', action: 'Share ' + type });
    }

    let newPermissions = Object.assign({}, permissions);
    /**
       * @type {string[]}
       */
    let usersAdded = [];
    let changeMade = false;

    let newEntities = proposedEntities;
    if (pendingProposedUsers) {
      newEntities = newEntities.concat(convertRawText(pendingProposedUsers));
    }

    usersAdded = [];
    for (let userIndex = 0; userIndex < newEntities.length; userIndex++) {
      const entity = newEntities[userIndex];
      let entityChanged = false;
      switch (entity.type) {
      case 'org':
        entityChanged = addOrgTeam(newPermissions, 'o:' + org.id);
        break;
      case 'team':
        entityChanged = addOrgTeam(newPermissions, 't:' + org.id + '///' + entity.value);
        break;
      case 'email':
      default:
        entityChanged = addEmail(newPermissions, usersAdded, entity.value.toLowerCase());
        break;
      }
      changeMade = changeMade || entityChanged;
    }


    if (validateUsersFn && !validateUsersFn(newEntities)) {
      return;
    }

    let cleanUp = () => {
      setProposedPermission(accesses[accesses.length - 1].name.toLowerCase());
      setProposedEntities([]);
      setPendingProposedUsers('');
      setTeamShare('team_select');
    };

    if (!changeMade) {
      cleanUp();
      return;
    }
    try {
      await onSave(newPermissions);
    } catch (error) {
      if (error === 'cancelled') {
        return;
      }
      toast(`Could not add users. ${(error && error.message) || ''}`, {
        duration: 4000,
        intent: 'danger'
      });
      throw error;
    }
    cleanUp();
    if (usersAdded.length && usersAddedFn) {
      usersAddedFn(usersAdded);
    };
  }

  function accessOptions() {
    let _accesses = accesses.slice();
    _accesses.reverse();
    return _accesses.map(x => {
      let type = x.name.toLowerCase();

      if (type === 'owner' && access !== 'owner') {
        return null;
      }

      return (
        <MenuItem value={type} key={type}>
          {x.label || x.name}
          <Tooltip
            arrow
            title={x.description}
            placement="right"
            sx={{
              fontSize: 20
            }}
          >
            <div style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            }}>
            </div>
          </Tooltip>
        </MenuItem>
      );
    });
  }

  function orgSelection() {
    return teamShare !== 'team_select';
  }

  function userSelection() {
    return proposedEntities.length || pendingProposedUsers;
  }
  function calculateDropdownSize() {
    setDropdownSize(selectRef.current.clientWidth);
  }

  return <div data-testid="add-users-box" style={{ width: '100%' }}>
    {!emailVerified ? <VerificationReminder /> :
      <>
        <T color="textSecondary" variant="caption" style={{ marginTop: 4 }}>{header} by email or team (comma separated)</T>
        {loading && (<CircularProgress size={14} />)}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              flex: 1,
              position: 'relative'
            }}
          >
            <TagInputRaw
              autoFocus={autoFocus}
              placeholder="jane@example.com, john@example.com"
              values={proposedEntities}
              options={options}
              onInputChange={(evt) => setPendingProposedUsers(evt.target.value)}
              inputValue={pendingProposedUsers}
              getOptionLabel={(opt) => opt.label}
              groupBy={(opt) => opt.type === 'email' ? 'People' : 'Team'}
              onRemove={(_val, index) => {
                let users = proposedEntities.slice();
                users.splice(index, 1);
                setProposedEntities(users);
              }}
              onAdd={(values) => {
                let users = proposedEntities.slice();
                setProposedEntities(users.concat(values));
                setPendingProposedUsers('');
              }}
              tagProps={(value) => value.type !== 'email' || validEmail(value.value) ? {} : { color: 'secondary', variant: 'default' }}
              convertRawText={convertRawText}
              sx={{
                '.MuiAutocomplete-inputRoot.MuiInputBase-root': {
                  paddingRight: dropdownSize ? `${dropdownSize + 16}px` : ''
                }
              }}
              ListboxProps={{
                sx: {
                  '& > li:last-of-type': {
                    border: '0'
                  },
                  '& > li': {
                    borderBottom: '1px solid',
                    borderColor: 'grey.300'
                  }
                }
              }}
            />
            <Select
              ref={selectRef}
              variant="standard"
              aria-label="select permission"
              value={proposedPermission}
              onChange={e => {
                setProposedPermission(e.target.value);
                calculateDropdownSize();
              }}
              sx={{
                position: 'absolute',
                right: '8px',
                top: '50%',
                transform: 'translate(0%, -50%)',
                '&:before': {
                  content: 'none'
                }
              }}
            >
              {accessOptions()}
            </Select>
          </div>
          <AsyncButton
            variant="contained"
            color="primary"
            sx={{
              ml: 2
            }}
            onClick={(done) => {
              let errors = proposedUsersErrors();
              if (!errors.length) {
                addUsers().then(done, done);
                return;
              }
              const showError = (errorMsg) => {
                toast(errorMsg, {
                  duration: 8000,
                  intent: 'danger'
                });
                done();
              };
              if (typeof errors === 'string') {
                showError(errors);
                return;
              }

              let msg = `${prettyList(errors.map(x => `"${x}"`))} ${errors.length > 1 ? 'are not valid emails' : 'is not a valid email'}.`;
              if (xSharingDisabled && domainWhitelist && domainWhitelist.length) {
                msg += ' Your Text Blaze administrator has restricted new emails to ones with the domain ' + prettyList(domainWhitelist.map(x => `"${x}"`), false) + '.';
              }
              showError(msg);
            }}
            disabled={!userSelection() && !orgSelection()}
          >{shareButton}</AsyncButton>
        </Box>
      </>
    }
  </div>;
}


const AddUsersBox = React.memo(AddUsersBoxBase);
export default AddUsersBox;



/**
 * 
 * @param {PermissionsType} permissions
 * @param {string} myEmail
 * @param {import("./utilities").Permission[]} currentShares 
 */
async function getEmails(permissions, myEmail, currentShares) {
  let currentEmails = currentShares.filter(x => x.entityType === 'user').map(x => x.entityValue);

  let availableEmails = (await listPermissions(permissions))
    .filter(x => 
      x.entityType === 'user'
      && !currentEmails.includes(x.entityValue)
      && x.entityValue !== myEmail
    )
    .sort((a, b) => {
      if (a.type === 'owner' && b.type !== 'owner') {
        return -1;
      }
      if (a.type !== 'owner' && b.type === 'owner') {
        return 1;
      }
      if (a.type === 'editor' && b.type !== 'editor') {
        return -1;
      }
      if (a.type !== 'editor' && b.type === 'editor') {
        return 1;
      }
      return a.entityValue.localeCompare(b.entityValue);
    }).map(x => ({
      value: x.entityValue,
      label: x.entityValue,
      type: 'email'
    }));
  return availableEmails;
}