import Quill from 'quill';
import { hasSameRowNextInOps } from '../utils';
function getOverrideText(range, length2) {
  try {
    let ops;
    if (typeof range.index !== 'undefined') {
      ops = this.editor.getContents(range.index, range.length);
    } else {
      ops = this.editor.getContents(range, length2);
    }
    const { ops: newOps } = this.convertDeltaToTextDelta({ ops });
    let textItems = [];
    for (let i = 0; i < newOps.length; i++) {
      const op = newOps[i];
      if (typeof op.insert !== 'string') {
        continue;
      }
      let tableCellLine = op.attributes?.tableCellLine;
      if (!tableCellLine) {
        textItems.push(op.insert);
        continue;
      }
      
      
      if (hasSameRowNextInOps(tableCellLine, newOps, i)) {
        textItems.push('\t');
      } else {
        textItems.push('\n');
      }
    }
    return textItems.join('');
  } catch (err) {
    console.error('Failed to use getOverrideText. Switching to original.', err);
    return this.originalGetText(range, length2);
  }
}

export function overrideQuill() {
  // @ts-ignore Custom function. Does not exist on Quill type
  if (!Quill.prototype.originalGetText) {
    // @ts-ignore Custom function. Does not exist on Quill type
    Quill.prototype.originalGetText = Quill.prototype.getText;
  }
  // @ts-ignore Custom function. Does not exist on Quill type
  Quill.prototype.convertDeltaToTextDelta = (delta) => delta;
  Quill.prototype.getText = getOverrideText;
}

