import Quill from 'quill';
import { css, getRelativeRect } from '../utils';
import { TableCell, TableCellLine } from '../formats/formats';

const PRIMARY_COLOR = '#0589f3';
const LINE_POSITIONS = ['left', 'right', 'top', 'bottom'];
const ERROR_LIMIT = 2;
const LINE_WIDTH = '2px';

export default class TableSelection {
  constructor (table, quill, options) {
    if (!table) {
      return null;
    }
    this.table = table;
    this.quill = quill;
    this.options = options;
    this.boundary = {};   // params for selected square
    this.selectedTds = /** @type {TableCell[]}  */ ([]);  // array for selected table-cells
    this.dragging = false;
    this.selectingHandler = this.mouseDownHandler.bind(this);
    this.formatHandler = this.keyDownHandler.bind(this);
    this.copyHandler = this.onCopy.bind(this, false);
    this.cutHandler = this.onCopy.bind(this, true);
    this.pasteHandler = this.onPaste.bind(this);


    this.helpLinesInitial();
    this.quill.root.addEventListener('mousedown',
      this.selectingHandler,
      false);

    document.body.addEventListener('keydown',
      this.formatHandler,
      false);
    document.body.addEventListener('copy', this.copyHandler);
    document.body.addEventListener('cut', this.cutHandler);
    document.body.addEventListener('paste', this.pasteHandler);
  }

  destroyed = false;

  /**
   * @type {HTMLElement}
   */
  left;

  
  /**
   * @type {HTMLElement}
   */
  right;
  
  /**
   * @type {HTMLElement}
   */
  top;
  
  /**
   * @type {HTMLElement}
   */
  bottom;

  helpLinesInitial () {
    let parent = this.quill.root.parentNode;
    LINE_POSITIONS.forEach(direction => {
      this[direction] = document.createElement('div');
      this[direction].classList.add('qlbt-selection-line');
      this[direction].classList.add('qlbt-selection-line-' + direction);
      css(this[direction], {
        position: 'absolute',
        display: 'none',
        'background-color': PRIMARY_COLOR
      });
      parent.appendChild(this[direction]);
    });
  }

  mouseDownHandler (e) {
    const self = this;
    let clearedSelection = false;
    if (!self.table.closest('.ql-editor')) {
      self.destroy();
      return;
    }
    if (e.button !== 0 || !e.target.closest('.quill-better-table')) {
      return;
    }

    function setCellsSelection (start, end) {
      /**
       * @type {import('quill').default}
       */
      const quill = self.quill;
      if (!start || !end) {
        return;
      }
      if (start.closest('table') !== end.closest('table')) {
        return;
      }
      const startTdRect = getRelativeRect(
        start.getBoundingClientRect(),
        quill.root.parentElement
      );
      const endTd = end;
      const endTdRect = getRelativeRect(
        endTd.getBoundingClientRect(),
        quill.root.parentElement
      );

      self.recordScrollPosition();
      self.boundary = computeBoundaryFromRects(startTdRect, endTdRect);
      self.correctBoundary();
      self.selectedTds = self.computeSelectedTds();
      self.repositionHelpLines();

      // set the selection to the start cell so previous selection is lost from other part of editor
      if (startTd !== endTd && !clearedSelection) {
        
        const startTableCell = /** @type {TableCell} */ (Quill.find(startTd));
        const startIndex = quill.getIndex(startTableCell);
        quill.setSelection(startIndex, 0, 'api');
        clearedSelection = true;
      }

      // avoid select text in multiple table-cell
      if (startTd !== endTd) {
        quill.blur();
      }
    }

    const startTd = e.target.closest('td[data-row]');
    if (!startTd) {
      return;
    }

    if (e.shiftKey === true && self.selectedTds.length) {
      setCellsSelection(self.selectedTds[0].domNode, startTd);
      self.refreshToolbar();
      return;
    }


    self.quill.root.addEventListener('mousemove', mouseMoveHandler, false);
    document.addEventListener('mouseup', mouseUpHandler, false);
    window.addEventListener('blur', mouseUpHandler, false);
    const startTdRect = getRelativeRect(
      startTd.getBoundingClientRect(),
      self.quill.root.parentNode
    );
    self.dragging = true;

    self.boundary = computeBoundaryFromRects(startTdRect, startTdRect);
    self.correctBoundary();
    self.selectedTds = self.computeSelectedTds();
    self.recordScrollPosition();
    self.repositionHelpLines();
    function mouseMoveHandler (e) {
      if (e.button !== 0 || !e.target.closest('.quill-better-table')) {
        self.quill.setSelection(
          self.selectedTds.length ? self.quill.getIndex(self.selectedTds[0]) : 0,
          0,
          'SILENT'
        );
        return;
      }
      const endTd = e.target.closest('td[data-row]');
      setCellsSelection(startTd, endTd);
    }

    function mouseUpHandler (e) {
      self.quill.root.removeEventListener('mousemove', mouseMoveHandler, false);
      document.removeEventListener('mouseup', mouseUpHandler, false);
      window.removeEventListener('blur', mouseUpHandler, false);
      self.dragging = false;

      self.refreshToolbar();
    }
  }

  correctBoundary () {
    const tableContainer = /** @type {import('../formats/tableContainer').default} */ (Quill.find(this.table));
    if (!tableContainer) {
      return;
    }
    const tableCells = tableContainer.descendants(TableCell);
    let boundary = this.boundary;
    tableCells.forEach((tableCell, index) => {
      let { x, y, width, height } = getRelativeRect(
        tableCell.domNode.getBoundingClientRect(),
        this.quill.root.parentNode
      );
      let isCellIncluded = (
        x + ERROR_LIMIT >= boundary.x &&
          x - ERROR_LIMIT + width <= boundary.x1
      ) && (
        y + ERROR_LIMIT >= boundary.y &&
          y - ERROR_LIMIT + height <= boundary.y1
      );
      let isCellIntersected = (
        (x + ERROR_LIMIT >= boundary.x && x + ERROR_LIMIT <= boundary.x1) ||
          (x - ERROR_LIMIT + width >= boundary.x && x - ERROR_LIMIT + width <= boundary.x1)
      ) && (
        (y + ERROR_LIMIT >= boundary.y && y + ERROR_LIMIT <= boundary.y1) ||
          (y - ERROR_LIMIT + height >= boundary.y && y - ERROR_LIMIT + height <= boundary.y1)
      );
      if (!isCellIncluded && isCellIntersected) {
        boundary = this.boundary = computeBoundaryFromRects(boundary, { x, y, width, height });
      }
    });
  }

  /**
   * @returns {TableCell[]}
   */
  computeSelectedTds () {
    const tableContainer = /** @type {import('../formats/tableContainer').default} */ (Quill.find(this.table));
    if (!tableContainer) {
      return [];
    }
    const tableCells = tableContainer.descendants(TableCell);

    return tableCells.reduce((selectedCells, tableCell) => {
      let { x, y, width, height } = getRelativeRect(
        tableCell.domNode.getBoundingClientRect(),
        this.quill.root.parentNode
      );
      let isCellIncluded = (
        x + ERROR_LIMIT >= this.boundary.x &&
          x - ERROR_LIMIT + width <= this.boundary.x1
      ) && (
        y + ERROR_LIMIT >= this.boundary.y &&
          y - ERROR_LIMIT + height <= this.boundary.y1
      );

      if (isCellIncluded) {
        selectedCells.push(tableCell);
      }

      return selectedCells;
    }, []);
  }

  repositionHelpLines () {
    const tableViewScrollLeft = this.table.parentNode.scrollLeft - (this.initialLeft || 0);
    css(this.left, {
      display: 'block',
      left: `${this.boundary.x - tableViewScrollLeft - 1}px`,
      top: `${this.boundary.y}px`,
      height: `${this.boundary.height + 1}px`,
      width: LINE_WIDTH
    });

    css(this.right, {
      display: 'block',
      left: `${this.boundary.x1 - tableViewScrollLeft}px`,
      top: `${this.boundary.y}px`,
      height: `${this.boundary.height + 1}px`,
      width: LINE_WIDTH
    });

    css(this.top, {
      display: 'block',
      left: `${this.boundary.x - 1 - tableViewScrollLeft}px`,
      top: `${this.boundary.y}px`,
      width: `${this.boundary.width + 1}px`,
      height: LINE_WIDTH
    });

    css(this.bottom, {
      display: 'block',
      left: `${this.boundary.x - 1 - tableViewScrollLeft}px`,
      top: `${this.boundary.y1 + 1}px`,
      width: `${this.boundary.width + 1}px`,
      height: LINE_WIDTH
    });
  }

  // based on selectedTds compute positions of help lines
  // It is useful when selectedTds are not changed
  refreshHelpLinesPosition () {
    if (!this.selectedTds[0]?.domNode || !this.selectedTds[this.selectedTds.length - 1]
      || !this.table.isConnected) {
      this.clearSelection();
      return;
    }
    const startRect = getRelativeRect(
      this.selectedTds[0].domNode.getBoundingClientRect(),
      this.quill.root.parentNode
    );
    const endRect = getRelativeRect(
      this.selectedTds[this.selectedTds.length - 1].domNode.getBoundingClientRect(),
      this.quill.root.parentNode
    );
    this.recordScrollPosition();
    this.boundary = computeBoundaryFromRects(startRect, endRect);
    this.repositionHelpLines();
  }

  recordScrollPosition () {

    this.initialLeft = this.table.parentNode.scrollLeft;
  }

  destroy () {
    LINE_POSITIONS.forEach(direction => {
      this[direction]?.remove();
      this[direction] = null;
    });
    this.destroyed = true;

    this.quill.root.removeEventListener('mousedown',
      this.selectingHandler,
      false);

    document.body.removeEventListener('keydown',
      this.formatHandler,
      false);
    
    document.body.removeEventListener('copy', this.copyHandler);
    document.body.removeEventListener('cut', this.cutHandler);
    document.body.removeEventListener('paste', this.pasteHandler);
    return null;
  }

  setSelection (startRect, endRect) {
    const quill = this.quill;
    this.boundary = computeBoundaryFromRects(
      getRelativeRect(startRect, quill.root.parentNode),
      getRelativeRect(endRect, quill.root.parentNode)
    );
    this.correctBoundary();
    this.selectedTds = this.computeSelectedTds();
    this.recordScrollPosition();
    this.repositionHelpLines();
    this.refreshToolbar();

  }

  clearSelection () {
    this.boundary = {};
    this.selectedTds = [];
    LINE_POSITIONS.forEach(direction => {
      this[direction] && css(this[direction], {
        display: 'none'
      });
    });
  }

  refreshToolbar () {
    const quill = this.quill,
      selectedTds = this.selectedTds;
    for (let index = 0; index < selectedTds?.length; index++) {
      const td = selectedTds[index];
      const quillIndex = quill.getIndex(td);
      const [line] = quill.getLine(quillIndex);
      if (line.cache?.delta?.ops.length > 1) {
        const toolbar = quill.getModule('toolbar');
        // Sandbox would not have toolbar to update
        toolbar?.update(quillIndex + 1);
        break;
      } 
    }
  }

  keyDownHandler (evt) {
    const quill = this.quill,
      selectedTds = this.selectedTds;
    if (!this.isSelectionActive()) {
      return;
    }

    if (!selectedTds?.length) {
      return;
    }
    if (!evt.metaKey && !evt.ctrlKey) {
      return; 
    }
    const KEY_MAP = {
      'b': 'bold',
      'i': 'italic',
      'u': 'underline'
    };
    const format = KEY_MAP[evt.key];
    if (!format) {
      return;
    }

    const selection = quill.getSelection();
    // Have text selection. Let the quill handle it.
    if (!!selection?.length) {
      return;
    }

    const betterTable = quill.getModule('better-table');
    const quillIndex = quill.getIndex(selectedTds[0]);
    const formats = quill.getFormat(quillIndex);

    betterTable.format(format, !formats[format]);
  }

  /**
   * Selects a cell.
   * @param {TableCell} cell 
   */
  selectCell (cell) {
    const rect = cell.domNode.getBoundingClientRect();
    this.setSelection(
      rect,
      rect
    );
  }

  /**
   * 
   * @param {boolean} isCut 
   * @param {ClipboardEvent} evt 
   */
  onCopy (isCut, evt) {
    const self = this,
      quill = self.quill;
    if (!this.isSelectionActive()) {
      return;
    }
    if (!clipboardChecker(quill, evt)) {
      return;
    };

    const selectionRange = quill.getSelection();

    if (selectionRange?.length > 0) {
      return;
    }

    evt.preventDefault();
    let tableHtml = [],
      tableText = [],
      ranges = [];
    const record = (index, length, isLast) => {
      let html = /** @type {string}  */ (null);
      if (cellsInRange > 1) {
        html = quill.editor.getHTML(index, length);
      } else {
        html = quill.editor.getHTML(index, length + 1);
        // remove other cells from the row
        html = html.substring(0, html.indexOf('</td>') + 5) + html.substring(html.indexOf('</tr>'));
        // remove other rows
        html = html.substring(0, html.indexOf('</tr>') + 5) + html.substring(html.lastIndexOf('</tr>') + 5);
      }
      if (!isLast) {
        html = html.substring(0, html.lastIndexOf('</tbody>'));
      }
      if (tableHtml.length) {
        html = html.substring(html.indexOf('<tbody>') + 7);
      }
      tableHtml.push(html);
      tableText.push(quill.getText({ index, length }));
      cellsInRange = 0;
    };
    let quillIndex = self.selectedTds[0].range(this.quill).index,
      length = 0,
      prev = self.selectedTds[0],
      cellsInRange = 0;
    self.selectedTds.forEach((td, index) => {
      let range;
      if (length && prev.parent !== td.parent && (prev.next || td.prev)) {
        record(quillIndex, length);

        range = td.range(this.quill);
        quillIndex = range.index;
        length = 0;
      }
      if (!range) {
        range = td.range(this.quill);
      }
      length += range.length;
      prev = td;
      cellsInRange++;

      ranges.push(range);
    });
    record(quillIndex, length, true);
    evt.clipboardData.setData('text/plain', tableText.join('\n'));
    evt.clipboardData.setData('text/html', tableHtml.join(''));
    if (isCut) {
      const newTds = [];
      this.selectedTds.forEach((td) => {
        const children = /** @type {import('parchment').LinkedList<TableCellLine>} */ (td.children);
        const formats = children.head.formats();
        const next = td.next;
        const blot = /** @type {TableCellLine} */ (td.parent.scroll.create(TableCellLine.blotName, formats[TableCellLine.blotName]));
        td.remove();
        td.parent.insertBefore(blot, next);
        
        blot.optimize();
        newTds.push(blot.parent);
      });
      this.setSelection(
        newTds[0].domNode.getBoundingClientRect(),
        newTds[newTds.length - 1].domNode.getBoundingClientRect()
      );
    }
  }

  /**
   * 
   * @param {ClipboardEvent} evt 
   */
  onPaste (evt) {
    if (!this.isSelectionActive()) {
      return;
    }
    if (!clipboardChecker(this.quill, evt)) {
      return;
    };
  }

  isSelectionActive () {
    if (!this.table || !document.body.contains(this.table)) {
      this.destroy();
      return false;
    }
    return true;
  }
}

/**
 * 
 * @param {Quill} quill 
 * @param {ClipboardEvent} evt 
 */
function clipboardChecker (quill, evt) {
  return (
    // Not prevented
    !evt.defaultPrevented &&
    // event occurs on body or inside editor.
    // If occured on other parts of elements ignore
    (
      evt.target === document.body ||
      quill.scroll.domNode.contains(/** @type {Node} */ (evt.target))
    )
  );
}

function computeBoundaryFromRects (startRect, endRect) {
  let x = Math.min(
    startRect.x,
    endRect.x,
    startRect.x + startRect.width - 1,
    endRect.x + endRect.width - 1
  );

  let x1 = Math.max(
    startRect.x,
    endRect.x,
    startRect.x + startRect.width - 1,
    endRect.x + endRect.width - 1
  );

  let y = Math.min(
    startRect.y,
    endRect.y,
    startRect.y + startRect.height - 1,
    endRect.y + endRect.height - 1
  );

  let y1 = Math.max(
    startRect.y,
    endRect.y,
    startRect.y + startRect.height - 1,
    endRect.y + endRect.height - 1
  );

  let width = x1 - x;
  let height = y1 - y;

  return {
    x: x,
    x1: x1,
    y,
    y1,
    width,
    height
  };
}