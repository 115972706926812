import React, { useEffect, useRef, useState } from 'react';
import { getStartWhitespace, removeStartWhitespace } from './syntax_highlighters';
import { ast } from '../../snippet_processor/Equation';
import { lex, lexSQLWithCommands, pushBlockTokens, pushRepeatTokens } from '../../snippet_processor/Lexer';
import { Environment } from '../../snippet_processor/DataContainer';
import EditorToolbar from './EditorToolbar';
import { astSQL } from '../../snippet_processor/SQL';
import AttributeQuillEditor from '../SnippetEditor/AttributeQuillEditor';


/**
 * @param {object} props 
 * @param {string} props.value 
 * @param {boolean=} props.readonly 
 * @param {function} props.onChange
 * @param {string} [props.placeholder]
 * @param {object} props.context
 * @param {boolean=} props.assignSignShown
 * @param {boolean} [props.isIterator] - whether it is a {repeat} iterator (e.g. could be 'for x in [a,b]' or '3')
 * @param {boolean} [props.isBlock] - whether it is a {run} block
 * @param {'bsql'|'formula'} [props.type]
 * @param {string} props.attributeName
 */
export default function FormulaEditor(props) {
  let [error, setError] = useState(/** @type {{position: number, message: string, value: string }} */ (null));
  let myMasterValue = useRef(props.value);

  const isSQL = props.type === 'bsql';

  useEffect(() => {
    // Check if new content (not managed here) has been passed
    // down.
    if (props.value.trimStart() !== myMasterValue.current.trimStart()) {
      myMasterValue.current = props.value;
      setError(null);
    }
  }, [props.value]);

  let handleValueChange = code => {
    let lexer = isSQL ? () => lexSQLWithCommands(code, new Environment(null, { stage: 'preview' })) : () => lex(code, new Environment(null, { stage: 'preview' }), { lexComment: props.isBlock });
    let { tokens, termination } = lexer();
    let invalidToken = tokens.find(t => t.type === 'INVALID');
    if (invalidToken) {
      setError({
        position: invalidToken.position,
        message: 'invalid character',
        value: code
      });
      return;
    } else if (termination !== 'END_STRING') {  
      // can happen if you have a random semi-colon in the formula
      setError({
        position: termination.length,
        message: 'invalid character',
        value: code
      });
      return;
    }

    try {
      if (props.isBlock) {
        pushBlockTokens(tokens);
      }
      if (props.isIterator) {
        // make the formula valid if it's an iterator
        pushRepeatTokens(tokens);
      }
      if (isSQL) {
        astSQL(code, new Environment());
      } else {
        ast(tokens, new Environment(), { startRule: props.isBlock ? 'Block' : undefined });
      }
    } catch (err) {
      setError({
        position: err.errorPosition,
        message: err.message,
        value: code
      });
      return;
    }
    
    setError(null);
    let masterValue = getStartWhitespace(props.value) + removeStartWhitespace(code);
    myMasterValue.current = masterValue;
    props.onChange(masterValue);
  };
  let hasError = error !== null;

  return <>
    <AttributeQuillEditor
      value={hasError ? error.value : props.value}
      disabled={props.readonly}
      onChange={handleValueChange}
      attributeType={isSQL ? 'sql' : (props.isIterator ? 'iterator' : (props.isBlock ? 'block' : 'formula'))}
      attributeName={props.attributeName}
      placeholder={props.placeholder}
      style={{
        marginTop: 10,
        minHeight: 'calc(1.5em + 16px)'
      }}
      error={hasError}
    />
    {(!props.readonly) && <EditorToolbar
      type={props.type}
      errorLabel={isSQL ? 'Invalid BSQL' : 'Invalid formula'}
      error={hasError ? error.message : null}
      context={props.context}
    />}
  </>;
}
