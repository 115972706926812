import { doSafeTableRequest } from '../../hooks/useTables';

const POLL_ITERATION_TIMEOUT = 500;

/**
 *
 * @param {string} jobId
 * @param {(job: any) => void=} progressUpdateFn
 * @param {number=} timeout
 * @return {Promise<any>}
 */
export async function pollJob(jobId, progressUpdateFn = undefined, timeout = 30 * 60 * 1000) {
  let iteration = 0;

  const pollIteration = (resolve, reject) => async() => {
    const jobResponse = await doSafeTableRequest(`jobs/${jobId}/`, 'GET');
    if (jobResponse.error) {
      reject(new Error(jobResponse.detail));
    } else if (jobResponse.state === 'finished') {
      resolve(jobResponse);
    } else if (jobResponse.state === 'failed') {
      reject(new Error(jobResponse.human_readable_error));
    } else if (++iteration * POLL_ITERATION_TIMEOUT >= timeout) {
      reject(new Error('Timeout while performing the operation!'));
    } else {
      if (progressUpdateFn) {
        progressUpdateFn(jobResponse);
      }
      setTimeout(pollIteration(resolve, reject), POLL_ITERATION_TIMEOUT);
    }
  };

  return new Promise((resolve, reject) => {
    pollIteration(resolve, reject)();
  });
}