import React, { useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { exportString } from '../../import_export/DeltaExport';
import TagInput from '../TagInput/TagInput';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import T from '@mui/material/Typography';
import AsyncButton from '../AsyncButton/AsyncButton';
import DoneIcon from '@mui/icons-material/Check';
import { validEmail } from '../../standalone_utilities';
import { toast } from '../../message';
import { log } from '../../logging/logging';
import CopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { publishSnippet } from '../../bapi';
import IconButton from '@mui/material/IconButton';


/**
 * @param {object} props
 * 
 * @param {string} props.snippetId
 * @param {string} props.groupId
 * @param {function():void=} props.onClose
 * @param {function():object} props.getDelta
 */
export default function PublishDialog(props) {
  const selectRef = useRef();
  const [description, setDescription] = useState('');
  const [proposedUsers, setProposedUsers] = useState([]);
  const [pendingProposedUsers, setPendingProposedUsers] = useState('');
  const [listed, setListed] = useState(false);
  const [{ url, username }, setResult] = useState({ url: undefined, username: undefined });

  const proposedUsersErrors = () => {
    let proposed = proposedUsers;
    if (pendingProposedUsers) {
      proposed = proposed.concat(pendingProposedUsers.split(','));
    }
    let users = Array.from(new Set(proposed.map(x => x.trim())));
    let errors = [];
    users.forEach(email => {
      if (!validEmail(email)) {
        errors.push(email);
      }
    });
    return {
      errors,
      emails: proposed
    };
  };

  const handleClose = () => {
    props.onClose();
  };

  const publish = async (done) => {
    let { errors, emails } = proposedUsersErrors();
    if (errors.length) {
      toast(`${errors.join(', ')} ${errors.length > 1 ? 'are not valid emails' : 'is not a valid email'}.`, {
        duration: 4000,
        intent: 'danger'
      });
      done();
    } else {
      try {
        let res = await publishSnippet({
          snippetId: props.snippetId,
          html: exportString(props.getDelta(), 'html').data
            .replace(/<p[^>]*?><br\/><\/p>/g, '<br/>')
            .replace(/<p[^>]*?>/g, '').replace(/<\/p>/g, '<br/>')
            /** Discourse treats "\<" as escaping the "<" */
            .replace(/\\/g, '&#92;')
            /* We construct the regex object as Firefox doesn't recognize the 's' flag and will fail on the script parsing */
            .replace(new RegExp('<br/>$', 's'), ''),
          description: description,
          unlisted: !listed,
          emails
        });

        setResult({
          url: res.data.url,
          username: res.data.username
        });

        log({ action: 'SNIPPET_PUBLISHED' }, {
          snippet_id: props.snippetId,
          group_id: props.groupId
        });
      } catch (err) {
        toast('Could not publish snippet. ' + err.message, {
          duration: 4000,
          intent: 'danger'
        });
        log({ action: 'FAILED_SNIPPET_PUBLISHED' }, {
          snippet_id: props.snippetId,
          group_id: props.groupId
        });
      }

      done();
    }
  };

  return <Dialog
    open
    onClose={handleClose}
    maxWidth="sm"
  >
    {url ? <>
      <DialogTitle><DoneIcon style={{ verticalAlign: 'middle' }} /> Published a snippet copy</DialogTitle>
      <DialogContent>
        <T color="textSecondary" gutterBottom>
          Your snippet has been copied and published. {proposedUsers.length ? 'We have sent ' + (proposedUsers.length > 1 ? '' : 'a') + 'notification email' + (proposedUsers.length > 1 ? 's' : '') + ' to ' + proposedUsers.join(', ') + '.' : null} You may email {(proposedUsers.length > 1 ? 'other' : '')} people this copy by sending them the following link:
        </T>

        <div style={{
          border: 'solid 1px #ccc',
          backgroundColor: '#fafafa',
          marginBottom: 16,
          marginTop: 16,
          borderRadius: 6,
          padding: 12,
          display: 'flex',
          alignItems: 'center'
        }}>
         
          <T style={{ flex: 1 }}><a href={url} target="_blank" rel="noopener noreferrer" ref={selectRef}>{url}</a></T>
          <div style={{
            marginLeft: 8
          }}><Tooltip title="Copy URL">
              <IconButton

                onClick={async () => {
                  let range = new Range();
                  range.selectNodeContents(selectRef.current);
                  document.getSelection().removeAllRanges();
                  document.getSelection().addRange(range);
                  await navigator.clipboard.writeText(url);

                  toast('Copied URL to your clipboard.');
                }}
              ><CopyIcon/>
              </IconButton></Tooltip>
          </div>
        </div>

        <T color="textSecondary">
          You may edit or delete this copy at the same link. You may view a list of all your published copies in the <a href={`https://community.blaze.today/u/${username}/activity/topics`} target="_blank" rel="noopener noreferrer">Text Blaze Community</a>.
        </T>
      </DialogContent>
      <DialogActions style={{ padding: 16 }}><div style={{ flex: 1 }} />
        <Button
          onClick={() => {
            handleClose();
          }}
        >Done</Button>
      </DialogActions>
    </> : <>
      <DialogTitle>Publish and share a snippet copy</DialogTitle>
      <DialogContent>
        <T color="textSecondary" variant="body2">
            Publish a copy of the snippet. A copy of the snippet will be made and published to the <a href="https://community.blaze.today/c/snippets" target="_blank" rel="noopener noreferrer">Text Blaze Community</a>. You can edit or delete the published copy at any time.
        </T>

        <TextField
          label="Description"
          multiline
          style={{
            width: '100%'
          }}
          rows={2}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          margin="normal"
          helperText="An optional description for the published snippet"
          variant="outlined"
        />

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={listed}
                onChange={() => setListed(!listed)}
                value="checkedB"
                color="primary"
              />
            }
            label="Publicly listed"
          />
        </FormGroup>

        <T color="textSecondary" variant="caption" paragraph>
            Publicly listed copies will be searchable in the <a href="https://community.blaze.today/c/snippets" target="_blank" rel="noopener noreferrer">Text Blaze Community</a> and indexed by search engines like Google. Unlisted copies are not searchable in the forums or published to search engines. You <u>should not publish sensitive content</u> even if it is unlisted as the URL could be guessed.
        </T>

        <T gutterBottom variant="subtitle1">
            Email notifications
        </T>

        <div style={{ display: 'flex', width: '100%' }}>
          <TagInput
            placeholder="jane@example.com, john@example.com"
            values={proposedUsers}
            onInputChange={(evt) => setPendingProposedUsers(evt.target.value)}
            inputValue={pendingProposedUsers}
            onRemove={(_val, index) => {
              let users = proposedUsers.slice();
              users.splice(index, 1);
              setProposedUsers(users);
            }}
            onAdd={(values) => {
              let users = proposedUsers.slice();
              setProposedUsers(users.concat(values));
              setPendingProposedUsers('');
            }}
            tagProps={(value) => validEmail(value) ? {} : { color: 'secondary', variant: 'default' }}
            startIcon={<AccountCircle />}
          />
        </div>

        <T color="textSecondary" variant="caption" style={{ marginTop: 8 }}>
            Optional. Emails will be sent to addresses listed. After publishing, you will be given a link you can share directly at any time.
        </T>

      </DialogContent>
      <DialogActions style={{ padding: 16 }}><div style={{ flex: 1 }} />
        <Button
          onClick={() => {
            handleClose();
          }}
          style={{ marginRight: 12 }}
        >Cancel</Button>
        <AsyncButton
          variant="contained"
          color="primary"
          onClick={(done) => publish(done)}
        >Publish</AsyncButton>
      </DialogActions>
    </>}
  </Dialog>;
}