import React, { Suspense } from 'react';
import DetailsCard from '../DetailsCard/DetailsCard';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import UsageIcon from '@mui/icons-material/ShowChart';
import AccountPanel from './AccountPanel';
import OptionsPanel from './OptionsPanel';
import LoadingProgressIndicator from '../Utilities/LoadingProgressIndicator';
import { eagerlyLoadImport } from '../../utilities';
const UsagePanel = React.lazy(eagerlyLoadImport(() => import('./UsagePanel')));


function ConfigureBase() {
  const { push: navigate } = useHistory();
  const { pathname } = useLocation();
  
  let path = pathname.split('/');
  let tab = path[path.length - 1];

  return <DetailsCard style={{ padding: 0 }} hideBackground>
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
      <div style={{ overflow: 'hidden', paddingBottom: 14 }}>
        <AppBar position="static" color="default" style={{ zIndex: 10 }} elevation={1}>
          <Tabs
            value={tab || 'options'}
            onChange={(_e, v) => {
              navigate('/configure/' + v);
            }}
            variant="fullWidth"
            centered
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              value="options"
              label={<span><SettingsIcon style={{ verticalAlign: 'middle' }}/>&nbsp;&nbsp;Options</span>}
            />
            <Tab
              value="usage"
              label={<span><UsageIcon style={{ verticalAlign: 'middle' }}/>&nbsp;&nbsp;Usage</span>}
            />
            <Tab
              value="account"
              label={<span><AccountCircleIcon style={{ verticalAlign: 'middle' }}/>&nbsp;&nbsp;Account</span>}
            />
          </Tabs>
    
        </AppBar>
      </div>

      <div style={{
        height: '100%',
        paddingTop: 10,
        padding: 32,
        position: 'relative',
        overflow: 'auto'
      }}>
        <Switch>
          <Route exact path="/configure/options" component={OptionsPanel}/>
          <Route exact path="/configure/usage" component={() => <Suspense fallback={<LoadingProgressIndicator />}>
            <UsagePanel />
          </Suspense>}/>
          <Route exact path="/configure/account" component={AccountPanel}/>
          <Redirect from="/configure" to="/configure/options"/>
        </Switch>
      </div>
    </div>
  </DetailsCard>;
}


const Configure = React.memo(ConfigureBase);
export default Configure;