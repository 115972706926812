import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/HelpOutline';


/**
 * @param {object} props
 * @param {any} props.children 
 * @param {boolean=} props.large 
 * @param {object=} props.style 
 */
export default function InfoChicklet(props) {
  let title = props.children;
  if (props.large) {
    title = <div style={{ padding: 8 }}>{title}</div>;
  }
  
  return <Tooltip
    title={title}
    placement="top"
    arrow
  >
    <HelpIcon
      fontSize="small"
      style={Object.assign({
        marginLeft: 8,
        color: '#777',
        verticalAlign: 'text-bottom'
      }, props.style)}
    />
  </Tooltip>;
}