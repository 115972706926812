import React, { useEffect, useState } from 'react';
import { doSafeTableRequest } from '../../hooks/useTables';
import { Badge, IconButton, SwipeableDrawer, Tooltip } from '@mui/material';
import CommentIcon from '@mui/icons-material/ChatOutlined';
import { isCommentsEnabled, isCommunityDocsOrBundle } from '../../flags';
import CommentsSidebar from './CommentsSidebar';
import { useTypedSelector } from '../../hooks';
import { useHistory, useLocation } from 'react-router-dom';


/**
 * @param {object} props
 * @param {string} props.entityId
 * @param {'snippet'|'page'} props.entityType
 */
function CommentsButton(props) {
  const location = useLocation();
  const history = useHistory();
  const commentsEnabled = useTypedSelector((store) =>  isCommentsEnabled(store.userState));
  const showComments = location.hash === '#comments';
  const [commentsCount, setCommentsCount] = useState(0);
  const [sidebarLoaded, setSidebarLoaded] = useState(showComments);

  useEffect(() => {
    if (commentsEnabled && !isCommunityDocsOrBundle()) {
      // when the user navigates to a different entity, initially set the comments count to 0, so that
      // it does not show an invalid count on UI before getting the new count
      setCommentsCount(0);
      if (sidebarLoaded) {
        // the sidebar, including the iframe, is loaded by either hovering the button, or directly opening the #comments URL
        // in that case, Data Blaze is updating us with the new counts via iframe messaging, no need to call
        // the endpoint when it's already loaded
        return;
      }

      // now we don't have the iframe loaded yet, retrieve the new count by calling the endpoint
      (async() => {
        try {
          const response = await doSafeTableRequest(`database/comments/entity/${props.entityType}/${props.entityId}/count/`, 'GET');
          if (typeof response === 'number') {
            setCommentsCount(response);
          } else {
            // response is an error object
            setCommentsCount(0);
          }
        } catch (error) {
          setCommentsCount(0);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsEnabled, props.entityId]);

  if (!commentsEnabled) {
    return null;
  }

  function setShowComments(value) {
    history.push({ hash: value ? 'comments' : '' });
  }

  return <>
    <Badge
      badgeContent={commentsCount}
      color="primary"
      sx={{
        '& .MuiBadge-badge': {
          top: 8,
          right: 4,
        }
      }}
      invisible={commentsCount === 0}
    >
      <Tooltip title="Comments">
        <IconButton
          color={showComments ? 'primary' : undefined}
          sx={{
            marginLeft: 1
          }}
          onClick={() => {
            setShowComments(!showComments);
          }}
          onMouseEnter={() => setSidebarLoaded(true)}
          size="large"
        >
          <CommentIcon />
        </IconButton>
      </Tooltip>
    </Badge>
    <SwipeableDrawer
      anchor="right"
      open={showComments}
      onClose={() => setShowComments(false)}
      onOpen={() => setSidebarLoaded(true)}
      PaperProps={{
        sx: {
          width: 300,
        },
      }}
      ModalProps={{
        keepMounted: true,
      }}
      SwipeAreaProps={{
        width: 0,
      }}
    >
      {sidebarLoaded && <CommentsSidebar
        entityType={props.entityType}
        entityId={props.entityId}
        closeSidebarFn={() => setShowComments(false)}
        onCommentsCountChanged={(newCount) => setCommentsCount(newCount)}
      />}
    </SwipeableDrawer>
  </>;
}


export default CommentsButton;