import React, { useState, useRef, useCallback } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { itemStyleFn, innerDivStyle, SNIPPET_HEIGHT } from './shared';
import BlockedChip from './BlockedChip';
import { Draggable } from 'react-beautiful-dnd';
import { sync } from '../../Sync/syncer';
import { isElectronApp, memberRestricted } from '../../flags';
import Shortcut from '../Shortcut/Shortcut';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DuplicateIcon from '@mui/icons-material/FileCopy';
import LinkIcon from '@mui/icons-material/Link';
import MoveIcon from '@mui/icons-material/MoveToInbox';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import { duplicateSnippet, deleteSnippet, updateSnippetData } from '../../data';
import { copyLinkToClipboardWithToast, showPrompt, showTrashConfirmation } from '../../message';
import MoveSnippet from '../GroupSelectors/MoveSnippet';
import { log } from '../../logging/logging';
import { useTypedSelector, useTypedSelectorShallowEquals } from '../../hooks';
import { createSelector } from 'reselect';
import ModifiedBadge from './SnippetModifiedBadge';
import T from '@mui/material/Typography';
import ConflictShortcutTooltip from './ConflictShortcutTooltip';
import { useViewportItem } from './viewport_utils';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, ClickAwayListener } from '@mui/material';
import { useHistory } from 'react-router-dom';
import AIIcon from '@mui/icons-material/AutoFixHigh';
import { isAiBlaze } from '../../aiBlaze';

let selector = createSelector([(store, _) => store.userState, (store, _) => store.dataState, (store, _) => store.orgState, (_store, snippetId) => snippetId], (userState, dataState, orgState, snippetId) => {
  let snippet = sync.getSnippetById(snippetId);

  return {
    groupCount: Object.keys(dataState.groups || {}).length,
    loaded: !!snippet,
    snippetName: snippet ? snippet.data.name : null,
    isAI: snippet ? snippet.data.options?.is_ai : false,
    groupId: snippet ? snippet.data.group_id : null,
    snippetShortcut: snippet ? snippet.data.shortcut : null,
    // @ts-ignore
    enableCreate: !memberRestricted({ userState, orgState }, 'create')
  };
});


/**
 * @param {object} props
 * @param {string} props.snippetId
 * @param {number} props.depth
 * @param {boolean} props.active
 * @param {object} props.violation
 * @param {number} props.index
 * @param {boolean=} props.fixed
 * @param {string} props.dragCategory
 * @param {boolean=} props.handlerMode
 * @param {React.CSSProperties=} props.style
 */
function SnippetItemBase(props) {
  let [contextClick, setContextClick] = useState(null);
  let [showMoveSnippet, setShowMoveSnippet] = useState(null);
  const { push: navigate } = useHistory();

  let moveMenuRef = useRef(null);

  let {
    groupCount,
    loaded,
    snippetName,
    groupId,
    snippetShortcut,
    enableCreate,
    isAI
  } = useTypedSelectorShallowEquals(store => {
    // @ts-ignore
    return selector(store, props.snippetId);
  });

  let viewportItem = useViewportItem([loaded]);

  let selected = useTypedSelector(store => store.uiState.selected === props.snippetId);

  const contextClickFn = useCallback(event => {
    event.preventDefault();
    navigate('/snippet/' + props.snippetId);
    setContextClick({
      x: event.clientX - 2,
      y: event.clientY - 4
    });

    // eslint-disable-next-line
  }, [props.snippetId]);


  function logFn(data) {
    log(data, {
      snippet_id: props.snippetId,
      group_id: groupId
    });
  }

  const contextClose = useCallback(() => {
    setContextClick(null);
  }, []);
  

  function renderContextMenu() {
    if (!contextClick) {
      return null;
    }
    let iconStyle = {
      opacity: 0.5,
      marginRight: 10
    };

    let disabled = props.fixed;

    return <ClickAwayListener onClickAway={contextClose} mouseEvent="onMouseDown">
      <Box>
        <Menu
          open
          onClose={contextClose}
          onContextMenu={(evt) => {
            evt.preventDefault();
            contextClose();
          }}
          sx={{
            pointerEvents: 'none'
          }}
          PaperProps={{
            sx: {
              pointerEvents: 'auto'
            }
          }}
          keepMounted
          disableAutoFocusItem
          transitionDuration={0}
          anchorReference="anchorPosition"
          anchorPosition={{ top: contextClick.y, left: contextClick.x }}
        >
          <MenuItem
            disabled={(!enableCreate) || disabled}
            onClick={async () => {
              contextClose();
              try {
                let created = await duplicateSnippet(sync.getSnippetById(props.snippetId));
                if (created) {
                  logFn({ action: 'Duplicated snippet', label: { source: 'context_menu' } });
                  navigate('/snippet/' + created.snippet_id);
                }
              } catch (err) {
              //not regular errors
                if (!['MAX_SNIPPETS_EXCEEDED'].includes(err)) {
                  throw err;
                }
              }
            }}><DuplicateIcon style={iconStyle} /> Duplicate snippet</MenuItem>
          <MenuItem
            disabled={disabled || groupCount < 2}
            ref={moveMenuRef}
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
            onClick={() => {
              setShowMoveSnippet(true);
            }}>
            <span style={{ display: 'flex' }}><MoveIcon style={iconStyle} /> Move to folder</span>
            <ArrowRightIcon style={{ marginLeft: 10, opacity: .5 }} />
          </MenuItem>
          <Divider />
          <MenuItem
            disabled={disabled}
            onClick={() => {
              contextClose();
              showPrompt({
                contents: <T>Label (describes the {isAiBlaze ? 'prompt' : 'snippet'})</T>,
                default: snippetName,
                onConfirm: (newName) => updateSnippetData(props.snippetId, { name: newName }),
                confirmButtonText: 'Rename'
              });
            }}><EditIcon style={iconStyle} /> Rename…</MenuItem>
          {isElectronApp() && <MenuItem onClick={() => {
            contextClose();
            copyLinkToClipboardWithToast('https://dashboard.blaze.today/snippet/' + props.snippetId, 'snippet');
          }}><LinkIcon style={iconStyle} />Share</MenuItem>}
          <Divider />    
          <MenuItem
            disabled={disabled}
            onClick={() => {
              contextClose();
              showTrashConfirmation({
                item: <span>the snippet <b>{snippetName}</b></span>,
                onDelete: async () => {
                  await deleteSnippet(props.snippetId, groupId, navigate);
                }
              });
            }}><DeleteIcon style={iconStyle} /> Delete snippet...</MenuItem>
        </Menu>
        {
          showMoveSnippet && <MoveSnippet 
            snippetId={props.snippetId}
            currentGroupId={groupId}
            target={moveMenuRef}
            done={(newGroupId) => {
              setShowMoveSnippet(false);
              contextClose();
            }}
          />
        }
      </Box>
    </ClickAwayListener>;
  }

  let style = itemStyleFn({
    selected: selected,
    depth: props.depth
  });

  function renderBody() {
    return <>
      {props.handlerMode ? null : renderContextMenu()}<ListItem
        onContextMenu={contextClickFn}
        dense
        button
        disableGutters
        disableRipple
        disabled={!loaded}
        data-testid="snippet-item"
        onClick={() => navigate('/snippet/' + props.snippetId)}
        style={style}
        className="list-selectable"
      >
        <ModifiedBadge selected={selected} snippetId={props.snippetId} groupId={groupId}/>
        <ListItemText style={{ paddingRight: 5, margin: 0, paddingLeft: 12 }} primary={loaded ?
          <div style={Object.assign({
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            opacity: (props.active && !props.violation) ? 1 : 0.7
          }, innerDivStyle(false))}>
            {(isAI && !isAiBlaze) ? <AIIcon fontSize="small" sx={{
              opacity: .7,
              marginRight: 1,
            }} /> : null}
            <div style={{
              flex: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              // a color means it's active and we want to have a slight bold
              fontWeight: style.color ? 500 : undefined
            }}>{snippetName || '...'}</div>
            <div style={{ whiteSpace: 'nowrap', maxWidth: '75%' }}>
              {
                props.violation ? (<BlockedChip error={props.violation.error} upgrade={props.violation.upgrade} />) : null
              }
              <SnippetShortcut
                shortcut={snippetShortcut}
                snippetId={props.snippetId}
                active={props.active}
                style={style}
                handlerMode={props.handlerMode}
              />
            </div>
          </div> : 'Loading...'}/>
      </ListItem>
    </>;
  }

  function renderInner() {
    return <Draggable
      draggableId={props.snippetId + '__/__' + props.dragCategory}
      index={props.index}
      isDragDisabled={props.fixed}    
    >
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={provided.draggableProps.style}
          tabIndex={-1}
        >
          {renderBody()}
        </div>
      )}</Draggable>;
  }

  if (props.handlerMode) {
    return renderBody();
  }

  return <div
    ref={viewportItem.ref}
    style={{
      position: 'absolute',
      left: 0,
      right: 0,
      top: props.index * SNIPPET_HEIGHT
    }}
    className={'list-item-' + props.snippetId} /* the list-item is for scrolling to */
  >
    {(viewportItem.appeared || selected) ? renderInner() : (
      <div style={style}>
        <div style={{
          margin: '5px 15px 5px 12px',
          backgroundColor: '#f9f9f9',
          borderRadius: 5,
          height: 14
        }}>
        </div>
      </div>
    )}
  </div>;
}



const SnippetItem = React.memo(SnippetItemBase);
export default SnippetItem;


/**
 * @param {object} props
 * @param {string} props.shortcut
 * @param {boolean} props.active
 * @param {string} props.snippetId
 * @param {boolean=} props.handlerMode
 * @param {React.CSSProperties=} props.style
 */
function SnippetShortcut(props) {

  let hasConflict = useTypedSelector(store => (
    props.active && props.shortcut && store.uiState.conflicts && store.uiState.conflicts.has(props.shortcut.toLocaleLowerCase())
  ));

  function renderBody() {
    return <span style={{
      display: 'inline-block',
      maxWidth: '100%'
    }}>
      <Shortcut
        shortcut={props.shortcut}
        hasConflict={hasConflict}
        notActive={!props.active}
        style={Object.assign({
          marginLeft: 4,
          cursor: 'pointer'
        }, {
          color: props.style.color,
          // using a slightly lighter color for the border looks better
          borderColor:  props.style.color && '#009faf'
        })}
      />
    </span>;
  }

  if (hasConflict && !props.handlerMode) {
    return <ConflictShortcutTooltip
      label="Conflicts with"
      snippetId={props.snippetId}
      placement="bottom-end"
      delay={800}
    >
      {renderBody()}
    </ConflictShortcutTooltip>;
  }

  return renderBody();
}
