import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { AttributeEditor } from '../../AttributeEditors/AttributeEditors';
import FormulaEditor from '../../FormulaEditor/FormulaEditor';
import AdvancedIcon from '@mui/icons-material/OfflineBoltOutlined';
import RemoveIcon from '@mui/icons-material/Close';
import { escapedToIntermediate, getCommandsFromAttribute, intermediateToNormal, normalToEscaped } from '../../../snippet_processor/ParserUtils';
import BufferedEditableText from '../../BufferedEditableText/BufferedEditableText';
import SnippetAttributeEditor from '../../FormulaEditor/SnippetAttributeEditor';
import SimpleSQLEditor from '../../SimpleSQLEditor/LazySimpleSQLEditor';
import { astSimpleSQL } from '../../../snippet_processor/SimpleSQL';
import { setEmbeddedAttribute } from './embedded_utilities';
import { updateGroupPermissions } from '../../../Sync/syncer';
import { getDateTimeConfig } from '../../../snippet_processor/Commands';
import { escapeBackticks, isSimpleName } from '../editor_utilities';
import { showConfirm } from '../../../message';
import Button from '@mui/material/Button';
import T from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HeaderInformation from './HeaderInformation';
import { MenuAutoConditionals } from './MenuAutoConditionals';

/**
 * @callback onChangeCallback
 * @param {import("../editor_utilities").CollapsedDataType} data
 * @param {object=} selectColumnUpdate
 * @param {boolean=} ignoreSidebar // useful when updating a snippet without needing the sidebar to update too
 */

/**
 * 
 * @typedef {Object} Attribute
 * @property {string} label
 * @property {number} priority
 * @property {boolean} used
 * @property {object} [spec]
 * @property {string} spec.type
 * @property {boolean} spec.isLegacyMenu
 * @property {('normal'|'unselectable')=} spec.list_type - used for with isLegacyMenu. `normal` - displays radio/checkbox. `unselectable` - does not display an option to select the item.
 * @property {boolean} spec.required
 * @property {string} spec.description
 * @property {ListType} spec.list
 * @property {string=} spec.assigned_name
 * @property {boolean=} spec.static
 * @property {boolean=} positional
 * @property {boolean=} fillIn
 * @property {string=} id
 * @property {(string|number|object|Array)=} value
 * @property {(string|number|object|Array)=} raw
 * @property {string=} tooltip
 * @property {boolean=} readonly
 */

/**
 * @param {object} props 
 * @param {string} props.commandType
 * @param {boolean=} props.readonly
 * @param {Attribute} props.attribute
 * @param {number} props.index
 * @param {onChangeCallback} props.onChange
 * @param {{ activeTypes: object }} props.equationContext 
 * @param {import("../editor_utilities").CollapsedDataType} props.data 
 * @param {{override: (string | import("../../../snippet_processor/ParseNode").InfoType)}} props.errorOverride 
 * @param {import("../editor_utilities").CollapsedDataType['value']['attributes']} props.values 
 * @param {string} props.groupId
 * @param {Function=} props.onVariableNameChange
 * @param {((name: string) =>  Promise<number>)=} props.getVariableCountInSnippet
 * @param {(hasError: boolean) => any} props.onError
 * @param {Function=} props.insert
 */
export default function EmbeddedAttributeSection(props) {
  let [manualAdvanced, setManualAdvanced] = useState(false);
  let [showCalcEditor, setShowCalcEditor] = useState(false);
  let [hasError, setHasErrorCount] = useState(0);
  let [attributeEditorOriginalValue, setAttributeEditorOriginalValue] = useState(null);
  let [isVariableRepeated, setIsVariableRepeated] = useState(false);
  let [debouncedTimer, setDebouncedTimer] = useState(null);

  let keyIndexing = props.index;
  let attr = props.attribute;
  /** @type {UserDefinedPropertyType & {isLegacyMenu?: boolean, assigned_name?: string}} */
  let spec = attr.spec;

  let equationContext = Object.assign({
    setHasError: (isError) => {
      if (isError) {
        props.onError(true);
        setHasErrorCount(e => e + 1);
      } else {
        setHasErrorCount(e => {
          const newValue = Math.max(0, e - 1);
          props.onError(newValue > 0);
          return newValue;
        });
      }
    }
  }, props.equationContext);

  const isEquationLike = ['lambda', 'equation', 'bsql'].includes(/** @type {string} */(spec.type));

  function debouncedCalculateIfVariableIsRepeated() {
    if (!props.getVariableCountInSnippet) {
      return;
    }

    clearTimeout(debouncedTimer);
     
    const timer = setTimeout(async () => {
      const isVariablePresentMultipleTimes = await props.getVariableCountInSnippet(attributeEditorOriginalValue) > 0;

      setIsVariableRepeated(isVariablePresentMultipleTimes);
    }, 300);
    
    setDebouncedTimer(timer);
  }

  // use data = null to delete
  /**
   * 
   * @param {string} name 
   * @param {{ value?: string, raw?: string}} data 
   * @param {{ positional: Boolean, fillIn: Boolean }} extraFields
   * @param {object=} nameUpdateObj
   */
  function setAttribute(name, data, extraFields = {
    positional: false,
    fillIn: false
  }, nameUpdateObj) {
    setAttributes([{ name, data }], extraFields, nameUpdateObj);
  }

  // use data = null to delete
  /**
   * extraFields is common for all attributes
   * right now. If needed, it can be extracted
   * into the same array. Right now, it is not 
   * needed
   * 
   * @param {{ name: string, data: { value?: string, raw?: string} }[]} attributes
   * @param {{ positional: Boolean, fillIn: Boolean }} extraFields
   * @param {object=} nameUpdateObj
   * @param {boolean=} ignoreSidebar
   */
  function setAttributes(attributes, extraFields = {
    positional: false,
    fillIn: false
  }, nameUpdateObj, ignoreSidebar = false) {
    let newData = props.data;
    const {
      commandType,
      values,
      groupId,
      attribute
    } = props;

    for (const attributeDiff of attributes) { 
      const { name, data } = attributeDiff;

      if (commandType.startsWith('remotedb') && ['query', 'space'].includes(attribute.label)) {

        if (attribute.label === 'space') {
          let query = values.find(x => x.name === 'query');
          updateGroupPermissions(groupId, data.value, query.raw);
        } else {
          let databaseId = values.find(x => x.name === 'space');
          if (databaseId) {
          // we won't have a groupId when we're in draft mode and haven't been added to a group yet
            if (groupId) {
              updateGroupPermissions(groupId, databaseId.value, data.raw);
            }
          }
        }
      }
      let value, raw;
      if (data !== null) {
        value = data.value;
        raw = data.raw;
        if (!hasValue(value) && !hasValue(raw)) {
          throw Error('Must set at least one of value/raw');
        }
        if (!hasValue(raw)) {
          if (attribute.spec.list) {
          // list will already be escaped
            raw = value;
          } else {
            raw = normalToEscaped(value, {
              escapeCommands: true,
              escapeEquals: false,
              escapeSlashes: true,
              escapeWhitespace: 'both'
            });
          }
        }
        if (!hasValue(value)) {
          value = raw;
        }
      }

      if (name === 'name') {
        const oldName = values?.find((value) => value.name === 'name');
        if (oldName === undefined || oldName.value !== value) {
          nameUpdateObj = {
            oldName: oldName && oldName.value,
            newName: value
          };
        }
      } else if (name === 'multiple' && value !== 'yes') {
        const defaultAttributes = newData.value.attributes.filter(a => a.name === 'default');
        const isValues = newData.value.attributes.some(a => a.name === 'values');
        if (defaultAttributes.length > 1 && !isValues) {
          for (let index = 1; index < defaultAttributes.length; index++) {
            const defaultAttribute = defaultAttributes[index];
            defaultAttribute.name = null;
            defaultAttribute.positional = true;
          }
        }
      }
      
  
      newData = setEmbeddedAttribute(
        name,
        newData,
        data ? {
          raw,
          value
        } : null,
        extraFields,
        props.errorOverride
      );
    }

    props.onChange(newData, nameUpdateObj, ignoreSidebar);
  }

  function replaceAttributes(attributes) {
    let newData = Object.assign({}, props.data);
    newData.value = Object.assign({}, newData.value);
    newData.meta = Object.assign({}, newData.meta);
    if (props.errorOverride) {
      newData.meta.error = props.errorOverride.override;
    }
    newData.value.attributes = attributes.slice();
    props.onChange(newData);
  }

  let sqlStructure = undefined, usableSpaceId = '';
  if (spec.type === 'bsql') {
    const spaceIdField = props.values.find(value => value.name === 'space');
    usableSpaceId = spaceIdField.value;
    // TODO: check for valid space id here
    if (usableSpaceId) {
      try {
        // @ts-ignore string type of commandType
        sqlStructure = astSimpleSQL(attr.raw, props.commandType);
      } catch (e) {
        sqlStructure = undefined;
        if (!manualAdvanced) {
          setManualAdvanced(_ => true);
        }
      }
    }
  }

  if (!spec.config) {
    spec.config = {};
  }
  if (spec.type === 'datetime') {
    const format = props.values.find(x => x.name === 'format');
    spec.config.datetime = getDateTimeConfig(format.value);
    const { date, time } = spec.config.datetime;
    if (date && time) {
      spec.description = (spec.description || '').replace(/(\s)(time|date|datetime)(\s)/g, ' datetime ');
    } else if (time) {
      spec.description = (spec.description || '').replace(/(\s)(time|date|datetime)(\s)/g, ' time ');
    } else {
      spec.description = (spec.description || '').replace(/(\s)(time|date|datetime)(\s)/g, ' date ');
    }
  }
  spec.config.isMultiple = props.values.find(x => x.name === 'multiple')?.value === 'yes';

  let isNaturalAdvanced = !spec.static && (spec.type !== 'lambda' && spec.type !== 'equation' && spec.type !== 'bsql') && getCommandsFromAttribute(attr.raw || '').length > 0;

  let isAdvanced = isNaturalAdvanced || manualAdvanced;

  let isList = spec.list;

  const getFormulaAttrEditor = () => {
    return <>
      {(showCalcEditor || spec.assigned_name) && <div style={{ fontSize: '16px', marginTop: 10 }} >
        <BufferedEditableText
          disabled={props.readonly}
          placeholder="Name"
          selectAllOnFocus
          minWidth={18}
          value={spec.assigned_name || ''}
          onChange={async (name) => {
            name = name.trim();

            let value = name;
            let raw;

            if (name) {
              if (!isSimpleName(name)) {
                raw = '`' + escapeBackticks(value) + '`';
              } else {
                raw = value;
              }

              const isVariablePresentMultipleTimes = props.getVariableCountInSnippet
                ? await props.getVariableCountInSnippet(spec.assigned_name) > 1
                : false;

              const setName = () =>
                setAttribute('name', {
                  value,
                  raw
                }, {
                  positional: false,
                  fillIn: true
                });

              if (isVariablePresentMultipleTimes) {
                showConfirm({
                  cancelButtonText: 'Rename selected',
                  confirmButtonText: 'Rename all',
                  contents: 'This variable is used multiple times. Rename all of them?',
                  // If we rename right away, the "replace all" can't find this variable and that complicates
                  // the logic when dealing with variables inside repeaters as it can't find if it's a unique declaration
                  onCancel: () => {
                    setName();
                  },
                  onConfirm: () => {
                    if (props.onVariableNameChange) {
                      props.onVariableNameChange(spec.assigned_name, raw);
                    }
                  }
                });
              } else {
                setName();
              }
            } else {
              setAttribute('name', null);
            }

            setShowCalcEditor(false);
          }}
        />
        <span style={{ marginLeft: 6 }}>=</span>
      </div>}
      <div style={{ display: 'flex', alignItems: 'top', maxWidth: '100%' }}>
        {!(spec.assigned_name || showCalcEditor) && <Tooltip title="Assign to variable">
          <span
            style={{ fontSize: '24px', paddingRight: 4, paddingTop: 13, cursor: 'pointer' }}
            onClick={() => setShowCalcEditor(true)}
            data-testid="assign-to-variable-button"
          >
            =
          </span>
        </Tooltip>}
        <div style={{ flex: 1, maxWidth: (showCalcEditor || spec.assigned_name) ? '100%' : 'calc(100% - 16px)' }}>
          <FormulaEditor
            readonly={props.readonly}
            value={attr.raw || ''}
            onChange={(value) => {
              setAttribute('formula', {
                raw: value
              }, {
                fillIn: false,
                positional: true
              });
            }}
            assignSignShown={!(spec.assigned_name || showCalcEditor)}
            placeholder={spec.placeholder === undefined ? spec.default : spec.placeholder}
            context={equationContext}
            attributeName="formula"
          />
        </div>
      </div>
    </>;
  };

  const getAttributeEditor = () => {
    const keyWithValue = isEquationLike ? 'raw' : 'value';

    if (attributeEditorOriginalValue === null && attr[keyWithValue]) {
      setAttributeEditorOriginalValue(attr[keyWithValue]);
    }
    

    return ((isAdvanced && !isList && spec.type !== 'bsql') ?
      <div style={{ marginTop: 14 }}>
        <SnippetAttributeEditor
          readonly={props.readonly}
          value={attr.raw || ''}
          onChange={(newValue) => {
            //This is only advanced, when not equation or lambda.
            //We need to clean the value like CollapsedCommand does.
            setAttribute(attr.label, {
              raw: newValue,
              value: intermediateToNormal(escapedToIntermediate(newValue))
            }, {
              positional: attr.positional || false,
              fillIn: false
            });
          }}
          placeholder={spec.placeholder === undefined ? spec.default : spec.placeholder}
          context={equationContext}
          isConstant={spec.constant}
          isEmbedded
          attributeName={spec.name}
        />
      </div>
      : <>
        <AttributeEditor
          readonly={props.readonly}
          suggestionBar={false}
          noDescription
          attribute={spec}
          value={attr[keyWithValue]}
          rawText
          isAdvanced={isAdvanced}
          autoFocus={props.index === 0}
          onChange={(newValue) => {
          /** @type {string} */
            let selValue = newValue;
            /** @type {Parameters<setAttributes>[0]} */
            let attributes = [];

            if (attr.label === 'selector' && typeof newValue === 'object') {
              selValue = newValue.selector;
              if (newValue.pageMatched !== undefined) {
              // Avoid setting in case of empty string
              // which implies user is fine with matching any page
                if (newValue.pageMatched) {
                  attributes.push({
                    name: 'page', data: {
                      value: newValue.pageMatched
                    }
                  });
                  attributes.push({
                    name: 'select', data: {
                      value: 'ifneeded'
                    }
                  });
                }
              }
            }
            if (attr.label !== 'selector') {
              setAttribute(attr.label, {
                [keyWithValue]: selValue
              }, {
                positional: attr.positional || false,
                fillIn: false
              });


              // once we know it's repeated, no need to calculate it again
              // only static values can be replaced
              if (!isVariableRepeated && attr.spec.static === true) {
                debouncedCalculateIfVariableIsRepeated();
              }
            } else {
              attributes.push({
                name: 'selector', data: {
                  value: selValue
                }
              });
              setAttributes(attributes, { positional: false, fillIn: false });
            }
          }}
          context={equationContext}
        />

        <RenameAllVariables
          spec={spec}
          onVariableNameChange={props.onVariableNameChange}
          attribute={attr}
          commandType={props.commandType}
          originalName={attributeEditorOriginalValue}
          isVariableRepeated={isVariableRepeated}
        />
      </>);
  };
  /**
   * Should have been only used for formmenu
   */
  const getLegacyAttributeEditor = () => {
    return <AttributeEditor
      readonly={props.readonly}
      suggestionBar={false}
      noDescription
      attribute={spec}
      value={attr.value}
      rawText
      isAdvanced={isAdvanced}
      autoFocus={props.index === 0}
      onChange={(newItems) => {
        let attrs = newItems.map(item => {
          let res = {
            name: null,
            value: intermediateToNormal(escapedToIntermediate(item.value.trimStart())),
            raw: item.value.trimStart(),
            positional: true,
            fillIn: false,
          };

          if (item.selected) {
            res.name = 'default';
            res.positional = false;
          }

          return res;
        });

        replaceAttributes(attrs.concat(props.values.filter(v => v.name !== 'options' && v.name !== 'default')));
      }}
      context={equationContext}
    />;
  };

  const getSimpleSQLEditor = () => {
    const passAttr = Object.assign({ type: 'bsql' }, attr);

    return <SimpleSQLEditor
      readonly={props.readonly}
      attribute={passAttr}
      onChange={(newValue, selectColumnUpdate) => {
        setAttribute(attr.label, {
          raw: newValue
        }, {
          positional: attr.positional || false,
          fillIn: false
        }, selectColumnUpdate);
      }}
      value={sqlStructure}
      databaseId={usableSpaceId}
      context={equationContext}
      alwaysShowTableLoader
    />;
  };

  const advancedEditorComponent = (
    spec.type !== 'lambda'
    && spec.type !== 'equation'
    && !spec.static
    && (spec.type !== 'bsql' || sqlStructure))
    && <Tooltip title="Advanced editor">
      <span>
        <IconButton
          data-testid="advanced-editor-toggle"
          size="small"
          onClick={() => {
            setManualAdvanced((currentValue) => !currentValue);
            setHasErrorCount(0);
          }}
          disabled={isNaturalAdvanced}
          style={{
            opacity: isAdvanced ? .8 : .25,
            marginLeft: 6,
            color: isAdvanced ? 'goldenrod' : undefined
          }}
        >
          <AdvancedIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>;

  const removeAttributeComponent = attr.used && (!spec.required) && <Tooltip title="Remove setting">
    <IconButton
      disabled={props.readonly}
      data-testid="remove-attribute-button"
      size="small"
      onClick={() => {
        if (spec.isLegacyMenu) {
          replaceAttributes(props.values.filter(v => v.name !== 'options' && v.name !== 'default'));
        } else {
          setAttribute(attr.label, null);
        }
      }}
    >
      <RemoveIcon fontSize="small" />
    </IconButton>
  </Tooltip>;

  function getMenuConditionals() {
    // tree shake this out of the docs bundle
    if (import.meta.env.VITE_APP_BUILD_TYPE === 'docs') {
      return null;
    }

    if (spec.isLegacyMenu) {
      return <MenuAutoConditionals
        values={props.values}
        insert={props.insert}
        setAttributes={setAttributes}
        options={attr.value.map(x => x.value)}
        isAdvanced={isAdvanced}
      />;
    }

    return null;
  }

  return (
    <div
      key={keyIndexing}
      className={'embedded-attribute-editor' + (attr.used ? ' used' : '') + (hasError ? ' embedded-attribute-editor-error' : '')}
      id={attr.id}
    >
      <HeaderInformation
        description={spec.description}
        label={attr.label}
        isUsed={attr.used}
        hasError={hasError}
        advancedEditorComponent={advancedEditorComponent}
        removeAttributeComponent={removeAttributeComponent}
        menuAutoConditionalsComponent={getMenuConditionals()}
      />
      {
        spec.isLegacyMenu ?
          getLegacyAttributeEditor() :
          (props.commandType === 'calc' && attr.label === 'formula' ?
            getFormulaAttrEditor() :
            ((sqlStructure && !isAdvanced) ?
              getSimpleSQLEditor() :
              getAttributeEditor()))
      }
    </div>
  );
};


/**
 * 
 * @param {string} property 
 */
function hasValue(property) {
  return (property !== undefined && property !== null);
}

/**
 * @param {object} props
 * @param {string} props.commandType
 * @param {string|null} props.originalName
 * @param {Attribute} props.attribute
 * @param {UserDefinedPropertyType} props.spec
 * @param {Function=} props.onVariableNameChange
 * @param {boolean} props.isVariableRepeated
 */
function RenameAllVariables(props) {
  // the original variable has changed, check if there's any other
  const isFormName = props.commandType.startsWith('form') && props.spec.name === 'name';

  if (!isFormName && props.spec.type !== 'identifier') {
    return null;
  }

  if (props.originalName === props.attribute.value || !props.isVariableRepeated) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 100px',
        alignItems: 'center',
        gap: '12px',
      }}
    >
      <T fontSize="12px">
          This variable is used multiple times.
        <b> Rename all of them?</b>
      </T>

      <Button
        variant="outlined"
        size="small"
        onClick={() =>{
          if (props.onVariableNameChange) {
            props.onVariableNameChange(escapeBackticks(props.originalName), escapeBackticks(props.attribute.value));
          }
        }}
      >
          Rename all
      </Button>
    </Box>
  );
}