import { getApp } from 'firebase/app';
import { collection, doc, getFirestore } from 'firebase/firestore';
import { getApiPath_Base, getTBFunctions_Base } from './firebase_utilities_base';

/**
 * @typedef {{
 *   (): import('firebase/firestore').Firestore
 *   (collectionName: string): import('firebase/firestore').CollectionReference,
 *   (collectionName: string, documentId: any): import('firebase/firestore').DocumentReference,
 *   (collectionName: string, documentId: any, nestedCollectionName: string): import('firebase/firestore').CollectionReference,
 *   (collectionName: string, documentId: any, nestedCollectionName: string, nestedDocumentId: any): import('firebase/firestore').DocumentReference,
 *   (
 *     ref: import('firebase/firestore').DocumentReference | import('firebase/firestore').CollectionReference,
 *     collectionName: string,
 *     documentId: any
 *   ): import('firebase/firestore').DocumentReference,
 * }} MakeRef
 */

/**
 * @type {MakeRef}
 */
export const makeRef = function() {
  let args = Array.prototype.slice.call(arguments);
  let ref = typeof args[0] === 'string' || args.length === 0
    ? getFirestore()
    : args.shift();

  while (args.length > 0) {
    let collectionName = args.shift();
    ref = collection(ref, collectionName);
    if (args.length > 0) {
      let documentId = args.shift();
      ref = doc(ref, documentId);
    }
  }

  return ref;
};

/**
 * @param {import('firebase/app').FirebaseApp} app 
 */
export const getApiPath = (app = null) => {
  if (!app) {
    app = getApp();
  }
  return getApiPath_Base(app);
};


/**
 * @param {import('firebase/app').FirebaseApp} app 
 */
export const getTBFunctions = (app = null) => {
  if (!app) {
    app = getApp();
  }
  return getTBFunctions_Base(app);
};