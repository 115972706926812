import React from 'react';
import { checkOrg } from '../../flags';
import Chip from '@mui/material/Chip';
import { NotProIcon } from '../Version/Assets';
import { useTypedSelector } from '../../hooks';
import { notProBackground } from '../Version/version_utilities';


const ZOOM = 0.6;
const PADDING = 3;


/**
 * @param {object} props
 * @param {string} props.error
 * @param {string=} props.upgrade
 */
function BlockedChipBase(props) {
  let isOrg = useTypedSelector(store => checkOrg(store));

  return <div
    style={{
      zoom: ZOOM,
      display: 'inline-block',
      verticalAlign: 'middle',
      paddingRight: PADDING,
      marginLeft: 4
    }}
    title={props.error}
  > <Chip
      style={{
        backgroundColor: notProBackground
      }}
      icon={<NotProIcon/>}
      label={(props.upgrade && !isOrg) ? 'UPGRADE' : 'LOCKED'}
    />
  </div>;
}


const BlockedChip = React.memo(BlockedChipBase);
export default BlockedChip;
