import { firebaseUser, firebaseEmail } from '@store';
import { getState } from './getState';
import { getData } from './components/DataDialog/DataDialog';
import { getAuth, signInWithPopup, GoogleAuthProvider, EmailAuthProvider, reauthenticateWithCredential, signInWithCustomToken } from 'firebase/auth';
import { isSamlUser, loginSamlUser } from './components/Auth/saml_auth';

// Should not include in build
if (import.meta.env.NODE_ENV !== 'production') {
  window['xgetAuth'] = getAuth;
  window['xsignInWithCustomToken'] = signInWithCustomToken;
}

/**
 * The permission the current user has given a permissions object.
 * 
 * @param {OrgObjectType|GroupObjectType} obj - A Group or an Org
 * 
 * @return {'owner'|'editor'|'viewer'=}
 */
export function userPermission(obj) {
  let permissions = obj.permissions;

  if (!permissions) {
    return;
  }

  let state = getState();
  let grants = [];

  if (('associated_org_id' in obj) && state.orgState) {
    if (state.userState.org && state.userState.org.id === obj.associated_org_id) {
      if (!obj.id) {
        console.error('Group id not specified in perms: ' + (new Error().stack));
      }

      // Org owners are owners of the associated groups
      if (state.userState.org.type === 'owner') {
        return 'owner';
        // Org editors are editors of the associated groups
      } else if (state.userState.org.type === 'editor') {
        grants.push('editor');
      } else if (state.orgState.org
        && state.orgState.org.default_groups
        && state.orgState.org.default_groups.includes(obj.id)) {
        grants.push('viewer');
      }
    }
    if (obj.associated_team_ids && state.orgState.teams) {
      for (let team of obj.associated_team_ids) {
        if (state.orgState.teams[team]) {
          grants.push('viewer');
        }
      }
    }
  }

  let grant = permissions['u:' + state.userState.uid];
  if (grant) {
    grants.push(grant.type);
  }
  if (state.userState.emailVerified) {
    grant = permissions['e:' + state.userState.email];
    if (grant) {
      grants.push(grant.type);
    }
  }
  if (state.orgState && state.orgState.org) {
    grant = permissions['o:' + state.orgState.org.id];
    if (grant) {
      grants.push(grant.type);
    }
  }
  if (state.orgState && state.orgState.org && state.orgState.teams) {
    for (let teamId in state.orgState.teams) {
      grant = permissions['t:' + state.orgState.org.id + '///' + teamId];
      if (grant) {
        grants.push(grant.type);
      }
    }
  }
  if (grants.includes('owner')) {
    return 'owner';
  }
  if (grants.includes('editor')) {
    return 'editor';
  }
  if (grants.includes('viewer') || grants.includes('member')) {
    return 'viewer';
  }
}


/**
 * Reauthenticate the current user.
 * 
 * @return {Promise}
 */
export async function reauthenticate() {
  let user = firebaseUser();
  let isSaml = await isSamlUser(user.email);
  let isPassword = !isSaml && !user.providerData.find(x => x.providerId !== 'password');
  return new Promise((resolve, reject) => {
    if (isPassword) {
      getData({
        title: 'Re-Authenticate to delete',
        applyButton: 'Delete account',
        info: 'In order to delete your account, enter your password to confirm it is you.',
        fields: [{
          name: 'Password',
          id: 'password',
          type: 'password',
          required: true
        }]
      }).then((data) => {
        finalizeReAuth(EmailAuthProvider.credential(
          firebaseEmail(),
          data[0].value || ''
        ));
      });
    } else if (isSaml) {
      loginSamlUser(user.email).then((token) => {
        return signInWithCustomToken(getAuth(), token);
      }).then(userCredential => {
        if (user.uid === userCredential.user.uid) {
          resolve();
        } else {
          reject('Not same user');
        }
      }).catch((err) => reject(err.message));
    } else {
      let provider = new GoogleAuthProvider();
      signInWithPopup(getAuth(), provider).then((result) => {
        finalizeReAuth(GoogleAuthProvider.credentialFromResult(result));
      }).catch(err => reject(err));
    }
    /**
     * 
     * @param {import("firebase/auth").AuthCredential} credentials 
     * @returns 
     */
    function finalizeReAuth(credentials) {
      return reauthenticateWithCredential(user, credentials).then(resolve).catch(err => reject(err.message));
    }
  });
}