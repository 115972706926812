import React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import AddonInstalledChip from './AddonInstalledChip';
import T from '@mui/material/Typography';



/**
 * @param {object} props 
 * @param {string[]} props.installedUserAddons
 * @param {string[]} props.installedOrgAddons
 * @param {function} props.onSelect
 * @param {GroupObjectType} props.group
 * @param {AddonObjectInnerType} props.addon
 */
export default function AddonCard(props) {
  return (
    <Card sx={{
      width: {
        xs: 'calc(100% - 40px)',
        sm: 345
      },
      height: 335,
      display: 'inline-block',
      m: '20px',
      textAlign: 'left'
    }}>
      <CardActionArea
        onClick={() => props.onSelect(props.addon)}
      >
        <div style={{ height: 140 }}>
          <CardMedia
            component="img"
            style={{
              objectFit: 'cover',
              maxHeight: 140
            }}
            image={props.addon.data.banner_image_url || props.addon.data.image_url}
          />
        </div>
        <CardContent>
          <T gutterBottom variant="h5">
            {props.addon.data.name} <AddonInstalledChip
              size="small"
              group={props.group}
              installedUserAddons={props.installedUserAddons}
              installedOrgAddons={props.installedOrgAddons}
            />
          </T>
          <T gutterBottom color="textSecondary">
            Published by <a href={'mailto:' + props.addon.data.author_email} target="_blank" rel="noopener noreferrer">{props.addon.data.author_name}</a>
          </T>
          <T style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            height: 48
          }}>
            {props.addon.data.description}
          </T>
        </CardContent>
      </CardActionArea>
      <CardActions style={{ textAlign: 'right' }}>
        <Button
          onClick={() => props.onSelect(props.addon)}
          size="small"
        >
          Learn more
        </Button>
      </CardActions>
    </Card>
  );
}
