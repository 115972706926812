import { CELL_ATTRIBUTES, CELL_DEFAULT, CELL_IDENTITY_KEYS, CELL_STYLES } from './constants';
import { cellId, rowId } from './util';
import Block from 'quill/blots/block';

class TableCellLine extends Block {
  static create(value) {
    const node = super.create(value);

    CELL_IDENTITY_KEYS.forEach(key => {
      let identityMaker = key === 'row'
        ? rowId : cellId;
      node.setAttribute(`data-${key}`, value[key] || identityMaker());
    });

    CELL_ATTRIBUTES.forEach(attrName => {
      node.setAttribute(`data-${attrName}`, value[attrName] || CELL_DEFAULT[attrName]);
    });

    CELL_STYLES.forEach(attrName => {
      if (!value[attrName]) {
        return;
      }
      node.setAttribute(`data-${attrName.toLowerCase()}`, value[attrName]);
    });

    return node;
  }

  static formats(domNode) {
    const formats = {};

    CELL_ATTRIBUTES.reduce((formats, attribute) => {
      if (!domNode.hasAttribute(`data-${attribute}`)) {
        return formats;
      }
      formats[attribute] = domNode.getAttribute(`data-${attribute}`) || '';
      if (['rowspan', 'colspan'].includes(attribute)) {
        formats[attribute] = parseInt(formats[attribute], 10) || undefined;
      }
      return formats;
    }, formats);

    CELL_IDENTITY_KEYS.reduce((formats, attribute) => {
      if (domNode.hasAttribute(`data-${attribute}`)) {
        formats[attribute] = domNode.getAttribute(`data-${attribute}`) || undefined;
      }
      return formats;
    }, formats);


    return CELL_STYLES.reduce((formats, attribute) => {
      if (domNode.hasAttribute(`data-${attribute.toLowerCase()}`)) {
        formats[attribute] = domNode.getAttribute(`data-${attribute.toLowerCase()}`) || undefined;
      }
      return formats;
    }, formats);
  }

  constructor (scroll, domNode) {/* eslint-disable no-unused-expressions */
    super(scroll, domNode);

    /**
     * Override the types of the properties.
     */
    
    /**
     * @type {import('./tableCell').default}
     */
    this.parent;

    /**
     * @type {this}
     */
    this.next;


    /**
     * @type {this}
     */
    this.prev;
  }

  format(name, value) {
    if (CELL_ATTRIBUTES.concat(CELL_IDENTITY_KEYS).indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(`data-${name}`, value);
      } else {
        this.domNode.removeAttribute(`data-${name}`);
      }
    } else if (name.startsWith('border')) {
      if (value) {
        this.domNode.setAttribute('data-' + name.toLowerCase(), value);
      } else {
        this.domNode.removeAttribute('data-' + name.toLowerCase());
      }
    // if not border
    } else if (CELL_STYLES.includes(name)) {
      if (value) {
        this.domNode.setAttribute(`data-${name}`, value);
      } else {
        this.domNode.removeAttribute(`data-${name}`);
      }
    } else if (name === 'header') {
      if (!value) {
        return;
      }
      const { row, cell, rowspan, colspan } = TableCellLine.formats(this.domNode);
      super.format(name, {
        value,
        row,
        cell,
        rowspan,
        colspan
      });
    } else {
      super.format(name, value);
    }
  }

  optimize(context) {
    // cover shadowBlot's wrap call, pass params parentBlot initialize
    // needed
    const formats = TableCellLine.formats(this.domNode);
    let parentFormats;
    if (this.statics.requiredContainer &&
      !(this.parent instanceof this.statics.requiredContainer)
    ) {
      const properties = {
        ...formats
      };
      for (let index = 0; index < CELL_STYLES.length; index++) {
        const attrName = CELL_STYLES[index];

        const attr = this.domNode.getAttribute('data-' + attrName.toLowerCase());
        properties[attrName] = attr;
      }
      this.wrap(this.statics.requiredContainer.blotName, properties);
    } else {
      parentFormats = this.parent.formats();
      if (formats.row !== parentFormats.row) {
        this.parent.format('row', formats.row);
      }
    }
    super.optimize(context);
  }

  tableCell() {
    return this.parent;
  }
}
TableCellLine.blotName = 'tableCellLine';
TableCellLine.className = 'qlbt-cell-line';
TableCellLine.tagName = 'P';

export default TableCellLine;