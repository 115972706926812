import React from 'react';
import { COMMANDS } from '../../snippet_processor/Commands';
import { strictEquals } from '../../snippet_processor/Equation';
import NodeEvaluator from './NodeEvaluator';
import FieldRenderer from './FieldRenderer';
import NodeRenderer from './NodeRenderer';
import RemoteRenderer from './RemoteRenderer';
import { PREVIEW_ICONS } from './shared';
import { ShowError, Chicklet, OuterInstantError } from './SharedComponents';
import './form.css';
import './fields.css';
import { Environment } from '../../snippet_processor/DataContainer';
import ChickletRenderer from './ChickletRenderer';
import SQLRenderer, { TableIcon } from './SQLRenderer';
import ButtonRenderer from './ButtonRenderer';
import { olItem } from '../../snippet_processor/SnippetProcessor';
import RunRenderer from './RunRenderer';

/**
 * 
 * @param {any} localData 
 * @param {Environment} env 
 * @returns 
 */
function localEnv(localData, env) {
  if (localData) {
    let data = env.config.store[localData];
    let newEnv = new Environment(data, env.config, env.locations);
    if (!newEnv.locations.includes('local_data - ' + localData)) {
      newEnv = newEnv.derivedLocation('local_data - ' + localData);
    }
    return newEnv;
  } else {
    return env;
  }
}



/**
 * @param {import("../../snippet_processor/ParseNode").default} dom
 * 
 * @return {string}
 */
function getLocationForDom(dom) {
  return dom.getLocalDataChain().join(' <c> ') + ' <p> ' +  dom.position();
}


/**
 * @param {object} props
 * @param {import("../../snippet_processor/ParseNode").default} props.dom
 * @param {Environment} props.env
 * @param {{ listDepth: number }} [props.state]
 * 
 * @return {any}
 */
function SnippetRendererBase(props) {
  let dom = props.dom;

  if (dom.dependencies) {
    // Note, we set this here instead of in the React.memo as the React.memo
    // comparison function isn't called on (re)creation.
    //
    // This is less than ideal as getDependenciesData() is called twice with the same data
    // when a change occurs. It should be a minor impact though.
    let location = getLocationForDom(dom);
    props.env.config.state.CACHED_DEP_DATA.set(location, getDependenciesData(dom.dependencies, props.env));
  }
  
  let env = localEnv(dom.localData, props.env);

  let el;

  if (typeof dom === 'string') {
    el = <span>{dom}</span>;
  } else if (dom.type === 'root') {
    el = dom.children.map(node => {
      return <SnippetRenderer
        key={getLocationForDom(node)}
        dom={node}
        env={env}
      />;
    });
  } else if (dom.type === 'error') {
    if (dom.info.node && dom.info.node.type === 'expand' && dom.info.node.tag === 'calc') {
      el = <NodeRenderer node={dom.info.node} env={env} />;
    } else if (dom.info.chicklet) {
      el = Chicklet(<span>
        {PREVIEW_ICONS['addon']} <span className="label">{dom.info.chicklet}</span>
      </span>, {
        title: dom.info.message
      });
    } else {
      el = <ShowError msg={dom.info.message} blocking={dom.info.blocking} nodeOrAddonId={dom} />;
    }
  } else if (dom.type === 'el') {
    /** @type {any} */
    let attr = Object.assign({}, dom.info);

    if (!dom.find(item => item.tag !== 'span' && item.tag !== 'button')) { 
      // if it's a button the button will handle it's own styling
      // and not to have a text background highlight
      delete attr.style;
    }
    if (dom.tag === 'img') {
      el = React.createElement(dom.tag, attr);
    } else {
      let children = [];
      const state = props.state ? { ...props.state } : { listDepth: 0 };
      if (dom.tag === 'ol' || dom.tag === 'ul') {
        state.listDepth++;
      }
      if (dom.children && dom.children.length) {
        children = dom.children.map(node => {
          return <SnippetRenderer
            key={getLocationForDom(node)}
            dom={node}
            env={env}
            state={state}
          />;
        });
      } else if (dom.tag === 'td') {
        children = [<React.Fragment key={getLocationForDom(dom) + '-empty-space'}>&nbsp;<br /></React.Fragment>];
      } else if (!dom.children) {
        children = null;
      }
      if (dom.tag === 'tr' && dom.children.every(node => node.display.isInteractive)) {
        attr.className = 'status-interactive';
      }
      if (dom.tag === 'ol') {
        const listStyleType = olItem(state.listDepth - 1, 0).css;
        if (listStyleType) {
          let currentStyles = attr.style;
          if (typeof currentStyles !== 'string') {
            attr.style = { ...currentStyles, listStyleType };
          }
        }
      }

      if (dom.tag === 'a') {
        // We want links in the preview to open in a new window.
        attr.target = '_blank';
        attr.rel = 'noopener noreferrer';
      }
      
      let link = React.createElement(dom.tag, attr, children);
      if (dom.display.isDynamicLink && !['PAGE', 'EXTENSION', 'DESKTOP'].includes(props.env.config.application)) {
        // we need to get the appropriate wrapper style for the link preview
        let finalStyle;
        if (dom.children.length) {
          let lastChild = dom.children[dom.children.length - 1];
          if (lastChild.info && lastChild.info.style) {
            finalStyle = lastChild.info.style;
          } 
        }

        el = <>
          {link}
          <span style={finalStyle}>
            <span style={{
              opacity: .8,
              fontSize: 'max(.8em, 9px)',
              border: 'solid 1px #ddd',
              padding: '1px 3px',
              margin: '0px 0px 0px 3px',
              backgroundColor: 'white',
              color: 'black',
              borderRadius: 5
            }}>
              <span style={{ width: '.9em', height: '.9em', marginRight: '.35em', position: 'relative', top: '.1em', display: 'inline-block', opacity: .7 }}>
                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.6,0,334.4.17A14.4,14.4,0,0,0,320,14.57V47.88a14.4,14.4,0,0,0,14.69,14.4l73.63-2.72,2.06,2.06L131.52,340.49a12,12,0,0,0,0,17l23,23a12,12,0,0,0,17,0L450.38,101.62l2.06,2.06-2.72,73.63A14.4,14.4,0,0,0,464.12,192h33.31a14.4,14.4,0,0,0,14.4-14.4L512,14.4A14.4,14.4,0,0,0,497.6,0ZM432,288H416a16,16,0,0,0-16,16V458a6,6,0,0,1-6,6H54a6,6,0,0,1-6-6V118a6,6,0,0,1,6-6H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V304A16,16,0,0,0,432,288Z"></path></svg>
              </span>{attr.href}
            </span>
          </span>
        </>;
      } else {
        el = link;
      }
    }
  } else if (dom.type === 'expand') {
    let command = COMMANDS[dom.tag.toUpperCase()];
    if (!command && dom.info.command) {
      command = COMMANDS[dom.info.command.toUpperCase()];
    }
    
    if (dom.tag === 'form' || dom.tag === 'note' || dom.tag === 'action') {
      switch (dom.info.type) {
      case 'toggle_end':
        return null;
      case 'repeat_start':
        return null;
      case 'repeat_end':
        return null;
      case 'if_start':
        return null;
      case 'if_elseif':
        return null;
      case 'if_else':
        return null;
      case 'if_end':
        return null;
      case 'action_start':
        el = Chicklet(<span>
          <span style={{
            opacity: .6
          }}>{PREVIEW_ICONS.action}</span> <span className="label">ACTION</span>
        </span>, {
          title: 'Action Start'
        });
        break;
      case 'action_end':
        el = Chicklet(<span>
          <span style={{
            opacity: .6
          }}>{PREVIEW_ICONS.action}</span> <span className="label">END ACTION</span>
        </span>, {
          title: 'Action End'
        });
        break;
      case 'note_start':
        return null;
      case 'note_end':
        return null;
      default:
        el = <FieldRenderer
          node={dom}
          env={env}
          id={'field_renderer_' + dom.info.formInfo.name}
        />;
      }
    } else if (dom.tag === 'button') {
      el = <ButtonRenderer
        node={dom}
        env={env}
      />;
    } else if (dom.tag === 'text') {
      if (dom.info.message === '') {
        return null;
      }
      el = <span>{dom.info.message}</span>;
    } else if (dom.tag === 'image') {
      if (env.config.unsafe) {
        el = <ShowError msg="Cannot preview {image} commands" blocking={false} />;
      } else {
        el = <NodeEvaluator
          node={dom}
          env={env}
          renderer={(res) => {
            if (res.type === 'error') {
              return <ShowError msg={res.info.message} blocking={false} />;
            }
            // We set a key so the image immediately refreshes on src change.
            // Otherwise it keeps the old image until the new image downloads
            return React.createElement('img', Object.assign({ key: 'img:' + res.info.imgAttrs.src }, res.info.imgAttrs));
          }}
        />;
      }
    } else if (dom.tag === 'html') {
      // comes from clipboard (see extension: evaluator.js)
      // don't need to sanitize, as pasting should only result in styled contents
      el = <span dangerouslySetInnerHTML={ { __html: dom.info.message } }></span>;
    } else if (dom.tag === 'remote') {
    
      if (env.config.unsafe) {
        // could be used to exfiltrate info
        el = <ShowError msg={'Cannot preview {' + dom.info.command + '} commands'} blocking={false} />;
      } else {
        el = <NodeEvaluator
          node={dom}
          env={env}
          renderer={(res) => {
            if (res.type === 'error') {
              return <ShowError msg={res.info.message} blocking={false} nodeOrAddonId={dom} />;
            }
            const propsToRenderer = { node: res, env, addonId: dom.findRootAddonLocalData() || undefined };
            const command = res.info.command;
            if (res.info.instant) { 
              return <OuterInstantError command={command} />;
            }
            if (command === 'dbselect') {
              return <SQLRenderer {...propsToRenderer} />;
            } else if (['dbupdate', 'dbinsert', 'dbdelete'].includes(command)) {
              return Chicklet(<span>
                <TableIcon /> <span className="label">{res.info.sqlBlurb[0]}{res.info.sqlBlurb.length === 2 ? <b>{res.info.sqlBlurb[1]}</b> : null}</span>
              </span>);
            } else {
              return <RemoteRenderer {...propsToRenderer} />;
            }
          }}
        />;
      }
    } else if (dom.tag === 'run') {
      el = <RunRenderer
        node={dom}
        env={env}
      />;
    } else if (dom.tag === 'addon') {
      if (dom.info.visibility) {
        // It's a start tag
        if (dom.info.visibility.preview === 'chicklet' || dom.info.visibility.preview === 'none') {
          el = <ChickletRenderer
            node={dom}
            env={env}
            type={dom.info.visibility.preview}
          />;
          delete dom.info.style;
        } else {
          return null;
        }
      } else {
        // it's an end tag
        return null;
      }
    } else if (command && command.preview) {
      let msg, tooltip = null;
      if (typeof command.preview === 'string') {
        msg = command.preview;
      } else {
        el = <NodeEvaluator
          node={dom}
          env={env}
          renderer={(res) => {
            if (res.type === 'error') {
              return <ShowError msg={res.info.message} blocking={false} nodeOrAddonId={dom} />;
            } else {
              /** @type {{text: string, tooltip: string}} */
              let msgData = typeof command.preview === 'string' ? { text: command.preview } : command.preview(res.info);
              tooltip = msgData.tooltip;
              msg = msgData.text;
              return Chicklet(<span>
                {PREVIEW_ICONS[command.tag]} <span className="label">{msg}</span>
              </span>, {
                title: tooltip
              });
            }
          }}
        />;
      }
      if (!el) {
        el = Chicklet(<span>
          {PREVIEW_ICONS[command.tag]} <span className="label">{msg}</span>
        </span>, {
          title: tooltip
        });
      }
    } else {
      el = <NodeRenderer node={dom} env={env} />;
    }
  } else {
    el = <span>[Skipping Node: {dom.type}, {dom.tag}]</span>;
  }

  if (dom.display) {
    if (dom.display.hidden) {
      el = <span style={{ display: 'none' }}>{el}</span>;
    }
    let isAddon = !!dom.findRootAddonLocalData();
    if (dom.display.isShownNote && !isAddon) {
      el = <span className="status-note">{el}</span>;
    }
    if (dom.display.isInteractive && !isAddon && !['tr', 'td'].includes(dom.tag)) {
      el = <span className="status-interactive">{el}</span>;
    }
  }

  return el;
}


/**
 * @param {Set} deps 
 * @param {Environment} env 
 * 
 * @return {object}
 */
function getDependenciesData(deps, env) {
  let nextCachedDependencies = Object.create(null);
  for (let dependency of deps) {
    // Or based on form data
    let storeId = 'root';
    let field = dependency;
      
    if (dependency.includes(' %% ')) {
      [storeId, field] = dependency.split(' %% ');
    }

    let data = env.config.storeSnapshots[storeId];
    if (!data) {
      nextCachedDependencies[dependency] = null;
    } else {
      nextCachedDependencies[dependency] = (field in data) ? data[field] : undefined;
    }
  }
  return nextCachedDependencies;
}



/**
 * @param {Set<string>} a 
 * @param {Set<string>} b 
 * 
 * @returns {boolean}
 */
function setsEqual(a, b) {
  if (a.size !== b.size) {
    return false;
  }
  for (let i of a) {
    if (!b.has(i)) {
      return false;
    }
  }
  return true;
}


// Prevents unnecessary re-renders at the React level (not for infinite re-renders)
let SnippetRenderer = React.memo(SnippetRendererBase, (currentProps, nextProps) => {
  if (nextProps.env.config.forceRerenderMode || currentProps.env.config.forceRerenderMode) {
    return false;
  }
  if (currentProps.dom.dependencies) {
    if (!nextProps.dom.dependencies || !setsEqual(currentProps.dom.dependencies, nextProps.dom.dependencies)) {
      // dependencies may be removed (e.g. when toggle off a multi-line)
      // {formtoggle}
      nextProps.env.config.state.CACHED_DEP_DATA.set(getLocationForDom(nextProps.dom), null);
      return false;
    }
  }

  if (!nextProps.dom.dependencies) {
    return true;
  }

  let location = getLocationForDom(nextProps.dom);

  let origDependencyData = nextProps.env.config.state.CACHED_DEP_DATA.get(location);

  if (!origDependencyData) {
    return false;
  }

  let newDependencyData = getDependenciesData(nextProps.dom.dependencies, nextProps.env);

  for (let dependency of nextProps.dom.dependencies) {
    if (newDependencyData[dependency] === null || (newDependencyData[dependency] === undefined && origDependencyData[dependency] !== undefined) || !strictEquals(origDependencyData[dependency], newDependencyData[dependency])) {
      return false;
    } 
  }
  
  return true;
});


export default SnippetRenderer;