import React, { useRef } from 'react';

import {
  Alert,
  Collapse
} from '@mui/material';

/**
 * Shows error for the command
 * @param {Object} props
 * @param {import('./SnippetEditor').EditorContextType} props.context
 * @param {number} props.commandErrorBottom
 * @returns
 */
const CommandError = ({
  context,
  commandErrorBottom,
}) => {
  const errorFromInfo = context?.token?.info.error;
  const hasError = !!errorFromInfo;
  const errorRef = useRef('');

  // This will make sure the error does not become empty during animation exit.
  if (hasError) {
    errorRef.current = errorFromInfo;
  }

  return (
    <Collapse
      in={hasError}
      orientation="vertical"
      sx={{
        position: 'absolute',
        bottom: commandErrorBottom,
        left: 0,
        right: 0,
        '.MuiCollapse-wrapperInner': {
          display: 'flex'
        }
      }}
    >
      <Alert
        severity="error"
        sx={{
          mx: 'auto'
        }}
      >
        {errorRef.current}
      </Alert>
    </Collapse>
  );
};

export default CommandError;