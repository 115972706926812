/**
 * This file adds all addon options except the snippet processing options
 * This split is so that the AI Blaze extension can still add the necessary 
 * addon options, without including snippet processor code. In case of AI Blaze,
 * the addon contents are processed in the AI iframe.
 */

/**
 * @param {SupportedPlatforms} sp1 
 * @param {SupportedPlatforms} sp2 
 * @returns {SupportedPlatforms}
 */
export function intersectPlatformSupport(sp1, sp2) {
  if (!sp1 || !sp2) {
    return sp1 ? sp1 : sp2;
  }
  const commonKeys = Object.keys(sp1).filter(key => key in sp2);
  const res = {};
  for (const key of commonKeys) {
    res[key] = sp2[key];
  }
  return res;
}


let transformType = (item) => {
  return item.type === 'select' ? ((item.config && item.config.options) || []) : item.type;
};

/**
 * @param {string} addonNamespace
 * @param {Pick<SnippetObjectType, 'options'|'shortcut'|'content'>} data 
 * 
 * @return {Promise<AddonOptionsType>}
 */
export async function addAddonAttributesNoProcessing(addonNamespace, data) {
  let snippetAddonSetting = data.options?.addon;
  if (!snippetAddonSetting) {
    snippetAddonSetting = /** @type {any} */ ({});
  }
  let positional = (snippetAddonSetting.positional || []).map(x => Object.assign({}, x));
  let named = (snippetAddonSetting.named || []).map(x => Object.assign({}, x));

  positional.forEach(attr => {
    attr.type = transformType(attr);
    attr.priority = 2;
    attr.required = true;
  });
  

  /**
   * @param {UserDefinedPropertyType} item 
   */
  let createDefault = (item) => {
    // note that default must be set to a string in addons
    if (item.type === 'boolean') {
      item.default = item.default || 'no';
    } else if (item.type === 'number') {
      item.default = item.default || '0';
    } else {
      item.default = item.default || '';
    };
  };


  let keys = {
    trim: {
      name: 'trim',
      type: ['left', 'right', 'yes', 'no'],
      constant: false,
      required: false,
      default: 'no',
      priority: 0,
      placeholder: 'yes',
      description: 'Trim whitespace around the command'
    }
  };
  let p = 3;
  let dp = 1 / (named.length + 1);
  for (let name of named) {
    keys[name.name] = {
      name: name.name,
      type: transformType(name),
      list: name.list || null,
      constant: name.type === 'identifier',
      required: name.required,
      default: name.default,
      description: name.description || null,
      placeholder: 'placeholder' in name ? name.placeholder : name.default,
      config: name.config || null,
      priority: name.visibility === 'hidden' ? -10 : p
    };
    createDefault(keys[name.name]);
    p -= dp;
  }

  for (let item of positional) {
    createDefault(item);
  }
  let command = (addonNamespace || '').toLowerCase() + '-' + data.shortcut.toLowerCase();

  let display = (data.options && data.options.addon && data.options.addon.display) || {};
  if (!display.preview) {
    display.preview = 'contents';
  }
  if (!display.insertion) {
    display.insertion = 'contents';
  }

  let invalidInAttribute = false;
  if (display.preview !== 'contents' || display.insertion !== 'contents') {
    // Non-content items shouldn't be used in attributes
    invalidInAttribute = true;
  }

  if (!invalidInAttribute) {
    for (let attr of positional.concat(named)) {
      if (attr.type === 'identifier') {
        // Items that pass data up shouldn't be valid in attributes.
        // This would particular run into issues in addons used in another
        // commands settings that's evaluate at the top level of the command's
        // `process()` function.
        invalidInAttribute = true;
        break;
      }
    }
  }
  
  /** @type {AddonOptionsType} */
  let addonOptions = {
    command,
    invalidInAttribute,
    attributes: {
      isAddon: true,
      commandName: command,
      positional: [positional.length, positional.length],
      named: keys,
      namedDef: named,
      positionalDef: positional[0] || null
    },
    platforms: null,
    connected: null,
    usedLambdaWhitelist: null
  };
  
  if (data?.options?.addon?.display?.valid_hosts?.length > 0) {
    addonOptions.platforms = intersectPlatformSupport(addonOptions.platforms, { browser: true });
  }

  return addonOptions;
}
