import React, { useState, useRef, useEffect } from 'react';
import ChangeHistory from './ChangeHistory';
import { BetaChip } from '../Version/VersionChip';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { showSidebarPanel } from '../../message';
import { usersSettingsRef } from '@store';
import { useIsMounted, useIsSmall, useTypedSelector, useTypedSelectorShallowEquals } from '../../hooks';
import { sync } from '../../Sync/syncer';
import { checkOrg } from '../../flags';
import { storage } from '../../utilities';
// @ts-ignore
import activityHistory from './activity_history.svg';
import ChangeHistoryPopup from './ChangeHistoryPopup';
import { log } from '../../logging/logging';

/**
 * 
 * @callback ButtonArgument 
 * @param {{ref: React.MutableRefObject<any>, onClick: React.MouseEventHandler }} props
 * @returns {React.ReactNode}
 */

/**
 * @param {object} props
 * @param {string} props.tooltip
 * @param {string} props.title
 * @param {'snippet'|'group'} props.type
 * @param {string} props.snippetId
 * @param {string} props.groupId
 * @param {ButtonArgument=} props.button
 * @param {import("@mui/material").TooltipProps['placement']=} props.tooltipPlacement
 */
export default function ChangeHistoryButton(props) {
  let isMounted = useIsMounted();
  let targetRef = useRef(null);
  let [lastEditData, setLastEditData] = useState(null);


  let isOrg = useTypedSelector((store) => checkOrg(store));
  let uid = useTypedSelector((store) => store.userState.uid);

  let viewTimestampRef = useRef(null);
  viewTimestampRef.current = useTypedSelectorShallowEquals(store => {
    if (props.type !== 'snippet') {
      return null;
    }
    
    return store.userState.views ? {
      snippet: store.userState.views[props.snippetId],
      group: store.userState.views[props.groupId]
    } : {};
  });


  let currentlyShowingRef = useRef(null);
  let lastEdit = useTypedSelectorShallowEquals(_store => {
    if (props.type !== 'snippet') {
      return null;
    }

    let snippet = sync.getSnippetById(props.snippetId);
    if (!snippet) {
      // can happen if as redux can re-render the child before
      // the parent.
      return null;
    }

    return {
      uid: snippet.data.updated_by,
      modifiedDate: snippet.data.updated_at && snippet.data.updated_at.toMillis(),
      createdDate: snippet.data.created_at && snippet.data.created_at.toMillis()
    };
  });
  

  function hideLastEdit() {
    if (currentlyShowingRef.current && !currentlyShowingRef.current.hidden) {
      setLastEditData(null);

      if (props.type === 'snippet') {
        if (!viewTimestampRef.current.snippet || currentlyShowingRef.current.modifiedDate > viewTimestampRef.current.snippet) {
          let viewData = {
            [`views.${props.snippetId}`]: Date.now()
          };
          if (!viewTimestampRef.current.group) {
            // We only log the first view for groups, not the most recent
            viewData[`views.${props.groupId}`] = Date.now();
          }
          storage.update(usersSettingsRef, viewData, 'HIDE_AUTOSAVE');
        }
      }
    
      clearTimeout(currentlyShowingRef.current.timeoutId);

      currentlyShowingRef.current.hidden = true;
    }
  }


  let isSmall = useIsSmall();


  useEffect(() => {
    if (lastEdit) {

      if (lastEdit.uid === uid) {
        if (currentlyShowingRef.current && !currentlyShowingRef.current.hidden) {
          hideLastEdit();
        }
        return; 
      }

      if (
        currentlyShowingRef.current
            && currentlyShowingRef.current.uid === lastEdit.uid
            && currentlyShowingRef.current.modifiedDate === lastEdit.modifiedDate) {
        return;
      }
  

      if (
        lastEdit.uid &&
        (
          (!viewTimestampRef.current.snippet && viewTimestampRef.current.group &&
            (viewTimestampRef.current.group < lastEdit.modifiedDate || viewTimestampRef.current.group < lastEdit.createdDate)) // new snippet in a group we've viewed
          || (viewTimestampRef.current.snippet && lastEdit.modifiedDate > viewTimestampRef.current.snippet)  // updated snippet
        )
      ) {
            
        // remove the popup after 9 seconds
        let timeoutId = setTimeout(() => {
          // we need to check if we're still mounted and also if that lastEdit has changed
          if (
            isMounted.current &&
            lastEdit.uid === currentlyShowingRef.current.uid &&
            lastEdit.modifiedDate === currentlyShowingRef.current.modifiedDate) {
            hideLastEdit();
          }
        }, 9000);


        currentlyShowingRef.current = lastEdit;
        currentlyShowingRef.current.timeoutId = timeoutId;

        setLastEditData(lastEdit);

        return () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        };
      }
    }

    // eslint-disable-next-line
  }, [lastEdit && lastEdit.uid, lastEdit && lastEdit.modifiedDate]);
  

  if (!isOrg) {
    // only available to business users
    return null;
  }


  if (lastEdit === null && props.type === 'snippet') {
    // This can happen if the snippet goes away but the ChangeHistory renders
    // before the parent <Snippet/> is removed.
    return null;
  }

  const onClick = () => {
    log({
      action: 'View history'
    }, {
      group_id: props.groupId,
      snippet_id: props.snippetId
    });

    hideLastEdit();
    showSidebarPanel({
      title: <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
        <div style={{
          flex: 1
        }}>
          {props.title}
        </div>
        <div style={{ marginRight: 24 }}>
          <BetaChip />
        </div>
      </div>,
      contents: <ChangeHistory type={props.type} groupId={props.groupId} snippetId={props.snippetId} />,
      width: isSmall ? 320 : 560
    });
  };


  return <>
    <Tooltip
      title={props.tooltip}
      placement={props.tooltipPlacement}
    >
      {props.button ? 
        props.button.call(this, {
          ref: targetRef,
          onClick
        }) :
        (<IconButton
          ref={targetRef}
          data-testid="change-history-button"
          style={{
            marginLeft: 8
          }}
          onClick={onClick}
          size="large">
          <img
            src={activityHistory}
            style={{
              width: 24,
              height: 24,
              opacity: .6
            }}
            alt="History"
            draggable="false"
          />
        </IconButton>
        )}
    </Tooltip>
    {lastEditData && <ChangeHistoryPopup
      target={targetRef.current}
      lastEditData={lastEditData}
      hideLastEdit={hideLastEdit}
    />}
  </>;
}
