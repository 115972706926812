import { decompressDelta } from '../../delta_proto/DeltaProto';

/**
 * This gets the snippet delta without any Firebase or Processor dependency
 * Note: This is only to be used in embedded/micro app
 * targets. For the Dashboard, snippetText() function from searchFull.js is recommended.
 */

/**
 * @param {SnippetObjectType} snippet 
 * @returns {DeltaType}
 */
export function getSnippetPlainDelta(snippet) {

  let delta;
  if (!(snippet.content?.delta.toUint8Array)) {
    if (snippet.delta) {
      // generated by the extension for the assistant
      delta = new Uint8Array(Object.values(snippet.delta));
    } else {
      let binaryString = snippet.content.binaryString || snippet.content.delta._binaryString;
      if (binaryString !== undefined) {
        delta = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          delta[i] = binaryString.charCodeAt(i);
        }
      } else {
        return;
      }
    }
  } else {
    delta = snippet.content.delta.toUint8Array();
  }

  return decompressDelta(delta);
}