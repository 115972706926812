import Quill from 'quill';
import ReactDOMServer from 'react-dom/server';
import React from 'react';
import { md5 } from 'js-md5';
import { addBreadcrumb } from '@sentry/browser';
import Embed from 'quill/blots/embed';
import { getQuill } from './editor_utilities';
import { APP_POSTFIX } from '../../config';

export const IMAGE_URL_PREFIX = `https://storage.googleapis.com/blaze-today${APP_POSTFIX}-user-images/`;


// False positive. Parchment is not a component
// eslint-disable-next-line react-refresh/only-export-components
let Parchment = Quill.import('parchment');

function createImageComponent({ url, width, height, pasted }) {
  return ReactDOMServer.renderToStaticMarkup(
    <>
      <img
        alt="Pasted content"
        className={pasted ? ImageBlot.pastedClassName(url) : ''}
        src={url}
        width={parseInt(width, 10) || undefined}
        height={parseInt(height, 10) || undefined}
      />
      {
        /**
         * renderToStaticMarkup/renderToString does not generate styles in React 18. 
         * As a work around, copied styles from MUI.
         * https://github.com/emotion-js/emotion/issues/2906
         * https://github.com/emotion-js/emotion/issues/2691
         */
      }
      { pasted && <span
        className="image-upload-progress"
      /> }
    </>
  );
}

export default class ImageBlot extends Embed {
  static create(value) {
    let node = /** @type {HTMLSpanElement} */ (super.create());
    node.innerHTML = createImageComponent(value);
    node.setAttribute('preserveAspect', value.preserveAspect === undefined ? true : value.preserveAspect);
    // needed to protect against drag and drop inside
    node.setAttribute('contenteditable', 'false');
    node.style.display = 'inline-block';

    node.addEventListener('dragstart', (e) => {
      // WORKAROUND: sometimes our default dragstart behavior does not correctly handle the image drag/drop without
      // a pre explicit selection to the image in quill, here we are overriding our implemented default behavior
      // and converting the action from MOVE to COPY to simplify the efforts needed to fix it, this can be enhanced
      // in the future by investigating a root fix to make the image moves correctly on drag/drop without pre-selection
      addBreadcrumb({
        message: 'Dragging image'
      });

      /** @type {Quill} */
      let quill = getQuill(node);
      let range = quill.getSelection();
      let index = quill.getIndex(Parchment.Registry.find(node));
      if (range && range.index <= index && range.index + range.length > index) {
        // part of a bigger selection, continue
        return;
      }

      e.dataTransfer.setData('text/html', /** @type {HTMLElement} */ node.innerHTML);
      e.dataTransfer.dropEffect = 'copy';
      e.stopPropagation();
    });

    return node;
  }

  /**
   * 
   * @param {HTMLSpanElement} node 
   * @returns 
   */
  static value(node) {
    const imgTag = this.getImageNode(node),
      src = imgTag?.getAttribute('src') || '';
    if (!src) {
      return null;
    }
    return {
      url: src,
      width: imgTag.getAttribute('width'),
      height: imgTag.getAttribute('height'),
      pasted: !src.startsWith(IMAGE_URL_PREFIX)
    };
  }

  constructor(scroll, node) {
    super(scroll, node);

    /**
     * Override the types of the property.
     * @type {HTMLElement}
     */
    /* eslint-disable no-unused-expressions */
    this.domNode;
  }
  
  uploadDone(url) {
    const imageNode = ImageBlot.getImageNode(this.domNode);
    imageNode.setAttribute('src', url);
    imageNode.classList.remove(imageNode.classList.item(0));
    this.domNode.getElementsByClassName('image-upload-progress')[0].remove();
  }

  format(name, value) {
    const domNode = this.domNode;
    if (['width', 'height'].includes(name)
      && value !== undefined && value !== null
    ) {
      ImageBlot.getImageNode(domNode).setAttribute(name, value);
    } else if (name === 'preserveAspect' && value !== undefined) {
      domNode.setAttribute(name, value);
    } else {
      super.format(name, value);
    }
  }

  static formats(domNode) {
    const imageNode = ImageBlot.getImageNode(domNode);
    let width = parseInt(imageNode.getAttribute('width'), 10);
    let height = parseInt(imageNode.getAttribute('height'), 10);

    return {
      width: isNaN(width) ? imageNode.naturalWidth || null : width,
      height: isNaN(height) ? imageNode.naturalHeight || null : height,
      naturalWidth: imageNode.naturalWidth,
      naturalHeight: imageNode.naturalHeight,
      preserveAspect: domNode.getAttribute('preserveAspect') === 'true'
    };
  }

  optimize(context) {
    super.optimize(context);

    /**
     * If its inside a table, lets resize the image to the cell, so the UI is distorted.
     */
    const tdBlot = this.parent.parent,
      tableBlot = tdBlot.parent?.parent?.parent;
    if (tdBlot.statics?.tagName === 'TD'
      && tableBlot?.statics.tagName === 'TABLE'
      && tableBlot.domNode.style.width !== 'auto') {
      const cellWidth = this.parent.domNode.clientWidth;
      const { width, height } = this.formats();
      if (width && width > cellWidth) {
        const ratio = cellWidth / width;
        this.format('width', cellWidth);
        this.format('height', height * ratio);
      }
    }
  }

  /**
   * 
   * @param {HTMLSpanElement} domNode 
   */
  static getImageNode(domNode) {
    return /** @type {HTMLImageElement} */ (domNode.children[0].children[0]);
  }

  formats() {
    let formats = super.formats();
    formats = Object.assign(formats, ImageBlot.formats(this.domNode));
    return formats;
  }

  static pastedClassName(url) {
    return `pasted-${md5(url)}`;
  }
}
ImageBlot.blotName = 'insert-image';
ImageBlot.tagName = 'span';
ImageBlot.className = 'inserted-image';

Quill.register(ImageBlot);
