import React from 'react';
import Quill from 'quill';
import { Environment } from '../../snippet_processor/DataContainer';
import { tokenize } from '../../snippet_processor/Parser';
import { addFormMenuOptionsAttribute, findAttribute, setEmbeddedAttribute } from './EmbeddedCommand/embedded_utilities';
import { KEYWORD_TOKENS } from '../../snippet_processor/Lexer';
import { showConfirm } from '../../message';
import { COMMANDS } from '../../snippet_processor/Commands';


// False positive. Parchment is not a component
// eslint-disable-next-line react-refresh/only-export-components
let Parchment = Quill.import('parchment');

/**
 * Expands any collapsed nodes within a delta.
 * 
 * @param {DeltaType | import('quill/core').Delta} delta 
 * 
 * @return {DeltaType}
 */
export function expandDeltaContents(delta) {
  let newDelta = {
    ops: delta.ops.map(d => {
      if (d.insert?.collapsedCommand) {
        let data = d.insert.collapsedCommand;
        let res = {
          insert: embedDataToCommand(data)
        };

        if (d.attributes) {
          res.attributes = d.attributes;
        }

        return res;
      }

      return d;
    })
  };

  return newDelta;
}


/**
 * @param {object} data 
 * 
 * @return {string}
 */
export function embedDataToCommand(data) {
  let value = data.value;
  let spec = value.spec;

  let equationName;
  if (spec.commandName === '=') {
    equationName = value.attributes.find(x => x.name === 'name')?.raw;
  };
  
  let attributes = value.attributes.filter(x => !x.fillIn);

  let attrString = attributes.map((a, i) => {
    let attrDef = spec.named[a.name];
    if (!attrDef) {
      if (a.positional && spec.positionalDef) {
        attrDef = spec.positionalDef;
      } else {
        attrDef = {};
      }
    }

    let v = a.raw || '';
    
    return a.positional ? (((v[0] !== ' ' && attrDef.name !== 'formula') ? ' ' : '') + v) : (i === 0 ? '' : ' ') + a.name + '=' + v;
  }).join(';');

  if (attributes[0] && !attributes[0].positional) {
    // for formatting want a space if the first attribute is a named attribute
    attrString = ' ' + attrString;
  }

  let res = '{' + (equationName ? equationName : '') + spec.commandName + (attributes.length ? (spec.commandName === '=' ? '' : ':') + attrString : '') + '}';

  return res;
}

/**
 * @typedef {ReturnType<getCollapsedData>} CollapsedDataType
 */


/**
 * @param {string} id 
 * @param {import("../../snippet_processor/ParseNode").default} token 
 * @param {number=} tokenStart 
 * @param {{ name: string, index: number, skip?: number }[]=} attributesPath
 */
export function getCollapsedData(id, token, tokenStart = 0, attributesPath) {
  let error = token.type === 'error' && (token.info.message || token.info);
  let type = getTokenType(token);

  if (token.type === 'error' && token.info.node) {
    token = token.info.node;
  }

  const rootToken = token.info.start || token;
  let rootTokenStartPosition = null;

  if (tokenStart) {
    rootTokenStartPosition = tokenStart;
  } else if (rootToken) {
    rootTokenStartPosition = rootToken.startPosition;
  }

  let attributes = token.info.attributes.position.map((x, aIndex) => ({
    name: x.name,
    fillIn: x.fillIn || false,
    positional: x.positional || false,
    raw: x.fillIn ? null : x.raw.trimStart(),
    value: x.fillIn ? null : x.snippetText(),
    nodes: getAttributeNodes(x, aIndex, rootTokenStartPosition, id, attributesPath || [])
  }));

  if (type === 'calc') {
    if (token.info.attributes.keys.name) {
      attributes.push({
        name: 'name',
        fillIn: true,
        positional: false,
        raw: token.info.attributes.keys.name.raw,
        value: token.info.attributes.keys.name.final,
        // name would not have tokens.
        nodes: []
      });
    }
  }

  // use null instead of undefined for missing here
  let res = { 
    meta: {
      id,
      error,
      rootTokenStartPosition: rootTokenStartPosition,
      startPosition: token.startPosition,
      endPosition: token.endPosition,
      attributesPath
    },
    value: {
      type: getTokenType(token),
      spec: token.info.attributes.spec,
      addon_id: token.info.addon?.group?.data?.associated_addon_id || null,
      icon_url: token.info.addon?.group?.data?.options?.addon?.icon_image_url || null,
      hasMatchingTokens: !!(token.info.start || token.info.end),
      attributes
    }
  };

  return res;
}


/**
 * @param {import("../../snippet_processor/ParseNode").default} token
 * 
 * @return {string}
 */
export function getTokenType(token) {
  let type = token.tag || token.type;
  let t = token.info.type;

  if (t) {
    type += t;
  }

  return type;
}



export function commandsSVG(style) {
  return <svg style={style} aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M324.42 103.16L384 128l24.84 59.58a8 8 0 0 0 14.32 0L448 128l59.58-24.84a8 8 0 0 0 0-14.32L448 64 423.16 4.42a8 8 0 0 0-14.32 0L384 64l-59.58 24.84a8 8 0 0 0 0 14.32zm183.16 305.68L448 384l-24.84-59.58a8 8 0 0 0-14.32 0L384 384l-59.58 24.84a8 8 0 0 0 0 14.32L384 448l24.84 59.58a8 8 0 0 0 14.32 0L448 448l59.58-24.84a8 8 0 0 0 0-14.32zM384 256a24 24 0 0 0-13.28-21.47l-104.85-52.42-52.4-104.84c-8.13-16.25-34.81-16.25-42.94 0l-52.41 104.84-104.84 52.42a24 24 0 0 0 0 42.94l104.84 52.42 52.41 104.85a24 24 0 0 0 42.94 0l52.4-104.85 104.85-52.42A24 24 0 0 0 384 256zm-146.72 34.53a24 24 0 0 0-10.75 10.74L192 370.33l-34.53-69.06a24 24 0 0 0-10.75-10.74L77.66 256l69.06-34.53a24 24 0 0 0 10.75-10.73L192 141.67l34.53 69.07a24 24 0 0 0 10.75 10.73L306.34 256z"></path></svg>;
}



/**
 * 
 * @param {import('../../snippet_processor/ParserUtils').NodeAttribute} attribute 
 * @param {number} attrIndex 
 * @param {number} rootTokenStartPosition 
 * @param {string} id 
 * @param {{name: string, index: number}[]} attributesPath 
 * @returns {any[]}
 */
export const getAttributeNodes = (attribute, attrIndex, rootTokenStartPosition, id, attributesPath) => {
  return attribute.tokens?.map((token, tokenIndex) => {
    const childTokenType = getTokenType(token);
    if (childTokenType === 'text') {
      return null;
    }
    return getCollapsedData(
      `${id}-${attrIndex}-${tokenIndex}`, 
      token,
      rootTokenStartPosition, 
      [...attributesPath, {
        name: attribute.name,
        index: token.startPosition
      }]
    );
  })?.filter(d => !!d);
};


/**
 * 
 * @param {import('./editor_utilities').CollapsedDataType} parentData 
 * @param {import('./editor_utilities').CollapsedDataType} changedData 
 * @param {{name: string, index: number, skip?: number}[]} attributesPath 
 * @returns 
 */
const updateAttribute = (parentData, changedData, attributesPath) => {
  if (!attributesPath.length) {
    return changedData;
  }
  const [ attributePath, ...remainingPath ] = attributesPath;

  const attributes = parentData.value.attributes;
  let index = findAttribute(parentData, attributePath);
  const attribute = attributes[index];
  const nodeIndex = attribute.nodes.findIndex(n => n.meta.startPosition === attributePath.index);
  const currentValue = attribute.raw;
  const attributeNode = attribute.nodes[nodeIndex];
  const start = currentValue.substring(0, attributeNode.meta.startPosition);
  const end = currentValue.substring(attributeNode.meta.endPosition);
  const attributeData = updateAttribute(attribute.nodes[nodeIndex], changedData, remainingPath);
  const commandText = embedDataToCommand(attributeData);
  const newValue = start + commandText + end;
  const newData = setEmbeddedAttribute(
    attributePath.name,
    parentData,
    { value: newValue, raw: newValue },
    null,
    null,
    attributePath.skip
  );
  const newAttr = newData.value.attributes[index];
  newAttr.nodes[nodeIndex] = attributeData;
  
  // Move cursor based on new change.
  const newEndPosition = attributeData.meta.startPosition + newValue.length;
  const changedPosition = newEndPosition - attributeData.meta.endPosition;
  attributeData.meta.endPosition += changedPosition;
  for (let index = nodeIndex + 1; index < newAttr.nodes.length; index++) {
    const node = newAttr.nodes[index];
    node.meta.startPosition += changedPosition;
    node.meta.endPosition += changedPosition;
  }

  //newData.meta.endPosition = newData.meta.startPosition + newValue.length;
  return newData;
};

/**
 * 
 * @param {import('./editor_utilities').CollapsedDataType} newData 
 * @param {HTMLElement} selectedNode 
 * @returns 
 */
export const updateAttributeTree = (newData, selectedNode) => {
  const attributesPath = newData.meta.attributesPath;

  let blot = /** @type {import('./highlighter').CollapsedCommandBlot} */ (Parchment.Registry.find(selectedNode));
  /**
   * @type {import('./editor_utilities').CollapsedDataType}
   */
  let blazeData = blot.blazeData;
  return updateAttribute(blazeData, newData, attributesPath);
};

/**
 * 
 * @param {import('./editor_utilities').CollapsedDataType} rootData 
 * @param {{name: string, index: number, skip?: number, collapsed?: boolean}[]} attributesPath 
 */
export const getAttributeData = (rootData, attributesPath) => {
  let newData = rootData;
  for (let pathIndex = 0; pathIndex < attributesPath.length; pathIndex++) {
    const attributePath = attributesPath[pathIndex];
    let attributeName = attributePath.name;
    let attributes = newData.value.attributes;
    if ((!attributeName || attributeName === 'default') && rootData.value.type === 'formmenu') {
      attributeName = 'options';
      attributes = [...attributes];
      addFormMenuOptionsAttribute(attributes);
    }
    let attribute = attributes.find(x => x.name === attributeName);
    if (!attribute) {
      return null;
    }
    let attributePathIndex = attributePath.index;
    if (attributePath.collapsed) {
      let collapsed = 0,
        startNode = 0,
        skip = attributePath.skip || 0;
      if (skip) {
        startNode = attribute.nodes.findIndex(n => n.meta.startPosition >= skip);
      }
      for (let nodeIndex = startNode; nodeIndex < attribute.nodes.length; nodeIndex++) {
        const node = attribute.nodes[nodeIndex];
        const startPosition = node.meta.startPosition;
        if (startPosition - collapsed - skip === attributePathIndex) {
          newData = node;
          break;
        }
        const length = node.meta.endPosition - startPosition;
        collapsed += length - 1;
      }
    } else {
      if (attributePath.skip) {
        attributePathIndex += attributePath.skip;
      }
      newData = attribute.nodes.find(n => n.meta.startPosition === attributePathIndex);
    }
  }
  return newData;
};


/**
 * 
 * @param {import('./editor_utilities').CollapsedDataType} rootData 
 * @param {{[x: string]: ActiveAddonType}=} activeAddons
 */
export const tokenizeData = async (rootData, activeAddons = {}) => {
  let commandData = embedDataToCommand(rootData);
  const chipType = rootData.value.type;
  return tokenizeCommand(commandData, activeAddons, rootData, chipType);
};

/**
 * 
 * @param {string} commandData 
 * @param {{[x: string]: ActiveAddonType}} activeAddons
 * @param {import('./editor_utilities').CollapsedDataType} existingData
 * @param {string=} chipType
 */
export const tokenizeCommand = async (
  commandData,
  activeAddons = {},
  existingData,
  chipType
) => {
  const env = new Environment(null, {
    stage: 'tokenization',
    domain: null,
    addons: activeAddons,
    clipboard: () => '',
    selectorFn: () => [''],
    remoteFn: async () => ''
  });

  if (!chipType) {
    chipType = detectChipType(commandData);
  }

  //reparse
  const endChips = {
    'formif_start': '{endif}',
    'formrepeat_start': '{endrepeat}',
    'formtoggle_start': '{endformtoggle}',
    'notenote_start': '{endnote}',
    'linklink_start': '{endlink}',
    'actionaction_start': '{endaction}',
  };
  const middleChips = {
    'formif_elseif': [
      '{if: 123}',
      '{endif}'
    ],
    'formif_else': [
      '{if: 123}',
      '{endif}'
    ]
  };
  const startChips = {
    'formif_end': '{if: 1}',
    'formrepeat_end': '{repeat: 2}',
    'formtoggle_end': '{formtoggle}',
    'notenote_end': '{note}',
    'linklink_end': '{link: foo}',
    'actionaction_end': '{action}',
  };
  let chipIndex = 0;
  if (chipType in endChips) {
    commandData += endChips[chipType];
  } else if (chipType in middleChips) {
    chipIndex = 1;
    commandData = middleChips[chipType][0] + commandData + middleChips[chipType][1];
  } else if (chipType in startChips) {
    chipIndex = 1;
    commandData = startChips[chipType] + commandData;
  }
  let tokens = await tokenize({
    ops: [{
      insert: commandData
    }]
  }, 'text', env);

  await tokenizeAttributes(tokens, env);
  let existingMeta = existingData.meta;
  const tokenData = tokens[chipIndex];
  if (!tokenData) {
    return null;
  }
  const finalData = getCollapsedData(existingMeta.id, tokens[chipIndex]);
  const currentMeta = finalData.meta;
  currentMeta.startPosition = existingMeta.startPosition;
  currentMeta.endPosition = existingMeta.endPosition;
  currentMeta.rootTokenStartPosition = existingMeta.rootTokenStartPosition;
  return finalData;
};

/**
 * Detects the chipType
 * @param {string} partialCommand 
 */
const detectChipType = (partialCommand) => {
  const commandsMapping = [
    // start chips
    'if',
    'repeat',
    'formtoggle',
    'note',
    'link',
    'action',

    // middle chips
    'elseif',
    'else',

    // endchips
    'endif',
    'endrepeat',
    'endformtoggle',
    'endnote',
    'endlink',
    'endaction'
  ];
  // Command is always lowercase
  let commandName = commandsMapping.find(cmdName => partialCommand.startsWith(`{${cmdName}`));
  if (!commandName) {
    // for now we can ignore other commands which are not listed above.
    return;
  }
  const command = COMMANDS[commandName.toUpperCase()];
  return command.tag + command.subType;
};



/**
 * 
 * @param {import("../../snippet_processor/ParseNode").default[]} nodes 
 * @param {Environment} env 
 */
export const tokenizeAttributes = async (nodes, env) => {
  for (let index = 0; index < nodes.length; index++) {
    const node = nodes[index];
    if (!node.info.attributes) {
      continue;
    }
    for (let attrIndex = 0; attrIndex < node.info.attributes.position.length; attrIndex++) {
      const attr =  node.info.attributes.position[attrIndex];
      if (!attr.raw) {
        continue;
      }
      await attr.tokenize(env);
      if (!attr.tokens) {
        continue;
      }
      await tokenizeAttributes(attr.tokens, env);
    }
  }
};

/**
 * @param {DeltaType} delta
 * 
 * @return {string}
 */
export function convertDeltaTypeToString(delta) {
  if (!delta) {
    return '';
  }
  return delta.ops.map(x => typeof x.insert === 'string' ? x.insert : '').join('');
}

/**
 * @param {any} d 
 * @returns {string}
 */
export function convertDeltaToString(d) {
  return convertDeltaTypeToString(expandDeltaContents(d));
}

// menu and cols are invalid because the inputs will not be shown
// debounce is invalid because the dbselect request will only run once
// name is invalid because the dbselect call is async and the response is received from the finish= handler
export const CODE_BLOCK_INVALID_ATTRS = {
  'dbselect': ['menu', 'cols', 'name', 'debounce'],
  'urlload': ['start', 'done', 'debounce'],
};


const SIMPLE_NAME_REGEX = new RegExp('^[a-z][a-z0-9_]*$', 'i');
/**
 * @param {string} name
 * @returns {boolean}
 */
export function isSimpleName(name) {
  return !!name.match(SIMPLE_NAME_REGEX)
  && !KEYWORD_TOKENS.includes(name.toUpperCase());
}

/**
 * @param {string} variableName
 * @returns {boolean}
 */
export function isComplexName(variableName) {
  return variableName.length > 2 && variableName.startsWith('`') && variableName.endsWith('`');
}

export function escapeFormName(text) {
  if (
    isSimpleName(text)
  ) {
    return text;
  }
  return '`' + text.replace(/`/g, '\\`') + '`';
}

/**
 * @param string
 * @returns string
 */
export function escapeBackticks(string) {
  // matches "hey`there" but not "hey\`there"
  return string.replace(/(?<!\\)`/g, '\\`');
}


/**
 * If return is undefined, then continue to close.
 * Resolves if user wants to close, or rejects if user wants to edit.
 * @param {Set<String>} errors
 */
export const beforeEmbeddedCommandClose = (errors) => {
  if (!errors?.size) {
    return;
  }
  return new Promise((resolve, reject) => {
    /*
      Without timeout it is giving error
      Cannot update a component (`ForwardRef`) while rendering a different component (`SnippetEditor`). To locate the bad setState() call inside `SnippetEditor`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
    */
    setTimeout(() => {
      let errorsArray = Array.from(errors);
      showConfirm({
        contents: <>
          The setting <i>{errorsArray[0]}</i> has an error. Keep editing to fix it or discard your changes.
        </>,
        confirmButtonText: 'Keep editing',
        cancelButtonText: 'Discard changes',
        onConfirm: () => {
          focusAttribute();
          reject();
        },
        onCancel: () => resolve()
      });
    }, 1);
  });
};

const focusAttribute = () => {
  // Handle using timeout so click is finished.
  setTimeout(() => {
    // Focus into any of the first element if required attribute not found
    let elToFocus = document.querySelector('.attribute-quill-editor .ql-editor, #sidePanel input');

    if (!elToFocus) {
      return;
    }

    // @ts-ignore
    elToFocus.focus();
  }, 100);
};

/*
 * 
 * @param {HTMLElement} node 
 * @param {boolean=} isContainer 
 * @returns {Quill}
 */
export const getQuill = (node, isContainer = false) => {
  if (!isContainer) {
    node = node.closest('.ql-container');
  }
  return /** @type {Quill} */ (Quill.find(node));
};



/**
* @param {string} name
* @param {object} data
*/
export function field(name, data) {
  function dataToStr(data) {
    const skip = ['contents', 'elsecontents'];
    let res = data.filter(x => x.value !== undefined && !skip.includes(x.id)).map(x => {
      if (typeof x.value === 'boolean') {
        x.value = x.value ? 'yes' : 'no';
      }
      if (x.id === 'values') {
        return x.value.map(x => x.selected ? `default=${x.value}` : x.value).join('; ');
      } else if (x.id && x.id !== '%positional') {
        return x.id + '=' + x.value;
      } else {
        return x.value;
      }
    }).join('; ');
    if (res && name !== '=') {
      res = ': ' + res;
    }
    return res;
  }
  const content = data.find(x => x.id === 'contents');
  const elseContent = data.find(x => x.id === 'elsecontents');
  let fieldStr = '{' + name + dataToStr(data) + '}';
  if (content) {
    fieldStr += content.value || '';
  }
  if (elseContent && elseContent.value) {
    fieldStr += `{else}${elseContent.value || ''}`;
  }
  if (content) {
    fieldStr += `{end${name}}`;
  }
  return fieldStr;
}


/**
 * @param {import("../../snippet_processor/Commands").CommandDef} command
 * @param {string[]} items
 */
export function commandAttributes(command, items) {
  let processAttribute = (attr, positional, defaults) => {
    attr = Object.assign({}, attr);

    let id = !positional ? attr.name : '%positional';
    if (attr.type === 'boolean') {
      defaults[id] = (typeof attr.default === 'string') ? attr.default === 'yes' : attr.default;
    }
    if (positional) {
      attr.required = true;
    }
    let rawText = false;
    if (['lambda', 'equation', 'bsql'].includes(/** @type {string} */(attr.type))) {
      rawText = true;
    }
    return {
      id,
      clearUntouched: true,
      rawText: rawText,
      attribute: attr
    };
  };

  let defaults = {};

  let attributes = [];

  for (let item of items) {
    if (item === 'POSITIONAL') {
      attributes.push(processAttribute(command.attributes.positionalDef, true, defaults));
      continue;
    }
    const attributeField = processAttribute(command.attributes.named[item], null, defaults);
    attributes.push(attributeField);
    attributeField.id = item;
    attributeField.attribute.name = item;
    if (item === 'values') {
      attributeField.attribute.isLegacyMenu = true;
      attributeField.attribute = {
        ...attributeField.attribute,
        isLegacyMenu: true,
        required: true,
        description: 'The options for the menu'
      };
    }
  }

  return {
    fields: attributes,
    defaults
  };
}