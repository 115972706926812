import {
  Environment,
  MAX_LOCATION_DEPTH,
} from '../../snippet_processor/DataContainer';
import { parse } from '../../snippet_processor/Parser';
import { findType } from '../../snippet_processor/Equation';
import ParseNode from '../../snippet_processor/ParseNode';
import { ParseError } from '../../snippet_processor/ParserUtils';
import { astSQL } from '../../snippet_processor/SQL';
import {
  escapeBackticks,
  isComplexName,
  isSimpleName,
} from './editor_utilities';

/**
 *
 * @param {DeltaType} delta
 * @param {ReplaceOrder[]} replaceOrder
 * @param {string} variableName
 * @param {string} newName
 * @return {Promise<DeltaType>}
 */
export async function changeVariableNameInDelta(
  delta,
  replaceOrder,
  variableName,
  newName,
) {
  if (!newName) {
    return delta;
  }

  newName = normalizeVariableNameForReplace(newName.trim());
  variableName = normalizeVariableNameForReplace(variableName.trim());

  let currentPosition = 0;
  let orderIndex = 0;

  const updatedOps = delta.ops.map((operation) => {
    // e.g images, nothing to change there
    if (typeof operation.insert !== 'string') {
      currentPosition++;

      return operation;
    }

    while (
      orderIndex < replaceOrder.length &&
      replaceOrder[orderIndex].position <
        currentPosition + operation.insert.length
    ) {
      const text = operation.insert;

      let varNewName = newName;
      let varName = variableName;

      // some attributes such as a form's "name" field or a repeater's "locals" don't use backticks
      if (replaceOrder[orderIndex].isAttribute) {
        varNewName = getSimplifiedName(newName);
        varName = getSimplifiedName(variableName);
      }

      let shiftPositionByOne = false;
      let startPosition = replaceOrder[orderIndex].position - currentPosition;
      let endPosition =
        replaceOrder[orderIndex].position - currentPosition + varName.length;

      // "{`hey`=10}" and "{hey=10}" are the same variable but we would only look for "hey"
      // this takes into account "`hey`"
      if (text.slice(startPosition, endPosition + 2) === '`' + varName + '`') {
        currentPosition = currentPosition - 1;

        // updated currentPosition so re-declare the variables
        startPosition = replaceOrder[orderIndex].position - currentPosition;
        endPosition =
          replaceOrder[orderIndex].position - currentPosition + varName.length;
        shiftPositionByOne = true;
      }

      if (replaceOrder[orderIndex].isBsql) {
        // take the "@" into account
        endPosition = endPosition + 1;

        // changing from "@var" to "{=`complex-var`}"
        if (isSimpleName(varName) && isComplexName(varNewName)) {
          varNewName = `{=${varNewName}}`;
        } else {
          // take the "@" into account (simple variables inside query are @variable),
          startPosition = startPosition + 1;
        }
      }

      //  text until position + new variable + rest of text
      operation.insert =
        text.substring(0, startPosition) +
        varNewName +
        text.substring(endPosition);

      if (varNewName.length !== varName.length) {
        currentPosition =
          currentPosition + (varName.length - varNewName.length);
      }

      if (shiftPositionByOne) {
        currentPosition = currentPosition + 1;
      }

      orderIndex++;
    }

    currentPosition = currentPosition + operation.insert.length;

    return operation;
  });

  return {
    ...delta,
    ops: updatedOps,
  };
}

/**
 * In order to make this more efficient, it'll only return a max length of 2, which is enough to know if it's repeated
 *
 * @param {DeltaType=} delta
 * @param {string=} variableName
 * @param {number=} rangeIndex
 * @return {Promise<number>}
 */
export async function getVariableCountInDelta(delta, variableName, rangeIndex) {
  if (!variableName || !delta) {
    return 0;
  }

  const replaceOrder = await getVariableReplaceOrderFromDelta(
    delta,
    variableName,
    rangeIndex,
    true
  );

  return replaceOrder.length;
}

/**
 *
 * @param {DeltaType} delta
 * @param {string} variableName
 * @param {number=} rangeIndex
 * @param {boolean=} onlyFindFirstInstancesOfVariable
 * @return {Promise<ReplaceOrder[]>}
 */
export async function getVariableReplaceOrderFromDelta(
  delta,
  variableName,
  rangeIndex,
  onlyFindFirstInstancesOfVariable = false
) {
  const env = new Environment(null, {
    stage: 'tokenization',
    domain: null,
    addons: {},
    clipboard: () => '',
    selectorFn: () => '',
    remoteFn: async () => '',
  });

  // clone can be removed when this ticket is fixed
  // https://spark-data.blaze.today/space/5p0NkY2CAYbgvDkpYsHh8o/table/3vAAsNAqEvgx4NYlskgIaD/view/5zsRa7qGjWGIWivqXcH279/row/6a55Nx5Xi7yLit0VFslTdv/
  const tree = await parse(JSON.parse(JSON.stringify(delta)), env);

  const usedLambdas = await getLambdasInNodes(tree, env);
  const selectedRepeaterPosition = await getPositionForSelectedRepeater(
    tree,
    variableName,
    rangeIndex,
    env,
  );

  const isLambdaOperation = !!usedLambdas.find(
    (lambda) =>
      getSimplifiedName(variableName).toLowerCase() === lambda.toLowerCase() ||
      variableName.toLowerCase() === lambda.toLowerCase(),
  );

  const { replaceOrder } = await getReplaceOrderFromTree(
    tree,
    env,
    variableName,
    isLambdaOperation,
    selectedRepeaterPosition,
    onlyFindFirstInstancesOfVariable
  );

  if (selectedRepeaterPosition) {
    return filterReplaceOrderForSelectedRepeater(selectedRepeaterPosition, replaceOrder);
  }

  return replaceOrder;
}

export function normalizeVariableNameForReplace(variableName) {
  if (!isSimpleName(variableName)) {
    const newNameWithEscapedBackticks = escapeBackticks(
      getSimplifiedName(variableName),
    );

    return '`' + newNameWithEscapedBackticks + '`';
  }

  return variableName;
}

/**
 * Remove the first and last "`"
 * @param {string} name
 * @returns string
 */
function getSimplifiedName(name) {
  if (isComplexName(name)) {
    return name.slice(1, -1);
  }

  return name;
}

/**
 * @param {string} variableName
 * @param {string} comparingName
 * @return boolean
 */
function variableNamesAreEqualCore(variableName, comparingName) {
  if (!comparingName || typeof comparingName !== 'string') {
    return false;
  }

  const escapedComparingName = getSimplifiedName(comparingName)
    .toLowerCase()
    .replace(/`/g, '\\`');
  const simplifiedName = getSimplifiedName(variableName).toLowerCase();

  return (
    simplifiedName === comparingName.toLowerCase() ||
    variableName.toLowerCase() === comparingName.toLowerCase() ||
    // note: comparingName comes from "final" values which already remove the initial and final "`" of complex names
    variableName.toLowerCase() ===
      '`' + escapeBackticks(comparingName).toLowerCase() + '`' ||
    simplifiedName === escapedComparingName
  );
}

/**
 *  e.g an equation {p=     (n)-> n+4}
 * the ast positions ignore whitespaces before the equation
 * @param {string} rawText
 * @returns {number}
 */
function countWhitespaces(rawText) {
  // count whitespaces or until end of text
  return rawText.search(/\S|$/);
}

/**
 * @typedef {{position: number, isAttribute?: boolean, isBsql?: boolean}} ReplaceOrder
 */

/**
 * @param {ParseNode} tree
 * @param {Environment} env
 * @param {string} variableName
 * @param {boolean} isLambdaOperation
 * @param {{startPosition: number, endPosition: number}|null} selectedRepeaterPosition used when the cursor is inside the repeat, and we're not dealing with outside variables that must be ignored
 * @param {boolean} onlyFindFirstInstancesOfVariable check if there are at least 2 instances of the variable, then bail out
 * @param {{replaceOrder: ReplaceOrder[]}} data
 * @param {number=} depth
 * @param {boolean=} markAllMatchingVariablesAsFalse
 * @param {number=}currentStartPosition
 * @returns {Promise<{replaceOrder: ReplaceOrder[]}>}
 */
async function getReplaceOrderFromTree(
  tree,
  env,
  variableName,
  isLambdaOperation,
  selectedRepeaterPosition,
  onlyFindFirstInstancesOfVariable = false,
  data = {
    replaceOrder: /** @type {ReplaceOrder[]} */ [],
  },
  depth = 0,
  markAllMatchingVariablesAsFalse = false,
  currentStartPosition = 0,
) {
  let insideRepeat = false;

  /**
   * ---- INTERNAL FUNCTIONS ----
   */
  /**
   * @param {string=} comparingName
   * @return boolean
   */
  function variableNamesAreEqual(comparingName) {
    return variableNamesAreEqualCore(variableName, comparingName);
  }

  /**
   * @param {object} ast
   * @param {number} parentStartPosition
   * @param {string=} nodeType
   * @returns {Promise<void>}
   */
  async function processAst(ast, parentStartPosition, nodeType) {
    let varIdentifierStartPosition = null;
    let ignorePosition = null;

    /**
     * Early returns
     */
    if (ast.type === 'function_call') {
      // named function "{max(1,5)}
      if (typeof ast.info.name.info === 'string') {
        // Here we ignore markAllMatchingVariablesAsFalse since we can't have a lambda as a repeater variable
        if (isLambdaOperation && variableNamesAreEqual(ast.info.name.info)) {
          data.replaceOrder.push({
            position: parentStartPosition + ast.startPosition,
          });
        }
        // self-invoking function "{(x -> x + 5)(5)}
      } else {
        await processAst(ast.info.name, parentStartPosition, nodeType);
      }

      for (const arg of ast.info.args) {
        await processAst(arg, parentStartPosition, nodeType);
      }

      return;
    }

    /**
     * Instances where we check if we need to ignore all matching variables in a node
     */

    if (ast.type === 'list_comprehension' && 'for' in ast.info) {
      const base = ast.info.for.info.base;

      // For list comprehension, replace only the variable we're using
      if (!markAllMatchingVariablesAsFalse &&
        typeof base.info === 'string' &&
        variableNamesAreEqual(base.info)
      ) {
        if (selectedRepeaterPosition) {
          ignorePosition = base.startPosition;
        } else {
          data.replaceOrder.push({
            position: parentStartPosition + base.startPosition,
          });
        }
      }

      if (!selectedRepeaterPosition) {
        // variables declared in a repeat override the variables inside them
        const argMatchesVariableName = ast.info.for?.info?.args?.find((arg) =>
          variableNamesAreEqual(arg.info),
        );

        if (argMatchesVariableName) {
          markAllMatchingVariablesAsFalse = true;
        }
      }

      if (nodeType === 'repeat_start') {
        insideRepeat = true;
      }
    } else if (ast.type === 'lambda') {
      const argMatchesVariableName = ast.info.args.find((arg) =>
        variableNamesAreEqual(arg.info),
      );

      // all matching variables within the lambda will reference the argument so we can skip them
      if (argMatchesVariableName) {
        markAllMatchingVariablesAsFalse = true;
      } else if (ast.info.exp) {
        await processAst(ast.info.exp, parentStartPosition, nodeType);

        return;
      }
    }

    if (!markAllMatchingVariablesAsFalse) {
      // find if a "var" variable matches the variableName to ignore all variables after it
      // "run" block command
      if (ast.info.type === 'statement_list') {
        const varIdentifier = ast.info.info.find((info) => {
          const maybeVariableName = info.info[0]?.info;

          return (
            info.type === 'initialize_local_statement' &&
            variableNamesAreEqual(maybeVariableName)
          );
        });

        if (varIdentifier && 'startPosition' in varIdentifier) {
          varIdentifierStartPosition = varIdentifier.startPosition - 4;
        }

        // "block" command inside a "lambda"
      } else if (ast.info?.statements?.info?.type === 'statement_list') {
        const varIdentifier = ast.info.statements.info.info.find((info) => {
          const maybeVariableName = info.info[0]?.info;

          return (
            variableNamesAreEqual(maybeVariableName) &&
            info.type === 'initialize_local_statement'
          );
        });

        if (varIdentifier && 'startPosition' in varIdentifier) {
          varIdentifierStartPosition = varIdentifier.startPosition - 4;
        }
      }

      const functionCallsMatchingVariableName = findType(
        ast,
        'function_call',
      ).filter((func) => variableNamesAreEqual(func.info.name.info));

      const identifiersMatchingVariableName = findType(ast, 'identifier')
        .filter((identifier) => {
          if (ast.type === 'query') {
            return variableNamesAreEqualCore(
              `@${variableName}`,
              identifier.info,
            );
          }

          return variableNamesAreEqual(identifier.info);
        })
        .filter((identifier) => {
          if (identifier.startPosition === ignorePosition) {
            return false;
          }

          // filter out functions that match the variable name
          if (!isLambdaOperation) {
            const identifiedIsAFunctionCall =
              !!functionCallsMatchingVariableName.find(
                (func) => func.startPosition === identifier.startPosition,
              );

            if (identifiedIsAFunctionCall) {
              return false;
            }
          }

          return true;
        });

      if (varIdentifierStartPosition === null) {
        const replaceOrderByType = identifiersMatchingVariableName.map(
          (node) => {
            return {
              position: node.startPosition + parentStartPosition,
              isBsql: ast.type === 'query' && node.info.startsWith('@'),
            };
          },
        );

        data.replaceOrder = [...data.replaceOrder, ...replaceOrderByType];
      } else {
        identifiersMatchingVariableName.forEach((node) => {
          if (node.startPosition < varIdentifierStartPosition) {
            data.replaceOrder.push({
              position: node.startPosition + parentStartPosition,
              isBsql: ast.type === 'query' && node.info.startsWith('@'),
            });
          }
        });
      }
    }

    const commands = findType(ast, 'command');

    for (const command of commands) {
      let dom = await parse({ ops: [{ insert: command.info }] }, env);

      await getReplaceOrderFromTree(
        dom,
        env,
        variableName,
        isLambdaOperation,
        selectedRepeaterPosition,
        onlyFindFirstInstancesOfVariable,
        data,
        depth + 1,
        markAllMatchingVariablesAsFalse,
        parentStartPosition + command.startPosition,
      );
    }

    // always reset it, unless we're inside a repeat, in which case it resets when the repeat closes
    if (!insideRepeat) {
      markAllMatchingVariablesAsFalse = false;
    }
  }

  /**
   * @param {import('../../snippet_processor/ParserUtils').NodeAttribute} attribute
   * */
  async function processAttribute(attribute) {
    if (attribute.type === 'lambda') {
      let ast;
      try {
        ast = await attribute.ast(env);
      } catch (err) {
        if (err instanceof ParseError) {
          // It's invalid syntax, so no usage
          return;
        } else {
          console.error(err.stack);
          throw err;
        }
      }

      await processAst(ast, attribute.midPosition + currentStartPosition);
    } else if (attribute.type === 'bsql') {
      let ast;
      try {
        ast = await astSQL(attribute.snippetText(), env);
      } catch (err) {
        if (err instanceof ParseError) {
          // It's invalid syntax, so no usage
          return;
        } else {
          console.error(err.stack);
          throw err;
        }
      }

      await processAst(
        ast,
        attribute.midPosition +
          currentStartPosition +
          countWhitespaces(attribute.raw),
      );
    } else {
      // avoid trimming since we want to keep track of the string length for position
      await attribute.doDom(env);


      if (attribute.dom) {
        let position = currentStartPosition;

        for (let i = 0; i < attribute.dom.length; i++) {
          const node = attribute.dom[i];
          const snippet = attribute.snippet[i];

          if (snippet.type === 'TEXT') {
            position += snippet.text.length;
          } else if (!(typeof node === 'string')) {
            await getReplaceOrderFromTree(
              node.dom,
              env,
              variableName,
              isLambdaOperation,
              selectedRepeaterPosition,
              onlyFindFirstInstancesOfVariable,
              data,
              depth + 1,
              markAllMatchingVariablesAsFalse,
              attribute.midPosition + position,
            );
          }
        }
      }
    }
  }

  /**
   * ---- END OF INTERNAL FUNCTIONS ----
   */

  if (depth > MAX_LOCATION_DEPTH) {
    // Bail out if there is a high recursion depth.
    // See DataContainer.js for more discussion.
    return data;
  }

  if (!(tree instanceof ParseNode)) {
    await processAst(tree, currentStartPosition);
  } else {
    // this must be sequential since we rely on the order of the replaceMap
    // and doing it in parallel can mess up the order if one node finishes before another
    await tree.sequentialAsyncEach(async (node) => {
      if (onlyFindFirstInstancesOfVariable && foundEnoughInstancesOfVariable(selectedRepeaterPosition, data.replaceOrder)) {
        return;
      }

      if (node.type === 'expand') {
        if (node.info && node.info.message === undefined) {
          let attrs = node.info.attributes;
          let positional = attrs.position;
          if (attrs) {
            if (
              node.tag === 'calc' ||
              node.tag === 'run' ||
              node.tag === 'button' ||
              (node.tag === 'form' &&
                ['if_start', 'if_elseif', 'repeat_start'].includes(
                  node.info.type,
                ))
            ) {
              const assigmentVariableName = attrs.keys?.name?.final;

              // variable declaration "{x=10}"
              if (
                !markAllMatchingVariablesAsFalse &&
                variableNamesAreEqual(assigmentVariableName)) {
                // +1 to take "{" into account, which the parsing ignores
                data.replaceOrder.push({
                  position: currentStartPosition + node.startPosition + 1,
                });
              }

              // note if there is an error in the equation syntax, we'll get an error
              // node and won't reach this point
              const equation = attrs.position[0];
              const ast = await equation.ast(env);
              positional = attrs.position.slice(1);

              await processAst(
                ast,
                currentStartPosition +
                  equation.startPosition +
                  countWhitespaces(equation.raw),
                node.info.type,
              );
            }

            for (let attribute of positional) {
              if (!markAllMatchingVariablesAsFalse &&
                variableNamesAreEqual(attribute.final || attribute.raw)) {
                data.replaceOrder.push({
                  position: currentStartPosition + attribute.midPosition,
                  isAttribute: true,
                });
              }

              await processAttribute(attribute);
            }
          }
        }
      }

      if (node.info && node.info.type === 'repeat_end') {
        markAllMatchingVariablesAsFalse = false;
        insideRepeat = false;
      }
    });
  }

  // when dealing with the AST, we can push in different order due to using findType or add the position twice
  const sortedOrder = data.replaceOrder.sort((a, b) => a.position - b.position);
  const dedupedOrder = [
    ...new Map(sortedOrder.map((order) => [order.position, order])).values(),
  ];

  data = {
    replaceOrder: dedupedOrder,
  };

  return data;
}

/**
 * @param {{startPosition: number, endPosition: number}|null} selectedRepeaterPosition
 * @param {ReplaceOrder[]} replaceOrder
 */
function foundEnoughInstancesOfVariable(selectedRepeaterPosition, replaceOrder) {
  if (replaceOrder.length <= 1) {
    return false;
  }

  // Avoid returning early when finding variables outside the repeater
  if (selectedRepeaterPosition) {
    return filterReplaceOrderForSelectedRepeater(selectedRepeaterPosition, replaceOrder).length > 1;
  }

  return true;
}

/**
 * @param {{startPosition: number, endPosition: number}|null} selectedRepeaterPosition
 * @param {ReplaceOrder[]} replaceOrder
 */
function filterReplaceOrderForSelectedRepeater(selectedRepeaterPosition, replaceOrder) {
  if (!selectedRepeaterPosition) {
    return replaceOrder;
  }

  return replaceOrder.filter(
    (order) =>
      order.position > selectedRepeaterPosition.startPosition &&
      order.position < selectedRepeaterPosition.endPosition,
  );
}

/**
 * @param {ParseNode} tree
 * @param {Environment} env
 * @returns {Promise<string[]>}
 */
async function getLambdasInNodes(tree, env) {
  const lambdas = [];

  if (tree instanceof ParseNode) {
    await tree.asyncEach(async (node) => {
      if (node.type === 'expand') {
        if (node.info && node.info.message === undefined) {
          const attrs = node.info.attributes;
          if (attrs && node.tag === 'calc') {
            const ast = await attrs.position[0].ast(env);
            const assigmentVariableName = attrs.keys?.name?.final;

            if (
              ast.type === 'lambda' &&
              assigmentVariableName &&
              !lambdas.includes(assigmentVariableName)
            ) {
              lambdas.push(assigmentVariableName);
            }
          }
        }
      }
    });
  }

  return lambdas;
}

/**
 * Go through the tree and find if the range (cursor position) is within any repeater nodes
 * and if the variable we want to replace is defined inside them
 *
 * @param {ParseNode} tree
 * @param {string} variableName
 * @param {number=} rangeIndex
 * @param {Environment=} env
 * @returns {Promise<{startPosition: number, endPosition: number}|null>}
 */
async function getPositionForSelectedRepeater(
  tree,
  variableName,
  rangeIndex,
  env,
) {
  const positions = {
    startPosition: null,
    endPosition: null,
  };

  let containsVariable = false;

  if (!rangeIndex || !(tree instanceof ParseNode)) {
    return null;
  }

  // find the positions for the repeat wrapping around the range
  await tree.sequentialAsyncEach(async (node) => {
    // we found our info
    if (positions.endPosition !== null || !node?.info?.type) {
      return;
    }

    if (node.info.type === 'repeat_start' && node.startPosition < rangeIndex) {
      positions.startPosition = node.startPosition;
    } else if (
      node.info.type === 'repeat_end' &&
      node.endPosition > rangeIndex
    ) {
      positions.endPosition = node.endPosition;
    } else if (
      node.info.type === 'repeat_end' &&
      node.endPosition < rangeIndex
    ) {
      // this node doesn't enclose the range, so clear up
      positions.startPosition = null;
      positions.endPosition = null;
    }
  });

  if (positions.endPosition === null || positions.startPosition === null) {
    return null;
  }

  // find if the repeat has variables we need to change
  await tree.sequentialAsyncEach(async (node) => {
    if (containsVariable) {
      return;
    }

    if (
      node.startPosition >= positions.startPosition &&
      node.endPosition <= positions.endPosition
    ) {
      if (
        node.type === 'expand' &&
        node.info &&
        node.info.message === undefined
      ) {
        let attrs = node.info.attributes;

        if (attrs) {
          if (node.tag === 'calc') {
            const assigmentVariableName = attrs.keys?.name?.final;

            // variable declaration "{x=10}"
            if (
              variableNamesAreEqualCore(variableName, assigmentVariableName)
            ) {
              containsVariable = true;
            }
          }

          if (node.tag === 'form' && node.info.type === 'repeat_start') {
            const equation = attrs.position[0];
            const ast = await equation.ast(env);

            // arguments in "for"
            if (ast.type === 'list_comprehension' && 'for' in ast.info) {
              const argMatchesVariableName = ast.info.for?.info?.args?.find(
                (arg) => variableNamesAreEqualCore(variableName, arg.info),
              );

              if (argMatchesVariableName) {
                containsVariable = true;
              }
            }
          }
        }
      }
    }
  });

  if (!containsVariable) {
    return null;
  }

  return positions;
}
