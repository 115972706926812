import Quill from 'quill';

import QuillBetterTable from 'quill-better-table';


import Block from 'quill/blots/block';
import Break from 'quill/blots/break';
import Container from 'quill/blots/container';
import Cursor from 'quill/blots/cursor';
import Inline from 'quill/blots/inline';
import Scroll from 'quill/blots/scroll';
import Text from 'quill/blots/text';

import { AlignClass, AlignStyle } from 'quill/formats/align';
import { BackgroundStyle } from 'quill/formats/background';
import Bold from 'quill/formats/bold';
import { ColorStyle } from 'quill/formats/color';
import { DirectionClass, DirectionStyle } from 'quill/formats/direction';
import { FontClass } from 'quill/formats/font';
import Image from 'quill/formats/image';
import Indent from 'quill/formats/indent';
import Italic from 'quill/formats/italic';
import Link from 'quill/formats/link';
import List, { ListContainer } from 'quill/formats/list';
import Script from 'quill/formats/script';
import { SizeClass } from 'quill/formats/size';
import Strike from 'quill/formats/strike';
import Underline from 'quill/formats/underline';



import SnippetSyntax from './highlighter';
import ImageBlot from './Image';

let Parchment = Quill.import('parchment');

export function registerTableWithQuill() {
  Quill.register({
    'modules/better-table': QuillBetterTable
  }, true);
  QuillBetterTable.overrideModules();
  return QuillBetterTable;
}

/**
 * @type {(import('quill/core/quill').Parchment.BlotConstructor | import('quill/core/quill').Parchment.Attributor)[]}
 */
const BASE_FORMATS = [
  Block,
  Break,
  Container,
  Cursor,
  Inline,
  Scroll,
  Text
];

/**
 * @type {typeof BASE_FORMATS}
 */
const FULL_FORMATS = [
  ...BASE_FORMATS,
  AlignClass,
  AlignStyle,
  BackgroundStyle,
  Bold,
  ColorStyle,
  DirectionClass,
  DirectionStyle,
  FontClass,
  Image,
  Indent,
  Italic,
  Link,
  List,
  ListContainer,
  Script,
  SizeClass,
  Strike,
  Underline,

  ImageBlot
];

export function getFullRegistry(name = 'full') {
  return getRegistry(
    name,
    FULL_FORMATS,
    [
      SnippetSyntax,
      QuillBetterTable
    ]
  );
}

/**
 * @param {string} name 
 * @param {typeof BASE_FORMATS} extraFormats 
 * @returns 
 */
export function getPlainRegistry(name = 'plain', extraFormats = null) {
  let formats = BASE_FORMATS;
  if (extraFormats?.length) {
    formats = formats.concat(extraFormats);
  }
  return getRegistry(
    name,
    formats,
    [
      SnippetSyntax
    ]
  );
};


/**
 * @type {{ [key: string]: import('quill/core/quill').Parchment.Registry }}
 */
let registryCache = {};
/**
 * Generates the registry
 * @param {string} name 
 * @param {typeof BASE_FORMATS} registries 
 * @param {{ register: Function }[]=} modules 
 */
const getRegistry = (name, registries, modules) => {
  let registry = registryCache[name];
  if (registry) {
    return registry;
  }
  registry = new Parchment.Registry();
  registryCache[name] = registry;

  registry.register(
    ...registries
  );

  if (modules?.length) {
    for (const module of modules) {
      module.register(registry);
    }
  }
  
  SnippetSyntax.register(registry);

  return registry;
};