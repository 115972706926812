import React, { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import LinearProgress from '@mui/material/LinearProgress';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import DatabaseChipIcon from '../Database/DatabaseChipIcon';
import IconButton from '@mui/material/IconButton';
import T from '@mui/material/Typography';
import RemoveIcon from '@mui/icons-material/Close';
import HostIcon from '@mui/icons-material/CloudDoneOutlined';
import HostDeniedIcon from '@mui/icons-material/CloudOffOutlined';
import { sync } from '../../Sync/syncer';
import { useTypedSelector, useTypedSelectorShallowEquals } from '../../hooks';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { APP_TARGET, checkOrg, orgId } from '../../flags';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import ListItemIcon from '@mui/material/ListItemIcon';
import { showConfirm } from '../../message';
import { BetaChip } from '../Version/VersionChip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTables } from '../../hooks/useTables';
import { OpenSpaceInNew } from '../Utilities/OpenInNewSpace';
import ConnectedSettingsContext from './ConnectedSettingsContext';


/**
 * @param {object} props
 * @param {function} props.onClose
 * @param {function} props.onAdd
 */
function AddHostDialog(props) {
  const { groupType, itemTypePluralCapitalized, itemTypePlural } = useContext(ConnectedSettingsContext);
  let [host, setHost] = useState('');
  let [type, setType] = useState('ping');

  let hasError = host.length > 0 && (host.length < 3 || !host.includes('.') || !!host.match(/\/ !\[\]{}\(\)\?:@#\$%\^&\*\+/));

  return (
    <Dialog
      open
      onClose={() => props.onClose()}
      maxWidth="sm"
    >
      <DialogContent>
        <T paragraph>Enter the website domain you want the {groupType}'s {itemTypePlural} to connect to:</T>
        <TextField
          value={host}
          autoFocus
          onChange={(e) => {
            setHost(e.target.value.trim().toLowerCase());
          }}
          error={hasError}
          placeholder="example.com"
          style={{
            width: '100%'
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HostIcon style={{ opacity: .7 }} />
              </InputAdornment>
            )
          }}
          variant="outlined"
          helperText={hasError && 'Not a valid website domain'}
        />
        <T style={{
          marginTop: 18
        }} component="div">
          {itemTypePluralCapitalized} can <Select
            value={type}
            onChange={(e) => {
              setType(/** @type {string} */ (e.target.value));
            }}
            variant="standard"
          >
            <MenuItem value="ping">send data to this site</MenuItem>
            <MenuItem value="load">send data to this site and read data on the site</MenuItem>
          </Select>
        </T>
      </DialogContent>
      <DialogActions style={{ padding: 16, textAlign: 'right' }}>
        <Button
          onClick={() => {
            props.onClose();
          }}
          style={{
            marginRight: 12
          }}
        >Cancel</Button>

        <Button
          disabled={hasError || !host.length}
          onClick={() => {
            props.onAdd(host, type);
          }}
          variant="outlined"
        >Add access</Button>
      </DialogActions>
    </Dialog>
  );
}



/**
 * Return elements in attempted array that are missing in allowed
 * @param {string[]} attempted
 * @param {string[]} allowed
 *
 * @return {string[]}
 */
function getMissing(attempted, allowed) {
  return attempted.filter(x => !allowed.includes(x));
}

/**
 * Return array of keys in src object that are missing in dest
 * @param {Object<string, any>} src
 * @param {Object<string, any>} dest
 */
function getMissingKeysInObject(src, dest) {
  return Object.keys(src).filter(key => !dest.hasOwnProperty(key));
}

/**
 * Return array of entries in src object that exist in dest
 * @param {Object<string, any>} src
 * @param {Object<string, any>} dest
 */
function getExistingPropsInObject(src, dest) {
  return Object.fromEntries(Object.entries(src).filter(([key, ]) => dest.hasOwnProperty(key)));
}


/**
 * @param {object} props
 * @param {string} props.host
 * @param {function} props.onRemove
 * @param {boolean} props.isExtra
 * @param {boolean} props.cantEdit
 */
function HostItem(props) {
  return <ListItem>
    <ListItemIcon>
      <HostIcon />
    </ListItemIcon>
    <ListItemText
      primary={props.host}
      primaryTypographyProps={{
        sx: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }}
      secondary={props.isExtra ? 'This is not being used and should be removed' : undefined}
    />
    <ListItemSecondaryAction>
      <IconButton
        disabled={props.cantEdit}
        edge="end"
        size="small"
        onClick={() => props.onRemove(props.host)}
      >
        <RemoveIcon fontSize="small" />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>;
}


/**
 * @param {object} props
 * @param {string[]} props.hosts
 * @param {string[]=} props.higherLevelHosts - as load access will also grant ping access
 * @param {string[]} props.attempted
 * @param {string} props.accessType
 * @param {function} props.onChange
 * @param {boolean} props.cantEdit
 */
function HostSelector(props) {
  const { groupType, itemTypePlural, groupTypeCapitalized } = useContext(ConnectedSettingsContext);
  let missing = getMissing(props.attempted, props.hosts.concat(props.higherLevelHosts || []));
  let extraHosts = getMissing(props.hosts, props.attempted);

  return <>
    {(props.hosts.length || missing.length) ? <div>
      <T variant="subtitle1">{groupTypeCapitalized} {itemTypePlural} can {props.accessType}</T>
      <List>
        {props.hosts.map(host => <HostItem
          key={host}
          host={host}
          cantEdit={props.cantEdit}
          isExtra={extraHosts.includes(host)}
          onRemove={(host) => {
            let newHosts = props.hosts.slice();
            newHosts.splice(newHosts.indexOf(host), 1);
            props.onChange(newHosts);
          }}
        />)}
      </List>
    </div> : null}

    {missing.length ? <div>
      <T variant="subtitle2">The {groupType}'s {itemTypePlural} attempt connections to the following blocked sites</T>
      <List>
        {missing.map(host => <ListItem key={host}
          sx={{
            pr: 15
          }}
        >
          <ListItemIcon>
            <HostDeniedIcon color="error" style={{ opacity: .6 }} fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={host}
            primaryTypographyProps={{
              variant: 'body2',
              sx: {
                opacity: .8,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }
            }}
          />
          <ListItemSecondaryAction>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                showConfirm({
                  confirmButtonText: 'Allow access',
                  cancelButtonText: 'Cancel',
                  contents: <T paragraph>Are you sure you want to allow {itemTypePlural} in this {groupType} to {props.accessType} {host}?</T>,
                  confirm: `Allow ${groupType} ${itemTypePlural} to ` + props.accessType + ' ' + host,
                  onConfirm: () => {
                    if (!props.hosts.includes(host)) {
                      props.onChange(props.hosts.concat(host));
                    }
                  }
                });
              }}
            >
              Allow access
            </Button>
          </ListItemSecondaryAction>
        </ListItem>)}
      </List>
    </div> : null}
  </>;
}


/**
 * @param {object} props
 * @param {SpaceSummaryObjectType} props.database
 * @param {string} props.databaseId
 * @param {string} props.groupId
 * @param {function} props.onRemove
 * @param {function} props.onReapprove
 * @param {boolean} props.isExtra
 * @param {boolean} props.isOutdated
 * @param {boolean} props.cantEdit
 * @param {function} props.refetch
 */
function DatabaseItem(props) {
  let secondaryText = null;

  if (props.isOutdated) {
    secondaryText = <>Update needed, queries have changed</>;
  } else if (props.isExtra) {
    secondaryText = 'This is not being used and will be removed on save';
  }

  return <ListItem
    sx={{
      border: props.isOutdated ? '1px solid #00acc0'
        : props.isExtra ? '1px solid red' : 'none',
      backgroundColor: props.isOutdated ? 'rgba(0, 172, 192, 0.08)'
        : props.isExtra ? '#FEF1F1' : '',
      borderRadius: '5px',
      marginTop: '2px',
      pr: props.isOutdated ? 22 : 11
    }}
  >
    <ListItemAvatar><DatabaseChipIcon icon={props.database?.icon} simple /></ListItemAvatar>
    <ListItemText
      primary={props.database?.name || props.databaseId}
      primaryTypographyProps={{
        sx: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }}
      secondary={secondaryText}
    />
    <ListItemSecondaryAction>
      {props.isOutdated && <Button
        disabled={props.cantEdit}
        size="small"
        color="primary"
        variant="outlined"
        onClick={() => {
          props.onReapprove(props.databaseId);
        }}
        sx={{
          mr: 2
        }}
      >Update</Button>}
      <OpenSpaceInNew databaseID={props.databaseId} />
      <IconButton
        disabled={props.cantEdit}
        edge="end"
        size="small"
        onClick={() => props.onRemove(props.databaseId)}
      >
        <RemoveIcon fontSize="small" />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>;
}


/**
 * @param {object} props
 * @param {Object<string, string[]>} props.databases
 * @param {Object<string, string[]>} props.attempted
 * @param {function} props.onChange
 * @param {boolean} props.cantEdit
 * @param {string} props.groupId
 */
export function DatabaseSelector(props) {
  /** @type {{loading: boolean, data: SpaceSummaryObjectType[], error: boolean, refetch: function}} */
  let { loading, data, refetch, error } = useTables('applications/summary/');
  const { groupType, groupTypeCapitalized, itemTypePlural } = useContext(ConnectedSettingsContext);

  if (loading) {
    return <LinearProgress variant="indeterminate" />;
  }

  if (error) {
    return <div style={{ padding: 12 }}>Error loading spaces. Please try again later.</div>;
  }

  let missing = getMissingKeysInObject(props.attempted, props.databases);
  let unused = getMissingKeysInObject(props.databases, props.attempted);
  let outdated = [];
  let databasesIds = [];
  for (let key in props.databases) {
    if (key in props.attempted) {
      if (!props.databases[key] || props.databases[key].join(' //// ') !== props.attempted[key].join(' //// ')) {
        outdated.push(key);
        databasesIds.unshift(key);
        continue;
      }
    }
    if (!unused.includes(key)) {
      databasesIds.push(key);
    }
  }
  databasesIds.push(...unused);

  return <>
    {(databasesIds.length || missing.length) ? <div>
      <T variant="subtitle1">{groupTypeCapitalized} {itemTypePlural} can query and write to tables in these Data Blaze spaces</T>
      <List>
        {databasesIds.map(databaseId => <DatabaseItem
          key={databaseId}
          refetch={refetch}
          database={data.find(x => x.id === databaseId)}
          databaseId={databaseId}
          groupId={props.groupId}
          cantEdit={props.cantEdit}
          isExtra={unused.includes(databaseId)}
          isOutdated={outdated.includes(databaseId)}
          onReapprove={(databaseId) => {
            let newDatabases = Object.assign({}, props.databases);
            newDatabases[databaseId] = props.attempted[databaseId];
            props.onChange(newDatabases);
          }}
          onRemove={(databaseId) => {
            let newDatabases = Object.assign({}, props.databases);
            delete newDatabases[databaseId];
            props.onChange(newDatabases);
          }}
        />)}
      </List>
    </div> : null}

    {missing.length ? <div>
      <T variant="subtitle2">The {groupType}'s {itemTypePlural} attempt to use tables in the following spaces</T>
      <List>
        {missing.map(databaseId => {
          let database = data.find(x => x.id === databaseId);
          return <ListItem key={databaseId}
            sx={{
              pr: 26
            }}
          >
            <ListItemAvatar><DatabaseChipIcon icon={database?.icon} simple /></ListItemAvatar>
            <ListItemText
              primary={database?.name || '[Inaccessible space]'}
              primaryTypographyProps={{
                variant: 'body2',
                sx: {
                  opacity: .8,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }
              }}
              title={`${databaseId}`}
            />
            <ListItemSecondaryAction>
              <Button
                size="small"
                variant="outlined"
                sx={{
                  marginRight: 2
                }}
                onClick={() => {
                  showConfirm({
                    confirmButtonText: 'Allow access',
                    cancelButtonText: 'Cancel',
                    contents: <T paragraph>Are you sure you want to allow {itemTypePlural} in this {groupType} to access <b>{database?.name || databaseId}</b>?</T>,
                    confirm: `Allow ${groupType} ${itemTypePlural} to access "` + (database?.name || databaseId) + '"',
                    onConfirm: () => {
                      if (!(databaseId in props.databases)) {
                        props.onChange(Object.assign({}, props.databases, { [databaseId]: props.attempted[databaseId] }));
                      }
                    }
                  });
                }}
              >
                Allow access
              </Button>
              <OpenSpaceInNew databaseID={databaseId}
                styles={{
                  style: {
                    marginRight: 24
                  }
                }}/>
            </ListItemSecondaryAction>
          </ListItem>;
        })}
      </List>
    </div> : null}
  </>;
}



/**
 * @param {object} props
 * @param {ConnectedSettingsType} props.settings
 * @param {string[]} props.attemptedPingHosts
 * @param {string[]} props.attemptedLoadHosts
 * @param {string[]} props.attemptedConnectedAddons
 * @param {Object<string, string[]>} props.attemptedDatabaseQueries
 * @param {function} props.onSave
 * @param {function} props.onClose
 * @param {'NOT_OWNER'|'ORG_LIMITS'|'SHARED'|'NOT_VERIFIED'} props.editBlockedReason
 * @param {string} props.groupId
 */
function ConnectedSnippetInner(props) {
  let [isModified, setIsModified] = useState(false);
  const {
    groupType,
    groupTypeCapitalized,
    itemType,
    itemTypePluralCapitalized,
    titleCapitalized,
    titlePluralCapitalized,
    title,
  } = useContext(ConnectedSettingsContext);


  let settings = props.settings || {
    is_connected: false,
    load_hosts: [],
    ping_hosts: [],
    addons: [],
    database_queries: {},
    connector: null
  };

  let cantEdit = !!props.editBlockedReason;
  let cantEditReason;
  if (props.editBlockedReason === 'NOT_OWNER') {
    cantEditReason = <>Only {groupType} owners can modify Connected {titleCapitalized} settings.</>;
  } else if (props.editBlockedReason === 'ORG_LIMITS') {
    cantEditReason = <>Only organization administrators can modify Connected {titleCapitalized} settings.</>;
  } else if (props.editBlockedReason === 'SHARED') {
    cantEditReason = <>Cannot connect a shared {groupType} outside a Business organization.</>;
  } else if (props.editBlockedReason === 'NOT_VERIFIED') {
    cantEditReason = <>You must verify your email to create or edit Connected {groupTypeCapitalized}.</>;
  }
  const cantDisconnect = cantEdit && props.editBlockedReason !== 'SHARED';

  let [showAddHost, setShowAddHost] = useState(false);
  let [isConnected, setIsConnected] = useState(settings.is_connected);
  let [pingHosts, setPingHosts] = useState(isConnected ? (settings.ping_hosts || []) : []);
  let [loadHosts, setLoadHosts] = useState(isConnected ? (settings.load_hosts || []) : []);
  let [databaseQueries, setDatabaseQueries] = useState(isConnected ? (settings.database_queries || {}) : {});
  let [addons, setAddons] = useState(isConnected ? (settings.addons || []) : []);
  let [connector, setConnector] = useState(isConnected ? settings.connector : null);


  let availableAddons = useTypedSelector(() => {
    let availableAddons = sync.activeAddons();
    for (let key in availableAddons) {
      let a = availableAddons[key];
      if (!a.addon.addonOptions.connected) {
        delete availableAddons[key];
      }
    }
    return availableAddons;
  });


  let addonsArray = Object.keys(availableAddons).sort();
  for (let addon of addons) {
    if (!addonsArray.includes(addon)) {
      addonsArray.unshift(addon);
    }
  }


  let {
    isConnector,
    myOrgId,
    orgUpgradeable,
    uid
  } = useTypedSelectorShallowEquals(store => {
    let isConnector = false, notOrgConnector = true;

    let uid = store.userState.uid;
    if (connector === 'u:' + uid) {
      isConnector = true;
    }

    let myOrgId = checkOrg(store) && orgId(store);
    if (myOrgId) {
      if ('o:' + myOrgId === connector) {
        isConnector = true;
        notOrgConnector = false;
      }
    }

    return {
      isConnector,
      myOrgId,
      orgUpgradeable: isConnector && (!!myOrgId) && notOrgConnector,
      uid
    };
  });



  let neededAddons = props.attemptedConnectedAddons.filter(x => !addons.includes(x));

  function removeConnection() {
    setIsConnected(!isConnected);
    setPingHosts([]);
    setLoadHosts([]);
    setAddons([]);
    setDatabaseQueries({});
    setConnector(null);
  }

  function saveChanges() {
    if (isConnected) {
      const queriesWithUnusedExcluded = getExistingPropsInObject(databaseQueries, props.attemptedDatabaseQueries);
      props.onSave({
        is_connected: isConnected,
        ping_hosts: pingHosts,
        load_hosts: loadHosts,
        database_queries: queriesWithUnusedExcluded,
        addons,
        connector
      });
    } else {
      props.onSave({
        is_connected: false
      });
    }
  }


  function renderInner() {
    if (!isConnected) {
      return <>
        <T paragraph>{itemTypePluralCapitalized} in Connected {titlePluralCapitalized} can send data to and read data on websites other than the website you are using the {itemType} on.</T>
        <T paragraph variant="body2" color="textSecondary">Connected {titlePluralCapitalized} are a Text Blaze Pro or Text Blaze Business feature.</T>
        <T variant="body2" color="textSecondary">Note that you can only share Connected {titlePluralCapitalized} within a Text Blaze Business Organization.</T>
      </>;
    } else if (!isConnector) {
      return <>
        <T paragraph>This is a Connected {titleCapitalized} but was not connected by you. Connected {titlePluralCapitalized} may only be shared within Text Blaze Business organizations.</T>
      </>;
    } else {
      return <>
        {orgUpgradeable && <Alert severity="info" style={{ marginBottom: 24 }}>
          <T paragraph variant="body2">You connected this {groupType}, but it's not connected for your Business organization. Would you like to connect it for your whole organization so other users in your organization can use it?</T>
          <Button
            disabled={cantEdit}
            onClick={() => {
              setConnector('o:' + myOrgId);
            }}
            size="small"
            variant="outlined"
          >Connect for organization</Button>
        </Alert>}

        {((databaseQueries
                && Object.keys(databaseQueries).length)
            || (props.attemptedDatabaseQueries && Object.keys(props.attemptedDatabaseQueries).length)) ? <Paper
            variant="outlined"
            style={{
              padding: 18
            }}
          >
            <T variant="h6" paragraph>Connected table spaces</T>

            <DatabaseSelector
              databases={databaseQueries}
              attempted={props.attemptedDatabaseQueries}
              cantEdit={cantEdit}
              onChange={(newDatabaseQueries) =>{
                setDatabaseQueries(newDatabaseQueries);
                setIsModified(true);
              }}
              groupId={props.groupId}
            />

          </Paper> : null}

        {(addonsArray.length > 0) && <Paper
          variant="outlined"
          style={{
            padding: 18,
            marginTop: 24
          }}
        >
          <T variant="h6" paragraph>Connected command packs</T>
          <Select
            style={{ width: '100%' }}
            multiple
            value={addons}
            onChange={(e) => {
              setIsModified(true);

              let newAddons = /** @type {string[]} */ (e.target.value);
              for (let addon of newAddons) {
                if (!addons.includes(addon)) {
                  setAddons(addons.concat(addon));

                  return;
                }
              }
              for (let addon of addons) {
                if (!newAddons.includes(addon)) {
                  let finalAddons = addons.slice();
                  finalAddons.splice(addons.indexOf(addon), 1);
                  setAddons(finalAddons);
                  return;
                }
              }
            }}
            input={<Input />}
            renderValue={selected => (
              <div style={{
                display: 'flex',
                flexWrap: 'wrap'
              }}>
                { /** @type {string[]} */ (selected).map((value) => <Chip
                  size="small"
                  variant="outlined"
                  key={value}
                  style={{ marginRight: 2, marginLeft: 2, marginTop: 1, marginBottom: 1 }}
                  label={`{${value.toLowerCase()}}`}
                />)}
              </div>
            )}
            variant="standard"
          >
            {addonsArray.map(command => (
              <MenuItem key={command} value={command}>
                <Checkbox checked={addons.includes(command)} />
                <ListItemText primary={<>{`{${command.toLowerCase()}}`}{neededAddons.includes(command.toLowerCase()) ? <HostDeniedIcon color="error" fontSize="small" style={{
                  verticalAlign: 'middle',
                  marginLeft: 12,
                  opacity: .6
                }} /> : null}</>} secondary={availableAddons[command] ? availableAddons[command].name : 'Command pack not installed'} />
              </MenuItem>
            ))}
          </Select>
          <T variant="caption" color="textSecondary" style={{
            marginTop: 12,
            display: 'inline-block'
          }}>Connections from other commands pack commands will not be allowed</T>
          {neededAddons.length > 0 && <T variant="body2" style={{ marginTop: 12 }}> <HostDeniedIcon color="error" fontSize="small" style={{
            verticalAlign: 'middle',
            marginRight: 8,
            opacity: .6
          }} /> Unconnected requested addons: <b>{neededAddons.map(x => '{' + x + '}').join(', ')}</b></T>}
        </Paper>}


        {APP_TARGET !== 'PAGE' && <Paper
          variant="outlined"
          style={{
            padding: 18,
            marginTop: 24
          }}
        >
          <T variant="h6" paragraph>Connected websites</T>

          {(pingHosts.length || loadHosts.length || props.attemptedPingHosts.length || props.attemptedLoadHosts.length) ? <>
            <HostSelector
              hosts={pingHosts}
              onChange={(newHosts) => {
                setPingHosts(newHosts);
                setIsModified(true);
              }}
              accessType="send data to"
              attempted={props.attemptedPingHosts || []}
              higherLevelHosts={loadHosts}
              cantEdit={cantEdit}
            />

            <HostSelector
              hosts={loadHosts}
              onChange={(newHosts) => {
                setLoadHosts(newHosts);
                setIsModified(true);
              }}
              accessType="both send data to and read data from"
              attempted={props.attemptedLoadHosts || []}
              cantEdit={cantEdit}
            />
          </>
            :
            <T style={{ marginTop: 18, marginBottom: 18 }} color="textSecondary">No connected website accesses allowed</T>}

          <div style={{ textAlign: 'right', marginTop: 18 }}>
            <Button
              disabled={cantEdit}
              size="small"
              onClick={() => {
                setShowAddHost(true);
              }}
            >Add additional allowed access</Button>

            {showAddHost && <AddHostDialog
              onClose={() => setShowAddHost(false)}
              onAdd={(host, type) => {
                setIsModified(true);

                if (type === 'ping') {
                  if (!pingHosts.includes(host)) {
                    setPingHosts(pingHosts.concat(host));
                  }
                } else {
                  if (!loadHosts.includes(host)) {
                    setLoadHosts(loadHosts.concat(host));
                  }
                }

                setShowAddHost(false);
              }}
            />}
          </div>
        </Paper>}
      </>;
    }
  }

  return <Dialog
    open
    onClose={() => {
      if (isModified) {
        showConfirm({
          confirmButtonText: 'Save',
          onConfirm: () => {
            saveChanges();
          },
          cancelButtonText: 'Don\'t save',
          onCancel: () =>{
            props.onClose();
          },
          contents: `Do you want to save your changes to the Connected ${groupTypeCapitalized} settings?`
        });
      } else {
        props.onClose();
      }
    }}
    maxWidth="sm"
  >
    <DialogContent sx={{
      minWidth: {
        xs: '280px',
        sm: '600px'
      }
    }}
    >
      <T
        style={{
          marginBottom: 24
        }}
        variant="h6"
        component="div"
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isConnected ? <>
            <div style={{ flex: 1 }}>Connected {title}</div>
            <Button
              disabled={cantDisconnect}
              onClick={() => {
                setIsModified(true);
                removeConnection();
              }}
              variant="outlined"
            >
              Disconnect {groupType}
            </Button>
          </> : <>
            <div style={{ flex: 1 }}>Not a Connected {titleCapitalized}</div>
            <Button
              disabled={cantEdit}
              variant="outlined"
              onClick={() => {
                setIsModified(true);
                setConnector(myOrgId ? ('o:' + myOrgId) : ('u:' + uid));
                setIsConnected(!isConnected);
              }}
            >
              Connect {groupType}
            </Button>
          </>}
        </div>
      </T>
      {cantEdit &&
          <Alert severity="info" style={{ marginBottom: 18 }}>{cantEditReason}</Alert>
      }
      {renderInner()}
    </DialogContent>
    <DialogActions style={{ padding: '16px 24px', display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1 }}><BetaChip /> <T
        variant="body2"
        style={{
          display: 'inline-block',
          marginLeft: 24
        }}><a style={{
          display: 'inline-flex',
          alignItems: 'center'
        }} href="https://blaze.today/connected" target="_blank" rel="noopener noreferrer">Learn more<ChevronRightIcon /></a></T></div>
      <Button
        onClick={() => {
          props.onClose();
        }}
        style={{
          marginRight: 12
        }}
      >Cancel</Button>

      <Button
        disabled={(cantEdit && (cantDisconnect || isConnected)) || (!isModified)}
        color="primary"
        onClick={() => {
          saveChanges();
        }}
        variant="contained"
      >Save</Button>
    </DialogActions>
  </Dialog>;
}

const ConnectedGroupDialog = React.memo(ConnectedSnippetInner);

export default  ConnectedGroupDialog;