import React from 'react';
import { Typography as T } from '@mui/material';
import { showConfirm } from '../../message';
import AddonGrantList from './AddonGrantList';


/**
 * @param {{ grants: GrantsType, onConfirm: any, onCancel?: any, confirmButtonText?: string }} config 
 */
export function confirmAddonGrants({ grants, onConfirm, onCancel, confirmButtonText }) {
  const grantList = <AddonGrantList grants={grants} />;
  let confirmText = confirmButtonText || 'Activate';
  showConfirm({
    cancelButtonText: 'Cancel',
    confirmButtonText: confirmText,
    onConfirm,
    onCancel,
    contents: <>
      <T variant="body1">Please review this command pack's permissions and confirm you want to {confirmText.toLocaleLowerCase()} it:</T>
      {grantList}
    </>
  });
};