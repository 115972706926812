import { fillDom, domToStream, trimDom, trimNotes } from './SnippetProcessor';
import { parse, tokenize } from './Parser';
import { toStr, toFn, ast, findType, toLst } from './Equation';
import { pushRepeatTokens } from './Lexer';
import { sanitizeText } from '../engine_utilities';
import { parseTimeShift } from './time_shift';
import moment from 'moment';
import { isAddonCommand } from '../utilities';


/** @typedef {{position: import('./ParserUtils').NodeAttribute[], keys: Object<string, NodeAttribute>, spec: object}} AttributesType */

let nameCounter = 0;
export const AUTO_PREFIX = '{auto_name}_';
export function newName() {
  nameCounter++;
  return AUTO_PREFIX + nameCounter;
}


export function DataRequiredError() {
  let lastPart = new Error().stack.match(/[^\s]+$/);
  this.stack = `${this.name} at ${lastPart}`;
}
DataRequiredError.prototype = Object.create(Error.prototype);
DataRequiredError.prototype.name = 'DataRequiredError';
DataRequiredError.prototype.message = '';
DataRequiredError.prototype.constructor = DataRequiredError;


export function ParseError(message = '') {
  this.message = message;
  let lastPart = new Error().stack.match(/[^\s]+$/);
  this.stack = `${this.name} at ${lastPart}`;
  this.errorPosition = null;
}
ParseError.prototype = Object.create(Error.prototype);
ParseError.prototype.name = 'ParseError';
ParseError.prototype.message = '';
ParseError.prototype.constructor = ParseError;


const DEFAULT_TYPE_ERROR = 'Invalid setting.';



export class NodeAttribute {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.name = data.name || null;
    /** @type {'snippet'|'dom'|'evaluated'|'final'} */
    this.stage = data.stage || 'snippet';
    /** @type {boolean} */
    this.constant = data.constant || false;
    /** @type {boolean} */
    this.static = data.static || false;
    /** @type {string} */
    this.mode = data.mode;
    /** @type {string} */
    this.typeError = data.typeError || DEFAULT_TYPE_ERROR;
    /** @type {ListType} */
    this.list = data.list;
    /** @type {object} */
    this.config = data.config;
    /** @type {Set} */
    this.deps = data.deps || undefined;
    /** @type {number} */
    this.startPosition = data.start;
    /** @type {number} */
    this.midPosition = data.mid;
    /** @type {number} */
    this.endPosition = data.end;
    /** @type {string} */
    this.raw = data.raw;
    // Not specified by the user.
    /** @type {boolean} */
    this.fillIn = data.fillIn;
    /** @type {boolean} */
    this.repeatable = data.repeatable || false;
    /** @type {boolean} */
    this.positional = data.positional || false;

    // cache of abstract syntax tree for equations
    this.astCache = null;

    if (this.stage === 'final') {
      this.final = data.value;
    } else if (this.stage === 'snippet') {
      // You should use snippetText()
      this.snippet = data.value;
    } else if (this.stage === 'dom') {
      // Array of parts (either a string, or a dom tree tagged as an addon or not)
      /** @type {(string|{dom: import("./ParseNode").default, isAddon: boolean, locations: string[]})[]} */
      this.dom = [data.value];
    } else if (this.stage === 'evaluated') {
      this.evaluated = data.value;
    }
    /**
     * @type {import("./ParseNode").default[]}
     */
    this.tokens = null;

    this.type = data.type || 'string';
  }

  /**
   * We need to clone the node when we have a {repeat}. Things like
   * the dom or ast cache should be cleared as the location paths will change
   * and any recording of those paths (e.g. storeId in addons) shouldn't
   * persist to the clones.
   * 
   * @return {NodeAttribute}
   */
  clone() {
    let attr = new NodeAttribute({
      name: this.name,
      constant: this.constant,
      static: this.static,
      mode: this.mode,
      config: this.config,
      fillIn: this.fillIn,
      type: this.type,
      start: this.startPosition,
      mid: this.midPosition,
      end: this.endPosition,
      repeatable: this.repeatable,
      deps: this.deps ? new Set(this.deps) : undefined,
      positional: this.positional,
      stage: this.stage,
      list: this.list,
      raw: this.raw
    });
    attr.typeError = this.typeError;
    attr.snippet = this.snippet;
    attr.evaluated = this.evaluated;
    attr.final = this.final;

    attr.astCache = this.astCache;
    
    if (this.stage === 'dom') {
      attr.stage = 'snippet';
    }
    attr.dom = null;

    return attr;
  }

  /**
   * @param {import("./DataContainer").Environment} env 
   * @param {string=} startRule - optional Nearley start rule for parsing (defaults to 'calc')
   */
  async ast(env, startRule) {
    if (this.astCache) {
      return this.astCache;
    }

    await this.doDom(env);

    /** @type {object[]} */
    let eqn = this.snippet;

    // We allow a shorthand syntax in {repeat}'s to specify the iterator:
    //   `for x in [1,2,3]`
    if (this.name === 'times') {
      pushRepeatTokens(eqn);
    }
    
    this.astCache = ast(eqn, env, {
      startRule
    });

    return this.astCache;
  }

  /**
   * @param {import("./DataContainer").Environment} env
   * 
   * @return {Promise<Set>}
   */
  async getDependencies(env) {
    let hasDom = !!this.dom;
    await this.doDom(env);

    let cleanUp = () => {
      if (!hasDom && this.dom) {
        // don't want to save the dom we build up when doing dependencies
        // as the locations array won't be the same as when we do processing
        this.dom = null;
        this.stage = 'snippet';
      }
    };

    if (this.deps) {
      cleanUp();
      return this.deps;
    }

    let dom = this.dom;
    if (!dom) {
      if (this.mode === 'bsql') {
        let ids = [];
        let commands = this.evaluated.filter(x => x.type === 'COMMAND').map(x => x.command);
        let identifiers = this.evaluated.filter(x => x.type === 'IDENTIFIER' && x.identifier[0] === '@').map(x => x.identifier.slice(1));

        for (let id of identifiers) {
          ids.push(id.toLowerCase());
        }

        for (let command of commands) {
          let isAddon = isAddonCommand(command);

          let myEnv = env.derivedConfig({
            mode: isAddon ? 'addon' : 'attribute'
          }, 'deps' + this.startPosition);

          let dom = await parse({ ops:[{ insert: command }] }, myEnv);
          let nodes = dom.findAll(n => !!n.dependencies);
          for (let node of nodes) {
            ids.push(...node.dependencies);
          }
        }
        
        this.deps = new Set(ids);

        cleanUp();
        return this.deps;
      } else if (this.mode === 'equation' && !this.list) {
        let tree;
        try {
          tree = await this.ast(env, this.config?.blockAST ? 'Block' : undefined);
        } catch (err) {
          throw new ParseError('Invalid formula for ' + (this.name ? `"${this.name}"` : 'setting') + ' – ' + err.message);
        }

        let ids = findType(tree, 'identifier').map(x => x.info.toLowerCase());
        let commands = findType(tree, 'command');

        for (let command of commands) {
          let isAddon = isAddonCommand(command.info);

          let myEnv = env.derivedConfig({
            mode: isAddon ? 'addon' : 'attribute'
          }, 'deps' + this.startPosition);

          let dom = await parse({ ops:[{ insert: command.info }] }, myEnv);
          let nodes = dom.findAll(n => !!n.dependencies);
          for (let node of nodes) {
            ids.push(...node.dependencies);
          }
        }
        
        this.deps = new Set(ids);

        cleanUp();
        return this.deps;
      }
    }

    if (this.deps === undefined && dom) {
      let nodes = [];
      dom.forEach(d => {
        if (typeof d === 'string') {
          //pass
        } else {
          nodes.push(...d.dom.findAll(n => !!n.dependencies));
        }
      });
      
      if (nodes.length) {
        if (nodes.length === 1) {
          this.deps = nodes[0].dependencies;
        } else {
          let item = nodes[0].dependencies;
          for (let i = 1; i < nodes.length; i++) {
            for (let dependency of nodes[i].dependencies) {
              item.add(dependency);
            }
          }
          this.deps = item;
        }
      }
    }

    if (!this.deps) {
      this.deps = null;
    }
    cleanUp();
    return this.deps;
  }

  

  /**
   * @return {string}
   */
  snippetText() {
    let attr = this.snippet;
    const isSQLOrEquation = this.mode === 'equation' || this.mode === 'bsql';
    if (typeof attr === 'string') {
      return intermediateToNormal(isSQLOrEquation ? attr : attr.trim());
    }
    let str = '';
    for (let part of attr) {
      if (part.type === 'TEXT') {
        str += part.text;
      } else if (part.type === 'COMMAND') {
        str += part.command;
      } else if ('source' in part) {
        str += part.source;
      } else {
        throw new ParseError('Unknown part: ' + JSON.stringify(part));
      }
    }
    return intermediateToNormal(isSQLOrEquation ? str : str.trim());
  }

  /**
   * @param {import("./DataContainer").Environment} env
   */
  async doDom(env) {
    if (this.stage === 'snippet') {

      if (!Array.isArray(this.snippet)) {
        this.dom = null;
        this.evaluated = this.snippet.trim();
        this.stage = 'evaluated';
        return;
      }
      
      
      if ((this.mode !== 'equation' && this.mode !== 'bsql') || this.list) {
        let parts = [];
        let hasCommand = false;
        for (let item of this.snippet) {
          if (item.type === 'TEXT') {
            parts.push(item.text);
          } else if (item.invalidCommand) {
            parts.push(`[Error - This setting doesn't allow commands like "${item.command}"]`);
          } else {
            hasCommand = true;

            let isAddon = isAddonCommand(item.command);

            let myLocation = 'attr - ' + parts.length + ' - ' + this.startPosition;

            let locations = [myLocation];
            if (env.locations.length === 0) {
              locations.unshift('local_data - root');
            }

            let myEnv = env.derivedConfig({
              mode: isAddon ? 'addon' : 'attribute'
            }, locations);

            let dom = await parse({ ops:[{ insert: item.command }] }, myEnv);
            
            parts.push({
              dom,
              isAddon,
              locations
            });
          }
        }

        if (!hasCommand) {
          this.dom = null;
          this.evaluated = parts.join('');
          // don't trim lists
          if (!this.list) {
            this.evaluated = this.evaluated.trim();
          }
          this.stage = 'evaluated';
          return;
        }


        // don't trim lists
        if (!this.list) {
          if (typeof parts[0] === 'string') {
            parts[0] = parts[0].trimStart();
          }
          if (typeof parts[parts.length - 1] === 'string') {
            parts[parts.length - 1] = parts[parts.length - 1].trimEnd();
          }
        }
        this.dom = parts;
        this.stage = 'dom';
      } else {
        // it's an equation so pass the tokenized snippet array forward
        this.dom = null;
        this.evaluated = this.snippet;
        this.stage = 'evaluated';
      }
    }
  }

  /**
   * @param {import("./DataContainer").Environment} env
   */
  async tokenize(env) {
    this.tokens = (await tokenize({
      ops: [{ insert: this.raw.trimStart() }]
    }, 'text', env));
  }

  /**
   * @param {import("./DataContainer").Environment} env
   * @param {string} onAddonValue - used in tokenization when there is an addon as we won't actually evaluate the addon
   * 
   * @return {Promise<object>}
   */
  async value(env, onAddonValue = '') {
    if (!this.dom) {
      await this.doDom(env);
    }
    
    if (this.stage === 'dom') {
      /** @type {any[]} */
      let dom = [];
      
      try {
        for (let d of this.dom) {
          if (typeof d === 'string') {
            dom.push(d); 
            continue;
          }
          if (d.isAddon && env.config.stage === 'tokenization') {
            return (this.config && this.config.default) || onAddonValue;
          }

          let myEnv = env.derivedConfig({
            mode: d.isAddon ? 'addon' : 'attribute'
          }, d.locations);

          if (this.constant) {
            // If we're a constant attribute, we can't depend on form data
            myEnv.config.noData = true;
          }

          if (d.isAddon) {
            // shouldn't pass this setting down between nested addons
            delete myEnv.config.rootAddonParentIsNoData;
            
            if (myEnv.config.noData) {
              // parent noData settings shouldn't propagate to addons, as the addon will be evaluated in a separate parse()
              delete myEnv.config.noData;
              // But they still need to apply tot he parentWrapperEnv, e.g.:
              //   {formtext: name={cmd-pck: {=abc}}}
              // The {=abc} is invalid at this level.
              myEnv.config.rootAddonParentIsNoData = true;
            }
          }
          let final = trimDom(await fillDom(d.dom.clone(), myEnv));
          trimNotes(final);
          dom.push(final);
        }
        if (dom.find(x => x.type === 'expand' && !['click', 'key', 'wait', 'text', 'html', 'image'].includes(x.tag))) {
          throw new DataRequiredError();
        }
      } catch (error) {
        if (error instanceof DataRequiredError) {
          let e = new ParseError((this.name ? `"${this.name}"` : 'This') + ' cannot depend on form data here');
          e.stack = e.stack + '\n\nCaused by:\n\n' + error.stack;
          throw e;
        }
        throw error;
      }

      let isStrictList = false;
      if (this.list) {
        // We need to check if it is a strict list variable, meaning it maps
        // to '[1,2,3]' and nothing else. If it is, we won't escape it and
        // will use it directly. This allows us to do {=list} to set the value
        // of the setting.
        //
        // If it isn't a strict list, we'll escape the contents (commas and, for
        // keyed lists, equals). This allows us to do `a,{="1,2"},b` and have that
        // map to three elements with the middle one being "1,2" instead of
        // splitting that middle one into two separate elements.

        let nodeCount = 0;
        for (let item of dom) {
          if (typeof item === 'string') {
            if (item.trim().length) {
              break;
            }
          } else {
            nodeCount++;
            if (nodeCount > 1) {
              break;
            }
            let str = domToStream(item, 'text').join('');
            let list;
            try {
              list = toLst(str);
            } catch (_) {};
            if (list) {
              isStrictList = true;
              break;
            }
          }
        }
      }

      this.evaluated = dom.map(s => {
        if (typeof s === 'string') {
          return s;
        } else {
          let str = domToStream(s, 'text').join('');
          if (this.list) {
            if (!isStrictList) {
              str = sanitizeText(str, {
                escapeCommas: true,
                escapeCommands: true,
                escapeEquals: this.list === 'keys',
                escapeSlashes: true,
                escapeWhitespace: null
              });
            }
          }
          return str;
        } 
      }).join('');
      // things will be recreated on rerun in attributes, so no need to cache
      if (env.config.mode !== 'attribute') {
        this.dataHash = env.data.getDataHash();
      }
      this.stage = 'evaluated';
    }
    if (this.stage === 'evaluated') {
      if (this.dataHash && this.dataHash !== env.data.getDataHash()) {
        if (this.dom) {
          this.stage = 'dom';
        } else {
          this.stage = 'snippet';
        }
        return this.value(env);
      }

      /** @type {function(string): any} */
      let parseAtom = (atom) => {
        // Commas are used for splitting lists, restore them; whitespace and '{' is also placeholdered
        // If it's an array, then it's a tokenized equation and we shouldn't do anything
        let atomBase = Array.isArray(atom) ? atom : intermediateToNormal(atom);

        if (this.fillIn) {
          // We don't want to validate default values as they may be used to express
          // "missingness" which the command will have specific logic to handle.
          //
          // E.g. you might have a number attribute with "NO_NUMBER" as the default.
          return atomBase;
        }

        if (this.type === 'number') {
          let val = Number(atomBase);
          if (isNaN(val) || atom.trim() === '') {
            throw new ParseError(`"${this.name}" must be a number but was "${toStr(atomBase)}"`);
          }
          if (this.config) {
            if (this.config.minimum !== undefined) {
              if (val < this.config.minimum) {
                throw new ParseError(`"${this.name}" cannot be less than ${this.config.minimum} but was ${toStr(atomBase)}`);
              }
            }
            if (this.config.maximum !== undefined) {
              if (val > this.config.maximum) {
                throw new ParseError(`"${this.name}" cannot exceed ${this.config.maximum} but was ${toStr(atomBase)}`);
              }
            }
            if (this.config.integer) {
              if (Math.round(val) !== val) {
                throw new ParseError(`"${this.name}" cannot be a decimal number but was ${toStr(atomBase)}`);
              }
            }
          }
          return val;
        } else if (this.type === 'boolean') {
          if (atom === 'yes') {
            return true;
          } else if (atom === 'no') {
            return false;
          } else {
            throw new ParseError(`"${this.name}" must be "yes" or "no" but was "${toStr(atomBase)}"`);
          }
        } else if (this.type === 'date') {
          if (this.config && this.config.doNotParse) {
            return atomBase;
          }
          let date = moment(atomBase, (this.config && this.config.format) ? [this.config.format, 'YYYY-MM-DD', moment.ISO_8601] : [moment.ISO_8601, 'YYYY-MM-DD', 'LL', 'L', 'll', 'l'], env.config.locale, true);
          if (!date.isValid()) {
            throw new ParseError(`"${atomBase}" is not a valid date - try using "${(this.config && this.config.format) ? this.config.format : 'YYYY-MM-DD'}" format`);
          }
          return date.format('YYYY-MM-DD');
        } else if (this.type === 'datetime') {
          if (this.config && this.config.doNotParse) {
            return atomBase;
          }
          let datetime = moment(atomBase, (this.config && this.config.format) ? [this.config.format, 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD', 'HH:mm', moment.ISO_8601] : [moment.ISO_8601, 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD', 'HH:mm', 'LLLL', 'llll', 'LTS', 'LLL', 'lll', 'LT', 'LL', 'L', 'll', 'l'], env.config.locale, true);
          if (!datetime.isValid()) {
            throw new ParseError(`"${atomBase}" is not a valid datetime - try using "${(this.config && this.config.format) ? this.config.format : 'YYYY-MM-DD HH:mm'}" format`);
          }
          return datetime.format('YYYY-MM-DD HH:mm');
        } else if (this.type === 'time') {
          if (this.config && this.config.duration) {
            return parseTimeShift(atomBase, true, this.typeError || 'Invalid time shift');
          }
          return parseTimeShift(atomBase, false, this.typeError || 'Invalid time shift');
        } else if (this.type === 'string' || this.type === 'equation' || this.type === 'time_format' || this.type === 'key' || this.type === 'numeric_format' || this.type === 'shortcut' || this.type === 'selector' || this.type === 'bsql' || this.type === 'database') {
          // Things treated as plain text and not validated in any way
          return atomBase;
        } else if (this.type === 'identifier') {
          if (!atomBase) {
            throw new ParseError(`"${atomBase}" is not a valid variable identifier`);
          }
          return atomBase.toLocaleLowerCase();
        } else if (this.type === 'lambda') {
          return toFn(atomBase, env);
        } else if (Array.isArray(this.type)) {
          let val = atomBase;
          if (this.type.includes(val)) {
            return val;
          } else if (this.config && this.config.insensitive && this.type.map(x => x.toLocaleUpperCase()).includes(val.toLocaleUpperCase())) {
            return this.type[this.type.map(x => x.toLocaleUpperCase()).indexOf(val.toLocaleUpperCase())];
          } else {
            if (!this.typeError || this.typeError === DEFAULT_TYPE_ERROR) {
              throw new ParseError(`"${this.name}" must be one of ${this.type.map(x => '"' + x + '"').join(', ')}`);
            } else {
              throw new ParseError(this.typeError);
            }
          }
        } else if (typeof this.type === 'function') {
          if (this.type(atomBase)) {
            return atomBase;
          } else {
            throw new ParseError(this.typeError);
          }
        } else {
          throw new ParseError(`"${this.name}" has an unknown type "${this.type}"`);
        }
      };

      if (this.list) {
        // First to try to parse it as a fully defined list
        let list;
        try {
          list = toLst(this.evaluated);
        } catch (_) {};

        if (list) {
          if (this.list === 'positional') {
            if (Object.keys(list.keys).length) {
              throw new ParseError(`"${this.name}" should not have named elements`);
            }
            this.final = await Promise.all(list.positional.map(x => parseAtom(toStr(x))));
          } else {
            let res = Object.create(null);
            if (list.positional.length) {
              throw new ParseError(`"${this.name}" should not have positional elements`);
            }
            for (let key in list.keys) {
              res[key] = await parseAtom(toStr(list.keys[key]));
            }
            this.final = res;
          }
        } else {
          // If that fails fall back to quick entry mode
          let current = escapedToIntermediate(this.evaluated);
          let entries = attributeSimpleListStringToObject(current, this.list, null, env);
          
          

          if (this.list === 'positional') {
            this.final = await Promise.all(entries.map(x => parseAtom(x.trim())));
          } else {
            let final = Object.create(null);
            for (let entry of entries) {
              let key = await parseAtom(entry[0].trim());
              if (!key) {
                throw new ParseError('Cannot have blank key in ' + (this.name ? `"${this.name}"` : 'setting'));
              }
              if (key in final) {
                throw new ParseError('Cannot have duplicate key "' + key + '" in ' + (this.name ? `"${this.name}"` : 'setting'));
              }
              final[key] = await parseAtom(entry[1].trim());
            }

            this.final = final;
          }
        }
      } else {
        this.final = await parseAtom(this.evaluated);
      }
      this.stage = 'final';
    }
    
    if (this.stage === 'final') {
      if (this.dataHash && this.dataHash !== env.data.getDataHash()) {
        if (this.dom) {
          this.stage = 'dom';
        } else {
          this.stage = 'snippet';
        }
        return this.value(env);
      }
      return this.final;
    }
  }
}

/**
 * Converts a raw attribute string to a list.
 * 
 * @param {string} str
 * @param {string} type
 * @param {{start: number, end: number}[]} commands
 * 
 * @return {object[]}
 */
export function attributeListStringToObject(str, type, commands) {
  let list;

  try {
    list = toLst(str);
  } catch (err) {
    // pass
  }

  if (list) {
    if (type === 'positional') {
      return list.positional;
    } else {
      return Object.entries(list.keys);
    }
  } else {
    return attributeSimpleListStringToObject(str, type, commands);
  }
}


/**
 * Converts a raw attribute string to a list.
 * 
 * @param {string} str
 * @param {string} type
 * @param {{start: number, end: number}[]=} commands - if defined we also escape '\' as we haven't converted to intermediate
 * @param {import("./DataContainer").Environment} env
 * 
 * @return {object[]}
 */
function attributeSimpleListStringToObject(str, type, commands = null, env = null) {
  /** @type {string[]} */
  let parts = [];
  let lastStart = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '='
      && (commands === null || !commands.find(c => i > c.start && i < c.end))) {
    }
    if (str[i] === ',' || (commands && str[i] === '\\')) {
      if (commands === null || !commands.find(c => i > c.start && i < c.end)) {
        if (str[i] === ',') {
          parts.push(str.slice(lastStart, i));
          lastStart = i + 1;
        } else if (str[i] === '\\') {
          i++;
        }
      }
    }
  }
  parts.push(str.slice(lastStart));
  if (type === 'positional') {
    return parts;
  } else {
    let keyOperator = ':';
    let res = [];
    if (parts.length === 1 && parts[0] === '') {
      return res;
    }
    for (let part of parts) {
      let pieces = part.split(keyOperator);
      if (pieces.length < 2) {
        throw new ParseError(`"${str}" should have the format "key1:value1, key1:value1"`);
      }
      res.push([pieces[0], pieces.slice(1).join(keyOperator)]);
    }
    return res;
  }
}


const COMMA_REGEX = new RegExp('\u2E41', 'g');
const SPACE_REGEX = new RegExp('\u2E31', 'g');
const EQUALS_REGEX = new RegExp('\u2256', 'g');
const BRACKET_REGEX = new RegExp('\u2E32', 'g');
const NEW_LINE_REGEX = new RegExp('\u2E33', 'g');


/**
 * @param {string} str
 * 
 * @return {string}
 */
export function intermediateToNormal(str) {
  // Commas are used for splitting lists, restore them; whitespace and '{' is also placeholdered
  return str.replace(COMMA_REGEX, ',')
    .replace(SPACE_REGEX, ' ')
    .replace(EQUALS_REGEX, '=')
    .replace(BRACKET_REGEX, '{')
    .replace(NEW_LINE_REGEX, '\n');
}


/**
 * @param {string} str
 * 
 * @return {string}
 */
export function escapedToIntermediate(str) {
  let current = '';
  let position = 0;
  while (position < str.length) {
    let char = str[position];
    position++;
    if (char === '\\') {
      if (position === str.length) {
        console.error('Error in intermediate conversion: ' + str);
        throw new ParseError();
      }
      let char = str[position];
      position++;
      if (char === 'n') {
        current += '\u2E33'; // needed to preserve whitespace removal
      } else if (char === 't') {
        current += '\t';
      } else if (char === 'r') {
        current += '\r';
      } else if (char === ',') {
        current += '\u2E41'; // comma split used in parser-utils for lists
      } else if (char === '=') {
        current += '\u2256'; // used for assignment in equality
      } else if (char === ' ') {
        current += '\u2E31'; // need to preserve for whitespace removal
      } else {
        current += char;
      }
    } else {
      current += char;
    }
  }

  return current;
}


/**
 * @param {string} str
 * @param {{escapeCommas?: boolean, escapeCommands?: boolean, escapeEquals?: boolean, escapeSlashes?: boolean, escapeWhitespace?: string}=} options
 * 
 * @return {string}
 */
export function normalToEscaped(str, options) {
  return sanitizeText(str, options);
}


/**
 * Finds the positions of commands in an attribute. Handles escaping and
 * nested commands.
 * 
 * @param {string} str 
 * 
 * @return {{start: number, end: number}[]}
 */
export function getCommandsFromAttribute(str) {
  let nodeDepth = 0;
  let position = 0;
  let commands = [];
  let commandStart;

  while (position < str.length) {
    let char = str[position];
    position++;
    if (char === '\\') {
      if (position === str.length) {
        break;
      }
      position++;
    } else if (char === '{' && /[=a-z]/i.test(str[position])) {
      if (nodeDepth === 0) {
        commandStart = position - 1;
      }
      nodeDepth++;
    } else if (char === '}') {
      if (nodeDepth === 1) {
        commands.push({
          start: commandStart,
          end: position
        });
      }
      if (nodeDepth > 0) {
        nodeDepth--;
      }
    }
  }

  return commands;
}