import React from 'react';
import {
  Link,
  useHistory,
  useParams
} from 'react-router-dom';
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Typography as T
} from '@mui/material';

import BusinessIcon from '@mui/icons-material/Business';

import { useTypedSelectorShallowEquals } from '../../hooks';
import AsyncButton from '../AsyncButton/AsyncButton';
import { updateOrgAssociation } from '../../bapi';
import { limitationsState } from '../Version/limitations';
import { memberRestricted } from '../../flags';
import { userPermission } from '../../auth';
import { toast } from '../../message';
import GroupPublicControls from './GroupPublicControls';
import { isGroupPublic } from './group_public_rules';
import ShareEntity from './ShareEntity';
import { storage } from '../../utilities';
import { makeRef } from '../../firebase_utilities';
import { FieldPath, deleteField } from 'firebase/firestore';
import GroupSharedSpaces from './GroupSharedSpaces';

const MEMBER_RESTRICTED = 1;
const NOT_ASSOCIATED = 2;
const NOT_CONNECTED_BY_US = 3;

/**
 *
 * @param {Object} props
 * @param {GroupObjectType} props.group
 * @param {function} props.update
 * @returns
 */
export default function ShareGroup({
  group,
  update
}) {
  const { tab } = /** @type {{ tab: ('sharing') }}  */  (useParams());
  const history = useHistory();
  let groupId = group.id;
  const {
    orgId,
    emailVerified,
    disableShare,
    sharedDisabledReason,
    maxUsersPerGroup,
    canUpgradeMaxUsersPerGroup,
    readonlyLoaded
  } = useTypedSelectorShallowEquals((state) => {

    let limits = limitationsState(state);
    let orgId = state.userState.org ? state.userState.org.id : null;
    let isConnected = group && group.connected && group.connected.is_connected;
    let isAssociatedToUs = group && !!orgId && group.associated_org_id === orgId;
    let disableShare = false,
      sharedDisabledReason = 0;
    let isConnectedByUs = group.connected && (
      group.connected.connector === 'u:' + state.userState.uid || group.connected.connector === 'o:' + orgId
    );
    if (memberRestricted(state, 'share')) {
      disableShare = true;
      sharedDisabledReason = MEMBER_RESTRICTED;
    } else if (isConnected && !isAssociatedToUs) {
      disableShare = true;
      sharedDisabledReason = NOT_ASSOCIATED;
    } else if (isConnected && !isConnectedByUs) {
      disableShare = true;
      sharedDisabledReason = NOT_CONNECTED_BY_US;
    }

    return {
      orgId,
      emailVerified: state.userState.emailVerified,
      disableShare,
      sharedDisabledReason,
      maxUsersPerGroup: limits.MAX_USERS_PER_GROUP,
      canUpgradeMaxUsersPerGroup: limits.CAN_UPGRADE_MAX_USERS_PER_GROUP,
      readonlyLoaded: state.userState.readonlyLoaded
    };
  });

  let isPublic = group && isGroupPublic(group);
  let options = (group && group.options) || {};

  function permission() {
    if (!group) {
      return null;
    }
    return userPermission(group);
  }
  function shareCount() {
    if (!group || !group.permissions) {
      return 0;
    }
    return Object.keys(group.permissions).length;
  };
  function showShareCount() {
    // only show to orgs if they are within a hundred of the limits
    return emailVerified && readonlyLoaded
      && (canUpgradeMaxUsersPerGroup || maxUsersPerGroup < shareCount() + 100);
  };

  function renderOrgAssociation() {
    if (permission() !== 'owner') {
      return;
    }

    if (!group.associated_org_id && !orgId) {
      return;
    }
    const getContents = () => {
      /**
       * @type {import('@mui/system').SxProps<import('@mui/material').Theme>}
       */
      const linkSx = {
        p: 0,
        verticalAlign: 'inherit',
        display: 'contents',
        textDecoration: 'underline'
      };
      if (!group.associated_org_id) {
        return (
          <span>
            This folder is not associated with your organization.&nbsp;
            <AsyncButton sx={linkSx} onClick={async (done) => {
              await updateOrgAssociation({
                groupId: groupId,
                action: 'set'
              });
              done();
            }}>Associate the folder with your organization</AsyncButton>
          </span>
        );
      }
      if (orgId === group.associated_org_id) {
        return <span>This folder is associated with <Button sx={linkSx} component={Link} to="/business">your organization</Button>. Organization administrators and editors will have access to it.</span>;
      }
      return (
        <span>
          The folder is associated with a Text Blaze organization. However you are not part of the organization{!disableShare ? <span> - you can&nbsp;
            <AsyncButton
              sx={linkSx}
              onClick={async (done) => {
                await updateOrgAssociation({
                  groupId: groupId,
                  action: 'remove'
                });
                done();
              }}
            >remove the folder association</AsyncButton></span>
            : '.'
          }
        </span>
      );
    };


    const contents = getContents();
    if (!contents) {
      return null;
    }


    return (
      <T
        color="textSecondary"
        sx={{
          display: 'flex',
          mb: 2,
        }}
        variant="body2"
        component="div"
      >
        <BusinessIcon
          fontSize="small"
          sx={{
            mr: 1.5,
            lineHeight: 1.43
          }}
        />
        <div>
          {contents}
        </div>
      </T>
    );
  }
  if (!group) {
    return null;
  }

  const orgAssociationHeader = renderOrgAssociation();
  function renderDisabledReason() {
    let message = null;
    if (sharedDisabledReason === NOT_CONNECTED_BY_US) {
      message = <>You can only edit shared Connected Folder snippets within the same Text Blaze Business organization.</>;
    } else if (orgAssociationHeader) {
      // do nothing
    } else if (permission() !== 'owner') {
      message = 'You do not have permission to change the permissions.';
    }  else if (sharedDisabledReason === MEMBER_RESTRICTED) {
      message = 'You are restricted to share the folder. Please contact your administrator.';
    } else if (sharedDisabledReason === NOT_ASSOCIATED && !orgId) {
      message = <>Connected Folders can only be shared within the same Text Blaze Business organization. <Link to="/upgrade">Upgrade to Text Blaze Business or Enterprise</Link> to share with your team.</>;
    }

    if (!message) {
      return null;
    }
    return <Alert
      color="info"
      icon={false}
      sx={{
        mb: 1.25
      }}
    >
      {message}
    </Alert>;
  }
  return (
    <>
      <ShareEntity
        open={tab === 'sharing'}
        onClose={() => {
          history.push(`/folder/${groupId}`);
        }}
        entityName={group.name}
        entityId={groupId}
        entityType="group"
        entityLabel="folder"
        permission={permission()}
        permissions={group.permissions}
        disabled={disableShare}
        hidden={isPublic}
        closeExtraSharingOptions={isPublic}
        validateUsersFn={(proposedEntities) => {
          //This change will effect all entities, unlike only emails in previous implementation
          if (proposedEntities.length + shareCount() <= maxUsersPerGroup) {
            return true;
          }
          toast('You may only share a folder with ' + maxUsersPerGroup + ' users.', {
            duration: 8000,
            intent: 'danger',
            upgrade: canUpgradeMaxUsersPerGroup && 'Upgrade to share with more users.'
          });
          return false;
        }}
        onChange={(update) => {
          if (update.type === 'SET') {
            const doc = makeRef('groups', groupId);
            return storage.update(doc, {
              permissions: update.data.newGrants
            }, 'HIDE_AUTOSAVE');
          } else if (update.type === 'UPDATE') {
            return storage.update(makeRef('groups', groupId), [
              new FieldPath('permissions', update.data.user), update.data.grant
            ]);
          } else if (update.type === 'REMOVE') {
            return storage.update(makeRef('groups', groupId), [
              new FieldPath('permissions', update.data.user),
              deleteField()
            ]);
          }

          throw new Error('Unknown onChange type: ' + update.type);
        }}
        extraSharingOptions={
          <>
            {orgAssociationHeader}
            {renderDisabledReason()}
            {permission() === 'owner' && !disableShare && <FormControlLabel
              sx={{
                mt: 1
              }}
              control={<Checkbox
                size="small"
                color="primary"
                checked={!options.viewers_source_hidden}
                onChange={(event) => update({
                  'options.viewers_source_hidden': !event.target.checked
                })}
              />}
              label={'Allow "viewers" to see snippet source text (in addition to previewing)'}
            />}

            <GroupPublicControls groupId={groupId} />
          </>
        }
        footer={
          <>
            {showShareCount() && <div style={{ textAlign: 'left', marginTop: 8, marginBottom: 6 }}>
              <T color="textSecondary" variant="body2" style={{ display: 'inline-block' }}>
                Currently using <b>{shareCount()} out of {maxUsersPerGroup}</b> available shares
              </T>
              {canUpgradeMaxUsersPerGroup && (
                <Button
                  size="small"
                  variant="outlined"
                  style={{
                    marginLeft: 10,
                    marginRight: 8
                  }}
                  onClick={() => history.push('/upgrade')}
                >Get more</Button>
              )}
            </div>}
            <GroupSharedSpaces groupId={groupId}/>
          </>
        }
        extraElements={<GroupPublicControls groupId={groupId} showOnlyIfPublic />}
        saveMsg="New users granted access to the folder. They can now choose to enable the folder in their account."
        btnText="Share folder"
      />
    </>
  );
}