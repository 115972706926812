import React, { useEffect, useRef, useState } from 'react';
import T from '@mui/material/Typography';
import { EmptyState } from '../EmptyState/EmptyState';
import Box from '@mui/material/Box';
import './integrations_pages.css';
import './prose.css';
import { Avatar, Chip, CircularProgress, Dialog } from '@mui/material';
import { importGroupSnippets } from '../Group/group_utils';
import { useTypedSelector } from '../../hooks';
import Button from '@mui/material/Button';
import AsyncButton from '../AsyncButton/AsyncButton';
import AddonInstaller from '../Addons/AddonInstaller';
import { getAddonInstallation } from '../../flags';
import { toast } from '../../message';
// @ts-ignore
import anim_form from '../../../images/anim_forms.webp';
// @ts-ignore
import command_pack_menu from '../../../images/command_pack_usage.webp';
// @ts-ignore
import healthcare_works_anywhere from '../../../images/healthcare_works_anywhere.webp';
// @ts-ignore
import landingpage_webm from '../../../images/main-landingpage-animation.webm';
// @ts-ignore
import donna_w from '../../../images/donna_w.webp';
// @ts-ignore
import jay_k from '../../../images/jay_k.webp';
// @ts-ignore
import mae_s from '../../../images/mae_s.webp';
import { DialogPaperStyles } from '../Auth/auth_utilities';
import AuthComponent from '../Auth/AuthComponent';
import { Link } from 'react-router-dom';
import { store } from '@store';
import { installExtension } from '../../install_extension';

/**
 * keep definition in superadmin -> Integrations.js up to date too
 *
 * @typedef IntegrationPage
 * @property {string} id
 * @property {string} name
 * @property {string} integration_name
 * @property {string} command_pack_id
 * @property {string} header_title
 * @property {string} header_subtitle
 * @property {string} description
 * @property {string} seo_description
 * @property {string} seo_title
 * @property {string[]} folder_ids
 * @property {'public'|'internal'} visibility
 * @property {string} logo_url
 * @property {string} description_image_url
 * @property {string} commands_image_url
 */

/**
 * @typedef {{folder: GroupObjectType, snippets: SnippetObjectType[]}} FolderData
 */

/**
 * @param {Object} props
 * @param {boolean|null} props.isAuth
 * @param {boolean} props.wasLoggedOut
 * @param {string|null} props.email
 * @param {boolean|null} props.isExtensionInstalled
 * @param {{pageData: IntegrationPage, addon: AddonObjectInnerType, folders: FolderData[]}} props.integrationData
 */
export default function IntegrationPages({ isAuth, wasLoggedOut, email, integrationData, isExtensionInstalled }) {
  let isLoaded = useTypedSelector((state) => state.userState?.isLoaded);

  let { isAddonInstalled } = useTypedSelector((state) => {
    if (!state.userState.isLoaded || !state.userState.readonlyLoaded) {
      return { isAddonInstalled: null };
    }

    if (!integrationData?.addon?.id) {
      return { isAddonInstalled: false };
    }

    const addonInstallation = getAddonInstallation(
      integrationData?.addon?.id,
      state.userState,
      state.orgState,
    );

    return { isAddonInstalled: !!addonInstallation.installedBy };
  });

  let addonInstalledRef = useRef(null);

  let [isAuthenticating, setIsAuthenticating] = useState(false);
  let [autoCopying, setAutoCopying] = useState(/** @type {'folders'|'command_pack'|null} */(null));
  let [copiedFolders, setCopiedFolders] = useState(false);

  const isLoading = isAuth === null || (isAuth && isAddonInstalled === null) || isExtensionInstalled === null;

  /**
   * @param {Function} done
   */
  async function copyFolders(done) {
    for (const folder of integrationData.folders) {
      folder.snippets = folder.snippets.map((snippet) => {
        // transform Node's buffer data into Uint8Array
        if (snippet.content.delta?.type === 'Buffer') {
          snippet.content.delta = new Uint8Array(snippet.content.delta.data);
        }

        return snippet;
      });

      await importGroupSnippets(folder.folder, folder.snippets);
    }

    toast('Copied folders to Text Blaze successfully', { intent: 'success' });
    setCopiedFolders(true);
    done();
  }
  
  function enableCommandPack(done) {
    addonInstalledRef.current.initInstall('user', done);
  }
  
  useEffect(() => {
    // it finished authenticating, hide auth dialog
    if (isAuth) {
      setIsAuthenticating(false);
    }
  }, [isAuth]);

  useEffect(() => {
    if (!wasLoggedOut || !isAuth || autoCopying !== null) {
      return;
    }

    // use location.href instead of router's navigate/push in order to clear the HTML outside the "root" that comes from SSR
    const navigateToWelcome = () => window.location.href = '/welcome';
    const onAddonCancelled = () => {
      // wait a bit and show the alert
      setTimeout(() => {
        toast(
          `To activate the ${integrationData.addon.data.name} command pack later, search for "Command Pack" on the sidebar.`,
          {
            duration: 12000,
            intent: 'info',
          },
        );
      }, 200);
    };

    // scroll to the top since we'll show the auto import loading now
    window.scrollTo(0, 0);

    // if the user signed up on this page, enable everything automatically
    // and disable "auto imports" anywhere else
    if (integrationData.folders.length) {
      store.dispatch({ type: 'DID_AUTO_IMPORT' });
      setAutoCopying('folders');

      copyFolders(() => {
        if (integrationData.addon) {
          setAutoCopying('command_pack');

          addonInstalledRef.current.initInstall('user', navigateToWelcome, onAddonCancelled);
        } else {
          navigateToWelcome();
        }
      });
    } else if (integrationData.addon) {
      store.dispatch({ type: 'DID_AUTO_IMPORT' });
      setAutoCopying('command_pack');

      addonInstalledRef.current.initInstall('user', navigateToWelcome, onAddonCancelled);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [wasLoggedOut, isAuth]);

  const isInternalButNotABlazeEmployee =
    integrationData?.pageData?.visibility === 'internal' &&
    isLoaded &&
    (email === undefined || !email?.endsWith('@blaze.today'));

  if (isInternalButNotABlazeEmployee) {
    return (
      <div style={{ paddingTop: '10vh', marginBottom: 30 }}>
        <EmptyState
          icon="MISSING"
          title="This page does not exist"
          description=""
        />
      </div>
    );
  }

  function DownloadExtensionButton(props) {
    return <Button variant="contained" {...props} onClick={installExtension}>
      <b>Download extension</b><span style={{ fontWeight: '400' }}>&nbsp;– It's free!</span>
    </Button>;
  }

  function getBannerActions() {
    if (isAuth) {
      if (integrationData.folders.length || integrationData.addon) {
        return (
          <Box className="integration__page-actions">
            {integrationData.folders.length ? (
              <AsyncButton
                disabled={copiedFolders}
                variant="contained"
                size="large"
                onClick={(done) => copyFolders(done)}
              >
                {copiedFolders ? 'Folders already copied' : 'Copy folders'}
              </AsyncButton>
            ) : null}

            {integrationData.addon && isAddonInstalled !== null && !isAddonInstalled ? (
              <AsyncButton
                variant="contained"
                size="large"
                onClick={(done) => enableCommandPack(done)}
              >
                Activate command pack
              </AsyncButton>
            ) : null}
          </Box>
        );
      }

      return <Link to="/">
        <Button variant="contained" size="large">
          Go to dashboard
        </Button>
      </Link>;
    }

    if (isExtensionInstalled) {
      return <LoginButton>Start using templates</LoginButton>;
    }

    return (
      <div>
        <Box className="integration__page-actions">
          <DownloadExtensionButton size="large"/>
          <a
            href="https://calendar.app.google/Jw7crSMyAsVeNark8"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="outlined" size="large" color="inherit" sx={{ color: 'white' }}>
              Schedule a demo
            </Button>
          </a>
        </Box>

        <Box sx={{
          mt: 4,
        }}>
          <div style={{ marginBottom: 6 }}>⭐⭐⭐⭐⭐ 1,000+ Chrome Web Store reviews</div>
          <div>400,000+ people use Text Blaze to end repetitive typing</div>
        </Box>
      </div>
    );
  }

  /**
   * @param {Object} props
   * @param {React.ReactNode} props.children
   * @param {Object=} props.sx
   */
  function LoginButton({ children, sx }) {
    return (
      <Button
        variant="contained"
        onClick={() => setIsAuthenticating(true)}
        sx={{ minWidth: '130px', ...sx }}
      >
        {children}
      </Button>
    );
  }

  return (
    <>
      <Dialog
        className="login-dialog"
        open={isAuthenticating}
        PaperProps={{
          style: DialogPaperStyles
        }}
        onClose={() => setIsAuthenticating(false)}
      >
        <AuthComponent/>
      </Dialog>

      {/*
        Note: we need to render everything together in order to ensure AddonInstaller initializes  addonInstalledRef
        If we do independent returns, it won't be defined on time to do the auto import
      */}
      <AddonInstaller ref={addonInstalledRef} addon={integrationData.addon}/>

      {isLoading && <div
        style={{
          paddingTop: '15vh',
          marginBottom: 30,
          justifyContent: 'center',
          display: 'flex',
          height: '100vh'
        }}
      >
        <CircularProgress size={150} thickness={1.9}/>
      </div>}

      {!isLoading && autoCopying &&
        <div style={{ height: '100vh' }}>
          <div
            style={{
              margin: '80px auto 0',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '1080px',
              gap: '50px'
            }}
          >
            <T variant="h4" marginBottom={3}>
              {autoCopying === 'folders' &&
            <span data-testid="integratin-page-importing-folders">Importing <b>{integrationData.pageData.integration_name}</b> folders for you</span>}

              {autoCopying === 'command_pack' &&
            <span data-testid="integratin-page-importing-command-pack">Activating the <b>{integrationData.addon.data.name}</b> command pack for you</span>}
            </T>

            {autoCopying === 'folders' && <CircularProgress size={150} thickness={1.9}/>}
          </div>
        </div>}

      {!isLoading && !autoCopying && <div>
        <div className="integrations__banner-wrapper">
          <div className="integrations__banner">
            <Box className="integrations__page-title">
              <T variant="h4" component="h1" color="white" fontWeight={integrationData.pageData.header_title ? 'bold' : undefined}>
                {integrationData.pageData.header_title ||
                <><b>{integrationData.pageData.integration_name}</b> is better with <b style={{ fontWeight: 500 }}>Text Blaze</b></>}
              </T>


              {integrationData.pageData.header_subtitle &&
                <T variant="h5" component="h2" color="white" mt={2}>
                  {integrationData.pageData.header_subtitle}
                </T>}

              <Box sx={{ marginTop: 3 }}>{getBannerActions()}</Box>
            </Box>
            <img
              className="integrations__banner-image"
              height={350}
              src={integrationData.pageData.logo_url}
              alt={`The logo for ${integrationData.pageData.integration_name}`}
            />
          </div>
        </div>

        {!isAuth && (<Box
          sx={{
            display: {
              xs: 'none',
              md: 'block'
            }
          }}
          className="integrations__content integrations__snippets-preview-wrapper-light"
        >
          <Box display="flex" gap="30px" flexWrap="wrap" justifyContent="center" padding="30px !important">

            <Review image={donna_w} name="Donna W"  content="This saves me so much time! I love how easy it is to use."/>
            <Review image={jay_k} name="Jay K." content="Such a great app! Time saver and best of all works across devices."/>
            <Review
              sx={{
                display: {
                  xs: 'none',
                  lg: 'block'
                }
              }}
              image={mae_s} name="Mae S." content={'A freaking mazing !! Can\'t live without it, best thing ever.'}/>
          </Box>
        </Box>)}

        <div className="integrations__content-wrapper">
          <div className="integrations__content integrations__content-white">
            <div>
              {integrationData.pageData ? (
                <Box sx={{
                  display: 'flex',
                  gap: '50px',
                  justifyContent: {
                    lg: 'initial',
                    xs: 'center'
                  },
                  '& video': {
                    alignSelf: 'flex-start',
                    marginTop: '10px',
                    display: {
                      xs: 'none',
                      lg: 'block'
                    }
                  }
                }}>
                  <Box sx={{
                    width: {
                      xs: '100%',
                      lg: '50%'
                    },
                    wordBreak: 'break-word'
                  }}
                  className="prose"
                  dangerouslySetInnerHTML={{
                    __html: integrationData.pageData.description,
                  }}
                  />

                  {integrationData.pageData.description_image_url
                    ? <img alt={`${integrationData.pageData.name} integration usage`} className="integrations__content-image" src={integrationData.pageData.description_image_url} width={480} />
                    : <video width={380} autoPlay loop muted playsInline
                      aria-label="A video showing Text Blaze being used across services">
                      <source src={landingpage_webm} type="video/webm"/>
                    </video>
                  }
                </Box>
              ) : null}
            </div>
          </div>

          {!isAuth && (
            <div className="integrations__content integrations__snippets-preview-wrapper-gray">
              <div className="integrations__banner">
                <div>
                  <T variant="h5" gutterBottom component="h2">
                    Eliminate repetitive typing and mistakes with Text Blaze
                  </T>
                  <T variant="h6" component="p">
                    Easy-to-use templates with endless customizability and
                    powerful automation. All with full control at your fingertips.
                  </T>

                  <Box sx={{ marginTop: '30px', textAlign: { xs: 'center', md: 'left' } }}>
                    {isExtensionInstalled ? (
                      <Button
                        variant="contained"
                        onClick={() => setIsAuthenticating(true)}
                        sx={{ minWidth: '130px' }}
                      >
                        Start using templates
                      </Button>
                    ) : (
                      <DownloadExtensionButton/>
                    )}
                  </Box>
                </div>

                <img
                  className="integrations__banner-image"
                  loading="lazy"
                  src={anim_form}
                  height={320}
                  alt="Forms Screenshot"
                />
              </div>
            </div>
          )}

          {integrationData.folders.length ? (
            <div className="integrations__snippets-preview-wrapper">
              <div className="integrations__banner">
                <div className="integrations__snippets-preview">
                  <div>
                    <T variant="h5" component="h2">
                      Import ready to use folders full of useful useful snippets.
                    </T>

                    {integrationData.folders.map((folderData) => (
                      <Box paddingTop="20px" key={folderData.folder.id}>
                        <T paragraph fontWeight="bold">
                          Folder "{folderData.folder.name}":
                        </T>

                        <Box
                          sx={{
                            gap: '12px 8px',
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          {folderData.snippets
                            .slice(0, 11)
                            .map((snippet, index) => {
                              if (!snippet) {
                                return null;
                              }

                              if (index === 10) {
                                return <T key={snippet.id} fontSize="24px" lineHeight={1}>...</T>;
                              }

                              return (
                                <Chip
                                  variant="outlined"
                                  key={snippet.id}
                                  label={snippet.name}
                                />
                              );
                            })}
                        </Box>
                      </Box>
                    ))}
                  </div>

                  {isAuth ? (
                    <AsyncButton
                      disabled={copiedFolders}
                      variant="contained"
                      size="large"
                      sx={{ marginTop: 4 }}
                      onClick={(done) => copyFolders(done)}
                    >
                      {copiedFolders ? 'Folders already copied' : 'Copy folders'}
                    </AsyncButton>
                  ) : (
                    <LoginButton sx={{ marginTop: 4 }}>
                      Start using templates
                    </LoginButton>
                  )}
                </div>

                <img
                  className="integrations__banner-image"
                  height={320}
                  src={healthcare_works_anywhere}
                  alt="Shows multiple uses of Text Blaze on different websites and apps"
                />
              </div>
            </div>
          ) : null}

          {integrationData.addon ? (
            <div className="integrations__snippets-preview-wrapper integrations__snippets-preview-wrapper-gray">
              <div className="integrations__banner">
                <div className="integrations__snippets-preview">
                  <div>
                    <T
                      variant="h5"
                      component="h2"
                      gutterBottom
                    >
                      {integrationData.pageData.integration_name} command pack:{' '}
                      {integrationData.addon.data.description.toLowerCase()}
                    </T>

                    <T gutterBottom>
                      Activating this command pack will add new dynamic commands
                      to Text Blaze, extending its capabilities. You can use these
                      new commands as part of your snippets to create powerful
                      combinations and simplify your work further.
                    </T>

                    <T>
                      This command pack has been created to work with{' '}
                      {integrationData.pageData.integration_name} in particular.
                    </T>

                    <Box paddingTop="20px">
                      <T paragraph fontWeight="bold">
                        Included commands:
                      </T>

                      <Box
                        sx={{
                          gap: '12px 8px',
                          display: 'flex',
                          flexWrap: 'wrap',
                          pre: { margin: 0 },
                        }}
                      >
                        {integrationData.addon.data.snippets
                          .slice(0, 11)
                          .map((addon, index) => {
                            if (index === 10) {
                              return <T key={addon.id} fontSize="24px" lineHeight={1}>...</T>;
                            }

                            return (
                              <Chip
                                avatar={
                                  <Avatar
                                    alt=""
                                    src={
                                      integrationData.addon.data?.icon_image_url
                                    }
                                  />
                                }
                                variant="outlined"
                                key={addon.id}
                                label={addon.name}
                              />
                            );
                          })}
                      </Box>
                    </Box>
                  </div>

                  {isAuth ? (
                    <AsyncButton
                      variant="contained"
                      size="large"
                      sx={{ marginTop: 5 }}
                      onClick={(done) => enableCommandPack(done)}
                      disabled={isAddonInstalled}
                    >
                      {isAddonInstalled
                        ? 'Command pack already active'
                        : 'Activate command pack'}
                    </AsyncButton>
                  ) : (
                    <LoginButton sx={{ marginTop: 5 }}>
                      Activate command pack
                    </LoginButton>
                  )}
                </div>

                <img
                  className="integrations__banner-image"
                  height={320}
                  src={integrationData.pageData.commands_image_url || command_pack_menu}
                  style={{ boxShadow: '1px 2px 9px hsl(0deg 0% 0% / 17%)' }}
                  alt="Shows the usage of command packs within Text Blaze"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>}
    </>
  );
}

/**
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.content
 * @param {string} props.image
 * @param {object=} props.sx
 * @return {JSX.Element}
 */
function Review({ name, content, image, sx }) {
  return (
    <Box className="integrations__review" maxWidth={320} sx={sx}>
      <div>⭐⭐⭐⭐⭐</div>
      <div className="integrations__review_content">{content}</div>

      <Box display="flex" gap="20px" paddingTop={1} alignItems="center">
        <Avatar sx={{ width: 30, height: 30, borderRadius: 30 }} src={image}  />
        <Box display="flex" flexDirection="column">
          <T fontSize={14}>{name}</T>
          <T fontSize={14} color="rgba(0,0,0,.6)">Chrome Web Store</T>
        </Box>
      </Box>
    </Box>
  );
}