import { joinDelta, tokenize } from '../../snippet_processor/Parser';
import Quill from 'quill';
import Embed from 'quill/blots/embed';
import Inline from 'quill/blots/inline';
import { Environment } from '../../snippet_processor/DataContainer';
import {
  embedDataToCommand,
  escapeFormName,
  expandDeltaContents,
  getCollapsedData,
  getQuill,
  getTokenType,
  tokenizeAttributes,
  tokenizeCommand
} from './editor_utilities';
import { toast } from '../../message';
import { createCommandChip } from './EmbeddedCommand/embedded_utilities';
import { addBreadcrumb } from '@sentry/browser';
import {
  areAttrsEqual,
  areChildNodesUpdated,
  getCollapsedCommandBlot,
  getHighlighter,
  getSelectedCommands,
  mergeFormats,
  MultiSet
} from './highlighter_utilities';
import { astSQL } from '../../snippet_processor/SQL';
import { ast, FUNCTIONS } from '../../snippet_processor/Equation';
import equals from 'fast-deep-equal/es6';
import { highlightFormulaOps } from '../FormulaEditor/syntax_highlighters';
import { COMMAND_HIGHLIGHT_TOKEN_DATA_TIMER, COMMAND_UPDATE_DATA_TIMER } from '../../flags';
import { COMMANDS } from '../../snippet_processor/Commands';

let globalId = 0;

let Delta = Quill.import('delta');
let Module = Quill.import('core/module');


const PLAIN_IDENTIFIERS_KEYS = new Set(
  Object.values(COMMANDS)
    .map(command => Object.entries(command.attributes.named))
    .flat()
    .filter(entry => entry[1].type === 'identifier' && !entry[1].holder)
    .map(entry => entry[0])
);

/**
 * @typedef {import("../../snippet_processor/ParseNode").default} ImportedParseNode
 */

/**
 *
 * The rangeIndex (cursor position) from Quill is nor reliable, it depends on the snippets being collapsed or not
 * Each collapsed snippet is a single character in Quill
 * Here we calculate the real text position of the cursor
 * By using "normalNodes" with all the correct text positions, and "nodesWithQuillPositions" with the calculated Quill positions
 *
 * Assuming the following are collapsed and that "*" is the cursor: {hey=10}*{=hey}
 * rangeIndex: 1
 * Real range index: 8
 *
 * @param {number|null} rangeIndex
 * @param {ImportedParseNode[]} nodesWithQuillPositions  // these atore the positions as seen in Quill
 * @param {ImportedParseNode[]} normalNodes // these are normal nodes with normal string positions
 * @returns {null|number}
 */
export function getRealRangeIndexFromNodes(rangeIndex, nodesWithQuillPositions, normalNodes) {
  let realRangeIndex = rangeIndex;

  nodesWithQuillPositions.forEach((node, index) => {
    if (node.endPosition === rangeIndex) {
      realRangeIndex = normalNodes[index].endPosition;
    } else if (node.startPosition === rangeIndex) {
      realRangeIndex = normalNodes[index].startPosition;
    } else if (rangeIndex > node.endPosition) {
      realRangeIndex = normalNodes[index].endPosition + (rangeIndex - node.endPosition);
    } else if (rangeIndex > node.startPosition) {
      realRangeIndex = normalNodes[index].startPosition + (rangeIndex - node.startPosition);
    }
  });


  return realRangeIndex;
}

function getAvailableVariablesForCursorPosition(pos, typesTree) {
  if (!typesTree) {
    return null;
  }
  let result = { ...typesTree.names };
  let typesTreeNode = typesTree;
  while (true) {
    let found = false;
    for (const child of typesTreeNode.children) {
      if (child.startPosition <= pos && pos <= child.endPosition) {
        typesTreeNode = child;
        found = true;
        result = { ...result, ...typesTreeNode.names };
        break;
      }
    }
    if (!found) {
      break;
    }
  }
  return result;  
}


/**
 * @param {HTMLElement} node 
 */
function addInsertionButtonListeners(node) {
  [...node.querySelectorAll('.insertion-chip')].forEach((chip) => {
    chip.addEventListener('click', (e) => {
      let target = /** @type {HTMLElement} */ (e.currentTarget);
      let insert = target.getAttribute('data-insert');
      // @ts-ignore
      let isMore = !!e.target.closest('.chip-type-more');

      let quill = getQuill(node);
      let highlighter = getHighlighter(quill);
      if (!isMore) {
        highlighter.insertFn('{=' + escapeFormName(insert) + '}');
      } else {
        highlighter.showTypedMenu(target, insert);
      }

      e.preventDefault();
      e.stopPropagation();
    });
  });
}


export class AttributeFormulaBlot extends Inline {

  static blotName = 'token';

  static tagName = ['span']; // if you use a <span> or <abbr> tag, copying and pasting retains the background color

  static className = 'formula-attribute'; // note if this is removed, quill will detect random <strong> tags as blots

  static create(val) {
    /** @type {HTMLElement} */
    let node = super.create(val);
    //node.classList.add('token-' + val);
    node.dataset.token = val;
    return node;
  }

  optimize(context) {
    if (this.parent.domNode.dataset.token === this.domNode.dataset.token) {
      super.optimize(context);
    }

  }

  static formats(domNode) {
    return domNode.dataset.token;
  }
}

class InlineCommandBlot extends Inline {
  static create(val) {
    /** @type {HTMLElement} */
    let node = super.create(val);

    if (!val?.data?.value?.type) {
      return node;
    }
    node.classList.add('replacement');
    if (val.leftSide) {
      node.classList.add('replacement-left');
    }
    if (val.rightSide) {
      node.classList.add('replacement-right');
    }
    let id = val.data.meta.id;
    if (val.rightSide) {
      id += '-end';
    } else if (val.leftSide) {
      // pass
    } else {
      id += '-mid';
    }

    node.setAttribute('data-blaze-id', id);
    node.setAttribute('data-blaze-type', val.data.value.type);
    node.classList.add('t-' + simplifyType(val.data));
    node.setAttribute('data-blaze-attributes', JSON.stringify(removeAttributeNodes(val.data.value.attributes)));

    node.setAttribute('spellcheck', 'false');

    if (val.data.meta.error) {
      node.setAttribute('title', val.data.meta.error);
    }

    return node;
  }

  static formats(domNode) {
    return 'replacement-id-' + domNode.getAttribute('data-blaze-id');
  }

  constructor(scroll, domNode, value) {
    super(scroll, domNode);
    const self = this;
    self.commandValue = value;

    domNode.addEventListener('dblclick', async () => {
      const self = this;
      let value = self.commandValue;
      let highlighter = getHighlighter(getQuill(domNode));

      if (!highlighter.isCollapsedEnabled) {
        return;
      }
      let quill = getQuill(domNode);
      let id = self.getBlazeId();

      // Does not have a property value because this is a part which was formatted/broken
      if (!value) {
        const startEl = quill.root.querySelector('[data-blaze-id="' + id + '"]');
        const startBlot = /** @type {InlineCommandBlot} */ (quill.scroll.find(startEl));
        value = startBlot.commandValue;
      }

      if (value.data.meta.error) {
        toast('You must resolve the error before collapsing. ' + value.data.meta.error, {
          intent: 'danger'
        });
        return;
      }

      
      let [startEl, endEl] = [...quill.root.querySelectorAll('[data-blaze-id="' + id + '"], [data-blaze-id="' + id + '-end"]')];
      let startPosition = quill.getIndex(getCollapsedCommandBlot(startEl));
      let endPosition = quill.getIndex(getCollapsedCommandBlot(endEl)) + 1;
      let text = quill.getText({
        index: startPosition,
        length: endPosition - startPosition
      });

      const data = await tokenizeCommand(text, highlighter.addons, value.data);
      if (!startEl.isConnected || !data) {
        return;
      }
      let currentDelta = quill.editor.delta;
      const opsRef = findNextOps(currentDelta, startPosition + 1);
      
      let delta = new Delta();
      delta.retain(startPosition);
      delta.delete(endPosition - startPosition);
      
      let attributes = (opsRef ? currentDelta.ops[opsRef.index].attributes : {});
      delta.insert({
        collapsedCommand: data
      }, {
        ...attributes,
        replacement: null
      });
      quill.updateContents(delta, 'user');
    });
  }

  getBlazeId() {
    const self = this,
      commandValue = self.commandValue;
    if (commandValue?.data?.meta?.id) {
      return commandValue.data.meta.id;
    }
    let attrBlazeId =  self.domNode.dataset.blazeId;
    if (!attrBlazeId) {
      return null;
    }
    return attrBlazeId.split('-')[0];
  }

  getCommandSide() {
    const self = this,
      commandValue = self.commandValue;
    if (commandValue) {
      if (commandValue.leftSide) {
        return 'start';
      } else if (commandValue.rightSide) {
        return 'end';
      } else {
        return 'mid';
      }
    }
    const classList = self.domNode.classList;
    if (classList.contains('replacement-left')) {
      return 'start';
    } else if (classList.contains('replacement-right')) {
      return 'end';
    } else {
      return 'mid';
    }
  }

  optimize(context) {
    const self = this;
    super.optimize(context);
    const domNode = self.domNode,
      blazeId = self.getBlazeId(),
      commandSide = self.getCommandSide(),
      prevFormats = this.currentFormats;
    let quill = getQuill(domNode);
    if (!quill || !blazeId) {
      return;
    }
    let allEls = [...quill.root.querySelectorAll(`[data-blaze-id="${blazeId}"], [data-blaze-id="${blazeId}-mid"], [data-blaze-id="${blazeId}-end"]`)];
    let { replacement, ...currentFormats } = quill.getFormat(quill.getIndex(self), 1);
    this.currentFormats = currentFormats;
    if (prevFormats && !mergeFormats(currentFormats, prevFormats).different) {
      return;
    }
    let startBlot;
    let endBlot;
    /**
     * 
     * @param {InlineCommandBlot} blot 
     * @param {ReturnType<InlineCommandBlot['getCommandSide']>} side 
     */
    let recordBlot = (blot, side) => {
      if (side === 'start') {
        startBlot = blot;
      } else if (side === 'end') {
        endBlot = blot;
      }
    };

    recordBlot(this, commandSide);
    /** @type {ReturnType<mergeFormats>['merged']} */
    let newFormats,
      changed;
    for (const el of allEls) {
      if (el === domNode) {
        continue;
      }
      const otherBlot = /** @type {InlineCommandBlot} */ (quill.scroll.find(el));
      recordBlot(otherBlot, otherBlot.getCommandSide());
      let { replacement, ...otherFormats } = otherBlot.currentFormats || quill.getFormat(quill.getIndex(otherBlot), 1);
      ({ merged: newFormats, different: changed } = mergeFormats(currentFormats, otherFormats));
      if (changed) {
        break;
      }
    }

    if (!changed) {
      return;
    }
    // Previous iteration should have exited before evaluating endBlot
    if (!endBlot) {
      endBlot = /** @type {InlineCommandBlot} */ (quill.scroll.find(allEls[allEls.length - 1]));
    }
    const startIndex = quill.getIndex(startBlot);
    const endIndex = quill.getIndex(endBlot);
    try {
      quill.formatText(startIndex, endIndex - startIndex + 1, newFormats, 'silent');
    } catch (err) {
      console.log(newFormats, currentFormats, prevFormats);
      console.error(err);
    }
    
  }
}
InlineCommandBlot.blotName = 'replacement';
InlineCommandBlot.tagName = 'strong'; // if you use a <span> or <abbr> tag, copying and pasting retains the background color
InlineCommandBlot.className = 'replacement-command'; // note if this is removed, quill will detect random <strong> tags as blots

export class CollapsedCommandBlot extends Embed {
  /**
   * @param {ReturnType<import('../SnippetEditor/editor_utilities').getCollapsedData>} data 
   */
  static create(data) {
    let node = /** @type {HTMLElement} */ (super.create(data)),
      id = data?.meta?.id || `${Math.floor((Math.random() * 100000))}`;

    node.setAttribute('data-value', JSON.stringify(data.value));
    
    node.id = 'command-' + id.replaceAll(/[[\]]/g, '_');
    node.innerHTML = createCommandChip(data);
    return node;
  }

  static value(domNode) {
    let attr = domNode.getAttribute('data-value');
    if (!attr) {
      addBreadcrumb({
        message: 'Missing data-value attr'
      });
    }
    return { meta: {}, value: JSON.parse(attr) };
  }

  /**
   * @param {object} scroll 
   * @param {HTMLElement} node 
   * @param {ReturnType<import('../SnippetEditor/editor_utilities').getCollapsedData>} data 
   */
  constructor (scroll, node, data) {
    super(scroll, node);
    if (!data) {
      addBreadcrumb({
        message: 'No data. Using from attribute',
        data: {
          html: node.outerHTML
        }
      });
      // Probably some extension or OS mutated the dom outside the scope of quill/editor.
      data = this.statics.value(node);

      /**
       * Override the types of the property.
       * @type {HTMLElement}
       */
      // eslint-disable-next-line no-unused-expressions
      this.domNode;
    }
    this.blazeData = data;

    /**
     * @returns {SnippetSyntax}
     */
    function getHighlighterWrapper() {
      return getHighlighter(getQuill(node));
    }


    node.addEventListener('mousedown', (_e) => {
      // If a react rerender happens between the mousedown
      // and mouseup events, a click event won't be fired.
      //
      // This can happen if the highlighter is triggered
      // in the middle of them. We reset the timer to prevent
      // this.

      getHighlighterWrapper().resetHighlightTimeout(true);
    });


    node.addEventListener('mouseenter', (e) => {
      if (e.buttons > 0) {
        // Check if we have the mouse down indicating we are selecting.
        // If we change the highlight in this case, it could clear the
        // current selection.
        return;
      }
      const target = /** @type {HTMLElement} */ (e.target);
      let highlighter = getHighlighterWrapper();
      if (highlighter.context?.collapsedSelected || highlighter.context?.token) {
        return;
      }
      const rootTokenStartPosition = getCollapsedCommandBlot(node).blazeData.meta.rootTokenStartPosition;
      const smallChip = /** @type {HTMLElement} */ (target.closest('.small-chip'));
      if (smallChip) {
        highlighter.updateTokenHighlight(rootTokenStartPosition, smallChip.dataset.tokenId);
      } else  {
        highlighter.updateTokenHighlight(rootTokenStartPosition);
      }
    }, {
      capture: true
    });

    node.addEventListener('mouseleave', (_e) => {
      //#160 In safari, mouseleave is being triggered after drop where command chip is deleted
      if (!node || !node.parentElement) {
        return;
      }
      let highlighter = getHighlighterWrapper();
      if (!highlighter.context?.collapsedSelected && !highlighter.context?.token) {
        highlighter.updateTokenHighlight(null);
      }
    });
    let clickTimer;

    /**
     * 
     * @param {HTMLElement} target 
     */
    let nodeClick = (target) => {
      let highlighter = getHighlighterWrapper();
      /**
       * @type {import('./editor_utilities').CollapsedDataType}
       */
      const rootData = getCollapsedCommandBlot(node).blazeData;
      /**
       * @type {import('./editor_utilities').CollapsedDataType}
       */
      let blazeData;
      const smallChip = target.closest('.small-chip');
      if (smallChip) {
        blazeData = JSON.parse(smallChip.getAttribute('data-value'));
      } else {
        blazeData = rootData;
      }

      addBreadcrumb({
        message: 'click collapsed: ' + blazeData.meta.id + ' - ' + blazeData.value.type
      });

      let attributeTarget = target.closest('.list-attribute');

      // when clicking on chip, clear quill selection
      let quill = getQuill(node);
      quill.setSelection(null);


      highlighter.updateContext({
        ...highlighter.context,
        collapsedSelected: {
          data: blazeData,
          rootData,
          node: node,
          attributeName: attributeTarget ? attributeTarget.getAttribute('data-attribute-name') : null
        },
        activeTypes: getAvailableVariablesForCursorPosition(blazeData.meta.rootTokenStartPosition, highlighter.context?.types),
        rangeIndex: blazeData.meta.rootTokenStartPosition
      });
      highlighter.updateTokenHighlight(rootData.meta.rootTokenStartPosition, blazeData.meta.attributesPath?.length ? blazeData.meta.id : null);
    };

    node.addEventListener('click', (e) => {
      let highlighter = getHighlighterWrapper();
      const target = /** @type {HTMLElement} */ (e.target);
      clearTimeout(clickTimer);
      if (highlighter.attributeType) {
        clickTimer = setTimeout(() => nodeClick(target), 200);
      } else {
        nodeClick(target);
      }
    });

    node.addEventListener('dblclick', (_e) => {
      clearTimeout(clickTimer);
      const blazeData = getCollapsedCommandBlot(node).blazeData;
      addBreadcrumb({
        message: 'dblclick collasped: ' + blazeData.meta.id + ' - ' + blazeData.value.type
      });
      
      /** @type {Quill} */
      let quill = getQuill(node);
      let highlighter = getHighlighterWrapper();

      let blot = getCollapsedCommandBlot(node);
      let command = embedDataToCommand(blazeData);

      let startPosition = quill.getIndex(blot);
      // @ts-ignore seems to not public. Probably find a better solution.
      let currentDelta = quill.editor.delta;
      const opsRef = findNextOps(currentDelta, startPosition);

      // need to do this in two separate updateContents call
      // only doing one leaves behind the styling of the inline blot
      let delta = new Delta();
      delta.retain(startPosition);
      delta.delete(1);
      let attributes = (opsRef ? currentDelta.ops[opsRef.index].attributes : {});
      delta.insert('{', {
        ...attributes,
        replacement: {
          data: blazeData,
          leftSide: true
        }
      });
      delta.insert(command.slice(1, command.length - 1), {
        ...attributes,
        replacement: {
          data: blazeData
        }
      });
      delta.insert('}', {
        ...attributes,
        replacement: {
          data: blazeData,
          rightSide: true
        }
      });

      // note we might want to do 'api' or 'silent'
      // here but quill doesn't seem to update
      // the undo/redo history delta retain's for the embedded
      // blot correctly.
      // Same with the reverse dblclick operation.
      quill.updateContents(delta, 'user');

      highlighter.updateContext(Object.assign({}, highlighter.context, { collapsedSelected: null }));

      highlighter.clearTokenHighlight();
    });

    addInsertionButtonListeners(node);

    let bottom = node.querySelector('.embedded-chip-bottom');
    if (bottom) {
      bottom.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
      });
    }


    node.setAttribute('draggable', 'true');

    node.addEventListener('dragstart', (e) => {

      addBreadcrumb({
        message: 'Dragging command'
      });

      /** @type {Quill} */
      let quill = getQuill(node);
      const commandBlot = getCollapsedCommandBlot(node);
      let range = quill.getSelection();
      let index = quill.getIndex(commandBlot);
      if (range && range.index <= index && range.index + range.length > index) {
        // part of a bigger selection, continue
        return;
      }
      
      e.dataTransfer.setData('text/html', /** @type {HTMLElement} */ (e.target).innerHTML);
      e.dataTransfer.setData('command-data', JSON.stringify(commandBlot.blazeData));
      e.dataTransfer.dropEffect = 'move';
      node.dataset.dragging = 'true';
      e.stopPropagation();
    });

    node.addEventListener('dragend', (_e) => {
      delete node.dataset.dragging;
    });
  }

  /**
   * For deleting blots on Android.
   * 
   * See: https://github.com/quilljs/quill/issues/1985
   * 
   * Redefine the `update` method to handle the `childList` case.
   * This is necessary to correctly handle "backspace" on Android using Gboard.
   * It behaves differently than other cases and we need to handle the node
   * removal instead of the `characterData`.
   */
  update(mutations, context) {
    // `childList` mutations are not handled on Quill
    // see `update` implementation on:
    // https://github.com/quilljs/quill/blob/master/blots/embed.js
  
    mutations.forEach(mutation => {
      if (mutation.type !== 'childList') {
        return;
      }
      if (mutation.removedNodes.length === 0) {
        return;
      }

      /**
       * Check if the removed nodes contain left or right gaurd
       * https://github.com/quilljs/quill/issues/1985#issuecomment-397555171
       */
      if (
        mutation.removedNodes[0] !== this.leftGuard &&
        mutation.removedNodes[0] !== this.rightGuard
      ) {
        return;
      }
  
      setTimeout(() => this._remove(), 0);
    });
  
    const unhandledMutations = mutations.filter(m => m.type !== 'childList');
    super.update(unhandledMutations, context);
  }
    
  _remove() {
    let closestContainer = /** @type {any} */ (this.contentNode.closest('.ql-container'));

    if (!closestContainer) {
      return; // due to async nature, ensure item has not been removed
    }

    let quill = getQuill(closestContainer, true);
    // NOTE: call this function as:
    // setTimeout(() => this._remove(), 0);
    // otherwise you'll get the error: "The given range isn't in document."
    let selection = quill.getSelection();
    let cursorPosition;
    if (selection) {
      // check for selection as we may have none
      cursorPosition = selection.index;
    }
  
    // see `remove` implementation on:
    // https://github.com/quilljs/parchment/blob/master/src/blot/abstract/shadow.ts
    this.remove();
      
    if (selection) {
      // schedule cursor positioning after quill is done with whatever has scheduled
      setTimeout(() => quill.setSelection(cursorPosition, Quill.sources.API), 0);
    }
  }

  html() {
    const inner = embedDataToCommand(this.blazeData);
    const dom = /** @type {typeof this.domNode} */ (this.domNode.cloneNode(true));
    while (dom.children.length) {
      dom.removeChild(dom.children[0]);
    }
    dom.appendChild(document.createTextNode(inner));
    return dom.outerHTML;
  }

  /**
   * 
   * @param {ReturnType<import('../SnippetEditor/editor_utilities').getCollapsedData>} data 
   * @param {boolean} shouldUpdateDom
   */
  updateData (data, shouldUpdateDom = false) {
    if (!data) {
      return;
    }
    this.blazeData = data;

    if (shouldUpdateDom) {
      this.updateDom();
    }
  }

  /**
   * Updates DOM based on the data
   */
  updateDom () {
    const domNode = /** @type {HTMLElement} */ (this.domNode);
    const data = this.blazeData;
    const contentNode = this.contentNode;

    clearTimeout(this.updateDataTimer);
    domNode.setAttribute('data-value', JSON.stringify(data.value));

    this.updateDataTimer = setTimeout(() => {
      if (!contentNode?.isConnected) {
        return;
      }
      let newHtml = createCommandChip(data);
      contentNode.innerHTML = newHtml;
      addInsertionButtonListeners(contentNode);
    }, COMMAND_UPDATE_DATA_TIMER);
    /** @type {Quill} */
    let quill = getQuill(domNode);
    let highlighter = getHighlighter(quill);
    highlighter.resetHighlightTimeout(true);
  }

  /**
   * Overriding replace with, so the highlighter is fixed
   */
  replaceWith (name, value) {
    const blot = super.replaceWith(name, value);
    let parent = blot?.parent;
    if (!parent) {
      return blot;
    }
    while (parent !== blot.scroll) {
      /**
       * cache is used by quill to calculate length and other properties on Block blots
       * When this blot is replaced/changed, the parent (inline format) of parent (Block) cached lengths are not cleared
       * This issue may also arise with other APIs.
       * A similar approach can be employed to handle such cases in those APIs.
       * 
       * Can remove this after https://github.com/slab/quill/issues/4264
       */
      if ('cache' in parent) {
        // @ts-ignore
        parent.cache = {};
      }
      parent = parent.parent;
    }
    return blot;
  }
}
CollapsedCommandBlot.blotName = 'collapsedCommand';
CollapsedCommandBlot.tagName = 'span';
CollapsedCommandBlot.className = 'collapsed-command';


export default class SnippetSyntax extends Module {
  static register(registry) {
    registry.register(InlineCommandBlot);
    registry.register(CollapsedCommandBlot);
  }

  isCollapsedEnabled = false;

  attributeType = /** @type {('text' | 'constant' | 'formula' | 'sql' | 'iterator'|'block')=} */ (null);

  types = {};

  /**
   * Inserts text into the current Quill instance.
   * 
   * @param {string} txt 
   * @param {boolean=} autoSelect - Selects the text generated.
   * @param {boolean=} useTableLogic - Inserts commands into the first selected cell and last selected cell of the table 
   * @returns {Promise}
   */
  insertFn = async (txt, autoSelect, useTableLogic) => null;

  /**
   * 
   * @param {HTMLElement} targetEl 
   * @param {string} name 
   */
  showTypedMenu = (targetEl, name) => {};

  /** @type {import('../SnippetEditor/SnippetEditor').EditorContextType} */
  context = null;

  /** @type {function(import('../SnippetEditor/SnippetEditor').EditorContextType): void} */
  contextCallback = null;

  /**
   * @param {Quill} quill 
   * @param {object} options 
   */
  constructor(quill, options) {
    super(quill, options);

    this.addons = {};

    this.highlightTimer = null;

    this.textChangeIndex = 0;

    this.resetHighlightTimeout = (onlyRefresh = false) => {
      if (!onlyRefresh || this.highlightTimer) {
        clearTimeout(this.highlightTimer);
        this.highlightTimer = setTimeout(() => {
          this.highlightTimer = null;
          this.highlight();
        }, COMMAND_HIGHLIGHT_TOKEN_DATA_TIMER);
      }
    };

    quill.on('text-change', (_new, _old, _source) => {
      this.textChangeIndex++;
      
      /** @type {import("../../snippet_processor/ParseNode").default[]} */
      this.tokens = null;
      clearTimeout(this.highlightTimer);
      this.resetHighlightTimeout();
    });
    const quillRoot = quill.root;
    const unbindSelectionHandler = () => {
      setStyles('');
      document.removeEventListener('selectionchange', documentSelectChangeHandler);
    };
    quillRoot.addEventListener('mousedown', () => {
      document.addEventListener('selectionchange', documentSelectChangeHandler);
    });
    quillRoot.addEventListener('mouseup', unbindSelectionHandler);


    

    const documentSelectChangeHandler = () => {
      /**
       * @type {HTMLElement}
       */
      const qlEditor = quill.root;

      // Editor not active anymore. Discard everything.
      if (!qlEditor.isConnected) {
        unbindSelectionHandler();
        return;
      }
      
      const selectedCommands = getSelectedCommands(this.quill);
      // Override styles from regular quill selection.
      let resetStyles = '.ql-editor .collapsed-command[data-selected="true"] { background: none !important; }';
      if (selectedCommands?.length) {
        // Should be same as dashboard/src/js/components/SnippetEditor/EmbeddedCommand/embedded_command.css (.collapsed-command[data-selected="true"])
        setStyles(`
          ${resetStyles}
          ${selectedCommands.map(id => '.ql-editor #' + id).join(', ')} { background: linear-gradient(Highlight, Highlight) !important; }
        `);
      } else {
        setStyles(resetStyles);
      }
    };
    this.quill.on('selection-change', (range) => {
      setStyles('');
      if (range !== null) {
        this.rangeOverride = null;
      }
      this.assessPosition(range, undefined, undefined, this.context?.types);
      this.updateSelected(range);
    });

    /**
     * @type {HTMLStyleElement}
     */
    let styleNode;
    /**
     * 
     * @param {string} styles 
     * @returns 
     */
    const setStyles = (styles) => {
      if (!styles) {
        if (styleNode) {
          styleNode.remove();
        }
        return;
      } 
      if (!styleNode) {
        styleNode = document.createElement('style');
        document.head.appendChild(styleNode);
      } else if (!styleNode.isConnected) {
        document.head.appendChild(styleNode);
      } else if (styleNode.textContent === styles) {
        return;
      }
      styleNode.textContent = styles;
    };
  }


  /** @type {function(import('../SnippetEditor/SnippetEditor').EditorContextType): void} */
  updateContext(c) {
    // necessary for the variable replace name to work correctly
    c.rangeIndex = c.rangeIndex ?? this.context?.rangeIndex;
    c.nodesData = c.nodesData ?? this.context?.nodesData;

    this.context = c;
    this.contextCallback(c);
  }

  // This is used to by the suggestion bar to keep
  // the editor position even when the editor is blurred.
  // Otherwise, quill returns null for the range.
  lockPosition(range) {
    this.rangeOverride = range;
  }

  /**
   * @param {object=} range
   * @param {import("../../snippet_processor/ParseNode").default[]} expandedNewTokens - needed for context calculation
   * @param {import("../../snippet_processor/ParseNode").default[]} fullNewTokens - needed for names
   * @param {object} nodeTypes
   * @param {ImportedParseNode[]} nodesData
   */
  assessPosition(range, expandedNewTokens = null, fullNewTokens = null, nodeTypes = null, nodesData = null) {

    if (this.rangeOverride) {
      range = this.rangeOverride;
    }
    if (!this.contextCallback) {
      return;
    }
    if (fullNewTokens) {
      this.tokens = expandedNewTokens.filter(t => (t.type === 'expand' && t.tag !== 'text') || t.type === 'error');
      this.tokens = this.tokens.map(t => {
        if (t.type === 'error' && t.info.node) {
          t.info.node.startPosition = t.startPosition;
          t.info.node.endPosition = t.endPosition;
          t.info.node.info.error = t.info.message;
          return t.info.node;
        }
        return t;
      });

      let lcNames = [];
      for (let token of fullNewTokens) {
        let info = token.info;
        // attributes may not be defined if it is an error
        if (info.attributes) {
          let attrs = info.attributes.position.slice();
          for (let key in info.attributes.keys) {
            let attr = info.attributes.keys[key];
            if (!attr.fillIn) {
              if (key === 'name' || attr.type === 'identifier') {
                if (!attrs.includes(attr)) {
                  attrs.push(attr);
                }
              }
            }
          }
          for (let attr of attrs) {
            let key = attr.name;
            if (!attr.fillIn) {
              if (key === 'name' || attr.type === 'identifier') {
                let name = attr.final || attr.evaluated;
                if (name) {
                  // for refs that have array indexing
                  if (!lcNames.includes(name.toLowerCase())) {
                    lcNames.push(name.toLowerCase());
                  }
                }
              } else if (info.command === 'dbselect' && attr.name === 'query') {
                if (info.attributes.keys['name'] && !info.attributes.keys['name'].fillIn) {
                  // don't need to save analyze query if results will be put in a single form
                  // name
                  continue;
                }
                try {
                  let parsed = astSQL(attr.final || attr.evaluated, new Environment());
                  if (parsed.type === 'query') {
                    for (let name of parsed.info.info.base.info.columns.map(col => col.info.alias.info)) {
                      if (!lcNames.includes(name.toLowerCase())) {
                        lcNames.push(name.toLowerCase());
                      }
                    }
                  }
                } catch {
                  // pass - invalid SQL
                }
              }
            }
          }
        }
      }
      this.names = lcNames;
    }

    if (!this.tokens || (!range && this.context)) {
      // nodeTypes is different when double clicking on
      // chip form of run command, but active types are
      // same.
      let activeTypes = getAvailableVariablesForCursorPosition(range?.index, nodeTypes);
      if (
        !equals(this.context?.activeTypes, activeTypes) ||
          !equals(this.context?.nodesData, nodesData) ||
          !equals(this.context?.types, nodeTypes)
      ) {
        this.updateContext({
          types: nodeTypes,
          activeTypes,
          rangeIndex: range?.index,
          nodesData: nodesData
        });
      }

      return;
    }

    let inToken, inAttribute, editingAttribute;
    if (range) {
      let cursorStart = range.index;
      let cursorEnd = range.index + range.length;
      inToken = this.tokens.find(token => typeof token.info !== 'string' && cursorStart > token.startPosition && cursorStart < token.endPosition && cursorEnd > token.startPosition && cursorEnd < token.endPosition);
      if (inToken) {
        let attributes = inToken.info && inToken.info.attributes && inToken.info.attributes.position;
        if (attributes) {
          inAttribute = attributes.find(attr => cursorStart >= attr.startPosition && cursorStart < attr.endPosition && cursorEnd >= attr.startPosition && cursorEnd < attr.endPosition);
          if (inAttribute) {
            editingAttribute = cursorStart >= inAttribute.midPosition && cursorStart <= inAttribute.endPosition && cursorEnd >= inAttribute.midPosition && cursorEnd <= inAttribute.endPosition;
          }
        }
      }
    }

    this.updateContext({
      hasFocus: !!range,
      tag: inToken && inToken.tag,
      token: inToken,
      attribute: inAttribute,
      editingAttribute,
      names: this.names,
      types: nodeTypes,
      activeTypes: getAvailableVariablesForCursorPosition(range?.index, nodeTypes),
      rangeIndex: range?.index,
      nodesData: nodesData
    });
    this.updateTokenHighlight(inToken && (inToken.info.start || inToken).startPosition);
  }

  highlightVersion = 0;

  /**
   * 
   * @param {boolean} embed 
   * @param {DeltaType} deltaBase 
   * @param {'user' | 'api' | 'silent' } updateSource
   * @returns {Promise<import('quill/core').Delta|undefined>}
   */
  async highlight(embed = false, deltaBase = null, updateSource = 'silent') {
    /**
     * @type {Quill}
     */
    const quill = this.quill;
    clearTimeout(this.highlightTimer);
    const currentVersion = ++this.highlightVersion;
    if (window['testing-highlighter-no-collapse']) {
      embed = false;
    }

    addBreadcrumb({
      message: 'Start highlight'
    });

    if (!this.isCollapsedEnabled) {
      embed = false;
    }

    // @ts-ignore
    if (quill.selection.composing) {
      return;
    }

    // keep track of if we are in the process of highlighting
    // as we don't want to save any updates due to it
    this.isUpdatingQuill = true;

    quill.update('user');
    
    let range = this.quill.getSelection();



    let origIndex = this.textChangeIndex;
    let currentContents = quill.getContents();
    // if we have a deltaBase, use that, otherwise use the
    // current contents
    let expanded = deltaBase || expandDeltaContents(/** @type {any} */ (currentContents));

    const env = getHighlightEnvironment(this.addons);
    let tokens = await tokenize(expanded, null, env);

    if (currentVersion !== this.highlightVersion) {
      return;
    }

    await tokenizeAttributes(tokens, env);

    if (currentVersion !== this.highlightVersion) {
      return;
    }

    if (this.attributeType && this.attributeType !== 'text') {
      // Remove command tokens which are not valid in a formula
      const attributeOps = highlightFormulaOps(joinDelta(expanded), {
        isSQL: this.attributeType === 'sql',
        isIterator: this.attributeType === 'iterator',
        isBlock: this.attributeType === 'block'
      });
      let attributeOpIndex = 0;
      let attributeCursor = 0;
      let newTokens = [...tokens];
      for (let i = 0; i < tokens.length; i++) {
        let token = tokens[i];
        let validCommand = false;
        for (; attributeOpIndex < attributeOps.length; attributeOpIndex++) {
          const op = attributeOps[attributeOpIndex],
            opLength = op.insert.length;
          if (
            attributeCursor === token.startPosition 
            && attributeCursor + opLength === token.endPosition
            && op.attributes.token === 'command'
          ) {
            validCommand = true;
          } else if (attributeCursor + opLength >= token.endPosition) {
            break;
          }
          attributeCursor += opLength;
        }
        if (!validCommand) {
          newTokens.splice(newTokens.indexOf(token), 1);
        }
      }
      tokens = newTokens;
    }

    const nodes = tokens.slice();

    addBreadcrumb({
      message: 'Done tokenizing'
    });


    if (origIndex !== this.textChangeIndex) {
      this.isUpdatingQuill = false;
      // bail if there was a text change while we were tokenizing
      return;
    }

    /**
     * @type {CollapsedCommandBlot[]}
     */
    let collapsedBlots = !deltaBase ? this.quill.scroll.descendants(CollapsedCommandBlot) : [];


    let origTokens = tokens.slice();


    // Maps a token start position for the first token in a matching set
    // to the quill ids used.
    /** @type {Object<number, string[]>} */
    this.tokenMap = {};
  
    let currentToken = 0;
    for (let i = 0; i < collapsedBlots.length; i++) {
      let collapsedBlot = collapsedBlots[i];
      let currentData = collapsedBlot.blazeData;
      let commandText = embedDataToCommand(currentData);
      let blotStart = this.quill.getIndex(collapsedBlot);
      
      if (currentToken >= tokens.length) {
        // no matching token for the blot, so convert it to text
        // no need to apply any index shifts as it will be text in the source
        collapsedBlot.replaceWith('text', commandText);
        if (range && blotStart < range.index) {
          range.index += commandText.length - 1;
        }
      } else {
        for (let j = currentToken; j < tokens.length; j++) {
          let token = tokens[j];
          if (token.startPosition >= blotStart) {
            
            if (token.startPosition === blotStart) {
              let id = currentData.meta.id;
              if (!id) {
                // when pasted in, we need to initialize the id and position
                id = '[' + (globalId++) + ']';
                currentData.meta.id = id;
                let newData = getCollapsedData(id, token);
                currentData.meta.blazeRootTokenStartPosition = newData.rootTokenStartPosition;
              }

              // we have a token matching the blot, let's update the
              // blot's data
              let newData = getCollapsedData(id, token);
              
              currentToken = j;

              if (!areAttrsEqual(collapsedBlot.blazeData, newData)) {
                // Note this should not occur, it can occur if there are `undefined`
                // on attributes or other parts of the spec. These get stripped
                // leading to differences, instead of `undefined` `null` should
                // be used.
                //
                // A great way to see the differences is:
                //
                // yarn add json-diff
                //
                // console.log(diffString(collapsedBlot.domNode._blazeData, newData));
                console.error('invalid token update', collapsedBlot.blazeData?.value?.type, newData?.value?.type);
              }
              if (embed && areChildNodesUpdated(collapsedBlot.blazeData, newData)) {
                collapsedBlot.replaceWith('collapsedCommand', newData);
              } else if (
                // Root have changed due to edit. Replace the data with new data.
                collapsedBlot.blazeData.meta.rootTokenStartPosition !== newData.meta.rootTokenStartPosition
              ) {
                collapsedBlot.updateData(newData);
              }
              //@ts-expect-error
              if (collapsedBlot.domNode._BlazeErrorCallbackFn) {
                //@ts-expect-error
                collapsedBlot.domNode._BlazeErrorCallbackFn(newData.meta.error);
              }

              const rootToken = token.info.start || token;
              if (!this.tokenMap[rootToken.startPosition]) {
                this.tokenMap[rootToken.startPosition] = [];
              }
              this.tokenMap[rootToken.startPosition].push(id);

              // the token itself should only have a length of 1
              token.endPosition = token.startPosition + 1;


              let shift = (commandText.length - 1);
              // we need to recalculate positions due to the collapsed blots
              for (let k = j + 1; k < tokens.length; k++) {
                tokens[k].startPosition -= shift;
                tokens[k].endPosition -= shift;
                if (tokens[k].info.attributes) {
                  // attributes may not be defined if it is an error
                  tokens[k].info.attributes.position.forEach(a => {
                    a.startPosition -= shift;
                    a.midPosition -= shift;
                    a.endPosition -= shift;
                  });
                }
              }

              tokens.splice(j, 1);   
            } else if (token.startPosition > blotStart) {
              // no matching token for the blot, so convert it to text
              // no need to apply any index shifts as it will be text in the source
              collapsedBlot.replaceWith('text', commandText);
              if (range && blotStart < range.index) {
                range.index += commandText.length - 1;
              }
            }
            
            break;
          } else if (token.endPosition > blotStart) {
            // note we check endPosition in case the collapsed blot is sourrounded
            // by the token (can happen when the token is a calc: `{= {collapsed} }` ).

            // no matching token for the blot, so convert it to text
            collapsedBlot.replaceWith('text', commandText);
            if (range && blotStart < range.index) {
              range.index += commandText.length - 1;
            }
            break;
          }
        }
      }
    }

    if (embed) {
      // if we're collapsing things, we need to adjust the position for all tokens
      let shifts = [];
      for (let i = 0; i < tokens.length; i++) {
        let token = tokens[i];
        shifts.push(token.endPosition - token.startPosition - 1);

        // for the delta change creation we need the original position
        token['origStartPosition'] = token.startPosition;
        token['origEndPosition'] = token.endPosition;
        token.endPosition = token.startPosition + 1;
      }
      for (let i = 0; i < tokens.length - 1; i++) {
        let shift = shifts[i];
        for (let k = i + 1; k < tokens.length; k++) {
          tokens[k].startPosition -= shift;
          tokens[k].endPosition -= shift;
        }
      }
    }

    let expandedTokens = tokens.slice();

    let tPos = [];

    for (let i = 0; i < tokens.length; i++) {
      tPos[i] = {
        item: tokens[i],
        start: tokens[i].startPosition,
        end: tokens[i].endPosition - 1,
        id: '[' + (globalId++) + ']',
        type: getTokenType(tokens[i]),
        attributes: JSON.stringify(removeAttributeNodes(getCollapsedData('', tokens[i]).value.attributes))
      };
    }
    /**
     * @type {InlineCommandBlot[]}
     */
    let replacementBlots = !deltaBase ? this.quill.scroll.descendants(InlineCommandBlot) : [];
    
    let rPos = [];
    for (let i = 0; i < replacementBlots.length; i++) {
      let start = this.quill.getIndex(replacementBlots[i]);
      let id = replacementBlots[i].domNode.getAttribute('data-blaze-id');
      if (!id) {
        // It's likely been copy and pasted in
        replacementBlots[i].unwrap();
        continue;
      }
      
      let items = [replacementBlots[i]];
      let missingValue = false;
      while (i < replacementBlots.length - 1
        && (replacementBlots[i + 1].domNode.getAttribute('data-blaze-id') || '').startsWith(id)) {
        i++;
        if (!replacementBlots[i].commandValue) {
          missingValue = true;
        }
        items.push(replacementBlots[i]);
      }
      if (!items?.length) {
        continue;
      }
      let newRPos = {
        items,
        start,
        type: items[0].domNode.getAttribute('data-blaze-type'),
        id: items[0].domNode.getAttribute('data-blaze-id'),
        attributes: items[0].domNode.getAttribute('data-blaze-attributes'),
        end: undefined,
        missingValue
      };

      rPos.push(newRPos);
      newRPos.end = this.quill.getIndex(items[items.length - 1]) + items[items.length - 1].length() - 1;
    }

    for (let r = 0; r < rPos.length; r++) {
      let replacement = rPos[r];

      for (let t = 0; t < tPos.length; t++) {
        let token = tPos[t];
        let isTheSame = replacement.start === token.start
          && replacement.end === token.end
          && (replacement.type === token.type
            || (token.type === 'error' && token.item?.info?.node && getTokenType(token.item.info.node) === replacement.type)
          )
         && replacement.attributes === token.attributes
         && !replacement.missingValue;

        if (!isTheSame) {
          continue;
        }
        const rootToken = token.item.info.start || token.item;
        let rootStartPosition = rootToken.startPosition;
        if (!this.tokenMap[rootStartPosition]) {
          this.tokenMap[rootStartPosition] = [];
        }
        this.tokenMap[rootStartPosition].push(replacement.id);

          
        rPos.splice(r, 1);
        tPos.splice(t, 1);
        r--;
        break;
      }
    }

    for (let r of rPos) {
      r.items.forEach(blot => blot.unwrap());
    }
      
    let changes = [];
    for (let t of tPos) {
      let token = t.item;
      const rootToken = token.info.start || token;
      let rootStartPosition = rootToken.startPosition;
      if (!this.tokenMap[rootStartPosition]) {
        this.tokenMap[rootStartPosition] = [];
      }
      this.tokenMap[rootStartPosition].push(t.id);

      let startPosition = 'origStartPosition' in token ? /** @type {number} */(token['origStartPosition']) : token.startPosition;
      let endPosition = 'origEndPosition' in token ? /** @type {number} */ (token['origEndPosition']) : token.endPosition;

      changes.push({
        start: startPosition,
        length: endPosition - startPosition,
        type: t.type,
        id: t.id,
        token
      });
    }

    changes.sort((a,b) => a.start - b.start);


    // We group all our changes together into one delta for efficiency
    let delta = new Delta();
    let last = 0;
    let deltaBaseTracker;
    for (let i = 0; i < changes.length; i++) {
      if (last !== changes[i].start) {
        delta.retain(changes[i].start - last);
      }

      let collapsedData = getCollapsedData(changes[i].id, changes[i].token);

      if (embed && !collapsedData.meta.error) {
        delta.delete(changes[i].length);
        deltaBaseTracker = findNextOps(expanded, changes[i].start, deltaBaseTracker);
        delta.insert({
          collapsedCommand: collapsedData
        }, deltaBaseTracker ? expanded.ops[deltaBaseTracker.index].attributes : null);
      } else {
        delta.retain(1, { replacement: {
          leftSide: true,
          data: collapsedData
        } });
        delta.retain(changes[i].length - 2, { replacement: {
          data: collapsedData
        } });
        delta.retain(1, { replacement: {
          rightSide: true,
          data: collapsedData
        } });
      }
      last = changes[i].start + changes[i].length;
    }

    if (deltaBase) {
      deltaBase.ops = deltaBase.ops.filter(op => op.insert !== '');
      // it's initial set highlight, so we don't use "updateSource"
      this.quill.setContents(new Delta(deltaBase).compose(delta));
    } else {
      // it's an update highlight
      if (delta.ops.length) {
        this.quill.updateContents(delta, updateSource);
      }
    }



    if (window['testing-no-highlighter']) {
      // don't assess position when testing as it
      // will trigger a callback that sets state
      this.isUpdatingQuill = false;
      return;
    }

    let nodeTypes = null;
    try {
      nodeTypes = await calculateNodeTypes(nodes);
    } catch (e) {
      console.error(e);
    }

    this.assessPosition(range, expandedTokens, origTokens, nodeTypes, nodes);

    // No need to use "updateSource" on these two quill updates since they change the range selection, and should be silent
    this.quill.update('silent');
    if (range != null) {
      this.quill.setSelection(range, 'silent');
    }
    
    this.isUpdatingQuill = false;

    return delta;
  }

  clearTokenHighlight = () => {
    [...this.quill.root.querySelectorAll('[data-highlighted="true"]')].forEach(
      el => delete /** @type {HTMLElement} */ (el).dataset.highlighted
    );
  };

  /**
   * 
   * @param {number} rootStartPosition 
   * @param {string=} smallChipId 
   * @returns 
   */
  updateTokenHighlight = (rootStartPosition, smallChipId) => {
    if (rootStartPosition === undefined || rootStartPosition === null || !this.tokenMap) {
      this.clearTokenHighlight();
      return;
    }

    const tokenEls = /** @type {HTMLElement[]} */ ([...this.quill.root.querySelectorAll('[data-blaze-id]')]);
    const tokenIds = this.tokenMap[rootStartPosition] || [];
    for (const tokenEl of tokenEls) {
      const highlighted = tokenIds.includes(tokenEl.dataset.blazeId.split('-')[0]);
      if (highlighted && !tokenEl.dataset.highlighted) {
        tokenEl.dataset.highlighted = 'true';
      } else if (!highlighted && tokenEl.dataset.highlighted) {
        delete tokenEl.dataset.highlighted;
      }
    }

    let collapsedCommands = /** @type {NodeListOf<HTMLElement>} */ (this.quill.root.querySelectorAll('.collapsed-command'));

    /**
     * @type {HTMLElement[]}
     */
    let rootCommandNodes = [];
    for (const collapsed of collapsedCommands) {
      const highlighted = tokenIds.includes(getCollapsedCommandBlot(collapsed).blazeData.meta.id);
      
      const isChildChip = !smallChipId;

      const rootToBeHighlighted = highlighted && isChildChip;
      if (highlighted) {
        rootCommandNodes.push(collapsed);
      }
      if (rootToBeHighlighted && !collapsed.dataset.highlighted) {
        collapsed.dataset.highlighted = 'true';
      } else if (!rootToBeHighlighted && collapsed.dataset.highlighted) {
        delete collapsed.dataset.highlighted;
      }
    }

    let smallChips = /** @type {NodeListOf<HTMLElement>} */ (this.quill.root.querySelectorAll('.small-chip'));
    for (const chip of smallChips) {
      if (
        !chip.dataset.highlighted
        && chip.dataset.tokenId === smallChipId
        && rootCommandNodes.some(node => node.contains(chip))
      ) {
        chip.dataset.highlighted = 'true';
      } else if (chip.dataset.highlighted && chip.dataset.tokenId !== smallChipId) {
        delete chip.dataset.highlighted;
      }
    }
  };


  /**
   * Update selection highlight for collapsed commands.
   * 
   * @param {{index: number, length: number}} range 
   */
  updateSelected(range) {
    let commands = /** @type {NodeListOf<HTMLElement>} */ (this.quill.root.querySelectorAll('.collapsed-command'));

    for (let command of commands) {
      // shortcut for common path when nothing is selected
      if (!range || !range.length) {
        if (command.dataset.selected) {
          delete command.dataset.selected;
        }
        continue;
      }

      let index = this.quill.getIndex(getCollapsedCommandBlot(command));
      if (index >= range.index && index < range.index + range.length) {
        command.dataset.selected = 'true';
      } else {
        if (command.dataset.selected) {
          delete command.dataset.selected;
        }
      }
    }
  }
}


Quill.register({ 'modules/snippetsyntax': SnippetSyntax });



/**
 * @param {ReturnType<import('../SnippetEditor/editor_utilities').getCollapsedData>} data 
 * 
 * @return {string}
 */
function simplifyType(data) {
  if (data.meta.error) {
    return 'error';
  }

  let t = data.value.type;

  if (t.endsWith('_end')) {
    t = t.split('_')[0] + '_start';
  }
  
  return t;
}

/**
 * Finds the ops index from the delta w.r.t start. 
 * @param {DeltaType | import('quill/core').Delta} delta
 * @param {number} start To find the ops based on the character index
 * @param {{ index: number, charIndex: number }} tracker To find the ops. If null, it starts from the start
 */
const findNextOps = (delta, start, tracker = {
  index: 0,
  charIndex: 0
}) => {
  if (!delta) {
    return null;
  }
  const deltaOps = delta.ops;
  let opsCharCount = getDeltaOpsCharacterCount(deltaOps[tracker.index]);

  // Lets check if the tracker is already at the required index.
  // this can be buggy, if tracker moved ahead and called with previous start
  if (tracker.charIndex + opsCharCount > start) {
    return tracker;
  }
  while (tracker.index + 1 < deltaOps.length) {
    tracker.index++;
    tracker.charIndex += opsCharCount;
    if (tracker.charIndex === start) {
      break;
    }
    opsCharCount = getDeltaOpsCharacterCount(deltaOps[tracker.index]);
    //Lets check if next ops exceed before moving to next
    if (tracker.charIndex + opsCharCount > start) {
      break;
    }
  }

  // Should not come here. A safe exit in case some thing was missed
  if (tracker.index >= deltaOps.length) {
    console.error('highlighter[findNextOps]: Index exceeded.');
    return null;
  }
  return tracker;
};

/**
 * Calculates the string length of the given ops
 * @param {DeltaType['ops'][0] | import('quill/core').Delta['ops'][0]} ops 
 */
const getDeltaOpsCharacterCount = (ops) => {
  let charCount = ops.insert.length;
  if (ops.insert['insert-image']
    || ops.insert['image']) {
    charCount = 1;
  }
  if (ops.insert.collapsedCommand) {
    charCount = 1;
  }
  
  if (charCount === null || typeof charCount === 'undefined') {
    console.error('highlighter[findNextOps]: Unknown ops, couldnt find the length', ops);
    charCount = 1;
  }

  return charCount;
};

/**
 * @param {import(".\./../snippet_processor/ParseNode").InfoType} info
 * @returns {string}
 */
const getNameAttribute = (info) => {
  for (const key in info.attributes.keys) {
    const attr = info.attributes.keys[key];
    if (!attr.fillIn && !PLAIN_IDENTIFIERS_KEYS.has(key)) {
      if (key === 'name' || attr.type === 'identifier') {
        return (attr.final || attr.evaluated).toLowerCase();
      }
    }
  }
  for (const attr of info.attributes.position) {
    const key = attr.name;
    if (!attr.fillIn && !PLAIN_IDENTIFIERS_KEYS.has(key)) {
      if (key === 'name' || attr.type === 'identifier') {
        let name = attr.final || attr.evaluated;
        if (name) {
          // for refs that have array indexing
          return name.toLowerCase();
        }
      }
    }
  }
};

/**
 * Finds all variables used in the given tree.
 * 
 * @param {object} tree
 * @param {MultiSet} notVariables
 * @param {string[]=} res
 */
export function getVariables(tree, notVariables, res = []) {
  if (!tree) {
    return;
  }

  if (tree.type) {
    if (tree.type === 'identifier' && !notVariables.has(tree.info)) {
      res.push(tree.info);
    }
  }

  if (tree.info) {
    if (tree.type === 'list') {
      for (let item of tree.info.positional) {
        getVariables(item, notVariables, res);
      }

      for (let item of tree.info.keys) {
        getVariables(item.key, notVariables, res);
        getVariables(item.value, notVariables, res);
      }
    } else if (tree.type === 'lambda') {
      for (let item of tree.info.args) {
        const args = getVariables(item, notVariables);
        args.forEach(arg => notVariables.add(arg));
      }

      getVariables(tree.info.exp, notVariables, res);

      for (let item of tree.info.args) {
        const args = getVariables(item, notVariables);
        args.forEach(arg => notVariables.remove(arg));
      }
    } else if (tree.type === 'list_comprehension') {
      const forExp = tree.info.for;
      getVariables(forExp.info.base, notVariables, res);
      for (let item of forExp.info.args) {
        const args = getVariables(item, notVariables);
        args.forEach(arg => notVariables.add(arg));
      }

      getVariables(tree.info.element, notVariables, res);

      for (let item of forExp.info.args) {
        const args = getVariables(item, notVariables);
        args.forEach(arg => notVariables.remove(arg));
      }
    } else if (tree.type === 'function_call') {
      for (let item of tree.info.args) {
        getVariables(item, notVariables, res);
      }
    } else if (tree.type === 'for') {
      getVariables(tree.info.base, notVariables, res);
      for (let item of tree.info.args) {
        getVariables(item, notVariables, res);
      }
    } else if (tree.type === 'query') {
      getVariables(tree.info, notVariables, res);
    } else if (tree.type === 'not' || tree.type === 'negate') {
      getVariables(tree.info, notVariables, res);
    } else if (tree.type === 'string') {
      // pass
    } else if (tree.type === 'identifier') {
      // pass
    } else if (tree.type === 'command') {
      // pass
    } else {
      for (let child of Object.keys(tree.info)) {
        if (tree.info[child]) {
          if (Array.isArray(tree.info[child])) {
            tree.info[child].map(c => getVariables(c, notVariables, res));
          } else {
            getVariables(tree.info[child], notVariables, res);
          }
        }
      }
    }
  } else if (tree.positional) {
    getVariables(tree.positional, notVariables, res);
  } else if (tree.key && tree.value) {
    getVariables(tree.key, notVariables, res);
    getVariables(tree.value, notVariables, res);
  }

  return res;
};

/**
 * @param {import(".\./../snippet_processor/ParseNode").InfoType} info
 * @returns {Promise<string[]>}
 */
const getDependencies = async (info) => {
  if (info.command !== '=') {
    return [];
  }
  const tree = await info.attributes.position[0].ast(new Environment());
  const identifiers = getVariables(tree, new MultiSet());
  return [...new Set(identifiers)];
};

/**
 * @param {object} tree 
 * @param {Object<string, object>} finalType 
 * @returns {object}
 */
const getTypeFromTree = (tree, finalType) => {
  if (tree.type === 'function_call') {
    const functionsThatReturnList =
      Object.entries(FUNCTIONS).filter(([key, value]) => value.output === 'list' && !['fromjson', 'map'].includes(key)).map(([key, value]) => key);
    if (['sort', 'unique', 'filter', 'slice'].includes(tree.info.name.info)) {
      const args = tree.info.args;
      if (args.length > 0) {
        const argType = getTypeFromTree(args[0], finalType);
        if (argType && argType.type === 'LIST') {
          return argType;
        }
        return { 'type': 'LIST' };
      }
      return { 'type': 'LIST' };
    } else if (tree.info.name.info === 'merge') {
      const args = tree.info.args;
      if (args.length > 0) {
        const result = getTypeFromTree(args[0], finalType);
        if (!result || result.type !== 'LIST') {
          return { 'type': 'LIST' };
        }
        for (let arg of args) {
          const curType = getTypeFromTree(arg, finalType);
          if (!equals(result, curType)) {
            return { 'type': 'LIST' };
          }
        }
        return result;
      }
      return { 'type': 'LIST' };
    } else if (functionsThatReturnList.includes(tree.info.name.info)) {
      return { 'type': 'LIST' };
    } else if (tree.info.name.info === 'map') {
      if (tree.info.args.length === 2) {
        const firstType = getTypeFromTree(tree.info.args[0], finalType);
        if (tree.info.args[1].type !== 'lambda') {
          return { 'type': 'LIST' };
        }
        const lambda = tree.info.args[1].info;
        const argIdentifier = lambda.args.length === 1 ? lambda.args[0].info : null;
        const oldValue = argIdentifier ? finalType[argIdentifier] : null;
        if (!firstType) {
          return { 'type': 'LIST' };
        }
        finalType[argIdentifier] = firstType['value'];
        const secondType = lambda.exp ? getTypeFromTree(lambda.exp, finalType) : null;
        if (oldValue) {
          finalType[argIdentifier] = oldValue;
        } else {
          delete finalType[argIdentifier];
        }
        const result = { 'type': 'LIST' };
        if (secondType) {
          result['value'] = secondType;
        }
        return result;  
      }
      return { 'type': 'LIST' };
    } else if (tree.info.name.info === 'reduce') {
      return getTypeFromTree(tree.info.args[2], finalType).value;
    } else if (tree.info.name.info === 'catch') {
      const firstType = getTypeFromTree(tree.info.args[0], finalType);
      const secondType = getTypeFromTree(tree.info.args[1], finalType);
      if (equals(firstType, secondType)) {
        return firstType;
      }
    } else if (tree.info.name.info === 'find') {
      const listType = getTypeFromTree(tree.info.args[0], finalType);
      if (listType?.type === 'LIST') {
        return listType.value;
      }
    } else if (finalType[tree.info.name.info]?.type === 'lambda') {
      return finalType[tree.info.name.info].value;
    }
  } else if (tree.type === 'identifier') {
    const name = tree.info;
    if (finalType[name]) {
      return finalType[name];
    }
  } else if (tree.type === 'ternary') {
    const trueType = getTypeFromTree(tree.info[1], finalType);
    const falseType = getTypeFromTree(tree.info[2], finalType);
    // This probably can be improved. When two types are not equal
    // like [[1, 2]] and [["a": 1]], we can still infer that the result is a
    // list.
    if (equals(trueType, falseType)) {
      return trueType;
    }
  } else if (tree.type === 'list_comprehension') {
    const base = tree.info.for.info.base;
    const baseType = getTypeFromTree(base, finalType);
    const elem = tree.info.element;
    if (elem.positional && baseType?.type === 'LIST') {
      const arg = tree.info.for.info.args[0].info;
      const originalType = finalType[arg];
      finalType[arg] = baseType.value;
      const elemType = getTypeFromTree(elem.positional, finalType);
      if (originalType) {
        finalType[arg] = originalType;
      } else {
        delete finalType[arg];
      }
      const result = { 'type': 'LIST' };
      if (elemType) {
        result['value'] = elemType;
      }
      return result;
    }
    if (tree.info.element.key) {
      return { 'type': 'KLIST', 'value': {} };
    }
    return { 'type': 'LIST' };
  } else if (tree.type === 'list') {
    if (tree.info.keys.length > 0) {
      const result = {};
      tree.info.keys.forEach(keyObj => {
        // figure best way to handle keyed lists which keys are expressions
        if (keyObj.key.type === 'string') {
          const name = keyObj.key.info;
          const value = getTypeFromTree(keyObj.value, finalType);
          result[name] = value ? value : null;
        }
      });
      return { 'type': 'KLIST', 'value': result };
    } else {
      const values = tree.info.positional;
      if (values.length === 0) {
        return { 'type': 'LIST' };
      }
      const firstValueType = getTypeFromTree(values[0], finalType);
      if (firstValueType && values.every(value => equals(getTypeFromTree(value, finalType), firstValueType))) {
        return { 'type': 'LIST', 'value': firstValueType };
      } else {
        return { 'type': 'LIST' };
      }
    }
  } else if (tree.type === 'command') {
    // for site with multiple option output type is a list
    if (tree.info.startsWith('{site:') && tree.info.includes(' multiple=yes')) {
      return { 'type': 'LIST' };
    }
  } else if (tree.type === 'select') {
    const base = tree.info.base;
    const baseType = getTypeFromTree(base, finalType);
    if (baseType?.type === 'LIST') {
      return baseType.value;
    }
    if (baseType?.type === 'KLIST') {
      const selector = tree.info.selector;
      if (selector.type === 'string') {
        const result = baseType.value[selector.info];
        return result ? result : null;
      }
    }
  } else if (tree.type === 'lambda' && tree.info.exp) {
    // TODO: properly support code blocks
    return {
      'type': 'lambda',
      'value': getTypeFromTree(tree.info.exp, finalType),
    };
  }
  return null;
};

/**
 * @param {Object<string, object>} finalType
 * @param {import(".\./../snippet_processor/ParseNode").InfoType} info
 * @param {string[]} deps
 * @returns {Promise<object>}
 */
const calculateType = async (finalType, info, deps, nestedScopeVariables) => {
  if (info.command === '=') {
    const tree = await info.attributes.position[0].ast(new Environment());
    return getTypeFromTree(tree, finalType);
  } else if (info.command === 'formmenu') {
    if (info.formInfo.multiple) {
      return { 'type': 'LIST' };
    }
  } else if (info.command === 'dbselect') {
    const attr = info.attributes.position.find(pos => pos.name === 'query');
    const multipleAttr = info.attributes.position.find(pos => pos.name === 'multiple');
    if (attr) {
      try {
        const parsed = astSQL(attr.final || attr.evaluated, new Environment());
        if (parsed.type === 'query') {
          const field_names = {};
          
          for (const name of parsed.info.info.base.info.columns.map(col => col.info.alias.info)) {
            field_names[name.toLowerCase()] = null;
          }
          
          const multiple = multipleAttr ? multipleAttr.final || multipleAttr.evaluated : 'no';
          if (multiple === 'yes') {
            return { 'type': 'LIST', 'value': { 'type': 'KLIST', 'value': field_names } };
          } else {
            return { 'type': 'KLIST', 'value': field_names };
          }
        }
      } catch {
        // pass - invalid SQL
      }
    }
  } else if (info.command === 'repeat') {
    const result = {};
    for (const dep of nestedScopeVariables) {
      result[dep] = finalType[dep] ? finalType[dep] : null;
    }
    return { 'type': 'LIST', 'value': { 'type': 'KLIST', 'value': result } };
  } else if (info.command === 'run') {
    return getTypeFromTree(info['statement'], finalType);
  }
  return null;
};

const calculateScopes = (nodes) => {
  const stack = [];
  const scope = [];
  for (let index = 0; index < nodes.length; index++) {
    const node = nodes[index];
    scope.push(index);
    if (node.info.type === 'repeat_start') {
      stack.push(index);
    } else if (node.info.type === 'repeat_end') {
      const startIndex = stack.pop();
      scope[startIndex] = index;
    }
  }
  return scope;
};

const calculateScopeWithNames = (nodes, finalType, availableVariables, localVariables, scopes, startIndex, endIndex) => {
  if (nodes.length === 0) {
    return { startPosition: 0, endPosition: 0, names: {}, children: [] };
  }
  const startPos = startIndex > 0 ? nodes[startIndex - 1].endPosition : 0;
  const endPos = endIndex < nodes.length ? nodes[endIndex].startPosition : Infinity;
  const result = {
    startPosition: Math.min(startPos, endPos),
    endPosition: Math.max(startPos, endPos),
    names: {},
    children: [],
  };
  const currentLevelVariables = [];
  for (let index = startIndex; index < endIndex;) {
    const node = nodes[index];
    const info = node.info;
    if (info.attributes) {
      const name = getNameAttribute(info);
      if (name && !availableVariables.has(name)) {
        currentLevelVariables.push(name);
        availableVariables.add(name);
      }
    }
    index = scopes[index] + 1;
  }
  for (const local of Object.keys(localVariables)) {
    currentLevelVariables.push(local);
    availableVariables.add(local);
  }
  for (let index = startIndex; index < endIndex;) {
    const node = nodes[index];
    const info = node.info;
    if (info.attributes) {
      const name = getNameAttribute(info);

      if (name && !currentLevelVariables.includes(name)) {
        index = scopes[index] + 1;
        continue;
      }

      if (info.type === 'repeat_start') {
        const finishIndex = scopes[index];

        const currentLocalVariables = {};
        const attr = info.attributes.position.find(el => el.name === 'times');
        const parsed = ast(attr.final || attr.evaluated, new Environment());
        if (parsed.type === 'list_comprehension') {
          const base = parsed.info.for.info.base;
          const baseType = getTypeFromTree(base, finalType);
          const arg = parsed.info.for.info.args[0].info;
          if (baseType && baseType.value) {
            finalType[arg] = baseType.value;
          } else {
            finalType[arg] = null;
          }
          currentLocalVariables[arg] = finalType[arg];
          // availableVariables.add(arg);
        }
        const nestedScope = calculateScopeWithNames(nodes, finalType, availableVariables, currentLocalVariables, scopes, index + 1, finishIndex);
        
        result.children.push(nestedScope);
      } else if (info.command === 'run') {
        const attr = info.attributes.position[0];
        const statementList = attr.astCache.info.info;
        const availableLocalVariables = {};
        const localIndexes = [];
        for (let i = 0; i < statementList.length; i++) {
          const statement = statementList[i];
          if (statement.type === 'initialize_local_statement') {
            localIndexes.push(i);
          } else if (statement.type === 'assign_statement') {
            const name = statement.info[0].info;
            result.names[name] = finalType[name];
          }
        }
        for (let i = 0; i < localIndexes.length; i++) {
          const idx = localIndexes[i];
          const nxt = i + 1 < localIndexes.length ? localIndexes[i + 1] : -1;
          const statement = statementList[idx];
          const name = statement.info[0].info;
          availableLocalVariables[name] = finalType[name];
          result.children.push({
            startPosition: node.startPosition + statement.startPosition,
            endPosition: nxt !== -1 ? node.startPosition + statementList[nxt].startPosition - 1 : node.endPosition - 1,
            names: { ...availableLocalVariables },
            children: [],
          });
        }
      }

      for (const key in info.attributes.keys) {
        if (PLAIN_IDENTIFIERS_KEYS.has(key)) {
          const attr = info.attributes.keys[key];
          const name = (attr.final || attr.evaluated).toLowerCase();
          result.names[name] = finalType[name];
        }
      }

      if (name) {
        result.names[name] = finalType[name]; 
      } else if (info.command === 'dbselect') {
        const attr = info.attributes.position.find(pos => pos.name === 'query');
        if (attr) {
          try {
            const parsed = astSQL(attr.final || attr.evaluated, new Environment());
            if (parsed.type === 'query') {
              for (const name of parsed.info.info.base.info.columns.map(col => col.info.alias.info)) {
                const lname = name.toLowerCase();
                result.names[lname] = finalType[lname];
              }
            }
          } catch {
            // pass - invalid SQL
          }
        }
      } else if (info.command === 'urlload') {
        const attributes = info.attributes;
        if (attributes?.keys?.done) {
          const attr = attributes.keys.done;
          try {
            const parsed = ast(attr.final || attr.evaluated, new Environment());
            if (parsed.type === 'lambda') {
              const type = getTypeFromTree(parsed.info.exp, finalType);
              if (type && type.type === 'KLIST') {
                for (const key in type.value) {
                  result.names[key] = type.value[key];
                }
              }
            }
          } catch (e) {
            // pass - invalid SQL
          }
        }
      }
    }
    index = scopes[index] + 1;
  }
  for (const variable of currentLevelVariables) {
    availableVariables.delete(variable);
  }
  for (const local of Object.keys(localVariables)) {
    if (!result.names[local]) {
      result.names[local] = localVariables[local];
    }
    availableVariables.delete(local);
  }
  return result;
};

const calculateDependenciesWithScopes = async (nodes, availableVariables, scopes, tree, nestedScopeVariables, nodeOfName, finalType, startIndex, endIndex) => {
  const currentLevelVariables = [];
  // first run through current level and get all available variable names
  for (let index = startIndex; index < endIndex;) {
    const node = nodes[index];
    const info = node.info;
    if (info.attributes) {
      const name = getNameAttribute(info);
      if (name && !availableVariables.has(name)) {
        currentLevelVariables.push(name);
        availableVariables.add(name);
      }
    }
    index = scopes[index] + 1;
  }
  for (let index = startIndex; index < endIndex;) {
    const node = nodes[index];
    const info = node.info;
    // attributes may not be defined if it is an error
    if (info.attributes) {
      const name = getNameAttribute(info);
      for (const key in info.attributes.keys) {
        if (PLAIN_IDENTIFIERS_KEYS.has(key)) {
          const attr = info.attributes.keys[key];
          const name = (attr.final || attr.evaluated).toLowerCase();
          tree[name] = [];
          finalType[name] = null;
        }
      }
      if (name) {
        if (info.type === 'repeat_start') {
          const finishIndex = scopes[index];
          const nestedDeps = await calculateDependenciesWithScopes(nodes, availableVariables, scopes, tree, nestedScopeVariables, nodeOfName, finalType, index + 1, finishIndex);
          tree[name] = nestedDeps;
          nestedScopeVariables[name] = nestedDeps;
          const attr = info.attributes.position.find(el => el.name === 'times');
          const parsed = ast(attr.final || attr.evaluated, new Environment());
          if (parsed.type === 'list_comprehension') {
            const variables = getVariables(parsed, new MultiSet());
            tree[name] = [...nestedDeps, ...variables];
            const arg = parsed.info.for.info.args[0].info;
            nestedScopeVariables[name] = [...nestedDeps, arg];
            const base = parsed.info.for.info.base;
            const baseType = getTypeFromTree(base, finalType);
            if (baseType && baseType.value) {
              finalType[arg] = baseType.value;
            } else {
              finalType[arg] = null;
            }
          }
          nodeOfName[name] = node;
        } else {
          const allDeps = await getDependencies(info);
          tree[name] = allDeps;
          nodeOfName[name] = node;
        }
      } else if (info.command === 'dbselect') {
        const multipleAttr = info.attributes.position.find(pos => pos.name === 'multiple');
        const multiple = multipleAttr ? multipleAttr.final || multipleAttr.evaluated : 'no';
        const attr = info.attributes.position.find(pos => pos.name === 'query');
        if (attr) {
          try {
            const parsed = astSQL(attr.final || attr.evaluated, new Environment());
            if (parsed.type === 'query') {
              for (const name of parsed.info.info.base.info.columns.map(col => col.info.alias.info)) {
                const lname = name.toLowerCase();
                tree[lname] = [];
                finalType[lname] = multiple === 'yes' ? { 'type': 'LIST' } : null;
              }
            }
          } catch {
            // pass - invalid SQL
          }
        }
      } else if (info.command === 'urlload') {
        const attributes = info.attributes;
        if (attributes?.keys?.done) {
          const attr = attributes.keys.done;
          try {
            const parsed = ast(attr.final || attr.evaluated, new Environment());
            if (parsed.type === 'lambda') {
              const type = getTypeFromTree(parsed.info.exp, finalType);
              if (type && type.type === 'KLIST') {
                for (const key in type.value) {
                  finalType[key] = type.value[key];
                }
              }
            }
          } catch (e) {
            // pass - invalid SQL
          }
        }
      } else if (info.type === 'repeat_start') {
        // find nested scope interval and make recursive call
        const finishIndex = scopes[index];
        await calculateDependenciesWithScopes(nodes, availableVariables, scopes, tree, nestedScopeVariables, nodeOfName, finalType, index + 1, finishIndex);
      } else if (info.command === 'run') {
        const ast = await info.attributes.position[0].ast(new Environment());
        const statementList = ast.info.info;
        for (const statement of statementList) {
          if (['initialize_local_statement', 'assign_statement'].includes(statement.type)) {
            const name = statement.info[0].info;
            const identifiers = getVariables(statement.info[1], new MultiSet());
            const allDeps = [...new Set(identifiers)];
            tree[name] = allDeps;
            nodeOfName[name] = { command: node.command, info: { ...node.info, 'statement': statement.info[1] } };
          }
        }
      }
    }
    index = scopes[index] + 1;
  }
  for (const variable of currentLevelVariables) {
    availableVariables.delete(variable);
  }
  return currentLevelVariables;
};

/**
 * @param {import("../../snippet_processor/ParseNode").default[]} nodes
 */
const calculateNodeTypes = async (nodes) => {
  const scopes = calculateScopes(nodes);
  // Build dependencies tree
  const tree = {};
  const nestedScopeVariables = {};
  const nodeOfName = {};
  const finalType = {};
  await calculateDependenciesWithScopes(nodes, new Set(), scopes, tree, nestedScopeVariables, nodeOfName, finalType, 0, nodes.length);

  // Build tree on same nodes, but with reversed edges
  const depCount = {};
  const dependants = {};
  for (const [name, allDeps] of Object.entries(tree)) {
    depCount[name] = allDeps.length;
    for (const dep of allDeps) {
      if (dependants[dep] === undefined) {
        dependants[dep] = [];
      }
      dependants[dep].push(name);
    }
  }

  const queue = [];
  for (const [name, depSize] of Object.entries(depCount)) {
    if (depSize === 0) {
      queue.push(name);
    }
  }
  let it = 0;
  while (it < queue.length) {
    const name = queue[it];
    it++;

    // Calculate type as all dependencies have their
    // types already calculated
    if (finalType[name] === undefined) {
      finalType[name] = await calculateType(finalType, nodeOfName[name].info, tree[name], nestedScopeVariables[name]);
    }

    const deps = dependants[name] || [];
    for (const dependant of deps) {
      depCount[dependant]--;
      if (depCount[dependant] === 0) {
        queue.push(dependant);
      }
    }
  }
  return calculateScopeWithNames(nodes, finalType, new Set(), {}, scopes, 0, nodes.length);
};

/**
 * 
 * @param {import('./editor_utilities').CollapsedDataType['value']['attributes']} attributes 
 */
const removeAttributeNodes = (attributes) => {
  if (!attributes) {
    return attributes;
  }
  return attributes.map(({ nodes, ...attr }) => attr);
};

/**
 * Reusable func to make sure the config remains the same for highlight and "replace all variables"
 * since the "tokenize" in both should match
 *
 * @param {object} addons
 * @return {Environment}
 */
export function getHighlightEnvironment(addons) {
  return new Environment(null, {
    stage: 'tokenization',
    domain: null,
    addons: addons || {},
    clipboard: () => '',
    selectorFn: () => '',
    remoteFn: async () => ''
  });
}