import { httpsCallable } from 'firebase/functions';
import { getTBFunctions } from './firebase_utilities';

/**
 * @type {import('firebase/functions').HttpsCallable<any, any>}
 */
let cpapi = httpsCallable(getTBFunctions(), 'cpapi');

/**
 * 
 * @param {string} email 
 * @returns {Promise<boolean>}
 */
export const isSamlUser = async (email) => {
  const response = await cpapi({
    type: 'is-saml-user',
    email: email
  });
  return response.data.check;
};

/**
 * 
 */
export const verifyAuth = async () => {
  const response = await fetch(getTBFunctions().customDomain + '/auth/verify', {
    method: 'POST',
    credentials: 'include',
    cache: 'no-cache',

  });
  if (!response.ok && response.status === 200) {
    return null;
  }
  return response.json();
};

/**
 * @param {string} idToken
 */
export const login = async (idToken) => {
  return fetch(getTBFunctions().customDomain + '/auth/login', {
    credentials: 'include',
    method: 'POST',
    cache: 'no-cache',
    
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    body: JSON.stringify({
      idToken
    })
  });
};

export const logout = async () => {
  await fetch(getTBFunctions().customDomain + '/auth/logout', {
    method: 'POST',
    credentials: 'include',
    cache: 'no-cache'
  });
};


/**
 * @param {string} documentId
 * @param {"user"|"category"|"search"} type
 * @param {Object} extraData
 */
export async function templatesLoadMore(type, documentId, extraData) {
  const response = await cpapi({
    type: 'templatesLoadMore',
    lastDocumentId: documentId,
    templateType: type,
    ...extraData
  });

  return response ? response.data : [];
}