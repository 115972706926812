import React from 'react';
import { log } from '../../logging/logging';
import { useTypedSelectorShallowEquals } from '../../hooks';
import useFirestore from '../../FirestoreLink/FirestoreHook';
import Switch from '@mui/material/Switch';
import { makeRef } from '../../firebase_utilities';
import { FormControlLabel } from '@mui/material';


/**
 * @callback GetDefaultFromStoreFn
 * @param {import('@store').RootState} store the Firestore store object
 * @returns {boolean} default value of 
 * 
 * @param {object} props
 * @param {string} props.propertyName human-readable name of property
 * @param {string} props.propertyKey Firestore key
 * @param {GetDefaultFromStoreFn} props.getDefaultFn 
 * @param {import("@mui/material").FormControlLabelProps["label"]} props.label 
 * @param {boolean} [props.disabled]
 */
export default function ToggleSetting(props) {
  let { propertyName, propertyKey, getDefaultFn } = props;
  let { uid, checkedDefault } = useTypedSelectorShallowEquals(store => ({
    uid: store.userState.uid,
    checkedDefault: getDefaultFn(store)
  }));
  
  let { data, updateFn } = useFirestore(makeRef('users_settings', uid));

  return (
    <FormControlLabel
      control={
        <Switch
          disabled={!data || props.disabled}
          checked={
            data && data.options && typeof data.options[propertyKey] === 'boolean'
              ? data.options[propertyKey]
              : checkedDefault
          }
          onChange={(e) => {
            let v = /** @type {boolean} */ (e.target.checked);
            log({ action: `Set ${propertyName}`, label: v, debounce: 10000 });

            const updKey = `options.${propertyKey}`;
            updateFn({
              [updKey]: v
            });
          }}
        />
      }
      label={props.label}
    />
  );
}
