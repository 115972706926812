import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { TABLES_FRONT_END_DOMAIN } from '../../hooks/useTables';
import DatabaseFrame from './DatabaseFrame';
import ReactDOM from 'react-dom';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import { useTypedSelectorShallowEquals } from '../../hooks';
import SpaceSnippetsPopover from './SpaceSnippetsPopover';

/**
 * @param {object} props
 * @param {object} props.config
 * @param {function=} props.config.onCreated
 * @returns
 */
export const DatabaseTemplatesDialog = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [config, setConfig] = useState(null);
  const [database, setDatabase] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState({ left: 0, top: 0 });
  const templateSuggestedSnippets = useRef(null);
  const iframeRef = useRef(null);
  const favorites = useTypedSelectorShallowEquals((state) => state.userState.settingsLoaded ? (state.userState?.options?.databases || {}) : null);

  let maxOrder = 0;
  if (favorites) {
    maxOrder = Math.max(...[0].concat(Object.values(favorites).filter(db => db.favorite).map(db => db.order || 0)));
  }

  useImperativeHandle(ref, () => ({
    show: (config) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setConfig(config);
        setIsOpen(true);
      });
    }
  }));

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      // @ts-ignore
      maxWidth="95vw"
      onClose={() => setIsOpen(false)}
    >
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: {
          xs: '300px',
          sm: '500px',
          md: '94vw',
        },
        height: '100vh',
        padding: 0,
      }}>
        {!!(database && database.snippets_folder_id) && <SpaceSnippetsPopover
          key={database.id}
          ref={templateSuggestedSnippets}
          database={database}
          anchorPosition={anchorPosition}
          templatesMode
        />}
        <DatabaseFrame
          ref={iframeRef}
          url={`${TABLES_FRONT_END_DOMAIN}/data-blaze/templates`}
          listener={(event) => {
            const type = event.data.type;
            if (type === 'open_imported_space') {
              const databaseId = event.data.data;
              setIsOpen(false);
              storage.update(usersSettingsRef, {
                [`options.databases.${databaseId}.favorite`]: true,
                [`options.databases.${databaseId}.order`]: maxOrder + 1,
              });
              if (config.onCreated) {
                config.onCreated({ databaseId });
              }
            } else if (type === 'close_dialog') {
              setIsOpen(false);
            } else if (type === 'template_database') {
              setDatabase(event.data.data);
            } else if (type === 'open_suggested_snippets') {
              const { rect } = event.data.data;
              setAnchorPosition(rect);
              templateSuggestedSnippets.current.open();
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
});
