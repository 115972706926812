import moment from 'moment';
import { formatLocale } from 'd3-format';
import { parse } from './Parser';
import { DataRequiredError, AUTO_PREFIX } from './ParserUtils';
import ParseNode from './ParseNode';
import { textFromHtml } from '../html_conversion';
import he from 'he';
import { 
  evaluateEquation,
  getLocalEnvironmentGenerator,
  processForStatement,
  toBool,
  toNum,
  eEqn,
  callFn,
  toStr,
  toLst,
  createListFromArray,
  equals,
  createListFromObject,
  runRemoteCommandInsideBlock,
  isSynchronousRemoteCommand,
  getNotificationTitleForRemoteCommand,
} from './Equation';
import { COMMANDS } from './Commands';
import { DataContainer, Environment, DerivedChange } from './DataContainer';
import { applyTimeShift } from './time_shift';
import { getLocale } from '../locales';
import { getOS } from '../engine_utilities';
import { isElectronApp } from '../flags';
import { STYLEABLE_TAGS, isStyleableTagPresent } from './DownstreamProcess';



// Enable to test running in web console:

/*
window['createDom'] = createDom;
window['domToStream'] = domToStream;
window['compressStream'] = compressStream;
window['structureDom'] = structureDom;
window['fillDom'] = fillDom;
window['postProcessDom'] = postProcessDom;
window['splitDom'] = splitDom;
window['dataEnv'] = Environment;
window['featureUsage'] = featureUsage;
*/

/*
compressStream(
  domToStream(
    postProcessDom(
      await fillDom(
        await createDom({ops:[{insert:'1a{note}bc{endnote}d2'}]})
        , new dataEnv({x: 'hi'}, { stage: 'insertion' })
      )
    )
  )
)
*/

/*
Profiling:

setTimeout(async () => {
for(let i = 0; i < 4000; i++){
  let env = new dataEnv();
  let dom = await createDom({ops:[{insert:'1a{note}bc{endnote}d {time:YYYY} {=1+1}2'}]}, env)
  let usage = await featureUsage(dom, env);
  let dEnv = new dataEnv({x: 'hi'}, { stage: 'insertion' });
  dEnv.config.usedCommands = usage.COMMANDS.map(x => x.toUpperCase())
compressStream(
  domToStream(
    postProcessDom(
      await fillDom(dom, dEnv)
    )
  )
);
}
}, 6000)
*/

let SPLIT_POINTS;
let SIDECHANNEL_SUBTYPES;
let REMOVE_SUBTYPES;

/**
 * @param {ParseNode} dom
 */
function findDefaultFont(dom) {
  let defaultFontProp = {
    fontFamilyInitial : null,
    fontSizeInitial : null
  };
  checkAllNodesForFontProp(dom, { fontSize: null, fontFamily: null }, defaultFontProp);
  if (defaultFontProp.fontFamilyInitial === 'none') {
    defaultFontProp.fontFamilyInitial = null;
  }
  if (defaultFontProp.fontSizeInitial === 'none') {
    defaultFontProp.fontSizeInitial = null;
  }
  return defaultFontProp;
}


/**
 * @param {ParseNode} dom
 * @param {{fontFamily, fontSize}} styleProp
 * @param {{fontFamilyInitial, fontSizeInitial}} defaultFontProp
 */
function checkAllNodesForFontProp(dom, styleProp, defaultFontProp) {
  let commonFontFamily = styleProp.fontFamily, commonFontSize = styleProp.fontSize;
  if (dom.tag === 'text') {
    // check only for the non-null text node
    if (dom.info.message) {
      // check if default fontFamily exists and it is not 'none'
      if (defaultFontProp.fontFamilyInitial && defaultFontProp.fontFamilyInitial !== 'none') {
        // if the fontFamily doesn't matches or this node fontFamily is not set, then set the font family property to 'none' 
        if (!commonFontFamily || defaultFontProp.fontFamilyInitial !== commonFontFamily) {
          defaultFontProp.fontFamilyInitial = 'none';
        }
      }
      // check if default fontSize exists and it is not 'none'
      if (defaultFontProp.fontSizeInitial && defaultFontProp.fontSizeInitial !== 'none') {
        // if the font doesn't matches of this node fontSize is not set, then set the font family property to 'none'
        if (!commonFontSize || defaultFontProp.fontSizeInitial !== commonFontSize) {
          defaultFontProp.fontSizeInitial = 'none';
        }
      }
    }
  } else {
    let currentStyle = dom.attrs?.style;
    if (currentStyle) {
      // checking if fontSize property exists and comparing it with the inherited styling 
      if (currentStyle.fontSize) {
        // set the prop of default if not already set
        if (!defaultFontProp.fontSizeInitial) {
          defaultFontProp.fontSizeInitial = currentStyle.fontSize;
        }

        if (commonFontSize && currentStyle.fontSize !== commonFontSize) {
          defaultFontProp.fontSizeInitial = 'none';
        } else {
          commonFontSize = currentStyle.fontSize;
        }
      }
      // checking if fontFamily property exists and comparing it with the inherited styling 
      if (currentStyle.fontFamily) {
        // set the prop of default if not already set
        if (!defaultFontProp.fontFamilyInitial) {
          defaultFontProp.fontFamilyInitial = currentStyle.fontFamily;
        }
        if (commonFontFamily && currentStyle.fontFamily !== commonFontFamily) {
          defaultFontProp.fontFamilyInitial = 'none';
        } else {
          commonFontFamily = currentStyle.fontFamily;
        }
      }
    }
    // set the default font property to 'none' if there is a mismatch
    if (commonFontFamily && commonFontFamily !== defaultFontProp.fontFamilyInitial) {
      defaultFontProp.fontFamilyInitial = 'none';
    }
    if (commonFontSize && commonFontSize !== defaultFontProp.fontSizeInitial) {
      defaultFontProp.fontSizeInitial = 'none';
    }

    let parentStyling = { fontFamily: commonFontFamily, fontSize: commonFontSize };
    for (let i = 0; i < dom.children?.length; i++) {
      if (defaultFontProp.fontFamilyInitial === 'none' && defaultFontProp.fontSizeInitial === 'none') {
        break;
      }
      checkAllNodesForFontProp(dom.children[i], parentStyling, defaultFontProp);
    }
  }
}

/**
 * @param {DeltaType | import('quill/core').Delta} snippet
 * @param {Environment} env
 * 
 * @return {Promise<ParseNode>}
 */
export async function createDom(snippet, env) {
  return parse(snippet, env);
}


/**
 * @param {ParseNode} dom
 * @param {string=} targetType - 'html' or 'text'
 * @param {boolean=} force - Whether to force the type to the targetType.
 * @param {import('./EvaluatorProcess').ContextualStylesType} [contextualStyles] - provided by desktop apps, CSS styles identified from the point of insertion
 * 
 * @return {object[]}
 */
export function domToStream(dom, targetType = 'html', force = false, contextualStyles = {}) {
  /** @type {any} */
  let stream;
  let kind;
  if (targetType === 'html' && (dom.isStyled || force)) {
    stream = domToHTMLStream(dom, undefined, contextualStyles);
    // For the single-line snippets we are attaching styles by adding an outer span as styleable tags will be present in that case
    if (!stream.some(part => typeof part === 'string' && isStyleableTagPresent(part)) && Object.keys(contextualStyles).length > 0) {
      const cssText = getCssTextFromStyle(contextualStyles);
      stream.unshift(`<span style="${escapeAttr('style', cssText)}" data-mce-style="${escapeAttr('data-mce-style', cssText)}">`);
      stream.push('</span>');
    }
    kind = 'html';
  } else {
    stream = domToTextStream(dom);
    if (stream[stream.length - 1] === '\n') {
      stream = stream.slice(0, stream.length - 1);
    }
    kind = 'text';
  }
  stream.kind = kind;
  return stream;
}

const romanize = num => {
  const romans = {
    c: 100,
    xc: 90,
    l: 50,
    xl: 40,
    x: 10,
    ix: 9,
    v: 5,
    iv: 4,
    i: 1
  };
  
  let roman = '';
  
  for (let key in romans) {
    const times = Math.trunc(num / romans[key]);
    roman += key.repeat(times);
    num -= romans[key] * times;
  }

  return roman;
};


/**
 * @param {number} indent 
 * @param {number} counter 
 * @returns {{ text: string, css: string }}
 */
export function olItem(indent, counter) {
  if (indent % 3 === 0) {
    return { text: counter + '. ', css: undefined };
  } else if (indent % 3 === 1) {
    return { text: String.fromCharCode(96 + counter) + '. ', css: 'lower-alpha' };
  } else {
    return { text: romanize(counter) + '. ', css: 'lower-roman' };
  }
}


/**
 * Converts the dom to an array of nodes.
 * 
 * @param {ParseNode} dom
 * @param {{listStack: any[], tableStack: any[]}} state -- notes is an array of booleans of whether the content can be inserted
 * 
 * @return {(string|ParseNode)[]}
 */
function domToTextStream(dom, state = { listStack: [], tableStack: [] }) {
  if (dom.type === 'el') {
    if (dom.tag === 'ol' || dom.tag === 'ul') {
      state.listStack.push({
        type: dom.tag,
        counter: 1
      });
    }
  }

  /** @type {(string|ParseNode)[]} */
  const stream = [];
  if (dom.children) {
    dom.children.forEach(x => stream.push(...domToTextStream(x, state)));
  }

  if (dom.type === 'el') {
    if (dom.tag === 'p' || dom.tag === 'blockquote' || dom.tag === 'br') {
      stream.push('\n');
    } else if (dom.tag === 'ol' || dom.tag === 'ul') {
      state.listStack.pop();
    } else if (dom.tag === 'li') {
      let prepend = '';
      for (let i = 0; i < state.listStack.length; i++) {
        prepend += '\t';
      }
      let list = state.listStack[state.listStack.length - 1];
      if (list.type === 'ul') {
        prepend += '* ';
      } else {
        prepend += olItem(state.listStack.length - 1, list.counter).text;
        list.counter++;
      }
      stream.unshift(prepend);
      stream.push('\n');
    } else if (dom.tag === 'td') {
      if (state.tableStack[state.tableStack.length - 1] === 'cell') {
        stream.unshift('\t');
      }
      state.tableStack.push('cell');
    } else if (dom.tag === 'tr') {
      state.tableStack.push('row');
      stream.push('\n');
    } else if (dom.tag === 'table') {
      state.tableStack.push('table');
    }
  } else if (dom.type === 'expand') {
    if (dom.tag === 'text') {
      stream.push(dom.info.message);
    } else if (dom.tag === 'html') {
      // needed for character counts, when using clipboard in html output
      stream.push(textFromHtml(dom.info.message));
    } else if (dom.tag === 'note' || (dom.tag === 'form' && ['toggle_start', 'toggle_end', 'if_start', 'if_else', 'if_elseif', 'if_end', 'repeat_start', 'repeat_end'].includes(dom.info.type))) {
      // pass
    } else if (dom.tag === 'image') {
      // skip over images in text mode
    } else {
      stream.push(dom);
    }
  } else if (dom.type === 'error') {
    stream.push(`[Error - ${dom.info.message}]`);
  }

  return stream;
}


/**
 * Validates a tag isn't malformed.
 * 
 * @param {string} tag
 * 
 * @return {boolean} true if valid
 */
function validateTag(tag) {
  if (/^[a-z][a-z1-9]*$/.test(tag.toLowerCase())) {
    return true;
  }
  if ((typeof window === 'undefined') || !window['disable_invalid_dom_warning_in_tests']) {
    console.warn('Invalid tag, omitting: ' + tag);
  }
  return false;
}


/**
 * Validates an attribute name.
 * The primary aim is to protect against script injection
 * 
 * @param {string} name
 * 
 * @return {boolean} true if valid
 */
function validateAttrName(name) {
  let lower = name.toLowerCase();

  if (/^[a-z-]+$/.test(lower) && !lower.startsWith('on')) {
    return true;
  }

  if ((typeof window === 'undefined') || !window['disable_invalid_dom_warning_in_tests']) {
    console.warn('Invalid attribute, omitting: ' + name);
  }

  return false;
}


/**
 * Escapes attribute.
 * 
 * @param {string} name
 * @param {string} str
 * 
 * @return {string}
 */
function escapeAttr(name, str) {
  let lName = name.toLowerCase();
  if (lName === 'src' || lName === 'href') {
    // Take extra care to avoid JavaScript URLs and data URLs for links
    let lStr = str.toLowerCase().trim();
    if (
      // Spurious message so,
      // eslint-disable-next-line
      lStr.startsWith('javascript:')
      || (lName === 'href' && lStr.startsWith('data:'))
    ) {
      if ((typeof window === 'undefined') || !window['disable_invalid_dom_warning_in_tests']) {
        console.warn('Invalid URL for: ' + name + '. ' + str);
      }
      return 'about:blank';
    }
  }

  // Escaping the double quote is sufficient, but let's go one level further.
  // https://security.stackexchange.com/questions/142333/xss-inside-html-attribute-where-and-are-filtered
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#x27;');
}

/**
 * 
 * @param {object} style - contains CSS styles in camel case format, such as fontFamily, fontSize, margin, padding, as well as some styles specific to Microsoft editors like msoParaMargin and msoPaddingAlt in desktop apps.
 * It includes both user-selected styles created when making the snippet and contextual styles obtained by desktop apps at the point of insertion.
 */
function getCssTextFromStyle(style) {
  let cssText = '';
  for (let key in style) {
    if (style[key] !== undefined) {
      cssText += key.replace(/([A-Z])/g, '-$1').toLowerCase();
      cssText += ':';
      cssText += style[key];
      cssText += ';';
    }
  }
  return cssText;
}


/**
 * @param {object} dom
 * @param {import('./EvaluatorProcess').ContextualStylesType} [contextualStyles]
 * 
 * @return {string}
 */
function attrStr(dom, contextualStyles = {}) {
  let attrs = dom.attrs;
  if (!attrs) {
    return '';
  }
  if (attrs.style && typeof attrs.style !== 'string') {
    let style = {};
    if (dom.tag === 'p') {
      style.margin = '0px !important';
      style.padding = '0px !important';
      if (isElectronApp()) {
        // We need to add this property for mso editors 
        style.msoParaMargin = '0px';
        style.msoPaddingAlt = '0px';
      }
    }

    for (let s in attrs.style) {
      style[s] = attrs.style[s];
    }

    if (STYLEABLE_TAGS.includes(dom.tag.toUpperCase())) {
      // Add contextual styles
      for (const k of ['font-family', 'font-style', 'font-weight']) {
        // Camel case
        const kc = k.replace(/-([a-z])/g, function(match, p1) {
          return p1.toUpperCase();
        });
        // We do not override existing styles. For example, if the user already has a snippet with the font family 'Arial', and from contextual styles we receive 'Georgia', we will not override it. 
        // However, if it is not present in the style object, which means it is the default, we will add a new font family property with the value 'Arial'.
        if (!style[kc] && contextualStyles[k]) {
          style[kc] = contextualStyles[k];
        }
      }
    }
    if (Object.keys(style).length === 0) {
      delete attrs.style;
    } else {
      const cssText = getCssTextFromStyle(style);
      if (cssText) {
        attrs.style = cssText;
      } else {
        delete attrs.style;
      }
    }
  }
  if (attrs.style) {
    attrs['data-mce-style'] = attrs.style;
  }
  const keys = Object.keys(attrs);
  if (keys.length) {
    return ' ' + keys.filter(x => attrs[x] && validateAttrName(x)).map(x => `${x}="${escapeAttr(x, attrs[x].toString())}"`).join(' ');
  } else {
    return '';
  }
};

/**
 * Recursively fix the last text node in this dom subtree
 * for this element's block subtree as well as subtree of
 * any other block element in this element
 * 
 * @param {ParseNode} dom assumed to be a block element
 * @returns {boolean} true if it successfully finds a textnode in the contained dom
 */
function fixLastTextNode(dom) {
  let alreadyProcessedTextNode = false;

  if (dom.children && dom.children.length > 0) {
    for (let index = dom.children.length - 1; index >= 0; index--) {
      const child = dom.children[index];

      if (child.block()) {
        // always search the last textnode of every block element
        fixLastTextNode(child);
      } else if (!alreadyProcessedTextNode) {
        // only called when a textnode has already not been found
        // for the current block
        const foundTextNode = fixLastTextNode(child);
        if (foundTextNode) {
          alreadyProcessedTextNode = true;
        }
      }
    }
  } else if (dom.type === 'expand' && dom.tag === 'text') {
    dom.info.isLastTextNodeInBlock = true;
    alreadyProcessedTextNode = true;
  } else {
    return false;
  }

  return alreadyProcessedTextNode;
}

/**
 * Converts the dom to an array of nodes.
 * 
 * @param {ParseNode} dom
 * @param {{ defaultFontProp: object, listDepth: number }} [state]
 * @param {import('./EvaluatorProcess').ContextualStylesType} [contextualStyles]
 * 
 * @return {(string|ParseNode)[]}
 */
export function domToHTMLStream(dom, state = { defaultFontProp: null, listDepth: 0 }, contextualStyles = {}) {
  /** @type {(string|ParseNode)[]} */
  const stream = [];
  if (dom.type === 'el' && (dom.tag === 'ol' || dom.tag === 'ul')) {
    state.listDepth++;
  }

  if (dom.type === 'root') {
    fixLastTextNode(dom);
    state.defaultFontProp = findDefaultFont(dom);
  }

  if (dom.children) {
    dom.children.forEach(x => stream.push(...domToHTMLStream(x, { ...state }, contextualStyles)));
  }

  if (dom.type === 'el') {
    if (dom.selfClosing()) {
      if (validateTag(dom.tag)) {
        stream.push('<' + dom.tag + attrStr(dom, contextualStyles) + '/>');
      }
    } else {
      if (validateTag(dom.tag)) {
        if (dom.tag === 'ol') {
          // apply the default styling if it exists
          /** @type {Partial<CSSStyleDeclaration>} */
          let stylesToApply = {};
          if (state.defaultFontProp?.fontFamilyInitial) {
            stylesToApply.fontFamily = state.defaultFontProp.fontFamilyInitial;
          }
          if (state.defaultFontProp?.fontSizeInitial) {
            stylesToApply.fontSize = state.defaultFontProp.fontSizeInitial;
          }

          const listStyleType = olItem(state.listDepth - 1, 0).css;
          if (listStyleType) {
            stylesToApply.listStyleType = listStyleType;
          }
          
          let currentStyles = dom.attrs?.style;
          if (typeof currentStyles !== 'string') {
            if (!dom.attrs) {
              dom.attrs = {};
            }
            dom.attrs.style = { ...dom.attrs.style, ...stylesToApply };
          }
        }
        
        stream.unshift('<' + dom.tag + attrStr(dom, contextualStyles) + '>');
        // Check if empty (whitespace counts as empty)
        // If so we need to put in a placeholder as empty <p> blocks are collapsed.
        //
        // Note that it would make sense to replace spaces with nbsp; instead. But for now
        // we are keeping this logic simpler.
        //
        // We use [ \t] rather than \s or .trim() as we want to preserve non-breaking spaces.
        let isInsertedBreakElement = false;
        if (dom.tag === 'p') {
          let contentNode = dom.find(n => {
            if (n.tag === 'text') {
              if (!!n.info.message.replace(/^[ \t]+/, '').replace(/[ \t]+$/, '')) {
                return true;
              }
            } else {
              if (n.isRenderedIfEmpty() && !(n.type === 'expand' && n.isContentlessExpand())) {
                return true;
              }
            }
            return false;
          }, false);
          if (!contentNode) {
            // If the node contains things like just whitespace, it would be collapsed on insert.
            // We remove it and put in a <br/> to preserve the newline
            let i = stream.findIndex(n => (n instanceof ParseNode) && n.isContentlessExpand());
            if (i > -1) {
              // If there is something like {cursor} we want the <br/> to be at that position
              isInsertedBreakElement = true;
              stream.splice(i + 1, 0, '<br/>');
            } else {
              let i = stream.findIndex(s => (typeof s === 'string') && s.startsWith('</'));
              if (i > -1) {
                // put it before the first closing tag
                isInsertedBreakElement = true;
                stream.splice(i, 0, '<br/>');
              } else {
                // can't find a good place for it, clobber everything inside
                stream.splice(1, stream.length - 1);
                isInsertedBreakElement = true;
                stream.push('<br/>');
              }
            }
          }
        }
        if (dom.tag === 'td') {
          // Use &nbsp; for outlook desktop
          if (stream.length === 1) {
            stream.push('&nbsp;<br />');
          }
          if (stream.filter(c => !c).length === stream.length - 1) {
            stream.push('&nbsp;<br />');
          }
        }
        stream.push('</' + dom.tag + '>');

        if (isInsertedBreakElement) {
          const editorData = dom.root().info.editorData;
          // These editors require a bare <br/> element
          // to render the newlines correctly
          if (editorData?.isDocs) {
            stream.shift();
            stream.pop();
          }
        }
      }
    }
  } else if (dom.type === 'expand') {
    if (dom.tag === 'text') { 
      let encodedText = he.encode(dom.info.message);
      if (dom.info.isLastTextNodeInBlock) {
        encodedText = encodedText.replace(/(\s+)(\n|$)/g, (_, $1, $2) => {
          let res = '';
          for (let _ = 0; _ < $1.length; _++) {
            res += '&nbsp;';
          }
          if ($2 === '\n') {
            res += '\n';
          }
          return res;
        });
      }
      const newlineReplacedText = encodedText.replace(/\n/g, '<br/>');
      stream.push(newlineReplacedText);
    } else if (dom.tag === 'html') {
      stream.push(dom.info.message);
    } else if (dom.tag === 'note' || (dom.tag === 'form' && ['toggle_start', 'toggle_end', 'if_start', 'if_else', 'if_elseif', 'if_end', 'repeat_start', 'repeat_end'].includes(dom.info.type))) {
      // pass
    } else if (dom.tag === 'image') {
      stream.push('<img' + attrStr({ attrs: dom.info.imgAttrs }) + '/>');
    } else {
      stream.push(dom);
    }
  } else if (dom.type === 'error') {
    stream.push(`[Error - ${dom.info.message}]`);
  }

  return stream;
}


/**
 * @param {ParseNode} dom
 */
export function trimNotes(dom) {
  dom.findAll(node => node.tag === 'note' && ['note_start', 'note_end'].includes(node.info.type)).map(n => n.info.processed = false);

  let allNotes = dom.findAll(node => node.tag === 'note' && ['note_start', 'note_end'].includes(node.info.type) && !node.info.processed);
  function nextNote() {
    // root check as node may be removed in prior iteration
    let notes = allNotes.filter(node => !node.info.processed && !!node.findParent(x => x.type === 'root'));
    let noteStack = [];
    let startNote;
    for (let note of notes) {
      if (note.info.type === 'note_start') {
        noteStack.push(note);
      } else {
        startNote = noteStack.pop();
        if (startNote) {
          startNote.info.end = note;
          note.info.start = startNote;
        } else {
          note.parent.removeChild(note);
        }
      }
    }

    while (noteStack.length) {
      let oldStartNote = noteStack.pop();
      oldStartNote.parent.removeChild(oldStartNote);
    }

    return startNote;
  }

  let startNote = nextNote();
  while (startNote) {
    let endNote = startNote.info.end;

    let isTableRow = false;
    let startTable = startNote.findParent(x => x.tag === 'table');
    let endTable = endNote.findParent(x => x.tag === 'table');

    if ((startTable || endTable) && (startNote.findParent(x => x.tag === 'td') !== endNote.findParent(x => x.tag === 'td'))) {
      isTableRow = true;
      // we're spanning table cells and need to ensure we start and end a row
      let valid = true;
      // eslint-disable-next-line no-loop-func
      function isItValid (error) {
        if (valid) {
          return true;
        }
        
        let msg = err(error || '{note}\'s must either be contained within a cell or span entire rows.', startNote, {});
        msg.dependencies = startNote.dependencies;
        startNote.replace(msg);
        startNote = nextNote();
        return false;
      };

      if (startTable !== endTable) {
        valid = false;
        isItValid('{note}\'s must be contained within a same table.');
        continue;
      }

      let cell = startNote.findParent(n => n.tag === 'td');
      let row = cell.findParent(n => n.tag === 'tr');
      if (valid && row.children[0] !== cell) {
        valid = false;
      }
      if (valid && cell.leftmostLeaf() !== startNote) {
        valid = false;
      }
      if (!isItValid()) {
        continue;
      }

      cell = endNote.findParent(n => n.tag === 'td');
      row = cell.findParent(n => n.tag === 'tr');
      if (valid && row.children[row.children.length - 1] !== cell) {
        valid = false;
      }
      if (valid && cell.rightmostLeaf() !== endNote) {
        valid = false;
      }
      if (!isItValid()) {
        continue;
      }
        
    }
    

    if (startNote.info.shouldInsert) {
      startNote.parent.removeChild(startNote);
      endNote.parent.removeChild(endNote);
    } else {
      // Delete all the note content
      if (isTableRow) {
        startNote.findParent(n => n.tag === 'tr').removeTo(endNote.findParent(n => n.tag === 'tr'));
      } else {
        let sParent = startNote.blockParent(), eParent = endNote.blockParent();
        startNote.removeTo(endNote);
        if (sParent && eParent && sParent.canConsume(eParent)) {
          let sGrandParent = sParent.parent, eGrandParent = eParent.parent;
          sParent.consume(eParent);

          // Lists of the same type should be merged together
          if (sGrandParent !== eGrandParent) {
            if (sParent.tag === 'li' && eParent.tag === 'li' && sGrandParent && sGrandParent.parent && eGrandParent && eGrandParent.parent) {
              if (!sGrandParent.contains(eGrandParent) && !eGrandParent.contains(sGrandParent)) {
                if (sGrandParent.tag === 'ul' && eGrandParent.tag === 'ul') {
                  sGrandParent.consume(eGrandParent);
                } else if (sGrandParent.tag === 'ol' && eGrandParent.tag === 'ol') {
                  sParent.parent.consume(eGrandParent);
                }
              }
            }
          }
        }
      }
    }
    startNote = nextNote();
  }
}


/**
 * Merges sequential strings in the stream.
 * 
 * @param {object[]} stream
 * 
 * @return {object[]}
 */
export function compressStream(stream) {
  let out = [];
  for (let i = 0; i < stream.length; i++) {
    if (out.length && (typeof out[out.length - 1] === 'string') && (typeof stream[i] === 'string')) {
      out[out.length - 1] += stream[i];
    } else {
      out.push(stream[i]);
    }
  }
  return out;
}


/**
 * @param {string} msg
 * @param {ParseNode} node
 * @param {object} config
 * @param {boolean=} config.blocking
 * @param {string=} config.show
 * @param {boolean=} config.embedNode
 */
function err(msg, node, config) {
  let blocking = ('blocking' in config) ? config.blocking : false;
  let show = ('show' in config) ? config.show : 'default';
  let embedNode = ('embedNode' in config) ? config.embedNode : false;
  
  let info = { message: msg };
  if (embedNode) {
    info.node = new ParseNode(node.type, node.tag, Object.assign({}, node.info), node);
  }
  info.blocking = blocking;
  info.show = show;
  let res = new ParseNode('error', undefined, info, node);
  res.localData = node.localData;
  res.importPrefix += '_error';
  if (node.display.hidden) {
    res.display.hidden = true;
  }
  return res;
}


/**
 * Creates a data container for a set of nodes. Pulls out default values and
 * any necessary derived calcs.
 * 
 * @param {ParseNode[]} nodes
 * @param {Environment} env
 * 
 * @return {Promise<{container: DataContainer, quickItems: object[], addon: ParseNode}>}
 */
export async function createContainer(nodes, env) {
  /** @type {CollectorType} */
  let collector = {
    inputs: [],
    addon: null,
    derived: [],
    repeats: 0,
    repeatLocals: [],
    bsql: /** @type {import('./ParseNode').InfoType[]} */ ([]),
    addons: 0
  };
  if (
    nodes.length
    && nodes[0].tag === 'tr'
    && nodes[nodes.length - 1].tag === 'tr'
  ) {
    // Extract all children of a table row
    const newNodes = nodes.map(trNode => 
      trNode.children.map(tdNode => 
        tdNode.children
      ).flat()
    ).flat();
    
    // remove repeat wrapped across row
    if (
      newNodes[0].info.type === 'repeat_start'
      && newNodes[newNodes.length - 1].info.type === 'repeat_end'
    ) {
      nodes = newNodes.slice(1, newNodes.length - 1);
    }
  }
  
  for (let node of nodes) {
    await extractKeyItems(node, collector, env);
  }

  let defaults = Object.create(null);
  let quickItems = [];
  let quickItemsNames = [];
  
  for (let item of collector.inputs) {
    if (item.default !== undefined && !env.data.defined(item.name)) {
      // TODO: Maybe we want some way to show a warning if the item is already defined?
      if (defaults[item.name] === undefined) {
        defaults[item.name] = item.default;
      }
    } else {
      if (!(item.name in defaults) && !env.data.defined(item.name)) {
        defaults[item.name] = undefined;
      }
    }
    if (!quickItemsNames.includes(item.key)) {
      quickItems.push({
        label: item.label,
        node: item.node,
        key: item.key
      });
      quickItemsNames.push(item.key);
    }
  }

  // Populate initial repeat `locals` attribute
  for (let item of collector.repeatLocals) {
    if (!(item in defaults)) {
      defaults[item] = createListFromArray([]);
    }
  }

  for (let info of collector.bsql) {
    if (info.haserror) {
      defaults[info.haserror] = 'no';
    }
    if (info.isloading) {
      defaults[info.isloading] = 'no';
    }
    if (!info.name) {
      for (let item in info.default) {
        defaults[item] = info.default[item];
      }
    } else {
      defaults[info.name] = createListFromObject(info.default);
    }
  }

  let container = new DataContainer(defaults, [], env, env.data);
  await container.ready;

  // Add missing after creation so init values don't use it
  /** @type {import('./DataContainer').DataUpdateChangeType[]} */
  let missing = [];
  for (let d in defaults) {
    if (!container.defined(d)) {
      missing.push({
        name: d,
        value: collector.inputs.find(item => item.name === d).missing
      });
    }
  }
  await container.bulkUpdate(missing, env);

  return {
    container: container,
    quickItems,
    addon: collector.addon
  };
}


/**
 * Remove items from the dom and carry out processing.
 * @typedef {{ inputs: { label: string, key: string, default: string, missing: string, name: string, node: import('./ParseNode').default }[]; addon: import('./ParseNode').default; derived: any[]; repeats: number; repeatLocals: any[]; bsql: import('./ParseNode').InfoType[]; addons: number; }} CollectorType
 * 
 * @param {ParseNode} dom - node to which extract from
 * @param {CollectorType} collector
 * @param {Environment} env
 */
async function extractKeyItems(dom, collector, env) {
  const getDefault = async function (formInfo, env) {
    let def = formInfo.default;
    let type = formInfo.type;
    let multiple = formInfo.multiple;
    let format = formInfo.format;

    if (!def || typeof def === 'string') {
      // return default as is
    } else {
      try {
        // noData so it throws an error on missing data
        if (type === 'menu' && multiple) {
          def = createListFromArray(await Promise.all(def.map(d => d.value(env.derivedConfig({ noData: true })))));
        } else if (type === 'date') {
          if (def.snippetText() === '') {
            def = ''; // we allow a blank default value for dates
          } else {
            let val = await def.value(env.derivedConfig({ noData: true }));
            def = moment(val).format(def.config.format);
          }
        } else {
          def = await def.value(env.derivedConfig({ noData: true }));
        }
      } catch (err) {
        if (err.message.includes('cannot depend on')) {
          def = '[Error - ' + err.message + ']';
        } else if (type === 'date') {
          def = '[Error - ' + err.message + ']';
        } else {
          def = '[Error - Invalid default value for form command.]';
        }
      }
    }


    // Missing is used to fill in the default if there are no defaults across all inputs for a name
    //  - formtoggle should be `false`
    //  - formmenu with `multiple` should be an empty list
    //  - date should be the current date
    /** @type {any} */
    let missing = '';
    if (type === 'menu' && multiple) {
      missing = createListFromArray([]);
    } else if (type === 'toggle_start') {
      missing = false;
    } else if (!def && type === 'date') {
      missing = moment(env.config.useRealtimeDates ? new Date() : env.config.date).format(format);
    }

    return {
      default: def,
      missing
    };
  };
  

  if (!collector.repeats && !collector.addons && dom.type === 'expand' && dom.tag === 'form' && !(['repeat_start', 'repeat_end', 'toggle_end', 'if_start', 'if_elseif', 'if_else', 'if_end'].includes(dom.info.type))) {
    let fills = await getDefault(dom.info.formInfo, env);
    collector.inputs.push({
      label: dom.info.formInfo.label,
      key: dom.info.formInfo.key,
      name: dom.info.formInfo.name,
      default: fills.default,
      missing: fills.missing,
      node: dom
    });
  } else if (dom.type === 'expand' && dom.tag === 'form' && ['repeat_start', 'repeat_end'].includes(dom.info.type)) {
    if ('repeat_start' === dom.info.type) {
      if (
        !collector.repeats
        && dom.info.attributes.keys.locals
        && !dom.info.attributes.keys.locals.fillIn
      ) {
        collector.repeatLocals.push(await dom.info.attributes.keys.locals.value(env));
      }
      collector.repeats++;
    } else {
      collector.repeats--;
    }
  } else if (dom.type === 'expand' && dom.tag === 'addon') {
    if (dom.info.end) {
      collector.addons++;
    } else if (!dom.info.end) {
      collector.addons--;
    }
  } else if (!collector.repeats && !collector.addons && dom.type === 'expand' && dom.tag === 'remote' && dom.info.command === 'dbselect') {
    try {
      collector.bsql.push(await dom.info.process(dom.info.attributes, env, true));
    } catch (_) {}
  }
  
  if (dom.children) {
    for (let i = 0; i < dom.children.length; i++) {
      await extractKeyItems(dom.children[i], collector, env);
    }
  }
}

/**
 * 
 * @param {ParseNode} dom 
 */
function getStructureEls(dom) {
  return dom.findAll((node) => ['link', 'addon'].includes(node.tag) || (node.tag === 'form' && ['repeat_start', 'repeat_end', 'if_start', 'if_elseif', 'if_else', 'if_end', 'toggle_start', 'toggle_end'].includes(node.info.type)));
}

/**
 * @param {ParseNode} dom
 * @param {Environment} env
 * @param {object} derived
 */
export async function doStructuring(dom, env, derived) {
  /** @param {ParseNode} n */
  let displayObject = async (n) => {
    try {
      return {
        trim: (n.info.attributes.keys && n.info.attributes.keys.trim) ? await getTrim(await n.info.attributes.keys.trim, n.display, env) : false
      };
    } catch (_) {
      // Error message display is handled later on the start and end nodes themselves
      return {
        trim: false
      };
    }
  };
  
  let structureEls = getStructureEls(dom);
  let needNewStructuredEls = false;
  
  structureEls.forEach((n) => { 
    n.info.processed = false;
  });

  /**
   * 
   * @param {ParseNode[]} conditionals 
   */
  function refreshConditionals(conditionals) {
    let conditionalStack = [];
    for (let conditional of conditionals) {
      // Resetting the else-if array because the same else-if nodes will be added repeatedly to the start-if node when the next node function is called.
      conditional.info.elseif = null;
      if (conditional.info.type === 'if_start') {
        conditionalStack.push(conditional);
      } else if (conditional.info.type === 'if_else') {
        if (conditionalStack[conditionalStack.length - 1]) {
          conditionalStack[conditionalStack.length - 1].info.else = conditional;
          conditional.info.start = conditionalStack[conditionalStack.length - 1];
        } else {
          conditional.info.processed = true;
        }
      } else if (conditional.info.type === 'if_elseif') {
        let start = conditionalStack[conditionalStack.length - 1];
        start.info.elseif = (start.info.elseif || []).concat(conditional);
      } else {
        let startConditional = conditionalStack.pop();
        if (startConditional) {
          startConditional.info.end = conditional;
          conditional.info.start = startConditional;
        } else {
          conditional.info.processed = true;
        }
      }
    }
  
    while (conditionalStack.length) {
      let oldStartIf = conditionalStack.pop();
      oldStartIf.info.processed = true;
      if (oldStartIf.info.else) {
        oldStartIf.info.else.info.processed = true;
      }
      oldStartIf.info.else = null;
      oldStartIf.info.end = null;
    }
  }

  /**
   * 
   * @param {ParseNode[]} toggles 
   */
  function refreshToggles(toggles) {
    let toggleStack = [];

    for (let toggle of toggles) {
      if (toggle.info.type === 'toggle_start') {
        toggleStack.push(toggle);
      } else {
        let startToggleItem = toggleStack.pop();
        if (startToggleItem) {
          startToggleItem.info.end = toggle;
          toggle.info.start = startToggleItem;
        } else {
          toggle.info.processed = true;
          toggle.info.start = null;
        }
      }
    }

    while (toggleStack.length) {
      let oldStartToggle = toggleStack.pop();
      oldStartToggle.info.processed = true;
      oldStartToggle.info.end = null;
    }
  }

  /**
   * 
   * @param {ParseNode[]} addons 
   */
  function refreshAddons(addons) {
    let addonStack = [];
    for (let addon of addons) {
      if (addon.info.end) {
        addonStack.push(addon);
      } else {
        let startAddon = addonStack.pop();
        if (startAddon) {
          startAddon.info.end = addon;
          addon.info.start = startAddon;
        } else {
          addon.info.processed = true;
        }
      }
    }
  
    while (addonStack.length) {
      let oldStartAddon = addonStack.pop();
      oldStartAddon.info.processed = true;
      oldStartAddon.info.end = null;
    }
  }

  /**
   * 
   * @param {ParseNode[]} repeats 
   */
  function refreshRepeats(repeats) {
    let repeatStack = [];
    for (let repeat of repeats) {
      if (repeat.info.type === 'repeat_start') {
        repeatStack.push(repeat);
      } else {
        let startRepeat = repeatStack.pop();
        if (startRepeat) {
          startRepeat.info.end = repeat;
          repeat.info.start = startRepeat;
        } else {
          repeat.info.processed = true;
        }
      }
    }

    while (repeatStack.length) {
      let oldStartRepeat = repeatStack.pop();
      oldStartRepeat.info.processed = true;
      oldStartRepeat.info.end = null;
    }
  }

  function refreshStructureEls() {
    const addons = [];
    const repeats = [];
    const conditionals = [];
    const toggles = [];

    for (const node of structureEls) {
      if (!node.info.processed) {
        if (node.tag === 'addon') {
          addons.push(node);
        } else if (['repeat_start', 'repeat_end'].includes(node.info.type)) {
          repeats.push(node);
        } else if (['if_start', 'if_elseif', 'if_else', 'if_end'].includes(node.info.type)) {
          conditionals.push(node);
        } else if (['toggle_start', 'toggle_end'].includes(node.info.type)) {
          toggles.push(node);
        }
      }
    }
    refreshAddons(addons);
    refreshRepeats(repeats);
    refreshConditionals(conditionals);
    refreshToggles(toggles);
  }
  refreshStructureEls();

  /**
   * Get the repeat starts and addons from the outer to the inners.
   * 
   * @return {ParseNode}
   */
  function nextStructureEl() {
    if (needNewStructuredEls) {
      // note, these may be added so we have to rerun the
      // full findAll() each time
      structureEls = getStructureEls(dom);
      refreshStructureEls();
      needNewStructuredEls = false;
    }

    let unprocessedEls = structureEls.filter(node => ((node.tag === 'form' && ['repeat_start', 'if_start', 'toggle_start'].includes(node.info.type)) || (node.tag === 'addon' && node.info.end)) && !node.info.processed);

    if (unprocessedEls.length) {
      // We have already processed them in refresh addons, refresh repeats, refresh conditionals and refresh toggles function
      return unprocessedEls[0];
    }
    return null;
  }
  

  let startNode = nextStructureEl();
  while (startNode) {
    if (startNode.tag === 'addon' || startNode.info.type === 'repeat_start') {
      // Structure {repeats} and {addons}'s
      needNewStructuredEls = true;
      
      // Shared between repeats and addons
      startNode.info.processed = true;
      
      /** @type {ParseNode} */
      let endNode = startNode.info.end;
      if (!endNode || !startNode.findParent(x => x.type === 'root')) {
        // could be due to an attribute error or another reason
        // {addon} or {repeat} is not processed so no need to refresh structure elements
        needNewStructuredEls = false;
        startNode = nextStructureEl();
        continue;
      }
      endNode.info.processed = true;
      
      let myRootStoreId = startNode.findLocalData();
      let myRootData = env.config.store[myRootStoreId];
        
      let myRoot = startNode.sharedParent(endNode);
      
      
      if (startNode.tag === 'addon') {
        let ancestors = [];
        // We use nextLeaf() / previousLeaf() to not change the localData for the addon
        // bookend nodes as those may embed data state from a {repeat}
        startNode.nextLeaf().encapsulateTo(endNode.previousLeaf(), (el) => ancestors.push(el));
      
        let storeId = startNode.info.storeId;
      
        let attrs = startNode.info.attributes;
      
        let position = attrs.position;
        let named = attrs.keys;
      
        // The environment for the addon itself
        let parentLocs = env.locations;
      
        let storeLocation = 'local_data - ' + myRootStoreId;
        if (!parentLocs.includes(storeLocation)) {
          parentLocs = parentLocs.concat(storeLocation);
        }
      
        /** @type {Environment} */
        let parentWrapperEnv;
      
        /**
             * @param {string[]} items 
             * @returns {string}
             */
        let findLastLocalData = (items) => {
          for (let i = items.length - 1; i >= 0; i--) {
            if (items[i].startsWith('local_data - ')) {
              return items[i];
            }
          }
        };
            
        // If the environment is for the same localData and ends in
        // something other than the local data, we want to use it.
        // It means it's something like a lambda scope in an attribute
        // or something like that.
        //
        // E.g. {formtext: formatter=(v) -> {pack-cmd: {=v}}}
        //
        // Otherwise we want to tranverse up the tree and use that
        // localData (e.g. we might in a {repeat}).
        //
        // It would be nice if the environment always had the correct
        // local data, so we could just that...
        //
        // TODO: explore if this is possible.
      
        if (findLastLocalData(parentLocs) === findLastLocalData(env.locations)) {
          if (!env.locations[env.locations.length - 1].startsWith('local_data -')) {
            // environment is for same local data, but there is also
            // some additional path on it (e.g. lambda call)
            parentWrapperEnv = env;
          }
        }
        if (!parentWrapperEnv) {
          parentWrapperEnv = new Environment(myRootData, env.config, parentLocs);
          await parentWrapperEnv.data.ready;
        }
        if (env.config.rootAddonParentIsNoData) {
          // If we're the top level addon on our parent has `rootAddonParentIsNoData`
          // We should set our wrapper environment ot be `noData`
          if (startNode.isFirstNode()) {
            parentWrapperEnv = parentWrapperEnv.derivedConfig({
              rootAddonParentIsNoData: false,
              noData: true
            });
          }
        }
        let addonWrapperEnv = new Environment(env.config.store[storeId], env.config, parentLocs.concat('local_data - ' + storeId));
        await addonWrapperEnv.data.ready;
      
        if (!env.config.store[storeId]) {
          let keyItems = await createContainer(ancestors, addonWrapperEnv);
          env.config.store[storeId] = keyItems.container;
          env.config.store[storeId].owner = env.ownerId();
          env.config.store[storeId].locations = addonWrapperEnv.locations;
          startNode.addDependencies(new Set(keyItems.quickItems.map(x => x.key)));
        }
        let myData = env.config.store[storeId];
      
        ancestors.forEach(x => x.localData = storeId);
      
        let identifiers = [];
        for (let key in named) {
          if (named[key].type === 'identifier' && !named[key].fillIn) {
            identifiers.push(named[key]);
          }
        }
          
        if (!derived[storeId]) {
          derived[storeId] = [];
        }
        let error = false;
        for (let identifier of identifiers) {
          let targetName;
          try {
            targetName = await identifier.value(env);
          } catch (e) {
            startNode.removeTo(endNode, false, true);
            let msg = err(e.message || 'Invalid addon identifier', startNode, {});
            msg.dependencies = startNode.dependencies;
            startNode.replace(msg);
            error = true;
            break;
          }
          derived[storeId].push(new DerivedChange(
            [identifier.name],
            async (_env, _changed, recursion) => {
              await myRootData.update(targetName, myData.get(identifier.name), parentWrapperEnv, recursion);
            }
          ));
        }
        if (error) {
          startNode = nextStructureEl();
          continue;
        }
      
        try {
          let attrs = position.filter(attr => attr.type !== 'identifier');
          /** @type {import('./DataContainer').DataUpdateChangeType[]} */
          let items = [];
          for (let attr of attrs) {
            // trim shouldn't be passed to the component
            if (attr.name !== 'trim') {
              let value = await attr.value(parentWrapperEnv);
      
              if (attr.list === 'positional') {
                value = createListFromArray(value);
              } else if (attr.list === 'keys') {
                value = createListFromObject(value);
              }
              items.push({
                name: attr.name,
                value
              });
            }
          }
      
          // Pull in settings page data
          let addonConfigData = startNode.info.addonConfigData;
          for (let n in addonConfigData) {
            items.push({
              name: n,
              value: addonConfigData[n]
            });
          }
      
          if (items.length) {
            await myData.bulkUpdate(items, addonWrapperEnv, []);
          }
        } catch (e) {
          startNode.removeTo(endNode, false, true);
          let msg = err(e.message || 'Invalid command', startNode, {});
          msg.dependencies = startNode.dependencies;
          startNode.replace(msg);
          startNode = nextStructureEl();
          continue;
        }
      
        if (['none', 'chicklet'].includes(startNode.info.visibility[env.config.stage])) {
          endNode.clearSurroundingEmptyText();
      
          // We need to merge blocks otherwise the paragraphs can still show up
          // as line breaks in the preview even when display is 'none'
          let endNodeParent = endNode.blockParent();
          let startNodeParent = startNode.blockParent();
          if (endNodeParent && startNodeParent) {
            while (endNodeParent !== startNodeParent) {
              let nextParent = startNodeParent.parent.children[startNodeParent.parent.children.indexOf(startNodeParent) + 1];
              if (!nextParent) {
                break;
              }
              startNodeParent.consume(nextParent);
              endNodeParent = endNode.blockParent();
            }
          }
      
          // hide the contents in the stage
          startNode.nextLeaf().encapsulateTo(endNode, (n) => {
            n.each(x => {
              if (x.info && typeof x.info.message === 'string') {
                x.info.message = '';
              }
              x.display.hidden = true;
            });
          });
        }
      
        if (startNode.info.visibility[env.config.stage] === 'contents') {
          /** @type {any} */
          let trim = startNode.info.attributes && startNode.info.attributes.keys && startNode.info.attributes.keys.trim;
          let leftTrimmer = new ParseNode('expand', 'text', '', startNode);
          leftTrimmer.importPrefix += '_left';
          let rightTrimmer = new ParseNode('expand', 'text', '', startNode);
          rightTrimmer.importPrefix += '_right';
          if (trim) {
            leftTrimmer.display.trimDirection = 'left';
            leftTrimmer.info.attributes = {
              keys: {
                trim: startNode.info.attributes.keys.trim
              },
              position: [],
              spec: {}
            };
            leftTrimmer.localData = startNode.localData;
      
            rightTrimmer.display.trimDirection = 'right';
            rightTrimmer.info.attributes = {
              keys: {
                trim: startNode.info.attributes.keys.trim
              },
              position: [],
              spec: {}
            };
            rightTrimmer.localData = startNode.localData;
          } else {
            leftTrimmer.display.trim = false;
            rightTrimmer.display.trim = false;
          }
      
      
          startNode.parent.insertChild(startNode.parent.children.indexOf(startNode), leftTrimmer);
          startNode.info.end.parent.insertChild(startNode.info.end.parent.children.indexOf(startNode.info.end) + 1, rightTrimmer);
        }
            
      } else {
        let wrapperEnv = new Environment(myRootData, Object.assign({}, env.config, { mode: 'attribute' }), env.locations.concat('repeat_scope - ' + myRootStoreId));
        await wrapperEnv.data.ready;
        let iterator;
        let count;
        let shape;
        try {
          let ast = await startNode.info.attributes.position[0].ast(wrapperEnv);
          if (ast.type === 'list_comprehension') {
            let tree = ast.info.for;
            let processed = await processForStatement(tree, wrapperEnv.derivedLocation('iterator'));
            shape = processed.sourceShape;
            iterator = { items: processed.values, args: tree.info.args };
            count = iterator.items.length;
          } else {
            count = toNum(await eEqn(ast, wrapperEnv.derivedLocation('iterator')));
            if (count < 0) {
              throw new Error('Repeat count must be non-negative');
            }
            if (count !== Math.round(count)) {
              throw new Error('Repeat count must an integer');
            }
            shape = {
              type: 'position',
              indexes: (new Array(count)).fill(0).map((_, i) => i + 1)
            };
          }
        } catch (e) {
          startNode.removeTo(endNode, false, true);
          let msg = err(e.message || 'Invalid Formula', startNode, {});
          msg.dependencies = startNode.dependencies;
          startNode.replace(msg);
          startNode = nextStructureEl();
          continue;
        }
      
        /** @type {function(ParseNode): ParseNode} */ 
        let ancFn = (node) => node.parent === myRoot ? node : node.findParent((n) => n.parent === myRoot);
        let startAncestor = ancFn(startNode);
        let endAncestor = ancFn(endNode);
      
        let isTableRow = false;
        let startTable = startNode.findParent(x => x.tag === 'table');
        let endTable = endNode.findParent(x => x.tag === 'table');
      
        if ((startTable || endTable) && (startNode.findParent(x => x.tag === 'td') !== endNode.findParent(x => x.tag === 'td'))) {
          isTableRow = true;
          // we're spanning table cells and need to ensure we start and end a row
          let valid = true;
      
          // eslint-disable-next-line no-loop-func
          function isItValid (error) {
            if (valid) {
              return true;
            }
            
            let msg = err(error || '{repeat}\'s must either be contained within a cell or span entire rows.', startNode, {});
            msg.dependencies = startNode.dependencies;
            startNode.replace(msg);
            // {repeat} is not processed so no need to refresh the structure elements
            needNewStructuredEls = false;
            startNode = nextStructureEl();
            return false;
          };
      
          if (startTable !== endTable) {
            valid = false;
            isItValid('{repeat}\'s cannot span table cells.');
            continue;
          }
      
          let cell = startNode.findParent(n => n.tag === 'td');
          let row = cell.findParent(n => n.tag === 'tr');
          if (valid && row.children[0] !== cell) {
            valid = false;
          }
          if (valid && cell.leftmostLeaf() !== startNode) {
            valid = false;
          }
          if (!isItValid()) {
            continue;
          }
      
          cell = endNode.findParent(n => n.tag === 'td');
          row = cell.findParent(n => n.tag === 'tr');
          if (valid && row.children[row.children.length - 1] !== cell) {
            valid = false;
          }
          if (valid && cell.rightmostLeaf() !== endNode) {
            valid = false;
          }
          if (!isItValid()) {
            continue;
          }
              
        }
      
        let allNewParts = [];
        let ancestors = myRoot.children.slice(myRoot.children.indexOf(startAncestor), myRoot.children.indexOf(endAncestor) + 1);
      
        let leader, follower, domIndex;
        if (isTableRow) {
          leader = startNode.findParent(x => x.tag === 'tr');
          follower = endNode.findParent(x => x.tag === 'tr');
          if (leader === follower) {
            ancestors = [leader];
            myRoot = myRoot.parent;
          }
      
          domIndex = myRoot.children.indexOf(leader) - 1;
      
          for (let i = 0; i < ancestors.length; i++) {
            ancestors[i].parent.removeChild(ancestors[i]);
          }
        } else {
          let savedStartParent = startAncestor.parent;
      
          startAncestor.parent = null;
          let startParts = startNode.split('left');
          startAncestor.parent = savedStartParent;
          startParts[0].parent = savedStartParent;
          startParts[1].parent = savedStartParent;
      
          leader = startParts[0];
          startAncestor.parent.replaceChild(startAncestor, leader);
          ancestors[0] = startParts[1];
      
          if (ancestors.length === 1) {
            endNode = ancestors[0].find(node => node.tag === 'form' && node.info.type === 'repeat_end');
            endNode.info.processed = true;
            endAncestor = ancFn(endNode);
          }
      
          let savedEndParent = endAncestor.parent;
          endAncestor.parent = null;
      
          let endParts = endNode.split('right');
          endAncestor.parent = savedEndParent;
          endParts[0].parent = savedEndParent;
          endParts[1].parent = savedEndParent;
      
          follower = endParts[1];
          if (ancestors.length === 1) {
            leader.parent.insertChild(leader.parent.children.indexOf(leader) + 1, follower);
            ancestors[0] = endParts[0];
          } else {
            endAncestor.parent.replaceChild(endAncestor, follower);
            ancestors[ancestors.length - 1] = endParts[0];
          }
      
          for (let i = 1; i < ancestors.length - 1; i++) {
            ancestors[i].parent.removeChild(ancestors[i]);
          }
      
      
          domIndex = myRoot.children.indexOf(leader);
        }
      
        let startDisplay = await displayObject(startNode);
        let endDisplay = await displayObject(endNode);
      
        let trim = 'no';
        if (['yes', true, 'right'].includes(startDisplay.trim) && ['yes', true, 'left'].includes(endDisplay.trim)) {
          trim = 'yes';
        } else if (['yes', true, 'right'].includes(startDisplay.trim)) {
          trim = 'right';
        } else if (['yes', true, 'left'].includes(endDisplay.trim)) {
          trim = 'left';
        }
      
        let origLeader = leader;
      
        let getLocalDataContainer = getLocalEnvironmentGenerator(iterator ? iterator.args : [], wrapperEnv, true);
        let getLocals = !!(startNode.info.attributes.keys.locals && !startNode.info.attributes.keys.locals.fillIn);
        let repeatLocals = [];
        for (let i = 0; i < count; i++) {
          let storeId = myRootStoreId + '-repeat_' + startNode.position() + '_' + i;
      
          let newParts = ancestors.map(node => {
            let n = node.clone(true, true, (origId) => origId + '__' + storeId);
            n.localData = storeId;
            return n;
          });
      
          allNewParts = allNewParts.concat(newParts);
      
          if (!env.config.store[storeId]) {
            let keyItems = await createContainer(newParts, wrapperEnv);
            env.config.store[storeId] = keyItems.container;
      
            env.config.store[storeId].owner = env.ownerId();
            env.config.store[storeId].locations = env.derivedLocation('local_data - ' + storeId).locations;
            startNode.addDependencies(new Set(keyItems.quickItems.map(x => x.key)));
          }
      
          // Note we don't use bulkUpdate here as we don't want values to propagate up
          // if they are defined in a higher scope.
          if (iterator) {
            let item = iterator.items[i];
            let locals = (await getLocalDataContainer(item[0], item[1])).getData();
            let madeChange = false;
            let current = env.config.store[storeId].getData();
            for (let name in locals) {
              if (current[name] !== locals[name]) {
                madeChange = true;
                break;
              }
            }
            if (madeChange) {
              env.config.store[storeId].data = Object.assign(Object.create(null), env.config.store[storeId].getData(), locals);
              await env.config.store[storeId].updateDerived(Object.keys(locals), env);
            }
          }
      
          if (getLocals) {
            let obj = Object.assign(Object.create(null), env.config.store[storeId].getData());
            repeatLocals.push(obj);
          }
      
          if (leader.block() && leader.canConsume(newParts[0])) {
            leader.consume(newParts[0]);
          } else {
            myRoot.insertChild(domIndex + 1, newParts[0]);
            domIndex++;
          }
          for (let i = 1; i < newParts.length; i++) {
            myRoot.insertChild(domIndex + 1, newParts[i]);
            domIndex++;
          }
          leader = myRoot.children[domIndex];
          if (trim !== 'no' && i < count - 1) {
            let trimEl = new ParseNode('expand', 'text', '', leader);
            trimEl.importPrefix += '_trimmer';
            trimEl.display.trim = trim;
            if (leader.block() && leader.canConsume(follower)) {
              leader.addChild(trimEl);
            } else {
              myRoot.insertChild(domIndex + 1, trimEl);
              domIndex++;
              leader = myRoot.children[domIndex];
            }
          }
        }
      
        if (getLocals) {
          let locals;
          let items = repeatLocals.map(v => createListFromObject(v));
          if (shape.type === 'position') {
            locals = createListFromArray(items);
          } else {
            let v = Object.create(null);
            shape.indexes.forEach((k, i) => {
              v[k] = items[i];
            });
            locals = createListFromObject(v);
          }
          await env.config.store[myRootStoreId].update((await startNode.info.attributes.keys.locals.value(env)), locals, env);
        }
        
            
        if (leader.block()) {
          leader.consume(follower);
          follower = leader;
        }
      
        if (isTableRow) {
          if (allNewParts.length) {
            // eslint-disable-next-line
            allNewParts[0].encapsulateTo(allNewParts[allNewParts.length - 1], x => x.addDependencies(startNode.dependencies));
          }
        } else {
          startNode = origLeader.findRight(x => x.tag === 'form' && x.info.type === 'repeat_start');
          startNode.info.end = follower.find(x => x.tag === 'form' && x.info.type === 'repeat_end');
              
          // eslint-disable-next-line
          startNode.encapsulateTo(startNode.info.end, x => x.addDependencies(startNode.dependencies));
        }
      }
    } else if (startNode.info.type === 'if_start') {
      /** Structure {if}'s
       * 
       * {if}s are evaluated from left to right
       * with outer {if} being evaluated before nested inners.
       * 
       * This guarantees inner won't conflict with outer's logic 
       * and grants slight performance improvement by not performing operations on
       * anyways-to-be-removed nodes.
       */
      startNode.info.processed = true;
  
      /** @type {ParseNode} */
      let endNode = startNode.info.end;
      if (!endNode || !startNode.findParent(x => x.type === 'root')) {
        // could be due to an attribute error or another reason
        startNode = nextStructureEl();
        continue;
      }
      endNode.info.processed = true;
    
      let branches = [startNode];
      if (startNode.info.elseif) {
        branches = branches.concat(startNode.info.elseif);
      }
      if (startNode.info.else) {
        branches.push(startNode.info.else);
      }
      branches.forEach(b => b.info.processed = true);
    
      let myRootData = startNode.findLocalData();
      let wrapperEnv = new Environment(env.config.store[myRootData], Object.assign({}, env.config, { mode: 'attribute' }), env.locations.concat('conditional - ' + myRootData));
      await wrapperEnv.data.ready;
    
      let commands = branches.concat(endNode);
      let tables = commands.map(x => x.findParent(p => p.tag === 'table'));
      let cells = commands.map(x => x.findParent(p => p.tag === 'td'));
    
      let isTableRow = false;
      if (tables.find(t => !!t) && cells.slice(1).some(cell => cell !== cells[0])) {
        isTableRow = true;
        // we're spanning table cells and need to ensure we start and end a row
        let valid = true;
        let table = tables[0];
    
        // eslint-disable-next-line no-loop-func
        function isItValid (error) {
          if (valid) {
            return true;
          }
            
          let msg = err(error || '{if}\'s must either be contained within a cell or span entire rows.', startNode, {});
          msg.dependencies = startNode.dependencies;
          startNode.replace(msg);
          startNode = nextStructureEl();
          return false;
        };
    
        for (let t of tables) {
          if (table !== t) {
            valid = false;
            continue;
          }
        }
        if (!isItValid('{if}\'s must be contained within a same table.')) {
          continue;
        }
    
        // {else} and {elseif} should appear at start of the row
        for (let i = 1; i < commands.length - 1; i++) {
          let node = commands[i];
          let cell = node.findParent(n => n.tag === 'td');
          let row = cell.findParent(n => n.tag === 'tr');
          let isFirstCell = row.children[0] === cell;
    
          if (!isFirstCell) {
            valid = false;
          }
          if (isFirstCell && cell.leftmostLeaf() !== node) {
            valid = false;
          }
        }
    
        if (!isItValid()) {
          continue;
        }
    
        // {if} appears at the start of row
        let cell = startNode.findParent(n => n.tag === 'td');
        let row = cell.findParent(n => n.tag === 'tr');
        if (row.children[0] !== cell) {
          valid = false;
        }
        if (cell.leftmostLeaf() !== startNode) {
          valid = false;
        }
        
    
        if (!isItValid()) {
          continue;
        }
    
        // {endif} appears at the end of the row
        cell = endNode.findParent(n => n.tag === 'td');
        row = cell.findParent(n => n.tag === 'tr');
        if (row.children[row.children.length - 1] !== cell) {
          valid = false;
        }
        if (cell.rightmostLeaf() !== endNode) {
          valid = false;
        }
        if (!isItValid()) {
          continue;
        }
      }
      /**
         * @param {ParseNode} startNode 
         * @param {ParseNode} endNode 
         * @param {boolean} removeStart 
         * @param {boolean} removeEnd 
         */
      function removeStartToEnd(startNode, endNode, removeStart, removeEnd) {
        const start = getStartForRemoval(startNode);
        if (!start) {
          return;
        }
        const end = getEndForRemoval(endNode);
        if (!end) {
          return;
        }
        start.removeTo(end, removeStart, removeEnd);
      }
        
      /**
         * @param {ParseNode} node 
         * @returns 
         */
      function getStartForRemoval(node) {
        if (!isTableRow) {
          return node;
        }
    
        let cell = node.findParent(n => n.tag === 'td');
        let row = cell.findParent(n => n.tag === 'tr');
        let isFirstCell = row.children[0] === cell;
        if (isFirstCell) {
          return row;
        } else {
          return row.next();
        }
      }
    
      /**
         * @param {ParseNode} node 
         * @returns 
         */
      function getEndForRemoval(node) {
        if (!isTableRow) {
          return node;
        }
    
        let cell = node.findParent(n => n.tag === 'td');
    
        let row = cell.findParent(n => n.tag === 'tr');
        let isFirstCell = row.children[0] === cell;
        if (isFirstCell) {
          return row.previous();
        } else {
          return row;
        }
      }
    
      // eslint-disable-next-line
      startNode.encapsulateTo(endNode, x => x.addDependencies(startNode.dependencies));
      for (let i = 0; i < branches.length; i++) {
        let node = branches[i];
        if (node.info.type === 'if_else') {
          if (branches.length - 1 > i) {
            // can't have an else block before an elseif or another else
            if (!isTableRow) {
              removeStartToEnd(node, endNode, false, true);
            }
            let msg = err('There must be only one {else} and it must be after any {elseif} commands.', node, {});
            msg.dependencies = node.dependencies;
            node.replace(msg);
            break;
          } else {
            // keep the contents, we don't need to do anything else
            continue;
          }
        } else {
          let item;
          try {
            item = await eEqn(await node.info.attributes.position[0].ast(wrapperEnv), wrapperEnv.derivedLocation('condition'));
          } catch (e) {
            if (!isTableRow) {
              removeStartToEnd(node, endNode, false, true);
            }
            let msg = err(e.message || 'Invalid Formula', node, {});
            msg.dependencies = node.dependencies;
            node.replace(msg);
            break;
          }
    
          try {
            item = toBool(item);
          } catch (e) {
            if (!isTableRow) {
              removeStartToEnd(node, endNode, false, true);
            }
            let msg = err(e.message || '"if" command formula must evaluate to yes/no.', node, {});
            msg.dependencies = node.dependencies;
            node.replace(msg);
            break;
          }
    
    
          if (item) {
            if (branches[i + 1]) {
              if (isTableRow) {
                removeStartToEnd(branches[i + 1], endNode, true, true);
              } else {
                removeStartToEnd(branches[i + 1], endNode, false, false);
                replaceNodeWithTrimPlaceholder(branches[i + 1]);
              }
              break;
            }
          } else {
            if (branches[i + 1]) {
              if (isTableRow) {
                removeStartToEnd(node, branches[i + 1], true, true);
              } else {
                node.removeTo(branches[i + 1], false, false);
                replaceNodeWithTrimPlaceholder(node);
              }
            } else {
              node.parent.addDependencies(node.dependencies);
              if (isTableRow) {
                removeStartToEnd(node, endNode, true, true);
              } else {
                node.removeTo(endNode, false, false);
                replaceNodeWithTrimPlaceholder(node);
                replaceNodeWithTrimPlaceholder(endNode);
              }
            }
          }
    
        }
      }
    } else if (startNode.info.type === 'toggle_start') {
      /**
       * Structure {formtoggle}'s
       * 
       * {formtoggles} are evaluated from left to right
       * with outer formtoggles being evaluated before nested inners.
       * 
       * This guarantees inner toggles won't conflict with outer toggle's logic 
       * and grants slight performance improvement by not performing operations on
       * anyways-to-be-removed nodes.
       */
      startNode.info.processed = true;
  
      /** @type {ParseNode} */
      let endNode = startNode.info.end;
      if (!endNode || !startNode.findParent(x => x.type === 'root')) {
        // could be due to an attribute error or another reason
        startNode = nextStructureEl();
        continue;
      }
      endNode.info.processed = true;
    
    
      let startTable = startNode.findParent(x => x.tag === 'table');
      let endTable = endNode.findParent(x => x.tag === 'table');
    
      if ((startTable || endTable) && (startNode.findParent(x => x.tag === 'td') !== endNode.findParent(x => x.tag === 'td'))) {
        let msg = err('{formtoggle}\'s cannot span table cells.', startNode, {});
        msg.dependencies = startNode.dependencies;
        startNode.replace(msg);
        startNode = nextStructureEl();
        continue;
      }
    
      let myRootData = startNode.findLocalData();
      let wrapperEnv = new Environment(env.config.store[myRootData], Object.assign({}, env.config, { mode: 'attribute' }), env.locations.concat('toggle_scope - ' + myRootData));
      await wrapperEnv.data.ready;
        
      let active = wrapperEnv.data.get(startNode.info.formInfo.name);
      try {
        active = toBool(active);
      } catch (_) {
        active = !!active; // fallback to js truthiness
      }
        
      // eslint-disable-next-line
      startNode.encapsulateTo(endNode, x => x.addDependencies(startNode.dependencies))
      if (!active) {
        startNode.removeTo(endNode, false, false);
        replaceNodeWithTrimPlaceholder(endNode);
        startNode.parent.addDependencies(startNode.dependencies);
      }
    }
  
    startNode = nextStructureEl();
  }
  
  // Now we need to find any 'identifier' attributes on components and create the defaults in the parent container
  let addons = dom.findAll(node => node.tag === 'addon' && !!node.info.end);
  for (let addon of addons) {
    let attrs = addon.info.attributes.position.filter(a => a.type === 'identifier' && !a.fillIn);
    for (let attr of attrs) {
      try {
        let outerName = (await attr.value(env)).toLowerCase();
        let def = (attr.config && attr.config.initial !== undefined) ? attr.config.initial : '';
        if (env.config.store[addon.findLocalData()].data[outerName] === undefined) {
          env.config.store[addon.findLocalData()].data[outerName] = def;
        }

        let innerName = attr.name.toLowerCase();
        if (env.config.store[addon.info.storeId].data[innerName] === undefined) {
          env.config.store[addon.info.storeId].data[innerName] = def;
        }
      } catch (_) {
        // ignore invalid identifier errors (e.g. 'name=foo bar')
        // as in that case we just won't assign
      }
    }
  }

  // Structure {notes}

  let allNotes = dom.findAll(node => node.tag === 'note' && ['note_start', 'note_end'].includes(node.info.type) && !node.info.processed);
  function nextNote() {
    // root check as node may be removed in prior iteration
    let notes = allNotes.filter(node => !node.info.processed && !!node.findParent(x => x.type === 'root'));
    let noteStack = [];
    let startNote;
    for (let note of notes) {
      if (note.info.type === 'note_start') {
        noteStack.push(note);
      } else {
        startNote = noteStack.pop();
        if (startNote) {
          startNote.info.end = note;
          note.info.start = startNote;
        } else {
          note.info.processed = true;
        }
      }
    }

    while (noteStack.length) {
      let oldStartNote = noteStack.pop();
      oldStartNote.info.processed = true;
      oldStartNote.info.end = null;
    }

    return startNote;
  }

  let startNote = nextNote();
  while (startNote) {
    startNote.info.processed = true;

    /** @type {ParseNode} */
    let endNote = startNote.info.end;
    if (!endNote || !startNote.findParent(x => x.type === 'root')) {
      // could be due to an attribute error or another reason
      startNote = nextNote();
      continue;
    }
    endNote.info.processed = true;

    // We need to preserve the contents of the notes or equation assignments.
    if (env.config.stage === 'preview') {
      let shouldShow = !startNote.info.attributes.keys.preview || (await startNote.info.attributes.keys.preview.value(env));
      if (!shouldShow) {
        // We need to merge blocks otherwise the paragraphs can still show up
        // as line breaks in the preview even when display is 'none'
        let endNodeParent = endNote.blockParent();
        let startNodeParent = startNote.blockParent();
        if (endNodeParent && startNodeParent) {
          while (endNodeParent !== startNodeParent) {
            let nextParent = startNodeParent.parent.children[startNodeParent.parent.children.indexOf(startNodeParent) + 1];
            if (!nextParent) {
              break;
            }
            /**
             * Collapses any nested lists.
             * 
             * @param {ParseNode} list 
             * @param {boolean=} remove 
             */
            function consumeList(list, remove = false) {
              let children = [...list.children];
              for (let child of children) {
                if (child.tag === 'ol' || child.tag === 'ul') {
                  consumeList(child);
                } else if (child.children.length === 1 && (child.children[0].tag === 'ol' || child.children[0].tag === 'ul')) {
                  consumeList(child.children[0]);
                } else {
                  startNodeParent.consume(child);
                }
              }
              if (remove) {
                list.parent.removeChild(list);
              }
            }
            if (nextParent.tag === 'ol' || nextParent.tag === 'ul') {
              // We want to collapse any list structure. If we leave it in place, the block nature of <li> will break other parts of our logic
              consumeList(nextParent, true);
            } else {
              startNodeParent.consume(nextParent);
            }
            endNodeParent = endNote.blockParent();
          }
        }
        startNote.encapsulateTo(endNote, (node) => {
          node.display.hidden = true;
        });
      }
    } else if (env.config.stage === 'insertion') {
      // Removal of notes for insertion will happen right before converting to a string
      startNote.info.shouldInsert = startNote.info.attributes.keys.insert && (await startNote.info.attributes.keys.insert.value(env));
    } else {
      throw new Error('Unknown stage: ' + env.config.stage);
    }
    

    startNote = nextNote();
  }

  if (needNewStructuredEls) {
    structureEls = getStructureEls(dom);
    needNewStructuredEls = false;
  }


  // Structure {link}'s

  let links = structureEls.filter(node => node.tag === 'link');
  let linkStack = [];
  let startLinks = [];
  let startLink;
  for (let link of links) {
    if (link.info.type === 'link_start') {
      linkStack.push(link);
    } else {
      startLink = linkStack.pop();
      if (startLink) {
        startLink.info.end = link;
        link.info.start = startLink;
      } else {
        link.info.processed = true;
      }
      startLinks.push(startLink);
    }
  }

  while (linkStack.length) {
    let oldStartLink = linkStack.pop();
    oldStartLink.info.processed = true;
    oldStartLink.info.end = null;
  }

  startNode = startLinks.shift();
  while (startNode) {
    startNode.info.processed = true;

    /** @type {ParseNode} */
    let endNode = startNode.info.end;
    if (!endNode || !startNode.findParent(x => x.type === 'root')) {
      // could be due to an attribute error or another reason
      startNode = startLinks.shift();
      continue;
    }
    endNode.info.processed = true;


    let myRootData = startNode.findLocalData();
    let wrapperEnv = new Environment(env.config.store[myRootData], Object.assign({}, env.config, { mode: 'attribute' }), env.locations);
    await wrapperEnv.data.ready;

    let attrs = {
      href: await startNode.info.attributes.position[0].value(wrapperEnv)
    };

    if (
      !(attrs.href.toLowerCase().startsWith('https:')
      || attrs.href.toLowerCase().startsWith('http:')
      || attrs.href.toLowerCase().startsWith('mailto:')
      || attrs.href.toLowerCase().startsWith('tel:'))) {
      startNode.removeTo(endNode, false, true);
      let msg = err('Invalid {link} URL: ' + attrs.href, startNode, {});
      msg.dependencies = startNode.dependencies;
      startNode.replace(msg);
      startNode = startLinks.shift();
      continue;
    }

    try {
      if ('new' === await (startNode.info.attributes.keys.window && startNode.info.attributes.keys.window.value(wrapperEnv))) {
        attrs.target = '_blank';
        attrs.rel = 'noopener noreferrer';
      }
    } catch (e) {
      startNode.removeTo(endNode, false, true);
      let msg = err(e.message || 'Invalid value for {link} command\'s "window" attribute', startNode, {});
      msg.dependencies = startNode.dependencies;
      startNode.replace(msg);
      startNode = startLinks.shift();
      continue;
    }

    // eslint-disable-next-line
    startNode.encapsulateTo(endNode, (x) => {
      /** @type {any} */
      let nodes = x;
      if (!Array.isArray(x)) {
        nodes = [x];
      }

      if (['table', 'tr', 'li', 'td', 'p'].includes(nodes[0].tag)) {
        let flatNodes = [];
        for (let node of nodes) {
          if (node.tag === 'tr' || node.tag === 'table') {
            flatNodes = flatNodes.concat(node.findAll(x => x.tag === 'td'));
          } else {
            flatNodes.push(node);
          }
        }
        flatNodes.forEach((node, i) => {
          wrapNodes(node, node.children.slice(), i);
        });
      } else {
        wrapNodes(nodes[0].parent, nodes);
      }

      function wrapNodes(parent, nodes, i = 0) {
        let linkWrapper = new ParseNode('el', 'a', attrs, startNode);
        linkWrapper.display.isDynamicLink = true;
        linkWrapper.importPrefix += '_linkwrapper' + i;
        parent.insertChild(parent.children.indexOf(nodes[0]), linkWrapper);
        linkWrapper.addDependencies(startNode.dependencies);
        linkWrapper.addChildren(nodes);
      }
    }, true);
    
    // We don't need the {link} nodes anymore
    replaceNodeWithTrimPlaceholder(startNode);
    replaceNodeWithTrimPlaceholder(endNode);

    startNode = startLinks.shift();
  }
}


/**
 * Prunes and updates if and repeat blocks. Note, the data must be available in the
 * config object at this time.
 * 
 * @param {ParseNode} dom
 * @param {Environment} env
 * 
 * @return {Promise<ParseNode>}
 */
export async function structureDom(dom, env) {
  if (env.config.stage === 'tokenization') {
    // We don't need to do structuring when tokenizing, this can happen in attributes.
    return dom.clone();
  }

  if (env.config.mode !== 'attribute') {
    dom.localData = 'root';

    if (env.locations.length === 0) {
      // Sometimes locations will be managed in which case we don't need to do this
      env = env.derivedLocation('local_data - ' + dom.localData);
    }
  }

  if (!env.config.store) {
    env.config.store = {};
  }
  if (!env.config.store.root) {
    let inputs = await createContainer([dom], env);
    env.config.quickItems = inputs.quickItems;
    inputs.container.owner = 'local_data - root';
    inputs.container.locations = ['local_data - root'];
    env.config.store.root = inputs.container;
  }

  if (!['preview', 'insertion'].includes(env.config.stage)) {
    throw new Error('"stage" must be "preview" or "insertion". Got: ' + env.config.stage);
  }

  // We want to collect all the derived from either assignments to
  // the addon data passing
  let derived = {};


  let changeMade = true;
  let structureIterations = 0;
  const MAX_ITERATIONS = 6; // Max number of times to restructure in response to form data changing

  async function runAssignments() {
    /**
     * We update all derived equations and then check if a change was to a form value.
     * 
     * If a change was made, we rerun this structure and equation evaluation process. The
     * reason for this is {if} or {repeat} may depend on the output of these equations.
     * 
     * Additionally, switching {if} branches may activate or disable derived equations.
     * Thus we should repeat this structuring loop until the results no longer change.
     * However, as a sanity check we put a cap on the MAX_ITERATIONS.
     * 
     * Note, users could create perverse snippets that would never stabilize. E.g:
     * 
     *    {show=yes}{if: show}{show=no}{endif}
     * 
     * Here the outer show will enable the {if} block which will then turn show off disabling
     * the block and the equation which turns show off, which will then allow show to be turned back on...
     * 
     * The MAX_ITERATIONS will prevent these from oscillating indefinitely.
     **/
    // Apply the changes
    for (let key in env.config.store) {
      if (env.config.store[key].owner === myOwnerId) {
        let myEnv = env.derived();
        myEnv.locations = env.config.store[key].locations;
        await env.config.store[key].updateDerived(null, myEnv);
      }
    }
    // We don't check the changes until all the updates have been made
    // As the changes could conflict. E.g. if you are updating a global
    // variable from within a {repeat}
    for (let key in env.config.store) {
      if (env.config.store[key].owner === myOwnerId) {
        let changes = env.config.store[key].getChangeLog();
        for (let changeId in changes) {
          let change = changes[changeId];
          let origVal = change.origVal;
          let newVal = change.newVal;
          if (origVal === undefined || newVal === undefined || origVal === null || newVal === null) {
            if ((newVal == null && origVal !== null) || (newVal !== null && origVal === null) || (newVal === undefined && origVal !== undefined) || (newVal !== undefined && origVal === undefined)) {
              changeMade = true;
              break;
            }
          } else {
            if (!equals(changes[changeId].origVal, changes[changeId].newVal)) {
              changeMade = true;
              break;
            }
          }
        }
      }
    }
  }

  let myOwnerId = env.ownerId();
  let origDom = dom;
  while (changeMade && structureIterations < MAX_ITERATIONS) {
    changeMade = false;

    // Reset the change logs
    // We should only reset the change log at the top level.
    // Otherwise nested eval's (like a command in an attribute) can incorrectly
    // clear the changes. Note that nested eval's shouldn't have any assignments.
    if (env.config.mode !== 'attribute') {
      for (let key in env.config.store) {
        // an addon should only clear its own changelog
        if (env.config.store[key].owner === myOwnerId) {
          env.config.store[key].resetChangeLog();
        }
      }
    }
    structureIterations++;

    if (structureIterations === MAX_ITERATIONS) {
      if ((typeof window === 'undefined') || !window['disable_structuring_warning_in_tests']) {
        console.warn('Structuring iterations reached MAX_ITERATIONS.');
      }
    }

    dom = origDom.clone(true, true, true);
    await doStructuring(dom, env, derived);

    // Do equation assignments (e.g. {a=1})
    let assignments = dom.findAll((el) => el.type === 'expand' && el.tag === 'calc' && !!el.info.attributes.keys.name);


    for (let key in env.config.store) {
      let cont = env.config.store[key];
      if (cont.owner === myOwnerId) {
        cont.derived = derived[key] || [];
        if (!cont.parent.children.includes(cont)) {
          cont.parent.children.push(cont);
        }
      }
    }

    /** @type {Set<string>} */
    let currentFixedAssignedGlobals = new Set();


    let staticEnv = env.derivedConfig({ mode: 'attribute' });
    staticEnv.data = new DataContainer(null);
    await staticEnv.data.ready;

    if (assignments.length) {
      for (let assignment of assignments) {
        let name = await assignment.info.attributes.keys.name.value(staticEnv);
        currentFixedAssignedGlobals.add(name.toLocaleLowerCase());
      }
    }

    env.config.currentFixedAssignedGlobals = currentFixedAssignedGlobals;
    let hasAssignments = false;
      
    if (assignments.length) {

      for (let assignment of assignments) {
        let tree;
        try {
          tree = await assignment.info.attributes.position[0].ast(staticEnv);
        } catch (_) {}

        let identifiers = [];
        if (tree) {
          let deps = await assignment.info.attributes.position[0].getDependencies(staticEnv);
          if (deps) {
            identifiers = [...deps];
          }
        }
        
        let name = await assignment.info.attributes.keys.name.value(staticEnv);

      
        /** @type {import('./DataContainer').DerivedChangeFn} */
        let fn = async (env, _changes, recursionStack) => {
          let res;
          if (recursionStack.includes(fn)) {
            if (recursionStack.length > 20) {
              res = {
                type: 'error',
                error: `Too many linked references in formula for "${name}"`
              };
            };
            if (recursionStack.includes(fn)) {
              res = {
                type: 'error',
                error: `Recursive formula when assigning "${name}" is not allowed`
              };
            }
          } else {
            if (!tree) {
              res = {
                type: 'error',
                error: 'Invalid formula'
              };
            } else {
              try {
                // we set dontForce as converting to a string and back
                // if the var can be used is expensive especially for lists.
                // we can just store the native form in the data container instead
                res = await evaluateEquation(tree, env.derivedConfig({ mode: 'attribute' }, 'assignment - ' + assignment.startPosition), true);

              } catch (err) {
                res = {
                  type: 'error',
                  error: err.message || 'Invalid formula'
                };
              }
            }
          }


          /** @type {import('./DataContainer').DataUpdateChangeType[]} */
          let updates = [{
            name,
            value: res
          }];
          await env.data.bulkUpdate(updates, env, recursionStack.concat(fn));
        };

        env.config.store[assignment.findLocalData()].derived.push(new DerivedChange(identifiers, /** @type {any} */ (fn)));
      }

      hasAssignments = true;
    }

    if (hasAssignments) {
      await runAssignments();
    }
  }

  // error out all autopilot commands in tables
  generateSplitPoints();
  let tables = dom.findAll(p => p.tag === 'table');
  for (let table of tables) {
    // eslint-disable-next-line
    table.each(node => {
      if (node.type === 'expand' && SPLIT_POINTS.includes(node.tag)) {
        let msg = err('Cannot use autopilot commands in tables.', node, {});
        msg.dependencies = node.dependencies;
        node.replace(msg);
      }
    });
  }
  
  // Update derived trims
  await dom.asyncEach(async (node) => {
    if (node.display.trim === undefined) {
      if (node.info && node.info.attributes && node.info.attributes.keys && node.info.attributes.keys.trim) {
        let newEnv = await localizedEnvironment(node, env);
        try {
          node.display.trim = await getTrim(node.info.attributes.keys.trim, node.display, newEnv);
        } catch (e) {
          let msg = err(e.message || 'Invalid trim formula.', node, {});
          msg.dependencies = node.dependencies;
          node.replace(msg);
        }
      }
    }
  });


  // apply dependencies up from the leaf node to the root
  dom.eachDFS((node) => {
    if (!node.parent) {
      return;
    }

    if (node.dependencies && node.dependencies.size) {
      if (node.parent.dependencies && node.parent.dependencies.size) {
        for (let item of node.dependencies) {
          node.parent.dependencies.add(item);
        }
      } else {
        node.parent.dependencies = new Set(node.dependencies.values());
      }
    }
  });

  // This property is used during domToStream
  // which doesn't have access to env config
  // So we pass the property from env to dom
  if (dom.type === 'root') {
    dom.info.editorData = env.config.editorData;
  }

  return dom;
}


/**
 * Removes a node while preserving its trimming behavior.
 * 
 * @param {ParseNode} node
 */
function replaceNodeWithTrimPlaceholder(node) {
  let trimmer = new ParseNode('expand', 'text', '', node);

  trimmer.display = Object.assign({}, node.display);
  if (node.display.trim === undefined) {
    if (node.info && node.info.attributes && node.info.attributes.keys && node.info.attributes.keys.trim) {
      trimmer.info.attributes = {
        keys: {
          trim: node.info.attributes.keys.trim
        },
        position: [],
        spec: {}
      };
    } else {
      trimmer.display.trim = false;
    }
  }

  trimmer.dependencies = node.dependencies;
  trimmer.localData = node.localData;
  trimmer.importPrefix = node.importPrefix + '_trim';
  node.replace(trimmer);
}


/**
 * Fills in data values in the dom.
 * 
 * @param {ParseNode} dom
 * @param {Environment} env
 * 
 * @return {Promise<ParseNode>}
 */
export async function fillDom(dom, env) {
  dom = await structureDom(dom, env);
  
  let res = (el, node) => {
    if (typeof el === 'string') {
      el = new ParseNode('expand', 'text', el, node);
      el.importPrefix += '_filled';
    }
    return el;
  };

  /**
   * @param {ParseNode} n
   * 
   * @return {Promise}
   */
  const mapFn = async (n) => {
    let newEnv = await localizedEnvironment(n, env);

    if (n.type === 'expand' && n.tag === 'form') {
      if (['single_line', 'multi_line', 'date', 'menu'].includes(n.info.type) || (n.info.type === 'toggle_start' && n.info.end == null)) {
        newEnv = newEnv.derivedLocation('s_command - ' + n.position());
        try {
          // We need to test that these attributes evaluate correctly so errors propagate
          // otherwise errors for these attributes will only show on the preview.
          const testKeys = ['width', 'height', 'cols', 'rows', 'start', 'end'];
          for (let key of testKeys) {
            if (n.info.attributes.keys[key]) {
              await n.info.attributes.keys[key].value(newEnv);
            }
          }

          let fmtFn = null;
          if (n.info.attributes.keys.formatter) {
            fmtFn = async (x) => {
              try {
                return toStr(await callFn(await n.info.attributes.keys.formatter.value(newEnv), [x], newEnv.derivedLocation('formatter')));
              } catch (e) {
                throw new Error('Invalid "formatter" function.');
              }
            };
          }

          const sel = n.info.formInfo.name;
          
          if (newEnv.data.defined(sel)) {
            let v = newEnv.data.get(sel);
            
            if (n.info.type === 'menu') {
              let multiple = n.info.attributes.keys.multiple && (await n.info.attributes.keys.multiple.value(env));
              if (n.info.attributes.keys.itemformatter) {
                // get the menu items so we can find the indexes for formatting
                let items = await Promise.all(n.info.attributes.position.filter(x => x.name === 'default' || x.name === null).map(a => a.value(newEnv)));
                if (n.info.attributes.keys.values) {
                  items = (await n.info.attributes.keys.values.value(newEnv)).slice();
                }
      
                let itemFormatter = await n.info.attributes.keys.itemformatter.value(newEnv);
                let itemFmt = async (x) => {
                  try {
                    let index = items.indexOf(x);
                    return callFn(itemFormatter, [x, index + 1], newEnv.derivedLocation('itemformatter - ' + index));
                  } catch (_) {
                    throw new Error('Invalid "itemformatter" function.');
                  }
                };
                if (multiple) {
                  v = toStr(createListFromArray(await Promise.all(toLst(v || '[]').positional.map(async x => await itemFmt(x)))));
                } else {
                  v = toStr(await itemFmt(v));
                }
              }
              if (!fmtFn) {
                if (multiple) {
                  fmtFn = (x) => toLst(x).positional.map(item => toStr(item)).join(', ');
                }
              }
              if (multiple) {
                // Ensure we pass a list if blank
                let oldFmt = fmtFn;
                fmtFn = x => oldFmt(x || '[]');
              }
            } else {
              if (v === true) {
                v = 'yes';
              } else if (v === false) {
                v = 'no';
              }
            }
            if (!fmtFn) {
              fmtFn = x => x;
            }
            return res(await fmtFn(toStr(v)), n);
          } else if (typeof sel === 'string' && sel.startsWith(AUTO_PREFIX)) {
            if (!fmtFn) {
              fmtFn = x => x;
            }
            return res(await fmtFn(''), n);
          } else {
            return res(err('Unknown name: ' + sel, n, {}));
          }
        } catch (e) {
          return err(e.message, n, {});
        }
      } else if (['repeat_start', 'repeat_end', 'if_start', 'if_else', 'if_elseif', 'if_end'].includes(n.info.type)) {
        // We need to return something rather than removing to keep trimming working
        let node = new ParseNode('expand', 'text', '', n);
        node.importPrefix += '_filled';
        node.display = Object.assign({}, n.display);
        if (node.display.trim === undefined) {
          node.display.trim = false;
        }
        return node;
      }
    } else if (n.info.command === 'dbselect') {
      let evaluated = await evaluateNode(n, newEnv);
      if (evaluated.type === 'error') {
        return evaluated;
      }
      if (newEnv.config.isOneoffFormula) {
        return evaluated;
      }
      return res('');
    } else if (n.type === 'error' && n.info.node && n.info.node.infoClone) {
      return evaluateNode(n.info.node, newEnv);
    } else if (n.tag === 'note') {
      return n; // need to preserve whether we should insert
    } else if (n.type === 'expand') {
      return evaluateNode(n, newEnv);
    }

    return n;
  };

  return await dom.asyncMap(mapFn);
}

/**
 * Trim and extract side channels from the dom.
 * 
 * @param {ParseNode} dom
 * 
 * @return {ParseNode}
 */
export function postProcessDom(dom) {
  SIDECHANNEL_SUBTYPES = SIDECHANNEL_SUBTYPES || Object.keys(COMMANDS).filter(command => COMMANDS[command].sidechannel).map(command => COMMANDS[command].subType);
  REMOVE_SUBTYPES = REMOVE_SUBTYPES || Object.keys(COMMANDS).filter(command => COMMANDS[command].subType && COMMANDS[command].remove).map(command => COMMANDS[command].subType);

  dom = trimDom(dom);

  // remove sidechannel and remove items
  dom.sideChannel = [];
  /**
   * @param {ParseNode} el 
   */
  function removeSideChannels(el) {
    if (el.type === 'expand' && SIDECHANNEL_SUBTYPES.includes(el.info.type)) {
      dom.sideChannel.push(el);
      el.parent.removeChild(el);
      return true;
    } else if (el.type === 'expand' && REMOVE_SUBTYPES.includes(el.info.type)) {
      el.parent.removeChild(el);
      return true;
    } else {
      if (el.children) {
        for (let i = 0; i < el.children.length; i++) {
          if (removeSideChannels(el.children[i])) {
            i--;
          }
        }
      }
    } 
  }
  removeSideChannels(dom);

  trimNotes(dom);

  // Remove useless spans.
  //
  // Note, we need to use this approach, rather than "dom.each(...)" as filled spans may
  // become empty as we mutate other spans.
  let getEmptySpan = () => dom.find(x => x.tag === 'span' && x.parent && (!x.attrs || !Object.keys(x.attrs).length));
  let emptySpan = getEmptySpan();
  while (emptySpan) {
    emptySpan.parent.insertChildren(emptySpan.parent.children.indexOf(emptySpan) + 1, emptySpan.children.slice());
    emptySpan.parent.removeChild(emptySpan);
    emptySpan = getEmptySpan();
  }

  // Remove unnecessary paragraph (<p>) wrapper if it exists
  if (dom.children.length === 1 && dom.children[0].tag === 'p') {
    let p = dom.children[0];
    if (!p.attrs || Object.keys(p.attrs).length === 0 || (Object.keys(p.attrs).length === 1 && p.attrs.style && Object.keys(p.attrs.style).length === 0)) {
      dom.addChildren(p.children);
      dom.removeChild(p);
    }
  }

  return dom;
}


/**
 * Trim whitespace from the dom.
 * 
 * @param {ParseNode} dom
 * 
 * @return {ParseNode}
 */
export function trimDom(dom) {
  /** @type {ParseNode[]} */
  let nodes = dom.findAll(x => ![false, 'no'].includes(x.display.trim));
  
  for (let node of nodes) {
    let n;
    let priorN;
    if ([true, 'yes', 'left'].includes(node.display.trim)) {
      priorN = node;
      n = node.previousLeaf();
      while (n) {
        let consumed = false;

        if (n.parent !== priorN.parent || n.block()) {
          let newBlock = n.blockParent();
          let block = priorN.blockParent();
          if (newBlock && block && newBlock !== block && newBlock.parent === block.parent && newBlock.tag === block.tag) {
            consumed = true;
            newBlock.consume(block);
          }
        }
        
        if (n.type === 'expand' && n.tag === 'text') {
          n.info.message = n.info.message.trimRight();
          if (n.info.message || (n.display.trim === 'no' || n.display.trim === false || n.display.trim === 'right')) {
            break;
          }
        } else if (!consumed || n.type === 'expand') {
          break;
        }
        priorN = n;
        n = n.previousLeaf();
      }
    }

    if ([true, 'yes', 'right'].includes(node.display.trim)) {
      priorN = node;
      n = node.nextLeaf();
      while (n) {
        let consumed = false;

        if (n.parent !== priorN.parent || priorN.block()) {
          let newBlock = n.blockParent();
          let block = priorN.blockParent();

          if (newBlock && block && newBlock !== block && newBlock.parent === block.parent && newBlock.tag === block.tag) {
            consumed = true;
            block.consume(newBlock);
          }
        }
        
        if (n.type === 'expand' && n.tag === 'text') {
          n.info.message = n.info.message.trimLeft();
          if (n.info.message || (n.display.trim === 'no' || n.display.trim === false || n.display.trim === 'left')) {
            break;
          }
        } else if (!consumed || n.type === 'expand') {
          break;
        }
        if (consumed) {
          n = priorN.nextLeaf();
        } else {
          priorN = n;
          n = n.nextLeaf();
        }
      }
    }
  }

  return dom;
}


/**
 * Marks notes and interactive areas. Mutates the dom.
 * 
 * @param {ParseNode} dom - note this is mutated
 * @param {Environment} env
 * 
 * @return {Promise<ParseNode>}
 */
export async function markupDomPreview(dom, env) {
  // Handle notes
  let allNotes = dom.findAll(node => node.tag === 'note' && ['note_start', 'note_end'].includes(node.info.type));
  allNotes.forEach(n => n.info.processed = false);
  function nextNote() {
    // root check as node may be removed in prior iteration
    let notes = allNotes.filter(node => !node.info.processed && !!node.findParent(x => x.type === 'root'));
    let noteStack = [];
    let startNote;
    for (let note of notes) {
      if (note.info.type === 'note_start') {
        noteStack.push(note);
      } else {
        startNote = noteStack.pop();
        if (startNote) {
          startNote.info.end = note;
          note.info.start = startNote;
        } else {
          note.parent.removeChild(note);
        }
      }
    }

    while (noteStack.length) {
      let oldStartNote = noteStack.pop();
      oldStartNote.parent.removeChild(oldStartNote);
    }

    return startNote;
  }

  let startNote = nextNote();
  while (startNote) {
    let endNote = startNote.info.end;
    startNote.info.processed = true;
    endNote.info.processed = true;

    let previewed = true;
    if (startNote.info.attributes.keys.preview) {
      previewed = startNote.info.attributes.keys.preview.final;
    }

    if (previewed) {
      startNote.encapsulateTo(endNote, (n) => n.display.isShownNote = true);
    }

    startNote = nextNote();
  }


  // Handle interactives

  const INTERACTIVES = ['toggle_start', 'toggle_end', 'if_start', 'if_else', 'if_elseif', 'if_end', 'repeat_start', 'repeat_end'];
  let allInteractives = dom.findAll(node => node.tag === 'form' && INTERACTIVES.includes(node.info.type));
  allInteractives.forEach(n => n.info.processed = false);
  function nextInteractive() {
    // root check as node may be removed in prior iteration
    let interactives = allInteractives.filter(node => !node.info.processed && !!node.findParent(x => x.type === 'root'));
    // deepscan-disable-next-line
    let stack = [];

    for (let interactive of interactives) {
      if (interactive.info.type.endsWith('_start') || interactive.info.type.endsWith('_else') || interactive.info.type.endsWith('_elseif')) {
        stack.push(interactive);
      } else {
        let type = interactive.info.type.split('_')[0];
        for (let i = stack.length - 1; i >= 0; i--) {
          if (interactives[i].info.type.startsWith(type)) {
            return [interactives[i], interactive];
          }
        }
      }
    }
    
    return [null, null];
  }

  let [startInteractive, endInteractive] = nextInteractive();
  while (startInteractive) {
    startInteractive.info.processed = true;
    endInteractive.info.processed = true;

    startInteractive.encapsulateTo(endInteractive, (n) => n.display.isInteractive = true);

    [startInteractive, endInteractive] = nextInteractive();
  }


  // Handle form keys

  let formFields = dom.findAll(node => node.tag === 'form' && ['single_line', 'multi_line', 'date', 'menu', 'toggle_start'].includes(node.info.type)).concat(env.config.quickItems.map(x => x.node));
  for (let node of formFields) {
    let myEnv = new Environment(env.config.store[node.findLocalData()], env.config, ['local_data - root'].concat(node.getLocalDataChain().slice(1).concat('s_command - ' + node.position())));

    let attr = node.info.attributes;
    let vals = Object.create(null);

    for (let key of ['cols', 'rows', 'width', 'height', 'start', 'end', 'itemformatter', 'formatter']) {
      if (key in attr.keys) {
        try {
          vals[key] = await attr.keys[key].value(myEnv);
        } catch (err) {
          vals[key] = err;
        }
      }
    }

    if (node.info.type === 'menu') {
      let items = await Promise.all(attr.position.filter(x => x.name === 'default' || x.name === null).map(async a => {
        try {
          return await a.value(myEnv);
        } catch (err) {
          return '[Error - ' + err.message + ']';
        }
      }));
      if (attr.keys.values) {
        try {
          items = (await attr.keys.values.value(myEnv)).slice();
        } catch (e) {
          let msg = err(e.message || 'Invalid {formmenu} values', node, {});
          msg.dependencies = node.dependencies;
          node.replace(msg);
          continue;
        }
      }
      let displayFn = (x, _) => x;
      if ('itemformatter' in attr.keys) {
        displayFn = async (x, i) => {
          try {
            return toStr(await callFn(vals.itemformatter, [x, i], myEnv.derivedLocation('itemformatter - ' + (i - 1))));
          } catch (err) {
            return err.message;
          }
        };
      }

      vals['menuitems'] = await Promise.all(items.map(async (x, i) => ({
        source: x,
        display: await displayFn(x, i + 1)
      })));
    }

    node.display.formKeys = vals;
  }

  return dom;
}


function generateSplitPoints() {
  SPLIT_POINTS = SPLIT_POINTS || Object.keys(COMMANDS).filter(command => COMMANDS[command].interaction).map(command => COMMANDS[command].tag);
}


/**
 * Split the dom at the various interactive split points (e.g. click simulations).
 * 
 * @param {ParseNode} dom
 * 
 * @return {ParseNode[]}
 */
export function splitDom(dom) {
  generateSplitPoints();

  let isStyled = dom.isStyled;
  let hadImport = dom.hadImport;

  let parts = [dom];
  let findSplit = () => parts[parts.length - 1].find(el => el.type === 'expand' && SPLIT_POINTS.includes(el.tag));
  let splitter = findSplit();
  while (splitter) {
    let [left, right] = splitter.split();
    parts.pop();
    if (left) {
      parts.push(left);
    }
    parts.push(splitter);
    if (right) {
      parts.push(right);
    }
    splitter = findSplit();
  }
  parts.forEach(p => p.isStyled = isStyled);
  parts.forEach(p => p.hadImport = hadImport);
  return parts;
}

/**
 * @param {ParseNode} node
 * @param {Environment} env
 * 
 * @return {Promise<ParseNode>}
 */
export async function evaluateNode(node, env) {
  let info = node.info;

  /** @type {import("./ParseNode").DisplayType} */
  let display = node.display;
  let res = (el) => {
    if (typeof el === 'string') {
      el = new ParseNode('expand', 'text', el, node);
    }
    if (Object.keys(display).length) {
      el.display = display;
    }
    return el;
  };

  async function runRemoteCommand() {
    try {
      const result = await runRemoteCommandInsideBlock({ info }, env);
      const isDBCommand = !!info.query;
      const isSuccess = isDBCommand ? result.status === 'success' : result.status < 300;
      if (isSuccess) {
        return res(toStr(result.data));
      } else {
        return err(result.error, node, {});
      }
    } catch (e) {
      return err(e, node, {});
    }
  }

  env = env.derivedLocation('s_command - ' + node.position());

  try {
    if (info.attributes && info.attributes.keys && info.attributes.keys.trim) {
      display.trim = await getTrim(info.attributes.keys.trim, display, env);
    }
    if (info.process) {
      // pass through standard flags
      let command = info.command;
      let type = info.type;
      info = await info.process(info.attributes, env);
      if (command && (typeof info !== 'string')) {
        info.command = command;
      }
      if (type) {
        info.type = type;
      }
    }
  } catch (error) {
    if (error instanceof DataRequiredError) {
      return node; // to be handled by fill data later on
    } else {
      return res(err(error.message, node, {
        embedNode: true
      }));
    }
  }

  if (node.type === 'error') {
    return res(err(info.message, node, {}));
  }
  
  switch (node.tag) {
  case 'addon':
    if (info.end && info.visibility[env.config.stage] === 'chicklet') {
      return res(node.infoClone(info));
    } else {
      return null;
    }
  case 'cursor':
    // Used in tests
    if (env.config['cursor']) {
      return res(await env.config['cursor']());
    } else {
      return res(node.infoClone(info));
    }
  case 'user': {
    if (!env.config.user || !env.config.user.hasOwnProperty(info.item)) {
      // We want anything on the user to override things like the 'os'
      if (info.item === 'os') {
        return res(getOS());
      }

      return res(err('Unknown {user} property: ' + info.item, node, {}));
    }
    let value = env.config.user[info.item];
    if (typeof value === 'string') {
      return res(value);
    } else {
      return res(createListFromObject(value));
    }
  } case 'snippet': {
    if (!env.config.snippet || !env.config.snippet.hasOwnProperty(info.item)) {
      return res(err('Unknown {snippet} property: ' + info.item, node, {}));
    }
    let value = env.config.snippet[info.item];
    if (typeof value === 'string') {
      return res(value);
    } else {
      return res(createListFromObject(value));
    }
  } case 'clipboard':
    if (env.config.unsafe && !env.config.unsafeOverrides?.includes('clipboard')) {
      // sensitive so lock down when unsafe
      return res(err('Cannot preview {clipboard} commands', node, {}));
    }

    // When we're in an attribute we always want the text version
    // of the clipboard. We could get the HTML version and convert
    // it to text, but that is lossy. Especially with tables
    // where we want tabs between the columns in text mode.
    let isAttribute = ['attribute', 'addon'].includes(env.config.mode);
    let cacheKey = 'clipboard';
    if (env.hasCache(cacheKey)) {
      let cached = env.getCache(cacheKey);
      if (!isAttribute || cached.tag === 'text') {
        // we clone it as it is mutated later on (e.g. by setting the `trim` options)
        return res(cached.clone());
      }
    }

    if (isAttribute) {
      cacheKey += '_attr';
      if (env.hasCache(cacheKey)) {
        return res(env.getCache(cacheKey).clone());
      }
    }

    let result = await env.config['clipboard'](isAttribute ? 'text' : undefined);
    if (typeof result === 'string') {
      // the clipboard function can return a string or a ParseNode
      result = new ParseNode('expand', 'text', result, node);
    } else {
      result.startPosition = node.startPosition;
      result.importPrefix = node.importPrefix;
    }
    await env.setCache(cacheKey, result);
    return res(result.clone());
  case 'alert':
    return res(err(info.message, node, {
      blocking: info.blocking,
      show: info.show,
      embedNode: true
    }));
  case 'error':
    return res(err(info.message, node, {}));
  case 'site':
    if (env.config.unsafe) {
      // sensitive so lock down when unsafe
      return res(err('Cannot preview {site} commands', node, {}));
    }
    if (info.type) {
      let result;
      // @ts-ignore
      let items = env.config.selectorFn(info);

      if (typeof items === 'object' && 'error' in items) {
        return res(err(items.error, node, {
          embedNode: true
        }));
      } else if (items === undefined) {
        let errString = 'Could not load {site} data.';
        if (info.page) {
          if (info.select === 'no') {
            errString = `Current tab does not match "${info.page}"`;
          } else {
            errString = `No matching tab found for "${info.page}"`;
          }
        }
        return res(err(errString, node, { embedNode: true }));
      }
      if (Array.isArray(items)) {
        result = toStr(createListFromArray(items));
      } else {
        result = items;
      }
      return res(result);
    } else {
      return res(info);
    }
  case 'test_string':
    return res(info);
  case 'datetime':
    let d;
    let atDate;
    if (info.at !== null) {
      atDate = moment(info.at, info.pattern || [moment.ISO_8601, 'LLLL', 'LLL', 'LL', 'L', 'LT', 'LTS', 'llll', 'lll', 'll', 'l', 'lt', 'lts'], info.locale || env.config.locale, true);
      if (!atDate.isValid()) {
        if (info.pattern !== null) {
          return res(err(`Could not parse "${info.at}" as a date or time with the pattern "${info.pattern}".`, node, { embedNode:true }));
        } else {
          return res(err(`Could not parse "${info.at}" as a date or time. Considering using the "pattern" setting.`, node, { embedNode: true }));
        }
      }
    }
    if (info.locale) {
      let oldLocale = moment.locale();
      d = atDate || moment(env.config.useRealtimeDates ? new Date() : env.config.date);
      d.locale(info.locale);
      moment.locale(oldLocale);
    } else {
      if (atDate) {
        d = atDate;
      } else {
        if (!env.config.moment) {
          env.config.moment = moment(env.config.useRealtimeDates ? new Date() : env.config.date).locale(getLocale(env.config.locale).moment());
        }
        d = env.config.moment.clone();
      }
    }
    
    if (info.shift) {
      applyTimeShift(d, info.shift);
    }
        
    let formatted = d.format(info.specification);
          
    return res(formatted);
  case 'run':
    return res(node.infoClone(info));
  case 'calc':
    let calculated;
    try {
      calculated = await evaluateEquation(info.eqn, env.derivedConfig({
        mode: 'attribute',
      }));
    } catch (error) {
      if (!(error instanceof DataRequiredError)) {
        return res(err(error.message, node, { embedNode: true }));
      }
      throw error;
    }
  
    const formatStr = info.format;
    if (formatStr) {
      if ((typeof calculated === 'string') && (calculated.trim() === '' || isNaN(Number(calculated)))) {
        return res(err('The "format" setting can only be used for numbers', node, {}));
      }
      if (!env.config.numberFormatter) {
        env.config.numberFormatter = formatLocale(getLocale(env.config.locale).d3);
      }
      try {
        calculated = env.config.numberFormatter.format(formatStr)(+calculated);
      } catch (error) {
        return res(err(`Invalid format "${info.format}"`, node, {}));
      }
    }
  
    if (info.silent) {
      return res(''); // suppress output
    } else {
      return res('' + calculated);
    }
  // We exhaustively enumerate the valid possibilities so
  // we can make sure to catch invalid ones.
  case 'key':
    return res(node.infoClone(info));
  case 'click':
    return res(node.infoClone(info));
  case 'button':
    return res(node.infoClone(info));
  case 'wait':
    return res(node.infoClone(info));
  case 'remote': {
    if (env.config.isOneoffFormula) {
      if (isSynchronousRemoteCommand({ info })) {
        /**
         * We refresh the form render with all the variable values that were assigned so far
         * in the statements of the currently running code block.
         * We do this before triggering the remote command and causing the code block to
         * wait for the network request to finish
         * If we don't do this, then the user will not see the updated value in the form renderer
         * (and wonder what went wrong)
         */
        env.config.callbacks?.onChange?.();
        return await runRemoteCommand();
      } else {
        runRemoteCommand().then(result => {
          if (result.type === 'error') {
            // Show a toast here because for async remote command
            // The resulting error is not propagated to the top
            // of the code block
            env.config?.showNotification({ message: result.info.message, title: getNotificationTitleForRemoteCommand({ info }) });
          }
        });
        // TODO: this should return void and we should
        // not use that value for assignment or function arguments
        // This issue is not unique to 'remote' commands, and also
        // exists for functions like notify() function
        return res('');
      }
    }
    return res(node.infoClone(info));
  } case 'image':
    return res(node.infoClone(info));
  case 'action':
    return res(node.infoClone(info));
  case 'text':
    return res(node.infoClone(info));
  case '__attrs_list': // for testing
    return res(node.infoClone(info));
  case '__attrs_lambda': // for testing
    return res(node.infoClone(info));
  case 'import':
    if (env.config.stage === 'tokenization') {
      // we don't execute imports
      return res(node.infoClone(info));
    }
    // else fall through to error
  default:
    console.warn('Unexpected node type:', node);
    console.warn(new Error().stack);
    return res(node.infoClone(info));
  }
}


/**
 * @param {ParseNode} node
 * @param {Environment} env
 * 
 * @return {Promise<Environment>}
 */
async function localizedEnvironment(node, env) {
  let chain = node.getLocalDataChain();

  if (chain.length) {
    let start = 0;

    if (env.locations.length) {
      start = 1; // will have been added by the attribute
    }

    let newLocations = env.locations.length ? env.locations.slice() : [];
      
    for (let i = start; i < chain.length; i++) {
      newLocations.push('local_data - ' + chain[i]);
    }

    let localData;
    for (let i = newLocations.length - 1; i >= 0; i--) {
      if (newLocations[i].startsWith('local_data -')) {
        localData = newLocations[i].slice('local_data - '.length);
        break;
      }
    }

    if (!localData || !env.config.store[localData]) {
      console.error('No local data for:', localData, 'New locations:', newLocations, 'Available local data:', Object.keys(env.config.store));
    }

    let newEnv = new Environment(env.config.store[localData], env.config, newLocations);
    await newEnv.data.ready;
    return newEnv;
  } else {
    return env;
  }
}


/**
 * @param {import('./ParserUtils').NodeAttribute} attr
 * @param {import('./ParseNode').DisplayType} display
 * @param {Environment} env
 */
async function getTrim(attr, display, env) {
  let trim = (await attr.value(env));
  if (typeof trim === 'string') {
    trim = trim.toLowerCase().trim();
  }
  let trimDirection = display.trimDirection;
  if (trimDirection === 'left') {
    if (trim === 'right') {
      trim = false;
    } else if (trim === 'left') {
      return 'left';
    } else if (toBool(trim)) {
      trim = 'left';
    } else {
      trim = false;
    }
  } else if (trimDirection === 'right') {
    if (trim === 'left') {
      trim = false;
    } else if (trim === 'right') {
      return 'right';
    } else if (toBool(trim)) {
      trim = 'right';
    } else {
      trim = false;
    }
  }
  
  return trim;
}