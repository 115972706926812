import React from 'react';
import { useTypedSelectorShallowEquals } from '../../hooks';
import GroupIconBasic from '@mui/icons-material/Folder';
import GroupSharedIcon from '@mui/icons-material/FolderShared';
import GroupAddonIcon from '@mui/icons-material/Widgets';
import GroupAddonIconInactive from '@mui/icons-material/WidgetsOutlined';
import GroupIconInactive from '@mui/icons-material/FolderOutlined';
import GroupSharedIconInactive from '@mui/icons-material/FolderSharedOutlined';
import PeopleIcon from '@mui/icons-material/PeopleAlt';
import GroupPublicIcon from '@mui/icons-material/FolderCopy';
import GroupPublicIconDisabled from '@mui/icons-material/FolderCopyOutlined';
import { isGroupPublic } from './group_public_rules.js';


/**
 * @param {object} props
 * @param {string=} props.groupId
 * @param {string=} props.icon
 * @param {number=} props.size
 * @param {boolean=} props.inactive
 * @param {React.CSSProperties=} props.iconStyle
 * @param {React.CSSProperties=} props.containerStyle
 * @param {React.CSSProperties=} props.emojiContainerStyle
 */
export default function GroupIcon(props) {
  let containerStyle = Object.assign({ display: 'inline-block' }, props.containerStyle);
  let iconStyle = props.iconStyle || {};
  let active = !props.inactive;
  let size = props.size || 24;

  let {
    isAddon,
    groupIcon: iconFromGroup,
    groupShared,
    isPublic
  } = useTypedSelectorShallowEquals((store)=> {
    let group = store.dataState.groups[props.groupId];

    return {
      isAddon: group && group.options && group.options.addon,
      groupIcon: group && group.options && group.options.icon,
      groupShared: group && group.shared,
      isPublic: group && isGroupPublic(group)
    };
  });
  let groupIcon = props.icon || iconFromGroup;

  if (!groupIcon) {
    iconStyle = Object.assign({}, iconStyle, containerStyle);
  }

  let icon = null;
  let miniIcon;
  if (isAddon) {
    if (active) {
      icon = <GroupAddonIcon style={iconStyle} />;
    } else {
      icon = <GroupAddonIconInactive style={iconStyle} />;
    }
    miniIcon = <GroupAddonIcon style={{
      fontSize: '14px'
    }} />;
  } else {
    if (isPublic) {
      if (active) {
        icon = <GroupPublicIcon style={iconStyle}/>;
      } else {
        icon = <GroupPublicIconDisabled style={iconStyle} />;
      }
    } else if (groupShared) {
      if (active) {
        icon = <GroupSharedIcon style={iconStyle} />;
      } else {
        icon = <GroupSharedIconInactive style={iconStyle} />;
      }
      miniIcon = <PeopleIcon style={{
        fontSize: '14px'
      }} />;
    } else {
      if (active) { 
        icon = <GroupIconBasic style={iconStyle} />;
      } else {
        icon = <GroupIconInactive style={iconStyle}/>;
      }
    }
  }

  if (groupIcon) {
    icon = <div translate="no" style={Object.assign(containerStyle, props.emojiContainerStyle)}>
      <div style={{
        position: 'relative',
        lineHeight: '24px'
      }}>
        {miniIcon && <div style={{
          position: 'absolute',
          borderRadius: 100,
          right: -4,
          bottom: -3,
          width: 16,
          height: 16,
          zIndex: 5,
          backgroundColor: '#999',
          overflow: 'hidden',
          border: 'solid 1px #999',
        }}>
          <div style={{
            position: 'relative',
            left: 0,
            top: -3,
            color: 'white',
            height: 16,
            width: 16,
            lineHeight: '24px'
          }}>{miniIcon}</div>
        </div>}
        <div style={{
          backgroundColor: '#f1f3f4',
          width: size,
          height: size,
          borderRadius: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'initial',
          paddingTop: 1,
          paddingLeft: size <= 24 ? 3 : 0,
          fontSize: size <= 24 ? '18px' : '22px',
          lineHeight: size <= 24 ? '18px' : '22px',
        }}>
          {groupIcon}
        </div>
      </div>
    </div>;
  }

  return icon;
}