import React, { useState, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import SearchResults from './SearchResults';
import { useIsSmall, useTypedSelectorShallowEquals } from '../../hooks';
import { searchSnippets, snippetText } from './searchFull';
import { alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { shouldShowAddonGroup } from '../../flags';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import { isAiBlaze } from '../../aiBlaze.js';


/**
 * @param {object} props
 * @param {() => void} props.onClose
 */
function SearchMain(props) {
  const { push: navigate } = useHistory();
  const {
    allSnippets,
    groups,
    isUserGroupDisabled,
    priorities,
    conflicts
  } = useTypedSelectorShallowEquals(store => {
    let groups = store.dataState.groups || {};
    /** @type {SnippetObjectType[]} */
    let allSnippets = [];
    for (let id in groups) {
      // We want to search regular groups and addons not installed from the store
      if (!groups[id].loading && (!groups[id].isAddon || shouldShowAddonGroup(id, store))) {
        allSnippets = allSnippets.concat(groups[id].snippets);
      }
    }

    /** @type {Object<string, boolean>} */
    const isUserGroupDisabled = {};
    if (store.userState && store.userState.groups) {
      for (const groupId of Object.keys(store.userState.groups)) {
        isUserGroupDisabled[groupId] = store.userState.groups[groupId].disabled;
      }
    }

    return {
      allSnippets,
      groups,
      isUserGroupDisabled,
      priorities: ((store.userState && store.userState.priorities && store.userState.priorities.snippets) || []).map(x => x.id),
      conflicts: store.uiState.conflicts
    };
  });

  return <SearchBase
    allSnippets={allSnippets}
    groups={groups}
    isUserGroupDisabled={isUserGroupDisabled}
    priorities={priorities}
    conflicts={conflicts}
    searchItemsFn={searchSnippets}
    itemTextFn={snippetText}
    selectFn={(snippetId) => navigate(`/snippet/${snippetId}`)}
    placeholder={`Search ${isAiBlaze ? 'prompt' : 'snippet'} name, shortcut or contents`}
    placeholderSmall="Search for snippets..."
    {...props}
  />;
}

/**
 * @param {object} props
 * @param {() => void} props.onClose
 * @param {(SnippetObjectType|PageObjectType)[]} props.allSnippets
 * @param {Object<string, GroupObjectType|SiteObjectType>} props.groups
 * @param {Record<string, boolean>=} props.isUserGroupDisabled
 * @param {string[]} props.priorities
 * @param {any=} props.conflicts
 * @param {(snippets: (SnippetObjectType|PageObjectType)[], priorities: string[], query?: string, groups?: Object<string, GroupObjectType|SiteObjectType>) => any[]} props.searchItemsFn
 * @param {(snippet: SnippetObjectType|PageObjectType) => string} props.itemTextFn
 * @param {(snippetId: string) => void} props.selectFn
 * @param {string} props.placeholder
 * @param {string} props.placeholderSmall
 */
export function SearchBase(props) {
  let [search, setSearch] = useState('');
  let [searchResults, setSearchResults] = useState(/** @type {{snippets: any[], fuseResults: object}} */ (null));
  let [highlightedIndex, setHighlightedIndex] = useState(0);
  let searchTimerId = useRef(/** @type {number} */ (null));
  let listRef = useRef();

  let isSmall = useIsSmall();

  const {
    allSnippets,
    groups,
    isUserGroupDisabled,
    priorities,
    conflicts,
    searchItemsFn,
  } = props;


  function scrollList(listRef, newIndex) {
    if (!listRef.current) {
      return;
    }

    let [lower, upper] = listRef.current.getVisibleRange();
    if (newIndex > upper || newIndex < lower) {
      listRef.current.scrollAround(newIndex);
    }
  }

  let select = ({ id: snippetId }) => {
    props.selectFn(snippetId);
    props.onClose();
  };

  function handleArrows(e) {
    if (!searchResults || !searchResults.snippets.length) {
      return;
    }
    if (e.key === 'ArrowDown') {
      let newIndexNext = Math.min(highlightedIndex + 1, searchResults.snippets.length - 1);
      scrollList(listRef, newIndexNext);
      setHighlightedIndex(newIndexNext);

      e.preventDefault();
      e.stopPropagation();
    }
    if (e.key === 'ArrowUp') {
      let newIndexPrev = Math.max(0, highlightedIndex - 1);
      scrollList(listRef, newIndexPrev);
      setHighlightedIndex(newIndexPrev);

      e.preventDefault();
      e.stopPropagation();
    }
    if (e.key === 'Enter') {
      let snippet = searchResults.snippets[highlightedIndex];
      select({ id: snippet.id });

      e.preventDefault();
      e.stopPropagation();
    }
  };

  
  function onChangeHandler(e) {
    let newSearchText = e.target.value;
    setSearch(newSearchText);
    setHighlightedIndex(0);
    if (searchTimerId.current) {
      clearTimeout(searchTimerId.current);
      searchTimerId.current = null;
    }
    searchTimerId.current = window.setTimeout(() => {
      let fuseResults = newSearchText ? searchItemsFn(allSnippets, priorities, newSearchText, groups) : [];
      
      // If user has disabled groups, we sort the results to prioritize
      // the non-disabled snippets above the disabled snippets
      if (isUserGroupDisabled) {
        const groupedFuseResults = [[], []];
        // Even with 4k snippets, this is a O(N) operation
        // and thus should happen sub-second
        fuseResults.forEach((item, index) => {
          const snip = item.item.snippet;
          const isDisabled = isUserGroupDisabled[snip.group_id];
          const idx = isDisabled ? 0 : 1;
          groupedFuseResults[idx].push(fuseResults[index]);
        });

        fuseResults = groupedFuseResults[1].concat(groupedFuseResults[0]);
      }

      const snippets = fuseResults.map(item => item.item.snippet);

      setSearchResults({
        snippets,
        fuseResults
      });
    }, 80);
  }

  return <Dialog
    open
    onClose={() => props.onClose()}
    PaperProps={{
      sx: {
        width: {
          xs: 320,
          sm: 400,
          md: 640
        },
        position: 'absolute',
        height: search ? '480px' : '62px',
        top: 'max(0px, calc(50vh - 240px))',
        transition: 'height 0.1s ease-in, margin-bottom 0.1s ease-in'
      }
    }}
    data-testid="text-blaze-search-dialog"
  >
    <Box
      sx={{
        position: 'relative',
        borderRadius: 1,
        backgroundColor: alpha('#ffffff', 0.15),
        '&:hover': {
          backgroundColor: alpha('#ffffff', 0.25),
        },
        marginLeft: 0,
        marginTop: .8,
        marginBottom: .6,
        width: '100%',
        '& root': {
          color: 'inherit',
        },
        '& .MuiInputBase-root': {
          pt: 1,
          pr: 1,
          pb: 1,
          pl: 7
        }
      }}>
      <Box
        sx={{
          width: '45px',
          height: '100%',
          position: 'absolute',
          pointerEvents: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SearchIcon />
      </Box>
      <InputBase
        autoFocus
        placeholder={isSmall ? props.placeholderSmall : props.placeholder}

        inputProps={{
          'aria-label': 'Search',
          'spellCheck': false,
          style: {
            width: '100%'
          }
        }}
        style={{
          width: '100%'
        }}
        value={search}
        onChange={onChangeHandler}
        onKeyDown={handleArrows}
      />
    </Box>

    {search && <div style={{
      paddingRight: 6,
      height: 428,
      overflowY: 'auto'
    }}>
      <SearchResults
        search={search}
        snippets={searchResults ? searchResults.snippets : []}
        searchResults={searchResults ? searchResults.fuseResults : []}
        highlightedIndex={highlightedIndex}
        listRef={listRef}
        conflicts={conflicts}
        handleArrows={handleArrows}
        insert={select}
        select={select}
        groups={groups}
        disabledGroups={isUserGroupDisabled}
        insertable={false}
        itemTextFn={props.itemTextFn}
      />
    </div>}
  </Dialog>;
}


const Search = React.memo(SearchMain);
export default Search;