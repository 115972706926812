import { useIsMounted, useTypedSelectorShallowEquals } from '../hooks';
import { useEffect } from 'react';
import { listPermissions } from '../components/PermissionList/utilities';

/**
 *
 * @param {boolean} iframeLoading
 * @param {(type: string, data: any) => void} sendMessageFn
 */
export default function useIFrameOrgUpdater(iframeLoading, sendMessageFn) {
  const isMounted = useIsMounted();
  const orgPermissions = useTypedSelectorShallowEquals((store) => {
    return store.orgState && store.orgState.org ? store.orgState.org.permissions : null;
  });

  useEffect(() => {
    if (orgPermissions && !iframeLoading) {
      // if a user is in an org, send all org user emails to the tables front-end
      listPermissions(orgPermissions).then((grants) => {
        if (isMounted.current) {
          const orgMembers = grants.filter(x => x.entityType === 'user').map(x => x.entityLabel).sort();
          sendMessageFn('org_members', orgMembers);
        }
      });
    }
    //eslint-disable-next-line
  }, [orgPermissions && JSON.stringify(orgPermissions), iframeLoading]);
}