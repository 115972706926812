import { ToggleButton, Tooltip } from '@mui/material';
import React, { forwardRef } from 'react';

/**
 * @type {React.ForwardRefExoticComponent<import("@mui/material").ToggleButtonProps&{label: string}>}
 */
const AlignToggleButton = forwardRef(({
  label,
  ...otherProps
}, ref) => {
  return (
    <Tooltip
      title={label}
    >
      <ToggleButton
        ref={ref}
        {...otherProps}
      />
    </Tooltip>
  );
});

export default AlignToggleButton;