import React from 'react';
import {
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Pageview';
import SettingsIcon from '@mui/icons-material/Settings';
import { useIsMedium } from '../../hooks';
import useHotkey from '../../hotkeys_hook';

/**
 * 
 * @param {object} props 
 * @param {boolean} props.isAddon 
 * @param {boolean} props.isHidden
 * @param {string} props.value
 * @param {(value: string) => any} props.onChange 
 * @param {boolean=} props.disableEdit 
 * @returns 
 */
const SnippetToggleBase = ({
  isAddon,
  value,
  onChange,
  disableEdit,
  isHidden
}) => {
  let isMedium = useIsMedium();

  useHotkey('cmd+shift+p, ctrl+shift+p', () => {
    if (disableEdit || isHidden) {
      return;
    }

    if (value === 'edit') {
      if (isAddon) {
        onChange('addon');
      } else {
        onChange('preview');
      }
    } else {
      onChange('edit');
    }
  }, [value]);

  return <ToggleButtonGroup
    color="primary"
    exclusive
    value={value}
    onChange={(_e, v) => {
      if (v) {
        onChange(v);
      }
    }}
    style={{
      paddingRight: 16,
      paddingLeft: isMedium ? 16 : 0
    }}
  >
    <ToggleButton value="edit" disabled={disableEdit}><EditIcon style={{
      marginRight: 8
    }} /> Edit</ToggleButton>
    {isAddon && <ToggleButton value="addon"><SettingsIcon style={{
      marginRight: 8
    }} /> Configure</ToggleButton>}
    {!isAddon && <ToggleButton value="preview"><PreviewIcon style={{
      marginRight: 8
    }} />Preview</ToggleButton>}
  </ToggleButtonGroup>;
};

const SnippetToggle = React.memo(SnippetToggleBase);
export default SnippetToggle;