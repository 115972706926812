
import { store, createSync, isOrg, uid } from '@store';
import { batch } from 'react-redux';
import findConflicts from './find_conflicts';
import { getState } from '../getState';
import { storage } from '../utilities';
import { orgId } from '../flags';
import { makeRef } from '../firebase_utilities';
import getDatabaseQueries from '../components/Utilities/getDatabaseQueries';
import { getQuerySkeleton } from '../snippet_processor/Commands';
import { lexSQLWithCommands } from '../snippet_processor/Lexer';
import { Environment } from '../snippet_processor/DataContainer';



let pendingDispatchTimer = null;
let pendingConflictsTimer = null;
let pendingDispatch = [];

/**
 * @this {import('./Sync').default}
 * @param {*} data
 */
function dispatch(data) {
  if (data.type === 'HANDLE_CONFLICTS') {
    if (pendingConflictsTimer) {
      clearTimeout(pendingConflictsTimer);
    }
    pendingConflictsTimer = setTimeout(() => {
      findConflicts();
    }, 500);
  } else if (data.type === 'GROUP_PERMISSION_DENIED') {
    if (data.errorCount > 2) {
      this.ignoreGroups.push(data.groupId);
      data.unSubscribe();
      this.emitDataChange();

      return true; // terminates the retry
    }
  } else {
    pendingDispatch.push(data);
    if (pendingDispatchTimer) {
      clearTimeout(pendingDispatchTimer);
    }
    pendingDispatchTimer = setTimeout(() => {
      let items = pendingDispatch.slice();
      pendingDispatchTimer = null;
      pendingDispatch = [];
      let lastDataChange = items.filter(x => x.type === 'DATA_CHANGE');
      lastDataChange = lastDataChange[lastDataChange.length - 1];

      // We batch multiple updates if we have them
      // so we don't have unnecessary redux re-renders
      batch(() => {
        items.forEach(item => {
          if (item.type === 'DATA_CHANGE') {
            if (item !== lastDataChange) {
              // We only want to dispatch the final data_change if multiple ones
              // have been queued up.
              return;
            }
          }
          store.dispatch(item);
        });
      });
    }, 0);
  }
}

let syncer = createSync(dispatch);

export const sync = syncer;





let groupUpdatingTimeouts = {};
const updateGroupConnected = (/** @type {GroupObjectType} */group, /** @type {ConnectedSettingsType} */connected) => storage.update(makeRef('groups', group.id), { connected });
const getGroupDatabaseQueries = (/** @type {GroupObjectType} */group, /** @type {string} */databaseId) => getDatabaseQueries(group.snippets, databaseId);

/**
 * @param {string} groupId
 * @param {string} databaseId
 * @param {string} query
 * @returns {Promise<void>}
 */
export async function updateGroupPermissions(groupId, databaseId, query) {
  const getGroupFn = () => getState().dataState.groups[groupId];
  await updateGroupOrSitePermissions(getGroupFn, databaseId, query, updateGroupConnected, getGroupDatabaseQueries);
}

/**
 * @param {() => GroupObjectType|SiteObjectType} getGroupFn
 * @param {string} databaseId
 * @param {string} query
 * @param {(group: GroupObjectType|SiteObjectType, connected: ConnectedSettingsType) => void} updateConnectedFn
 * @param {(group: GroupObjectType|SiteObjectType, databaseId: string) => Promise<import("../components/Utilities/getDatabaseQueries").ConnectedAttributes>} getDatabaseQueriesFn
 */
export async function updateGroupOrSitePermissions(getGroupFn, databaseId, query, updateConnectedFn, getDatabaseQueriesFn) {
  const group = getGroupFn();
  let key = group.id + '-' + databaseId;

  if (groupUpdatingTimeouts[key]) {
    clearTimeout(groupUpdatingTimeouts[key]);
  }

  // Update the connected permissions in two steps:

  // 1) We immediately add the new query, so the user can use it right away
  let state = getState();

  let connected = group.connected || /** @type {ConnectedSettingsType} */ ({
    database_queries: {}
  });


  if (!connected.database_queries) {
    connected.database_queries = {};
  }

  if (!connected.database_queries[databaseId]) {
    connected.database_queries[databaseId] = [];
  }

  let tokens = lexSQLWithCommands(query, new Environment({}, { stage: 'preview' }));

  // @ts-ignore
  let skeleton = getQuerySkeleton({
    evaluated: tokens.tokens
  });

  if (!connected.database_queries[databaseId].includes(skeleton)) {
    connected.database_queries[databaseId].push(skeleton);

    if (!connected.is_connected) {
      connected.is_connected = true;
      connected.connector = isOrg() ? ('o:' + orgId(state)) : ('u:' + uid());
    }

    updateConnectedFn(group, connected);
  }


  // 2) And then we do a full group snippet scan and refresh after a delay
  groupUpdatingTimeouts[key] = setTimeout(() => updateFullGroupOrSitePermissions(getGroupFn, databaseId, updateConnectedFn, getDatabaseQueriesFn), 2000);
}


/**
 * @param {string} groupId
 * @param {string} databaseId
 */
export async function updateFullGroupPermissions(groupId, databaseId) {
  const getGroupFn = () => getState().dataState.groups[groupId];
  await updateFullGroupOrSitePermissions(getGroupFn, databaseId, updateGroupConnected, getGroupDatabaseQueries);
}


/**
 * @param {() => GroupObjectType|SiteObjectType} getGroupFn
 * @param {string} databaseId
 * @param {(groupId: GroupObjectType|SiteObjectType, connected: ConnectedSettingsType) => void} updateConnectedFn
 * @param {(group: GroupObjectType|SiteObjectType, databaseId: string) => Promise<import("../components/Utilities/getDatabaseQueries").ConnectedAttributes>} getDatabaseQueriesFn
 */
export async function updateFullGroupOrSitePermissions(getGroupFn, databaseId, updateConnectedFn, getDatabaseQueriesFn) {
  let state = getState();

  const group = getGroupFn();
  let connected = group.connected || /** @type {ConnectedSettingsType} */ ({
    database_queries: {}
  });

  if (!connected.database_queries) {
    connected.database_queries = {};
  }

  if (!connected.is_connected) {
    connected.is_connected = true;
    connected.connector = isOrg() ? ('o:' + orgId(state)) : ('u:' + uid());
  }

  const { databaseQueries } = await getDatabaseQueriesFn(group, databaseId);

  if (databaseQueries[databaseId]) {
    connected.database_queries[databaseId] = databaseQueries[databaseId];
  } else {
    delete connected.database_queries[databaseId];
  }

  updateConnectedFn(group, connected);
}

/**
 * Update group to be connected to a given addon
 *
 * @param {string} groupId
 * @param {string} addonCommand
 */
export function updateGroupAddonPermissions(groupId, addonCommand) {
  let state = getState();

  let group = state.dataState.groups[groupId];

  let connected = group.connected || /** @type {ConnectedSettingsType} */ ({
    addons: []
  });

  if (!connected.addons) {
    connected.addons = [];
  }

  if (!connected.addons.includes(addonCommand)) {
    connected.addons.push(addonCommand);

    if (!connected.is_connected) {
      connected.is_connected = true;
      connected.connector = isOrg() ? ('o:' + orgId(state)) : ('u:' + uid());
    }

    storage.update(makeRef('groups', groupId), {
      connected
    });
  }
}