
import { createTheme } from '@mui/material/styles';


export const textTheme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: '#00acc0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#e04f40',
      contrastText: '#fff',
    }
  }
});


export const dataTheme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: '#00acc0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#1976d2',
      contrastText: '#fff',
    }
  }
});


export const pageTheme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: '#00acc0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#009688',
      contrastText: '#fff',
    }
  }
});

export const aiTheme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: '#00acc0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#c471f5',
      contrastText: '#fff',
    }
  }
});