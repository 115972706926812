import {
  Alert,
  Box,
  Button,
  Typography as T
} from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import EmbeddedCommandPopper from './EmbeddedCommandPopper';
import HelpIcon from '@mui/icons-material/HelpOutline';
import './embedded_command.css';
import ExpandIcon from '@mui/icons-material/KeyboardArrowDown';
import { useIsMounted } from '../../../hooks';
import EmbeddedAttributeSection from './EmbeddedAttributeSection';
import { Link } from 'react-router-dom';
import { ICON_MAPPING } from './embedded_utilities';
import { isCommunityDocsOrBundle, isPublicSpace } from '../../../flags';
import { CODE_BLOCK_INVALID_ATTRS, beforeEmbeddedCommandClose, getAttributeData } from '../editor_utilities';
import CommandNavigator from './CommandNavigator';

/**
 * 
 * @typedef {import("./EmbeddedAttributeSection").Attribute} Attribute 
 */

/**
 * @param {object} props 
 * @param {import("../editor_utilities").CollapsedDataType} props.data
 * @param {import("../editor_utilities").CollapsedDataType} props.rootData
 * @param {HTMLElement} props.target
 * @param {string} props.targetAttributeName
 * @param {{ activeTypes: object }} props.equationContext
 * @param {function} props.onClose
 * @param {import("./EmbeddedAttributeSection").onChangeCallback} props.onChange
 * @param {string} props.groupId
 * @param {Parameters<EmbeddedCommandContents>['0']['onBack']} props.onBack
 */
export default function EmbeddedCommand(props) {
  const hasEmbeddedCommandError = useRef(new Set());
  return <EmbeddedCommandPopper
    target={props.target}
    onClose={async () => {
      try {
        await beforeEmbeddedCommandClose(hasEmbeddedCommandError.current);
        props.onClose();
      } catch {
        // do nothing
      }
    }}
  >
    <EmbeddedCommandContents {...props} 
      onError={(errors) => {
        hasEmbeddedCommandError.current = errors;
      }}
    />
  </EmbeddedCommandPopper>;
}


const COMMAND_NAME_MAPPING = {
  click: 'click',
  clipboard: 'clipboard',
  cursor: 'cursor',
  key: 'key',
  site: 'site',
  formtext: 'formsingle_line',
  formparagraph: 'formmulti_line',
  formmenu: 'formmenu',
  formdate: 'formdate',
  formtoggle: 'formtoggle_start',
  time: 'datetime',
  user: 'user',
  snippet: 'snippet',
  wait: 'wait',
  import: 'import',
  urload: 'remoteurl_load',
  urlsend: 'remoteurl_ping',
  dbselect: 'remotedbselect',
  dbupdate: 'remotedbupdate',
  dbdelete: 'remotedbdelete',
  dbinsert: 'remotedbinsert',
  link: 'linklink_start',
  alert: 'alert',
  image: 'image',
  note: 'notenote_start',
  run: 'run',
  '=': 'calc'
};

/**
 * @param {object} props 
 * @param {import("../editor_utilities").CollapsedDataType} props.data
 * @param {import("../editor_utilities").CollapsedDataType} props.rootData
 * @param {HTMLElement} props.target
 * @param {string} props.targetAttributeName
 * @param {{ activeTypes: object }} props.equationContext
 * @param {function} props.onClose
 * @param {import("./EmbeddedAttributeSection").onChangeCallback} props.onChange
 * @param {string} props.groupId
 * @param {boolean=} props.sidebar
 * @param {(() => any)=} props.onMouseDown
 * @param {boolean=} props.readonly
 * @param {((path: import("../editor_utilities").CollapsedDataType['meta']['attributesPath']) => any)=} props.onBack
 * @param {(data: import("../editor_utilities").CollapsedDataType) => any} [props.onSelectCommand]
 * @param {() => import("../editor_utilities").CollapsedDataType[]} [props.getSibilings]
 * @param {Function=} props.onVariableNameChange
 * @param {((name: string) =>  Promise<number>)=} props.getVariableCountInSnippet
 * @param {((errors: Set<string>) => any)=} props.onError
 * @param {Function=} props.insert
 */
export function EmbeddedCommandContents(props, ref) {
  let origValues = props.data.value.attributes;
  let attrOrder = useRef(null);
  let meta = props.data.meta;
  let [showAdvanced, setShowAdvanced] = useState(false);
  let isMounted = useIsMounted();
  let [errorOverride, setErrorOverride] = useState(/** @type {{override: (string | import("../../../snippet_processor/ParseNode").InfoType)}} */(null));
  const errors = useRef(new Set());

  let type = props.data.value.type;
  let spec = props.data.value.spec;
  let attributesPath = props.data.meta.attributesPath;
  let parentData = props.rootData;
  let headerInfo = [];
  let pathHistory = [];
  for (let index = 0; index < attributesPath?.length && parentData; index++) {
    const path = attributesPath[index];
    headerInfo.push({
      name: parentData.value.spec.commandName,
      path: [...pathHistory]
    });
    pathHistory.push(path);
    parentData = getAttributeData(parentData, [path]);
  }
  headerInfo.push({
    name: spec.commandName
  });
  if (headerInfo.length > 6) {
    headerInfo = headerInfo.slice(headerInfo.length - 6);
  }

  const isNestedCommand = Array.isArray(meta.attributesPath) && meta.attributesPath.length > 0;
  let error = errorOverride ? errorOverride.override : meta.error;


  // @ts-ignore
  props.target._BlazeErrorCallbackFn = (error) => {
    if (isMounted.current) {
      setErrorOverride({ override: error });
    } 
  };

  /** @type {function(HTMLElement): void} */
  let handleElFocus = (el) => {
    // we use this instead of scrollIntoView() as that causes
    // the whole page to scroll on the docs site
    el.parentElement.scrollTop = el.offsetTop;
    
    el.classList.remove('highlight-flash');
    setTimeout(() => el.classList.add('highlight-flash'), 0);
    if (!isCommunityDocsOrBundle()) {
      // Only highlight the text field on the dashboard
      // On the community forum and docs site this may highlight the wrong
      // text box causing the scroll to jump to the top.
      
      /** @type {HTMLInputElement} */
      let input = el.querySelector('input, textarea, .ql-editor');
      if (input) {
        input.focus();
      }
    }
  };

  useEffect(() => {
    if (props.targetAttributeName) {
      let id = '#' + labelToId(props.targetAttributeName);
      let el = /** @type {HTMLElement} */ (document.querySelector(id));
      if (el) {
        handleElFocus(el);
      } else {
        setTimeout(() => {
          let id = '#' + labelToId(props.targetAttributeName);
          let el = /** @type {HTMLElement} */ (document.querySelector(id));
          if (el) {
            handleElFocus(el);
          }
        }, 20);
      }
    }
  }, [props.targetAttributeName]);

  /**
   * @type {Attribute[]}
   */
  let attrs = [];

  let named = spec.named;
  let positional = spec.positionalDef;
  if (positional) {
    if (origValues.length && origValues[0].positional) {
      attrs.push({
        label: positional.name,
        priority: 1000,
        used: true,
        positional: true,
        spec: Object.assign({ required: true }, positional),
        value: origValues[0].value,
        raw: origValues[0].raw,
        id: null
      });
    } else {
      attrs.push({
        label: positional.name,
        priority: 1000,
        used: true,
        spec: Object.assign({ required: true }, positional),
        value: '',
        raw: '',
        positional: true,
        fillIn: true,
        id: null
      });
    }
  }


  if (named) {
    /** @type {Attribute[]} */
    let tmpAttrs = [];

    // Go through unused named attributes
    tmpAttrs = [];
    for (let name of Object.keys(named)) {
      let existing = origValues.find(x => x.name === name);
      let used = !(!existing || existing.fillIn);
      let attribute = named[name];
      // Do not show trim for nested chips (unless user mentioned explicitly)
      if (name === 'trim' && props.data.meta.attributesPath?.length && !used) {
        continue;
      }
      if (!used && attribute.priority === -10) {
        // deprecate or items we want to discourage
        continue;
      }
      let found = origValues.find(x => x.name === name);
      
      tmpAttrs.push({
        label: name,
        used,
        priority: attribute.priority,
        tooltip: attribute.description,
        spec: {
          name,
          ...attribute
        },
        value: found ? found.value : undefined,
        raw: found ? found.raw : undefined
      });
    }

    // this sort order should be kept in sync with embedded_utilities.js
    tmpAttrs.sort((a, b) => {
      let diff = (b.priority || 0) - (a.priority || 0);
      if (diff === 0) {
        return a.label.localeCompare(b.label);
      }
      return diff;
    });

    if (isNestedCommand) {
      if (type === 'remotedbselect' || type === 'remoteurl_load') {
        const commandName = type === 'remotedbselect' ? 'dbselect' : 'urlload';
        /** @type {string[]} */
        const invalids = CODE_BLOCK_INVALID_ATTRS[commandName];
        for (let i = 0; i < tmpAttrs.length; i++) {
          const currentAttr = tmpAttrs[i];
          if (!invalids.includes(currentAttr.label)) {
            continue;
          }
          tmpAttrs.splice(i, 1);
          i--;
        }
      } else if (['remoteurl_ping', 'remotedbinsert', 'remotedbdelete', 'remotedbupdate'].includes(type)) {
        // Mark instant setting on the sidechannel commands as readonly
        // (it is already previously set to true, so the user cannot change it now)
        const attribute = tmpAttrs.find(x => x.label === 'instant');
        if (attribute) {
          attribute.readonly = true;
        }
      }
    }
    attrs = attrs.concat(tmpAttrs);
  }

  let labelToId = (label) => 'attr_' + label.replace(/[^a-z]/g, '_');
  attrs.forEach(attr => attr.id = labelToId(attr.label));
  

  // we want to hold the order constant when adding items in the advanced section
  /** @type {Attribute[]}  */
  let normal = [],
    /** @type {Attribute[]}  */
    advanced = [];
  /**
   * If attributes have increased from previous set
   * like Site selector command which adds page, select
   * Then recalculate normal and advanced
   */
  if (attrOrder.current && attrOrder.current.attrsLength >= attrs.length) {
    // Note we need to filter as some deprecated/experimental things (like site xpath) can be 
    // removed completely from attrs on removal, leading to the found attribute to be undefined

    normal = attrOrder.current.normal.map(label => attrs.find(a => a.label === label)).filter(a => !!a);
    advanced = attrOrder.current.advanced.map(label => attrs.find(a => a.label === label)).filter(a => !!a);
  } else {
    normal = attrs.filter(attr => attr.priority > 1 || attr.used);
    advanced = attrs.filter(attr => attr.priority <= 1 && !attr.used);
    attrOrder.current = {
      normal: normal.map(x => x.label),
      advanced: advanced.map(x => x.label),
      attrsLength: attrs.length
    };
  }

  let values = origValues.slice();
  let hasPositional = false;
  if (spec.commandName === 'formmenu') {
    let menuOptions = [];

    // need special handling for formmenu
    for (let i = 0; i < values.length; i++) {
      let value = values[i],
        isPositional = value.positional;
      if (isPositional || value.name === 'default') {
        if (isPositional) {
          hasPositional = true;
        }
        menuOptions.push({
          value: value.raw,
          selected: value.name === 'default'
        });

        values.splice(i, 1);
        i--;
      }
    }
    const isValues = origValues.some(v => v.name === 'values');
    const showAsDefault = !hasPositional && isValues;
    normal.splice(normal.findIndex(x => x.label === 'default'), 1);
    if (menuOptions.length) {
      // @ts-ignore - special signal
      values.unshift({
        name: showAsDefault ? 'default' : 'options'
      });
    }

    normal.unshift({
      label: showAsDefault ? 'default' : 'options',
      used: !!menuOptions.length,
      priority: 10000,
      positional: true,
      spec: {
        type: 'string',
        isLegacyMenu: true,
        list_type: showAsDefault ? 'unselectable' : 'normal',
        required: !isValues,
        description: showAsDefault ? 'The default value for the menu' : 'The options for the menu',
        list: 'positional'
      },
      value: showAsDefault && !menuOptions.length ? [{ value: '', selected: true }] : menuOptions,
      raw: menuOptions.map(x => x.value).join(', '), // needed for command checking for advanced editor
      id: labelToId(showAsDefault ? 'default' : 'options')
    });
  }


  if (type === 'calc') {
    let name = origValues.find(v => v.name === 'name');
    if (name) {
      normal[0].spec.assigned_name = name.value;
    }
  }


  values.sort((a, b) => attrs.findIndex(attr => attr.label === a.name) - attrs.findIndex(attr => attr.label === b.name));


  let docsElement = null;
  if (spec.isAddon) {
    // when it's an addon, link to the addon page
    
    if (props.data.value.addon_id) {
      docsElement = <Link data-testid="docs-link" to={'/packs/' + props.data.value.addon_id}><HelpIcon fontSize="small" style={{ opacity: .6, verticalAlign: 'middle' }} /></Link>;
    }
  } else {
    // otherwise link to the docs site page

    let docsCommand = spec.commandName;
    if (docsCommand === '=') {
      docsCommand = 'formula';
    } else if (docsCommand === 'else' || docsCommand === 'elseif') {
      docsCommand = 'if';
    }
    if (docsCommand.startsWith('end')) {
      docsCommand = docsCommand.slice(3);
    };
    docsElement = <a data-testid="docs-link" href={'https://blaze.today/commands/' + docsCommand} target="_blank" rel="noopener noreferrer" ><HelpIcon fontSize="small" style={{ opacity: .6, verticalAlign: 'middle' }} /></a>;
  }

  if (['dbinsert', 'dbupdate', 'dbselect', 'dbdelete'].includes(spec.commandName)) {
    const querySection = normal[0];
    const spaceSection = normal[1];
    if (spaceSection.label === 'space' && querySection.label === 'query') {
      normal[0] = spaceSection;
      normal[1] = querySection;
    }
  }

  /**
   * 
   * @param {Attribute} attr 
   * @param {boolean} hasError 
   */
  const onAttributeError = (attr, hasError) => {
    const errorsSet = errors.current;
    if (hasError) {
      errorsSet.add(attr.label);
    } else {
      errorsSet.delete(attr.label);
    }
    props.onError?.(errorsSet);
  };

  return <div 
    style={{
      width: props.sidebar ? undefined : 290,
      minWidth: props.sidebar ? undefined : 290,
      resize: props.sidebar ? undefined : 'horizontal',
      backgroundColor: props.sidebar ? 'rgb(227, 242, 255, .08)' : undefined
    }}
    onMouseDown={props.onMouseDown}
  >
    <div
      style={{ 
        overflowY: props.sidebar ? undefined : 'scroll',
        maxHeight: props.sidebar ? undefined : (isPublicSpace() ? 180 : 320), // public space has less vertical space
      }}
    >
      <div style={{
        maxHeight: 400,
        overflow: 'auto'
      }}>
        {headerInfo.map((info, index) => {
          let normalizedIndex = index;
          if (normalizedIndex > 6) {
            normalizedIndex = 6;
          }
          return (
            <Box
              key={index}
              className="embedded-header"
              onClick={info.path !== undefined ? () => {
                props.onBack(info.path);
              } : null}
              sx={[{
                borderLeft: 'none',
                borderRight: 'none',
                borderTop: 'none',
                width: '100%',
                padding: 1.5,
                borderBottom: 'solid 1px #eee',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: props.sidebar ? 'rgb(227, 242, 255, .4)' : '#fcfcfc',
                opacity: (index + 1) / headerInfo.length,
                paddingLeft: 1.5 * (normalizedIndex + 1)
              }, !!info.path && {
                cursor: 'pointer',
                '&:hover': {
                  opacity: 1
                }
              }]}
            >
              <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                {props.sidebar && <div className="embedded-command-top-icon">{ICON_MAPPING[COMMAND_NAME_MAPPING[info.name]]}</div>}
                <T variant="subtitle2" style={{ display: 'inline-block', marginRight: 12 }}> 
                  {info.name === '=' ? 'formula' : info.name}</T>
              </div>
              {!info.path && docsElement}
            </Box>
          );
        })}
      </div>


      {normal.map((attr, i) => <EmbeddedAttributeSection
        readonly={props.readonly || attr.readonly}
        key={i}
        groupId={props.groupId}
        attribute={attr}
        index={i}
        equationContext={props.equationContext}
        onChange={props.onChange}
        data={props.data}
        errorOverride={errorOverride}
        commandType={type}
        values={values}
        onVariableNameChange={props.onVariableNameChange}
        getVariableCountInSnippet={props.getVariableCountInSnippet}
        onError={(hasError) => onAttributeError(attr, hasError)}
        insert={props.insert}
      />)}
      {(!showAdvanced && advanced.length) ? <div
        style={{
          textAlign: 'center',
          marginTop: 20,
          marginBottom: 20
        }}
      >
        <Button
          size="small"
          variant="outlined"
          onClick={() => setShowAdvanced(true)}
          startIcon={<ExpandIcon />}
        >Show advanced settings</Button>
      </div> : null}
      {showAdvanced && advanced.map((attr, i) => <EmbeddedAttributeSection
        readonly={props.readonly}
        key={i}
        groupId={props.groupId}
        attribute={attr}
        index={i}
        equationContext={props.equationContext}
        onChange={props.onChange}
        data={props.data}
        errorOverride={errorOverride}
        commandType={type}
        values={values}
        onVariableNameChange={props.onVariableNameChange}
        getVariableCountInSnippet={props.getVariableCountInSnippet}
        onError={(hasError) => onAttributeError(attr, hasError)}
      />)}
      {!normal.length && !advanced.length && !error && (
        <T
          sx={{
            textAlign: 'center',
            mt: 10,
            mb: 10
          }}
          variant="overline"
          component="p"
        >
          No settings to configure
        </T>
      )}
      {!!props.onSelectCommand && !!props.getSibilings && <CommandNavigator
        commandData={props.data}
        dataList={props.getSibilings()}
        onSelect={props.onSelectCommand}
      />}
    </div>
    {error && (
      <Alert
        severity="error"
        sx={{
          position: 'sticky',
          bottom: 0
        }}
      >
        {/** @type {string} */ (error)}
      </Alert>
    )}
  </div>;
}