import ParseNode from '../../snippet_processor/ParseNode';
import { getAuth, getIdToken } from '../../firebase_shared_driver';
import { sendToExtension, checkExtensionInstalled } from '../../extension';
import { isElectronApp, TABLES_BACKEND_DOMAIN } from '../../flags';
import { captureException } from '@sentry/browser';
import { PROPAGATE_ERROR, getDataFromClient } from '../../desktop_shared_utils';
import { objectToList } from '../../snippet_processor/Equation';
import { getSiteCommandResponseForDesktop, handleRemoteCommand } from '../../desktop_utilities';
import { parseURL } from '../../snippet_processor/DownstreamProcess';
import { fullAppName } from '../../raw_flags';

async function getClipboard() {
  // Default text to show
  let text = '[Clipboard Contents]';

  try {
    if (isElectronApp()) {
      // Get clipboard from the app
      let response = await getDataFromClient({
        type: 'clipboard-request'
      }, PROPAGATE_ERROR);
      if (response?.text !== undefined) {
        text = response.text;
      }
    } else {
      // Get the clipboard from the extension
      let response = await sendToExtension({ type: 'getClipboard' });

      if (response?.text !== undefined) {
        text = response.text;
      } else {
        try {
          // Fallback to regular readText
          // Note we don't do this first as it triggers a
          // {clipboard} permission prompt which is not visible
          // in the assistant
          text = await navigator.clipboard.readText();
        } catch (e) {
          // no permission
        }
      }
    }
  } catch (e) {
    captureException(e);
  }

  return new ParseNode('expand', 'text', text);
}

/**
 *
 * @param {Parameters<import('../../snippet_processor/DownstreamProcess').NativeGetSiteDataFn>[0]} item
 * @returns
 */
export function previewSelectorFn(item) {
  if (isElectronApp()) {
    return getSiteCommandResponseForDesktop();
  } else if (item.part === 'url') {
    return parseURL(window.location.href, item.urlPart);
  } else if (item.part === 'title') {
    return document.title;
  } else {

    if (item.selector || item.xpath) {
      try {
        if (item.multiple) {
          let arr;
          if (item.selector) {
            arr = Array.from(document.querySelectorAll(item.selector));
          } else {
            let matches = document.evaluate(item.xpath, document, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE);
            arr = [];
            for (let i = 0, length = matches.snapshotLength; i < length; i++) {
              arr.push(matches.snapshotItem(i));
            }
          }
          return arr.map(getData);
        } else {
          if (item.selector) {
            let i = getData(document.querySelector(item.selector));
            if (i === null) {
              return { error: `No match found for selector: "${item.selector}"` };
            } else {
              return i;
            }
          } else {
            let i = document.evaluate(item.xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE).singleNodeValue;
            if (i === null) {
              return { error: `No match found for XPath: "${item.xpath}"` };
            } else {
              return getData(i);
            }
          }
        }
      } catch (err) {
        if (item.selector) {
          return { error: 'Invalid selector "' + item.selector + '"' };
        } else {
          return { error: 'Invalid XPath "' + item.xpath + '"' };
        }
      }
    } else {
      return getData(document.documentElement);
    }
  }

  function getData(el) {
    if (!el) {
      return null;
    }
    if (item.part === 'text') {
      let nodeName = el.nodeName.toLowerCase();
      if (['input', 'textarea'].includes(nodeName)) {
        let type = el.getAttribute('type');
        // Don't want to get password values
        if (!type || type.toLowerCase() !== 'password') {
          return el.value;
        } else {
          return '';
        }
      } else if (nodeName === 'select') {
        return el.options[el.selectedIndex].text;
      }
      return el.innerText;
    }
    if (item.part === 'html') {
      return el.outerHTML;
    }
  }

}

/**
 * @param {Partial<Parameters<typeof import('./SnippetPreview').default>[0]>} props
 * @return {import('../../snippet_processor/DataContainer').Config}
 */
export function makeConfig(props) {
  return Object.assign(/** @type {import('../../snippet_processor/DataContainer').Config} */ ({
    clipboard: getClipboard,
    quickentry: props.quickentry,
    noAutoFocus: props.noAutoFocus,
    unsafe: props.unsafe,
    unsafeOverrides: props.unsafeOverrides,
    stage: 'preview',
    randomSeed: Math.random(),
    date: new Date(),
    doCommandsRan: {},
    domain: window.location.hostname,
    user: {
      email: 'user@example.com',
      role: 'member'
    },
    selectorFn: function (item) {
      return previewSelectorFn(item);
    },
  }), props.config || {});
}

/**
 * @param {ParseNode} node
 */
export async function snippetPreviewRemoteFn(node) {
  let command = node.info.type;
  if (command.startsWith('url_')) {
    // URLLOAD

    let res;
    if (await checkExtensionInstalled()) {
      res = await sendToExtension({
        type: 'remote',
        command: command,
        attributes: node.info
      });
    } else if (isElectronApp()) {
      // Call getData without passing token, we don't need token
      // in url* commands
      res = await handleRemoteCommand(node.info);
    } else {
      res = {
        data: fullAppName + ' must be installed to preview URL commands.',
        status: 'error'
      };
    }
    // We have replaced text with raw where objectToList is not already applied, but older extension can still have response with having text
    if (res?.raw) {
      res.text = objectToList(res.raw);
      delete res.raw;
    }
    return res;
  } else {
    return snippetPreviewRemoteDbFn(node, await getIdToken(getAuth().currentUser));
  }
}

/**
 * 
 * @param {number} status 
 * @param {Response['type']} type 
 * @returns {string}
 */
function getErrorMessage(status, type) {
  if (status === 429) {
    return 'Too many requests, please retry later.';
  } else if (type === 'error') {
    return 'Network error';
  } else {
    return 'Could not run SQL';
  }
}

/**
 *
 * @param {{info: import('../../snippet_processor/ParseNode').InfoType}} node
 * @param {string} token
 * @param {'text'|'page'=} clientType
 * @returns {Promise<{message: string, status: string}|any>}
 */
export async function snippetPreviewRemoteDbFn(node, token, clientType) {
  let body = {
    database_id: node.info.databaseId,
    query: node.info.query,
    parameters: node.info.parameters,
    folder_id: node.info.folderid
  };

  if (clientType) {
    body.client_type = clientType;
  }

  if ('multiple' in node.info) {
    body.single = !node.info.multiple && !node.info.menu;
  }

  if ('autoaddfields' in node.info) {
    body.auto_create_missing_fields = node.info.autoaddfields;
  }

  if ('set' in node.info) {
    body.set_values = node.info.set;
  }


  const headers = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers['Authorization'] = `FBBEARER ${token}`;
  }

  // DB Commands
  let req = await fetch(TABLES_BACKEND_DOMAIN + '/api/database/query/', {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  }).catch((e) => {
    console.warn('Fetch error:', e);
    return Response.error();
  });

  if (req.status !== 200) {
    return {
      'status': 'error',
      'message': getErrorMessage(req.status, req.type)
    };
  }

  return await req.json();
}
