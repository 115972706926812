import { processSnippetsFn, searchSnippetsOrPages } from './sort_snippets';
import { getSnippetPlainDelta } from './utilities';
import { captureException } from '@sentry/browser';
import { exportString } from '../../import_export/DeltaExport';
import { Bytes } from 'firebase/firestore';
import { decompressDelta } from '../../delta_proto/DeltaProto';

/**
 * @type {WeakMap<SnippetObjectType, string>}
 */
let snippetTextMap = new WeakMap();
/**
 * @param {SnippetObjectType} snippet 
 * @returns {string}
 */
export function snippetText(snippet) {
  if (!snippetTextMap.has(snippet)) {
    let delta = getSnippetPlainDelta(snippet);

    if (!delta) {
      if (snippet.content?.delta) {
        delta = decompressDelta(Bytes.prototype.toUint8Array.apply(snippet.content.delta));
      } else {
        captureException(new Error('Snippet delta not found: ' + snippet.id));
      }
    }
    snippetTextMap.set(snippet, exportString(delta, 'text').data);
  }

  return snippetTextMap.get(snippet);
}


const processSnippets = (snippets, groups) => processSnippetsFn(snippetText, snippets, groups);

/**
 * `query` is empty string when in AppMicro, in order to display list of most used snippets
 *
 * @param {SnippetObjectType[]} snippets List of all snippets that need to be searched
 * @param {string[]} priorities List of 30 most used snippet IDs
 * @param {string} query User query that was typed
 * @param {Object<string, GroupObjectType>} [groups] Mapping from group id to group object
 * @returns
 */
export function searchSnippets(snippets, priorities, query = '', groups = {}) {
  return searchSnippetsOrPages(snippets, priorities, processSnippets, query, groups);
}
