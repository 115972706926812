export function isTextEditor(type, isInputElement, isTextAreaElement, isContentEditable) {
  // We can't insert text on all InputElement types (e.g checkbox, date)
  const isTextInput = isInputElement && [
    'text',
    'password',
    'email',
    'number',
    'search',
    'tel',
    'url'
  ].includes(type);

  return isContentEditable || isTextAreaElement || isTextInput;
}

function createHotkeys() {
  let checkers = [];
  let documentListenerAttached = false;
  let keySequence = [];
  let timeoutId;

  function keyDownHandler(event) {
    if (!event.key) {
      return;
    }

    keySequence.push(event.key.toLowerCase());

    for (const checker of checkers) {
      checker(event);
    }
  }

  function destroy() {
    checkers = [];
    checkDocumentListener();
  }

  function checkDocumentListener() {
    if (checkers.length > 0) {
      if (!documentListenerAttached) {
        document.addEventListener('keydown', keyDownHandler, false);
        documentListenerAttached = true;
      }
    } else if (documentListenerAttached) {
      document.removeEventListener('keydown', keyDownHandler);
      documentListenerAttached = false;
    }
  }

  function createPatternChecker(pattern, fn) {
    /**
     * @type {string[][]}
     */
    const groups = pattern.toLowerCase().split(',').map(expr => (
      expr.split('+').map(key => key.trim())
    ));

    return (event) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const matched = groups.some(group => {
        const isMetaKeyHotkey = group[0] === 'cmd' || group[0] === 'ctrl';

        // when on a text field, allow only meta key hotkeys. Otherwise, we'd trigger them when the user is writing
        if (!isMetaKeyHotkey && isEventInsideTextEditor(event)) {
          return false;
        }

        // We check the given group in the trailing keys of the key sequence
        const startIndex = keySequence.length - group.length;
        
        return group.every((key, index) => {
          switch (key) {
          case 'cmd':
            return event.metaKey;
          case 'ctrl':
            return event.ctrlKey;
          case 'shift':
            return event.shiftKey;
          default:
            // To check if the required index is present in the key sequence array
            return startIndex + index >= 0 && startIndex + index < keySequence.length && key === keySequence[startIndex + index];
          }
        });
      });

      if (matched) {
        fn(event, [...keySequence]);
        keySequence = [];
      }

      timeoutId = setTimeout(() => {
        keySequence = [];
      }, 500);
    };
  }

  function isEventInsideTextEditor(event) {
    const target = event.target;
    if (!target) {
      return false;
    }

    return isTextEditor(
      target.type,
      target instanceof HTMLInputElement,
      target instanceof HTMLTextAreaElement,
      target.isContentEditable
    );
  }

  function listen(pattern, fn) {
    const checker = createPatternChecker(pattern, fn);

    checkers.push(checker);
    checkDocumentListener();

    return () => {
      checkers = checkers.filter(f => f !== checker);
      checkDocumentListener();
    };
  }

  return { destroy, listen };
}
const hotkeys = createHotkeys();
export default hotkeys;