import Quill from 'quill';
import TableBody from '../formats/tableBody';
import { generateCellMap, groupCells } from '../formats/util';
import { getRelativeRect } from '../utils';
const ERROR_LIMIT = 5;

const MENU_ACTIONS = {
  insertColumnRight: {
    text: 'Insert column right',
    iconSrc: 'insert_column_right',
    handler() {
      const tableContainer = getTableContainer(this.table);
      const newColumn = tableContainer.insertColumn(
        this.selectedTds,
        true,
        this.quill.root.parentNode);

      this.tableColumnTool.updateToolCells();
      this.quill.update(Quill.sources.USER);
      this.quill.setSelection(
        this.quill.getIndex(newColumn[0]),
        0,
        Quill.sources.SILENT
      );
      this.tableSelection.setSelection(
        newColumn[0].domNode.getBoundingClientRect(),
        newColumn[0].domNode.getBoundingClientRect()
      );
    }
  },

  insertColumnLeft: {
    text: 'Insert column left',
    iconSrc: 'insert_column_left',
    handler() {
      const tableContainer = getTableContainer(this.table);
      const newColumn = tableContainer.insertColumn(
        this.selectedTds,
        false,
        this.quill.root.parentNode);

      this.tableColumnTool.updateToolCells();
      this.quill.update(Quill.sources.USER);
      this.quill.setSelection(
        this.quill.getIndex(newColumn[0]),
        0,
        Quill.sources.SILENT
      );
      this.tableSelection.setSelection(
        newColumn[0].domNode.getBoundingClientRect(),
        newColumn[0].domNode.getBoundingClientRect()
      );
    }
  },

  insertRowUp: {
    text: 'Insert row up',
    iconSrc: 'insert_row_up',
    handler() {
      const tableContainer = getTableContainer(this.table);
      const affectedCells = tableContainer.insertRow(
        this.tableSelection.boundary,
        false,
        this.quill.root.parentNode
      );
      this.quill.update(Quill.sources.USER);
      this.quill.setSelection(
        this.quill.getIndex(affectedCells[0]),
        0,
        Quill.sources.SILENT
      );
      this.tableSelection.selectCell(
        affectedCells[0]
      );
    }
  },

  insertRowDown: {
    text: 'Insert row down',
    iconSrc: 'insert_row_down',
    handler() {
      const tableContainer = getTableContainer(this.table);
      const affectedCells = tableContainer.insertRow(
        this.tableSelection.boundary,
        true,
        this.quill.root.parentNode
      );
      this.quill.update(Quill.sources.USER);
      this.quill.setSelection(
        this.quill.getIndex(affectedCells[0]),
        0,
        Quill.sources.SILENT
      );
      this.tableSelection.selectCell(
        affectedCells[0]
      );
    }
  },

  mergeCells: {
    text: 'Merge selected cells',
    iconSrc: 'merge_cells',
    handler() {
      const tableContainer = getTableContainer(this.table);
      const [body] = tableContainer.descendants(TableBody);
      const cellMap = generateCellMap(body);
      const selectedTdsInRows = groupCells(this.selectedTds, cellMap);
      let colspan = 0;
      const firstRowIndexInSelection = Object.keys(selectedTdsInRows)[0];
      const firstRowInSelection = selectedTdsInRows[firstRowIndexInSelection];
      cellMap[firstRowIndexInSelection].forEach(cell => {
        if (firstRowInSelection.includes(cell)) {
          colspan++;
        }
      });

      let rowspan = 0;

      for (let rowIndex = 0; rowIndex < cellMap.length; rowIndex++) {
        const cellsInRow = cellMap[rowIndex];
        const selectedTdsInRow = selectedTdsInRows[rowIndex];
        if (!selectedTdsInRow) {
          continue;
        }
        const cell = selectedTdsInRow[0];
        let column = cellsInRow.indexOf(cell);
        let nextCell;
        rowspan++;
        while (rowIndex + 1 < cellMap.length) {
          nextCell = cellMap[rowIndex + 1][column];
          if (nextCell !== cell) {
            break;
          }
          rowspan++;
          rowIndex++;
        }
        
      }

      const mergedCell = tableContainer.mergeCells(
        this.selectedTds,
        rowspan,
        colspan
      );
      this.quill.update(Quill.sources.USER);
      this.tableSelection.selectCell(
        mergedCell
      );
    }
  },

  unmergeCells: {
    text: 'Unmerge cells',
    iconSrc: 'unmerge_cells',
    handler() {
      const tableContainer = getTableContainer(this.table);
      tableContainer.unmergeCells(
        this.selectedTds,
        this.quill.root.parentNode
      );
      this.quill.update(Quill.sources.USER);
      this.tableSelection.clearSelection();
    }
  },

  deleteColumn: {
    text: 'Delete selected columns',
    iconSrc: 'delete_columns',
    handler() {
      const tableContainer = getTableContainer(this.table);
      const [body] = tableContainer.descendants(TableBody);
      const cellMap = generateCellMap(body);
      let selectedTdsInRows = groupCells(this.selectedTds, cellMap);
      const rowIndex = Object.keys(selectedTdsInRows)[0];
      const allCellsInRow = cellMap[rowIndex];
      let colIndexes = [];
      selectedTdsInRows[rowIndex].forEach((cell) => {
        for (let index = 0; index < allCellsInRow.length; index++) {
          const cellInRow = allCellsInRow[index];
          if (cellInRow === cell) {
            colIndexes.push(index);
          }
        }
      });
      let isDeleteTable = tableContainer.deleteColumns(
        this.tableSelection.boundary,
        colIndexes,
        this.quill.root.parentNode
      );
      if (!isDeleteTable) {
        this.tableColumnTool.updateToolCells();
        this.quill.update(Quill.sources.USER);
        this.tableSelection.clearSelection();
      }
    }
  },

  deleteRow: {
    text: 'Delete selected rows',
    iconSrc: 'delete_rows',
    handler() {
      const tableContainer = getTableContainer(this.table);
      tableContainer.deleteRow(
        this.tableSelection.boundary,
        this.quill.root.parentNode
      );
      this.quill.update(Quill.sources.USER);
      this.tableSelection.clearSelection();
    }
  },

  deleteTable: {
    text: 'Delete table',
    iconSrc: 'delete_table',
    handler() {
      const betterTableModule = this.quill.getModule('better-table');
      const tableContainer = getTableContainer(this.table);
      betterTableModule.hideTableTools();
      tableContainer.remove();
      this.quill.update(Quill.sources.USER);
    }
  },

  color: {
    text: 'Background Colors',
    colors: ['white', 'red', 'yellow', 'blue'],
    handler(color) {
      const self = this;
      const selectedTds = self.tableSelection.selectedTds;
      if (selectedTds && selectedTds.length > 0) {
        selectedTds.forEach(tableCell => {
          tableCell.format('backgroundColor', color);
        });
      }
    }
  },

  border: {
    text: 'Borders',
    colors: ['white', 'red', 'yellow', 'blue'],
    widths: ['1px', '2px', '3px'],
    styles: ['solid', 'dashed', 'dotted'],
    handler(styles) {
      const context = {};
      const selectedTds = this.tableSelection.selectedTds;
      let corners = null;
      if (!Array.isArray(styles)) {
        styles = [styles];
      }
      for (let styleIndex = 0; styleIndex < styles.length; styleIndex++) {
        const style = styles[styleIndex];
        let {
          side,
          type,
          value
        } = style;
        type = type[0].toUpperCase() + type.substr(1);
        const isall = side === 'all';
        if (['all', 'top', 'right', 'bottom', 'left'].includes(side)) {
          for (let tdIndex = 0; tdIndex < selectedTds.length; tdIndex++) {
            const tableCell = selectedTds[tdIndex];

            if (isall || side === 'left') {
              tableCell.format(`borderLeft${type}`, value, context);
            }
            if (isall || side === 'right') {
              tableCell.format(`borderRight${type}`, value, context);
            }
            if (isall || side === 'top') {
              tableCell.format(`borderTop${type}`, value, context);
            }
            if (isall || side === 'bottom') {
              tableCell.format(`borderBottom${type}`, value, context);
            }
          }
        }
        if (['corners', 'inside'].includes(side) && corners === null) {
          corners = {
            top: [],
            right: [],
            bottom: [],
            left: []
          };
          const compareRect = this.tableSelection.boundary;
          for (let index = 0; index < selectedTds.length; index++) {
            const cell = selectedTds[index];
            const cellRect = getRelativeRect(
              cell.domNode.getBoundingClientRect(),
              this.quill.root.parentNode
            );

            if (Math.abs(cellRect.x1 - compareRect.x1) < ERROR_LIMIT) {
            // the right of selected boundary equal to the right of table cell,
            // add a new table cell right aside this table cell
              corners.right.push(cell);
            }
            if (Math.abs(cellRect.x - compareRect.x) < ERROR_LIMIT) {
            // left of selected boundary equal to left of table cell,
            // add a new table cell left aside this table cell
              corners.left.push(cell);
            }
            if (Math.abs(cellRect.y1 - compareRect.y1) < ERROR_LIMIT) {
              corners.bottom.push(cell);
            }
            if (Math.abs(cellRect.y - compareRect.y) < ERROR_LIMIT) {
              corners.top.push(cell);
            }
          }
        }
        if (side === 'corners') {
          corners.top.forEach(cell => cell.format(`borderTop${type}`, value, context));
          corners.bottom.forEach(cell => cell.format(`borderBottom${type}`, value, context));
          corners.left.forEach(cell => cell.format(`borderLeft${type}`, value, context));
          corners.right.forEach(cell => cell.format(`borderRight${type}`, value, context));
        }

        if (side === 'inside') {

          for (let tdIndex = 0; tdIndex < selectedTds.length; tdIndex++) {
            const tableCell = selectedTds[tdIndex];
            if (!corners.left.includes(tableCell)) {
              tableCell.format(`borderLeft${type}`, value, context);
            }
            if (!corners.right.includes(tableCell)) {
              tableCell.format(`borderRight${type}`, value, context);
            }
            if (!corners.top.includes(tableCell)) {
              tableCell.format(`borderTop${type}`, value, context);
            }
            if (!corners.bottom.includes(tableCell)) {
              tableCell.format(`borderBottom${type}`, value, context);
            }
          }
        }

      }
    }
  },

  align: {
    text: 'Table alignment',
    handler(align) {
      const tableContainer = getTableContainer(this.table);

      tableContainer.format('align', align);
      this.tableColumnTool.repositionColTools();
      this.tableSelection.clearSelection();
    }
  },

  autoWidth: {
    text: 'Auto width',
    handler () {
      const self = this;
      const tableContainer = getTableContainer(self.table);
      const colGroup = tableContainer.colGroup();
      colGroup.children.forEach(col => {
        col.format('width', 'auto');
      });
      tableContainer.updateTableWidth();
      setTimeout(() => {
        self.tableColumnTool.updateToolCells();
      }, 100);
    }
  },
  fixedWidth: {
    text: 'Fixed width',
    handler(width) {
      const self = this;
      const tableContainer = getTableContainer(self.table);
      const tableBody = tableContainer.children.tail;
      const colGroup = tableContainer.colGroup();
      for (let index = 0; index < colGroup.children.length; index++) {
        const col = colGroup.children.at(index);
        let colWidth;
        let rowIndex = 0;
        while (!colWidth && rowIndex < tableBody.children.length) {
          const row = tableBody.children.at(rowIndex);
          let cellCursor = 0;
          let cell = row.children.head;
          while (cellCursor < index && cell.next) {
            cell = cell.next;
            cellCursor += cell.colspan() || 1;
          }
          if (cell && cell.colspan() === 1) {
            colWidth = cell.domNode.clientWidth;
          }
          rowIndex++;
          
        }

        col.format('width', colWidth);
      };
      tableContainer.updateTableWidth();
      setTimeout(() => {
        self.tableColumnTool.updateToolCells();
      }, 100);
      
    }
  },


  insertLineBefore: {
    text: 'Insert a line (before)',
    handler() {
      const self = this,
        quill = self.quill;
      const tableContainer = getTableContainer(self.table);
      const viewWrapper = tableContainer.parent;
      const block = quill.scroll.create('block');
      viewWrapper.parent.insertBefore(block, viewWrapper);

      const betterTableModule = quill.getModule('better-table');
      betterTableModule.hideTableTools();
      setTimeout(() => {
        quill.setSelection(block.offset(quill.scroll), 0, Quill.sources.USER);
      }, 10);

    }
  },

  insertLineAfter: {
    text: 'Insert a line (after)',
    handler() {
      const self = this,
        quill = self.quill;
      const tableContainer = getTableContainer(self.table);
      const viewWrapper = tableContainer.parent;
      const block = quill.scroll.create('block');
      viewWrapper.parent.insertBefore(block, viewWrapper.next || null);


      const betterTableModule = quill.getModule('better-table');
      betterTableModule.hideTableTools();
      setTimeout(() => {
        quill.setSelection(block.offset(quill.scroll), 0, Quill.sources.USER);
      }, 10);
    }
  },

  halign: {
    text: 'Horizontal align',
    handler(value) {
      const self = this,
        quill = self.quill;
      const betterTableModule = quill.getModule('better-table');
      betterTableModule.format('align', value === 'left' ? '' : value);
    }
  },

  valign: {
    text: 'Vertical align',
    handler(value) {
      this.selectedTds.forEach((td) => {
        td.format('verticalAlign', value);
      });
    }
  }
};


export default class TableOperationActions {
  constructor(params, quill) {
    const betterTableModule = quill.getModule('better-table');
    this.tableSelection = betterTableModule.tableSelection;
    this.table = params.table;
    this.quill = quill;
    this.tableColumnTool = betterTableModule.columnTool;
    this.selectedTds = this.tableSelection.selectedTds;
  }

  actions(overrides) {
    /**
     * @type {typeof MENU_ACTIONS}
     */
    const actions = Object.assign({}, MENU_ACTIONS, overrides);

    for (let name in actions) {
      if (!actions[name]) {
        return;
      }
      const action = actions[name] = Object.assign({}, MENU_ACTIONS[name], overrides[name]);
      action.handler = action.handler.bind(this);
    }

    return actions;

  }

}

/**
 * 
 * @param {HTMLElement} el 
 */
const getTableContainer = (el) => {
  return /** @type {import('../formats/tableContainer').default} */ (Quill.find(el));
};
