import React, { useState } from 'react';
import Callout from '../Callout/Callout';
import { useTypedSelector } from '../../hooks';
import T from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import { sendToExtension } from '../../extension';
import { toast } from '../../message';
import { log } from '../../logging/logging';

export function ExtensionIssueAlert() {
  // Do not show this message when user signs in through auth component
  const isOutOfSync = useTypedSelector(store => store.uiState.isOutOfSync && store.userState.isLoaded && !store.userState.isReloginPersisted);
  const isAuthStuck = useTypedSelector(store => store.uiState.isExtAuthStuck);
  const [show, setShow] = useState(true);
  if (!show) {
    return;
  }
  if (!isOutOfSync && !isAuthStuck) {
    // No issue exists
    return;
  }
  const errorMessage = isAuthStuck ? 'Text Blaze extension could not load' : 'Text Blaze extension may have out of date snippets';
  return <Callout
    warning
  >
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <T variant="body2">{errorMessage}</T>
      <Button
        size="small"
        variant="contained"
        style={{
          margin: '0 12px'
        }}
        onClick={() => {
          toast('Reloading extension. It may take up to 30 seconds for the new snippets to appear.', {
            duration: 6000,
            intent: 'info',
          });

          log({
            action: isOutOfSync ? 'Extension out of sync reload' : 'Extension auth stuck reload',
          });
          sendToExtension({ type: 'reload' });

          setShow(false);
        }}
      >
        Reload the extension
      </Button>
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          setShow(false);
        }}>Dismiss</Button>
    </Box>
  </Callout>;
}