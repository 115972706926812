import { CURRENT_PLATFORM } from '../../flags';

/**
 * @type {{ code: string, name: string, platforms?: SupportedPlatforms }[]}
 */
const standardKeys = [
  { code: 'tab', name: 'Tab' },
  { code: 'enter', name: 'Enter' },
  { code: 'space', name: 'Space' },
  { code: 'backspace', name: 'Backspace' },
  { code: 'leftarrow', name: 'Left arrow' },
  { code: 'rightarrow', name: 'Right arrow' },
  { code: 'uparrow', name: 'Up arrow', platforms: { windows: true, mac: true } },
  { code: 'downarrow', name: 'Down arrow', platforms: { windows: true, mac: true } }
].filter(key => {
  return !key.platforms || key.platforms[CURRENT_PLATFORM];
});

/**
 * @type {string[]}
 */
const functionKeys = [
  'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'
];

export { standardKeys, functionKeys };