import React, { useRef, useState } from 'react';
import T from '@mui/material/Typography';
import Shortcut from '../Shortcut/Shortcut';
import AsyncButton from '../AsyncButton/AsyncButton';
import NewIcon from '@mui/icons-material/AddCircle';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { hasUserInsertedAnySnippet, isElectronApp, isMacPlatform, isPartOfNewGuidedOnboardingExperiment, memberRestricted } from '../../flags';
import NewSnippet from '../GroupSelectors/NewSnippet';
import { useTypedSelectorShallowEquals } from '../../hooks';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import TryMe from './TryMe';
import { useHistory } from 'react-router-dom';
import TryMeNew from './TryMeNew';
import { useDesktopClientSupportsFeature } from '../../desktop_hooks';
import { isAiBlaze } from '../../aiBlaze';
import { fullAppName } from '../../raw_flags';


export default function Scratch(_props) {
  const { push: navigate } = useHistory();
  let [placeholderTypingIndex, setPlaceholderTypingIndex] = useState(0);
  let firstLoadedGroupRef = useRef(/** @type {GroupObjectType} */ (null));
  const [showOnboardingLastStep, setShowLastStep] = useState(false);
  const isNewOnboardingApp = useDesktopClientSupportsFeature('new-try-it-onboarding');

  let {
    settingsLoaded,
    loaded,
    group,
    enableCreate,
    hasInserted,
    isNewOnboarding,
    hasQuestOpen,
  } = useTypedSelectorShallowEquals((store) => {
    const quest = store.userState?.quest;
    const hasQuestOpen = quest && quest.mode === 'walkthrough' && !quest.dismissed;

    let settingsLoaded = store.userState && store.userState.settingsLoaded;
    let userGroups = store.userState.groups;
    let dataGroups = store.dataState.groups;
    let firstGroupLoading = false;

    function detectFirstLoadedGroup() {
      let found = null;
      let loading = false;

      let enabledGroups = [];
      for (let key in userGroups) {
        if (!userGroups[key].disabled) {
          enabledGroups.push(Object.assign({ id: key }, userGroups[key]));
        }
      }

      for (let group of enabledGroups) {
        let g = dataGroups && dataGroups[group.id];
        if (g && g.options && g.options.addon) {
          continue;
        }
        if (!g || g.loading) {
          loading = true;
        } else {
          loading = false;
          found = g;
          break;
        }
      }

      firstLoadedGroupRef.current = found;
      return { loading };
    }

    if (settingsLoaded) {
      let group = firstLoadedGroupRef.current;
      if (group) {
        let id = group.id;
        if (!userGroups || !userGroups[id] || userGroups[id].disabled ||
          !dataGroups[id] || (dataGroups[id].options && dataGroups[id].options.addon)
        ) {
          detectFirstLoadedGroup();
        } else if (dataGroups[id] !== group) {
          firstLoadedGroupRef.current = dataGroups[id];
        }
      } else {
        firstGroupLoading = detectFirstLoadedGroup().loading;
      }
    }

    const supportsOnboarding = isElectronApp() ? isNewOnboardingApp : true;
    const isNewOnboarding = supportsOnboarding && isPartOfNewGuidedOnboardingExperiment(store);

    return {
      settingsLoaded,
      loaded: settingsLoaded && !firstGroupLoading,
      group: firstLoadedGroupRef.current,
      enableCreate: !memberRestricted(store, 'create'),
      hasInserted: hasUserInsertedAnySnippet(store),
      isNewOnboarding,
      hasQuestOpen,
    };
  });

  function shortcut(txt) {
    return <Shortcut shortcut={txt}/>;
  }

  function snippetSuggestions() {
    if (group && group.snippets && group.snippets.length) {
      const snippets = group.snippets;
      return <T paragraph style={{ lineHeight: '1.7em' }}>{
        !hasInserted
          ? 'Type'
          : 'You can try out your snippets below. Try typing'
      } the shortcut {shortcut(snippets[0].shortcut)} {
        snippets.length > 1 && snippets[0].shortcut !== snippets[1].shortcut
          // For new users who inserted 0 snippets so far, only showing the first shortcut to reduce confusion from more choices
          && hasInserted ? <span>or the shortcut {shortcut(snippets[1].shortcut)}</span>
          : null
      } below. {
        !hasInserted && !isAiBlaze && 'It will instantly be replaced by the snippet text.'
      }</T>;
    }
  }

  function renderInner() {
    const snippet = group && group.snippets && group.snippets.length && group.snippets[placeholderTypingIndex];
    const showOnboarding = isNewOnboarding && (!hasInserted || placeholderTypingIndex === 1 || showOnboardingLastStep);

    return <>
      {group && <>
        {snippetSuggestions()}

        {isNewOnboarding ? <TryMeNew
          showOnboarding={showOnboarding}
          shortcut={showOnboarding && snippet ? snippet.shortcut : null}
          isSecondAttempt={placeholderTypingIndex === 1}
          onShortcutTrigger={() => {
            if (placeholderTypingIndex <= 1) {
              // For a new user, we're assuming the first loaded group
              // is the default group which has at least five snippets
              // So the snippet at index 1 will certainly exist
              setPlaceholderTypingIndex(v => v + 1);
            }
            const showLastStep = (placeholderTypingIndex === 1);
            if (showLastStep) {
              setShowLastStep(true);
              setTimeout(() => {
                setShowLastStep(false);
              }, 2000);
            }
            return showLastStep;
          }}
          disablePulsing={hasQuestOpen}
        /> : <TryMe
          hasInserted={hasInserted}
          onTypingDone={() => {
            setPlaceholderTypingIndex((placeholderTypingIndex + 1) % group.snippets.length);
          }}
          typistKey={hasInserted ? null : snippet?.id}
          shortcut={hasInserted ? null : snippet?.shortcut}
        />}
      </>}

      {!isAiBlaze && <T paragraph>Your shortcuts insert

        <Tooltip
          title={<div style={{ padding: 8 }}>
            {isAiBlaze
              ? <T variant="body2" paragraph style={{ fontWeight: 500 }}>An "AI snippet" lets you use AI to insert messages, or polish your existing text when a shortcut is typed.</T>
              : <T variant="body2" paragraph style={{ fontWeight: 500 }}>A "snippet" is a short or long piece of text inserted when a shortcut is typed.</T>
            }
            <T variant="body2" paragraph style={{ fontWeight: 500 }}>You can use snippets for things you type repeatedly or common messages you send.</T>
            <T variant="body2" style={{ fontWeight: 500 }}>You can also create dynamic snippets. For example, you can include the current date or the contents of your clipboard in a snippet.</T>
          </div>}
          placement="top"
          arrow
        >
          <span style={{ borderBottom: 'dashed 1px #999' }}>{isAiBlaze ? ' AI' : ''} snippets <HelpIcon fontSize="small" style={{ position: 'relative', top: 3, left: -2, color: '#999', zoom: '85%' }} /></span>
        </Tooltip>

        {isElectronApp() ? 'and will work in any application. You can also left-click on the ' + (isMacPlatform() ? 'menubar' : 'systray') + ' icon to select a snippet from the Assistant.' : 'and will work on any website. You can also right-click on text boxes to select a snippet from the context menu.'} </T>}
      {enableCreate && <T style={{ marginTop: 30 }}>Create a new {isAiBlaze ? 'AI prompt' : 'snippet'} now&nbsp;&nbsp;&nbsp;<NewSnippet component={(ref, createSnippetFn) => {
        return <AsyncButton
          ref={ref}
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<NewIcon />}
          onClick={(done) =>
            createSnippetFn({
              name: isAiBlaze ? 'New AI prompt' : 'New Snippet',
              type: 'text',
              options: isAiBlaze
                ? /** @type {SnippetObjectType['options']} */ ({
                  is_ai: true,
                  include_page_context: true,
                  ai_action: {
                    updated_at: Date.now(),
                    action: 'polish',
                  },
                  // TODO: remove polish_mode on extension update
                  polish_mode: true
                })
                : {}
            }).then(data => {
              navigate('/snippet/' + data.snippet_id);
              return data;
            }).then(done).catch(() => done())
          }>
          {`New ${isAiBlaze ? 'AI prompt' : 'snippet'}`}
        </AsyncButton>;
      }} /></T>}
    </>;
  }


  return <div style={{ position: 'relative' }}>
    {settingsLoaded ? <>
      {!isAiBlaze && <T variant="h4" paragraph>Welcome to {fullAppName}</T>}
      {loaded ? renderInner() : (
        <>
          <div style={{ width: '80%' }}><Skeleton /></div>
          <div style={{ width: '70%' }}><Skeleton /></div>
        </>
      )}
    </> : null}
  </div>;
}