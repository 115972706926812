import React, { useState, useEffect, useContext } from 'react';
import ConnectedIcon from '@mui/icons-material/CloudOutlined';
import { useIsMounted, useTypedSelectorShallowEquals } from '../../hooks';
import ConnectedGroupDialog from './ConnectedGroupDialog';
import { isOrgOwner, orgPref } from '../../flags';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import T from '@mui/material/Typography';
import InfoChicklet from '../InfoChicklet/InfoChicklet';
import getDatabaseQueries from '../Utilities/getDatabaseQueries';
import ConnectedSettingsContext from './ConnectedSettingsContext';
import { connectedGroupContextValue } from './group_utils';

/**
 * @param {object} props
 * @param {string} props.groupId
 * @param {function} props.groupUpdateFn
 * @param {boolean} props.isOwner
 * @param {boolean} props.shareBlocksConnect
 */
function ConnectedGroupButtonMain(props) {
  let {
    loaded,
    group,
  } = useTypedSelectorShallowEquals((store) => {
    const group = store.dataState.groups[props.groupId];
    if (!group) {
      return { loaded: false };
    }

    return {
      loaded: true,
      group,
    };
  });

  return <ConnectedSettingsContext.Provider value={connectedGroupContextValue}>
    <ConnectedGroupButtonBase
      {...props}
      loaded={loaded}
      settings={group?.connected}
      snippets={group?.snippets}
      getDatabaseQueriesFn={getDatabaseQueries}
    />
  </ConnectedSettingsContext.Provider>;
}

/**
 * @param {object} props
 * @param {string} props.groupId
 * @param {function} props.groupUpdateFn
 * @param {boolean} props.isOwner
 * @param {boolean} props.shareBlocksConnect
 * @param {boolean} props.loaded
 * @param {SnippetObjectType[]} props.snippets
 * @param {ConnectedSettingsType} props.settings
 * @param {(snippets: SnippetObjectType[]|PageObjectType[]) => Promise<import("../../components/Utilities/getDatabaseQueries").ConnectedAttributes>} props.getDatabaseQueriesFn
 */
export function ConnectedGroupButtonBase(props) {
  const { loaded, snippets, settings, getDatabaseQueriesFn } = props;
  const { push: navigate } = useHistory();
  let isMounted = useIsMounted();
  const { pathname } = useLocation();
  const { groupType, title } = useContext(ConnectedSettingsContext);

  let baseUrl = `/${groupType}/${props.groupId}`;
  let showConnected = pathname === baseUrl + '/connected';

  let [attempted, setAttempted] = useState({
    pingHosts: [],
    loadHosts: [],
    connectedAddons: [],
    databaseQueries: {}
  });

  const isConnected = settings?.is_connected;
  let {
    orgLimitsConnectedEdit,
    emailVerified
  } = useTypedSelectorShallowEquals((store) => {
    return {
      emailVerified: store.userState.emailVerified,
      orgLimitsConnectedEdit: !isOrgOwner(store) && orgPref(store, 'userConnectedDisabled', false, false)
    };
  });


  useEffect(() => {
    if (!loaded || !showConnected) {
      return;
    }

    (async function() {
      const { pingHosts, loadHosts, connectedAddons, databaseQueries } = await getDatabaseQueriesFn(snippets);

      if (!isMounted.current) {
        return;
      }
      let attemptedToSet = {
        pingHosts,
        loadHosts,
        connectedAddons,
        databaseQueries
      };
      setAttempted((prev) => {
        // if any new object has data.
        if (pingHosts.length
            || loadHosts.length
            || connectedAddons.length
            || Object.keys(databaseQueries).length) {
          return attemptedToSet;
        }

        // if prev object has data and new object has no data.
        if (prev.pingHosts.length
            || prev.loadHosts.length
            || prev.connectedAddons.length
            || Object.keys(prev.databaseQueries).length) {
          return attemptedToSet;
        }
        return prev;
      });
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConnected, loaded, props.groupId, snippets && snippets.length]);


  /** @type {'NOT_OWNER'|'ORG_LIMITS'|'SHARED'|'NOT_VERIFIED'} */
  let editBlockedReason = null;
  if (!props.isOwner) {
    editBlockedReason = 'NOT_OWNER';
  } else if (orgLimitsConnectedEdit) {
    if (!isConnected) {
      // Don't even show the button, if we aren't connected
      // and were org_limits'ed
      return null;
    }
    editBlockedReason = 'ORG_LIMITS';
  } else if (props.shareBlocksConnect) {
    editBlockedReason = 'SHARED';
  } else if (!emailVerified) {
    editBlockedReason = 'NOT_VERIFIED';
  }

  if (!(isConnected
      || attempted.pingHosts.length
      || attempted.loadHosts.length
      || attempted.connectedAddons.length
      || Object.keys(attempted.databaseQueries).length)) {
    return null;
  }

  return (
    <div>
      <div>
        <T variant="subtitle2" style={{ display: 'inline-block' }}>Connected {title} settings</T>
        <T variant="caption" style={{ display: 'inline-block', marginLeft: 2 }}><a href="https://blaze.today/connected" target="_blank" rel="noopener noreferrer">
          <InfoChicklet large style={{ verticalAlign: 'middle' }}>
            <T variant="body2">Learn more</T>
          </InfoChicklet></a>
        </T>
      </div>
      <Button
        size="small"
        onClick={() => {
          navigate(baseUrl + '/connected');
        }}
        startIcon={<ConnectedIcon />}
      >
          Configure
      </Button>



      {showConnected && <ConnectedGroupDialog
        settings={settings}
        editBlockedReason={editBlockedReason}
        attemptedLoadHosts={attempted.loadHosts}
        attemptedPingHosts={attempted.pingHosts}
        attemptedConnectedAddons={attempted.connectedAddons}
        attemptedDatabaseQueries={attempted.databaseQueries}
        onClose={() => navigate(baseUrl)}
        onSave={(connectedSettings) => {
          props.groupUpdateFn({ connected: connectedSettings });
          navigate(baseUrl);
        }}
        groupId={props.groupId}
      />}
    </div>);
}


const ConnectedGroupButton = React.memo(ConnectedGroupButtonMain);
export default ConnectedGroupButton;