export const fontSizes = [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]; // pt
// legacy pixel sizes, we've now switched to pt sizes to align with
// word processors like Google Docs
export const fontPixelSizes = [8, 9, 10, 12, 16, 24, 36, 48, 60, 72, 96]; 

export const fonts = [
  {
    name: 'Verdana',
    family: 'Verdana, Geneva, sans-serif'
  }, {
    name: 'Lucida Sans',
    family: '\'Lucida Sans Unicode\', \'Lucida Grande\', sans-serif'
  }, {
    name: 'Tahoma',
    family: 'Tahoma, Geneva, sans-serif'
  },{
    name: 'Trebuchet',
    family: '\'Trebuchet MS\', Helvetica, sans-serif'
  }, {
    name: 'Times New Roman',
    family: '\'Times New Roman\', Times, serif'
  },  {
    name: 'Palatino',
    family: '\'Palatino Linotype\', \'Book Antiqua\', Palatino, serif'
  }, {
    name: 'Georgia',
    family: 'Georgia, serif'
  }, {
    name: 'Arial',
    family: 'Arial, Helvetica, sans-serif'
  }, {
    name: 'Arial Narrow',
    family: '\'Arial Narrow\', sans-serif'
  }, {
    name: 'Comic Sans',
    family: '\'Comic Sans MS\', cursive'
  }, {
    name: 'Century Gothic',
    family: 'Century Gothic, sans-serif'
  }, {
    name: 'Impact',
    family: 'Impact, Charcoal, sans-serif'
  }, {
    name: 'Courier New',
    family: '\'Courier New\', Courier, monospace'
  }, {
    name: 'Lucida Console',
    family: '\'Lucida Console\', Monaco, monospace'
  }, 
];

fonts.forEach(x => x.class = x.name.toLowerCase().replace(/ /g, '_'));

export function registerWithQuill(Quill) {
  let sizeStyle = Quill.import('attributors/class/size');
  sizeStyle.whitelist = ['small', 'large', 'normal', 'huge', ...fontSizes.map(x => `${x}pt`),...fontPixelSizes.map(x => `${x}px`)];
  Quill.register(sizeStyle, true);

  let fontStyle = Quill.import('formats/font');
  fontStyle.whitelist = ['serif', 'monospace', ...fonts.map(x => `${x.class}`)];
  Quill.register(fontStyle, true);

}

export const fontStyle = (
  fontSizes.map(x => `.ql-size-${x}pt {font-size: ${x}pt}`).join('\n')
  + '\n' +
  fontPixelSizes.map(x => `.ql-size-${x}px {font-size: ${x}px}`).join('\n')
  + '\n' +
  fonts.map(x => `
  .ql-font-${x.class}, .ql-picker-item[data-value=${x.class}]::before  {
    font-family: ${x.family};
  }
  `).join('\n'));