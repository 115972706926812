import React, { useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip, Collapse
} from '@mui/material';

import PersonAddIcon from '@mui/icons-material/PersonAddAlt';
import CloseIcon from '@mui/icons-material/Close';

import LinkIcon from '@mui/icons-material/Link';
import { listPermissions } from '../PermissionList/utilities';
import { firebaseEmail } from '@store';
import { sendShare } from '../../bapi';
import PermissionList from '../PermissionList/PermissionList';
import AddUsersBox from '../PermissionList/AddUsersBox';
import { copyLinkToClipboardWithToast, toast } from '../../message';
import { log } from '../../logging/logging';
import { stringToHslColor } from '../UserAvatar/avatar_utilities';
import { isElectronApp } from '../../flags';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
/**
 *
 * @param {Object} props
 * @param {string} props.entityName
 * @param {'group'|'database'|'site'} props.entityType
 * @param {string} props.entityId
 * @param {string} props.entityLabel
 * @param {'owner'|'editor'|'viewer'} props.permission
 * @param {PermissionsType} props.permissions
 * @param {boolean} props.disabled Hides/Disables all sharing ability
 * @param {boolean} props.hidden When to hide listing and sharing controls
 * @param {JSX.Element} props.extraSharingOptions
 * @param {JSX.Element} props.footer
 * @param {JSX.Element} props.extraElements
 * @param {import("../PermissionList/AddUsersBox").validateUsersFn=} props.validateUsersFn
 * @param {function({type: 'SET'|'UPDATE'|'REMOVE', data: object}): Promise} props.onChange
 * @param {string} props.saveMsg
 * @param {string} props.btnText
 * @param {boolean=} props.hideButton
 * @param {function=} props.onClose
 * @param {import("@mui/material").ButtonProps=} props.buttonProps
 * @param {boolean=} props.hideGrantIcons
 * @param {boolean=} props.closeExtraSharingOptions
 * @param {boolean=} props.open
 */
export default function ShareEntity({
  entityName,
  entityId,
  entityType,
  permission,
  permissions,
  disabled,
  hidden,
  extraSharingOptions,
  footer,
  extraElements,
  validateUsersFn,
  onChange,
  saveMsg,
  btnText,
  hideButton,
  onClose,
  entityLabel,
  buttonProps,
  hideGrantIcons,
  open,
  closeExtraSharingOptions
}) {
  const [grantsIcons, setGrantsIcons] = useState(/** @type {import('../PermissionList/utilities').Permission[]} */(null));
  const [showDialog, setShowDialog] = useState(false);
  const [showExtraSharingOptions, setShowExtraSharingOptions] = useState(false);

  useDeepCompareEffect(() => {
    let cancel = false;
    if (hidden) {
      setGrantsIcons(null);
      return;
    }
    listPermissions(permissions).then((grants) => {
      if (cancel) {
        return;
      }
      setGrantsIcons(grants.filter(g => g.entityType === 'user' && g.entityLabel !== firebaseEmail()));
    });
    return () => {
      cancel = true;
    };
  }, [hidden, permissions || {}]);

  useEffect(() => {
    if (closeExtraSharingOptions) {
      setShowExtraSharingOptions(false);
    }

  }, [closeExtraSharingOptions]);

  const closeDialog = () => {
    setShowDialog(false);
    if (onClose) {
      onClose();
    }
  };

  let availablePermissions;

  if (entityType === 'group') {
    availablePermissions = [
      { name: 'Owner', description: 'Full access' },
      { name: 'Editor', description: 'Use/View/Edit snippets' },
      { name: 'Viewer', description: 'Use/View snippets' }
    ];
  } else if (entityType === 'database') {
    availablePermissions = [
      { name: 'Owner', description: 'Full access' },
      { name: 'Editor', description: 'View/Edit/Delete tables' },
      { name: 'Viewer', description: 'View tables' }
    ];
  } else if (entityType === 'site') {
    availablePermissions = [
      { name: 'Owner', description: 'Full access' },
      { name: 'Editor', description: 'View/Edit/Delete pages' },
      { name: 'Viewer', description: 'View pages' }
    ];
  }

  /** @type {log} */
  function logFn(config) {
    return log(config, {
      [`${entityType}_id`]: entityId
    });
  }

  const showSharingControls = (permission === 'owner' && !disabled);
  const canShowExtraSharingOptions = permission === 'owner';
  return (
    <>
      {!hideButton ? <>
        <Button
          variant="contained"
          startIcon={<PersonAddIcon />}
          onClick={() => {
            setShowExtraSharingOptions(false);
            setShowDialog(true);
          }}
          {...buttonProps}
        >
          {btnText}
        </Button>
        {!hideGrantIcons && grantsIcons && (
          <Box
            sx={{
              mt: 2
            }}
          >
            {grantsIcons.slice(0, 5).map(({
              entityLabel
            }, i) => (
              <Tooltip title={entityLabel} key={entityLabel}>
                <ShareIcon
                  removeNegativeMargin={i === 0}
                  color={stringToHslColor(entityLabel, 45, 40)}
                >
                  {entityLabel[0].toUpperCase()}
                </ShareIcon>
              </Tooltip>
            ))}
            {grantsIcons.length > 5 && <ShareIcon>+{grantsIcons.length - 5}</ShareIcon>}

          </Box>
        )}
      </> : null}
      <Dialog
        open={open || showDialog}
        onClose={closeDialog}
        maxWidth="sm"
        BackdropProps={{
          style: {
            opacity: 0.2
          }
        }}>
        <DialogTitle
          sx={[{
            backgroundColor: 'grey.100'
          }, !showExtraSharingOptions && {
            display: 'flex'
          }, showExtraSharingOptions && {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }]}
        >
          {showExtraSharingOptions ? <>
            <Tooltip
              title="Back"
            >
              <IconButton
                onClick={() => setShowExtraSharingOptions(false)}
                sx={{ mr: 0.5 }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            Sharing settings for <b>{entityName}</b>
          </> : <>
            <span
              style={{
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                lineHeight: '40px',
                marginRight: 16
              }}
            >Share <b>{entityName}</b></span>
            {isElectronApp() && entityType === 'group' && (
              <Button
                startIcon={<LinkIcon />}
                onClick={() => copyLinkToClipboardWithToast('https://dashboard.blaze.today/folder/' + entityId, 'folder')}
              >Copy link to folder</Button>
            )}
            {!hidden && !!canShowExtraSharingOptions && <Tooltip
              title="Extra sharing options"
            >
              <IconButton
                onClick={() => setShowExtraSharingOptions(true)}
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip>}
            <IconButton
              onClick={closeDialog}
            >
              <CloseIcon />
            </IconButton>
          </>}
        </DialogTitle>
        <DialogContent
          sx={{
            px: 3,
            pb: 2,
            mt: 2,
          }}
        >
          {!hidden && (<>
            {canShowExtraSharingOptions && (
              <Collapse in={showExtraSharingOptions}>
                {extraSharingOptions}
              </Collapse>
            )}
            <Collapse in={!showExtraSharingOptions}>
              {showSharingControls && !disabled && (
                <>
                  <AddUsersBox
                    autoFocus
                    permissions={permissions}
                    entityId={entityId}
                    type={entityType}
                    access={permission}
                    accesses={availablePermissions}
                    shareButton="Share"
                    header="Share"
                    validateUsersFn={validateUsersFn}
                    onSave={(newGrants) => onChange({
                      type: 'SET',
                      data: {
                        newGrants
                      }
                    })}
                    usersAddedFn={(usersAdded) => {
                      if (entityType !== 'database' && entityType !== 'site') {
                        sendShare({
                          entityId,
                          entityType,
                          users_added: usersAdded
                        }).catch(err => {
                          console.log(err);
                          logFn({
                            action: `Failed ${entityType} share`
                          });
                        });
                      }

                      toast(saveMsg, {
                        duration: 10000,
                        intent: 'success'
                      });
                    }}
                  />
                  <div style={{ height: '24px' }}></div>
                </>
              )}
              <PermissionList
                permissions={permissions}
                entityId={entityId}
                type={entityType}
                userHeader="User"
                permissionHeader={'Permission for ' + entityLabel}
                /* If sharing is disabled, we use the viewer permission so we can't change anything */
                access={disabled ? 'viewer' : permission}
                accesses={availablePermissions.map(p => p.name)}
                onRemove={(user) => onChange({
                  type: 'REMOVE',
                  data: {
                    user: user
                  }
                })}
                onChange={(user, grant) => onChange({
                  type: 'UPDATE',
                  data: {
                    user: user,
                    grant: grant
                  }
                })}
              />
              {showSharingControls && footer}
            </Collapse>
          </>
          )}

          {!showExtraSharingOptions && extraElements}
        </DialogContent>
      </Dialog>
    </>
  );
}
const ShareIcon = React.forwardRef(/**
  * @param {Object} props
  * @param {React.ReactNode} props.children
  * @param {Boolean=} props.removeNegativeMargin
  * @param {string=} props.color
  */
  ({
    children,
    removeNegativeMargin,
    color,
    ...otherProps
  }, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          display: 'inline-block',
          borderRadius: '100%',
          backgroundColor: color || 'grey.400',
          color: 'primary.contrastText',
          borderColor: 'primary.contrastText',
          borderWidth: 1,
          borderStyle: 'solid',
          width: '2rem',
          height: '2rem',
          lineHeight: '2rem',
          textAlign: 'center',
          marginLeft: '-0.5rem',
          cursor: 'default',
          '&:first-of-type': {
            marginLeft: '0'
          }
        }}
        {...otherProps}
      >
        {children}
      </Box>
    );
  });