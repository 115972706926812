import React, { useEffect, useRef, useState } from 'react';
import EditorToolbar from './EditorToolbar';
import { getStartWhitespace, removeStartWhitespace } from './syntax_highlighters';
import { isInvalidAttribute } from '../SnippetEditor/EmbeddedCommand/embedded_utilities';
import './highlighter-editor.css';
import AttributeQuillEditor from '../SnippetEditor/AttributeQuillEditor';


/**
 * @param {object} props 
 * @param {string} props.value 
 * @param {function} props.onChange
 * @param {string=} props.placeholder 
 * @param {object} props.context
 * @param {boolean=} props.isConstant
 * @param {boolean=} props.isList
 * @param {boolean=} props.isEmbedded
 * @param {boolean=} props.readonly 
 * @param {string} props.attributeName 
 * @param {number=} props.startIndex 
 * @param {boolean=} props.isLegacyMenu 
 */
export default function SnippetAttributeEditor(props) {
  let [error, setError] = useState(/** @type {string} */ (null));
  let myMasterValue = useRef(props.value);
  let [value, setValue] = useState(removeStartWhitespace(props.value));


  useEffect(() => {
    // Check if new content (not managed here) has been passed
    // down.
    if (props.value.trimStart() !== myMasterValue.current.trimStart()) {
      myMasterValue.current = props.value;
      setValue(removeStartWhitespace(props.value));
      setError(null);
    }
  }, [props.value]);


  let handleValueChange = code => {
    let isInvalid = isInvalidAttribute(code, {
      isListContext: props.isList,
      isLegacyMenu: props.isLegacyMenu
    });
    if (isInvalid) {
      setError(isInvalid.message);
      return;
    }
    setError(null);
    setValue(code);
    let masterValue = getStartWhitespace(props.value) + removeStartWhitespace(code);
    myMasterValue.current = masterValue;
    props.onChange(masterValue);
  };

  let hasError = error !== null;

  return <div>
    <AttributeQuillEditor
      value={value}
      onChange={handleValueChange}
      placeholder={props.placeholder}
      disabled={props.readonly}
      attributeName={props.attributeName}
      style={{
        minHeight: 'calc(1.5em + 16px)'
      }}
      attributeType={props.isConstant ? 'constant' : 'text'}
      error={hasError}
      startIndex={props.startIndex}
    />
    {!props.readonly && <EditorToolbar 
      errorLabel={props.isList ? 'Invalid list value' : 'Invalid setting'}
      error={hasError ? error : null}
      type="attribute"
      context={props.context}
    />}
  </div>;
}