import { ParseError } from './ParserUtils';


/**
 * @param {string} str 
 * @param {boolean} duration - true if it is a duration (can't be negative), otherwise it's a shift (which can be and supports '<' and '>')
 * @param {string} errorMsg 
 * 
 * @return {object[]}
 */
export function parseTimeShift(str, duration, errorMsg) {
  let error = new ParseError(`${errorMsg} "${str}"; the proper form is "[length][unit]" like "2D" or "1Y".`);
  let parts = str.split(/(D\(skip=.+?\)|SAT|SUN|MON|TUE|WED|THU|FRI|[YQMWDhms])/);
  if (parts.pop() !== '') {
    throw error;
  }
  let shifts = [];
  if (parts.length % 2 !== 0) {
    throw error;
  }

  for (let i = 0; i < parts.length; i += 2) {
    let distance = parts[i].trim();
    let unit = parts[i + 1].trim();
    if (distance === '') {
      throw error;
    }
    let n = Number(distance);


    if (!(distance === '<' || distance === '>') && isNaN(n)) {
      throw error;
    } else if (!(distance === '<' || distance === '>') && unit.length > 1 && !unit.includes('(')) {
      throw error;
    } else if (duration && isNaN(n)) {
      throw error;
    } else if (duration && unit.length > 1) {
      throw error;
    } else {
      let units;
      let skips;

      if (unit.includes('(')) {
        units = 'days';
        if (n > 500 || n < -500) {
          throw new ParseError('Cannot use day skip with a shift of more than 500 days');
        }
        skips = unit.match(/D\(skip=(.+)\)/);
        if (!skips) {
          throw new ParseError('Invalid day skip');
        }
        skips = skips[1];
        if (!skips) {
          throw new ParseError('Invalid day skip');
        }
        skips = skips.split(',').map(s => s.trim());
        if (skips.length > 6) {
          throw new ParseError('Too many day skips: ' + skips);
        }
        if (skips.length !== [...new Set(skips)].length) {
          throw new ParseError('Day skips cannot contain duplicates: ' + skips);
        }
        for (let skip of skips) {
          if (!['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'].includes(skip)) {
            throw new ParseError('Invalid day skip: "' + skip + '". Must be one of MON, TUE, WED, THU, FRI, SAT, or SUN.');
          }
        }
      } else {
        units = {
          'Y': 'years',
          'Q': 'quarters',
          'M': 'months',
          'W': 'weeks',
          'D': 'days',
          'h': 'hours',
          'm': 'minutes',
          's': 'seconds'
        }[unit];
      }

      if (isNaN(n)) {
        shifts.push({
          direction: distance,
          units,
          day: unit.length > 1 && {
            'SUN': 0,
            'MON': 1,
            'TUE': 2,
            'WED': 3,
            'THU': 4,
            'FRI': 5,
            'SAT': 6
          }[unit]
        });
      } else {
        shifts.push({
          shift: n,
          skips,
          units
        });
      }
    }
  }
  if (!shifts.length) {
    throw error;
  }

  return shifts;
}


/**
 * @param {import("moment").Moment} momentRef 
 * @param {object} info 
 */
export function applyTimeShift(momentRef, info) {
  for (let item of info) {
    if ('shift' in item) {
      if (item.skips) {
        let days = item.shift;
        let multiplier = Math.sign(days); // to determine whether we need to increase or decrease things
        while (days * multiplier > 0) {
          momentRef.add(multiplier, 'days');
          let day = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'][momentRef.isoWeekday() - 1];
          if (!item.skips.includes(day)) {
            days -= multiplier;
          }
        }
      } else {
        momentRef.add(item.shift, item.units);
      }
    } else {
      if (item.units) {
        if (item.direction === '>') {
          momentRef.endOf(item.units);
        } else {
          momentRef.startOf(item.units);
        }
      } else {
        if (item.direction === '<') {
          // Go backwards
          if (momentRef.day() > item.day) {
            // to the one earlier this week
            momentRef.day(item.day);
          } else if (momentRef.day() < item.day) {
            // to the one last week
            momentRef.day(item.day - 7);
          }
        } else {
          // Go forwards
          if (momentRef.day() < item.day) {
            // to the one later this week
            momentRef.day(item.day);
          } else if (momentRef.day() > item.day) {
            // to the one next week
            momentRef.day(item.day + 7);
          }
        }
      }
    }
  }
}


/**
 * @param {object[]} shifts
 * 
 * @returns {number} seconds
 */
export function shiftsToSeconds(shifts) {
  let multipliers = { 'years': 3.154e+7, 'quarters': 2.628e+6 * 3, 'months': 2.628e+6, 'weeks': 604800, 'days': 86400, 'hours': 3600, 'minutes': 60, 'seconds': 1 };

  let delay = 0;
  for (let shift of shifts) {
    delay += shift.shift * multipliers[shift.units];
  }

  return delay;
}