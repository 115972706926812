import { css } from '../utils';
import TableOperationActions from './table-operation-actions';

// svg icons
const icons = {};

const MENU_MIN_HEIHGT = 150;
const MENU_WIDTH = 200;

export default class TableOperationMenu {
  constructor (params, quill, options) {
    
    this.options = options; 
    const menuActions = new TableOperationActions(params, quill);
    let menuOptions = options.items;
    if (options.color) {
      menuOptions = Object.assign(menuOptions, { color: options.color });
    }
    this.menuItems = menuActions.actions(menuOptions);
    this.destroyHandler = this.destroy.bind(this);
    this.menuInitial(params);
    this.mount();
    document.addEventListener('click', this.destroyHandler, false);
  }

  mount () {
    document.body.appendChild(this.domNode);
  }

  destroy (evt) {
    if (evt && this.domNode.contains(evt.target)) {
      return;
    }
    this.domNode.remove();
    document.removeEventListener('click', this.destroyHandler, false);
    return null;
  }

  menuInitial ({ table, evt }) {
    const self = this,
      domNode = self.domNode = document.createElement('div'),
      {
        color: colorMenu,
        border: borderMenu,
        ...menuItems
      } = self.menuItems,
      optionsItems = self.options.items;
    domNode.classList.add('qlbt-operation-menu');
    css(domNode, {
      position: 'absolute',
      left: `${evt.pageX}px`,
      top: `${evt.pageY}px`,
      'min-height': `${MENU_MIN_HEIHGT}px`,
      width: `${MENU_WIDTH}px`
    });

    for (let name in menuItems) {
      if (menuItems[name]) {
        domNode.appendChild(
          self.menuItemCreator(menuItems[name])
        );

        if (['insertRowDown', 'unmergeCells'].indexOf(name) > -1) {
          domNode.appendChild(
            dividingCreator()
          );
        }
      }
    }

    // if colors option is false, disabled bg color
    if (self.options.color || (optionsItems && optionsItems.color)) {
      domNode.appendChild(
        dividingCreator()
      );
      domNode.appendChild(
        subTitleCreator(colorMenu.text)
      );
      domNode.appendChild(self.colorsItemCreator(colorMenu.colors, colorMenu.handler));
    }



    // if colors option is false, disabled bg color
    if (self.options.border || (optionsItems && optionsItems.border)) {
      domNode.appendChild(
        dividingCreator()
      );
      domNode.appendChild(
        subTitleCreator(borderMenu.text)
      );
      const styles = [];
      for (let i = 0; i < borderMenu.styles.length; i++) {
        const style = borderMenu.styles[i];
        for (let j = 0; j < borderMenu.widths.length; j++) {
          const width = borderMenu.widths[j];
          styles.push(width + ' ' + style);
        }
      }
      const childNodes = self.borderStylesItemCreator(borderMenu.colors, styles, borderMenu.handler);
      childNodes.forEach(node => domNode.appendChild(node));
    }

    // create dividing line
    function dividingCreator () {
      const dividing = document.createElement('div');
      dividing.classList.add('qlbt-operation-menu-dividing');
      return dividing;
    }

    // create subtitle for menu
    function subTitleCreator (title) {
      const subTitle = document.createElement('div');
      subTitle.classList.add('qlbt-operation-menu-subtitle');
      subTitle.innerText = title;
      return subTitle;
    }
  }

  colorsItemCreator (colors, handler) {
    const self = this;
    const node = document.createElement('div');
    node.classList.add('qlbt-operation-color-picker');

    colors.forEach(color => {
      let colorBox = colorBoxCreator(color);
      node.appendChild(colorBox);
    });

    function colorBoxCreator (color) {
      const box = document.createElement('div');
      box.classList.add('qlbt-operation-color-picker-item');
      box.setAttribute('data-color', color);
      box.style.backgroundColor = color;

      box.addEventListener('click', function () {
        handler(color);
        self.destroy();
      }, false);

      return box;
    }

    return node;
  }

  borderStylesItemCreator (colors, styles, handler) {
    const self = this;
    const styleNode = document.createElement('div');
    styleNode.classList.add('qlbt-operation-width-picker');

    const colorNode = document.createElement('div');
    colorNode.classList.add('qlbt-operation-color-picker');

    styles.forEach(size => {
      let styleBox = widthBoxCreator(size);
      styleNode.appendChild(styleBox);
    });

    colors.forEach(color => {
      let colorBox = colorBoxCreator(color);
      colorNode.appendChild(colorBox);
    });

    function colorBoxCreator (color) {
      const box = document.createElement('div');
      box.classList.add('qlbt-operation-color-picker-item');
      box.setAttribute('data-border-color', color);
      box.style.backgroundColor = color;

      box.addEventListener('click', function () {

        handler({
          side: 'all',
          type: 'color',
          value: color
        });
        self.destroy();
      }, false);

      return box;
    }

    function widthBoxCreator (style) {
      const box = document.createElement('div');
      box.classList.add('qlbt-operation-width-picker-item');
      box.setAttribute('data-border-width', style);
      box.setAttribute('title', style);
      box.style.borderTop = style;

      box.addEventListener('click', function () {
        const [width, styl] = style.split(' ');
        handler([{
          side: 'all',
          type: 'width',
          value: width
        }, {
          side: 'all',
          type: 'style',
          value: styl
        }]);
        self.destroy();
      }, false);

      return box;
    }

    return [styleNode, colorNode];
  }

  menuItemCreator ({ text, iconSrc, handler }) {
    const node = document.createElement('div');
    node.classList.add('qlbt-operation-menu-item');

    const iconSpan = document.createElement('span');
    iconSpan.classList.add('qlbt-operation-menu-icon');
    iconSpan.innerHTML = icons[iconSrc];

    const textSpan = document.createElement('span');
    textSpan.classList.add('qlbt-operation-menu-text');
    textSpan.innerText = text;

    node.appendChild(iconSpan);
    node.appendChild(textSpan);
    node.addEventListener('click', () => {
      handler();
      this.destroy();
    }, false);
    return node;
  }
}