import React from 'react';
import {
  Dialog
} from '@mui/material';


/** 
 * 
 * @param {object} props
 * @param {() => any} props.onClose
 * @param {React.ReactNode} props.children
 */
const TrashedDialog = function({
  onClose,
  children
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '90vw',
          height: '85vh'
        }
      }}
      componentsProps={{
        backdrop: {
          style: {
            opacity: .2
          }
        }
      }}
      data-testid="trashed-dialog"
    >
      {children}
    </Dialog>
  );
};

export default TrashedDialog;
