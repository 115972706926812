export function css (domNode, rules) {
  if (typeof rules === 'object') {
    for (let prop in rules) {
      domNode.style[prop] = rules[prop];
    }
  }
}

/**
 * getRelativeRect
 * @param  {DOMRect} targetRect  rect data for target element
 * @param  {Element} container  container element
 * @return {Object}             an object with rect data
 */
export function getRelativeRect (targetRect, container) {

  let containerRect = container.getBoundingClientRect();
  const scrollLeft = container.scrollLeft || 0,
    scrollTop = container.scrollTop || 0;
  return {
    x: targetRect.x - containerRect.x - scrollLeft,
    y: targetRect.y - containerRect.y - scrollTop,
    x1: targetRect.x - containerRect.x - scrollLeft + targetRect.width,
    y1: targetRect.y - containerRect.y - scrollTop + targetRect.height,
    width: targetRect.width,
    height: targetRect.height
  };
}

/**
 * _omit
 * @param  {Object} obj         target Object
 * @param  {Array} uselessKeys  keys of removed properties
 * @return {Object}             new Object without useless properties
 */
export function _omit (obj, uselessKeys) {
  return obj && Object.keys(obj).reduce((acc, key) => {
    return uselessKeys.includes(key) ?
      acc :
      Object.assign({}, acc, { [key]: obj[key] });
  }, {});
}

/**
 * getEventComposedPath
 *  compatibility fixed for Event.path/Event.composedPath
 *  Event.path is only for chrome/opera
 *  Event.composedPath is for Safari, FF
 *  Neither for Micro Edge
 * @param {Event} evt
 * @return {Array} an array of event.path
 */
export function getEventComposedPath (evt) {
  let path;
  // chrome, opera, safari, firefox
  path = (
    // @ts-ignore path is deprecated. We have it because source had it.
    evt.path
    || (evt.composedPath && evt.composedPath())
  );

  // other: edge
  if (path === undefined && evt.target) {
    path = [];
    let target = /** @type {Node} */ (evt.target);
    path.push(target);

    while (target && target.parentNode) {
      target = target.parentNode;
      path.push(target);
    }
  }

  return path;
}

export function convertToHex (rgb) {
  let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  // if rgb
  if (/^(rgb|RGB)/.test(rgb)) {
    let color = rgb.toString().match(/\d+/g);
    let hex = '#';

    for (let i = 0; i < 3; i++) {
      hex += ('0' + Number(color[i]).toString(16)).slice(-2);
    }
    return hex;
  } else if (reg.test(rgb)) {
    let aNum = rgb.replace(/#/,'').split('');
    if (aNum.length === 6) {
      return rgb;
    } else if (aNum.length === 3) {
      let numHex = '#';
      for (let i = 0; i < aNum.length; i += 1) {
        numHex += (aNum[i] + aNum[i]);
      }
      return numHex;
    }
  }

  return rgb;
}

/**
 * checks if next lines has same row but different cell
 * @param {{ row: string, cell: string }} currentCell 
 * @param {import('quill/core').Delta['ops']} ops 
 * @param {number} currentIndex 
 */
export const hasSameRowNextInOps = (currentCell, ops, currentIndex) => {
  return hasSameTableNextInOps(currentCell, ops, currentIndex, (cell1, cell2) => (
    cell1.row === cell2.row
    && cell1.cell !== cell2.cell
  ));
};

/**
 * checks if next lines has same cell
 * @type {typeof hasSameRowNextInOps}
 */
export const hasSameCellNextInOps = (currentCell, ops, currentIndex) => {
  return hasSameTableNextInOps(currentCell, ops, currentIndex, (cell1, cell2) => (
    cell1.cell === cell2.cell
    && cell1.row === cell2.row
  ));
};

/**
 * checks if next lines are same based on the callback
 * @param {T} currentCell 
 * @param {import('quill/core').Delta['ops']} ops 
 * @param {number} currentIndex 
 * @param {(cell1: T, cell2: T) => boolean} callbackFn 
 * 
 * @template {{ row: string, cell: string }} T 
 */
const hasSameTableNextInOps = (currentCell, ops, currentIndex, callbackFn) => {
  for (let j = currentIndex + 1; j < ops.length; j++) {
    const nextOp = ops[j],
      nextOpAttributes = nextOp.attributes;

    if (typeof nextOp.insert !== 'string'
      || (
        nextOp.insert !== '\n'
        && !nextOp.insert.includes('\n')
      )
    ) {
      // Ignore. Not block
      continue;
    }
    const nextTableCellLine = /** @type {typeof currentCell} */ (nextOpAttributes?.tableCellLine);
    if (!nextTableCellLine) {
      return false;
    }
    return callbackFn(nextTableCellLine, currentCell);
  }
  return false;
};