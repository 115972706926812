import { deltaproto } from './Proto';
import equals from 'fast-deep-equal';


const DeltaMessage = deltaproto.Delta;
const align = DeltaMessage.Op.Attributes.Align;
const list = DeltaMessage.Op.Attributes.List;
const script = DeltaMessage.Op.Attributes.Script;
const tableAlign = DeltaMessage.Op.Attributes.TableCol.TableAlign;
const verticalAlign = DeltaMessage.Op.Attributes.VerticalAlign;


// window['compressDelta'] = compressDelta;
/**
 * Delta POGO -> Proto
 * 
 * @param {DeltaType | import('quill/core').Delta} delta
 * 
 * @return {Uint8Array}
 */
export function compressDelta(delta) {
  // break references
  delta = /** @type {DeltaType} */ (JSON.parse(JSON.stringify(delta)));

  for (let i = 0; i < delta.ops.length; i++) {
    let op = delta.ops[i];
    if (op.insert && typeof op.insert !== 'string') {
      op.insertObject = JSON.stringify(op.insert);
      delete op.insert;
    }
    if (op.attributes) {
      const attributes = op.attributes;
      if (attributes.list) {
        attributes.list = list[attributes.list];
      }
      if (attributes.align) {
        attributes.align = align[attributes.align];
      }
      if (attributes.script) {
        attributes.script = script[attributes.script];
      }
      if (attributes.tableCol?.align) {
        attributes.tableCol.align = tableAlign[attributes.tableCol.align];
      }
      if (attributes?.tableCellLine?.verticalAlign) {
        attributes.tableCellLine.verticalAlign = verticalAlign[attributes.tableCellLine.verticalAlign];
      }
    }
  }
  let message = DeltaMessage.create(delta);
  let array = DeltaMessage.encode(message).finish();
  // to convert from node's buffer to uint8array
  if (typeof array === 'object' && array.constructor.name === 'Buffer') {
    array = new Uint8Array(array);
  }
  
  return array;
}


// window['decompressDelta'] = decompressDelta;
/**
 * Delta Proto -> POJO
 * 
 * @param {string|Uint8Array|object} input
 * 
 * @return {DeltaType}
 */
export function decompressDelta(input) {
  let array;
  if (typeof input === 'string') {
    array = new Uint8Array(input.length);
    for (let i = 0 , j = input.length; i < j; ++i) {
      array[i] = input.charCodeAt(i);
    }
  } else if (input instanceof Uint8Array) {
    array = input;
  } else if (typeof input === 'object' && input.constructor.name === 'Buffer') {
    array = input;
  } else {
    throw Error('Unknown delta input: ' + (typeof input));
  }

  let message = DeltaMessage.decode(array);
  let obj = /** @type {DeltaType} */ (DeltaMessage.toObject(message, {
    enums: String,  // enums as string names
    arrays: true   // populates empty arrays (repeated fields) even if defaults=false
  }));

  for (let i = 0; i < obj.ops.length; i++) {
    if (obj.ops[i].insertObject) {
      obj.ops[i].insert = JSON.parse(obj.ops[i].insertObject);
      delete obj.ops[i].insertObject;
    }
  }

  return obj;
}




/**
 * Tests whether two deltas are equal.
 * @param {DeltaType} delta1 
 * @param {DeltaType} delta2
 * 
 * @return {boolean}
 */
export function deltasEqual(delta1, delta2) {
  return equals(delta1, delta2);
}