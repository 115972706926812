import React from 'react';
import { Link } from 'react-router-dom';
import { generateAppRoute } from '../../hooks/useAppNavigate';


const AppLink = React.forwardRef(
  /**
   * 
   * @typedef {object} AppLinkProps
   * @property {AppType} appType
   * @property {string} to
   * 
   * 
   * Generate link for the app based on the type.
   * @param {AppLinkProps & import("react-router-dom").LinkProps} props
   */
  ({
    appType,
    to,
    target,
    ...otherProps
  }, ref) => {
    const {
      url: newTo,
      sameWindow
    } = generateAppRoute(to, appType);
    if (!sameWindow) {
      return <a
        ref={ref}
        {...otherProps}
        href={newTo}
        target={target || '_blank'}
      >{otherProps.children}</a>;
    }
    return <Link
      ref={ref}
      to={newTo}
      target={target}
      {...otherProps}
    />;
  }
);

export default AppLink;