import React from 'react';
import Paper from '@mui/material/Paper';
import { useTypedSelector } from '../../hooks';
import { commas, timeSaved, usePlaceholderOrgData, useUserDomain } from './placeholder_utilities';
import T from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { checkOrg } from '../../flags';
import { useHistory } from 'react-router-dom';


/**
 * @param {object} props 
 * @param {string=} props.previewDomain
 */
export default function PlaceholderOrganizationBanner(props) {
  let domain = useUserDomain();
  if (props.previewDomain) {
    domain = props.previewDomain;
  }

  let { push: navigate } = useHistory();

  let isOrg = useTypedSelector(state => checkOrg(state));

  let userCreatedTimestamp = useTypedSelector(state => state.userState.createdTimestamp);

  let canQuery = !isOrg || props.previewDomain; // don't show to org users

  let { data, error } = usePlaceholderOrgData(canQuery ? domain : null);


  if (!data) {
    return null;
  }


  if (error) {
    return null;
  }
  

  let {
    name,
    is_live,
    domains,
    all_time,
    orgs
  } = data;
  

  if (!props.previewDomain) {
    if (!is_live) {
      // only show banner when live
      return null;
    }

    if (orgs && orgs.length) {
      // don't show in the first day for users in a company with orgs
      let hoursAgo = (Date.now() - userCreatedTimestamp) / 1000 / 60 / 60;
      if (hoursAgo < 24) {
        return null;
      }
    }
  }

  return <Paper
    variant="outlined"
    style={{
      padding: 14,
      marginBottom: 24
    }}
  >
    <img
      src={'//logo.clearbit.com/' + domains[0]}
      alt="Logo" style={{
        height: 30,
        marginRight: 14,
        marginBottom: 8,
        float: 'left'
      }}
      // @ts-ignore
      referrerPolicy="no-referrer"
    />

    <T>You're part of {commas(all_time.active_users)} users at {name} who have saved a total of {timeSaved(all_time.total_characters)} with Text Blaze.</T>

    <div style={{
      textAlign: 'right'
    }}>
      <Button
        variant="outlined"
        style={{
          marginTop: 12
        }}
        onClick={() => {
          navigate('/company');
        }}
      >
        {name} ❤️ Text Blaze
      </Button>
    </div>
  </Paper>;
}