import equals from 'fast-deep-equal';
import { captureException } from '@sentry/browser';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { checkOrg, checkPro, limitationsConfig } from '../../flags';
import { getPreferencesStore } from '../../extension_utilities';

/**
 * @typedef {object} LimitationsDef
 * @property {string=} _PLAN
 * @property {number=} MAX_GROUPS
 * @property {boolean=} CAN_UPGRADE_MAX_GROUPS
 * @property {number=} MAX_SNIPPETS_PER_GROUP
 * @property {boolean=} CAN_UPGRADE_MAX_SNIPPETS_PER_GROUP
 * @property {number=} MAX_SNIPPETS
 * @property {boolean=} CAN_UPGRADE_MAX_SNIPPETS
 * @property {number=} MAX_PRO_SNIPPETS_PER_DAY
 * @property {boolean=} CAN_UPGRADE_MAX_PRO_SNIPPETS_PER_DAY
 * @property {number=} MAX_SNIPPET_SIZE
 * @property {boolean=} CAN_UPGRADE_MAX_SNIPPETS_SIZE
 * @property {number=} MAX_USERS_PER_GROUP
 * @property {boolean=} CAN_UPGRADE_MAX_USERS_PER_GROUP
 */



/**
 * @param {LimitationsDef} base - original limitations
 * @param {object} adjustment - the adjustment you want to apply
 * 
 * @returns {LimitationsDef}
 */
function adjustLimitations(base, adjustment) {
  if (!adjustment || !Object.keys(adjustment).length) {
    return base;
  }

  /** @type {LimitationsDef} */
  let res = Object.assign({}, base);
  for (let key in adjustment) {
    if (adjustment[key].type === 'sum') {
      if (typeof res[key] === 'number') {
        // in case it's accidentally entered as a string
        // convert to number:
        let value = (+adjustment[key].value);
        if (isNaN(value)) {
          console.warn('Invalid sum adjustment: ' + key + ' -- ' + adjustment[key].value);
        } else {
          res[key] += value;
        }
      }
    } else if (adjustment[key].type === 'set') {
      res[key] = adjustment[key].value;
    } else if (adjustment[key].type === 'replace') {
      if (key in res) {
        res[key] = adjustment[key].value;
      }
    }
  }

  return res;
}


/**
 * @param {object} plans
 * @param {ReturnType<typeof limitationsConfig>['plan']} plan
 * @param {'business'|'basic'|'pro'} sku
 * 
 * @return {LimitationsDef}
 **/
export function pickLimitations(plans, plan, sku) {
  if (!plans || !Object.keys(plans).length) {
    return {};
  }

  let el = plans[plan];
  if (!el) {
    if ((typeof window === 'undefined') || !window['disable_capabilities_plan_warning_in_tests']) {
      console.warn('Invalid plan: ' + plan);
    }
    // fallback to _STANDARD if the plan is invalid
    el = plans['_STANDARD'];
    if (!el) {
      return {};
    }
  }
  /** @type {LimitationsDef} */
  let limitations = Object.assign({}, el.shared, el.skus && el.skus[sku]);
  while (el.parent) {
    el = plans[el.parent];
    limitations = Object.assign({}, el.shared, el.skus && el.skus[sku], limitations);
  }
  return limitations;
}

/**
 * @param {Partial<import('@store').RootState>} state
 * 
 * @return {LimitationsDef}
 **/
export let limitationsState = (state) => {
  try {
    const limitations =  limitationsStateInner(state);

    // empty try-catch to avoid any potential issues since this code should only run for one user
    try {
      // mark from bootsdigitalhealth
      // todo remove around October 2024, as this is only a grace period to help them migrate
      if (state.userState?.isLoaded && state.userState?.uid?.startsWith('z7lGs27rSoNEoB77')) {
        limitations.MAX_GROUPS = 120;
      }
    } catch (e) {
    }

    return limitations;
  } catch (err) {
    // We want to make sure the limitations code never takes out the app
    console.error('Capabilities failures.', err);
    captureException(err);
    return {};
  }
};


/**
 * @param {import('@store').RootState} state
 * 
 * @return {LimitationsDef}
 **/
let limitationsStateInner = createSelectorCreator(
  defaultMemoize,
  equals // deep equals for comparisons
)(
  [
    state => state.config && state.config.plans,
    state => limitationsConfig(state),
    state => limitationSkus(state)
  ],
  (plans, /** @type {ReturnType<typeof limitationsConfig>} */ config, /** @type {ReturnType<typeof limitationSkus>} */ skus) => {
    if (!plans) {
      // failsafe in case data hasn't loaded yet
      return {};
    }
    let { plan, adjustments } = config;

    let [currentSku, nextSku] = skus;
    let currentLimitations = pickLimitations(plans, plan, currentSku);

    /** @type {LimitationsDef} */
    let nextLimitations;
    if (nextSku) {
      nextLimitations = pickLimitations(plans, plan, nextSku);
      for (let key in currentLimitations) {
        currentLimitations['CAN_UPGRADE_' + key] = (currentLimitations[key] !== nextLimitations[key]);
      }
    } else {
      for (let key in currentLimitations) {
        currentLimitations['CAN_UPGRADE_' + key] = false;
      }
    }

    currentLimitations = adjustLimitations(currentLimitations, adjustments);

    currentLimitations._PLAN = plan;

    return currentLimitations;
  }
);


/**
 * @param {import('@store').RootState} state 
 * @returns {['business', null]|['pro', 'business']|['basic', 'pro']}
 */
function limitationSkus(state) {
  if (checkOrg(state)) {
    return ['business', null];
  } else if (checkPro(state)) {
    return ['pro', 'business'];
  } else {
    return ['basic', 'pro'];
  }
}


/**
 * Counts usage of a feature.
 * 
 * @param {'pro_snippets'} type
 */
export async function usageCount(type) {
  let key = 'usage_' + type;
  let storedEvents = await getPreferencesStore().getItem(key);
  /** @type {number[]} */
  let events = [];
  if (storedEvents) {
    events = JSON.parse(storedEvents);
  }
  events = events.filter(event => (new Date(event)).toDateString() === (new Date()).toDateString());
  await getPreferencesStore().setItem(key, JSON.stringify(events));

  return events.length;
};

/**
 * Tracks usage of a feature.
 * 
 * @param {'pro_snippets'} type
 */
export async function useFeature(type) {
  let key = 'usage_' + type;
  let storedEvents = await getPreferencesStore().getItem(key);
  /** @type {number[]} */
  let events = [];
  if (storedEvents) {
    events = JSON.parse(storedEvents);
  }
  if (events.length > 100) {
    // Prevent this from growing unbounded.
    // Note this sets a limit on MAX_PRO_SNIPPETS_PER_DAY of 100,
    // anything higher means unlimited.
    events = events.slice(events.length - 100);
  }
  events.push(Date.now());
  await getPreferencesStore().setItem(key, JSON.stringify(events));
}