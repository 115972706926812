import { toast } from '../../message';
import { log } from '../../logging/logging';
import { getAuth, sendEmailVerification } from 'firebase/auth';


/**
 * @param {string} email
 * @param {() => void} done
 */
export function resendEmailVerification(email, done) {
  sendEmailVerification(getAuth().currentUser).then(() => {
    toast(`Verification email resent to (${email}). Follow the instructions in it to verify your address.`, {
      duration: 6000,
      intent: 'success'
    });
    log({ category: 'Authentication', action: 'Sent verification email' });
    done();
  }).catch((error) => {
    toast(`Verification email could not be sent to (${email}). ${error}`, {
      duration: 6000,
      intent: 'danger'
    });
    log({ category: 'Authentication', action: 'Failed to send verification email' });
    done();
  });
}