import React from 'react';
import T from '@mui/material/Typography';
import VolumeSlider from './VolumeSlider';
import Locale from './Locale';
import { APP_PLATFORM_NAME, isDev, isElectronApp, isProduction } from '../../flags';
import LimitationsSelector from './LimitationsSelector';
import SnippetEditingChips from './SnippetEditingChips';
import WidgetConfig from './WidgetConfig';
import { isPartOfWidgetExperiment } from './WidgetUtil';
import { store } from '@store';
import AppConflictConfig from '../AppConflict/AppConflictConfig';
import { useTypedSelectorShallowEquals } from '../../hooks';
import ConfettiConfig from './ConfettiConfig';
import { AssistantShortcut } from './AssistantShortcut';
import { useDesktopClientSupportsFeature } from '../../desktop_hooks';
import { APP_TARGET, fullAppName } from '../../raw_flags';
import { AIBlazeBlacklistDomain } from './AIBlazeOptions';
import { isAiBlaze } from '../../aiBlaze';

export default function OptionsPanel(_props) {
  const isAssistantShortcutApp = useDesktopClientSupportsFeature('assistant-shortcut');

  let { snippetVolume } = useTypedSelectorShallowEquals((store) => {
    return {
      snippetVolume: store.userState?.snippet_volume
    };
  });

  return <>
    <T paragraph variant="h6">Snippet expansion sound</T>
    <T paragraph color="textSecondary">Control the volume of the sound that is played when you complete a snippet; or turn it off completely.</T>
    <VolumeSlider/>

    {!isElectronApp() && <>
      <ConfettiConfig disabled={snippetVolume === 0} />
    </>}

    <br/>
    <br/>
    <T gutterBottom variant="h6">Locale &amp; formatting</T>
    <T paragraph color="textSecondary" style={{ marginBottom: 20 }}>Specify your locale to customize how {fullAppName} formats dates and numbers.</T>

    <Locale />
    <br/>

    <SnippetEditingChips />

    {isElectronApp() && isAssistantShortcutApp && <>
      <T
        gutterBottom
        variant="h6"
        sx={{
          mt: 4
        }}
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >{APP_PLATFORM_NAME + ' ' + (isAiBlaze ? 'Sidebar shortcut' : 'Assistant shortcut')}</T>
      <T paragraph color="textSecondary" style={{ marginBottom: 20 }}>Click the edit button, enter your desired shortcut, and it will be automatically saved.</T>
      <AssistantShortcut />
    </>}

    {!isElectronApp() && APP_TARGET === 'TEXT' && (isPartOfWidgetExperiment(store.getState()) || !isProduction()) && <>
      <T
        gutterBottom
        variant="h6"
        sx={{
          mt: 4
        }}
      >Instant Assistant</T>
      <WidgetConfig />
    </>}
      
    {isElectronApp() && <>
      <T
        gutterBottom
        variant="h6"
        sx={{
          mt: 4
        }}
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >{APP_PLATFORM_NAME} app in Chrome-based browsers (like Chrome and Brave)</T>
      <AppConflictConfig />
    </>}

    {!isElectronApp() && APP_TARGET === 'AI' && <AIBlazeBlacklistDomain />}

    {isDev() && <LimitationsSelector />}
  </>;
}
