import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import PublishIcon from '@mui/icons-material/ShareOutlined';
import PublishDialog from './PublishDialog';
import { useTypedSelector } from '../../hooks';
import { toast } from '../../message';
import { log } from '../../logging/logging';
import Tooltip from '@mui/material/Tooltip';


function PublishButtonBase(props) {
  let emailVerified = useTypedSelector(state => state.userState.emailVerified);
  let [open, setOpen] = useState(false);

  return <>
    <Tooltip
      title="Publish and share a copy of this snippet"
    >
      <IconButton
        data-testid="publish-button"
        onClick={() => {
          if (emailVerified) {
            log({ action: 'OPEN_SNIPPET_PUBLISH_DIALOG' }, {
              snippet_id: props.snippetId,
              group_id: props.groupId
            });

            setOpen(true);
          } else {
            toast('You must verify your email address before publishing snippets', {
              duration: 4000,
              intent: 'danger'
            });
          }
        }}
        style={Object.assign({ marginTop: 0, marginLeft: 8 }, props.style)}
        size="large"
      >
        <PublishIcon/>
      </IconButton>
    </Tooltip>
    {open && <PublishDialog
      snippetId={props.snippetId}
      groupId={props.groupId}
      getDelta={props.getDelta}
      onClose={() => setOpen(false)}
    />}
  </>;
}

const PublishButton = React.memo(PublishButtonBase);
export default PublishButton;