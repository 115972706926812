import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { runBlock } from '../../snippet_processor/Equation';
import { ActionContext, ShowError } from './SharedComponents';
import { styled } from '@mui/system';
import NodeEvaluator from './NodeEvaluator';

/**
 * @param {object} props
 * @param {import("../../snippet_processor/ParseNode").default} props.node
 * @param {import("../../snippet_processor/DataContainer").Environment} props.env
 */
export default function ButtonRenderer(props) {
  let { onChange, showToast } = useContext(ActionContext);

  return <NodeEvaluator node={props.node} env={props.env} renderer={(res) => {
    if (res.type === 'error') {
      return <ShowError msg={res.info.message} blocking={res.info.blocking} nodeOrAddonId={props.node} />;
    }
    const info = res.info;
    if (!info) {
      return null;
    }

    let commandStyle = {};
    if (props.node.info.style) {
      commandStyle = props.node.info.style;
    }

    return <PillButton
      // @ts-ignore
      buttonStyle={commandStyle}
      variant="outlined"
      size="small"
      disabled={info.buttonDisabled}
      onClick={async () => {
        let tree;
        try {
          tree = await props.node.info.attributes.position[0].ast(props.env, 'Block');
          await runBlock(tree, props.env);
          onChange(false);

        } catch (e) {
          showToast('Error with the button code. ' + e.message, {
            intent: 'danger',
          });
        }
      }}
    >
      {info.buttonLabel}
    </PillButton>;
  }} />;
}



const PillButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'buttonStyle'

  // @ts-ignore
})(({ buttonStyle }) => ({
  backgroundColor: buttonStyle.backgroundColor || 'transparent',
  fontFamily: buttonStyle.fontFamily,
  color: buttonStyle.color || '#333',
  border: '1px solid #c4c4c4',
  borderRadius: '50px',
  fontSize: buttonStyle.fontSize || '0.8rem',
  lineHeight: '1',
  padding: '3px 9px', 
  textTransform: 'none',
  position: 'relative',
  marginTop: 2,
  marginBottom: 2,
  marginLeft: 2,
  marginRight: 2,
  minWidth: 40,
  top: -1,
  boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.08), -2px -2px 4px rgba(255, 255, 255, 0.9)',
  '&:hover': {
    backgroundColor: buttonStyle.backgroundColor || 'transparent',
    boxShadow: 'inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.9)',
  },
  '&:focus': {
    outline: 'none',
  },
  '&:active': {
    backgroundColor: buttonStyle.backgroundColor || 'transparent',
    boxShadow: 'inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.9)',
  }
}));