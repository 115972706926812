import { escapeBSQLName } from '../components/Database/bsql_utilities';
import { Environment } from './DataContainer';
import { findType, sEqn } from './Equation';
import { astSQL } from './SQL';
import { getColumnValue, getColumnDisplayFromValue } from '../components/SimpleSQLEditor/utilities';

/**
 * @typedef {{type: string, info: any}} ASTObject
 * // Keep consistent with values in constant below AST_RELATIONAL_OPERATORS
 * @typedef {'gt'|'lt'|'gt_eq'|'lt_eq'|'equals'|'not_equals'} ValidRelationalOperators
 * @typedef {'contains'|'includes'|'startswith'} ValidFunctionalOperators
 * @typedef {{type: 'operator', value: ValidRelationalOperators}} RelationalOperator
 * @typedef {{type: 'function', value: ValidFunctionalOperators}} FunctionalOperator
 * // identifier of first column, and then datatype of second item being compared to
 * // TODO: can replace first ASTObject with string name of column identifier
 * @typedef {[ASTObject, RelationalOperator | FunctionalOperator | { type: 'other', value: string }, ASTObject]} ConditionClause
 * @typedef {[ASTObject, ASTObject]} ValueSetClause
 * @typedef {{ alias: string, column: string }} ColumnDesc
 * @typedef {'select'|'update'|'insert'|'delete'} ValidQueryType
 * @typedef {'dbselect'|'dbupdate'|'dbinsert'|'dbdelete'} ValidCommandType
 * 
 * @typedef {{
 *  tableName: string,
 *  columnsSelected?: ColumnDesc[],
 *  conditions?: ConditionClause[],
 *  setValues?: ValueSetClause[],
 *  orderBy?: { direction: string, column: string },
 *  limit?: number
 * }} CommonSQLStructure
 * 
 * @typedef {{queryType: ValidQueryType} & CommonSQLStructure} ParsedSQLStructure
 */

export const AST_RELATIONAL_OPERATORS = { 'gt': '>', 'lt': '<', 'gt_eq': '>=', 'lt_eq': '<=', 'equals': '=', 'not_equals': '<>' };

/** 
 * Helper function to get rid of 'startPosition' from info objects
 * @returns {ASTObject}
*/
function getObject(infoValue) {
  return { type: infoValue.type, info: infoValue.info };
}

/**
 * @param {string} formName 
 */
function formNameToIdentifier(formName) {
  if (formName.match(/^[A-Za-z][A-Za-z0-9_]*$/)) {
    return `@${formName}`;
  }
  return `\`@${formName}\``;
}

/**
 * @param {ASTObject} obj 
 * @returns {string}
 */
export function astObjectToStringRepr(obj) {
  if (obj.type === 'form_identifier') {
    return formNameToIdentifier(obj.info);
  }
  if (obj.type === 'identifier') {
    if (obj.info.startsWith('__%%_linkrow_id_')) {
      return '`' + obj.info.slice('__%%_linkrow_id_'.length) + '->id`';
    }
  }
  if (obj.type === 'datetime') {
    obj = {
      type: 'string',
      info: '' + obj.info
    };
  }
  return sEqn(obj);
}

/**
 * @param {object} node 
 * @returns {ConditionClause[]}
 */
function getANDConditionsFromWhereClause(node) {
  /**
   * Object which represents exactly one condition
   * (not joined with logical operators)
   * @param {object} objectToUse 
   * @param {ValidRelationalOperators} currType 
   * 
   * @return {ConditionClause}
   */
  function extractCondition(objectToUse, currType) {
    const [left, right] = objectToUse.info;
    // special logic for datetime diffs
    if (left.type === 'function_call' && left.info.name.info === 'datetimediff') {
      if (right.type === 'number' && right.info === 0) {
        if (['lt', 'gt', 'gt_eq', 'lt_eq'].includes(currType)) {
          return [getObject(left.info.args[0]), { type: 'other', value: '_' + currType }, getObject(left.info.args[1])];
        }
      }
    }

    if (left.type !== 'identifier' && left.info.name.info !== 'rowid') {
      return null;
    }
    if (!['identifier', 'form_identifier', 'number', 'boolean', 'string'].includes(right.type)) {
      return null;
    }
    return [getObject(left), { type: 'operator', value: currType }, getObject(right)];
  }

  /**
   * Extract a condition given by a function call on the columns
   * @param {object} objectToUse 
   * @returns {ConditionClause} true iff successfully extracted
   */
  function extractFunctionalCondition(objectToUse) {
    const functionName = objectToUse.name.info;
    /** @type {ValidFunctionalOperators[]} */
    let validFunctions = ['contains', 'includes', 'startswith'];
    if (!validFunctions.includes(functionName)) {
      return null;
    }

    // only binary operators for now
    if (objectToUse.args.length !== 2) {
      return null;
    }

    const lhs = objectToUse.args[0];
    const rhs = objectToUse.args[1];
    if (lhs.type !== 'identifier' && lhs.info.name.info !== 'rowid') {
      return null;
    }

    return [getObject(lhs), { type: 'function', value: functionName }, getObject(rhs)];
  }

  function extractCommonCondition(objectToUse) {
    if (objectToUse.type === 'function_call') {
      return extractFunctionalCondition(objectToUse.info);
    } else {
      return extractCondition(objectToUse, objectToUse.type);
    }
  }

  const type = node.type;
  const isValidLogical = type === 'and';
  if (isValidLogical) {
    let leftConditions = getANDConditionsFromWhereClause(node.info[0]);
    let rightConditions = getANDConditionsFromWhereClause(node.info[1]);
    if (!leftConditions || !rightConditions) {
      // not a valid where condition for simpleUI
      return null;
    }
    return leftConditions.concat(rightConditions);
  }
  const isValidOperator = Object.keys(AST_RELATIONAL_OPERATORS).includes(type);
  const isValidFunction = type === 'function_call';
  if (!isValidOperator && !isValidLogical && !isValidFunction) {
    return null;
  }

  let condition = extractCommonCondition(node);
  
  return condition ? [condition] : null;
}

/**
 * @param {object[]} columns 
 * @returns {ColumnDesc[]}
 */
function getColumnAliases(columns) {
  /** @type {ColumnDesc[]} */
  const returnColumns = [];
  for (const col of columns) {
    const isRowId = col.info.column.type === 'function_call' && col.info.column.info.name.info === 'rowid';
    if (col.type !== 'select_option' 
      || col.info.alias.type !== 'identifier'
      || (col.info.column.type !== 'identifier' && !isRowId)) {
      return null;
    }

    let alias = col.info.alias.info, column;
    if (isRowId) {
      column = '__%%_rowid';
    } else if (col.info.column.type === 'identifier' && (col.info.column.info.endsWith('->id`') || col.info.column.info.endsWith('->id'))) {
      let name = col.info.column.info;
      if (name[0] === '`') {
        name = name.slice(1, name.length - 1);
      }
      if (alias[0] === '`') {
        alias = alias.slice(1, alias.length - 1);
      }
      column = '__%%_linkrow_id_' + name.slice(0, name.length - '->id'.length);
    } else {
      column = getColumnDisplayFromValue(col.info.column.info);
    }
    returnColumns.push({ alias, column });
  }
  return returnColumns;
}

/**
 * @param {string} queryText 
 * @param {ValidCommandType} commandName
 * @returns {ParsedSQLStructure}
 */
export function astSimpleSQL(queryText, commandName) {
  if (!queryText) {
    if (commandName === 'dbselect') {
      return {
        queryType: 'select',
        conditions: [],
        tableName: '',
        setValues: [],
        columnsSelected: []
      };
    } else if (commandName === 'dbdelete') {
      return {
        queryType: 'delete',
        conditions: [],
        tableName: '',
        setValues: [],
        columnsSelected: []
      };
    } else if (commandName === 'dbupdate') {
      return {
        queryType: 'update',
        conditions: [],
        tableName: '',
        setValues: null,
        columnsSelected: []
      };
    } else if (commandName === 'dbinsert') {
      return {
        queryType: 'insert',
        conditions: [],
        tableName: '',
        setValues: null,
        columnsSelected: []
      };
    }
  }

  let ast = astSQL(queryText, new Environment());
  if (ast.type !== 'query') {
    return null;
  }
  
  for (let id of findType(ast, 'identifier')) {
    if (id.info.startsWith('@')) {
      id.info = id.info.slice(1);
      id.type = 'form_identifier';
    }
  }

  const info = ast.info.info;
  const matcherDict = {
    'select': matchesSelectQuery,
    'insert': matchesInsertQuery,
    'update': matchesUpdateQuery,
    'delete': matchesDeleteQuery
  };
  const queryType = ast.info.type;

  if (commandName === 'dbselect' && queryType !== 'select') {
    return null;
  } else if (commandName === 'dbupdate' && queryType !== 'update') {
    return null;
  } else if (commandName === 'dbdelete' && queryType !== 'delete') {
    return null;
  } else if (commandName === 'dbinsert' && queryType !== 'insert') {
    return null;
  }

  /** @type {matchesSelectQuery} */
  const matcherFunc = matcherDict[queryType];
  if (!matcherFunc) {
    return null;
  }
  const res = matcherFunc(info);
  if (!res) {
    return null;
  }

  return  { queryType, ...res };
}

/**
 * @param {*} info 
 * @returns {string}
 */
function readTableName(info) {
  if (info.type !== 'identifier') {
    return null;
  }
  return info.info;
}

/**
 * @param {object} info
 * @returns {CommonSQLStructure}
 */
function matchesSelectQuery(info) {
  if (info.group_by) {
    return null;
  }

  const conditions = info.where ? getANDConditionsFromWhereClause(info.where.info) : [];
  if (!conditions) {
    return null;
  }

  const base = info.base;
  const baseInfo = base.info;
  const tableName = readTableName(baseInfo.from);
  if (!tableName) {
    return null;
  }

  const columnsSelected = getColumnAliases(baseInfo.columns);
  if (!columnsSelected) {
    return null;
  }

  if (info.order_by) {
    if (info.order_by.info.length > 1) {
      // we only support one column for order by in the simple editor
      return null;
    }
    if (info.order_by.info[0].info.item.type !== 'identifier') {
      // we only support identifier order by (no expressions)
      return null;
    }
  }

  return {
    tableName,
    columnsSelected,
    conditions,
    orderBy: info.order_by ? { direction: info.order_by.info[0].info.direction, column: info.order_by.info[0].info.item.info } : null,
    limit: info.limit?.info?.info,
  };
}

function matchesInsertQuery(info) {
  return matchesMutatingQuery(info, 'insert');
}

/**
 * @param {*} info 
 * @param {'update'|'delete'|'insert'} queryType
 * @returns {CommonSQLStructure}
 */
function matchesMutatingQuery(info, queryType) {
  const tableInfo = info.table;
  const tableName = readTableName(tableInfo);
  if (!tableName) {
    return null;
  }
  const isConditionedQuery = queryType === 'delete' || queryType === 'update';
  const conditions = isConditionedQuery ? (info.where ? getANDConditionsFromWhereClause(info.where.info) : null) : [];
  if (!conditions) {
    return null;
  }
  /** @type {[ASTObject, ASTObject][]} */
  let setValues = [];
  if (queryType === 'update' || queryType === 'insert') {
    if (info.values === null) {
      setValues = null;
    } else {
      for (const setValue of info.values) {
        if (setValue.type !== 'assignment_option') {
          return null;
        }
        const name = setValue.info.name;
        const value = setValue.info.value;
        if (name.type !== 'identifier') {
          return null;
        }
        if (value.type !== 'form_identifier') {
          return null;
        }
        setValues.push([getObject(name), getObject(value)]);
      }
    }
  }
  return { tableName, conditions, setValues };
}

function matchesUpdateQuery(info) {
  return matchesMutatingQuery(info, 'update');
}

function matchesDeleteQuery(info) {
  return matchesMutatingQuery(info, 'delete');
}

/**
 * @param {ConditionClause} condition
 */
function conditionClauseToText([lhs, operator, rhs]) {
  const lhsText = astObjectToStringRepr(lhs);
  const rhsText = astObjectToStringRepr(rhs);
  if (operator.type === 'operator') {
    return `${lhsText} ${AST_RELATIONAL_OPERATORS[operator.value]} ${rhsText}`;
  } else if (operator.type === 'function') {
    return `${operator.value}(${lhsText}, ${rhsText})`;
  } else if (operator.type === 'other') {
    return `datetimediff(${lhsText}, ${rhsText}, "D") ${AST_RELATIONAL_OPERATORS[operator.value.slice(1)]} 0`;
  }

  throw new Error('unexpected operator type');
}

/**
 * @param {ParsedSQLStructure} queryData
 * 
 * @returns {string} the interpreted query text
 */
export function simpleSQLToText(queryData) {
  const { queryType, conditions, tableName, setValues, orderBy, limit } = queryData;
  const tableNameToRender = sEqn({ type: 'identifier', info: tableName });

  const conditionsList = conditions || [];
  const conditionClauses = conditionsList.map((value) => conditionClauseToText(value));
  const whereClause = conditionsList.length ? ` WHERE ${conditionClauses.join(' AND ')}` : '';
  const orderByClause = orderBy ? ` ORDER BY ${escapeBSQLName(orderBy.column)} ${orderBy.direction}` : '';
  const limitClause = limit ? ` LIMIT ${limit}` : '';

  const setValuesList = setValues || [];
  const setClauses = setValuesList.map(([lhs, rhs]) => astObjectToStringRepr(lhs) + '=' + astObjectToStringRepr(rhs));
  const setClause = setClauses.join(', ');
  const setClauseConditional = setValues === null ? '' : ` SET ${setClause}`;

  if (queryType === 'select') {
    const { columnsSelected } = queryData;
    const columnNames = columnsSelected.map(({ alias, column }) => {
      const sC = sEqn({ type: 'identifier', info: getColumnValue(column) });
      const sA = sEqn({ type: 'identifier', info: alias });
      if (alias === column && column === '__%%_rowid') {
        return `${sC} AS \`Row ID\``;
      }
      return (alias !== column) ? `${sC} AS ${sA}` : sC;
    }).join(', ');

    return `SELECT ${columnNames} FROM ${tableNameToRender}${whereClause}${orderByClause}${limitClause}`;
  } else if (queryType === 'update') {
    return `UPDATE ${tableNameToRender}${setClauseConditional}${whereClause}`;
  } else if (queryType === 'insert') {
    return `INSERT INTO ${tableNameToRender}${setClauseConditional}`;
  } else if (queryType === 'delete') {
    return `DELETE FROM ${tableNameToRender}${whereClause}`;
  } else {
    return null;
  }
}