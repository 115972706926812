import React, { useEffect, useState, useRef, useContext } from 'react';
import T from '@mui/material/Typography';
import {
  Alert,
  AlertTitle,
  Collapse
} from '@mui/material';
import { useTypedSelector, useTypedSelectorShallowEquals } from '../../hooks';
import { APP_TARGET, getConnectedConfigOptions, INSTALLABLE_ADDONS, isOrgOwner, orgId, orgPref } from '../../flags';
import { makeRef } from '../../firebase_utilities';
import { isOrg, uid } from '@store';
import { storage } from '../../utilities';
import { getState } from '../../getState';
import { toast } from '../../message';
import SettingsIcon from '@mui/icons-material/SettingsSuggestOutlined';
import { featureUsage } from '../Version/limitations';
import { Environment } from '../../snippet_processor/DataContainer';
import { sync } from '../../Sync/syncer';
import { makeConfig } from '../SnippetPreview/preview_utilities';
import { decompressDelta } from '../../delta_proto/DeltaProto';
import { createDom } from '../../snippet_processor/SnippetProcessor';
import AddonInstaller from '../Addons/AddonInstaller';
import { DatabaseLabel } from '../Database/DatabaseLabel';
import InfoChicklet from '../InfoChicklet/InfoChicklet';
import AsyncButton from '../AsyncButton/AsyncButton';
import ConnectedSettingsContext from '../Group/ConnectedSettingsContext';
import { connectedGroupContextValue } from '../Group/group_utils';
import { resendEmailVerification } from '../VerificationReminder/verificationreminder_utilites';


let reqTimeout = null;


/**
 * Shows error for the command
 * @param {Object} props
 * @param {string} props.snippetId
 * @param {string} props.groupId
 * @param {boolean} props.owner
 * @returns 
 */
const SnippetChangesRequired = (props) => {
  // We disable when running tests as it requires a lot of additional mocks
  // to support things like looking up the the dataState or activeAddons that
  // are irrelevant for most tests of the snippet editor.
  if (typeof vi !== 'undefined') {
    return null;
  }

  return <SnippetChangesRequiredInner {...props} />;
};


/**
 * @param {Object} props
 * @param {string} props.snippetId
 * @param {string} props.groupId
 * @param {boolean} props.owner
 * @returns
 */
const SnippetChangesRequiredInner = (props) => {
  let { connected, group, snippet, orgLimitsConnectedEdit } = useTypedSelector((store) => {
    let group = store.dataState.groups[props.groupId];
    let snippet = group ? group.snippets.find(s => s.id === props.snippetId) : null;
    let connected = group && getConnectedConfigOptions(store.userState, group);

    return {
      connected,
      group,
      snippet,
      orgLimitsConnectedEdit: !isOrgOwner(store) && orgPref(store, 'userConnectedDisabled', false, false)
    };
  });

  return <ConnectedSettingsContext.Provider value={connectedGroupContextValue}>
    <ChangesRequiredBase
      {...props}
      connected={connected}
      group={group}
      snippet={snippet}
      orgLimitsConnectedEdit={orgLimitsConnectedEdit}
      findSnippet={(name) => {
        let snippets = sync.getSnippetsByShortcut(name.toLocaleLowerCase());
        if (snippets && snippets.length) {
          return {
            delta: snippets[0].data.content.delta.toUint8Array()
          };
        }
      }}
      groupUpdateFn={(data) => storage.update(makeRef('groups', props.groupId), data)}
    />
  </ConnectedSettingsContext.Provider>;
};


/**
 * @param {Object} props
 * @param {string} props.snippetId
 * @param {string} props.groupId
 * @param {boolean} props.owner
 * @param {{isConnected: boolean, invalidShare?: boolean, config: import('../../flags').ConnectedConfig}} props.connected
 * @param {GroupObjectType|SiteObjectType} props.group
 * @param {SnippetObjectType|PageObjectType} props.snippet
 * @param {boolean} props.orgLimitsConnectedEdit
 * @param {(name: string) => {delta: DeltaType}|void} props.findSnippet
 * @param {(data: any) => Promise} props.groupUpdateFn
 * @returns
 */
export const ChangesRequiredBase = (props) => {
  let [installingAddon, setInstallingAddon] = useState(null);
  let addonInstallerRef = useRef(null);
  const { connected, group, snippet, orgLimitsConnectedEdit } = props;
  const labels = useContext(ConnectedSettingsContext);

  const { isEmailVerified, email } = useTypedSelectorShallowEquals(store => ({
    isEmailVerified: store.userState.emailVerified,
    email: store.userState.email
  }));

  const failedToUpdateText = `Could not update the Connected Settings for this ${labels.groupType}.`
    + (orgLimitsConnectedEdit ? ' Contact an administrator of your organization to make this change.' : '')
    + (!isEmailVerified && !orgLimitsConnectedEdit ? ' You must verify your email first.' : '');

  const permissionsGrantedText = `Permission granted to ${labels.title}. You can review and change this on the ${labels.groupType}'s Connected Settings page.`;

  let [requirements, setRequirements] = useState(/** @type {Awaited<ReturnType<featureUsage>>} */ (null));
  useEffect(() => {
    if (!snippet) {
      return;
    }

    let f = (async function() {
      let activeAddons = new Set();

      let orgAddons = sync.org?.data?.addons;
      if (orgAddons) {
        for (let key in orgAddons) {
          if (orgAddons[key].enabled) {
            activeAddons.add(key);
          }
        }
      }
      let userAddons = sync.getUserState()?.addons;
      if (userAddons) {
        for (let key in userAddons) {
          if (userAddons[key].enabled) {
            activeAddons.add(key);
          }
        }
      }

      let installableAddons = {
        ...INSTALLABLE_ADDONS
      };
      // don't ask to install an addon we already have installed
      for (let [key, value] of Object.entries(installableAddons)) {
        if (activeAddons.has(value.id)) {
          delete installableAddons[key];
        }
      }

      let config = makeConfig({
        config: {
          // @ts-ignore Invalid property
          stage: 'snippet', // we need to parse nested items to track things like nested addons
          doNotPullInAddons: true,
          addons: sync.activeAddons(),
          snippet: {},
          user: {},
          installableAddons,
          findSnippet: props.findSnippet,
        }
      });

      let env = new Environment({}, config);
      let delta;
      if (labels.itemType === 'snippet') {
        delta = decompressDelta((/** @type {SnippetObjectType} */ (snippet)).content.delta.toUint8Array());
      } else {
        delta = JSON.parse(JSON.stringify((/** @type {PageObjectType} */ (snippet)).current_revision.delta));
      }
      let r = await featureUsage(await createDom(delta, env), env);
      setRequirements(r);
    });
    clearTimeout(reqTimeout);

    // debounce to run every 500ms
    reqTimeout = setTimeout(f, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet]);

  let neededRequirements = [];


  if (group && 'isAddon' in group && group.isAddon) {
    // we don't want to render on addon snippets
    return null;
  }

  if (!snippet) {
    return;
  }

  if (requirements) {
    if (APP_TARGET !== 'PAGE') {
      if (requirements.LOAD_HOSTS) {
        for (let loadHost of requirements.LOAD_HOSTS) {
          if (!connected.config.loadHostWhitelist.includes(loadHost)) {
            neededRequirements.push({
              key: 'loadhost-' + loadHost,
              message: <>Allow {labels.itemType} in this {labels.groupType} to read and write data from <b>{loadHost}</b></>,
              requiresOwner: true,
              action: () => {
                let connected = group.connected || /** @type {ConnectedSettingsType} */ ({
                  load_hosts: []
                });

                if (!connected.load_hosts) {
                  connected.load_hosts = [];
                }

                if (!connected.is_connected) {
                  connected.is_connected = true;
                  connected.connector = isOrg() ? ('o:' + orgId(getState())) : ('u:' + uid());
                }

                if (!connected.load_hosts.includes(loadHost)) {
                  connected.load_hosts.push(loadHost);
                }

                return props.groupUpdateFn({
                  connected
                })
                  .then(() => toast(permissionsGrantedText, { intent: 'success' }))
                  .catch(() => toast(failedToUpdateText, { intent: 'danger' }));
              }
            });
          }
        }
      }

      if (requirements.PING_HOSTS) {
        for (let pingHost of requirements.PING_HOSTS) {
          // If we already have a ping OR load whitelist we're good
          if (!connected.config.pingHostWhitelist.includes(pingHost) && !connected.config?.loadHostWhitelist?.includes(pingHost)) {
            neededRequirements.push({
              key: 'pingHost-' + pingHost,
              message: <>Allow {labels.itemTypePlural} in this {labels.groupType} to send data to <b>{pingHost}</b></>,
              requiresOwner: true,
              action: () => {
                let connected = group.connected || /** @type {ConnectedSettingsType} */ ({
                  ping_hosts: []
                });

                if (!connected.ping_hosts) {
                  connected.ping_hosts = [];
                }

                if (!connected.is_connected) {
                  connected.is_connected = true;
                  connected.connector = isOrg() ? ('o:' + orgId(getState())) : ('u:' + uid());
                }

                if (!connected.ping_hosts.includes(pingHost)) {
                  connected.ping_hosts.push(pingHost);
                }

                return props.groupUpdateFn({
                  connected
                })
                  .then(() => toast(permissionsGrantedText, { intent: 'success' }))
                  .catch(() => toast(failedToUpdateText, { intent: 'danger' }));
              }
            });
          }
        }
      }

      if (requirements.CONNECTED_ADDONS) {
        for (let connectedAddon of requirements.CONNECTED_ADDONS) {
          if (!connected.config.connectedAddonWhitelist.includes(connectedAddon)) {
            neededRequirements.push({
              key: 'connectedaddon-' + connectedAddon,
              message: <>Allow {labels.itemTypePlural} in this {labels.groupType} to use the <b>{connectedAddon}</b> connected command</>,
              requiresOwner: true,
              action: () => {
                let connected = group.connected || /** @type {ConnectedSettingsType} */ ({
                  addons: []
                });

                if (!connected.addons) {
                  connected.addons = [];
                }

                if (!connected.is_connected) {
                  connected.is_connected = true;
                  connected.connector = isOrg() ? ('o:' + orgId(getState())) : ('u:' + uid());
                }

                if (!connected.addons.includes(connectedAddon)) {
                  connected.addons.push(connectedAddon);
                }

                return props.groupUpdateFn({
                  connected
                })
                  .then(() => toast(permissionsGrantedText, { intent: 'success' }))
                  .catch(() => toast(failedToUpdateText, { intent: 'danger' }));
              }
            });
          }
        }
      }


      if (requirements.MISSING_ADDONS) {
        for (let missingAddon of Object.entries(requirements.MISSING_ADDONS)) {
          neededRequirements.push({
            key: 'missingaddon-' + missingAddon[1].name,
            message: <>Activate the <b>{missingAddon[1].name}</b> command pack</>,
            actionLabel: 'Activate',
            action: async () => {
              let addonDoc = await storage.get(makeRef('addons', missingAddon[1].id));

              let addon = Object.assign({
                id: missingAddon[1].id
              },  addonDoc.data().active);

              setInstallingAddon(addon);
            }
          });
        }
      }
    }

    
    if (requirements.DATABASES) {
      for (let [spaceId, spaceQueries] of Object.entries(requirements.DATABASES)) {
        let queryWhitelist = connected.config.databaseQueryWhitelist[spaceId];
        if (!queryWhitelist) {
          neededRequirements.push({
            key: 'query-' + spaceId,
            message: <span 
              style={{
                display: 'inline-flex',
                alignItems: 'center'
              }}
            >Allow {labels.itemTypePlural} in this {labels.groupType} to connect to the space <DatabaseLabel id={spaceId} /></span>,
            requiresOwner: true,
            action: () => {
              let connected = group.connected || /** @type {ConnectedSettingsType} */ ({
                database_queries: {}
              });

              if (!connected.database_queries) {
                connected.database_queries = {};
              }

              if (!connected.is_connected) {
                connected.is_connected = true;
                connected.connector = isOrg() ? ('o:' + orgId(getState())) : ('u:' + uid());
              }

              connected.database_queries[spaceId] = (connected.database_queries[spaceId] || []).concat(Array.from(spaceQueries));

              return props.groupUpdateFn({
                connected
              })
                .then(() => toast(permissionsGrantedText, { intent: 'success' }))
                .catch(() => toast(failedToUpdateText, { intent: 'danger' }));
            }
          });
        } else {
          for (let query of spaceQueries) {
            if (!queryWhitelist.includes(query)) {
              neededRequirements.push({
                key: 'queryupdate-' + spaceId,
                message: <span 
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center'
                  }}
                >Update allowed connections to the space <DatabaseLabel id={spaceId} /></span>,
                requiresOwner: true,
                actionLabel: 'Update',
                action: () => {

                  let connected = group.connected || /** @type {ConnectedSettingsType} */ ({
                    database_queries: {}
                  });
    
                  if (!connected.database_queries) {
                    connected.database_queries = {};
                  }
    
                  if (!connected.is_connected) {
                    connected.is_connected = true;
                    connected.connector = isOrg() ? ('o:' + orgId(getState())) : ('u:' + uid());
                  }
    
                  connected.database_queries[spaceId] = (connected.database_queries[spaceId] || []).concat(Array.from(spaceQueries));
    
                  return props.groupUpdateFn({
                    connected
                  }).then(() => toast(`Permission updated for the ${labels.title}. You can review and change this on the ${labels.groupTypeCapitalized}'s Connected Settings page.`, { intent: 'success' }))
                    .catch(() => toast(failedToUpdateText, { intent: 'danger' }));

                }
              });
              break;
            }
          }
        }
      }
    }
  }

  if (!neededRequirements.length) {
    return null;
  }

  if (!isEmailVerified && !orgLimitsConnectedEdit &&
    // if there's only one requirement, check that it's not "missing addon", because it's the only one that doesn't require verified email to work
    (neededRequirements.length > 1 || !neededRequirements[0].key.startsWith('missingaddon'))) {
    // add at the beginning since all other requirements won't work before verifying email
    neededRequirements.unshift({
      key: 'unverifiedemail',
      message: <>Verify your email in order to update the snippet requirements</>,
      actionLabel: 'Resend verification email',
      action: async () => {
        resendEmailVerification(email, () => {});
      }
    });
  }

  return (
    <Collapse
      in={!!neededRequirements.length}
      orientation="vertical"
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        '.MuiCollapse-wrapperInner': {
          display: 'flex'
        }
      }}
    >
      {installingAddon && <AddonInstaller
        ref={addonInstallerRef}
        addon={installingAddon}
        initOnLoad={{
          type: 'user',
          onDone: () => {
            setInstallingAddon(null);
          }
        }}
      />}
      <Alert
        severity="info"
        sx={{
          mx: 'auto',
          minWidth: '400px'
        }}
        icon={<SettingsIcon fontSize="large" />}
      >
        <AlertTitle>Additional {labels.itemType} requirements <InfoChicklet children={`These additional requirements are needed to fully use this ${labels.itemType}. Note that some of these may allow the ${labels.itemType} to send or receive data from a remote source, so review them carefully.`} /></AlertTitle>
        <ul
          style={{
            listStyleType: 'none',
            padding: 0,
            margin: 0,
          }}
        >
          {neededRequirements.map((requirement, i) => <li
            key={requirement.key + i}
            style={{
              marginTop: 8,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}><T component="span">{requirement.message}</T> <AsyncButton
              size="small"
              variant="outlined"
              sx={{
                mr: 2,
                ml: 2,
              }}
              onClick={async (done) => {
                if (requirement.requiresOwner && !props.owner) {
                  toast(`You must be an owner of this ${labels.groupType} to make this change, you can request a ${labels.groupType} owner to do it`, { intent: 'warning' });
                  done();
                  return;
                }
                await requirement.action();
                done();
              }}
            >{requirement.actionLabel || 'Allow'}</AsyncButton></li>)}
        </ul>

      </Alert>
    </Collapse>
  );
};

export default SnippetChangesRequired;