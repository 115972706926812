import React from 'react';
import PageDialog from '../Pages/PageDialog';
import { decompressDelta } from '../../delta_proto/DeltaProto';
import Button from '@mui/material/Button';
import { Bytes } from 'firebase/firestore';
import { getAddonData, userType } from '../../flags';
import equals from 'fast-deep-equal';
import AddonIcon from '@mui/icons-material/WidgetsOutlined';
import { useTypedSelector } from '../../hooks';


/**
 * @param {object} props 
 * @param {GroupObjectType} props.group
 * @param {function} props.onSave
 * @param {function=} props.onCancel
 * @param {function=} props.onClose
 * @param {string=} props.action
 * @param {'user'|'org'=} props.installedOverride
 * @param {boolean=} props.initial
 */
export default function AddonConfigDialog(props) {
  let {
    id,
    data,
    installedBy,
    role
  } = useTypedSelector((state) => {
    let { installed, id, data } = getAddonData(props.group, state.userState, state.orgState, props.installedOverride === 'org' ? 'organization' : props.installedOverride);

    let role = userType(state);
    return {
      data,
      installedBy: installed,
      role,
      id
    };
  }, (a, b) => {
    return equals(a, b);
  });


  let onAccept = function(dataContainer) {
    let formNames = props.group.options.addon.config.form_names || {};

    let orgData = {};
    let userData = {};

    let data = dataContainer.root.collapseData();
    for (let key in data) {
      if (key in formNames) {
        if (formNames[key].type === 'standard' || installedBy === 'user') {
          userData[key] = data[key];
        } else {
          if (role === 'owner') {
            orgData[key] = data[key];
          }
        }
      }
    }

    props.onSave && props.onSave({ userData, orgData, id });
    props.onClose && props.onClose();
  };

  return <PageDialog
    configOverride={{
      snippet: { source: installedBy }
    }}
    delta={decompressDelta(
      Bytes.prototype.toUint8Array.apply(props.group.options.addon.config.content.delta)
    )}
    data={data}
    title={<div style={{
      display: 'flex',
      alignItems: 'center'
    }}>{props.group.options?.addon?.icon_image_url ? <img src={props.group.options?.addon?.icon_image_url} width={32} height={32} alt="Icon" style={{ marginRight: 12 }} /> : <AddonIcon style={{ verticalAlign: 'middle', marginRight: 12 }} />} {props.group.name} {props.action || 'Settings'}</div>}
    controls={({ onAccept }) => {
      let saveButton = <Button
        variant="contained"
        color="primary"
        onClick={() => onAccept()}
      >
        {props.initial ? 'Activate' : 'Save'}
      </Button>;

      let cancelButton = <Button
        style={{ marginRight: 16 }}
        onClick={() => {
          props.onCancel && props.onCancel();
          props.onClose && props.onClose();
        }}>
        Cancel
      </Button>;

      return <div style={{
        textAlign: 'right',
        width: '100%'
      }}>
        {cancelButton}
        {saveButton}
      </div>;
    }}
    onAccept={onAccept}
    onReject={props.onClose}
  />;
}