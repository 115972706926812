import React from 'react';
import { format } from 'd3-format';
import EnterpriseIcon from '@mui/icons-material/Business';
import T from '@mui/material/Typography';
import VersionPurchaseButton from './VersionPurchaseButton';

let f = format(',');


/**
 * @param {object} props
 * @param {string} props.billing
 * @param {boolean=} props.isOrg
 * @param {boolean=} props.isPayingPro
 * @param {function(object):void} props.onBuy
 */
export default function VersionPlans({ billing, isOrg, isPayingPro, onBuy, ...props }) {
  return <>
    {getPlans(props).map((plan, i) => <VersionPlan
      key={i} plan={plan}
      billing={billing}
      isOrg={isOrg}
      isPayingPro={isPayingPro}
      onBuy={onBuy}
    />)}
  </>;
}

/**
 * @param {object} params
 * @param {object} params.plan
 * @param {string} params.billing
 * @param {boolean} params.isOrg
 * @param {boolean} params.isPayingPro
 * @param {function(object):void} params.onBuy
 */
function VersionPlan({ plan, billing, isOrg, isPayingPro, onBuy }) {
  return <div className="sku" id={plan.id} data-testid={plan.id}>
    <div className="top">
      <T variant="h6" className="name">{plan.name}</T>
      <T className="who-for">{plan.who}</T>
      {plan.price ?
        <>
          <div className="price"><T color="textSecondary" className="denomination">$</T><div className="value">{plan.price[billing][0]}{plan.price[billing][1] > 0 ? <sup>{plan.price[billing][1]}</sup> : <sup>&nbsp;</sup>}</div></div>
          <T color="textSecondary" className="unit single-line">{['business', 'enterprise'].includes(plan.id) ? 'Per user / month' : 'Per month'}</T>
          <T color="textSecondary" className="single-line">{plan.id === 'enterprise' ? 'Annual Contract' : <>Billed {billing === 'annually' ? 'yearly' : billing}</>}</T>
        </> :
        (plan.priceIcon ? <div>
          <div className="price-icon">{plan.priceIcon}</div>
          <T color="textSecondary" className="single-line">Contact <a href="mailto:sales@blaze.today?subject=Text Blaze Enterprise" target="_blank" rel="noreferrer">sales@blaze.today</a></T>
          <T color="textSecondary" className="single-line">for customized pricing</T>
        </div> : <div>
          <div className="price" style={{ marginTop: 12, marginBottom: 6 }}><div className="value">–</div></div>
          <T color="textSecondary">Free starter version</T>
        </div>)
      }
      <div className="purchase">
        <VersionPurchaseButton plan={plan} isOrg={isOrg} isPayingPro={isPayingPro} onBuy={onBuy} />
      </div>
    </div>
    <ul className="features">
      {plan.features.filter(feature => !!feature).map((feature, i) => <li key={i}>{feature}</li>)}
    </ul>
  </div>;
}


function getPlans(props) {
  return [
    {
      id: 'basic',
      name: 'Basic',
      price: undefined,
      who: 'Free starter version for trialing Text Blaze',
      features: [
        props.snippetsUpgradeable ? <>Up to <b>{f(props.skus.basic.MAX_SNIPPETS)}</b> snippets</> : null,
        props.groupsUpgradeable ? <>Up to <b>{f(props.skus.basic.MAX_GROUPS)}</b> snippet folders</> : null,
        props.groupSizeUpgradeable ? <>Up to <b>{f(props.skus.basic.MAX_SNIPPETS_PER_GROUP)}</b> snippets per folder</> : null,
        props.snippetSizeUpgradeable ?  <>Up to <b>{f(props.skus.basic.MAX_SNIPPET_SIZE)}</b> characters in a snippet</> : null,
        <><b>Limited</b> snippet sharing</>,
        <><b>Trial</b> forms support</>
      ]
    }, {
      id: 'pro',
      name: 'Pro',
      who: 'Great for individuals and consultants',
      price: {
        monthly: [3, 49],
        annually: [2, 99]
      },
      features: [
        props.snippetsUpgradeable ? <>Up to <b>{f(props.skus.pro.MAX_SNIPPETS)}</b> snippets</> : null,
        props.groupsUpgradeable ? <>Up to <b>{f(props.skus.pro.MAX_GROUPS)}</b> snippet folders</> : null,
        props.groupSizeUpgradeable ? <>Up to <b>{f(props.skus.pro.MAX_SNIPPETS_PER_GROUP)}</b> snippets per folder</> : null,
        props.snippetSizeUpgradeable ? <>Up to <b>{f(props.skus.pro.MAX_SNIPPET_SIZE)}</b> characters in a snippet</> : null,
        <><b>Double</b> the snippet sharing</>,
        <><b>Full</b> forms support</>,
        <>Images in snippets</>
      ]
    }, {
      id: 'business',
      name: 'Business',
      who: 'Great for teams and small businesses',
      price: {
        monthly: [8, 39],
        annually: [6, 99]
      },
      features: [
        props.snippetsUpgradeable ? <>Up to <b>{f(props.skus.business.MAX_SNIPPETS)}</b> snippets</> : null,
        props.groupsUpgradeable ? <>Up to <b>{f(props.skus.business.MAX_GROUPS)}</b> snippet folders</> : null,
        props.groupSizeUpgradeable ? <>Up to <b>{f(props.skus.business.MAX_SNIPPETS_PER_GROUP)}</b> snippets per folder</> : null,
        props.snippetSizeUpgradeable ? <>Up to <b>{f(props.skus.business.MAX_SNIPPET_SIZE)}</b> characters in a snippet</> : null,
        <><b>Unlimited</b> snippet sharing</>,
        <><b>Full</b> forms support</>,
        <>Images in snippets</>,
        <>Managed multi-user billing</>,
        <>Last editor tracking for snippets</>,
        <>Create teams with shared folders</>,
        <>Consolidated org usage statistics</>,
        <>Organization-wide access controls</>
      ]
    }, {
      id: 'enterprise',
      name: 'Enterprise',
      who: 'Great for larger organizations',
      price: undefined,
      priceIcon: <EnterpriseIcon style={{
        fontSize: 72,
        marginTop: 4
      }}/>,
      features: [
        <><span style={{ opacity: 0.7 }}>Everything in <b>Business</b>, and:</span></>,
        <>Additional billing options</>,
        <>SAML Single Sign-On (SSO)</>,
        <>Event-level action data</>,
        <>High touch support</>,
        <>User trainings</>,
        <>Implementation consulting</>,
        <>Dedicated feature work</>
      ]
    }
  ]
    // Removing the display of free plan increases conversions.
    // However, let's keep it defined above so we can easily use it
    // in the future if we want.
    .slice(1);
}