import React from 'react';
import ReadonlyIcon from '@mui/icons-material/RemoveRedEye';

import {
  Alert,
  Collapse
} from '@mui/material';
import { APP_TARGET } from '../../flags';

const ReadonlyNotice = () => {
  return (
    <Collapse
      in
      orientation="vertical"
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        '.MuiCollapse-wrapperInner': {
          display: 'flex'
        }
      }}
    >
      <Alert
        severity="info"
        sx={{
          mx: 'auto'
        }}
        icon={<ReadonlyIcon />}
      >
        You are a viewer of this shared {APP_TARGET === 'PAGE' ? 'page' : 'snippet'} and cannot edit it.
      </Alert>
    </Collapse>
  );
};

export default ReadonlyNotice;