import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import EmojiSelectorPopper from './EmojiPopper';
import { Box } from '@mui/material';
import { eagerlyLoadImport } from '../../utilities';
import useOnMount from '../../hooks/useOnMount';

const emojiDataFactory = eagerlyLoadImport(() => import('@emoji-mart/data/sets/14/native.json'));
const emojiComponentFactory = eagerlyLoadImport(() => import('./EmojiSelector'));

/** @type {ReturnType<typeof emojiComponentFactory>} */
let EmojiSelectorPromise = null;
/** @type {ReturnType<typeof emojiDataFactory>} */
let loadedEmojiDataPromise = null;

/** @type {import('./EmojiSelector').default} */
let EmojiSelector;

/**
 * @param {Omit<Parameters<import('./EmojiSelector').default>[0], 'emojiData'>} props 
 */
function DelayedEmojiFallback(props) {
  const [showLoading, setShowLoading] = useState(false);

  useOnMount(() => {
    // Show loading indicator only after a initial timeout
    // to avoid loading jank for users on fast internet connections
    const timeout = setTimeout(() => {
      setShowLoading(true);
    }, 400);
    setShowLoading(false);
    return () => {
      clearTimeout(timeout);
    };
  });

  if (!showLoading) {
    return null;
  }

  return <EmojiSelectorPopper {...props}>
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // As long as we don't upgrade the emoji-mart package,
      // its width should be fixed (because number
      // of emojis per line and their sizes are fixed)
      // The height will change only when user adds one 
      // new row of favorite emojis at the top
      height: '400px',
      width: '352px',
    }}>
      <CircularProgress size={60} />
    </Box>
  </EmojiSelectorPopper>;
}

/**
 * @param {Omit<Parameters<import('./EmojiSelector').default>[0], 'emojiData'>} props 
 */
export default function LazyEmojiSelector(props) {
  const [emojiData, setEmojiData] = useState(/** @type {typeof import('@emoji-mart/data/sets/14/native.json')} */ (null));
  const [componentLoaded, setComponentLoaded] = useState(0);

  useEffect(() => {
    if (!loadedEmojiDataPromise) {
      loadedEmojiDataPromise = emojiDataFactory();
      EmojiSelectorPromise = emojiComponentFactory();
    }
    loadedEmojiDataPromise.then(json => {
      setEmojiData(json.default);
    });
    EmojiSelectorPromise.then(module => {
      EmojiSelector = module.default;
      setComponentLoaded(1);
    }).catch(() => {
      setComponentLoaded(-1);
    });
  }, []);
  if (componentLoaded === -1) {
    const error = new Error('LazyEmojiSelector failed to load');
    error.name = 'ChunkLoadError';
    throw error;
  }

  if (!componentLoaded || !emojiData) {
    return <DelayedEmojiFallback {...props} />;
  }

  return <EmojiSelector {...props} emojiData={emojiData} />;
}