import React, { useRef } from 'react';
import AsyncButton from '../AsyncButton/AsyncButton';
import { useTypedSelectorShallowEquals } from '../../hooks';
import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  Typography as T
} from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import PublicIcon from '@mui/icons-material/Public';
import { log } from '../../logging/logging';
import { showConfirm, toast } from '../../message';
import { groupPublic } from '../../bapi';
import { BetaChip } from '../Version/VersionChip';
import { canGroupPublicManage, isGroupPublic } from './group_public_rules';
import { isBlaze, orgPref, TEXT_APP_DOMAIN } from '../../flags';
import { TemplateShare } from '../Templates/TemplatePublicControl';

/**
 * @param {object} props
 * @param {boolean=} props.showOnlyIfPublic
 * @param {string} props.groupId
 */
function GroupPublicControlsBase(props) {
  let { templateData, templateDataLoading } = useTypedSelectorShallowEquals(store => {
    const templates = store.dataState && store.dataState.templates;

    return {
      templateDataLoading: templates === null,
      templateData: templates?.find(template => template.id === props.groupId)
    };
  });

  const {
    canPublic,
    isPublic,
    url,
    hasShares,
    // TODO templates library: allow for everyone on full release
    isBlazeUser,
    xSharingDisabled
  } = useTypedSelectorShallowEquals((state) => {
    let user = state.userState;
    let group = state.dataState.groups[props.groupId];

    let canPublic = group && canGroupPublicManage(user, group);
    let isPublic = group && isGroupPublic(group);

    let url = TEXT_APP_DOMAIN + '/gallery/' + props.groupId;

    let hasShares = false;
    if (!isPublic) {
      let uid = user.uid;
      let permissions = group?.permissions || {};
      let list = ['u:' + uid, 'e:' + user.email, '_:all'];

      for (let key in permissions) {
        if (!list.includes(key)) {
          hasShares = true;
          break;
        }
      }
    }

    return {
      canPublic,
      isPublic,
      url,
      hasShares,
      isBlazeUser: isBlaze(state.userState),
      xSharingDisabled: orgPref(state, 'xSharingDisabled')
    };
  });

  /** @type {log} */
  function logFn(config) {
    return log(config, {
      group_id: props.groupId
    });
  }

  async function confirmPublish() {
    return new Promise((resolve) => {
      showConfirm({
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Publish it',
        intent: 'primary',
        contents: (
          <T style={{ maxWidth: 350 }}>
            Publishing a template folder will allow anyone with the template URL to view it and copy it.
            {hasShares && ' All shared user permissions will be removed.'}
          </T>
        ),
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false)
      });
    });
  };

  async function confirmUnpublish() {
    return new Promise((resolve) => {
      showConfirm({
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete it',
        intent: 'primary',
        contents: (
          templateData
            ? <T>
              This template is submitted to the templates library, deleting it will make the folder private and remove it from the library.
              {templateData && templateData.paid && <b style={{ display: 'block', marginTop: '4px' }}>Any users that paid for this template won't be able to copy it anymore.</b>}
            </T>
            : <T>
              Deleting the template will make the folder private and allow you to share it with specific users. It won't affect the folder or its snippets.
            </T>

        ),
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false)
      });
    });
  };

  async function toggleGroupPublic(done) {
    try {
      let confirmed = false;

      if (isPublic) {
        confirmed = await confirmUnpublish();
      } else {
        confirmed = await confirmPublish();
      }

      if (confirmed) {
        await groupPublic({
          group_id: props.groupId,
          unpublic: isPublic
        });

        log({
          action: 'SET_GROUP_PUBLIC_STATUS',
          label: {
            public: !isPublic
          }
        }, {
          group_id: props.groupId
        });

        let msg = isPublic
          ? 'You deleted the template folder. Now you can share it with Text Blaze users normally.'
          : 'You\'ve created a template folder. You can share it with everyone via its URL.';

        toast(msg, {
          duration: 10000,
          intent: 'success'
        });
      }
    } catch (err) {
      console.log(err);
      logFn({
        action: 'Failed group ' + (isPublic ? 'unpublic' : 'public')
      });
    }
    done();
  }

  if (!canPublic || (!isPublic && props.showOnlyIfPublic) || xSharingDisabled) {
    return null;
  }

  return (
    <>
      {!isPublic && <Divider sx={{ my: 3 }}/>}

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        mb: 2
      }}>

        <PublicIcon fontSize="small" sx={{ mr: 1 }}/>
        <T variant="h6">{isPublic ? 'This is a template folder' : 'Share with everyone'}</T>

        <BetaFeature />
      </Box>

      {isPublic
        ? templateData && templateData.paid
          ? <T paragraph>This template folder is listed in the templates library as paid. Users can only copy it via the hidden URL, but can preview it using the URL below.</T>
          : <T paragraph>Anyone who has the template URL can view the snippets and copy them to their account.</T>
        : <T paragraph>Template folders can be viewed and copied by anyone with the template URL, and can optionally be listed in our public templates library.</T>
      }

      <Box sx={{ display: 'grid',
        gridTemplateColumns: 'auto max-content',
        gap: '12px',
        alignItems: 'center'
      }}>
        {isPublic
          ? <TextWithCopy url={url} />
          : <div></div>
        }

        <AsyncButton
          data-testid="group-publish-button"
          variant="outlined"
          color={isPublic ? 'error' : 'primary'}
          onClick={toggleGroupPublic}
        >
          {isPublic ? 'Delete template' : 'Publish template folder'}
        </AsyncButton>
      </Box>

      {isBlazeUser && isPublic && <TemplateShare isLoading={templateDataLoading} templateData={templateData} collectionId={props.groupId} collectionType="folder" />}
    </>
  );
}

export function BetaFeature() {
  return <div
    style={{
      flex: '1 1 80px',
      marginLeft: '10px',
      textAlign: 'right',
      overflow: 'hidden'
    }}>
    <BetaChip
      style={{ maxWidth: '100%' }}
      styleChip={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100%'
      }}/>
  </div>;
}



/**
 * @param {Object} props
 * @param {string=} props.url
 * @param {string=} props.content
 */
export function TextWithCopy({ url, content }) {
  let selectRef = useRef(null);

  return (
    <div style={{
      display: 'inline-flex',
      alignItems: 'center',
      maxWidth: '100%',
      verticalAlign: 'top',
      overflow: 'hidden',
      width: '100%'
    }}>
      <T style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {url && <a href={url} target="_blank" rel="noopener noreferrer" ref={selectRef} title={url}
          data-testid="group-publish-link">{url}</a>}

        {content && <Box sx={{
          fontFamily: 'monospace',
          background: '#252525',
          padding: '4px',
          color: 'white',
          borderRadius: '4px'
        }}
        >
          {content}
        </Box>}
      </T>
      <div style={{
        marginLeft: 8
      }}>
        <Tooltip title="Copy URL">
          <IconButton
            onClick={async () => {
              await navigator.clipboard.writeText(url || content);
              toast(`Copied ${url ? 'URL' : 'content'} to your clipboard.`);
            }}
          >
            <CopyIcon/>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}


const GroupPublicControls = React.memo(GroupPublicControlsBase);
export default GroupPublicControls;