import React, { useEffect, useState } from 'react';
import { makePageConfig } from './page_utilities';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Page from './Page';


/**
 * @param {object} props
 * @param {object} props.configOverride
 * @param {object} props.data
 * @param {DeltaType} props.delta
 * @param {any} props.onReject
 * @param {any} props.onAccept
 * @param {object|boolean} props.controls
 * @param {string|React.ReactElement} props.title
 */
export default function PageDialog(props) {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    setConfig(makePageConfig(props.configOverride));
  }, [props.configOverride]);
  
  return (
    <Dialog
      open
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          props.onReject(event, reason);
        }
      }}
      maxWidth="md"
    >
      <DialogTitle>
        {props.title}
      </DialogTitle>
      <div 
        style={{
          width: 720,
          height: 560,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Page {...props} config={config}/>
      </div>
    </Dialog>
  );
}