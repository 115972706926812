import { htmlToText } from 'html-to-text';
import he from 'he';


/**
 * For use in tests.
 * 
 * @param {string} txt
 * 
 * @return {string}
 */
export function htmlFromText(txt) {
  let pStyle = 'margin: 0px !important; padding: 0px !important;';

  let paragraphs = he.encode(txt).split('\n');
  let res = '';
  for (let p of paragraphs) {
    if (!p) {
      res += `<p style="${pStyle}"><br/></p>`;
    } else {
      res += `<p style="${pStyle}">${p}</p>`;
    }
  }
  return res;
}


/**
 * @param {string} html - an html string to convert to text
 * 
 * @return {string} a textual representation of the html
 */
export function textFromHtml(html) {
  // </div> hack required for new lines should be removed when html-to-text updates
  return htmlToText(html.replace(/<p[^>]*><br[^>]*\/?><\/p>/g, '<br/>').replace(/<div[^>]*><br[^>]*\/?><\/div>/g, '<br/>').replace(/<\/div><div/g, '</div><br/></div'), {
    wordwrap: false,
    ignoreImage: true,
    singleNewLineParagraphs: true,
    ignoreHref: true
  });
}