import React, { useEffect } from 'react';
import T from '@mui/material/Typography';
import { checkPayingPro, checkOrg, checkPro, limitationsConfig, isBlaze } from '../../flags';
import { pickLimitations } from '../Version/limitations';
import './VersionComparison.css';
import { showCallout, showWaitForStateChange, toast } from '../../message';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { log } from '../../logging/logging';
import { getAuth, getIdTokenResult } from '../../firebase_shared_driver';
// @ts-ignore
import moment from 'moment';
import { ReferIcon } from '../ReferDialog/refer_utils';
import LockIcon from '@mui/icons-material/Lock';
import BusinessIcon from '@mui/icons-material/Business';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import { subscribeProPayment, subscribeOrgPayment } from '../../bapi';
import VersionPlans from './VersionPlans';
import { useQuery, useTypedSelector, useTypedSelectorDeepEquals } from '../../hooks';
import Box from '@mui/material/Box';
import { Alert, Dialog, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { showPayment } from '../BillingDetails/billing_utils';
import { getState } from '../../getState';
import { randomize } from '../../experiment/experiment';
import { Pricing } from './Pricing';
import { PRICES } from './version_utilities';

export default function VersionComparison() {
  const { billing = 'annually', upgrade } = /** @type {{billing: ("annually"|"monthly"), upgrade: "upgrade-business"|"upgrade-pro"}} */ (useParams());
  const props = usePricingProps();
  const history = useHistory();
  const navigate = history.push;
  const { pathname } = useLocation();
  const query = useQuery();
  const type = /** @type {'pro'|'business'} */ (query.get('type'));
  const redirectStatus = query.get('redirect_status');
  const isNewPricingPage = useTypedSelector(state => isNewPricingPageExperiment(state.userState));

  useEffect(() => {
    if (
      redirectStatus !== 'succeeded'
    ) {
      return;
    }
    // Lets remove the parameters which was redirected from backend.
    // So that user when he uses back button he will nto go into checking state.
    [
      'type',
      'setup_intent',
      'payment_intent',
      'redirect_status',
      'setup_intent_client_secret',
      'payment_intent_client_secret'
    ].forEach(prop => query.delete(prop));
    history.replace(pathname + '?' + query.toString());
    onPurchase(billing, type, navigate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billing, type, redirectStatus]);

  useEffect(() => {
    if (!upgrade || !props.loaded) {
      return;
    }

    if (props.isOrg) {
      toast('You are already part of an organization.', {
        intent: 'info',
        duration: 8000
      });

      history.replace(`/pro/${billing}`);
      return;
    } else if (props.isPayingPro) {
      toast(`You already have a Pro subscription. ${upgrade === 'upgrade-business' ? 'Please upgrade it to Business.' : ''}`, {
        intent: 'info',
        duration: 8000
      });

      history.replace(`/pro/${billing}`);
      return;
    }

    const seats = query.get('seats') ? Number(query.get('seats')) : 1;

    log({
      category: 'New User Account Upgrade',
      action: 'Signed up and upgrading',
      label: {
        upgradeType: upgrade === 'upgrade-business' ? 'business' : 'pro',
        billing,
        seats,
      }
    });

    purchase({
      props,
      done: () => {},
      type: upgrade === 'upgrade-pro' ? 'pro' : 'business',
      billing,
      navigate,
      source: window.location.origin,
      quantity: seats,
      instantUpgrade: true,
    })
      .then(() => {
        log({ category: 'Account Upgrade', action: 'Successfully upgraded', });
      })
      .catch((e) => {
        log({ category: 'Account Upgrade', action: 'Failed to upgraded', label: { e } });

        // the user may want to open it up again, so push instead of replace
        navigate(`/pro/${billing}`, '');
      });
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [props.loaded, upgrade]
  );

  // Don't show the content before we show the payment dialog
  if (upgrade) {
    return <Dialog open sx={{ background: 'hsl(0 0% 99% / 1)' }} hideBackdrop  />;
  }

  if (!props.loaded) {
    return <div style={{ paddingTop: '15vh', marginBottom: 30, justifyContent: 'center', display: 'flex' }}>
      <CircularProgress size={150} thickness={1.9} />
    </div>;
  }

  return (<div style={{
    height: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingTop: 20,
    paddingBottom: 30
  }}>
    {props.balance ? <Alert severity="success" icon={false}
      sx = {{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 5,
        position:'relative',
      }}
    > 
      <T variant="h6">Use your credits! </T>
      <T variant="body1" paragraph>You have <b>${props.balance}</b> worth of credits in your account which can be used towards upgrading. 
      You won't be charged until you have exhausted your credits.</T>
      <Tooltip title="For example if you have $10 credit in your account and you upgrade to Pro monthly ($3.49) then you won't be charged anything 
      for first two months, only $0.47 in the third month and $3.49 from the 4th month onward.


      If you upgrading to Pro annual ($35.88) and you have $20 credit in your account, then you will charged only $15.88 in the first year and 
      $35.88 from second year onward.
      
      If you upgrade to Business, you can only use your own credit towards it.
      
      ">
        <InfoIcon 
          sx={{
            position:'absolute',
            top:5,
            right:5,
            opacity:0.7,
          }}
        />
      </Tooltip>
      
    </Alert>
      : null}
    <div style={{ textAlign: 'center', marginTop: 4, marginBottom: 42 }}>
      <ToggleButtonGroup
        value={billing}
        exclusive
        size="small"
        onChange={(_, newValue) => {
          // You can untoggle a button. In this case newValue
          // will be null. We don't let this do anything.
          const route = window.location.href.includes('/pro') ? '/pro' : '/upgrade';
          if (newValue === 'annually') {
            navigate(route + '/');
          } else if (newValue === 'monthly') {
            navigate(route + '/' + newValue);
          }
        }}
      >
        <ToggleButton value="annually" size="small">
          <T color={billing === 'annually' ? 'textPrimary' : 'textSecondary'} variant="subtitle2">Annual pricing&nbsp;&nbsp;<Chip style={{ opacity: billing === 'annually' ? .9 : .6 }} label="Save up to 15%" size="small" variant="outlined" /></T>
        </ToggleButton>
        <ToggleButton value="monthly" size="small">
          <T color={billing === 'monthly' ? 'textPrimary' : 'textSecondary'} variant="subtitle2">Monthly pricing</T>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>

    {(props.isPro && !props.isPayingPro && props.proGrantExpiry) && <T color="textSecondary" variant="body1" style={{
      textAlign: 'center',
      width: '80%',
      marginTop: 10,
      marginBottom: 40,
      marginLeft: 'auto',
      marginRight: 'auto'
    }} paragraph><ReferIcon width={24} /> Your Text Blaze Pro trial will expire on {moment(props.proGrantExpiry).format('LL')}. Buy Pro now and you won't be billed until then.</T>}

    {isNewPricingPage === false &&
      <>
        <Box
          className="skus"
          sx={{
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'row'
            },
            alignItems: {
              xs: 'center',
              sm: 'center',
              md: 'baseline'
            }
          }}
        >
          <VersionPlans {...props} billing={billing}
            onBuy={({ done, type, source }) => purchase({ done, type, billing, props, navigate, source }).then((tokenReceived) => tokenReceived ? null : done()).catch(() => done())}
          />
        </Box>

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <T variant="body2" style={{ marginBottom: 14 }}><span style={{
            color: '#1db270'
          }}><LockIcon fontSize="small" style={{ verticalAlign: 'middle' }} /> SSL</span> Secured Payment</T>
        </div>
      </>}

    {isNewPricingPage === true &&
      <Pricing
        isAnnually={billing === 'annually'}
        onBuy={({ done, type, source }) => purchase({ done, type, billing, props, navigate, source }).then((tokenReceived) => tokenReceived ? null : done()).catch(() => done())}
        {...props}
      />}
  </div>);
}

function purchase({ props, billing, type, done, navigate, source, quantity = 1, instantUpgrade = false }) {
  log({
    category: 'Purchase',
    action: 'Open payment form',
    label: {
      type
    }
  });
  return new Promise((resolve, reject) => {
    if (props.isPayingPro && type === 'business') {
      showCallout({
        anchorEl: source,
        btnText: 'Upgrade',
        contents: 'This will change your existing subscription from Pro to Business.',
        // We should just upgrade the existing subscription
        onAccept: () => {
          subscribeOrgPayment({
            email: props.email,
            blaze_billing: billing,
            source: window.location.origin,
            quantity: 1
          }).then(() => {
            return onPurchase(billing, type, navigate);
          }).catch(err => {
            if (err === 'closed') {
              onPurchaseFailed(type, err);
            } else {
              toast('Failed to upgrade to Business - ' + err?.message, {
                intent: 'danger'
              });
            }
            done(false);
            reject(err);
          });
        },
        onCancel: () => resolve(),
        startIcon: <BusinessIcon />
      });
    } else {
      const showPaymentForm = () => {
        let amount = (
          PRICES[type][billing]['regular']
          || PRICES[type][billing]
        );
        showPayment({
          returnUrl: {
            path: window.location.href,
            params: {
              type
            },
          },
          title: 'Upgrade to Text Blaze ' + (type === 'pro' ? 'Pro' : 'Business'),
          showSeatSelection: type !== 'pro',
          instantUpgrade,
          btnContents: type !== 'pro' ? 'Subscribe' : `Subscribe \${totalprice}/${(billing === 'monthly' ? 'Month' : 'Year')}`,
          setupBtnContents: 'Add card',
          continueToBtnContents: props.isPayingPro ? 'Subscribe' : 'Continue to payment',
          header: ({
            quantity,
            totalPrice,
            startingBalance,
            endingBalance,
            setupIntent
          }) => <>
            {!!startingBalance && (
              <Alert
                sx={{
                  mb: 2
                }}
                color="info"
              >
                You used ${(startingBalance - endingBalance).toFixed(2)} credits{!!endingBalance && ` ($${endingBalance.toFixed(2)} remaining)`} for the upgrade. Please enter your card details to proceed.
              </Alert>
            )}
            {type !== 'pro' && (
              <>
                <T sx={{ mb: 2 }}>
                  Text Blaze {type === 'pro' ? 'Pro' : 'Business'}: {quantity} x ${amount}/{billing === 'monthly' ? 'Month' : 'Year'}/User
                </T>
                <T sx={{ mb: 2 }}>
                  Total: <b>${totalPrice}/{billing === 'monthly' ? 'Month' : 'Year'}</b>
                </T>
              </>
            )}
          </>,
          price: amount,
          defaultQuantity: quantity,
          billing: billing,
          getSecret: (quantity) => {
            if (type === 'pro') {
              return subscribeProPayment({
                email: props.email,
                blaze_billing: billing,
                source: window.location.origin
              });
            } else {
              return subscribeOrgPayment({
                email: props.email,
                blaze_billing: billing,
                source: window.location.origin,
                quantity
              });
            }
          }
        }).then(async (result) => {
          return onPurchase(billing, type, navigate);
        }).then(() => {
          resolve();
        }).catch((err) => {
          if (err === 'closed') {
            onPurchaseFailed(type, err);
          }
          done(false);
          reject(err);
        });
      };
      showPaymentForm();
      
    }
  });
}

/**
 * 
 * @param {('annually'|'monthly')} billing 
 * @param {('pro'|'business')} type 
 * @param {function} navigate 
 */
const onPurchase = async (billing, type, navigate) => {
  return new Promise((resolve, reject) => {
    log({
      category: 'Purchase',
      action: 'Complete purchase UI',
      label: {
        period: billing,
        type
      }
    });

    if (type === 'pro') {
      showWaitForStateChange({
        message: 'Upgrading to Pro...',
        onSuccessMessage: 'Congratulations, you have unlocked Text Blaze Pro! Enjoy your new Text Blaze features.',
        checkFn: (state) => state.userState.is_pro,
        onClose: () => {
          log({
            category: 'Purchase',
            action: 'Pro purchase success'
          });
          navigate('/welcome');
          resolve();
        }
      });
    } else {
      showWaitForStateChange({
        message: 'Upgrading to Business...',
        onSuccessMessage: 'Congratulations, your new organization has been created. You may now create teams or add users to it.',
        checkFn: (state) => !!state.userState.org,
        onClose: () => {
          const state = getState();
          const orgId = state.userState.org.id;

          // For now triggering both events to make it consistent.
          // Need to remove one of the events.

          log({
            category: 'Organization',
            action: 'New Org created',
            label: { orgId: orgId }
          });
          testOrg(orgId);
        }
      });
      /**
       * 
       * @param {string} newOrgId 
       */
      const testOrg = async (newOrgId) => {
        getIdTokenResult(getAuth().currentUser).then(x => {
          let org = x.claims && x.claims.org && /** @type {{ id: string }} */(x.claims.org).id;
          if (newOrgId === org) {
            // done not called as not needed as we navigate away
            // if this is new flow, navigate to members
            navigate('/business/members');
            
            log({
              category: 'Organization',
              action: 'Create org completed',
              label: { orgId: newOrgId }
            });
            resolve();
          } else {
            return new Promise(res => {
              setTimeout(() => {
                testOrg(newOrgId).then(res);
              }, 2000);
            });
          }
        });
      };
    }
  });
};

const onPurchaseFailed = (type, err) => {
  let failure = (msg) => {
    toast('Could not upgrade Text Blaze.' + ((msg && msg.message) ? (' ' + msg.message) : msg) + ' Contact support@blaze.today for assistance.', {
      duration: 8000,
      intent: 'danger'
    });
  };

  if (type === 'pro') {

    log({
      category: 'Purchase',
      action: 'Pro purchase failed'
    });
    console.log('err', err);
  }

  failure(err);
};

const usePricingProps = function () {
  const storeProps = useTypedSelectorDeepEquals(store => {
    // wait for everything to load, including info to check isPro and isOrg
    // avoids flashing buttons
    let loaded = store.config.configLoaded && store.userState.isLoaded && store.userState.readonlyLoaded;

    if (!loaded) {
      return {
        loaded: false
      };
    }

    let { plan } = limitationsConfig(store);
    let plans = store.config && store.config.plans;
    let skus = {
      basic: pickLimitations(plans, plan, 'basic'),
      pro: pickLimitations(plans, plan, 'pro'),
      business: pickLimitations(plans, plan, 'business')
    };

    return {
      loaded,
      isPro: checkPro(store),
      isPayingPro: checkPayingPro(store),
      isOrg: checkOrg(store),
      email: store.userState.email,
      skus,
      snippetsUpgradeable: skus.basic.MAX_SNIPPETS !== skus.pro.MAX_SNIPPETS,
      groupsUpgradeable: skus.basic.MAX_GROUPS !== skus.pro.MAX_GROUPS,
      groupSizeUpgradeable: skus.basic.MAX_SNIPPETS_PER_GROUP !== skus.pro.MAX_SNIPPETS_PER_GROUP,
      snippetSizeUpgradeable: skus.basic.MAX_SNIPPET_SIZE !== skus.pro.MAX_SNIPPET_SIZE,
      proGrantExpiry: store.userState.pro_grant_expiry && store.userState.pro_grant_expiry.toDate(),
      balance: store.userState.credit_balance
    };
  });

  return storeProps;
};

/**
 * @typedef {ReturnType<usePricingProps>} PricingProps
 */

/**
 * @param {import('@store').UserStateDef} userState
 * @return {boolean|boolean}
 */
function isNewPricingPageExperiment(userState) {
  if (!userState?.isLoaded) {
    return null;
  }

  if (isBlaze(userState)) {
    return true;
  }

  return randomize('New pricing page', {
    type: 'WeightedChoice',
    choices: ['show', 'none'],
    weights: [0.5, 0.5]
  }) === 'show';
}