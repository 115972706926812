import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import { sendMessageToClient } from '../../desktop_utilities';
import T from '@mui/material/Typography';
import { Fade, keyframes } from '@mui/material';
// @ts-ignore
import hiImageSrc from './../../../images/panda/hi_headshot.png';
import './tryMe.css';
import { usersSettingsRef } from '@store';
import { storage } from '../../utilities';
import { isElectronApp } from '../../flags';

/** 
 * @typedef {{ type: 'clear', reason: string }|{ type: 'add', character: string }|{ type: 'pop' }} CustomStreamEventData
 * @typedef {CustomStreamEventData & { stream: string }} CustomStreamEventDetail
 */

const HIGHLIGHT_OPACITY = '90%';
const isElectronFlag = isElectronApp();
const insertionEvents = isElectronFlag ? ['insert finished'] : ['form triggered', 'inserted replacement'];

/**
 * @param {HTMLElement} targetElement 
 * @returns {{ left: number, top: number }}
 */
function getCursorPosition(targetElement) {
  const sel = window.getSelection();

  if (document.activeElement !== targetElement || sel.rangeCount === 0) {
    // Can happen when navigating between pages
    // Or when the textbox is not focused
    return null;
  }

  const range = sel.getRangeAt(0);
  let cursorPosition = range.getBoundingClientRect();
  let { left, top } = cursorPosition;
  // Happens when focusing 2-3 times into an 
  // initially loaded empty div
  if (top === 0 && left === 0) {
    let tmpNode = document.createTextNode('\ufeff');
    range.insertNode(tmpNode);
    cursorPosition = range.getBoundingClientRect();
    tmpNode.remove();
    ({ left, top } = cursorPosition);
  }
  // maintain space from cursor
  left += 5;
  return { left, top };
}

function PulseAnimation({
  duration = 2000,
  children
}) {
  if (duration === 0) {
    return children;
  }

  const pulse = keyframes `
    0% {
      opacity: ${HIGHLIGHT_OPACITY};
    }
    50% {
      opacity: 60%;
    }
    100% {
      opacity: ${HIGHLIGHT_OPACITY};
    }
  `;

  return (
    <Box
      sx={{
        animation: `${pulse} ${duration}ms ease infinite`
      }}
    >
      {children}
    </Box>
  );
}

/**
 * @param {{ keyDisplay: string, suffix: string, fontSize: import("@mui/material/Typography").TypographyProps['variant'], prefix: string }} props
 */
function ToastElement({ keyDisplay, suffix, fontSize, prefix }) {
  return <Box sx={{ display: 'flex', alignItems: 'center', }}>
    <T variant={fontSize}>{prefix}</T>
    <span className="key"><i>{keyDisplay}</i></span>
    <T variant={fontSize}> key {suffix}</T>
  </Box>;
}

/**
 * @param {object} props 
 * @param {boolean} props.showOnboarding we don't show onboarding after user has inserted a snippet
 * @param {string} props.shortcut
 * @param {string} [props.containerId]
 * @param {React.CSSProperties} [props.style]
 * @param {boolean} [props.isSnippetTry] "Try it out" section in snippet view
 * @param {() => boolean} [props.onShortcutTrigger]
 * @param {boolean} [props.disablePulsing]
 * @param {boolean} [props.isSecondAttempt]
 */
function TryMeNew({ showOnboarding, shortcut, containerId, style, isSnippetTry, onShortcutTrigger, isSecondAttempt, disablePulsing }) {
  const [keyPosition, setKeyPosition] = useState(/** @type {{ left: number, top: number }} */ (null));
  const [hideKey, setHideKey] = useState(false);
  const ref = useRef(/** @type {HTMLDivElement} */ (null));
  const initialStreamData = useMemo(() => /** @type {{ expCharacter: string, streamIndex: number, remainingCharacters: string, suffix: string }} */ (shortcut ? { expCharacter: shortcut[0], streamIndex: 0, remainingCharacters: shortcut, suffix: isSecondAttempt ? 'to try another snippet' : isSnippetTry ? 'to get started' : 'to continue' } : null), [shortcut, isSecondAttempt, isSnippetTry]);
  const streamData = useRef(initialStreamData);
  let timeoutId = useRef(0);
  const toastFontSize = useRef(/** @type {import("@mui/material/Typography").TypographyProps['variant']} */ (isSnippetTry ? 'subtitle2' : 'body1'));
  const [toastMessage, setToastMessage] = useState(/** @type {JSX.Element} */ (<T variant={toastFontSize.current}>Click in this text box to get started</T>));

  useEffect(() => {
    return () => sendMessageToClient({ type: 'try-me-box', data: false });
  }, []);

  const showToastWithString = useCallback((/** @type {string} */ toastMsg) => {
    setToastMessage(<T variant={toastFontSize.current} sx={{ whiteSpace: 'pre-wrap' }}>{toastMsg}</T>);
  }, []);

  const updateToastAsPerCharacter = useCallback(() => {
    if (!streamData.current) {
      setToastMessage(null);
    } else {
      const prefix = (streamData.current.streamIndex === 0 && !isSnippetTry) ? 'Now, press the ' : 'Press the';
      setToastMessage(<ToastElement keyDisplay={streamData.current.expCharacter} suffix={streamData.current.suffix} fontSize={toastFontSize.current} prefix={prefix} />);
    }
  }, [isSnippetTry]);

  const showToastWithTimeout = useCallback((/** @type {string} */ toastMsg, timeout = 3000) => {
    showToastWithString(toastMsg);

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = window.setTimeout(() => {
      updateToastAsPerCharacter();
    }, timeout);
  }, [updateToastAsPerCharacter, showToastWithString]);

  useEffect(() => {
    if (!showOnboarding || !shortcut) {
      return;
    }
    if (isSecondAttempt && !streamData.current) {
      streamData.current = initialStreamData;
    }
    // Keep in sync with contentScript.js
    const STREAM_EVENT_NAME = 'TB_streamUpdate';

    function eventListener(event, eventData) {
      if (isElectronFlag && eventData?.type !== STREAM_EVENT_NAME) {
        return;
      }
      if (!streamData) {
        return;
      }
      /** @type {CustomStreamEventDetail} */
      const data = isElectronFlag ? eventData.detail : event.detail;
      /** @type {number} */
      let newStreamLength = data.stream.length;
      let newCharacter = shortcut[newStreamLength];
      /** @type {string} */
      let toastMessage = null;
      let newRemainingStream = shortcut.substring(newStreamLength);
      let shortcutTriggered = false;

      if (data.type === 'add') {
        if (streamData.current?.expCharacter.toLocaleLowerCase() !== data.character.toLocaleLowerCase()) {
          toastMessage = 'Please type the shown character';
          // Show space character to the user so as to clear their stream
          newCharacter = ' ';
          newRemainingStream = ' ';
          newStreamLength = -1;
        }
      } else if (data.type === 'clear') {
        if (insertionEvents.some((v) => data.reason.startsWith(v))) {
          // Once the user triggers their snippet, then
          // anyway we will not trigger this event listener
          toastMessage = 'Snippet triggered!  🎉';
          newCharacter = null;
          shortcutTriggered = true;
        } else if (streamData.current?.expCharacter !== ' ') {
          // Don't show an extra toast message if we wanted the user to clear their stream
          // (because the expected character was space)
          // and then user did clear the stream, either by typing the space or by timeout
          if (data.reason.startsWith('timeout')) {
            toastMessage = 'Please type the shortcut fast';
          } else if (data.reason.startsWith('input whitespace')) {
            toastMessage = 'Please type the shortcut without spaces';
          }
        }
      } else if (data.type === 'pop') {
        // pass, the shown character will update on its own, no need to do much here
      } else {
        // Should never happen, but checking anyway
        console.error('Unknown stream event', data);
        return;
      }

      streamData.current = newCharacter !== null ? { streamIndex: newStreamLength, expCharacter: newCharacter, remainingCharacters: newRemainingStream, suffix: newStreamLength === 0 ? 'to get started' : 'to continue' } : null;

      if (!toastMessage) {
        // We did not override the toast message with an special cases,
        // so now show the default one
        updateToastAsPerCharacter();
      } else {
        let timeout = undefined;
        if (toastMessage.includes('triggered')) {
          // Use shorter timeout when snippet has been triggered
          timeout = 1000;
        }
        showToastWithTimeout(toastMessage, timeout);
      }

      if (shortcutTriggered) {
        storage.update(usersSettingsRef, {
          'quest.snippetTriggered': true,
        }, 'HIDE_AUTOSAVE');
        if (onShortcutTrigger?.()) {
          showToastWithTimeout(isSnippetTry ? 'Great! 🎉 Now try this on any website!' : 'Great!  🎉 Now, try this snippet on any website!');
        }
        setHideKey(true);
        setTimeout(() => setHideKey(false), 1000);
      }
    }

    if (isElectronFlag) {
      const removeListener = window['electronAPI'].attachMessageListener(eventListener);
      return () => {
        removeListener?.();
      };
    } else {
      window.addEventListener(STREAM_EVENT_NAME, eventListener);
      return () => {
        window.removeEventListener(STREAM_EVENT_NAME, eventListener);
      };
    }
  }, [shortcut, showOnboarding, showToastWithTimeout, updateToastAsPerCharacter, onShortcutTrigger, isSecondAttempt, initialStreamData, isSnippetTry]);

  function updateCursorPosition() {
    setKeyPosition(getCursorPosition(ref.current));
  }

  useEffect(() => {
    // Run this once on page mount,
    // or in case the positioning logic changes
    updateCursorPosition();
  }, []);

  /** @type {React.FormEventHandler<HTMLDivElement>} */
  function onInput(event) {
    const target = event.target;
    if (!(target instanceof HTMLDivElement)) {
      return;
    }

    // Without delay, it takes last cursor text position,
    // for example, when you click on heading and then click
    // again inside the div element
    setTimeout(() => updateCursorPosition(), 10);
  }

  const keyCharacterToShow = streamData.current?.remainingCharacters.split('').map(x => x === ' ' ? 'space' : x);

  return <Box sx={{
    position: 'relative',
    width: '100%',
    height: 170,
    minHeight: 60,
  }}>
    <div
      id={containerId || 'try-it-now-home'}
      className={'lpt3-input lpt3-large lpt3-fill allow-blaze' + (!showOnboarding || disablePulsing ? '' : ' animated-try-it-now')}
      data-enable-grammarly="false"
      onFocus={() => {
        // Need to use higher timeout because cursor positioning
        // in snippet try it out fails otherwise (gives wrong value)
        setTimeout(() => updateCursorPosition(), isSnippetTry ? 1000 : 10);
        updateToastAsPerCharacter();
        sendMessageToClient({
          type: 'try-me-box',
          data: true
        });
      }}
      onBlur={() => {
        showToastWithString('Click here again to continue');
        setKeyPosition(null);
        sendMessageToClient({
          type: 'try-me-box',
          data: false
        });
      }}
      contentEditable
      onInput={onInput}
      ref={ref}
      style={{
        height: 170,
        minHeight: 60,
        overflow: 'auto',
        marginTop: 20,
        marginBottom: 20,
        wordBreak: 'break-word',
        resize: 'vertical',
        position: 'relative',
        ...style,
      }}>
    </div>
    {!hideKey && showOnboarding && keyPosition !== null && keyCharacterToShow?.length > 0 && keyCharacterToShow.map((key, i) => {
      const inner = <div className={'key key-primary' + (i > 0 ? ' key-next' : '')} style={{
        top: keyPosition.top,
        left: keyPosition.left + (i > 0 ? 28 : 0) + (i > 1 ? (i - 1) * 23 : 0),
        opacity: i === 0 ? HIGHLIGHT_OPACITY : '50%',
      }}>
        <i>{key}</i>
      </div>;
      return <PulseAnimation key={key + i} duration={i === 0 ? 2000 : 0}>
        {inner}
      </PulseAnimation>;
    })}
    {!!toastMessage && showOnboarding && <Box sx={{
      // Align the toast at the bottom of the container, so
      // we use position 'absolute' here
      position: 'absolute',
      margin: '20px',
      bottom: 0,
      left: 0,
      height: '30px',
      width: 'calc(100% - 40px)',
      color: 'white',
      background: '#01acc0',
      borderRadius: '8px',
      padding: '6px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '40px',
      ...(isSnippetTry ? { 
        gap: '10px',
        width: 'calc(100% - 5px)',
        margin: '15px 2px',
      } : {})
    }}>
      <img src={hiImageSrc} alt="Panda waving hi" style={{
        width: '84px',
        position: 'relative',
        bottom: '10px',
      }} />
      <div style={{ textAlign: 'center', }} onClick={() => {
        ref.current.focus();
      }}>
        <Fade in>
          <div>{toastMessage}</div>
        </Fade>
      </div>
    </Box>}
  </Box>;
}

export default TryMeNew;
