import { randomize } from './experiment/experiment';
import { isAiBlaze, wasOnboardedWithAiBlaze } from './aiBlaze';
import { isMacPlatform, isWinPlatform, isDev, isElectronApp, getIsWebpageEmbedded, isAndroid, MY_WEB_VERSION, APP_TARGET, } from './raw_flags';

export const FOLDER_VIEW_KEY = 'desktop_assistant_view';
export const FOLDER_COLLAPSE_KEY = 'desktop_assistant_folder_collapse';
export const DESKTOP_PROTOCOL = isAiBlaze ? 'aiblaze' : 'textblaze';


/**
 * @param {import('@store').UserStateDef} userState 
 * 
 * @return {boolean}
 */
export const isCommentsEnabled = (userState) => userState && userState.email && userState.emailVerified;


/**
 * @param {import('@store').UserStateDef} userState 
 * 
 * @return {boolean}
 */
export function isBlaze(userState) {
  return (userState && userState.email && userState.emailVerified && userState.email.endsWith('@blaze.today'));
}

/**
 * @return {boolean} true if on docs or community site (not on dashboard)
 */
export function isCommunityDocs() {
  if (window.location.port === '1313') {
    // dev docs site port
    return true;
  }
  return !isDev() && !isSpark() && !isProduction();
}

/**
 * @return {boolean} true if on docs or community site or embedded public space/bundle
 */
export function isCommunityDocsOrBundle() {
  if (typeof vi !== 'undefined') {
    return false;
  }

  return isPublicSpace() || isCommunityDocs();
}

/**
 * This function is usable across frontend and extension files alike
 * It uses an explicit whitelist to determine community/docs
 */
export function isCommunityDocsWhitelist() {
  if (typeof vi !== 'undefined' || typeof window === 'undefined') {
    return false;
  }

  if (window.location.port === '1313') {
    // dev docs site port 
    return true;
  }

  return ['community.', 'blaze.'].some(d => window.location.hostname.startsWith(d));
}

/**
 * @return {boolean} true if on public space page
 */
export function isPublicSpace() {
  return window.location.pathname.startsWith('/public/embed/space');
}


/**
 * @return {boolean} true if we are running on a spark
 */
export function isSpark() {
  return window.location.hostname.includes('spark');
}


/**
 * @return {boolean} true if we are running on local
 */
export function isLocal() {
  return ['3000', '3001'].includes(window.location.port);
}


/**
 * @return {boolean} true if we are running on production sites like dashboard.blaze.today
 */
export function isProduction() {
  let hostname = window.location.hostname;
  return [
    'dashboard',
    'text',
    'data',
    'page',
    'ai'
  ].some(d => hostname.startsWith(d));
}

/**
 * 
 * @returns {boolean}
 */
export function isChromium() {
  return !!window.chrome;
}

/**
 * 
 * @returns {boolean}
 */
export function checkExtensionDefaultChoice() {
  // Chrome extension is default if the desktop app is not supported on that platform
  return isChromium() || !supportsDesktopApp();
}

/**
 * 
 * @returns {boolean}
 */
export function supportsDesktopApp() {
  return isMacPlatform() || isWinPlatform();
}

export function isMacApp() {
  // return true if code is running inside macOS app 
  return isMacPlatform() && isElectronApp();
}

/**
 * 
 * @returns {boolean} true if we are running on Windows app
 */
export function isWinApp() {
  return isElectronApp() && isWinPlatform();
}

/**
 * Whether or not the user is in an org.
 * 
 * @param {Pick<import('@store').RootState, 'userState'>} state 
 * 
 * @return {boolean}
 */
export function checkOrg(state) {
  return !!(state.userState && state.userState.org && state.userState.org.id);
}


/**
 * @param {import('@store').RootState} state 
 * 
 * @return {string}
 */
export function orgId(state) {
  if (!state || !state.userState || !state.orgState) {
    return undefined;
  }
  if (!state.userState.org || !(state.orgState.org && state.orgState.org.id)) {
    return undefined;
  }
  return state.orgState.org.id;
}


/**
 * @param {import('@store').RootState} state 
 * 
 * @return {string}
 */
export function orgName(state) {
  if (!state || !state.userState || !state.orgState) {
    return undefined;
  }
  if (!state.userState.org || !(state.orgState.org && state.orgState.org.name)) {
    return undefined;
  }
  return state.orgState.org.name;
}


/**
 * @param {import('@store').RootState} state 
 * @param {string} field
 * 
 * @return {any}
 */
export function orgReadonlyField(state, field) {
  if (!state || !state.userState || !state.orgState) {
    return undefined;
  }
  if (!state.userState.org || !(state.orgState.org && state.orgState.org.readonly)) {
    return undefined;
  }

  return state.orgState.org.readonly[field];
}



/**
 * @param {import('@store').RootState} state 
 * 
 * @return {string[]}
 */
export function orgAutoJoinDomains(state) {
  // auto_join_domains may only be used by enterprise organizations
  return orgReadonlyField(state, 'is_enterprise') ? orgReadonlyField(state, 'auto_join_domains') : undefined;
}

/**
 * @param {import('@store').RootState} state 
 * 
 * @return {boolean}
 */
export function orgShouldAutoJoinOnSignup(state) {
  const autoJoinDomains = orgAutoJoinDomains(state);
  // auto_join_domains may only be used by enterprise organizations
  return autoJoinDomains && autoJoinDomains.length
    ? !!orgReadonlyField(state, 'should_auto_join_on_signup') : false;
}


/**
 * @param {import('@store').RootState} state 
 * 
 * @return {object}
 */
export function billingIssue(state) {
  if (!state || !state.userState) {
    return null;
  }

  if (!checkPayingPro(state)) {
    // If we're not pro, we don't show any billing issue even if one exists
    return null;
  }

  let type, issue, email;
  if (checkOrg(state)) {
    if (!state.orgState || !state.orgState.org) {
      return null;
    }

    issue = orgReadonlyField(state, 'billing_alert');
    if (!issue) {
      return null;
    }

    type = {
      type: 'org',
      orgId: orgId(state)
    };

    email = state.orgState.org.email;

  } else {
    issue = state.userState.billing_alert;

    if (!issue) {
      return null;
    }

    type = {
      type: 'user'
    };

    email = state.userState.email;
  }

  return { type, issue, email, isEnterprise: checkEnterprise(state), userType: userType(state) };
}

/**
 * See if an organization level preference is set.
 * 
 * @param {Pick<import('@store').RootState, 'userState'|'orgState'>} state 
 * @param {string} name 
 * @param {object=} nonOrgDefault - default if user is not in an org
 * @param {object=} orgDefault - default if user is in an org
 * 
 * @return {object}
 */
export function orgPref(state, name, nonOrgDefault = undefined, orgDefault = undefined) {
  if (!['xSharingDisabled', 'shouldWhitelistCommands', 'commandWhitelist', 'domainWhitelist', 'smartsEnabled', 'userAddonsEnabled', 'userConnectedDisabled', 'userBlazeChatDisabled'].includes(name)) {
    throw Error('Unknown org pref: ' + name);
  }

  if (!state || !state.userState || !state.userState.org) {
    return nonOrgDefault;
  }

  if (!state.orgState || !state.orgState.org) {
    return orgDefault;
  }

  if (state.orgState.org.options) {
    return (name in state.orgState.org.options) ? state.orgState.org.options[name] : orgDefault;
  } else {
    return orgDefault;
  }
}

/** @param {Pick<import('@store').RootState, 'userState'|'orgState'>} userData */
export function getOrgCommandsWhitelist(userData) {
  return orgPref(userData, 'shouldWhitelistCommands') ? (orgPref(userData, 'commandWhitelist') || []) : null;
}


/**
 * 'individual' if not part of an organization, otherwise 'member', 'editor' or 'owner'
 * 
 * @return {('individual'|'member'|'editor'|'owner')}
 */
export function userType(state) {
  if (!state || !state.userState || !state.orgState) {
    return 'individual';
  }
  if (!state.userState.org || !state.orgState.org) {
    return 'individual';
  }
  return state.userState.org.type;
}


/**
 * Whether the user is an owner of the organization.
 * 
 * @return {boolean}
 */
export function isOrgOwner(state) {
  return !!(state && state.userState && state.userState.org && state.userState.org.type === 'owner');
}


/**
 * If the user is a member (not owner/editor) of an org, sees if a specific restriction applies.
 * 
 * @param {import('@store').RootState} state
 * @param {string} restriction
 * 
 * @return {boolean} true if the restriction applies
 */
export function memberRestricted(state, restriction) {
  if (!['create', 'share'].includes(restriction)) {
    throw Error('Unknown member restriction: ' + restriction);
  }

  if (!state || !state.userState || !state.orgState) {
    return false;
  }
  if (!state.userState.org || !state.orgState.org) {
    return false;
  }
  if (state.userState.org.type !== 'member') {
    // restrictions only apply to members
    return false;
  }
  return state.orgState.org.options ? (state.orgState.org.options['memberRestrictions'] || '').includes(restriction) : false;
}


/**
 * @param {import('@store').RootState} state 
 * 
 * @return {boolean}
 */
export function checkOrgRestrictionsLoaded(state) {
  return !!(state.userState && state.userState.readonlyLoaded && (!state.userState.org || state.userState.org.type !== 'member' || (state.orgState && state.orgState.org && state.orgState.org.id)));
}


/**
 * Return true if the org has been loaded or the user isn't part of an org.
 * 
 * @param {import('@store').RootState} state 
 * 
 * @return {boolean}
 */
export function orgLoadedIfNeeded(state) {
  return !!(state.userState && state.userState.readonlyLoaded && (!state.userState.org || (state.orgState && state.orgState.org && state.orgState.org.id)));
}


const DEFAULT_PLAN = 'DEFAULT';

/**
 * @param {import('@store').RootState} state 
 * 
 * @return {{ plan: 'DEFAULT' | 'SNIPPET_2020' | 'SNIPPET_2020_R1' | 'SNIPPET_2022' | '_STANDARD', adjustments: object }}
 */
export function limitationsConfig(state) {
  let plan, adjustments;
  if (checkOrg(state)) {
    plan = orgReadonlyField(state, 'capabilities_plan') || DEFAULT_PLAN;
    adjustments = orgReadonlyField(state, 'capabilities_adjustment');
  } else {
    if (!state.userState || !state.userState.options || !state.userState.options.capabilities_plan) {
      plan = DEFAULT_PLAN;
    } else {
      plan = /** @type {any} */ (state.userState.options.capabilities_plan);
    }
    adjustments = state.userState && state.userState.capabilities_adjustment;
  }
  return { plan, adjustments };
}


/**
 * @param {Pick<import('@store').RootState, 'userState'>} state 
 * 
 * @return {boolean}
 */
export function checkPro(state) {
  if (!state || !state.userState) {
    return undefined;
  }
  let proExpiry = state.userState.pro_grant_expiry && state.userState.pro_grant_expiry.toDate && state.userState.pro_grant_expiry.toDate();
  return !!(checkPayingPro(state) || (proExpiry && (new Date()) < proExpiry));
}


/**
 * @param {Pick<import('@store').RootState, 'userState'>} state 
 * 
 * @return {boolean}
 */
export function checkPayingPro(state) {
  if (!state || !state.userState) {
    return undefined;
  }
  return state.userState.is_pro || !!checkOrg(state);
}


/**
 * Whether or not the user is in an enterprise org.
 * 
 * @param {import('@store').RootState} state 
 * 
 * @return {boolean}
 */
export function checkEnterprise(state) {
  if (!state || !state.userState || !state.orgState) {
    return undefined;
  }
  if (!state.userState.org || !state.orgState.org) {
    return false;
  }

  return !!orgReadonlyField(state, 'is_enterprise');
}

/**
 * Get the Business logo.
 * 
 * @param {import('@store').RootState} state 
 * 
 * @return {string}
 */
export function getOrgLogo(state) {
  return state.orgState?.org?.options?.logo;
}

/**
 * Gets a referral cookie if one exists.
 * 
 * Note this cookie is set by the docs site in a different
 * repository.
 * 
 * @return {string}
 */
export function getReferralCookie() {
  let v = document.cookie.match('(^|;) ?ref=([^;]*)(;|$)');
  return v ? unescape(v[2]) : null;
}


/**
 * Gets a landing page cookie if one exists.
 * 
 * Note this cookie is set by the docs site in a different
 * repository.
 * 
 * @param {''|'s'=} prefix - blank for regular cookie, 's' for short-term
 * 
 * @return {object}
 */
export function getLPCookie(prefix = '') {
  try {
    const v = document.cookie.match('(^|;) ?' + prefix + 'blpp_data=([^;]*)(;|$)');
    if (!v) {
      return null;
    }
    const blpp_data = JSON.parse(unescape(v[2]));
    for (let key in blpp_data) {
      blpp_data[key] = decodeURIComponent(atob(blpp_data[key]));
    }

    // Backward compatibility (same key `lp` while logging)
    blpp_data['lp'] = blpp_data['landingPage'];
    delete blpp_data['landingPage'];
    return blpp_data;
  } catch (err) {
    console.error('Get LP cookie failed:', err.message);
  }
  return null;
}


/**
 * Gets a token cookie if one exists.
 * 
 * Note this cookie is set by the firebase functions.
 * 
 * @return {string}
 */
export function getTokenCookie() {
  return getCookie('saml-fb-token');
}


/**
 * Gets a auth cookie if one exists.
 * 
 * Note this cookie is set by the firebase functions.
 * 
 * @return {string}
 */
export function getAuthCookie() {
  return getCookie('__session');
}

function getCookie(name) {
  let v = document.cookie.match(`(^|; )${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;

}

export function deleteTokenCookie() {
  document.cookie = 'saml-fb-token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=blaze.today;path=/';
}

/**
 * @param {import('@store').RootState} state
 *
 * @return {boolean} if the addon content should be visible in the side bar (and searchable)
 */
export function shouldShowAddonGroup(id, state) {
  if (state.userState && state.userState.groups && state.userState.groups[id]) {
    return true;
  }
  if (state.orgState.org) {
    if (state.orgState.org.default_groups && state.orgState.org.default_groups.includes(id)) {
      return true;
    }
    if (state.orgState.teams) {
      for (let team of Object.values(state.orgState.teams)) {
        if (team.groups && team.groups.includes(id)) {
          return true;
        }
      }
    }
  }

  return false;
}


/**
 * @param {string} id 
 * @param {import('@store').UserStateDef} userState 
 * @param {import('@store').OrgStateDef} orgState 
 * @returns {{installedBy: ('organization'|'user'), approvedGrants: GrantsType}}
 */
export function getAddonInstallation(id, userState, orgState) {
  /** @type {'organization'|'user'} */
  let installed;
  let approvedGrants;

  if (userState.addons && userState.addons[id] && userState.addons[id].enabled) {
    // we need to check enabled as an org installed addon
    // can still have user specific data stored in the userState.addons object
    installed = 'user';
    approvedGrants = userState.addons[id].approved_grants;
  }
  if (orgState &&
    orgState.org &&
    orgState.org.addons &&
    orgState.org.addons[id]) {
    // we don't need to check for 'enabled' as the object won't exist if it
    // wasn't installed on the group level
    installed = 'organization';
    approvedGrants = orgState.org.addons[id].approved_grants;
  }

  return {
    installedBy: installed,
    approvedGrants
  };
}


/**
 * Get the settings data for an addon. Also indicates install source.
 * 
 * The id returned will be the addon id if it is installed. Otherwise
 * (for local development) it will be the group id.
 * 
 * @param {GroupObjectType} group
 * @param {import('@store').UserStateDef} userState
 * @param {import('@store').OrgStateDef} orgState
 * @param {'organization'|'user'} installedOverride - used during installation to specify the installed source
 * 
 * @return {{id: string, installed: ('organization'|'user'), data: object, approvedGrants: ('LOCAL_DEVELOPMENT'|GrantsType)}}
 */
export function getAddonData(group, userState, orgState, installedOverride = null) {
  let formNames = (group.options.addon.config && group.options.addon.config.form_names) || {};

  let id, userData, orgData;
  /** @type {'LOCAL_DEVELOPMENT'|GrantsType} */
  let approvedGrants;

  if (installedOverride) {
    // It's from the store.
    // Approved grants aren't needed in this case as it hasn't
    // been installed yet.
    id = group.associated_addon_id;
  }

  let installed = installedOverride;
  if (!installed && group.associated_addon_id) {
    // we use the addon id if it is an installed addon
    id = group.associated_addon_id;
    let installation = getAddonInstallation(id, userState, orgState);
    installed = installation.installedBy;
    approvedGrants = installation.approvedGrants;
    if (installed === 'organization') {
      orgData = orgState.org.addons[id].data;
    }
  }
  if (!installed) {
    // if it's not installed and we're doing local development
    // we use the group id
    id = group.id;
    installed = 'user';
    approvedGrants = 'LOCAL_DEVELOPMENT'; // grant all accesses
  }
  userData = userState.addons && userState.addons[id] && userState.addons[id].data;

  if (installed === 'user') {
    for (let key in userData) {
      if (!(key in formNames)) {
        delete userData[key];
      }
    }
  } else {
    for (let key in userData) {
      if (!(key in formNames) || formNames[key].type !== 'standard') {
        delete userData[key];
      }
    }
    for (let key in orgData) {
      if (!(key in formNames) || formNames[key].type !== 'shared') {
        delete orgData[key];
      }
    }
  }

  let data = Object.assign({}, userData, orgData);

  // We make sure each formName has a least a blank value so
  // we don't get "name does not exist" errors
  for (let key in formNames) {
    if (!(key in data)) {
      data[key] = formNames[key].default;
    }
  }

  return {
    installed,
    data,
    id,
    approvedGrants
  };
}


/**
 * @typedef {object} ConnectedConfig
 * @property {string[]} pingHostWhitelist
 * @property {string[]} loadHostWhitelist
 * @property {string[]} connectedAddonWhitelist
 * @property {Object<string, string[]>} databaseQueryWhitelist
 * @property {string=} pingHostWhitelistErrorTemplate - supports '{domain}' and '{command}'
 * @property {string=} loadHostWhitelistErrorTemplate - supports '{domain}' and '{command}'
 * @property {string=} databaseQueryWhitelistErrorTemplate - supports '{command}'
 * @property {string=} connectedAddonWhitelistErrorTemplate - supports '{domain}' and '{command}'
 */

/**
 * Given a userState (containing uid and org id info) and a group, determines the connected config
 * settings for rendering.
 * 
 * @param {import('@store').UserStateDef} userState
 * @param {Partial<GroupObjectType>} groupData
 * @param {boolean=} isPageBlaze
 *
 * @return {{isConnected: boolean, invalidShare?: boolean, config: ConnectedConfig}}
 */
export function getConnectedConfigOptions(userState, groupData, isPageBlaze) {
  let connectedSettings = groupData.connected;
  let userOrgId = userState && userState.org && userState.org.id;
  let uid = userState && userState.uid;
  const itemName = isPageBlaze ? 'Page' : 'Snippet';

  const invalidShare = (sharedErrorItem) => {
    let sharedError = 'Cannot use {command} in a shared ' + sharedErrorItem + ' outside a Text Blaze Business organization';
    return {
      isConnected: false,
      invalidShare: true,
      config: {
        pingHostWhitelist: [],
        loadHostWhitelist: [],
        connectedAddonWhitelist: [],
        databaseQueryWhitelist: {},
        pingHostWhitelistErrorTemplate: sharedError,
        loadHostWhitelistErrorTemplate: sharedError,
        connectedAddonWhitelistErrorTemplate: sharedError,
        databaseQueryWhitelistErrorTemplate: sharedError
      }
    };
  };

  if (connectedSettings && connectedSettings.is_connected) {
    // if we're not the connector disallow it
    if (!connectedSettings.connector) {
      // This should not be possible as rules should block it,
      // but let's keep it as a safety check.
      return invalidShare(`Connected ${itemName}`);
    } else if (!isPageBlaze && !(
      connectedSettings.connector === 'u:' + uid
      || (userOrgId && connectedSettings.connector === 'o:' + userOrgId)
    )) {
      return invalidShare(`Connected ${itemName}`);
    }

    // it's a valid connection, allow the specified accesses
    return {
      isConnected: true,
      config: {
        pingHostWhitelist: isPageBlaze ? null : (connectedSettings.ping_hosts || []),
        loadHostWhitelist: isPageBlaze ? null : (connectedSettings.load_hosts || []),
        connectedAddonWhitelist: isPageBlaze ? null : (connectedSettings.addons || []),
        databaseQueryWhitelist: connectedSettings.database_queries || {},
        // IMPORTANT: these are depended on by the error handler in shared.js
        // Don't change without updating there.
        pingHostWhitelistErrorTemplate: `${itemName} not connected to send data to {domain}`,
        loadHostWhitelistErrorTemplate: `${itemName} not connected to send data to and load data from {domain}`,
        databaseQueryWhitelistErrorTemplate: `${itemName} not connected to run this query on this space`,
        connectedAddonWhitelistErrorTemplate: '{command} is not connected'
      }
    };
  } else {
    // not connected, don't allow any access
    // IMPORTANT: this is depended on by the error handler in shared.js
    // Don't change without updating there.
    let whitelistErrorTemplate = `You can only use {command} in a Connected ${itemName}`;
    return {
      isConnected: false,
      config: {
        pingHostWhitelist: [],
        loadHostWhitelist: [],
        connectedAddonWhitelist: [],
        databaseQueryWhitelist: {},
        pingHostWhitelistErrorTemplate: whitelistErrorTemplate,
        loadHostWhitelistErrorTemplate: whitelistErrorTemplate,
        databaseQueryWhitelistErrorTemplate: whitelistErrorTemplate,
        connectedAddonWhitelistErrorTemplate: whitelistErrorTemplate
      }
    };
  }
}

let textAppDomain = 'https://dashboard.blaze.today';
let dataAppDomain = 'https://data.blaze.today';
let pageAppDomain = 'https://page.blaze.today';
let aiAppDomain = 'https://ai.blaze.today';

// This should not be added for production build
if (import.meta.env.VITE_APP_DEPLOY_TARGET !== 'production') {
  const APP_PAIRS = {
    production: {
      TEXT: textAppDomain,
      DATA: dataAppDomain,
      PAGE: pageAppDomain,
      AI: aiAppDomain,
    },
    spark: {
      TEXT: 'https://spark.blaze.today',
      DATA: 'https://spark-data.blaze.today',
      PAGE: 'https://spark-page.blaze.today',
      AI: 'https://spark-ai.blaze.today',
    },
    local: {
      TEXT: 'http://localhost:3000',
      DATA: 'http://localhost:3010',
      PAGE: 'http://localhost:3020',
      AI: 'http://localhost:3040',
    }
  };
  const locationHost = window.location.host;

  const APP_PAIR = locationHost.startsWith('localhost') ? APP_PAIRS.local
    : locationHost.startsWith('spark') ? APP_PAIRS.spark
      : APP_PAIRS.production;
  if (APP_PAIR.TEXT === textAppDomain) {
    console.error('Dev alert');
  }
  textAppDomain = APP_PAIR.TEXT;
  dataAppDomain = APP_PAIR.DATA;
  pageAppDomain = APP_PAIR.PAGE;
  aiAppDomain = APP_PAIR.AI;
}

export const AI_APP_DOMAIN = aiAppDomain;
export const TEXT_APP_DOMAIN = textAppDomain;
export const DATA_APP_DOMAIN = dataAppDomain;
export const PAGE_APP_DOMAIN = pageAppDomain;

/** 
 * Used only for adjusting the UI components
 * @type {SupportedPlatformNames}
 */
export const CURRENT_PLATFORM = isElectronApp() ? (isMacPlatform() ? 'mac' : 'windows') : 'browser';

// User will be prompted to install these addons if needed for a snippet
export const INSTALLABLE_ADDONS = {
  linkedin: {
    id: 'PLSoMNmk1ihX91qyU73K',
    name: 'LinkedIn'
  },
  openai: {
    id: '0vJtXxohFCmRX1Jc6Ru6',
    name: 'OpenAI'
  },
  gmail: {
    id: '6sdaXONs1k8WHdDoRMmO',
    name: 'Gmail'
  },
  capitalize: {
    id: 'ZSrGf4pcXpRhWdCoDyVE',
    name: 'Capitalize'
  },
  random: {
    id: 's03nRaUlkckJZ0lAhgfN',
    name: 'Randomize'
  }
};

/**
 * @param {Pick<import('@store').RootState, 'userState'>} store 
 * @returns {boolean}
 */
export function isAISnippetsEnabled(store) {
  if (isElectronApp() && APP_TARGET === 'TEXT') {
    return false;
  }

  // side-step the other checks if the user came from AI Blaze
  // even from Text Blaze, because it's weird if they have AI snippets in AI Blaze and not in TB
  if (wasOnboardedWithAiBlaze(store.userState)) {
    return true;
  }

  if (!store.userState.readonlyLoaded) {
    return false;
  }

  if (!store.userState.emailVerified) {
    return false;
  }

  // Enable for the team
  if (isBlaze(store.userState)) {
    return true;
  }

  // leo, villi, paulma, mark, tom
  if (['ixltxpNUuRdAGX', 'uqM0jMvITXMhZ0', 'KOCIjLDS88dykp', 'wk7W2m4IFAWzRJ', 'krQuacjwWgOk7M',
    // monica.scholte@air-up.com
    // callum.whitley@air-up.com
    // romy.schrijver@air-up.com
    // elif.kurutas@air-up.com
    // yasmina.benlagha@air-up.com
    // meredith.chamen@air-up.com
    // monica.scholte@air-up.com
    // michelle.baeumer@air-up.com
    // jennifer.page@air-up.com
    // jahan.guldurdyyeva@air-up.com
    // lisa.deboer@air-up.com
    // leslie.beck@air-up.com
    // edith.schueller@air-up.com
    // sam.cordoba@air-up.com
    // martina.toso@air-up.com
    // alissa.carlsson@air-up.com
    // raghdah.alhelly@air-up.com
    // kelsey.katkin@air-up.com
    '7EKxp9AAMiYYZ2',
    'BeWe7jcji7ODp0',
    'Ph7njMiJLbMyYh',
    'DPcj3ka8nkdEoC',
    'fMhtP5V1zdPC4c',
    '6b8OyWKBBdTCMx',
    '7EKxp9AAMiYYZ2',
    'REhPZkzenUSEhw',
    'qMunQ7QuQHQHnT',
    'GnaqM4R9unhyeD',
    'Lv6VMVQ2gVNFo2',
    '6Y9U4QSNrwTxy0',
    'uDzG6kB7Jia4F4',
    'Ols7wYsIyvhhnN',
    'NBRgYQqAMTceDv',
    'eS30WEEJUxcthd',
    'qI19iRlXtoMM8q',
    'qsW0pfXhZRb5nq',
    // ryan@whalesellingsystem.com
    'YtHDkDgaOIfbTc',
    // dagenais.charles@gmail.com
    'vGyMudv3Hyc9AB',
    // bsiegal@gmail.com
    'MYiteUAek2NtIc',
    // skoteff@k12.com/sbjacksclass@gmail.com
    'GCRXWkiIN2XJfE',
    // edward@darrahcounseling.com
    'NyRPHgcYQ4PrTK',
    // simonm@giftbasketsoverseas.com 
    'nmYAzvPT0TfXo3'
  ].some(x => store.userState.uid.startsWith(x))) {
    return true;
  }

  // Disable for org users initially as we haven't figured out the privacy
  // aspects yet
  if (checkOrg(store)) {
    // Once org is whitelisted, this is the condition to use
    // isOrgOwner(store) || orgPref(store, 'userBlazeChatDisabled', '', '') !== 'none'
    return false;
  }

  // For non-paying pro users, we still show the UI but then show a different UI
  // when they try to submit a message
  const showFraction = 0.1;
  const randomResult = randomize('AISnippets', {
    type: 'WeightedChoice',
    choices: ['show', 'none'],
    weights: [showFraction, 1 - showFraction]
  });

  return randomResult === 'show';
}

/**
 * @param {Pick<import('@store').RootState, 'userState'|'orgState'>} store 
 * @returns {boolean}
 */
export function isAISnippetsAllowedPageContent(store) {
  const expected = 'full';
  return isOrgOwner(store) || orgPref(store, 'userBlazeChatDisabled', expected, expected) === expected;
}

// if editing, edit in chrome_extension/remote.js also
export const TABLES_BACKEND_DOMAIN = 'https://data-api.blaze.today'; // 'http://localhost:8000';
export const TABLES_BACKEND_ASGI_DOMAIN = 'https://data-owl.blaze.today'; // 'http://localhost:8000';
export const TABLES_FRONT_END_DOMAIN = 'https://data-embed.blaze.today'; // 'http://localhost:3005';
// Page Blaze URLs
export const PAGE_ENGINE_PUBLISHED_DOMAINS = import.meta.env.VITE_APP_DEPLOY_TARGET !== 'production' ?
  ['https://spark.pageblaze.com'] :
  ['https://pageblaze.com', 'https://wwww.pageblaze.com']; // ['http://localhost:3030'];
export const PAGE_ENGINE_PUBLISHED_DOMAIN = PAGE_ENGINE_PUBLISHED_DOMAINS[0];
export const PAGE_ENGINE_PRIVATE_DOMAIN_LOCAL = 'http://localhost:3030';
export const AUTH_URL_PREFIX = '/p/';

if ([TABLES_BACKEND_DOMAIN, TABLES_FRONT_END_DOMAIN].some(x => x.includes('localhost'))) {
  console.warn('Do NOT use local database in production.');
}

if (PAGE_ENGINE_PUBLISHED_DOMAINS.some(x => x.includes('localhost'))) {
  console.warn('Do NOT use local Page Blaze in production.');
}

// AI is served on same hostname as public backend
// but on a different protocol and path
const autoWriteEndpoint = new URL(TABLES_BACKEND_ASGI_DOMAIN);
autoWriteEndpoint.pathname = '/ai/autowrite/';
autoWriteEndpoint.protocol = TABLES_BACKEND_ASGI_DOMAIN.startsWith('https:') ? 'wss:' : 'ws:';
export const AUTOWRITE_ENDPOINT = autoWriteEndpoint.toString();

// Constants for desktop apps
export const DESKTOP_APP_NAME = isMacPlatform() ? 'macOS App' : 'Windows App';
export const APP_PLATFORM_NAME = isMacPlatform() ? 'macOS' : 'Windows';
export const DESKTOP_PLATFORM = isMacPlatform() ? 'mac' : 'windows';

/**
 * Page Blaze flags
 */
export const PAGE_API_PREFIX = 'pageblaze';

export function isPageBlazeEnabled(store) {
  if (isBlaze(store.userState)) {
    return true;
  }

  // Abdalla's personal account
  if (store.userState && store.userState.uid && store.userState.uid.endsWith('tr5rYEZzElyCD3')) {
    return true;
  }

  return false;
}


/**
 * @param {Pick<import('@store').RootState, 'userState'>} store 
 * @returns {boolean}
 */
export function hasUserInsertedAnySnippet(store) {
  if (store.userState?.usage?.stats?.characters > 0) {
    // Updated via a backend job once daily
    // for any snippet trigger anywhere
    return true;
  }
  if (store.userState?.quest?.snippetTriggered) {
    // Updated instantly when snippet is 
    // triggered inside the onboarding dialog
    return true;
  }
  return false;
}


/**
 * @param {Pick<import('@store').RootState, 'userState'>} store 
 * @returns {boolean}
 */
export function isPartOfNewGuidedOnboardingExperiment(store) {
  if (APP_TARGET !== 'TEXT') {
    // Don't show on Page Blaze/Data Blaze (even by accident)
    return false;
  }

  if (!isChromium()) {
    // Don't show for users using desktop app
    // via Firefox/Safari
    return false;
  }

  if (!store.userState?.readonlyLoaded) {
    // Wait for user profile data to load
    return false;
  }

  // The onboarding experiment is only for new users
  // we don't want to mix old users into this experiment
  // as they might have triggered a snippet previously

  const userCreationTime = store.userState.firebaseMetadata?.creationTime;
  if (!userCreationTime) {
    return false;
  }
  const userCreatedAt = new Date(userCreationTime).getTime();
  if (userCreatedAt < new Date('2024-03-15').getTime()) {
    return false;
  }

  const showFraction = 0.1;
  const randomResult = randomize('Guided Try Onboarding', {
    type: 'WeightedChoice',
    choices: ['show', 'none'],
    weights: [showFraction, 1 - showFraction]
  });

  return randomResult === 'show';
}



/**
 * TIMEOUT DELAY
 */
export const ERROR_DELAY_MS = 300;

export const COMMAND_UPDATE_DATA_TIMER = 500;

export const COMMAND_HIGHLIGHT_TOKEN_DATA_TIMER = 500;

export const AI_BACKEND_ENDPOINT = import.meta.env.VITE_APP_CONFIG === 'stage' ? 'https://spark-ai-api.blaze.today' : 'https://ai-api.blaze.today'; // 'http://localhost:8080';

export { isDev, isWinPlatform, isMacPlatform, getIsWebpageEmbedded, isElectronApp, isAndroid, MY_WEB_VERSION, APP_TARGET, };