import React, { useContext } from 'react';
import { dynamicStyle } from './shared';
import { ShowError, AttemptedInsertContext } from './SharedComponents';
import NodeEvaluator from './NodeEvaluator';


/**
 * @param {object} props
 * @param {import("../../snippet_processor/ParseNode").default} props.node
 * @param {import("../../snippet_processor/DataContainer").Environment} props.env
 */
function NodeRendererBase(props) {

  let attemptedInsert = useContext(AttemptedInsertContext);

  return <NodeEvaluator
    node={props.node}
    env={props.env}
    renderer={(res) => {
      if (res.type === 'error') {
        if (res.info.show === 'validate' && !attemptedInsert) {
          return <></>;
        }
        return <ShowError msg={res.info.message} blocking={res.info.blocking} nodeOrAddonId={props.node} />;
      } else {
        /** @type {React.CSSProperties} */
        let style = Object.assign({}, props.node.findRootAddonLocalData() ? {} : dynamicStyle, /** @type {React.CSSProperties} */ ({ whiteSpace: 'pre-line' }));
        if (res.tag === 'html') {
          // comes from clipboard (see extension: evaluator.js)
          // don't need to sanitize, as pasting should only result in styled contents
          return <span className="dynamic" style={style} dangerouslySetInnerHTML={ { __html: res.info.message } }></span>;
        }
        return <span className="dynamic" style={style}>{res.info.message}</span>;
      }
    }}
  />;
}


const NodeRenderer = React.memo(NodeRendererBase);
export default NodeRenderer;