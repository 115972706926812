import React, { Component } from 'react';
import { logError } from '../../logging/logging';
import { toast } from '../../message';
// @ts-ignore
import errorPanda from './../../../images/panda/error.jpeg';
// @ts-ignore
import cleaningPanda from './../../../images/panda/cleaning.jpeg';
import ErrorMessageComponent from './ErrorMessageComponent';

const chunkErrorMessage = 'This application has been updated, you need to reload the page to receive required updates.';
/**
 * @typedef {object} Props
 * @prop {boolean=} dialog - if true show a message
 * @prop {object=} component - the react component to show
 * @prop {object=} style - style to use with the default component
 * @prop {string=} details - more details about where the error occurs. only used when dialog=true
 * @prop {React.ReactNode} children - Children.
 *
 * @extends {Component<Props>}
 */
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      hasError: false,
      children: /** @type {React.ReactNode} */ (null)
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      error: error,
      hasError: true
    });
    if (isChunkError(error)) {
      const toastMessage = `${chunkErrorMessage} If this issue persists, please contact us at support@blaze.today.`;
      toast(toastMessage, {
        duration: 20000,
        intent: 'danger'
      });
      return;
    }
    logError(error, info);
    if (this.props.dialog) {
      toast(`An error occurred${this.props.details ? ' (' + this.props.details + ')' : ''}. Please try again. If this issue persists, please contact us at support@blaze.today.`, {
        duration: 8000,
        intent: 'danger'
      });
    }
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }
    if (this.props.dialog) {
      return null;
    } else if (this.props.component) {
      return this.props.component;
    } else if (isChunkError(this.state.error)) {
      return <ErrorMessageComponent
        icon="UPDATE"
        title="Reload required"
        description={chunkErrorMessage}
        image={cleaningPanda}
        style={this.props.style}
      />;
    } else {
      return <ErrorMessageComponent
        icon="ERROR"
        title="An error occurred"
        description="Please try again."
        image={errorPanda}
        style={this.props.style}
      />;
    }
  }
}

function isChunkError(error) {
  if (error.name === 'ChunkLoadError') {
    return true;
  }
  return false;
}