import { useTypedSelector } from '../../hooks';
import { isBlaze } from '../../flags';


/**
 * @param {object} props
 * @param {React.ReactElement} props.children
 */
export default function BlazeOnly(props) {
  const isBlazeUser = useTypedSelector(store => isBlaze(store.userState));

  if (isBlazeUser) {
    return props.children;
  }

  return null;
}
