import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { getData } from './DataDialog';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import T from '@mui/material/Typography';


/**
 * @param {object} props 
 * @param {string=} props.kindLabel 
 * @param {'field'|'positional'|'named'} props.type - `field` is for org member properties
 * @param {function} props.onChange 
 * @param {UserDefinedPropertyType} props.attribute 
 */
export default function ConfigureAttribute(props) {
  let [validationMenuAnchorEl, setValidationMenuAnchorEl] = useState(null);
  const listAllowed = ['lambda', 'select', 'identifier'];

  /**
   * @param {Partial<UserDefinedPropertyType>} newData 
   */
  function onChange(newData) {
    let data = Object.assign({}, props.attribute, newData);
    for (let key in newData) {
      if (newData[key] === undefined) {
        delete data[key];
      }
    } 
    props.onChange(data);
  }

  function renderConfig() {
    let attribute = props.attribute;
    let config = attribute.config || {};

    let items;
    if (attribute.type === 'number') {
      items = <>
        <TextField
          label="Minimum"
          type="number"
          size="small"
          helperText="Minimum allowed value"
          variant="outlined"
          value={config.minimum === undefined ? '' : config.minimum}
          onChange={(e) => {
            let newConfig = Object.assign({}, config);
            let val = e.target.value;
            if (val.trim() === '') {
              delete newConfig.minimum;
            } else {
              newConfig.minimum = Number(val);
            }
            onChange({ config: newConfig });
          }}
        />

        <TextField
          label="Maximum"
          type="number"
          size="small"
          helperText="Maximum allowed value"
          variant="outlined"
          value={config.maximum === undefined ? '' : config.maximum}
          onChange={(e) => {
            let newConfig = Object.assign({}, config);
            let val = e.target.value;
            if (val.trim() === '') {
              delete newConfig.maximum;
            } else {
              newConfig.maximum = Number(val);
            }
            onChange({ config: newConfig });
          }}
        />

        <FormControlLabel
          control={
            <Switch
              checked={!!config.integer}
              onChange={() => onChange({ config: Object.assign(config, { integer: !config.integer }) })}
              value="integerToggle"
              color="primary"
            />
          }
          label="Integer only"
        />
      </>;
    } else if (attribute.type === 'string') {
      let setValidation = (regex, message) => {
        setValidationMenuAnchorEl(null);

        let newConfig = Object.assign({}, config);
        newConfig.validationRegex = regex;
        newConfig.validationMessage = message;
        onChange({
          config: newConfig
        });
      };

      items = <div style={{ width: '100%' }}>
        <div style={{
          display: 'flex'
        }}>
          <FormControlLabel
            control={
              <Switch
                checked={!!config.multiline}
                onChange={() => onChange({ config: Object.assign(config, { multiline: !config.multiline }) })}
                value="multilineToggle"
                color="primary"
              />
            }
            label="Multiline entry field"
          />
          <FormControlLabel
            control={
              <Switch
                checked={!!config.validate}
                onChange={() => onChange({ config: Object.assign(config, { validate: !config.validate }) })}
                value="validateTextToggle"
                color="primary"
              />
            }
            label="Validate input"
          />
          {config.validate && 
            <>
              <div style={{ flex: 1 }}></div>
              <Button
                size="small"
                onClick={(e) => {
                  setValidationMenuAnchorEl(e.target);
                }}
                style={{
                  marginLeft: 4
                }}
                startIcon={
                  <div style={{ width: 14 }}>
                    <svg style={{ position: 'relative', top: 2 }} aria-hidden="true" focusable="false" role="img" viewBox="0 0 384 512"><path fill="currentColor" d="M126.2 286.4l64.2-63.6c2.1-2.1 2.1-5.5 0-7.6l-12.6-12.7c-2.1-2.1-5.5-2.1-7.6 0l-47.6 47.2-20.6-20.9c-2.1-2.1-5.5-2.1-7.6 0l-12.7 12.6c-2.1 2.1-2.1 5.5 0 7.6l37.1 37.4c1.9 2.1 5.3 2.1 7.4 0zM336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM192 48c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm144 408c0 4.4-3.6 8-8 8H56c-4.4 0-8-3.6-8-8V120c0-4.4 3.6-8 8-8h40v32c0 8.8 7.2 16 16 16h160c8.8 0 16-7.2 16-16v-32h40c4.4 0 8 3.6 8 8v336zM112 328c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm168-88h-63.3c-1.3 1.8-2.1 3.9-3.7 5.5L186.2 272H280c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm0 96H168c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8z"></path></svg>
                  </div>
                }
              >Common validations</Button>
              <Menu
                anchorEl={validationMenuAnchorEl}
                open={!!validationMenuAnchorEl}
                onClose={() => setValidationMenuAnchorEl(null)}

              >
                <MenuItem onClick={() => {
                  setValidation('^[0-9\\(\\)\\/\\+ \\-]+$', 'Not a valid phone number');
                }}>
                  <ListItemText primary="Phone Number"/>
                </MenuItem>
                <MenuItem onClick={() => {
                  setValidation('^\\S+@\\S+\\.\\S+$', 'Not a valid email address');
                }}>
                  <ListItemText primary="Email"/>
                </MenuItem>
                <MenuItem onClick={() => {
                  setValidation('^((https?):\\/\\/)?(www.)?[a-z0-9]+\\.[a-z]+(\\/[a-zA-Z0-9#]+\\/?)*$', 'Not a valid URL');
                }}>
                  <ListItemText primary="URL"/>
                </MenuItem>
              </Menu>
            </>}
        </div>
        {config.validate && <div style={{
          marginTop: 10,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'top',
          width: '100%' 
        }}>
          <TextField
            style={{
              flex: 1,
              marginRight: 8
            }}
            label="Validation Regular Expression"
            size="small"
            helperText="Regular expression to validate property"
            variant="outlined"
            value={config.validationRegex === undefined ? '' : config.validationRegex}
            onChange={(e) => {
              let newConfig = Object.assign({}, config);
              newConfig.validationRegex = e.target.value;
              onChange({ config: newConfig });
            }}
          />
          
          <TextField
            style={{
              flex: 1,
              marginLeft: 8
            }}
            label="Validation Error Message"
            size="small"
            helperText="Message shown if validation fails"  
            variant="outlined"
            value={config.validationMessage === undefined ? '' : config.validationMessage}
            onChange={(e) => {
              let newConfig = Object.assign({}, config);
              newConfig.validationMessage = e.target.value;
              onChange({ config: newConfig });
            }}
          />
        </div>}
      </div>;
    } else if (attribute.type === 'identifier') {
      items = <TextField
        label="Initial Value"
        size="small"
        helperText="Initial value for the form variable"
        variant="outlined"
        value={config.initial === undefined ? '' : config.initial}
        onChange={(e) => {
          let newConfig = Object.assign({}, config);
          newConfig.initial = e.target.value;
          onChange({ config: newConfig });
        }}
      />;
    } else if (attribute.type === 'select') {
      items = [
        <Button
          key="select-options-button"
          startIcon={<EditIcon />}
          color="primary"
          size="large"
          style={{
            marginTop: 6
          }}
          variant="outlined"
          onClick={() => {
            let currentList = [{ value: 'alpha' }, { value: 'bravo', selected: true }, { value: 'charlie' }];
            if (props.attribute.config.options) {
              currentList = props.attribute.config.options.map(i => ({ value: i, selected: props.attribute.default === i }));
            }
          
            getData({
              title: 'Configure category choices',
              applyButton: 'Save',
              fields: [],
              list: currentList
            }).then((data) => {
              let selected = data.list.find(x => x.selected);
              let values = props.type === 'field' ? data.list.map(v => v.value) : data.list.map(v => v.value = v.value.toLowerCase());
              onChange({
                config: {
                  options: values,
                  insensitive: !!config.insensitive
                },
                default: selected ? selected.value : ''
              });
            });
          }}
        >
          Configure category choices
        </Button>,
        props.type === 'field' ? null : <FormControlLabel
          key="select-insensitive-toggle"
          control={
            <Switch
              checked={!!config.insensitive}
              onChange={() => onChange({ config: Object.assign(config, { insensitive: !config.insensitive }) })}
              value="insensitiveToggle"
              color="primary"
            />
          }
          label="Case insensitive"
        />
      ];
    }

    if (items) {
      return <div style={{ marginTop: 16 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {items}
        </div>
      </div>;
    }

    return null;
  }

  function kindLabel() {
    return props.kindLabel || 'Setting';
  }

  let hasNameError = !(props.type === 'field' ? /^[a-z]+$/ : /^[a-zA-Z]+$/).test(props.attribute.name || '');


  let isAddon = props.type !== 'field';
  let noDefault = (props.attribute.type === 'identifier' || props.attribute.required || props.type === 'positional');


  return (
    <div>
      <div style={{
        display: 'flex'
      }}>
        <TextField
          style={{
            flex: 1
          }}
          label={kindLabel() + ' name'}
          fullWidth
          disabled={!!props.attribute.frozen_name}
          value={props.attribute.name || ''}
          onChange={(e) => onChange({
            name: e.target.value
          })}
          variant="outlined"
          helperText={hasNameError ? ('Must be one or more ' + (props.type === 'field' ? 'lowercase ' : '') + 'letters') : `Name of the ${kindLabel().toLowerCase()}` + (props.attribute.frozen_name ? ' (cannot be changed)' : '')}
          error={hasNameError}
        />

        {
          props.type === 'field' ?
            <FormGroup style={{
              marginLeft: 15
            }}>
              <Select
                input={
                  <OutlinedInput
                    size="small"
                  />
                }
                style={{
                  flex: 1
                }}
                value={props.attribute.sharing}
                onChange={(e) => {
                  let value = /** @type {string} */ (e.target.value);
                  onChange({
                    sharing: value
                  });
                }}
                fullWidth
                variant="standard"
              >
                <MenuItem value="editable">
                  <ListItemText primary="User editable"  secondary="Users may use and edit" />
                </MenuItem>
    
                <MenuItem value="readonly">
                  <ListItemText primary="User use only" secondary="Users may not edit" />
                </MenuItem>

                {/** TODO: enable after ui polish in this editor **/ null && <MenuItem value="master">
                  <ListItemText primary="Single value" secondary="One value for all users" />
                </MenuItem>}
              </Select>
            </FormGroup>
            :
            <FormGroup style={{
              marginLeft: 15
            }}>
              <Select
                disabled={props.type === 'positional'}
                input={
                  <OutlinedInput
                    size="small"
                  />
                }
                style={{
                  flex: 1
                }}
                value={props.type === 'positional' ? 'required' : (props.attribute.required ? 'required' : (props.attribute.visibility === 'hidden' ? 'hidden' : 'optional'))}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value === 'required') {
                    onChange({
                      required: true,
                      visibility: 'default'
                    });
                  } else if (value === 'optional') {
                    onChange({
                      required: false,
                      visibility: 'default'
                    });
                  } else if (value === 'hidden') {
                    onChange({
                      required: false,
                      visibility: 'hidden'
                    });
                  } else {
                    throw new Error('Unknown setting status: ' + value);
                  }
                }}
                fullWidth
                variant="standard"
              >
                <MenuItem value="required">
                  <ListItemText primary="Setting is required"  secondary="The user must use this setting"/>
                </MenuItem>

                <MenuItem value="optional">
                  <ListItemText primary="Optional setting" secondary="The user may choose whether to use this setting" />
                </MenuItem>

                <MenuItem value="hidden">
                  <ListItemText primary="Hidden optional setting" secondary="The setting is not shown to the user" />
                </MenuItem>
              </Select>
            </FormGroup>
        }
      </div>
        
      <TextField
        label={kindLabel() + ' description'}
        fullWidth
        value={props.attribute.description || ''}
        onChange={(e) => onChange({
          description: e.target.value
        })}
        style={{ marginTop: 24 }}
        helperText={'The description of the ' + kindLabel().toLowerCase()}
        variant="outlined"
      />

      <div style={{
        display: 'flex',
        marginTop: 8
      }}>
        <Select
          input={
            <OutlinedInput
              size="small"
            />
          }
          style={{
            marginBottom: 4,
            marginTop: 10,
            flex: 3
          }}
          value={props.attribute.type}
          onChange={(e) => {
            const value = /** @type {string} */ (e.target.value);
            const changes = {
              type: value
            };
            //if categorical/function/indentifier, lets reset the list
            if (listAllowed.includes(value)) {
              changes['list'] = undefined;
            }
            onChange(changes);
          }}
          fullWidth
          variant="standard"
        >
          <MenuItem value="string">
            <ListItemText primary="Text"  secondary="A single or multiline text string"/>
          </MenuItem>

          <MenuItem value="number">
            <ListItemText primary="Number" secondary="A numeric value (e.g. 7 or 2.3)" />
          </MenuItem>

          <MenuItem value="boolean">
            <ListItemText primary="Boolean" secondary="A yes/no value" />
          </MenuItem>

          <MenuItem value="select">
            <ListItemText primary="Categorical" secondary="Select a value from a list of options" />
          </MenuItem>

          <MenuItem value="date">
            <ListItemText primary="Date" secondary="Select a date" />
          </MenuItem>

          {props.type !== 'field' ? <MenuItem value="lambda">
            <ListItemText primary="Function" secondary="An equation function (e.g. (x) -> uppercase(x))" />
          </MenuItem> : null}

          {props.type === 'named' ? <MenuItem value="identifier">
            <ListItemText primary="Form Variable Name" secondary="Send data to the parent snippet" />
          </MenuItem> : null}
        </Select>
        {props.type !== 'field' && !listAllowed.includes(/** @type {any} */ (props.attribute.type)) &&
            <Select
              input={
                <OutlinedInput
                  size="small"
                />
              }
              style={{
                marginLeft: 15,
                flex: 1,
                marginBottom: 4,
                marginTop: 10,
              }}
              value={props.attribute.list ? props.attribute.list : 'scalar'}
              onChange={(e) => {
                const value = /** @type {ListType|'scalar'} */ (e.target.value);
                onChange({ list: value === 'scalar' ? undefined : value });
              }}
              fullWidth
              variant="standard"
            >
              <MenuItem value="scalar">
                <ListItemText primary="Not a list"  secondary="Standard behavior"/>
              </MenuItem>

              <MenuItem value="positional">
                <ListItemText primary="Positional list" secondary="User may enter multiple comma separated values" />
              </MenuItem>

              <MenuItem value="keys">
                <ListItemText primary="Keyed list" secondary="User may enter multiple comma separated key=value pairs" />
              </MenuItem>
            </Select>
        }
      </div>

      <div style={{
        display: 'flex'
      }}>
        <div style={{
          flex: 1,
          marginRight: 12,
          opacity: noDefault ? .5 : 1,
          pointerEvents: noDefault ? 'none' : undefined
        }}>
          <ValueEditor
            attribute={props.attribute}
            onChange={onChange}
            type={props.type}
            label={props.attribute.sharing === 'master' ? 'Value' : (isAddon ? 'Missing value' : 'Default value')}
            kind="default"
          />
          {isAddon && <T variant="caption" color="textSecondary">Value used in the command when no value is provided by the user. Only applies to optional settings.</T>}
        </div>

        {isAddon && <div style={{
          flex: 1,
          marginLeft: 12
        }}>
          <ValueEditor
            attribute={props.attribute}
            onChange={onChange}
            type={props.type}
            label="Placeholder"
            kind="placeholder"
          />
          <T variant="caption" color="textSecondary">Example value shown to the user. If no placeholder is provided, the missing value is used as the placeholder.</T>
        </div>}
      </div>

      {renderConfig()}

    </div>
  );
}


/**
 * @param {object} props 
 * @param {UserDefinedPropertyType} props.attribute
 * @param {function} props.onChange
 * @param {string} props.type
 * @param {string} props.label
 * @param {'default'|'placeholder'} props.kind
 */
function ValueEditor(props) {
  if (props.attribute.type === 'boolean') {
    return <FormGroup style={{ marginTop: 10 }}>
      <FormControlLabel
        control={
          <Switch
            checked={props.attribute[props.kind] === 'yes' || false}
            onChange={() => props.onChange({ [props.kind]: props.attribute[props.kind] === 'yes' ? 'no' : 'yes' })}
            value="checkedD"
            color="primary"
          />
        }
        label={props.kind === 'default' ? 'Default value is "yes"' : 'Placeholder is "yes"'}
      />
    </FormGroup>;
  } else {
    return <TextField
      style={{ marginTop: 24 }}
      size="small"
      type={props.attribute.type === 'number' ? 'number' : undefined}
      label={props.label}
      multiline={props.attribute.config && props.attribute.config.multiline}
      fullWidth
      helperText={(props.type === 'field' && props.attribute.sharing !== 'master') ? 'Used if the user has not saved a value for this property.' : undefined}
      value={props.attribute[props.kind] || ''}
      onChange={(e) => props.onChange({
        [props.kind]: e.target.value
      })}
      variant="outlined"
    />;
  }
}