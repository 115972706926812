import { getAuth, getIdToken, httpsCallable } from './firebase_shared_driver';
import { getTBFunctions_Base } from './firebase_utilities_base';


/**
 * Get a custom sign-in token for the current user.
 * This is not a JWT token, do NOT use it for regular authorization.
 * This is only for use in sign-in.
 * We don't import firebaseApp as this is also used in the extension
 * 
 * @param {import('firebase/app').FirebaseApp} firebaseApp
 */
export async function getTokenCredentials(firebaseApp) {
  let data = {};
  try {
    data.agent = window.navigator.userAgent;
    data.ext = window['chrome'] && window['chrome'].runtime && window['chrome'].runtime.id;
  } catch (_err) {
    // pass
  }
  // make sure user is still logged in and the token is not revoked.
  await getIdToken(getAuth().currentUser, true);
  /**
   * @type {import('firebase/functions').HttpsCallable<any, {
   *   token: string,
   *   email: string,
   *   uid: string
   * }>}
   */
  let func = httpsCallable(getTBFunctions_Base(firebaseApp), 'lomiktext');
  let res = await func(data);
  return { token: res.data.token, email: res.data.email, uid: res.data.uid };
}
