import React from 'react';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { APP_TARGET } from '../../flags';
import {
  aiTheme,
  dataTheme,
  pageTheme,
  textTheme
} from './themes';

const themeMap = {
  TEXT: textTheme,
  DATA: dataTheme,
  PAGE: pageTheme,
  AI: aiTheme
};

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {AppType} props.appType
 */
export function MUIThemeProvider({ children, appType }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeMap[appType]}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export default function MUITheme(props) {
  return (
    <MUIThemeProvider appType={APP_TARGET}>
      {props.children}
    </MUIThemeProvider>
  );
}

const alertTheme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: '#795548',
    },
    secondary: {
      main: '#FFCA28'
    },
  }
});


const addonTheme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: '#FFC107',
    },
    secondary: {
      main: '#607D8B'
    }
  }
});


export function AlertTheme(props) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={alertTheme}>{props.children}</ThemeProvider>
    </StyledEngineProvider>
  );
}


export function AddonTheme(props) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={addonTheme}>{props.children}</ThemeProvider>
    </StyledEngineProvider>
  );
}