import React, { createContext, useState } from 'react';
import TrashedDialog from '../../TrashedDialog/TrashedDialog';
import TrashedDialogTitle from '../../TrashedDialog/TrashedDialogTitle';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import TrashedGroups from './TrashedGroups';
import TrashedSnippets from './TrashedSnippets';
import { TRASH_LABELS } from './trashed_utils';

/**
 * @typedef TrashedDialogContextProps
 * @property {boolean} loading
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setLoading
 * @property {React.Dispatch<React.SetStateAction<string>>} setNavigateToOnClose
 */


export const TrashedDialogContext = createContext(
  /** @type {TrashedDialogContextProps} */ ({})
);
const TrashedTextBlazeDialog = () => {
  const [navigateToOnClose, setNavigateToOnClose] = useState(null);
  const [loading, setLoading] = useState(false);
  const { hash } = useLocation();
  const trashType = hash !== '#trash/folders' ? 'snippets' : 'groups';
  const { push: navigate } = useHistory();
  const label = TRASH_LABELS[trashType];
  const handleClose = () => {
    if (navigateToOnClose) {
      navigate(navigateToOnClose);
    } else {
      navigate({
        hash: ''
      });
    }
  };

  if (![
    '#trash',
    '#trash/folders',
    // Backward compatible.
    '#trash/snippets'
  ].includes(hash)) {
    return null;
  }


  return (

    <TrashedDialogContext.Provider
      value={{
        loading: loading,
        setLoading: setLoading,
        setNavigateToOnClose: setNavigateToOnClose
      }}
    >
      <TrashedDialog
        onClose={handleClose}
      >
        <TrashedDialogTitle
          onClose={handleClose}
        >
          <span>{`Trashed ${label.groupTypePlural}`}</span>
          <ToggleButtonGroup
            value={trashType}
            exclusive
            onChange={(_, v) => {
              if (!v) {
                return;
              }
              navigate({
                hash: v === 'groups' ? 'trash/folders' : 'trash'
              });
            }}
            sx={{
              justifyContent: 'center',
              height: 24,
              ml: 2
            }}
          >
            <ToggleButton
              value="snippets"
              sx={{ borderRadius: 3 }}
            >
              Snippets
            </ToggleButton>
            <ToggleButton
              value="groups"
              sx={{ borderRadius: 3 }}
            >
              Folders
            </ToggleButton>
          </ToggleButtonGroup>
        </TrashedDialogTitle>
        {trashType === 'groups' ? (

          <TrashedGroups />
        ) : (
          <TrashedSnippets />
        )}
      </TrashedDialog>
    </TrashedDialogContext.Provider>
  );
};

export default TrashedTextBlazeDialog;