import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import ReactDOM from 'react-dom';

const SimpleDialog = forwardRef((_props, ref) => {
  let [open, setOpen] = useState(false);
  let [config, setConfig] = useState(null);

  useImperativeHandle(ref, () => ({
    show: (config) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setOpen(true);
        setConfig(config);
      });
    }
  }));

  const hide = () => {
    setOpen(false);
    setConfig(null);
  };

  const close = () => {
    if (config.onClose) {
      config.onClose();
    }
    hide();
  };

  if (!open) {
    return null;
  }


  let contents;
  let showAction = true;

  if (config.contents instanceof Function) {
    contents = config.contents(close);
    showAction = false;
  } else {
    contents = config.contents;
  }


  return (
    <Dialog
      open
      onClose={close}
      maxWidth="md"
      PaperProps={{
        style:{
          overflowX: 'hidden'
        }
      }}
    >
      {config.title && <DialogTitle>{config.title}</DialogTitle>}
      <DialogContent
        sx={Object.assign({
          minWidth: {
            xs: 'min(calc(100% - 40px), 370px)',
            sm: '370px'
          }
        }, config.contentStyle)}>
        {contents}
      </DialogContent>

      {showAction && <DialogActions style={{ marginRight: 10, marginBottom: 15 }}>
        <Button color="primary" onClick={close} variant="outlined">{config.closeButtonText || 'Ok'}</Button>
      </DialogActions>}
    </Dialog>
  );
});

export default SimpleDialog;