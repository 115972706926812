import React from 'react';
import {
  Alert,
  Typography as T
} from '@mui/material';

/**
 * 
 * @param {object} props
 * @param {string} props.label
 * @param {string=} props.expiryLabel - Defaults: 3 days
 * @param {React.ReactElement=} props.extraContents
 * @param {import('@mui/material').AlertColor=} props.variant
 * @returns 
 */
const TrashedInfo = ({
  label,
  expiryLabel,
  extraContents,
  variant
}) => {
  return (
    <Alert
      severity={variant || 'info'}
      sx={{
        mt: 2,
        mx: 2,
        alignItems: 'center',
        // To handle mobile view
        '.MuiAlert-icon': {
          minWidth: 22
        }
      }}
    >
      <T fontSize={14}>Trashed {label} are permanently deleted after {expiryLabel || '3 days'} and cannot be restored.</T>
      {extraContents}
    </Alert>
  );
};

export default TrashedInfo;