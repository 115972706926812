import React from 'react';
import {
  Button,
  Typography as T
} from '@mui/material';
import { PRICES } from '../Version/version_utilities';
import Incrementor from './Incrementor';
import BillingCycleSelect from './BillingCycleSelect';

/**
 * @param {Object} props
 * @param {number} props.quantity
 * @param {(value: number) => any} props.onChange
 * @param {Function} props.onNext
 * @param {Function=} props.onBillingChange
 * @param {string} props.totalPrice
 * @param {import("./PaymentDialog").PaymentDialogConfig['billing']} props.billing
 * @param {import("./PaymentDialog").PaymentDialogConfig['continueToBtnContents']} props.continueText
 */
function BusinessPlanSeatSelection(props) {
  const monthlyPrice = PRICES['business']['monthly'];
  const annualPriceDelta = (PRICES['business']['deltaMonthly'] * 12 * props.quantity).toFixed(2);

  return <>
    <Incrementor
      inputWidth={160}
      value={props.quantity}
      onChange={props.onChange}
      minimum={1}
      maximum={100}
      label="Team size / number of seats:"
      sx={{
        mb: 4
      }}
      helperText={
        <T>
          <span>Total: &nbsp;</span>
          <b>$<span>{props.totalPrice}</span>/{props.billing === 'monthly' ? 'Month' : 'Year'}</b>
        </T>
      }
    />

    {props.onBillingChange && <BillingCycleSelect
      onBillingChange={props.onBillingChange}
      billing={props.billing}
      annualPriceDelta={annualPriceDelta}
    />}

    <T
      sx={{
        mt: 2,
        opacity: 0.6
      }}
      variant="caption"
      component="div"
    >
      Text Blaze Business is ${monthlyPrice} USD a month per user{props.billing === 'monthly' ? '' : ' billed annually'}. You
      can add additional users to your account in the future.
    </T>
    <Button
      fullWidth
      variant="contained"
      onClick={() => props.onNext()}
      sx={{
        mt: 2,
        mb: 1
      }}
    >
      {props.continueText}
    </Button>
  </>;
}

export default BusinessPlanSeatSelection;