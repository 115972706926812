/**
 * Gets the current {user} metadata properties
 * 
 * @param {string} email
 * @param {object[]} memberFields
 * @param {object} memberFieldsData
 */
export function userMemberData(email, memberFields, memberFieldsData) {
  let core = {
    email
  };

  if (!memberFields) {
    return core;
  }
  
  let base = {}, master = {};
  for (let item of memberFields) {
    if (item.sharing === 'master') {
      master[item.name] = item.default;
    } else {
      base[item.name] =  item.default;
    }
  }

  let cleanedUser = {};
  if (memberFieldsData) {
    for (let item of memberFields) {
      if (item.name in memberFieldsData) {
        cleanedUser[item.name] = memberFieldsData[item.name];
      }
    }
  }

  return Object.assign(core, base, cleanedUser, master);
};


