import Quill from 'quill';
import Break from 'quill/blots/break';
import { getRelativeRect } from '../utils';
import { CELL_DEFAULT, ERROR_LIMIT, TABLE_ATTRIBUTES, CELL_STYLES } from './constants';
import TableBody from './tableBody';
import TableCell from './tableCell';
import TableCellLine from './tableCellLine';
import TableCol from './tableCol';
import TableColGroup from './tableColGroup';
import TableRow from './tableRow';
import { balanceCells, cellId, generateCellMap, groupCells, rowId } from './util';
import TypedContainer from './typedContainer';


const BORDER_STYLES  = CELL_STYLES.filter(style => style.startsWith('border'));
/**
 * @extends TypedContainer<import('./tableBody').default, import('./tableViewWrapper').default>
 */
class TableContainer extends TypedContainer {
  checkMerge() {
    if (super.checkMerge() && this.next.children.head) {
      return !(
        this.children.tail.statics.blotName === TableBody.blotName
        && this.next.children.head.statics.blotName === TableColGroup.blotName
      );
    }
    return false;
  }

  static create() {
    let node = super.create();
    return node;
  }

  static getFormats(domNode) {
    const parentNode = domNode.parentNode;
    return TABLE_ATTRIBUTES.reduce((formats, attribute) => {
      if (attribute === 'align') {
        let value = '';
        if (domNode.style.marginLeft === 'auto') {
          value = 'right';
        }
        if (value === 'right' && domNode.style.marginRight === 'auto') {
          value = 'center';
        }
        if (!value
          && parentNode.hasAttribute(attribute)
        ) {
          value = parentNode.getAttribute(attribute);
        } else if (!value
          && domNode.hasAttribute(attribute)
        ) {
          value = domNode.getAttribute(attribute);
        }
        formats[attribute] = value || undefined;
      } else if (domNode.hasAttribute(attribute)) {
        formats[attribute] =
          domNode.getAttribute(attribute) || undefined;
      }
      return formats;
    }, {});
  }

  constructor (scroll, domNode) {
    super(scroll, domNode);
    this.updateFormats();
    this.tableWidthSet = false;
  }

  optimize(context) {
    super.optimize(context);
    // Element has been removed. ignore
    if (!this.domNode.isConnected) {
      return;
    }
    this.updateTableWidth(true);
  }

  balanceCells () {
    clearTimeout(this.balanceTimer);
    this.balanceTimer = setTimeout(() => {
      balanceCells(this);
      this.statics.dependents.forEach(blotClass => {
        this.descendants(blotClass).forEach(blot => blot.optimize({}));
      });
    }, 10);
  }

  updateTableWidth (immediate) {
    const doUpdate = () => {
      if (!this.domNode.parentElement) {
        return;
      }
      let colGroup = this.colGroup();
      if (!colGroup) {
        return;
      }
      /**
       * @type {Number | 'auto'}
       */
      let tableWidth = 0;
      for (let index = 0; index < colGroup.children.length; index++) {
        const col = colGroup.children.at(index);
        // Quill is still optimizing. Lets ignore.
        if (col.statics.blotName !== TableCol.blotName) {
          return;
        }
        const width = col.formats()[TableCol.blotName].width;
        if (width === 'auto') {
          tableWidth = 'auto';
          break;
        }
        tableWidth += parseInt(width, 10);
      }
      const domNode = this.domNode;
      const widthToSet = tableWidth === 'auto' ? 'auto' : `${tableWidth}px`;
      if (widthToSet !== domNode.style.width) {
        domNode.style.width  = widthToSet;
      }

      setTimeout(() => {
        if (tableWidth === 'auto' && !domNode.classList.contains('auto-width')) {
          domNode.classList.add('auto-width');
        } else if (tableWidth !== 'auto' && domNode.classList.contains('auto-width')) {
          domNode.classList.remove('auto-width');
        }
      }, 1);
      if (!this.tableWidthSet) {
        domNode.style.opacity = '100';
        this.tableWidthSet = true;
      }
    };
    clearTimeout(this.updateTimer);
    if (immediate) {
      doUpdate();
    } else {
      this.updateTimer = setTimeout(doUpdate, 0);
    }
  }

  formatChildren (name, value) {
    const colGroup = this.colGroup();
    colGroup.children.forEach((col) => {

      col.format(name, value);
    });
  }

  format (name, value) {
    if (name === 'align') {
      this.align(value);

      this.formatChildren(name, value);
    } else if (TABLE_ATTRIBUTES.includes(name)) {
      const attrValue = value;
      if (typeof attrValue !== 'undefined') {
        this.domNode.setAttribute(`data-${name}`, value);
      } else {
        this.domNode.removeAttribute(`data-${name}`);
      }

      this.formatChildren(name, value);
    } else {
      // @ts-ignore
      super.format(name, value);
    }
  }

  updateFormats () {
    setTimeout(() => {
      const colGroup = this.colGroup();
      if (!colGroup) {
        return;
      }
      const col = colGroup.children.at(0);
      // Still optimzing. ColGroup does not have col yet.
      if (col.statics.blotName !== TableCol.blotName) {
        return;
      }
      const { align } = col.statics.formats(col.domNode);
      if (align) {
        this.align(align);
      }
    }, 0);
  }

  align(value) {
    let marginLeft = null,
      marginRight = null;

    if (value === 'center') {
      marginLeft = 'auto';
      marginRight = 'auto';
    } else if (value === 'right') {
      marginLeft = 'auto';
    }
    this.domNode.style.marginLeft = marginLeft;
    this.domNode.style.marginRight = marginRight;
  }

  cells(column) {
    return this.rows().map(row => row.children.at(column));
  }

  colGroup () {
    return this.descendant(TableColGroup, 0)[0];
  }

  deleteColumns(compareRect, delIndexes = [], editorWrapper) {
    const [body] = this.descendants(TableBody);
    if (body == null || body.children.head == null) {
      return;
    }

    const tableCells = this.descendants(TableCell);
    const removedCells = [];
    const modifiedCells = [];

    tableCells.forEach(cell => {
      const cellRect = getRelativeRect(
        cell.domNode.getBoundingClientRect(),
        editorWrapper
      );

      if (
        cellRect.x + ERROR_LIMIT > compareRect.x &&
        cellRect.x1 - ERROR_LIMIT < compareRect.x1
      ) {
        removedCells.push(cell);
      } else if (
        cellRect.x < compareRect.x + ERROR_LIMIT &&
        cellRect.x1 > compareRect.x1 - ERROR_LIMIT
      ) {
        modifiedCells.push(cell);
      }
    });

    if (removedCells.length === tableCells.length) {
      this.tableDestroy();
      return true;
    }

    // remove the matches column tool cell
    delIndexes.forEach((delIndex) => {
      this.colGroup().children.at(delIndexes[0]).remove();
    });

    removedCells.forEach(cell => {
      cell.remove();
    });

    modifiedCells.forEach(cell => {
      const cellColspan = parseInt(cell.formats().colspan, 10);
      //const cellWidth = parseInt(cell.formats().width, 10);
      cell.format('colspan', cellColspan - delIndexes.length);
    });

    this.updateTableWidth();
  }

  deleteRow(compareRect, editorWrapper) {
    const [body] = this.descendants(TableBody);
    if (body == null || body.children.head == null) {
      return;
    }

    const tableCells = this.descendants(TableCell);
    const tableRows = this.descendants(TableRow);
    const removedCells = [];  // cells to be removed
    const modifiedCells = []; // cells to be modified
    const fallCells = [];     // cells to fall into next row

    // compute rows to remove
    // bugfix: #21 There will be a empty tr left if delete the last row of a table
    const removedRows = tableRows.filter(row => {
      const rowRect = getRelativeRect(
        row.domNode.getBoundingClientRect(),
        editorWrapper
      );
      
      return rowRect.y > compareRect.y - ERROR_LIMIT &&
        rowRect.y1 < compareRect.y1 + ERROR_LIMIT;
    });

    tableCells.forEach(cell => {
      const cellRect = getRelativeRect(
        cell.domNode.getBoundingClientRect(),
        editorWrapper
      );

      if (
        cellRect.y > compareRect.y - ERROR_LIMIT &&
        cellRect.y1 < compareRect.y1 + ERROR_LIMIT
      ) {
        removedCells.push(cell);
      } else if (
        cellRect.y < compareRect.y + ERROR_LIMIT &&
        cellRect.y1 > compareRect.y1 - ERROR_LIMIT
      ) {
        modifiedCells.push(cell);

        if (Math.abs(cellRect.y - compareRect.y) < ERROR_LIMIT) {
          fallCells.push(cell);
        }
      }
    });

    if (removedCells.length === tableCells.length) {
      this.tableDestroy();
      return;
    }

    // compute length of removed rows
    const removedRowsLength = this.rows().reduce((sum, row) => {
      let rowRect  = getRelativeRect(
        row.domNode.getBoundingClientRect(),
        editorWrapper
      );

      if (
        rowRect.y > compareRect.y - ERROR_LIMIT &&
        rowRect.y1 < compareRect.y1 + ERROR_LIMIT
      ) {
        sum += 1;
      }
      return sum;
    }, 0);

    // it must excute before the table layout changed with other operation
    fallCells.forEach(cell => {
      const cellRect = getRelativeRect(
        cell.domNode.getBoundingClientRect(),
        editorWrapper
      );
      const nextRow = cell.parent.next;
      const cellsInNextRow = nextRow.children;

      const refCell = cellsInNextRow.reduce((ref, compareCell) => {
        const compareRect = getRelativeRect(
          compareCell.domNode.getBoundingClientRect(),
          editorWrapper
        );
        if (Math.abs(cellRect.x1 - compareRect.x) < ERROR_LIMIT) {
          ref = compareCell;
        }
        return ref;
      }, null);

      nextRow.insertBefore(cell, refCell);
      cell.format('row', nextRow.formats().row);
    });

    removedCells.forEach(cell => {
      cell.remove();
    });

    modifiedCells.forEach(cell => {
      const cellRowspan = parseInt(cell.formats().rowspan, 10);
      cell.format('rowspan', cellRowspan - removedRowsLength);
    });

    // remove selected rows
    removedRows.forEach(row => row.remove());
  }

  tableDestroy() {
    const quill = /** @type {Quill} */ (Quill.find(this.scroll.domNode.parentNode));
    const tableModule = /** @type {import('quill-better-table').default} */ (quill.getModule('better-table'));
    this.remove();
    tableModule.hideTableTools();
    quill.update(Quill.sources.USER);
  }

  insertCell(tableRow, ref) {
    const id = cellId();
    const rId = tableRow.formats().row;
    const tableCell = /** @type {TableCell} */ (this.scroll.create(
      TableCell.blotName,
      Object.assign({}, CELL_DEFAULT, {
        row: rId
      })
    ));
    
    const cellLine = /** @type {TableCellLine} */ (this.scroll.create(TableCellLine.blotName, {
      row: rId,
      cell: id
    }));
    tableCell.appendChild(cellLine);

    if (ref) {
      tableRow.insertBefore(tableCell, ref);
    } else {
      tableRow.appendChild(tableCell);
    }
  }

  /**
   * 
   * @param {TableCell[]} selectedTds =
   * @param {boolean} isRight 
   * @param {HTMLElement} editorWrapper 
   * @returns 
   */
  insertColumn(selectedTds, isRight = true, editorWrapper) {
    
    const [body] = this.descendants(TableBody);
    const [tableColGroup] = this.descendants(TableColGroup);
    const tableCols = this.descendants(TableCol);
    const cellMap = generateCellMap(body);
    let addAsideCells = [
    ];
    let modifiedCells = [];
    let affectedCells = [];

    if (body == null || body.children.head == null) {
      return;
    }
    let selectedTdsInRows = groupCells(selectedTds, cellMap);


    let colIndex = null;
    for (const rowIndex in selectedTdsInRows) {
      const selectedTdsInRow = selectedTdsInRows[rowIndex];
      let cellToChoose;
      if (isRight) {
        cellToChoose = selectedTdsInRow[selectedTdsInRow.length - 1];
      } else {
        cellToChoose = selectedTdsInRow[0];
      }
      addAsideCells.push(cellToChoose);
      if (!colIndex) {
        if (isRight) {
          colIndex = cellMap[rowIndex].lastIndexOf(cellToChoose);
        } else {
          colIndex = cellMap[rowIndex].indexOf(cellToChoose);
        }
      }
    }
    
    cellMap.forEach((cellsInRow, rowIndex) => {
      // This row is already processed
      if (selectedTdsInRows[rowIndex]) {
        return;
      }
      let cellToChoose = cellsInRow[colIndex];

      // already added. It should be a merged cell.
      if (
        addAsideCells.includes(cellToChoose)
        || modifiedCells.includes(cellToChoose)
      ) {
        return;
      }
      if (isRight) {
        if (
          // if no cell adjacent to it
          colIndex >= cellsInRow.length
          // or adjacent cell is not same
          || cellsInRow[colIndex + 1] !== cellToChoose
        ) {
          addAsideCells.push(cellToChoose);
        } else {
          // adjacent cell is same
          modifiedCells.push(cellToChoose);
        }
      } else {
        if (
          // if no cell adjacent to it
          colIndex === 0
          // or adjacent cell is not same
          || cellsInRow[colIndex - 1] !== cellToChoose
        ) {
          addAsideCells.push(cellToChoose);
        } else {
          // adjacent cell is same
          modifiedCells.push(cellToChoose);
        }
      }
    });

    addAsideCells.forEach(cell => {
      const ref = isRight ? cell.next : cell;
      const id = cellId();
      const tableRow = cell.parent;
      const rId = tableRow.formats().row;
      const cellFormats = cell.formats();

      const borderSideToConsider = isRight ? 'Right' : 'Left',
        borderSideToIgnore = isRight ? 'Left' : 'Right';
      const tdFormats = CELL_STYLES.reduce(function (formats, attrName) {
        if (!cellFormats[attrName]) {
          return formats;
        }
        if (attrName.startsWith('border' + borderSideToConsider)) {
          formats[attrName] = cellFormats[attrName];
          formats[attrName.replace(borderSideToConsider, borderSideToIgnore)] = cellFormats[attrName];
        } else if (!attrName.startsWith('border' + borderSideToIgnore)) {
          formats[attrName] = cellFormats[attrName];
        }
        return formats;
      }, {});
      const tableCell = /** @type {TableCell} */ (this.scroll.create(
        TableCell.blotName,
        Object.assign({}, CELL_DEFAULT, {
          row: rId,
          rowspan: cellFormats.rowspan
        }, tdFormats)
      ));
      const cellLine = this.scroll.create(TableCellLine.blotName, Object.assign({
        row: rId,
        cell: id,
        rowspan: cellFormats.rowspan
      }, tdFormats));
      tableCell.appendChild(cellLine);

      if (ref) {
        tableRow.insertBefore(tableCell, ref);
      } else {
        tableRow.appendChild(tableCell);
      }
      affectedCells.push(tableCell);
    });
    // insert new tableCol
    let colRef = isRight ? tableCols[colIndex].next : tableCols[colIndex];
    let formats = tableCols[colIndex]?.formats()[TableCol.blotName];
    const tableCol = this.scroll.create(TableCol.blotName, {
      ...formats
    });
    if (colRef) {
      tableColGroup.insertBefore(tableCol, colRef);
    } else {
      tableColGroup.appendChild(tableCol);
    }

    modifiedCells.forEach(cell => {
      const cellColspan = cell.formats().colspan;
      cell.format('colspan', parseInt(cellColspan, 10) + 1);
      affectedCells.push(cell);
    });

    affectedCells.sort((cellA, cellB) => {
      let y1 = cellA.domNode.getBoundingClientRect().y;
      let y2 = cellB.domNode.getBoundingClientRect().y;
      return y1 - y2;
    });

    this.updateTableWidth();
    return affectedCells;
  }

  insertRow(compareRect, isDown, editorWrapper) {
    const [body] = this.descendants(TableBody);
    if (body == null || body.children.head == null) {
      return;
    }

    const tableCells = this.descendants(TableCell);
    const rId = rowId();
    const newRow = /** @type {TableRow} */ (this.scroll.create(TableRow.blotName, {
      row: rId
    }));
    let addBelowCells = [];
    let modifiedCells = [];
    let affectedCells = [];

    tableCells.forEach(cell => {
      const cellRect = getRelativeRect(
        cell.domNode.getBoundingClientRect(),
        editorWrapper
      );

      if (isDown) {
        if (Math.abs(cellRect.y1 - compareRect.y1) < ERROR_LIMIT) {
          addBelowCells.push(cell);
        } else if (
          compareRect.y1 - cellRect.y > ERROR_LIMIT &&
          compareRect.y1 - cellRect.y1 < -ERROR_LIMIT
        ) {
          modifiedCells.push(cell);
        }
      } else {
        if (Math.abs(cellRect.y - compareRect.y) < ERROR_LIMIT) {
          addBelowCells.push(cell);
        } else if (
          compareRect.y - cellRect.y > ERROR_LIMIT &&
          compareRect.y - cellRect.y1 < -ERROR_LIMIT
        ) {
          modifiedCells.push(cell);
        }
      }
    });

    // ordered table cells with rect.x, fix error for inserting
    // new table cell in complicated table with wrong order.
    const sortFunc = (cellA, cellB) => {
      let x1 = cellA.domNode.getBoundingClientRect().x;
      let x2 = cellB.domNode.getBoundingClientRect().x;
      return x1 - x2;
    };
    addBelowCells.sort(sortFunc);

    addBelowCells.forEach(cell => {
      const cId = cellId();
      const cellFormats = cell.formats();


      const borderSideToConsider = isDown ? 'Bottom' : 'Top',
        borderSideToIgnore = isDown ? 'Top' : 'Bottom';
      const tdFormats = CELL_STYLES.reduce(function (formats, attrName) {
        if (!cellFormats[attrName]) {
          return formats;
        }
        if (attrName.startsWith('border' + borderSideToConsider)) {
          formats[attrName] = cellFormats[attrName];
          formats[attrName.replace(borderSideToConsider, borderSideToIgnore)] = cellFormats[attrName];
        } else if (!attrName.startsWith('border' + borderSideToIgnore)) {
          formats[attrName] = cellFormats[attrName];
        }
        return formats;
      }, {});
      const tableCell = /** @type {TableCell} */ (this.scroll.create(TableCell.blotName, Object.assign(
        {}, CELL_DEFAULT, { row: rId, colspan: cellFormats.colspan }, tdFormats
      )));
      const cellLine = /** @type {TableCellLine} */ (this.scroll.create(TableCellLine.blotName, Object.assign({
        row: rId,
        cell: cId,
        colspan: cellFormats.colspan
      }, tdFormats)));
      const empty = this.scroll.create(Break.blotName);
      cellLine.appendChild(empty);
      tableCell.appendChild(cellLine);
      newRow.appendChild(tableCell);
      affectedCells.push(tableCell);
    });

    modifiedCells.forEach(cell => {
      const cellRowspan = parseInt(cell.formats().rowspan, 10);
      cell.format('rowspan', cellRowspan + 1);
      affectedCells.push(cell);
    });

    let refRow = this.rows().find(row => {
      let rowRect = getRelativeRect(
        row.domNode.getBoundingClientRect(),
        editorWrapper
      );
      if (isDown) {
        return Math.abs(rowRect.y - compareRect.y - compareRect.height) < ERROR_LIMIT;
      } else {
        return Math.abs(rowRect.y - compareRect.y) < ERROR_LIMIT;
      }
    });
    if (!refRow) {
      if (isDown) {
        refRow = addBelowCells[0].parent?.next;
      } else {
        refRow = addBelowCells[0].parent;
      }
    }
    body.insertBefore(newRow, refRow);

    // reordering affectedCells
    affectedCells.sort(sortFunc);
    return affectedCells;
  }

  mergeCells (mergingCells, rowspan, colspan) {
    let mergedCell = mergingCells[0];
    mergedCell.format('colspan', colspan);
    mergedCell.format('rowspan', rowspan);
    for (let index = 1; index < mergingCells.length; index++) {
      const tableCell = mergingCells[index];

      // Do not move the cell if the cell is empty.
      if (
        tableCell.children.length !== 1
        || tableCell.children.head.children.length !== 1
        || tableCell.children.head.children.head.statics.blotName !== 'break'
      ) {
        tableCell.moveChildren(mergedCell);
      }
      tableCell.remove();
    }

    let rowId = mergedCell.domNode.getAttribute('data-row');
    let cellId = mergedCell.children.head.domNode.getAttribute('data-cell');
    mergedCell.children.forEach(cellLine => {
      cellLine.format('cell', cellId);
      cellLine.format('row', rowId);
      cellLine.format('colspan', colspan);
      cellLine.format('rowspan', rowspan);
    });

    return mergedCell;
  }

  unmergeCells (unmergingCells, editorWrapper) {
    let cellFormats = {};
    let cellRowspan = 1;
    let cellColspan = 1;

    unmergingCells.forEach(tableCell => {
      cellFormats = tableCell.formats();
      cellRowspan = cellFormats.rowspan;
      cellColspan = cellFormats.colspan;

      if (cellColspan > 1) {
        let ref = tableCell.next;
        let row = tableCell.row();
        tableCell.format('colspan', 1);
        for (let i = cellColspan; i > 1; i--) {
          this.insertCell(row, ref);
        }
      }

      if (cellRowspan > 1) {
        let i = cellRowspan;
        let nextRow = tableCell.row().next;
        while (i > 1) {
          let refInNextRow = nextRow.children
            .reduce((result, cell) => {
              let compareRect = getRelativeRect(
                tableCell.domNode.getBoundingClientRect(),
                editorWrapper
              );
              let cellRect = getRelativeRect(
                cell.domNode.getBoundingClientRect(),
                editorWrapper
              );
              if (Math.abs(compareRect.x1 - cellRect.x) < ERROR_LIMIT) {
                result = cell;
              }
              return result;
            }, null);

          for (let i = cellColspan; i > 0; i--) {
            this.insertCell(nextRow, refInNextRow);
          }

          i -= 1;
          nextRow = nextRow.next;
        }

        tableCell.format('rowspan', 1);
      }
    });
  }
  
  /**
   * @returns {TableRow[]}
   */
  rows() {
    const body = this.children.tail;
    if (body == null) {
      return [];
    }
    return body.children.map(row => row);
  }
  
  /**
   * 
   * @param {number} from 
   * @param {number} to 
   */
  moveColumn(from, to) {
    
    const [body] = this.descendants(TableBody);
    const [tableColGroup] = this.descendants(TableColGroup);
    const tableCols = this.descendants(TableCol);
    const cellMap = generateCellMap(body);
    let insertBeforeIndex = to + 1;
    if (from > to) {
      insertBeforeIndex = to;
    }
    if (insertBeforeIndex === from) {
      insertBeforeIndex = insertBeforeIndex - 1;
    }

    for (let rowIndex = 0; rowIndex < cellMap.length; rowIndex++) {
      const cells = cellMap[rowIndex];

      if (
        from > 0 
        && from + 1 < cells.length
        && (
          cells[from] === cells[from + 1]
          || cells[from] === cells[from - 1]
        )
      ) {
        throw RangeError('Column cannot be moved while cells are merged. Please unmerge the cells and try again.');
      }

      if (
        insertBeforeIndex > 0
        && cells[insertBeforeIndex] === cells[insertBeforeIndex - 1]
      ) {
        throw RangeError('Unable to move column to destination with merged cells. Please unmerge the cells and try again.');
      }
      let formats;
      for (let colIndex = 0; colIndex < cells.length; colIndex++) {
        const cell = cells[colIndex];
        const cellFormats = cell.formats();
        if (!formats) {
          formats = cellFormats;
        } else {
          for (let styleIndex = 0; styleIndex < BORDER_STYLES.length; styleIndex++) {
            const style = BORDER_STYLES[styleIndex];
            if (formats[style] !== cellFormats[style]) {
              throw RangeError('Column cannot be moved due to inconsistent border styles in some rows. Please make sure all cells in a row have the same border style before moving.');
            }
          }
        }
      }
    }
    tableColGroup.insertBefore(tableCols[from], tableCols[insertBeforeIndex]);
    cellMap.forEach((cells, index) => {
      const cellToMove = cells[from];
      // This is a cell spanned across row. Should have been already moved. So ignore.
      if (index > 0
      && cellMap[index - 1][from]  === cellToMove) {
        return;
      }
      let cursor = insertBeforeIndex;
      // This is a cell spanned across row. Use the next cell.
      while (
        index > 0
        && cursor < cells.length
        && cellMap[index - 1][cursor]  === cells[cursor]
      ) {
        cursor++;
      }
      let moveBefore = cursor <= cells.length ? cells[cursor] : null;

      // Moving to same location. So ignore.
      if (cellToMove === moveBefore) {
        return;
      }
      
      // Quill handles `insertBefore` if `moveBefore` is null. 
      // `appendChild` is just `insertBefore` without ref
      cellToMove.parent.insertBefore(cellToMove, moveBefore);
    });

  }
}
TableContainer.blotName = 'table-container';
TableContainer.className = 'quill-better-table';
TableContainer.tagName = 'TABLE';
TableContainer.dependents = [];
export default TableContainer;
