/**
 * 
 * @typedef {Object} PopupRef
 * @property {Window} _ref - Private
 * @property {Object<string, ((this: Window, ev: WindowEventMap[keyof WindowEventMap]) => any)>} _events - Private
 * @property {Parameters<clearInterval>['0']=} _closeInterval - Private
 */

/**
 * 
 * @param {object} props 
 * @param {string} props.url 
 * @param {string?} props.name - defaults to TBPopup
 * @returns {PopupRef}
 */
export const openPopup = ({
  url,
  name
}) => {
  const ref = window.open(url, name || 'TBPopup');
  return {
    _ref: ref,
    _events: {}
  };
};

/**
 * 
 * @param {PopupRef} ref 
 */
export const closePopup = (ref) => {
  removeAllListeners(ref);
  clearInterval(ref._closeInterval);
  ref._ref.close();
};

/**
 * 
 * @param {PopupRef} ref 
 * @param {() => any} listener 
 */
export const onPopupClose = (ref, listener) => {
  ref._closeInterval = setInterval(() => {

    if (!ref._ref || ref._ref.closed) {
      clearInterval(ref._closeInterval);
      listener();
    }
  }, 500);
};

/**
 * 
 * @param {PopupRef} ref =
 */
const removeAllListeners = (ref) => {
  for (const evt in ref._events) {
    if (!Object.hasOwnProperty.call(ref._events, evt)) {
      continue;
    }
    const handler = ref._events[evt];
    // @ts-ignore
    removeListener(ref, evt, handler);
  }
};


/**
 * 
 * @param {PopupRef} ref 
 * @param {K} event 
 * @param {((this: Window, ev: WindowEventMap[K]) => any)=} handler 
 * @template {keyof WindowEventMap} K
 */
const removeListener = (ref, event, handler) => {
  delete ref._events[event];
  ref._ref.removeEventListener(event, handler || ref._events[event]);
};

/**
 * Allows only one handler for an event
 * @param {PopupRef} ref 
 * @param {K} event 
 * @param {(this: Window, ev: WindowEventMap[K]) => any} handler 
 * @template {keyof WindowEventMap} K
 */
export const addListener = (ref, event, handler) => {
  ref._events[event] = handler;
  ref._ref.addEventListener(event, handler);
};