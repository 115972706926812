import TableCol from './tableCol';
import TableColGroup from './tableColGroup';
import TableCellLine from './tableCellLine';
import TableCell from './tableCell';
import TableRow from './tableRow';
import TableBody from './tableBody';
import TableContainer from './tableContainer';
import TableViewWrapper from './tableViewWrapper';
import Header from './header';



import {
  rowId,
  cellId
} from './util';

import { CELL_ATTRIBUTES, CELL_IDENTITY_KEYS } from './constants';


TableViewWrapper.allowedChildren = [TableContainer];
TableContainer.requiredContainer = TableViewWrapper;

TableContainer.allowedChildren = [TableBody, TableColGroup];
TableBody.requiredContainer = TableContainer;

TableBody.allowedChildren = [TableRow];
TableRow.requiredContainer = TableBody;

TableRow.allowedChildren = [TableCell];
TableCell.requiredContainer = TableRow;

TableCell.allowedChildren = [TableCellLine, Header];
TableCellLine.requiredContainer = TableCell;

TableColGroup.allowedChildren = [TableCol];
TableColGroup.requiredContainer = TableContainer;

TableCol.requiredContainer = TableColGroup;



export {
  // blots
  TableCol,
  TableColGroup,
  TableCellLine,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableViewWrapper,

  // identity getters
  rowId,
  cellId,

  // attributes
  CELL_IDENTITY_KEYS,
  CELL_ATTRIBUTES
};

