import { useTypedSelector } from './hooks';
import { useEffect, useState } from 'react';
import { checkAnyAppInstalled } from './extension';
import { clientSupportsFeature } from './desktop_shared_utils';
import { isElectronApp } from './raw_flags';

/**
 * @returns {{ hasApp: boolean, loaded: boolean }}
 */
export function useAnyAppInstalled() {
  const userAuthenticatedEmail = useTypedSelector(store => store.userState.isLoaded && store.userState.email);
  const [hasApp, setHasApp] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    checkAnyAppInstalled(userAuthenticatedEmail).then((installed) => {
      setHasApp(!!installed);
      setLoaded(true);
    });
  }, [userAuthenticatedEmail]);

  return { hasApp, loaded };
}

/**
 * 
 * @param {string} featureName 
 * @param {boolean} initialState 
 * @returns {boolean} 
 */
export function useDesktopClientSupportsFeature(featureName, initialState = false) {
  const [support, setSupport] = useState(initialState);

  useEffect(() => {
    if (isElectronApp()) {
      (async function() {
        setSupport(await clientSupportsFeature(featureName));
      })();
    }
  }, [featureName]);

  return support;
}