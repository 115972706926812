import TableBody from './tableBody';
import TableCell from './tableCell';
import TableCellLine from './tableCellLine';
import TableColGroup from './tableColGroup';
import TableRow from './tableRow';

export function rowId() {
  const id = Math.random()
    .toString(36)
    .slice(2, 6);
  return `row-${id}`;
}

export function cellId() {
  const id = Math.random()
    .toString(36)
    .slice(2, 6);
  return `cell-${id}`;
}

/**
 * 
 * @param {TableColGroup} colGroup 
 * @param {number} cols 
 * @returns 
 */
function fixCols(colGroup, cols) {
  const colsLength = colGroup?.children.length || 0;
  if (colsLength === cols) {
    return;
  }
  if (colsLength < cols) {
    /*if (!colGroup) {
      colGroup = colGroup.scroll.create(TableColGroup.blotName, {});
      table.insertBefore(colGroup, body);
    }*/
    for (let index = 0; index < (cols - colsLength); index++) {
      colGroup.insertBefore(
        colGroup.scroll.create(TableColGroup.allowedChildren[0].blotName, {}),
        colGroup.children.tail || null
      );
    }
  } else if (colsLength > cols) {
    for (let index = 0; index < (colsLength - cols); index++) {
      colGroup.children.tail.remove();
    }
  }
}

/**
 * Balances the cells.
 * @param {import('./tableContainer').default} table 
 * @returns 
 */
export function balanceCells(table) {
  if (
    !table
    || !table.domNode?.parentNode?.parentNode
  ) {
    return;
  }
  const tableBodies = /** @type {TableBody[]} */ (table.descendants(TableBody));
  if (!tableBodies.length) {
    // Remove table if there are no bodies.
    table.remove();
    return;
  }
  if (tableBodies.length !== 1) {
    return;
  }
  const tableBody = tableBodies[0];

  const rows = /** @type {TableRow[]} */ (tableBody.descendants(TableRow));
  if (!rows?.length) {
    return;
  }

  let [colGroup] = /** @type {[TableColGroup, number]} */ (table.descendant(TableColGroup, 0));
  if (!colGroup) {
    colGroup = /** @type {TableColGroup} */ (table.scroll.create(TableColGroup.blotName, {
    }));
    table.insertBefore(colGroup, tableBody);
  }
  const cellMap = generateCellMap(tableBody);
  const columnsLength = cellMap.reduce((len, rowCells) => {
    return len > rowCells.length ? len : rowCells.length;
  }, 0);
  fixCols(colGroup, columnsLength);
  cellMap.forEach((cellsInRow, rowIndex) => {
    if (cellsInRow.length >= columnsLength) {
      return;
    }
    const row = tableBody.children.at(rowIndex);
    new Array(columnsLength - cellsInRow.length).fill(0).forEach(() => {
      let rowId;
      if (row.children.head != null) {
        const formats = TableCell.formats(row.children.head.domNode);
        rowId = formats.row;
      }
      const blot = /** @type {TableCellLine} */ (row.scroll.create(TableCellLine.blotName, {
        row: rowId
      }));
      row.appendChild(blot);
      blot.optimize(); // Add break blot
    });
  });
}


/**
 * Generates an array of array of cells. Cells will repeated in the place of spanned cell.
 * @param {TableBody} tableBody 
 * @param {boolean=} repeat - If true, cells will be repeated or will be set null.
 */
export const generateCellMap = (tableBody, repeat = true) => {
  const mappings = [];
  const spannedCells = {};
  let row = /** @type {TableRow} */ (tableBody.children.head);
  let rowCursor = 0;
  // for every row
  while (row) {
    let cellCursor = 0;
    /**
     * @type {TableCell[]}
     */
    const rowMapping = [];
    mappings.push(rowMapping);

    // for every cell
    for (let rowChildIndex = 0; rowChildIndex < row.children.length; rowChildIndex++) {
      const cell = row.children.at(rowChildIndex);
      let numColspan = cell.colspan(),
        numRowspan = cell.rowspan();

      // Spanned cell exist. Lets push those cells first
      while (spannedCells[rowCursor] && spannedCells[rowCursor][cellCursor]) {
        rowMapping.push(repeat ? spannedCells[rowCursor][cellCursor] : null);

        // Move the cell cursor
        cellCursor++;
      }

      // Lets record the cells in next rows for future.
      for (let rowspanIndex = 1; rowspanIndex < numRowspan; rowspanIndex++) {
        spannedCells[rowCursor + rowspanIndex] = spannedCells[rowCursor + rowspanIndex] || {};
        spannedCells[rowCursor + rowspanIndex][cellCursor] = cell;
      }

      for (let colspanIndex = 1; colspanIndex < numColspan; colspanIndex++) {
        // Move the cell cursor
        cellCursor++;

        //We need to repeat those many times.
        rowMapping.push(cell);

        // We have spanned cell recording. Lets record as colspan too
        for (let rowspanIndex = 1; rowspanIndex < numRowspan; rowspanIndex++) {
          spannedCells[rowCursor + rowspanIndex][cellCursor] = cell;
        }
      }

      rowMapping.push(cell);
      cellCursor++;
      
    }
    // Spanned cells exist. Lets push those cells also
    while (spannedCells[rowCursor] && spannedCells[rowCursor][cellCursor]) {
      rowMapping.push(repeat ? spannedCells[rowCursor][cellCursor] : null);

      // Move the cell cursor
      cellCursor++;
    }
    row = row.next;
    rowCursor++;
  }
  return mappings;
};


/**
 * Groups the cells into respective rows.
 * @param {TableCell[]} cells 
 * @param {TableCell[][]} cellMap 
 */
export const groupCells = (cells, cellMap) => {
  /**
   * @type {{ [key: string]: TableCell[] }}
   */ 
  let rows = {};
  for (const cell of cells) {
    const rowIndex = cellMap.findIndex((cellsInRow) => cellsInRow.includes(cell));
    if (!rows[rowIndex]) {
      rows[rowIndex] = [];
    }
    rows[rowIndex].push(cell);
  }
  return rows;
};