import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import T from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import TeamIcon from '@mui/icons-material/GroupWork';
import ListSubheader from '@mui/material/ListSubheader';
import GroupItem from './GroupItem';
import AddButton from './AddButton';
import ConflictWarning from './ConflictWarning';
import { useDispatch } from 'react-redux';
import { EmptyState } from '../EmptyState/EmptyState';
import FolderDisabledIcon from '@mui/icons-material/WorkOffOutlined';
import Collapse from '@mui/material/Collapse';
import { moveSnippet, moveGroup, enableGroup } from '../../data';
import { iconStyleFn, itemStyleFn, innerDivStyle, FOLDER_HEADER_HEIGHT, SNIPPET_HEIGHT } from './shared';
import { limitationsState } from '../Version/limitations';
import { usersSettingsRef } from '@store';
import { useTypedSelectorDeepEquals, useTypedSelectorShallowEquals } from '../../hooks';
import { getOrgLogo, memberRestricted, orgLoadedIfNeeded } from '../../flags';
import IconButton from '@mui/material/IconButton';
import ExpandIcon from '@mui/icons-material/KeyboardArrowRight';
import CollapseIcon from '@mui/icons-material/KeyboardArrowDown';
import { storage } from '../../utilities';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Skeleton } from '@mui/material';
import { isGroupPublicManageRestricted } from '../Group/group_public_rules';
import Viewport from './Viewport';
import { sync } from '../../Sync/syncer';
import { useHistory } from 'react-router-dom';
import OrgLogo from '../Org/OrgLogo';


// Snippet list viewport extra buffer distance in pixels. About 5 folders or snippets.
const VIEWPORT_OVERSIZE = Math.max(FOLDER_HEADER_HEIGHT, SNIPPET_HEIGHT) * 5;


/**
 * @param {object} props
 * @param {React.CSSProperties=} props.styleContent
 */
function SnippetListBase(props) {
  let {
    creatingSnippets,
    disabledGroupsCollapsed,
    teamGroupsCollapsed,
    groupsLoaded,
    peekingGroupId,
    peekingOnly,
    orgLoadedCheck,
    isPeekingGroupPublicRestricted,
    selected,
    orgLogo
  } = useTypedSelectorShallowEquals(store => {
    let user = store.userState;
    let peekingGroupId = store.uiState.peekingGroupId;
    let peekingGroup = store.dataState.groups[peekingGroupId];
    return {
      peekingGroupId: store.uiState.peekingGroupId,
      peekingOnly: store.uiState.peekingGroupId && store.dataState.groups[store.uiState.peekingGroupId] && store.dataState.groups[store.uiState.peekingGroupId].peekingOnly,
      creatingSnippets: store.uiState.creatingSnippets,
      disabledGroupsCollapsed: !!store.userState.disabled_groups_collapsed,
      teamGroupsCollapsed: !!store.userState.team_groups_collapsed,
      groupsLoaded: store.userState.settingsLoaded,
      orgLoadedCheck: orgLoadedIfNeeded(store),
      isPeekingGroupPublicRestricted: peekingGroup && isGroupPublicManageRestricted(user, peekingGroup),
      selected: store.uiState.selected,
      orgLogo: getOrgLogo(store)
    };
  });
  const snippetListRef = useRef(/** @type {HTMLDivElement} */ (null));
  const [buttonsHaveShadow, setShowShadow] = useState(false);

  let limitations = useTypedSelectorDeepEquals(store => limitationsState(store));

  let teams = useTypedSelectorDeepEquals(store => {
    /**
     * @type {Object<string, {name: string, groups: any[]}>}
     */
    let teams = null;
    
    if (store.orgState && store.orgState.teams) {
      teams = {};
      for (let teamId in store.orgState.teams) {
        teams[teamId] = {
          name: store.orgState.teams[teamId].name,
          groups: store.orgState.teams[teamId].groups
        };
      }
    }

    return teams;
  });

  let groupsBeenLoadedRef = useRef(false);
  let {
    groupIds,
    groupSnippetStarts,
    groupSelectedId,
    isDisabledGroupSelected,
    userGroups,
    orgDefaultGroups,
    groupsBeenLoaded,
    groupSnippetCounts,
  } = useTypedSelectorDeepEquals((store) => {
    let groupIds = [];
    let disableAdd = memberRestricted(store, 'create');
    let groupsLoading = false;

    let userGroups = store.userState.groups;

    let groups = store.dataState.groups;
    for (let id in groups) {
      if (store.dataState.ignoreGroups.includes(id)) {
        continue;
      }
      if (disableAdd) {
        // Don't show groups created by the user that haven't been shared
        // Logic mirrored in sync.js
        if (groups[id].created_by === store.userState.uid
              && Object.keys(groups[id].permissions).length === 1 &&
              (!groups[id].associated_team_ids || groups[id].associated_team_ids.length === 0)) {
          continue;
        }
      }
      groupIds.push(id);

      if (!groupsLoading && groups[id].loading) {
        groupsLoading = true;
      }
    }
    groupIds.sort(); // so redux comparisons are stable

    if (!groupsLoading && groupIds.length > 0) {
      groupsBeenLoadedRef.current = true;
    }

    let groupSnippetStarts = {},
      groupSnippetCounts = {};
    let snippetCount = 0;
    if (userGroups) {
      // New maximum snippet determination
      let enabledGroups = Object.keys(userGroups).map(id => ({
        id,
        userInfo: userGroups[id]
      })).filter(x => !x.userInfo.disabled && groups[x.id]);
      enabledGroups.sort((a, b) => (a.userInfo.order || 0) - (b.userInfo.order || 0));
      
      for (let g of enabledGroups) {
        groupSnippetStarts[g.id] = snippetCount;
        const thisGroupSnippetCount = groups[g.id].snippets.length;
        groupSnippetCounts[g.id] = thisGroupSnippetCount;
        snippetCount += thisGroupSnippetCount;
      }
    }
    const selected = store.uiState.selected;
    let groupSelectedId = groups[selected] ? selected : null;

    if (!groupSelectedId && groups) {
      groupSelectedId = sync?.snippets?.[selected]?.group_id;
    }

    return {
      groupIds,
      groupSnippetStarts,
      groupSelectedId: groupSelectedId,
      isDisabledGroupSelected: userGroups?.[groupSelectedId]?.disabled,
      userGroups,
      orgDefaultGroups: store.orgState.org && store.orgState.org.default_groups,
      groupsBeenLoaded: groupsBeenLoadedRef.current,
      groupSnippetCounts
    };
  });

  useEffect(() => {
    const target = snippetListRef.current;
    if (!target) {
      return;
    }
    function listener() {
      if (target.scrollTop > 0) {
        setShowShadow(true);
      } else {
        setShowShadow(false);
      }
    }
    target.addEventListener('scroll', listener);
    return () => {
      target.removeEventListener('scroll', listener);
    };
  }, []);

  useLayoutEffect(() => {
    // scroll to selected snippet or folder after snippets loaded
    if (!groupsBeenLoaded || !selected) {
      return;
    }
    let item;
    let items = document.getElementsByClassName(`list-item-${selected}`);
    if (items.length === 1) {
      item = items[0];
    } else if (items.length === 0) {
      //If not item selected. Lets check if collapsed root group is selected
      item = document.querySelector('[data-root-group-selected]');
    }
    if (!item) {
      return;
    }

    item.scrollIntoView({
      behavior: 'auto',
      block: 'nearest'
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsBeenLoaded]);

  let dispatch = useDispatch();
  const { push: navigate } = useHistory();

  /**
   * @param {string} type
   * 
   * @return {object}
   */
  function groups(type) {
    if (!['enabled', 'disabled'].includes(type)) {
      throw Error('invalid group type: ' + type);
    }

    let hasEnabledGroups = userGroups && !!Object.keys(userGroups).filter(x => !userGroups[x].disabled).length;

    let isCreatingSnippets = creatingSnippets && !Object.keys(groupIds).length;

    if (!isCreatingSnippets && groupsLoaded && (!hasEnabledGroups || Object.keys(groupIds).length)) {
      let children = [];
      for (const id of groupIds) {
        if (
          (type === 'enabled' && (id in userGroups) && !userGroups[id].disabled)
          || (type === 'disabled' && (id in userGroups) && userGroups[id].disabled)) {
          let info = userGroups[id];
          let order = 0;
          if (info) {
            order = info.order;
          }

          let child = {
            id,
            order,
            depth: type === 'enabled' ? 0 : 1,
            active: type === 'enabled'
          };

          children.push({
            id,
            order,
            child
          });
        }
      }
      
      children.sort((a, b) => {
        let aOrder = a.order || 0;
        let bOrder = b.order || 0;
        
        if (aOrder === bOrder) {
          return a.id.localeCompare(b.id);
        }
        return aOrder - bOrder;
      });

      if (type === 'enabled' && !children.length) {
        return (<div style={{ marginTop: 30, marginBottom: 30 }}>
          <EmptyState
            icon="MISSING"
            title="No enabled snippets"
            description="Create snippets to get started..."
          />
        </div>);
      } else {
        return children.map((item, i) => {
          let groupId = item.child.id,
            snippetStartIndex = groupSnippetStarts[groupId],
            snippetCount = groupSnippetCounts[groupId];

          let violation;
          if (type === 'enabled') {
            if (limitations.MAX_GROUPS) {
              if (i >= limitations.MAX_GROUPS) {
                violation = {
                  error: `You cannot have more than ${limitations.MAX_GROUPS} enabled folders.`,
                  upgrade: limitations.CAN_UPGRADE_MAX_GROUPS && 'Upgrade for more.'
                };
              }
            }
          }
          // By default, no snippet in this group exceeds limits
          let limitExceedIndex = undefined;
          if (limitations.MAX_SNIPPETS !== undefined && snippetStartIndex !== undefined) {
            if (snippetStartIndex >= limitations.MAX_SNIPPETS) {
              // First snippet in this group exceeds limits
              limitExceedIndex = 0;
            } else if (snippetStartIndex + snippetCount > limitations.MAX_SNIPPETS) {
              // Some snippets in this group exceeds limits
              limitExceedIndex = limitations.MAX_SNIPPETS - snippetStartIndex;
            }
          }

          return <GroupItem
            dragCategory={type}
            groupId={groupId}
            key={groupId}
            index={i}
            depth={item.child.depth}
            limitExceedIndex={limitExceedIndex}
            violation={violation}
            active={item.child.active}
          />;
        });
      }
    } else {
      return <div style={{ marginTop: 12 }}>
        <div style={{ width: '60%', display: 'flex' }}><Skeleton variant="circular" width={24} height={24}/> <Skeleton sx={{ flex: 1, ml: 2 }} /></div>
        <div style={{ marginLeft: 32, width: '80%' }}><Skeleton /></div>
        <div style={{ marginLeft: 32, width: '70%' }}><Skeleton /></div>
        <div style={{ marginLeft: 32, width: '80%' }}><Skeleton /></div>
        <div style={{ marginLeft: 32, width: '70%' }}><Skeleton /></div>
        <div style={{ width: '60%', display: 'flex', marginTop: 12 }}><Skeleton variant="circular" width={24} height={24} /> <Skeleton sx={{ flex: 1, ml: 2 }} /></div>
        <div style={{ marginLeft: 32, width: '80%' }}><Skeleton /></div>
        <div style={{ marginLeft: 32, width: '70%' }}><Skeleton /></div>
        <div style={{ marginLeft: 32, width: '80%' }}><Skeleton /></div>
      </div>;
    }
  }

  function teamGroupsList() {
    let found = false;
    let isTeamGroupSelected = false;
    let organizedGroups = {};

    if (teams) {
      let teamKeys = Object.keys(teams);
      // teams should be sorted alphabetically
      teamKeys.sort((a, b) => (teams[a].name && teams[b].name) ? teams[a].name.localeCompare(teams[b].name) : 0);

      for (let teamId of teamKeys) {
        if (teams[teamId].groups && teams[teamId].groups.length) {
          found = true;
          // eslint-disable-next-line no-loop-func
          organizedGroups[teamId] = teams[teamId].groups.map((id, i) => {
            if (groupSelectedId === id) {
              isTeamGroupSelected = true;
            }
            return (
              <GroupItem
                dragCategory={'teams_' + teamId}
                groupId={id}
                key={teamId + '-' + id}
                index={i}
                depth={1}
                active={!userGroups || !userGroups[id] || !userGroups[id].disabled}
                local="collapsed"
                fixed
              />
            );
          });
        }
      }
    }
    
    if (orgDefaultGroups && orgDefaultGroups.length) {
      found = true;
      isTeamGroupSelected = isTeamGroupSelected || orgDefaultGroups.some(g => g === groupSelectedId);
    }

    if (!found) {
      return null;
    }

    let teamsList = [];
    if (orgDefaultGroups && orgDefaultGroups.length) {
      teamsList.push(<ListSubheader key="org" disableSticky style={{
        fontSize: 16
      }}>Your organization</ListSubheader>);
      teamsList = teamsList.concat(orgDefaultGroups.map((id, i) => <GroupItem
        dragCategory="teams_org"
        groupId={id}
        key={'org-' + id}
        index={i}
        depth={1}
        active={!userGroups || !userGroups[id] || !userGroups[id].disabled}
        local="collapsed"
        fixed
      />));
    }
    for (let teamId in organizedGroups) {
      teamsList.push(<ListSubheader key={teamId} disableSticky style={{
        fontSize: 16
      }}>{teams[teamId].name}</ListSubheader>);
      teamsList = teamsList.concat(organizedGroups[teamId]);
    }
    
    
    return (<>
      <Divider style={{ marginTop: 15, marginBottom: 15 }} />

      <ListItem
        dense
        disableGutters
        data-root-group-selected={isTeamGroupSelected || undefined}
        style={itemStyleFn({
          selected: isTeamGroupSelected && teamGroupsCollapsed !== false,
          depth: 0,
          selectable: false
        })}
      >
        <ListItemIcon sx={{ minWidth: 44 }}>{orgLogo ? <OrgLogo logo={orgLogo} size={28} style={iconStyleFn({})} /> : <TeamIcon style={iconStyleFn({})} />}</ListItemIcon>
        <ListItemText
          style={{ paddingLeft: 0 }}
          primary={<div style={innerDivStyle(true)}>Team folders</div>}
        />
        {teamGroupsCollapsed === false ?
          <IconButton
            onClick={() => {
              storage.update(usersSettingsRef, { team_groups_collapsed: true }, 'HIDE_AUTOSAVE');
            }
            }
            size="small" style={{ marginRight: 3, marginLeft: 3 }} 
          >
            <CollapseIcon fontSize="small" />
          </IconButton> : <IconButton
            onClick={() => {
              storage.update(usersSettingsRef, { team_groups_collapsed: false }, 'HIDE_AUTOSAVE');
            }
            }
            size="small" style={{ marginRight: 3, marginLeft: 3 }}
          >
            <ExpandIcon fontSize="small" />
          </IconButton>
        }
      </ListItem>
      <Collapse in={teamGroupsCollapsed === false} timeout="auto" unmountOnExit>
        {teamsList}
      </Collapse>
    </>);
  }

  let enabledGroups = groups('enabled');
  let disabledGroups = groups('disabled');

  return (<div style={{
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRight: 'solid 1px #e0e0e0'
  }}>
    <AddButton hasBottomShadow={buttonsHaveShadow} />

    <DragDropContext
      onDragEnd={(result)=>{
        if (result.reason === 'DROP' && result.destination) {
          const destination = result.destination;
          if (destination.droppableId.startsWith('GROUP_')) {
            moveSnippet(result.draggableId.split('__/__')[0], destination.droppableId.split('__/__')[1], destination.index);
          } else if (destination.droppableId === 'ACTIVE_GROUPS') {
            moveGroup(result.draggableId, 'active', destination.index);
          } else if (destination.droppableId === 'DISABLED_GROUPS') {
            moveGroup(result.draggableId, 'disabled', destination.index);
          }
        }
      }}
    >
      <Viewport oversize={VIEWPORT_OVERSIZE} style={{
        position: 'relative',
        flex: 1,
        display: 'block',
        paddingRight: 15
      }}
      ref={snippetListRef}
      >
        <List
          dense
          disablePadding
          sx={{
            paddingTop: {
              xs: '60px',
              sm: '36px'
            },
            paddingBottom: '20px'
          }}
          style={props.styleContent || {
            paddingLeft: 0,
          }}
        >
          {groupsLoaded && orgLoadedCheck && peekingGroupId && peekingOnly && <Paper variant="outlined" style={{ padding: 14, marginBottom: 12 }}>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <T style={{ flex: 1 }} variant="subtitle2">Quick look</T>
              {isPeekingGroupPublicRestricted ? null : <Button
                startIcon={<CloseIcon />}
                variant="outlined"
                size="small"
                onClick={() => {
                  dispatch({ type: 'CLEAR_PEEKING_GROUP' });
                  navigate('/welcome');
                }}
              >Done</Button>}
            </div>
            <T variant="caption" color="textSecondary" style={{ display: 'block', marginBottom: 12 }}>
              {isPeekingGroupPublicRestricted 
                ? 'Quick look to view public shared folder.'
                : 'Quick look to view and edit this folder without enabling it. If you would like to use the folder, you can '}
              {
                !isPeekingGroupPublicRestricted 
                  ?  <span style={{ textDecoration: 'underline', color: '#1068bf', cursor: 'pointer', }} onClick={ async (event) => {
                    event.preventDefault();
                    await enableGroup(peekingGroupId, navigate);
                  }}>enable it.</span> : null }
            </T>
            <GroupItem
              dragCategory={'other_' + peekingGroupId}
              groupId={peekingGroupId}
              key="other_group"
              index={0}
              depth={0}
              active={!userGroups || !userGroups[peekingGroupId] || !userGroups[peekingGroupId].disabled}
              local="expanded"
              fixed
              childrenFixed
            />
          </Paper>}

          <Droppable
            type="GROUP"
            droppableId="ACTIVE_GROUPS"
          >
            {(provided) => (
              <div
                ref={provided.innerRef} 
                {...provided.droppableProps}
              >
                {enabledGroups}

                {provided.placeholder}
              </div>
            )}
          </Droppable>

          {teamGroupsList()}

          {(Array.isArray(disabledGroups) && disabledGroups.length) ? (<div>
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            
            <ListItem
              dense
              disableGutters
              data-root-group-selected={isDisabledGroupSelected || undefined}
              style={Object.assign({ marginBottom: 10 }, itemStyleFn({
                selected: isDisabledGroupSelected && disabledGroupsCollapsed !== false,
                depth: 0,
                selectable: false
              }))}
            >
              <ListItemIcon sx={{ minWidth: 44 }}><FolderDisabledIcon style={iconStyleFn({})} /></ListItemIcon>
              <ListItemText
                style={{ paddingLeft: 0 }}
                primary={<div style={innerDivStyle(true)}>Disabled folders</div>}
              />
              {disabledGroupsCollapsed === false ?
                <IconButton
                  onClick={() => {
                    storage.update(usersSettingsRef, { disabled_groups_collapsed: true }, 'HIDE_AUTOSAVE');
                  }
                  }
                  size="small" style={{ marginRight: 3, marginLeft: 3 }}
                >
                  <CollapseIcon fontSize="small" />
                </IconButton> : <IconButton
                  onClick={() => {
                    storage.update(usersSettingsRef, { disabled_groups_collapsed: false }, 'HIDE_AUTOSAVE');
                  }
                  }
                  size="small" style={{ marginRight: 3, marginLeft: 3 }}
                >
                  <ExpandIcon fontSize="small" />
                </IconButton>
              }
            </ListItem>
            <Collapse in={disabledGroupsCollapsed === false} timeout="auto" unmountOnExit>
              <Droppable
                type="GROUP"
                droppableId="DISABLED_GROUPS"
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef} 
                    {...provided.droppableProps}
                  >
                    {disabledGroups}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Collapse>
          </div>) : null}
        </List>
      </Viewport>
    </DragDropContext>
    {groupsBeenLoaded ? <ConflictWarning /> : null}
  </div>);
}


const SnippetList = React.memo(SnippetListBase);
export default SnippetList;
