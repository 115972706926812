import React from 'react';
import {
  Box,
  IconButton,
  Tooltip
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LinkIcon from '@mui/icons-material/Link';
import { sync } from '../../Sync/syncer';
import { Link } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';

/**
 * 
 * @param {object} props 
 * @param {string} props.shortcut
 * @param {boolean=} props.inNewWindow
 * @param {import('@mui/system').SystemStyleObject<import('@mui/system').Theme>=} props.sx
 * @returns 
 */
const SnippetLink = ({
  shortcut,
  inNewWindow,
  sx
}) => {
  let snippetId = null;
  let snippetName = null;
  const snippets = sync.getSnippetsByShortcut(shortcut);
  if (snippets.length) {
    // Use first snippet
    snippetId = snippets[0].id;
    snippetName = snippets[0].data.name;
  }
  const multiple = snippets.length > 1;
  if (!snippetId) {
    return <Tooltip title="Snippet not found">
      <Box
        sx={[{
          px: 1,
          display: 'flex',
          alignItems: 'center'
        }, sx]}
        component="span"
      >
        <WarningIcon
          color="warning"
          fontSize="small"
        />
      </Box>
    </Tooltip>;
  }
  return (
    <Tooltip
      title={multiple ? 'Multiple snippets with the same shortcut' : `Open ${snippetName}`}
    >
      <IconButton
        size="small"
        component={Link}
        to={snippetId ? `/snippet/${snippetId}` : undefined}
        sx={sx}
        color={multiple ? 'warning' : 'primary'}
        target={inNewWindow ? '_blank' : '_self'}
        onClick={(evt) => {
          // This is to stop Snippect selector from selecting the item.
          evt.stopPropagation();
        }}
      >
        {inNewWindow
          ? <OpenInNewIcon fontSize="small" />
          : <LinkIcon fontSize="small" />
        }
      </IconButton>
    </Tooltip>
  );
};

export default SnippetLink;