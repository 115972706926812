import React, { useState } from 'react';
import { addBreadcrumb } from '@sentry/browser';
import { createDom } from '../../snippet_processor/SnippetProcessor';
import { Environment } from '../../snippet_processor/DataContainer';
import FormRenderer from '../FormRenderer/FormRenderer';
import { proUsage } from '../Version/limitations';
import { ProChip } from '../Version/VersionChip';
import { Link } from 'react-router-dom';
import './SnippetPreview.css';
import { makeConfig } from './preview_utilities';
import useDeepCompareEffect from 'use-deep-compare-effect';
import T from '@mui/material/Typography';
import { sendToExtension } from '../../extension';


/**
 * @param {object} props
 * @param {DeltaType | import('quill/core').Delta} props.delta
 * @param {boolean=} props.quickentry
 * @param {boolean=} props.isPro
 * @param {boolean=} props.isBusiness
 * @param {boolean=} props.hideProMessage
 * @param {boolean=} props.noAutoFocus
 * @param {import('../../flags').ConnectedConfig=} props.connected - connected settings
 * @param {boolean=} props.unsafe - don't allow potentially sensitive things such as remote data loading
 * @param {import('../../snippet_processor/DataContainer').Config['unsafeOverrides']} [props.unsafeOverrides]
 * @param {import('../../snippet_processor/DataContainer').Config} props.config
 * @param {React.CSSProperties=} props.containerStyle
 * @param {function=} props.usageCallback - Optional callback with pro usage data
 * @param {Function=} props.domGeneratedFn - optional function called when the dom is first generated
 * @param {Function=} props.domUpdatedFn - optional function called when the dom is updated
 * @param {Function=} props.domRefreshFn - optional function called when new dom is received
 * @param {Function=} props.connectedConfigureFn
 * @param {Function=} props.addonConfigureFn
 * @param {boolean=} props.disableSelection
 * @param {import('../../snippet_processor/DownstreamProcess').OnFormAcceptFn} [props.onAccept]
 * @param {import('../../snippet_processor/DownstreamProcess').ConfigDefType['usedSiteSelectorData']} [props.usedSiteSelectorData]
 * @param {Parameters<typeof FormRenderer>[0]['controls']} [props.controls]
 * @param {(items: import('../FormRenderer/FormRenderer').RemoteProgressItemType[]) => void} [props.onRemoteStatusUpdate]
 */
function SnippetPreviewBase(props) {
  let [domData, setDomData] = useState(/** @type {{ dom: import('../../snippet_processor/ParseNode').default, config: import('../../snippet_processor/DataContainer').Config, proUsageData: Awaited<ReturnType<typeof proUsage>>}} */ (null));
  let [domGenerated, setDomGenerated] = useState(/** @type {boolean} */ (null));
  const [formKey, setFormKey] = useState(0);

  useDeepCompareEffect(() => {
    updateState(props);
  }, [props.quickentry, props.delta, props.config.user, props.config.snippet?.id]);

  /**
   * @param {Parameters<typeof SnippetPreviewBase>[0]} props 
   */
  async function updateState(props) {
    let config = makeConfig(props);

    if (props.connected) {
      config = Object.assign(config, props.connected);
    } else {
      // lock down the connected whitelists by default if nothing is specified
      config.pingHostWhitelist = [];
      config.loadHostWhitelist = [];
      config.connectedAddonWhitelist = [];
    }

    let newDelta = /** @type {DeltaType} */ ({ ops: props.delta.ops.slice() });
    let env = new Environment({}, config);
    let dom = await createDom(newDelta, env);
    let proUsageData = await proUsage(dom.clone(), env); // can mutate the dom, so we clone it

    config.usedCommandsWhitelist = proUsageData.features.COMMANDS.map(x => x.toUpperCase());
    config.usedLambdaWhitelist = proUsageData.features.LAMBDAS.slice();
    config.usedSiteSelectors = proUsageData.features.SITE_SELECTORS;
    if (env.config.usedSiteSelectors?.length && env.config.usedSiteSelectors.some(item => item.page || item.select !== 'no' || item.group)) {
      // Avoid making a round trip to the extension, if the user is not using
      // {site} commands that need that round trip.
      // This avoids a white flash when rendering the snippet preview in the dashboard.
      const extData = (await sendToExtension({ type: 'get_matching_tab_data', items: env.config.usedSiteSelectors }));
      const isOldExtension = extData === 'received';
      config.usedSiteSelectorData = isOldExtension ? (props.usedSiteSelectorData || env.config.usedSiteSelectors.map(x => env.config.selectorFn(x))) : (props.usedSiteSelectorData || extData?.usedSiteSelectorData || []);
      config.usedSiteTabSelections = extData?.usedSiteTabSelections || {};
      config.needsTabSelectInSiteCommand = !!extData?.needsTabSelectInSiteCommand;
    } else {
      config.usedSiteSelectorData = props.usedSiteSelectorData || [];
      config.usedSiteTabSelections = {};
    }

    if (props.usageCallback) {
      props.usageCallback(proUsageData);
    }
    setDomData({
      dom,
      config,
      proUsageData
    });
  }

  if (!domData) {
    return null;
  }

  /** @type {JSX.Element} */
  let proNotice = null;
  /** @type {JSX.Element} */
  let connectedNotice = null;

  if (!props.hideProMessage) {
    let pro = domData.proUsageData;
    let labels = (pro.proLabels || []).concat(pro.businessLabels || []);
    if (labels.length) {
      let uses = '';
      if (labels.length > 1) {
        uses = labels.slice(0, labels.length - 1).join(', ') + ' and ' + labels[labels.length - 1];
      } else {
        uses = labels[0];
      }
      if (pro.businessLabels && pro.businessLabels.length) {
        proNotice = <div className="snippet-pro"><ProChip
          zoom={0.75}
          type="business"
        /> <T variant="body2" style={{ display: 'inline-block' }}>This snippet uses
          {' ' + uses}. {props.isBusiness ? '' : <span>Get <Link to="/pro">Text Blaze Business</Link> to unlock {labels.length > 1 ? 'these' : 'this'} and more.</span>}</T></div>;
      } else {
        proNotice = <div className="snippet-pro"><ProChip
          zoom={0.75}
        /> <T variant="body2" style={{ display: 'inline-block' }}>This snippet uses
          {' ' + uses}. {props.isPro ? '' : <span>Get <Link to="/pro">Text Blaze Pro</Link> to unlock {labels.length > 1 ? 'these' : 'this'} and more.</span>}</T></div>;
      }
    }
  }


  return <div style={Object.assign({}, props.containerStyle || { overflow: 'hidden' }, {
    display: !domGenerated ? 'none' : undefined
  })} className="snippet-preview-wrapper">
    {connectedNotice}
    <div
      className="snippet-preview"
      style={{ userSelect: props.disableSelection ? 'none' : 'auto', overflow: props.controls !== 'chat-preview' ? 'auto' : undefined }}>
      <FormRenderer
        key={formKey}
        dom={domData.dom}
        countUsage={false}
        isPro
        config={domData.config}
        domUpdatedFn={props.domUpdatedFn}
        domRefreshFn={props.domRefreshFn}
        domGeneratedFn={() => {
          setDomGenerated(true);
          if (props.domGeneratedFn) {
            props.domGeneratedFn();
          }
        }}
        onRemoteStatusUpdate={props.onRemoteStatusUpdate}
        controls={props.controls}
        onAccept={props.onAccept}
        connectedConfigureFn={props.connectedConfigureFn}
        addonConfigureFn={props.addonConfigureFn}
        onReRender={async (fn) => {
          addBreadcrumb({
            message: 'Cross tab selection updated',
          });
          /** @type {typeof props.delta} */
          // @ts-ignore
          let newDelta = { ops: props.delta.ops.slice() };
          fn(domData.config);
          let env = new Environment({}, domData.config);
          let dom = await createDom(newDelta, env);
          setDomData(data => {
            return {
              dom,
              config: data.config,
              proUsageData: data.proUsageData,
            };
          });
          setFormKey(v => v + 1);
        }}
      />
    </div>
    {proNotice}
  </div>;
}


const SnippetPreview = React.memo(SnippetPreviewBase);
export default SnippetPreview;