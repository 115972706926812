
/**
 * @typedef {{
 *  waitForPendingWrites: typeof import('firebase/firestore')['waitForPendingWrites'];
 *  serverTimestamp: typeof import('firebase/firestore')['serverTimestamp'];
 *  getDocs: typeof import('firebase/firestore')['getDocs'];
 *  getDoc: typeof import('firebase/firestore')['getDoc'];
 *  onSnapshot: typeof import('firebase/firestore')['onSnapshot'];
 *  addDoc: typeof import('firebase/firestore')['addDoc'];
 *  updateDoc: typeof import('firebase/firestore')['updateDoc'];
 *  setDoc: typeof import('firebase/firestore')['setDoc'];
 *  deleteDoc: typeof import('firebase/firestore')['deleteDoc'];
 *  getFirestore: typeof import('firebase/firestore')['getFirestore'];
 *  getAuth: typeof import('firebase/auth')['getAuth'];
 *  getIdToken: typeof import('firebase/auth')['getIdToken'];
 *  getIdTokenResult: typeof import('firebase/auth')['getIdTokenResult'];
 *  collection: typeof import('firebase/firestore')['collection'];
 *  doc: typeof import('firebase/firestore')['doc'];
 *  where: typeof import('firebase/firestore')['where'];
 *  orderBy: typeof import('firebase/firestore')['orderBy'];
 *  limit: typeof import('firebase/firestore')['limit'];
 *  query: typeof import('firebase/firestore')['query'];
 *  getFunctions: typeof import('firebase/functions')['getFunctions'];
 *  httpsCallable: typeof import('firebase/functions')['httpsCallable'];
 *  docExists: (ref: import('firebase/firestore').QueryDocumentSnapshot) => boolean
 * }} Driver
 *
 * @exports Driver
 */


/** @type {(keyof Driver)[]} */
export let METHODS = [
  // storage.js
  'waitForPendingWrites',
  'serverTimestamp',
  'getDocs',
  'getDoc',
  'onSnapshot',
  'addDoc',
  'updateDoc',
  'setDoc',
  'deleteDoc',

  // sync.js
  'getFirestore',
  'getAuth',
  'getIdToken',
  'getIdTokenResult',
  'collection',
  'doc',
  'where',
  'orderBy',
  'limit',
  'query',
  'docExists',

  // credentials.js
  'getFunctions',

  // logging.js
  'httpsCallable'
];


let implementation = {};

let driver = createDriver();


export const waitForPendingWrites = driver.waitForPendingWrites;
export const serverTimestamp = driver.serverTimestamp;
export const getDocs = driver.getDocs;
export const getDoc = driver.getDoc;
export const onSnapshot = driver.onSnapshot;
export const addDoc = driver.addDoc;
export const updateDoc = driver.updateDoc;
export const setDoc = driver.setDoc;
export const deleteDoc = driver.deleteDoc;
export const getFirestore = driver.getFirestore;
export const getAuth = driver.getAuth;
let idTokenPromise;
/**
 * @type {typeof driver.getIdToken}
 */
export const getIdToken = async (...args) => {
  if (idTokenPromise) {
    return idTokenPromise;
  }
  try {
    idTokenPromise = driver.getIdToken(...args);
    const result = await idTokenPromise;
    return result;
  } finally {
    idTokenPromise = null;
  }

};
export const getIdTokenResult = driver.getIdTokenResult;
export const collection = driver.collection;
export const doc = driver.doc;
export const docExists = driver.docExists;
export const where = driver.where;
export const orderBy = driver.orderBy;
export const limit = driver.limit;
export const query = driver.query;
export const getFunctions = driver.getFunctions;
export const httpsCallable = driver.httpsCallable;


/**
 * Shared implementation for "makeRef" function from "firebase_utilities"
 * 
 * @type {import('./firebase_utilities').MakeRef}
 */
export const makeRef = function() {
  let args = Array.prototype.slice.call(arguments);
  let ref = typeof args[0] === 'string' || args.length === 0
    ? getFirestore()
    : args.shift();

  while (args.length > 0) {
    let collectionName = args.shift();
    ref = collection(ref, collectionName);
    if (args.length > 0) {
      let documentId = args.shift();
      ref = doc(ref, documentId);
    }
  }

  return ref;
};



/**
 * Set shared driver implementation
 *
 * @param {Partial<Driver>[]} impls
 */
export function setFirebaseDriver(...impls) {
  impls.forEach(impl => {
    for (let name of METHODS) {
      if (impl.hasOwnProperty(name)) {
        implementation[name] = impl[name];
      }
    }
  });
}


function createDriver() {
  let driver = /** @type {Driver} */ ({});
  for (let name of METHODS) {
    driver[name] = createFunc(name);
  }
  return driver;
}

function createFunc(name) {
  return function() {
    if (!implementation[name]) {
      throw new Error(`Function ${name} of the firebase shared driver not implemented`);
    } else {
      return implementation[name].apply(this, arguments);
    }
  };
}
