import React from 'react';
// @ts-ignore
import missingPanda from './../../../images/panda/missing.jpeg';
import { EmptyState } from '../EmptyState/EmptyState';

function NotFoundBase() {
  return (
    <div style={{ textAlign: 'center' }}>
      <EmptyState
        icon="WARNING"
        title="404 Error: Could not find page"
        description={<span>Could not find: <u>{window.location.href}</u>. Please check the URL.</span>}
      />
      <img src={missingPanda} alt="Missing Panda" style={{
        height: 240,
        marginTop: 50
      }} />
    </div>
  );
}

const NotFound = React.memo(NotFoundBase);
export default NotFound;