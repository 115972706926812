import React, { useEffect, useState } from 'react';
import { getExtensionType, getProperCurrentBrowserName } from '../../extension';
import Button from '@mui/material/Button';
import { log } from '../../logging/logging';
import T from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import ExtensionIcon from '@mui/icons-material/Extension';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import { APP_PLATFORM_NAME, DESKTOP_APP_NAME, checkExtensionDefaultChoice } from '../../flags';
import { useAnyAppInstalled } from '../../desktop_hooks';
import { useTypedSelector } from '../../hooks';
import { installExtension } from '../../install_extension';
import { fullAppName } from '../../raw_flags';
import { isAiBlaze } from '../../aiBlaze.js';
import { installDesktopApp } from '../../desktop_shared_utils';

const isExtensionDefaultChoice = checkExtensionDefaultChoice();
const iconStyles = {
  opacity: 0.5,
  marginRight: 4,
  verticalAlign: 'middle'
};

function InstallExtensionBase() {
  const { hasApp, loaded } = useAnyAppInstalled();
  const [open, setOpen] = useState(false);

  const userActivatedAppOrExtension = useTypedSelector(
    (store) => store.userState?.userActivatedAppOrExtension,
  );

  // Show the dialog some time after the page has loaded,
  // so that users really notice it
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 4000);
  }, []);

  if (hasApp || !loaded || !open || userActivatedAppOrExtension) {
    // Do not show the install extension prompt
    // if we are installed on any platform
    return null;
  }

  return (
    <Slide direction="up" in>
      <Paper
        elevation={6}
        sx={{
          position: 'fixed',
          right: '10px',
          bottom: '10px',
          width: {
            xs: '300px',
            sm: '425px',
          },
          padding: '12px 24px',
          zIndex: 2
        }}
      >
        <T variant="h5" paragraph style={{ marginTop: 10 }}>{isExtensionDefaultChoice ? <ExtensionIcon fontSize="large" style={iconStyles}/> : <InstallDesktopIcon fontSize="large" style={iconStyles}/>} Add {fullAppName} to {isExtensionDefaultChoice ? getProperCurrentBrowserName() : APP_PLATFORM_NAME}</T>
        <T paragraph>To use {fullAppName}, you need to install our free {isExtensionDefaultChoice ? getExtensionType() : DESKTOP_APP_NAME}. It only takes a few seconds.</T>
        {isExtensionDefaultChoice && <T paragraph>To insert {isAiBlaze ? 'prompts' : 'snippets'}, {fullAppName} needs to be able to read and change data on the websites you visit.</T>}
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => setOpen(false)}
            style={{ marginRight: 8, marginBottom: 8 }}
          >I'll do it later</Button>&nbsp;&nbsp;
          {isExtensionDefaultChoice ? <Button
            onClick={() => {
              log({ category: 'Extension', action: 'Attempt install' });
              installExtension();
            }}
            variant="contained"
            color="primary"
            style={{
              marginBottom: 8
            }}
          >Add extension to {getProperCurrentBrowserName()}</Button> : <Button
            onClick={() => {
              log({ category: 'desktop', action: 'Attempt install desktop' });
              installDesktopApp();
            }}
            variant="contained"
            color="primary"
            style={{
              marginBottom: 8
            }}
          >Install {DESKTOP_APP_NAME}</Button>}
        </div>
      </Paper>
    </Slide>
  );
}


const InstallExtension = React.memo(InstallExtensionBase);
export default InstallExtension;
