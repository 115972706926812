import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import SnippetEditor from '../SnippetEditor/SnippetEditor';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Pageview';
import PagePreview from './PagePreview';
import IconButton from '@mui/material/IconButton';
import FormNamesIcon from '@mui/icons-material/Dns';
import Menu from '@mui/material/Menu';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Switch from '@mui/material/Switch';
import T from '@mui/material/Typography';
import { Environment } from '../../snippet_processor/DataContainer';
import { createDom, createContainer } from '../../snippet_processor/SnippetProcessor';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import SnippetWrapper from '../Snippet/SnippetWrapper';


export default function PageEditor(props) {
  let [tab, setTab] = useState('edit');
  let [names, setNames] = useState([]);
  let [content, setContent] = useState(props.content || { delta: { ops: [] }, id: '0' });
  let [formNames, setFormNames] = useState(props.formNames || {});
  let [menuAnchor, setMenuAnchor] = useState(null);

  let [installedBy, setInstalledBy] = useState('user');
  let [userRole, setUserRole] = useState('owner');

  return (
    <Dialog
      open
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          props.onClose(event, reason);
        }
      }}
      maxWidth="md"
      style={{
        zIndex: 16 /** Behind Embed Command Popper */
      }}>
      <DialogTitle>
        {props.title}
      </DialogTitle>
      <div 
        style={{
          width: 720,
          height: 560,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}>
        
        <div style={{ overflow: 'hidden', paddingBottom: 3 }}>
          <Tabs
            value={tab}
            onChange={(_e, v) => setTab(v)}
            variant="fullWidth"
            centered
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={<span><EditIcon style={{ verticalAlign: 'middle' }}/>&nbsp;&nbsp;Edit</span>}
              value="edit"
            />
            <Tab
              label={<span><PreviewIcon style={{ verticalAlign: 'middle' }}/>&nbsp;&nbsp;Preview</span>}
              value="preview"
            />
          </Tabs>
        </div>

        {tab === 'edit' &&
          <div style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            position: 'relative',
            overflow: 'hidden'
          }}>
            <ErrorBoundary style={{ paddingTop: 30 }}>
              <SnippetWrapper
                style={{
                  display: 'block'
                }}
              >
                <SnippetEditor
                  handleNames={(names) => setNames(names || [])}
                  isPage
                  snippetId="_settings"
                  groupId={props.groupId}
                  editable
                  owner
                  preview
                  value={content}
                  // quickentry is disable so _type will only ever be 'delta'
                  onChange={(_type, d, i) => setContent({ delta: d, id: i })} 
                  limitations={{
                    MAX_SNIPPET_SIZE: 2000
                  }}
                />
              </SnippetWrapper>
            </ErrorBoundary>
          </div>
        }

        {
          tab === 'preview' &&
          <>
            <div className="page" style={{ padding: 0, flex: 1, overflow: 'auto' }}>
              <PagePreview
                delta={content.delta}
                configOverride={{
                  snippet: {
                    source: installedBy
                  },
                  user: {
                    email: 'user@example.com',
                    role: userRole
                  }
                }}
              />
            </div>
            <div style={{ height: 64, padding: 14, borderTop: '1px solid rgb(221, 221, 221)' }}>
              <T color="textSecondary" style={{ display: 'inline-block', marginRight: 16 }}>Preview</T>
              <T variant="body2" style={{ display: 'inline-block' }}>Installed by</T> <Select
                onChange={e => setInstalledBy(/** @type {string} */ (e.target.value))}
                value={installedBy}
                native
                style={{
                  marginLeft: 6,
                  marginRight: 16
                }}
                variant="standard"
              >
                <option value="user">User</option>
                <option value="organization">Organization</option>
              </Select>
              {installedBy === 'organization' && <>
                <T variant="body2" style={{ display: 'inline-block' }}>User role is</T> <Select
                  onChange={e => setUserRole(/** @type {string} */ (e.target.value))}
                  value={userRole}
                  native
                  style={{
                    marginLeft: 6
                  }}
                  variant="standard"
                >
                  <option value="member">Member</option>
                  <option value="editor">Editor</option>
                  <option value="owner">Owner</option>
                </Select>
              </>}
              
            </div>
          </>
        }
      </div>
      <div style={{ position: 'absolute', right: 10, bottom: 7 }}>
        <Tooltip
          title="Form data which is persisted and passed to components"
          placement="top"
        >
          <span>
            <IconButton
              onClick={(event) => setMenuAnchor(event.currentTarget)}
              disabled={names.length === 0}
              data-testid="form-names-type-editor"
              size="large">
              <FormNamesIcon />
            </IconButton>
          </span>
        </Tooltip>

        {menuAnchor &&
        <Menu
          anchorEl={menuAnchor}
          open
          onClose={() => setMenuAnchor(null)}
        >
          <List dense subheader={<ListSubheader>Persisted form data</ListSubheader>}>
            {names.map(name => {
              name = name.toLocaleLowerCase();
              return <ListItem key={name}>
                <ListItemIcon>
                  <Switch
                    size="small"
                    color="primary"
                    onChange={(_e) => {
                      let newNames = Object.assign({}, formNames);
                      if (formNames[name]) {
                        delete newNames[name];
                      } else {
                        newNames[name] = { type: 'standard' };
                      }
                      setFormNames(newNames);
                    }}
                    checked={!!formNames[name]}
                  />
                </ListItemIcon>
                <ListItemText primary={name} style={{ paddingRight: 60 }}/>
                {formNames[name] && <ListItemSecondaryAction>
                  <select
                    style={{
                      width: 80 
                    }}
                    value={formNames[name].type}
                    onChange={e => setFormNames(Object.assign({}, formNames, { [name]: { type: e.target.value } }))}
                  >
                    <option value="standard">Standard – Each user has their own value</option>
                    <option value="shared">Shared – Single value per organization</option>
                  </select>
                </ListItemSecondaryAction>}
              </ListItem>;
            })}
          </List>
        </Menu>
        }

        <Button
          onClick={() => {
            props.onClose();
          }}
          style={{ marginRight: 10 }}
        >Cancel</Button>
        <Button
          onClick={async () => props.onDone({
            content: content,
            formNames: await updateNames(formNames, content.delta, names)
          })}
          style={{ marginRight: 10 }}
          color="primary"
          variant="contained"
          data-testid="page-save-button"
        >Save</Button>
      </div>
    </Dialog>
  );
}


/**
 * Removes properties from obj that aren't in reference. And
 * adds default values.
 * 
 * @param {object} obj
 * @param {DeltaType} delta
 * @param {string[]} reference
 * 
 * @return {Promise<object>}
 */
async function updateNames(obj, delta, reference) {
  let env = new Environment(Object.create(null), { stage: 'preview' });
  let dom = await createDom(JSON.parse(JSON.stringify(delta)), env);
  let { container } = await createContainer([dom], env);
  let defaults = container.data;
  
  obj = Object.assign({}, obj);
  for (let key in obj) {
    if (!reference.includes(key)) {
      delete obj[key];
    } else {
      obj[key].default = defaults[key] === undefined ? '' : defaults[key];
    }
  }
  return obj;
}