import React from 'react';
import Callout from '../Callout/Callout';
import AsyncButton from '../AsyncButton/AsyncButton';
import WarningIcon from '@mui/icons-material/WarningRounded';
import T from '@mui/material/Typography';
import { useTypedSelector } from '../../hooks';
import Alert from '@mui/material/Alert';
import { resendEmailVerification } from './verificationreminder_utilites';


/**
 * @param {object} props
 * @param {string=} props.reason
 */
function VerificationReminderBase(props) {
  let email = useTypedSelector(store => store.userState.email);
  
  return <Callout
    icon={<WarningIcon />}
    warning
    style={{ marginBottom: 10 }}
    title="Verify your email address"
  >
    <T paragraph>You have not yet verified your email address <b>{email}</b>.</T>
      
    <T paragraph>Until you verify your email, {props.reason || 'you will not be able to either share folders or access folders that were shared with your email'}.</T>

    <T paragraph>After email verification, you may need to wait a few minutes for the verification to be processed and to take effect.</T>

    <div style={{ textAlign: 'right', marginTop: 8 }}>
      <AsyncButton
        variant="contained"
        color="primary"
        onClick={(done) => resendEmailVerification(email, done)}
      >Resend verification email</AsyncButton>
    </div>
  </Callout>;
}

/**
 * @param {object} props
 * @param {string=} props.productName
 */
export function LightweightVerificationReminder(props) {
  const { email, emailVerified } = useTypedSelector(store => ({
    email: store.userState.email,
    emailVerified: store.userState.emailVerified,
  }));

  if (emailVerified) {
    return null;
  }

  return <Alert
    severity="warning"
    onClose={() => {}}
    action={<AsyncButton
      onClick={(done) => resendEmailVerification(email, done)}
      color="primary"
      size="small"
      variant="outlined"
    >
      Resend verification email
    </AsyncButton>}
    sx={{
      mt: 4,
    }}
  >
    Please verify your email address <b>{email}</b> to access all the features of {props.productName} like sharing and commenting.
  </Alert>;
}

const VerificationReminder = React.memo(VerificationReminderBase);
export default VerificationReminder;