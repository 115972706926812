import {
  Button,
  IconButton,
  Paper,
  Typography as T,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import React, { useMemo, useState } from 'react';
import { useTypedSelector } from '../../hooks';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import { arrayUnion } from 'firebase/firestore';
import { randomize } from '../../experiment/experiment';

const REFERRAL_FOR_CREDIT = '%APP_referral_for_credit';

const ReferralPrompt = () => {
  const [closeReferDialog, setCloseReferDialog] = useState(false);

  const showCreditsPrompt = useMemo(() => randomize('Show Credits Prompt 2', {
    type: 'WeightedChoice',
    choices: ['show', 'none'],
    weights: [0.1, 0.9]
  }) === 'show', []);
  let dismissedCreditReferral = useTypedSelector((store) =>
    store.userState.dismissed_notifications && store.userState.dismissed_notifications.includes(REFERRAL_FOR_CREDIT),
  );

  if (
    closeReferDialog
    || !showCreditsPrompt
    || dismissedCreditReferral
  ) {
    return null;
  }
  
  return (
    <Paper variant="outlined" sx={{
      padding: '16px 16px',
      position: 'relative',
      overflow: 'auto'
    }} data-testid="get-free-credits">
      <IconButton
        sx={{
          position: 'absolute',
          top: 5,
          right: 6,
          opacity: .7
        }}
        size="small"
        title="Dismiss"
        onClick={() => {
          setCloseReferDialog(true);
          storage.update(usersSettingsRef, {
            dismissed_notifications: arrayUnion(REFERRAL_FOR_CREDIT)
          }, 'HIDE_AUTOSAVE');
        }}
      > <CloseIcon fontSize="small" /> </IconButton>
      <T variant="h6">Get free credits</T>
      <div style={{ textAlign: 'center', marginTop: 12, marginBottom: 16 }}>
        <Button
          size="large"
          startIcon={<CardGiftcardIcon fontSize="large" />}
          color="success"
          variant="outlined"
          href="#refer"
        >Invite your friends</Button>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Tooltip title="You will get the credits once the referee sign up and verify the email address.">
          <T variant="body1"> Earn $10 in credit for <b>every</b> successful invite</T>
        </Tooltip>
      </div>
    </Paper>
  );
};

export default ReferralPrompt;