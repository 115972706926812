/*
 * This file is vendored from Blueprint.js
 * 
 * ~~~~~~~~~~~~
 *
 * Copyright 2016 Palantir Technologies, Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PureComponent } from 'react';

export const ENTER = 13;
export const ESCAPE = 27;

export const EDITABLE_TEXT = 'lpt3-editable-text';
export const EDITABLE_TEXT_CONTENT = `${EDITABLE_TEXT}-content`;
export const EDITABLE_TEXT_EDITING = `${EDITABLE_TEXT}-editing`;
export const EDITABLE_TEXT_INPUT = `${EDITABLE_TEXT}-input`;
export const EDITABLE_TEXT_PLACEHOLDER = `${EDITABLE_TEXT}-placeholder`;
export const DISABLED = 'lpt3-disabled';
export const MULTILINE = 'lpt3-multiline';

const BUFFER_WIDTH_DEFAULT = 5;


function isFunction(value) {
  return typeof value === 'function';
}

/**
 * Clamps the given number between min and max values. Returns value if within
 * range, or closest bound.
 */
export function clamp(val, min, max) {
  if (val == null) {
    return val;
  }
  if (max < min) {
    throw new Error('Clamp violated.');
  }
  return Math.min(Math.max(val, min), max);
}

function safeInvoke(func, ...args) {
  if (isFunction(func)) {
    return func(...args);
  }
  return undefined;
}

export default class EditableText extends PureComponent {
  static defaultProps = {
    confirmOnEnterKey: false,
    defaultValue: '',
    disabled: false,
    maxLines: Infinity,
    minLines: 1,
    minWidth: 80,
    multiline: false,
    placeholder: 'Click to Edit',
    type: 'text',
  };

  refHandlers = {
    content: (spanElement) => {
      this.valueElement = spanElement;
    },
    input: (input) => {
      if (input != null) {
        input.focus();
        const { length } = input.value;
        input.setSelectionRange(this.props.selectAllOnFocus ? 0 : length, length);
        if (!this.props.selectAllOnFocus) {
          input.scrollLeft = input.scrollWidth;
        }
      }
    },
  };

  timeoutIds = [];

  constructor(props, context) {
    super(props, context);

    const value = props.value == null ? props.defaultValue : props.value;
    this.state = {
      inputHeight: 0,
      inputWidth: 0,
      isEditing: props.isEditing === true && props.disabled === false,
      lastValue: value,
      value,
    };
  }


  /**
   * Set a timeout and remember its ID.
   * All stored timeouts will be cleared when component unmounts.
   * @returns a "cancel" function that will clear timeout when invoked.
   */
  setTimeout(callback, timeout) {
    const handle = window.setTimeout(callback, timeout);
    this.timeoutIds.push(handle);
    return () => window.clearTimeout(handle);
  }

  componentWillUnmount() {
    this.clearTimeouts();
    if (this.state.isEditing) {
      safeInvoke(this.props.onConfirm, this.state.value);
    }
  }

  clearTimeouts = () => {
    if (this.timeoutIds.length > 0) {
      for (const timeoutId of this.timeoutIds) {
        window.clearTimeout(timeoutId);
      }
      this.timeoutIds = [];
    }
  };

  render() {
    let { disabled, multiline } = this.props;
    const value = this.props.value == null ? this.state.value : this.props.value;
    const hasValue = value != null && value !== '';

    let classes = EDITABLE_TEXT;
    if (disabled) {
      classes += ' ' + DISABLED;
    }
    if (this.state.isEditing) {
      classes += ' ' + EDITABLE_TEXT_EDITING;
    }
    if (!hasValue) {
      classes += ' ' + EDITABLE_TEXT_PLACEHOLDER;
    }
    if (multiline) {
      classes += ' ' + MULTILINE;
    }
    if (this.props.className) {
      classes += ' ' + this.props.className;
    }
    let contentStyle;
    if (multiline) {
      // set height only in multiline mode when not editing
      // otherwise we're measuring this element to determine appropriate height of text
      contentStyle = { height: !this.state.isEditing ? this.state.inputHeight : null };
    } else {
      // minWidth only applies in single line mode (multiline == width 100%)
      contentStyle = {
        height: this.state.inputHeight,
        lineHeight: this.state.inputHeight != null ? `${this.state.inputHeight}px` : null,
        minWidth: this.props.minWidth,
      };
    }

    // make enclosing div focusable when not editing, so it can still be tabbed to focus
    // (when editing, input itself is focusable so div doesn't need to be)
    const tabIndex = this.state.isEditing || disabled ? null : 0;
    return (
      <div className={classes} style={this.props.style} onFocus={this.handleFocus} tabIndex={tabIndex}>
        {this.maybeRenderInput(value)}
        <span className={EDITABLE_TEXT_CONTENT} ref={this.refHandlers.content} style={contentStyle}>
          {hasValue ? value : this.props.placeholder}
        </span>
      </div>
    );
  }

  componentDidMount() {
    this.updateInputDimensions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEditing && !prevState.isEditing) {
      safeInvoke(this.props.onEdit, this.state.value);
    }

    // updateInputDimensions is an expensive method. Call it only when the props
    // it depends on change
    if (
      this.state.value !== prevState.value ||
      this.props.alwaysRenderInput !== prevProps.alwaysRenderInput ||
      this.props.maxLines !== prevProps.maxLines ||
      this.props.minLines !== prevProps.minLines ||
      this.props.minWidth !== prevProps.minWidth ||
      this.props.multiline !== prevProps.multiline
    ) {
      this.updateInputDimensions();
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const newState = {};
    if (nextProps.value != null) {
      newState.value = nextProps.value;
    }
    if (nextProps.isEditing != null) {
      newState.isEditing = nextProps.isEditing;
    }
    if (nextProps.disabled) { // in porting had to also disable this branch: || (nextProps.disabled == null && this.props.disabled)
      newState.isEditing = false;
    }
    return newState;
  }

  cancelEditing = () => {
    const { lastValue, value } = this.state;
    this.setState({ isEditing: false, value: lastValue });
    if (value !== lastValue) {
      safeInvoke(this.props.onChange, lastValue);
    }
    safeInvoke(this.props.onCancel, lastValue);
  };

  toggleEditing = () => {
    if (this.state.isEditing) {
      const { value } = this.state;
      this.setState({ isEditing: false, lastValue: value });
      safeInvoke(this.props.onConfirm, value);
    } else if (!this.props.disabled) {
      this.setState({ isEditing: true });
    }
  };

  handleFocus = () => {
    if (!this.props.disabled) {
      this.setState({ isEditing: true });
    }
  };

  handleTextChange = (event) => {
    const value = event.target.value;
    // state value should be updated only when uncontrolled
    if (this.props.value == null) {
      this.setState({ value });
    }
    safeInvoke(this.props.onChange, value);
  };

  handleKeyEvent = (event) => {
    const { altKey, ctrlKey, metaKey, shiftKey, which } = event;
    if (which === ESCAPE) {
      this.cancelEditing();
      return;
    }

    const hasModifierKey = altKey || ctrlKey || metaKey || shiftKey;
    if (which === ENTER) {
      // prevent IE11 from full screening with alt + enter
      // shift + enter adds a newline by default
      if (altKey || shiftKey) {
        event.preventDefault();
      }

      if (this.props.confirmOnEnterKey && this.props.multiline) {
        if (event.target != null && hasModifierKey) {
          insertAtCaret(event.target, '\n');
          this.handleTextChange(event);
        } else {
          this.toggleEditing();
        }
      } else if (!this.props.multiline || hasModifierKey) {
        this.toggleEditing();
      }
    }
  };

  maybeRenderInput(value) {
    const { maxLength, multiline, type, placeholder } = this.props;
    if (!this.state.isEditing) {
      return undefined;
    }
    const props = {
      className: EDITABLE_TEXT_INPUT,
      maxLength,
      onBlur: this.toggleEditing,
      onChange: this.handleTextChange,
      onKeyDown: this.handleKeyEvent,
      placeholder,
      style: {
        height: this.state.inputHeight,
        lineHeight: !multiline && this.state.inputHeight != null ? `${this.state.inputHeight}px` : null,
        width: multiline ? '100%' : this.state.inputWidth,
      },
      value,
    };
    return multiline ? (
      <textarea ref={this.refHandlers.input} {...props} />
    ) : (
      <input ref={this.refHandlers.input} type={type} {...props} />
    );
  }

  updateInputDimensions() {
    if (this.valueElement != null) {
      const { maxLines, minLines, minWidth, multiline } = this.props;
      const { parentElement, textContent } = this.valueElement;
      let { scrollHeight, scrollWidth } = this.valueElement;
      const lineHeight = getLineHeight(this.valueElement);
      // add one line to computed <span> height if text ends in newline
      // because <span> collapses that trailing whitespace but <textarea> shows it
      if (multiline && this.state.isEditing && /\n$/.test(textContent)) {
        scrollHeight += lineHeight;
      }
      if (lineHeight > 0) {
        // line height could be 0 if the isNaN block from getLineHeight kicks in
        scrollHeight = clamp(scrollHeight, minLines * lineHeight, maxLines * lineHeight);
      }
      // Chrome's input caret height misaligns text so the line-height must be larger than font-size.
      // The computed scrollHeight must also account for a larger inherited line-height from the parent.
      scrollHeight = Math.max(scrollHeight, getFontSize(this.valueElement) + 1, getLineHeight(parentElement));
      // Need to add a small buffer so text does not shift prior to resizing, causing an infinite loop.
      scrollWidth += BUFFER_WIDTH_DEFAULT;
          
      this.setState({
        inputHeight: scrollHeight,
        inputWidth: Math.max(scrollWidth, minWidth),
      });
      // synchronizes the ::before pseudo-element's height while editing for Chrome 53
      if (multiline && this.state.isEditing) {
        this.setTimeout(() => (parentElement.style.height = `${scrollHeight}px`));
      }
    }
  }
}

function getFontSize(element) {
  const fontSize = getComputedStyle(element).fontSize;
  return fontSize === '' ? 0 : parseInt(fontSize.slice(0, -2), 10);
}

function getLineHeight(element) {
  // getComputedStyle() => 18.0001px => 18
  let lineHeight = parseInt(getComputedStyle(element).lineHeight.slice(0, -2), 10);
  // this check will be true if line-height is a keyword like "normal"
  if (isNaN(lineHeight)) {
    // @see http://stackoverflow.com/a/18430767/6342931
    const line = document.createElement('span');
    line.innerHTML = '<br>';
    element.appendChild(line);
    const singleLineHeight = element.offsetHeight;
    line.innerHTML = '<br><br>';
    const doubleLineHeight = element.offsetHeight;
    element.removeChild(line);
    // this can return 0 in edge cases
    lineHeight = doubleLineHeight - singleLineHeight;
  }
  return lineHeight;
}

function insertAtCaret(el, text) {
  const { selectionEnd, selectionStart, value } = el;
  if (selectionStart >= 0) {
    const before = value.substring(0, selectionStart);
    const after = value.substring(selectionEnd, value.length);
    const len = text.length;
    el.value = `${before}${text}${after}`;
    el.selectionStart = selectionStart + len;
    el.selectionEnd = selectionStart + len;
  }
}