import { randomize } from '../../experiment/experiment';
import { APP_TARGET } from '../../raw_flags';

/**
 * @typedef WidgetConfigArgument
 * @property {import('@store').ConfigDef} config 
 * @property {import('@store').UserStateDef} userState 
 * 
 * @param {WidgetConfigArgument} arg0
 * @returns 
 */
export function isPartOfWidgetExperiment({ config, userState }) {
  if (APP_TARGET !== 'TEXT') {
    return false;
  }
  const widgetEnablePercent = config?.experiments?.WIDGET_PERCENT;

  if (!widgetEnablePercent) {
    return false;
  }

  const configStartDate = config?.experiments?.WIDGET_START_DATE_NEW;
  const userCreationTime = userState?.firebaseMetadata?.creationTime;
  // Disable for new users
  if (userCreationTime) {
    const userCreatedAt = new Date(userCreationTime).getTime();
    if (userCreatedAt >= new Date('2023-07-15').getTime()) {
      return false;
    }
  }
  if (configStartDate && userCreationTime) {
    const configStartTimestamp = configStartDate.seconds * 1000;
    const userCreatedAt = new Date(userCreationTime).getTime();

    if (userCreatedAt < configStartTimestamp) {
      return false;
    }
  }
  const randomResult = randomize('Extension Widget', {
    type: 'WeightedChoice',
    choices: ['show', 'none'],
    weights: [widgetEnablePercent, 1 - widgetEnablePercent]
  });

  return randomResult === 'show';
}

/**
 * Returns default value for "widget" option.
 * @param {import('@store').RootState} _state
 *
 * @returns {boolean}
 */
export function getWidgetDefault(_state) {
  return isPartOfWidgetExperiment(_state);
}
