import React from 'react';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import SnippetPreview from '../SnippetPreview/SnippetPreview';
import { makePageConfig } from './page_utilities';


/**
 * @param {object} props 
 * @param {DeltaType} props.delta
 * @param {import("../../snippet_processor/DataContainer").Config} props.configOverride
 */
export default function PagePreview(props) {
  return <ErrorBoundary style={{ paddingTop: 30 }}>
    <SnippetPreview
      delta={props.delta}
      hideProMessage
      quickentry={false}
      config={makePageConfig(props.configOverride || {})}
    />
  </ErrorBoundary>;
}