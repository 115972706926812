import React from 'react';
import { updateCardDetails, restartOrgBilling } from './billing_utils';
import BillingIssueBase from './BillingIssue';
import { pendingPayment } from '../../bapi';

/**
 * @param {Omit<Parameters<BillingIssueBase>['0'], 'action'>} props
 */
function BillingIssueWithActionBase(props) {
  return <BillingIssueBase
    {...props}
    action={async (type, issue, email) => {
      if (type.type === 'org' && issue.type === 'unpaid') {
        // If the invoice is unpaid the subscription has been cancelled
        // and we need to restart it
        await restartOrgBilling(type, email);
      } else if (['trialling_ends', 'no_card'].includes(issue.type)) {
        await updateCardDetails(type, email);
      } else {
        await updateCardDetails(type, email, null, pendingPayment);
      }
    }}
  />;
}


const BillingIssueWithAction = React.memo(BillingIssueWithActionBase);
export default BillingIssueWithAction;