/**
 * This file contains flags that work without any 
 * imports, and prevent circular imports
 */

/** @type {() => void} */
export let clarityLoadedResolver = null;
/** @type {Promise<void>} */
const hasClarityLoaded = new Promise(resolve => {
  clarityLoadedResolver = resolve;
});

/** @param {() => void} fn */
export function onClarityLoaded(fn) {
  hasClarityLoaded.then(() => {
    if (window['clarity']) {
      fn();
    }
  });
}

/**
 * @return {boolean} true if we are running on a dev server
 */
export function isDev() {
  if (typeof window === 'undefined' || 'vi' in window) {
    return false;
  }
  // @ts-ignore
  return !['', '80', '443'].includes(window.location.port);
}

/**
 * @returns {boolean} true if running on electron app
 */
export function isElectronApp() {
  if (typeof window === 'undefined') {
    return false;
  }
  if (window['electronAPI']) {
    return true;
  }
  return false;
}

export function getIsWebpageEmbedded() {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.location.pathname.startsWith('/sidebar/') || window.location.pathname.startsWith('/embed/') || (isDev() && window.location.hash === '#force-chat');
}

/**
 * @return {boolean} true if we are running on macintosh
 */
export function isMacPlatform() {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.navigator.userAgent.toLowerCase().includes('macintosh');
}

/**
 * @return {boolean} true if we are running on Windows
 */
export function isWinPlatform() {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.navigator.platform.toLowerCase().startsWith('win');
}

/**
 * @return {boolean} true if we are running on android
 */
export function isAndroid() {
  if (typeof window === 'undefined') {
    return false;
  }
  // AndroidInterface - Form interface,
  // ReactNativeWebView - ReactNative interface
  // AndroidAssistantInterface - Assistant Interface
  if (window['ReactNativeWebView'] || window['AndroidInterface'] || window['AndroidAssistantInterface']) {
    return true;
  } else {
    return false;
  }
}

export const MY_WEB_VERSION = 25;

export const APP_TARGET = /** @type {AppType} */ (import.meta.env.VITE_APP_APP_TYPE) || 'TEXT';

export const fullAppName = import.meta.env.VITE_APP_APP_NAME || 'Text Blaze';

export const AI_SIDEBAR_CHAT_SNIPPET_ID = '_AI_SNIPPET_CHAT_SNIPPET';

export const isElectronFlag = isElectronApp();