import { uid as myUid, firebaseEmail, firebaseUser, store } from '@store';
import { httpsCallable } from 'firebase/functions';
import { getTBFunctions } from '../../firebase_utilities';
import { sync } from '../../Sync/syncer';
import fetchSitesSummary from '../Database/fetchSitesSummary';


/**
 * @typedef {object} UserInfo
 * @property {string} email
 * @property {string} name
 * @property {string} imageUrl
 * @property {boolean} emailVerified
 * @property {string} uid
 */

/**
 * @type {Object<string, Promise<UserInfo>>}
 */
const userInfoCache = {};

const emailToUserInfoCache = {};


/**
 * @type {import('firebase/functions').HttpsCallable<any, any>}
 */
let getUserPublic = httpsCallable(getTBFunctions(), 'getUserPublic');


/**
 * Utility function to get email, image, and name for a uid.
 *
 * @param {string[]} uids
 *
 * @return {Promise<UserInfo[]>}
 */
export async function uidsToUserInfo(uids) {
  let mid = myUid();

  /** @type {string[]} */
  let requestUids = [];
  let requestResponses = [];
  /** @type {string[]} */
  let neededUids = [];

  for (let uid of uids) {
    if (uid in userInfoCache) {
      requestUids.push(uid);
      requestResponses.push(userInfoCache[uid]);
      continue;
    }

    if (uid === mid) {
      requestUids.push(uid);
      let user = firebaseUser();
      requestResponses.push({
        email: user.email,
        name: user.displayName,
        imageUrl: user.photoURL,
        emailVerified: user.emailVerified,
        uid
      });
      continue;
    }
    neededUids.push(uid);
  }

  if (neededUids.length) {
    let promise = (async () => {
      let userInfos = (await getUserPublic({ uid: neededUids })).data;

      let res = {};
      userInfos.forEach((userInfo, i) => {
        if (!userInfo.email) {
          res[neededUids[i]] = {
            email: '',
            name: null,
            imageUrl: null,
            emailVerified: false,
            uid: neededUids[i]
          };
          return;
        }

        let info = {
          email: userInfo.email,
          name: userInfo.name,
          imageUrl: userInfo.imageUrl,
          emailVerified: userInfo.emailVerified,
          uid: neededUids[i]
        };

        res[neededUids[i]] = info;

        emailToUserInfoCache[info.email] = info;
      });

      return res;
    })();

    for (let uid of neededUids) {
      userInfoCache[uid] = promise.then(data => data[uid]);
      requestUids.push(uid);
      requestResponses.push(userInfoCache[uid]);
    }
  }


  let res = [];
  for (let uid of uids) {
    res.push(requestResponses[requestUids.findIndex(u => u === uid)]);
  }

  return Promise.all(res);
}


/**
 * Utility function to get email, image, and name for a uid.
 *
 * @param {string} targetUid
 *
 * @return {Promise<UserInfo>}
 */
export async function uidToUserInfo(targetUid) {
  return (await uidsToUserInfo([targetUid]))[0];
}


/**
  * @param {string} email
  *
  * @return {UserInfo}
  */
export function emailToUserInfo(email) {
  let user = firebaseUser();
  if (user.email === email) {
    return {
      email: user.email,
      name: user.displayName,
      imageUrl: user.photoURL,
      emailVerified: user.emailVerified,
      uid: user.uid
    };
  }
  return emailToUserInfoCache[email];
}


/**
 * Small utility function to get an email from a uid.
 *
 * @param {string} uid
 *
 * @return {Promise<string>}
 */
export async function uidToEmail(uid) {
  let data = await listPermissions({
    ['u:' + uid]: { type: null }
  });
  return data[0].entityValue;
}

/**
 * @typedef {object} Permission
 * @property {string} original
 * @property {string} type
 * @property {'user'|'org'|'team'|'public'|'group'|'site'} entityType
 * @property {string} entityValue
 * @property {string} entityLabel
 */


/**
 * Gets emails for a permissions list.
 *
 * @param {PermissionsType} permissionsObj
 *
 * @return {Promise<Permission[]>}
 */
export async function listPermissions(permissionsObj) {
  /** @type {Permission[]} */
  let permissions = [];
  let promises = [];
  return new Promise((resolve) => {
    for (let user in permissionsObj) {
      if (user === 'u:' + myUid()) {
        const currentEmail = firebaseEmail();
        permissions.push({
          original: user,
          type: permissionsObj[user].type,
          entityType: 'user',
          entityValue: currentEmail,
          entityLabel: currentEmail
        });
      } else if (user.startsWith('e:')) {
        const email = user.slice(2);
        permissions.push({
          original: user,
          type: permissionsObj[user].type,
          entityType: 'user',
          entityValue: email,
          entityLabel: email
        });
      } else if (user.startsWith('o:')) {
        let orgId = user.slice(2);
        const orgName = (sync.org && sync.org.id === orgId && sync.org.data.name) || '[Private]';
        permissions.push({
          original: user,
          type: permissionsObj[user].type,
          entityType: 'org',
          entityValue: orgId,
          entityLabel: orgName
        });
      } else if (user.startsWith('g:')) {
        let groupId = user.slice(2);
        const groupName = sync.groups[groupId]?.data?.name || '[Private]';
        permissions.push({
          original: user,
          type: 'Folder snippets',
          entityType: 'group',
          entityValue: groupId,
          entityLabel: groupName
        });
      } else if (user.startsWith('s:')) {
        let siteId = user.slice(2);
        const siteName = store.getState().dbState.siteNamesMap[siteId];
        if (siteName) {
          permissions.push({
            original: user,
            type: 'Site',
            entityType: 'site',
            entityValue: siteId,
            entityLabel: siteName,
          });
        } else {
          // eslint-disable-next-line
          promises.push(fetchSitesSummary().then(() => {
            let siteName = store.getState().dbState.siteNamesMap[siteId];
            if (!siteName) {
              siteName = '[Private]';
              store.dispatch({
                type: 'SET_SITE_NAMES',
                siteNamesMap: { [siteId]: siteName },
              });
            }
            permissions.push({
              original: user,
              type: 'Site',
              entityType: 'site',
              entityValue: siteId,
              entityLabel: siteName,
            });
          }));
        }

      } else if (user.startsWith('t:')) {
        let [, teamId] = user.slice(2).split('///');
        const teamName = (sync.allTeams && sync.allTeams.teams[teamId] && sync.allTeams.teams[teamId].data.name) || '[Private]';
        permissions.push({
          original: user,
          type: permissionsObj[user].type,
          entityType: 'team',
          entityValue: teamId,
          entityLabel: teamName
        });
      } else if (user === '_:all') {
        permissions.push({
          original: user,
          type: permissionsObj[user].type,
          entityType: 'public',
          entityValue: user,
          entityLabel: user
        });
      } else {
        let myUser = user;
        // eslint-disable-next-line
        promises.push(uidToUserInfo(myUser.slice(2)).then(data => {
          const email = data.email || '[Unknown Email]';
          permissions.push({
            original: myUser,
            type: permissionsObj[myUser].type,
            entityType: 'user',
            entityValue: email,
            entityLabel: email
          });
        }));
      }
    }
    Promise.all(promises).then(() => {
      permissions.sort((a, b) => {
        if (a.entityType === 'org' && b.entityType !== 'org') {
          return -1;
        }
        if (b.entityType === 'org' && a.entityType !== 'org') {
          return 1;
        }
        if (a.entityType === 'team' && b.entityType !== 'team') {
          return -1;
        }
        if (b.entityType === 'team' && a.entityType !== 'team') {
          return 1;
        }
        if (a.entityType === 'group' && b.entityType !== 'group') {
          return -1;
        }
        if (b.entityType === 'group' && a.entityType !== 'group') {
          return 1;
        }
        if (a.entityType === 'site' && b.entityType !== 'site') {
          return -1;
        }
        if (b.entityType === 'site' && a.entityType !== 'site') {
          return 1;
        }

        if (a.type === b.type) {
          if (a.original === 'u:' + myUid()) {
            return -1;
          }
          if (b.original === 'u:' + myUid()) {
            return 1;
          }
          return a.entityLabel < b.entityLabel ? -1 : 1;
        }
        if (a.type === 'owner') {
          return -1;
        }
        if (b.type === 'owner') {
          return 1;
        }
        if (a.type === 'editor') {
          return -1;
        }
        if (b.type === 'editor') {
          return 1;
        }
        if (a.type === 'viewer') {
          return -1;
        }
        if (b.type === 'viewer') {
          return 1;
        }
        if (a.type === 'member') {
          return -1;
        }
        if (b.type === 'member') {
          return 1;
        }
        return 0;
      });

      let existingElements = new Set();
      
      permissions = permissions.filter((x) => {
        if (x.entityType === 'user') {
          if (existingElements.has('user:' + x.entityValue)) {
            return false;
          }
          existingElements.add('user:' + x.entityValue);
        }
        if (existingElements.has('original:' + x.original)) {
          return false;
        }
        existingElements.add('original:' + x.original);

        return true;
      });

      resolve(permissions);
    });
  });
}