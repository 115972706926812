import React, { useEffect, useMemo, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';


const MemoizedChip = React.memo(Chip);


/**
 * @param {object} props
 * @param {React.ReactElement=} props.startIcon
 * @param {any=} props.tagProps
 * @param {Function=} props.onAdd  
 * @param {Function=} props.onRemove  
 * @param {Function=} props.onInputChange  
 * @param {string=} props.label
 * @param {string} props.inputValue  
 * @param {any[]} props.values
 * @param {any[]} props.options
 * @param {(option: any) => string=} props.getOptionLabel
 * @param {(option: any) => string=} props.groupBy
 * @param {string=} props.placeholder  
 * @param {('medium'|'small')=} props.size
 * @param {function=} props.convertRawText
 * @param {import('@mui/system').SxProps<import('@mui/material').Theme>=} props.sx
 * @param {object=} props.ListboxProps
 * @param {boolean=} props.autoFocus
 */
function TagInputRawBase(props) {

  const onRemoveRef = useRef(props.onRemove);

  useEffect(() => {
    onRemoveRef.current = props.onRemove;
  }, [props.onRemove]);

  // Rendering performance issue when you have more than 100 chips.
  const chipDeleteHandlers = useMemo(() => {
    let values = props.values || [];
    let handlers = {};
    for (let value of values) {
      handlers[value] = () => {
        let index = values.indexOf(value);
        if (index >= 0) {
          /*
            We cannot use onRemove from props as hook's dependency.
            Since it probably changes every time inputValue changes. 
            We only need to update this hook when props.values change.
            For this reason, we pass it through ref.
          */
          onRemoveRef.current?.(value, index);
        }
      };
    }
    return handlers;
  }, [props.values]);

  /**
   * 
   * @param {any[]} values 
   * @returns 
   */
  const onRemoveWrapper = (values) => {
    if (!props.onRemove) {
      return;
    }
    for (let i = 0; i < props.values.length; i++) {
      let v = props.values[i];
      if (!values.includes(v)) {
        props.onRemove(v, i);
        return;
      }
    }
  };


  /**
   * 
   * @param {any[]} values 
   * @returns 
   */
  const onAddWrapper = (values) => {
    if (!props.onAdd) {
      return;
    }
    let newValues = [];
    for (let v of values) {
      if (props.values.includes(v)) {
        continue;
      }
      if (typeof v !== 'string') {
        newValues.push(v);
        continue;
      }
      let items = props.convertRawText(v);
      items = [...new Set(items)];
      newValues = newValues.concat(items);
    }
    props.onAdd(newValues);
    //props.onAdd(values);
  };


  return <Autocomplete
    sx={{
      '& > li': {
        borderTop: '1px solid',
        borderColor: 'grey.200'
      },
      ...props.sx
    }}
    multiple
    fullWidth
    size={props.size || 'small'}
    value={props.values}
    inputValue={props.inputValue}
    options={(props.options || []).filter(x => !props.values.includes(x))}
    getOptionLabel={props.getOptionLabel}
    groupBy={props.groupBy}
    defaultValue={[]}
    disableClearable
    ListboxProps={props.ListboxProps}
    freeSolo
    
    onChange={(_e, values, reason) => {
      if (reason === 'createOption' || reason === 'selectOption') {
        onAddWrapper(values);
      } else if (reason === 'removeOption') {
        onRemoveWrapper(values);
      }
    }}
    renderTags={(value, getTagProps) =>{
      let items = value.map((option, index) => (
        <MemoizedChip key={index} variant="outlined" size="small" label={props.getOptionLabel ? props.getOptionLabel(option) : option}
          {...getTagProps({ index })}
          onDelete={chipDeleteHandlers[option]}
          {...(props.tagProps ? props.tagProps(option) : {})}
        />
      ));
      if (props.startIcon) {
        items.unshift(<InputAdornment key="adorn" position="start" style={{
          opacity: 0.6
        }}>
          {props.startIcon}
        </InputAdornment>);
      }

      return items;
    }}
    renderInput={(params) => {
      let empty = !props.values || !props.values.length;
      if (empty && props.startIcon) {
        params.InputProps.startAdornment = <InputAdornment position="start" style={{
          opacity: 0.6
        }}>
          {props.startIcon}
        </InputAdornment>;
      }
      return <TextField
        {...params}
        variant="outlined"
        label={props.label}
        placeholder={empty ? props.placeholder : undefined}
        onChange={e => props.onInputChange(e)}
        autoFocus={props.autoFocus}
      />;
    }}
  />;
}


const TagInputRaw = React.memo(TagInputRawBase);
export default TagInputRaw;