import React from 'react';

/**
 * 
 * @param {{ page: string, size?: number }} props
 * @returns 
 */
export default function URLAvatar({ page, size }) {
  let useSize = size || 22;

  return <img
    className="embedded-command-image"
    alt={page}
    key={page}
    style={{ height: useSize }}
    src={`https://www.google.com/s2/favicons?domain=${page}&sz=${44}`}
  />;
}