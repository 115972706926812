import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import IntegrationPages from '../IntegrationPage/IntegrationPages';
import { useIsExtensionInstalled, useTypedSelectorShallowEquals } from '../../hooks';
import { EmptyState } from '../EmptyState/EmptyState';
import { Helmet } from 'react-helmet-async';
import Toolbar from '@mui/material/Toolbar';
import AppLink from '../Utilities/AppLink';
import T from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { installExtension } from '../../install_extension';

/**
 * How to run this page locally:
 * Copy the `window.integrationData` data from prod and just paste it here.
 * Or copy it from the local server if you're making changes
 */

// window.integrationData = {.......}

const integrationData = window.integrationData ?? null;
const isError = 'integrationDataError' in window;

export default function InnerPublicIntegration() {
  let { isAuth, email } = useTypedSelectorShallowEquals(
    (store) => ({
      isAuth: store.userState.isLoaded ? !!store.userState.uid : null,
      email: store.userState.isLoaded ? store.userState.email : null,
    }),
  );

  let [wasLoggedOut, setWasLoggedOut] = useState(false);

  const isExtensionInstalled = useIsExtensionInstalled();

  const integrationName = integrationData?.pageData?.integration_name;

  useEffect(() => {
    if (isAuth === false) {
      setWasLoggedOut(true);
    }
  }, [isAuth]);

  return (
    <>
      {!isError && integrationData &&
        <AppBar style={{ zIndex: 12 }} position="static" className="top-bar integrations__topbar" color="secondary" elevation={0} >
          <Toolbar variant="dense" style={{ display: 'flex', overflowX: 'hidden', justifyContent: 'space-between' }}>
            <AppLink
              appType="TEXT"
              to="/snippets/"
              style={{
                textDecoration: 'none',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <T
                variant="h6"
                color="inherit"
                sx={{
                  display: 'inline',
                  marginLeft: {
                    xs: '30px',
                    md: '120px'
                  },
                  whiteSpace: 'nowrap'
                }}
              >
                <Box sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center'
                }}>
                  <span>Text Blaze</span>

                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" id="heart">
                    <path
                      fill="white"
                      d="M6.28001656,3.46389584e-14 C6.91001656,0.0191596721 7.52001656,0.129159672 8.11101656,0.330159672 L8.11101656,0.330159672 L8.17001656,0.330159672 C8.21001656,0.349159672 8.24001656,0.370159672 8.26001656,0.389159672 C8.48101656,0.460159672 8.69001656,0.540159672 8.89001656,0.650159672 L8.89001656,0.650159672 L9.27001656,0.820159672 C9.42001656,0.900159672 9.60001656,1.04915967 9.70001656,1.11015967 C9.80001656,1.16915967 9.91001656,1.23015967 10.0000166,1.29915967 C11.1110166,0.450159672 12.4600166,-0.00984032788 13.8500166,3.46389584e-14 C14.4810166,3.46389584e-14 15.1110166,0.0891596721 15.7100166,0.290159672 C19.4010166,1.49015967 20.7310166,5.54015967 19.6200166,9.08015967 C18.9900166,10.8891597 17.9600166,12.5401597 16.6110166,13.8891597 C14.6800166,15.7591597 12.5610166,17.4191597 10.2800166,18.8491597 L10.2800166,18.8491597 L10.0300166,19.0001597 L9.77001656,18.8391597 C7.48101656,17.4191597 5.35001656,15.7591597 3.40101656,13.8791597 C2.06101656,12.5301597 1.03001656,10.8891597 0.390016562,9.08015967 C-0.739983438,5.54015967 0.590016562,1.49015967 4.32101656,0.269159672 C4.61101656,0.169159672 4.91001656,0.0991596721 5.21001656,0.0601596721 L5.21001656,0.0601596721 L5.33001656,0.0601596721 C5.61101656,0.0191596721 5.89001656,3.46389584e-14 6.17001656,3.46389584e-14 L6.17001656,3.46389584e-14 Z M15.1900166,3.16015967 C14.7800166,3.01915967 14.3300166,3.24015967 14.1800166,3.66015967 C14.0400166,4.08015967 14.2600166,4.54015967 14.6800166,4.68915967 C15.3210166,4.92915967 15.7500166,5.56015967 15.7500166,6.25915967 L15.7500166,6.25915967 L15.7500166,6.29015967 C15.7310166,6.51915967 15.8000166,6.74015967 15.9400166,6.91015967 C16.0800166,7.08015967 16.2900166,7.17915967 16.5100166,7.20015967 C16.9200166,7.18915967 17.2700166,6.86015967 17.3000166,6.43915967 L17.3000166,6.43915967 L17.3000166,6.32015967 C17.3300166,4.91915967 16.4810166,3.65015967 15.1900166,3.16015967 Z"
                      transform="translate(2 2.5)">
                    </path>
                  </svg>

                  <span>{integrationName}</span>
                </Box>

              </T>
            </AppLink>

            {isExtensionInstalled === false &&
              <Button
                variant="outlined"
                sx={{
                  borderColor: 'white', color: 'white',
                  display: {
                    xs: 'none',
                    sm: 'inline'
                  },
                  '&:hover': {
                    borderColor: 'white'
                  }
                }}
                onClick={installExtension}
              >
                <b>Download extension</b><span style={{ fontWeight: '400' }}>&nbsp;– It's free!</span>
              </Button>
            }

          </Toolbar>
        </AppBar>}

      <ErrorBoundary>
        {isError || !integrationData ?
          <div style={{ paddingTop: '10vh', marginBottom: 30 }}>
            <EmptyState
              icon="MISSING"
              title="This page does not exist"
              description=""
            />
          </div>
          :
          <div>
            <SsrPageAdjustments seoTitle={integrationData?.pageData?.seo_title}/>

            <IntegrationPages
              isAuth={isAuth}
              wasLoggedOut={wasLoggedOut}
              email={email}
              integrationData={integrationData}
              isExtensionInstalled={isExtensionInstalled}
            />
          </div>
        }
      </ErrorBoundary>

    </>
  );
}

/**
 * @param {Object} props
 * @param {string=} props.seoTitle
 */
export function SsrPageAdjustments({ seoTitle }) {
  // can't add it to CSS because it'll apply everywhere
  // and we need it only within this page (and to work locally and on ssr)
  // this is due to the footer coming from ssr
  const bodyStyle = 'body { overflow: auto } #root{ height: auto}';

  return (
    <>
      {seoTitle && <Helmet defer={false}>
        <title>
          {seoTitle}
        </title>
      </Helmet>}

      <style>{bodyStyle}</style>
    </>
  );
}