import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TopBar from './TopBar';
import BillingIssueWithAction from '../BillingDetails/BillingIssueWithAction';
import WelcomeBack from '../Walkthrough/WelcomeBack';
import './inner_main.css';
import GlobalReferDialog from './GlobalReferDialog';
import DesktopAuth from '../Auth/DesktopAuth';
import OnboardingDialog from '../OnboardingFlow/OnboardingDialog';

/**
 * 
 * @param {object} props 
 * @param {boolean=} props.isUserLoaded - State prop
 * @param {object} props.children
 * @returns 
 */
function AuthenticatedRoutesBase(props) {
  const location = useLocation();
  const dispatch = useDispatch();

  // This is to mimic LOCATION_CHANGE from connected-react-router.
  // As of now we only care of pathname changes, so keeping it same
  useEffect(() => {
    dispatch({
      type: 'LOCATION_CHANGE',
      location
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  if (props.isUserLoaded && ['#windowsauth', '#desktopauth'].includes(location.hash)) {
    return <DesktopAuth />;
  }
  return  <>
    <TopBar />
    <BillingIssueWithAction />
    <WelcomeBack />
    <OnboardingDialog />
    {props.isUserLoaded && <GlobalReferDialog />}

    {props.isUserLoaded && props.children}
  </>;
}

// We use connect() rather than hooks here to address Zombie children:
//   https://react-redux.js.org/api/hooks#usage-warnings
// Can probably remove this when everything is converted to hooks...

/** @type {function(import('@store').RootState, object): { isUserLoaded: boolean }} */
const mapStateToProps = function (store) {
  return {
    isUserLoaded: store.userState.isLoaded && !!store.userState.uid
  };
};

const AuthenticatedRoutes = connect(mapStateToProps)(AuthenticatedRoutesBase);
export default AuthenticatedRoutes;


