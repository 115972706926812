import React, { useMemo, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { sync } from '../../Sync/syncer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Shortcut from '../Shortcut/Shortcut';
import T from '@mui/material/Typography';
import ArrowPopper from '../ArrowPopper/ArrowPopper';
import GroupIcon from '../Group/GroupIcon';
import SnippetLink from '../AttributeEditors/SnippetLink';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';



/**
 * @param {object} props
 * @param {object} props.target
 * @param {function} props.onClose
 * @param {function} props.onSelect
 */
export default function SnippetSelector(props) {
  let [searchValue, setSearchValue] = useState('');
  let [debouncedSearch, setDebouncedSearch] = useState('');
  const debounceTimeOut = useRef(null);
  const groupIds = useRef(sync.getGroupIds({ order: true }));

  const options = useMemo(() => {
    const search = debouncedSearch && debouncedSearch.trim().toLocaleLowerCase();

    const optionsList = [];

    for (let groupId of groupIds.current) {
      let group = sync.groups[groupId];
      optionsList.push(<ListItem key={groupId} style={{
        fontWeight: 'bold'
      }}>
        <GroupIcon
          groupId={group.id}
          containerStyle={{
            marginRight: 10
          }}
          iconStyle={{ color: '#888' }}
        />
        {group.data.name}
      </ListItem>,
      );

      let currentGroupHasSnippets = false;

      for (let snippet of group.snippets) {
        if (snippet.data.options.is_ai) {
          // can't import AI snippets
          continue;
        }

        if (
          search &&
            !snippet.data.name.toLocaleLowerCase().includes(search) &&
            !snippet.data.shortcut.toLocaleLowerCase().includes(search)
        ) {
          continue;
        }

        currentGroupHasSnippets = true;

        optionsList.push(<ListItemButton
          key={snippet.id}
          style={{ width: '100%', display: 'flex' }}
          onClick={() => props.onSelect(snippet)}
        >
          <span style={{
            flex: 1,
            paddingLeft: 20,
            paddingRight: 12,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>{snippet.data.name}</span> <span style={{ overflow: 'hidden' }}>
            <Shortcut
              shortcut={snippet.shortcut}
            />
          </span>
          <SnippetLink shortcut={snippet.shortcut} inNewWindow sx={{ pl: 1 }}/>
        </ListItemButton>
        );
      }

      // This group is empty, don't show it
      if (!currentGroupHasSnippets) {
        optionsList.pop();
      }
    }
    return optionsList;
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [debouncedSearch]
  );

  function handleSearch(e) {
    setSearchValue(e.target.value);

    if (debounceTimeOut.current) {
      clearTimeout(debounceTimeOut.current);
    }

    debounceTimeOut.current = setTimeout(() => {
      setDebouncedSearch(e.target.value);
    }, 200);
  }

  return <ArrowPopper
    placement="right"
    anchorEl={props.target}
    open
    modifiers={[
      {
        name: 'flip',
        enabled: true,
      },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          padding: 8,
          rootBoundary: 'viewport'
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, 7]
        }
      }
    ]}
    sx={{
      width: 400
    }}
  > 
    <ClickAwayListener
      mouseEvent="onMouseDown"
      onClickAway={()=>{
        props.onClose && props.onClose();
      }}
    >
      <Paper
        onClick={(e) => {
          e.stopPropagation();
        }}
        elevation={2}
        style={{
          border: 'solid 1px #f2f2f2',
          boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 4px 6px 0px rgba(0,0,0,0.14), 0px 6px 12px 0px rgba(0,0,0,0.12)',
          maxHeight: 500,
          maxWidth: 400,
          overflow: 'auto'
        }}
        className="suggestion-popper"
      >
        {/* Using a ListItem here so that it matches the spacing of the snippets list */}
        <ListItem
          component="div"
          sx={{
            position: 'sticky',
            top: 0,
            background: '#fff',
            zIndex: 1
          }}
        >
          <TextField
            value={searchValue}
            onChange={handleSearch}
            size="small"
            placeholder="Shortcut or snippet name"
            fullWidth
            margin="dense"
            autoFocus
            name="snippet-selector-search"
            sx={{
              mb: 1,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{
                  pointerEvents: 'none'
                }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </ListItem>

        {options.length > 0
          ? (
            <>
              <List>
                {options.slice(0, 100)}
              </List>

              {options.length > 100 &&
                <T color="textSecondary" variant="body1" sx={{ padding: '8px 12px 18px', textAlign: 'center', fontWeight: 500 }}>
                Too many snippets to show, please use the search bar
                </T>}
            </>
          )
          : <T color="textSecondary" variant="body1" sx={{ padding: '8px 12px 18px', textAlign: 'center' }}>
            {debouncedSearch ? 'No matching snippets found' : 'No snippets to select'}
          </T>}
      </Paper>
    </ClickAwayListener>
  </ArrowPopper>;
}