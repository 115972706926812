import React from 'react';
import { iconForAttribute } from '../SnippetEditor/type_icons';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircle';
import T from '@mui/material/Typography';


export default function AttributeList(props) {
  return <>
    <Droppable droppableId={'attribute_droppable_' + props.type} direction="horizontal">
      {(provided,) => (
        <span
          style={{
            padding: 2,
            marginLeft: 4,
            marginRight: 0
          }}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {!props.attributes.length ?
            <T
              color="textSecondary"
              style={{
                display: 'inline-block',
                paddingRight: 6,
                paddingLeft: 6
              }}>No {props.type} settings</T> : props.attributes.map((attr, i) => <Draggable
              key={props.type + '_' + i}
              draggableId={props.type + '_' + i}
              index={i}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={Object.assign({ display: 'inline-block' }, provided.draggableProps.style)}
                >
                  <AttributeChip
                    key={i}
                    selected={props.selected}
                    i={i}
                    attr={attr}
                    onSelect={props.onSelect}
                    onDelete={props.onDelete}
                    type={props.type}
                  />
                </div>
              )}
            </Draggable>)
          }
          {provided.placeholder}
        </span>
      )}
    </Droppable>
    
    <IconButton
      title={'Add ' + props.type + ' setting'}
      // You can only have 1 positional attribute
      disabled={!!(props.type === 'positional' && props.attributes.length)}
      onClick={() => {
        props.onAdd();
      }}
      style={{
        marginRight: 10
      }}
      size="large"><AddIcon /></IconButton>
  </>;
}


export function AttributeChip(props) {
  let icon = iconForAttribute(props.attr);

  return <Chip
    variant="outlined"
    icon={icon}
    color={props.selected && props.selected[0] === props.type && props.selected[1] === props.i ? 'primary' : undefined}
    label={props.type === 'positional' ? <>{props.attr.name} (positional)</> : <>{props.attr.name}=…</>}
    onClick={() => props.onSelect(props.i)}
    onDelete={props.onDelete ? () => props.onDelete(props.i) : undefined}
    style={{
      marginRight: 3
    }}
  />;
}
