import React, { useState, useRef, useEffect } from 'react';
import { evaluateNode } from '../../snippet_processor/SnippetProcessor';
import { useIsMounted } from '../../hooks';


/**
 * Evaluates async attributes before rendering the passed function.
 * 
 * @param {object} props
 * @param {import("../../snippet_processor/ParseNode").default} props.node
 * @param {import("../../snippet_processor/DataContainer").Environment} props.env
 * @param {(node: import("../../snippet_processor/ParseNode").default) => JSX.Element} props.renderer
 */
function NodeEvaluatorBase(props) {
  let [res, setRes] = useState(/** @type {import("../../snippet_processor/ParseNode").default} */ (null));
  let isMounted = useIsMounted();
  let activeItem = useRef(0);

  useEffect(() => {
    async function loadNode() {
      let id = ++(activeItem.current);
      let newRes = await evaluateNode(props.node, props.env);
      // Since it's async, we check to see if another render has been kicked off
      // and bail if it has
      if (id === activeItem.current && isMounted.current) {
        setRes(newRes);
      }
    }

    loadNode();
    // don't re-execute on isMounted, so:
    // eslint-disable-next-line
  }, [props.env, props.node]);


  return res ?
    props.renderer(res)
    : null;
}


const NodeEvaluator = React.memo(NodeEvaluatorBase);
export default NodeEvaluator;