import React from 'react';
import ToggleOff from '@mui/icons-material/ToggleOff';
import FormatSize from '@mui/icons-material/FormatSize';
import Menu from '@mui/icons-material/Menu';
import DialpadIcon from '@mui/icons-material/Dialpad';
import FunctionsIcon from '@mui/icons-material/Functions';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import EventIcon from '@mui/icons-material/Event';


export const menuIcon = <Menu />;


export const iconForAttribute = (attr) => {

  if (attr.list) {
    return menuIcon;
  }

  let type = attr.type;
  switch (type) {
  case 'string':
    return <FormatSize />;
  case 'identifier':
    return <TextFormatIcon/>;
  case 'boolean':
    return <ToggleOff />;
  case 'number':
    return <DialpadIcon />;
  case 'lambda':
    return <FunctionsIcon />;
  case 'select':
    return menuIcon;
  case 'date':
    return <EventIcon />;
  default:
    if (Array.isArray(type)) {
      return menuIcon;
    }
    throw Error('Unknown attribute type: ' + type);
  };
};