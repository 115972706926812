import React, { useEffect, useState } from 'react';
import { waitForLogin } from '@store';
import { httpsCallable } from 'firebase/functions';
import { getTBFunctions } from '../../firebase_utilities';
import VerificationReminder from '../VerificationReminder/VerificationReminder';
import CircularProgress from '@mui/material/CircularProgress'; 
import T from '@mui/material/Typography';
import { useIsMounted } from '../../hooks';

/**
 * @type {import('firebase/functions').HttpsCallable<any, any>}
 */
let discourse = httpsCallable(getTBFunctions(), 'discourse');

export default function DiscourseSSO (props) {
  let [loading, setLoading] = useState(true);
  let [emailVerified, setEmailVerified] = useState(true);

  let isMounted = useIsMounted();

  useEffect(() => {
    waitForLogin().then(async (user) => {
      if (!isMounted.current) {
        return null;
      }
      if (!user.emailVerified) {
        setLoading(false);
        setEmailVerified(false);
      } else {
        let params = new URLSearchParams((new URL(window.location.href)).search);

        let login = await discourse({
          sso: params.get('sso'),
          sig: params.get('sig')
        });

        window.location.replace('https://community.blaze.today/session/sso_login?sso=' + login.data.payload + '&sig=' + login.data.payloadSignature);
      }
    });
  // isMounted dependency complain
  // eslint-disable-next-line
  }, []);
  
  if (loading) {
    return <div style={{ marginTop: 30, marginBottom: 30, justifyContent: 'center', display: 'flex'  }}>
      <div style={{ textAlign: 'center' }}><CircularProgress size={120} thickness={1.9} />
        <br/>
        <br/>
        <T variant="h6">Logging you into the community forums...</T>
      </div>
    </div>;
  } else if (!emailVerified) {
    return <VerificationReminder reason="you will not be able to login into the discussion forums" />;
  }
  return null;
};
