import React, {  } from 'react';
import { Typography as T } from '@mui/material';
import PermissionIcon from '@mui/icons-material/NewReleases';


/**
 * @param {object} props
 * @param {GrantsType} props.grants
 */
export default function AddonGrantList({ grants }) {
  let issues = [];
  let notes = [];

  let strList = (lst) => {
    let hosts = lst.map((h, i) => <b key={i}>{h}</b>);
    for (let i = hosts.length - 1; i >= 1; i--) {
      hosts.splice(i, 0, ' and ');
    }
    return hosts;
  };

  if (grants.user) {
    issues.push('Has access to information provided by the {user} command (e.g. your email)');
  }

  if (grants.clipboard) {
    issues.push('Has access to your clipboard contents');
  } else {
    notes.push('Does not have access to your clipboard');
  }

  if (grants.action_hosts && grants.action_hosts.length) {
    if (grants.action_hosts === '*') {
      issues.push('Can trigger actions on the sites you use it on');
    } else {
      issues.push(<span>Can trigger actions when you use it on {strList(grants.action_hosts)}</span>);
    }
  } else {
    notes.push('Does not trigger keypresses or clicks on sites');
  }

  if (grants.site_hosts && grants.site_hosts.length) {
    if (grants.site_hosts === '*') {
      issues.push('Has access to the contents of the sites you use it on');
    } else {
      issues.push(<span>Has access to site contents when you use it on {strList(grants.site_hosts)}</span>);
    }
  } else {
    notes.push('Does not have access to the sites you use it on');
  }

  if (grants.snippet) {
    issues.push('Accesses snippet metadata like the name or shortcut (but not snippet contents)');
  } else {
    notes.push('Does not have access to the contents of your snippets');
  }

  if (grants.remote_hosts && grants.remote_hosts.length) {
    if (grants.remote_hosts === '*') {
      issues.push(<span>Communicates with <b>remote servers</b></span>);
    } else {
      issues.push(<span>Communicates with {strList(grants.remote_hosts)}</span>);
    }
  } else {
    notes.push('Does not communicate with remote servers');
  }

  return <ul style={{
    listStyle: 'none', 
    paddingLeft: 20,
    listStylePosition: 'outside'
  }}>
    {issues.map((x, i) => {
      return <li key={'m_' + i}>
        <PermissionIcon style={{
          verticalAlign: 'middle',
          color: '#d9822b',
          opacity: .9,
          marginRight: 9
        }} />
        <T style={{ display: 'inline' }}>{x}</T>
      </li>;
    })}
  </ul>;
}