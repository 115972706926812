import React from 'react';
import { useHistory } from 'react-router-dom';
import { IS_MICRO, waitForLogin } from '@store';
import AsyncButton from '../AsyncButton/AsyncButton';
import { clearNotification } from '../../data';

/**
 * Action button for notifications
 * @param {object} props
 * @param {string} props.notificationKey
 * @param {string} props.url
 * @param {React.ReactElement} props.children
 */
const ActionButton = ({
  notificationKey,
  url,
  children
}) => {
  const { push: navigate } = useHistory();
  return <AsyncButton
    variant="outlined"
    color="primary"
    size="small"
    style={{ marginLeft: 10 }}
    onClick={async (done) => {
      if (url.startsWith('/') && !IS_MICRO) {
        // It's an internal link, let's push it to react router
        // Note we should actually navigate to it if we are in the Micro view.
        navigate(url);
      } else {
        window.open(url, '_blanks');
      }
      await waitForLogin();
      clearNotification(notificationKey);
      done();
    }}
  >
    {children}
  </AsyncButton>;
};

export default ActionButton;