import React from 'react';
// @ts-ignore
import contentPanda from './../../../images/panda/content.jpeg';
import { EmptyState } from '../EmptyState/EmptyState';
import Box from '@mui/material/Box';


export const NO_NOTIFICATIONS = <Box
  sx={{
    textAlign: 'center',
    width: {
      xs: '300px',
      sm: '340px'
    }
  }}
>
  <img src={contentPanda} alt="All Caught Up Panda" style={{
    height: 135,
    marginBottom: 20
  }} />
  <EmptyState
    title="All caught up!"
    description="You have no new notifications"
  />
</Box>;