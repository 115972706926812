import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import ArrowPopper from '../ArrowPopper/ArrowPopper';



export default function LimitsPopper(props) {
  let [open, setOpen] = useState(true);
    
  return <ArrowPopper
    placement="top"
    anchorEl={props.target}
    open={open}
    modifiers={[
      {
        name: 'flip',
        enabled: true,
      },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          padding: 0,
          rootBoundary: 'viewport'
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, 7]
        }
      }
    ]}
  > 
    <Paper
      onClick={() => setOpen(false)}
      elevation={2}
      style={Object.assign({
        maxWidth: '80%',
        marginLeft: 8,
        border: 'solid 1px #f2f2f2',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 4px 6px 0px rgba(0,0,0,0.14), 0px 6px 12px 0px rgba(0,0,0,0.12)'
      }, props.style)}
    >
      {props.children}
    </Paper>
  </ArrowPopper>;
}