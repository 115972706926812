import React, { useRef, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Menu from '@mui/material/Menu';


/**
 * @param {object} props
 * @param {React.CSSProperties=} props.itemStyle
 * @param {any=} props.item
 * @param {React.CSSProperties=} props.menuStyle
 * @param {any} props.children
 * @param {(() => void)=} props.onClose
 * @param {boolean=} props.disabled
 * @returns 
 */
function NestedMenuItemBase(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorElementRef = useRef(/** @type {HTMLElement} */ (null));

  function handleOpenClick(event) {
    if (!anchorElementRef.current) {
      anchorElementRef.current = event.currentTarget;
    }
    setMenuOpen(state => !state);
  }

  function handleClose() {
    setMenuOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  }


  return (
    <>
      <MenuItem
        disabled={props.disabled}
        onClick={handleOpenClick}
        style={props.itemStyle}
      >
        {props.item}
        <ArrowRightIcon style={{ opacity: .5 }} />
      </MenuItem>
      <Menu
        open={menuOpen}
        disableAutoFocusItem
        transitionDuration={0}
        keepMounted
        anchorEl={() => anchorElementRef.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={props.menuStyle}
        onClose={handleClose}
      >
        {props.children}
      </Menu>
    </>
  );
}

const NestedMenuItem = React.memo(NestedMenuItemBase);
export default NestedMenuItem;
