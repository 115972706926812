import React from 'react';
import { EmptyState } from '../EmptyState/EmptyState';
import { Button, Box, } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

/**
 * 
 * @param {Object} props 
 * @param {Parameters<EmptyState>[0]['icon']} props.icon
 * @param {string} props.title
 * @param {string} props.description
 * @param {string} props.image
 * @param {object=} props.style
 * @returns 
 */
function ErrorMessageComponent({
  icon,
  title,
  description,
  image,
  style
}) {
  return (
    <div style={Object.assign({ textAlign: 'center' }, style)}>
      <EmptyState
        icon={icon}
        title={title}
        description={
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', }}>
            <span style={{
              flex: '3',
            }}>
              {description}
            &nbsp;If this issue persists, please contact us at <a href="mailto:support@blaze.today">support@blaze.today</a>.
            </span>
            {title.includes('Reload required') && <Button variant="contained" sx={{ padding: 1, flex: '1', }} onClick={() => {
              window.location.reload();
            }} startIcon={<RefreshIcon fontSize="small" />}>Reload now</Button>}
          </Box>
        }
      />
      <img src={image} alt="error Panda" style={{
        height: 240,
        marginTop: 50
      }} />
    </div>
  );
};

export default ErrorMessageComponent;