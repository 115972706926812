import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useIsMounted } from '../../hooks';

/**
 * @callback onClickHandler
 * @param {() => void} done
 * @param {Parameters<import('@mui/lab/LoadingButton').LoadingButtonProps['onClick']>['0']} evt
 */

/**
 * 
 * @param {{ isLoading?: boolean, onClick?: onClickHandler } & Omit<import("@mui/lab/LoadingButton").LoadingButtonProps, "onClick">} props
 * @param {React.Ref<HTMLButtonElement>} ref 
 * @returns 
 */
function AsyncButtonBase({
  disabled,
  isLoading: isLoadingProp,
  onClick,
  children,
  endIcon,  //Current implementation removes endIcon. Keeping the same expectation
  ...otherProps
}, ref) {
  let [isLoading, setIsLoading] = useState(false);
  let isMounted = useIsMounted();
  const isString = (typeof children) === 'string';
  return (
    <LoadingButton
      ref={ref}
      disabled={disabled || isLoading}
      onClick={(evt) => {
        if (isLoadingProp === undefined) {
          setIsLoading(true);
        }
        if (onClick) {
          onClick(() => {
            if (isLoadingProp === undefined && isMounted.current) {
              setIsLoading(false);
            }
          }, evt);
        }
      }}
      loading={isLoading || isLoadingProp}
      {...otherProps}
    >
      {/**
       * TODO: remove when <https://github.com/mui-org/material-ui/issues/27853> is fixed
       * When google translate, it mutates the text element nodes. 
       * So any other than string children, other usages should take care of google translate on its own
       * Why not wrap for other children with a wrapper? Contents will loose the styling inside eg: https://gitlab.com/textblaze/bono/-/issues/181
       */}
      {isString ? <span>{children}</span> : children}
    </LoadingButton>
  );
}


const AsyncButton = React.forwardRef(AsyncButtonBase);
export default AsyncButton;