import React from 'react';
import {
  Chip
} from '@mui/material';
import Shortcut from '../../Shortcut/Shortcut';

/**
 * @param {ReturnType<import('./trashed_utils').mapData>} data
 * @param {{ [x: string]: GroupObjectType }} groups
 * @param {any=} pageRef
 */
export const transformData = (data, groups, pageRef) => ({
  ...data,
  pageRef,
  subLabel: (
    <Chip
      size="small"
      variant="outlined"
      component="span"
      label={(
        <span style={{ whiteSpace: 'pre' }}>
          <span>{groups?.[data.actual.group_id]?.name}</span>
        </span>
      )}
      sx={{
        color: 'rgba(0, 0, 0, .7)',
        fontWeight: 400,
        border: 'none',
        backgroundColor: 'rgba(0, 0, 0, .05)',
        mr: 1
      }}
    />
  ),
  label: <div style={{
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 8,
    marginBottom: 8
  }}>
    <span style={{
      maxWidth: '85%',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: 50
    }}>{data.name}</span>
    <Shortcut
      shortcut={data.actual.shortcut}
    />
  </div>
});