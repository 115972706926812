import TypedContainer from './typedContainer';

/**
 * @extends TypedContainer<import('./tableRow').default, import('./tableContainer').default>
 */
class TableBody extends TypedContainer {
}
TableBody.blotName = 'table-body';
TableBody.tagName = 'TBODY';

export default TableBody;