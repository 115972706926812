import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Badge,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip
} from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';
import UsageIcon from '@mui/icons-material/ShowChart';
import BusinessIcon from '@mui/icons-material/Business';
import SharingIcon from '@mui/icons-material/SupervisorAccount';
import TeamsIcon from '@mui/icons-material/GroupWork';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LogoutIcon from '@mui/icons-material/Logout';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AssistantIcon from '@mui/icons-material/Assistant';
import PowerSettingsIcon from '@mui/icons-material/PowerSettingsNew';
import AccountCircle from '@mui/icons-material/AccountCircle';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';

import { sendToAndroid, store } from '@store';
import { APP_TARGET, checkPayingPro, orgId, orgName, isAndroid, userType, orgLoadedIfNeeded, getOrgLogo } from '../../flags';
import { useTypedSelector, useTypedSelectorShallowEquals } from '../../hooks';
import { getState } from '../../getState';
import MenuDropDown from './MenuDropDown';
import { signout } from '../../signout';
import {
  editUser,
  hasEditableFields,
  userEditableFields,
  userEditIcon
} from '../Org/UserMetadataEditor';
import { makeRef } from '../../firebase_utilities';
import { getTeamsAdminedByUser } from '../Org/org_utilities';
import OrgLogo from '../Org/OrgLogo';
import useHotkey from '../../hotkeys_hook';
import { useTeams } from '../../hooks/useTeams';


export default function AccountMenu() {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef();
  const hasTemplates = useTypedSelector(store => store.dataState && !!store.dataState.templates?.length);

  const isAndroidFlag = isAndroid();
  let {
    isPayingPro,
    myOrgId,
    userHasMemberFields,
    orgLoadedCheck,
    myUserType,
    orgLogo
  } = useTypedSelectorShallowEquals((store) => {
    const orgIdValue = orgId(store);
    return {
      isPayingPro: checkPayingPro(store),
      myOrgId: orgIdValue,
      userHasMemberFields: !!(orgIdValue && store.orgState && store.orgState.org && hasEditableFields(store.orgState.org.member_fields)),
      orgLoadedCheck: orgLoadedIfNeeded(store),
      myUserType: userType(store),
      orgLogo: getOrgLogo(store)
    };
  });
  const isOrg = !!myOrgId;
  const isOrgAdmin = ['editor', 'owner'].includes(myUserType);

  let {
    email,
    uid
  } = useTypedSelectorShallowEquals((store) => {
    return {
      email: store.userState.email,
      uid: store.userState.uid
    };
  });

  const teams = useTeams();
  const userAdminedTeams = teams ? getTeamsAdminedByUser(teams, uid, email) : null;

  useHotkey('g+a',() => {
    setOpen(true);
  });

  let editMemberProperties = async () => {
    let state = getState();
    editUser(
      makeRef('orgs', myOrgId),
      'self',
      state.userState.email,
      userEditableFields(state.orgState.org.member_fields),
      () => { });
  };

  const close = () => {
    setOpen(false);
  };
  let menuIcon = <AccountCircle />;
  if (isAndroid()) {
    menuIcon = <SettingsIcon />;
  } else if (isOrg) {
    menuIcon = (
      <Badge
        badgeContent={(
          <BusinessIcon style={{ width: 12, height: 12 }} />
        )}
        color="primary"
        sx={
          {
            '& .MuiBadge-badge': {
              padding: 0
            }
          }
        }
      >
        <AccountCircle />
      </Badge>
    );
  }


  return (
    <>
      <Tooltip
        title="Account settings and options"
      >
        <IconButton
          color="inherit"
          size="small"
          sx={[{
            marginRight: 0
          }, !!orgLogo && {
            border: '1px solid',
            borderRadius: 2,
            px: 1
          }]}
          ref={buttonRef}
          onClick={() => setOpen(!open)}
        >
          {!!orgLogo && (
            <OrgLogo
              logo={orgLogo}
              style={{
                marginRight: 5
              }}
            />
          )}
          {menuIcon}
        </IconButton>
      </Tooltip>
      <MenuDropDown
        MenuListProps={{
          // @ts-ignore
          component: 'div'
        }}
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
        width={300}
      >
        {!orgLoadedCheck && (
          <LinearProgress
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '1px'
            }}
          />
        )}
        {isAndroidFlag && (
          <AccountMenuButtonItem
            title="Autostart settings"
            icon={<PowerSettingsIcon />}
            onClick={() => {
              sendToAndroid({
                type: 'autostart-setting'
              });
              close();
            }}
          />
        )}
        {isAndroidFlag && (
          <AccountMenuButtonItem
            title="Accessibility settings"
            icon={<AccessibilityIcon />}
            onClick={() => {
              sendToAndroid({
                type: 'accessibility-setting'
              });
              close();
            }}
          />
        )}
        {isAndroidFlag && (
          <Divider />
        )}

        {isAndroidFlag && (
          <AccountMenuButtonItem
            title= {getState().userState.isAndroidAssistantActive ? 'Stop Assistant' : 'Start assistant'} 
            icon={<AssistantIcon />}
            onClick={() => {
              sendToAndroid({
                type: 'android-assistant',
                data: {
                  startService: !getState().userState.isAndroidAssistantActive
                }
              });
              
              store.dispatch({
                type: 'ANDROID_ASSISTANT',
                isRunning: !getState().userState.isAndroidAssistantActive
              });
              close();
            }}
          />
        )}
        {isAndroidFlag && (
          <Divider />
        )}


        <AccountAvatarMenuItem close={close} />
        <Divider />
        <AccountIconMenuItem title="Options"
          icon={<SettingsIcon />}
          url="/configure/options"
          close={close} />
        <AccountIconMenuItem title="Usage"
          icon={<UsageIcon />}
          url="/configure/usage"
          close={close} />
        <AccountIconMenuItem
          title={APP_TARGET === 'TEXT' || APP_TARGET === 'AI'
            ? 'Trash'
            : (`Trashed ${APP_TARGET === 'DATA' ? 'spaces' : 'sites'}`)}
          icon={<RestoreFromTrashOutlinedIcon />}
          url="#trash"
          close={close}
        />
        {hasTemplates && <AccountIconMenuItem
          title="Templates"
          icon={<PermMediaOutlinedIcon />}
          url="/template/list"
          close={close}
        />}
        <AccountMenuButtonItem title="Sign out"
          icon={<LogoutIcon />}
          onClick={() => {
            signout();
            close();
          }} />
        {isOrg && (
          <OrgMenu
            close={close}
            isLast={!userHasMemberFields && isAndroidFlag} />
        )}
        {orgLoadedCheck && !isOrg && isPayingPro && (
          [
            <Divider key="div" />,
            <AccountMenuLinkItem url="/pro" close={close} key="amli">
              <div style={{ textAlign: 'center', width: '100%' }} >
                <Button variant="contained" color="primary" startIcon={<BusinessIcon />}>
                  Upgrade to Text Blaze for teams
                </Button>
              </div>
            </AccountMenuLinkItem>
          ]
        )}
        {isOrg && userHasMemberFields && (
          <AccountMenuButtonItem
            title="My organization properties"
            icon={userEditIcon}
            onClick={() => {
              editMemberProperties();
              close();
            }}
          />
        )}
        {isOrg && userHasMemberFields && !isAndroidFlag && (
          <Divider style={{ borderStyle: 'dashed' }} />
        )}
        {isOrg && !isAndroidFlag && (
          <AccountIconMenuItem
            title="Members"
            icon={<SharingIcon />}
            url="/business/members"
            close={close} />
        )}
        {(isOrgAdmin || (userAdminedTeams && !!userAdminedTeams.length)) && !isAndroidFlag && (
          <AccountIconMenuItem
            title="Teams"
            icon={<TeamsIcon />}
            url="/business/teams"
            close={close} />
        )}
        {isOrgAdmin && !isAndroidFlag && (
          <AccountIconMenuItem
            title="Usage"
            icon={<UsageIcon />}
            url="/business/usage"
            close={close} />
        )}
        {myUserType === 'owner' && !isAndroidFlag && (
          <AccountIconMenuItem
            title="Billing"
            icon={<CreditCardIcon />}
            url="/business/billing"
            close={close} />
        )}
      </MenuDropDown>
    </>
  );
}

/**
 *
 * @param {object} props
 * @param {Function} props.close
 * @returns
 */
function AccountAvatarMenuItem({
  close
}) {

  let {
    photoUrl,
    email
  } = useTypedSelectorShallowEquals((store) => {
    return {
      photoUrl: store.userState.photoUrl,
      email: store.userState.email
    };
  });
  return (
    <AccountMenuLinkItem
      url="/configure/account"
      close={close}
      autoFocus
    >
      <Avatar
        src={photoUrl}
      />
      <ListItemText
        primaryTypographyProps={{
          sx: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }
        }}
        primary={email}
        secondary={<u>Manage your account</u>} />
    </AccountMenuLinkItem>
  );
}

/**
 * 
 * @param {object} props 
 * @param {Function=} props.close
 * @param {boolean} props.isLast
 * @returns 
 */
function OrgMenu({
  close,
  isLast,
  ...others
}) {
  let {
    myOrgName,
    orgLogo
  } = useTypedSelectorShallowEquals((store) => {
    return {
      myOrgName: orgName(store),
      orgLogo: getOrgLogo(store)
    };
  });
  return (
    <>
      <Divider />
      <AccountIconMenuItem
        url="/business"
        close={close}
        icon={orgLogo ? <OrgLogo logo={orgLogo} size={24} /> : <BusinessIcon />}
        title="Organization settings"
        subTitle={<u>{myOrgName}</u>}
        {...others}
      />
      {!isLast && <Divider />}
    </>
  );
}

/**
 * 
 * @param {object} props 
 * @param {string} props.title
 * @param {string|React.ReactElement=} props.subTitle
 * @param {React.ReactNode} props.icon
 * @param {string} props.url
 * @param {Function} props.close
 * @returns 
 */
function AccountIconMenuItem({
  title,
  subTitle,
  icon,
  ...otherProps
}) {

  return (
    <AccountMenuLinkItem
      {...otherProps}
    >
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={title}
        secondary={subTitle}
        secondaryTypographyProps={{
          sx: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }
        }}
      />
    </AccountMenuLinkItem>
  );
}


/**
 * 
 * @param {object} props
 * @param {string} props.url
 * @param {boolean=} props.autoFocus
 * @param {Function} props.close
 * @param {React.ReactNode} props.children
 * @returns 
 */
function AccountMenuLinkItem({
  url,
  close,
  children,
  autoFocus
}) {
  return (
    <MenuItem
      component={Link}
      to={url}
      onClick={() => {
        close();
      }}
      sx={{
        '&:hover': {
          color: 'inherit'
        }
      }}
      autoFocus={autoFocus}
    >
      {children}
    </MenuItem>
  );
}

/**
 * @param {object} props 
 * @param {string} props.title
 * @param {string=} props.subTitle
 * @param {React.ReactNode} props.icon
 * @param {import('react').EventHandler<any>} props.onClick
 * @returns 
 */
function AccountMenuButtonItem({
  title,
  subTitle,
  icon,
  onClick
}) {
  return (
    <MenuItem
      style={{
        width: '100%',
        textAlign: 'left'
      }}
      onClick={onClick}
    >
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={title} secondary={subTitle} />
    </MenuItem>
  );
}
