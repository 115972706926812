import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography as T
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Shortcut from '../Shortcut/Shortcut';

const LEAVE_WARNING = 'Sorry, you were trying to use a conflicting shortcut for this snippet. This could cause some of your snippets to not work properly.';

/**
 * Dialog to show when navigating away from the snippet with shortcut conflict.
 * @param {object} props
 * @param {string=} props.shortcut - Shortcut of the current snippet.
 * @param {string[]=} props.conflicts - conflicted with error.
 * @param {string=} props.conflictsMoreText - conflicts more text.
 * @param {() => any} props.onClose - on dialog close.
 */
const ShortcutConflictDialog = ({
  shortcut,
  conflicts,
  conflictsMoreText,
  onClose
}) => {
  const [open, setOpen] = useState(false);
  const [navigatingTo, setNavigatingTo] = useState('');
  const history = useHistory();
  const unblockRef = useRef(/** @type {import("history").UnregisterCallback} */ (null));


  useEffect(() => {
    unblockRef.current = history.block((ref) => {
      if (ref.pathname === history.location.pathname) {
        return;
      }
      setNavigatingTo(ref.pathname);
      setOpen(true);
      return false;
    });
    return () => unblockRef.current();
  }, [history]);

  const onContinue = () => {
    unblockRef.current?.();
    history.push(navigatingTo);
  };
  const closeDialog = () => {
    setOpen(false);
    onClose();
  };
  
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      disableRestoreFocus
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >Conflicting shortcut <Shortcut hasConflict shortcut={shortcut} /></DialogTitle>
      <DialogContent>
        <T>
          {LEAVE_WARNING}
        </T>
        {conflicts && (
          <T sx={{ mt: 2 }}>
            Conflicts with
            <Shortcut
              hasConflict
              shortcut={conflicts[0]}
              style={{
                marginLeft: 8
              }}
            />
            {conflictsMoreText}
             
          .</T>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          mb: 2,
          mx: 1.25
        }}
      >
        <Button
          onClick={onContinue}
        >
          Ignore and continue
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={closeDialog}
        >
          Edit shortcut
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShortcutConflictDialog;