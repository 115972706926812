import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import equalsES6 from 'fast-deep-equal/es6';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router-dom';
import { getConnectedConfigOptions } from './flags';
import { checkAnyAppInstalled } from './extension';


export const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};


export const useIsMedium = () => {
  // @ts-ignore
  return useMediaQuery((theme) => theme.breakpoints.down('md'));
};


export const useIsSmall = () => {
  // @ts-ignore
  return useMediaQuery((theme) => theme.breakpoints.down('sm'));
};


export const useIsXSmall = () => {
  // @ts-ignore
  return useMediaQuery((theme) => theme.breakpoints.only('xs'));
};


export const useIsXShort = () => {
  // @ts-ignore
  return useMediaQuery('(max-height:500px)');
};


/**
 *  @type {import("react-redux").TypedUseSelectorHook<import('@store').RootState>} 
 */
export const useTypedSelector = useSelector;


/**
 *  @type {import("react-redux").TypedUseSelectorHook<import('@store').RootState>} 
 */
export const useTypedSelectorShallowEquals = (fn) => useSelector(fn, shallowEqual);


/**
 *  @type {import("react-redux").TypedUseSelectorHook<import('@store').RootState>} 
 */
export const useTypedSelectorDeepEquals = (fn) => useSelector(fn, equalsES6);


/**
 * @returns whether the user has completed onboardings for Text/Data Blaze
 */
export const useAppsOnboardingCompleted = () => {
  let { quest } = useTypedSelector((store) => {
    return {
      quest: store.userState && store.userState.quest
    };
  });

  let res = {
    TEXT: false,
    DATA: false
  };

  if (!quest) {
    return res;
  }

  if (quest.db_completed) {
    res.DATA = true;
  }
  if (quest.completed || quest.dismissed) {
    res.TEXT = true;
  }

  return res;
};

const allowedReferrers = [
  'https://data.blaze.today/',
  'https://community.blaze.today/',
  'https://blaze.today/'
];

export function useIsNewUserAndFromAllowedReferrer() {
  return useTypedSelector(store => {
    return store.userState?.newSignUp &&
      !store.userState?.didAutoImport &&
      // prevent malicious actors from sending a malicious URL to a new user and making them auto-import snippets
      !!allowedReferrers.find(referrer => document.referrer.startsWith(referrer));
  });
}

// from react-router docs: https://v5.reactrouter.com/web/example/query-parameters
export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useIsExtensionInstalled() {
  let [isExtensionInstalled, setExtensionInstalled] = useState(null);
  let email = useTypedSelector(store => store.userState.isLoaded ? store.userState.email : null);

  useEffect(() => {
    if (email === null) {
      return;
    }

    async function checkIfExtensionInstalled() {
      setExtensionInstalled(await checkAnyAppInstalled(email));
    }

    checkIfExtensionInstalled();
  }, [email]);

  return isExtensionInstalled;
}


export function useConnectedSettings({ groupId }) {
  const connectedSettings = useTypedSelectorDeepEquals((store) => {
    let group = groupId ? store.dataState?.groups?.[groupId] : null;
    // if the group hasn't loaded yet, default to not connected
    return group ? getConnectedConfigOptions(store.userState, group).config : getConnectedConfigOptions(null, { connected: { is_connected: false } }).config;
  });
  return connectedSettings;
}