import React, { useEffect, useRef, useState } from 'react';
import { currentIdToken, waitForLogin } from '@store';
import { TABLES_FRONT_END_DOMAIN } from '../../hooks/useTables';
import { Box, LinearProgress, Skeleton } from '@mui/material';
import { getAuth, getIdToken } from 'firebase/auth';


async function sendTokenToFrame(frame) {
  frame.contentWindow.postMessage({
    type: 'new_token',
    data: {
      token: await getIdToken(getAuth().currentUser, true),
    }
  }, TABLES_FRONT_END_DOMAIN);
}

/**
 * @param {object} props
 * @param {string} props.url
 * @param {boolean=} props.loading
 * @param {boolean=} props.showSkeleton
 * @param {boolean=} props.hidden
 * @param {function=} props.listener
 * @param {function=} props.onLoaded
 * @param {any=} props.style
 * @param {React.ReactElement=} props.children
 * @param {object} ref
 * @returns
 */
function DatabaseFrameBase(props, ref) {
  const name = useRef(`frame_${Date.now()}_${Math.floor(Math.random() * 100)}`);
  const iframeRef = useRef(null);
  const submitted = useRef(true);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const tokenRequested = useRef(false);

  useEffect(() => {
    if (!token) {
      (async () => {
        await waitForLogin();
        setToken(await currentIdToken());
      })();
    }
    // eslint-disable-next-line
  }, []);

  async function onOnline() {
    if (tokenRequested.current) {
      tokenRequested.current = false;
      await sendTokenToFrame(iframeRef.current);
    }
  }

  useEffect(() => {
    const listener = async (event) => {
      if (event.origin !== TABLES_FRONT_END_DOMAIN) {
        return;
      }

      const type = event.data.type;
      if (type === 'refresh_token') {
        try {
          await sendTokenToFrame(iframeRef.current);
        } catch (error) {
          if (error.code === 'auth/network-request-failed') {
            tokenRequested.current = true;
          } else {
            throw error;
          }
        }
      } else if (iframeRef.current.contentWindow !== event.source) {
        // Only process messages sent from current iframe, to avoid receiving the same message multiple times
        return;
      }

      if (props.listener) {
        props.listener(event);
      }
    };

    window.addEventListener('message', listener);
    window.addEventListener('online', onOnline);
    return () => {
      window.removeEventListener('message', listener);
      window.removeEventListener('online', onOnline);
    };

    // eslint-disable-next-line
  }, [props.listener]);

  useEffect(() => {
    submitted.current = false;
    setLoading(true);
  }, [props.url]);

  return token && (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'rgb(250,250,250)'
      }}>
      { (props.loading && !props.showSkeleton) && <LinearProgress variant="indeterminate" /> }
      { (loading || props.loading) && props.showSkeleton && <>
        <Box sx={{
          px: 2.4,
          py: .7,
          display: 'flex',
          gap: '12px',
          alignItems: 'center',
          backgroundColor: 'white',
          position: 'absolute',
          borderBottom: 'solid 1px #ddd',
          left: 0,
          right: 0
        }}>
          <Skeleton animation="wave" variant="text" sx={{
            fontSize: '1.6rem',
            width: '120px'
          }} />
          <Skeleton
            variant="circular"
            animation="wave"
            sx={{
              width: '1.3rem',
              height: '1.3rem'
            }}
          />
          {[1,2,3,4,5].map((_, i) => <Skeleton
            key={i}
            variant="text"
            animation="wave"
            sx={{
              fontSize: '1.6rem',
              width: '80px'
            }} />)}
          <div style={{ flex: 1 }} />

          <Skeleton
            variant="circular"
            animation="wave"
            sx={{
              width: '1.3rem',
              height: '1.3rem'
            }}
          />
        </Box>
      </>}
      <React.Fragment key={props.url}>
        <form
          // We use a form that submits the iframe in order to use POST rather than GET.
          // This is so the token won't be unintentially logged on the server
          target={name.current}
          action={props.url}
          method="POST"
          ref={(el) => {
            // "React will call your ref callback with the DOM node when it’s time to set the ref,
            // and with null when it’s time to clear it."
            // src: https://react.dev/learn/manipulating-the-dom-with-refs
            if (!el) {
              return;
            }

            if (!submitted.current) {
              submitted.current = true;
              el.submit();
            }
          }}
          style={{
            display: 'none'
          }}
        >
          <input type="text" name="token" defaultValue={token} />
          {props.children}
        </form>
        { /* eslint-disable-next-line */ }
        <iframe
          name={name.current}
          className="iframe-db"
          src=""
          allow="clipboard-read *; clipboard-write *"
          style={{
            width: '100%',
            flex: 1,
            borderWidth: 0,
            opacity: (loading || props.loading) ? 0 : 1,
            display: props.hidden ? 'none' : 'block',
            ...props.style,
          }}
          ref={(el) => {
            iframeRef.current = el;
            if (ref) {
              ref.current = el;
            }
          }}
          onLoad={() => {
            setLoading(false);
            if (props.onLoaded) {
              props.onLoaded();
            }
          }}
        />
      </React.Fragment>
    </div>
  );
}

const DatabaseFrame = React.forwardRef(DatabaseFrameBase);
export default DatabaseFrame;
