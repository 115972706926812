import { Box, Button, ClickAwayListener, Paper, Popper, Tooltip } from '@mui/material';
import React, { useRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { isPro } from '@store';
import { toast } from '../../../message';

/**
 * 
 * @param {object} props
 * @param {string[][]} props.colors
 * @param {string} props.value
 * @param {(string) => any} props.onChange
 */
const ColorPicker = ({
  colors,
  value,
  onChange
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const inputColorRef = useRef(/** @type {HTMLInputElement} */(null));
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <PlainButton
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
        style={{
          display: 'flex',
          border: '1px solid #888888',
          borderRadius: 5,
          overflow: 'hidden'
        }}
      >
        <ColorNode color={value} />
        <ArrowDropDownIcon sx={{
          color: '#888888'
        }} />
      </PlainButton>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 10
        }}
      >
        <ClickAwayListener onClickAway={(evt) => {
          if (anchorEl.contains(evt.target)) {
            return;
          }
          handleClose();
        }}>
          <Paper elevation={1} sx={{
            padding: 1,
            display: 'flex',
            gap: 0.5,
            flexDirection: 'column'
          }}>
            {colors.map((row, index) => (
              <Box key={`color-row-${index}`} sx={{
                display: 'flex',
                gap: 0.5
              }}>
                {row.map((color) => (
                  <PlainButton
                    key={`color-${color}`}
                    onClick={() => {
                      onChange(color);
                      handleClose();
                    }}
                  >
                    <ColorNode color={color} />
                  </PlainButton>
                ))}
              </Box>
            ))}
            <Button
              onClick={() => {
                if (!isPro()) {
                  toast('Custom colors are only available to Pro users.', {
                    duration: 8000,
                    intent: 'danger',
                    upgrade: 'Upgrade to get them.'
                  });
                  return;
                }
                inputColorRef.current.click();
              }}
            >Custom color</Button>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <input
        ref={inputColorRef}
        type="color"
        style={{
          visibility: 'hidden',
          width: 0,
          height: 0,
          margin: 0,
          padding: 0,
          borderWidth: 0
        }}
        onChange={(e) => onChange(e.target.value)}
        tabIndex={-1}
      />
    </>
  );
};

export default ColorPicker;


const ColorNode = ({
  color
}) => {
  return (
    <Tooltip
      title={color === null ? 'Unset' : color === '' ? 'Transparent' : color}
    >
      <div style={{
        backgroundColor: color,
        width: 24,
        height: 24,
        border: '1px solid #777777',
        borderRadius: 2
      }}>
        {color === 'transparent' && (
          <div style={{
            transform: `
              translate(0px, 11px)
              rotate(-45deg)
              scaleX(1.5)
            `,
            height: 1,
            width: '100%',
            backgroundColor: 'red'
          }}></div>
        )}

        {color === null && (
          <div style={{
            display: 'flex',
            margin: '0 auto',
            transform: `
              translate(-2px, 5px)
              rotate(-45deg)
              scaleX(0.5)
            `,
          }}>unset</div>
        )}
      </div>
    </Tooltip>
  );
};

/**
 * 
 * @param {import('react').ButtonHTMLAttributes} props 
 * @returns 
 */
const PlainButton = ({
  children,
  style,
  ...otherProps
}) => {
  return <button style={{
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    margin: 0,
    ...style
  }} {...otherProps}>{children}</button>;
};