import React from 'react';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { sync } from '../../Sync/syncer';
import ModifiedBadge from './ModifiedBadge';


/**
 * @param {object} props
 * @param {boolean} props.selected
 * @param {string} props.snippetId
 * @param {string} props.groupId
 */
function SnippetModifiedBadgeBase(props) {
  let {
    modifiedDate,
    modifiedUID,
    createdDate,
    createdUID
  } = useTypedSelectorShallowEquals(_store => {
    let snippet = sync.getSnippetById(props.snippetId);
    return {
      createdDate: snippet && snippet.data.created_at && snippet.data.created_at.toMillis(),
      modifiedDate: snippet && snippet.data.updated_at && snippet.data.updated_at.toMillis(),
      modifiedUID: snippet && snippet.data.updated_by,
      createdUID: snippet && snippet.data.created_by
    };
  });

  return <ModifiedBadge
    selected={props.selected}
    snippetId={props.snippetId}
    groupId={props.groupId}
    createdDate={createdDate}
    modifiedDate={modifiedDate}
    modifiedUID={modifiedUID}
    createdUID={createdUID}
  />;
}


const SnippetModifiedBadge = React.memo(SnippetModifiedBadgeBase);
export default SnippetModifiedBadge;
