import React, { useContext } from 'react';
import { PREVIEW_ICONS, loopIcon } from './shared';
import { AddonStatusContext, Chicklet, ShowError } from './SharedComponents';
import { ParseError } from '../../snippet_processor/ParserUtils';


/**
 * @param {object} props
 * @param {import("../../snippet_processor/ParseNode").default} props.node
 * @param {import("../../snippet_processor/DataContainer").Environment} props.env
 * @param {string} props.type
 */
function ChickletRendererBase(props) {
  let addonStatuses = useContext(AddonStatusContext);
  let status = addonStatuses[props.node.info.storeId] || {};

  
  let loading = status.loading;
  let error = status.error;

  let dom = props.node;
  if (error) {
    let msg;
    if (typeof error === 'string') {
      msg = error;
    } else {
      msg = error instanceof ParseError ? error.message : error.info.message;
    }
    return <ShowError msg={dom.info.visibility.name + ': ' + msg} />;
  }
    
  if (props.type === 'none') {
    return null;
  }

  return Chicklet(<span>
    {PREVIEW_ICONS['addon']} <span className={'label' + (loading ? ' can-load' : '')}>{dom.info.visibility.name}</span>
    {loading ? <span className="chick-loader">{loopIcon}</span> : null}
  </span>, {
    title: dom.info.visibility.description
  });
}


const ChickletRenderer = React.memo(ChickletRendererBase);
export default ChickletRenderer;