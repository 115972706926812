import equals from 'fast-deep-equal';
import { memberRestricted, getAddonData, isOrgOwner } from '../flags';
import { addAddonAttributes } from './AddonProcessing';
import {
  getAuth,
  getIdTokenResult,
  getIdToken,
  where,
  orderBy,
  limit,
  query,
  docExists,
  makeRef,
  doc,
} from '../firebase_shared_driver';
import { debounce, throttle } from '../utilities';

const ORG_DEFAULTS_KEY = '_ORG_DEFAULTS_';

/**
 * @typedef {object} GroupQueryType
 * @property {boolean=} config.includePending - if true, include groups that haven't loaded yet
 * @property {boolean=} config.includeDisabled - if true, include inactive disabled groups
 * @property {boolean=} config.order - if true, order the group results
 * @property {'exclude'|'include'|'only'=} config.addons - whether to include the addons in the results, 'only' means only return addons (defaults to 'exclude')
 */

export class Group {
  constructor(config, data = {}) {
    /** @type {boolean} */
    this.deleting = false;

    /** @type {Function} */
    this.unSubscribe = config.unSubscribe;

    // If true, snippets have not yet downloaded
    /** @type {boolean} */
    this.loading = config.loading;

    // If true, the group data (e.g. name, description) has not yet downloaded
    /** @type {boolean} */
    this.stub = config.stub;

    /** @type {Snippet[]} */
    this.snippets = [];

    // For addons
    /** @type {string} */
    this.namespace = null;

    /** @type {GroupObjectType} */
    this.data = /** @type {any} */ ({});
    this.updateData(data);
  }

  updateData(data) {
    Object.assign(this.data, data);

    /** @type {string} */
    this.id = this.id || data.id;

    if (this.isAddon()) {
      this.namespace = this.data.options.addon.namespace;
    }
  }

  isAddon() {
    return !!(this.data.options && this.data.options.addon);
  }
}


export class Snippet {
  constructor(config, data = {}) {
    /** @type {Group} */
    this.group = config.group;

    /** @type {SnippetObjectType} */
    this.data = /** @type {any} */ ({});

    /** @type {AddonOptionsType} */
    this.addonOptions = null;

    this.updateData(data);
  }

  updateData(data) {
    Object.assign(this.data, data);

    /** @type {string} */
    this.id = this.id || data.id;

    /** @type {string} */
    this.shortcut = data.shortcut.toLocaleLowerCase();

    /** @type {string} */
    this.group_id = data.group_id;
  }
}


class Org {
  constructor(data) {
    /** @type {Function} */
    this.unSubscribe = null;

    /** @type {OrgObjectType} */
    this.data = /** @type {any} */ ({});
    this.updateData(data);
  }

  updateData(data) {
    Object.assign(this.data, data);

    /** @type {string} */
    this.id = this.id || data.id;
  }
}


class Team {
  constructor(data) {
    /** @type {Function} */
    this.unSubscribe = null;


    /** @type {TeamObjectType} */
    this.data = /** @type {any} */ ({});
    this.updateData(data);
  }

  updateData(data) {
    Object.assign(this.data, data);

    /** @type {string[]} */
    this.groups = this.data.groups;
  }
}


class Addon {
  constructor(data) {
    /** @type {Function} */
    this.unSubscribe = null;

    /** @type {AddonObjectType} */
    this.data = /** @type {any} */ ({});
    this.updateData(data);
  }

  updateData(data) {
    Object.assign(this.data, data);

    /** @type {string} */
    this.group_id = this.data.active && this.data.active.group_id;
  }
}


export default class Sync {
  /**
   * @param {object} config
   * @param {import("../Storage/Storage").default} config.storage
   * @param {Function} config.log
   * @param {Function} config.dispatch
   * @param {function(): import('@store').UserStateDef} config.getUserState
   * @param {Function} config.entityPostProcess
   * @param {boolean} [config.skipDisabledGroups]
   * @param {boolean} config.isDashboard
   */
  constructor(config) {
    /** @type {(typeof config)['storage']} */
    this.storage = config.storage;
    /** @type {(typeof config)['log']} */
    this.log = config.log || (() => {});
    /** @type {(typeof config)['dispatch']} */
    this.dispatch = (config.dispatch || (() => {})).bind(this);
    /** @type {(typeof config)['getUserState']} */
    this.getUserState = config.getUserState;
    /** @type {(typeof config)['entityPostProcess']} */
    this.entityPostProcess = config.entityPostProcess || ((_type, obj) => obj);
    /** @type {(typeof config)['skipDisabledGroups']} */
    this.skipDisabledGroups = config.skipDisabledGroups || false;

    // Whether we are running in the dashboard where we need to download
    // some different content than the extension.
    /** @type {boolean} */
    this.isDashboard = config.isDashboard;

    /** @type {Object<string, Group>} */
    this.groups = {};

    /** @type {Object<string, Snippet>} */
    this.snippets = {};

    /** @type {Object<string, Team>} */
    this.teams = {};

    /** @type {Object<string, Addon>} */
    this.userAddons = {};

    /** @type {Object<string, Addon>} */
    this.orgAddons = {};

    /** @type {Org} */
    this.org = null;

    // We ignore these groups as permission was denied to them
    // We don't want to keep trying to load them
    /** @type string[] */
    this.ignoreGroups = [];

    // Records who is requesting the groups be download (the 'user' or a specific team id)
    /** @type {Object<string, string[]>} */
    this.groupSubscriptions = {};

    const fn = this.emitDataChangeInner.bind(this);
    this.emitDataChangeHandler = this.isDashboard ? debounce(fn, 50) : throttle(fn, 50);

    if (typeof window !== 'undefined') {
      window['xsync'] = this;
      // eslint-disable-next-line no-restricted-globals
    } else if (typeof self !== 'undefined') {
      // eslint-disable-next-line no-restricted-globals
      self['xsync'] = this;
    }
  }
  

  /** @type {import('@store').UserStateDef} */
  currentUserState = null;


  /**
   * @param {string} id
   * @param {string} subscriber
   */
  subscribeToGroup(id, subscriber) {
    if (!(id in this.groupSubscriptions)) {
      this.groupSubscriptions[id] = [];
    }
    if (!this.groupSubscriptions[id].includes(subscriber)) {
      this.groupSubscriptions[id].push(subscriber);
      this.updateGroups();
    }
  }


  /**
   * @param {string} id
   * @param {string} subscriber
   */
  unsubscribeToGroup(id, subscriber) {
    if (this.groupSubscriptions[id] && this.groupSubscriptions[id].includes(subscriber)) {
      this.groupSubscriptions[id].splice(this.groupSubscriptions[id].indexOf(subscriber), 1);
      if (this.groupSubscriptions[id].length === 0) {
        delete this.groupSubscriptions[id];
      }
      this.updateGroups();
    }
  }

  updateGroups() {
    let currentGroups = Object.keys(this.groups);
    let nextGroups = Object.keys(this.groupSubscriptions);
    for (let id of currentGroups) {
      if (!nextGroups.includes(id)) {
        this.deleteGroup(id);
      }
    }
    for (let id of nextGroups) {
      if (!currentGroups.includes(id)) {
        this.linkGroup(id);
      }
    }
  }



  /**
   * List of currently active group ids.
   * 
   * @param {GroupQueryType} config
   * 
   * @return {string[]}
   */
  getGroupIds(config = {}) {
    let { includePending, order, addons } = config;

    addons = addons || 'exclude';

    let userState = this.getUserState();
    
    // Is the user not allowed to create, then hide what they've already created
    // @ts-ignore - as we are passing a smaller reduced state object
    let createMemberRestricted = this.org && memberRestricted({
      userState,
      orgState: { org: this.org.data }
    }, 'create');

    // Combine team group id's with user group id's
    let baseGroups = Object.keys(userState.groups || {}).filter(id => {
      if (userState.groups[id].disabled && !config.includeDisabled) {
        return false;
      }

      let group = this.groups[id];
      if (!group) {
        return false;
      }

      if (!includePending && (group.stub || group.loading)) {
        // We haven't loaded data about it so can't check it or use it
        return false;
      }

      if (group.isAddon()) {
        if (addons === 'exclude') {
          return false;
        }
        let createdByUser = group.data.created_by === (this.currentUserState && this.currentUserState.uid);
        let orgFolder = group.data.associated_org_id === (this.currentUserState && this.currentUserState.org && this.currentUserState.org.id);
        if (!createdByUser && !orgFolder) {
          // Don't load shared addon groups that weren't created by the user or associated with the user's org
          // The user can view and edit them, but they shouldn't be executable, this is to prevent
          // malicious logic being shared via add-on groups.
          return false;
        }
      } else {
        if (addons === 'only') {
          return false;
        }
      }

      if (createMemberRestricted) {
        // Disable groups (like the demo group) they have created before joining
        // logic mirrored in SnippetList.js
        if (group.data.created_by === (this.currentUserState && this.currentUserState.uid)
          && Object.keys(group.data.permissions).length === 1
          && (!group.data.associated_team_ids || group.data.associated_team_ids.length === 0)) {
          return false;
        }
      }
      return true;
    });
    // add in team default groups (note the team object may be created before the teams are downloaded)
    baseGroups = baseGroups.concat(...Object.values(this.teams).map(team => team.groups || []));
    // add in org default groups if they exist
    baseGroups = baseGroups.concat((this.org && this.org.data && this.org.data.default_groups) || []);

    // Dedupe them as there could be copies.
    let ids = Array.from(new Set(
      // note it's not clear what we should do when state.groups is not defined
      // (can occur is readonly is loaded before settings, and then we load the org objects with
      // with default groups).
      //
      // It may be want to include rather the automatically excluding when state.groups is undefined.
      // Automatically excluding reduces the chances we may incorrectly enable a snippet
      baseGroups.filter(id => userState.groups && (!userState.groups[id] || !userState.groups[id].disabled || config.includeDisabled))
    ));

    let downloadedGroups = Object.keys(this.groups).filter(id => {
      let group = this.groups[id];

      if (group.isAddon()) {
        if (addons === 'exclude') {
          return false;
        }
      } else {
        if (addons === 'only') {
          return false;
        }
      }

      return !group.stub && !group.loading;
    });
    // Check to make sure the group isn't ignored and it's been downloaded (or is in the process)
    ids = ids.filter(id => !this.ignoreGroups.includes(id) && downloadedGroups.includes(id));

    if (order) {
      if (userState.groups) {

        // teams should be sorted alphabetically
        let teamKeys = Object.keys(this.teams);
        teamKeys.sort((a, b) => (this.teams[a].data.name && this.teams[b].data.name) ? this.teams[a].data.name.localeCompare(this.teams[b].data.name) : 0);
              
        let items = ids.map(id => {
          let userOrder = null;
          let isDisabled = false;

          if (userState.groups[id]) {
            userOrder = userState.groups[id].order;
            isDisabled = userState.groups[id].disabled;
          }

          if (this.groupSubscriptions[id] && this.groupSubscriptions[id].length) {
            if (userOrder === null) {
              // check if it is an org default
              if (this.groupSubscriptions[id].includes(ORG_DEFAULTS_KEY)) {
                userOrder = 100000 + this.org.data.default_groups.indexOf(id);
              }
            }

            if (userOrder === null) {
              // check if it is a team default
              let teamId = this.groupSubscriptions[id][0];
              if (teamId in this.teams) {
                userOrder = 200000 + teamKeys.indexOf(teamId) * 10000 + this.teams[teamId].data.groups.indexOf(id);
              }
            }
          }

          return {
            id,
            order: userOrder,
            isDisabled
          };
        });

        items.sort((a, b) => {
          if (a.isDisabled && !b.isDisabled) {
            return 1;
          }
          if (b.isDisabled && !a.isDisabled) {
            return -1;
          }

          // Put things without orders at the end
          let aOrder = a.order || 999999;
          let bOrder = b.order || 999999;

          if (aOrder === bOrder) {
            return a.id.localeCompare(b.id);
          }

          return aOrder - bOrder;
        });

        ids = items.map(x => x.id);
      }
    }

    return ids;
  }


  /**
   * Object of currently active shortcuts.
   * 
   * @param {{ abSnippets?: 'only', tbSnippets?: 'only' }} [config]
   * @return {Object<string, Snippet[]>}
   */
  activeShortcuts(config = {}) {
    let activeGroups = this.getGroupIds({
      addons: 'exclude'
    });

    /** @type {Object<string, Snippet[]>} */
    let res = Object.create(null);
    
    for (let groupId of activeGroups) {
      let group = this.groups[groupId];
      for (let snippet of group.snippets) {
        let shortcut = snippet.shortcut;
        if ((config.abSnippets !== 'only' || snippet.data.options?.is_ai) && (config.tbSnippets !== 'only' || !snippet.data.options?.is_ai)) {
          if (!res[shortcut]) {
            res[shortcut] = [];
          }
          res[shortcut].push(snippet);
        }
      }
    }

    return res;
  }


  /**
   * Object of currently active addons.
   * 
   * @return {Object<string, ActiveAddonType>}
   */
  activeAddons() {
    // Load user addons not installed from the marketplace.
    let activeGroups = [];

    /**
     * @return {boolean}
     */
    let isUsableAddon = (groupId) => {
      let group = this.groups[groupId];
      
      // We check for isAddon() to confirm the group has downloaded. Since we concat that addon groups, it may not have.
      //
      // It's possible for an addon to be created without a namespace during development
      // so we check for that.
      return !!(group && group.isAddon() && group.data.options.addon.namespace);
    };

    let nonInstalledAddons = this.getGroupIds({
      addons: 'only'
    }).filter(id => isUsableAddon(id));

    // Only load user ones if:
    //   - not part of org 
    //   - org user addons are enabled
    //   - is org owner
    if (
      !this.org
      || (
        (this.org.data.options && this.org.data.options.userAddonsEnabled)
        || isOrgOwner({ userState: this.currentUserState })
      )
    ) {
      activeGroups = activeGroups.concat(Object.values(this.userAddons).map(x => x.group_id));
    }

    if (this.orgAddons) {
      activeGroups = activeGroups.concat(Object.values(this.orgAddons).map(x => x.group_id));
    }

    activeGroups = activeGroups.filter(id => isUsableAddon(id));


    // User installed addons should override globally installed addons, but should 
    // only do so if the associated_addon_id is equal to the installed addon, so a
    // user doesn't accidentally create a namespace collision.
    for (let nonInstallI = 0; nonInstallI < nonInstalledAddons.length; nonInstallI++) {
      let nonInstalledAddonId = nonInstalledAddons[nonInstallI];
      let userAddon = this.groups[nonInstalledAddonId];
      for (let installedI = 0; installedI < activeGroups.length; installedI++) {
        let installedAddonId = activeGroups[installedI];
        let installedAddon = this.groups[installedAddonId];

        if (userAddon.data.options.addon.namespace === installedAddon.data.options.addon.namespace) {
          if (userAddon.data.associated_addon_id === installedAddon.data.associated_addon_id) {
            // installed addon conflicts with a development version of it
            // as a user folder, remove the installed addon
            activeGroups.splice(installedI, 1);
            installedI--;
          } else {
            // user folder addon namespace conflicts with installed addon (but not same
            // associated_addon_id), remove the user folder version
            nonInstalledAddons.splice(nonInstallI, 1);
            nonInstallI--;
          }
        }
      }
    }

    activeGroups = activeGroups.concat(nonInstalledAddons);

    /** @type {Object<string, ActiveAddonType>} */
    let res = {};
    
    for (let groupId of activeGroups) {
      let group = this.groups[groupId];
      let addonData = getAddonData(group.data, this.currentUserState, this.org && this.org.data ? { org: this.org.data } : undefined);
      let addonConfigData = addonData.data;

      for (let snippet of group.snippets) {
        let addonOptions = snippet.addonOptions;
        if (!addonOptions) {
          continue;
        }

        res[addonOptions.command] = {
          invalidIn: addonOptions.invalidInAttribute ? 'attribute' : null,
          addon: snippet,
          addonConfigData,
          installedBy: addonData.installed,
          approvedGrants: addonData.approvedGrants,
          command: addonOptions.command,
          name: group.data.name + ' – ' + snippet.data.name,
          attributes: addonOptions.attributes
        };
      }
    }

    return res;
  }


  /**
   * @param {string} snippetId
   * 
   * @return {Snippet}
   */
  getSnippetById(snippetId) {
    let snippet = this.snippets[snippetId];
    if (snippet) {
      return snippet;
    }
    
    // the below shouldn't be necessary, but we have it as a fallback
    // in case the snippets object is not updated correctly for some reason
    for (let groupId in this.groups) {
      for (let snippet of this.groups[groupId].snippets) {
        if (snippet.id === snippetId) {
          // if these error messages never happen, we should be able to get
          // rid of the fallback.
          console.warn('Back-filling missing snippet id: ' + snippetId);
          this.snippets[snippetId] = snippet;
          return snippet;
        }
      }
    }
    
    return null;
  }


  /**
   * @param {string} shortcut
   * 
   * @return {Snippet[]}
   */
  getSnippetsByShortcut(shortcut) {
    let items = [];
    shortcut = shortcut.toLocaleLowerCase();
    for (let groupId of this.getGroupIds()) {
      for (let snippet of this.groups[groupId].snippets) {
        if (snippet.shortcut === shortcut) {
          items.push(snippet);
        }
      }
    }
    return items;
  }


  /**
   * @param {string} groupId 
   */
  deleteGroup(groupId) {
    let group = this.groups[groupId];
    if (group) {
      group.deleting = true;
      this.groups[groupId].unSubscribe();
    }
    for (let snippet of this.groups[groupId].snippets) {
      // We do this check to make sure we don't delete a moved snippet
      // that is no longer part of this group.
      if (this.snippets[snippet.id] && this.snippets[snippet.id].group_id === groupId) {
        delete this.snippets[snippet.id];
      }
    }
    delete this.groups[groupId];
  };


  /**
   * When the logged in user changes.
   * 
   * E.g. logging in or logging out
   */
  userChanges() {
    let userState = this.getUserState();

    if (userState && this.currentUserState && userState.uid !== this.currentUserState.uid) {
      this.ignoreGroups = [];

      // org and team removals will be handled below
      
      for (let id in this.groups) {
        this.unsubscribeToGroup(id, 'user');
      }

      // snippets should be cleaned up with the groups
      // but we have this as a fallback
      this.snippets = {};

      this.emitDataChange();
    }

    let getOrgMemberType = (orgData) => orgData && orgData.org && orgData.org.type === 'member';
    if (getOrgMemberType(userState) !== getOrgMemberType(this.currentUserState)) {
      // We track membership type as if they move from member to non-member or vice versa
      // that can change what snippets are available if create is disabled
      this.emitDataChange();
    }

    let originalUserGroups = this.currentUserGroups;

    this.currentUserState = userState;
    this.currentUserGroups = userState && userState.groups;
    
    // Update the org:

    // Clear out the org if we have one and the new one does not exist
    if (this.org && (!userState || !userState.org)) {
      if (this.org.unSubscribe) {
        this.org.unSubscribe();
        for (let groupId of (this.org.data.default_groups || [])) {
          this.unsubscribeToGroup(groupId, ORG_DEFAULTS_KEY);
        }

        this.allTeams = {
          unsubscribe: null,
          teams: {}
        };
        this.emitDataChange('ORG_ALL_TEAMS_UPDATE');
      }

      this.org = null;
      this.emitDataChange('ORG_UPDATE');
    }

    /**
     * @param {string[]} oldGroups
     * @param {string[]} newGroups
     * @param {string} subscriber
     */
    let handleGroupsChange = (oldGroups, newGroups, subscriber) => {
      oldGroups = oldGroups || [];
      newGroups = newGroups || [];

      let groupChange = false;

      // Add the new groups
      for (let key of newGroups) {
        if (!oldGroups.includes(key)) {
          groupChange = true;
          this.subscribeToGroup(key, subscriber);
        }
      }
      
      // Remove the old groups
      for (let key of oldGroups) {
        if (!newGroups.includes(key)) {
          groupChange = true;
          this.unsubscribeToGroup(key, subscriber);
        }
      }
      
      if (groupChange) {
        this.emitDataChange();
      }
    };

    // create/update the org
    if (userState && userState.org) {
      if (!this.org || userState.org.id !== this.org.id) {
        if (this.org && this.org.unSubscribe) {
          // we have a new org, let's unsubscribe the old one
          this.org.unSubscribe();
        }

        let currentUser = getAuth().currentUser;
        this.org = new Org({ id: userState.org.id });
        this.allTeams = {
          unsubscribe: null,
          teams: {}
        };
        getIdTokenResult(currentUser).then((token) => {
          // If the org is already set on the claims, we can immediately proceed to load the org
          // object in order to decrease loading latency (important to do as affects UI).
          let orgId = this.org.id;
          if (token.claims && token.claims.org && /** @type {{id: string}} */ (token.claims.org).id === orgId) {
            // We're set (note, that we'll refresh the token below for the teams)
            return token;
          } else {
            return getIdTokenResult(currentUser, true);
          }
        }).catch(e => {
          if (e?.message?.startsWith?.('Firebase: Error')) {
            // We could not load the id token, so we reset the org
            // This is so that we will try to load it again after the
            // connection is restored
            this.org = null;
            if (typeof vi === 'undefined') {
              console.warn('Could not load org because of FirebaseError', e.message);
            }
            // We need to retry this again, to ensure the org
            // snippets (and the team snippets) do get loaded when user is back online.
            // Note that userChanges() will almost never re-run when user is back online.
            // (unless some users_readonly or users_settings values are also changed)
            // Note that if the org does not get loaded, then none of the team snippets will load either
            // Right now, we retry this whenever the user is back online
            return null;
          } else {
            throw e;
          }
        }).then((token) => {
          if (!token) {
            return;
          }
          // Since the surrounding call is async, it's possible the org will get deleted
          // before we get here, if so, no need to subscribe.
          if (this.org) {
            const orgId = this.org.id;

            let teamUnsubscribe;
            // we only need to download all the teams on the dashboard for messaging and sharing
            if (this.isDashboard) {
              teamUnsubscribe = this.storage.onSnapshotQuery(makeRef('orgs', orgId, 'teams'), (docs) => {
                this.allTeams.teams = {};
                docs.docs.forEach(d => this.allTeams.teams[d.id] = new Team(d.data()));
                this.emitDataChange('ORG_ALL_TEAMS_UPDATE');
              });
            }

            let orgUnsubscribe = this.storage.onSnapshot(makeRef('orgs', orgId), doc => {
              if (this.org) {
                let getMemberRestrictions = (orgData) => orgData && orgData.options && (orgData.options.memberRestrictions || '').includes('create');

                let data = doc.data();

                if (getMemberRestrictions(data) !== getMemberRestrictions(this.org.data)) {
                  // Removing create ability can change available data
                  this.emitDataChange();
                }

                handleGroupsChange(
                  this.org.data && this.org.data.default_groups,
                  data && data.default_groups, ORG_DEFAULTS_KEY
                );

                this.org.updateData(Object.assign({ id: orgId }, data));

                if (Object.keys(this.orgAddons).length || (this.org && this.org.data.addons)) {
                  this.processAddonChanges(this.orgAddons, (this.org && this.org.data.addons) || {}, 'org_');
                }
                
                this.emitDataChange('ORG_UPDATE');
              }
            });
          
            this.org.unSubscribe = function() {
              if (teamUnsubscribe) {
                teamUnsubscribe();
              }
              orgUnsubscribe();
            };
          }
        });
      }
    }

    if (this.teams || (userState && userState.teams)) {
      let newTeams = (userState && userState.teams) || {};

      if (this.org && this.org.id) {
        if (!equals(Object.keys(newTeams).sort(), Object.keys(this.teams).sort())) {
  
          let origOrgId = this.org.id;
          // Update our custom claims so we get access to the new groups
          this.tryGetIdTokenTeams(userState).catch((e) => {
            if (e?.message?.startsWith?.('Firebase: Error')) {
              if (e.message.includes('auth/network-request-failed') || e.message.includes('auth/user-token-expired')) {
                return null; 
              }
            }

            throw e;
          }).then((result) => {
            // We could not get the token
            if (!result) {
              return;
            }
            if (!this.org || this.org.id !== origOrgId) {
              // Since this is called async, we may have logged out or lost the org
              // by the time we get here. Bail out if that is the case.
              return;
            }

            for (let teamId in newTeams) {
              if (!(teamId in this.teams)) {
                let team = new Team({});
                this.teams[teamId] = team;

                team.unSubscribe = this.storage.onSnapshot(makeRef('orgs', this.org.id, 'teams', teamId), doc => {
                  if (!docExists(doc)) {
                    if (doc.metadata.fromCache) {
                      /**
                       * Do not process this empty document if it is from the cache. Steps to repro issue:
                       * 1. log out of the dashboard and then log into the dashboard.
                       * 2. Then, quit the chrome process as soon as your first snippets appeared in the UI.
                       * 3. Then, disconnect from network and open the Chrome browser while offline
                       * 4. Then, reconnect to the network
                       * 
                       * Now note that groups from default team folders have not loaded and will never load.
                       * (because they would be unsubscribed in the code below)
                       * 
                       * This is why we need to do an early return now, to avoid that unsubscription
                       */
                      return;
                    }
                  }
                  let oldGroups = team.groups;
      
                  let newGroups = [];
                  if (docExists(doc)) {
                    team.updateData(doc.data());
                    newGroups = team.groups;
                  }

                  handleGroupsChange(oldGroups, newGroups, teamId);
                  
                  /**
                   * Notes on this unsubscription:
                   * We should not unsubscribe the team here if the document does not exist
                   * This is because the document is empty when the user is offline
                   * In this case, we want to load the teams when the user is back online
                   * BUT, this condition has been here for a years, and we are not
                   * sure if it's really unnecessary. So we are keeping it as is for now.
                   */
                  // Code BEGIN
                  if (!docExists(doc) && team.unSubscribe)  {
                    team.unSubscribe();
                    // If the team got deleted, we'll clean it up below
                  }
                  // Code END

                  // When the team gets deleted or user loses access to it, we will
                  // remove the team from the userstate in the changeMade code below

                  this.emitDataChange('ORG_TEAMS_UPDATE');
                },  (err) => {
                  if (err.code === 'permission-denied') {
                    // Let's unsubscribe everything, 
                    // the team itself and associated groups will be cleaned up
                    // when the userState updates.
                    if (team.unSubscribe) {
                      team.unSubscribe();
                    }

                    for (let key of (team.groups || [])) {
                      this.unsubscribeToGroup(key, teamId);
                    }
      
                    this.emitDataChange();

                    this.emitDataChange('ORG_TEAMS_UPDATE');
                  }
                });
              }
            }
          });
        }
      }

      let changeMade = false;
      for (let teamId in this.teams) {
        if (!(teamId in newTeams)) {
          changeMade = true;
          if (this.teams[teamId].unSubscribe) {
            this.teams[teamId].unSubscribe();
          }
          for (let id of (this.teams[teamId].groups || [])) {
            this.unsubscribeToGroup(id, teamId);
          }
          delete this.teams[teamId];
        }
      }

      if (changeMade) {
        this.emitDataChange();
        
        this.emitDataChange('ORG_TEAMS_UPDATE');
      }
    }

    if (userState && userState.groups) {
      let groupChange = false;

      let newGroups = Object.assign({}, userState.groups);
      if (this.skipDisabledGroups) {
        for (let id in newGroups) {
          if (newGroups[id].disabled) {
            delete newGroups[id];
          }
        }
      }

      for (let id in newGroups) {
        if (!this.groupSubscriptions[id] || !this.groupSubscriptions[id].includes('user')) {
          groupChange = true;
          this.subscribeToGroup(id, 'user');
        }

        // we should emit a data_change event if a groups disabled state is toggled
        // the frontend uses this for findConflicts(), the extension for the context menu
        if (!groupChange && originalUserGroups) {
          if (newGroups[id].disabled && (!originalUserGroups[id] || !originalUserGroups[id].disabled)) {
            groupChange = true;
          } else if (!newGroups[id].disabled && (originalUserGroups[id] && originalUserGroups[id].disabled)) {
            groupChange = true;
          // } else if (originalUserGroups[id] && originalUserGroups[id].order !== newGroups[id].order) {
            // Used for the extension context menu
            // TODO should we enable this? If not the extension context menu doesn't update on re-order
            // there appears to be unnecessary calls to this on drag though. (if you drag but don't actually move)
            // maybe wait until tre virtualization
            // groupChange = true;
          }
        }
      }

      for (let id in this.groupSubscriptions) {
        if (this.groupSubscriptions[id].includes('user') && !(id in newGroups)) {
          groupChange = true;
          this.unsubscribeToGroup(id, 'user');
        }
      }

      if (groupChange) {
        this.emitDataChange();
      }
    }

    if (Object.keys(this.userAddons).length || (userState && userState.addons)) {
      this.processAddonChanges(this.userAddons, (userState && userState.addons) || {}, 'user_');
    }

    if (Object.keys(this.orgAddons).length || (this.org && this.org.data.addons)) {
      this.processAddonChanges(this.orgAddons, (this.org && this.org.data.addons) || {}, 'org_');
    }
  }


  /**
   * @param {Object<string, Addon>} oldAddons
   * @param {Object<string, Object>} newAddonsConfig
   * @param {string} key
   */
  processAddonChanges(oldAddons, newAddonsConfig, key) {
    
    let addonChange;
      
    // subscribe to any new addons
    for (let addonId in newAddonsConfig) {
      if (newAddonsConfig[addonId].enabled && !(addonId in oldAddons)) {
        addonChange = true;

        let addon = new Addon({});
        oldAddons[addonId] = addon;

        addon.unSubscribe = this.storage.onSnapshot(makeRef('addons', addonId), doc => {
          // Refer to teams subscription listener for comments on this early return
          if (!docExists(doc)) {
            if (doc.metadata.fromCache) {
              return;
            }
          }
          let oldGroupId = addon.group_id || null;
      
          let newGroupId = null;
          if (docExists(doc)) {
            addon.updateData(doc.data());
            newGroupId = addon.group_id || null;
          }

          // Subscribe to the addon group (unsubscribing from an old group if needed)
          if (oldGroupId !== newGroupId) {
            if (oldGroupId) {
              this.unsubscribeToGroup(oldGroupId, key + addonId);
            }
            if (newGroupId) {
              this.subscribeToGroup(newGroupId, key + addonId);
            }
          }


          if (!docExists(doc) && addon.unSubscribe)  {
            addon.unSubscribe();
            // If the addon got deleted, we'll clean it up below
          }
        }, (err) => {
          if (err.code === 'permission-denied') {
            // Let's unsubscribe everything.
            if (addon.unSubscribe) {
              addon.unSubscribe();
            }

            if (addon.group_id) {
              this.unsubscribeToGroup(addon.group_id, key + addonId);
            }

            // Don't delete it from oldAddons, it will be cleaned up later on.
            // If we `delete oldAddons[addonId]` like this, it we'll keep oscillating between adding and deleting it.
            // if (oldAddons[addonId] === addon) {
            //  delete oldAddons[addonId];
            // }
          }
        });
      }
    }
      

    // unsubscribe to any old addons
    for (let addonId in oldAddons) {
      if (!(addonId in newAddonsConfig) || !newAddonsConfig[addonId].enabled) {
        addonChange = true;
        // remove the addon subscription
        if (oldAddons[addonId].unSubscribe) {
          oldAddons[addonId].unSubscribe();
        }
        // remove the addon group subscription
        this.unsubscribeToGroup(oldAddons[addonId].group_id, key + addonId);
        // delete the addon object
        delete oldAddons[addonId];
      }
    }

    if (addonChange) {
      this.emitDataChange();
    }
  }


  /**
   * @param {string} groupId
   * 
   * @return {Group}
   */
  linkGroup(groupId) {
    if (this.ignoreGroups.includes(groupId)) {
      return;
    }

    let newGroup = true;

    /** @type {function} */
    let groupUnsubscribe = () => {};
    /** @type {function} */
    let snapUnsubscribe = () => {};

    let unSubscribe = () => {
      groupUnsubscribe();
      snapUnsubscribe();
    };

    let group = new Group({
      loading: true,
      unSubscribe,
      stub: true
    }, {
      id: groupId
    });

    this.groups[groupId] = group;
    
    // note: the options should probably include metadata
    // however firestore seems to be buggy when this is enabled and you have 
    // doc and query listener
    groupUnsubscribe(); // in case we already have a subscription
    groupUnsubscribe = this.retrySnapshot(makeRef('groups', groupId), null, /** fn */ (doc) => {
      if (!docExists(doc)) {
        if (!doc.metadata.fromCache) {
          this.ignoreGroups.push(groupId);

          unSubscribe();
          this.emitDataChange();
        }
        return;
      } else {
        if (!newGroup) {
          let originalNamespace = group.namespace;

          group.updateData(this.entityPostProcess('group', doc.data()));

          if (group.isAddon() && originalNamespace && originalNamespace !== group.namespace) {
            // the namespace has changed, we need to update the relevant addonOptions
            for (let snippet of group.snippets) {
              // when dragging a snippet to an addon, addonOptions may not be defined
              // at first.
              if (snippet.addonOptions) {
                let newCommand = group.namespace + '-' + snippet.addonOptions.command.split('-')[1];
                snippet.addonOptions.command = newCommand;
                if (snippet.addonOptions.attributes) {
                  snippet.addonOptions.attributes.commandName = newCommand;
                }
              }
            }
          }
        } else {
          newGroup = false;
          group.stub = false;

          group.updateData(this.entityPostProcess('group', doc.data()));


          snapUnsubscribe(); // in case we already have a subscription
          let processFn = /** @type {function(import('firebase/firestore').QuerySnapshot)} */ async (snapshot) => {
            group.loading = false;

            let needSort = false;

            for (let change of snapshot.docChanges()) {
              let sid = change.doc.id;
              const firebaseChangeDoc = change.doc.data();
              // Check Storage.js for source
              // Computing addonOptions is expensive in extension
              // because it needs to be done by message passing
              // with the offscreen document. So, we persist
              // these addonOptions in the cache instead
              const isFromCache = firebaseChangeDoc?.tbSource === 'cache';
              const sourceData = isFromCache ? firebaseChangeDoc.data : firebaseChangeDoc;
              const sourceAddonOptions = isFromCache ? firebaseChangeDoc.addonOptions : null;

              let data = Object.assign({ id: sid }, this.entityPostProcess('snippet', sourceData));
              if (change.type === 'added' || change.type === 'modified') {
                let oldSnippet = group.snippets.find(x => x.id === data.id);

                /** @type {AddonOptionsType} */
                let addonOptions;
                if (sourceAddonOptions) {
                  addonOptions = sourceAddonOptions;
                } else if (group.isAddon()) {
                  const addonNamespace = group.data.options.addon.namespace;
                  addonOptions = await addAddonAttributes(addonNamespace, data);
                }

                if (oldSnippet) {
                  oldSnippet.updateData(data);
                  if (addonOptions) {
                    oldSnippet.addonOptions = addonOptions;
                  }
                } else {
                  let snip = new Snippet({ group }, data);
                  if (addonOptions) {
                    snip.addonOptions = addonOptions;
                  }
                  group.snippets.push(snip);
                  this.snippets[data.id] = snip;
                }
                needSort = true;
              } else if (change.type === 'removed') {
                let oldIndex = group.snippets.findIndex(x => x.id === data.id);
                if (oldIndex > -1) {
                  group.snippets.splice(oldIndex, 1);
                }
                if (this.snippets[data.id].group_id === groupId) {
                  // We check to see if it is from the group as if the snippet was
                  // moved between groups, the snippet here may be for the new group
                  delete this.snippets[data.id];
                }
              }
            }

            if (needSort) {
              group.snippets.sort((a, b) => a.data.order - b.data.order);
            }
            
            this.emitDataChange();
          };

          // Max size is 300, add a little bit for overages
          snapUnsubscribe = this.retrySnapshot(query(makeRef('snippets'), where('group_id', '==', groupId), orderBy('order'), limit(310)), null, processFn, () => group.deleting);
        }
        this.emitDataChange();
      }
    }, /** terminate check */ (err, errorCount) =>  {
      if (group.deleting) {
        return true;
      }

      if (err !== null && err.code === 'permission-denied') {
        // unsubscribing is handled by the syncer (or the extension)
        return this.dispatch({
          type: 'GROUP_PERMISSION_DENIED',
          errorCount,
          unSubscribe,
          groupId
        });
      }
    }, /** lost access function */ () => {
      snapUnsubscribe();
      group.loading = true;
      group.stub = true;
      group.snippets = [];
      newGroup = true;

      this.emitDataChange();
    });
  }


  emitDataChangeTimer = null;

  /** @type {Set} */
  pendingDataChanges = null;

  /**
   * @param {string|string[]} items
   */
  emitDataChange(items = ['DATA_CHANGE']) {
    if (!Array.isArray(items)) {
      items = [items];
    }
    if (!this.pendingDataChanges) {
      this.pendingDataChanges = new Set();
    }
    for (let item of items) {
      this.pendingDataChanges.add(item);
    }

    this.emitDataChangeHandler();
  }

  emitDataChangeInner() {
    let pendingChanges = this.pendingDataChanges;
    this.pendingDataChanges = null;

    if (pendingChanges.has('DATA_CHANGE')) {
      this.dispatch({
        type: 'DATA_CHANGE',
        groups: this.groups,
        groupSubscriptions: this.groupSubscriptions,
        ignoreGroups: this.ignoreGroups
      });
      
      this.dispatch({
        type: 'HANDLE_CONFLICTS'
      });
    }

    if (pendingChanges.has('ORG_UPDATE')) {
      this.dispatch({
        type: 'ORG_UPDATE',
        data: this.org ? this.org.data : null
      });
    }

    if (pendingChanges.has('ORG_TEAMS_UPDATE')) {
      this.dispatch({
        type: 'ORG_TEAMS_UPDATE',
        teams: this.teams
      });
    }

    if (pendingChanges.has('ORG_ALL_TEAMS_UPDATE')) {
      this.dispatch({
        type: 'ORG_ALL_TEAMS_UPDATE',
        teams: this.allTeams.teams
      });
    }

    // This is to prevent snippets containing addons to
    // trigger when the addons themselves have not yet loaded
    try {
      if (this.storage.hasLoadedAllCacheData(() => {
        const result = { snippets: 0, addons: [] };
        result.addons = Object.keys(this.activeAddons());
        const shortcuts = this.activeShortcuts();
        for (const shortcut in shortcuts) {
          result.snippets += shortcuts[shortcut].length;
        }
        return result;
      })) {
        this.storage.resolveInitializedCachePromise();
      }
    } catch (e) {
      // eslint-disable-next-line no-restricted-globals
      self?.['reportToErrorMonitoring'](e);
    }
  }


  /**
   * @param {*} query - Firestore query
   * @param {*} options - Firestore query options
   * @param {function} fn - To handle results
   * @param {function} terminateCheckFn - If true and we need to retry, just bail.
   * @param {function=} lostAccessFn - Called when we lose access
   * 
   * @return {function} unsubscribe callback -- calling it also terminates any retries
   */
  retrySnapshot(query, options, fn, terminateCheckFn, lostAccessFn) {
    const INIT_DELAY = 1000; // 1 second initial retry delay	
    const MAX_DELAY = 10 * 60 * 1000; // 10 minutes is max retry
    let delay = INIT_DELAY;
    let errorCount = 0;
    let errorResetTimerId;
    let successfullyLoaded = false;

    /** @type {function} */
    let mostRecentUnsubscribe = () => {};
    let unSubscribed = false;
    let unSubscribe = (shouldKill = true) => {
      if (shouldKill) {
        unSubscribed = true;
      }
      mostRecentUnsubscribe();
    };
    
    const createSnapshot = () => {
      let resFn = (...args) => {
        // Don't reset the error counter if we're coming from the cache
        // as the server may return an error.
        if (!(args[0] && args[0].metadata && args[0].metadata.fromCache)) {
          errorResetTimerId = setTimeout(() => {
            errorCount = 0;
            delay = INIT_DELAY;
            errorResetTimerId = null;
            successfullyLoaded = true;
          }, 5000);
        }
        // Success! we have data (either live or from the cache), let's return it
        fn(...args);
      };
      let errFn = (err) => {
        if (successfullyLoaded) {
          if (err.code === 'permission-denied') {
            // We've downloaded data and now we've lost access, let's clean that data up.
            if (lostAccessFn) {
              lostAccessFn();
            }
          }
          successfullyLoaded = false;
          // We then want to reset the loading
        }

        if (errorResetTimerId) {
          // We clear the timeout to prevent oscillation between success and failure,
          // which can happen with cached/networked data.
          clearTimeout(errorResetTimerId);
          errorResetTimerId = null;
        }
        errorCount++;
        if (!unSubscribed && !terminateCheckFn(err, errorCount)) {
          // Clear last subscription (null op if we don't have one)
          mostRecentUnsubscribe(false);

          setTimeout(() => {
            if (!unSubscribed && !terminateCheckFn(null)) {
              createSnapshot();
            }
          }, delay);
          delay = Math.min(MAX_DELAY, delay * 2);
        }
      };
      
      if (query.path) {
        query.dead = false;
        // Only docs have a path, not collections or queries
        if (options) {
          mostRecentUnsubscribe = this.storage.onSnapshot(query, resFn, errFn, options);
        } else {
          mostRecentUnsubscribe = this.storage.onSnapshot(query, resFn, errFn);
        }
      } else {
        if (options) {
          mostRecentUnsubscribe = this.storage.onSnapshotQuery(query, resFn, errFn, options);
        } else {
          mostRecentUnsubscribe = this.storage.onSnapshotQuery(query, resFn, errFn);
        }
      }
    };

    createSnapshot();

    return unSubscribe;
  }

  /**
   * @param {import("../store").UserStateDef} userState 
   * @returns {Promise<string>}
   */
  async tryGetIdTokenTeams(userState) {
    let newTeams = (userState && userState.teams) || {};
  
    const tokenResult = await getIdTokenResult(getAuth().currentUser);
    // @ts-ignore
    let orgIdFromClaims = tokenResult.claims?.org?.id;

    if (orgIdFromClaims !== userState.org?.id) {
      // if no orgs
      return getIdToken(getAuth().currentUser, true);
    }
    let teamsFromCurrentToken = tokenResult.claims?.teams;

    if (!teamsFromCurrentToken) {
      return getIdToken(getAuth().currentUser, true);
    }

    let needRefresh = false;

    for (const teamId in newTeams) {
      // Claims doesn't have new team
      if (!teamsFromCurrentToken[teamId]) {
        needRefresh = true;
        break;
      }
    }
    
    if (!needRefresh) {
      return tokenResult.token;
    }
    // Update our custom claims so we get access to the new groups
    return getIdToken(getAuth().currentUser, true);
  }

  /**
   * @template {keyof SnippetObjectType['options']} T
   * @param {string} snippetId
   * @param {T} key
   * @param {SnippetObjectType['options'][T]} value
   */
  updateSnippetOption(snippetId, key, value) {
    this.storage.update(doc(makeRef('snippets'), snippetId), { [`options.${key}`]: value, }, 'HIDE_AUTOSAVE');
  }
}
