// Source: https://stackoverflow.com/questions/28993157/visibilitychange-event-is-not-triggered-when-switching-program-window-with-altt
/**
 * 
 * @param {() => void} [onVisibleCallback] 
 * @param {() => void} [onHiddenCallback] 
 */
export function visibilityChangeHandler(onVisibleCallback, onHiddenCallback) {
  // window or document can be undefined for server-side rendering
  let isVisible = true; // internal flag, defaults to true

  function onVisible() {
    // prevent double execution
    if (isVisible) {
      return;
    }
  
    // change flag value
    isVisible = true;
    if (onVisibleCallback) {
      onVisibleCallback();
    }
  }

  function onHidden() {
    // prevent double execution
    if (!isVisible) {
      return;
    }

    // change flag value
    isVisible = false;
    if (onHiddenCallback) {
      onHiddenCallback();
    }
  }

  function handleVisibilityChange(forcedFlag) {
    // forcedFlag is a boolean when this event handler is triggered by a
    // focus or blur event otherwise it's an Event object
    if (typeof forcedFlag === 'boolean') {
      if (forcedFlag) {
        return onVisible();
      }

      return onHidden();
    }

    if (document['hidden']) {
      return onHidden();
    }

    return onVisible();
  }

  function forceVisibilityChange() {
    handleVisibilityChange(true);
  }

  function allowVisibilityChange() {
    handleVisibilityChange(false);
  }

  document.addEventListener('visibilitychange', handleVisibilityChange, false);

  // extra event listeners for better behavior (visibility change event doesn't emit when the user switches b/w windows using alt+tab for example)
  // We tested on Chrome, Edge, Vivaldi, Safari, Opera, and Arc and listeners on Window worked fine whereas for Firefox both Window and document event are triggering simultaneously so we just kept listeners on Window
  window.addEventListener('focus', forceVisibilityChange, false);
  window.addEventListener('blur', allowVisibilityChange, false);

  return () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
    window.removeEventListener('focus', forceVisibilityChange);
    window.removeEventListener('blur', allowVisibilityChange);
  };
}