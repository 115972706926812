import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Grid from '@mui/material/Grid';
import TopBar, { StatelessTopBar } from './TopBar';
import PublicPanel from '../PublicPanel/PublicPanel';
import { useIsExtensionInstalled, useTypedSelector } from '../../hooks';
import { EmptyState } from '../EmptyState/EmptyState';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { SsrPageAdjustments } from './InnerPublicIntegration';
import { installExtension } from '../../install_extension';

/**
 * How to run this page locally:
 * Copy the `window.publicGalleryData` data from prod and just paste it here.
 * Or copy it from the local server if you're making changes
 */

// window.publicGalleryData = {.......}

// @ts-ignore
const pageData = window.publicGalleryData ?? null;
const isError = 'publicGalleryDataError' in window;

function InnerPublic() {
  let isAuth = useTypedSelector(store => store.userState.isLoaded ? !!store.userState.uid : null);
  const isExtensionInstalled = useIsExtensionInstalled();

  let [wasLoggedOut, setWasLoggedOut] = useState(false);

  useEffect(() => {
    if (isAuth === false) {
      setWasLoggedOut(true);
    }

  }, [isAuth]);

  return (
    <>
      {isAuth
        ? <TopBar />
        : <StatelessTopBar appType="TEXT" isHome={false}>
          <Box sx={{ flex: 1 }} />

          {isExtensionInstalled === false &&
            <Button
              variant="outlined"
              sx={{
                borderColor: 'white', color: 'white',
                display: {
                  xs: 'none',
                  sm: 'inline'
                },
                '&:hover': {
                  borderColor: 'white'
                }
              }}
              onClick={installExtension}
            >
              <b>Download extension</b><span style={{ fontWeight: '400' }}>&nbsp;– It's free!</span>
            </Button>
          }
        </StatelessTopBar>
      }

      <div className="container-fluid page-body" style={{ overflowY: 'auto' }}>

        <Grid container style={{ height: '100%', justifyContent: 'center' }}>
          <Grid item xs={12} sm={11} md={9}>
            <ErrorBoundary>
              {isError || !pageData
                ?  <div style={{ paddingTop: '10vh', marginBottom: 30 }}>
                  <EmptyState
                    icon="MISSING"
                    title="Could not load folder"
                    description="This folder does not exist or you do not have access to it."
                  />
                </div>

                : <>
                  <SsrPageAdjustments seoTitle={pageData.seo_title}/>

                  <PublicPanel pageData={pageData} isAuth={isAuth} wasLoggedOut={wasLoggedOut} isExtensionInstalled={isExtensionInstalled}/>
                </>
              }
            </ErrorBoundary>
          </Grid>
        </Grid>

      </div>
    </>
  );
}

const InnerPublicMemoized = React.memo(InnerPublic);
export default InnerPublicMemoized;

