import React, { useState, useRef, useCallback, useEffect, useMemo, useImperativeHandle } from 'react';
import { useFeature } from '../Version/limitations';
import { ProChipBase } from '../Version/VersionChipBase';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import SnippetRenderer from './SnippetRenderer';
import FieldRenderer from './FieldRenderer';
import { Environment } from '../../snippet_processor/DataContainer';
import { trimDom, structureDom, markupDomPreview } from '../../snippet_processor/SnippetProcessor';
import T from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RemoteStore from './RemoteStore';
import SQLStore from './SQLStore';
import { blockingImg, nameToSelector } from './shared';
import { ActionContext, AddonStatusContext, AttemptedInsertContext } from './SharedComponents';
import equals from 'fast-deep-equal';
import { useIsMounted, useIsXSmall } from '../../hooks';
import Box from '@mui/material/Box';
import LimitsPopper from './LimitsPopper';
import { Dialog, DialogActions, DialogContent, DialogTitle, Avatar, Tooltip, ClickAwayListener, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { electron } from '../../utilities';
import { SitePageSelectorMemo } from './SitePageSelector';
import { isCommunityDocsWhitelist, isElectronApp } from '../../flags';
import { sendMessageToClient } from '../../desktop_utilities';
import ArrowPopper from '../ArrowPopper/ArrowPopper';
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlined from '@mui/icons-material/CloudUploadOutlined';
import CloudDone from '@mui/icons-material/CloudDone';
import CloudDoneOutlined from '@mui/icons-material/CloudDoneOutlined';
import CloudSync from '@mui/icons-material/CloudSync';
import TableChart from '@mui/icons-material/TableChart';
import AppLink from '../Utilities/AppLink';
import SidebarResizer from '../SnippetEditor/SidebarResizer';
import { getDataFromClient } from '../../desktop_shared_utils';
import { getPreferencesStore, isExtension, } from '../../extension_utilities';
import useOnMount from '../../hooks/useOnMount';
import MessageDisplayInner from '../Dialog/MessageDisplayInner';


/** @typedef {{ label: string, status: 'error'|'success'|'pending', spaceId?: string, type?: 'urlload'|'urlsend', displayText: string, data?: string, responseCode?: number, id: string, triggerTimestamp: number }} RemoteProgressItemType */

/**
 * 
 * @param {RemoteProgressItemType} props
 * @returns 
 */
function RemoteProgressItem({ displayText, label, status, spaceId, type, data, responseCode, triggerTimestamp, }) {
  const SIZE = '20px';
  const dataGot = typeof data === 'string' ? data : data !== undefined ? JSON.stringify(data) : data;
  const dataShow = dataGot?.length > 100 ? dataGot.substring(0, 100) + '...' : dataGot;
  /** @type {JSX.Element} */
  let avatar;
  if (status === 'pending') {
    avatar = <CircularProgress size={18} />;
  } else if (spaceId) {
    avatar = <Avatar sx={{
      background: 'none',
      height: SIZE,
      width: SIZE,
      fontSize: '100%',
      color: '#777',
    }}>
      <TableChart fontSize="small" />
    </Avatar>;
  } else if (type === 'urlload') {
    avatar = <CloudDownloadOutlined fontSize="small" />;
  } else {
    avatar = <CloudUploadOutlined fontSize="small" />;
  }
  const textElm = <T variant="caption" sx={{
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    lineHeight: null, // for vertical align
    color: status === 'error' ? '#d70000' : undefined,
  }} className="request-label">
    {displayText}
  </T>;

  return <Tooltip placement="right" title={<>
    {spaceId ? 'BSQL Query: ' : 'Request: '}{label} {status === 'pending' ? ' (loading)' : ''}
    <br />Triggered at: {new Date(triggerTimestamp).toISOString()}
    {responseCode !== undefined && <>
      <br />
      Response status code: {responseCode}
    </>}
    {dataShow && <>
      <br />
      Response: {dataShow}
    </>}
  </>}>
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      flexDirection: 'row',
      '&:hover .request-label': {
        fontWeight: '700 !important'
      }
    }}>
      <Box sx={{
        width: SIZE,
        height: SIZE,
      }}>
        {spaceId ? <AppLink
          appType="DATA"
          to={`/space/${spaceId}`}
          target="_blank"
        >
          {avatar}
        </AppLink> : avatar}
      </Box>
      {textElm}
    </Box>
  </Tooltip>;
}

/**
 * @param {object} props 
 * @param {RemoteProgressItemType[]} props.items
 * @param {React.CSSProperties} [props.containerStyle]
 */
export function RemoteBottomStatusBar({ items, containerStyle }) {
  const [show, setShow] = useState(false);

  const [popperAnchor, setPopperAnchor] = useState(null);

  if (!items || items.length === 0) {
    return;
  }

  const hasAnyErrors = items.some(x => x.status === 'error');
  const countPendingRequests = items.filter(x => x.status === 'pending').length;
  const allDone = countPendingRequests === 0;
  const canBeOpen = show && !!popperAnchor;
  const id = canBeOpen ? 'transition-popper' : undefined;
  const firstPendingItem = items.find(x => x.status === 'pending');
  let displayStatus = '';
  if (firstPendingItem) {
    const displayText = firstPendingItem.displayText;
    const firstPendingItemIsDB = !!firstPendingItem.spaceId;
    if (firstPendingItemIsDB) {
      // change 'Read' to 'Reading'
      const firstWord = displayText.split(' ')[0];
      const otherWords = displayText.split(' ').slice(1).join(' ');
      displayStatus = firstWord + 'ing ' + otherWords;
    } else {
      const prefixAction = firstPendingItem.type === 'urlload' ? 'Loading' : 'Sending to';
      displayStatus = `${prefixAction} ${displayText?.toLowerCase()}`;
    }
  }

  if (displayStatus.length > 40) {
    displayStatus = displayStatus.substring(0, 40) + '...';
  }
  if (countPendingRequests > 1) {
    displayStatus += ` +${countPendingRequests - 1} more`;
  }

  const itemProgresses = <Paper sx={{
    flexDirection: 'column',
    gap: '5px',
    display: 'flex',
    borderRadius: '10px',
    padding: 1.5,
  }}
  elevation={4}
  >
    {items.map((x, i) => {
      return <RemoteProgressItem key={x.id + '-' + i} {...x} />;
    })}
  </Paper>;

  const IconComp = allDone ? (hasAnyErrors ? CloudDoneOutlined : CloudDone) : CloudSync;

  let tooltipTitle = 'All requests complete' + (hasAnyErrors ? ' (some requests failed)' : '');
  if (!allDone) {
    tooltipTitle = `${countPendingRequests} requests pending`;
  }

  const smallButton = <>
    <Tooltip placement="right" title={tooltipTitle}>
      <IconComp aria-label="Remote progress indicator" aria-describedby={id} ref={(ref) => {
        setPopperAnchor(ref);
      }} sx={{
        color: hasAnyErrors ? '#d70000' : undefined,
        margin: '2px 2px',
        fontSize: '16px',
      }} />
    </Tooltip>
    <ArrowPopper id={id} open={show} placement="top" anchorEl={popperAnchor} sx={{
      background: 'white',
      // Cannot make this over 10000
      // otherwise tooltip explainer on the elements
      // itself gets hidden
      zIndex: 1000,
      maxWidth: '80%',
    }} modifiers={[
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 10],
        },
      },
    ]}
    >
      <ClickAwayListener onClickAway={() => {
        if (show) {
          setShow(false);
        }
      }}>
        {/* Cannot use Fade transition with ArrowPopper due to direct child requirement */}
        {itemProgresses}
      </ClickAwayListener>
    </ArrowPopper>
  </>;


  // Design inspired from Chrome status bar on bottom left
  // It shows up when navigating/hovering on links
  const statusBar = <Box data-testid="remote-status-container" sx={{
    // fix the position right above the controls panel
    position: 'absolute',
    bottom: '100%',
    left: 0,
    // colors
    background: 'rgb(241 241 241)',
    color: '#838383',
    borderTopRightRadius: '5px',
    padding: '2px 6px 2px 4px',
    boxShadow: '0.25px -0.25px 0.5px black',
    width: 'fit-content',
    maxWidth: 'min(360px, 95%)',
    // general styling
    cursor: 'pointer',
    // layout styling
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    ...containerStyle,
  }} onClick={() => {
    if (!show) {
      setShow(true);
    }
  }}>
    {smallButton}
    {displayStatus && <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <T variant="caption" data-testid="remote-status-bar" sx={{
        // vertical align text
        lineHeight: null,
        // space from icon
        marginLeft: '3px',
        // size styling
        fontSize: '11px',
        // darker text color
        color: '#444',
      }}>
        {displayStatus}
      </T>
    </Box>}
  </Box>;

  return statusBar;
}

/**
 * @param {object} props
 * @param {import("../../snippet_processor/ParseNode").default} props.dom
 * @param {import('../../snippet_processor/DownstreamProcess').OnFormAcceptFn=} props.onAccept
 * @param {import('../../snippet_processor/DownstreamProcess').OnFormRejectFn=} props.onReject
 * @param {boolean=} props.isPro
 * @param {boolean=} props.isOrg
 * @param {number=} props.maxFreeProSnippets
 * @param {'form'|'chat-preview'|Function} [props.controls]
 * @param {boolean} props.countUsage
 * @param {boolean} [props.isUsingProFeatures]
 * @param {string=} props.message - optional message to show in the submit bar area
 * @param {import('../../snippet_processor/DataContainer').Config=} props.config
 * @param {object=} props.initialData
 * @param {Function=} props.domGeneratedFn - optional function called when the dom is first generated
 * @param {Function=} props.domUpdatedFn - optional function called when the dom is updated
 * @param {Function=} props.domRefreshFn - optional function called when the new dom is received
 * @param {Function=} props.connectedConfigureFn
 * @param {Function=} props.addonConfigureFn
 * @param {(fn: Function) => void} [props.onReRender]
 * @param {object} [props.formSubmitRef]
 * @param {(items: RemoteProgressItemType[]) => void} [props.onRemoteStatusUpdate]
 * @param {number} [props.usageProSnippets]
 */
function FormRendererBase(props) {
  let isMounted = useIsMounted();
  let [attemptedInsert, setAttemptedInsert] = useState(false);
  let [displayError, setDisplayError] = useState(null);
  let [displayRestoreMsg, setDisplayRestoreMsg] = useState(/** @type {{ isVisible: boolean, canInsert?: boolean }} */ ({ isVisible: false }));
  let [displayRerender, setDisplayRerender] = useState({ isVisible: false });
  let rerenderData = useRef(/** @type {{ groupKey: string, tabId: number, res: object }}} */ (null));
  // In case of docs site, we can multiple form renders on the same page
  // Each form needs to have its own snackbar, instead of sharing a global
  // snackbar with other renders. This way each render can notify independently
  const thisFormSnackbar = useRef(/** @type import('../Dialog/MessageDisplayInner').ToastRefType} */ (null));

  let [addonStatuses, setAddonStatuses] = useState({});
  let addonStatusesCurrent = useRef(null);
  addonStatusesCurrent.current = addonStatuses;

  let domLoadTime = useRef(0);
  let onChangeTimer = useRef(null);
  let lastSuccesfulRender = useRef(Date.now());
  let onChangeCount = useRef(0);

  let domCurrent = useRef(props.dom);
  domCurrent.current = props.dom;

  let [domData, setDomData] = useState(/** @type {{ dom: import('../../snippet_processor/ParseNode').default, env: Environment, localData: Environment }} */ (null));
  let domDataCurrent = useRef(/** @type {typeof domData} */ (null));
  domDataCurrent.current = domData;
  let isFormDirty = useRef(false);
  let hasJustSubmitted = useRef(false);
  const [isSubmissionPending, setSubmissionPending] = useState(false);


  let formRef = useRef(null);
  let hasAutoFocused = useRef(false);

  let CACHED_DEP_DATA = useRef(new Map());

  let limitsRef = useRef();
  
  const remoteProgressItems = useRef(/** @type {RemoteProgressItemType[]} */ ([]));
  const [remoteItemsState, setRemoteItemsState] = useState(/** @type {RemoteProgressItemType[]} */ ([]));

  useOnMount(() => {
    // If we're showing the regular controls, we want the
    // enter key to always insert if nothing is focused.
    if (props.controls === 'form' || props.controls === 'chat-preview') {
      let fn = (/** @type {KeyboardEvent} */ e) => {
        if (document.activeElement === document.body) {
          if (e.key === 'Enter') {
            onAccept();
            e.preventDefault();
            e.stopPropagation();
          }
        }
      };
      window.addEventListener('keypress', fn);
      return () => window.removeEventListener('keypress', fn);
    }
  });

  useImperativeHandle(props.formSubmitRef || React.createRef(), () => ({
    submitForm: onAccept
  }));

  const onRemoteStatusUpdate = props.onRemoteStatusUpdate;
  const onRemoteUpdate = useCallback((/** @type {RemoteProgressItemType} */ update) => {
    let found = false;
    for (const item of remoteProgressItems.current) {
      if (item.id === update.id) {
        item.status = update.status;
        if (update.data) {
          item.data = update.data;
        }
        if (update.responseCode) {
          item.responseCode = update.responseCode;
        }
        found = true;
        break;
      }
    }
    if (!found) {
      remoteProgressItems.current.push(update);
    }
    setRemoteItemsState([...remoteProgressItems.current]);
    onRemoteStatusUpdate?.([...remoteProgressItems.current]);
  }, [onRemoteStatusUpdate]);

  let isLoadingDomRef = useRef(false);
  let needsLoadDomRef = useRef(null);

  /**
   * If allowDelay is true, we'll debounce for 5ms before
   * rerendering to allow batching changes. This is useful
   * for {formmenu}'s without defaults.
   * 
   * If it's not set we will try to smartly debounce
   * "heavy" renders that take longer than [maxDomLoadTime] 
   * and occur too frequently, by limiting them to 
   * [maxUnbouncedRenders] renders per [autobounceWindow] timeframe.
   * 
   * Any render above the ratio will be debounced, up to maximum 
   * [maxDelay] timeframe (to guarantee minimum render rate).
   */
  let onChange = useCallback((allowDelay = false, isUserInput = false) => {
    if (isLoadingDomRef.current) {
      needsLoadDomRef.current = { isUserInput: needsLoadDomRef.current?.isUserInput || isUserInput };
      return;
    }


    needsLoadDomRef.current = null;

    if (!domDataCurrent.current) {
      // This happens when nested remote commands trigger
      // on document load. This can almost never happen in
      // practice because of network delays, but this happens
      // always in the Jest tests.
      return;
    }
    isLoadingDomRef.current = true;
    if (isUserInput) {
      isFormDirty.current = true;
      // For now, this is always true. But we might support
      // undoing form dirty state down the line
      props.config.onFormDirty?.(true);
    }
    let now = Date.now();
    let delay = 5, maxDelay = 3000, maxDomLoadTime = 50; //ms
    let maxUnbouncedRenders = 3, autobounceWindow = 500, debounceDelay = 300;

    // Debounce on "heavy" dom load only
    if (domLoadTime.current > maxDomLoadTime) {
      if (now < lastSuccesfulRender.current + autobounceWindow) {
        ++onChangeCount.current;
        if (onChangeCount.current > maxUnbouncedRenders) {
          allowDelay = true;
          delay = debounceDelay;
        }
      } else {
        onChangeCount.current = 0;
      }
    }

    if (allowDelay) {
      debounceSmart(() => {
        if (isMounted.current) {
          loadDom(domCurrent.current, domDataCurrent.current.env);
        }
      }, delay, maxDelay);
    } else {
      clearDebounce();
      loadDom(props.dom, domDataCurrent.current.env);
      lastSuccesfulRender.current = now;
    }
    // eslint-disable-next-line
  }, [props.dom]);

  if (props.config) {
    // We attach the callbacks to props.config, instead of env.config
    // This is because we construct a new environment each time
    // in loadDom. This env is based on props.config, so it will inherit
    // changes. The domData.current.env is based on this new Environment
    // that gets constructed in loadDom
    props.config.callbacks = { onRemoteUpdate, onChange };
  }

  /** @type {import('./SharedComponents').ActionContextType['showToast']} */
  const showToast = useCallback((msg, config) => {
    const id = thisFormSnackbar.current.show(msg, {
      duration: 6000,
      intent: 'info',
      ...config,
    });
    return () => {
      thisFormSnackbar.current.remove(id);
    };
  }, []);

  // initial load
  useEffect(() => {
    (async () => {
      const dom = props.dom;
      const oldEnv = domDataCurrent.current?.env;
      const getInitialData = () => props.initialData ? Object.assign({}, props.initialData) : {};
      const getInitialConfig = () => Object.assign(/** @type {import('../../snippet_processor/DataContainer').Config} */ ({
        remoteStore: new RemoteStore(props.config),
        sqlStore: new SQLStore(props.config),
      }), props.config, /** @type {import('../../snippet_processor/DataContainer').Config} */ ({
        showNotification: ({ title, message }) => {
          showToast(title ? `${title}: ${message}` : message, {
            intent: 'info',
            snackbarStyle: {
              // make the content to the left/right of the snackbar clickable
              margin: '0 auto',
              width: 'fit-content',
              // for community/docs, we want to show the toast
              // in the preview section, not at the top of the page
              ...(isCommunityDocsWhitelist() ? {
                position: 'absolute',
              } : {}),
            }
          });
        },
      }));
      const env = oldEnv || new Environment(getInitialData(), getInitialConfig());

      if (props.domRefreshFn) {
        await props.domRefreshFn(dom, env);
      }

      loadDom(dom, env);
    })();
    // eslint-disable-next-line
  }, [props.dom]);


  // remove the mutation observer on unmount
  useOnMount(null, () => {
    if (formRef.current && formRef.current.observer) {
      formRef.current.observer.disconnect();
    }
  });
  const onBeforeUnloadHandler = useCallback((event) => {
    if (isFormDirty.current) {
      event.preventDefault();
      // This return value is not displayed by any browser (including Chrome)
      // but it still needs to be set to a non-empty value for the 
      // confirmation dialog box to be visible.
      event.returnValue = 'Are you sure?';
      return event.returnValue;
    }
    return false;
  }, []);

  useOnMount(() => {
    const removeListener = electron()?.attachMessageListener((event, data) => {
      if (data?.type === 'form-restore') {
        hasJustSubmitted.current = false;
        setSubmissionPending(false);
        setDisplayRestoreMsg({ isVisible: true });
      }
    });

    if (props.config?.application === 'EXTENSION') {
      // Only enable in extension pages
      // Don't enable in - for example - dashboard preview or the Electron app
      window.addEventListener('beforeunload', onBeforeUnloadHandler);
    }
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnloadHandler);
      if (typeof removeListener === 'function') {
        removeListener();
      }
    };
  });

  /**
   * @param {import("../../snippet_processor/ParseNode").default} dom
   * @param {Environment} env
   */
  async function loadDom(dom, env) {
    if (dom) {
      const t0 = performance.now();

      await env.data.ready;


      let d = await markupDomPreview(trimDom(await structureDom(dom.clone(true, true, true), env)), env);
      let derivedData = await env.derivedData(env.config.store[d.localData]);

      if (!derivedData.locations || !derivedData.locations.includes('local_data - ' + d.localData)) {
        derivedData = derivedData.derivedLocation('local_data - ' + d.localData);
      }
      if (props.dom === dom && isMounted.current) {
        let noDom = !domData;
        // We take a snapshot of the data as it may mutate during rendering leading
        // to breakage (especially if the data gets cached by the snippetRenderer
        // equality function when different data was used in structureDom).
        //
        // Note the test case for this fails to actually catch it in jsdom. So manually
        // check the following before modifying:
        //
        // `{formmenu: name=n; 1;2;3} {repeat: n}x{endrepeat}`
        env.config.storeSnapshots = {};
        for (let d in env.config.store) {
          if (d === '_cache') {
            env.config.storeSnapshots[d] = Object.assign({}, env.config.storeSnapshots[d]);
          } else {
            env.config.storeSnapshots[d] = env.config.store[d].collapseData();
          }
        }
        setDomData({
          dom: d,
          env,
          localData: derivedData,
        });
        if (noDom && props.domGeneratedFn) {
          props.domGeneratedFn();
        }
        if (props.domUpdatedFn) {
          props.domUpdatedFn(dom, env);
        }
      }
      domLoadTime.current = performance.now() - t0;

      if (isExtension()) {
        // Keep the background page alive every time the form is updated
        // This is to avoid lag when submitting the form after inputting into
        // it for several minutes straight (without switching any tabs)
        window.parent.postMessage({ type: 'background', msg: { request: 'formUpdated' }, }, '*');
      }

      isLoadingDomRef.current = false;
      if (needsLoadDomRef.current) {
        let isUserInput = needsLoadDomRef.current.isUserInput;
        needsLoadDomRef.current = null;
      
        onChange(false, isUserInput);
      }
    }
  }


  /**
   * When in the extension, whether we can insert this snippet or not.
   * 
   * @return {boolean}
   */
  let allowed = useCallback(() => {
    return props.isPro
      || (!props.maxFreeProSnippets)
      || (props.usageProSnippets < props.maxFreeProSnippets);
  }, [props.isPro, props.maxFreeProSnippets, props.usageProSnippets]);


  let onReject = () => {
    window.removeEventListener('beforeunload', onBeforeUnloadHandler);
    props.onReject();
  };

  let onAccept = useCallback(async (/** @type {import('../../snippet_processor/DownstreamProcess').FormRestoreConfig} */ config = {}) => {
    if (hasJustSubmitted.current) {
      // Don't submit again if it was already submitted
      return;
    }
    if (props.onAccept) {
      if (!allowed()) {
        return;
      }
      if (!domDataCurrent.current) {
        // If a large snippet takes too long to load, and the user presses Enter
        // during that time, the domData.current is null, and we bail
        return;
      }

      if (props.countUsage && !(props.isPro || props.isOrg)) {
        // not a hook
        // eslint-disable-next-line react-hooks/rules-of-hooks
        await useFeature('pro_snippets');
      }

      let errors = domDataCurrent.current.dom.findAll(x => x.tag === 'alert');
      errors = errors.filter(err => err.info.attributes.keys.block);
      let blocking = await Promise.all(errors.map(async err => await err.info.attributes.keys.block.value(domDataCurrent.current.env)));
      errors = errors.filter((_err, i) => blocking[i]);
      if (errors.length) {
        (domDataCurrent.current.env.config.blockingErrorFn || ((error) => setDisplayError(error)))(
          await errors[0].info.attributes.position[0].value(domDataCurrent.current.env)
        );
        setAttemptedInsert(true);
      } else {
        // Now we are going to close the form window,
        // and submitting without fail

        window.removeEventListener('beforeunload', onBeforeUnloadHandler);
        hasJustSubmitted.current = true;

        // Disable the button after 100ms to avoid
        // any flashes in the simple form submission case
        setTimeout(() => {
          if (hasJustSubmitted.current) {
            setSubmissionPending(true);
          }
        }, 100);

        return props.onAccept(domDataCurrent.current.env.config.store, config);
      }
    }
    // props won't change
    // eslint-disable-next-line
  }, [allowed, props.onAccept]);


  let isXSmall = useIsXSmall();

  /**
   * @param {string} title 
   * @param {JSX.Element} contents 
   * @param {(...args) => void} onClose
   * @param {boolean} open
   * @param {JSX.Element[]} actionButtons
   * @returns 
   */
  let renderDialog = (title, contents, onClose, open, actionButtons) => {
    return <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}> <T>{title}</T>
          <IconButton
            style={{
              position: 'absolute',
              top: 8,
              right: 12,
              opacity: .7
            }}
            title="Dismiss"
            onClick={onClose}
          ><CloseIcon fontSize="small" /></IconButton>
        </DialogTitle>
        <DialogContent>{contents}</DialogContent>
        <DialogActions style={{ padding: 16 }}>
          <div style={{ flex: 1 }} />
          {actionButtons}
        </DialogActions>
      </Dialog>
    </>;
  };

  function triggerReRender() {
    const data = rerenderData.current;
    props.onReRender((/** @type {import('../../snippet_processor/DataContainer').Config} */ config) => {
      config.usedSiteTabSelections[data.groupKey] = { tabId: data.tabId, res: data.res };
    });
  }

  let renderRerenderDialog = () => {
    function onClose() {
      setDisplayRerender({ isVisible: false });
      rerenderData.current = null;
    }
    const actionButtons = [<Button key="cancel" onClick={onClose}>Cancel</Button>, <Button key="close" variant="contained" data-test-id="form-rerender-button" onClick={() => {
      triggerReRender();
    }} autoFocus>Switch tab</Button>];
    return renderDialog('Switch tabs?', <T>When you switch tabs, this form will reset your changes</T>, onClose, !!displayRerender.isVisible, actionButtons);
  };

  let renderErrorDialog = () => {
    function onClose() {
      setDisplayError(null);
    }
    const contents = <span className="blaze-error" style={{ fontSize: '18px' }}>
      <svg viewBox="0 0 24 24" style={{ display: 'inline-block', color: 'rgba(0, 0, 0, 0.87)', fill: 'currentcolor', height: '30px', width: '30px', userSelect: 'none' }}>{blockingImg}</svg> {displayError}
    </span>;
    const title = 'Please fix this error before inserting';
    const actionButtons = [<Button key="close" onClick={onClose}>Close</Button>];
    return renderDialog(title, contents, onClose, !!displayError, actionButtons);
  };

  let renderRestoreDialog = () => {
    function onClose() {
      setDisplayRestoreMsg({ isVisible: false });
    }
    function onInsertAnyway() {
      onClose();
      onAccept({ skipElementChecks: true });
    }
    function onClipboardCopy() {
      onClose();
      if (isElectronApp()) {
        getDataFromClient({
          type: 'restore-form-mechanism'
        }).then((data) => {
          if (data === 'yes') {
            sendMessageToClient({
              type: 'copy-last-form-data'
            });
          } else {
            // TODO: Backward compatible change. Remove when all users have migrated to >=v1.3.2
            onAccept({ onlyClipboardCopy: true });
          }
        });
      } else {
        onAccept({ onlyClipboardCopy: true });
      }

    }
    const contents = <T variant="body2" data-testid="form-restored-msg">We were not able to insert the form snippet.<br />You can copy/paste and insert the snippet yourself.</T>;
    const title = 'Could not insert form snippet';
    const actionButtons = [<Button key="clipboard-copy" data-testid="copy-clipboard" onClick={onClipboardCopy}>Copy to clipboard</Button>];
    if (displayRestoreMsg.canInsert) {
      actionButtons.unshift(<Button key="insert-anyway" onClick={onInsertAnyway} data-testid="insert-anyway">Insert into focused textbox</Button>);
    }

    return renderDialog(title, contents, onClose, displayRestoreMsg.isVisible, actionButtons);
  };

  let controls = () => {
    if (props.controls) {
      /** @type {any} */
      let limitsMessage = '';
      if (props.controls === 'form' && props.isUsingProFeatures) {
        if (props.message || (!props.isPro && props.maxFreeProSnippets)) {
          limitsMessage = <div
            style={{
              fontSize: '90%',
              padding: 12
            }}
          >
            {props.message || <>You have used <b>{Math.min(props.maxFreeProSnippets, props.usageProSnippets)}/{props.maxFreeProSnippets}</b> of your daily trial of Pro features. Unlock <a href="https://dashboard.blaze.today/upgrade" target="_blank" rel="noopener noreferrer">Text Blaze Pro</a> for unlimited forms.</>}
          </div>;
        }
      }

      return (<div style={{
        width: '100%',
        zIndex: 100,
        flexShrink: 0,
        position: 'relative',
      }}>
        <RemoteBottomStatusBar items={remoteItemsState} />
        {props.controls === 'form' && tabSelectionComponent}
        {props.controls !== 'chat-preview' && <Paper
          style={{
            padding: 20,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '10px',
          }}
          elevation={2}
          className="form__bottom-controls"
          data-test-id="bottom-controls-form"
        >
          {props.controls === 'form' ? <>
            {(limitsMessage && limitsRef.current && isXSmall) && <LimitsPopper
              target={limitsRef.current}
            >
              {limitsMessage}
            </LimitsPopper>}
            <div
              style={{
                verticalAlign: 'middle'
              }}
              ref={limitsRef}
            >
              <ProChipBase
                zoom={0.9}
                isActive={props.isPro || props.isOrg}
                label={props.isOrg ? 'business' : 'pro'}
                noLabel={isXSmall}
              />
            </div>
            <div
              style={{
                verticalAlign: 'middle',
                flex: 1,
                minWidth: 20
              }}
            >
              {(limitsMessage && !isXSmall) && <div style={{
                color: '#666',
                verticalAlign: 'middle',
                paddingLeft: 10,
                paddingRight: 40
              }}>{limitsMessage}</div>}
            </div>
            <Box
              sx={{
                whiteSpace: 'nowrap'
              }}
            >
              <Button
                disabled={isSubmissionPending}
                onClick={() => onReject()}
              >Cancel</Button>
              <Button
                variant="contained"
                style={{ marginLeft: 20 }}
                disabled={isSubmissionPending || !allowed()}
                color="primary"
                onClick={() => onAccept()}
              >Insert</Button>
            </Box>
          </> : props.controls({
            onAccept
          })}
        </Paper>}
      </div>);
    }
    return null;
  };


  let quickEntryPanel = (items, env) => {
    if (env.config.quickentry) {
      let fields = null;
      if (items.length) {
        fields = <QuickEntryPanel
          env={env}
          items={items}

        />;
      }
      return fields;
    }
  };

  let clearDebounce = () => {
    clearTimeout(onChangeTimer.current);
  };
  let debounce = (callback, delay = 5) => {
    clearDebounce();
    onChangeTimer.current = setTimeout(() => {
      callback();
      onChangeTimer.current = null;
    }, delay);
  };

  let debounceSmart = (callback, delay = 5, maxDelay = 1000) => {
    let now = Date.now();
    if (onChangeTimer.current && now > lastSuccesfulRender.current + maxDelay) { // if more than max debounce window
      clearDebounce(); // clear any hanging debounce
      callback(); // force callback
      lastSuccesfulRender.current = now;
    } else {
      debounce(() => {
        callback();
        lastSuccesfulRender.current = now;
      }, delay);
    }
  };


  let actionContextData = useMemo(() => ({
    onSubmit: onAccept,
    onChange: onChange,
    connectedConfigureFn: props.connectedConfigureFn,
    addonConfigureFn: props.addonConfigureFn,
    showToast,
    onRemoteUpdate,
  }), [onAccept, onChange, props.connectedConfigureFn, props.addonConfigureFn, onRemoteUpdate, showToast]);

  let inner = null;
  if (domData) {
    let env = domData.env;
    /** @type {import("../../snippet_processor/ParseNode").default} */
    let dom = domData.dom;
    let localData = domData.localData;

    env.config.state = {
      CACHED_DEP_DATA: CACHED_DEP_DATA.current
    };

    inner = <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      minHeight: '100%',
    }} className="form-container">
      <ActionContext.Provider value={actionContextData}>
        {quickEntryPanel(env.config.quickItems, localData)}

        <div className="form-sandbox" style={{ padding: 20, flex: 1 }}>
          <AddonStatusContext.Provider value={addonStatuses}>
            <AttemptedInsertContext.Provider value={attemptedInsert}>
              <SnippetRenderer
                dom={dom}
                env={env}
              />
            </AttemptedInsertContext.Provider>
          </AddonStatusContext.Provider>
          {renderErrorDialog()}
          {renderRestoreDialog()}
          {renderRerenderDialog()}
        </div>
      </ActionContext.Provider>
    </div>;
  }

  const isFormWindow = props.controls === 'form';
  const tabSelectionComponent = props.config.needsTabSelectInSiteCommand && <Paper data-test-id="site-tab-selector" elevation={2} style={{
    position: 'relative',
    zIndex: 10,
    boxShadow: 'none',
    borderBottom:  'none',
  }}>
    <Box
      sx={{
        whiteSpace: 'nowrap'
      }}
    >
      <SitePageSelectorMemo items={props.config.usedSiteSelectors} allData={props.config.usedSiteSelectorData} selectedTabs={props.config.usedSiteTabSelections} onMatchSelect={({ groupKey, tabId, res }) => {
        rerenderData.current = { groupKey, tabId, res };
        if (isFormDirty.current) {
          setDisplayRerender({ isVisible: true });
        } else {
          triggerReRender();
        }
      }} />
    </Box>
  </Paper>;

  return <>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: isFormWindow ? '100vh' : undefined,
      maxHeight: isFormWindow ? '100vh' : undefined,
      // align both children at either ends
      justifyContent: 'space-between',
    }}>
      <div data-test-id="form-scroll-container" style={{
        overflowY: 'auto',
        height: '100%',
      }} ref={(node) => {
        if (!node) {
          // Will be called with null
          // https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
          return;
        }
        if (formRef.current) {
          if (formRef.current.node === node) {
            return;
          }
          formRef.current.node = node;
        } else {
          formRef.current = {
            node: node,
            observer: new MutationObserver((_mutationList) => {
              if (!hasAutoFocused.current && !domDataCurrent.current.env.config.noAutoFocus) {
                const input = formRef.current.node.querySelector('textarea, input, select, [tabindex]');
                // dbselect menu first produces an unusable input node that should be ignored
                const isValidInput = input && !input.disabled;
                if (isValidInput) {
                  hasAutoFocused.current = true;
                  input.focus();
                }
              }

              let newStatus = {};
              let errors = formRef.current.node.querySelectorAll('.blaze-error[data-storeid]');
              for (let error of errors) {
                newStatus[error.getAttribute('data-storeid')] = {
                  error: error.getAttribute('data-error')
                };
              }

              let loaders = formRef.current.node.querySelectorAll('.blaze-loading[data-storeid]');
              for (let loader of loaders) {
                let id = loader.getAttribute('data-storeid');
                if (!newStatus[id]) {
                  newStatus[id] = {};
                }
                newStatus[id].loading = true;
              }

              if (!equals(newStatus, addonStatusesCurrent.current)) {
                setAddonStatuses(newStatus);
              }
            })
          };
        }

        formRef.current.observer.observe(node, {
          subtree: true,
          childList: true,
          attributes: true,
          attributeFilter: ['data-storeid']
        });
      }}>{inner}</div>
      {controls()}
      {props.controls !== 'form' && tabSelectionComponent}
      <MessageDisplayInner ref={(comp) => {
        // Don't override the pre-existing snackbar for dashboard pages
        thisFormSnackbar.current = comp;
      }} />
    </div>
  </>;
}


const FormRenderer = React.memo(FormRendererBase);
export default FormRenderer;

const DEFAULT_QUICK_ENTRY_SIZE = 160;
const QUICK_ENTRY_SIZE_KEY = 'snippetQuickEntrySize';



/**
 * 
 * @param {object} props 
 * @param {Environment} props.env 
 * @param {object[]} props.items 
 * @returns 
 */
const QuickEntryPanel = ({
  env,
  items
}) => {

  let [open, setOpen] = useState(true);
  
  let [sidebarSize, setSidebarSize] = useState(DEFAULT_QUICK_ENTRY_SIZE);
  useOnMount(async () => {
    const sizeFromStore = await getPreferencesStore().getItem(QUICK_ENTRY_SIZE_KEY);
    if (sizeFromStore) {
      // In case of NaN
      setSidebarSize(parseInt(sizeFromStore) || DEFAULT_QUICK_ENTRY_SIZE);
    }
  });
  const onResize = (/** @type {number} */ size) => {
    getPreferencesStore().setItem(QUICK_ENTRY_SIZE_KEY, size?.toString());
    setSidebarSize(size || DEFAULT_QUICK_ENTRY_SIZE);
  };
  return (
    <Paper
      className="form-sidebar"
      elevation={1}
      style={{
        boxShadow: 'inset rgba(0, 0, 0, 0.1) -3px 0px 2px 0px',
        borderRadius: 0,
        width: open ? sidebarSize : undefined,
        position: 'relative',
        // To handle resize of form window to smaller size after resized by handle.
        maxWidth: '95%'
      }}
    >
      {open ? <>
        <div style={{
          padding: 10,
          userSelect: 'none'
        }}>
          {items.map((x, i) => (
            <div key={i} id={'quick_entry_' + x.node.info.formInfo.name}>
              <style>
                {`.form-container:not(:has(#field_renderer_${nameToSelector(x.node.info.formInfo.name)})) #quick_entry_${nameToSelector(x.node.info.formInfo.name)} {`}
                display: none;
                {'}'}
              </style>
              <T variant="subtitle2" style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
                fontWeight: 'bold'
              }}><label htmlFor={'form_table_' + i}>{x.label || '(no name)'}</label></T>
              <div style={{ marginBottom: 10 }}>
                <FieldRenderer
                  small
                  node={x.node}
                  env={env}
                  id={'form_table_' + i}
                />
              </div>
            </div>
          ))}
        </div>
        <Divider />
      </> : null}

      <div style={{
        padding: 5,
        textAlign: 'right'
      }}>
        <IconButton onClick={() => setOpen(!open)} size="large">
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <SidebarResizer
        align="right"
        parentSelector=".form-container"
        offsetX={-16}
        minSize={100}
        maxSizeOff={100}
        size={sidebarSize}
        onResize={onResize}
      />
    </Paper>
  );
};