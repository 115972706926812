import React from 'react';
import { createRoot } from 'react-dom/client';

/**
 * Renders the App
 * @param {() => React.ReactElement} App 
 */
const render = (App) => {
  const root = createRoot(
    document.getElementById('root')
  );
  
  root.render(<App />);
};

export default render;