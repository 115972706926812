/* istanbul ignore file */
import { formatDefaultLocale } from 'd3-format';
import moment from 'moment';


let definedLocales = {};

/**
 * @param {string} name
 * @param {object} config
 */
function defineLocale(name, config) {
  if (!definedLocales[name]) {
    let oldLocale = moment.locale();
    definedLocales[name] = true;
    moment.defineLocale(name, config);
    moment.locale(oldLocale);
  }
}

let spanishDate = () => {
  let monthsShortDot = 'ene._feb._mar._abr._may._jun._jul._ago._sep._oct._nov._dic.'.split('_'),
    monthsShort = 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic'.split('_');

  let monthsParse = [/^ene/i, /^feb/i, /^mar/i, /^abr/i, /^may/i, /^jun/i, /^jul/i, /^ago/i, /^sep/i, /^oct/i, /^nov/i, /^dic/i];
  let monthsRegex = /^(enero|febrero|marzo|abril|mayo|junio|julio|agosto|septiembre|octubre|noviembre|diciembre|ene\.?|feb\.?|mar\.?|abr\.?|may\.?|jun\.?|jul\.?|ago\.?|sep\.?|oct\.?|nov\.?|dic\.?)/i;

  defineLocale('es', {
    months : 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
    monthsShort : function (m, format) {
      if (!m) {
        return monthsShortDot;
      } else if (/-MMM-/.test(format)) {
        return monthsShort[m.month()];
      } else {
        return monthsShortDot[m.month()];
      }
    },
    monthsRegex : monthsRegex,
    monthsShortRegex : monthsRegex,
    monthsStrictRegex : /^(enero|febrero|marzo|abril|mayo|junio|julio|agosto|septiembre|octubre|noviembre|diciembre)/i,
    monthsShortStrictRegex : /^(ene\.?|feb\.?|mar\.?|abr\.?|may\.?|jun\.?|jul\.?|ago\.?|sep\.?|oct\.?|nov\.?|dic\.?)/i,
    monthsParse : monthsParse,
    longMonthsParse : monthsParse,
    shortMonthsParse : monthsParse,
    weekdays : 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
    weekdaysShort : 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
    weekdaysMin : 'do_lu_ma_mi_ju_vi_sá'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
      LT : 'H:mm',
      LTS : 'H:mm:ss',
      L : 'DD/MM/YYYY',
      LL : 'D [de] MMMM [de] YYYY',
      LLL : 'D [de] MMMM [de] YYYY H:mm',
      LLLL : 'dddd, D [de] MMMM [de] YYYY H:mm'
    },
    calendar : {
      sameDay : function () {
        return '[hoy a la' + ((this.hours() !== 1) ? 's' : '') + '] LT';
      },
      nextDay : function () {
        return '[mañana a la' + ((this.hours() !== 1) ? 's' : '') + '] LT';
      },
      nextWeek : function () {
        return 'dddd [a la' + ((this.hours() !== 1) ? 's' : '') + '] LT';
      },
      lastDay : function () {
        return '[ayer a la' + ((this.hours() !== 1) ? 's' : '') + '] LT';
      },
      lastWeek : function () {
        return '[el] dddd [pasado a la' + ((this.hours() !== 1) ? 's' : '') + '] LT';
      },
      sameElse : 'L'
    },
    relativeTime : {
      future : 'en %s',
      past : 'hace %s',
      s : 'unos segundos',
      ss : '%d segundos',
      m : 'un minuto',
      mm : '%d minutos',
      h : 'una hora',
      hh : '%d horas',
      d : 'un día',
      dd : '%d días',
      M : 'un mes',
      MM : '%d meses',
      y : 'un año',
      yy : '%d años'
    },
    dayOfMonthOrdinalParse : /\d{1,2}º/,
    ordinal : '%dº',
    week : {
      dow : 1, // Monday is the first day of the week.
      doy : 4  // The week that contains Jan 4th is the first week of the year.
    }
  });

  return 'es';
};


/**
 * @param {string} locale 
 * @returns 
 */
let gbAuDateTime = (locale) => () => {
  defineLocale(locale, {
    months : 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
    monthsShort : 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    weekdays : 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
    weekdaysShort : 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
    weekdaysMin : 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
    longDateFormat : {
      LT : locale.endsWith('-au') ? 'h:mm A' : 'HH:mm',
      LTS : locale.endsWith('-au') ? 'h:mm:ss A' : 'HH:mm:ss',
      L : 'DD/MM/YYYY',
      LL : 'D MMMM YYYY',
      LLL : locale.endsWith('-au') ? 'D MMMM YYYY h:mm A' : 'D MMMM YYYY HH:mm',
      LLLL : locale.endsWith('-au') ? 'dddd, D MMMM YYYY h:mm A' : 'dddd, D MMMM YYYY HH:mm'
    },
    calendar : {
      sameDay : '[Today at] LT',
      nextDay : '[Tomorrow at] LT',
      nextWeek : 'dddd [at] LT',
      lastDay : '[Yesterday at] LT',
      lastWeek : '[Last] dddd [at] LT',
      sameElse : 'L'
    },
    relativeTime : {
      future : 'in %s',
      past : '%s ago',
      s : 'a few seconds',
      ss : '%d seconds',
      m : 'a minute',
      mm : '%d minutes',
      h : 'an hour',
      hh : '%d hours',
      d : 'a day',
      dd : '%d days',
      M : 'a month',
      MM : '%d months',
      y : 'a year',
      yy : '%d years'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(st|nd|rd|th)/,
    ordinal : function (number) {
      let b = number % 10,
        output = (~~(number % 100 / 10) === 1) ? 'th' :
          (b === 1) ? 'st' :
            (b === 2) ? 'nd' :
              (b === 3) ? 'rd' : 'th';
      return number + output;
    },
    week : {
      dow : 1, // Monday is the first day of the week.
      doy : 4  // The week that contains Jan 4th is the first week of the year.
    }
  });
  return locale;
};


/**
 * @param {string} code
 */
export function setLocale(code) {
  let locale = getLocale((code || '').toLowerCase());

  formatDefaultLocale(locale.d3);

  moment.locale(locale.moment());
}


/**
 * @return {string[]}
 */
export function getLocales() {
  return Object.keys(LOCALES).sort();
}


/**
 * @param {string} code
 * @param {boolean} noDefault - if true return undefined when locale cannot be found
 * 
 * @return {object}
 */
export function getLocale(code, noDefault = false) {
  // Legacy accounts without a code
  if (!code) {
    return buildLocale(DEFAULT_LOCALE);
  }

  if (code === 'auto') {
    // On React Native navigator.languages is undefined
    // TODO: see if there is a way to get the locale for the user some other way.
    let codes = navigator.languages ? navigator.languages : 'en-US';
    for (let code of codes) {
      let locale = getLocale(code, true);
      if (locale) {
        return locale;
      }
    }
    return buildLocale(DEFAULT_LOCALE);
  }


  // Exact match
  if (code.length === 5) {
    if (LOCALES[code]) {
      return buildLocale(code);
    }
    code = code.slice(0, 2);
  }


  // Top level match
  code = code + '-' + code;
  if (LOCALES[code]) {
    return buildLocale(code);
  }


  // Top level alternative
  code = code.slice(0, 2);
  let options = getLocales().filter(x => x.startsWith(code));
  if (options.length) {
    let primary = options.find(x => LOCALES[x].primary);
    if (primary) {
      return buildLocale(primary);
    }
    return buildLocale(options[0]);
  }


  // Fallback to default
  if (!noDefault) {
    return buildLocale(DEFAULT_LOCALE);
  }
}


function buildLocale(code) {
  return Object.assign({
    code
  }, LOCALES[code]);
}


/**
 * @type {Record<string, { primary?: true, d3: { decimal: string, thousands: string, grouping: number[], currency: [string, string], numerals?: string[], percent?: string }, moment: () => string }>}
 */
const LOCALES = {
  'ar-ar': {
    d3: {
      'decimal': '\u066b',
      'thousands': '\u066c',
      'grouping': [3],
      'currency': ['', ''],
      'numerals' : ['\u0660', '\u0661', '\u0662', '\u0663', '\u0664', '\u0665', '\u0666', '\u0667', '\u0668', '\u0669']
    },
    moment: () => {
      let symbolMap = {
          '1': '١',
          '2': '٢',
          '3': '٣',
          '4': '٤',
          '5': '٥',
          '6': '٦',
          '7': '٧',
          '8': '٨',
          '9': '٩',
          '0': '٠'
        }, numberMap = {
          '١': '1',
          '٢': '2',
          '٣': '3',
          '٤': '4',
          '٥': '5',
          '٦': '6',
          '٧': '7',
          '٨': '8',
          '٩': '9',
          '٠': '0'
        }, pluralForm = function (n) {
          return n === 0 ? 0 : n === 1 ? 1 : n === 2 ? 2 : n % 100 >= 3 && n % 100 <= 10 ? 3 : n % 100 >= 11 ? 4 : 5;
        }, plurals = {
          s : ['أقل من ثانية', 'ثانية واحدة', ['ثانيتان', 'ثانيتين'], '%d ثوان', '%d ثانية', '%d ثانية'],
          m : ['أقل من دقيقة', 'دقيقة واحدة', ['دقيقتان', 'دقيقتين'], '%d دقائق', '%d دقيقة', '%d دقيقة'],
          h : ['أقل من ساعة', 'ساعة واحدة', ['ساعتان', 'ساعتين'], '%d ساعات', '%d ساعة', '%d ساعة'],
          d : ['أقل من يوم', 'يوم واحد', ['يومان', 'يومين'], '%d أيام', '%d يومًا', '%d يوم'],
          M : ['أقل من شهر', 'شهر واحد', ['شهران', 'شهرين'], '%d أشهر', '%d شهرا', '%d شهر'],
          y : ['أقل من عام', 'عام واحد', ['عامان', 'عامين'], '%d أعوام', '%d عامًا', '%d عام']
        }, pluralize = function (u) {
          return function (number, withoutSuffix, string, isFuture) {
            let f = pluralForm(number),
              str = plurals[u][pluralForm(number)];
            if (f === 2) {
              str = str[withoutSuffix ? 0 : 1];
            }
            return str.replace(/%d/i, number);
          };
        }, months = [
          'يناير',
          'فبراير',
          'مارس',
          'أبريل',
          'مايو',
          'يونيو',
          'يوليو',
          'أغسطس',
          'سبتمبر',
          'أكتوبر',
          'نوفمبر',
          'ديسمبر'
        ];

      defineLocale('ar', {
        months : months,
        monthsShort : months,
        weekdays : 'الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت'.split('_'),
        weekdaysShort : 'أحد_إثنين_ثلاثاء_أربعاء_خميس_جمعة_سبت'.split('_'),
        weekdaysMin : 'ح_ن_ث_ر_خ_ج_س'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
          LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'D/\u200FM/\u200FYYYY',
          LL : 'D MMMM YYYY',
          LLL : 'D MMMM YYYY HH:mm',
          LLLL : 'dddd D MMMM YYYY HH:mm'
        },
        meridiemParse: /ص|م/,
        isPM : function (input) {
          return 'م' === input;
        },
        meridiem : function (hour, minute, isLower) {
          if (hour < 12) {
            return 'ص';
          } else {
            return 'م';
          }
        },
        calendar : {
          sameDay: '[اليوم عند الساعة] LT',
          nextDay: '[غدًا عند الساعة] LT',
          nextWeek: 'dddd [عند الساعة] LT',
          lastDay: '[أمس عند الساعة] LT',
          lastWeek: 'dddd [عند الساعة] LT',
          sameElse: 'L'
        },
        relativeTime : {
          future : 'بعد %s',
          past : 'منذ %s',
          s : pluralize('s'),
          ss : pluralize('s'),
          m : pluralize('m'),
          mm : pluralize('m'),
          h : pluralize('h'),
          hh : pluralize('h'),
          d : pluralize('d'),
          dd : pluralize('d'),
          M : pluralize('M'),
          MM : pluralize('M'),
          y : pluralize('y'),
          yy : pluralize('y')
        },
        preparse: function (string) {
          return string.replace(/[١٢٣٤٥٦٧٨٩٠]/g, function (match) {
            return numberMap[match];
          }).replace(/،/g, ',');
        },
        postformat: function (string) {
          return string.replace(/\d/g, function (match) {
            return symbolMap[match];
          }).replace(/,/g, '،');
        },
        week : {
          dow : 6, // Saturday is the first day of the week.
          doy : 12  // The week that contains Jan 1st is the first week of the year.
        }
      });
      return 'ar';
    }
  },
  'ca-ca': {
    d3: {
      'decimal': ',',
      'thousands': '.',
      'grouping': [3],
      'currency': ['', '\u00a0€']
    },
    moment: () => {
      defineLocale('ca', {
        months : {
          standalone: 'gener_febrer_març_abril_maig_juny_juliol_agost_setembre_octubre_novembre_desembre'.split('_'),
          format: 'de gener_de febrer_de març_d\'abril_de maig_de juny_de juliol_d\'agost_de setembre_d\'octubre_de novembre_de desembre'.split('_'),
          isFormat: /D[oD]?(\s)+MMMM/
        },
        monthsShort : 'gen._febr._març_abr._maig_juny_jul._ag._set._oct._nov._des.'.split('_'),
        monthsParseExact : true,
        weekdays : 'diumenge_dilluns_dimarts_dimecres_dijous_divendres_dissabte'.split('_'),
        weekdaysShort : 'dg._dl._dt._dc._dj._dv._ds.'.split('_'),
        weekdaysMin : 'dg_dl_dt_dc_dj_dv_ds'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
          LT : 'H:mm',
          LTS : 'H:mm:ss',
          L : 'DD/MM/YYYY',
          LL : 'D MMMM [de] YYYY',
          ll : 'D MMM YYYY',
          LLL : 'D MMMM [de] YYYY [a les] H:mm',
          lll : 'D MMM YYYY, H:mm',
          LLLL : 'dddd D MMMM [de] YYYY [a les] H:mm',
          llll : 'ddd D MMM YYYY, H:mm'
        },
        calendar : {
          sameDay : function () {
            return '[avui a ' + ((this.hours() !== 1) ? 'les' : 'la') + '] LT';
          },
          nextDay : function () {
            return '[demà a ' + ((this.hours() !== 1) ? 'les' : 'la') + '] LT';
          },
          nextWeek : function () {
            return 'dddd [a ' + ((this.hours() !== 1) ? 'les' : 'la') + '] LT';
          },
          lastDay : function () {
            return '[ahir a ' + ((this.hours() !== 1) ? 'les' : 'la') + '] LT';
          },
          lastWeek : function () {
            return '[el] dddd [passat a ' + ((this.hours() !== 1) ? 'les' : 'la') + '] LT';
          },
          sameElse : 'L'
        },
        relativeTime : {
          future : 'd\'aquí %s',
          past : 'fa %s',
          s : 'uns segons',
          ss : '%d segons',
          m : 'un minut',
          mm : '%d minuts',
          h : 'una hora',
          hh : '%d hores',
          d : 'un dia',
          dd : '%d dies',
          M : 'un mes',
          MM : '%d mesos',
          y : 'un any',
          yy : '%d anys'
        },
        dayOfMonthOrdinalParse: /\d{1,2}(r|n|t|è|a)/,
        ordinal : function (number, period) {
          let output = (number === 1) ? 'r' :
            (number === 2) ? 'n' :
              (number === 3) ? 'r' :
                (number === 4) ? 't' : 'è';
          if (period === 'w' || period === 'W') {
            output = 'a';
          }
          return number + output;
        },
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 4  // The week that contains Jan 4th is the first week of the year.
        }
      });
      return 'ca';
    }
  },
  'de-ch': {
    d3: {
      'decimal': '.',
      'thousands': '\'',
      'grouping': [3],
      'currency': ['', '\u00a0CHF']
    },
    moment: () => {
      function processRelativeTime(number, withoutSuffix, key, isFuture) {
        let format = {
          'm': ['eine Minute', 'einer Minute'],
          'h': ['eine Stunde', 'einer Stunde'],
          'd': ['ein Tag', 'einem Tag'],
          'dd': [number + ' Tage', number + ' Tagen'],
          'M': ['ein Monat', 'einem Monat'],
          'MM': [number + ' Monate', number + ' Monaten'],
          'y': ['ein Jahr', 'einem Jahr'],
          'yy': [number + ' Jahre', number + ' Jahren']
        };
        return withoutSuffix ? format[key][0] : format[key][1];
      }

      defineLocale('de-ch', {
        months : 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
        monthsShort : 'Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.'.split('_'),
        monthsParseExact : true,
        weekdays : 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
        weekdaysShort : 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
        weekdaysMin : 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L : 'DD.MM.YYYY',
          LL : 'D. MMMM YYYY',
          LLL : 'D. MMMM YYYY HH:mm',
          LLLL : 'dddd, D. MMMM YYYY HH:mm'
        },
        calendar : {
          sameDay: '[heute um] LT [Uhr]',
          sameElse: 'L',
          nextDay: '[morgen um] LT [Uhr]',
          nextWeek: 'dddd [um] LT [Uhr]',
          lastDay: '[gestern um] LT [Uhr]',
          lastWeek: '[letzten] dddd [um] LT [Uhr]'
        },
        relativeTime : {
          future : 'in %s',
          past : 'vor %s',
          s : 'ein paar Sekunden',
          ss : '%d Sekunden',
          m : processRelativeTime,
          mm : '%d Minuten',
          h : processRelativeTime,
          hh : '%d Stunden',
          d : processRelativeTime,
          dd : processRelativeTime,
          M : processRelativeTime,
          MM : processRelativeTime,
          y : processRelativeTime,
          yy : processRelativeTime
        },
        dayOfMonthOrdinalParse: /\d{1,2}\./,
        ordinal : '%d.',
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 4  // The week that contains Jan 4th is the first week of the year.
        }
      });
      return 'de-ch';
    }
  },
  'de-de': {
    d3: {
      'decimal': ',',
      'thousands': '.',
      'grouping': [3],
      'currency': ['', '\u00a0€']
    },
    moment: () => {

      function processRelativeTime(number, withoutSuffix, key, isFuture) {
        let format = {
          'm': ['eine Minute', 'einer Minute'],
          'h': ['eine Stunde', 'einer Stunde'],
          'd': ['ein Tag', 'einem Tag'],
          'dd': [number + ' Tage', number + ' Tagen'],
          'M': ['ein Monat', 'einem Monat'],
          'MM': [number + ' Monate', number + ' Monaten'],
          'y': ['ein Jahr', 'einem Jahr'],
          'yy': [number + ' Jahre', number + ' Jahren']
        };
        return withoutSuffix ? format[key][0] : format[key][1];
      }

      defineLocale('de', {
        months : 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
        monthsShort : 'Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.'.split('_'),
        monthsParseExact : true,
        weekdays : 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
        weekdaysShort : 'So._Mo._Di._Mi._Do._Fr._Sa.'.split('_'),
        weekdaysMin : 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L : 'DD.MM.YYYY',
          LL : 'D. MMMM YYYY',
          LLL : 'D. MMMM YYYY HH:mm',
          LLLL : 'dddd, D. MMMM YYYY HH:mm'
        },
        calendar : {
          sameDay: '[heute um] LT [Uhr]',
          sameElse: 'L',
          nextDay: '[morgen um] LT [Uhr]',
          nextWeek: 'dddd [um] LT [Uhr]',
          lastDay: '[gestern um] LT [Uhr]',
          lastWeek: '[letzten] dddd [um] LT [Uhr]'
        },
        relativeTime : {
          future : 'in %s',
          past : 'vor %s',
          s : 'ein paar Sekunden',
          ss : '%d Sekunden',
          m : processRelativeTime,
          mm : '%d Minuten',
          h : processRelativeTime,
          hh : '%d Stunden',
          d : processRelativeTime,
          dd : processRelativeTime,
          M : processRelativeTime,
          MM : processRelativeTime,
          y : processRelativeTime,
          yy : processRelativeTime
        },
        dayOfMonthOrdinalParse: /\d{1,2}\./,
        ordinal : '%d.',
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 4  // The week that contains Jan 4th is the first week of the year.
        }
      });

      return 'de';
    }
  },
  'en-us': {
    primary: true,
    d3: {
      'decimal': '.',
      'thousands': ',',
      'grouping': [3],
      'currency': ['$', '']
    },
    moment: () => {
      return 'en';
    }
  },
  'en-ca': {
    d3: {
      'decimal': '.',
      'thousands': ',',
      'grouping': [3],
      'currency': ['$', '']
    },
    moment: () => {
      defineLocale('en-ca', {
        months : 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
        monthsShort : 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
        weekdays : 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
        weekdaysShort : 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
        weekdaysMin : 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
        longDateFormat : {
          LT : 'h:mm A',
          LTS : 'h:mm:ss A',
          L : 'YYYY-MM-DD',
          LL : 'MMMM D, YYYY',
          LLL : 'MMMM D, YYYY h:mm A',
          LLLL : 'dddd, MMMM D, YYYY h:mm A'
        },
        calendar : {
          sameDay : '[Today at] LT',
          nextDay : '[Tomorrow at] LT',
          nextWeek : 'dddd [at] LT',
          lastDay : '[Yesterday at] LT',
          lastWeek : '[Last] dddd [at] LT',
          sameElse : 'L'
        },
        relativeTime : {
          future : 'in %s',
          past : '%s ago',
          s : 'a few seconds',
          ss : '%d seconds',
          m : 'a minute',
          mm : '%d minutes',
          h : 'an hour',
          hh : '%d hours',
          d : 'a day',
          dd : '%d days',
          M : 'a month',
          MM : '%d months',
          y : 'a year',
          yy : '%d years'
        },
        dayOfMonthOrdinalParse: /\d{1,2}(st|nd|rd|th)/,
        ordinal : function (number) {
          let b = number % 10,
            output = (~~(number % 100 / 10) === 1) ? 'th' :
              (b === 1) ? 'st' :
                (b === 2) ? 'nd' :
                  (b === 3) ? 'rd' : 'th';
          return number + output;
        }
      });
      return 'en-ca';
    }
  },
  'en-gb': {
    d3: {
      'decimal': '.',
      'thousands': ',',
      'grouping': [3],
      'currency': ['£', '']
    },
    moment: gbAuDateTime('en-gb')
  },
  'en-au': {
    primary: true,
    d3: {
      'decimal': '.',
      'thousands': ',',
      'grouping': [3],
      'currency': ['$', '']
    },
    moment: gbAuDateTime('en-au')
  },
  'en-in': {
    d3: {
      'decimal': '.',
      'thousands': ',',
      'grouping': [3, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      'currency': ['₹', '']
    },
    moment: () => {
      defineLocale('en-in', {
        months : 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
        monthsShort : 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
        weekdays : 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
        weekdaysShort : 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
        weekdaysMin : 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
        longDateFormat : {
          LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'DD/MM/YYYY',
          LL : 'D MMMM YYYY',
          LLL : 'D MMMM YYYY HH:mm',
          LLLL : 'dddd, D MMMM YYYY HH:mm'
        },
        calendar : {
          sameDay : '[Today at] LT',
          nextDay : '[Tomorrow at] LT',
          nextWeek : 'dddd [at] LT',
          lastDay : '[Yesterday at] LT',
          lastWeek : '[Last] dddd [at] LT',
          sameElse : 'L'
        },
        relativeTime : {
          future : 'in %s',
          past : '%s ago',
          s : 'a few seconds',
          ss : '%d seconds',
          m : 'a minute',
          mm : '%d minutes',
          h : 'an hour',
          hh : '%d hours',
          d : 'a day',
          dd : '%d days',
          M : 'a month',
          MM : '%d months',
          y : 'a year',
          yy : '%d years'
        },
        dayOfMonthOrdinalParse: /\d{1,2}(st|nd|rd|th)/,
        ordinal : function (number) {
          let b = number % 10,
            output = (~~(number % 100 / 10) === 1) ? 'th' :
              (b === 1) ? 'st' :
                (b === 2) ? 'nd' :
                  (b === 3) ? 'rd' : 'th';
          return number + output;
        },
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 4  // The week that contains Jan 4th is the first week of the year.
        }
      });
      return 'en-in';
    }
  },
  'es-us': {
    d3: {
      'decimal': '.',
      'thousands': ',',
      'grouping': [3],
      'currency': ['$', '']
    },
    moment: () => {
      return 'en';
    }
  },
  'es-cl': {
    d3: {
      'decimal': ',',
      'thousands': '.',
      'grouping': [3],
      'currency': ['$', '']
    },
    moment: spanishDate
  },
  'es-es': {
    d3: {
      'decimal': ',',
      'thousands': '.',
      'grouping': [3],
      'currency': ['', '\u00a0€']
    },
    moment: spanishDate
  },
  'es-mx': {
    d3: {
      'decimal': '.',
      'thousands': ',',
      'grouping': [3],
      'currency': ['$', '']
    },
    moment: spanishDate
  },
  'fi-fi': {
    d3: {
      'decimal': ',',
      'thousands': '\u00a0',
      'grouping': [3],
      'currency': ['', '\u00a0€']
    },
    moment: () => {
      let numbersPast = 'nolla yksi kaksi kolme neljä viisi kuusi seitsemän kahdeksan yhdeksän'.split(' '),
        numbersFuture = [
          'nolla', 'yhden', 'kahden', 'kolmen', 'neljän', 'viiden', 'kuuden',
          numbersPast[7], numbersPast[8], numbersPast[9]
        ];
      function translate(number, withoutSuffix, key, isFuture) {
        let result = '';
        // eslint-disable-next-line
        switch (key) {
        case 's':
          return isFuture ? 'muutaman sekunnin' : 'muutama sekunti';
        case 'ss':
          return isFuture ? 'sekunnin' : 'sekuntia';
        case 'm':
          return isFuture ? 'minuutin' : 'minuutti';
        case 'mm':
          result = isFuture ? 'minuutin' : 'minuuttia';
          break;
        case 'h':
          return isFuture ? 'tunnin' : 'tunti';
        case 'hh':
          result = isFuture ? 'tunnin' : 'tuntia';
          break;
        case 'd':
          return isFuture ? 'päivän' : 'päivä';
        case 'dd':
          result = isFuture ? 'päivän' : 'päivää';
          break;
        case 'M':
          return isFuture ? 'kuukauden' : 'kuukausi';
        case 'MM':
          result = isFuture ? 'kuukauden' : 'kuukautta';
          break;
        case 'y':
          return isFuture ? 'vuoden' : 'vuosi';
        case 'yy':
          result = isFuture ? 'vuoden' : 'vuotta';
          break;
        }
        result = verbalNumber(number, isFuture) + ' ' + result;
        return result;
      }
      function verbalNumber(number, isFuture) {
        return number < 10 ? (isFuture ? numbersFuture[number] : numbersPast[number]) : number;
      }

      defineLocale('fi', {
        months : 'tammikuu_helmikuu_maaliskuu_huhtikuu_toukokuu_kesäkuu_heinäkuu_elokuu_syyskuu_lokakuu_marraskuu_joulukuu'.split('_'),
        monthsShort : 'tammi_helmi_maalis_huhti_touko_kesä_heinä_elo_syys_loka_marras_joulu'.split('_'),
        weekdays : 'sunnuntai_maanantai_tiistai_keskiviikko_torstai_perjantai_lauantai'.split('_'),
        weekdaysShort : 'su_ma_ti_ke_to_pe_la'.split('_'),
        weekdaysMin : 'su_ma_ti_ke_to_pe_la'.split('_'),
        longDateFormat : {
          LT : 'HH.mm',
          LTS : 'HH.mm.ss',
          L : 'DD.MM.YYYY',
          LL : 'Do MMMM[ta] YYYY',
          LLL : 'Do MMMM[ta] YYYY, [klo] HH.mm',
          LLLL : 'dddd, Do MMMM[ta] YYYY, [klo] HH.mm',
          l : 'D.M.YYYY',
          ll : 'Do MMM YYYY',
          lll : 'Do MMM YYYY, [klo] HH.mm',
          llll : 'ddd, Do MMM YYYY, [klo] HH.mm'
        },
        calendar : {
          sameDay : '[tänään] [klo] LT',
          nextDay : '[huomenna] [klo] LT',
          nextWeek : 'dddd [klo] LT',
          lastDay : '[eilen] [klo] LT',
          lastWeek : '[viime] dddd[na] [klo] LT',
          sameElse : 'L'
        },
        relativeTime : {
          future : '%s päästä',
          past : '%s sitten',
          s : translate,
          ss : translate,
          m : translate,
          mm : translate,
          h : translate,
          hh : translate,
          d : translate,
          dd : translate,
          M : translate,
          MM : translate,
          y : translate,
          yy : translate
        },
        dayOfMonthOrdinalParse: /\d{1,2}\./,
        ordinal : '%d.',
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 4  // The week that contains Jan 4th is the first week of the year.
        }
      });


      return 'fi';

    }
  },
  'fr-ca': {
    d3: {
      'decimal': ',',
      'thousands': '\u00a0',
      'grouping': [3],
      'currency': ['', '$']
    },
    moment: () => {
      defineLocale('fr-ca', {
        months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
        monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
        monthsParseExact : true,
        weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
        weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
        weekdaysMin : 'di_lu_ma_me_je_ve_sa'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
          LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'YYYY-MM-DD',
          LL : 'D MMMM YYYY',
          LLL : 'D MMMM YYYY HH:mm',
          LLLL : 'dddd D MMMM YYYY HH:mm'
        },
        calendar : {
          sameDay : '[Aujourd’hui à] LT',
          nextDay : '[Demain à] LT',
          nextWeek : 'dddd [à] LT',
          lastDay : '[Hier à] LT',
          lastWeek : 'dddd [dernier à] LT',
          sameElse : 'L'
        },
        relativeTime : {
          future : 'dans %s',
          past : 'il y a %s',
          s : 'quelques secondes',
          ss : '%d secondes',
          m : 'une minute',
          mm : '%d minutes',
          h : 'une heure',
          hh : '%d heures',
          d : 'un jour',
          dd : '%d jours',
          M : 'un mois',
          MM : '%d mois',
          y : 'un an',
          yy : '%d ans'
        },
        dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
        ordinal : function (number, period) {
          switch (period) {
          // Words with masculine grammatical gender: mois, trimestre, jour
          default:
          case 'M':
          case 'Q':
          case 'D':
          case 'DDD':
          case 'd':
            return number + (number === 1 ? 'er' : 'e');

            // Words with feminine grammatical gender: semaine
          case 'w':
          case 'W':
            return number + (number === 1 ? 're' : 'e');
          }
        }
      });
      return 'fr-ca';
    }
  },
  'fr-fr': {
    d3: {
      'decimal': ',',
      'thousands': '\u00a0',
      'grouping': [3],
      'currency': ['', '\u00a0€'],
      'percent': '\u202f%'
    },
    moment: () => {


      defineLocale('fr', {
        months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
        monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
        monthsParseExact : true,
        weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
        weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
        weekdaysMin : 'di_lu_ma_me_je_ve_sa'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
          LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'DD/MM/YYYY',
          LL : 'D MMMM YYYY',
          LLL : 'D MMMM YYYY HH:mm',
          LLLL : 'dddd D MMMM YYYY HH:mm'
        },
        calendar : {
          sameDay : '[Aujourd’hui à] LT',
          nextDay : '[Demain à] LT',
          nextWeek : 'dddd [à] LT',
          lastDay : '[Hier à] LT',
          lastWeek : 'dddd [dernier à] LT',
          sameElse : 'L'
        },
        relativeTime : {
          future : 'dans %s',
          past : 'il y a %s',
          s : 'quelques secondes',
          ss : '%d secondes',
          m : 'une minute',
          mm : '%d minutes',
          h : 'une heure',
          hh : '%d heures',
          d : 'un jour',
          dd : '%d jours',
          M : 'un mois',
          MM : '%d mois',
          y : 'un an',
          yy : '%d ans'
        },
        dayOfMonthOrdinalParse: /\d{1,2}(er|)/,
        ordinal : function (number, period) {
          switch (period) {
          // TODO: Return 'e' when day of month > 1. Move this case inside
          // block for masculine words below.
          // See https://github.com/moment/moment/issues/3375
          case 'D':
            return number + (number === 1 ? 'er' : '');

            // Words with masculine grammatical gender: mois, trimestre, jour
          default:
          case 'M':
          case 'Q':
          case 'DDD':
          case 'd':
            return number + (number === 1 ? 'er' : 'e');

            // Words with feminine grammatical gender: semaine
          case 'w':
          case 'W':
            return number + (number === 1 ? 're' : 'e');
          }
        },
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 4  // The week that contains Jan 4th is the first week of the year.
        }
      });
      return 'fr';
    }
  },
  'he-il': {
    d3: {
      'decimal': '.',
      'thousands': ',',
      'grouping': [3],
      'currency': ['₪', '']
    },
    moment: () => {
      defineLocale('he', {
        months : 'ינואר_פברואר_מרץ_אפריל_מאי_יוני_יולי_אוגוסט_ספטמבר_אוקטובר_נובמבר_דצמבר'.split('_'),
        monthsShort : 'ינו׳_פבר׳_מרץ_אפר׳_מאי_יוני_יולי_אוג׳_ספט׳_אוק׳_נוב׳_דצמ׳'.split('_'),
        weekdays : 'ראשון_שני_שלישי_רביעי_חמישי_שישי_שבת'.split('_'),
        weekdaysShort : 'א׳_ב׳_ג׳_ד׳_ה׳_ו׳_ש׳'.split('_'),
        weekdaysMin : 'א_ב_ג_ד_ה_ו_ש'.split('_'),
        longDateFormat : {
          LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'DD/MM/YYYY',
          LL : 'D [ב]MMMM YYYY',
          LLL : 'D [ב]MMMM YYYY HH:mm',
          LLLL : 'dddd, D [ב]MMMM YYYY HH:mm',
          l : 'D/M/YYYY',
          ll : 'D MMM YYYY',
          lll : 'D MMM YYYY HH:mm',
          llll : 'ddd, D MMM YYYY HH:mm'
        },
        calendar : {
          sameDay : '[היום ב־]LT',
          nextDay : '[מחר ב־]LT',
          nextWeek : 'dddd [בשעה] LT',
          lastDay : '[אתמול ב־]LT',
          lastWeek : '[ביום] dddd [האחרון בשעה] LT',
          sameElse : 'L'
        },
        relativeTime : {
          future : 'בעוד %s',
          past : 'לפני %s',
          s : 'מספר שניות',
          ss : '%d שניות',
          m : 'דקה',
          mm : '%d דקות',
          h : 'שעה',
          hh : function (number) {
            if (number === 2) {
              return 'שעתיים';
            }
            return number + ' שעות';
          },
          d : 'יום',
          dd : function (number) {
            if (number === 2) {
              return 'יומיים';
            }
            return number + ' ימים';
          },
          M : 'חודש',
          MM : function (number) {
            if (number === 2) {
              return 'חודשיים';
            }
            return number + ' חודשים';
          },
          y : 'שנה',
          yy : function (number) {
            if (number === 2) {
              return 'שנתיים';
            } else if (number % 10 === 0 && number !== 10) {
              return number + ' שנה';
            }
            return number + ' שנים';
          }
        },
        meridiemParse: /אחה"צ|לפנה"צ|אחרי הצהריים|לפני הצהריים|לפנות בוקר|בבוקר|בערב/i,
        isPM : function (input) {
          return /^(אחה"צ|אחרי הצהריים|בערב)$/.test(input);
        },
        meridiem : function (hour, minute, isLower) {
          if (hour < 5) {
            return 'לפנות בוקר';
          } else if (hour < 10) {
            return 'בבוקר';
          } else if (hour < 12) {
            return isLower ? 'לפנה"צ' : 'לפני הצהריים';
          } else if (hour < 18) {
            return isLower ? 'אחה"צ' : 'אחרי הצהריים';
          } else {
            return 'בערב';
          }
        }
      });

      return 'he';
    }
  },
  'hi-in': {
    d3: {
      'decimal': '.',
      'thousands': ',',
      'grouping': [3, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      'currency': ['₹', '']
    },
    moment: () => {
      let symbolMap = {
          '1': '१',
          '2': '२',
          '3': '३',
          '4': '४',
          '5': '५',
          '6': '६',
          '7': '७',
          '8': '८',
          '9': '९',
          '0': '०'
        },
        numberMap = {
          '१': '1',
          '२': '2',
          '३': '3',
          '४': '4',
          '५': '5',
          '६': '6',
          '७': '7',
          '८': '8',
          '९': '9',
          '०': '0'
        };

      defineLocale('hi', {
        months : 'जनवरी_फ़रवरी_मार्च_अप्रैल_मई_जून_जुलाई_अगस्त_सितम्बर_अक्टूबर_नवम्बर_दिसम्बर'.split('_'),
        monthsShort : 'जन._फ़र._मार्च_अप्रै._मई_जून_जुल._अग._सित._अक्टू._नव._दिस.'.split('_'),
        monthsParseExact: true,
        weekdays : 'रविवार_सोमवार_मंगलवार_बुधवार_गुरूवार_शुक्रवार_शनिवार'.split('_'),
        weekdaysShort : 'रवि_सोम_मंगल_बुध_गुरू_शुक्र_शनि'.split('_'),
        weekdaysMin : 'र_सो_मं_बु_गु_शु_श'.split('_'),
        longDateFormat : {
          LT : 'A h:mm बजे',
          LTS : 'A h:mm:ss बजे',
          L : 'DD/MM/YYYY',
          LL : 'D MMMM YYYY',
          LLL : 'D MMMM YYYY, A h:mm बजे',
          LLLL : 'dddd, D MMMM YYYY, A h:mm बजे'
        },
        calendar : {
          sameDay : '[आज] LT',
          nextDay : '[कल] LT',
          nextWeek : 'dddd, LT',
          lastDay : '[कल] LT',
          lastWeek : '[पिछले] dddd, LT',
          sameElse : 'L'
        },
        relativeTime : {
          future : '%s में',
          past : '%s पहले',
          s : 'कुछ ही क्षण',
          ss : '%d सेकंड',
          m : 'एक मिनट',
          mm : '%d मिनट',
          h : 'एक घंटा',
          hh : '%d घंटे',
          d : 'एक दिन',
          dd : '%d दिन',
          M : 'एक महीने',
          MM : '%d महीने',
          y : 'एक वर्ष',
          yy : '%d वर्ष'
        },
        preparse: function (string) {
          return string.replace(/[१२३४५६७८९०]/g, function (match) {
            return numberMap[match];
          });
        },
        postformat: function (string) {
          return string.replace(/\d/g, function (match) {
            return symbolMap[match];
          });
        },
        // Hindi notation for meridiems are quite fuzzy in practice. While there exists
        // a rigid notion of a 'Pahar' it is not used as rigidly in modern Hindi.
        meridiemParse: /रात|सुबह|दोपहर|शाम/,
        meridiemHour : function (hour, meridiem) {
          if (hour === 12) {
            hour = 0;
          }
          if (meridiem === 'रात') {
            return hour < 4 ? hour : hour + 12;
          } else if (meridiem === 'सुबह') {
            return hour;
          } else if (meridiem === 'दोपहर') {
            return hour >= 10 ? hour : hour + 12;
          } else if (meridiem === 'शाम') {
            return hour + 12;
          }
        },
        meridiem : function (hour, minute, isLower) {
          if (hour < 4) {
            return 'रात';
          } else if (hour < 10) {
            return 'सुबह';
          } else if (hour < 17) {
            return 'दोपहर';
          } else if (hour < 20) {
            return 'शाम';
          } else {
            return 'रात';
          }
        },
        week : {
          dow : 0, // Sunday is the first day of the week.
          doy : 6  // The week that contains Jan 1st is the first week of the year.
        }
      });

      return 'hi';
    }
  },
  'hu-hu': {
    d3: {
      'decimal': ',',
      'thousands': '\u00a0',
      'grouping': [3],
      'currency': ['', '\u00a0Ft']
    },
    moment: () => {

      let weekEndings = 'vasárnap hétfőn kedden szerdán csütörtökön pénteken szombaton'.split(' ');
      function translate(number, withoutSuffix, key, isFuture) {
        let num = number;

        // eslint-disable-next-line
        switch (key) {
        case 's':
          return (isFuture || withoutSuffix) ? 'néhány másodperc' : 'néhány másodperce';
        case 'ss':
          return num + ((isFuture || withoutSuffix) ? ' másodperc' : ' másodperce');
        case 'm':
          return 'egy' + (isFuture || withoutSuffix ? ' perc' : ' perce');
        case 'mm':
          return num + (isFuture || withoutSuffix ? ' perc' : ' perce');
        case 'h':
          return 'egy' + (isFuture || withoutSuffix ? ' óra' : ' órája');
        case 'hh':
          return num + (isFuture || withoutSuffix ? ' óra' : ' órája');
        case 'd':
          return 'egy' + (isFuture || withoutSuffix ? ' nap' : ' napja');
        case 'dd':
          return num + (isFuture || withoutSuffix ? ' nap' : ' napja');
        case 'M':
          return 'egy' + (isFuture || withoutSuffix ? ' hónap' : ' hónapja');
        case 'MM':
          return num + (isFuture || withoutSuffix ? ' hónap' : ' hónapja');
        case 'y':
          return 'egy' + (isFuture || withoutSuffix ? ' év' : ' éve');
        case 'yy':
          return num + (isFuture || withoutSuffix ? ' év' : ' éve');
        }
        return '';
      }
      function week(isFuture) {
        return (isFuture ? '' : '[múlt] ') + '[' + weekEndings[this.day()] + '] LT[-kor]';
      }
  
      defineLocale('hu', {
        months : 'január_február_március_április_május_június_július_augusztus_szeptember_október_november_december'.split('_'),
        monthsShort : 'jan_feb_márc_ápr_máj_jún_júl_aug_szept_okt_nov_dec'.split('_'),
        weekdays : 'vasárnap_hétfő_kedd_szerda_csütörtök_péntek_szombat'.split('_'),
        weekdaysShort : 'vas_hét_kedd_sze_csüt_pén_szo'.split('_'),
        weekdaysMin : 'v_h_k_sze_cs_p_szo'.split('_'),
        longDateFormat : {
          LT : 'H:mm',
          LTS : 'H:mm:ss',
          L : 'YYYY.MM.DD.',
          LL : 'YYYY. MMMM D.',
          LLL : 'YYYY. MMMM D. H:mm',
          LLLL : 'YYYY. MMMM D., dddd H:mm'
        },
        meridiemParse: /de|du/i,
        isPM: function (input) {
          return input.charAt(1).toLowerCase() === 'u';
        },
        meridiem : function (hours, minutes, isLower) {
          if (hours < 12) {
            return isLower === true ? 'de' : 'DE';
          } else {
            return isLower === true ? 'du' : 'DU';
          }
        },
        calendar : {
          sameDay : '[ma] LT[-kor]',
          nextDay : '[holnap] LT[-kor]',
          nextWeek : function () {
            return week.call(this, true);
          },
          lastDay : '[tegnap] LT[-kor]',
          lastWeek : function () {
            return week.call(this, false);
          },
          sameElse : 'L'
        },
        relativeTime : {
          future : '%s múlva',
          past : '%s',
          s : translate,
          ss : translate,
          m : translate,
          mm : translate,
          h : translate,
          hh : translate,
          d : translate,
          dd : translate,
          M : translate,
          MM : translate,
          y : translate,
          yy : translate
        },
        dayOfMonthOrdinalParse: /\d{1,2}\./,
        ordinal : '%d.',
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 4  // The week that contains Jan 4th is the first week of the year.
        }
      });

      return 'hu';

    }
  },
  'it-it': {
    d3: {
      'decimal': ',',
      'thousands': '.',
      'grouping': [3],
      'currency': ['€', '']
    },
    moment: () => {

      defineLocale('it', {
        months : 'gennaio_febbraio_marzo_aprile_maggio_giugno_luglio_agosto_settembre_ottobre_novembre_dicembre'.split('_'),
        monthsShort : 'gen_feb_mar_apr_mag_giu_lug_ago_set_ott_nov_dic'.split('_'),
        weekdays : 'domenica_lunedì_martedì_mercoledì_giovedì_venerdì_sabato'.split('_'),
        weekdaysShort : 'dom_lun_mar_mer_gio_ven_sab'.split('_'),
        weekdaysMin : 'do_lu_ma_me_gi_ve_sa'.split('_'),
        longDateFormat : {
          LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'DD/MM/YYYY',
          LL : 'D MMMM YYYY',
          LLL : 'D MMMM YYYY HH:mm',
          LLLL : 'dddd D MMMM YYYY HH:mm'
        },
        calendar : {
          sameDay: '[Oggi alle] LT',
          nextDay: '[Domani alle] LT',
          nextWeek: 'dddd [alle] LT',
          lastDay: '[Ieri alle] LT',
          lastWeek: function () {
            switch (this.day()) {
            case 0:
              return '[la scorsa] dddd [alle] LT';
            default:
              return '[lo scorso] dddd [alle] LT';
            }
          },
          sameElse: 'L'
        },
        relativeTime : {
          future : function (s) {
            return ((/^[0-9].+$/).test(s) ? 'tra' : 'in') + ' ' + s;
          },
          past : '%s fa',
          s : 'alcuni secondi',
          ss : '%d secondi',
          m : 'un minuto',
          mm : '%d minuti',
          h : 'un\'ora',
          hh : '%d ore',
          d : 'un giorno',
          dd : '%d giorni',
          M : 'un mese',
          MM : '%d mesi',
          y : 'un anno',
          yy : '%d anni'
        },
        dayOfMonthOrdinalParse : /\d{1,2}º/,
        ordinal: '%dº',
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 4  // The week that contains Jan 4th is the first week of the year.
        }
      });
      return 'it';
    }
  },
  'ja-jp': {
    d3: {
      'decimal': '.',
      'thousands': ',',
      'grouping': [3],
      'currency': ['', '円']
    },
    moment: () => {
      defineLocale('ja', {
        months : '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
        monthsShort : '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
        weekdays : '日曜日_月曜日_火曜日_水曜日_木曜日_金曜日_土曜日'.split('_'),
        weekdaysShort : '日_月_火_水_木_金_土'.split('_'),
        weekdaysMin : '日_月_火_水_木_金_土'.split('_'),
        longDateFormat : {
          LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'YYYY/MM/DD',
          LL : 'YYYY年M月D日',
          LLL : 'YYYY年M月D日 HH:mm',
          LLLL : 'YYYY年M月D日 dddd HH:mm',
          l : 'YYYY/MM/DD',
          ll : 'YYYY年M月D日',
          lll : 'YYYY年M月D日 HH:mm',
          llll : 'YYYY年M月D日(ddd) HH:mm'
        },
        meridiemParse: /午前|午後/i,
        isPM : function (input) {
          return input === '午後';
        },
        meridiem : function (hour, minute, isLower) {
          if (hour < 12) {
            return '午前';
          } else {
            return '午後';
          }
        },
        calendar : {
          sameDay : '[今日] LT',
          nextDay : '[明日] LT',
          nextWeek : function (now) {
            if (now.week() < this.week()) {
              return '[来週]dddd LT';
            } else {
              return 'dddd LT';
            }
          },
          lastDay : '[昨日] LT',
          lastWeek : function (now) {
            if (this.week() < now.week()) {
              return '[先週]dddd LT';
            } else {
              return 'dddd LT';
            }
          },
          sameElse : 'L'
        },
        dayOfMonthOrdinalParse : /\d{1,2}日/,
        ordinal : function (number, period) {
          switch (period) {
          case 'd':
          case 'D':
          case 'DDD':
            return number + '日';
          default:
            return number;
          }
        },
        relativeTime : {
          future : '%s後',
          past : '%s前',
          s : '数秒',
          ss : '%d秒',
          m : '1分',
          mm : '%d分',
          h : '1時間',
          hh : '%d時間',
          d : '1日',
          dd : '%d日',
          M : '1ヶ月',
          MM : '%dヶ月',
          y : '1年',
          yy : '%d年'
        }
      });

      return 'ja';
    }
  },
  'ko-kr': {
    d3: {
      'decimal': '.',
      'thousands': ',',
      'grouping': [3],
      'currency': ['₩', '']
    },
    moment: () => {
      defineLocale('ko', {
        months : '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
        monthsShort : '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
        weekdays : '일요일_월요일_화요일_수요일_목요일_금요일_토요일'.split('_'),
        weekdaysShort : '일_월_화_수_목_금_토'.split('_'),
        weekdaysMin : '일_월_화_수_목_금_토'.split('_'),
        longDateFormat : {
          LT : 'A h:mm',
          LTS : 'A h:mm:ss',
          L : 'YYYY.MM.DD.',
          LL : 'YYYY년 MMMM D일',
          LLL : 'YYYY년 MMMM D일 A h:mm',
          LLLL : 'YYYY년 MMMM D일 dddd A h:mm',
          l : 'YYYY.MM.DD.',
          ll : 'YYYY년 MMMM D일',
          lll : 'YYYY년 MMMM D일 A h:mm',
          llll : 'YYYY년 MMMM D일 dddd A h:mm'
        },
        calendar : {
          sameDay : '오늘 LT',
          nextDay : '내일 LT',
          nextWeek : 'dddd LT',
          lastDay : '어제 LT',
          lastWeek : '지난주 dddd LT',
          sameElse : 'L'
        },
        relativeTime : {
          future : '%s 후',
          past : '%s 전',
          s : '몇 초',
          ss : '%d초',
          m : '1분',
          mm : '%d분',
          h : '한 시간',
          hh : '%d시간',
          d : '하루',
          dd : '%d일',
          M : '한 달',
          MM : '%d달',
          y : '일 년',
          yy : '%d년'
        },
        dayOfMonthOrdinalParse : /\d{1,2}(일|월|주)/,
        ordinal : function (number, period) {
          switch (period) {
          case 'd':
          case 'D':
          case 'DDD':
            return number + '일';
          case 'M':
            return number + '월';
          case 'w':
          case 'W':
            return number + '주';
          default:
            return number;
          }
        },
        meridiemParse : /오전|오후/,
        isPM : function (token) {
          return token === '오후';
        },
        meridiem : function (hour, minute, isUpper) {
          return hour < 12 ? '오전' : '오후';
        }
      });

      return 'ko';
    }
  },
  'mk-mk': {
    d3: {
      'decimal': ',',
      'thousands': '.',
      'grouping': [3],
      'currency': ['', '\u00a0ден.']
    },
    moment: () => {
      defineLocale('mk', {
        months : 'јануари_февруари_март_април_мај_јуни_јули_август_септември_октомври_ноември_декември'.split('_'),
        monthsShort : 'јан_фев_мар_апр_мај_јун_јул_авг_сеп_окт_ное_дек'.split('_'),
        weekdays : 'недела_понеделник_вторник_среда_четврток_петок_сабота'.split('_'),
        weekdaysShort : 'нед_пон_вто_сре_чет_пет_саб'.split('_'),
        weekdaysMin : 'нe_пo_вт_ср_че_пе_сa'.split('_'),
        longDateFormat : {
          LT : 'H:mm',
          LTS : 'H:mm:ss',
          L : 'D.MM.YYYY',
          LL : 'D MMMM YYYY',
          LLL : 'D MMMM YYYY H:mm',
          LLLL : 'dddd, D MMMM YYYY H:mm'
        },
        calendar : {
          sameDay : '[Денес во] LT',
          nextDay : '[Утре во] LT',
          nextWeek : '[Во] dddd [во] LT',
          lastDay : '[Вчера во] LT',
          lastWeek : function () {

            // eslint-disable-next-line
            switch (this.day()) {
            case 0:
            case 3:
            case 6:
              return '[Изминатата] dddd [во] LT';
            case 1:
            case 2:
            case 4:
            case 5:
              return '[Изминатиот] dddd [во] LT';
            }
          },
          sameElse : 'L'
        },
        relativeTime : {
          future : 'после %s',
          past : 'пред %s',
          s : 'неколку секунди',
          ss : '%d секунди',
          m : 'минута',
          mm : '%d минути',
          h : 'час',
          hh : '%d часа',
          d : 'ден',
          dd : '%d дена',
          M : 'месец',
          MM : '%d месеци',
          y : 'година',
          yy : '%d години'
        },
        dayOfMonthOrdinalParse: /\d{1,2}-(ев|ен|ти|ви|ри|ми)/,
        ordinal : function (number) {
          let lastDigit = number % 10,
            last2Digits = number % 100;
          if (number === 0) {
            return number + '-ев';
          } else if (last2Digits === 0) {
            return number + '-ен';
          } else if (last2Digits > 10 && last2Digits < 20) {
            return number + '-ти';
          } else if (lastDigit === 1) {
            return number + '-ви';
          } else if (lastDigit === 2) {
            return number + '-ри';
          } else if (lastDigit === 7 || lastDigit === 8) {
            return number + '-ми';
          } else {
            return number + '-ти';
          }
        },
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 7  // The week that contains Jan 1st is the first week of the year.
        }
      });

      return 'mk';
    }
  },
  'pl-pl': {
    d3: {
      'decimal': ',',
      'thousands': '.',
      'grouping': [3],
      'currency': ['', 'zł']
    },
    moment: () => {

      let monthsNominative = 'styczeń_luty_marzec_kwiecień_maj_czerwiec_lipiec_sierpień_wrzesień_październik_listopad_grudzień'.split('_'),
        monthsSubjective = 'stycznia_lutego_marca_kwietnia_maja_czerwca_lipca_sierpnia_września_października_listopada_grudnia'.split('_');
      function plural(n) {
        return (n % 10 < 5) && (n % 10 > 1) && ((~~(n / 10) % 10) !== 1);
      }
      function translate(number, withoutSuffix, key) {
        let result = number + ' ';

        // eslint-disable-next-line
        switch (key) {
        case 'ss':
          return result + (plural(number) ? 'sekundy' : 'sekund');
        case 'm':
          return withoutSuffix ? 'minuta' : 'minutę';
        case 'mm':
          return result + (plural(number) ? 'minuty' : 'minut');
        case 'h':
          return withoutSuffix  ? 'godzina'  : 'godzinę';
        case 'hh':
          return result + (plural(number) ? 'godziny' : 'godzin');
        case 'MM':
          return result + (plural(number) ? 'miesiące' : 'miesięcy');
        case 'yy':
          return result + (plural(number) ? 'lata' : 'lat');
        }
      }

      defineLocale('pl', {
        months : function (momentToFormat, format) {
          if (!momentToFormat) {
            return monthsNominative;
          } else if (format === '') {
            // Hack: if format empty we know this is used to generate
            // RegExp by moment. Give then back both valid forms of months
            // in RegExp ready format.
            return '(' + monthsSubjective[momentToFormat.month()] + '|' + monthsNominative[momentToFormat.month()] + ')';
          } else if (/D MMMM/.test(format)) {
            return monthsSubjective[momentToFormat.month()];
          } else {
            return monthsNominative[momentToFormat.month()];
          }
        },
        monthsShort : 'sty_lut_mar_kwi_maj_cze_lip_sie_wrz_paź_lis_gru'.split('_'),
        weekdays : 'niedziela_poniedziałek_wtorek_środa_czwartek_piątek_sobota'.split('_'),
        weekdaysShort : 'ndz_pon_wt_śr_czw_pt_sob'.split('_'),
        weekdaysMin : 'Nd_Pn_Wt_Śr_Cz_Pt_So'.split('_'),
        longDateFormat : {
          LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'DD.MM.YYYY',
          LL : 'D MMMM YYYY',
          LLL : 'D MMMM YYYY HH:mm',
          LLLL : 'dddd, D MMMM YYYY HH:mm'
        },
        calendar : {
          sameDay: '[Dziś o] LT',
          nextDay: '[Jutro o] LT',
          nextWeek: function () {
            switch (this.day()) {
            case 0:
              return '[W niedzielę o] LT';

            case 2:
              return '[We wtorek o] LT';

            case 3:
              return '[W środę o] LT';

            case 6:
              return '[W sobotę o] LT';

            default:
              return '[W] dddd [o] LT';
            }
          },
          lastDay: '[Wczoraj o] LT',
          lastWeek: function () {
            switch (this.day()) {
            case 0:
              return '[W zeszłą niedzielę o] LT';
            case 3:
              return '[W zeszłą środę o] LT';
            case 6:
              return '[W zeszłą sobotę o] LT';
            default:
              return '[W zeszły] dddd [o] LT';
            }
          },
          sameElse: 'L'
        },
        relativeTime : {
          future : 'za %s',
          past : '%s temu',
          s : 'kilka sekund',
          ss : translate,
          m : translate,
          mm : translate,
          h : translate,
          hh : translate,
          d : '1 dzień',
          dd : '%d dni',
          M : 'miesiąc',
          MM : translate,
          y : 'rok',
          yy : translate
        },
        dayOfMonthOrdinalParse: /\d{1,2}\./,
        ordinal : '%d.',
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 4  // The week that contains Jan 4th is the first week of the year.
        }
      });

      return 'pl';
    }
  },
  'nb-no': {
    d3: {
      currency: ['kr', ''],
      decimal: ',',
      grouping: [3],
      thousands: '.',
    },
    moment: () => {
      defineLocale('nb', {
        months: 'januar_februar_mars_april_mai_juni_juli_august_september_oktober_november_desember'.split(
          '_'
        ),
        monthsShort:
            'jan._feb._mars_apr._mai_juni_juli_aug._sep._okt._nov._des.'.split('_'),
        monthsParseExact: true,
        weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split('_'),
        weekdaysShort: 'sø._ma._ti._on._to._fr._lø.'.split('_'),
        weekdaysMin: 'sø_ma_ti_on_to_fr_lø'.split('_'),
        weekdaysParseExact: true,
        longDateFormat: {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD.MM.YYYY',
          LL: 'D. MMMM YYYY',
          LLL: 'D. MMMM YYYY [kl.] HH:mm',
          LLLL: 'dddd D. MMMM YYYY [kl.] HH:mm',
        },
        calendar: {
          sameDay: '[i dag kl.] LT',
          nextDay: '[i morgen kl.] LT',
          nextWeek: 'dddd [kl.] LT',
          lastDay: '[i går kl.] LT',
          lastWeek: '[forrige] dddd [kl.] LT',
          sameElse: 'L',
        },
        relativeTime: {
          future: 'om %s',
          past: '%s siden',
          s: 'noen sekunder',
          ss: '%d sekunder',
          m: 'ett minutt',
          mm: '%d minutter',
          h: 'en time',
          hh: '%d timer',
          d: 'en dag',
          dd: '%d dager',
          w: 'en uke',
          ww: '%d uker',
          M: 'en måned',
          MM: '%d måneder',
          y: 'ett år',
          yy: '%d år',
        },
        dayOfMonthOrdinalParse: /\d{1,2}\./,
        ordinal: '%d.',
        week: {
          dow: 1, // Monday is the first day of the week.
          doy: 4, // The week that contains Jan 4th is the first week of the year.
        },
      });

      return 'nb';
    }
  },
  'nl-nl': {
    d3: {
      'decimal': ',',
      'thousands': '.',
      'grouping': [3],
      'currency': ['€\u00a0', '']
    },
    moment: () => {


      let monthsShortWithDots = 'jan._feb._mrt._apr._mei_jun._jul._aug._sep._okt._nov._dec.'.split('_'),
        monthsShortWithoutDots = 'jan_feb_mrt_apr_mei_jun_jul_aug_sep_okt_nov_dec'.split('_');

      let monthsParse = [/^jan/i, /^feb/i, /^maart|mrt.?$/i, /^apr/i, /^mei$/i, /^jun[i.]?$/i, /^jul[i.]?$/i, /^aug/i, /^sep/i, /^okt/i, /^nov/i, /^dec/i];
      let monthsRegex = /^(januari|februari|maart|april|mei|april|ju[nl]i|augustus|september|oktober|november|december|jan\.?|feb\.?|mrt\.?|apr\.?|ju[nl]\.?|aug\.?|sep\.?|okt\.?|nov\.?|dec\.?)/i;

      defineLocale('nl', {
        months : 'januari_februari_maart_april_mei_juni_juli_augustus_september_oktober_november_december'.split('_'),
        monthsShort : function (m, format) {
          if (!m) {
            return monthsShortWithDots;
          } else if (/-MMM-/.test(format)) {
            return monthsShortWithoutDots[m.month()];
          } else {
            return monthsShortWithDots[m.month()];
          }
        },

        monthsRegex: monthsRegex,
        monthsShortRegex: monthsRegex,
        monthsStrictRegex: /^(januari|februari|maart|mei|ju[nl]i|april|augustus|september|oktober|november|december)/i,
        monthsShortStrictRegex: /^(jan\.?|feb\.?|mrt\.?|apr\.?|mei|ju[nl]\.?|aug\.?|sep\.?|okt\.?|nov\.?|dec\.?)/i,

        monthsParse : monthsParse,
        longMonthsParse : monthsParse,
        shortMonthsParse : monthsParse,

        weekdays : 'zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag'.split('_'),
        weekdaysShort : 'zo._ma._di._wo._do._vr._za.'.split('_'),
        weekdaysMin : 'zo_ma_di_wo_do_vr_za'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
          LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'DD-MM-YYYY',
          LL : 'D MMMM YYYY',
          LLL : 'D MMMM YYYY HH:mm',
          LLLL : 'dddd D MMMM YYYY HH:mm'
        },
        calendar : {
          sameDay: '[vandaag om] LT',
          nextDay: '[morgen om] LT',
          nextWeek: 'dddd [om] LT',
          lastDay: '[gisteren om] LT',
          lastWeek: '[afgelopen] dddd [om] LT',
          sameElse: 'L'
        },
        relativeTime : {
          future : 'over %s',
          past : '%s geleden',
          s : 'een paar seconden',
          ss : '%d seconden',
          m : 'één minuut',
          mm : '%d minuten',
          h : 'één uur',
          hh : '%d uur',
          d : 'één dag',
          dd : '%d dagen',
          M : 'één maand',
          MM : '%d maanden',
          y : 'één jaar',
          yy : '%d jaar'
        },
        dayOfMonthOrdinalParse: /\d{1,2}(ste|de)/,
        ordinal : function (number) {
          return number + ((number === 1 || number === 8 || number >= 20) ? 'ste' : 'de');
        },
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 4  // The week that contains Jan 4th is the first week of the year.
        }
      });

      return 'nl';

    }
  },
  'pt-br': {
    d3: {
      'decimal': ',',
      'thousands': '.',
      'grouping': [3],
      'currency': ['R$', '']
    },
    moment: () => {
      defineLocale('pt-br', {
        months : 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split('_'),
        monthsShort : 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_'),
        weekdays : 'Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sábado'.split('_'),
        weekdaysShort : 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
        weekdaysMin : 'Do_2ª_3ª_4ª_5ª_6ª_Sá'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
          LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'DD/MM/YYYY',
          LL : 'D [de] MMMM [de] YYYY',
          LLL : 'D [de] MMMM [de] YYYY [às] HH:mm',
          LLLL : 'dddd, D [de] MMMM [de] YYYY [às] HH:mm'
        },
        calendar : {
          sameDay: '[Hoje às] LT',
          nextDay: '[Amanhã às] LT',
          nextWeek: 'dddd [às] LT',
          lastDay: '[Ontem às] LT',
          lastWeek: function () {
            return (this.day() === 0 || this.day() === 6) ?
              '[Último] dddd [às] LT' : // Saturday + Sunday
              '[Última] dddd [às] LT'; // Monday - Friday
          },
          sameElse: 'L'
        },
        relativeTime : {
          future : 'em %s',
          past : 'há %s',
          s : 'poucos segundos',
          ss : '%d segundos',
          m : 'um minuto',
          mm : '%d minutos',
          h : 'uma hora',
          hh : '%d horas',
          d : 'um dia',
          dd : '%d dias',
          M : 'um mês',
          MM : '%d meses',
          y : 'um ano',
          yy : '%d anos'
        },
        dayOfMonthOrdinalParse: /\d{1,2}º/,
        ordinal : '%dº'
      });

      return 'pt-br';
    }
  },
  'pt-pt': {
    d3: {
      'decimal': ',',
      'thousands': '\u00a0',
      'grouping': [3],
      'currency': ['', '\u00a0€']
    },
    moment: () => {
      defineLocale('pt', {
        months: 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split(
          '_'
        ),
        monthsShort: 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_'),
        weekdays:
            'Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sábado'.split(
              '_'
            ),
        weekdaysShort: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
        weekdaysMin: 'Do_2ª_3ª_4ª_5ª_6ª_Sá'.split('_'),
        weekdaysParseExact: true,
        longDateFormat: {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD/MM/YYYY',
          LL: 'D [de] MMMM [de] YYYY',
          LLL: 'D [de] MMMM [de] YYYY HH:mm',
          LLLL: 'dddd, D [de] MMMM [de] YYYY HH:mm',
        },
        calendar: {
          sameDay: '[Hoje às] LT',
          nextDay: '[Amanhã às] LT',
          nextWeek: 'dddd [às] LT',
          lastDay: '[Ontem às] LT',
          lastWeek: function () {
            return this.day() === 0 || this.day() === 6
              ? '[Último] dddd [às] LT' // Saturday + Sunday
              : '[Última] dddd [às] LT'; // Monday - Friday
          },
          sameElse: 'L',
        },
        relativeTime: {
          future: 'em %s',
          past: 'há %s',
          s: 'segundos',
          ss: '%d segundos',
          m: 'um minuto',
          mm: '%d minutos',
          h: 'uma hora',
          hh: '%d horas',
          d: 'um dia',
          dd: '%d dias',
          w: 'uma semana',
          ww: '%d semanas',
          M: 'um mês',
          MM: '%d meses',
          y: 'um ano',
          yy: '%d anos',
        },
        dayOfMonthOrdinalParse: /\d{1,2}º/,
        ordinal: '%dº',
        week: {
          dow: 1, // Monday is the first day of the week.
          doy: 4, // The week that contains Jan 4th is the first week of the year.
        } });
      return 'pt';
    }
  },
  'ru-ru': {
    d3: {
      'decimal': ',',
      'thousands': '\u00a0',
      'grouping': [3],
      'currency': ['', '\u00a0руб.']
    },
    moment: () => {
      function plural(word, num) {
        let forms = word.split('_');
        return num % 10 === 1 && num % 100 !== 11 ? forms[0] : (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20) ? forms[1] : forms[2]);
      }
      function relativeTimeWithPlural(number, withoutSuffix, key) {
        let format = {
          'ss': withoutSuffix ? 'секунда_секунды_секунд' : 'секунду_секунды_секунд',
          'mm': withoutSuffix ? 'минута_минуты_минут' : 'минуту_минуты_минут',
          'hh': 'час_часа_часов',
          'dd': 'день_дня_дней',
          'MM': 'месяц_месяца_месяцев',
          'yy': 'год_года_лет'
        };
        if (key === 'm') {
          return withoutSuffix ? 'минута' : 'минуту';
        } else {
          return number + ' ' + plural(format[key], +number);
        }
      }
      let monthsParse = [/^янв/i, /^фев/i, /^мар/i, /^апр/i, /^ма[йя]/i, /^июн/i, /^июл/i, /^авг/i, /^сен/i, /^окт/i, /^ноя/i, /^дек/i];

      // http://new.gramota.ru/spravka/rules/139-prop : § 103
      // Сокращения месяцев: http://new.gramota.ru/spravka/buro/search-answer?s=242637
      // CLDR data:          http://www.unicode.org/cldr/charts/28/summary/ru.html#1753
      defineLocale('ru', {
        months : {
          format: 'января_февраля_марта_апреля_мая_июня_июля_августа_сентября_октября_ноября_декабря'.split('_'),
          standalone: 'январь_февраль_март_апрель_май_июнь_июль_август_сентябрь_октябрь_ноябрь_декабрь'.split('_')
        },
        monthsShort : {
          // по CLDR именно "июл." и "июн.", но какой смысл менять букву на точку ?
          format: 'янв._февр._мар._апр._мая_июня_июля_авг._сент._окт._нояб._дек.'.split('_'),
          standalone: 'янв._февр._март_апр._май_июнь_июль_авг._сент._окт._нояб._дек.'.split('_')
        },
        weekdays : {
          standalone: 'воскресенье_понедельник_вторник_среда_четверг_пятница_суббота'.split('_'),
          format: 'воскресенье_понедельник_вторник_среду_четверг_пятницу_субботу'.split('_'),
          isFormat: /\[ ?[Вв] ?(?:прошлую|следующую|эту)? ?\] ?dddd/
        },
        weekdaysShort : 'вс_пн_вт_ср_чт_пт_сб'.split('_'),
        weekdaysMin : 'вс_пн_вт_ср_чт_пт_сб'.split('_'),
        monthsParse : monthsParse,
        longMonthsParse : monthsParse,
        shortMonthsParse : monthsParse,

        // полные названия с падежами, по три буквы, для некоторых, по 4 буквы, сокращения с точкой и без точки
        monthsRegex: /^(январ[ья]|янв\.?|феврал[ья]|февр?\.?|марта?|мар\.?|апрел[ья]|апр\.?|ма[йя]|июн[ья]|июн\.?|июл[ья]|июл\.?|августа?|авг\.?|сентябр[ья]|сент?\.?|октябр[ья]|окт\.?|ноябр[ья]|нояб?\.?|декабр[ья]|дек\.?)/i,

        // копия предыдущего
        monthsShortRegex: /^(январ[ья]|янв\.?|феврал[ья]|февр?\.?|марта?|мар\.?|апрел[ья]|апр\.?|ма[йя]|июн[ья]|июн\.?|июл[ья]|июл\.?|августа?|авг\.?|сентябр[ья]|сент?\.?|октябр[ья]|окт\.?|ноябр[ья]|нояб?\.?|декабр[ья]|дек\.?)/i,

        // полные названия с падежами
        monthsStrictRegex: /^(январ[яь]|феврал[яь]|марта?|апрел[яь]|ма[яй]|июн[яь]|июл[яь]|августа?|сентябр[яь]|октябр[яь]|ноябр[яь]|декабр[яь])/i,

        // Выражение, которое соотвествует только сокращённым формам
        monthsShortStrictRegex: /^(янв\.|февр?\.|мар[т.]|апр\.|ма[яй]|июн[ья.]|июл[ья.]|авг\.|сент?\.|окт\.|нояб?\.|дек\.)/i,
        longDateFormat : {
          LT : 'H:mm',
          LTS : 'H:mm:ss',
          L : 'DD.MM.YYYY',
          LL : 'D MMMM YYYY г.',
          LLL : 'D MMMM YYYY г., H:mm',
          LLLL : 'dddd, D MMMM YYYY г., H:mm'
        },
        calendar : {
          sameDay: '[Сегодня, в] LT',
          nextDay: '[Завтра, в] LT',
          lastDay: '[Вчера, в] LT',
          nextWeek: function (now) {
            if (now.week() !== this.week()) {

              // eslint-disable-next-line
              switch (this.day()) {
              case 0:
                return '[В следующее] dddd, [в] LT';
              case 1:
              case 2:
              case 4:
                return '[В следующий] dddd, [в] LT';
              case 3:
              case 5:
              case 6:
                return '[В следующую] dddd, [в] LT';
              }
            } else {
              if (this.day() === 2) {
                return '[Во] dddd, [в] LT';
              } else {
                return '[В] dddd, [в] LT';
              }
            }
          },
          lastWeek: function (now) {
            if (now.week() !== this.week()) {

              // eslint-disable-next-line
              switch (this.day()) {
              case 0:
                return '[В прошлое] dddd, [в] LT';
              case 1:
              case 2:
              case 4:
                return '[В прошлый] dddd, [в] LT';
              case 3:
              case 5:
              case 6:
                return '[В прошлую] dddd, [в] LT';
              }
            } else {
              if (this.day() === 2) {
                return '[Во] dddd, [в] LT';
              } else {
                return '[В] dddd, [в] LT';
              }
            }
          },
          sameElse: 'L'
        },
        relativeTime : {
          future : 'через %s',
          past : '%s назад',
          s : 'несколько секунд',
          ss : relativeTimeWithPlural,
          m : relativeTimeWithPlural,
          mm : relativeTimeWithPlural,
          h : 'час',
          hh : relativeTimeWithPlural,
          d : 'день',
          dd : relativeTimeWithPlural,
          M : 'месяц',
          MM : relativeTimeWithPlural,
          y : 'год',
          yy : relativeTimeWithPlural
        },
        meridiemParse: /ночи|утра|дня|вечера/i,
        isPM : function (input) {
          return /^(дня|вечера)$/.test(input);
        },
        meridiem : function (hour, minute, isLower) {
          if (hour < 4) {
            return 'ночи';
          } else if (hour < 12) {
            return 'утра';
          } else if (hour < 17) {
            return 'дня';
          } else {
            return 'вечера';
          }
        },
        dayOfMonthOrdinalParse: /\d{1,2}-(й|го|я)/,
        ordinal: function (number, period) {
          switch (period) {
          case 'M':
          case 'd':
          case 'DDD':
            return number + '-й';
          case 'D':
            return number + '-го';
          case 'w':
          case 'W':
            return number + '-я';
          default:
            return number;
          }
        },
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 4  // The week that contains Jan 4th is the first week of the year.
        }
      });

      return 'ru';
    }
  },
  'sv-se': {
    d3: {
      'decimal': ',',
      'thousands': '\u00a0',
      'grouping': [3],
      'currency': ['', 'SEK']
    },
    moment: () => {

      defineLocale('sv', {
        months : 'januari_februari_mars_april_maj_juni_juli_augusti_september_oktober_november_december'.split('_'),
        monthsShort : 'jan_feb_mar_apr_maj_jun_jul_aug_sep_okt_nov_dec'.split('_'),
        weekdays : 'söndag_måndag_tisdag_onsdag_torsdag_fredag_lördag'.split('_'),
        weekdaysShort : 'sön_mån_tis_ons_tor_fre_lör'.split('_'),
        weekdaysMin : 'sö_må_ti_on_to_fr_lö'.split('_'),
        longDateFormat : {
          LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'YYYY-MM-DD',
          LL : 'D MMMM YYYY',
          LLL : 'D MMMM YYYY [kl.] HH:mm',
          LLLL : 'dddd D MMMM YYYY [kl.] HH:mm',
          lll : 'D MMM YYYY HH:mm',
          llll : 'ddd D MMM YYYY HH:mm'
        },
        calendar : {
          sameDay: '[Idag] LT',
          nextDay: '[Imorgon] LT',
          lastDay: '[Igår] LT',
          nextWeek: '[På] dddd LT',
          lastWeek: '[I] dddd[s] LT',
          sameElse: 'L'
        },
        relativeTime : {
          future : 'om %s',
          past : 'för %s sedan',
          s : 'några sekunder',
          ss : '%d sekunder',
          m : 'en minut',
          mm : '%d minuter',
          h : 'en timme',
          hh : '%d timmar',
          d : 'en dag',
          dd : '%d dagar',
          M : 'en månad',
          MM : '%d månader',
          y : 'ett år',
          yy : '%d år'
        },
        dayOfMonthOrdinalParse: /\d{1,2}(e|a)/,
        ordinal : function (number) {
          let b = number % 10,
            output = (~~(number % 100 / 10) === 1) ? 'e' :
              (b === 1) ? 'a' :
                (b === 2) ? 'a' : 'e';
          return number + output;
        },
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 4  // The week that contains Jan 4th is the first week of the year.
        }
      });

      return 'sv';
    }
  },
  'uk-ua': {
    d3: {
      'decimal': ',',
      'thousands': '\u00a0',
      'grouping': [3],
      'currency': ['', '\u00a0₴.']
    },

    moment: () => {

      function plural(word, num) {
        let forms = word.split('_');
        return num % 10 === 1 && num % 100 !== 11 ? forms[0] : (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20) ? forms[1] : forms[2]);
      }
      function relativeTimeWithPlural(number, withoutSuffix, key) {
        let format = {
          'ss': withoutSuffix ? 'секунда_секунди_секунд' : 'секунду_секунди_секунд',
          'mm': withoutSuffix ? 'хвилина_хвилини_хвилин' : 'хвилину_хвилини_хвилин',
          'hh': withoutSuffix ? 'година_години_годин' : 'годину_години_годин',
          'dd': 'день_дні_днів',
          'MM': 'місяць_місяці_місяців',
          'yy': 'рік_роки_років'
        };
        if (key === 'm') {
          return withoutSuffix ? 'хвилина' : 'хвилину';
        } else if (key === 'h') {
          return withoutSuffix ? 'година' : 'годину';
        } else {
          return number + ' ' + plural(format[key], +number);
        }
      }
      function weekdaysCaseReplace(m, format) {
        let weekdays = {
          'nominative': 'неділя_понеділок_вівторок_середа_четвер_п’ятниця_субота'.split('_'),
          'accusative': 'неділю_понеділок_вівторок_середу_четвер_п’ятницю_суботу'.split('_'),
          'genitive': 'неділі_понеділка_вівторка_середи_четверга_п’ятниці_суботи'.split('_')
        };

        if (!m) {
          return weekdays['nominative'];
        }

        let nounCase = (/(\[[ВвУу]\]) ?dddd/).test(format) ?
          'accusative' :
          ((/\[?(?:минулої|наступної)? ?\] ?dddd/).test(format) ?
            'genitive' :
            'nominative');
        return weekdays[nounCase][m.day()];
      }
      function processHoursFunction(str) {
        return function () {
          return str + 'о' + (this.hours() === 11 ? 'б' : '') + '] LT';
        };
      }

      defineLocale('uk', {
        months : {
          'format': 'січня_лютого_березня_квітня_травня_червня_липня_серпня_вересня_жовтня_листопада_грудня'.split('_'),
          'standalone': 'січень_лютий_березень_квітень_травень_червень_липень_серпень_вересень_жовтень_листопад_грудень'.split('_')
        },
        monthsShort : 'січ_лют_бер_квіт_трав_черв_лип_серп_вер_жовт_лист_груд'.split('_'),
        weekdays : weekdaysCaseReplace,
        weekdaysShort : 'нд_пн_вт_ср_чт_пт_сб'.split('_'),
        weekdaysMin : 'нд_пн_вт_ср_чт_пт_сб'.split('_'),
        longDateFormat : {
          LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'DD.MM.YYYY',
          LL : 'D MMMM YYYY р.',
          LLL : 'D MMMM YYYY р., HH:mm',
          LLLL : 'dddd, D MMMM YYYY р., HH:mm'
        },
        calendar : {
          sameDay: processHoursFunction('[Сьогодні '),
          nextDay: processHoursFunction('[Завтра '),
          lastDay: processHoursFunction('[Вчора '),
          nextWeek: processHoursFunction('[У] dddd ['),
          lastWeek: function () {

            // eslint-disable-next-line
            switch (this.day()) {
            case 0:
            case 3:
            case 5:
            case 6:
              return processHoursFunction('[Минулої] dddd [').call(this);
            case 1:
            case 2:
            case 4:
              return processHoursFunction('[Минулого] dddd [').call(this);
            }
          },
          sameElse: 'L'
        },
        relativeTime : {
          future : 'за %s',
          past : '%s тому',
          s : 'декілька секунд',
          ss : relativeTimeWithPlural,
          m : relativeTimeWithPlural,
          mm : relativeTimeWithPlural,
          h : 'годину',
          hh : relativeTimeWithPlural,
          d : 'день',
          dd : relativeTimeWithPlural,
          M : 'місяць',
          MM : relativeTimeWithPlural,
          y : 'рік',
          yy : relativeTimeWithPlural
        },
        // M. E.: those two are virtually unused but a user might want to implement them for his/her website for some reason
        meridiemParse: /ночі|ранку|дня|вечора/,
        isPM: function (input) {
          return /^(дня|вечора)$/.test(input);
        },
        meridiem : function (hour, minute, isLower) {
          if (hour < 4) {
            return 'ночі';
          } else if (hour < 12) {
            return 'ранку';
          } else if (hour < 17) {
            return 'дня';
          } else {
            return 'вечора';
          }
        },
        dayOfMonthOrdinalParse: /\d{1,2}-(й|го)/,
        ordinal: function (number, period) {
          switch (period) {
          case 'M':
          case 'd':
          case 'DDD':
          case 'w':
          case 'W':
            return number + '-й';
          case 'D':
            return number + '-го';
          default:
            return number;
          }
        },
        week : {
          dow : 1, // Monday is the first day of the week.
          doy : 7  // The week that contains Jan 1st is the first week of the year.
        }
      });

      return 'uk';

    }
  },
  'zh-cn': {
    d3: {
      'decimal': '.',
      'thousands': ',',
      'grouping': [3],
      'currency': ['¥', '']
    },
    moment: () => {
      defineLocale('zh-cn', {
        months : '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
        monthsShort : '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
        weekdays : '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
        weekdaysShort : '周日_周一_周二_周三_周四_周五_周六'.split('_'),
        weekdaysMin : '日_一_二_三_四_五_六'.split('_'),
        longDateFormat : {
          LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'YYYY/MM/DD',
          LL : 'YYYY年M月D日',
          LLL : 'YYYY年M月D日Ah点mm分',
          LLLL : 'YYYY年M月D日ddddAh点mm分',
          l : 'YYYY/M/D',
          ll : 'YYYY年M月D日',
          lll : 'YYYY年M月D日 HH:mm',
          llll : 'YYYY年M月D日dddd HH:mm'
        },
        meridiemParse: /凌晨|早上|上午|中午|下午|晚上/,
        meridiemHour: function (hour, meridiem) {
          if (hour === 12) {
            hour = 0;
          }
          if (meridiem === '凌晨' || meridiem === '早上' ||
                  meridiem === '上午') {
            return hour;
          } else if (meridiem === '下午' || meridiem === '晚上') {
            return hour + 12;
          } else {
            // '中午'
            return hour >= 11 ? hour : hour + 12;
          }
        },
        meridiem : function (hour, minute, isLower) {
          let hm = hour * 100 + minute;
          if (hm < 600) {
            return '凌晨';
          } else if (hm < 900) {
            return '早上';
          } else if (hm < 1130) {
            return '上午';
          } else if (hm < 1230) {
            return '中午';
          } else if (hm < 1800) {
            return '下午';
          } else {
            return '晚上';
          }
        },
        calendar : {
          sameDay : '[今天]LT',
          nextDay : '[明天]LT',
          nextWeek : '[下]ddddLT',
          lastDay : '[昨天]LT',
          lastWeek : '[上]ddddLT',
          sameElse : 'L'
        },
        dayOfMonthOrdinalParse: /\d{1,2}(日|月|周)/,
        ordinal : function (number, period) {
          switch (period) {
          case 'd':
          case 'D':
          case 'DDD':
            return number + '日';
          case 'M':
            return number + '月';
          case 'w':
          case 'W':
            return number + '周';
          default:
            return number;
          }
        },
        relativeTime : {
          future : '%s内',
          past : '%s前',
          s : '几秒',
          ss : '%d 秒',
          m : '1 分钟',
          mm : '%d 分钟',
          h : '1 小时',
          hh : '%d 小时',
          d : '1 天',
          dd : '%d 天',
          M : '1 个月',
          MM : '%d 个月',
          y : '1 年',
          yy : '%d 年'
        },
        week : {
          // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
          dow : 1, // Monday is the first day of the week.
          doy : 4  // The week that contains Jan 4th is the first week of the year.
        }
      });

      return 'zh-cn';
    }
  }
};

const DEFAULT_LOCALE = 'en-us';