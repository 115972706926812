/**
 * Separated this function from DeltaImport.js so that if any application
 * wants to use only this function, they don't need to import entire 'quill' package
 * (like AI Embed application)
 * 
 * Converts a text string to a delta.
 * 
 * @param {string} text
 * 
 * @return {DeltaType}
 */
export function importText(text) {
  return {
    ops: [{
      insert: text + '\n'
    }]
  };
}