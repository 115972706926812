import { useEffect, useRef, useState } from 'react';
import { currentIdToken, waitForLogin } from '@store';
import { toast } from '../message';
import { storage } from '../utilities';
import { TABLES_BACKEND_DOMAIN as TBD, TABLES_BACKEND_ASGI_DOMAIN as TBAD, TABLES_FRONT_END_DOMAIN as TFED } from '../flags';
import { getState } from '../getState';
import { visibilityChangeHandler } from '../visibilityChange';

export const TABLES_BACKEND_DOMAIN = TBD;
export const TABLES_BACKEND_ASGI_DOMAIN = TBAD;
export const TABLES_FRONT_END_DOMAIN = TFED;

/**
 * @type {Object<string, { request: Promise<{ status: number, success: false }|{ success: true }> }>}
 */
let PENDING_REQUESTS = {};

/**
 * @type {Object<string, { timestamp: number, data: object }>}
 */
let CACHED_RESPONSES = {};

/**
 * @type {Array<() => Promise<void>>}
 */
let SYNC_FUNCTIONS = [];

/**
 *
 * @returns {Promise<Array<void>>}
 */
export function refetchAll() {
  return Promise.all(SYNC_FUNCTIONS.map((f) => f()));
}

function invalidateCache() {
  for (const cached of Object.values(CACHED_RESPONSES)) {
    cached.timestamp = 0;
  }
}

// To remove and reattach global visibility change listeners when we are attaching another ones in the hook
let removeGlobalVisibilityListener = visibilityChangeHandler(invalidateCache);
let globalVisibilityListenerUsageCounter = 0;

/**
 *
 * @param {() => void} onVisible
 */
export function useVisibilityChange(onVisible) {
  useEffect(() => {
    globalVisibilityListenerUsageCounter++;
    if (globalVisibilityListenerUsageCounter === 1) {
      removeGlobalVisibilityListener();
    }
    const removeListeners = visibilityChangeHandler(onVisible);
    return () => {
      removeListeners();
      globalVisibilityListenerUsageCounter--;
      if (globalVisibilityListenerUsageCounter === 0) {
        removeGlobalVisibilityListener = visibilityChangeHandler(invalidateCache);
      }
    };
  }, [onVisible]);
}

/**
 * @param {string} endpoint
 * @param {{ cache_seconds?: number, noAuth?: boolean }=} config
 */
export const useTables = (endpoint, config) => {
  let cached = null;
  if (config?.cache_seconds) {
    cached = CACHED_RESPONSES[endpoint];
    if (cached) {
      if (cached.timestamp + config.cache_seconds * 1000 > Date.now()) {
        // use it
      } else {
        cached = null;
      }
    }
  }

  const [loading, setLoading] = useState(cached ? false : true);
  const [data, setData] = useState(cached ? JSON.parse(cached.data.data) : null);
  const [error, setError] = useState(cached ? cached.data.error : false);
  const [errorStatusCode, setErrorStatusCode] = useState(cached ? cached.data.errorStatusCode : null);
  const lastLoading = useRef(endpoint);
  let refetchPromises = useRef([]);
  let syncPromises = useRef([]);

  function resolveSyncPromises() {
    syncPromises.current.map(f => f());
    syncPromises.current = [];
  }

  let [forceUpdate, setForceUpdate] = useState(0);

  useEffect(() => {
    if (cached && forceUpdate === 0) {
      if (!(JSON.stringify(data) === cached.data.data && error === cached.data.error && errorStatusCode === cached.data.errorStatusCode)) {
        // This can happen if the endpoint changes to something that has already been cached
        setErrorStatusCode(cached.data.errorStatusCode);
        setError(cached.data.error);
        setData(JSON.parse(cached.data.data));
        setLoading(false);
      }
      return;
    }

    (async function () {

      function updateStateFromCached() {
        let data = JSON.parse(CACHED_RESPONSES[endpoint].data.data);
        setData(data);
        setLoading(false);
        setError(false);
        setErrorStatusCode(null);
        refetchPromises.current.map(fn => fn(data));
        refetchPromises.current = [];
      }
      /**
       *
       * @param {boolean} error
       * @param {object} data
       * @param {boolean} loading
       * @param {number} status
       */
      function updateErrorState(error, data, loading, status) {
        if (CACHED_RESPONSES[endpoint]) {
          // Revalidation failed
          updateStateFromCached();
          return;
        }
        setError(error);
        setData(data);
        setLoading(loading);
        setErrorStatusCode(status);
      }

      /** @type {(data: { status: number, success: false }|{ success: true }) => void} */
      let pendingRequestResolve = null;
      if (PENDING_REQUESTS[endpoint]) {
        PENDING_REQUESTS[endpoint].request.then((data) => {
          if (data.success === true) {
            updateStateFromCached();
          } else {
            updateErrorState(true, null, false, data.status);
          }
          resolveSyncPromises();
          return data;
        });
        return;
      } else {
        PENDING_REQUESTS[endpoint] = {
          request: new Promise((resolve) => {
            pendingRequestResolve = resolve;
          })
        };
      }

      if (lastLoading.current !== endpoint) {
        setLoading(true);
      }
      lastLoading.current = endpoint;

      if (!config || !config.noAuth) {
        await waitForLogin();
      }

      /** @type {any} */
      let headers = {};
      if (!config || !config.noAuth) {
        headers['Authorization'] = `FBBEARER ${await currentIdToken()}`;
      }

      fetch(TABLES_BACKEND_DOMAIN + '/api/' + endpoint, { headers }).then(async (res) => {
        if (!res.ok) {
          updateErrorState(true, null, false, res.status);
          if (pendingRequestResolve) {
            pendingRequestResolve({ status: res.status, success: false });
          }
          PENDING_REQUESTS[endpoint] = null;
          resolveSyncPromises();
          return;
        }

        let data = await res.json();
        CACHED_RESPONSES[endpoint] = {
          timestamp: Date.now(),
          data: {
            error: false,
            errorStatusCode: null,
            // stringify/parse so we return a new instance for each read from the cache (in case something mutates it downstream)
            data: JSON.stringify(data),
          }
        };
        updateStateFromCached();
        if (pendingRequestResolve) {
          pendingRequestResolve({ success: true });
        }
        PENDING_REQUESTS[endpoint] = null;
        resolveSyncPromises();
      }).catch((_e) => {
        updateErrorState(true, null, false, null);
        if (pendingRequestResolve) {
          pendingRequestResolve({ status: null, success: false });
        }
        PENDING_REQUESTS[endpoint] = null;
        resolveSyncPromises();
      });
    })();
    // eslint-disable-next-line
  }, [endpoint, forceUpdate]);

  useEffect(() => {
    const sync = () => {
      if (cached) {
        let promise = new Promise((resolve) => {
          syncPromises.current.push(resolve);
        });
        cached.timestamp = 0;
        setForceUpdate(x => x + 1);
        return promise;
      }
    };
    SYNC_FUNCTIONS.push(sync);
    return () => {
      SYNC_FUNCTIONS = SYNC_FUNCTIONS.filter((f) => f !== sync);
    };
  }, [cached]);

  return {
    loading,
    error,
    data,
    errorStatusCode,
    refetch: () => {
      let promise = new Promise((resolve) => {
        refetchPromises.current.push(resolve);
      });
      setForceUpdate(x => x + 1);
      return promise;
    }
  };
};


/**
 * @param {string} endpoint
 * @param {string=} method
 * @param {object=} data - request data
 * @param {SafeTableRequestConfig|null} config
 */
export const doTableRequest = async (endpoint, method = 'GET', data = null, config = null) => {
  if (!config || !config.noAuth) {
    await waitForLogin();
  }

  const notifyWillWrite = method === 'GET' || !!config?.noNotify ? null : storage.notifyWillWrite();
  const headers = {
    'Content-Type': 'application/json'
  };
  if (!config || !config.noAuth) {
    headers['Authorization'] = `FBBEARER ${await currentIdToken()}`;
  }

  const state = getState();
  if (state.dbState?.clientSessionId) {
    headers['ClientSessionId'] = state.dbState.clientSessionId;
  }

  if ((!config || !config.ignoreWebSocketId) && state.dbState?.webSocketId) {
    headers['WebSocketId'] = state.dbState.webSocketId;
  }

  return fetch(TABLES_BACKEND_DOMAIN + '/api/' + endpoint, {
    headers,
    method,
    body: data ? JSON.stringify(data) : undefined
  }).then(async (res) => {
    if (res.status === 204) {
      return null;
    }
    try {
      return await res.json();
    } catch (err) {
      // @ts-ignore
      err.responseStatus = res.status;
      throw err;
    }
  }).finally(() => {
    if (notifyWillWrite) {
      notifyWillWrite();
    }
  });
};

/**
 * @typedef {object} SafeTableRequestConfig
 * @property {string=} toastMessage
 * @property {boolean=} noAuth
 * @property {string=} clientSessionId
 * @property {boolean=} ignoreWebSocketId
 * @property {boolean=} noNotify
 */

/**
 * @typedef {object} TableErrorResponse
 * @property {string} error
 * @property {string} detail
 */

/**
 * Safer version of doTableRequest with mandatory arguments and error message
 *
 * @param {string} endpoint
 * @param {string} method
 * @param {object|null} data - can be null if method is 'DELETE'
 * @param {SafeTableRequestConfig|null} config
 */
export const doSafeTableRequest = async (endpoint, method, data = null, config = null) => {
  if (!data && !['GET', 'DELETE'].includes(method)) {
    console.log('Bad arguments to safe table request');
    throw new Error('Missing data');
  }

  try {
    return await doTableRequest(endpoint, method, data, config);
  } catch (error) {
    if (config && config.toastMessage) {
      toast(config.toastMessage, { duration: 6000, intent: 'danger' });
    }
    throw error;
  }
};

/**
 * Properly convert a Baserow's error to a readable string.
 *
 * @param {string | object} detail
 * @returns {string}
 */
export const errorDetailToString = (detail) =>{
  let error;
  if (typeof detail === 'string') {
    error = detail;
  } else {
    // Baserow's validation can be a nested object, example:
    // {
    //   "slug": [
    //     {
    //       "error": "Ensure this field has at least 4 characters.",
    //       "code": "min_length"
    //     }
    //   ]
    // }
    let errors = [];
    for (let attr in detail) {
      for (const attrError of detail[attr]) {
        errors.push(`${attr}: ${attrError.error}`);
      }
    }

    error = errors.join(', ');
  }

  if (!error.endsWith('.')) {
    // always include a sentence terminating period at the end
    return `${error}.`;
  }

  return error;
};
