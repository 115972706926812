import React, { useState, useEffect, useRef } from 'react';
import SaveDone from '@mui/icons-material/Done';
import SyncIcon from '@mui/icons-material/Sync';
import { storage } from '../../utilities';
import Fade from '@mui/material/Grow';
import Chip from '@mui/material/Chip';
import { useIsMounted, useIsSmall } from '../../hooks';
import './autosave.css';


// Show the "Saving..." message for at least this long (milliseconds)
// even if it finishes faster.
const MIN_SAVE_SHOW = 700;

// Hide the saved indicator after this long (milliseconds)
const SAVED_HIDE_AFTER = 5000;
  

function AutoSaveNotificationBase() {
  let [saving, setSaving] = useState(null);
  let isMounted = useIsMounted();


  let timers = useRef({
    savingStart: null,
    showSavedTimeout: null,
    clearSaveTimeout: null,
  });

  let isSmall = useIsSmall();

  useEffect(() => {
    let fn = (state) => {
      if (!isMounted.current) {
        return;
      }
      if (timers.current.clearSaveTimeout) {
        clearTimeout(timers.current.clearSaveTimeout);
        timers.current.clearSaveTimeout = null;
      }
  
      if (state === 'SAVING') {
        timers.current.savingStart = Date.now();
        setSaving(true);
        if (timers.current.showSavedTimeout) {
          clearTimeout(timers.current.showSavedTimeout);
          timers.current.showSavedTimeout = null;
        }
      } else {
        timers.current.showSavedTimeout = setTimeout(() => {
          if (!isMounted) {
            return;
          }
          
          timers.current.clearSaveTimeout = setTimeout(() => {
            if (!isMounted) {
              return;
            }

            setSaving(null);
          }, SAVED_HIDE_AFTER);
  
          setSaving(false);
          timers.current.showSavedTimeout = null;
        }, Math.max(0, (timers.current.savingStart + MIN_SAVE_SHOW) - Date.now()));
      }
    };

    storage.onSaveStateChange('ON_SAVE', fn);

    return () => {
      storage.removeSaveStateChange('ON_SAVE');
    };
    // eslint-disable-next-line
  }, []);


  return <div style={{ display: saving === null ? 'none' : undefined }}>
    <Fade in={saving !== null} timeout={{ enter: 130, exit: 400 }}>
      <Chip
        title="Changes you make are saved automatically"
        style={{ color: 'inherit', border: 'solid 1px rgba(255,255,255, 0)' }}
        variant="outlined"
        size="small"
        label={saving ? 'Saving...' : (isSmall ? 'Saved' : 'All changes saved')}
        icon={saving ? <SyncIcon style={{ color: 'inherit', opacity: .7, animation: 'autosave-rotation 2s infinite linear' }} /> : <SaveDone style={{ color: 'inherit' }} />}
      />
    </Fade>
  </div>;
}


const AutoSaveNotification = React.memo(AutoSaveNotificationBase);
export default AutoSaveNotification;