import React from 'react';
import T from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import StateChange from './components/WaitFor/StateChange';
import { copyToClipboard } from './utilities';
import { messageCenter, toast } from './messageToast';
import MessageDisplay from './components/Dialog/MessageDisplay';
import ConfirmDialog from './components/Dialog/ConfirmDialog';
import SimpleDialog from './components/Dialog/SimpleDialog';
import DeleteDialog from './components/Dialog/DeleteDialog';
import DestructiveDialog from './components/Dialog/DestructiveDialog';
import PromptDialog from './components/Dialog/PromptDialog';
import SidebarPanel from './components/Dialog/SidebarPanel';
import CalloutPopper from './components/Dialog/CalloutPopper';

/**
 * @param {{ store: import('redux').Store, isOrg: () => boolean, }} param0 
 * @returns {React.ReactNode}
 */
export const dialogs = ({ store, isOrg }) => <>
  <MessageDisplay store={store} isOrg={isOrg} ref={(comp) => {
    messageCenter.snackbar = comp;
  }}/>
  <DeleteDialog ref={(comp) => {
    messageCenter.deleteDialog = comp;
  }}/>
  <ConfirmDialog ref={(comp) => {
    messageCenter.confirmDialog = comp;
  }}/>
  <PromptDialog ref={(comp) => {
    messageCenter.promptDialog = comp;
  }}/>
  <DestructiveDialog ref={(comp) => {
    messageCenter.destructiveDialog = comp;
  }}/>
  <SimpleDialog ref={(comp) => {
    messageCenter.simpleDialog = comp;
  }}/>
  <SidebarPanel ref={(comp) => {
    messageCenter.sidebarPanel = comp;
  }}/>
  {/** StateChange isn't usable in a non-store context (like the docs) */}
  {!!store && <StateChange ref={(comp) => {
    messageCenter.stateChange = comp;
  }}/>}
  <CalloutPopper ref={(comp) => {
    messageCenter.callout = comp;
  }} />
</>;

/**
 * Display a delete message. 
 * 
 * @param {object} config 
 * @param {string|React.ReactElement} config.item - name of the thing being deleted
 * @param {(string|React.ReactElement)=} config.extra - extra text to show
 * @param {(string|React.ReactElement)=} config.confirm - Optional text they must check the box by
 * @param {string=} config.confirmButtonText
 * @param {() => Promise} config.onDelete
 * @param {function():void=} config.onCancel
 */
export async function showDelete(config) {
  return messageCenter.deleteDialog.show(config);
}


/**
 * Display a confirm message. 
 * 
 * @param {object} config 
 * @param {string=} config.confirmButtonText
 * @param {React.ReactNode=} config.confirmButtonIcon
 * @param {string=} config.cancelButtonText
 * @param {React.ReactElement|string} config.contents
 * @param {(React.ReactElement|string)=} config.confirm - checkbox text if required
 * @param {('danger'|'primary')=} config.intent
 * @param {function():void} config.onConfirm
 * @param {function():void=} config.onCancel
 *
 */
export async function showConfirm(config) {
  messageCenter.confirmDialog.show(config);
}


/**
 * Prompts for a single line of text 
 * 
 * @param {object} config 
 * @param {string=} config.confirmButtonText
 * @param {string=} config.cancelButtonText
 * @param {React.ReactElement|string} config.contents
 * @param {string=} config.placeholder
 * @param {string=} config.default
 * @param {function():void} config.onConfirm
 * @param {function():void=} config.onCancel
 *
 */
export async function showPrompt(config) {
  messageCenter.promptDialog.show(config);
}


/**
 * Display a deletion message requiring the user to explicitly type confirmation.
 * 
 * @param {object} config 
 * @param {string} config.confirmation - the string the user must type to delete
 * @param {string=} config.confirmButtonText
 * @param {string=} config.cancelButtonText
 * @param {React.ReactElement} config.contents
 * @param {function(string):void} config.onDelete
 * @param {function():void=} config.onCancel
 */
export async function showDestructive(config) {
  return messageCenter.destructiveDialog.show(config);
}


/**
 * Displays a dialog with a close button.
 * 
 * @param {object} config 
 * @param {(string|React.ReactElement)=} config.title
 * @param {string=} config.closeButtonText
 * @param {object=} config.contentStyle
 * @param {React.ReactElement|function} config.contents
 * @param {function():void=} config.onClose
 */
export async function showDialog(config) {
  return messageCenter.simpleDialog.show(config);
}


/**
 * Displays a sidebar over-layed over all other content
 * 
 * @param {object} config 
 * @param {React.ReactElement} config.contents
 * @param {number=} config.width
 * @param {(React.ReactElement|string)=} config.title
 * @param {function():void=} config.onClose
 */
export async function showSidebarPanel(config) {
  return messageCenter.sidebarPanel.show(config);
}


/**
 * Displays a waiting for state change dialog
 * 
 * @param {import('./components/WaitFor/StateChange').StateChangeConfig} config
 * 
 * @return {function}
 */
export function showWaitForStateChange(config) {
  return messageCenter.stateChange.show(config);
}

/**
 * @param {object} config 
 * @param {string} config.btnText
 * @param {string|React.ReactNode} config.contents
 * @param {HTMLElement} config.anchorEl
 * @param {() => any} config.onAccept
 * @param {() => any} config.onCancel
 * @param {import('@mui/material').ButtonProps['startIcon']=} config.startIcon
 * @param {boolean} [config.requireExplicitClose]
 * @param {object[]} [config.modifiers]
 */
export const showCallout = (config) => {
  return messageCenter.callout.show(config);
};

/**
 * @param {string} text
 * @param {string} name
 */
export function copyLinkToClipboardWithToast(text, name) {
  return copyToClipboard(text).then(() => {
    toast(`Copied ${name} link to clipboard. Only users who have access to this folder can access the link`);
  });
}

/**
 * Display a deletion message requiring the user to explicitly type confirmation.
 * 
 * @param {object} config 
 * @param {string=} config.confirmButtonText
 * @param {string=} config.cancelButtonText
 * @param {(string|React.ReactElement)=} config.item
 * @param {React.ReactElement=} config.extra
 * @param {(string|React.ReactElement)=} config.confirm,
 * @param {() => any} config.onDelete
 * @param {(() => any)=} config.onCancel
 * @param {string=} config.pronoun - Defaults to `it`
 */
export async function showTrashConfirmation(config) {
  return showConfirm({
    contents: <>
      <T gutterBottom sx={{
        wordBreak: 'break-word'
      }}>
        Are you sure you want to move the {config.item} to the trash? {config.pronoun || 'It'} will be permanently deleted after a few days.
      </T>
      {config.extra && <T gutterBottom>{config.extra}</T>}
    </>,
    onConfirm: config.onDelete,
    onCancel: config.onCancel,
    confirm: config.confirm,
    confirmButtonText: 'Move to trash' || config.confirmButtonText,
    cancelButtonText: config.cancelButtonText,
    intent: 'danger',
    confirmButtonIcon: <DeleteIcon />
  });
}

export { toast, messageCenter };