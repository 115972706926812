import { configureScope, addBreadcrumb, withScope, captureException } from '@sentry/browser';
import { getIsWebpageEmbedded, isDev } from '../raw_flags';
import { compressLog } from './LogProto';
import { setExperimentUserId, setExperimentOrgId } from '../experiment/experiment';
import promiseRetry from 'promise-retry';
import { httpsCallable } from '../firebase_shared_driver';
import { safeLocalStorage } from '../localstorage_utility';
import psa from './psa';
import { sendToExtension } from '../extension';


let pulse;


/**
 * Pulls the stored cid and creates a new one if does not exist.
 * 
 * @return {string}
 */
function getCID() {

 
  const key = 'my-cid';
  if (!safeLocalStorage.getItem(key)) {
    safeLocalStorage.setItem(key, 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      // eslint-disable-next-line
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    }));
  }
  return safeLocalStorage.getItem(key);
}



export function logError(error, info) {
  if (!isDev()) {
    withScope(scope => {
      scope.setExtra('extra', info);
      captureException(error);
    });
  }
}


let debounceTimers = {};
/**
 * @param {{ category?: string, action: string, label?: object, value?: number, debounce?: number, resolveOnFinish?: boolean }} config
 * @param {{ group_id?: string, snippet_id?: string }} customDimensions
 */
export function log(config, customDimensions = {}) {
  if (getIsWebpageEmbedded()) { 
    return sendToExtension({ type: 'performLogging', args: [config, customDimensions], });
  }
  function labelToString(label) {
    if (!label) {
      return label;
    }
    if (typeof label === 'string') {
      return label;
    } else {
      return JSON.stringify(label, undefined, 2);
    }
  }

  function doLog() {
    let category = config.category || 'General';

    addBreadcrumb({
      message: config.action,
      category: category,
      data: {
        label: '' + labelToString(config.label),
        value: config.value,
        snippet_id: customDimensions.snippet_id,
        group_id: customDimensions.group_id,
      }
    });

    return addPulse({
      event: config.action,
      groupId: customDimensions.group_id,
      snippetId: customDimensions.snippet_id,
      data: labelToString(config.label),
      timestamp: '' + Math.floor(Date.now() / 1000),
      location: typeof window !== 'undefined' ? window.location.href : ''
    }, config.resolveOnFinish);
  }

  if (config.debounce && config.resolveOnFinish) {
    throw new Error('Cannot use both `debounce` and `resolveOnFinish`');
  }

  if (config.debounce) {
    let key = '' + config.category + config.action + labelToString(config.label) + JSON.stringify(customDimensions);
    if (debounceTimers[key]) {
      clearTimeout(debounceTimers[key]);
      delete debounceTimers[key];
    }
    debounceTimers[key] = setTimeout(() => doLog(), config.debounce);
  } else {
    return doLog();
  }
}

const PULSE_DELAY_SECONDS = 7;
let pulseTimeout;
let pulses = [];

/**
 * @param {object} data
 * @param {boolean=} immediate
 */
function addPulse(data, immediate = false) {
  pulses.push(data);

  if (immediate) {
    clearTimeout(pulseTimeout);
    pulseTimeout = null;

    return flushPulses();
  } else {
    if (pulseTimeout && pulses.length < 8) {
      clearTimeout(pulseTimeout);
      pulseTimeout = null;
    }
    if (!pulseTimeout) {
      pulseTimeout = setTimeout(flushPulses, PULSE_DELAY_SECONDS * 1000);
    }
  }
}


/**
 * Send all stored pulses to the server.
 */
function flushPulses() {
  let _pulses = [...pulses];
  pulses = [];
  if (!_pulses.length) {
    return;
  }
  let remoteFn = pulseAnonymous;
  if (typeof pulse === 'function') {
    remoteFn = pulse;
    // remove location from pulse
    for (const _pulse of _pulses) {
      delete _pulse.location;
    }
  }
  const logMessage = compressLog(_pulses);


    
  return promiseRetry((retry) => {
    return remoteFn({
      e: logMessage
    }).catch(retry);
  }, {
    minTimeout: 3000,
    retries: 3,
    factor: 3,
  }).catch((err) => {
    throw err;
  });
}


/**
 * @param {object} functions 
 */
export function setFunctions(functions) {
  pulse = httpsCallable(functions, 'ps');
}

function pulseAnonymous(data) {
  return psa({
    ...data,
    cid: getCID()
  });
}


/**
 * @param {string} uid 
 */
export function setLogUID(uid) {
  configureScope(scope => {
    scope.setUser({ id: uid });
  });

  setExperimentUserId(uid);
}

/**
 * @param {string} orgId 
 */
export function setLogOrgID(orgId) {
  setExperimentOrgId(orgId);
}