import React, { useEffect, useState } from 'react';
import { useTypedSelectorShallowEquals } from '../../hooks';
import {
  Avatar, AvatarGroup,
  CircularProgress,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography as T
} from '@mui/material';
import { toast } from '../../message';
import { doSafeTableRequest, useTables } from '../../hooks/useTables';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import SpaceIcon from '@mui/icons-material/TableChartOutlined';
import DatabaseIcon from '@mui/icons-material/TableChart';
import { OpenSpaceInNew } from '../Utilities/OpenInNewSpace';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


/**
 * @typedef {SpaceSummaryObjectType & {_inaccessible?: boolean}} SharedSpaceObjectType
 * @property {any} insert
 * @property {Object<string, any>=} attributes
 * @property {any=} insertObject
 */


/**
 * @param {object} props
 * @param {string} props.groupId
 */
function GroupSharedSpacesBase(props) {
  const group = useTypedSelectorShallowEquals((state) => state.dataState.groups[props.groupId]);
  if (!group) {
    return null;
  }

  const databaseIds = Object.keys(group.connected?.database_queries || {});
  if (!databaseIds.length) {
    return null;
  }

  return <GroupSharedSpacesLoader
    groupId={props.groupId}
    databaseIds={databaseIds}
  />;
}


/**
 * @param {object} props
 * @param {string[]} props.databaseIds
 * @param {string} props.groupId
 */
function GroupSharedSpacesLoader(props) {
  const [sharedSpaces, setSharedSpaces] = useState([]);
  const [checkedSpaces, setCheckedSpaces] = useState({});
  /** @type {{loading: boolean, data: SpaceSummaryObjectType[], error: boolean, refetch: function}} */
  let { loading, data, error, refetch } = useTables('applications/summary/', {
    cache_seconds: 30 * 60,
  });

  // loading = true;
  useEffect(() => {
    if (!loading) {
      const databaseIdsSet = new Set(props.databaseIds);
      setSharedSpaces(data.filter(database =>
        // some spaces can be shared with all folder users but removed from the connected settings
        databaseIdsSet.has(database.id) &&
        `g:${props.groupId}` in database.permissions
      ).sort(compareSpacesFn));
      setCheckedSpaces({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (!props.databaseIds.length) {
    return null;
  }

  if (error) {
    return <Alert severity="error" sx={{ mt: 3 }}>
      Error loading connected spaces.
    </Alert>;
  }

  const isShared = (connectedSpace) => {
    if (connectedSpace.id in checkedSpaces) {
      return checkedSpaces[connectedSpace.id];
    }

    return `g:${props.groupId}` in connectedSpace.permissions;
  };

  function compareSpacesFn(a, b) {
    const isSharedA = isShared(a);
    const isSharedB = isShared(b);
    if (isSharedA && !isSharedB) {
      return -1;
    }

    if (isSharedB && !isSharedA) {
      return 1;
    }

    return a.name.localeCompare(b.name);
  }

  /** @type {SharedSpaceObjectType[]} */
  let connectedSpaces = [];
  /** @type {SharedSpaceObjectType[]} */
  const inaccessibleSpaces = [];
  if (!loading) {
    for (const databaseId of props.databaseIds) {
      const space = data?.find(x => x.id === databaseId);
      if (space) {
        connectedSpaces.push(space);
      } else {
        inaccessibleSpaces.push({
          id: databaseId,
          name: 'Inaccessible space',
          icon: null,
          permissions: {},
          _inaccessible: true,
        });
      }
    }
  }

  connectedSpaces = connectedSpaces.sort(compareSpacesFn).concat(inaccessibleSpaces);

  if (!loading && !connectedSpaces.length) {
    return null;
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        border: 0,
        mt: 4,
      }}>
      <div style={{ display: 'flex ' }}>
        <T
          color="textSecondary"
          sx={{
            mb: 1.25,
            display: 'flex',
            width: 300,
          }}
          variant="body2"
          component="div"
        >
          <SpaceIcon
            fontSize="small"
            sx={{
              mt: 0.2,
              mr: 1.5,
              lineHeight: 1.43
            }}
          />
          <div>
          Share the <Link
              component={RouterLink}
              to={`/folder/${props.groupId}/connected`}
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
            connected spaces
            </Link> with all folder users.
          </div>
        </T>

        <Tooltip
          placement="left-end"
          title="When using the folder's snippets, allow all users to make requests to and from the space from the folder's snippets."
        >
          <Select
            multiple
            displayEmpty
            value={sharedSpaces}
            disabled={loading}
            placeholder="No shared spaces."
            sx={{
              height: 40,
              flexGrow: 1,
              minWidth: 0,
            }}
            renderValue={(selected) => {
              if (loading) {
                return <LinearProgress variant="indeterminate" sx={{ mt: 3 }}/>;
              }

              return <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 4 }}><b>{selected.length}</b> / {connectedSpaces.length} space{connectedSpaces.length !== 1 ? 's' : ''} shared</span>
                <AvatarGroup
                  max={3}
                  sx={{
                    flexGrow: 1,
                    '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 12, bgcolor: '#ddd', color: '#777' },
                  }}
                >
                  {selected.map(space => <Avatar
                    key={space.id}
                    sx={{ width: 24, height: 24, bgcolor: '#ddd' }}
                  >
                    {space.icon ? <span style={{ fontSize: '140%', position: 'relative', top: 1 }}>{space.icon}</span> : <DatabaseIcon fontSize="small" />}
                  </Avatar>)}
                </AvatarGroup>
              </div>;
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  width: 250,
                },
              },
            }}
          >
            {connectedSpaces.map(connectedSpace => <DatabaseItem
              key={connectedSpace.id}
              database={connectedSpace}
              groupId={props.groupId}
              isShared={isShared(connectedSpace)}
              onShared={async() => {
                setCheckedSpaces(v => Object.assign({}, v, { [connectedSpace.id]: true }));
                setSharedSpaces(v => [connectedSpace, ...v]);
                await refetch();
              }}
              onUnshared={async() => {
                setCheckedSpaces(v => Object.assign({}, v, { [connectedSpace.id]: false }));
                const index = sharedSpaces.findIndex(space => space.id === connectedSpace.id);
                setSharedSpaces(v => [...v.slice(0, index), ...v.slice(index + 1)]);
                await refetch();
              }}
            />)}
          </Select>
        </Tooltip>
      </div>
    </Paper>
  );
}


/**
 * @param {object} props
 * @param {SharedSpaceObjectType} props.database
 * @param {string} props.groupId
 * @param {() => void} props.onShared
 * @param {() => void} props.onUnshared
 * @param {boolean} props.isShared
 */
function DatabaseItem(props) {
  let [isUpdating, setIsUpdating] = useState(false);

  async function share() {
    setIsUpdating(true);

    try {
      let res = await doSafeTableRequest(`applications/${props.database.id}/`,
        'PATCH',
        {
          permissions: Object.assign({}, props.database.permissions, {
            [`g:${props.groupId}`]: { type: 'group' }
          })
        }, { toastMessage: 'Could not share with all folder users.' });
      if (res.error) {
        if (res.error === 'ERROR_USER_INSUFFICIENT_PERMISSIONS') {
          toast('Could not share with all users. You need to be an owner of the space to do this.', { intent: 'danger' });
        } else {
          toast('Could not share with all users.', { intent: 'danger' });
        }
      } else {
        props.onShared();
        toast('Space shared with all folder users. They will now be able to use snippets in the folder that connect with the space.', {
          intent: 'success',
          duration: 6000
        });
      }
    } catch {
      setIsUpdating(false);
      return;
    }

    setIsUpdating(false);
  }

  async function unshare() {
    setIsUpdating(true);

    let newPermissions = Object.assign({}, props.database.permissions);
    delete newPermissions[`g:${props.groupId}`];
    try {
      let res = await doSafeTableRequest(`applications/${props.database.id}/`,
        'PATCH',
        {
          permissions: newPermissions
        }, { toastMessage: 'Could not unshare folder.' });
      if (res.error) {
        if (res.error === 'ERROR_USER_INSUFFICIENT_PERMISSIONS') {
          toast('Could not unshare. You need to be an owner of the space to do this.', { intent: 'danger' });
        } else {
          toast('Could not unshare with all users.', { intent: 'danger' });
        }
      } else {
        props.onUnshared();
        toast('No longer shared with all folder users. They will no longer be able to use snippets in the folder that connect with the space unless the space is shared with them directly.', {
          intent: 'success',
          duration: 8000
        });
      }
    } catch {
      setIsUpdating(false);
      return;
    }

    setIsUpdating(false);
  }

  async function handleShareUnshare() {
    if (isUpdating || !!props.database._inaccessible) {
      return;
    }

    if (props.isShared) {
      await unshare();
    } else {
      await share();
    }
  }

  return (
    <Tooltip title={
      <React.Fragment>
        {(props.database.name || props.database.id)}
        {!!props.database._inaccessible && <>
          &nbsp;({props.database.id})
          This connected space is either deleted or you have no access to it, you may need to edit the <Link
            component={RouterLink}
            to={`/folder/${props.groupId}/connected`}
            sx={{
              whiteSpace: 'nowrap',
              textDecorationColor: 'white',
              color: 'white',
              '&:hover': {
                color: 'white !important',
              },
            }}
          >
          connected spaces
          </Link> to remove it.
        </>}
      </React.Fragment>
    }>
      <MenuItem
        value={props.database.id}
        sx={{
          height: 40,
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={handleShareUnshare}
      >
        {isUpdating ?
          <div style={{ paddingRight: 12, paddingTop: 10, paddingLeft: 5 }}><CircularProgress size={16}/>
          </div> : <Checkbox
            sx={{ pl: 0 }}
            disabled={!!props.database._inaccessible}
            checked={props.isShared}
          />}
        <div style={{ marginRight: 8 }}>
          {props.database?.icon ? <span style={{
            fontSize: 24,
          }}>{props.database.icon}</span> : <Avatar sx={{
            zoom: 0.6,
            backgroundColor: 'secondary.light',
            userSelect: 'none'
          }}><DatabaseIcon/></Avatar>}
        </div>
        <T
          sx={{
            flexGrow: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          color={props.database._inaccessible ? 'textSecondary' : undefined}
        >{props.database.name || props.database.id}</T>
        {!props.database._inaccessible && <span onClick={(e) => e.stopPropagation()}>
          <OpenSpaceInNew databaseID={props.database.id}/>
        </span>}
      </MenuItem>
    </Tooltip>
  );
}


const GroupSharedSpaces = React.memo(GroupSharedSpacesBase);
export default GroupSharedSpaces;