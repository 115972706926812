import { lexSQLWithCommands } from './Lexer';
import { ParseError } from './ParserUtils';
import nearley from 'nearley';
import sqlGrammar from '../equation_parser/sql_grammar';

/**
 * 
 * @param {string|any[]} val 
 * @param {import("./DataContainer").Environment} env 
 * @returns 
 */
export function astSQL(val, env) {
  let tokens;

  if (typeof val === 'string') {
    let lexed = lexSQLWithCommands(val, env);
    if (lexed.termination !== 'END_STRING') {
      throw new ParseError(val);
    }
    tokens = lexed.tokens;
  } else {
    tokens = val;
  }

    
  // @ts-ignore
  let parser = new nearley.Parser(sqlGrammar.ParserRules, sqlGrammar.ParserStart);
  try {
    // @ts-ignore
    parser.feed(tokens);
  } catch (err) {

    let str = tokens.map(x => x.source || '').join('');

    let tokenIndex = err.offset;

    let token = tokens[tokenIndex];

    let errorPosition = tokens[tokenIndex].position;
    let char = token.source.slice(0, 1);
    let snippet = str.slice(Math.max(errorPosition - 2, 0), errorPosition + 3);
    if (errorPosition > 2) {
      snippet = '…' + snippet;
    }
    if (errorPosition < str.length - 3) {
      snippet = snippet + '…';
    }

    let errObj = new ParseError(`unexpected “${char}” in “${snippet}”`);
    errObj.errorPosition = errorPosition;
    throw errObj;
  }

  if (parser.results !== undefined && parser.results[0] !== undefined) {
    if (parser.results.length > 1) {
      console.warn(`Query grammar had ${parser.results.length} results`, val, parser.results);
    }
    return parser.results[0];
  } else {
    let err = new ParseError('unexpected end of query');
    let str = tokens.reduce((count, t) => count + t.source, '');
    err.errorPosition = str.trimRight().length - 1;
    throw err;
  }
}
