import React, { useEffect, useRef, useState } from 'react';
import { waitForLogin } from '@store';
import { log } from '../../logging/logging';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from '../../message';
import Button from '@mui/material/Button';
import { AlertTheme } from '../Theme/MUITheme';
import SnippetMetadata from '../Snippet/SnippetMetadata';
import SnippetEditor from '../SnippetEditor/SnippetEditor';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { importText } from '../../import_export/DeltaImport';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { compressDelta } from '../../delta_proto/DeltaProto';
import { memberRestricted, checkOrgRestrictionsLoaded } from '../../flags';
import AsyncButton from '../AsyncButton/AsyncButton';
import NewSnippet from '../GroupSelectors/NewSnippet';
import { EmptyState } from '../EmptyState/EmptyState';
import { useIsMedium, useIsNewUserAndFromAllowedReferrer, useTypedSelector } from '../../hooks';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import T from '@mui/material/Typography';
import SnippetWrapper from '../Snippet/SnippetWrapper';
import { createGroup, createSnippet } from '../../data';
import { isAiBlaze } from '../../aiBlaze';


const LEAVE_WARNING = 'Are you sure you want to leave without saving the draft?';

/**
 * 
 * @param {{ search: string, textParam: string }} props 
 * @returns 
 */
function CreateSnippetInner({ search, textParam }) {
  const [name, setName] = useState('');
  const [shortcut, setShortcut] = useState('');
  const [quickentry, setQuickentry] = useState(false);
  const [delta, setDelta] = useState(/** @type DeltaType */ (undefined));
  const [loading, setLoading] = useState(true);
  const [editorId, setEditorId] = useState(Math.random().toString(32));
  const [showCommands, setShowCommands] = useState(false);
  let [didAutoImport, setDidAutoImport] = useState(false);
  const { push: navigate } = useHistory();

  const select = useSelect();
  const disableAdd = useTypedSelector(store => memberRestricted(store, 'create'));
  const loaded = useTypedSelector(store => checkOrgRestrictionsLoaded(store));

  const unloadWarning = useRef(null);
  const showNavigateWarning = useRef(true);

  let isMedium = useIsMedium();
  const shouldDoAutoImport = useIsNewUserAndFromAllowedReferrer();

  let [aiAction, setAiAction] = useState(/** @type {'chat'|'write'|'polish'} */ ('write'));
  let [aiIncludePageContext, setAiIncludePageContext] = useState(true);

  useEffect(() => {
    unloadWarning.current = function (e) {
      e.returnValue = LEAVE_WARNING;
    }; 

    window.addEventListener('beforeunload', unloadWarning.current);

    return () => window.removeEventListener('beforeunload', unloadWarning.current);
  }, []);

  useEffect(() => {
    if (shouldDoAutoImport && !loading && loaded && !didAutoImport) {
      async function createSnippetFunc() {
        try {
          // avoid this running again and if it errors, allow the user to import manually
          setDidAutoImport(true);

          const groupId = await createGroup({
            name: 'Imported snippet',
          }, true);

          const snippet = {
            type: /** @type {const} */ ('delta'),
            delta: compressDelta(delta),
            name: name.trim() ? name : 'New imported snippet',
            shortcut,
            group_id: groupId,
            options: { quick_entry: quickentry },
          };

          const newSnippet = await createSnippet(snippet, true, true, false);

          log(
            { action: 'New snippet auto imported', label: { referrer: document.referrer, source: 'CreateSnippet' } },
            snippet
          );

          navigate(`/snippet/${newSnippet.snippet_id}?preview=true`);
        } catch (e) {
          toast('Could not import snippet automatically', { intent: 'danger' });
          console.error(e);
        }
      }

      createSnippetFunc();
    }
  }, [delta, shouldDoAutoImport, loaded, loading, name, shortcut, navigate, quickentry, didAutoImport]);


  useEffect(() => {
    waitForLogin().then(() => {
      let name = 'New Snippet';
      let shortcut = '/new';
      let quickentry = false;
      /** @type {DeltaType} */
      let delta = null;

      let source;

      if (textParam) {
        delta = importText(decodeURIComponent(textParam));
        source = 'contextmenu';
      } else {
        let params = new URLSearchParams(search);

        if (params.has('source')) {
          source = params.get('source');
        } else {
          source = 'sandbox';
        }

        if (params.has('name') && params.get('name').trim()) {
          name = params.get('name');
        }

        if (params.has('quickentry')) {
          quickentry = params.get('quickentry') === 'true';
        }

        if (params.has('shortcut') && params.get('shortcut').trim()) {
          shortcut = params.get('shortcut');
        }

        if (params.has('delta')) {
          try {
            delta = JSON.parse(decodeURIComponent(atob(params.get('delta'))));
          } catch (err) {
            toast('Invalid snippet base.', { intent: 'danger' });
            showNavigateWarning.current = false;
            select('page', 'welcome');
            return;
          }
        }

        if (params.has('action')) {
          const value = params.get('action');
          if (['write', 'polish', 'chat'].includes(value)) {
            // @ts-ignore
            setAiAction(value);
          }
        }

        if (params.has('text')) {
          delta = importText(params.get('text'));
        }

        if (!delta) {
          delta = { ops: [] };
        }
      }


      log({ action: 'Review snippet', label: source });

      setName(name);
      setShortcut(shortcut);
      setQuickentry(quickentry);
      setDelta(delta);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancel = () => {
    showNavigateWarning.current = false;
    select('page', 'welcome');
  };

  const save = async (createSnippetFn) => {
    if (name.trim() === '' || shortcut.trim() === '') {
      toast('You must specify a name and shortcut.', { intent: 'danger' });
      return;
    }

    const aiOptions = isAiBlaze
      ? /** @type {SnippetObjectType['options']} */ ({
        is_ai: true,
        include_page_context: aiIncludePageContext,
        ai_action: {
          updated_at: Date.now(),
          action: aiAction,
        },
        // TODO: remove polish_mode
        polish_mode: aiAction === 'polish',
      })
      : {};

    const snippet = {
      type: 'delta',
      delta: compressDelta(delta),
      name: name,
      shortcut: shortcut,
      options: {
        quick_entry: quickentry,
        ...aiOptions
      }
    };

    return createSnippetFn(snippet, false, true).then(data => {
      showNavigateWarning.current = false;
      select('snippet', data.snippet_id);
    }).catch((_e) => {
      // Error, let's wait for them to try to fix it
      // Error should be displayed by the erroring function with a toast
    });
  };

  const handleName = (name) => setName(name);

  const handleShortcut = (shortcut) => setShortcut(shortcut);

  const getValue = () => {
    let val = {
      id: editorId,
      delta: delta
    };

    return val;
  };

  /**
   * @type {Parameters<(typeof SnippetEditor)>[0]['onChange']}
   */
  const handleEditor = (type, value, id) => {
    if (type === 'delta') {
      setEditorId(id);
      setDelta(value);
    } else if (type === 'quickentry') {
      setQuickentry(value);
    } else if (type === 'ai_action_user') {
      setAiAction(value);
    } else if (type === 'include_page_context') {
      setAiIncludePageContext(value);
    }
  };

  if (disableAdd) {
    return <EmptyState
      icon="ERROR"
      title="Cannot create snippets"
      description="Your account cannot create snippets. Please contact your Text Blaze administrator."
    />;
  }


  if (shouldDoAutoImport) {
    return <Wrapper isMedium={isMedium}>
      <div style={{ paddingTop: 40, marginBottom: 30, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <T variant="h4" mb={9}>Copying folder to your account</T>
        <CircularProgress size={120} thickness={1.9}/>
      </div>
    </Wrapper>;
  }

  if ((loading || !loaded)) {
    return <Wrapper isMedium={isMedium}>
      <div style={{ paddingTop: 30, marginBottom: 30, justifyContent: 'center', display: 'flex' }}
        data-testid="loading-create-snippet">
        <CircularProgress size={120} thickness={1.9}/>
      </div>
    </Wrapper>;
  }
  

  return <Wrapper isMedium={isMedium}>
    <Prompt message={() => {
      if (showNavigateWarning.current) {
        return LEAVE_WARNING;
      }
      return true;
    }}/>
    <AlertTheme>
      <div style={{
        overflow: 'hidden',
        paddingBottom: 3,
        marginRight: 12,
        marginLeft: isMedium ? 12 : 0
      }}>
        <AppBar position="static" elevation={0} style={{
          borderRadius: 8
        }}>
          <Toolbar style={{
            display: 'flex'
          }}>
            <T variant="body2" sx={{ padding: 1, paddingRight: 4, flex: 1 }}>This is a snippet draft you can review and save. If you navigate away from this page without saving, the draft will be discarded.</T>

            <Button color="secondary" onClick={cancel} sx={{ marginRight: 2 }}>Discard</Button>
            <NewSnippet component={(ref, createSnippetFn) => {
              return <AsyncButton ref={ref} variant="outlined" color="secondary" onClick={(done) => save(createSnippetFn).finally(done)}>
                      Save
              </AsyncButton>;
            }} />
          </Toolbar>
        </AppBar>
      </div>
    </AlertTheme>

    <SnippetWrapper style={{
      position: 'relative',
      overflow: 'auto'
    }}>
      <div style={{
        gridColumnStart: 'main-start',
        gridColumnEnd: 'sidebar-start',
        gridRowStart: 'main-start',
        gridRowEnd: 'editor-start',
        display: 'flex',
        alignItems: 'center'
      }}>
        <SnippetMetadata
          editable
          name={name}
          shortcut={shortcut}
          handleName={handleName}
          handleShortcut={handleShortcut}
        />
      </div>

      <ErrorBoundary style={{ paddingTop: 30 }}>
        <SnippetEditor
          value={getValue()}
          editable
          owner
          onChange={handleEditor}
          isAI={isAiBlaze}
          aiAction={aiAction}
          includePageContext={aiIncludePageContext}
          preview
          quickentry={quickentry}
          showCommands={showCommands}
          setShowCommands={setShowCommands}
        />
      </ErrorBoundary>
    </SnippetWrapper>
  </Wrapper>;
};

const CreateSnippetInnerMemoized = React.memo(CreateSnippetInner);

const CreateSnippet = () => {
  const { text: textParam } = /** @type {{ text: string }} */ (useParams());
  let { search, hash } = useLocation();
  if (search.length <= 1) {
    search = hash.slice(1);
  }
  const params = new URLSearchParams(search);
  const key = textParam || params.get('text') || params.get('delta');
  // To remount the create snippet inner in case of navigation
  return <CreateSnippetInnerMemoized key={key} search={search} textParam={textParam} />;
};

export default CreateSnippet;

const useSelect = function () {
  const { push: navigate } = useHistory();

  return (kind, id) => {
    if (kind === 'page') {
      navigate('/' + id);
    } else {
      navigate('/' + kind + '/' + id);
    }
  };
};


/**
 * @param {object} props
 * @param {boolean} props.isMedium
 * @param {any} props.children 
 * @returns 
 */
function Wrapper(props) {
  return <div style={{ height: '100%', maxHeight: '100%', paddingLeft: props.isMedium ? 0 : 20, paddingTop: 12, display: 'flex', flexDirection: 'column' }}>
    {props.children}
  </div>;
}