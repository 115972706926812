import RemoteStore from './RemoteStore';


/**
 * This class caches DB loads so we don't load the same
 * thing repeatedly.
 * 
 * The cache key are the SQL and other request parameters.
 */

export default class SQLStore extends RemoteStore {
  static NO_FINGERPRINT_KEYS = ['name', 'isloading', 'haserror', 'trim', 'labelName', 'finish', 'begin', 'error'];

  /**
   * @param {any} res
   * @param {string} fingerprint
   * @returns {void}
   */
  updateCache(res, fingerprint) {
    this.cached[fingerprint].result = res.status === 'error' ? res.message : res;
    this.cached[fingerprint].status = res.status;
    if (res.status === 'error') {
      this.cached[fingerprint].onError.forEach(fn => fn(res.message));
    } else {
      this.cached[fingerprint].onDone.forEach(fn => fn(res));
    }
  }

  /**
   * @param {import('../../snippet_processor/ParseNode').InfoType} obj 
   * @returns {string}
   */
  static getFingerprint(obj) {
    return super.getFingerprint(obj, SQLStore);
  }

  /**
   * @param {import('../../snippet_processor/ParseNode').InfoType} data - the info of the node
   * @param {import('./RemoteStore').HandlerFunctionType} onDone - called when successfully loaded
   * @param {import('./RemoteStore').HandlerFunctionType} onError - called when error occurs
   * @param {{ ms: number, id: string }=} debounce - whether to debounce
   * @returns {boolean}
   */
  request(data, onDone, onError, debounce) {
    return super.request(data, onDone, onError, debounce, SQLStore);
  }
}