import React from 'react';


export function highlightResults(text, highlight) {
  if (highlight) {
    const textUpper = text.toUpperCase();
    const highlightUpper = highlight.toUpperCase();
    let previousIndex = 0;
    const parts = [];

    // split text by highlight as a separator, case-insensitive
    while (true) {
      const nextIndex = textUpper.indexOf(highlightUpper, previousIndex);
      if (nextIndex === -1) {
        parts.push(text.slice(previousIndex));
        break;
      }

      parts.push(text.slice(previousIndex, nextIndex));
      parts.push(text.slice(nextIndex, nextIndex + highlight.length));

      previousIndex = nextIndex + highlight.length;
    }

    return (
      <>
        {parts.map((part, index) => index % 2 ?
          <strong key={index}>{ part }</strong> :
          <React.Fragment key={index}>{ part }</React.Fragment>
        )}
      </>
    );
  } else {
    return <>{ text }</>;
  }
}
