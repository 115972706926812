/**
 * @param {string} val
 * @returns {string} column value to be used in bsql
 */
export const getColumnValue = (val) => {
  if (val === '__%%_rowid') {
    return 'rowid()';
  }
  if (val.startsWith('__%%_linkrow_id_')) {
    return val.slice('__%%_linkrow_id_'.length) + '->id';
  }
  return val;
};

/**
 * @param {string} val
 * @returns {string} column name to be shown in UI
 */
export const getColumnDisplayFromValue = (val, valueMapping = null) => {
  if (valueMapping && (val in valueMapping)) {
    return valueMapping[val];
  }

  if (val === '__%%_rowid') {
    return 'Row ID';
  } else if (val.endsWith('->id')) {
    return val.slice(0, val.length - '->id'.length) + ' ID';
  } else if (val.startsWith('__%%_linkrow_id_')) {
    return val.slice('__%%_linkrow_id_'.length) + ' ID';
  }

  return val;
};

export const FIELD_TO_TYPE = {
  text: 'string',
  long_text: 'string',
  single_select: 'string',
  multiple_select: 'list',
  email: 'string',
  phone_number: 'string',
  url: 'string',
  date: 'datetime',
  created_on: 'datetime',
  last_modified: 'datetime',
  boolean: 'boolean',
  rating: 'number',
  number: 'number',
  formula: 'formula',
  lookup: 'list',
  link_row: 'list',
  link: 'named_list',
  multiple_collaborators: 'list',
  link_row_id: 'list', // pseudo field
  row_id: 'string', // pseudo field
  rollup: 'formula',
  count: 'formula',
  created_by: 'string',
  last_modified_by: 'string',
  autonumber: 'number',
  uuid: 'string',
  duration: 'string'
  // TODO: 'file'
};
