import { toStr } from '../../snippet_processor/Equation';


/**
 * This class caches remote data loads so we don't load the same
 * thing repeatedly.
 * 
 * The cache key are the url and other request parameters.
 */

/**
 * @typedef {(data: object) => void} HandlerFunctionType
 */

export default class RemoteStore {
  static NO_FINGERPRINT_KEYS = ['start', 'done', 'trim', 'finish', 'begin', 'error'];

  /**
   * @param {import('../../snippet_processor/DataContainer').Config} config
   */
  constructor(config) {
    this.config = config;

    /** @type {Record<string, { status: 'success'|'error'|'pending', result: object, onDone: HandlerFunctionType[], onError: HandlerFunctionType[] }>} */
    this.cached = Object.create(null);

    /** @type {Record<string, { timeoutId: number }>} */
    this.timeouts = Object.create(null);
  }

  /**
   * @param {import('../../snippet_processor/ParseNode').InfoType} data
   * @returns {boolean}
   */
  hasCached(data, cls = RemoteStore) {
    let fingerprint = cls.getFingerprint(data);
    return fingerprint in this.cached;
  }

  /**
   * @param {import('../../snippet_processor/ParseNode').InfoType} data - the info of the node
   * @param {HandlerFunctionType} onDone - called when successfully loaded
   * @param {HandlerFunctionType} onError - called when error occurs
   * @param {{ ms: number, id: string }=} debounce - whether to debounce
   * @param {typeof RemoteStore} cls class whose static method will be called
   * 
   * @return {boolean} - true if we have data already and we shouldn't trigger the start
   */
  request(data, onDone, onError, debounce = null, cls = RemoteStore) {
    let fingerprint = cls.getFingerprint(data);
    if (fingerprint in this.cached) {
      if (this.cached[fingerprint].status === 'success') {
        onDone(this.cached[fingerprint].result);
        return true;
      } else if (this.cached[fingerprint].status === 'error') {
        onError(this.cached[fingerprint].result);
        return true;
      } else {
        this.cached[fingerprint].onDone.push(onDone);
        this.cached[fingerprint].onError.push(onError);
      }
    } else {
      if (debounce && this.timeouts[debounce.id]) {
        clearTimeout(this.timeouts[debounce.id].timeoutId);

        this.timeouts[debounce.id] = {
          timeoutId: window.setTimeout(() => {
            this.cached[fingerprint] = {
              result: null,
              status: 'pending',
              onDone: [onDone],
              onError: [onError]
            };
  
            this.doRequest(fingerprint, data);
          }, debounce.ms)
        };
      } else {
        if (debounce) {
          // If we have a debounce but there isn't a `this.timeouts[debounce.id]`,
          // then this is the first run, we want to execute the query immediately,
          // and we created the timeouts object so we know we've run already for future
          // runs.
          this.timeouts[debounce.id] = { timeoutId: null };
        }

        this.cached[fingerprint] = {
          result: null,
          status: 'pending',
          onDone: [onDone],
          onError: [onError]
        };

        this.doRequest(fingerprint, data);
      }
    }
    return false;
  }

  /**
   * @param {import('../../snippet_processor/ParseNode').InfoType} obj 
   * @returns {string}
   */
  static getFingerprint(obj, cls = RemoteStore) {
    let res = '';

    let keys = Object.keys(obj);
    keys.sort();

    for (let key of keys) {
      if (!cls.canFingerprintProperty(key)) {
        // These properties don't matter for the url parameters
        continue;
      }
      res += key + '---';
      if (obj[key] === null || obj[key] === undefined) {
        res += '' + obj[key];
      } else if (typeof obj[key] === 'object') {
        res += JSON.stringify(obj[key]);
      } else {
        res += toStr(obj[key]);
      }
    }

    return res;
  }


  /**
   * @param {string} key
   * @returns {boolean}
   */
  static canFingerprintProperty(key) {
    return !this.NO_FINGERPRINT_KEYS.includes(key);
  }


  /**
   * @param {string} fingerprint 
   * @param {import('../../snippet_processor/ParseNode').InfoType} data 
   * @returns {Promise<void>}
   */
  async doRequest(fingerprint, data) {
    let res;
    // TODO: Report errors to sentry when all the users are shifted to extension and app handling network error in fetch API call itself
    try {
      res = await this.config.remoteFn({
        info: data
      });
    } catch (e) {
      console.warn('RemoteStore fetch error caught', e);
      res = { status: 'error', message: 'Network error' };
    }

    this.updateCache(res, fingerprint);
  }

  /**
   * @param {any} res
   * @param {string} fingerprint
   * @returns {void}
   */
  updateCache(res, fingerprint) {
    this.cached[fingerprint].result = res;
    this.cached[fingerprint].status = 'success';
    this.cached[fingerprint].onDone.forEach(fn => fn(res));
  }
}