export const SNIPPET_HEIGHT = 34;

export const FOLDER_HEADER_HEIGHT = 36;

/**
 * @param {{ selected: boolean, selectable?: boolean, depth: number }} config
 * @return {React.CSSProperties}
 */
export let itemStyleFn = (config) => {
  let { selected, depth, selectable } = config;
  let backgroundColor, borderRadius, color;

  if (selected && selectable !== false) {
    backgroundColor = 'rgba(0, 188, 212, 0.12)';
    borderRadius = '4px';
    color = '#008ba3';
  } else if (selected) {
    //For disabled folders and team folders
    backgroundColor = 'rgba(0, 188, 212, 0.12)';
    borderRadius = '4px';
  }

  let res = {
    paddingLeft: 44 * (depth || 0) - 12,
    paddingTop: 3,
    paddingBottom: 3
  };


  if (borderRadius) {
    res.borderRadius = borderRadius;
  }
  if (backgroundColor) {
    res.backgroundColor = backgroundColor;
  }
  if (color) {
    res.color = color;
  }
  
  return res;
};


/**
 * @param {boolean} icon - whether the list item has an icon
 * 
 * @return {React.CSSProperties}
 */
export let innerDivStyle = (icon) => ({
  padding: icon ? '4px 4px 4px 0px' : '2px 2px 2px 2px',
  userSelect: 'none',
  fontSize: '16px'
});


/** @return {React.CSSProperties} */
export let iconStyleFn = (config) => {
  let { selected } = config;
  
  let res = {
    marginRight: 0,
    marginLeft: 2,
    fontSize: 28,
  };

  if (selected) {
    res.color = '#008ba3';
  }

  return res;
};
