import React from 'react';
import {
  Fade,
  Typography as T
} from '@mui/material';
import UsedIcon from '@mui/icons-material/CheckOutlined';



/**
 * 
 * @param {object} props 
 * @param {string} props.description
 * @param {number} props.hasError
 * @param {boolean} [props.isUsed]
 * @param {string} props.label
 * @param {object} [props.advancedEditorComponent]
 * @param {object} [props.removeAttributeComponent]
 * @param {React.ReactNode} props.menuAutoConditionalsComponent
 * @returns 
 */
export default function HeaderInformation(props) {
  return <>
    <div style={{
      display: 'flex',
      alignItems: 'center'
    }}>
      {props.isUsed && <UsedIcon fontSize="small" style={{ marginRight: 8, opacity: .5 }} />}<T variant="subtitle2" style={{ flex: 1 }}>
        {props.label}
        {props.advancedEditorComponent || null}
        {props.hasError ? <Fade in timeout={200}>
          <T variant="caption" color="textSecondary" sx={{
            ml: 2.25,
            color: 'warning.main'
          }}>(unsaved)</T>
        </Fade> : null}
      </T>
      {props.removeAttributeComponent}
      {props.menuAutoConditionalsComponent}
    </div>
    <T variant="caption" color="textSecondary">{props.description}</T>
  </>;
}