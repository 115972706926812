/**
 * @param {string} rawText 
 * @returns {{ key: string, modifiers: string[], }}
 */
export function extractKeys(rawText) {
  if (rawText === undefined || rawText === null) {
    return {
      key: '',
      modifiers: []
    };
  }
  /** @type {string[]} */
  let parts = rawText.split('-');
  // If the last key was a minus key, the last two elements
  // in the array would be blank strings. So we handle that case separately
  if (parts.length >= 2 && parts[parts.length - 1] === '' && parts[parts.length - 2] === '') {
    // Ignore the last two blank strings,
    // and replace them with the minus key
    parts = parts.slice(0, -2).concat(['-']);
  }
  let key = parts.pop();
  let modifiers = parts.map(x => x.toLowerCase());
  return {
    key,
    modifiers
  };
}