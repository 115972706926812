import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel
} from '@mui/material';
import ReactDOM from 'react-dom';
import AsyncButton from '../AsyncButton/AsyncButton';

const ConfirmDialog = forwardRef((_props, ref) => {
  let [open, setOpen] = useState(false);
  let [config, setConfig] = useState(null);
  let [confirmChecked, setConfirmChecked] = useState(false);

  useImperativeHandle(ref, () => ({
    show: (config) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setOpen(true);
        setConfig(config);
        setConfirmChecked(false);
      });
    }
  }));

  const hide = () => {
    setOpen(false);
    setConfig(null);
  };

  const cancel = () => {
    if (config.onCancel) {
      config.onCancel();
    }
    hide();
  };

  const accept = async (done) => {
    await config.onConfirm();
    hide();
    done();
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={cancel}
      maxWidth="sm"
    >
      <DialogContent
        sx={{
          minWidth: {
            xs: 'min(calc(100% - 40px), 370px)',
            sm: '370px'
          }
        }}>
        {config.contents}
        {config.confirm ?
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmChecked}
                onChange={() => setConfirmChecked(!confirmChecked)}
                value="confirmChecked"
                color="primary"
              />
            }
            label={config.confirm}
          /> : null}

      </DialogContent>

      <DialogActions style={{ marginRight: 10, marginBottom: 15 }}>
        <Button onClick={cancel} variant="outlined" style={{ marginRight: 12 }}>{config.cancelButtonText || 'Cancel'}</Button>
        <AsyncButton
          disabled={config.confirm && !confirmChecked}
          startIcon={config.confirmButtonIcon}
          color={config.intent === 'danger' ? 'error' : 'primary'}
          onClick={accept}
          variant="contained"
        >{config.confirmButtonText || 'Ok'}</AsyncButton>
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmDialog;