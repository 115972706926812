import React, { useState, useCallback, useMemo } from 'react';
import SnippetPreview from '../SnippetPreview/SnippetPreview';
import Shortcut from '../Shortcut/Shortcut';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { userMemberData } from '../../engine_utilities';
import { sync } from '../../Sync/syncer';
import { orgPref, checkPro, checkOrg, isOrgOwner, getAddonInstallation } from '../../flags';
// @ts-ignore
import previewArrow from '../../../images/arrow_left_down.png';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { toast } from '../../message';
import { getState } from '../../getState';
import { snippetPreviewRemoteFn } from '../SnippetPreview/preview_utilities';
import { useHistory } from 'react-router-dom';
import { selectorFn } from '../../snippet_processor/DownstreamProcess';

/**
 * @param {object} props
 * @param {Pick<SnippetObjectType, 'shortcut'|'options'|'group_id'|'id'>} props.snippet
 * @param {string} props.snippetId
 * @param {DeltaType|import('quill/core').Delta} props.delta
 * @param {import('../../flags').ConnectedConfig=} props.connected
 * @param {boolean=} props.hideShortcut - hides the shortcut on the top
 * @param {boolean=} props.hideProMessage - hides pro message on the bottom
 * @param {boolean=} props.unsafe - don't allow potentially sensitive things such as remote data loading
 * @param {object=} props.style style of the root element
 * @param {(import('../../snippet_processor/DownstreamProcess').NativeFindSnippetFn|((arg: string)=>void))=} props.findSnippet
 * @param {(items: import('../FormRenderer/FormRenderer').RemoteProgressItemType[]) => void} [props.onRemoteStatusUpdate]
x */
function SnippetPreviewPanelBase(props) {
  const { push: navigate } = useHistory();
  let [domGenerated, setDomGenerated] = useState(false);

  let {
    isPro,
    isBusiness,
    email,
    memberFields,
    memberFieldsData,
    commandWhitelist,
    locale
  } = useTypedSelectorShallowEquals(store => ({
    isPro: checkPro(store),
    isBusiness: checkOrg(store),
    email: store.userState.email,
    memberFields: store.orgState && store.orgState.org && store.orgState.org.member_fields,
    memberFieldsData: store.userState.member_fields_data,
    commandWhitelist: orgPref(store, 'shouldWhitelistCommands') && (orgPref(store, 'commandWhitelist') || []),
    locale: store.userState.locale,
  }));


  // We wait for `domGenerated` to avoid flashing/jank
  let domGenerateCallback = useCallback(() => {
    setDomGenerated(true);
  }, []);

  const findSnippetDefault = (name) => {
    let snippets = sync.getSnippetsByShortcut(name.toLocaleLowerCase());
    if (snippets && snippets.length) {
      return {
        delta: snippets[0].data.content.delta.toUint8Array()
      };
    }
  };
  const findSnippet = props.findSnippet || findSnippetDefault;

  let configMemo = useMemo(() => {
    return /** @type {import('../../snippet_processor/DataContainer').Config} */ ({
      addons: sync.activeAddons(),
      snippet: {
        id: props.snippetId,
        shortcut: props.snippet ? props.snippet.shortcut : '/example',
        trigger: props.snippet ? props.snippet.shortcut : '/example',
        folderid: props.snippet ? props.snippet.group_id : 'group123'
      },
      user: userMemberData(
        email || 'user@example.com',
        memberFields,
        memberFieldsData
      ),
      locale: locale,
      findSnippet,
      remoteFn: snippetPreviewRemoteFn,
      commandWhitelist: commandWhitelist,
      selectorFn: function (item) {
        return selectorFn(this.usedSiteTabSelections, item);
      },
    });
  }, [commandWhitelist, email, locale, memberFields, memberFieldsData, props.snippet, props.snippetId, findSnippet]);


  let connectedConfigureFn = useCallback(() => {
    navigate('/folder/' + props.snippet.group_id + '/connected');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.snippet.group_id]);


  let addonConfigureFn = useCallback((namespace) => {
    let addonId = null;
    for (let id in sync.groups) {
      if (sync.groups[id] && sync.groups[id].isAddon() && sync.groups[id]?.data?.options?.addon?.namespace === namespace) {
        addonId = sync.groups[id]?.data?.associated_addon_id;
        break;
      }
    }
    if (!addonId) {
      return toast('Could not find command pack to update. Please navigate to it directly.', {
        intent: 'danger'
      });
    }

    let state = getState();
    let addonInstallData = getAddonInstallation(addonId, state.userState, state.orgState);

    if (addonInstallData.installedBy === 'organization' && !isOrgOwner(state)) {
      toast('You must be an administrator in your organization to update the permissions. Please ask your organization administrator to do so.', {
        intent: 'danger'
      });
    } else {
      navigate('/packs/' + addonId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{
      padding: 0,
      display: domGenerated ? undefined : 'none',
      ...(props.style || {}),
    }}>
      <ErrorBoundary style={{ paddingTop: 30 }}>
        <div style={{
          padding: '14px 20px 0px 20px',
          position: 'relative',
          display: props.hideShortcut ? 'none' : 'block'
        }}>
          <p style={{ width: '100%', backgroundColor: '#eee', textAlign: 'center', color: 'transparent', fontWeight: 'bold', overflow: 'hidden', height: 22 }}>PREVIEW</p>
          <div style={{ position: 'relative' }}>
            <p style={{ width: '32%', backgroundColor: '#eee', textAlign: 'center', color: 'transparent', fontWeight: 'bold', display: 'inline-block', height: 22 }}>PREVIEW</p>
            <Shortcut
              shortcut={props.snippet && props.snippet.shortcut}
              style={{
                marginLeft: 4,
                opacity: 0.85,
                maxWidth: '80%'
              }}
            />
            <img src={previewArrow} alt="Will insert" style={{
              opacity: 0.35,
              width: 34,
              position: 'absolute',
              paddingTop: 5,
              paddingLeft: 0
            }} />

           
          </div>
        </div>
        <SnippetPreview
          delta={props.delta}
          isPro={isPro}
          isBusiness={isBusiness}
          connected={props.connected}
          quickentry={props.snippet ? (props.snippet.options && props.snippet.options.quick_entry) || false : undefined}
          domGeneratedFn={domGenerateCallback}
          config={configMemo}
          connectedConfigureFn={connectedConfigureFn}
          addonConfigureFn={addonConfigureFn}
          hideProMessage={props.hideProMessage}
          unsafe={props.unsafe}
          onRemoteStatusUpdate={props.onRemoteStatusUpdate}
        />
      </ErrorBoundary>
    </div>
  );
}

const SnippetPreviewPanel = React.memo(SnippetPreviewPanelBase);
export default SnippetPreviewPanel;