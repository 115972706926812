import React, { useState } from 'react';
import PositionalListIcon from '@mui/icons-material/FormatListNumbered';
import KeyedListIcon from '@mui/icons-material/DnsOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RepeatIcon from '@mui/icons-material/Repeat';
import TableIcon from '@mui/icons-material/TableView';
import SentenceIcon from '@mui/icons-material/Subject';
import { Box, Chip, Menu, MenuItem, Divider, IconButton, Tooltip } from '@mui/material';
import T from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { escapeFormName, isSimpleName } from './editor_utilities';
import { showPrompt } from '../../message';


/**
 * @param {string} root 
 * @param {string} name 
 */
function combineRootAndName(root, name) {
  if (name[0] === '[') {
    return root + name;
  } else if (isSimpleName(name)) {
    return root + '.' + name;
  } else {
    return root + '["' + name.replaceAll('"', '\\"') + '"]';
  }
}

/**
 * 
 * @param {object} props
 * @param {boolean=} props.plainInsert
 * @param {string} props.name
 * @param {object} props.type
 * @param {{ insert?: function, onChange?: function }} props.actions
 * @param {boolean=} props.inAttribute
 * @returns 
 */
export function NameChip(props) {
  let [show, setShow] = useState(null);
  
  let { name, type } = props;

  // Don't show if we're not a list, or we're a keyed list but have no known keys
  // example of the last case ({zz=[x: x for x in ["a","B", "c"]]})
  let isListWithMenu = (type?.type === 'KLIST' && Object.keys(type.value).length) || type?.type === 'LIST';

  // always show it for lists, but don't show it only for "change name" when it's in an attribute
  let showMenu = isListWithMenu || !props.inAttribute;

  return <>
    {show && showMenu && <TypedMenu
      showChangeName={!props.inAttribute}
      name={escapeFormName(name)}
      type={type}
      actions={props.actions}
      targetEl={show}
      onClose={() => {
        setShow(null);
      }}
      plainInsert={props.plainInsert}
    />}

    <Tooltip title={name}>
      <Chip
        label={name}
        size="small"
        variant="outlined"
        sx={{
          marginRight: '6px',
          maxWidth: 'calc(100% - 6px)',
          paddingRight: '3px',
          paddingLeft: type?.type === 'KLIST' || type?.type === 'LIST' ? '4px' : ''
        }}
        key={name}
        onClick={() => {
          if (props.plainInsert) {
            props.actions.insert(escapeFormName(name));
          } else {
            props.actions.insert('{=' + escapeFormName(name) + '}');
          }
        }}
        icon={type?.type === 'KLIST' ? <KeyedListIcon /> : (type?.type === 'LIST' ? <PositionalListIcon /> : null)}
        deleteIcon={showMenu ? <MoreHorizIcon /> : null}
        onDelete={showMenu
          ? (e) => {
            setShow(e.target); 
          }
          : null}
      />
    </Tooltip>
  </>;

}


/**
 * @param {object} props
 * @param {Function=} props.onVariableNameChange
 * @param {Function} props.onClose
 * @param {string} props.name
 */
function ChangeNameMenuItem(props) {
  const handleContinue = (newName) => {
    if (!newName) {
      return;
    }

    if (props.onVariableNameChange) {
      props.onVariableNameChange(props.name, newName);
    }
  };

  return (
    <MenuItem
      onClick={() => {
        showPrompt({
          contents: <T>Change variable name</T>,
          onConfirm: handleContinue,
          confirmButtonText: 'Rename',
        });

        props.onClose();
      }}
    >
      <EditOutlinedIcon
        fontSize="small"
        sx={{
          mr: 1,
          opacity: .7,
        }}
      />

      Change name...
    </MenuItem>
  );
}

/**
 * @param {object} props
 * @param {string} props.name
 * @param {object} props.type
 * @param {HTMLElement} props.targetEl
 * @param {function} props.onClose
 * @param {boolean=} props.plainInsert
 * @param {object} props.actions
 * @param {boolean=} props.showChangeName
 * @returns
 */
export function TypedMenu(props) {
  let { name, type } = props;

  return <Menu
    anchorEl={props.targetEl}
    open
    onClose={() => props.onClose()}
    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
  >

    {props.showChangeName
      ? <ChangeNameMenuItem
        name={name}
        onClose={props.onClose}
        onVariableNameChange={props.actions.onVariableNameChange}
      />
      : null}

    {type?.type === 'LIST' || type?.type === 'KLIST'
      ? <Divider />
      : null}

    {type?.type === 'LIST' ?
      (props.plainInsert ? [] : (type.value?.type === 'KLIST' ? [<MenuItem key="table"
        onClick={() => {
          let rows = [[],[]];
          for (let key of Object.keys(type.value.value)) {
            rows[0].push({
              insert: key,
              attributes: {
                bold: true
              }
            });
            rows[1].push('{=' + combineRootAndName('row', key) + '}');
          }
          rows[1][0] = '{repeat: for row in ' + name + '}' + rows[1][0];
          rows[1][rows[1].length - 1] += '{endrepeat}';
          props.actions.insertTable(rows);
          props.onClose();
        }}
      ><TableIcon
          fontSize="small"
          sx={{
            mr: 1,
            opacity: .7,
          }}
        /> Insert table</MenuItem>] : [<MenuItem
        key="join"
        onClick={() => {
          props.actions.insert('{=join(' + name + ', "BLAZE_AND")}');
          props.onClose();
        }}
      ><SentenceIcon
          fontSize="small"
          sx={{
            mr: 1,
            opacity: .7,
          }}
        /> Insert sentence</MenuItem>]).concat([
        <MenuItem
          key="repeat"
          onClick={() => {
            props.actions.insert('{repeat: for item in ' + name + '}\n{=item}\n{endrepeat}');
            props.onClose();
          }}
        ><RepeatIcon
            fontSize="small"
            sx={{
              mr: 1,
              opacity: .7,
            }}
          /> Insert repeat</MenuItem>
      ]).concat([
        <Divider key="div-index" />
      ])).concat([
        <TypedMenuItem
          key="first-list-t"
          root={name}
          label="First list item"
          name="[1]"
          type={type.value}
          actions={props.actions}
          plainInsert={props.plainInsert}
          onClose={() => props.onClose()}
        />,

        <TypedMenuItem
          key="last-list-t"
          root={name} label="Last list item"
          name="[-1]"
          type={type.value}
          actions={props.actions}
          plainInsert={props.plainInsert}
          onClose={() => props.onClose()}
        />
      ])
      : null}

    {type?.type === 'KLIST' ?
      [
        <T
          variant="subtitle2"
          key="_label"
          color="textSecondary"
          sx={{
            mx: 2,
          }}
        >Available keys</T>,

      ].concat(Object.keys(type.value).map(key => {
        return <TypedMenuItem key={key} root={name} name={key} type={type.value[key]} actions={props.actions} plainInsert={props.plainInsert} onClose={() => props.onClose()} />;
      }))
      : null}
  </Menu>;
}

function TypedMenuItem(props) {
  let { root, name, type, label } = props;
  let [show, setShow] = useState(null);

  // Don't show if we're not a list, or we're a keyed list but have no known keys
  // example of the last case ({zz=[x: x for x in ["a","B", "c"]]})
  let hasMenu = (type?.type === 'KLIST' && Object.keys(type.value).length) || type?.type === 'LIST';

  return <>
    {show && <TypedMenu
      {...props}
      name={combineRootAndName(root, name)}
      targetEl={show}
      onClose={() => {
        if (props.onClose) {
          props.onClose();
          return;
        }
        setShow(null);
      }}
      plainInsert={props.plainInsert}
    />}
    <MenuItem
      style={{ display: 'flex' }}
      onClick={() => {
        if (props.plainInsert) {
          props.actions.insert(combineRootAndName(root, name));
        } else {
          props.actions.insert('{=' + combineRootAndName(root, name) + '}');
        }
        props.onClose();
      }}
    >
      {type?.type === 'KLIST' ? <KeyedListIcon
        fontSize="small"
        sx={{
          mr: 1,
          opacity: .7,
        }}
      /> : (type?.type === 'LIST' ? <PositionalListIcon
        fontSize="small"
        sx={{
          mr: 1,
          opacity: .7,
        }}
      /> : <Box sx={{
        mr: 1,
        width: '20px'
      }}></Box>)}
      <div style={{ flex: 1 }}>
        {label || (isSimpleName(name) ? <><span style={{ opacity: 0.4 }}>{root + '.'}</span>{name}</> : <><span style={{ opacity: 0.4 }}>{root + '["'}</span>{name}<span style={{ opacity: 0.4 }}>"]</span></>)}
      </div>
      {hasMenu ? <IconButton
        size="small"
        onClick={e => {
          setShow(e.target);
          e.stopPropagation();
        }}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton> : null}
    </MenuItem>
  </>;

}

