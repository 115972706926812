import {
  waitForPendingWrites,
  serverTimestamp,
  getFirestore,
  getDocs,
  getDoc,
  onSnapshot,
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  collection,
  doc,
  where,
  orderBy,
  limit,
  query
} from 'firebase/firestore';
import { getAuth, getIdToken, getIdTokenResult } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';


export let firebaseDriver = {
  waitForPendingWrites,
  serverTimestamp,
  getDocs,
  getDoc,
  onSnapshot,
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  getFirestore,
  getAuth,
  getIdToken,
  getIdTokenResult,
  collection,
  doc,
  where,
  orderBy,
  limit,
  query,
  getFunctions,
  httpsCallable,

  /**
   * @param {import('firebase/firestore').QueryDocumentSnapshot} ref
   */
  docExists(ref) {
    return ref.exists();
  }
};

