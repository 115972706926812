import React from 'react';
import VerificationReminder from '../VerificationReminder/VerificationReminder';
import T from '@mui/material/Typography';
import { reauthenticate, userPermission } from '../../auth';
import { getAuth, deleteUser } from 'firebase/auth';
import { getState } from '../../getState';
import { toast, showDestructive, showConfirm, showWaitForStateChange } from '../../message';
import AsyncButton from '../AsyncButton/AsyncButton';
import BillingDetails from '../BillingDetails/BillingDetails';
import { deleteGroup } from '../../data';
import { getExtensionType } from '../../extension';
import Button from '@mui/material/Button';
import { log } from '../../logging/logging';
import moment from 'moment';
import { ReferIcon } from '../ReferDialog/refer_utils';
import { checkPro, orgId, orgName, checkPayingPro, orgAutoJoinDomains, orgLoadedIfNeeded, isElectronApp, DESKTOP_APP_NAME } from '../../flags';
import { ChangeEmail } from './ChangeEmail';
import { unsubscribePro, cancelUnsubscribePro, leaveOrganization } from '../../bapi';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { CircularProgress } from '@mui/material';
import { ChangePassword } from './ChangePassword';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { signoutLocalApp, sendSignoutMessages, signout } from '../../signout';
import { useHistory, useLocation } from 'react-router-dom';
import { generateAppRoute } from '../../hooks/useAppNavigate';
import useOnMount from '../../hooks/useOnMount';
import { isAiBlaze } from '../../aiBlaze';
import { fullAppName } from '../../raw_flags';
import { deleteUserAccount } from '../../firebase-api';

const token = new URLSearchParams(window.location.search).get('token');

export default function AccountPanel() {
  const { push: navigate, replace } = useHistory();
  const { hash } = useLocation();
  const {
    loaded,
    isPro,
    isPayingPro,
    proGrantExpiry,
    willCancel,
    email,
    emailVerified,
    myOrgName,
    myOrgId,
    accountAge,
    canTryToLeaveOrg,
    isSoleOrgOwner
  } = useTypedSelectorShallowEquals(store => {
    let uid = store.userState.uid;

    if (!uid) {
      // This is to prevent zombie redux updates. It is probably not needed as long as app is
      // wrapped in a connect(). Can test by seeing if error is triggered on logout.

      // not logged in
      return {};
    }

    let email = store.userState.email.toLowerCase();
    let autoJoinDomains = orgAutoJoinDomains(store);
    let canTryToLeaveOrg = !autoJoinDomains || !autoJoinDomains.includes(email.split('@')[1]);

    let org = store.orgState && store.orgState.org;
    let isSoleOrgOwner = false;
    if (org) {
      let perms = org.permissions;
      // we can define the org object before the permission have loaded
      // so check that we have the permissions
      if (perms) {
        if (
          perms['u:' + uid]?.type !== 'owner'
          && perms['e:' + email]?.type !== 'owner'
        ) {
          // not an owner
          // leave isSoleOrgOwner as false
        } else {
          isSoleOrgOwner = Object.values(perms).filter(x => x.type === 'owner').length === 1;
        }
      }
    }

    let accountAge = null;
    if ((Date.now() - new Date(store.userState.firebaseUser.metadata.creationTime).getTime()) > 24 * 60 * 60 * 1000) {
      // Show thank you message if over than a day old
      accountAge = moment(store.userState.firebaseUser.metadata.creationTime).fromNow(true);
    }

    return {
      loaded: store.userState.settingsLoaded && store.userState.readonlyLoaded && orgLoadedIfNeeded(store),
      isSoleOrgOwner,
      isPro: checkPro(store),
      isPayingPro: checkPayingPro(store),
      proGrantExpiry: store.userState.pro_grant_expiry && moment(store.userState.pro_grant_expiry.toDate()).format('LL'),
      accountAge,
      willCancel: store.userState.will_cancel,
      email,
      emailVerified: store.userState.emailVerified,
      myOrgName: orgName(store),
      myOrgId: orgId(store),
      canTryToLeaveOrg
    };
  });
  useOnMount(() => {
    setTimeout(() => {
      if (token) {
        replace({ hash });
      }
    }, 500);
  });

  if (!loaded) {
    return <div
      style={{ paddingTop: '5vh', marginBottom: 80, justifyContent: 'center', display: 'flex' }}
      data-testid="loading-indicator"
    >
      <CircularProgress size={150} thickness={1.9} />
    </div>; 
  }

  const deleteAccountButtonText = 'Permanently delete';
  const attemptDeleteAccount = () => {
    showDestructive({
      confirmButtonText: deleteAccountButtonText,
      cancelButtonText: 'Cancel',
      contents: <>
        <T paragraph>Are you sure you want to <b>permanently delete</b> your account associated with <b>{email}</b>? Deleting your account will delete all your snippets, folders and templates.</T>
        <T paragraph>Deleting an account <b>is permanent and cannot be undone</b>.</T>
        <T paragraph>To delete your account, type "<span translate="no">PERMANENTLY DELETE</span>" (in all caps) into the field below and click the <b>{deleteAccountButtonText}</b> button.</T>
      </>,
      confirmation: 'PERMANENTLY DELETE',
      onDelete: (confirmation) => {
        if (confirmation !== 'PERMANENTLY DELETE') {
          return toast('Incorrect confirmation.', {
            duration: 8000,
            intent: 'danger'
          });
        }
  
        return reauthenticate().then(async () => {
          log({
            category: 'Admin',
            action: 'Delete account'
          });

          sendSignoutMessages();

          await signoutLocalApp(email, token);

          let state = getState();
          let groups = state.dataState.groups;

          // checked in the user_settings sync to not recreate groups
          window['deleting_user'] = true;
          let groupDeletions = [];
          for (let groupId in groups) {
            let group = groups[groupId];
            let permissions = group.permissions;
            // Only delete groups that we are an owner on and that has no other members
            if (userPermission(group) === 'owner' && Object.values(permissions).length === 1) {
              // Check to see if it is part of an org; if it is, we don't do an automated deletion as the org
              // owners still have access and the group may be used.
              // The user can of course manually delete the group if they want.
              if (!group.associated_org_id) {
                groupDeletions.push(deleteGroup(groupId));
              }
            }
          }

          await Promise.all(groupDeletions);

          await deleteUserAccount({
            confirmation: confirmation,
            uid: 'me'
          });
          
          await deleteUser(getAuth().currentUser);
          window['deleting_user'] = false;
  
          toast(`Your account has been deleted. If you have not already done so, uninstall the ${isAiBlaze ? 'AI Blaze' : 'Text Blaze'} ${getExtensionType()} or the ${DESKTOP_APP_NAME}.`, {
            duration: 20000,
            intent: 'success'
          });
        }).catch(err => {
          console.error(err);
          toast('Could not reauthenticate. ' + (typeof err === 'string' ? err : JSON.stringify(err)) + ' Please try again.', {
            duration: 8000,
            intent: 'danger'
          });
        });
      }
    });

  };

  const attemptDowngrade = () => {
    showConfirm({
      cancelButtonText: 'Keep Pro',
      confirmButtonText:'Downgrade',
      intent: 'danger',
      contents: <>
        <T paragraph>If you downgrade, you will lose access to {fullAppName} Pro features such as forms and images in snippets.</T>
        <T paragraph>When you downgrade, your subscription will not renew and you will lose access to Pro features at the end of your current billing period.</T>
        <T paragraph>Are you sure you want to downgrade?</T>
      </>,
      onConfirm: () => {
        function failure(msg) {
          toast('Failed to unsubscribe. Please try again in a few minutes. If this error persists, contact <support@blaze.today> for assistance.', {
            duration: 8000,
            intent: 'danger'
          });
    
          log({
            category: 'Purchase',
            action: 'Unsubscribe failure'
          });
    
          console.warn(msg);
        }
    
        unsubscribePro().then(() => {
          log({
            category: 'Purchase',
            action: 'Unsubscribe'
          });
          toast(`You have been successfully unsubscribed from ${fullAppName} Pro.`, {
            duration: 4000,
            intent: 'success'
          });
        }).catch((err) => {
          failure(err);
        });
      }
    });
  };

  const yourPlan = () => {
    if (myOrgName) {
      return <div>
        <T paragraph color="textSecondary">You have Text Blaze Business. This account is managed by <b>{myOrgName}</b>.</T>
        {canTryToLeaveOrg && <AsyncButton
          onClick={(done) => {
            if (isSoleOrgOwner) {
              toast('You cannot leave an organization that you are the sole administrator of. Either delete the organization, or add another administrator and then try again.', {
                duration: 12000,
                intent: 'danger'
              });
              done();
              return;
            }

            showConfirm({
              cancelButtonText: 'Stay',
              confirmButtonText: 'Leave organization',
              intent: 'danger',
              contents: <>
                <T paragraph>Organization management allows the organization to control aspects of your Text Blaze account such as what folders are enabled.</T>
                <T paragraph>Removing management of this account may remove your access to some Text Blaze features.</T>
                <T paragraph>Are you sure you want to make this account unmanaged?</T>
              </>,
              onConfirm: () => {
                let clearStateChange = showWaitForStateChange({
                  message: 'Leaving organization...',
                  onSuccessMessage: 'You have been removed from the organization.',
                  checkFn: (state) => !state.userState.org
                });
                
                leaveOrganization({}).then(() => {
                  done();
                  log({
                    category: 'Organization',
                    action: 'Left org',
                    label: {
                      orgId: myOrgId
                    }
                  });
                }).catch(err => {
                  done();
                  clearStateChange();
                  toast('Could not remove from organization. ' + ((err && err.message) || ''), {
                    duration: 8000,
                    intent: 'danger'
                  });
                  log({
                    category: 'Organization',
                    action: 'Left org error',
                    label: {
                      orgId: myOrgId
                    }
                  });
                });
              },
              onCancel: () => done()
            });
          }}
        >Leave organization</AsyncButton>}
      </div>;
    } else {
      if (isPayingPro) {
        if (willCancel) {
          return <div>
            <T paragraph color="textSecondary">You have canceled your Pro subscription. You will lose access to Pro features at the end of your current payment period (<b>{(new Date(willCancel * 1000)).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</b>).</T>
            <AsyncButton
              variant="contained"
              color="primary"
              onClick={(done) => cancelDowngrade().then(done, done)}
            >Reactivate to keep Pro</AsyncButton>
          </div>;
        } else {
          return <>
            <T paragraph color="textSecondary">Congratulations, you have Text Blaze Pro!</T>
            <BillingDetails email={email} />
            
            <T variant="body2" color="textSecondary" style={{ marginTop: 24, zoom: .9, marginLeft: 10 }}>
              <a
                href="#downgrade"
                onClick={() => attemptDowngrade()}
                style={{ opacity: 0.7, color: '#555' }}
              >Downgrade to Basic</a>
            </T>
            <div>
              <T paragraph color="textSecondary" style={{ marginBottom: 20, marginTop: 20 }}>Are you part of a team or company? Seamlessly collaborate with your team with Text Blaze Business.</T>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate('/upgrade')}
              >Learn more about Text Blaze Business</Button>
            </div>
          </>;
        }
      } else {
        if (isPro) {
          return <div>
            <T paragraph><ReferIcon width={24}/> You have a trial of {fullAppName} Pro. This trial will end on {proGrantExpiry}. Upgrade to Pro to ensure you don't lose Pro features then (we won't bill you until your trial ends).</T>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/pro')}
            >Learn more about Pro</Button>
          </div>;
        } else if (!isAiBlaze) {
          return <div>
            <T paragraph>You are using the Basic version of Text Blaze. Text Blaze Pro has a number of additional features and capabilities. Currently you do not have Pro.</T>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/pro')}
            >Learn more about Pro</Button>
          </div>;
        }
      }
    }
  };

  const referrals = () => {
    if (!myOrgId) {
      return <>
        <T gutterBottom variant="h6" style={{ marginTop: 20 }}>Invite a friend</T>
        <T paragraph color="textSecondary">
          When they accept your invite and verify their email, you will get $10 credit.
        </T>
        <Button
          style={{ marginBottom: 18 }}
          variant="outlined"
          color="primary"
          onClick={() => {
            log({ action: 'OPEN_REFERRAL_DIALOG' });

            document.location.hash = 'refer';
          }}
          startIcon={<ReferIcon width={24} />}
        >Invite a friend</Button>
      </>;
    }
  };

  const cancelDowngrade = () => {
    return new Promise((resolve, reject) => {
      function failure(msg) {
        toast('Failed to cancel unsubscribe. Please try again in a few minutes. If this error persists, contact <support@blaze.today> for assistance.', {
          duration: 8000,
          intent: 'danger'
        });
        log({
          category: 'Purchase',
          action: 'Cancel unsubscribe failure'
        });
        reject();
        console.warn(msg);
      }
      
      cancelUnsubscribePro().then(() => {
        log({
          category: 'Purchase',
          action: 'Cancel unsubscribe'
        });
        toast('Your Text Blaze Pro subscription has been reactivated.', {
          duration: 4000,
          intent: 'success'
        });
        resolve();
      }).catch((err) => {
        reject();
        failure(err);
      });
    });
  };

  

  return <>
    <T gutterBottom variant="h6">Your plan</T>

    {yourPlan()}

    {referrals()}

    <br/>
    <T gutterBottom variant="h6">Your account</T>

    <T paragraph color="textSecondary">You are signed in as <b>{email}</b>. {accountAge && <>Thank you for using Text Blaze for {accountAge}!</>}</T>

    {emailVerified ? null : <VerificationReminder/>}

    {(!myOrgId) ? <div style={{ marginTop: emailVerified ? 0 : 20, marginBottom: 20 }}>
      <ChangeEmail/>
      <ChangePassword/>
    </div> : null}


    <T gutterBottom variant="h6">Import/Export</T>


    <T paragraph color="textSecondary">Import snippets from other applications and export your own snippets for offline backup.</T>

    <Button
      startIcon={<ImportExportIcon />}
      onClick={() => {
        navigate('/configure/importexport');
      }}
      sx={{
        mb: 2
      }}
    >Import/Export </Button>


    <T gutterBottom variant="h6">Sign out</T>

    <T paragraph color="textSecondary">Note that signing out of {fullAppName} will disable snippet completion on this computer. You can sign in again to re-enable snippets.</T>

    <div>
      <Button
        onClick={() => {
          signout();
        }}
      >Sign out of {fullAppName}</Button>
      <T variant="body2" color="textSecondary" style={{ zoom: 0.9, marginLeft: 10, marginTop: 24 }}>
        <a
          style={{
            opacity: 0.7,
            color: '#555'
          }}
          href="#deleteaccount"
          onClick={() => {
            if (isSoleOrgOwner) {
              toast('You cannot delete your account while you are the sole administrator of an organization. Delete the organization or add another administrator and then try again.', {
                duration: 4000,
                intent: 'danger'
              });
              return;
            }
            if (isElectronApp()) {
              showConfirm({
                contents: <T>Please delete your account from the browser</T>,
                confirmButtonText: 'Redirect to browser',
                onConfirm: () => {
                  window.open(generateAppRoute('/configure/account#deleteaccount', 'TEXT').url, '_blank');
                }
              });
              return;
            }

            attemptDeleteAccount();
          }}>Delete account</a>
      </T>
    </div>
  </>;
}
