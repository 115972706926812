import TypedContainer from './typedContainer';
import { CELL_ATTRIBUTES, CELL_STYLES, DEFAULT_CELL_STYLES } from './constants';
import TableCellLine from './tableCellLine';
import { generateCellMap } from './util';

/**
 * @extends TypedContainer<TableCellLine, import('./tableRow').default>
 */
class TableCell extends TypedContainer {
  checkMerge() {
    const nextChildren = this.next.children,
      nextHeadChild = nextChildren.head;
    if (super.checkMerge() && nextHeadChild != null) {
      const children = this.children,
        headChild = children.head,
        tailChild = children.tail,
        nextTailChild = nextChildren.tail;
      const thisHead = headChild.formats()[headChild.statics.blotName];
      const thisTail = tailChild.formats()[tailChild.statics.blotName];
      const nextHead = nextHeadChild.formats()[nextHeadChild.statics.blotName];
      const nextTail = nextTailChild.formats()[nextTailChild.statics.blotName];
      return (
        thisHead.cell === thisTail.cell &&
        thisHead.cell === nextHead.cell &&
        thisHead.cell === nextTail.cell
      );
    }
    return false;
  }

  static create(value) {
    const node = /** @type {HTMLElement} */ (super.create(value));
    node.setAttribute('data-row', value.row);

    CELL_ATTRIBUTES.forEach(attrName => {
      if (value[attrName]) {
        node.setAttribute(attrName, value[attrName]);
      }
    });
    CELL_STYLES.forEach(attrName => {
      if (!value[attrName] && !DEFAULT_CELL_STYLES[attrName]) {
        return;
      }

      node.style[attrName] = value[attrName] || DEFAULT_CELL_STYLES[attrName];

    });
    node.style['padding'] = '2px 5px';
    return node;
  }

  static formats(domNode) {
    const formats = {};

    if (domNode.hasAttribute('data-row')) {
      formats['row'] = domNode.getAttribute('data-row');
    }
    CELL_STYLES.reduce((formats, attrName) => {
      if (domNode.style[attrName]) {
        formats[attrName] = domNode.style[attrName];
      }
      return formats;
    }, formats);

    return CELL_ATTRIBUTES.reduce((formats, attribute) => {
      if (!domNode.hasAttribute(attribute)) {
        return formats;
      }
      formats[attribute] = domNode.getAttribute(attribute) || '';
      if (['rowspan', 'colspan'].includes(attribute)) {
        formats[attribute] = parseInt(formats[attribute], 10) || undefined;
      }

      return formats;
    }, formats);
  }

  cellOffset() {
    if (this.parent) {
      return this.parent.children.indexOf(this);
    }
    return -1;
  }

  formats() {
    const domNode = this.domNode,
      formats = {};

    if (domNode.hasAttribute('data-row')) {
      formats['row'] = this.domNode.getAttribute('data-row');
    }
    CELL_STYLES.reduce((formats, attribute) => {
      if (domNode.style[attribute]) {
        formats[attribute] = domNode.style[attribute];
      }

      return formats;
    }, formats);

    return CELL_ATTRIBUTES.reduce((formats, attribute) => {
      if (!domNode.hasAttribute(attribute)) {
        return formats;
      }
      formats[attribute] = domNode.getAttribute(attribute) || '';
      if (['rowspan', 'colspan'].includes(attribute)) {
        formats[attribute] = parseInt(formats[attribute], 10) || undefined;
      }

      return formats;
    }, formats);
  }

  toggleAttribute (name, value) {
    if (value) {
      this.domNode.setAttribute(name, value);
    } else {
      this.domNode.removeAttribute(name);
    }
  }

  formatChildren (name, value) {
    const children = this.children;
    children.forEach(child => {
      child.format(name, value);
    });
  }

  format(name, value, context = {}) {
    if (CELL_ATTRIBUTES.indexOf(name) > -1) {
      this.toggleAttribute(name, value);
      this.formatChildren(name, value);
    } else if (['row'].indexOf(name) > -1) {
      this.toggleAttribute(`data-${name}`, value);
      //this.formatChildren(name, value);
    } else if (name.startsWith('border')) {
      const parent = this.parent;
      if (!context.cellMap) {
        context.cellMap = generateCellMap(parent.parent);
      }
      const cellMap = context.cellMap;
      this.formatChildren(name, value);
      const [, side, type] = name.replace(/([a-z0-9])([A-Z])/g, '$1 $2').toLowerCase().split(' ');
      const valueToSet = value || 'initial';
      this.domNode.style[name] = valueToSet;
      let sibilingsToSet, siblingSideToSet;
      const rowOffset = parent.rowOffset(),
        cellOffset = cellMap[rowOffset].indexOf(this),
        colspan = this.colspan(),
        rowspan = this.rowspan();
      if (side === 'left' && cellOffset > 0) {
        sibilingsToSet = [];
        for (let index = 0; index < rowspan; index++) {
          const sibling = cellMap[rowOffset + index][cellOffset - 1];
          if (!sibilingsToSet.includes(sibling)) {
            sibilingsToSet.push(sibling);
          }
        }
        siblingSideToSet = 'right';
      } else if (side === 'right' && cellOffset + colspan < cellMap[rowOffset].length) {
        sibilingsToSet = [];
        for (let index = 0; index < rowspan; index++) {
          const sibling = cellMap[rowOffset + index][cellOffset + colspan];
          if (!sibilingsToSet.includes(sibling)) {
            sibilingsToSet.push(sibling);
          }
        }
        siblingSideToSet = 'left';
      } else if (side === 'top' && rowOffset > 0) {
        sibilingsToSet = [];
        for (let index = 0; index < colspan; index++) {
          const sibling = cellMap[rowOffset - 1][cellOffset + index];
          if (!sibilingsToSet.includes(sibling)) {
            sibilingsToSet.push(sibling);
          }
        }
        siblingSideToSet = 'bottom';
      } else if (side === 'bottom' && rowOffset + rowspan < cellMap.length) {
        sibilingsToSet = [];
        for (let index = 0; index < colspan; index++) {
          const sibling = cellMap[rowOffset + rowspan][cellOffset + index];
          if (!sibilingsToSet.includes(sibling)) {
            sibilingsToSet.push(sibling);
          }
        }
        siblingSideToSet = 'top';
      }
      
      if (sibilingsToSet) {
        const siblingNameToSet = `border${titleCase(siblingSideToSet)}${titleCase(type)}`;
        for (let siblingIndex = 0; siblingIndex < sibilingsToSet.length; siblingIndex++) {
          const sibilingToSet = sibilingsToSet[siblingIndex];
          
          if (sibilingToSet.domNode.style[siblingNameToSet] !== value) {
            sibilingToSet.formatChildren(siblingNameToSet, value);
            sibilingToSet.domNode.style[siblingNameToSet] = valueToSet;
          }
        }
      }
    // if not border
    } else if (CELL_STYLES.includes(name)) {
      this.formatChildren(name, value);

      if (value) {
        this.domNode.style[name] = value;
      } else {
        this.domNode.style[name] = 'initial';
      }
    }  else {
      // @ts-ignore
      super.format(name, value);
    }
    return context;
  }

  optimize(context) {
    const rowId = this.domNode.getAttribute('data-row'),
      parent = this.parent;
    if (!this.domNode.parentElement) {
      parent.domNode.insertBefore(this.domNode, this.next?.domNode);
    }
    if (this.statics.requiredContainer &&
      !(parent instanceof this.statics.requiredContainer)) {
      this.wrap(this.statics.requiredContainer.blotName, {
        row: rowId
      });
    } else if (rowId !== parent.formats().row) {
      parent.format('row', rowId);
    }
    this.children.forEach(child => {
      if (child.next == null) {
        return;
      }

      const childFormats = TableCellLine.formats(child.domNode);
      const nextFormats = TableCellLine.formats(child.next.domNode);
      if (childFormats.cell !== nextFormats.cell) {
        const next = /** @type {TableCellLine} */ (this.splitAfter(child));
        if (next) {
          next.optimize();
        }
        // We might be able to merge with prev now
        if (this.prev) {
          this.prev.optimize({});
        }
        child.optimize({});
      }
    });
    super.optimize(context);

    const currentFormats = this.formats();
    if (this.children.head) {
      
      const childFormats = TableCellLine.formats(this.children.head.domNode);
      if (childFormats.colspan !== currentFormats.colspan
        || childFormats.rowspan !== currentFormats.rowspan) {
        this.format('colspan', childFormats.colspan);
        this.format('rowspan', childFormats.rowspan);
      }
    }
  }
  
  row() {
    return this.parent;
  }

  rowOffset() {
    if (this.row()) {
      return this.row().rowOffset();
    }
    return -1;
  }

  table() {
    return this.row() && this.row().table();
  }

  rowspan() {
    const rowspan = this.domNode.getAttribute('rowspan');
    if (!rowspan) {
      return 1;
    }
    return parseInt(rowspan);
  }

  colspan() {
    const colspan = this.domNode.getAttribute('colspan');
    if (!colspan) {
      return 1;
    }
    return parseInt(colspan);
  }

  /**
   * @param {import('quill').default} quill
   * @returns {import('quill/core/selection').Range}
   */
  range (quill) {
    let length = 0;
    this.children.forEach(el => {
      length += el.cache.length;
    });
    return {
      index: quill.getIndex(this),
      length
    };
  }
}
TableCell.blotName = 'table';
TableCell.tagName = 'TD';

function titleCase (str) {
  return str[0].toUpperCase() + str.substring(1);
}


export default TableCell;