import {
  Button,
  ButtonGroup,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip
} from '@mui/material';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { addBreadcrumb } from '@sentry/browser';
import QuillBetterTable from 'quill-better-table';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';


import TableFormat from './TableFormat';

import { COMMANDS } from '../../../snippet_processor/Commands';
import { dialogConfigs } from '../../DataDialog/dialogConfigs';
import { commandAttributes, field } from '../editor_utilities';

import BrushIcon from '@mui/icons-material/Brush';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import WrapTextIcon from '@mui/icons-material/WrapText';
import IfIcon from '@mui/icons-material/AltRouteOutlined';
import RepeatIcon from '@mui/icons-material/Repeat';
import GridOnIcon from '@mui/icons-material/GridOn';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
// @ts-ignore
import delete_columns from 'quill-better-table/src/assets/icons/delete_columns.svg';
// @ts-ignore
import insert_row_down from 'quill-better-table/src/assets/icons/insert_row_down.svg';
// @ts-ignore
import delete_rows from 'quill-better-table/src/assets/icons/delete_rows.svg';
// @ts-ignore
import insert_row_up from 'quill-better-table/src/assets/icons/insert_row_up.svg';
// @ts-ignore
import delete_table from 'quill-better-table/src/assets/icons/delete_table.svg';
// @ts-ignore
import merge_cells from 'quill-better-table/src/assets/icons/merge_cells.svg';
// @ts-ignore
import insert_column_left from 'quill-better-table/src/assets/icons/insert_column_left.svg';
// @ts-ignore
import unmerge_cells from 'quill-better-table/src/assets/icons/unmerge_cells.svg';
// @ts-ignore
import insert_column_right from 'quill-better-table/src/assets/icons/insert_column_right.svg';
import { isBlaze } from '../../../flags';
import { useTypedSelector } from '../../../hooks';
const icons = {
  delete_columns,
  insert_row_down,
  delete_rows,
  insert_row_up,
  delete_table,
  merge_cells,
  insert_column_left,
  unmerge_cells,
  insert_column_right
};


const OperationActions = QuillBetterTable.OperationActions;

/**
 * 
 * @callback TableMenuShow
 * @param {Element} anchorEl
 * @param {object} params
 * 
 * @typedef TableMenuRef
 * @property {TableMenuShow} show
 * 
 * @typedef {ReturnType<OperationActions['prototype']['actions']>} OperationActionsType
 */

/**
 * @param {object} props
 * @param {(txt: string, autoSelect?: boolean, useTableLogic?: boolean) => any} props.insert
 * @param {(config: any) => any} props.dialog
 * @param {import('react').Ref<TableMenuRef>} ref
 */
const TableMenuBase = ({
  insert,
  dialog
}, ref) => {
  const [actions, setActions] = useState(/** @type {OperationActionsType}  */ ({}));
  const tableFormatRef = useRef(/** @type {import('./TableFormat').TableFormatDialogDef} */ (null));
  const [params, setParams] = useState(null);
  const isBlazeUser = useTypedSelector((state) => isBlaze(state.userState));
  const {
    anchorEl
  } = params || {};
  useImperativeHandle(ref, () => ({
    show: (anchorEl, params) => {
      addBreadcrumb({
        message: 'Showing table actions menu.'
      });
      const operationActions = new OperationActions(params, params.quill);
      setParams({
        anchorEl
      });
      setActions(operationActions.actions({}));
    }
  }), []);

  const handleClose = () => {
    setParams(null);
  };
  const alignHandler = (side) => {
    return () => {
      actions['align'].handler(side !== 'left' ? side : undefined);
      handleClose();
    };
  };


  const commandDialog = (command, title) => {
    addBreadcrumb({
      message: 'Table command dialog - ' + title
    });
    let commandObj = COMMANDS[command];
    let commandKey = command;
    const {
      items,
      fields,
      ...dialogConfig
    } = dialogConfigs[commandKey];
    let attributes = commandAttributes(commandObj, items);
    const config = {
      ...dialogConfig,
      ...attributes,
      fields: [
        ...attributes.fields
      ],
      defaults: {
        ...dialogConfig.defaults,
        ...attributes.defaults
      },
      title: title || dialogConfig.title
    };
    return dialog(config).then(data => {
      data.push({
        id: 'contents'
      });
      addBreadcrumb({
        message: 'Table inserting command - ' + commandKey.toLowerCase()
      });
      insert(field(commandKey.toLowerCase(), data), false, true);
      return data;
    });
  };


  const onIfCondition = () => {
    commandDialog('IF', 'dynamic if on the row');
    handleClose();
  };
  const onRepeat = () => {
    commandDialog('REPEAT', 'dynamic repeat on the row');
    handleClose();
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={!!params}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            addBreadcrumb({
              message: 'Showing table format dialog'
            });
            tableFormatRef.current.show(actions);
            handleClose();
          }}
        >
          <ListItemIcon>
            <BrushIcon />
          </ListItemIcon>
          <ListItemText primary="Format cells…" />
        </MenuItem>
        <Divider />
        <MenuItem
        ><ListItemText>
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <div style={{
                flex: 1
              }}>
                Table width</div>
              <ButtonGroup size="small">
                <TableSizeButton title="Autosize" oAction={actions['autoWidth']} onClose={handleClose}>
                  <Box style={{
                    borderWidth: '0 2px',
                    borderStyle: 'solid',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <ArrowRightAltIcon fontSize="small" />
                  </Box>
                </TableSizeButton>

                <TableSizeButton title="Fixed" oAction={actions['fixedWidth']} onClose={handleClose}>
                  <Box style={{
                    borderWidth: '0 2px',
                    borderStyle: 'solid',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <WrapTextIcon fontSize="small" />
                  </Box>
                </TableSizeButton>
              </ButtonGroup>
            </div>
          </ListItemText>
        </MenuItem>
        {isBlazeUser && <MenuItem
        >
          <ListItemText>
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <div style={{
                flex: 1
              }}>
                Table align</div>
              <ButtonGroup size="small">

                <AlignButton title="Left" onClick={alignHandler('left')}>
                  <FormatAlignLeftIcon fontSize="small" />
                </AlignButton>
                <AlignButton title="Center" onClick={alignHandler('center')}>
                  <FormatAlignCenterIcon fontSize="small" />
                </AlignButton>
                <AlignButton title="Right" onClick={alignHandler('right')}>
                  <FormatAlignRightIcon fontSize="small" />
                </AlignButton>

              </ButtonGroup>
            </div>
          </ListItemText>
        </MenuItem>}
        <Divider />
        <MenuItem onClick={onIfCondition}>
          <ListItemIcon>
            <IfIcon />
          </ListItemIcon>
          <ListItemText primary="Conditionally show row" />
        </MenuItem>
        <MenuItem onClick={onRepeat}>
          <ListItemIcon>
            <RepeatIcon />
          </ListItemIcon>
          <ListItemText primary="Repeat row multiple times" />
        </MenuItem>
        <Divider />
        <SimpleActionMenuItem
          action={actions['insertColumnRight']}
          onClose={handleClose}
        >
          Insert column right
        </SimpleActionMenuItem>
        <SimpleActionMenuItem
          action={actions['insertColumnLeft']}
          onClose={handleClose}
        >
          Insert column left
        </SimpleActionMenuItem>
        <SimpleActionMenuItem
          action={actions['insertRowUp']}
          onClose={handleClose}
        >
          Insert row above
        </SimpleActionMenuItem>
        <SimpleActionMenuItem
          action={actions['insertRowDown']}
          onClose={handleClose}
        >
          Insert row below
        </SimpleActionMenuItem>
        <SimpleActionMenuItem
          action={actions['insertLineBefore']}
          onClose={handleClose}
          icon={
            <ListItemIcon>
              <InsertLineIcon side="top" />
            </ListItemIcon>
          }
        >
          Insert a line before table
        </SimpleActionMenuItem>
        <SimpleActionMenuItem
          action={actions['insertLineAfter']}
          onClose={handleClose}
          icon={
            <ListItemIcon>
              <InsertLineIcon side="bottom" />
            </ListItemIcon>
          }
        >
          Insert a line after table
        </SimpleActionMenuItem>
        <Divider />
        <SimpleActionMenuItem
          action={actions['mergeCells']}
          onClose={handleClose}
        >
          Merge selected cells
        </SimpleActionMenuItem>
        <SimpleActionMenuItem
          action={actions['unmergeCells']}
          onClose={handleClose}
        >
          Unmerge selected cells
        </SimpleActionMenuItem>
        <Divider />
        <SimpleActionMenuItem
          action={actions['deleteColumn']}
          onClose={handleClose}
        >
          Delete selected columns
        </SimpleActionMenuItem>
        <SimpleActionMenuItem
          action={actions['deleteRow']}
          onClose={handleClose}
        >
          Delete selected rows
        </SimpleActionMenuItem>
        <SimpleActionMenuItem
          action={actions['deleteTable']}
          onClose={handleClose}
        >
          Delete table
        </SimpleActionMenuItem>
      </Menu>
      <TableFormat
        ref={tableFormatRef}
      />
    </>
  );
};



const TableMenu = React.memo(React.forwardRef(TableMenuBase));
export default TableMenu;

/**
 * @param {object} props
 * @param {OperationActionsType['']} props.action
 * @param {React.ReactNode} props.children
 * @param {() => any} props.onClose
 * @param {React.ReactNode=} props.icon
 * @returns 
 */
const SimpleActionMenuItem = ({
  action,
  children,
  onClose,
  icon
}) => {
  const clicked = useRef(false);
  const onSimpleActionClick = () => {
    if (clicked.current) {
      return;
    }
    clicked.current = true;
    addBreadcrumb({
      message: 'Table simple action - ' + action.text
    });
    action.handler();
    onClose();
    setTimeout(() => {
      // Reset flag just in case.
      clicked.current = false;
    }, 1000);
  };
  if (action.iconSrc) {
    icon = <img
      src={icons[action.iconSrc]}
      style={{
        width: 24,
        height: 24,
        verticalAlign: 'middle'
      }}
      alt={`Table side ${children}`}
    />;
  }

  return (
    <MenuItem
      onClick={() => onSimpleActionClick()}
    >
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={children} />
    </MenuItem>
  );
};




/**
 * @param {import('@mui/material').ButtonProps & { title: string, oAction: OperationActionsType[''], onClose: () => any }} props
 */
const TableSizeButton = ({
  title,
  oAction,
  onClose,
  ...props
}) => {


  const onClick = () => {
    addBreadcrumb({
      message: 'Setting table size - ' + title
    });
    oAction.handler();
    onClose();
  };
  return (
    <Tooltip title={title}>
      <Button
        size="small"
        onClick={onClick}
        {...props}
      />
    </Tooltip>
  );
};

/**
 * 
 * @param {object} props 
 * @param {('top' | 'bottom')} props.side
 */
const InsertLineIcon = ({
  side
}) => {
  const isTop = side === 'top';
  return (

    <ListItemIcon>
      <Box sx={{
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: isTop ? 'flex-end' : 'flex-start',
        borderTop: isTop ? '1px solid' : null,
        borderBottom: !isTop ? '1px solid' : null,
        position: 'relative'
      }}>
        <GridOnIcon sx={{
          fontSize: 14
        }} />
        <SwitchAccessShortcutAddIcon sx={{
          fontSize: 14,
          position: 'absolute',
          bottom: 2,
          right: isTop ? null : -11,
          left: isTop ? -11 : null,
          transform: isTop ? null : 'scaleX(-1) scaleY(-1)'
        }} />
      </Box>
    </ListItemIcon>
  );
};

/**
 * @param {import('@mui/material').ButtonProps & { title: string }} props
 */
const AlignButton = ({
  title,
  ...props
}) => {
  return (
    <Tooltip title={title}>
      <Button
        size="small"
        {...props}
      />
    </Tooltip>
  );
};