import React, { useState, useRef, useCallback, useEffect } from 'react';
import { createSnippet } from '../../data';
import { limitationsState } from '../Version/limitations';
import { getState } from '../../getState';
import { messageCenter, showCallout, toast } from '../../message';
import Popover from '@mui/material/Popover';
import NewSnippetInner from './NewSnippetInner';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { isAiBlaze } from '../../aiBlaze';
import { log } from '../../logging/logging';
import { useHistory } from 'react-router-dom';
import hotkeys from '../../hotkeys';
import { AI_SNIPPET_CALLOUT_NOTIF_KEY, clearAICallout } from './newsnippet_utilities';

/**
 * @param {object} props
 * @param {function} props.component
 * @param {boolean=} props.isAI
 * @param {boolean=} props.addHotkey
 */
export default function NewSnippet(props) {
  let ref = useRef();
  let [error, setError] = useState(null);
  let [data, setData] = useState(null);
  let [resolve, setResolve] = useState(null);
  let [reject, setReject] = useState(null);
  let { dismissedAISnippetCallout, userCreationTimestamp } = useTypedSelectorShallowEquals(store => ({
    dismissedAISnippetCallout: !!store.userState.dismissed_notifications?.includes(AI_SNIPPET_CALLOUT_NOTIF_KEY),
    userCreationTimestamp: store.userState.createdTimestamp
  }));
  const { push } = useHistory();

  // Not using "useHotkey" hook since I need to register the listeners conditionally
  useEffect(() => {
    if (props.addHotkey) {
      const navigateToSnippet = data => {
        push(`/snippet/${data.snippet_id}`);
        return data;
      };

      if (isAiBlaze) {
        // New AI snippet
        return hotkeys.listen('n+s', () => {
          createSnippetFn({ type: 'text', name: 'New AI prompt', options: { quick_entry: false, is_ai: true, include_page_context: true, polish_mode: true } })
            .then(navigateToSnippet)
            .then(({ group_id, snippet_id }) => {
              log({ action: 'New AI snippet created via hotkeys' }, { snippet_id, group_id });
            })
            .catch(() => { }); // pass on error;
        });
      } else {
        // New Snippet
        return hotkeys.listen('n+s', () => {
          createSnippetFn({ type: 'text' })
            .then(navigateToSnippet)
            .then(({ group_id, snippet_id }) => {
              log({ action: 'New snippet created from hotkeys' }, { snippet_id, group_id });
            })
            .catch(() => { }); // pass on error;
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Only show the callout to users created before 13th July 2023 (date of launch of this feature)
    if (!isAiBlaze && props.isAI && !dismissedAISnippetCallout && userCreationTimestamp < (new Date(2023, 6, 13)).getTime()) {
      showCallout({
        anchorEl: ref.current,
        contents: <><b>NEW!</b> Connect to ChatGPT using AI Snippets</>,
        btnText: '',
        onAccept: () => {},
        onCancel: () => {
          clearAICallout();
        },
        requireExplicitClose: true,
        modifiers: [{
          name: 'offset',
          options: {
            offset: [20, 25],
          },
        }]
      });
    } else if (dismissedAISnippetCallout) {
      messageCenter.callout?.close();
    }
  }, [dismissedAISnippetCallout, props.isAI, userCreationTimestamp]);

  let createSnippetFn = useCallback((data) => {
    return new Promise(async (resolve, reject) => {
      setData(data);
      setResolve(() => resolve);
      setReject(() => reject);

      try {
        let res = await createSnippet(data, false, false, false);
        return resolve(res);
      } catch (err) {
        let state = getState();
        let limits = limitationsState(state);

        if (err === 'MAX_SNIPPETS_EXCEEDED') {
          toast(`You can have at most ${limits.MAX_SNIPPETS} snippets ${limits.CAN_UPGRADE_MAX_SNIPPETS ? ' in your version of Text Blaze' : ''}.`, {
            duration: 8000,
            intent: 'danger',
            upgrade: limits.CAN_UPGRADE_MAX_SNIPPETS && 'Upgrade for more snippets.'
          });
          return reject();
        }

        let userGroups = state.userState.groups;
        let groups = state.dataState.groups;
        if (!userGroups || !groups) {
          toast('Text Blaze is still loading. Please wait a few seconds and try again.');
          return reject();
        }

        setError(err);
      }
    });
  }, []);

  return <>
    {props.component(ref, createSnippetFn)}
    {!!error && <Popover
      open
      anchorEl={ref.current}
      onClose={() => {
        setError(null);
        reject();
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <NewSnippetInner
        snippetConfig={data}
        onCreated={(res) => {
          setError(null);
          resolve(res);
        }}
      />
    </Popover>}
  </>;
}

