// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d) { return d[0]; }
const kSELECT = { test: x => x.type === 'SELECT' };
const kDELETE = { test: x => x.type === 'DELETE' };
const kSET = { test: x => x.type === 'SET' };
const kVALUES = { test: x => x.type === 'VALUES' };
const kUPDATE = { test: x => x.type === 'UPDATE' };
const kINSERT = { test: x => x.type === 'INSERT' };
const kINTO = { test: x => x.type === 'INTO' };
const kWHERE = { test: x => x.type === 'WHERE' };
const kORDER = { test: x => x.type === 'ORDER' };
const kGROUP = { test: x => x.type === 'GROUP' };
const kHAVING = { test: x => x.type === 'HAVING' };
const kBY = { test: x => x.type === 'BY' };
const kFROM = { test: x => x.type === 'FROM' };
const kLIMIT = { test: x => x.type === 'LIMIT' };
const kAS = { test: x => x.type === 'AS' };
const kASC = { test: x => x.type === 'ASC' };
const kDESC = { test: x => x.type === 'DESC' };
function node(type, info, position) {
    return {
        type,
        info,
        startPosition: position
    };
}
const kNUMBER = { test: x => x.type === 'NUMBER' };
const kBOOLEAN = { test: x => x.type === 'BOOLEAN' };
const kSTRING = { test: x => x.type === 'STRING' };
const kCOMMAND = { test: x => x.type === 'COMMAND' };
const kREMOTE_COMMAND = { test: x => kCOMMAND.test(x) && ['urlload', 'urlsend', 'dbselect', 'dbdelete', 'dbinsert', 'dbupdate'].some(cmd => x.command.startsWith('{' + cmd)) };
const kIDENTIFIER = { test: x => x.type === 'IDENTIFIER' /* || ['RETURN', 'ELSEIF', 'ENDIF', 'BLOCK',  'ENDBLOCK', 'TRY', 'CATCHERROR', 'ENDTRY', 'VAR'].includes(x.type)*/ };
const kWS = { test: x => x.type === 'WS' };
const kWS_B = { test: x => x.type === 'WS_B' };
const kPLUS = { test: x => x.type === 'PLUS' };
const kMINUS = { test: x => x.type === 'MINUS' };
const kMULTIPLY = { test: x => x.type === 'MULTIPLY' };
const kDIVIDE = { test: x => x.type === 'DIVIDE' };
const kPOWER = { test: x => x.type === 'POWER' };
const kAMP = { test: x => x.type === 'AMP' };
const kLT = { test: x => x.type === 'LT' };
const kGT = { test: x => x.type === 'GT' };
const kLTE = { test: x => x.type === 'LTE' };
const kGTE = { test: x => x.type === 'GTE' };
const kEQUALS = { test: x => x.type === 'EQUALS' };
const kEQUALITY = { test: x => x.type === 'EQUALITY' };
const kNOT_EQUALITY = { test: x => x.type === 'NOT_EQUALITY' };
const kSTRONG_EQUALITY = { test: x => x.type === 'STRONG_EQUALITY' };
const kLISTASSIGNS = { test: x => x.type === 'LISTASSIGNS' };
const kCALLS = { test: x => x.type === 'CALLS' };
const kLPAREN = { test: x => x.type === 'LPAREN' };
const kRPAREN = { test: x => x.type === 'RPAREN' };
const kLBRACKET = { test: x => x.type === 'LBRACKET' };
const kRBRACKET = { test: x => x.type === 'RBRACKET' };
const kPERIOD = { test: x => x.type === 'PERIOD' };
const kCOMMA = { test: x => x.type === 'COMMA' };
const kAND = { test: x => x.type === 'AND' };
const kOR = { test: x => x.type === 'OR' };
const kNOT = { test: x => x.type === 'NOT' };
const kIF = { test: x => x.type === 'IF' };
const kELSE = { test: x => x.type === 'ELSE' };
const kFOR = { test: x => x.type === 'FOR' };
const kIN = { test: x => x.type === 'IN' };
const kRETURN = { test: x => x.type === 'RETURN' };
const kELSEIF = { test: x => x.type === 'ELSEIF' };
const kENDIF = { test: x => x.type === 'ENDIF' };
const kENDFOR = { test: x => x.type === 'ENDFOR' };
const kBLOCK = { test: x => x.type === 'BLOCK' };
const kENDBLOCK = { test: x => x.type === 'ENDBLOCK' };
const kTRY = { test: x => x.type === 'TRY' };
const kCATCH = { test: x => x.type === 'CATCHERROR' };
const kENDTRY = { test: x => x.type === 'ENDTRY' };
const kVAR = { test: x => x.type === 'VAR' };
;
;
;
;
const grammar = {
    Lexer: undefined,
    ParserRules: [
        { "name": "main", "symbols": ["_", "SELECT_QUERY", "_"], "postprocess": d => node('query', d[1], d[1].position) },
        { "name": "main", "symbols": ["_", "INSERT_QUERY", "_"], "postprocess": d => node('query', d[1], d[1].position) },
        { "name": "main", "symbols": ["_", "UPDATE_QUERY", "_"], "postprocess": d => node('query', d[1], d[1].position) },
        { "name": "main", "symbols": ["_", "DELETE_QUERY", "_"], "postprocess": d => node('query', d[1], d[1].position) },
        { "name": "INSERT_QUERY", "symbols": [kINSERT, "__", kINTO, "__", "Name", "__", kSET, "__", "ASSIGNMENT_OPTIONS"], "postprocess": d => node('insert', { table: d[4], values: d[8] }, d[0].position) },
        { "name": "INSERT_QUERY", "symbols": [kINSERT, "__", kINTO, "__", "Name"], "postprocess": d => node('insert', { table: d[4], values: null }, d[0].position) },
        { "name": "INSERT_QUERY", "symbols": [kINSERT, "__", kINTO, "__", "Name", "_", kLPAREN, "_", "NAME_LIST", "_", kRPAREN, "__", kVALUES, "__", "List"], "postprocess": d => node('insert', { table: d[4], field_names: d[8], positional_list: d[14] }, d[0].position) },
        { "name": "INSERT_QUERY", "symbols": [kINSERT, "__", kINTO, "__", "Name", "__", kVALUES, "__", "List"], "postprocess": d => node('insert', { table: d[4], named_list: d[8] }, d[0].position) },
        { "name": "UPDATE_QUERY", "symbols": [kUPDATE, "__", "Name", "__", kSET, "__", "ASSIGNMENT_OPTIONS", "WHERE_CLAUSE"], "postprocess": d => node('update', { table: d[2], values: d[6], where: d[7] }, d[0].position) },
        { "name": "UPDATE_QUERY", "symbols": [kUPDATE, "__", "Name", "WHERE_CLAUSE"], "postprocess": d => node('update', { table: d[2], values: null, where: d[3] }, d[0].position) },
        { "name": "DELETE_QUERY", "symbols": [kDELETE, "__", kFROM, "__", "Name", "WHERE_CLAUSE"], "postprocess": d => node('delete', { table: d[4], where: d[5] }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "WHERE_CLAUSE", "GROUP_BY_CLAUSE", "ORDER_BY_CLAUSE", "LIMIT_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: d[1], group_by: d[2], order_by: d[3], limit: d[4] }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "WHERE_CLAUSE", "GROUP_BY_CLAUSE", "ORDER_BY_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: d[1], group_by: d[2], order_by: d[3], limit: null }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "WHERE_CLAUSE", "GROUP_BY_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: d[1], group_by: d[2], order_by: null, limit: null }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "GROUP_BY_CLAUSE", "ORDER_BY_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: null, group_by: d[1], order_by: d[2], limit: null }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "GROUP_BY_CLAUSE", "ORDER_BY_CLAUSE", "LIMIT_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: null, group_by: d[1], order_by: d[2], limit: d[3] }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "GROUP_BY_CLAUSE", "LIMIT_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: null, group_by: d[1], order_by: null, limit: d[2] }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "WHERE_CLAUSE", "GROUP_BY_CLAUSE", "LIMIT_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: d[1], group_by: d[2], order_by: null, limit: d[3] }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "GROUP_BY_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: null, group_by: d[1], order_by: null, limit: null }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "WHERE_CLAUSE", "ORDER_BY_CLAUSE", "LIMIT_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: d[1], group_by: null, order_by: d[2], limit: d[3] }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "WHERE_CLAUSE", "ORDER_BY_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: d[1], group_by: null, order_by: d[2], limit: null }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "WHERE_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: d[1], group_by: null, order_by: null, limit: null }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "ORDER_BY_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: null, group_by: null, order_by: d[1], limit: null }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "ORDER_BY_CLAUSE", "LIMIT_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: null, group_by: null, order_by: d[1], limit: d[2] }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "LIMIT_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: null, group_by: null, order_by: null, limit: d[1] }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE", "WHERE_CLAUSE", "LIMIT_CLAUSE"], "postprocess": d => node('select', { base: d[0], where: d[1], group_by: null, order_by: null, limit: d[2] }, d[0].position) },
        { "name": "SELECT_QUERY", "symbols": ["SELECT_BASE"], "postprocess": d => node('select', { base: d[0], where: null, group_by: null, order_by: null, limit: null }, d[0].position) },
        { "name": "SELECT_BASE", "symbols": [kSELECT, "__", "SELECT_OPTIONS", "__", kFROM, "__", "Name"], "postprocess": d => node('select_base', { columns: d[2], from: d[6] }, d[0].position) },
        { "name": "NAME_LIST", "symbols": ["Name"], "postprocess": d => [d[0]] },
        { "name": "NAME_LIST", "symbols": ["NAME_LIST", "_", kCOMMA, "_", "Name"], "postprocess": d => d[0].concat(d[4]) },
        { "name": "SELECT_OPTIONS", "symbols": ["SELECT_OPTION"], "postprocess": d => [d[0]] },
        { "name": "SELECT_OPTIONS", "symbols": ["SELECT_OPTIONS", "_", kCOMMA, "_", "SELECT_OPTION"], "postprocess": d => d[0].concat(d[4]) },
        { "name": "SELECT_OPTION", "symbols": ["Name"], "postprocess": d => node('select_option', { column: d[0], alias: d[0] }, d[0].position) },
        { "name": "SELECT_OPTION", "symbols": ["EXPR", "__", kAS, "__", "Name"], "postprocess": d => node('select_option', { column: d[0], alias: d[4] }, d[0].position) },
        { "name": "ASSIGNMENT_OPTIONS", "symbols": ["ASSIGNMENT_OPTION"], "postprocess": d => [d[0]] },
        { "name": "ASSIGNMENT_OPTIONS", "symbols": ["ASSIGNMENT_OPTIONS", "_", kCOMMA, "_", "ASSIGNMENT_OPTION"], "postprocess": d => d[0].concat(d[4]) },
        { "name": "ASSIGNMENT_OPTION", "symbols": ["Name"], "postprocess": d => node('assignment_option', { name: d[0], value: d[0] }, d[0].position) },
        { "name": "ASSIGNMENT_OPTION", "symbols": ["Name", "_", kEQUALS, "_", "EXPR"], "postprocess": d => node('assignment_option', { name: d[0], value: d[4] }, d[0].position) },
        { "name": "WHERE_CLAUSE", "symbols": ["__", kWHERE, "__", "EXPR"], "postprocess": d => node('where_clause', d[3], d[1].position) },
        { "name": "ORDER_EXPR_LIST", "symbols": ["ORDER_EXPR"], "postprocess": d => [d[0]] },
        { "name": "ORDER_EXPR_LIST", "symbols": ["ORDER_EXPR_LIST", "_", kCOMMA, "_", "ORDER_EXPR"], "postprocess": d => d[0].concat(d[4]) },
        { "name": "ORDER_EXPR", "symbols": ["EXPR"], "postprocess": (d, _, reject) => {
                let item = d[0];
                if (item.type === 'number') {
                    // we disallow order by index
                    return reject;
                }
                return node('order_expression', { direction: "ASC", item: item }, item.position);
            } },
        { "name": "ORDER_EXPR", "symbols": ["EXPR", "__", kASC], "postprocess": (d, _, reject) => {
                let item = d[0];
                if (item.type === 'number') {
                    // we disallow order by index
                    return reject;
                }
                return node('order_expression', { direction: "ASC", item: item }, item.position);
            } },
        { "name": "ORDER_EXPR", "symbols": ["EXPR", "__", kDESC], "postprocess": (d, _, reject) => {
                let item = d[0];
                if (item.type === 'number') {
                    // we disallow order by index
                    return reject;
                }
                return node('order_expression', { direction: "DESC", item: item }, item.position);
            } },
        { "name": "ORDER_BY_CLAUSE", "symbols": ["__", kORDER, "__", kBY, "__", "ORDER_EXPR_LIST"], "postprocess": d => node('order_by_clause', d[5], d[1].position) },
        { "name": "LIMIT_CLAUSE", "symbols": ["__", kLIMIT, "__", "EXPR"], "postprocess": d => node('limit_clause', d[3], d[1].position) },
        { "name": "GROUP_EXPR_LIST", "symbols": ["EXPR"], "postprocess": (d, _, reject) => {
                let item = d[0];
                if (item.type === 'number') {
                    // we disallow grouping by index
                    return reject;
                }
                return [item];
            } },
        { "name": "GROUP_EXPR_LIST", "symbols": ["GROUP_EXPR_LIST", "_", kCOMMA, "_", "EXPR"], "postprocess": (d, _, reject) => {
                let item = d[4];
                if (item.type === 'number') {
                    // we disallow grouping by index
                    return reject;
                }
                return d[0].concat(item);
            }
        },
        { "name": "GROUP_BY_CLAUSE", "symbols": ["__", kGROUP, "__", kBY, "__", "GROUP_EXPR_LIST"], "postprocess": d => node('group_by_clause', { group_by: d[5], having: null }, d[1].position) },
        { "name": "GROUP_BY_CLAUSE", "symbols": ["__", kGROUP, "__", kBY, "__", "GROUP_EXPR_LIST", "__", kHAVING, "__", "EXPR"], "postprocess": d => node('group_by_clause', { group_by: d[5], having: d[9] }, d[1].position) },
        { "name": "EXPR", "symbols": ["Exp"], "postprocess": id },
        { "name": "Name", "symbols": ["Identifier_Atom"], "postprocess": (d, location, reject) => {
                if (d[0].info[0] === '@') {
                    // it's a @var which is should only be used in expressions
                    return reject;
                }
                return d[0];
            }
        },
        { "name": "calc", "symbols": ["_", "Exp", "_"], "postprocess": d => d[1] },
        { "name": "Block", "symbols": [kBLOCK, "__N__", "StatementList", "_S_", "__N__", kENDBLOCK], "postprocess": d => node('block', d[2], d[0].position) },
        { "name": "StatementList", "symbols": ["StatementListInner"], "postprocess": d => node('statement_list', d[0], 0) },
        { "name": "StatementListInner", "symbols": ["Statement"], "postprocess": d => [d[0]] },
        { "name": "StatementListInner", "symbols": ["StatementListInner", "__N__", "Statement"], "postprocess": d => d[0].concat(d[2]) },
        { "name": "Statement", "symbols": [kRETURN, "__S__", "Exp"], "postprocess": d => node('return_statement', [d[2]], d[0].position) },
        { "name": "Statement", "symbols": [kRETURN], "postprocess": d => node('return_statement', [null], d[0].position) },
        { "name": "Statement", "symbols": ["Var", "_S_", kEQUALS, "_S_", "Exp"], "postprocess": d => node('assign_statement', [d[0], d[4]], d[2].position) },
        { "name": "Statement", "symbols": [kREMOTE_COMMAND], "postprocess": d => node('command', d[0].command, d[0].position) },
        { "name": "Statement", "symbols": ["FunctionCall"], "postprocess": id },
        { "name": "Statement", "symbols": [kVAR, "__S__", "Var", "_S_", kEQUALS, "_S_", "Exp"], "postprocess": d => node('initialize_local_statement', [d[2], d[6]], d[4].position) },
        { "name": "Statement$ebnf$1", "symbols": [] },
        { "name": "Statement$ebnf$1$subexpression$1", "symbols": ["ElseIfStatement"] },
        { "name": "Statement$ebnf$1", "symbols": ["Statement$ebnf$1", "Statement$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]]) },
        { "name": "Statement$ebnf$2$subexpression$1", "symbols": ["ElseStatement"] },
        { "name": "Statement$ebnf$2", "symbols": ["Statement$ebnf$2$subexpression$1"], "postprocess": id },
        { "name": "Statement$ebnf$2", "symbols": [], "postprocess": () => null },
        { "name": "Statement", "symbols": [kIF, "__S__", "Exp", "__N__", "StatementList", "Statement$ebnf$1", "Statement$ebnf$2", "__N__", kENDIF], "postprocess": d => node('if_statement', [d[2], d[4], d[5].length ? { type: 'else_if', info: d[5].map(x => x[0]), position: 0 } : null, d[6] ? d[6][0] : null], d[0].position) },
        { "name": "Statement", "symbols": ["ForStatement", "__N__", "StatementList", "__N__", kENDFOR], "postprocess": d => node('for_statement', [d[0], d[2]], d[4].position) },
        { "name": "Statement$ebnf$3", "symbols": ["CatchStatement"], "postprocess": id },
        { "name": "Statement$ebnf$3", "symbols": [], "postprocess": () => null },
        { "name": "Statement", "symbols": [kTRY, "__N__", "StatementList", "Statement$ebnf$3", "__N__", kENDTRY], "postprocess": d => node('try_statement', [d[2], d[3]], d[0].position) },
        { "name": "CatchStatement", "symbols": ["__N__", kCATCH, "__N__", "StatementList"], "postprocess": d => node('catch_statement', [d[3]], d[1].position) },
        { "name": "ElseIfStatement", "symbols": ["__N__", kELSEIF, "__S__", "Exp", "__N__", "StatementList"], "postprocess": d => node('elseif_statement', [d[3], d[5]], d[1].position) },
        { "name": "ElseStatement", "symbols": ["__N__", kELSE, "__N__", "StatementList"], "postprocess": d => node('else_statement', d[3], d[1].position) },
        { "name": "EXPONENTS", "symbols": ["Atom", "_", kPOWER, "_", "EXPONENTS"], "postprocess": d => node('pow', [d[0], d[4]], d[2].position) },
        { "name": "EXPONENTS", "symbols": ["Atom", "_", kPOWER, "_", kMINUS, "_", "EXPONENTS"], "postprocess": d => node('pow', [d[0], node('negate', d[6], d[4].position)], d[2].position) },
        { "name": "EXPONENTS", "symbols": ["Atom"], "postprocess": id },
        { "name": "Not_Op", "symbols": [kNOT, "__", "EXPONENTS"], "postprocess": d => node('not', d[2], d[0].position) },
        { "name": "Not_Op", "symbols": [kMINUS, "_", "EXPONENTS"], "postprocess": d => node('negate', d[2], d[0].position) },
        { "name": "Not_Op", "symbols": ["EXPONENTS"], "postprocess": id },
        { "name": "MD_Op", "symbols": ["MD_Op", "_", kMULTIPLY, "_", "Not_Op"], "postprocess": d => node('mult', [d[0], d[4]], d[0].position) },
        { "name": "MD_Op", "symbols": ["MD_Op", "_", kDIVIDE, "_", "Not_Op"], "postprocess": d => node('div', [d[0], d[4]], d[0].position) },
        { "name": "MD_Op", "symbols": ["Not_Op"], "postprocess": id },
        { "name": "AS_Op", "symbols": ["AS_Op", "_", kPLUS, "_", "MD_Op"], "postprocess": d => node('plus', [d[0], d[4]], d[0].position) },
        { "name": "AS_Op", "symbols": ["AS_Op", "_", kMINUS, "_", "MD_Op"], "postprocess": d => node('minus', [d[0], d[4]], d[0].position) },
        { "name": "AS_Op", "symbols": ["MD_Op"], "postprocess": id },
        { "name": "Concat_OP", "symbols": ["Concat_OP", "_", kAMP, "_", "AS_Op"], "postprocess": d => node('concat', [d[0], d[4]], d[2].position) },
        { "name": "Concat_OP", "symbols": ["AS_Op"], "postprocess": id },
        { "name": "Comp_Op", "symbols": ["Comp_Op", "_", kGT, "_", "Concat_OP"], "postprocess": d => node('gt', [d[0], d[4]], d[2].position) },
        { "name": "Comp_Op", "symbols": ["Comp_Op", "_", kGTE, "_", "Concat_OP"], "postprocess": d => node('gt_eq', [d[0], d[4]], d[2].position) },
        { "name": "Comp_Op", "symbols": ["Comp_Op", "_", kLT, "_", "Concat_OP"], "postprocess": d => node('lt', [d[0], d[4]], d[2].position) },
        { "name": "Comp_Op", "symbols": ["Comp_Op", "_", kLTE, "_", "Concat_OP"], "postprocess": d => node('lt_eq', [d[0], d[4]], d[2].position) },
        { "name": "Comp_Op", "symbols": ["Concat_OP"], "postprocess": id },
        { "name": "Eq_Op", "symbols": ["Eq_Op", "_", kEQUALITY, "_", "Comp_Op"], "postprocess": d => node('equals', [d[0], d[4]], d[2].position) },
        { "name": "Eq_Op", "symbols": ["Eq_Op", "_", kEQUALS, "_", "Comp_Op"], "postprocess": d => node('equals', [d[0], d[4]], d[2].position) },
        { "name": "Eq_Op", "symbols": ["Eq_Op", "_", kSTRONG_EQUALITY, "_", "Comp_Op"], "postprocess": d => node('strict_equals', [d[0], d[4]], d[2].position) },
        { "name": "Eq_Op", "symbols": ["Eq_Op", "_", kNOT_EQUALITY, "_", "Comp_Op"], "postprocess": d => node('not_equals', [d[0], d[4]], d[2].position) },
        { "name": "Eq_Op", "symbols": ["Comp_Op"], "postprocess": id },
        { "name": "And_Op", "symbols": ["And_Op", "__", kAND, "__", "Eq_Op"], "postprocess": d => node('and', [d[0], d[4]], d[0].position) },
        { "name": "And_Op", "symbols": ["Eq_Op"], "postprocess": id },
        { "name": "Or_Op", "symbols": ["Or_Op", "__", kOR, "__", "And_Op"], "postprocess": d => node('or', [d[0], d[4]], d[0].position) },
        { "name": "Or_Op", "symbols": ["And_Op"], "postprocess": id },
        { "name": "TernaryExp", "symbols": ["Or_Op"], "postprocess": id },
        { "name": "TernaryExp", "symbols": ["Or_Op", "__", kIF, "__", "Exp", "__", kELSE, "__", "Exp"], "postprocess": d => node('ternary', [d[4], d[0], d[8]], d[2].position) },
        { "name": "Exp", "symbols": ["LambdaDef"], "postprocess": id },
        { "name": "Exp", "symbols": ["TernaryExp"], "postprocess": id },
        { "name": "Atom", "symbols": ["Number_Atom"], "postprocess": id },
        { "name": "Atom", "symbols": ["Boolean_Atom"], "postprocess": id },
        { "name": "Atom", "symbols": ["PrefixExp"], "postprocess": id },
        { "name": "PrefixExp", "symbols": ["Var"], "postprocess": id },
        { "name": "PrefixExp", "symbols": ["FunctionCall"], "postprocess": id },
        { "name": "PrefixExp", "symbols": ["Paren"], "postprocess": id },
        { "name": "PrefixExp", "symbols": [kCOMMAND], "postprocess": d => node('command', d[0].command, d[0].position) },
        { "name": "PrefixExp", "symbols": ["List"], "postprocess": id },
        { "name": "PrefixExp", "symbols": [kSTRING], "postprocess": d => node('string', d[0].string, d[0].position) },
        { "name": "Var", "symbols": ["Identifier_Atom"], "postprocess": id },
        { "name": "Var", "symbols": ["PrefixExp", "_", kLBRACKET, "_", "Exp", "_", kRBRACKET], "postprocess": d => node('select', {
                base: d[0],
                selector: d[4]
            }, d[2].position) },
        { "name": "Var", "symbols": ["PrefixExp", kPERIOD, "Identifier_Atom"], "postprocess": d => node('select', {
                base: d[0],
                selector: node('string', d[2].info, d[2].position)
            }, d[1].position) },
        { "name": "FunctionCall", "symbols": ["PrefixExp", "_", "Args"], "postprocess": d => node('function_call', { name: d[0], args: d[2] }, d[0].startPosition) },
        { "name": "List", "symbols": [kLBRACKET, "_", kRBRACKET], "postprocess": d => node('list', { positional: [], keys: [] }, d[0].position) },
        { "name": "List", "symbols": [kLBRACKET, "_", "ListElementList", "_", kRBRACKET], "postprocess": d => {
                let list = d[2];
                let res = { positional: [], keys: [] };
                for (let i = 0; i < list.length; i++) {
                    let x = list[i];
                    if ('key' in x) {
                        res.keys.push(x);
                    }
                    else {
                        res.positional.push(x.positional);
                    }
                }
                return node('list', res, d[0].position);
            } },
        { "name": "List", "symbols": [kLBRACKET, "_", "ListElement", "__", "ForExp", "_", kRBRACKET], "postprocess": d => node('list_comprehension', { element: d[2], for: d[4] }, d[0].position) },
        { "name": "ForExp$ebnf$1", "symbols": ["IfExp"], "postprocess": id },
        { "name": "ForExp$ebnf$1", "symbols": [], "postprocess": () => null },
        { "name": "ForExp", "symbols": [kFOR, "__", "LambdaArgsDef", "__", kIN, "__", "Exp", "ForExp$ebnf$1"], "postprocess": d => node('for', { args: d[2], base: d[6], if: d[7] }, d[0].position) },
        { "name": "IfExp", "symbols": ["__", kIF, "__", "Exp"], "postprocess": d => node('if', d[3], d[1].position) },
        { "name": "ForStatement$ebnf$1", "symbols": ["IfStatement"], "postprocess": id },
        { "name": "ForStatement$ebnf$1", "symbols": [], "postprocess": () => null },
        { "name": "ForStatement", "symbols": [kFOR, "__S__", "LambdaArgsDef", "__S__", kIN, "__S__", "Exp", "ForStatement$ebnf$1"], "postprocess": d => node('for', { args: d[2], base: d[6], if: d[7] }, d[0].position) },
        { "name": "IfStatement", "symbols": ["__S__", kIF, "__S__", "Exp"], "postprocess": d => node('if', d[3], d[1].position) },
        { "name": "ListElementList", "symbols": ["ListElement"], "postprocess": d => [d[0]] },
        { "name": "ListElementList", "symbols": ["ListElementList", "_", kCOMMA, "_", "ListElement"], "postprocess": d => d[0].concat(d[4]) },
        { "name": "ListElement", "symbols": ["Exp"], "postprocess": d => ({ positional: d[0] }) },
        { "name": "ListElement", "symbols": ["Exp", "_", kLISTASSIGNS, "_", "Exp"], "postprocess": d => ({ key: d[0], value: d[4], type: "LISTASSIGNS" }) },
        { "name": "LambdaArgsDef", "symbols": ["ArgsDef"], "postprocess": d => d[0] },
        { "name": "LambdaArgsDef", "symbols": ["Identifier_Atom"], "postprocess": d => [d[0]] },
        { "name": "ArgsDef", "symbols": [kLPAREN, "_", kRPAREN], "postprocess": d => [] },
        { "name": "ArgsDef", "symbols": [kLPAREN, "_", "IdentifierList", "_", kRPAREN], "postprocess": d => d[2] },
        { "name": "IdentifierList", "symbols": ["Identifier_Atom"], "postprocess": d => [d[0]] },
        { "name": "IdentifierList", "symbols": ["IdentifierList", "_", kCOMMA, "_", "Identifier_Atom"], "postprocess": d => d[0].concat(d[4]) },
        { "name": "LambdaDef", "symbols": ["LambdaArgsDef", "_", kCALLS, "_", "Exp"], "postprocess": d => node('lambda', { args: d[0], exp: d[4] }, d[2].position) },
        { "name": "LambdaDef", "symbols": ["LambdaArgsDef", "_", kCALLS, "_", "Block"], "postprocess": d => node('lambda', { args: d[0], statements: d[4] }, d[2].position) },
        { "name": "Args", "symbols": [kLPAREN, "_", kRPAREN], "postprocess": d => [] },
        { "name": "Args", "symbols": [kLPAREN, "_", "ExpList", "_", kRPAREN], "postprocess": d => d[2] },
        { "name": "ExpList", "symbols": ["Exp"], "postprocess": d => [d[0]] },
        { "name": "ExpList", "symbols": ["ExpList", "_", kCOMMA, "_", "Exp"], "postprocess": d => d[0].concat(d[4]) },
        { "name": "Paren", "symbols": [kLPAREN, "_", "Exp", "_", kRPAREN], "postprocess": d => d[2] },
        { "name": "Number_Atom", "symbols": [kNUMBER], "postprocess": d => node('number', d[0].number, d[0].position) },
        { "name": "Boolean_Atom", "symbols": [kBOOLEAN], "postprocess": d => node('boolean', d[0].boolean, d[0].position) },
        { "name": "Identifier_Atom", "symbols": [kIDENTIFIER], "postprocess": d => node('identifier', d[0].identifier, d[0].position) },
        { "name": "_$ebnf$1", "symbols": [] },
        { "name": "_$ebnf$1", "symbols": ["_$ebnf$1", "WS"], "postprocess": (d) => d[0].concat([d[1]]) },
        { "name": "_", "symbols": ["_$ebnf$1"], "postprocess": d => null },
        { "name": "__", "symbols": ["WS"] },
        { "name": "__", "symbols": ["__", "WS"], "postprocess": function () { } },
        { "name": "__N__$ebnf$1", "symbols": [kWS_B] },
        { "name": "__N__$ebnf$1", "symbols": ["__N__$ebnf$1", kWS_B], "postprocess": (d) => d[0].concat([d[1]]) },
        { "name": "__N__", "symbols": ["__N__$ebnf$1"], "postprocess": d => null },
        { "name": "_S_$ebnf$1", "symbols": [] },
        { "name": "_S_$ebnf$1", "symbols": ["_S_$ebnf$1", kWS], "postprocess": (d) => d[0].concat([d[1]]) },
        { "name": "_S_", "symbols": ["_S_$ebnf$1"], "postprocess": d => null },
        { "name": "__S__$ebnf$1", "symbols": [kWS] },
        { "name": "__S__$ebnf$1", "symbols": ["__S__$ebnf$1", kWS], "postprocess": (d) => d[0].concat([d[1]]) },
        { "name": "__S__", "symbols": ["__S__$ebnf$1"], "postprocess": d => null },
        { "name": "WS", "symbols": [kWS] },
        { "name": "WS", "symbols": [kWS_B] }
    ],
    ParserStart: "main",
};
export default grammar;
