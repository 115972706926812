import { CURRENT_PLATFORM } from '../../flags';
import { usersSettingsRef } from '@store';
import { storage } from '../../utilities';
import { arrayUnion } from 'firebase/firestore';


export async function disableDesktopAppInBrowser() {
  await storage.update(usersSettingsRef, {
    [`options.is_${CURRENT_PLATFORM}_app_disabled_in_chrome_browsers`]: true,
  }, 'HIDE_AUTOSAVE');
}

export async function enableDesktopAppInBrowser() {
  await storage.update(usersSettingsRef, {
    [`options.is_${CURRENT_PLATFORM}_app_disabled_in_chrome_browsers`]: false,
  }, 'HIDE_AUTOSAVE');
}

export async function dismissNotification(notificationString) {
  await storage.update(usersSettingsRef, {
    dismissed_notifications: arrayUnion(notificationString)
  }, 'HIDE_AUTOSAVE');
}