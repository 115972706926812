import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SnippetList from '../SnippetList/SnippetList';
import InstallExtension from '../InstallExtension/InstallExtension';
import MainPanel from '../MainPanel/MainPanel';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Grid from '@mui/material/Grid';
import { useIsXSmall, useTypedSelector } from '../../hooks';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import './inner_main.css';
import { useLocation } from 'react-router-dom';
import PaymentDialog from '../BillingDetails/PaymentDialog';
import SidebarResizerWithState from '../SnippetEditor/SidebarResizerWithState';
import TrashedTextBlazeDialog from '../TextBlaze/TrashedDialog/TrashedTextBlazeDialog';
import { usePushAISidebar } from '../../hooks/usePushAISidebar';


export default function InnerModuleSnippets() {
  let pushAISidebar = usePushAISidebar();

  return  (<>
    {!pushAISidebar && <SnippetListDrawer />}

    <div className="container-fluid page-body">
      <Grid container sx={{ height: '100%', flexWrap: 'nowrap' }}>
        {!pushAISidebar && <SnippetListGrid />}
        <Grid item style={{ height: '100%', flex: 1, overflowX: 'hidden' }}>
          <ErrorBoundary>
            <MainPanel />
            <PaymentDialog />
          </ErrorBoundary>
        </Grid>
      </Grid>

      <InstallExtension/>
      <TrashedTextBlazeDialog />
    </div>
  </>);
}

function SnippetListGrid() {
  let isXSmall = useIsXSmall();
  const paddingLeft = 15;

  let snippetSidebarSize = useTypedSelector(state => state.uiState?.snippetAppSidebarSize);
  if (isXSmall) {
    return null;
  }

  return <Grid
    item
    sx={[{
      position: 'relative',
      height: '100%'
    }, snippetSidebarSize ? {
      width: snippetSidebarSize
    } : {
      width: {
        sm: '33%',
        md: '25%'
      }
    }]}
  >


    <SidebarResizerWithState
      type="snippetAppSidebarSize"
      align="right"
      parentSelector=".page-body"
      offsetX={-16}
    />
    <div style={{
      paddingLeft,
      height: '100%',
      backgroundColor: '#fdfbfb'
    }}>
      <ErrorBoundary>
        <SnippetList />
      </ErrorBoundary>
    </div>
  </Grid>;
}


function SnippetListDrawer() {
  let sidebarOpen = useTypedSelector(store => store.uiState.sidebarOpen);
  let dispatch = useDispatch();
  let {
    pathname,
    hash,
    search
  } = useLocation();

  let isXSmall = useIsXSmall();
  useEffect(() => {
    if (!isXSmall) {
      return;
    }
    dispatch({
      type: 'CLOSE_SIDEBAR'
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isXSmall, pathname, hash, search]);


  if (isXSmall) {
    return <SwipeableDrawer
      open={sidebarOpen}
      onOpen={() => dispatch({
        type: 'OPEN_SIDEBAR'
      })}
      onClose={() => dispatch({
        type: 'CLOSE_SIDEBAR'
      })}
      classes={{
        paper: 'snippet-list-drawer-container'
      }}
    >
      <Box
        sx={{
          width: '300px',
          paddingLeft: 1,
          maxHeight: '100%'
        }}
      >
        {sidebarOpen && <SnippetList styleContent={{ marginRight: 8 }} />}
      </Box>
    </SwipeableDrawer>;
  }

  return null;
}