import React, { useState } from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip
} from '@mui/material';
import moment from 'moment';
import { showDelete, toast } from '../../message';
import AsyncButton from '../AsyncButton/AsyncButton';
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useOnMount from '../../hooks/useOnMount';

/**
 * @param {string|number} time
 * @return {string}
 */
function formatTime(time) {
  let dt;
  if (typeof time === 'string') {
    dt = moment
      .utc(time, moment.ISO_8601)
      .local();
  } else {
    dt = moment(time);
  }
  return dt.format('MMMM D, YYYY hh:mm a');
}


/**
 * @typedef {object} TrashedDialogLabels
 * @property {string} groupType
 * @property {string} groupTypeCapitalized
 * @property {string} groupTypePlural
 */

/**
 * @typedef {object} TrashedData
 * @property {string} id
 * @property {string} name
 * @property {React.ReactNode=} label
 * @property {React.ReactElement=} subLabel
 * @property {string} icon
 * @property {object} [trashed_by]
 * @property {string} trashed_by.email
 * @property {string?} trashed_by.name
 * @property {number | string} trashed_at
 */

/** 
 * @template {TrashedData} TTrashedData
 * @template {{ icon: string }} TIconProps
 * 
 * @param {object} props
 * @param {TrashedDialogLabels} props.labels
 * @param {boolean=} props.hideDelete
 * @param {TTrashedData[]} props.data
 * @param {(item: TTrashedData) => Promise} props.onDelete
 * @param {(item: TTrashedData) => Promise} props.onRestore
 * @param {boolean=} props.error
 * @param {React.ComponentType<TIconProps>=} props.IconComponent
 * @param {Omit<TIconProps, "icon">=} props.IconComponentProps
 */
const TrashedList = function({
  labels,
  hideDelete,
  data,
  onDelete,
  onRestore,
  error,
  IconComponent,
  IconComponentProps
}) {
  const IconComponentPropsTyped = /** @type {TIconProps} */ (IconComponentProps);
  const [isUpdating, setIsUpdating] = useState(false);
  // where to use rendering counter
  const [renderingCounter, setRenderingCounter] = useState(0);


  useOnMount(() => {
    // re-render the list every one-minute to update the time ago labels
    const timerId = setInterval(() => {
      setRenderingCounter(counter => counter + 1);
    },  1000 * 60);
    return () => clearTimeout(timerId);
  });

  if (error || !data?.length) {
    return null;
  }
  return (
    <List key={renderingCounter}>
      {data?.map((entry) =>
        <ListItem
          key={entry.id}
        >
          <ListItemIcon>
            <IconComponent
              { ...IconComponentPropsTyped}
              icon={entry?.icon}
            />
          </ListItemIcon>
          <ListItemText
            primary={entry.label || entry.name}
            secondary={<>
              {entry.subLabel}
              <span>
                <span>Deleted </span>
                {!!entry.trashed_by && (
                  <span>by <b title={entry.trashed_by?.email}>{entry.trashed_by?.name || 'Deleted user'}</b>, </span>
                )}
                <Tooltip title={formatTime(entry.trashed_at)}><span>{moment(entry.trashed_at).fromNow()}</span></Tooltip>.
              </span>
            </>}
            sx={{
              overflow: 'hidden',
              flex: '1 1 0%'
            }}
            primaryTypographyProps={{
              sx: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }
            }}
            secondaryTypographyProps={{
              sx: {
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 1
              }
            }}
          />
          <Tooltip
            title="Restore"
          >
            <span>
              <AsyncButton
                sx={(theme) => ({
                  ml: 1,
                  minWidth: 20,
                  [theme.breakpoints.between('xs', 450)]: {
                    '.MuiButton-startIcon': {
                      mx: 0
                    },
                    '.btn-txt': {
                      display: 'none'
                    }
                  }
                })}
                variant="outlined"
                disabled={isUpdating}
                onClick={async (done) => {
                  try {
                    setIsUpdating(true);
                    await onRestore(entry);
                  } catch (err) {
                    toast(`Error restoring the ${labels.groupType}: ${err.message}`, {
                      intent: 'danger'
                    });
                    return;
                  } finally {
                    done();
                    setIsUpdating(false);
                  }

                  toast(`${labels.groupTypeCapitalized} "${entry.name}" restored.`, {
                    intent: 'success'
                  });
                }}
                size="small"
                startIcon={
                  <RestoreIcon/>
                }
              >
                <span className="btn-txt">Restore</span>
              </AsyncButton>
            </span>
          </Tooltip>
          <ItemOptions
            showDelete={!hideDelete}
            onDelete={() => {
              showDelete({
                item: <>the {labels.groupType} <b>{entry.name}s</b></>,
                confirm: `I understand that this ${labels.groupType} will be permanently deleted.`,
                confirmButtonText: 'Delete permanently',
                onDelete: async () => {
                  try {
                    setIsUpdating(true);
                    await onDelete(entry);
                  } catch (err) {
                    toast(`Error permanently deleting the ${labels.groupType}: ${err.message}`, {
                      intent: 'danger'
                    });
                    return;
                  } finally {
                    setIsUpdating(false);
                  }

                  toast(`${labels.groupTypeCapitalized} permanently deleted.`, {
                    intent: 'success'
                  });
                }
              });
            }}
          />
        </ListItem>
      )}
    </List>
  );
};

export default TrashedList;

/**
 * 
 * @param {object} props
 * @param {boolean} props.showDelete
 * @param {() => any} props.onDelete
 */
const ItemOptions = ({
  showDelete,
  onDelete
}) => {
  const [anchorEl, setAnchorEl] = React.useState(/** @type {HTMLElement} */ (null));
  // Add more conditions here.
  const showMenu = !!showDelete;
  if (!showMenu) {
    return null;
  }
  return (<>
    <IconButton
      onClick={(evt) => setAnchorEl(evt.currentTarget)}
    >
      <MoreVertIcon fontSize="small"/>
    </IconButton>
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {showDelete && <MenuItem
        onClick={() => {
          onDelete();
          setAnchorEl(null);
        }}
      >
        <ListItemIcon><DeleteIcon color="error" /></ListItemIcon>
        <ListItemText>Delete permanently</ListItemText>
      </MenuItem>}
    </Menu>
  </>
  );
};
