import React from 'react';
import ArrowPopper from '../ArrowPopper/ArrowPopper';
import { ClickAwayListener, Paper } from '@mui/material';

/**
 * @param {Omit<Parameters<typeof import('./EmojiSelector').default>[0], 'emojiData'> & { children: React.ReactNode }} props 
 */
export default function EmojiSelectorPopper(props) {
  return <ArrowPopper
    translate="no"
    placement={props.placement || 'top'}
    anchorEl={props.target}
    open
    modifiers={[
      {
        name: 'flip',
        enabled: true,
      },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          padding: 8,
          rootBoundary: 'viewport',
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, 7]
        }
      }
    ]}
  > 
    <ClickAwayListener
      mouseEvent="onMouseDown"
      onClickAway={()=>{
        props.onClose && props.onClose();
      }}
    >
      <Paper
        onClick={(e) => {
          e.stopPropagation();
        }}
        elevation={2}
        style={Object.assign({
          border: 'solid 1px #f2f2f2',
          boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 4px 6px 0px rgba(0,0,0,0.14), 0px 6px 12px 0px rgba(0,0,0,0.12)',
          // to avoid jump of popper (from bottom to top and then back to bottom)
          //  when the "remove icon" button appears before the emojipicker has appeared
          minHeight: '400px',
        }, props.style)}
        className="suggestion-popper"
      >
        {props.children}
      </Paper>
    </ClickAwayListener>
  </ArrowPopper>;
}