import { useEffect, useState } from 'react';
import { checkExtensionInstalled } from '../extension';
import { useTypedSelector } from '../hooks';
import { isAiBlaze } from '../aiBlaze';


export function usePushAISidebar() {
  let [isInstalled, setIsInstalled] = useState(true);

  let { hasAIBlazeSidebarOpened, hasAIBlazeSidebarBeenUsed } = useTypedSelector(store => ({
    hasAIBlazeSidebarOpened: store.userState?.options?.ai_sidebar_opened,
    hasAIBlazeSidebarBeenUsed: store.userState?.options?.ai_sidebar_used
  })
  );

  useEffect(() => {
    (async function () {
      if (isAiBlaze) {
        let installed = await checkExtensionInstalled();
        setIsInstalled(installed);
      }
    })();
  }, []);

  if (isInstalled && isAiBlaze) {
    if (!hasAIBlazeSidebarOpened) {
      return 'OPEN';
    }
    if (!hasAIBlazeSidebarBeenUsed) {
      return 'USE';
    }
  }
  
  return null;
}