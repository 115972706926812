import {
  DialogContent, LinearProgress
} from '@mui/material';
import React from 'react';

/**
 * 
 * @param {object} props
 * @param {boolean} props.loading
 * @param {React.ReactNode} props.children
 * @returns 
 */
const TrashedDialogContent = ({
  loading,
  children
}) => {
  return (
    <DialogContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: 0,
        position: 'relative'
      }}
    >
      {loading && (
        <LinearProgress
          variant="indeterminate"
          sx={{
            position: 'absolute',
            top: 0,
            width: '100%'
          }}
        />
      )}
      {children}
    </DialogContent>
  );
};

export default TrashedDialogContent;