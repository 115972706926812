import React, { useEffect, useState } from 'react';
import AsyncButton from '../AsyncButton/AsyncButton';
import { toast } from '../../message';
import {
  getAuth,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword
} from 'firebase/auth';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { log } from '../../logging/logging';
import T from '@mui/material/Typography';
import PasswordIcon from '@mui/icons-material/LockOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Alert
} from '@mui/material';
import { isSamlUser } from '../Auth/saml_auth';
import { validateNewPassword } from '../Auth/auth_utilities';



export function ChangePassword() {

  const {
    isPassword,
    email,
    firebaseUser
  } = useTypedSelectorShallowEquals((store) => {
    return {
      isPassword: !!(store.userState.providerData && !store.userState.providerData.find(x => x.providerId !== 'password')),
      email: store.userState.email.toLowerCase(),
      firebaseUser: store.userState.firebaseUser
    };
  });

  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [newPassword2Error, setNewPassword2Error] = useState('');
  // Assuming it is saml user. So that we don't show links and hide them later
  // Hiding and then showing it with a delay seems better
  const [isSaml, setIsSaml] = useState(true);
  useEffect(() => {
    //If its not password or change in gsuite email. Not required to calculate
    if (!isPassword) {
      return;
    }
    (async () => {
      setIsSaml(await isSamlUser(email));
    })();
  }, [email, isPassword]);
  const handleDialogOpen = (done) => {
    setOpen(true);
    done();
  };
  const handleDialogClose = () => {
    setOpen(false);
    setOldPassword('');
    setNewPassword('');
    setNewPassword2('');
    setNewPasswordError('');
    setNewPassword2Error('');
  };

  const handleTabChange = (_event, value) => {
    setTabIndex(value);

    // validation changes based on tab
    if (1 === value) {
      setNewPasswordError(newPassword && validatePassword(newPassword));
      setNewPassword2Error(newPassword2 && validatePassword2(newPassword2));
    }
  };

  const validatePassword = (input) => {
    return validateNewPassword(input);
  };

  const validatePassword2 = (input, password1) => {
    if (input && input !== (password1 || newPassword)) {
      return 'Your passwords do not match.';
    }
  };

  const canReset = () => {
    return oldPassword && newPassword && newPassword2 && !newPasswordError && !newPassword2Error;
  };

  const changePassword = (done) => {
    log({ category: 'Account', action: 'Attempt to change password' });

    reauthenticateWithCredential(firebaseUser, EmailAuthProvider.credential(
      email,
      oldPassword
    )).then(() => {
      updatePassword(firebaseUser, newPassword).then(() => {
        handleDialogClose();
        toast('Your password has been changed.', {
          duration: 4000,
          intent: 'success'
        });
        log({
          action: 'Change password'
        });
        done();
      }).catch((error) => {
        toast(`Failed to update password. ${error}`, {
          duration: 20000,
          intent: 'danger'
        });
      });
      done();
    }).catch((error) => {
      toast(`Failed to update password. ${error}`, {
        duration: 20000,
        intent: 'danger'
      });
      done();
    });
  };

  const resetPasswordViaEmail = (done) => {
    log({ category: 'Authentication', action: 'Attempt reset password' });

    sendPasswordResetEmail(getAuth(), email).then(() => {
      log({ category: 'Authentication', action: 'Reset password sent' });
      done();
      handleDialogClose();
      toast('Sent password reset email.', {
        duration: 4000,
        intent: 'success'
      });
    }, (error) => {
      log({ category: 'Authentication', action: 'Failed reset password' });
      done();
      toast(`Failed to send password reset email. ${error}`, { 
        duration: 20000,
        intent: 'danger'
      });
    });
  };

  const handleSubmit = (done) => {
    if (!tabIndex) {
      resetPasswordViaEmail(done);
    } else if (tabIndex === 1 && canReset()) {
      changePassword(done);
    }
  };

  const chagePasswordComponents = {
    content:
      <form onSubmit={handleSubmit}>
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start" style={{ opacity: 0.4 }}><PasswordIcon fontSize="small" /></InputAdornment>,
          }}
          helperText=" " // intentionally left blank to render empty line (spacing) under "oldPassword" field so that it stays fisually aligned with other fields 
          fullWidth
          variant="outlined"
          type="password"
          label="Current password"
          required
          onChange={(e) => {
            let v = e.target.value;
            setOldPassword(v);
          }}
          size="small"
          sx={{
            my: .8
          }}
        />
        <TextField
          fullWidth
          label="New password"
          helperText={newPasswordError || ' '}
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start" style={{ opacity: 0.4 }}><PasswordIcon fontSize="small" /></InputAdornment>,
          }}
          type="password"
          size="small"
          required
          value={newPassword}
          onChange={(e) => {
            let v = e.target.value;
            setNewPassword(v);
            setNewPasswordError(v && validatePassword(v));
            setNewPassword2Error(v && validatePassword2(newPassword2, v));
          }}
          error={!!newPasswordError}
          sx={{
            my: .8
          }}
        />
        <TextField
          fullWidth
          label="Repeat new password"
          helperText={newPassword2Error || ' '}
          InputProps={{
            startAdornment: <InputAdornment position="start" style={{ opacity: 0.4 }}><PasswordIcon fontSize="small" /></InputAdornment>,
          }}
          variant="outlined"
          type="password"
          size="small"
          required
          value={newPassword2}
          onChange={(e) => {
            let v = e.target.value;
            setNewPassword2(v);
            setNewPassword2Error(v && validatePassword2(v));
          }}
          error={!!newPassword2Error}
          sx={{
            my: .8
          }}
        />
        <Alert severity="info">
          New password must be at least 8 characters long.
        </Alert>
      </form>,
    action:
      <AsyncButton
        variant="contained"
        color="primary"
        onClick={(done) => handleSubmit(done)}
        style={{ marginLeft: 20 }}
        disabled={!canReset()}
      >Change password</AsyncButton>
  };

  const resetViaEmailComponents = {
    content: <T paragraph>You will receive an email to <strong>{email}</strong> with password change request link.</T>,
    action: <AsyncButton
      variant="contained"
      color="primary"
      onClick={(done) => resetPasswordViaEmail(done)}
      style={{
        marginLeft: 18
      }}
    >Send email</AsyncButton>
  };
  if (isSaml) {
    return null;
  } else if (isPassword) {
    return <>
      <AsyncButton
        sx={{
          m: 1,
          ml: 2
        }}
        size="small"
        onClick={handleDialogOpen}
      >Change password</AsyncButton>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="xs"
      >
        <DialogContent>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            centered
          >
            <Tab label="Reset via email" />
            <Tab label="Change password" />
          </Tabs>

          <div style={{ padding: 25, width: '100%' }}>
            {tabIndex === 0 ? resetViaEmailComponents.content : chagePasswordComponents.content}
          </div>      
        </DialogContent>
        <DialogActions>
          <Box padding={2}>
            <Button
              onClick={handleDialogClose}
            >Cancel</Button>
            {tabIndex === 0 ? resetViaEmailComponents.action : chagePasswordComponents.action}
          </Box>
        </DialogActions>
      </Dialog>
    </>;
  } else {
    // if Gsuite, don't show anything
    return null;
  }
}