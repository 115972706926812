import React from 'react';
import { Chip, CircularProgress } from '@mui/material';
import { useTables } from '../../hooks/useTables';
import DatabaseChipIcon from './DatabaseChipIcon';
import T from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/WarningAmber';
import { OpenSpaceInNew } from '../Utilities/OpenInNewSpace';


/**
 * @param {object} props 
 * @param {string} props.id
 */
export function DatabaseLabel(props) {
    
  // we use a broader query than necessary to take advantage of broader caching
  /** @type {{loading: boolean, data: SpaceSummaryObjectType[], error: boolean}} */
  let { loading, data, error } = useTables('applications/summary/', {
    cache_seconds: 30 * 60
  });

  if (loading) {
    return <div style={{ display: 'inline-flex', alignItems: 'center', whiteSpace: 'nowrap', marginLeft: 6, marginRight: 6 }}>
      <CircularProgress size={16} style={{ marginRight: 6 }} />
      <T color="textSecondary">Space Loading...</T>
    </div>;
  }
  // data.find may not be mocked in tests
  let db = (error || !data?.find) ? null : data.find(x => x.id === props.id);

  if (!db) {
    return <Chip color="error" size="small" icon={<WarningIcon />} label={<>Space not accessible 
      <OpenSpaceInNew databaseID={props.id} small styles={{ style: { color: 'white', opacity: .9 } }} /></>} style={{ marginLeft: 6, marginRight: 6 }} />;
  }

  return <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', marginLeft: 6, marginRight: 6 }}>
    <DatabaseChipIcon
      simple
      icon={db.icon} />
    <T variant="subtitle1" style={{ marginLeft: 12, flex: 1, marginRight: 12, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 180 }}>{db.name}</T>
    
    <OpenSpaceInNew databaseID={props.id} />
  </div>;
}