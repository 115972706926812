import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import T from '@mui/material/Typography';
// @ts-ignore
import celebrateImg from './celebrate.png';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTypedSelector } from '../../hooks';
import { useDispatch } from 'react-redux';
import { fullAppName } from '../../raw_flags';

function WelcomeBackBase() {
  let dispatch = useDispatch();

  let isRelogin = useTypedSelector(store => store.userState?.isRelogin);

  if (!isRelogin) {
    return null;
  }

  function close() {
    dispatch({
      type: 'DISMISS_LOGGING_BACK_IN'
    });
  }

  return (
    <Dialog
      open
      disableEscapeKeyDown={false}
      PaperProps={{
        style: {
          width: 400
        }
      }}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          close();
        }
      }}
    >
      <IconButton
        style={{
          position: 'absolute',
          top: 12,
          right: 12,
          opacity: .7
        }}
        size="small"
        title="Dismiss"
        onClick={async () => {
          close();
        }}
      ><CloseIcon /></IconButton>
      
      <DialogContent style={{
        textAlign: 'center',
        padding: '20px 30px'
      }}>
        <T variant="h4" style={{
          marginBottom: 40,
          marginTop: 20
        }}>
          Welcome back!
        </T>

        <img
          src={celebrateImg}
          alt="Celebrate"
          style={{
            height: 260
          }}
        />

        <T variant="body1" style={{
          marginTop: 36,
          marginBottom: 30
        }}>
          <span style={{ color: '#e04f40', fontWeight: 'bold' }}>Hey!</span> We've been hard at work improving {fullAppName}. <a href="https://community.blaze.today/c/news/8" target="_blank" rel="noopener noreferrer" onClick={() => close()}>Check out our updates</a> to see what's new since your last log-in.
        </T>
      </DialogContent>
     
    </Dialog>
  );
}


const WelcomeBack = React.memo(WelcomeBackBase);
export default WelcomeBack;
