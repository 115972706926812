import React, { useMemo, useState } from 'react';
import T from '@mui/material/Typography';
import { Avatar, Box, styled } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import { isExtension } from '../../extension_utilities';
import { isElectronApp } from '../../flags';
import { getSiteItemGroupingKey } from '../../snippet_processor/DownstreamProcess';
import { ShowError } from './SharedComponents';
import { sendToExtension } from '../../extension';

const isNotDownstream = !(isExtension() || isElectronApp());

const Accordion = styled((props) => {
  return <MuiAccordion disableGutters elevation={0} square {...props} />;
})(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  ...(isNotDownstream ? { borderLeft: 'none', borderRight: 'none', } : null),
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  width: '100%',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.5rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '5px 0 5px 0',
    marginLeft: theme.spacing(1),
  },
  minHeight: 0
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  overflow: 'hidden',
  maxHeight: '160px',
  overflowY: 'auto',
}));

/** @typedef {Record<string, { matches: { tabId: number, items: { res: (string | string[] | { error: string; }), item: import('../../snippet_processor/DownstreamProcess').SiteSelectorItem }[] }[], page: string, group: string }>} PageGroupMatchesType */
/**
 * @param {object} props 
 * @param {PageGroupMatchesType[string]} props.matches
 * @param {(tabId: number) => void} props.onMatchSelect
 * @param {number} props.selectedTabId
 * @param {Record<string, { title: string, favicon: string }>} props.tabData
 * @returns 
 */
function PageSelector({ matches, onMatchSelect, selectedTabId, tabData }) {
  const matchedTabIds = matches?.matches?.map(x => x.tabId);
  if (matchedTabIds && matchedTabIds.length === 1) {
    // Don't show the page selector if only one page has matched
    // and the item has select=ifneeded set on it
    let shouldShow = false;
    for (const { items } of matches.matches) {
      for (const { item } of items) {
        if (item.select === 'yes') {
          shouldShow = true;
          break;
        }
      }
    }
    if (!shouldShow) {
      return null;
    }
  }
  const group = matches.group, page = matches.page; 

  const ICON_DIMENSION = 16;

  return <div className="page-match-container">
    {!matchedTabIds?.length && <T variant="caption" style={{
      margin: '5px 10px',
      display: 'inline-block',
    }}>⚠️ No matching tabs found for {page}!</T>}
    {!!matchedTabIds?.length &&
      <>
        {group && <T variant="caption">{group}</T>}
        <div className="browser-tabs-list">
          {matchedTabIds.map((tabId, index) => {
            const { title, favicon } = tabData[tabId];
            const tooltipTitle = <Box sx={{ padding: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div>{title}</div><IconButton onClick={(e) => {
                if (isExtension()) {
                  window.parent.postMessage({ type: 'background', msg: { request: 'focusTab', tabId }, }, '*');
                } else {
                  sendToExtension({ type: 'focusTab', tabId, });
                }
                // don't trigger the onChange handler
                e.stopPropagation();
                e.preventDefault();
              }} data-test-id="view-tab-button" sx={{ color: 'white' }}>
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Box>;

            return <button
              key={index} 
              onClick={() => {
                onMatchSelect(tabId);
              }}
              className={(selectedTabId === matchedTabIds[index + 1] ? 'browser-tab-before' : selectedTabId === matchedTabIds[index - 1] ? 'browser-tab-after' : selectedTabId === matchedTabIds[index] ? 'browser-tab-selected' : '') + ' browser-tab-item'}>
              <Tooltip title={tooltipTitle} arrow placement="bottom">
                {/* span is required as a direct descendant to show the tooltip */}
                <span className="tooltip-span"> 
                  <Box
                    sx={{
                      display: 'flex',
                      maxWidth: '100%',
                      alignItems: 'center',
                    }}>
                    <Avatar alt={page} key={page} variant="square" style={{ width: ICON_DIMENSION, height: ICON_DIMENSION, marginRight: '5px' }} src={favicon}/>
                    <T
                      sx={{
                        overflow: 'hidden',
                        // clip text instead of showing ellipsis 
                        textOverflow: 'clip',
                        whiteSpace: 'nowrap',
                        // align to center
                        height: '20px',
                        lineHeight: '20px',
                      }}
                      variant="caption">
                      {title}
                    </T>
                  </Box>
                  <div className="round-corner"></div>
                </span>
              </Tooltip>
            </button>;
          })}
        </div>
      </>}
  </div>;
}

const PageSelectorMemo = React.memo(PageSelector);

/**
 * @param {object} props 
 * @param {import('../../snippet_processor/DownstreamProcess').SiteSelectorItem[]} props.items
 * @param {import('../../snippet_processor/DownstreamProcess').ConfigDefType['usedSiteTabSelections']} props.selectedTabs
 * @param {import('../../snippet_processor/DownstreamProcess').AllTabsDataType} props.allData
 * @param {(arg: { groupKey: string, tabId: number, res: object }) => void} props.onMatchSelect
 * @returns 
 */
function SitePageSelector({ items, onMatchSelect, allData, selectedTabs }) {
  const [open, setOpen] = useState(true);

  const { keyGroups, tabData, distinctGroups } = useMemo(() => {
    const DEF_RETURN = {
      keyGroups: /** @type {PageGroupMatchesType} */ (null), 
      tabData: /** @type {Record<string, { title: string, favicon: string }>} */ (null),
      distinctGroups: /** @type {string[]} */ (null),
    };
    if (!items || items.length === 0) {
      return DEF_RETURN;
    }

    /** @type {PageGroupMatchesType} */
    const keyGroups = {};
    /** @type {Record<string, { title: string, favicon: string }>} */
    const tabData = {};
    for (const item of items) {
      if (item.select === 'yes' || item.page) {
        keyGroups[getSiteItemGroupingKey(item)] = { page: item.page, group: item.group, matches: [] };
      }
    }
    for (const element of allData) {
      const { tabId, data, title, favicon } = element;
      tabData[tabId] = { title, favicon };
      for (const { res, index } of data) {
        const item = items[index];
        if (item.select === 'yes' || item.page) {
          const groups = keyGroups[getSiteItemGroupingKey(item)].matches;
          if (!groups.length || groups[groups.length - 1]?.tabId !== tabId) {
            groups.push({ tabId, items: [] });
          }
          groups[groups.length - 1].items.push({ item, res });
        }
      }
    }

    const distinctGroups = Object.keys(keyGroups);

    // Show pages that have available matches ahead of those that don't
    distinctGroups.sort((a, b) => keyGroups[b].matches.length === 0 ? -1 : keyGroups[a].matches.length === 0 ? 1 : 0);

    return { keyGroups, tabData, distinctGroups };
  }, [allData, items]);

  if (!items || items.length === 0) {
    return null;
  }

  /** @type {{ group: string, pages: [string, string] }} */
  let invalidError = null;
  // Only to show an error to the user
  const groupPageMapping = {};
  for (const item of items) {
    if (item.group) {
      const existingPage = groupPageMapping[item.group];
      const newPage = item.page;
      if (existingPage !== undefined && existingPage !== newPage) {
        invalidError = { group: item.group, pages: [existingPage, item.page] };
        break;
      }
      groupPageMapping[item.group] = newPage;
    }
  }

  function inner() {
    if (invalidError) {
      return <ShowError msg={`You cannot use the same group across different page selectors. Currently, the group "${invalidError.group}" is used with these two pages:
      "${invalidError.pages[0]}" and "${invalidError.pages[1]}"`} />;
    }

    return distinctGroups.map(groupKey => {
      const matches = keyGroups[groupKey];
      return <PageSelectorMemo tabData={tabData} selectedTabId={selectedTabs[groupKey]?.tabId} key={groupKey} matches={matches} onMatchSelect={(tabId) => {
        onMatchSelect({ groupKey, tabId, res: keyGroups[groupKey].matches.find(x => x.tabId === tabId).items });
      }} />;
    });
  }

  const handleClick = () => {
    setOpen(!open);
  };

  return <Accordion expanded={open} onChange={handleClick}>
    <AccordionSummary>
      <T variant="caption">Select site command source tabs</T>
    </AccordionSummary>
    <AccordionDetails>
      {inner()}
    </AccordionDetails>
  </Accordion>;
}

export const SitePageSelectorMemo = React.memo(SitePageSelector);