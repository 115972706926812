import React, { useState } from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import useHotkey from '../../hotkeys_hook';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import T from '@mui/material/Typography';
import { Box } from '@mui/system';
import { APP_TARGET, isMacPlatform } from '../../flags';
import { isAiBlaze } from '../../aiBlaze';

export function KeyboardShortcutsDialog() {
  let [isOpen, setIsOpen] = useState(false);


  useHotkey('?', () => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const platformIcon = isMacPlatform() ? '⌘' : 'Ctrl';

  const isTextBlaze = APP_TARGET === 'TEXT';
  const isDataBlaze = APP_TARGET === 'DATA';
  const isPageBlaze = APP_TARGET === 'PAGE';

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="shortcuts-dialog"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Keyboard Shortcuts
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setIsOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{
        display: 'grid',
        gap: '40px',
        gridTemplateColumns: {
          xs: undefined,
          md: '1fr 1fr'
        },
        paddingBottom: '30px',
        paddingTop: '30px'
      }}
      dividers
      >
        {(isTextBlaze || isAiBlaze) && <>
          <SectionContent title="Global shortcuts">
            <Shortcut keys={[platformIcon, '/']} separator="+" action={`Open ${isAiBlaze ? 'prompts' : 'snippets'} search`}/>
            <Shortcut keys={['n', 's']} action={isAiBlaze ? 'New AI prompt' : 'New snippet'}/>
            <Shortcut keys={['n', 'f']} action={isAiBlaze ? 'New prompt folder' : 'New folder'}/>
            <Shortcut keys={['g', 'a']} action="Go to account menu"/>
            <Shortcut keys={['?']} action="Open this help dialog"/>
          </SectionContent>

          <SectionContent title="Editing snippets">
            <Shortcut keys={[platformIcon, 'Shift', 'k']} separator="+" action="Focus commands search"/>

            {isTextBlaze && <>
              {/* AI snippet have no preview, or Quill editor with formatting */}
              <Shortcut keys={[platformIcon, 'Shift', 'p']} separator="+" action="Toggle preview"/>
              <Shortcut keys={[platformIcon, 'b']} separator="+" action="Bold text"/>
              <Shortcut keys={[platformIcon, 'i']} separator="+" action="Italic text"/>
              <Shortcut keys={[platformIcon, 'u']} separator="+" action="Underline text"/>
              <Shortcut keys={[platformIcon, 'k']} separator="+" action="Link text"/>
            </>}
          </SectionContent>
        </>}

        {isPageBlaze && <>
          <Box sx={{ display: 'grid', gap: '40px' }}>
            <SectionContent title="Global shortcuts">
              <Shortcut keys={[platformIcon, '/']} separator="+" action="Open search"/>
              <Shortcut keys={['g', 'a']} action="Go to account menu"/>
              <Shortcut keys={['?']} action="Open this help dialog"/>
            </SectionContent>

            <SectionContent title="Homepage shortcuts">
              <Shortcut keys={['n', 's']} action="New site"/>
            </SectionContent>
          </Box>

          <SectionContent title="Editing a site">
            <Shortcut keys={[platformIcon, 'Shift', 'k']} separator="+" action="Focus commands search"/>
            <Shortcut keys={['n', 'p']} action="New page"/>
            <Shortcut keys={[platformIcon, 'b']} separator="+" action="Bold text"/>
            <Shortcut keys={[platformIcon, 'i']} separator="+" action="Italic text"/>
            <Shortcut keys={[platformIcon, 'u']} separator="+" action="Underline text"/>
            <Shortcut keys={[platformIcon, 'k']} separator="+" action="Link text"/>
          </SectionContent>
        </>}

        {isDataBlaze && <>
          <SectionContent title="Homepage shortcuts">
            <Shortcut keys={[platformIcon, '/']} separator="+" action="Search spaces"/>
            <Shortcut keys={['n', 's']} action="New space"/>
            <Shortcut keys={['g', 'a']} action="Go to account menu"/>
            <Shortcut keys={['?']} action="Open this help dialog"/>
          </SectionContent>

          <SectionContent title="Space shortcuts">
            <Shortcut keys={[platformIcon, '/']} separator="+" action="Search rows"/>
            <Shortcut keys={['n', 't']} action="New table"/>
            <Shortcut keys={['g', 't']} action="Go to table"/>
          </SectionContent>
        </>}

      </DialogContent>
    </Dialog>
  );
}

function SectionContent({ title, children }) {
  return         <Box sx={{
    border: '1px solid hsl(0 0% 90% / 1)',
    borderRadius: '4px',
    alignSelf: 'baseline'
  }}>
    <T fontWeight="bold" sx={{
      padding: '10px 20px',
      background: 'hsl(0 0% 98% / 1)',
      borderBottom: '1px solid hsl(0 0% 90% / 1)',
      borderRadius: '4px 4px 0 0'
    }}>
      {title}
    </T>

    {children}
  </Box>;

}

/**
 * @param {Object} props
 * @param {string[]} props.keys
 * @param {string=} props.separator
 * @param {string} props.action
 */
function Shortcut({ keys, separator, action }) {
  return <Box sx={{
    display: 'grid',
    gridTemplateColumns: '1fr 160px',
    padding: '10px 20px',
    borderBottom: '1px solid hsl(0 0% 90% / 1)',
    borderRadius: '4px 4px 0 0',

    '&:last-of-type': {
      borderBottom: 'none'
    }
  }}>
    <T component="span" mr={2}>{action}</T>

    <Box justifyContent="space-between" textAlign="right" fontSize="14px">
      {keys.map((key, index) => (
        <React.Fragment key={key}>
          <Box sx={{
            display: 'inline-block',
            padding: '3px 6px',
            lineHeight: 'normal',
            color: '#535158',
            backgroundColor: '#fbfafd',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: '#bfbfc3',
            border: '1px solid #dcdcde #dcdcde #bfbfc3',
            borderRadius: '3px',
            boxShadow: '0 -1px 0 #bfbfc3 inset',
            fontFamily: 'monospace'
          }}>
            {key}
          </Box>

          {index + 1 < keys.length && <Box mx={0.6} display="inline-block">
            {separator}
          </Box>}

        </React.Fragment>
      ))}
    </Box>
  </Box>;
}