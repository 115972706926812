import React, { useState } from 'react';
import EditableText from './EditableText';


function BufferedEditableTextBase(props) {
  let [editingValue, setEditingValue] = useState(null);

  let passThroughProps = {};
  for (let key in props) {
    if (!['onChange', 'validate', 'value'].includes(key)) {
      passThroughProps[key] = props[key];
    }
  }

  return <EditableText
    value={editingValue !== null ? editingValue : props.value}
    onChange={(v) => setEditingValue(v)}
    onCancel={() => setEditingValue(null)}
    onConfirm={(v) => {
      if (props.validate) {
        if (!props.validate(v)) {
          setEditingValue(null);
          return;
        }
      }
      if (props.onChange && v !== props.value) {
        props.onChange(v);
      }
      setEditingValue(null);
    }}
    {...passThroughProps}
  />;
}


const BufferedEditableText = React.memo(BufferedEditableTextBase);
export default BufferedEditableText;