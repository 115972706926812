import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_TARGET, DATA_APP_DOMAIN, TEXT_APP_DOMAIN, PAGE_APP_DOMAIN, AI_APP_DOMAIN } from '../flags';

/**
 * Navigates to the url based on the app type.
 */
const useAppNavigate = () => {
  const history = useHistory();
  const ref = useRef(
    /**
      * 
      * @param {string} url 
      * @param {AppType} appType
      * @param {('_blank')=} target 
      */
    (url, appType, target) => {
      const { url: newUrl, sameWindow } =  generateAppRoute(url, appType);
      if (sameWindow && target !== '_blank') {
        history.push(newUrl);
      } else {
        window.open(newUrl);
      }
    }
  );
  return ref.current;
};

export default useAppNavigate;

/**
 * 
 * @param {string} url 
 * @param {AppType} appType
 */
export const generateAppRoute = (url, appType) => {
  let newUrl = url;
  let sameWindow = true;
  if (
    [
      '/spaces',
      '/spaces/',
      '/snippets',
      '/snippets/',
      '/sites',
      '/sites/',
    ].includes(newUrl)
  ) {
    newUrl = '/';
  }
  if (APP_TARGET !== appType) {
    if (appType === 'TEXT') {
      newUrl = TEXT_APP_DOMAIN +  newUrl;
    } else if (appType === 'DATA') {
      newUrl = DATA_APP_DOMAIN +  newUrl;
    } else if (appType === 'PAGE') {
      newUrl = PAGE_APP_DOMAIN +  newUrl;
    } else if (appType === 'AI') {
      newUrl = AI_APP_DOMAIN +  newUrl;
    }
    sameWindow = false;
  }


  return {
    url: newUrl,
    sameWindow
  };
};