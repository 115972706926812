import { isAiBlaze } from './aiBlaze';
import { log } from './logging/logging';
import { EXTENSION_BROWSER_NAME, getCurrentBrowserName } from './extension';


const TB_CHROME_WEBSTORE_LINK = 'https://chromewebstore.google.com/detail/text-blaze-templates-and/idgadaccgipmpannjkmfddolnnhmeklj';
const TB_EDGE_STORE_LINK = 'https://microsoftedge.microsoft.com/addons/detail/fephhmmlanlhoiaphlodlhbmbnkmkckn';

const AI_CHROME_WEBSTORE_LINK = 'https://chromewebstore.google.com/detail/cebmnlammjhancocbbnfcglifgdpfejc';

export function installExtension() {
  let storeLink = {
    chrome: isAiBlaze ? AI_CHROME_WEBSTORE_LINK : TB_CHROME_WEBSTORE_LINK,
    edge: isAiBlaze ? AI_CHROME_WEBSTORE_LINK : TB_EDGE_STORE_LINK,
  }[getCurrentBrowserName()];

  log({ category: 'Extension', action: 'Opening Web Store to install' });
  window.open(storeLink, '_blank', 'menubar=yes,location=yes,resizable=yes,scrollbars=yes');
}

export function getFeedbackLink() {
  return {
    chrome: `${isAiBlaze ? AI_CHROME_WEBSTORE_LINK : TB_CHROME_WEBSTORE_LINK}/reviews`,
    edge: isAiBlaze ? AI_CHROME_WEBSTORE_LINK : TB_EDGE_STORE_LINK,
  }[EXTENSION_BROWSER_NAME];
}