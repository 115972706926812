import React, { useEffect } from 'react';
import { toast } from '../../message';
import { isDev, isSpark } from '../../flags';
import Box from '@mui/system/Box';


export function StatusNotification() {
  useEffect(() => {
    setTimeout(async () => {
      let data = null;
      try {
        let res = await fetch('https://community.blaze.today/t/33776/posts.json', {
       
        });
        data = await res.json();
      } catch (err) {
        // There seems to be a significant number of intermittent connection issues
        // Suppressing error, if user encounters an app issue they are likely to reload
        // when hopefully the request will now succeed.
        if (!err.message.includes('Failed to fetch')) {
          console.error(err);
        }
        return;
      }
      
      let posts = data?.post_stream?.posts;
      if (!posts || posts.length <= 1) {
        return;
      }

      let notificationHTML = posts[1].cooked;

      if (notificationHTML) {
        /**
         * If notification text contains "TESTING" in all caps, only show on spark
         * and dev.
         */
        if (notificationHTML.includes('TESTING') && !(isSpark() || isDev())) {
          return;
        }
        
        toast(<Box
          sx={{
            whiteSpace: 'pre-wrap',
            minWidth: 300,
            'a': {
              color: 'white',
              textDecoration: 'underline',
              ':hover': {
                color: 'white !important',
                textDecoration: 'underline',
              },
            },
          }}
    
          dangerouslySetInnerHTML={{ __html: notificationHTML }}
        ></Box>, {
          duration: 20000,
          intent: 'info'
        });
      }

      // delay to not impact initial load
    }, 4000);
  }, []);

  return null;
}