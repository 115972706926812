import React, { useEffect, useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import T from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useIsMounted } from '../../hooks';
import './editor_toolbar.css';
import { ERROR_DELAY_MS } from '../../flags';
;


/**
 * @param {object} props 
 * @param {object} props.context
 * @param {string|React.ReactElement} props.error
 * @param {string} props.errorLabel
 * @param {'bsql'|'formula'|'attribute'} [props.type]
 */
export default function EditorToolbar({
  context,
  error,
  errorLabel,
  type
}) {
  let isMounted = useIsMounted();
  let [showingError, setShowingError] = useState(false);
  let errorTimeoutRef = useRef(null);
  let lastErrorState = useRef(false);

  let hasError = error !== null;

  useEffect(() => {
    if (lastErrorState.current !== hasError) {
      lastErrorState.current = hasError;
    } else {
      // no change needed
      return;
    }

    if (hasError) {
      // We have delay so the error doesn't toggle on and off
      // incredibly fast when typing
      errorTimeoutRef.current = setTimeout(() => {
        if (isMounted.current) {
          setShowingError(true);
          context.setHasError(true);
        }
      }, ERROR_DELAY_MS);
    } else {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      if (showingError) {
        setShowingError(false);
      }

      context.setHasError(false);
    }
    // eslint-disable-next-line
  }, [hasError]);

  useEffect(() => {
    // on unmounting clear any error
    return () => {
      if (lastErrorState.current) {
        context.setHasError(false);
      }
    };
    // eslint-disable-next-line
  }, []);


  return (
    <Fade in>
      <div style={{
        marginTop: 10,
        display: 'flex',
        alignItems: 'center'
      }}>
        <div style={{ flex: 1, cursor: error ? 'help' : undefined }}>{showingError ?
          <Tooltip title={error || ''}>
            <Fade in timeout={200}>
              <div style={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <ErrorOutlineIcon fontSize="small" color="error" style={{
                  marginRight: 4
                }}/> <T color="error" variant="caption">{errorLabel}</T>
              </div>
            </Fade>
          </Tooltip>
          : (type === 'bsql' ? <div style={{
            display: 'flex',
            alignItems: 'center'
          }}><T variant="caption"><a href="https://blaze.today/bsql/reference/" target="_blank" rel="noreferrer">Learn more about BSQL…</a></T>
          </div> : null)}</div>

      </div>
    </Fade>
  );
}