import React, { useState, Suspense  } from 'react';
import Button from '@mui/material/Button';
import T from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import ExportList from './ExportList';
import { memberRestricted } from '../../flags';
import { useTypedSelector } from '../../hooks';
import { eagerlyLoadImport } from '../../utilities';
const SnippetImporter = React.lazy(eagerlyLoadImport(() => import('../SnippetImporter/SnippetImporter')));


export default function ImportExportPanel(_props) {
  let [importerOpen, setImporterOpen] = useState(false);
  const disableAdd = useTypedSelector(state => memberRestricted(state, 'create'));

  return <div style={{
    height: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingTop: 40,
    paddingBottom: 40,
    padding: 40,
    paddingLeft: 20,
    paddingRight: 60
  }}>
    {disableAdd ? null : <> 
      <T gutterBottom variant="h6">Import</T>

      <T paragraph color="textSecondary">Import exports from Text Blaze and other text expansion programs.</T>

      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setImporterOpen(true);
        }}
      >Import snippets</Button>
    </>}
    

    <T gutterBottom variant="h6" style={{ marginTop: 40 }}>Export</T>

    <T paragraph color="textSecondary">Export snippets from Text Blaze folders you are an owner of. If you are using this feature to share snippets with others, it is better to use the folder sharing feature as that will keep your snippets in sync.</T>

    <ExportList />

    <Suspense fallback={<Dialog
      open
      PaperComponent={props => <div style={{
        textAlign: 'center'
      }}>{props.children}</div>}
    >
      <CircularProgress size={150}/>
    </Dialog>}>
      {importerOpen && <SnippetImporter
        onClose={() => setImporterOpen(false)}
      />}
    </Suspense>
  </div>;
}