import React from 'react';
import T from '@mui/material/Typography';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { decompressDelta } from '../../delta_proto/DeltaProto';
import Shortcut from '../Shortcut/Shortcut';
import SnippetPreviewPanel from '../Snippet/SnippetPreviewPanel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AIIcon from '@mui/icons-material/AutoFixHigh';
import './public_snippet_preview.css';

/**
 * @param {object} props
 * @param {SnippetObjectType} props.snippet
 */
function PublicSnippetPreviewBase(props) {
  let snippet = props.snippet;

  function getValue() {
    return {
      id: snippet.content.id,
      // delta comes from Node and it's not an Uint8Array yet
      delta: decompressDelta(new Uint8Array(snippet.content.delta.data))
    };
  }
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          content: 'public-snippet-preview-accordion'
        }}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '100%'
        }}>
          {props.snippet.options?.is_ai && <AIIcon
            fontSize="medium"
            sx={{
              opacity: .7,
              mr: 2
            }}
          />}
          <T variant="h6" style={{ 
            paddingRight: 14, 
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}>{props.snippet.name}</T>
          <Shortcut shortcut={props.snippet.shortcut} style={{ cursor: 'pointer' }} />
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0, paddingBottom: 12, marginTop: -24 }}>
        <ErrorBoundary>
          <SnippetPreviewPanel
            snippet={snippet}
            snippetId={snippet.id}
            delta={getValue().delta}
            hideShortcut
            hideProMessage
            unsafe
          />
        </ErrorBoundary>
      </AccordionDetails>
    </Accordion>
  );
}

const PublicSnippetPreview = React.memo(PublicSnippetPreviewBase);
export default PublicSnippetPreview;
