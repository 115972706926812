import React from 'react';
import {
  Autocomplete, Divider, TextField
} from '@mui/material';
import GroupIcon from '../../Group/GroupIcon';
import { useTypedSelector } from '../../../hooks';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';

const ALL_GROUPS_ID = '__ALL__';
const ALL_GROUPS_LABEL = 'All folders';

/**
 * 
 * @param {object} props 
 * @param {string} props.value
 * @param {string[]} props.options
 * @param {(value: string) => any} props.onChange
 * @param {string=} props.clearText
 * @param {import("@mui/system").SxProps=} props.sx
 * @returns 
 */
const GroupSelector = ({
  value,
  options,
  onChange,
  sx
}) => {
  const groups = useTypedSelector((state) => state.dataState.groups);
  const getLabel = (id) => {
    if (id === ALL_GROUPS_ID) {
      return ALL_GROUPS_LABEL;
    }
    return groups[id]?.name;
  };
  return (
    <Autocomplete
      value={value || ALL_GROUPS_ID}
      options={options?.length ? [ALL_GROUPS_ID, ...options] : []}
      sx={sx}
      getOptionLabel={getLabel}
      disableClearable
      renderOption={(props, id) => {
        const getOption = (icon) => (
          <li
            {...props}
            key={id}
          >
            {icon}
            {getLabel(id)}
          </li>
        );
        if (id === ALL_GROUPS_ID) {
          return [
            getOption(<FolderCopyIcon sx={{
              marginRight: 1.2,
              color: '#888'
            }}/>),
            <Divider key={ALL_GROUPS_ID + '-divider'} />
          ];
        } else {
          return getOption(<GroupIcon
            groupId={id}
            containerStyle={{ verticalAlign: 'middle', marginRight: 10 }}
            iconStyle={{ color: '#888' }}
          />); 
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          label="Select snippets from a folder"
        />
      )}
      onChange={(_, val) => {
        return onChange(val === ALL_GROUPS_ID ? null : val);
      }}
    />
  );
};

export default GroupSelector;