// used for the "ATA" conference Dan attended
export const ataBusinessTrialHash = '#business_ata';
// used for the "Support Driven" conference Dan attended
export const sdBusinessTrialHash = '#business_sd';

export const signUpHashesTb =  [
  '#start',
  '#desktopauth',
  ataBusinessTrialHash,
  sdBusinessTrialHash
];

export const signUpHashesAi =  [
  '#start',
  // Included for backwards compatibility with older extension
  // TODO: remove in November
  '#start_ai',
];

export const signUpHashes =  [...signUpHashesAi, ...signUpHashesTb];


export function isBusinessTrialOffer() {
  // Don't allow  more trials 3 days after the conferences close
  const currentDate = new Date();
  const removeHash = () => window.history.replaceState(null, null, ' ');

  if (window.location.hash === ataBusinessTrialHash) {
    const ataTrialAvailableUntil = new Date('2024-05-11');

    if (currentDate > ataTrialAvailableUntil) {
      removeHash();

      return false;
    }

    return true;
  }

  if (window.location.hash === sdBusinessTrialHash) {
    const sdTrialAvailableUntil = new Date('2024-05-18');

    if (currentDate > sdTrialAvailableUntil) {
      removeHash();
      return false;
    }

    return true;
  }


  return false;
}