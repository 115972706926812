import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Diff from '../Diff/Diff';
import T from '@mui/material/Typography';
import { useIsMounted } from '../../hooks';
import { showDialog } from '../../message';
import DeltaViewer from './DeltaViewer';
import Shortcut from '../Shortcut/Shortcut';
import { uidToUserInfo } from '../PermissionList/utilities';
import { getEarliestCreation } from './change_utilities';
import UserAvatar from '../UserAvatar/UserAvatar';
// @ts-ignore
import activityHistory from './activity_history.svg';



function timeFormat(timestamp) {
  let t = moment(timestamp);
  if (moment().diff(t, 'minutes') <= 2) {
    // in last 2 minutes - use "just now"
    return <div>
      <T color="textSecondary" style={{ fontWeight: 'bold' }}>Just now</T>
    </div>;
  } else if (moment().diff(t, 'hours') < 22) {
    // in last day - use relative time "ago"
    return <div>
      <T color="textSecondary" style={{ fontWeight: 'bold' }}>{t.fromNow()}</T>
    </div>;
  } else {
    // use date and time
    return <>
      <div><T color="textSecondary" style={{ fontWeight: 'bold' }}>{t.format('LL')}</T></div>
      <div><T color="textSecondary">{t.format('LT')}</T></div>
    </>;
  }
}


/**
 * @param {object} props
 * @param {object} props.snippet
 * @param {boolean=} props.last
 * @param {boolean=} props.showFraming
 */
export default function SnippetChangeHistory(props) {
  let isMounted = useIsMounted();
  let [mostRecentUpdateBy, setMostRecentUpdateBy] = useState(null);
  let [expanded, setExpanded] = useState(false);

  let snippetData = props.snippet;
  let showDeleted = !snippetData.currentSnippet;
  let history = snippetData.changes;
  let mostRecentUpdatedByUid = snippetData.currentSnippet && snippetData.currentSnippet.data.updated_by;

  useEffect(() => {
    if (mostRecentUpdatedByUid) {
      uidToUserInfo(mostRecentUpdatedByUid).then(data => {
        if (isMounted.current) {
          setMostRecentUpdateBy(data);
        }
      });
    }
    // eslint-disable-next-line
  }, [mostRecentUpdatedByUid]);


  let showFraming = props.showFraming;
  

  function wrap(contents) {
    if (showFraming) {
      return <>
        <div style={{
          margin: '12px 24px',
          display: 'flex',
          alignItems: 'center'
        }}>
          <T variant="h6" style={{
            flex: 1,
            verticalAlign: 'middle',
            marginRight: 30,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>{snippetData.currentName}</T> <Shortcut shortcut={snippetData.currentShortcut} />
        </div>
        {contents}
        {!props.last && <div style={{
          marginTop: 20,
          marginBottom: 24,
          border: 'solid 1px #eee'
        }}></div>}
      </>;
    } else {
      return contents;
    }
  }

  let mostRecentUpdatedByEl = mostRecentUpdateBy && <UserAvatar info={mostRecentUpdateBy}/>;


  let hasMore = false;
  if (!expanded && showFraming) {
    let historyMaxLength = snippetData.currentDiff ? 1 : 2;
    if (history.length > historyMaxLength) {
      history = history.slice(0, historyMaxLength);
      hasMore = true;
    }
  }


  return wrap(
    <>
      <Timeline>
        {snippetData.showCurrent && <TimelineItem>
          <TimelineOppositeContent style={{
            width: 170,
            flex: 'none'
          }}>
            {timeFormat(snippetData.currentSnippet.data.updated_at ? snippetData.currentSnippet.data.updated_at.toMillis() : Date.now())}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <T paragraph style={{ display: 'flex' }}><span style={{ flex: 1, fontWeight: 'bold' }}>{history.length === 0 ? 'Added' : 'Current version'}</span></T>
            {snippetData.currentDiff && <>
              <T variant="subtitle2" paragraph>Changes made in this version</T>
              {snippetData.currentDiff.length ? <Diff changes={snippetData.currentDiff}/> : <T style={{ fontStyle: 'italic' }}>Formatting changes</T>}
            </>}
            {mostRecentUpdatedByEl ? <>
              <T variant="subtitle2" paragraph style={{ marginTop: 20 }}>Most recent update by</T>
              <div>{mostRecentUpdatedByEl}</div>
            </> : null}
            <div style={{ height: 40 }}></div>
          </TimelineContent>
        </TimelineItem>}


        {history.map((item, i) => {
          let isDeleted = i === 0 && showDeleted;

          let diff = JSON.parse(item.diff);
          // If it's been deleted or the time is really old (longer than our backups), use the backup timestamp
          // otherwise use the updated_at time for more precision
          let time = (isDeleted || (new Date(item.updated_at.value)).getTime() < getEarliestCreation()) ? item.timestamp.value : item.updated_at.value;

          let isCurrent = i === 0 && !snippetData.showCurrent && !showDeleted;
          let isNew = i === history.length - 1 && snippetData.currentSnippet && snippetData.currentSnippet.data.created_at && snippetData.currentSnippet.data.created_at.toMillis() > getEarliestCreation();
        
          let lastUpdatedByEl = <UserAvatar info={{
            email: item.last_updated_by_email,
            name: item.last_updated_by_name,
            imageUrl: item.last_updated_by_image_url,
            emailVerified: true,
            uid: ''
          }} />;
        

          return <TimelineItem key={i}>
            <TimelineOppositeContent style={{
              width: 170,
              flex: 'none'
            }}>
              {timeFormat(time)}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color={(isCurrent ? 'primary' : (isDeleted ? 'secondary' : undefined))} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {!isCurrent && 
              <Button
                size="small"
                style={{ float: 'right' }}
                onClick={() => {
                  showDialog({
                    contents: <DeltaViewer delta={item.delta}/>,
                    title: <>
                      <img
                        src={activityHistory}
                        style={{
                          width: 24,
                          height: 24,
                          opacity: .6,
                          verticalAlign: 'middle',
                          marginRight: 16
                        }}
                        alt="History"
                      />
                      Prior snippet version
                    </>,
                    contentStyle: { padding: '0px 0px 8px' },
                    closeButtonText: 'Close'
                  });
                }}
              >View this version
              </Button>}
              <T paragraph style={{ display: 'flex' }}><span style={{ flex: 1, fontWeight: 'bold' }}>{(isNew ? 'Added' : (isCurrent ? 'Current version' : (isDeleted ? 'Last version' :  'Prior version')))}</span></T>
              {(!hasMore && i === history.length - 1) ? <T color="textSecondary">Oldest available version</T> : null}
              {diff && <>
                <T variant="subtitle2" paragraph>Changes made in this version</T>
                {diff.length ? <Diff changes={diff}/> : <T color="textSecondary" variant="body2">Formatting changes</T>}
              </>}
              <>
                <T variant="subtitle2" paragraph style={{ marginTop: 20 }}>Last version update made by</T>
                <div>{lastUpdatedByEl}</div>
              </>
              <div style={{ height: 40 }}></div>
            </TimelineContent>
          </TimelineItem>;
        })}
      </Timeline>
      {hasMore && <div style={{
        textAlign: 'center',
        marginTop: 0,
        marginBottom: 16
      }}>
        <Button onClick={() => setExpanded(true)}>More</Button>
      </div>}
    </>
  );
}