import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { limit, orderBy, query, where } from 'firebase/firestore';
import { useAppsOnboardingCompleted, useTypedSelector, useTypedSelectorDeepEquals } from '../../hooks';
import {
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fab,
  ListItemIcon,
  ListItemText,
  MenuList,
  Popover,
  Tooltip
} from '@mui/material';
import T from '@mui/material/Typography';
import { compressDelta, decompressDelta } from '../../delta_proto/DeltaProto';
import MenuItem from '@mui/material/MenuItem';
import ArrowPopper from '../ArrowPopper/ArrowPopper';
import Paper from '@mui/material/Paper';
import SnippetPreviewPanel from '../Snippet/SnippetPreviewPanel';
import Button from '@mui/material/Button';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import SnippetFolderOutlinedIcon from '@mui/icons-material/SnippetFolderOutlined';
import Shortcut from '../Shortcut/Shortcut';
import { getConnectedConfigOptions } from '../../flags';
import Badge from '@mui/material/Badge';
import { extractDatabaseUsages } from './database_utilities';
import { FIELD_TO_TYPE } from '../SimpleSQLEditor/utilities';
import { escapeBSQLName } from './bsql_utilities';
import './space-snippets.css';
import { storage } from '../../utilities';
import { makeRef } from '../../firebase_utilities';
// @ts-ignore
import InsertionImg from '../Walkthrough/insertion.webp';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/AddCircle';
import AppLink from '../Utilities/AppLink';
import { escapeFormName } from '../SnippetEditor/editor_utilities';
import useOnMount from '../../hooks/useOnMount';

const DAY_IN_MILLIS = 24 * 60 * 60 * 1000;
const SUGGESTED_SNIPPETS_TIMEOUT_IN_MILLIS = 2 * DAY_IN_MILLIS;
const SUPPORTED_FIELD_TYPES_IN_INSERT_QUERIES = [
  'text',
  'long_text',
  'url',
  'number',
  'rating',
  'date',
  'email',
  'single_select',
  'multiple_select',
  'phone_number',
  'multiple_collaborators',
];

function generateShortcutPostfix(tableName) {
  return tableName.toLowerCase().replace(/[^a-z0-9]+/g, '');
}

/**
 * @type {{ canGenerate: (function(any): boolean), name: (function(any): string), shortcut: (function(any): string), content: (function(any): string)}[]}
 */
const AUTOMATIC_SNIPPETS = [
  {
    canGenerate: (table) => {
      const primary = table.fields.find(f => f.primary);
      return primary && !primary.error && table.fields.some(f => !f.error);
    },
    name: (table) => `Use row in ${table.name}`,
    shortcut: (table) => `/read${generateShortcutPostfix(table.name)}`,
    content: (table) => {
      const primary = table.fields.find(f => f.primary);
      const columns = table.fields
        .filter((column) => column.type in FIELD_TO_TYPE && !column.error)
        .map((column) => escapeBSQLName(column.name))
        .join(', ');
      return `{dbselect: SELECT ${columns} FROM ${escapeBSQLName(table.name)}; space=${table.database_id}; menu=yes}
${primary.name}: {=${escapeFormName(primary.name)}}`;
    },
  },
  {
    canGenerate: (table) => {
      const primary = table.fields.find(f => f.primary);
      return primary && SUPPORTED_FIELD_TYPES_IN_INSERT_QUERIES.includes(primary.type);
    },
    name: (table) => `Add row to ${table.name}`,
    shortcut: (table) => `/insert${generateShortcutPostfix(table.name)}`,
    content: (table) => {
      const primary = table.fields.find(f => f.primary);
      return `${primary.name}: {formtext: name=${primary.name}}
{dbinsert: INSERT INTO ${escapeBSQLName(table.name)}; space=${table.database_id}; autoaddfields=yes}`;
    }
  },
];

/**
 * Filter a group of snippets by the ones that use the given database
 * @param {SnippetObjectType[]} snippets Snippets to filter
 * @param {string} databaseId Database to filter by its usage
 * @returns {Promise<SnippetObjectType[]>} snippets used by the given database
 */
async function filterSnippetsUsage(snippets, databaseId) {
  const filteredSnippets = [];

  await Promise.all(snippets.map(async (snippet) => {
    const usages = await extractDatabaseUsages(snippet);

    if (!usages) {
      return;
    }
    if (!!usages[databaseId]) {
      filteredSnippets.push(snippet);
    }
  }));

  return filteredSnippets;
}

/**
 * Change all database ids in a given snippet queries to the current database id
 * @param {SnippetObjectType} snippet
 * @param {GroupObjectType} group
 * @param {string} newDatabaseId
 * @returns {Promise<any>}
 */
async function changeSnippetDatabaseIds(snippet, group, newDatabaseId) {
  const connected = group.connected;
  const databaseUsages = await extractDatabaseUsages(snippet);
  const databaseIds = Object.keys(databaseUsages);
  const newConnected = JSON.parse(JSON.stringify(connected));
  const d = decompressDelta(snippet.content.delta.toUint8Array());
  for (let op of d.ops) {
    if ((typeof op.insert === 'string') && op.insert.startsWith('{db')) {
      for (const databaseId of databaseIds) {
        op.insert = op.insert.replace(new RegExp(databaseId, 'g'), newDatabaseId);
        const databaseQueries = newConnected.database_queries[databaseId];
        delete newConnected.database_queries[databaseId];
        newConnected.database_queries[newDatabaseId] = (newConnected.database_queries[newDatabaseId] || []).concat(databaseQueries);
      }
    }
  }

  const newDelta = compressDelta(d);
  return Object.assign(JSON.parse(JSON.stringify(snippet)), {
    content: {
      delta: newDelta,
    },
    connected: newConnected,
  });
}

/**
 * @typedef {object} SpaceSnippetsPopoverProps
 * @property {object} database
 * @property {object=} currentTable
 * @property {boolean=} templatesMode
 * @property {object=} anchorPosition
 * @property {function=} showSelectFolderDialog
 * @property {object=} ref
 */

/**
 * @type React.FC<SpaceSnippetsPopoverProps>
 */
const SpaceSnippetsPopover = forwardRef((props, ref) => {
  const { currentTable, templatesMode } = props;

  let onboardingCompleted = useAppsOnboardingCompleted();

  const [suggestedSnippets, setSuggestedSnippets] = useState(/** @type {SnippetObjectType[]} */ ([]));
  const [suggestedSnippetsGroup, setSuggestedSnippetsGroup] = useState(/** @type {GroupObjectType} */ (null));
  const [linkedSnippets, setLinkedSnippets] = useState(
    /** @type {Set<string>} */ (new Set())
  );
  const [linkedSnippetGroups, setLinkedSnippetGroups] = useState(
    /** @type {{ [groupId: string]: { loaded: boolean }}} */ ({})
  );
  const [automaticSnippets, setAutomaticSnippet] = useState([]);

  const snippetsButton = useRef();

  const [isSnippetsOpen, setIsSnippetsOpen] = useState(false);
  const [snippetOpened, setSnippetOpened] = useState(
    /** @type {{  snippet: (SnippetObjectType & { connected: import('../../flags').ConnectedConfig }), el: HTMLElement }} */ (null)
  );
  const menuMouseOverRef = useRef(null);

  useImperativeHandle(ref, () => ({
    open: () => setIsSnippetsOpen(true)
  }));

  // extract all snippets inside folders that reference the selected database
  const { groups, userState } = useTypedSelectorDeepEquals((store) => {
    const groups = store.dataState.groups;
    const userState = store.userState;
    
    /**
     * @type {{[groupId: string]: { name: string, connected: ConnectedSettingsType}}}
     */
    const filteredGroups = {};
    for (const [id, group] of Object.entries(groups)) {
      filteredGroups[id] = {
        name: group.name,
        connected: group.connected,
      };
    }

    return {
      groups: filteredGroups,
      userState: {
        uid: userState.uid,
        org: userState.org && {
          id: userState.org.id,
          type: userState.org.type,
        },
      }
    };
  });

  const connectedGroups = useTypedSelector(state => {
    const allGroups = state.dataState.groups;
    const userGroups = state.userState.groups;

    if (templatesMode) {
      return [];
    }
    if (!userGroups) {
      return [];
    }
    // New maximum snippet determination
    const enabledGroups = Object.keys(userGroups).map(id => ({
      id,
      userInfo: userGroups[id]
    })).filter(x => !x.userInfo.disabled && allGroups[x.id]);
    const connectedGroups = [];

    for (const enabledGroup of enabledGroups) {
      const group = allGroups[enabledGroup.id];
      if (!group?.connected?.database_queries) {
        continue;
      }
      if (!group?.connected?.database_queries[props.database.id]) {
        continue;
      }
      connectedGroups.push({
        id: group.id,
        name: group.name,
        snippets: group.snippets
      });
    }
    return connectedGroups;
  });


  /**
   * 
   * @param {typeof connectedGroups} groups 
   */
  async function loadGroupLinkedSnippets (groups) {
    /**
       * @type {typeof linkedSnippetGroups}
       */
    const newLinkedSnippetGroups = {};
    /**
     * @type {SnippetObjectType[][]}
     */
    const groupSnippetsSets = [];
    for (const group of groups) {
      const groupId = group.id,
        groupSnippets = group.snippets,
        groupSnippetsCount = groupSnippets.length;
      
      newLinkedSnippetGroups[groupId] = {
        loaded: !groupSnippetsCount
      };
      groupSnippetsSets.push(groupSnippets);
    }
    setLinkedSnippetGroups(newLinkedSnippetGroups);
    fetchLinkedSnippetSets(groupSnippetsSets);
  };

  /**
   * @param {SnippetObjectType[][]} snippetSets
   */
  async function fetchLinkedSnippetSets(snippetSets) {
    const totalSnippetsCount = snippetSets.reduce((total, set) => total + set.length, 0);
    for (const snippetSet of snippetSets) {
      if (totalSnippetsCount > 10) {
        // Free thread for the UI to update
        await new Promise(resolve => setTimeout(resolve, 200));
      }
      await fetchLinkedSnippets(snippetSet);
    }
  }

  /**
   * @param {(SnippetObjectType)[]} candidateSnippets 
   * @returns 
   */
  async function fetchLinkedSnippets(candidateSnippets) {
    if (!candidateSnippets.length) {
      return;
    }
    const filteredSnippets = await filterSnippetsUsage(candidateSnippets, props.database.id);
    setLinkedSnippets(currentSnippets => {
      const newSnippets = new Set(currentSnippets);
      for (const snippet of candidateSnippets) {
        if (filteredSnippets.includes(snippet)) {
          newSnippets.add(snippet.id);
        } else {
          newSnippets.delete(snippet.id);
        }
      }
      return newSnippets;
    });
    setLinkedSnippetGroups(currentGroups => ({
      ...currentGroups,
      [candidateSnippets[0].group_id]: {
        loaded: true
      }
    }));
  };

  function isSuggestedSnippetsExpired() {
    return (new Date().getTime() - new Date(props.database.created_on).getTime()) > SUGGESTED_SNIPPETS_TIMEOUT_IN_MILLIS;
  }

  function closePopover() {
    setIsSnippetsOpen(false);
    closeSnippetsPreview();
  }

  function closeSnippetsPreview() {
    clearTimeout(menuMouseOverRef.current);
    setSnippetOpened(null);
  }

  function showSelectFolderDialog(snippet, openCommand = false) {
    closePopover();
    props.showSelectFolderDialog(snippet, openCommand);
  }

  const suggestedSnippetsCount = suggestedSnippets?.length || 0;
  const hasSuggestedSnippets = !!suggestedSnippets.length;

  const linkedSnippetsCount = connectedGroups.reduce((total, connectedGroup) => {
    return total + connectedGroup.snippets.filter(s => linkedSnippets.has(s.id)).length;
  }, 0);


  const linkedSnippetsGroupsCount = connectedGroups?.length || 0;
  const hasLinkedSnippetsGroups = !!linkedSnippetsGroupsCount;

  const totalGroupsCount = suggestedSnippetsCount + linkedSnippetsGroupsCount
      + (automaticSnippets?.length || 0);
  /**
   * 
   * @param {string} groupId 
   * @returns {{ name?: string, connected?: ConnectedSettingsType }}
   */
  const getGroup = (groupId) => {
    if (groups?.[groupId]) {
      return groups[groupId];
    }
    if (suggestedSnippetsGroup && suggestedSnippetsGroup.id === groupId) {
      return suggestedSnippetsGroup;
    }
    return null;
  };

  function renderSnippetPreview() {
    //const allSnippets = (/** @type {any[]} */ (linkedSnippetGroups)).concat(suggestedSnippets).concat(automaticSnippets);
    const snippet = snippetOpened.snippet;
    const installable = !snippet.id;
    if (!isAutomaticSnippet(snippet) && !getGroup(snippet.group_id)) {
      return null;
    }

    return <ArrowPopper
      placement="left"
      anchorEl={snippetOpened?.el}
      open
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'viewport',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: false,
            altBoundary: true,
            tether: false,
            rootBoundary: 'viewport',
            padding: 8,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 7]
          }
        }
      ]}
    >
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={closeSnippetsPreview}
      >
        <Paper
          onClick={(e) => {
            e.stopPropagation();
          }}
          elevation={2}
          style={{
            border: 'solid 1px #f2f2f2',
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 4px 6px 0px rgba(0,0,0,0.14), 0px 6px 12px 0px rgba(0,0,0,0.12)',
            maxHeight: 500,
            maxWidth: 400,
          }}
          className="suggestion-popper"
        >
          <div style={{ padding: 10, width: 400, height: 200 }}>
            <div className="space-snippets-preview-container" style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              paddingBottom: templatesMode ? 0 : 15,
            }}>
              <div style={{
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'scroll',
                border: 'dashed 1px #bbb',
              }}>
                <SnippetPreviewPanel
                  key={snippet.id}
                  snippet={snippet}
                  snippetId={snippet.id}
                  delta={getSnippetDelta(snippet)}
                  connected={snippet.connected || getConnectedConfig(userState, getGroup(snippet.group_id))}
                  hideProMessage
                  hideShortcut
                  style={{ flexGrow: 1, padding: 0, margin: 0 }}
                />
              </div>
              {!templatesMode && <div style={{
                position: 'fixed',
                right: 10,
                bottom: 10,
                left: 10,
                textAlign: 'center',
                zIndex: 9999999,
              }}>
                {installable ?
                  <Fab size="small" variant="extended" color="primary" onClick={() => {
                    showSelectFolderDialog(snippet, true);
                  }}><AddIcon sx={{ mr: 1 }} /> Add to Text Blaze</Fab> :
                  <Fab size="small" variant="extended" color="primary"
                    component={AppLink}
                    appType="TEXT"
                    to={snippetUrl(snippet)}
                    target="_blank"
                    onClick={closePopover}
                  >Open in Text Blaze</Fab>}
              </div>}
            </div>
          </div>
        </Paper>
      </ClickAwayListener>
    </ArrowPopper>;
  }

  function renderSnippets(snippets, startIndex, displayShortcut, hideBadge = false) {
    if (!snippets.length) {
      return <MenuItem disabled>
        <T variant="caption" color="grey.700">No linked snippets</T>
      </MenuItem>;
    }
    return <MenuList dense>
      {snippets.map((snippet, localIndex) => {
        const index = startIndex + localIndex;
        return <MenuItem
          key={snippet.id || index}
          selected={snippetOpened?.snippet === snippet}
          onMouseOver={(evt) => {
            // delay selecting the snippet.
            // In case user moves faster and we gonna process lot of snippets in the process
            clearTimeout(menuMouseOverRef.current);
            menuMouseOverRef.current = setTimeout(() => {
              setSnippetOpened((current) => current?.snippet !== snippet || !current.el.isConnected ? {
                snippet,
                el: /** @type {HTMLElement} */ (evt.target.closest('[role=menuitem]'))
              } : current);
            }, 200);
          }}
          component={!!snippet.id ? AppLink : 'li'}
          {...(!!snippet.id ? {
            appType: 'TEXT',
            to: snippetUrl(snippet)
          } : {})}
          onClick={() => {
            if (templatesMode || snippet.id) {
              return;
            }
            showSelectFolderDialog(snippet, true);
          }}
          sx={{ maxWidth: 700 }}
        >
          <ListItemIcon>
            {displayShortcut || hideBadge ? <TextSnippetOutlinedIcon /> : <Badge
              badgeContent="+"
              color="secondary"
              sx={{ '& .MuiBadge-badge': { height: 14, minWidth: 14, width: 14 } }}
            >
              <TextSnippetOutlinedIcon />
            </Badge>}
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: 64,
          }}>{snippet.name}</ListItemText>
          {displayShortcut && <span style={{ display: 'inline-block', marginLeft: 20 }}>
            <Shortcut shortcut={snippet.shortcut} style={{ cursor: 'pointer', maxWidth: 300 }} />
          </span> }
        </MenuItem>;
      })}
    </MenuList>;
  }

  useOnMount(async () => {
    if (!props.database.snippets_folder_id || isSuggestedSnippetsExpired()) {
      return;
    }
    try {
      const resGroups = await storage.get(makeRef('groups', props.database.snippets_folder_id));
      const group = resGroups.exists()
        ? Object.assign({ id: resGroups.id }, resGroups.data())
        : null;
      if (!group) {
        return;
      }
      const resSnippets = await storage.getQuery(query(makeRef('snippets'),
        where('group_id', '==', props.database.snippets_folder_id),
        orderBy('order'),
        limit(25)
      ));
      let snippets = resSnippets.docs.map(doc => (
        Object.assign({ id: doc.id }, doc.data())
      ));
      snippets = await Promise.all(snippets.map(async(snippet) => {
        return await changeSnippetDatabaseIds(snippet, group, props.database.id);
      }));

      setSuggestedSnippetsGroup(group);
      setSuggestedSnippets(snippets);
    } catch (error) {
      console.error('Error while getting group ' + props.database.id, error);
      setSuggestedSnippetsGroup(null);
      setSuggestedSnippets([]);
    }
  });

  useEffect(() => {
    setAutomaticSnippet(generateAutomaticSnippets(props.database.id, userState, currentTable));
    // eslint-disable-next-line
  }, [currentTable]);

  return (
    <>
      {templatesMode ? <Box
        ref={snippetsButton}
        sx={{
          position: 'absolute',
          display: 'hidden',
          userSelect: 'none',
          zIndex: -1,
          ...props.anchorPosition,
        }}
      /> : <Tooltip
        title={linkedSnippetsGroupsCount ? `Linked folders (${linkedSnippetsGroupsCount})` :
          hasSuggestedSnippets ? `Suggested snippets (${suggestedSnippetsCount})` :
            'No linked folders'}
      >
        <Button
          onClick={() => {
            loadGroupLinkedSnippets(connectedGroups);
            setIsSnippetsOpen(value => !value);
          }}
          color="inherit"
          size="small"
          sx={{
            marginRight: {
              xs: 1,
              sm: 2,
              md: 2,
              lg: 2
            },
            color: 'white',
            opacity: 0.7
          }}
          ref={snippetsButton}
          startIcon={(hasLinkedSnippetsGroups || hasSuggestedSnippets) ?
            <Badge
              color="primary"
              sx={{
                '& .MuiBadge-badge': {
                  padding: '0 4px',
                  minWidth: 10,
                  height: 10,
                  borderRadius: 10
                }
              }}
              variant="dot"
              invisible={!(hasLinkedSnippetsGroups || hasSuggestedSnippets) }
            ><SnippetFolderOutlinedIcon fontSize="small" /></Badge> : <SnippetFolderOutlinedIcon />}
        >
          Snippets
        </Button>
      </Tooltip>}
      { isSnippetsOpen && <Popover
        open
        anchorEl={snippetsButton.current}
        onClose={() => setIsSnippetsOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        { !!totalGroupsCount ?
          <div style={{ maxHeight: '80vh' }}>
            {!onboardingCompleted.TEXT && <div style={{ width: 300, textAlign: 'center', padding: 10, margin: '0 auto' }}>
              <T>Text Blaze snippets and templates let you use Data Blaze in any website.</T>
              <img src={InsertionImg} style={{ height: 224 }} alt="Text Blaze" />
              <Button component="a" target="_blank" href="https://blaze.today/datablaze/docs/db_and_snippets/">Learn more</Button>
            </div>}
            {!!connectedGroups.length && (<>
              <T
                variant="h5"
                sx={{
                  pt: 1,
                  px: 2,
                  fontSize: 15
                }}
                color="textSecondary"
              >
                Linked folders ({linkedSnippetsGroupsCount})
              </T>
              {connectedGroups.map((group, index) => {
                let startIndex = 0;
                const linkedSnippetGroup = linkedSnippetGroups[group.id];
                if (!linkedSnippetGroup) {
                  return null;
                }
                const latestSnippets = group.snippets.filter(s => linkedSnippets.has(s.id));
                for (let i = 0; i < index; i++) {
                  startIndex += latestSnippets.length;
                }
                return (<React.Fragment key={group.id}>
                  <MenuItem
                    sx={{
                      fontSize: 12,
                      mt: 1,
                      color: 'grey.700'
                    }}
                    component={AppLink}
                    appType="TEXT"
                    to={folderUrl(group)}
                    onMouseOver={() => {
                      clearTimeout(menuMouseOverRef.current);
                      menuMouseOverRef.current = setTimeout(() => {
                        setSnippetOpened(null);
                      }, 200);
                    }}
                  >
                    {group.name} {!linkedSnippetGroup.loaded && <CircularProgress sx={{ ml: 0.8 }} size={12} /> }
                  </MenuItem>
                  {renderSnippets(latestSnippets, startIndex, true)}
                </React.Fragment>);
              })}
            </>)}
            {!!(automaticSnippets.length + suggestedSnippetsCount) && <>
              <Divider />
              {!templatesMode && <T variant="h5" style={{ margin: '8px 12px 0', fontSize: '15px' }} color="textSecondary">Add a snippet</T>}
              {!!suggestedSnippetsCount && (<>
                {!templatesMode && <T variant="h6" style={{ margin: '8px 12px 0', fontSize: '12px' }} color="textSecondary">Suggested snippets</T>}
                {renderSnippets(suggestedSnippets, linkedSnippetsCount, false, templatesMode)}
              </>)}
              {!!automaticSnippets.length && (<>
                <T variant="h6" style={{ margin: '8px 12px 0', fontSize: '12px' }} color="textSecondary">Automatic snippets</T>
                {renderSnippets(automaticSnippets, linkedSnippetsCount + suggestedSnippetsCount, false)}
              </>)}
            </>}
          </div> : <div style={{
            padding: '5px 10px',
            width: 220,
          }}>No linked folders found...</div>
        }
      </Popover> }
      {!!snippetOpened?.el?.isConnected && renderSnippetPreview()}
    </>
  );
});

export default SpaceSnippetsPopover;



/**
 * 
 * @param {string} databaseId 
 * @param {import('@store').UserStateDef} userState 
 * @param {Parameters<SpaceSnippetsPopover>['0']['currentTable']} table 
 * @returns 
 */
function generateAutomaticSnippets(databaseId, userState, table) {
  if (userState && table) {
    return AUTOMATIC_SNIPPETS
      .filter(snippetFactory => snippetFactory.canGenerate(table))
      .map(snippetFactory => {
        const snippetData = {
          name: snippetFactory.name(table),
          shortcut: snippetFactory.shortcut(table),
          content: {
            delta: compressDelta({
              ops: [{
                insert: snippetFactory.content(table),
              }],
            }),
          },
        };
        return {
          ...snippetData,
          connected: createAutomaticSnippetConnectedConfig(databaseId, userState, snippetData),
        };
      }).sort((a, b) => a.name.localeCompare(b.name));
  }
  return [];
}

/**
 * @param {string} databaseId 
 * @param {import('@store').UserStateDef} userState 
 * @param {Partial<SnippetObjectType>} snippet 
 */
async function createAutomaticSnippetConnectedConfig(databaseId, userState, snippet) {
  const databaseUsages = await extractDatabaseUsages(snippet);
  const databaseQueries = [];
  for (const databaseUsage of Object.values(databaseUsages)) {
    databaseQueries.push(...databaseUsage);
  }
  return {
    isConnected: true,
    connector: `u:${userState.uid}`,
    database_queries: { [databaseId]: databaseQueries },
  };
}

/**
 * 
 * @param {SnippetObjectType} snippet 
 * @returns 
 */
function getSnippetDelta(snippet) {
  return snippet.content.delta.toUint8Array ?
    decompressDelta(snippet.content.delta.toUint8Array()) :
    decompressDelta(snippet.content.delta);
}


/**
 * 
 * @param {import('@store').UserStateDef} userState 
 * @param {Partial<GroupObjectType>} group 
 * @returns 
 */
function getConnectedConfig(userState, group) {
  return group ?
    getConnectedConfigOptions(userState, group).config :
    getConnectedConfigOptions(null, { connected: { is_connected: false } }).config;
}


/**
 * @param {object} snippet 
 * @param {string} snippet.id 
 */
const isAutomaticSnippet = (snippet) => !snippet.id;


/**
 * @param {object} snippet 
 * @param {string} snippet.id 
 */
const snippetUrl = ({ id }) => `/snippet/${id}`;


/**
 * @param {object} group 
 * @param {string} group.id 
 */
const folderUrl = ({ id }) => `/folder/${id}`;