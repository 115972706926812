import { captureException } from '@sentry/browser';
import { DESKTOP_PLATFORM, isElectronApp } from './flags';
import { log } from './logging/logging';

// Source: https://github.com/electron/electron/issues/24427
function decodeError({ name, message, stack, extra }) {
  const mainError = new Error(message);
  mainError.name = name;
  mainError.stack = stack;
  Object.assign(mainError, extra);
  const capturedError = new Error('Reported error');
  // @ts-ignore
  capturedError.cause = mainError;
  return capturedError;
}
    
async function invokeWithCustomErrors(data) {
  const response = await window['electronAPI'].invokeHandler(data);
  // For backwards compatibility
  if (response?._tb_version > 0) {
    const { error, result } = response;
    if (error) {
      throw decodeError(error);
    }
    return result;
  } else {
    return response;
  }
}

export const PROPAGATE_ERROR = 'propagate-error';
/**
 * 
 * @typedef {{ type: 'remote-command-request', data: { type: string, info: Parameters<import('./snippet_processor/DownstreamProcess').NativeRemoteCommandFn>[0] }} |
 * { type: 'clipboard-request' } |
 * { type: 'get-version' } |
 * { type: 'get-context' } |
 * { type: 'ai-chat', subType: 'get-preceding-text', data: { insertionType: import('./store_platform').InsertionType }} |
 * { type: 'ai-chat', subType: 'get-screenshot' | 'get-window-text' } |
 * { type: 'supports-feature', data: { featureName: string }}
 * } CheckerInvokerInputType
 * 
 * @typedef {{ type: 'remote-command-request', data: { type: string, info: Parameters<import('./snippet_processor/DownstreamProcess').NativeRemoteCommandFn>[0] }} |
 * { type: 'clipboard-request' } |
 * { type: 'restore-form-mechanism' } |
 * { type: 'get-context' }
 * } FormInvokerInputType
 * 
 * @typedef {{ type: 'remote-command-request', data: { type: string, info: Parameters<import('./snippet_processor/DownstreamProcess').NativeRemoteCommandFn>[0] }} |
 * { type: 'clipboard-request' } |
 * { type: 'supports-feature', data: { featureName: string }} |
 * { type: 'assistant-shortcut' }
 * } DashboardInvokerInputType
 * 
 * @typedef {{ type: 'assistant-shortcut' }
 * } AssistantInvokerInputType
 * 
 * @typedef {{ type: 'remote-command-request', data: { type: string, info: Parameters<import('./snippet_processor/DownstreamProcess').NativeRemoteCommandFn>[0] }} |
 * { type: 'clipboard-request' } |
 * { type: 'get-snippets-by-shortcut', shortcut: string } |
 * { type: 'get_credentials', data: string } |
 * { type: 'autowrite', subType: 'embedLoaded' } |
 * { type: 'supports-feature', data: { featureName: string }} |
 * { type: 'get-context' }
 * } AIInvokerInputType
 * 
 * @param {CheckerInvokerInputType | FormInvokerInputType | DashboardInvokerInputType | AssistantInvokerInputType | AIInvokerInputType} data
 * @param {*=} errorData
 * 
 * @returns {Promise<any>}
 */
export function getDataFromClient(data, errorData = null) {
  // invoke a message to client and wait for client handler to return value
  if (isElectronApp()) {
    const result = invokeWithCustomErrors(data);
    if (errorData === PROPAGATE_ERROR) {
      return result;
    }
    return result.catch((err) => {
      captureException(err);
      return errorData;
    });
  } else {
    return Promise.resolve(null);
  }
}

/**
 * 
 * @param {string} featureName 
 * @returns  {Promise<boolean>}
 */
export async function clientSupportsFeature(featureName) {
  return !!(await getDataFromClient({ type: 'supports-feature', data : { featureName } }));
}

export function installDesktopApp() {
  const link = `https://blaze.today/${DESKTOP_PLATFORM}/`;
  
  log({ category: 'desktop', action: 'Launch desktop installation site' });
  window.open(link, '_blank', 'width=1200,height=800,menubar=yes,location=yes,resizable=yes,scrollbars=yes');
}