import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CURRENT_PLATFORM, isElectronApp } from '../../flags';
import { toast } from '../../messageToast';
import { Button } from '@mui/material';
import { signout } from '../../signout';

const signOutAction = () => (
  <Button size="small" variant="contained" onClick={signout}>
    Sign out
  </Button>
);

/** @type {Object<string, import('../Dialog/MessageDisplayInner').SnackbarActionType>} */
const actions = {
  'sign-out': signOutAction
};

function DesktopNavigation() {
  const history = useHistory();
  useEffect(() => {
    async function callback(_, dataReceived) {
      if (dataReceived && dataReceived.type) {
        const data = dataReceived.data;
        switch (dataReceived.type) {
        case 'edit-snippet':
          history.push('/snippet/' + data);
          break;
        case 'new-snippet':
          const source = data.source ? data.source : CURRENT_PLATFORM;
          history.push(`/new#?source=${source}&text=New%20Snippet`);
          break;
        case 'navigate-to-url':
          history.push(data);
          break;
        case 'show-notification':
          if (data.action) {
            data.config.action = actions[data.action];
          }
          toast(data.message, data.config);
          break;
        default:
          break;
        }
      }
    }
    if (isElectronApp()) {
      const removeListener = window['electronAPI'].attachMessageListener(callback);
    
      return () => {
        removeListener?.();
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return null;
}

export default DesktopNavigation;