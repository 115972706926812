// These utilities have no dependencies and can safely be included
// in the API or other builds.

/**
 * @param {number} ms 
 * @returns {Promise<void>}
 */
export function promiseDelay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Checks email validity. Note, the regex is fairly loose and may mark
 * invalid emails as valid.
 * 
 * @param {string} email
 * @param {boolean} ignoreOtherChecks
 * 
 * @return {boolean} true if the email is valid
 */
export function validEmail(email, ignoreOtherChecks = false) {
  if (email.includes('/') || (email.startsWith('__') && email.endsWith('__'))) {
    // Strictly speaking this could be a valid email,  but Firestore keys can't contain slashes
    // and the email is a key for the notifications collection. So let's block it.
    // Firestore keys also can't start and end with two '__'.
    //
    // See:
    //   https://firebase.google.com/docs/firestore/quotas

    return false;
  }
  return !!email.trim().match(/^[^\s@]+@[^\s@]+\.[^\s@]*[^.\s@]$/);
}


/**
 * Very lightweight domain validation.
 * 
 * @param {string} str
 * 
 * @return {boolean}
 */
export function validDomain(str) {
  return !str.includes('@')
    && !str.includes(' ')
    && str.includes('.')
    && !str.includes(':')
    && !str.startsWith('.')
    && !str.includes('..');
}


/**
 * @param {string} email
 * 
 * @return {string}
 */
export function emailDomain(email) {
  return email.trim().split('@')[1].toLowerCase();
};


/**
 * @param {string[]} list
 * @param {boolean=} useAnd
 * 
 * @return {string}
 */
export function prettyList(list, useAnd = true) {
  if (list.length === 1) {
    return list[0];
  } else {
    return list.slice(0, list.length - 1).join(', ') + (useAnd ? ' and ' : ' or ') + list[list.length - 1];
  }
}