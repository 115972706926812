import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Paper,
  Typography as T
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowPopper from '../ArrowPopper/ArrowPopper';

const CalloutPopper = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState(
    /** @type {Parameters<typeof import('../../message').showCallout>['0']} */ ({})
  );
  const clearConfig = () => {
    setConfig(/** @type {config} */ ({}));
  };
  const close = () => {
    config.onCancel?.();
    clearConfig();
  };
  const confirm = () => {
    config.onAccept();
    clearConfig();  
  };
  useImperativeHandle(ref, () => ({
    /**
     * @param {config} config 
     */
    show: (config) => {
      setConfig(config);
    },
    close: () => {
      close();
    }
  }));
  const anchorEl = config.anchorEl;
  // This will ensure to trigger useEffect.
  // Issue: Open popper, navigate away from the page using browser back.
  const effectRef = anchorEl ? {} : false;
  useEffect(() => {
    if (!anchorEl) {
      setOpen(false);
      return;
    }
    const box = anchorEl.getBoundingClientRect();

    if (import.meta.env.NODE_ENV !== 'test' && box.top === 0 && box.left === 0 && box.right === 0 && box.bottom === 0) {
      setOpen(false);
      close();
      return;
    }
    setOpen(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl, effectRef]);
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      onClickAway={(evt) => {
        if (config.requireExplicitClose) {
          return;
        }
        if (anchorEl.contains(/** @type {Node} */ (evt.target))) {
          return;
        }
        close();
      }}
    >
      <ArrowPopper
        open={!!anchorEl && open}
        anchorEl={anchorEl}
        placement="bottom"
        style={{
          maxWidth: '250px'
        }}
        modifiers={config.modifiers}
      >
        <Paper
          elevation={2}
          sx={{
            maxWidth: 500,
            px: 2,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <T component="div" sx={{
            my: 2
          }}>
            {config.contents}
          </T>
          {config.requireExplicitClose && <Button
            style={{ width: 'fit-content', minWidth: '0', position: 'absolute', right: 0, color: 'grey', backgroundColor: 'transparent' }}
            onClick={() => close()}
            variant="text"
          ><CloseIcon fontSize="small" /></Button>}
          {config.btnText !== '' && <Button
            onClick={confirm}
            variant="contained"
            color="primary"
            sx={{
              mb: 2,
              alignSelf: 'center'
            }}
            startIcon={config.startIcon}
            tabIndex={0}
          >{config.btnText || 'Ok'}</Button>}
        </Paper>
      </ArrowPopper>
    </ClickAwayListener>
  );
});

export default CalloutPopper;