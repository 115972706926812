import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Zoom from '@mui/material/Zoom';
import { useTypedSelector } from '../../hooks';
import WarningIcon from '@mui/icons-material/ErrorOutline';
import ConflictShortcutTooltip from './ConflictShortcutTooltip';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import { arrayUnion } from 'firebase/firestore';
import { fullAppName } from '../../raw_flags';


const DISMISSED_KEY = '%APP_conflict_warning';

function ConflictWarningBase(_props) {
  const hasConflict = useTypedSelector(state => state.uiState.conflicts && state.uiState.conflicts.size);

  if (hasConflict) {
    return <WarningPanel />;
  } else {
    return null;
  }
};


const ConflictWarning = React.memo(ConflictWarningBase);
export default ConflictWarning;


function WarningPanel(_props) {
  const [animationFinished, setAnimationFinished] = useState(false);
  const [notificationClosed, setNotificationClosed] = useState(false);

  const dismissed = useTypedSelector(state => {
    if (!state.userState.settingsLoaded) {
      return true;
    }
    return state.userState.dismissed_notifications && state.userState.dismissed_notifications.includes(DISMISSED_KEY);
  });

  const close = () => {
    setNotificationClosed(true);

    storage.update(usersSettingsRef, {
      dismissed_notifications: arrayUnion(DISMISSED_KEY)
    }, 'HIDE_AUTOSAVE');
  };

  return (
    <Zoom in onEntered={() => setAnimationFinished(true)}>
      <div>
        <ConflictShortcutTooltip
          label={<>Some of your shortcuts start the same (<a target="_blank" rel="noopener noreferrer" href="https://youtu.be/gqa0J36uG60">learn more</a>). {fullAppName} won't know which of those to use until you fix them:</>}
          forcedOpened={!dismissed && !notificationClosed && animationFinished}
          onForcedClose={close}
        >
          <Alert
            icon={<WarningIcon fontSize="inherit" />}
            severity="warning"
            style={{ marginBottom: 12, marginRight: 12 }}>
                You have conflicting shortcuts.
          </Alert>
        </ConflictShortcutTooltip>
      </div>
    </Zoom>
  );
};
