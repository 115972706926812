import { AI_BACKEND_ENDPOINT, getIsWebpageEmbedded } from '../../flags';
import { captureException } from '@sentry/browser';
import { getCredentials } from './credentials';
import { sendToExtension } from '../../extension';
import { readFromStorage } from '../AppMicro/load_data';
import { sendMessageToClient } from '../../desktop_utilities';
import { chatproto } from '../../chat_proto/ChatProto.js';
import { isElectronFlag } from '../../raw_flags';

/**
 * @typedef {{ content: string, role: 'user'|'assistant', }} ConversationMessageType
 */

export const isWebpageEmbedded = getIsWebpageEmbedded();
export const isLSEnabled = !isWebpageEmbedded;

export const WHITE_BG_COLOR = 'rgba(255,255,255, .8)';

/**
 * With localstorage, it persists across multiple days and 
 * gives incorrect counts (even when daily limit was reset)
 * So we just use a global variable which satisfies the original
 * intention (persist usage count when user opens/closes AI Write)
 */
let storedUsageCount = null;
/**
 * @returns {{ maxUsage: number, usage: number, actionType: 'upgrade'|'upgrade-paying'|''|'verify-email' }}
 */
export function getStoredUsageCount() {
  return storedUsageCount ? JSON.parse(storedUsageCount) : null;
}

/**
 * @param {ReturnType<typeof getStoredUsageCount>} data
 */
export function setStoredUsageCount(data) {
  storedUsageCount = JSON.stringify(data);
}

export function closePopup() {
  window.parent.postMessage({
    type: 'close'
  }, '*');
}

/**
 * @param {{ path: '/write', body: Omit<import('../../exported_types').WritePostDataType, 'idToken'>, controller: AbortController, }|{ path: '/chat', body: Omit<import('../../exported_types').ChatPostDataType, 'idToken'>, controller: AbortController, }|{ path: '/chatp', body: Omit<import('../../exported_types').ChatPostDataType, 'idToken'>, controller: AbortController, }|{ path: '/classify', body: Omit<import('../../exported_types').ClassifyPostDataType, 'idToken'>, }} requestObject
 * @returns {Promise<Response|{ error: any, }>}
 */
export async function makeBackendRequest(requestObject) {
  if (requestObject.path.endsWith('p')) {
    // @ts-ignore
    return makeBackendRequestP(requestObject);
  }
  const { path, body, } = requestObject;
  const signal = 'controller' in requestObject ? requestObject.controller.signal : undefined;
  const idToken = await getCredentials();

  if (body.domainData) {
    // None of these are needed for non-chat endpoints, ensure they aren't sent
    body.domainData = Object.assign({}, body.domainData);

    // @ts-ignore
    delete body.domainData.rawCapturedImage;
    // @ts-ignore
    delete body.domainData.rawImageType;
    // @ts-ignore
    delete body.domainData.pageContent;
  }

  return fetch(AI_BACKEND_ENDPOINT + path, {
    signal,
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain'
    },
    body: JSON.stringify({
      idToken,
      ...body,
    })
  }).catch(error => {
    captureException(error);
    return { error, };
  });
}

/**
 * @param {{ path: '/chatp', body: Omit<import('../../exported_types').ChatPostDataType, 'idToken'>, controller: AbortController, }} requestObject
 * @returns {Promise<Response|{ error: any, }>}
 */
export async function makeBackendRequestP(requestObject) {
  const { path, body, } = requestObject;
  const signal = 'controller' in requestObject ? requestObject.controller.signal : undefined;
  const idToken = await getCredentials();



  let data = {
    idToken,
    ...body,
  };

  if (data.domainData) {
    data.domainData = Object.assign({}, data.domainData);
    // @ts-ignore
    if (data.domainData.rawCapturedImage) {
      // @ts-ignore
      data.domainData.rawCapturedImage = new Uint8Array(data.domainData.rawCapturedImage);
    }
  }

  let message = chatproto.Request.create(data);
  let array = chatproto.Request.encode(message).finish();


  // we use a form upload so we don't have a CORS pre-flight request.
  const blob = new Blob([array], { type: 'application/octet-stream' });
  const formData = new FormData();
  formData.append('chat_request', blob, 'tmp.txt');
  
  return fetch(AI_BACKEND_ENDPOINT + path, {
    signal,
    method: 'POST',
    body: formData
  }).catch(error => {
    captureException(error);
    return { error, };
  });
}

/**
 * 
 * @param {{ domainData: import('../../exported_types').AIHostDataType, messages: object[], }} param0 
 * @returns 
 */
export async function makeClassificationRequest({ domainData, messages, }) {
  // Classify the user input as the action is not already available
  return makeBackendRequest({
    path: '/classify', body: {
      domainData,
      messages,
    }
  }).then(async response => {
    if ('error' in response) {
      return response;
    }
    /** @type {import('../../exported_types').ClassifyReturnDataType} */
    const result = await response.json();
    return result;
  });
}

/**
 * @param {import('../AppEmbed/App').AIModelType} newModel 
 * @returns 
 */
export function saveAIModelToClient(newModel) {
  if (isElectronFlag) {
    sendMessageToClient({ type: 'autowrite', subType: 'saveModel', model: newModel, });
  } else {
    // TODO: remove end of August, for backwards compat with old extension
    sendToExtension({ type: 'autowrite', subType: 'saveGPT4', checked: newModel.name === 'gpt4' });
    sendToExtension({ type: 'autowrite', subType: 'saveModel', model: newModel, });
  }
}

export const SIDEBAR_CONFIG_DATA_STORAGE_KEY = 'sidebarConfigData';

/**
 * @returns {Promise<import('../../uiAndDataMicroStore').SidebarStoredConfigData>}
 */
export async function getSidebarStoredData() {
  /** @type {import('../../uiAndDataMicroStore').SidebarStoredConfigData} */
  const config = (await readFromStorage(SIDEBAR_CONFIG_DATA_STORAGE_KEY)) || {};
  if (!config.triggers) {
    config.triggers = [];
  }
  return config;
}
