import React, { useRef, useState } from 'react';
import {
  IconButton,
  Menu,
  Tooltip
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LastInvoiceButton from './LastInvoiceButton';
import BillingDescription from './BillingDescription';
import ChangeTeamSizeButton from './ChangeTeamSizeButton';
import MonthlyBillingSwitchButton from './MonthlyBillingSwitchButton';

/**
 * @param {object} props
 * @param {object} props.billingDetails
 * @param {() => any} props.onUpdate
 * @param {object} props.entity
 */
export default function BillingOptions({ billingDetails, onUpdate, entity }) {
  const [isOpen, setOpen] = useState(false);
  const anchorEl = useRef();

  const handleClose = () => setOpen(false);
  const moreButton = <IconButton
    style={{ marginLeft: '12px' }}
    size="small"
    ref={anchorEl}
    onClick={() => setOpen(!isOpen)}>
    <MoreVertIcon fontSize="small"/>
  </IconButton>;
  
  return <>
    <Tooltip title="Show more billing options">{moreButton}</Tooltip>
    <Menu
      anchorEl={anchorEl.current}
      keepMounted
      open={isOpen}
      onClose={handleClose}>
      {billingDetails.product !== 'business' && <LastInvoiceButton entity={entity} onClose={handleClose} />}
      <BillingDescription onClose={handleClose} billingDetails={billingDetails} onUpdate={onUpdate} />
      {billingDetails.team_size 
      && billingDetails.team_size >= billingDetails.quantity 
      && getYearsSince(billingDetails.created) >= 1
      && (
        <ChangeTeamSizeButton
          teamSize={billingDetails.team_size}
          onClose={handleClose}
          onUpdate={onUpdate}
        />
      )}
      {billingDetails.period === 'annually' && (
        <MonthlyBillingSwitchButton
          entity={entity}
          onClose={handleClose}
          onUpdate={onUpdate}

        />
      )}
    </Menu>
  </>;
}


const SECONDS_IN_A_YEAR = 60 * 60 * 24 * 365;
/**
 * @param {number} timeInSeconds 
 * @returns 
 */
const getYearsSince = (timeInSeconds) => {
  return (new Date().getTime() / 1000 - timeInSeconds) / SECONDS_IN_A_YEAR;
};