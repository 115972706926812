import { log } from '../logging/logging';
import { orgId, isDev } from '../flags';
import {
  waitForPendingWrites,
  serverTimestamp,
  getDocs,
  getDoc,
  onSnapshot,
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  getFirestore
} from '../firebase_shared_driver';

export const LATEST_CACHE_STORAGE_VERSION = 4;

let timeStamp = () => Math.floor(Date.now() / 1000 / 60 / 60);

/**
 * @typedef {{ groups: Object<string, GroupObjectType>, snippets: Object<string, { data: SnippetObjectType, addonOptions: AddonOptionsType}[]>, users_readonly: object, users_settings: object, users_notifications: object, org: OrgObjectType, teams: Object<string, TeamObjectType>, addons: { [x: string]: AddonObjectType, }, version: number }} ExtensionSnippetCacheType
 */

function buildCachingLayer() {
  /** @type {Set<string>} */
  let remainingReads = null;
  let cacheExhausted = false;
  /**
   * We wait for these two entities (all snippets and all addons)
   * to fully load before we start answering requests in the
   * onMessage listeners in the service worker. This is to ensure
   * snippet lookups work correctly. If all snippets/addons have 
   * loaded, that means all corresponding teams and groups have
   * also loaded.
   */
  /** @type {{ snippets: number, addons: string[], }} */
  let cacheStorageCount = null;

  /** @type {(value: any) => void} */
  let initializationPromiseResolver = null;
  const initializationPromiseInner = new Promise(resolve => {
    initializationPromiseResolver = resolve;
  });
  /** @type {(value: any) => void} */
  let cacheTimeoutPromiseResolver = null;
  const cacheTimeoutPromise = new Promise(resolve => {
    cacheTimeoutPromiseResolver = resolve;
  });
  let cacheTimeoutId = -1;
  function initiateCacheTimeout() {
    if (cacheTimeoutId === -1) {
      // @ts-ignore
      cacheTimeoutId = setTimeout(() => {
        cacheTimeoutPromiseResolver();
      }, 500);
    }
  }
  const FROM_SLEEP_CONSTANT = 'FROM_SLEEP';
  const initializationPromise = Promise.race([initializationPromiseInner, cacheTimeoutPromise.then(_ => {
    return FROM_SLEEP_CONSTANT;
  })]).then(result => {
    cacheExhausted = true;
    return result;
  });

  /**
   * @returns {ExtensionSnippetCacheType}
   */
  function getExtensionStorageCache() {
    /** @type {ExtensionSnippetCacheType} */
    // eslint-disable-next-line
    const result = self?.['cachedStorageData'] || null;

    if (result) {
      if (result.version !== LATEST_CACHE_STORAGE_VERSION) {
        if (initializationPromiseResolver) {
          initializationPromiseResolver('MISMATCH');
          initializationPromiseResolver = null;
        }
        return null;
      }
      if (!remainingReads) {
        // Initialize remaining reads if not already done
        remainingReads = new Set(['users_readonly', 'users_settings', 'users_notifications']);
        cacheStorageCount = {
          addons: [],
          snippets: 0,
        };
        for (const groupId in result.snippets) {
          for (const snippet of result.snippets[groupId]) {
            if (!snippet.addonOptions) {
              cacheStorageCount.snippets++;
            }
          }
          remainingReads.add(`snippets/${groupId}`);
        }
        for (const groupId in result.groups) {
          remainingReads.add(`groups/${groupId}`);
        }
        if (result.org) {
          remainingReads.add('org');
        }
        if (result.teams) {
          for (const teamId in result.teams) {
            remainingReads.add(`teams/${teamId}`);
          }
        }
        if (result.addons) {
          for (const addonId in result.addons) {
            const addonObj = result.addons[addonId].active;
            if (addonObj) {
              const namespace = addonObj.namespace;
              cacheStorageCount.addons.push(...addonObj.data.snippets.map(x => `${namespace}-${x.command}`));
              remainingReads.add(`addons/${addonId}`);
            }
          }
        }
      } else if (cacheExhausted) {
        // The cache was fully exhausted, so we,
        // stop supplying any more cached data.
        // This can happen for example when user joins 
        // an org or creates a folder during the
        // service worker lifecycle
        return null;
      }
    } else if (initializationPromiseResolver) {
      // If we have no data in cache, unblock the message listeners immediately
      initializationPromiseResolver('EMPTY');
      initializationPromiseResolver = null;
    }

    return result;
  }

  /** 
   * @param {any} data
   * @returns {import('firebase/firestore').DocumentSnapshot}
   */
  function mockDocumentSnapshot(data) {
    // Firebase QueryDocumentSnapshot constructor is protected
    // and we cannot call it. We hope this mock object is a 
    // sufficient alternative
    // @ts-ignore
    return {
      // @ts-ignore
      exists: () => true,
      data: () => {
        return data;
      }
    };
  }

  /** 
   * @param {ExtensionSnippetCacheType['snippets']['groupid']} data
   * @returns {import('firebase/firestore').QuerySnapshot}
   */
  function mockQueryDocumentSnapshot(data) {
    // @ts-ignore
    return {
      size: data.length,
      empty: !!data.length,
      // @ts-ignore
      docChanges: () => {
        return data.map((doc, index) => ({
          type: 'added',
          doc: {
            id: doc.data.id,
            data: () => ({ tbSource: 'cache', data: doc.data, addonOptions: doc.addonOptions }),
            exists: () => true,
          },
          // oldIndex is -1 for added events
          // https://firebase.google.com/docs/reference/js/firestore_.documentchange
          oldIndex: -1,
          newIndex: index,
        }));
      }
    };
  }

  /**
   * @param {import('firebase/firestore').Query} ref 
   * @param {(snapshot: import('firebase/firestore').QuerySnapshot) => void} onSuccess 
   * @returns {void}
   */
  function checkQueryCacheOnStartup(ref, onSuccess) {
    const snippetData = getExtensionStorageCache();
    if (!snippetData) {
      return;
    }
    /** @type {ExtensionSnippetCacheType['snippets']['groupid']} */
    let dataToReturn = null;

    // https://github.com/firebase/firebase-js-sdk/blob/e60188d47f59d00f7faf7ebb2c0d8e338014a0f8/packages/firestore/src/core/query.ts#L50
    // @ts-ignore
    const query = ref._query;

    if (query.filters.length === 1 && query.path.segments.length === 1) {
      const [ filter ] = query.filters;
      const [ segment ] = query.path.segments;
      if (filter.field.segments[0] === 'group_id' && filter.op === '==' && segment === 'snippets') {
        const groupId = filter.value.stringValue;
        dataToReturn = snippetData.snippets[groupId];
        remainingReads.delete(`snippets/${groupId}`);
      }
    }

    if (dataToReturn) {
      setTimeout(() => {
        onSuccess(mockQueryDocumentSnapshot(dataToReturn));
      }, 0);
    }
  }

  /**
   * @param {import('firebase/firestore').DocumentReference} ref 
   * @param {(snapshot: import('firebase/firestore').DocumentSnapshot) => void} onSuccess 
   * @returns {void}
   */
  function checkDocumentCacheOnStartup(ref, onSuccess) {
    const snippetData = getExtensionStorageCache();
    if (!snippetData) {
      return;
    }
    let dataToReturn = null;

    const refPath = ref.path;
    // Older group IDs (like the one on test account)
    // also contain minus sign in them. So we use a less strict regex
    const groupId = refPath.match(/^groups\/([^/]+)$/)?.[1];
    if (groupId && snippetData.groups[groupId]) {
      dataToReturn = snippetData.groups[groupId];
      remainingReads.delete(`groups/${groupId}`);
    }
    const userCollection = refPath.match(/^(users_readonly|users_settings|users_notifications)\/.+$/)?.[1];
    if (userCollection) {
      dataToReturn = snippetData[userCollection];
      remainingReads.delete(userCollection);
    }
    const orgId = refPath.match(/^orgs\/([^/]+)$/)?.[1];
    if (orgId) {
      if (snippetData.org?.id === orgId) {
        dataToReturn = snippetData.org; 
        remainingReads.delete('org');
      } else {
        // this should not happen
      }
    }
    const teamId = refPath.match(/^orgs\/\w+\/teams\/([^/]+)$/)?.[1];
    if (teamId && snippetData.teams[teamId]) {
      dataToReturn = snippetData.teams[teamId];
      remainingReads.delete(`teams/${teamId}`);
    }

    const addonId = refPath.match(/^addons\/([^/]+)$/)?.[1];
    if (addonId && snippetData.addons[addonId]) {
      dataToReturn = snippetData.addons[addonId];
      remainingReads.delete(`addons/${addonId}`);
    }

    if (dataToReturn) {
      setTimeout(() => {
        onSuccess(mockDocumentSnapshot(dataToReturn));
      }, 0);
    }
  }
  
  function getRemainingReads() {
    return remainingReads ? [...remainingReads] : null;
  }

  /**
   * 
   * @param {() => (typeof cacheStorageCount)} loaderFn 
   * @returns 
   */
  function hasLoadedAllCacheData(loaderFn) {
    if (cacheExhausted || getRemainingReads()?.length > 0 || !cacheStorageCount) {
      return false;
    }
    const existingData = loaderFn();
     
    // console.log('Has cache', JSON.stringify(existingData));
    // console.log('Want cache', JSON.stringify(cacheStorageCount));

    if (existingData.snippets < cacheStorageCount.snippets) {
      return false;
    }
    const addons = new Set(cacheStorageCount.addons);
    for (const addonCommand of existingData.addons) {
      addons.delete(addonCommand);
    }
    if (addons.size) {
      console.log('Remaining addons', JSON.stringify([...addons]));
      return false;
    }
    return true;
  }

  return { checkDocumentCacheOnStartup, checkQueryCacheOnStartup, initializationPromise, FROM_SLEEP_CONSTANT, getRemainingReads, initializationPromiseResolver, initiateCacheTimeout, hasLoadedAllCacheData, };
}

const CacheHandler = buildCachingLayer();
 
export default class Storage {
  /**
   * @param {object} limits a key value list of the max number of operations per hour
   * @param {function=} onError an optional function to call if limits are exceeded
   */
  constructor(limits, onError = (() => { })) {
    this.totalUsage = {
      read: 0,
      write: 0,
      delete: 0
    };

    this.hourUsage = {
      hour: timeStamp(),
      read: 0,
      write: 0,
      delete: 0
    };

    /** @type {{[key: string]: Function }} */
    this.stateFns = {};

    for (let key in limits) {
      if (!(key in this.totalUsage)) {
        throw Error('Unknown limit: ' + key);
      }
    }

    this.unsubscribes = new Set();

    this.limits = limits;
    this.onError = onError;


    /** @type {import("redux").Store} */
    this.store = undefined;
    /** @type {Function} */
    this.uid = undefined;

    /** @type {Set<string>} */
    this.initializationPendingTypes = new Set();
  }

  /**
   * @private
   */
  currentState = 'SAVED';

  /**
   * @private
   */
  willWrites = 0;

  /**
   * @private
   */
  pendingWrites = false;

  /**
   * @private
   */
  _updateWriteState() {
    let newState = 'SAVED';
    if (this.willWrites) {
      newState = 'SAVING';
    } else if (this.pendingWrites) {
      newState = 'SAVING';
    }
    if (this.currentState !== newState) {
      this.currentState = newState;
      Object.values(this.stateFns).forEach(f => f(newState));
    }
  }

  /**
   * @private
   */
  _changed() {
    this.pendingWrites = true;

    // This promise will be rejected when new writes occur so we
    // don't need to clean it up when multiple writes happen.
    waitForPendingWrites(getFirestore()).then(() => {
      this.pendingWrites = false;
      this._updateWriteState();
    }).catch(() => {
      // This will happen when multiple writes occur. We'll get a FirebaseError
      // `'waitForPendingWrites' promise is rejected due to a user change`.
      //
      // We'll add another listener when that occurs so we don't need
      // to do anything here.
    });
    this._updateWriteState();
  }

  async hasInitializedFromCache() {
    const result = await CacheHandler.initializationPromise;
    if (result === CacheHandler.FROM_SLEEP_CONSTANT) {
      return { result, remaining: CacheHandler.getRemainingReads() };
    }
    return result;
  }

  resolveInitializedCachePromise() {
    if (CacheHandler.initializationPromiseResolver && CacheHandler.getRemainingReads()?.length === 0) {
      CacheHandler.initializationPromiseResolver('CACHE');
      CacheHandler.initializationPromiseResolver = null;
    }
  }

  /**
   * We need to wait for auth to finish, otherwise
   * snippets are simply not loaded
   * We need to wait for offscreen document to initialize,
   * otherwise addon processing does not happen.
   * Either of these can happen one before the other
   * @param {'offscreen'|'auth'} loaded 
   */
  initiateCacheTimeout(loaded) {
    this.initializationPendingTypes.add(loaded);
    if (this.initializationPendingTypes.size === 2) {
      CacheHandler.initiateCacheTimeout();
    }
  }

  /** @type {(typeof CacheHandler)['hasLoadedAllCacheData']} */
  hasLoadedAllCacheData(...args) {
    // @ts-ignore
    return CacheHandler.hasLoadedAllCacheData(...args);
  }

  /**
   * @return {function} call this to release the pending write
   */
  notifyWillWrite() {
    this.willWrites++;
    this._updateWriteState();
    return () => {
      this.willWrites--;
      this._updateWriteState();
    };
  }


  /**
   * @param {string} key
   * @param {Function} fn
   */
  onSaveStateChange(key, fn) {
    this.stateFns[key] = fn;
  }

  showSavedNotification() {
    // don't override the state if it's in progress
    if (this.currentState !== 'SAVED') {
      return;
    }

    if ('ON_SAVE' in this.stateFns) {
      // AutoSaveNotification.js handles showing the notification for a minimum amount of time
      // so no need to worry about that here
      this.stateFns['ON_SAVE']('SAVED');
    }
  }

  /**
   * @param {string} key
   */
  removeSaveStateChange(key) {
    if (key in this.stateFns) {
      delete this.stateFns[key];
    }
  }

  /**
   * Sets the store and user data.
   * 
   * @param {{ uid?: function, store?: import("redux").Store}} config
   */
  setStore({ uid, store }) {
    this.uid = uid;
    this.store = store;
  }

  /**
   * @param {string} type 
   */
  log(type) {
    this.totalUsage[type]++;
    let myHour = timeStamp();
    if (this.hourUsage.hour !== myHour) {
      this.hourUsage = {
        hour: myHour,
        read: 0,
        write: 0,
        delete: 0
      };
    }
    this.hourUsage[type]++;
    if (isDev() && !this.shownHighStorageMessage) {
      if (this.hourUsage[type] > 350) {
        this.shownHighStorageMessage = true;
        console.warn('High Storage usage of ' + type + ':', this.hourUsage[type]);
      }
    }
    for (let key in this.limits) {
      if (this.hourUsage[key] > this.limits[key]) {
        log({ category: 'Limits', action: 'Limits exceeded', label: this.hourUsage });
        this.kill();
      }
    }
  }

  /**
   * @return {boolean} false if we're dead
   */
  test() {
    if (this.dead) {
      if (!this.silent) {
        throw new Error('Limits exceeded');
      }
      return false;
    }
    return true;
  }

  /**
   * @param {boolean=} silent - if true no errors are raised
   */
  kill(silent = false) {
    this.silent = silent;
    this.dead = true;
    [...this.unsubscribes].forEach(fn => fn());
    if (!silent) {
      this.onError();
      throw new Error('Limits exceeded');
    }
  }

  addUnsubscribe(fn) {
    let wrapper = () => {
      fn();
      this.unsubscribes.delete(wrapper);
    };
    this.unsubscribes.add(wrapper);
    return wrapper;
  }

  /**
   * Set updated and created timestamps and editor and owner.
   * 
   * @param {object} obj - object being written
   * @param {import('firebase/firestore').DocumentReference|import('firebase/firestore').CollectionReference} ref - ref to the firestore object
   * @param {boolean=} create - true if we should add the create doc items
   * 
   * @return {object}
   */
  setMetadata(obj, ref, create = false) {
    let newData = {
      updated_at: serverTimestamp(),
      updated_by: this.uid && this.uid()
    };

    if (create) {
      Object.assign(newData, {
        created_at: serverTimestamp(),
        created_by: this.uid && this.uid()
      });
    }

    if (this.store) {
      if ('groups' === ref.id || (ref.parent && 'groups' === ref.parent.id)) {
        // Add the associated org id for groups
        let myOrgId = orgId(this.store.getState());
        if (myOrgId) {
          if (create) {
            newData.associated_org_id = myOrgId;
            newData.associated_team_ids = [];
          }
        }
      }
      if ('messages' === ref.id || (ref.parent && 'messages' === ref.parent.id)) {
        // Add the associated org id for messages
        let myOrgId = orgId(this.store.getState());
        if (myOrgId) {
          if (create) {
            newData.associated_org_id = myOrgId;
          }
        }
      }
    }

    if (Array.isArray(obj)) {
      let newArray = obj.slice();
      for (let key in newData) {
        newArray.push(key);
        newArray.push(newData[key]);
      }
      return newArray;
    } else {
      return Object.assign(newData, obj);
    }
  }


  /**
   * @param {import('firebase/firestore').Query} ref
   * 
   * @return {Promise<import('firebase/firestore').QuerySnapshot>}
   */
  async getQuery(ref) {
    if (!this.test()) {
      return;
    }

    return new Promise((resolve, reject) => {
      getDocs(ref).then((snapshot) => {
        if (snapshot.docs && snapshot.docs.length) {
          snapshot.docs.forEach(() => this.log('read'));
        } else {
          // all queries are at least one read
          this.log('read');
        }
        resolve(snapshot);
      }).catch(err => reject(err));
    });
  }

  /**
   * @param {import('firebase/firestore').DocumentReference} ref
   * 
   * @return {Promise<import('firebase/firestore').QueryDocumentSnapshot>}
   */
  async get(ref) {
    if (!this.test()) {
      return;
    }

    return new Promise((resolve, reject) => {
      getDoc(ref).then((snapshot) => {
        this.log('read');
        resolve(snapshot);
      }).catch(reject);
    });
  }

  /**
   * @param {import('firebase/firestore').Query} ref
   * @param {function(import('firebase/firestore').QuerySnapshot):void} onSuccess
   * @param {function(import('firebase/app').FirebaseError):void=} onError
   * @param {import('firebase/firestore').SnapshotListenOptions=} options
   * 
   * @return {Function}
   */
  onSnapshotQuery(ref, onSuccess, onError = undefined, options = undefined) {
    try {
      CacheHandler.checkQueryCacheOnStartup(ref, onSuccess);
    } catch (e) {
      // eslint-disable-next-line no-restricted-globals
      self?.['reportToErrorMonitoring']?.(e);
    }

    if (!this.test()) {
      return;
    }

    let unsubscribe;
    if (!options) {
      unsubscribe = onSnapshot(ref, (snapshot) => {
        if (snapshot.docs && snapshot.docs.length) {
          // Use changes as if we count the docs themselves,
          // we would incorrectly be counting unchanged docs too
          snapshot.docChanges().forEach(() => this.log('read'));
        } else {
          // all queries are at least one read
          this.log('read');
        }
        onSuccess(snapshot);
      }, onError);
    } else {
      unsubscribe = onSnapshot(ref, options, (snapshot) => {
        if (snapshot.docs && snapshot.docs.length) {
          // Use changes as if we count the docs themselves,
          // we would incorrectly be counting unchanged docs too
          snapshot.docChanges().forEach(() => this.log('read'));
        } else {
          // all queries are at least one read
          this.log('read');
        }
        onSuccess(snapshot);
      }, onError);
    }

    return this.addUnsubscribe(unsubscribe);
  }


  /**
   * @param {import('firebase/firestore').DocumentReference} ref
   * @param {function(import('firebase/firestore').QueryDocumentSnapshot):void} onSuccess
   * @param {function(import('firebase/app').FirebaseError):void=} onError
   * @param {import('firebase/firestore').SnapshotListenOptions=} options
   * 
   * @return {Function}
   */
  onSnapshot(ref, onSuccess, onError = undefined, options = undefined) {
    try {
      CacheHandler.checkDocumentCacheOnStartup(ref, onSuccess);
    } catch (e) {
      // eslint-disable-next-line no-restricted-globals
      self?.['reportToErrorMonitoring']?.(e);
    }

    if (!this.test()) {
      return;
    }

    let unsubscribe;
    if (!options) {
      unsubscribe = onSnapshot(ref, (snapshot) => {
        this.log('read');
        onSuccess(snapshot);
      }, onError);
    } else {
      unsubscribe = onSnapshot(ref, options, (snapshot) => {
        this.log('read');
        onSuccess(snapshot);
      }, onError);
    }

    return this.addUnsubscribe(unsubscribe);
  }

  /**
   * @param {import('firebase/firestore').CollectionReference} ref
   * @param {object} obj
   * @param {AutoSaveType=} autoSave - Whether to show an autosave indicator
   * 
   * @return {Promise<import('firebase/firestore').DocumentReference>}
   */
  async add(ref, obj, autoSave = 'SHOW') {
    if (!this.test()) {
      return;
    }

    obj = this.setMetadata(obj, ref, true);

    this.log('write');
    let p = addDoc(ref, obj);
    if (autoSave === 'SHOW') {
      this._changed();
    }
    return p;
  }


  /**
   * @param {import('firebase/firestore').DocumentReference} ref
   * @param {object} obj
   * @param {AutoSaveType} autoSave Whether to show an autosave indicator. Defaults to 'SHOW'
   * 
   * @return {Promise<void>}
   */
  async update(ref, obj, autoSave = 'SHOW') {
    if (!this.test()) {
      return;
    }

    obj = this.setMetadata(obj, ref);

    this.log('write');
    let p;
    if (Array.isArray(obj)) {
      // @ts-ignore
      p = updateDoc(ref, ...obj);
    } else {
      p = updateDoc(ref, obj);
    }

    if (autoSave === 'SHOW') {
      this._changed();
    }

    return p;
  }

  /**
   * @param {import('firebase/firestore').DocumentReference} ref
   * @param {object} obj
   * @param {import('firebase/firestore').SetOptions=} options
   * @param {AutoSaveType=} autoSave - Whether to show an autosave indicator
   * 
   * @return {Promise<void>}
   */
  async set(ref, obj, options = undefined, autoSave = 'SHOW') {
    if (!this.test()) {
      return;
    }

    let isMerge = options && /** @type {{ merge: boolean }} */ (options).merge;

    obj = this.setMetadata(obj, ref, !isMerge);

    this.log('write');
    let p = setDoc(ref, obj, options);
    if (autoSave === 'SHOW') {
      this._changed();
    }

    return p;
  }

  /**
   * @param {import('firebase/firestore').DocumentReference} ref
   * 
   * @return {Promise<void>}
   */
  async delete(ref) {
    if (!this.test()) {
      return;
    }

    this.log('delete');
    let p = deleteDoc(ref);
    // Don't show save for delete as it's clear from the UI
    // that you deleted something.
    // this._changed();
    return p;
  }
}