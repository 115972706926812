import React, { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Snippet from '../Snippet/Snippet';
import Group from '../Group/Group';
import Welcome from '../Welcome/Welcome';
import { connect } from 'react-redux';
import CreateSnippet from '../CreateSnippet/CreateSnippet';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { EmptyState } from '../EmptyState/EmptyState';
import AddonsListing from '../Addons/AddonsListing';
import Box from '@mui/material/Box';
import NotFound from '../NotFound/NotFound';
import CommonMainPanel from './CommonMainPanel';
 

function MainPanel(props) {
  const { pathname: path } = useLocation();
  let isSnippet = path.startsWith('/snippet') || path.startsWith('/new');

  useEffect(() => {
    let currentPath = path;
    if (!currentPath) {
      return;
    }
    if (!currentPath.startsWith('/folder') && !currentPath.startsWith('/snippet')) {
      return;
    }
    let getItemToScroll = () => {
      let item;
      let items = document.getElementsByClassName('list-item-' + currentPath.split('/')[2]);
      if (items.length === 1) {
        // If there are two groups or snippets (e.g. user and team default)
        // Don't want to scroll between them
        item = items[0];
      } else if (items.length === 0) {
        item = document.querySelector('[data-root-group-selected]');
      }
      return item;
    };
    let scrollToItem = (el) => {
      el.scrollIntoView({
        block: 'nearest'
      });
    };
    let itemToScroll = getItemToScroll();
    if (itemToScroll) {
      scrollToItem(itemToScroll);
      return;
    }
    // sometime there can be a delay before the element exists
    // if the folder was previously closed
    const timeout = setTimeout(() => {
      // Since we're async we may have navigated away
      if (currentPath !== path) {
        return;
      }
      let itemToScroll = getItemToScroll();
      if (itemToScroll) {
        scrollToItem(itemToScroll);
      }
    } , 200);
    return () => clearTimeout(timeout);
  }, [path]);

  function renderInner() {
    if (props.outdatedExtension) {
      return (<div 
        style={{ marginTop: 100 }}>
        <EmptyState
          icon="UPDATE"
          title="Update your Text Blaze extension now"
          description={<span>A newer version of the Text Blaze Extension is available. To continue using Text Blaze you need to update your extension. Follow <a href="https://www.howtogeek.com/64525/how-to-manually-force-google-chrome-to-update-extensions/">these instructions</a> to do so then reload this page.</span>}
        />
      </div>);
    } else {
      return (<CommonMainPanel>
        <Route exact path="/" component={Welcome}/>
        <Route exact path="/welcome/" component={Welcome}/>
        <Route exact path="/packs/:id?" component={AddonsListing}/>
        <Redirect from="/group/:id" to="/folder/:id"/> {/* Deprecated */}
        <Route exact path="/folder/:id/:tab?" render={() => <ErrorBoundary>
          <Group />
        </ErrorBoundary>}/>
        <Route exact path="/snippet/:id" render={() => <ErrorBoundary>
          <Snippet />
        </ErrorBoundary>}/>
        <Route exact path="/new" component={CreateSnippet}/>
        <Route exact path="/create/:text?" component={CreateSnippet}/> {/* Deprecated */}
        <Route exact path="/copy" component={CreateSnippet}/> {/* Deprecated - TODO remove from forums */}
        <Route component={NotFound}/>
      </CommonMainPanel>);
    }
  }


  if (path.startsWith('/welcome') || path.startsWith('/company') || path.startsWith('/packs') || path === '/' || path.startsWith('/pro') || path.startsWith('/upgrade') || path === '/configure/importexport' || isSnippet) {
    return <Box
      sx={{
        paddingLeft: {
          xs: 0,
          sm: isSnippet ? 0 : 2,
          md: isSnippet ? 0 : 2,
          lg: isSnippet ? 0 : 2,
          xl: isSnippet ? 0 : 2
        },
        height: '100%',
        position: 'relative'
      }}>
      {renderInner()}
    </Box>;
  } else {
    return <Box
      sx={{
        position: 'relative',
        height: {
          xs: '100%',
          sm: 'calc(100% - 4vh)'
        },
        marginTop: {
          xs: 0,
          sm: '2vh'
        },
        paddingLeft: {
          xs: 0,
          sm: 1.5
        },
        paddingRight: {
          xs: 0,
          sm: 2
        }
      }}>
      {renderInner()}
    </Box>;
  }
}


/** @type {function(import('@store').RootState): object} */
const mapStateToProps = function(store) {
  return {
    outdatedExtension: store.uiState.outdatedExtension
  };
};

const MainPanelMemoized = connect(
  mapStateToProps
)(MainPanel);

export default MainPanelMemoized;
