import React from 'react';
import { log } from '../../logging/logging';
import { useTypedSelectorShallowEquals } from '../../hooks';
import useFirestore from '../../FirestoreLink/FirestoreHook';
import { makeRef } from '../../firebase_utilities';
import { ListItemText, MenuItem, Select } from '@mui/material';
import { APP_PLATFORM_NAME, CURRENT_PLATFORM } from '../../flags';
import { toast } from '../../message';
import { useDesktopClientSupportsFeature } from '../../desktop_hooks';


const propertyName = `disable ${APP_PLATFORM_NAME} app in Chrome-based browsers`;
const propertyKey = `is_${CURRENT_PLATFORM}_app_disabled_in_chrome_browsers`;

const VALUES_MAP  = {
  null: 'AUTO',
  true: 'OFF',
  false: 'ON'
};

/** @type {Object<string, {value: boolean, label: string, description: string}>} */
const options = {
  'AUTO': {
    value: null,
    label: 'Auto disable desktop app in Chrome (Recommended)',
    description: 'Automatically disable the desktop app in Chrome when the Text Blaze extension is installed'
  }, 
  'OFF': {
    value: true,
    label: 'Never active in Chrome',
    description: 'The desktop app will never be active in Chrome regardless of whether the Text Blaze extension is installed'
  }, 
  'ON': {
    value:false,
    label: 'Always active in Chrome',
    description: 'The desktop app will always be active in Chrome regardless of whether the Text Blaze extension is installed'
  }
};


export default function AppConflictConfig() {
  const isAutoDetectApp = useDesktopClientSupportsFeature('extension-detection');

  let { uid, selectedDefault } = useTypedSelectorShallowEquals(store => ({
    uid: store.userState.uid,
    selectedDefault: (isAutoDetectApp ? null : false)
  }));
  let { data, updateFn } = useFirestore(makeRef('users_settings', uid));
  
  return (
    <Select
      id="desktop-app-disable"
      disabled={!data}
      value={
        VALUES_MAP[data && data.options ? (data.options[propertyKey] ?? selectedDefault) : selectedDefault]
      }
      onChange={(e) => {
        const option =  options[e.target.value];
        const value = option.value;
        log({ action: `Set ${propertyName}`, label: value, debounce: 10000 });

        const updKey = `options.${propertyKey}`;
        updateFn({
          [updKey]: value
        });

        if (isAutoDetectApp && value !== null) {
          toast(`It is not recommended to select "${option.label}" and the option will go away within the next few months. Please contact us at support@blaze.today for assistance.`, {
            duration: 5000,
            intent: 'warning'
          });
        }
      }}
      variant="outlined"
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        PaperProps: {
          style: {
            width: 'min-content'
          }
        }
      }}
    >
      {Object.keys(options).map((v, k) => {
        const option = options[v];

        // Don't show the AUTO option in older binaries
        if (!isAutoDetectApp && option.value === null) {
          return null;
        }
        return <MenuItem key={k} value={v}>
          <ListItemText 
            primary={option.label} 
            secondary={option.description} 
            primaryTypographyProps={{ whiteSpace: 'normal' }} 
            secondaryTypographyProps={{ whiteSpace: 'normal' }}
          />
        </MenuItem>;
      })}
    </Select>
  );
}