import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import T from '@mui/material/Typography';
// @ts-ignore
import BackgroundImage from '../../../images/background_512.png';
import { useTypedSelector } from '../../hooks';
import { checkInstalled, shouldRouteToDesktopApp } from '../../extension';
import { openDashboardLinkInDesktop } from '../../desktop_utilities';

export function DesktopOpenedDialog() {
  const location = useLocation();
  let userAuthenticatedEmail = useTypedSelector(store => store.userState.isLoaded && store.userState.email);
  let [open, setOpen] = useState(false);

  useEffect(() => {
    (async() => {
      // If we are installed on any platform, we're good
      const isInstalled = await checkInstalled();
      if (isInstalled) {
        return;
      }
      if (!userAuthenticatedEmail) {
        return;
      }
      if (await shouldRouteToDesktopApp({ url: location, email: userAuthenticatedEmail })) {
        setOpen(true);
        openDashboardLinkInDesktop(window.location.href);
      }
    })();
    // Only run this on page load, or when auth state loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuthenticatedEmail]);

  if (!userAuthenticatedEmail) {
    return null;
  }
  if (!open) {
    return null;
  }

  const hide = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open
      maxWidth="sm"
      BackdropProps={{
        style: {
          backgroundColor: 'white',
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right bottom',
          backgroundAttachment: 'fixed',
          backgroundSize: 'contain'
        }
      }}
    >
      <DialogContent
        sx={{
          minWidth: {
            xs: 'min(calc(100% - 40px), 370px)',
            sm: '370px'
          }
        }}>
        <T>We beamed the info to your Text Blaze Desktop app. You can close this browser tab, or continue working here.</T>
      </DialogContent>

      <DialogActions style={{ marginRight: 10, marginBottom: 15 }}>
        <Button onClick={hide} variant="outlined" style={{ marginRight: 12 }}><T>Continue here</T></Button>
      </DialogActions>
    </Dialog>
  );
}