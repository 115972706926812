import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Typography as T
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AsyncButton from '../AsyncButton/AsyncButton';

const DeleteDialog = forwardRef((_props, ref) => {
  let [open, setOpen] = useState(false);
  let [config, setConfig] = useState(null);
  let [loading, setLoading] = useState(false);
  let [confirmChecked, setConfirmChecked] = useState(false);

  useImperativeHandle(ref, () => ({
    show: (config) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setOpen(true);
        setConfig(config);
        setConfirmChecked(false);
      });
    }
  }));

  const hide = () => {
    setOpen(false);
    setConfig(null);
    setConfirmChecked(false);
    setLoading(false);
  };

  const cancel = () => {
    if (loading) {
      return;
    }
    if (config.onCancel) {
      config.onCancel();
    }
    hide();
  };

  const accept = async (done) => {
    let confirming = config.onDelete();
    if (confirming) {
      setLoading(true);
      await confirming;
    }
    hide();
    done();
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          cancel();
        }
      }}
      maxWidth="sm"
      BackdropProps={{
        style: {
          opacity: 0.2
        }
      }}>
      <DialogContent>
        <T gutterBottom>
          Are you sure you want to delete {config.item}? Deletion cannot be undone.
        </T>
        {config.extra && <T gutterBottom>{config.extra}</T>}
        {config.confirm ?
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmChecked}
                onChange={() => setConfirmChecked(!confirmChecked)}
                value="deleteChecked"
                color="primary"
              />
            }
            label={config.confirm}
          /> : null}

      </DialogContent>
      <DialogActions style={{ padding: 16 }}><div style={{ flex: 1 }}/>
        <Button
          style={{ marginRight: 12 }}
          onClick={cancel}
          disabled={loading}
        >Cancel</Button>

        <AsyncButton
          disabled={config.confirm && !confirmChecked}
          startIcon={<DeleteIcon style={{ opacity: 0.8 }}/>}
          color="error"
          variant="contained"
          sx={{ ml: 2 }}
          onClick={accept}
        >
          {config.confirmButtonText || 'Delete'}
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
});

export default DeleteDialog;