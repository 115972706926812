import React, { useState, useEffect, useCallback } from 'react';
import { createSnippet, childrenOrder, createGroup } from '../../data';
import T from '@mui/material/Typography'; 
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { userPermission } from '../../auth';
import { limitationsState } from '../Version/limitations';
import AsyncButton from '../AsyncButton/AsyncButton';
import { sync } from '../../Sync/syncer';
import { getState } from '../../getState';

/**
 * @param {object} props
 * @param {object} props.snippetConfig
 * @param {function} props.onCreated
 * @param {boolean=} props.quite
 */
export default function NewSnippetInner(props) {
  const { snippetConfig, onCreated } = props;
  let [newGroupName, setNewGroupName] = useState('');
  let [groupId, setGroupId] = useState('');
  let [idMapping, setIdMapping] = useState(null);
  let [availableGroups, setAvailableGroups] = useState([]);
  let [loading, setLoading] = useState(true);

  const loadGroups = useCallback(() => {
    let state = getState();
    let limits = limitationsState(state);

    let userGroups = state.userState.groups;
    let groups = state.dataState.groups;

    let maxSnippetsPerGroup = limits.MAX_SNIPPETS_PER_GROUP;
    let hasMaxSnippetsPerGroupUpgrade = limits.CAN_UPGRADE_MAX_SNIPPETS_PER_GROUP;

    let selectedGroup;
    if (state.uiState.selectedKind === 'folder') {
      selectedGroup = state.uiState.selected;
    } else if (state.uiState.selectedKind === 'snippet' && sync.getSnippetById(state.uiState.selected)) {
      selectedGroup = sync.getSnippetById(state.uiState.selected).group_id;
    }

    let validGroups = doGroups({
      groups,
      userGroups,
      maxSnippetsPerGroup,
      hasMaxSnippetsPerGroupUpgrade
    }).filter(g => !g.error);

    setNewGroupName('');
    setGroupId((snippetConfig && snippetConfig.group_id) || selectedGroup || (validGroups.length ? validGroups[0].id : '_new'));
    setLoading(false);
  }, [snippetConfig]);

  function doGroups(data) {
    let userGroups = data.userGroups;
    let groups = data.groups;
    let enabledGroups = [];
    let disabledGroups = [];

    let processGroup = (groupId) => {
      let group = groups[groupId];
      let error;
      
      if (!['owner', 'editor'].includes(userPermission(group))) {
        error = 'You do not have edit access to the folder';
      } else if (data.maxSnippetsPerGroup && group.snippets.length >= data.maxSnippetsPerGroup) {
        error = `You cannot have more than ${data.maxSnippetsPerGroup} snippets in a folder (${data.hasMaxSnippetsPerGroupUpgrade ? 'Upgrade for more or' : 'To add more snippets'} create a new folder)`;
      }
    

      let base = {
        id: groupId,
        name: group.name
      };
      if (error) {
        base.disabled = true;
        base.error = error; 
      }
      return base;
    };

    let idMapping = {};

    for (let id of childrenOrder(userGroups)) {
      if (!groups[id] || groups[id].stub) {
        continue;
      }

      idMapping[id] = processGroup(id);
      if (userGroups[id].disabled) {
        disabledGroups.push(idMapping[id]);
      } else {
        enabledGroups.push(idMapping[id]);
      }
    }

    let availableGroups = enabledGroups.concat(disabledGroups);
    setIdMapping(idMapping);
    setAvailableGroups(availableGroups);
    return availableGroups;
  }

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  return <div style={{ width: 220 }}>
    {!loading && (<div style={{ padding: 15, width: 220 }}>
      <T variant="caption" style={{ textAlign: 'center' }}>Select where to add the snippet.</T>
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        <Select
          size="small"
          native
          fullWidth
          value={groupId}
          onChange={(e) => setGroupId(/** @type {string} */ (e.target.value))}
          variant="standard"
          autoFocus
        >
          {availableGroups.map(group => <option key={group.id} value={group.id}>
            {group.name}
          </option>)}
          <option value="_new">
                  Create a new folder...
          </option>
        </Select>
      </div>
      {idMapping[groupId] && idMapping[groupId].error && <T variant="caption" color="error">{idMapping[groupId].error}</T>}
      {groupId === '_new' && <div style={{ textAlign: 'center', marginTop: 6 }}>
        <TextField
          size="small"
          fullWidth
          label="New Folder Name"
          placeholder="New Folder"
          value={newGroupName}
          onChange={e => setNewGroupName(e.target.value)}
          variant="standard"
        />
      </div>}
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        <AsyncButton
          variant="outlined"
          color="primary"
          size="small"
          disabled={groupId === '_new' ? (!newGroupName || !newGroupName.trim()) : (idMapping[groupId] && !!idMapping[groupId].error)}
          onClick={async (done) => {
            let id = groupId;
            if (id === '_new') {
              try {
                id = await createGroup({
                  name: newGroupName,
                  info: ''
                }, true);
              } catch (err) {
                return done();
              }
            }

            const res = await createSnippet(Object.assign({}, snippetConfig, { group_id: id }), props.quite);
            if (onCreated) {
              onCreated(res);
            }
            done();
          }}
        >Create snippet</AsyncButton>
      </div>
    </div>)}
  </div>;
}

