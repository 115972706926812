import React from 'react';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import AddIconPlain from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import { useTables } from '../../hooks/useTables';
import { Autocomplete, Avatar, InputAdornment, LinearProgress, ListItemAvatar, ListItemText } from '@mui/material';
import DatabaseChipIcon from '../Database/DatabaseChipIcon';
import { createNewDatabase } from '../Database/database_utilities';
import { OpenSpaceInNew } from '../Utilities/OpenInNewSpace';
import { generateAppRoute } from '../../hooks/useAppNavigate';

/**
 * Selects a tables database.
 * 
 * @param {import('./AttributeEditors').EditorProps & { createSelects?: boolean }} props
 * createSelects - if true, the "create space" option is a selectable menu. If not true, it behaves like a button starting the new space creation process
 */
export function DatabaseField(props) {
  let value = props.value;

  let { loading, data, refetch, error } = useTables('applications/shared/', {
    cache_seconds: 30 * 60
  });


  if (loading) {
    return <LinearProgress variant="indeterminate" />;
  }

  if (error) {
    return <div style={{ padding: 12 }}>Error loading spaces. Please try again later.</div>;
  }

  let db = data.find(x => x.id === value) || ((props.createSelects && value === '_ADD') ? { id: '_ADD', name: 'Create new space' } : null);
  return <Autocomplete
    disabled={props.readonly}
    value={db}
    options={data.concat({ id: '_ADD' })}
    fullWidth
    sx={{
      mt: 1
    }}
    className="database-select"
    disableClearable
    isOptionEqualToValue={(option, value) => {
      return option.id === value?.id;
    }}
    filterOptions={(options, state) => {
      let input = state.inputValue.toLowerCase();
      return options.filter(db => db.id === '_ADD' || db.name.toLowerCase().includes(input));
    }}
    getOptionLabel={(db) => {
      if (db.id === '_ADD') {
        return props.createSelects ? 'Create new space' : '';
      }
      return db.name || '';
    }}
    renderInput={(params) => <TextField
      {...params}
      label="Space"
      InputProps={Object.assign(params.InputProps, {
        startAdornment: !!db ? <InputAdornment position="start" sx={{ pt: .5 }}>{db.id === '_ADD' ? <Avatar><AddIconPlain fontSize="large" /></Avatar> : <DatabaseChipIcon icon={db.icon} simple />}</InputAdornment> : undefined,
      })}
    />}
    onChange={(_e, database) => {
      if (!database) {
        props.onChange(undefined);
        return;
      }
      props.onChange(database.id);
    }}
    renderOption={(optionProps, database) => {
      if (database.id === '_ADD') {
        return <React.Fragment key="_ADD">
          <Divider />
          <MenuItem 
            dense
            {...(props.createSelects ? optionProps : {
              onClick: () => {
                createNewDatabase({
                  createDefaultTable: true,
                  openImportedButtonLabel: 'Select imported space',
                }).then(async ({ databaseId }) => {
                  await refetch();
                  window.open(generateAppRoute('/space/' + databaseId, 'DATA').url);

                  props.onChange(databaseId);
                });
              }
            })}
          >
            <ListItemAvatar><Avatar><AddIconPlain fontSize="large" /></Avatar></ListItemAvatar>
            <ListItemText
              primary="Create new space"
              secondary="New space for tables"
            />
          </MenuItem>
        </React.Fragment>;
      } 

      return <MenuItem 
        {...optionProps}
        dense
        key={database.id}
      >
        <div style={{
          display: 'flex',
          verticalAlign: 'middle',
          alignItems: 'center',
          fontSize: '0.875rem',
          overflow: 'hidden',
          lineHeight: 1.43,
          width: '100%'
        }}>
          <ListItemAvatar><DatabaseChipIcon icon={database.icon} simple /></ListItemAvatar>
          <ListItemText
            style={{
              flex: 1
            }}
            primary={<div style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>{database.name}</div>}
          />
          <OpenSpaceInNew databaseID={database.id} styles={{ }}/>
        </div>
      </MenuItem>;
    }}
  />;
}