import React from 'react';
import Paper from '@mui/material/Paper';
import Zoom from '@mui/material/Zoom';
import UserAvatar from '../UserAvatar/UserAvatar';
import T from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import { useUserInfo } from '../../hooks/useUserInfo';
import ArrowPopper from '../ArrowPopper/ArrowPopper';




/**
 * @param {object} props
 * @param {{modifiedDate: number, uid: string}} props.lastEditData
 * @param {function} props.hideLastEdit
 * @param {Element} props.target
 * @param {object=} props.style
 */
export default function ChangeHistoryPopup(props) {

  let userInfo = useUserInfo(props.lastEditData.uid);


  if (!props.target) {
    return null;
  }

  if (!userInfo) {
    return null;
  }

  let updatedByEl = <UserAvatar info={userInfo} config={{
    variant: 'body2',
    avatarSize: 20,
    labelStyle: {
      fontWeight: 'bold'
    },
    avatarStyle: {
      marginRight: 6
    }
  }} />;
  
  return <ArrowPopper
    placement="top"
    anchorEl={props.target}
    open
    modifiers={[
      {
        name: 'flip',
        enabled: false
      },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          padding: 8,
          rootBoundary: 'viewport'
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, 7]
        }
      }
    ]}
  > 
    <Zoom in exit>
      <Paper
        elevation={2}
        style={Object.assign({
          border: 'solid 1px #f2f2f2',
          boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 4px 6px 0px rgba(0,0,0,0.14), 0px 6px 12px 0px rgba(0,0,0,0.12)'
        }, props.style)}
      >
        <div style={{
          padding: '8px 12px',
          whiteSpace: 'nowrap',
          display: 'flex',
          alignItems: 'center'
        }}>
          <T
            style={{
              display: 'inline-block'
            }}
            component="div"
            variant="body2"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>{updatedByEl} <div style={{ marginLeft: 6 }}>edited {(Date.now() - props.lastEditData.modifiedDate) / 1000 < 60 ? 'just now' : moment(props.lastEditData.modifiedDate).fromNow()}</div></div></T>
          <IconButton
            size="small"
            aria-label="Close"
            onClick={() => {
              props.hideLastEdit();
            }}
            style={{
              marginLeft: 8
            }}
          >
            <CloseIcon fontSize="small" style={{
              fontSize: '1.2rem'
            }} />
          </IconButton>
        </div>
      </Paper>
    </Zoom>
  </ArrowPopper>;
}