import React, { useRef, useCallback } from 'react';
import Quill from 'quill';
import Highlighter from '../SnippetEditor/highlighter';  // eslint-disable-line no-unused-vars
import { decompressDelta } from '../../delta_proto/DeltaProto';


function useHookWithRefCallback(props) {
  const ref = useRef(null);
  const setRef = useCallback(node => {
    if (!ref.current && node) {
      let quill = new Quill(node, {
        theme: 'snow',
        modules: {
          snippetsyntax: true,
          toolbar: null
        }
      });
      quill.setContents(/** @type {any} */ (decompressDelta(new Uint8Array(Object.values(props.delta)))));
      quill.disable();
      let highlighter = /** @type {Highlighter} */ (quill.getModule('snippetsyntax'));
      highlighter.highlight();
    }
    
    // Save a reference to the node
    ref.current = node;
    // eslint-disable-next-line
  }, []);
  
  return [setRef];
}


export default function DeltaViewer(props) {
  let [ref] = useHookWithRefCallback(props);

  return <div ref={ref} style={{
    border: 'solid 1px grey',
    overflow: 'auto',
    height: 400,
    fontSize: '100%',
    width: 500
  }}></div>;
}