import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AppLink from './AppLink';

/**
 * @param {object} props 
 * @param {string} props.databaseID
 * @param {Object<string, any>} [props.styles]
 * @param {boolean=} props.small
 */
export function OpenSpaceInNew(props) {
  return <IconButton
    {...props.styles}
    component={AppLink}
    to={`/space/${props.databaseID}`}
    appType="DATA"
    target="_blank"
    size={props.small ? 'small' : undefined}
  >
    <Tooltip title="View space">
      <OpenInNewIcon fontSize={props.small ? 'small' : undefined} />
    </Tooltip>
  </IconButton>;
}