import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import AddonConfigDialog from './AddonConfigDialog';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import { toast } from '../../message';
import { saveUserAddonData } from '../../data';
import { useDispatch } from 'react-redux';
import { log } from '../../logging/logging';
import { makeRef } from '../../firebase_utilities';
import { isNonsensitiveGrant } from '../Version/limitations';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { checkOrg, orgPref, orgId as getOrgId, userType } from '../../flags';
import { confirmAddonGrants } from './addon_utilities';


/**
 * @param {object} props
 * @param {AddonObjectInnerType} props.addon
 * @param {{type: 'user'|'org', onDone: function}=} props.initOnLoad
 */
function AddonInstallerBase(props, ref) {
  let [showConfigData, setShowConfigData] = useState(/** @type {{ group: GroupObjectType, type: 'user'|'org', onDone: function}} */ (null));

  let {
    isOrg,
    orgRole,
    orgId,
    userAddonsEnabled
  } = useTypedSelectorShallowEquals((store)=> {
    let isOrg = checkOrg(store);

    return {
      isOrg,
      orgRole: userType(store),
      orgId: getOrgId(store),
      userAddonsEnabled: isOrg && ((store.orgState?.org) ? orgPref(store, 'userAddonsEnabled', true, undefined) : 'LOADING')
    };
  });

  let userAddonsBlocked = isOrg && orgRole !== 'owner' && !userAddonsEnabled;

  let dispatch = useDispatch();


  /**
   * @param {'org'|'user'} type
   * @param {function} done 
   * @param {function=} cancelled
   */
  let initInstall = (type, done, cancelled) => {
    if (userAddonsBlocked) {
      return toast('Contact your organization administrator to activate command packs.', {
        intent: 'danger'
      });
    }
    
    if (!isNonsensitiveGrant(props.addon.grants)) {
      const LOG_CATEGORY = 'Addon permission';
      log({ category: LOG_CATEGORY, action: `Dialog shown - ${type}` });
      confirmAddonGrants({
        grants: props.addon.grants,
        onConfirm: () => {
          log({ category: LOG_CATEGORY, action: `Installed - ${type}` });
          install(type, done);
        },
        onCancel: () => {
          log({ category: LOG_CATEGORY, action: `Cancelled - ${type}` });

          if (cancelled) {
            cancelled();
          }

          done();
        }
      });
    } else {
      install(type, done);
    }
  };

  useEffect(() => {
    if (props.initOnLoad) {
      initInstall(props.initOnLoad.type, props.initOnLoad.onDone);
    }
    // Only run on load so:
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(ref, () => ({
    /**
     * @param {'org'|'user'} type 
     * @param {function} done 
     */
    initInstall: (type, done, cancelled) => initInstall(type, done, cancelled)
  }));
  
  
  /**
   * @param {'org'|'user'} type 
   * @param {function} onDone 
   */
  async function install(type, onDone) {
    if (props.addon.data.has_config) {
      let group = await storage.get(makeRef('groups', props.addon.group_id));
      setShowConfigData({
        group: /** @type {any} */ (Object.assign({ id: group.id }, group.data())),
        onDone,
        type
      });
    } else {
      finishInstall(type, {
        onDone,
        userData: {},
        orgData: {},
        id: props.addon.id
      });
    }
  }



  /**
   * @param {'org'|'user'} type 
   * @param {any} data 
   */
  async function finishInstall(type, data) {
    log({
      action: 'Add Addon',
      label: {
        id: data.id,
        is_org: type === 'org'
      }
    });

    try {
      const messagePrefix = `${props.addon.data.name} command pack activated`;
      if (type === 'org') {
        await storage.update(makeRef('orgs', orgId) , {
          ['addons.' + data.id]: {
            enabled: true,
            installed_at: new Date(),
            data: data.orgData,
            approved_grants: props.addon.grants
          }
        }, 'HIDE_AUTOSAVE');
        await saveUserAddonData(data.id, data.userData);
        toast(`${messagePrefix} for your organization. Users can insert commands using the command sidebar to the right of the snippet editor.`, {
          duration: 6000,
          intent: 'success',
        });
      } else {
        await storage.update(usersSettingsRef, {
          ['addons.' + data.id]: {
            enabled: true,
            installed_at: new Date(),
            data: data.userData,
            approved_grants: props.addon.grants
          }
        }, 'HIDE_AUTOSAVE');
        toast(`${messagePrefix}. You can insert commands using the command sidebar to the right of the snippet editor.`, {
          duration: 6000,
          intent: 'success'
        });
      }

      dispatch({
        type: 'ADDON_INSTALLED',
        data: {
          name: props.addon.data.name
        }
      });

    } catch (e) {
      console.error(e);
      toast('An error occurred activating the command pack.', {
        duration: 6000,
        intent: 'danger'
      });
    }
    data.onDone();
  }



  return (<>
    {showConfigData && <AddonConfigDialog
      action="Setup"
      installedOverride={showConfigData.type}
      group={showConfigData.group}
      initial
      onSave={(data) => {
        finishInstall(showConfigData.type, Object.assign(data, { onDone: showConfigData.onDone }));
        setShowConfigData(null);
      }}
      onCancel={() => {
        showConfigData.onDone();
        setShowConfigData(null);
      }}
    />}
  </>);
}


const AddonInstaller = forwardRef(AddonInstallerBase);
export default AddonInstaller;
