import React, { useState } from 'react';
import './shortcut.css';
import Chip from '@mui/material/Chip';
import WarningIcon from '@mui/icons-material/ErrorOutline';
import { useIsMounted } from '../../hooks';
import Typer from './Typer';

/**
 * @param {object} props
 * @param {string | JSX.Element[]} props.shortcut
 * @param {boolean=} props.hasConflict
 * @param {boolean=} props.notActive
 * @param {React.CSSProperties} [props.style]
 */
function ShortcutBase(props) {
  let [isTyping, setTyping] = useState(false);
  let [timerId, setTimerId] = useState(null);

  let isMounted = useIsMounted();

  let style = {};

  if (props.hasConflict) {
    style = {
      color: '#bf7326',
      borderColor: '#bf7326bb'
    };
  } else if (props.notActive && (!props.style || !props.style.borderColor)) {
    style = {
      borderColor: '#888'
    };
  } else if (!props.style || !props.style.borderColor) {
    style = {
      borderColor: 'rgb(0 188 212)'
    };
  }

  return <span translate="no" className="snippet-shortcut" style={{ overflow: 'hidden' }}>
    <Chip
      component="span"
      onMouseEnter={() => {
        if (timerId) {
          clearTimeout(timerId);
        }
        setTimerId(setTimeout(() => {
          if (isMounted.current) {
            setTyping(true);
          }
        }, 1000));
      }}
      onMouseLeave={() => {
        if (timerId) {
          clearTimeout(timerId);
        }
      }}
      style={Object.assign({
        verticalAlign: 'middle',
        position: 'relative',
        maxWidth: '100%',
      }, props.style, style)}
      size="small"
      label={isTyping ? <span style={{
        position: 'relative',
        overflow: 'hidden',
        display: 'block'
      }}>
        <span style={{
          visibility: 'hidden',
          opacity: 0,
          pointerEvents: 'none'
        }}>{props.shortcut}</span>
        <Typer
          className="snippet-shortcut-typist-box"
          speed={90}
          onTypingDone={() => setTyping(false)}
        >
          {props.shortcut}
        </Typer>
      </span> : props.shortcut}
      variant="outlined"
      icon={props.hasConflict ? <WarningIcon stroke="#bf7326cc" style={{ color: '#bf7326cc' }} /> : null}
    />
  </span>;
}

const Shortcut = React.memo(ShortcutBase);
export default Shortcut;
