import React from 'react';
import { Box } from '@mui/material';


/**
 * 
 * @param {object} props
 * @param {string} props.logo
 * @param {number=} props.size
 * @param {import('react').CSSProperties=} props.style
 */
const OrgLogo = ({
  logo,
  size,
  style
}) => {
  return <Box 
    sx={{
      backgroundImage: `url(${logo})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      width: size || 20,
      height: size || 20
    }}
    style={style}
  />;
};

export default OrgLogo;