import React from 'react';
import useAppNavigate from '../../hooks/useAppNavigate';
import AsyncButton from '../AsyncButton/AsyncButton';
import { waitForLogin } from '@store';
import { enableGroup } from '../../data';
/**
 * Enable group button for notifications.
 * @param {object} props 
 * @param {string} props.id - Folder id
 * @returns 
 */
const EnableGroupButton = ({
  id
}) => {
  const navigate = useAppNavigate();
  return <AsyncButton
    variant="outlined"
    color="primary"
    size="small"
    style={{ marginLeft: 10 }}
    onClick={async (done) => {
      await waitForLogin();
      await enableGroup(id, navigate);
      done();
    }}
  >
    Enable folder
  </AsyncButton>;
};

export default EnableGroupButton;