import React from 'react';
import Chip from '@mui/material/Chip';
import {
  ProIcon,
  NotProIcon,
  BetaIcon,
  ChromeIcon,
  WindowsIcon,
  MacIcon
} from './Assets';
import Tooltip from '@mui/material/Tooltip';
import { notProBackground, proBackground } from './version_utilities';


/**
 * @param {object} props
 * @param {number=} props.zoom
 * @param {string=} props.tooltip
 * @param {string=} props.type
 * @param {object=} props.style
 * @param {boolean=} props.isActive
 * @param {string=} props.label
 * @param {boolean=} props.noLabel
 */
export function ProChipBase(props) {
  let upperLabel = props.label.toUpperCase();
  let capLabel = props.label[0].toUpperCase() + props.label.slice(1);

  let icon = props.isActive ? <ProIcon/> : <NotProIcon/>;
  return <div
    style={Object.assign({ zoom: props.zoom, display: 'inline-block' }, props.style || {})}
    title={props.tooltip || (props.isActive ? 'Congratulations! You have Text Blaze ' + capLabel : 'You don\'t have Text Blaze ' + capLabel)}
  >
    {props.noLabel ? <div style={{
      verticalAlign: 'middle',
      opacity: .7
    }}>{icon}</div> : <Chip
      style={{
        backgroundColor: props.isActive ? proBackground : notProBackground
      }}
      icon={icon}
      label={upperLabel}
    />}
  </div>;
}

/**
 * @param {object} props
 * @param {number=} props.zoom
 * @param {React.CSSProperties=} props.style
 * @param {React.CSSProperties=} props.styleChip
 */
export function BetaChipBase(props) {
  return <div
    style={Object.assign({ zoom: props.zoom, display: 'inline-block' }, props.style || {})}
  >
    <Tooltip
      title="This is an experimental Beta feature"
    >
      <Chip
        variant="outlined"
        size="small"
        label="Beta feature"
        icon={<BetaIcon/>}
        style={props.styleChip}
      />
    </Tooltip>
  </div>;
}

/**
 * @param {object} props
 * @param {string=} props.title
 */
export function ChromeChipBase(props) {
  return <div
    style={{ zoom: 0.7 }}
  >
    <Chip
      title={props.title || 'To use this feature, install our Chrome extension'}
      variant="outlined"
      label="Chrome"
      icon={<div style={{ marginLeft: '5px', marginTop: '3px', position: 'relative' }}><ChromeIcon /></div>}
    />
  </div>;
}

export function WindowsChipBase() {
  return <div style={{ zoom: 0.7, marginLeft: '10px' }}>
    <Chip
      title="This feature is fully supported on Windows"
      variant="outlined"
      label="Windows"
      icon={<WindowsIcon />}
    />
  </div>;
}

export function MacChipBase() {
  return <div style={{ zoom: 0.7, marginLeft: '10px' }}>
    <Chip
      title="This feature is fully supported on macOS"
      variant="outlined"
      label="macOS"
      icon={<MacIcon />}
    />
  </div>;
}