import React, { useEffect, useRef, useState } from 'react';
import { useIsMounted } from '../../hooks';


const DEFAULT_TYPING_SPEED = 200; 

/**
 * Shows an typing animation on the text.
 * @param {object} props
 * @param {string | React.ReactElement[]} props.children
 * @param {number=} props.speed - Animation speed in ms. Defaults: 200
 * @param {number=} props.delay - Animation delay in ms. Defaults: 0
 * @param {string=} props.className - Defaults: auto
 * @param {React.CSSProperties=} props.style - Defaults: auto
 * @param {(() => void)=} props.onTypingDone
 */
const Typer = ({
  speed,
  delay,
  className,
  style,
  children,
  onTypingDone
}) => {
  const [lengthToShow, setLength] = useState(0);
  const mounted = useIsMounted();
  const actualElement = useRef();

  let textNodes = extractTextFromElement(children);
  
  
  useEffect(() => {
    setLength(0);
    let cancelled = false;
    let typingTimer;
    const totalText = textNodes.join('');
    let currentLength = 0;

    function doType() {
      if (cancelled) {
        return;
      }
      currentLength += 1;
      if (currentLength > totalText.length) {
        onTypingDoneHandler();
        return;
      }
      setLength(currentLength);
      typingTimer = setTimeout(doType, speed || DEFAULT_TYPING_SPEED);
    }

    const delayTimer = setTimeout(() => {
      doType();
    }, delay);
    return () => {
      cancelled = true;
      clearTimeout(delayTimer);
      clearTimeout(typingTimer);
    };

  // We want to restart animation on change of children or speed.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, speed]);
  const onTypingDoneHandler = () => {
    if (!onTypingDone) {
      return;
    }

    setTimeout(() => {
      // Just in case to protect against unmount
      if (!mounted.current) {
        return;
      }
      onTypingDone();
    }, delay);
  };
  return (
    <span style={style} className={className}>
      <span ref={actualElement}>
        {generateTextToLength(children, textNodes, lengthToShow)}
      </span>
    </span>
  );
};

export default Typer;

function extractTextFromElement(element) {
  const stack = element ? [element] : [];
  /**
   * @type {string[]}
   */
  const lines = [];

  while (stack.length > 0) {
    const current = stack.pop();
    if (React.isValidElement(current)) {
      React.Children.forEach(current.props.children, (child) => {
        stack.push(child);
      });
    } else if (Array.isArray(current)) {
      for (const el of current) {
        stack.push(el);
      }
    } else {
      lines.unshift(current);
    }
  }

  return lines;
}

/**
 * 
 * @param {string | React.ReactElement[]} children 
 * @param {ReturnType<extractTextFromElement>} textNodes 
 * @param {number} length 
 * @returns 
 */
const generateTextToLength = (children, textNodes, length) => {
  if (typeof children === 'string') {
    return children.substring(0, length);
  }
  let renderedSoFar = 0;
  const toRender = [];
  for (let index = 0; index < textNodes.length; index++) {
    const textNode = textNodes[index];
    const textLength = textNode.length;
    const el = children[index];
    if (textLength + renderedSoFar > length) {
      const {
        children,
        ...props
      } = el.props;
      props.key = `typer-cloned-${index}`;
      
      const cloned = React.createElement(el.type, props, textNode.substring(0, length - renderedSoFar));
      toRender.push(cloned);
      break;
    }

    toRender.push(el);
    renderedSoFar += textLength;
  }

  return toRender;
};