import { getCommandsFromAttribute } from './snippet_processor/ParserUtils';
import { userMemberData } from './userMemberData';

/**
 * @param {string} txt
 * @param {{escapeCommas?: boolean, escapeCommands?: boolean, escapeEquals?: boolean, escapeWhitespace?: string, escapeSlashes?: boolean}=} options
 * 
 * @return {string}
 */
export function sanitizeText(txt, options = {}) {
  let escapeCommas = 'escapeCommas' in options ? options.escapeCommas : false;
  let escapeCommands = 'escapeCommands' in options ? options.escapeCommands : true;
  let escapeEquals = 'escapeEquals' in options ? options.escapeEquals : true;
  let escapeSlashes = 'escapeSlashes' in options ? options.escapeSlashes : true;
  let escapeWhitespace = 'escapeWhitespace' in options ? options.escapeWhitespace : 'both';

  if (escapeCommands === false) {
    // don't escape commands or their contents
    let commands = getCommandsFromAttribute(txt);
    if (commands.length) {
      let res = '';
      let cursor = 0;
      for (let command of commands) {
        if (cursor < command.start) {
          res += sanitizeText(txt.slice(cursor, command.start), Object.assign({}, options, {
            escapeWhitespace: cursor === 0 ? 'left' : 'no'
          }));
        }
        res += txt.slice(command.start, command.end);
        cursor = command.end;
      }
      if (cursor < txt.length) {
        res += sanitizeText(txt.slice(cursor), Object.assign({}, options, {
          escapeWhitespace: cursor === 0 ? 'both' : 'right'
        }));
      }
      return res;
    }
  }

  if (escapeSlashes) {
    txt = txt.replace(/\\/g, '\\\\');
  }
  txt = txt.replace(/;/g, '\\;');
  txt = txt.replace(/}/g, '\\}');
  txt = txt.replace(/{/g, '\\{');
  if (escapeCommas) {
    txt = txt.replace(/,/g, '\\,');
  }
  if (escapeEquals) {
    txt = txt.replace(/=/g, '\\=');
  }
  if (['both', 'right'].includes(escapeWhitespace)) {
    if (txt[txt.length - 1] === ' ') {
      txt = txt.slice(0, txt.length - 1) + '\\ ';
    }
    if (txt[txt.length - 1] === '\n') {
      txt = txt.slice(0, txt.length - 1) + '\\n';
    }
  }
  if (['both', 'left'].includes(escapeWhitespace)) {
    if (txt[0] === ' ') {
      txt = '\\ ' + txt.slice(1);
    }
    if (txt[0] === '\n') {
      txt = '\\n' + txt.slice(1);
    }
  }
  return txt;
}


/**
 * @returns {'api'|'win'|'mac'|'linux'|'cros'|'unknown'}
 */
export function getOS() {
  if (import.meta.env.VITE_APP_BUILD_TYPE === 'api') {
    return 'api';
  }
  
  let app = window.navigator.appVersion;
  if (app.includes('Win')) {
    return 'win';
  } else if (app.includes('Mac')) {
    return 'mac';
  } else if (app.includes('Linux')) {
    return 'linux';
  } else if (app.includes('CrOS')) {
    return 'cros';
  }
  
  return 'unknown';
}

export { userMemberData };