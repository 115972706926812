import Link from '@mui/material/Link';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from '../../hooks';
import { sync } from '../../Sync/syncer';

/**
 * @param {object} props
 * @param {string} props.shortcut
 * @param {string=} props.snippetId
 * @param {(() => void)=} props.onClick
 */
const ConflictShortcutLinkBase = function (props) {
  const { push } = useHistory();

  let targetSnippetId = useTypedSelector(_store => {
    let snippets = sync.getSnippetsByShortcut(props.shortcut);
    for (let snippet of snippets) {
      if (snippet.id !== props.snippetId) {
        return snippet.id;
      }
    }
  });

  const clickFn = (event) => {
    event.stopPropagation();
    push('/snippet/' + targetSnippetId);

    props.onClick?.();
  };


  return (
    <Link onClick={clickFn} title={props.shortcut} style={{
      display: 'inline-flex',
      maxWidth: '80px',
    }}>
      <span translate="no" style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}>
        {props.shortcut}
      </span>
    </Link>
  );
};

const ConflictShortcutLink = React.memo(ConflictShortcutLinkBase);
export default ConflictShortcutLink;
