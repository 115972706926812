import React from 'react';
import TagInputRaw from './TagInputRaw';


/**
 * @param {object} props
 * @param {React.ReactElement=} props.startIcon
 * @param {any=} props.tagProps
 * @param {Function=} props.onAdd  
 * @param {Function=} props.onRemove  
 * @param {Function=} props.onInputChange  
 * @param {string=} props.label
 * @param {string} props.inputValue  
 * @param {any[]} props.values
 * @param {string[]=} props.options
 * @param {string=} props.placeholder  
 * @param {('medium'|'small')=} props.size
 */
function TagInputBase({
  startIcon,
  tagProps,
  onAdd,
  onRemove,
  onInputChange,
  label,
  inputValue,
  values,
  options,
  placeholder,
  size
}) {

  return (
    <TagInputRaw
      startIcon={startIcon}
      tagProps={tagProps}
      onAdd={onAdd}
      onRemove={onRemove}
      onInputChange={onInputChange}
      label={label}
      inputValue={inputValue}
      values={values}
      options={options}
      placeholder={placeholder}
      size={size}
      convertRawText={(rawText) => rawText.split(',')
        .map(v => v.trim())
        .filter(v => !!v && !values.includes(v))}
    />
  );
}


const TagInput = React.memo(TagInputBase);
export default TagInput;