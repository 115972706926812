import React, { useRef, useState, useEffect, useContext } from 'react';
import { loopIcon } from './shared';
import { ShowError, Chicklet, ActionContext } from './SharedComponents';
import { callFn, toLst, runFinishBlock, objectToList, runErrorBlock, getBaseUpdateForRemoteNode, getResponseAndCodeForRemoteCommand, showErrorNotificationForInvalidCommand, runBeginBlock } from '../../snippet_processor/Equation';
import { ParseError } from '../../snippet_processor/ParserUtils';
import RemoteStore from './RemoteStore';
import { useIsMounted } from '../../hooks';


const loadIcon = <svg viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/>
  <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z" />
</svg>;


const pingIcon = <svg viewBox="0 0 24 24">
  <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path>
</svg>;


/**
 * @param {import('../../snippet_processor/ParseNode').InfoType} info 
 * @returns {{ text: string, tooltip: string }}
 */
function urlPreview(info) {
  let url = info.url;
  url = url.slice(url.indexOf('://') + 3).toUpperCase();
  return {
    text: url.slice(0, 15) + (url.length > 15 ? '...' : ''),
    tooltip: info.url
  };
}


/**
 * @typedef {object} RemoteProps
 * @property {import("../../snippet_processor/ParseNode").default} node
 * @property {import("../../snippet_processor/DataContainer").Environment} env
 * @property {string} addonId
 * 
 * @param {RemoteProps} props
 */
function RemoteRendererBase(props) {
  let requestCount = useRef(0);
  let isMounted = useIsMounted();

  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(null);

  let { onChange, onRemoteUpdate } = useContext(ActionContext);

  let debounceId = useRef(Math.random().toString(16));
  let debounceTime = props.node.info.debounceMs;
  const node = props.node;
  const command = node.info.command;
  const isLoader = node.info.type.endsWith('_load');
  const baseUpdate = getBaseUpdateForRemoteNode(node, isLoader);

  useEffect(() => {
    if (node.type !== 'error') {
      if (isLoader) {
        setError(null);

        let received = doRequest();
        if (received) {
          // We have the data immediately, let's bail
          return;
        }
        
        if (node.info.start) {
          setLoading(true);
          doStart();
        }
        if (node.info.begin) {
          setLoading(true);
          handleBegin();
        }
      }
    }
    // eslint-disable-next-line
  }, [props.node && RemoteStore.getFingerprint(props.node.info)])


  /**
   * Run start= function when data loading has started
   * @returns {Promise<void>}
   */
  async function doStart() {
    try {
      const values = toLst(await callFn(node.info.start, [], props.env.derivedLocation('start')));
      if (values.positional.length) {
        throw new ParseError('"start" must return a named list');
      }
      props.env.updateData(Object.entries(values.keys).map(x => ({ name: x[0], value: x[1] })), null, {
        callback: onChange,
        onError: (err) => {
          setError(err);
        }
      });
    } catch (err) {
      setError(err);
    }
  }


  /**
   * @param {'begin'|'error'|'finish'} blockName
   * @param {import('../../snippet_processor/ParseNode').default} block 
   * @param {() => Promise<void>} runner 
   */
  async function handlerWrapper(blockName, block, runner) {
    if (node.type !== 'error') {
      if (block) {
        try {
          await runner();
        } catch (e) {
          showErrorNotificationForInvalidCommand(props.env, '', e.message, blockName);
        }
        onChange();
      }
    }
  }

  async function handleBegin() {
    const beginBlock = node.info.begin;
    await handlerWrapper('begin', beginBlock, () => runBeginBlock(command, beginBlock, props.env));
  }

  async function handleFinish(data) {
    const finishBlock = node.info.finish;
    await handlerWrapper('finish', finishBlock, () => runFinishBlock(command, finishBlock, props.env, data));
  }

  async function handleError(data) {
    const errorBlock = node.info.error;
    await handlerWrapper('error', errorBlock, () => runErrorBlock(command, errorBlock, props.env, data['data'], data['status']));
  }

  /**
   * @returns {boolean}
   */
  function doRequest() {
    requestCount.current++;
    let myCount = requestCount.current;

    setLoading(true);
    onRemoteUpdate({ ...baseUpdate, status: 'pending' });

    return props.env.config.remoteStore.request(node.info, async (res) => {
      const { responseCode, status } = getResponseAndCodeForRemoteCommand(res);
      onRemoteUpdate({ ...baseUpdate, status, data: res?.data, responseCode });
      if (requestCount.current === myCount) {
        if (isMounted.current) {
          if (status === 'error') {
            await handleError(res);
          } else {
            await handleFinish(res);
          }
          await handleDone(res);
          setLoading(false);
        }
      }
    }, async (err) => {
      onRemoteUpdate({ ...baseUpdate, status: 'error', data: err?.data });
      if (requestCount.current === myCount) {
        if (isMounted.current) {
          await handleError(err);
          setLoading(false);
          setError(new ParseError(err));
        }
      }
    }, debounceTime ? { ms: debounceTime, id: debounceId.current } : null);
  }


  /**
   * Runs the done= function when loading has finished
   * Also updates the received keyed list data in the env
   * 
   * @param {object} data 
   * @returns {Promise<void>}
   */
  async function handleDone(data) {
    if (node.type !== 'error') {
      if (node.info.done) {
        try {
          let result = data.data;
          if (typeof result === 'object') {
            result = objectToList(result);
          }
          let values = toLst(await callFn(node.info.done, [result, data.status], props.env.derivedLocation('done')));
          if (values.positional.length) {
            throw new ParseError('"done" must return a named list');
          }
          props.env.updateData(Object.entries(values.keys).map(x => ({ name: x[0], value: x[1] })), null, {
            callback: onChange,
            onError: (err) => {
              if (isMounted.current) {
                setError(new ParseError(err.message));
              }
            }
          });
        } catch (err) {
          onRemoteUpdate({ ...baseUpdate, status: 'error', data: err });
          if (isMounted.current) {
            setError(new ParseError(err.message));
          }
          return;
        }
      }
    } else {
      // TODO: how to test this?
      onRemoteUpdate({ ...baseUpdate, status: 'error' });
      if (isMounted.current) {
        setError(node);
      }
    }
  }

  if (node.type === 'error' || error) {
    let err = error || node;
    return <ShowError msg={(err instanceof ParseError || err instanceof Error) ? err.message : node.info.message} blocking={false} nodeOrAddonId={props.addonId} />;
  } else {
    if (node.info.silent) {
      return null;
    }
    let category = node.info.type.split('_')[0];
    let action = node.info.type.split('_')[1];
    if (category === 'url') {
      let loader = action === 'load';
      let msg = urlPreview(node.info);
      return Chicklet(<span>{loader ? loadIcon : pingIcon} <span className={'label' + (loader ? ' can-load' : '')}>{msg.text}</span> {loading ? <span className="chick-loader blaze-loading" data-storeid={props.addonId}>{loopIcon}</span> : null}</span>, {
        title: msg.tooltip
      });
    } else {
      return <ShowError msg={'Unknown category: ' + category} blocking={false} nodeOrAddonId={props.addonId} />;
    }
  }
}

const RemoteRenderer = React.memo(RemoteRendererBase);
export default RemoteRenderer;
