import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { TABLES_FRONT_END_DOMAIN } from '../../hooks/useTables';
import DatabaseFrame from './DatabaseFrame';
import ReactDOM from 'react-dom';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import { useTypedSelectorShallowEquals } from '../../hooks';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

/**
 * @param {object} props
 * @param {object} props.config
 * @param {function=} props.config.onRestored
 * @returns
 */
export const DatabaseSnapshotsDialog = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [config, setConfig] = useState(null);
  const favorites = useTypedSelectorShallowEquals((state) => state.userState.settingsLoaded ? (state.userState?.options?.databases || {}) : null);
  const databaseFrame = useRef(null);

  let maxOrder = 0;
  if (favorites) {
    maxOrder = Math.max(...[0].concat(Object.values(favorites).filter(db => db.favorite).map(db => db.order || 0)));
  }

  useImperativeHandle(ref, () => ({
    show: (config) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setConfig(config);
        setIsOpen(true);
      });
    }
  }));

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      // @ts-ignore
      maxWidth="95vw"
      onClose={() => setIsOpen(false)}
      BackdropProps={{
        style: {
          opacity: 0.2
        }
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: 'grey.100',
          pr: 6
        }}
      >
        Snapshots of <b>{config.database.name}</b>
        <IconButton
          onClick={() => setIsOpen(false)}
          sx={{
            position: 'absolute',
            transform: 'translate(50%, -50%)',
            right: 28,
            top: 32
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: {
          xs: '300px',
          sm: '500px',
          md: '650px',
        },
        height: '70vh',
        padding: 0,
      }}>
        <DatabaseFrame
          ref={databaseFrame}
          url={`${TABLES_FRONT_END_DOMAIN}/data-blaze/snapshots/${config.database.id}`}
          onLoaded={() => {
            databaseFrame.current.contentWindow.postMessage({
              type: 'init_database_names',
              data: config.databaseNames
            }, TABLES_FRONT_END_DOMAIN);
          }}
          listener={(event) => {
            const type = event.data.type;
            if (type === 'snapshot_restored') {
              const database = event.data.data;
              storage.update(usersSettingsRef, {
                [`options.databases.${database.id}.favorite`]: true,
                [`options.databases.${database.id}.order`]: maxOrder + 1,
              });
              if (config.onRestored) {
                config.onRestored(database);
              }
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
});