import React from 'react';
import T from '@mui/material/Typography';


/**
 * @param {object} props
 * @param {((React.ReactElement|string)[]|React.ReactElement|string)=} props.children
 * @param {React.ReactElement=} props.icon
 * @param {string=} props.title
 * @param {string=} props.className
 * @param {object=} props.style
 * @param {boolean=} props.warning
 */
function CalloutBase(props) {
  return  <div
    className={props.className || undefined}
    style={Object.assign({
      lineHeight: 1.5,
      fontSize: 14,
      position: 'relative',
      borderRadius: 3,
      backgroundColor: props.warning ? 'rgba(217, 130, 43, 0.15)' : 'rgba(138, 155, 168, 0.15)',
      width: '100%',
      padding: props.title ? '10px 16px 16px' : '10px 16px'
    }, props.style)}
  >
    {props.title ? <T variant="h6" style={{
      color: props.warning ? '#bf7326' : undefined
    }} paragraph>{props.icon ? React.cloneElement(props.icon, {
        style: {
          opacity: props.warning ? undefined : 0.65,
          verticalAlign: 'middle',
          position: 'relative',
          top: -2
        }
      }) : null} {props.title}</T> : null}
    {props.children}
  </div>;
}

const Callout = React.memo(CalloutBase);
export default Callout;
