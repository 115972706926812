import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  ToggleButtonGroup,
  Typography as T
} from '@mui/material';
import { addBreadcrumb } from '@sentry/browser';
import React, { useImperativeHandle, useState } from 'react';
import BorderSidePicker from './BorderSidePicker';
import ColorPicker from './ColorPicker';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignToggleButton from './AlignToggleButton';


const COLORS = [
  [null, 'transparent'],
  ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff'],
  ['#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff'],
  ['#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff'],
  ['#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2'],
  ['#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466']
];

const BORDER_WIDTHS = [0, 0.5, 1, 2, 3, 4, 5];

const BORDER_STYLES = [
  'none',
  'solid',
  'dashed',
  'dotted'
];

/**
 * @typedef {object} TableFormatDialogDef
 * @property {(actions: import('./TableMenu').OperationActionsType) => any} show
 */


/**
 * 
 * @param {object} props
 * @param {import('react').Ref<TableFormatDialogDef>} ref
 */
const TableFormatBase = (props, ref) => {
  const [actions, setActions] = useState(/** @type {Parameters<TableFormatDialogDef['show']>['0']} */ (null));
  const [formats, setFormats] = useState(/** @type {{[key: string]: any}} */({}));

  const {
    border: borderAction,
    color: cellBgAction,
    halign: halignAction,
    valign: valignAction
  } = actions || {};
  const {
    backgroundColor = null,
    borderColor = null,
    borderWidth = null,
    borderStyle = null,
    borderSides = ['all'],
    halign = '',
    valign = ''
  } = formats;


  useImperativeHandle(ref, () => ({
    show: (actions) => {
      // to reset the values
      setFormats({});
      setActions(actions);
    }
  }), []);
  const handleClose = () => {
    setActions(null);
  };

  const applyFormats = () => {
    if (backgroundColor !== null) {
      addBreadcrumb({
        message: 'Setting table cell bgcolor - ' + backgroundColor
      });
      cellBgAction.handler(backgroundColor);
    }
    if (halign) {
      addBreadcrumb({
        message: 'Setting table cell halign - ' + halign
      });
      halignAction.handler(halign);
    }
    if (valign) {
      addBreadcrumb({
        message: 'Setting table cell valign - ' + valign
      });
      valignAction.handler(valign);
    }

    const borderActionsToSet = [];
    borderSides.forEach((side) => {

      if (borderColor !== null) {
        borderActionsToSet.push({
          type: 'color',
          side: side,
          value: borderColor
        });
      }

      if (borderWidth !== null) {
        borderActionsToSet.push({
          type: 'width',
          side: side,
          value: borderWidth + 'px'
        });
      }


      if (borderStyle !== null) {
        borderActionsToSet.push({
          type: 'style',
          side: side,
          value: borderStyle
        });
      }
    });

    if (borderActionsToSet.length) {
      addBreadcrumb({
        message: 'Setting table border',
        data: borderActionsToSet
      });
      borderAction.handler(borderActionsToSet);
    }
  };
  return (
    <Dialog
      open={!!actions}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>Format cells</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Box sx={{
          display: 'flex',
          gap: 2
        }}>
          <T>
            Background color
          </T>
          <ColorPicker
            value={backgroundColor}
            onChange={(v) => {
              setFormats(current => ({
                ...current,
                backgroundColor: v
              }));
            }}
            colors={COLORS}
          />
        </Box>
        <Divider />
        <Box sx={{
          display: 'flex',
          gap: 2
        }}>
          <T>
            Border color
          </T>
          <ColorPicker
            value={borderColor}
            onChange={(v) => {
              setFormats(current => ({
                ...current,
                borderColor: v
              }));
            }}
            colors={COLORS}
          />
        </Box>

        <FormControl fullWidth>
          <InputLabel id="table-format-border-width">Border width</InputLabel>
          <Select
            labelId="table-format-border-width"
            value={borderWidth !== undefined && borderWidth !== null ? borderWidth : ''}
            onChange={(evt) => {
              setFormats(current => ({
                ...current,
                borderWidth: evt.target.value
              }));
            }}
            input={<OutlinedInput label="Border width" />}
          >
            {BORDER_WIDTHS.map((size) => (
              <MenuItem
                key={size}
                value={size}
              >
                {size} px
              </MenuItem>
            ))}
          </Select>
        </FormControl>


        <FormControl fullWidth sx={{
        }}>
          <InputLabel id="table-format-border-style">Border style</InputLabel>
          <Select
            labelId="table-format-border-style"
            value={borderStyle || ''}
            onChange={(evt) => {
              setFormats(current => ({
                ...current,
                borderStyle: evt.target.value
              }));
            }}
            input={<OutlinedInput label="Border style" />}
          >
            {BORDER_STYLES.map((style) => (
              <MenuItem
                key={style}
                value={style}

              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flex: 1
                  }}
                >
                  {style} <div style={{
                    width: 100,
                    height: 1,
                    borderTopWidth: borderWidth || 1,
                    // @ts-ignore
                    borderTopStyle: style,
                    borderTopColor: borderColor
                  }} />
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <BorderSidePicker
          value={borderSides}
          onChange={(sides) => setFormats(current => ({
            ...current,
            borderSides: sides
          }))}
        />

        <Divider />
        <Box sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center'
        }}>
          <T>
            Horizontal alignment
          </T>
          <ToggleButtonGroup
            value={[halign]}
            onChange={(evt, v) => setFormats((current) => ({
              ...current,
              halign: v
            }))}
            exclusive
          >
            <AlignToggleButton
              label="Leave unchanged"
              sx={{
                p: 0
              }}
              value=""
            >
              <div style={{
                display: 'flex',
                margin: '0 auto',
                transform: `
                  translate(0px, 0px)
                  rotate(-45deg)
                  scaleX(0.5)
                `,
              }}>unset</div>
            </AlignToggleButton>
            <AlignToggleButton label="Left" value="left">
              <AlignHorizontalLeftIcon />
            </AlignToggleButton>
            <AlignToggleButton label="Center" value="center">
              <AlignHorizontalCenterIcon />
            </AlignToggleButton>
            <AlignToggleButton label="Right" value="right">
              <AlignHorizontalRightIcon />
            </AlignToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box sx={{
          display: 'flex',
          gap: 2
        }}>
          <T>
            Vertical alignment
          </T>

          <ToggleButtonGroup
            value={[valign]}
            onChange={(evt, v) => setFormats((current) => ({
              ...current,
              valign: v
            }))}
            exclusive
          >
            <AlignToggleButton
              label="Leave unchanged"
              sx={{
                p: 0
              }}
              value=""
            >
              <div style={{
                display: 'flex',
                margin: '0 auto',
                transform: `
                  translate(0px, 0px)
                  rotate(-45deg)
                  scaleX(0.5)
                `,
              }}>unset</div>
            </AlignToggleButton>
            <AlignToggleButton label="Top" value="top">
              <AlignVerticalTopIcon />
            </AlignToggleButton>
            <AlignToggleButton label="Middle" value="middle">
              <AlignVerticalCenterIcon />
            </AlignToggleButton>
            <AlignToggleButton label="Bottom" value="bottom">
              <AlignVerticalBottomIcon />
            </AlignToggleButton>
          </ToggleButtonGroup>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!actions}
          onClick={() => {
            applyFormats();
            handleClose();
          }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TableFormat = React.memo(React.forwardRef(TableFormatBase));
export default TableFormat;