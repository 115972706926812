import { useTypedSelectorShallowEquals } from '../hooks';

/**
 * @returns {TeamObjectType[]|null}
 */
export function useTeams() {
  return useTypedSelectorShallowEquals((store) => {
    let teams = store.orgState?.allTeams;
    if (teams) {
      return Object.values(teams).sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
    }
    return null;
  });
}