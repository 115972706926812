import React from 'react';
import { Button } from '@mui/material';


const SideBarButton = React.forwardRef(/**
 *
 * @param {object} props
 * @param {React.ReactNode} props.startIcon
 * @param {React.ReactNode} props.children,
 * @param {React.MouseEventHandler=} props.onClick
 * @param {boolean=} props.disabled
 * @param {import('@mui/material').ButtonProps['sx']=} props.sx
 */
  function ({
    startIcon,
    children,
    onClick,
    sx,
    ...otherProps
  }, ref) {
    return (
      <Button
        ref={ref}
        startIcon={startIcon}
        onClick={onClick}
        sx={{
          color: 'text.secondary',
          p: 1.5,
          justifyContent: 'start',
          ...sx,
        }}
        {...otherProps}
      >
        {children}
      </Button>
    );
  });

export default SideBarButton;
