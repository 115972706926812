import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { orgId, checkPayingPro, isMacPlatform, APP_TARGET } from '../../flags';
import Button from '@mui/material/Button';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import AppBar from '@mui/material/AppBar';
import T from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Link, useLocation } from 'react-router-dom';
import NotificationsMenu from './NotificationsMenu';
import Search from '../Search/Search';
import Tooltip from '@mui/material/Tooltip';
import AutoSaveNotification from './AutoSaveNotification';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DocsIcon from '@mui/icons-material/Book';
import VideoIcon from '@mui/icons-material/YouTube';
import ForumIcon from '@mui/icons-material/Forum';
import { useIsSmall, useTypedSelectorShallowEquals } from '../../hooks';
import HelpIcon from '@mui/icons-material/HelpOutline';
import CollectionsIcon from '@mui/icons-material/Collections';
import './top_bar.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import useHotkey from '../../hotkeys_hook';
import AccountMenu from './AccountMenu';
import MenuDropDown from './MenuDropDown';
import crownIcon from './CrownIcon';
import SwitcherMenu from './SwitcherMenu';
import { log } from '../../logging/logging';
import AppLink from '../Utilities/AppLink';
import { aiBlazeBackgroundGradient } from '../../aiBlaze';
import { fullAppName, isElectronApp } from '../../raw_flags';
import { usePushAISidebar } from '../../hooks/usePushAISidebar';

export default function TopBar() {
  let {
    isPayingPro,
    myOrgId,
    isUserLoaded,
    isReadonlyLoaded,
  } = useTypedSelectorShallowEquals((store) => {
    return {
      isPayingPro: checkPayingPro(store),
      myOrgId: orgId(store),
      isUserLoaded: store.userState.isLoaded && !!store.userState.uid,
      isReadonlyLoaded: store.userState.readonlyLoaded
    };
  });
  const location = useLocation();
  const hasPurchaseButton = isReadonlyLoaded && !myOrgId && !isPayingPro && APP_TARGET === 'TEXT';

  if (!isUserLoaded) {
    return null;
  }

  return <TopBarChildrenMemo
    hasPurchaseButton={hasPurchaseButton}
    myOrgId={myOrgId}
    isHome={!location.pathname || location.pathname === '/'}
    appType={APP_TARGET}
  />;
}


/**
 * @param {object} props
 * @param {boolean} props.hasPurchaseButton
 * @param {string} props.myOrgId
 * @param {AppType} props.appType
 * @param {boolean=} props.isHome
 */
function TopBarChildren({
  hasPurchaseButton,
  myOrgId,
  isHome,
  appType,
}) {

  let pushAISidebar = usePushAISidebar();

  let dispatch = useDispatch();
  let [searchOpen, setSearchOpen] = useState(false);

  const isAppTextBlazeOrAiBlaze = appType === 'TEXT' || appType === 'AI';
  
  useHotkey('cmd+/, ctrl+/', () => {
    if (isAppTextBlazeOrAiBlaze) {
      if (appType === 'TEXT' && isElectronApp()) {
        log({ action: 'Open search using hotkey' });
      }
      setSearchOpen(true);
    } else {
      dispatch({ type: 'OPEN_SEARCH' });
    }
  }, [appType]);

  const isSmall = useIsSmall();
  const isLowSpaceForSearchButton = useMediaQuery('(max-width:850px)');

  function purchaseButton() {
    if (hasPurchaseButton) {
      return <Link to="/pro" style={{ textDecoration: 'none' }} data-testid="top-upgrade-button">
        {!isSmall ? <Button
          color="inherit"
          sx={{
            marginRight: {
              xs: 1,
              sm: 2,
              md: 2,
              lg: 2
            },
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            verticalAlign: 'middle'
          }}
          startIcon={crownIcon({
            width: 20,
            height: 20,
            color: 'yellow'
          })}
        >
          Get Text Blaze Pro
        </Button> :
          <IconButton
            sx={{
              marginRight: {
                xs: 1,
                sm: 2,
                md: 2,
                lg: 2
              },
              color: 'yellow'
            }}
          >
            {crownIcon()}
          </IconButton>}
      </Link>;
    }
  }

  function searchButton() {

    function searchClick() {
      if (appType === 'TEXT' || appType === 'AI') {
        setSearchOpen(true);
      } else if (appType === 'DATA' || appType === 'PAGE') {
        dispatch({ type: 'OPEN_SEARCH' });
      }
    }

    if (isSmall || (isLowSpaceForSearchButton && (hasPurchaseButton || myOrgId))) {
      return <Tooltip
        title="Search snippets"
      >
        <IconButton
          onClick={searchClick}
          color="inherit"
          size="small"
          sx={{
            marginRight: {
              xs: 1,
              sm: 2,
              md: 2
            }
          }}
        >
          <SearchIcon />
        </IconButton>
      </Tooltip>;
    }

    return (
      <Box
        onClick={searchClick}
        className="top-bar-search-button"
        aria-label="Search snippets"
        sx={{
          bgcolor: 'secondary.light',
          '&:hover': {
            bgcolor: 'secondary.dark'
          }
        }}
      >
        <SearchIcon style={{
          zoom: 0.9,
          opacity: 0.9,
          marginRight: 8
        }} />
        <span style={{
          opacity: 0.5
        }}>Search...</span>
        <span style={{ flex: 1 }}></span>
        <Button
          size="small"
          component="div"
          style={{
            minWidth: 'auto',
            lineHeight: 'normal',
            border: '1px solid rgba(255,255,255,0.4)',
            color: 'currentColor',
            padding: '0px 5px 1px',
            opacity: 0.4
          }}
          variant="outlined"
        >
          {isMacPlatform() ? '⌘' : 'Ctrl-'}/
        </Button>
      </Box>
    );
  }

  function userItems() {
    return <>
      {!isSmall && purchaseButton()}

      {!pushAISidebar && searchButton()}

      {(!isSmall && !pushAISidebar) ? <ErrorBoundary component={<span>[Switcher Error]</span>}>
        <SwitcherMenu appType={appType} />
      </ErrorBoundary>
        : null }

      <ErrorBoundary component={<span>[Notification Error]</span>}>
        <NotificationsMenu />
      </ErrorBoundary>

      <AccountMenu />
    </>;
  }

  return <>
    {isAppTextBlazeOrAiBlaze && <ErrorBoundary component={<span>[Search Error]</span>}>
      {searchOpen ? <Search
        onClose={() => setSearchOpen(false)}
      /> : null}
    </ErrorBoundary>}

    <StatelessTopBar
      appType={appType}
      isHome={isHome}
      prependedChildren={
        <Box
          sx={{
            display: {
              'xs': isAppTextBlazeOrAiBlaze || isHome ? 'inline' : 'none',
              'sm': 'none'
            },
            // For text blaze it is 90+, but reserving space in data blaze for Add icon and help icon
            minWidth: 80
          }}
        >
          {isAppTextBlazeOrAiBlaze && <Button
            color="inherit"
            onClick={() => {
              dispatch({
                type: 'OPEN_SIDEBAR'
              });
            }}
            startIcon={<MenuIcon />}
            variant="outlined"
            size="small"
          >
            Snippets
          </Button>}
        </Box>}
    >
      {isSmall && purchaseButton()}

      <Box sx={{
        color: 'inherit',
        flex: 1,
        opacity: .85,
        paddingLeft: {
          xs: 0,
          sm: 0,
          md: 2
        },
        alignItems: 'center',
        lineHeight: 1.75
      }}>
        <AutoSaveNotification />
      </Box>

      {userItems()}
    </StatelessTopBar>
  </>;
}

const TopBarChildrenMemo = React.memo(TopBarChildren);

/**
 *
 * @param {Object} props
 * @param {JSX.Element} props.contents
 * @param {Function} props.menuOptions
 * @param {number=} props.width
 * @returns
 */
function MenuButton(props) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef();

  return (
    <>
      <div
        ref={buttonRef}
        onClick={() => setOpen(!open)}
      >{props.contents}</div>
      <MenuDropDown
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
        width="auto"
      >
        {props.menuOptions(() => setOpen(false)).filter(option => !!option)}
      </MenuDropDown>
    </>
  );

}

const GALLERY_URL = 'https://blaze.today/gallery';
const GalleryMenuItemMobile = React.forwardRef(
  /**
   * Gallery menu item to show in popup menu.
   * @param {object} props
   * @param {() => any} props.onClick
   */
  (props, ref) => {
    return (
      <MenuItem
        ref={ref}
        component="a"
        href={GALLERY_URL}
        target="_blank"
        onClick={() => {
          log({
            action: 'Clicking on topbar snippet gallery'
          });
          props.onClick();
        }}
      >
        <ListItemIcon>
          <CollectionsIcon />
        </ListItemIcon>
        Gallery
      </MenuItem>
    );
  }
);


const GalleryMenuItem = () => {
  return (
    <Box
      component="a" href={GALLERY_URL} target="_blank" rel="noopener noreferrer"
      sx={{
        display: {
          xs: 'none',
          lg: 'contents'
        }
      }}
      onClick={() => {
        log({
          action: 'Clicking on topbar snippet gallery'
        });
      }}
    >
      <T variant="subtitle1" color="inherit" style={{ marginLeft: 24, position: 'relative', top: 1 }}>
        Gallery
      </T>
    </Box>
  );
};

/**
 * @param {object} props
 * @param {AppType} props.appType
 * @param {boolean=} props.isHome
 * @param {React.ReactNode=} props.prependedChildren
 * @param {React.ReactNode=} props.children
 * @param {boolean=} props.hideLinks
 */
export function StatelessTopBar({
  appType,
  prependedChildren,
  children,
  isHome,
  hideLinks
}) {

  const isAppTextBlaze = appType === 'TEXT';
  const docsUrl = appType === 'TEXT' ? 'https://blaze.today/docs/' :
    appType === 'DATA' ? 'https://blaze.today/datablaze/docs/' :
      appType === 'PAGE' ? 'https://blaze.today/pageblaze/docs/' :
        null;


  const tooltipTitle = isHome ? '' : (fullAppName + ' Home');

  const isAiBlaze = appType === 'AI';

  return (
    <AppBar
      style={{ zIndex: 12 }}
      position="static"
      className="top-bar"
      color="secondary"
      elevation={0}
      sx={{
        background: appType === 'AI' ? aiBlazeBackgroundGradient : undefined }}
    >
      <Toolbar variant="dense" style={{ display: 'flex', overflowX: 'hidden' }}>
        {prependedChildren}

        <AppLink
          appType={appType}
          to={appType === 'DATA' ? '/spaces/' : (appType === 'PAGE' ? '/sites/' : '/snippets/')}
          style={{
            textDecoration: 'none',
          }}
        >
          <T
            variant="h6"
            color="inherit"
            sx={{
              display: {
                xs: isAppTextBlaze || isHome || isAiBlaze ? 'none' : 'inline',
                sm: 'inline'
              },
              marginLeft: {
                xs: 1,
                sm: '120px'
              },
              whiteSpace: 'nowrap'
            }}
          >
            {/* Don't show the tooltip if user is already on the home page */}
            {!!tooltipTitle ? (
              <Tooltip title={tooltipTitle} arrow>
                <span>{fullAppName}</span>
              </Tooltip> 
            ) : <span>{fullAppName}</span>}

          </T>
        </AppLink>

        {appType !== 'AI' && !hideLinks && <>
          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'contents'
              },
            }}
          >
            <a href={docsUrl} target="_blank" rel="noopener noreferrer">
              <T variant="subtitle1" color="inherit" style={{ marginLeft: 32, position: 'relative', top: 1 }}>
              Docs
              </T>
            </a>

            <a href="https://community.blaze.today/" target="_blank" rel="noopener noreferrer">
              <T variant="subtitle1" color="inherit" style={{ marginLeft: 24, position: 'relative', top: 1 }}>
              Community
              </T>
            </a>
            {isAppTextBlaze && <GalleryMenuItem />}
          </Box>

          <Box
            sx={{
              display: {
                xs: 'contents',
                md: 'none'
              },
            }}
          >
            <MenuButton
              contents={<IconButton color="inherit" size="small" sx={{ ml: 1 }}>
                <HelpIcon />
              </IconButton>}
              menuOptions={(close) => [
              // @ts-ignore
                <MenuItem
                  key="docs"
                  component="a"
                  href={docsUrl}
                  target="_blank"
                  onClick={() => close()}
                >
                  <ListItemIcon>
                    <DocsIcon />
                  </ListItemIcon>
                Docs
                </MenuItem>,
                // @ts-ignore
                <MenuItem
                  key="youtube"
                  component="a"
                  href="https://www.youtube.com/channel/UC_0WgZe1x4uy74plhobv8Fg"
                  target="_blank"
                  onClick={() => close()}
                >
                  <ListItemIcon>
                    <VideoIcon />
                  </ListItemIcon>
                Tutorial videos
                </MenuItem>,
                // @ts-ignore
                <MenuItem
                  key="community"
                  component="a"
                  href="https://community.blaze.today"
                  target="_blank"
                  onClick={() => close()}
                >
                  <ListItemIcon>
                    <ForumIcon />
                  </ListItemIcon>
                Community forums
                </MenuItem>,
                isAppTextBlaze && <GalleryMenuItemMobile key="gallery" onClick={() => close()} />
              ]}
            />
          </Box>
        </>}

        {children}
      </Toolbar>
    </AppBar>
  );
}