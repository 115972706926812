import { FOLDER_COLLAPSE_KEY, getIsWebpageEmbedded } from '../../flags';
import { getApplicationStateFromExtension, sendToExtension } from '../../extension';
import { isElectronFlag } from '../../raw_flags';

/**
 * @param {string} key
 */
export async function readFromStorage(key) {
  if (isElectronFlag) {
    return JSON.parse(localStorage.getItem(key));
  } else {
    return sendToExtension({ type: 'extensionStorage', subType: 'get', key, });
  }
}

/**
 * @param {string} key
 * @param {any} value
 */
export async function writeToStorage(key, value) {
  if (isElectronFlag) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    return sendToExtension({ type: 'extensionStorage', subType: 'set', key, value, });
  }
}

function checkInsert() {
  return sendToExtension({
    type: 'active_tab_editable',
  });
}

/**
 * @param {import("redux").Store} store 
 * @param {Parameters<typeof getApplicationStateFromExtension>[0]} config
 */
export async function loadApplicationStateExtension(store, config = {}) {
  checkInsert().then((res) => {
    if (res) {
      store.dispatch({
        type: 'CAN_INSERT',
        hasSelection: !!res.hasSelection,
      });
    }
  });

  const incomingData = await getApplicationStateFromExtension(config);
  if (!incomingData) {
    store.dispatch({
      type: 'EXTENSION_NOT_LOADED'
    });
    return;
  }

  const { data, allSnippets } = incomingData;
  let groups;
  if (data.groups) {
    groups = {};
    for (let group of data.groups) {
      if (group.id) { // shouldn't be necessary, but let's be defensive
        groups[group.id] = group;
        groups[group.id].snippets = allSnippets.filter((snippet) => snippet.group_id === group.id);
      }
    }
  }
  let addons = data.addons;
  store.dispatch({
    type: 'DATA_UPDATED',
    data: {
      snippets: allSnippets,
      groups,
      groupIds: data.groups.map(({ id }) => id),
      addons
    }
  });
  if (data.org) {
    store.dispatch({
      type: 'ORG_UPDATE',
      data: data.org
    });
  }
  if (data.config) {
    store.dispatch({
      type: 'NEW_CONFIG',
      data: data.config
    });
  }
  if (getIsWebpageEmbedded()) {
    const priorities = data.userData.priorities?.snippets;
    if (priorities) {
      store.dispatch({
        type: 'USER_SETTINGS_UPDATED',
        data: {
          priorities,
        }
      });
    }

    readFromStorage('view').then(result => {
      store.dispatch({
        type: 'UPDATE_VIEW',
        view: result || 'snippet',
      });
    });

    readFromStorage(FOLDER_COLLAPSE_KEY).then((result) => {
      const collapsed = result ? JSON.parse(result) : {};
      updateCollapsedState(collapsed, store, groups);
    });
  } else {
    const collapsed = JSON.parse(localStorage.getItem(FOLDER_COLLAPSE_KEY)) || {};
    updateCollapsedState(collapsed, store, groups);
  }
  return data;
}

function updateCollapsedState(collapsed, store, groups) {
  store.dispatch({
    type: 'UPDATE_COLLAPSED',
    collapsed: Object.keys(groups).reduce((prev, id) => {
      prev[id] = collapsed[id] || false;
      return prev;
    }, {})
  });

}

/**
 * 
 * @param {import("redux").Store} store 
 * @param {object} data 
 * @returns 
 */
export function setApplicationData(store, data) {
  let groups;
  if (data.groups) {
    groups = {};
    for (let group of data.groups) {
      if (group.id) { // shouldn't be necessary, but let's be defensive
        groups[group.id] = group;
        groups[group.id].snippets = data.snippets.filter((snippet) => snippet.group_id === group.id);
      }
    }
  }
  store.dispatch({
    type: 'DATA_UPDATED',
    data: {
      snippets: data.snippets,
      groups,
      groupIds: data.groups.map(({ id }) => id),
    }
  });
  if (data.org) {
    store.dispatch({
      type: 'ORG_UPDATE',
      data: data.org
    });
  }
  if (data.config) {
    store.dispatch({
      type: 'NEW_CONFIG',
      data: data.config
    });
  }
  const collapsed = JSON.parse(localStorage.getItem(FOLDER_COLLAPSE_KEY)) || {};
  store.dispatch({
    type: 'UPDATE_COLLAPSED',
    collapsed: Object.keys(groups).reduce((prev, id) => {
      prev[id] =  collapsed[id] || false;
      return prev;
    }, {})
  });
  return data;
}