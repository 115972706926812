import TypedContainer from './typedContainer';
/**
 * @extends TypedContainer<import('./tableCell').default, import('./tableBody').default>
 */
class TableRow extends TypedContainer {
  checkMerge() {
    const nextChildren = /** @type {import('parchment').LinkedList<import('./tableCell').default>} */ (this.next.children),
      nextHeadChild = nextChildren.head;
    if (super.checkMerge() && this.next.children.head != null) {
      const children = /** @type {typeof nextChildren} */ (this.children),
        headChild = children.head,
        tailChild = children.tail,
        nextTailChild = nextChildren.tail;
      const thisHead = headChild.formats();
      const thisTail = tailChild.formats();
      const nextHead = nextHeadChild.formats();
      const nextTail = nextTailChild.formats();

      return (
        thisHead.row === thisTail.row &&
        thisHead.row === nextHead.row &&
        thisHead.row === nextTail.row
      );
    }
    return false;
  }

  static create(value) {
    const node = /** @type {HTMLTableRowElement} */ (super.create(value));
    if (value.row) {
      node.setAttribute('data-row', value.row);
    }
    return node;
  }

  /**
   * 
   * @returns {{[key: string]: string}}
   */
  formats() {
    return ['row'].reduce((formats, attrName) => {
      if (this.domNode.hasAttribute(`data-${attrName}`)) {
        formats[attrName] = this.domNode.getAttribute(`data-${attrName}`);
      }
      return formats;
    }, {});
  }

  format(key, value) {
    this.domNode.setAttribute(`data-${key}`, value);
  }

  optimize (context) {
    super.optimize(context);
    const children = /** @type {import('parchment').LinkedList<import('./tableCell').default>} */ (this.children);
    children.forEach(child => {
      const nextChild = /** @type {typeof child} */ (child.next);
      if (nextChild == null) {
        return;
      }
      const childFormats = child.formats();
      const nextFormats = nextChild.formats();
      if (childFormats.row !== nextFormats.row) {
        const next = this.splitAfter(child);
        if (next) {
          next.optimize({});
        }
        // We might be able to merge with prev now
        if (this.prev) {
          this.prev.optimize();
        }
      }
    });
  }

  /**
   * @returns {number}
   */
  rowOffset() {
    if (this.parent) {
      return this.parent.children.indexOf(this);
    }
    return -1;
  }

  table() {
    return this.parent && this.parent.parent;
  }


  update(mutations, context) {
    
    super.update(mutations, context);
  }
}
TableRow.blotName = 'table-row';
TableRow.tagName = 'TR';

export default TableRow;