import { isExtension } from '../../extension_utilities';
import { findType, sEqn } from '../../snippet_processor/Equation';
import { parse } from '../../snippet_processor/Parser';
import { Environment, MAX_LOCATION_DEPTH } from '../../snippet_processor/DataContainer';
import { createDom } from '../../snippet_processor/SnippetProcessor';
import { decompressDelta } from '../../delta_proto/DeltaProto';
import equals from 'fast-deep-equal';
import ParseNode from '../../snippet_processor/ParseNode';
import { ParseError } from '../../snippet_processor/ParserUtils';
import { getStaticDomain, getSiteSummary, getQuerySkeleton } from '../../snippet_processor/Commands';
import { astSQL } from '../../snippet_processor/SQL';
import { pickLimitations, limitationsState, usageCount, useFeature, } from './usageLimitations';


/**
 * @param {import('@store').RootState} state
 * 
 * @return {number}
 **/
export function enabledSnippetsCount(state) {
  let count = 0;
  if (!(state.userState && state.userState.groups && state.dataState && state.dataState.groups)) {
    return count;
  }

  let enabledGroups = Object.keys(state.userState.groups).map(id => ({
    id,
    userInfo: state.userState.groups[id],
    data: state.dataState.groups[id]
  })).filter(x => !x.userInfo.disabled && x.data);
  
  for (let group of enabledGroups) {
    count += group.data.snippets.length;
  }

  return count;
}

/**
 * @param {import("../../snippet_processor/ParseNode").default} tree
 * @param {Environment} env
 */
export async function proUsage(tree, env) {
  let features = await featureUsage(tree, env);
  let proLabels = [], businessLabels = [];

  if (features['TABLE']) {
    proLabels.push('tables');
  }

  if (features['IMAGE']) {
    proLabels.push('images');
  }
  
  if (features['FORM']) {
    proLabels.push('forms');
  }

  if (features['DYNAMIC']) {
    proLabels.push('dynamic logic');
  }

  if (features['ADDON']) {
    proLabels.push('command packs');
  }

  if (features['REMOTE']) {
    // 'dbinsert' is not a pro feature so we don't flag it here
    if (['urlsend', 'urlload', 'dbselect', 'dbupdate', 'dbdelete'].find(command => features.COMMANDS.includes(command))) {
      proLabels.push('load/send data');
    }
  }

  if (features['FOLLOWUP']) {
    proLabels.push('followups');
  }

  if (features['USER']) {
    businessLabels.push('user properties');
  }

  return {
    features,
    proLabels: proLabels.length ? proLabels : undefined,
    businessLabels: businessLabels.length ? businessLabels : undefined
  };
};


/**
 * @typedef {object} FeaturesUsageType
 * @property {string[]} COMMANDS
 * @property {string[]} LAMBDAS
 * @property {Object<string, {id: string, name: string}>} MISSING_ADDONS
 * @property {Awaited<ReturnType<getSiteSummary>>[]} SITE_SELECTORS
 * @property {boolean} [ADDON]
 * @property {boolean} [IMAGE]
 * @property {boolean} [TABLE]
 * @property {boolean} [DYNAMIC]
 * @property {boolean} [FORM]
 * @property {boolean} [STYLED]
 * @property {boolean} [CONNECTED]
 * @property {boolean} [AUTOPILOT]
 * @property {boolean} [SITE]
 * @property {boolean} [CLIPBOARD]
 * @property {boolean} [SNIPPET]
 * @property {boolean} [FOLLOWUP]
 * @property {boolean} [USER]
 * @property {boolean} [REMOTE]
 * @property {boolean} [REMOTE_LOAD]
 * @property {boolean} [IMAGE]
 * @property {boolean} [BUTTON]
 * @property {boolean} [RUN]
 * @property {number} [LENGTH]
 * @property {string[]} [PING_HOSTS]
 * @property {string[]} [LOAD_HOSTS]
 * @property {string[]} [CONNECTED_ADDONS]
 * @property {Object<string, string[]>} [DATABASES]
 * 
 * Note this doesn't include the contents of {imports} or add-ons (unless
 * they are already in the tree).
 * 
 * @param {import("../../snippet_processor/ParseNode").default} tree
 * @param {Environment} env
 * @param {FeaturesUsageType} features
 */
export async function featureUsage(tree, env, features = {
  COMMANDS: [],
  LAMBDAS: [],
  MISSING_ADDONS: {},
  SITE_SELECTORS: [],
}, depth = 0) {
  if (depth > MAX_LOCATION_DEPTH) {
    // Bail out if there is a high recursion depth.
    // See DataContainer.js for more discussion.
    return features;
  }
  
  if (env.config.missingAddons) {
    for (let key in env.config.missingAddons) {
      features.MISSING_ADDONS[key] = env.config.missingAddons[key];
    }
  }

  if (tree.hadImport) {
    if (!features.COMMANDS.includes('import')) {
      features.COMMANDS.push('import');
    }
  }

  async function processAst(ast) {
    if (ast.type === 'lambda') {
      // we save the list of lambdas that are processed
      let stringified = sEqn(ast);
      if (!features.LAMBDAS.includes(stringified)) {
        features.LAMBDAS.push(stringified);
      }
    }

    let commands = findType(ast, 'command');
    for (let command of commands) {
      let c = command.info;
      let txt = c[1] === '=' ? '=' : c.slice(1, Math.min(c.indexOf(':'), c.indexOf('}') - 1));
      if (!features.COMMANDS.includes(txt)) {
        features.COMMANDS.push(txt);
      }

      let isAddon = txt.match(/^\w+-\w+$/);
      if (isAddon) {
        features['ADDON'] = true;
      }

      let dom = await parse({ ops:[{ insert: c }] }, env);
      await featureUsage(dom, env, features, depth + 1);
    }
  }

  if (!(tree instanceof ParseNode)) {
    await processAst(tree);
  } else {
    let len = 0;
    await tree.asyncEach(async node => {
      if (node.tag === 'text') {
        len += node.info.message.length;
      } else if (node.type === 'el') {
        if (node.tag === 'img') {
          features['IMAGE'] = true;
          // It's not technically a command, but we treat it the same.
          // This is important for things like analyzing if the snippet
          // can ping a remote url in addons which has privacy/security
          // implications.
          if (!features.COMMANDS.includes('image')) {
            features.COMMANDS.push('image');
          }  
        } else if (node.tag === 'table') {
          features['TABLE'] = true;
        }
      } else if (node.type === 'expand') {
        // Keep a command list
        if (node.info && (node.info.message === undefined)) {
          // We need to find any nested commands. These can be several layers
          // deep so we use recursion
          let attrs = node.info.attributes;
          if (attrs) {
            let positional = attrs.position;

            /**
             * @param {import('../../snippet_processor/ParserUtils').NodeAttribute} attribute
             */
            let processAttribute = async (attribute) => {
              if (attribute.type === 'lambda' || attribute.type === 'equation') {
                let ast;
                try {
                  ast = await attribute.ast(env);
                } catch (err) {
                  if (err instanceof ParseError) {
                    // It's invalid syntax, so no usage
                    return;
                  } else {
                    console.error(err.stack);
                    throw err;
                  }
                }
                await processAst(ast);
              } else if (attribute.type === 'bsql') {
                let ast;
                try {
                  ast = await astSQL(attribute.snippetText(), env);
                } catch (err) {
                  if (err instanceof ParseError) {
                    // It's invalid syntax, so no usage
                    return;
                  } else {
                    console.error(err.stack);
                    throw err;
                  }
                }
                await processAst(ast);
              } else {
                await attribute.doDom(env);
                if (attribute.dom) {
                  await Promise.all(attribute.dom.map(async part => {
                    if (typeof part === 'string') {
                      return;
                    }
                    return featureUsage(part.dom, env, features, depth + 1);
                  }));
                }
              }
            };

            for (let attribute of positional) {
              await processAttribute(attribute);
            }
            for (let key in attrs.keys) {
              await processAttribute(attrs.keys[key]);
            }
          }
          if (node.info.command) {
            if (!features.COMMANDS.includes(node.info.command)) {
              features.COMMANDS.push(node.info.command);
            }
          }
        }
        // Specific features
        if (node.tag === 'form') {
          if (['repeat_start', 'repeat_end', 'if_start', 'if_else', 'if_elseif', 'if_end'].includes(node.info.type)) {
            features['DYNAMIC'] = true;
          } else {
            features['FORM'] = true;
          }
        } else if (node.tag === 'calc') {
          features['DYNAMIC'] = true;
        } else if (node.tag === 'addon') {
          if (node.info.spec) {
            let txt = node.info.spec.commandName;
            if (!features.COMMANDS.includes(txt)) {
              features.COMMANDS.push(txt);
            }
          }
          features['ADDON'] = true;
          if (node.info.attributes) {
            let commandName = node.info.attributes.spec.commandName;
            if (commandName && env.config.addons && env.config.addons[commandName]) {
              let addon = env.config.addons[commandName];
              if (addon.addon && addon.addon.addonOptions && addon.addon.addonOptions.connected) {
                features['CONNECTED'] = true;

                if (!features['CONNECTED_ADDONS'])  {
                  features['CONNECTED_ADDONS'] = [];
                }
                if (!features['CONNECTED_ADDONS'].includes(commandName)) {
                  features['CONNECTED_ADDONS'].push(commandName);
                }
              }
            }
          }
        } else if (node.tag === 'key' || node.tag === 'click') {
          features['AUTOPILOT'] = true;
        } else if (node.tag === 'site') {
          features['SITE'] = true;
          try {
            let req = await getSiteSummary(node.info.attributes, env);

            let existing = false;
            
            // dedupe
            for (let current of features.SITE_SELECTORS) {
              if (equals(current, req)) {
                existing = true;
                break;
              }
            }
          
            if (!existing) {
              features.SITE_SELECTORS.push(req);
            }
          } catch (_err) {
            // if it's an invalid site selector or the like, ignore it
          }
        } else if (node.tag === 'clipboard') {
          features['CLIPBOARD'] = true;
        } else if (node.tag === 'snippet') {
          features['SNIPPET'] = true;
        } else if (node.tag === 'followup') {
          features['FOLLOWUP'] = true;
        } else if (node.tag === 'user') {
          features['USER'] = true;
        } else if (['remote'].includes(node.tag)) {
          features['REMOTE'] = true;
          features['CONNECTED'] = true;
          if (node.info.type.endsWith('_load') || node.info.type === 'bsql') {
            features['REMOTE_LOAD'] = true;
          }
        } else if (node.tag === 'image') {
          features['CONNECTED'] = true;
          features['IMAGE'] = true;
        } else if (node.tag === 'button') {
          features['BUTTON'] = true;
        } else if (node.tag === 'run') {
          features.RUN = true;
        }
        
      
        if (node.tag === 'remote' && ['dbselect', 'dbupdate', 'dbdelete', 'dbinsert'].includes(node.info.command)) {
          features['REMOTE'] = true;
          if (node.info.command === 'dbselect') {
            features['REMOTE_LOAD'] = true;
          }

          let databaseId = node.info.attributes.keys['space'].snippetText().trim();
          let querySkeleton = getQuerySkeleton(node.info.attributes.position[0]);
          if (!features['DATABASES'])  {
            features['DATABASES'] = {};
          }
          if (!features['DATABASES'][databaseId]) {
            features['DATABASES'][databaseId] = [];
          }
          features['DATABASES'][databaseId].push(querySkeleton);
        } else if (node.tag === 'remote' || node.tag === 'image') {
          let domain = getStaticDomain(node.info.attributes.position[0]);
          if (domain) {
            if (!features['PING_HOSTS'])  {
              features['PING_HOSTS'] = [];
            }
            if (!features['LOAD_HOSTS'])  {
              features['LOAD_HOSTS'] = [];
            }

            if (node.tag === 'remote' && node.info.type.endsWith('_load')) {
              if (!features['LOAD_HOSTS'].includes(domain)) {
                features['LOAD_HOSTS'].push(domain);
              }
            } else {
              if (!features['PING_HOSTS'].includes(domain)) {
                features['PING_HOSTS'].push(domain);
              }
            }
          }
        }
      } else if (node.type === 'error') {
        if (node.info.node) {
          const txt = node.info.node.info.command;
          // In order to handle parsing of commands having parse error correctly in form snippets
          if (!features.COMMANDS.includes(txt)) {
            features.COMMANDS.push(txt);
          }
        }
      }
    });

    // the outer length will be used
    features['LENGTH'] = len;

    // the outer style will be used
    features['STYLED'] = tree.isStyled;
  }

  // remove duplicates
  for (const databaseId in features.DATABASES) {
    features.DATABASES[databaseId] = [...new Set(features.DATABASES[databaseId])];
  }

  return features;
};


/**
 * @param {import("../../snippet_processor/ParseNode").default} tree
 * 
 * @return {"*"|string[]} an array of hosts used in the snippet. Return '*' if
 * the snippet can connect to arbitrary hosts (the host part of the url is dynamic)
 */
export function remoteHostUsage(tree) {
  if (!(tree instanceof ParseNode)) {
    return []; // AST's can't trigger any remote requests
  }

  let urls = [];
  tree.each(node => {
    if (node.type === 'el') {
      if (node.tag === 'img') {
        urls.push(node.attrs.src);
      }
    } else if (node.type === 'expand') {
      // Note the {urlload}, {urlsend}, and {image} can't be nested
      // so we don't need any recursion.
      if (['remote', 'image'].includes(node.tag)) {
        urls.push(node.info.attributes.position[0].snippetText());
      }
    }
  });

  /** @type {string[]} */
  let hosts = [];

  for (let url of urls) {
    let data;
    try {
      data = new URL(url);
    } catch (_e) {
      // URL parsing will fail if we have a command
      // in the protocol part of the url for instance.
      // Err on the side of caution if we can't determine
      // the host precisely.
      return '*';
    }
    if (data.origin.includes('{') || data.origin.includes('}')
      || data.username.includes('{') || data.username.includes('}')
      || data.password.includes('{') || data.password.includes('}')
      || data.port.includes('{') || data.port.includes('}')
      || data.protocol.includes('{') || data.protocol.includes('}')) {
      // It has a dynamic command and we can't
      // statically analyze it
      return '*';
    }
    hosts.push(data.hostname.toLowerCase());
  }

  // dedupe any hosts
  return removeUnnecessarySubdomains([...new Set(hosts)]);
};


/**
 * @param {SnippetObjectType} addon
 * 
 * @return {Promise<GrantsType>}
 */
export async function getAddonGrants(addon) {
  let dom = await createDom(addon.content.delta.ops ? addon.content.delta : decompressDelta(addon.content.delta), new Environment({}, { type: 'text', stage: 'tokenization' }));

  let usage = await featureUsage(dom, new Environment(null, { stage: 'tokenization' }));

  /** @type {string[]|'*'} */
  let allowedHosts = (addon.options && addon.options.addon && addon.options.addon.display && addon.options.addon.display.valid_hosts && addon.options.addon.display.valid_hosts.length) ? addon.options.addon.display.valid_hosts : '*';

  return {
    remote_hosts: removeUnnecessarySubdomains(remoteHostUsage(dom)),
    site_hosts: usage['SITE'] ? removeUnnecessarySubdomains(allowedHosts) : [],
    action_hosts: usage['AUTOPILOT'] ? removeUnnecessarySubdomains(allowedHosts) : [],
    clipboard: !!usage['CLIPBOARD'],
    user: !!usage['USER'],
    snippet: !!usage['SNIPPET'],
    commands: usage['COMMANDS']
  };
}


/**
 * @param {(string[]|"*")} arr
 * 
 * @return {(string[]|"*")} arr
 */
function removeUnnecessarySubdomains(arr) {
  if (arr === '*') {
    return '*';
  }
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr.length; j++) {
      if (i === j) {
        continue;
      }
      if (arr[j].endsWith('.' + arr[i])) {
        arr.splice(j, 1);
        j--;
      } else if (arr[i].endsWith('.' + arr[j])) {
        arr.splice(i, 1);
        i--;
        break;
      }
    }
  }
  return arr;
};


/**
 * @param {GrantsType[]} grants
 * 
 * @return {GrantsType}
 */
export function mergeGrants(grants) {
  let mergeAndDedupe = (a, b) => {
    if (a === '*' || b === '*') {
      return '*';
    }
    return [...new Set(a.concat(b))];
  };

  let permission = Object.assign({}, grants.pop());
  for (let perm of grants) {
    permission.remote_hosts = mergeAndDedupe(permission.remote_hosts, perm.remote_hosts);
    permission.site_hosts = mergeAndDedupe(permission.site_hosts, perm.site_hosts);
    permission.action_hosts = mergeAndDedupe(permission.action_hosts, perm.action_hosts);
    permission.clipboard = permission.clipboard || perm.clipboard;
    permission.user = permission.user || perm.user;
    permission.snippet = permission.snippet || perm.snippet;
    permission.commands = [...new Set(permission.commands.concat(perm.commands))];
  }
  permission.remote_hosts = removeUnnecessarySubdomains(permission.remote_hosts);
  permission.action_hosts = removeUnnecessarySubdomains(permission.action_hosts);
  permission.site_hosts = removeUnnecessarySubdomains(permission.site_hosts);

  return permission;
}


/**
 * @param {string[]|"*"} base
 * @param {string[]|"*"} newArray
 * 
 * @return {string[]|"*"}
 */
function arrayExpansion(base, newArray) {
  if (!newArray) {
    // allow us to remove arrays in the future
    return null;
  }
  if (!base) {
    // allow us to add an array that wasn't defined before
    return newArray;
  }

  if (base === '*') {
    return null;
  }
  if (newArray === '*') {
    return '*';
  }
  let expanded = [];
  for (let newItem of newArray) {
    let found = false;
    for (let baseItem of base) {
      if (baseItem === newItem ||  newItem.endsWith('.' + baseItem)) {
        found = true;
        break;
      }
    }
    if (!found) {
      expanded.push(newItem);
    }
  }
  if (expanded.length) {
    return expanded;
  }
  return null;
}


/**
 * @param {GrantsType} base
 * @param {GrantsType} newGrants
 * 
 * @return {GrantsType}
 */
export function grantExpansion(base, newGrants) {
  let res = {
    clipboard: null,
    user: null,
    snippet: null,
    remote_hosts: null,
    site_hosts: null,
    action_hosts: null,
    commands: null
  };

  if (newGrants.clipboard && !base.clipboard) {
    res.clipboard = true;
  }
  if (newGrants.user && !base.user) {
    res.user = true;
  }
  if (newGrants.snippet && !base.snippet) {
    res.snippet = true;
  }

  let change = arrayExpansion(base.remote_hosts, newGrants.remote_hosts);
  if (change) {
    res.remote_hosts = change;
  }

  change = arrayExpansion(base.site_hosts, newGrants.site_hosts);
  if (change) {
    res.site_hosts = change;
  }

  change = arrayExpansion(base.action_hosts, newGrants.action_hosts);
  if (change) {
    res.action_hosts = change;
  }

  change = arrayExpansion(base.commands, newGrants.commands);
  if (change) {
    res.commands = change;
  }

  let changed = false;
  for (let key in res) {
    if (res[key] !== null) {
      changed = true;
    } else {
      delete res[key];
    }
  }

  if (!changed) {
    return null;
  }

  return res;
}


/**
 * @param {GrantsType} grants
 * 
 * @return {boolean}
 */
export function isNonsensitiveGrant(grants) {
  for (let key in grants) {
    if (['commands'].includes(key)) {
      // only flag sensitive commands which will be
      // listed as a separate grant
      continue;
    }

    let val = grants[key];
    if (!val) {
      continue;
    } else if (Array.isArray(val) && !val.length) {
      continue;
    } else {
      // The permissions grant something to be flagged
      return false;
    }
  }
  // Th permissions grant nothing to be flagged
  return true;
}


/**
* Confirms that a ParseNode is valid given a set of permission grants.
* 
* @param {string} addonNamespace
* @param {'LOCAL_DEVELOPMENT'|GrantsType} grants
* @param {import("../../snippet_processor/ParseNode").default} tree
* @param {Environment} env
* 
* @return {Promise<boolean>} - true if the permissions are satisfied
*/
export async function grantsSatisfied(addonNamespace, grants, tree, env) {
  let currentHost = env.config.domain;
  let usage = await featureUsage(tree, env);

  for (let command of usage.COMMANDS) {
    // even when doing local development we want to confirm
    // other packs' add-ons aren't used
    if (command.includes('-') && command.split('-')[0] !== addonNamespace) {
      return false; // cannot include other addon packs
    }
  }


  if (grants !== 'LOCAL_DEVELOPMENT') {
    if (!grants.clipboard && usage['CLIPBOARD']) {
      return false;
    }

    if (!grants.user && usage['USER']) {
      return false;
    }

    if (!grants.snippet && usage['SNIPPET']) {
      return false;
    }

    function hostSatisfied(host, hosts) {
      if (!hosts) {
        return false;
      }
      for (let h of hosts) {
        if (h === host || host.endsWith('.' + h)) {
          return true;
        }
      }
      return false;
    };

    if (grants.remote_hosts !== '*') {
      let used = remoteHostUsage(tree);
      if (used) {
        if (used === '*') {
          return false;
        } else {
          for (let u of used) {
            if (!hostSatisfied(u, grants.remote_hosts)) {
              return false;
            }
          }
        }
      }
    }
 
    if (grants.site_hosts !== '*') {
      if (usage.COMMANDS.includes('site') && !hostSatisfied(currentHost, grants.site_hosts)) {
        return false;
      }
    }

    if (grants.action_hosts !== '*') {
      if ((usage.COMMANDS.includes('key') || usage.COMMANDS.includes('click')) && !hostSatisfied(currentHost, grants.action_hosts)) {
        return false;
      }
    }

    // TODO remove this grants.command block once the updated extension has
    // rolled out. It's unnecessary, given the other grants. Note a few
    // SnippetProcessor tests should be removed when we do.
    if (!isExtension()) {
      if (grants.commands) {
        let usedCommands = usage.COMMANDS;
        for (let command of usedCommands) {
          if (!grants.commands.includes(command)) {
            let isSameAddonPack = command.includes('-') && command.split('-')[0] === addonNamespace;
            // can include commands from the same addon pack without explicit permission
            if (!isSameAddonPack) {
              return false;
            }
          }
        }
      }
    }
  }

  return true;
}

export { useFeature, pickLimitations, limitationsState, usageCount, };