import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Button,
  IconButton,
  Radio,
  TextField,
  Typography as T
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import AddChoiceIcon from '@mui/icons-material/PlaylistAdd';
import { EmptyState } from '../EmptyState/EmptyState';

/**
 * @param {object} props 
 * @param {{selected: boolean, value: string}[]} props.items
 * @param {function} props.onChange
 * @param {("small"|"default")=} props.size
 */
function DataMenuList(props) {
  let small = props.size === 'small';
  let items = props.items;

  return <div style={{ marginTop: 20, marginBottom: 5 }}>
    { items.length ?
      <table 
        style={{
          width: '100%'
        }}
      >
        <thead>
          <tr>
            <td style={{ paddingLeft: 24 }}><T variant="body2" color="textSecondary" style={{ textAlign: 'left' }}>Default</T></td>
            <td><T variant="body2" color="textSecondary" style={{ textAlign: 'center' }}>Label</T></td>
            <td></td>
          </tr>
        </thead>
        <DragDropContext onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }   
            
          let si = result.source.index;
          let di = result.destination.index;
          const newItems = items.map(x => Object.assign({}, x));
          let moved = newItems[si];

          newItems.splice(si, 1);
          newItems.splice(di, 0, moved);
          props.onChange(newItems);
        }}>
          <Droppable droppableId="attribute_droppable_list_popup" direction="vertical">
            {(provided,) => (

              <tbody 
                ref={provided.innerRef}
                {...provided.droppableProps}>
                {items.map((x, i) => (<Draggable
                  key={i}
                  draggableId={'popup_' + i}
                  index={i}>
                  {(provided) => (<tr 
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={Object.assign({ marginBottom: 6 }, provided.draggableProps.style)} key={i}>
                    <td
                      style={{
                        paddingLeft: 24
                      }}
                    >
                      <Radio
                        title="Default"
                        name="docs-radio-regular"
                        size={small ? 'small' : undefined}
                        checked={!!(x.selected || false)}
                        onChange={_e => {
                          const newItems = items.map(x => Object.assign({}, x));
                          newItems.forEach((item, ind) => item.selected = i === ind);
                          props.onChange(newItems);
                        }}
                      />
                    </td>
                    <td>
                      <TextField
                        value={x.value}
                        variant={small ? 'standard' : 'outlined'}
                        placeholder="choice text"
                        style={{ width: '100%' }}
                        size="small"
                        onChange={e => {
                          let val = e.target.value;
                          const newItems = items.map(x => Object.assign({}, x));
                          newItems[i].value = val;
                          props.onChange(newItems);
                        }}
                      />
                    </td>
                    <td style={{
                      paddingRight: 24
                    }}>
                      <IconButton
                        title="Remove"
                        size={small ? 'small' : undefined}
                        style={{
                          opacity: 0.7,
                          marginLeft: 6
                        }}
                        onClick={() => {
                          const newItems = items.map(x => Object.assign({}, x));
                          newItems.splice(i, 1);
                          props.onChange(newItems);
                        }}
                      >
                        <RemoveIcon
                          fontSize={small ? 'small' : undefined}
                        />
                      </IconButton>
                    </td>
                  </tr>)}
                </Draggable>))}
                    

                {provided.placeholder}
              </tbody>)}

          </Droppable>

        </DragDropContext>
      </table> : small ? <T variant="subtitle1" color="textSecondary" style={{ marginBottom: 20, textAlign: 'center' }}>No choices configured</T> : <div style={{ marginBottom: 24 }}><EmptyState
        icon={AddChoiceIcon}
        title="No choices configured"
      /></div>}

    <div style={{ textAlign: 'right', marginBottom: 15, marginTop: 10, marginRight: 24 }}>
      <Button
        variant="outlined"
        size={small ? 'small' : undefined}
        onClick={() => {
          const newItems = items.map(x => Object.assign({}, x));
          newItems.push({ selected: false, value: 'New Choice' });
          props.onChange(newItems);
        }}
        startIcon={<AddIcon style={{ opacity: .5 }} />}
      >Add choice</Button>
    </div>
  </div>;
};

export default DataMenuList;