import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import T from '@mui/material/Typography';
import { changeCustomerDescription } from '../../bapi';
import { useTypedSelector } from '../../hooks';
import { orgId } from '../../flags';
import AsyncButton from '../AsyncButton/AsyncButton';
import { toast } from '../../message';
import MenuItem from '@mui/material/MenuItem';


/**
 * @param {object} props
 * @param {object} props.billingDetails
 * @param {function} props.onClose
 * @param {function} props.onUpdate
 */
function BillingDescriptionBase({ billingDetails, onClose, onUpdate }, ref) {
  const [isOpen, setOpen] = useState(false);

  return <>
    <MenuItem
      ref={ref}
      onClick={async () => {
        setOpen(true);
        onClose();
      }}
    >Invoice description</MenuItem>
    {isOpen && <DescriptionDialog
      defaultDescription={billingDetails.description}
      isOpen
      onClose={(updated) => {
        setOpen(false);
        if (updated) {
          onUpdate();
        }
      }}/>}
  </>;
}


/**
 * @param {object} props
 * @param {string=} props.defaultDescription
 * @param {boolean} props.isOpen
 * @param {function} props.onClose
 */
function DescriptionDialog({ defaultDescription, isOpen, onClose }) {
  const initDescription = defaultDescription || ''; // default description may be null
  const [description, setDescription] = useState(initDescription);
  const organizationId = useTypedSelector(store => orgId(store));
  const [isSaving, setSaving] = useState(false);

  async function onSave(done) {
    if (description.length > 250) {
      toast('The description can be at most 250 characters long.', {
        duration: 4000,
        intent: 'danger'
      });
      return;
    }

    try {
      setSaving(true);
      await changeCustomerDescription({
        description,
        ...(organizationId ? {
          type: 'org',
          orgId: organizationId
        } : {})
      });
      toast('Billing description changed successfully', {
        duration: 5000,
        intent: 'success'
      });
      setSaving(false);
      done();
      onClose(true);
    } catch (e) {
      toast('Unable to change the description. Please try again and contact support@blaze.today if it does not work.', {
        duration: 8000,
        intent: 'danger'
      });
      console.error(e);
      done();
      setSaving(false);
    }
  }

  function onCancel() {
    if (!isSaving) {
      onClose();
      setDescription(defaultDescription);
    }
  }

  return <Dialog
    open={isOpen}
    onClose={onCancel}
    maxWidth="sm"
    BackdropProps={{
      style: {
        opacity: 0.18
      }
    }}
  >
    <DialogTitle>Invoice description</DialogTitle>
    <DialogContent>
      <T gutterBottom>Add business information like your company name or address to your invoice.</T>

      <TextField
        multiline // it's multiline, but Stripe will ignore that, still let's keep it for better UX (input value can be very long)
        disabled={isSaving}
        value={description}
        variant="outlined"
        placeholder="Acme Inc; 123 Main Street..."
        fullWidth
        size="small"
        onChange={e => setDescription(e.target.value)}
      />
    </DialogContent>
    <DialogActions style={{ padding: 16 }}>
      <div style={{ flex: 1 }}/>
      <Button onClick={onCancel}>Cancel</Button>
      <AsyncButton
        variant="outlined"
        color="primary"
        size="small"
        disabled={description === initDescription}
        onClick={(done) => onSave(done)}
        data-testid="billing-description-save"
      >Save</AsyncButton>
    </DialogActions>
  </Dialog>;
}


const BillingDescription = React.forwardRef(BillingDescriptionBase);
export default BillingDescription;