import React from 'react';
import DetailsCard from '../DetailsCard/DetailsCard';
import AsyncButton from '../AsyncButton/AsyncButton';
import Paper from '@mui/material/Paper';
import { joinOrganization } from '../../data';
import { useTypedSelectorShallowEquals } from '../../hooks';
import T from '@mui/material/Typography';
import { orgId } from '../../flags';
import VerificationReminder from '../VerificationReminder/VerificationReminder';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import EmailMismatchReminder from '../EmailMismatch/EmailMismatch';


export default function JoinOrg() {
  const { id: orgIdParam } = /** @type {{ id: string }} */ (useParams());
  const { push: navigate } = useHistory();
  const { search: urlSearch } = useLocation();
  const intendedRecipient = (new URLSearchParams(urlSearch)).get('recipient');
  let {
    existingOrgId,
    emailVerified,
    email
  } = useTypedSelectorShallowEquals((store) => {
    return {
      emailVerified: store.userState && store.userState.emailVerified,
      existingOrgId: orgId(store),
      email: store.userState.email
    };
  });

  let base = <>
    <T paragraph>Do you want to join the organization?</T>
    <div style={{ textAlign: 'right' }}>
      <AsyncButton
        variant="contained"
        color="primary"
        onClick={(done) => {
          joinOrganization(orgIdParam).then(() => {
            done();
            navigate('/business');
          }).catch(() => done());
        }}
      >
          Join organization
      </AsyncButton>
    </div>
  </>;

  let inner;
  if (!emailVerified) {
    inner = <VerificationReminder reason="you will not be able to join this organization" />;
  } else if (existingOrgId === orgIdParam) {
    inner = <T paragraph>You are already a member of this organization and do not need to do anything.</T>;
  } else if (existingOrgId) {
    inner = <>
      <T paragraph>You are already a member of another organization. You must leave your current organization before joining this one. You can do so <Link to="/configure/account">here</Link>.</T>
    </>;
  } else if (intendedRecipient && intendedRecipient !== email) {
    inner = <EmailMismatchReminder expectedEmail={intendedRecipient} actualEmail={email} type="org" />;
  } else {
    inner = base;
  }

  return (
    <DetailsCard>
      <Paper style={{ marginTop: 50, padding: 20 }} elevation={3}>
        <T paragraph>You have been invited to join an organization as part of Text Blaze Business. If you accept this invitation, the organization will be able to manage your account.</T>
        
        {inner}
      </Paper>
    </DetailsCard>
  );
}
