import React, { useEffect, useState } from 'react';
import AsyncButton from '../AsyncButton/AsyncButton';
import { getData } from '../DataDialog/DataDialog';
import { changeEmailConfig } from '../DataDialog/dialogConfigs';
import { toast, showConfirm } from '../../message';
import { store } from '@store';
import {
  getAuth,
  signOut,
  reauthenticateWithCredential,
  EmailAuthProvider,
  GoogleAuthProvider,
  verifyBeforeUpdateEmail,
  reauthenticateWithPopup
} from 'firebase/auth';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { log } from '../../logging/logging';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import T from '@mui/material/Typography';
import { isSamlUser } from '../Auth/saml_auth';
import { isValidEmail } from '../Auth/auth_utilities';
import { signoutLocalApp, beforeSignout, sendSignoutMessages } from '../../signout';
import { isElectronApp } from '../../flags';
import { generateAppRoute } from '../../hooks/useAppNavigate';

const token = new URLSearchParams(window.location.search).get('token');
export function ChangeEmail() {
  // Assuming it is saml user. So that we don't show links and hide them later
  // Hiding and then showing it with a delay seems better
  const [isSaml, setIsSaml] = useState(true);
  let {
    isPassword,
    email,
    firebaseUser,
    gsuitePrimaryEmail
  } = useTypedSelectorShallowEquals((store) => {
    let isPassword = !!(store.userState.providerData && !store.userState.providerData.find(x => x.providerId !== 'password'));
    let gsuitePrimaryEmail;
    if (!isPassword) {
      let gsuiteProvider = store.userState.providerData.find(x => x.providerId === 'google.com');
      if (gsuiteProvider) {
        gsuitePrimaryEmail = gsuiteProvider.email;
      }
    }
    return {
      isPassword,
      email: store.userState.email.toLowerCase(),
      firebaseUser: store.userState.firebaseUser,
      gsuitePrimaryEmail
    };
  });

  useEffect(() => {
    //If its not password and change in gsuite email. Not required to calculate
    if (!isPassword
      && (
        !gsuitePrimaryEmail
        || gsuitePrimaryEmail === email
      )
    ) {
      return;
    }
    (async () => {
      setIsSaml(await isSamlUser(email));
    })();
  }, [email, isPassword, gsuitePrimaryEmail]);
  if (isSaml) {
    return null;
  } else if (isPassword) {
    return (
      <AsyncButton
        size="small"
        href="#changeemail"
        onClick={(done) => {
          getData(Object.assign({}, changeEmailConfig, {
            onCancel: done,
          })).then((data) => {
            let newEmail = data[0].value;
            let password = data[2].value;
            if (newEmail !== data[1].value) {
              toast('The new emails do not match. Please try again.', {
                duration: 4000,
                intent: 'danger'
              });
              done();
              return;
            }
            if (!newEmail || !password) {
              toast('You must enter a new email address and your existing password.', {
                duration: 4000,
                intent: 'danger'
              });
              done();
              return;
            }
            if (!isValidEmail(newEmail)) {
              toast(`(${newEmail}) is not a valid email address.`, {
                duration: 4000,
                intent: 'danger'
              });
              done();
              return;
            }
            reauthenticateWithCredential(firebaseUser, EmailAuthProvider.credential(
              email,
              password
            )).then(() => {
              verifyBeforeUpdateEmail(firebaseUser, newEmail).then(async () => {
                done();
               
                toast(`A verification email has been sent to (${newEmail}). You will need to click the link in that new email and then sign back into Text Blaze to finalize the email change. Until you do so, your account will use your old email address.`, {
                  duration: 20000,
                  intent: 'success'
                });
                log({
                  action: 'Change Email'
                });

                // if we don't sign out, our firebase email won't update
                // until the user reloads the page
                await beforeSignout();
                await signOut(getAuth());
                store.dispatch({
                  type: 'USER_LOGIN',
                  user: {
                    isLoaded: true
                  }
                });

                sendSignoutMessages();
              }).catch((error) => {
                toast(`Failed to update email. ${error}`, {
                  duration: 4000,
                  intent: 'danger'
                });
                done();
              });
            }).catch((error) => {
              toast(`Failed to update email. Check that your password is correct. ${error}`, {
                duration: 4000,
                intent: 'danger'
              });
              done();
            });
          });
        }}
      >Change account email address</AsyncButton>
    );
  } else if (gsuitePrimaryEmail && gsuitePrimaryEmail !== email) {
    return <Alert severity="info">
      <AlertTitle>Update email address</AlertTitle>
      <T paragraph>You are using Google Login and it appears that Google has a newer email address for you (<b>{gsuitePrimaryEmail}</b>). Do you want to change your Text Blaze email address to that?</T>
      <T>If you change your email, you will lose access to any resources shared with {email}.</T>
      <div style={{ textAlign: 'right', marginTop: 12 }}>
        <AsyncButton
          href="#changeemail"
          variant="outlined"
          onClick={(done) => {
            if (isElectronApp()) {
              showConfirm({
                contents: <T>Please change your email address from the browser</T>,
                confirmButtonText: 'Redirect to browser',
                onCancel: done,
                onConfirm: () => {
                  window.open(generateAppRoute('/configure/account#changeemail', 'TEXT').url, '_blank');
                  done();
                }
              });
              return;
            }
            
            showConfirm({
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Change email',
              intent: 'danger',
              contents: <>
                <T paragraph>Are you sure you want to change your email address to <b>{gsuitePrimaryEmail}</b>?</T>
                <T paragraph><b>You will no longer have access to any resources shared with {email} and they will need to be shared with your new email address.</b></T>
                <T paragraph>We will also log you out of Text Blaze and you will need to log in again.</T>
              </>,
              onCancel: () => {
                done();
              },
              onConfirm: () => {
                reauthenticateWithPopup(firebaseUser, new GoogleAuthProvider()).then(() => {
                  verifyBeforeUpdateEmail(firebaseUser, gsuitePrimaryEmail).then(async () => {
                    done();

                    toast(`A verification email has been sent to (${gsuitePrimaryEmail}). You will need to click the link in that new email and then sign back into Text Blaze to finalize the change.`, {
                      duration: 12000,
                      intent: 'success'
                    });
              
                    log({
                      action: 'Change Email to Gsuite Primary'
                    });

                    log({
                      action: 'Logout'
                    });

                    // log us out
                    await signOut(getAuth());
                    store.dispatch({
                      type: 'USER_LOGIN',
                      user: {
                        isLoaded: true
                      }
                    });

                    sendSignoutMessages();
                    await signoutLocalApp(email, token);
                  }).catch((error) => {
                    toast(`Failed to update email. ${error}`, {
                      duration: 4000,
                      intent: 'danger'
                    });
                    done();
                  });
                }).catch((error) => {
                  toast(`Failed to update email. ${error}`, {
                    duration: 4000,
                    intent: 'danger'
                  });
                  done();
                });
              }
            });
          }}
        >
          Change email address
        </AsyncButton>
      </div>
    </Alert>;
  } else {
    // if Gsuite and the correct email, don't show anything
    return null;
  }
}