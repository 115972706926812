import { grey, amber } from '@mui/material/colors';

export const proColor = grey[600];
export const proBackground = grey[200];
export const notProColor = grey[600];
export const notProBackground = grey[200];

export const betaColor = amber[800];
export const betaBackground = amber[600];


export const PRICES = {
  'pro': {
    'monthly': 3.49,
    'annually': {
      'edu': 0.89 * 12,
      'regular': 2.99 * 12
    },
    'deltaMonthly': 0.5
  },
  'business': {
    'monthly': 8.39,
    'annually': 6.99 * 12,
    'deltaMonthly': 1.4
  }
};