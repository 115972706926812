import { useMediaQuery } from '@mui/material';
import React, { createContext, useRef, useState } from 'react';
import { useIsMedium, useTypedSelector } from '../../hooks';
const MAX_SIDEBAR_SIZE_FOR_NOTLARGE = 330;

/**
 * @typedef {('text'|'constant'|'formula'|'sql'|'iterator'|'block')} QuillAttributeType
 * 
 * 
 * @typedef {object} SnippetWrapperContext
 * @property {boolean=} attributeFocused
 * @property {(focused: boolean) => any} setAttributeFocused
 * @property {boolean=} inCommandsPanel
 * @property {(focused: boolean) => any} setInCommandsPanel
 * @property {{[x: string]: ActiveAddonType;}} addons
 * @property {(addons: {[x: string]: ActiveAddonType;}) => any} setAddons
 * @property {Function} insertIntoAttribute
 * @property {(insert: Function) => any} setInsertIntoAttribute
 * @property {{type: QuillAttributeType, element: HTMLElement}} attribute
 * @property {(attribute: {type: QuillAttributeType, element: HTMLElement}) => any} setAttribute
 * @property {{attributeName: string, attributesPath: import('../SnippetEditor/editor_utilities').CollapsedDataType['meta']['attributesPath']}} subAttributeSelected
 * @property {(subAttribute: {attributeName: string, attributesPath: import('../SnippetEditor/editor_utilities').CollapsedDataType['meta']['attributesPath']}) => any} setSubAttributeSelected
 * 
 */

export const SnippetEditorContext = createContext(
  /** @type {SnippetWrapperContext} */ ({})
);

/**
 * 
 * @param {object} params
 * @param {import("react").ReactNode} params.children
 * @param {import("react").CSSProperties=} params.style
 */
const SnippetWrapperBase = ({
  style,
  children
}) => {
  const [attributeFocused, setAttributeFocused] = useState(false);
  const [inCommandsPanel, setInCommandsPanel] = useState(false);
  const [addons, setAddons] = useState(
    /** @type {{[x: string]: ActiveAddonType;}} */ ({})
  );
  const [subAttributeSelected, setSubAttributeSelected] = useState((null));
  const [attribute, setAttribute] = useState(/** @type {SnippetWrapperContext['attribute']} */ (null));
  const insertIntoAttribute = useRef(/** @type {Function} */ (null));
  let isNotLarge = useMediaQuery('(max-width:1050px)');
  const DEFAULT_SIDEBAR_SIZE = isNotLarge ? 260 : 300;
  let snippetSidebarSize = useTypedSelector(state => state.uiState?.snippetSidebarSize || DEFAULT_SIDEBAR_SIZE);
  let isMedium = useIsMedium();
  
  // If UI is not large, then usual size might not fit it. Lets resize to max allowed
  if (isNotLarge && snippetSidebarSize > MAX_SIDEBAR_SIZE_FOR_NOTLARGE) {
    snippetSidebarSize = MAX_SIDEBAR_SIZE_FOR_NOTLARGE;
  }
  return (
    <SnippetEditorContext.Provider value={{
      attributeFocused,
      setAttributeFocused,
      inCommandsPanel,
      setInCommandsPanel,
      addons,
      setAddons,
      insertIntoAttribute: insertIntoAttribute.current,
      setInsertIntoAttribute: (insert) => insertIntoAttribute.current = insert,
      attribute,
      setAttribute,
      subAttributeSelected,
      setSubAttributeSelected
    }}>
      <div
        style={{
          display: (!isMedium) ? 'grid' : 'flex',
          flexDirection: (!isMedium) ? undefined : 'column',
          flex: 1,
          gridTemplateColumns: `[main-start] 1fr [sidebar-start] minmax(235px, ${snippetSidebarSize}px) [sidebar-end]`,
          // 100px is same as offset for Resizer in dashboard/src/js/components/SnippetEditor/BottomToolbar.js
          gridTemplateRows: '[main-start] 100px min-content [editor-start] 1fr [editor-end]',
          ...style
        }}
        className="snippet-wrapper"
      >
        {children}
      </div>
    </SnippetEditorContext.Provider>
  );
};


const SnippetWrapper = React.memo(SnippetWrapperBase);
export default SnippetWrapper;