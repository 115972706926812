import React, { useRef, useState } from 'react';
import Box from '@mui/system/Box';
import T from '@mui/material/Typography';
import {
  DialogContent, DialogActions, Dialog, DialogTitle,
  TextField, Button,
  Alert, InputAdornment, Tooltip, CircularProgress
} from '@mui/material';
import AsyncButton from '../AsyncButton/AsyncButton';
import { getUserReferralCode, sendInviteReferralCredits } from '../../bapi';
import { toast } from '../../message';
import { useTypedSelector } from '../../hooks';
import { log } from '../../logging/logging';
import PersonIcon from '@mui/icons-material/Person';
import CopyIcon from '@mui/icons-material/FileCopy';
import { EmptyState } from '../EmptyState/EmptyState';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { CURRENT_PLATFORM, isElectronApp } from '../../flags';
import { prettyList } from '../../standalone_utilities';
import { isAiBlaze } from '../../aiBlaze';
import { fullAppName } from '../../raw_flags';

const REFERRAL_PATH = 'https://blaze.today/' + (isElectronApp() ? CURRENT_PLATFORM + '/' : '');

/**
 * @param {object} props
 * @param {boolean} [props.onboarding]
 * @param {boolean} props.open
 * @param {function} props.onClose
 */
function ReferOnboardingBase(props) {
  let [u1, setU1] = useState('');
  let [u2, setU2] = useState('');
  let [u3, setU3] = useState('');
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const gettingReferralCode = useRef(false);
  const referralCode = useTypedSelector(store => store.userState.referral_code);
  let referralUrl;

  /**
   * @type {string[]}
   */
  let invites = [];
  if (u1.trim()) {
    invites.push(u1.trim().toLowerCase());
  }

  if (u2.trim()) {
    invites.push(u2.trim().toLowerCase());
  }

  if (u3.trim()) {
    invites.push(u3.trim().toLowerCase());
  }

  invites = [...new Set(invites)].filter(x => x.includes('@'));

  function onInvite(done) {
    const uniqueEmails = [...new Set(invites)];
    const invalidEmails = uniqueEmails.filter(email => !isValidEmail(email));

    if (!!invalidEmails.length) {
      let msg = `${prettyList(invalidEmails.map(x => `"${x}"`))} ${invalidEmails.length > 1 ? 'are not valid emails' : 'is not a valid email'}.`;
      toast(msg, {
        intent: 'danger'
      });
      setShowError(true);
      done();
      return;
    } 
    if (!referralCode) {
      toast('Failed to send invites. Please try again later. ');
      return;
    }
    sendInviteReferralCredits({
      emails: invites,
      referral_code: referralCode + 'N',
      platform: isElectronApp() ? CURRENT_PLATFORM : ''
    }).then(function () {
      toast('Thank you for sharing!', { intent: 'success' });
      props.onClose();
    }).catch(function (error) {
      toast('Failed to send invites. Please try again later. ' + JSON.stringify(error), { intent: 'danger' });
    }).finally(function () {
      done();
    });

    log({
      action: 'Refer Onboarding'
    });
  }

  function renderInviteLink() {
    if (error) {
      return <div style={{ padding: 20 }}>
        <EmptyState
          icon="ERROR"
          title={error}
          description="Could not get invite link. Please try again later."
        />
      </div>;
    }

    if (!referralCode) {
      if (!gettingReferralCode.current) {
        gettingReferralCode.current = true;
        getUserReferralCode().catch(err => {
          log({ action: 'FAILED_GET_REFERRAL_CODE' });
          setError('' + err);
        });
      }

      return <div style={{ textAlign: 'center', padding: 20 }}>
        <CircularProgress
          size={40}
        />
      </div>;
    }

    // update the referralUrl
    const referralUrlPrefix = isAiBlaze ? `${REFERRAL_PATH}ai` : REFERRAL_PATH;
    referralUrl =  `${referralUrlPrefix}?ref=${referralCode}N`;

    return <>
      <T variant="body2" sx={{ mt: 3, mb: 2 }} color="textPrimary">
        You can also share this link to invite.
      </T>
      <TextField
        fullWidth
        value={referralUrl}
        disabled
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <CopyIcon
                onClick={async () => {
                  await navigator.clipboard.writeText(referralUrl);
                  toast('Copied referral link to your clipboard.');
                }}
              />
            </InputAdornment>
          )
        }}
      />
    </>;
  }

  return <Dialog
    disableEscapeKeyDown={props.onboarding}
    open={props.open}
    onClose={() => props.onClose()}
  >
    <DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
      {fullAppName} is better together
      {props.onboarding && <T style={{ flex: 1, textAlign: 'right', opacity: 0.6 }}>Step 2 of 2 </T>}
    </DialogTitle>
    <DialogContent>
      <T variant="body1" paragraph>
        Who do you collaborate with?
      </T>
      <Box>
        <EmailField
          label="Invite 1"
          value={u1}
          onChange={(e) => setU1(e.target.value)}
          placeholder="jane@example.com"
          showError={showError}
        />
        <EmailField
          label="Invite 2"
          value={u2}
          onChange={(e) => setU2(e.target.value)}
          placeholder="jack@example.com"
          showError={showError}
        />
        <EmailField
          label="Invite 3"
          value={u3}
          onChange={(e) => setU3(e.target.value)}
          placeholder="john@example.com"
          showError={showError}
        />

        {renderInviteLink()}

      </Box>
      <Tooltip title="You will get the credits once the referee signs up and verify the email address.">
        <Alert sx={{ my: 2 }} severity="success" icon={<CardGiftcardIcon />}>Earn $10 in credit for every successful invite</Alert>
      </Tooltip>

    </DialogContent>
    <DialogActions sx={{ p: 2 }}>
      <Button
        sx={{ mr: 2 }}
        onClick={() => props.onClose()}
        color="primary"
      > {props.onboarding ? 'Skip' : 'Cancel'}</Button>
      <AsyncButton
        variant="contained"
        disabled={invites.length === 0}
        onClick={onInvite}
      >Invite</AsyncButton>
    </DialogActions>
  </Dialog>;
};
const ReferOnboarding = React.memo(ReferOnboardingBase);
export default ReferOnboarding;




// Keeping it simple so users are not discouraged
// Checking if email has "@", "." dot and two letters at the last
const emailRegex = new RegExp('^.+@.+\\.\\w{2,}$', 'i');


/**
 * @param {string} email 
 */
const isValidEmail = (email) => {
  return !!emailRegex.test(email);
};

/**
 * 
 * @param {object} props 
 * @param {string} props.value
 * @param {import('@mui/material').TextFieldProps['onChange']} props.onChange
 * @param {string} props.label
 * @param {string} props.placeholder
 * @param {boolean=} props.showError
 * @returns 
 */
const EmailField = ({
  value,
  onChange,
  label,
  placeholder,
  showError
}) => {
  const hasError = !!value && !isValidEmail(value);
  return (
    <TextField
      sx={{
        my: 2
      }}
      fullWidth
      size="small"
      label={label}
      type="email"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      error={hasError}
      helperText={showError && hasError && 'Invalid email address'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PersonIcon />
          </InputAdornment>
        )
      }}
    />
  );
};