import React from 'react';
import ToggleSetting from './ToggleSetting';
import { getWidgetDefault } from './WidgetUtil';

export default function WidgetConfig() {
  return <ToggleSetting
    propertyName="widget intellisense"
    propertyKey="widget_enabled"
    getDefaultFn={getWidgetDefault}
    label="Use the Instant Assistant to see snippet auto completions as you type."
  />;
}