/**
 * This file is private to highlighter.js
 */
import { addBreadcrumb } from '@sentry/browser';
import { CollapsedCommandBlot } from './highlighter';
import Quill from 'quill';

/**
 * need to change child only if attributes changed
 * @param {import("./editor_utilities").CollapsedDataType['value']['attributes']} attrs 
 * @returns 
 */
const parseAttrs = (attrs) => {
  if (!attrs) {
    return {
      keys: [],
      attributes: {}
    };
  }
  /**
   * @type {Object<string, attrs[0]>}
   */
  const attrsObj = {};
  /**
   * @type {string[]}
   */
  const keys = [];
  for (let index = 0; index < attrs.length; index++) {
    const attr = attrs[index];
    // can be null for legacy formmenus
    const attrName = attr.name || `#empty#-${index}`;
    // fill in won't be drawn so we remove them
    if (attr.fillIn) {
      continue;
    }
    attrsObj[attrName] = attr;
    keys.push(attrName);
  }
  return {
    keys,
    attributes: attrsObj
  };
};

/**
 * need to change child only if attributes changed
 * @param {import("./editor_utilities").CollapsedDataType['value']['attributes'][0]} oldAttr 
 * @param {import("./editor_utilities").CollapsedDataType['value']['attributes'][0]} newAttr 
 * @returns 
 */
const isAttrEqual = (oldAttr, newAttr) => {
  if (!oldAttr || !newAttr) {
    return oldAttr === newAttr;
  }
  const areRegularFieldsSame = (
    oldAttr.name === newAttr.name
    && oldAttr.positional === newAttr.positional
    && oldAttr.raw === newAttr.raw
    && oldAttr.fillIn === newAttr.fillIn
  );
  //Some fields are not same. Lets reject
  if (!areRegularFieldsSame) {
    return false;
  }
  if (oldAttr.value === newAttr.value
    //#138 some fields while parsed, newData trimmed value removes new-lines and tabs etc
    //So lets check with oldvalue trimmed too
    || oldAttr.value.trim() === newAttr.value) {
    return true;
  }
  // value seems to be not same. Lets reject
  return false;
};

/**
 * need to change child only if attributes changed
 * @param {import("./editor_utilities").CollapsedDataType} oldData 
 * @param {import("./editor_utilities").CollapsedDataType} newData 
 * @returns 
 */
export const areAttrsEqual = (oldData, newData) => {
  let oldAttrs = oldData?.value?.attributes;
  let newAttrs = newData?.value?.attributes;
  if (!oldAttrs || !newAttrs) {
    //Remove breadcrumbs once all the issues are fixed
    addBreadcrumb({
      message: 'invalid token update: One of the attributes is null',
      data: {
        old: oldAttrs,
        new: newAttrs
      }
    });
    return oldAttrs === newAttrs;
  }

  const {
    keys: oldAttrsKeys,
    attributes: oldAttrsObj
  } = parseAttrs(oldAttrs);

  const {
    keys: newAttrsKeys,
    attributes: newAttrsObj
  } = parseAttrs(newAttrs);

  if (oldAttrsKeys.length !== newAttrsKeys.length) {
    //Remove breadcrumbs once all the issues are fixed
    addBreadcrumb({
      message: 'invalid token update: Has different attributes length',
      data: {
        old: oldAttrsKeys,
        new: newAttrsKeys
      }
    });
    return false;
  }

  for (let index = 0; index < oldAttrsKeys.length; index++) {
    const key = oldAttrsKeys[index];
    const oldAttr = oldAttrsObj[key];
    const newAttr = newAttrsObj[key];
    if (!isAttrEqual(oldAttr, newAttr)) {
      //Remove breadcrumbs once all the issues are fixed
      addBreadcrumb({
        message: 'invalid token update: Has different attribute',
        data: {
          old: oldAttr,
          new: newAttr
        }
      }); 
      return false;
    }
  }
  //looks good
  return true;
};


/**
 * Checks if attribute nodes have been updated.
 * @param {import("./editor_utilities").CollapsedDataType} oldData 
 * @param {import("./editor_utilities").CollapsedDataType} newData 
 * @returns 
 */
export const areChildNodesUpdated = (oldData, newData) => {
  let oldAttrs = oldData?.value?.attributes;
  let newAttrs = newData?.value?.attributes;
  if (!oldAttrs || !newAttrs) {
    return oldAttrs === newAttrs;
  }
  for (let attrIndex = 0; attrIndex < oldData.value.attributes.length; attrIndex++) {
    const oldAttr = oldData.value.attributes[attrIndex];
    const newAttr = newData.value.attributes[attrIndex];
    // Nodes doesn't exist. May be check if nodes do not exists on both.
    if (!oldAttr.nodes || !newAttr.nodes) {
      return oldAttr.nodes === newAttr.nodes;
    }

    if (oldAttr.nodes.length !== newAttr.nodes.length) {
      // Nodes have been added or removed
      return true;
    }

    // Check for child of nodes.
    for (let nodeIndex = 0; nodeIndex < oldAttr.nodes.length; nodeIndex++) {
      const oldNode = oldAttr.nodes[nodeIndex];
      const newNode = newAttr.nodes[nodeIndex];
      if (areChildNodesUpdated(oldNode, newNode)) {
        return true;
      }
    }
    
  }
  return false;
};

export class MultiSet {
  constructor() {
    this.map = new Map();
  }

  has(key) {
    return this.map.has(key);
  }

  add(key) {
    if (!this.map.has(key)) {
      this.map.set(key, 1);
    } else {
      this.map.set(key, this.map.get(key) + 1);
    }
  }

  remove(key) {
    const value = this.map.get(key) - 1;
    if (value === 0) {
      this.map.delete(key);
    } else {
      this.map.set(key, value);
    }
  }
}

const MAX_INTERATIONS = 50000;

/**
 * 
 * @param {import('quill').default} quill 
 * @returns 
 */
export const getSelectedCommands = (quill) => {
  let iterator = 0;
  /**
   * @type {HTMLElement}
   */
  const qlEditor = quill.root;

  const currentSelection = document.getSelection();

  if (currentSelection.isCollapsed) {
    return;
  }

  // not inside editor
  if (!currentSelection.containsNode(qlEditor, true)) {
    return;
  }
  let selectedCommands = [];

  /**
   * @param {Element} node
   */
  let setCommandAsSelected = (node) => {
    if (selectedCommands.includes(node.id)) {
      return node;
    }
    selectedCommands.push(node.id);
  };
  let startNode = currentSelection.anchorNode,
    startOffset = currentSelection.anchorOffset,
    endNode = currentSelection.focusNode,
    endOffset = currentSelection.focusOffset;

  //reverse
  if (startNode.compareDocumentPosition(endNode) === Node.DOCUMENT_POSITION_PRECEDING) {
    startNode = currentSelection.focusNode;
    startOffset = currentSelection.focusOffset;
    endNode = currentSelection.anchorNode;
    endOffset = currentSelection.anchorOffset;
  }
  let startNodeCommand = startNode.parentElement.closest('.collapsed-command');
  if (startNodeCommand) {
    startNode = startNodeCommand;
    if (startOffset === 0) {
      setCommandAsSelected(startNodeCommand);
    }
  }
  let endNodeCommand = endNode.parentElement.closest('.collapsed-command');
  if (endNodeCommand) {
    endNode = endNodeCommand;
    if (endOffset > 0) {
      setCommandAsSelected(endNodeCommand);
    }
  }

  /**
   * 
   * @param {Node} node 
   */
  let checkCommandNode = (node) => {
    iterator++;
    if (!(node instanceof HTMLElement)) {
      return node;
    }
    if (node === endNode) {
      return null;
    }
    if (!node.classList.contains('collapsed-command')) {
      return node;
    }
    setCommandAsSelected(node);
    return getNextNode(node);
  };
  /**
   * 
   * @param {Node} node 
   */
  let getNextNode = (node) => {
    let cursor = node;
    if (!cursor || cursor === endNode) {
      return null;
    }
    while (!cursor.nextSibling && iterator < MAX_INTERATIONS) {
      cursor = checkCommandNode(cursor.parentNode);
      if (cursor === qlEditor) {
        return null;
      }
      if (cursor === endNode) {
        return null;
      }
      if (!cursor) {
        return null;
      }
    }
    cursor = checkCommandNode(cursor.nextSibling);
    while (cursor?.childNodes?.length && iterator < MAX_INTERATIONS) {
      if (cursor === endNode) {
        break;
      }
      cursor = checkCommandNode(cursor.childNodes[0]);
    }
    return cursor;
  };
  let nextNode = getNextNode(startNode);
  while (nextNode && nextNode !== endNode && iterator < MAX_INTERATIONS) {
    nextNode = getNextNode(nextNode);
  }
  return selectedCommands;
};

/**
 * Replacement for "quill.getLeaf" as that it's not reliable
 * @param {number} startIndex
 * @param {import('quill').default} quill
 * */
export function getCollapsedCommandByIndex(quill, startIndex) {
  // @ts-ignore
  const allCollapsedCommandBlots = quill.scroll.descendants(CollapsedCommandBlot);

  return allCollapsedCommandBlots.find(blot => blot.blazeData.meta.startPosition === startIndex);
}


/**
 * 
 * @param {import('quill').default} quill 
 */
export const getHighlighter = (quill) => {
  return /** @type {import('./highlighter').default} */ (quill.getModule('snippetsyntax'));
};

/**
 * 
 * @param {Element} node 
 * @returns 
 */
export const getCollapsedCommandBlot = (node) => {
  const blot = /** @type {CollapsedCommandBlot} */ (Quill.find(node));
  if (
    (!blot || !blot.blazeData)
    && !node?.classList?.contains('replacement-command')
  ) {
    addBreadcrumb({
      message: 'Not a valid collapsed command',
      data: {
        html: node.outerHTML
      }
    });
  }
  return blot;
};

/**
 * Merges formats to generate format from obj2 as false.
 * @param {{[key: string]: any}} obj1 
 * @param {{[key: string]: any}} obj2 
 * @returns 
 */
export function mergeFormats(obj1, obj2) {
  /**
   * @type {typeof obj1}
   */
  const result = {};
  let different = false; // Flag to track if objects are different
  if (!obj2) {
    obj2 = {};
  }
  // Merge obj1 into result
  for (const key in obj1) {
    result[key] = obj1[key];
    // ignore comparing table object but still copy from source.
    if (key === 'tableCellLine') {
      continue;
    }
    if (!(key in obj2)) {
      different = true; // Set different flag to true if key is in obj1 but not in obj2
    }
  }

  // Merge obj2 into result
  for (const key in obj2) {
    // ignore comparing table object
    if (key === 'tableCellLine') {
      continue;
    }
    // If key exists in result and values are not equal, set to false
    if (key in result && result[key] !== obj2[key]) {
      different = true; // Set different flag to true
    } else if (!(key in result)) {
      result[key] = false;
      different = true; // Set different flag to true if a new key is added
    }
  }
  return { merged: result, different };
}