import React, { useEffect, useState } from 'react';
import { listOrgInvoices } from '../../bapi';
import { log } from '../../logging/logging';
import { toast } from '../../message';
import MenuItem from '@mui/material/MenuItem';


/**
 * @param {object} props
 * @param {function} props.onClose
 * @param {object} props.entity
 */
function LastInvoiceButtonBase({ onClose, entity }, ref) {
  /** @type {{ error: object; invoiceLink: string; }} */
  const result = useLastInvoiceLink(entity);

  const downloadInvoice = () => {
    onClose();

    if (!result) {
      return;
    }

    try {
      if (result.error) {
        throw result.error;
      }

      log({ category: 'Billing', action: 'Last invoice download requested' });
      if (result.invoiceLink) {
        window.open(result.invoiceLink);
      } else {
        toast('There is no invoice available for this account. Contact support@blaze.today for assistance.');
        log({ category: 'Billing', action: 'No invoice.' });
      }
    } catch (error) {
      toast(`Unable to download last invoice. Contact support@blaze.today for assistance. ${error}`, {
        duration: 6000,
        intent: 'danger'
      });
      log({ category: 'Billing', action: 'Failed to download invoice.' });
      console.error(error);
    }
  };

  return <MenuItem
    ref={ref}
    disabled={!result}
    onClick={downloadInvoice}>Download last invoice</MenuItem>;
}

function useLastInvoiceLink(entity) {
  const [result, setResult] = useState();
  useEffect(() => {
    // @ts-ignore
    getLastInvoiceLink(entity).then(invoiceLink => setResult({ invoiceLink })).catch(error => setResult({ error }));
    // eslint-disable-next-line
  }, []);
  return result;
}


/**
 * @return {Promise<string>}
 */
async function getLastInvoiceLink(entity) {
  const { data: { invoices: [invoice] } } = await listOrgInvoices(entity || {});
  return invoice?.link;
}

const LastInvoiceButton = React.forwardRef(LastInvoiceButtonBase);
export default LastInvoiceButton;