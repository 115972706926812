import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography as T
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Incrementor from './Incrementor';
import { changeTeamSize } from '../../bapi';
import { toast } from '../../message';
import AsyncButton from '../AsyncButton/AsyncButton';

/**
 * Component to set/unset Team size dialog.
 * @param {object} props 
 * @param {number} props.teamSize
 * @param {() => any} props.onUpdate
 * @param {() => any} props.onClose
 */
const TeamSizeDialog = ({
  teamSize,
  onUpdate,
  onClose
}) => {
  const [value, setValue] = useState(teamSize);

  /**
   * @param {number} teamSize 
   * @param {() => any} done 
   */
  const changeTeamSizeWrapper = async (teamSize, done) => {
    try {
      await changeTeamSize(teamSize);
      onUpdate();
      done();
      onClose();
    } catch (err) {
      toast('Unable to change team size - ' + err.message, {
        intent: 'danger'
      });
    }
  };

  return (
    <Dialog
      open
      maxWidth="xs"
      onClose={onClose}
    >
      <DialogTitle>
        Business Team Size
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            transform: 'translate(50%, -50%)',
            right: 28,
            top: 32
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Incrementor
          value={value}
          onChange={(value) => setValue(value)}
          minimum={1}
          maximum={100}
          inputWidth={170}
          label="Team size / number of seats:"
          sx={{
            mb: 2
          }}
        />
        <T>
          This is minimum charge for your billing.
          You will still be charged for extra persons if the members are more than the value you would set.
        </T>
      </DialogContent>

      <DialogActions
        sx={{
          p: 3
        }}
      >
        <Button onClick={onClose}>Cancel</Button>
        <AsyncButton
          onClick={(done) => changeTeamSizeWrapper(null, done)}
        >Make it flexible</AsyncButton>
        <AsyncButton
          variant="contained"
          color="primary"
          onClick={(done) => changeTeamSizeWrapper(value, done)}
        >Change</AsyncButton>
      </DialogActions>
    </Dialog>
  );
};

export default TeamSizeDialog;