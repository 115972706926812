import React from 'react';
import {
  Box
} from '@mui/material';
import { EmptyState } from '../EmptyState/EmptyState';

/**
 * 
 * @param {object} props 
 * @param {string} props.label 
 * @param {React.ReactNode=} props.children 
 * @param {string=} props.expiryLabel 
 * @returns 
 */
const TrashedEmptyState = ({
  label,
  children,
  expiryLabel 
}) => {
  
  return (
    <Box sx={{
      pt: 5,
      px: 3
    }}>
      <EmptyState
        icon="MISSING"
        title={`No trashed ${label}`}
        description={<>
          <span>{`Trashed ${label} are permanently deleted after ${expiryLabel || '3 days'} and cannot be restored.`}</span>
          {children}
        </>}
      />
    </Box>
  );
};

export default TrashedEmptyState;