import Quill from 'quill';
import { css } from '../utils';

const COL_TOOL_HEIGHT = 12;
const COL_TOOL_CELL_HEIGHT = 12;
//const ROW_TOOL_WIDTH = 12;
const CELL_MIN_WIDTH = 5;
const PRIMARY_COLOR = '#35A7ED';

export default class TableColumnTool {
  constructor (table, quill, options) {
    if (!table) {
      return null;
    }
    this.table = table;
    this.quill = quill;
    this.options = options;
    this.domNode = null;
    this.hasAutoWidth = false;

    this.initColTool();
  }

  initColTool () {
    const self = this;
    const parent = self.quill.root.parentNode;
    const domNode = self.domNode = document.createElement('div');
    domNode.classList.add('qlbt-col-tool');

    self.updateToolCells();
    parent.appendChild(domNode);

    let updateTimer;
    const textChangeHandler = () => {
      if (self.table?.parentElement?.parentElement) {
        clearTimeout(updateTimer);
        updateTimer = setTimeout(() => self.updateToolCells(), 200);
        return;
      }
      self.destroy();
      self.quill.off('text-change', textChangeHandler);
    };
    self.quill.on('text-change', textChangeHandler);
  }

  createToolCell () {
    const toolCell = document.createElement('div');
    toolCell.classList.add('qlbt-col-tool-cell');
    const resizeHolder = document.createElement('div');
    resizeHolder.classList.add('qlbt-col-tool-cell-holder');
    const textElement = document.createElement('span');
    css(toolCell, {
      'height': `${COL_TOOL_CELL_HEIGHT}px`
    });
    toolCell.appendChild(resizeHolder);

    textElement.classList.add('qlbt-col-tool-cell-text-element');
    toolCell.appendChild(textElement);
    return {
      domNode: toolCell,
      textElement
    };
  }

  updateToolCells () {
    const tableContainer = getTableContainer(this.table);
    const CellsInFirstRow = tableContainer?.children?.tail?.children?.head?.children;
    if (!CellsInFirstRow || CellsInFirstRow.head.statics.blotName !== 'table') {
      return;
    }
    const tableCols = tableContainer.colGroup()?.children;
    // Somehow tableCol is missing. It will be recreated later.
    if (!tableCols) {
      return;
    }
    const cellsNumber = computeCellsNumber(CellsInFirstRow);
    let existCells = Array.from(this.domNode.querySelectorAll('.qlbt-col-tool-cell'));
    for (let index = 0; index < Math.max(cellsNumber, existCells.length); index++) {
      let col = tableCols.at(index);
      let colWidthStr = col && col.formats()[col.statics.blotName].width;
      let colWidth = colWidthStr !== 'auto' ? parseInt(colWidthStr, 10) : 'auto';
      if (index === 0) {
        this.domNode.style.display = colWidth === 'auto' ? 'none' : null;
      }
      // if cell already exist
      let toolCell = null;
      if (!existCells[index]) {
        const { domNode, textElement } = this.createToolCell();
        toolCell = domNode;
        this.domNode.appendChild(toolCell);
        this.addColCellResizeHandler(toolCell);
        this.addColCellMoveHandler(toolCell);
        // set tool cell min-width
        css(toolCell, {
          'min-width': `${colWidth}px`
        });
        textElement.innerHTML = `${roundOffWidth(colWidth)}px`;
      } else if (existCells[index] && index >= cellsNumber) {
        existCells[index].remove();
      } else {
        toolCell = existCells[index];
        // set tool cell min-width
        css(toolCell, {
          'min-width': `${colWidth}px`
        });
      }
    }

    this.repositionColTools();
  }

  destroy () {
    const self = this;
    self.domNode.remove();
    return null;
  }

  /**
   * 
   * @param {HTMLElement} cell 
   */
  addColCellResizeHandler(cell) {
    const tableContainer = getTableContainer(this.table);
    const $holder = cell.querySelector('.qlbt-col-tool-cell-holder');
    const textElement = cell.querySelector('.qlbt-col-tool-cell-text-element');
    let dragging = false;
    let x0 = 0;
    let x = 0;
    let delta = 0;
    let width0 = 0;
    // helpLine relation varrible
    let tableRect = {};
    let cellRect = {};
    let $helpLine = null;

    const handleDrag = e => {
      e.preventDefault();

      if (dragging) {
        x = e.clientX;

        if (width0 + x - x0 >= CELL_MIN_WIDTH) {
          delta = x - x0;
        } else {
          delta = CELL_MIN_WIDTH - width0;
        }

        css($helpLine, {
          'left': `${cellRect.left + cellRect.width - 1 + delta}px`
        });

        textElement.innerHTML = `${roundOffWidth(width0 + delta)}px`;
      }
    };

    const handleMouseup = e => {
      e.preventDefault();
      const existCells = Array.from(this.domNode.querySelectorAll('.qlbt-col-tool-cell'));
      const colIndex = existCells.indexOf(cell);
      const colBlot = tableContainer.colGroup().children.at(colIndex);

      if (dragging) {
        colBlot.format('width', width0 + delta);
        css(cell, { 'min-width': `${width0 + delta}px` });

        x0 = 0;
        x = 0;
        delta = 0;
        width0 = 0;
        dragging = false;
        $holder.classList.remove('dragging');
      }

      document.removeEventListener('mousemove', handleDrag, false);
      document.removeEventListener('mouseup', handleMouseup, false);
      tableRect = {};
      cellRect = {};
      $helpLine.remove();
      $helpLine = null;
      tableContainer.updateTableWidth();

      const tableSelection = this.quill.getModule('better-table').tableSelection;
      tableSelection && tableSelection.clearSelection();
      this.repositionColTools();
    };

    const handleMousedown = e => {
      document.addEventListener('mousemove', handleDrag, false);
      document.addEventListener('mouseup', handleMouseup, false);

      tableRect = this.table.getBoundingClientRect();
      cellRect = cell.getBoundingClientRect();
      $helpLine = document.createElement('div');
      css($helpLine, {
        position: 'fixed',
        top: `${cellRect.top}px`,
        left: `${cellRect.left + cellRect.width - 1}px`,
        zIndex: '100',
        height: `${tableRect.height + COL_TOOL_HEIGHT + 4}px`,
        width: '1px',
        backgroundColor: PRIMARY_COLOR
      });

      document.body.appendChild($helpLine);
      dragging = true;
      x0 = e.clientX;
      width0 = cellRect.width;
      $holder.classList.add('dragging');
    };
    $holder.addEventListener('mousedown', handleMousedown, false);
  }

  /**
   * 
   * @param {HTMLElement} cell 
   */
  addColCellMoveHandler(cell) {
    const self = this;
    let positionFrom;
    cell.setAttribute('draggable', 'true');
    cell.addEventListener('dragstart', (e) => {
      cell.classList.add('dragging');
      positionFrom = [...cell.parentElement.children].indexOf(cell);
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/plain', null);
      cell.parentElement.addEventListener('dragover', dragOver, false);
      cell.addEventListener('dragend', dragEnd, false);
    });
    function isBefore(el1, el2) {
      let cur;
      if (el2.parentNode === el1.parentNode) {
        for (cur = el1.previousSibling; cur; cur = cur.previousSibling) {
          if (cur === el2) {
            return true; 
          }
        }
      }
      return false;
    }
    
    /**
     * 
     * @param {DragEvent} e 
     * @returns 
     */
    function dragOver(e) {
      const target = /** @type {HTMLElement} */ (e.target);
      if (target.parentElement !== cell.parentElement) {
        return;
      }
      e.dataTransfer.dropEffect = 'move';
      e.preventDefault();
      if (isBefore(cell, e.target)) {
        target.parentElement.insertBefore(cell, target);
      } else {
        target.parentElement.insertBefore(cell, target.nextElementSibling);
      }
    }
    function dragEnd(e) {
      e.preventDefault();
      let positionTo = [...cell.parentElement.children].indexOf(cell);
      cell.classList.remove('dragging');
      cell.parentElement.removeEventListener('dragover', dragOver, false);
      cell.removeEventListener('dragend', dragEnd, false);

      const tableContainer = getTableContainer(self.table);
      if (positionFrom === positionTo) {
        return;
      }
      try {
        tableContainer.moveColumn(positionFrom, positionTo);
      } catch (err) {
        if (!(err instanceof RangeError)) {
          console.error(err);
        }
        self.quill.getModule('better-table').onMessage(err.message, 'danger');
      }
      self.updateToolCells();
    }
  }

  colToolCells () {
    return Array.from(this.domNode.querySelectorAll('.qlbt-col-tool-cell'));
  }

  repositionColTools() {
    const self = this;
    clearTimeout(self.respositionTimer);
    self.respositionTimer = setTimeout(() => {
      if (!document.body.contains(self.table)) {
        return;
      }
      const parent = self.quill.root.parentNode;
      const containerRect = parent.getBoundingClientRect();
      const tableViewRect = self.table.parentNode.getBoundingClientRect();
      const cellRect = self.table.querySelector('td').getBoundingClientRect();
      let leftToConsier = tableViewRect.left;
      if (cellRect.left > leftToConsier) {
        leftToConsier = cellRect.left;
      }
      css(self.domNode, {
        width: `${tableViewRect.width}px`,
        height: `${COL_TOOL_HEIGHT}px`,
        left: `${leftToConsier - containerRect.left}px`,
        top: `${tableViewRect.top - containerRect.top - COL_TOOL_HEIGHT - 5}px`
      });
      self.domNode.scrollLeft = self.table.parentNode.scrollLeft;

    }, 10);
  }
}

function computeCellsNumber (CellsInFirstRow) {
  return CellsInFirstRow.reduce((sum, cell) => {
    const cellColspan = cell.formats().colspan;
    sum = sum + parseInt(cellColspan, 10);
    return sum;
  }, 0);
}


function roundOffWidth(width) {
  return Math.round(width * 100) / 100;
}

/**
 * 
 * @param {HTMLElement} el 
 */
function getTableContainer(el) {
  return /** @type {import('../formats/tableContainer').default} */ (Quill.find(el));
}