import { COL_ATTRIBUTES, COL_DEFAULT, TABLE_ATTRIBUTES } from './constants';
import TableContainer from './tableContainer';
import Block from 'quill/blots/block';



class TableCol extends Block {
  static create (value) {
    let node = super.create(value);
    COL_ATTRIBUTES.forEach(attrName => {
      const attrValue = value[attrName] || COL_DEFAULT[attrName];
      if (typeof attrValue === 'undefined') {
        return;
      }
      node.setAttribute(`${attrName}`, attrValue);
    });
    TABLE_ATTRIBUTES.forEach(attrName => {
      const attrValue = value[attrName];
      if (typeof attrValue === 'undefined') {
        return;
      }
      node.setAttribute(`table-${attrName}`, attrValue);
    });
    return node;
  }

  static formats(domNode) {
    const formats = {};
    const tableFormats = TableContainer.getFormats(domNode.parentElement.parentElement);
    TABLE_ATTRIBUTES.forEach(attrName => {
      if (typeof tableFormats[attrName] !== 'undefined' || !domNode.hasAttribute(`table-${attrName}`)) {
        return;
      }
      tableFormats[attrName] = domNode.getAttribute(`table-${attrName}`);
    });
    Object.assign(formats, tableFormats);
    
    return COL_ATTRIBUTES.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] =
          domNode.getAttribute(attribute) || undefined;
      } 

      // Check if other columns are auto 
      if (
        attribute === 'width'
        && formats[attribute] === 'auto'
      ) {
        for (const col of domNode.parentElement.children) {
          if (col.getAttribute(attribute) !== 'auto') {
            // Do not consider it as an auto column
            // because we require all columns to be auto
            // This was probably copied from some website 
            delete formats[attribute];
            break;
          }
        }
      }
      return formats;
    }, formats);
    
  }

  optimize(context) {
    super.optimize(context);
  }

  format(name, value) {
    if (COL_ATTRIBUTES.indexOf(name) > -1) {
      const attrValue = value || COL_DEFAULT[name];
      if (typeof attrValue !== 'undefined') {
        this.domNode.setAttribute(`${name}`, attrValue);
      } else {
        this.domNode.removeAttribute(`${name}`);
      }
    } else if (TABLE_ATTRIBUTES.indexOf(name) > -1) {
      if (typeof value !== 'undefined') {
        this.domNode.setAttribute(`table-${name}`, value);
      } else {
        this.domNode.removeAttribute(`table-${name}`);
      }
    } else {
      super.format(name, value);
    }
  }

  formats() {
    const currentFormats = this.statics.formats(this.domNode);
    
    const formats = super.formats();
    formats[this.statics.blotName] = {
      ...formats[this.statics.blotName],
      ...currentFormats
    };
    return formats;
  }

  html () {
    return this.domNode.outerHTML;
  }
}
TableCol.blotName = 'tableCol';
TableCol.tagName = 'col';

export default TableCol;