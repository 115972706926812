import { getState } from './getState';
import { sync } from './Sync/syncer';


/**
 * @param {import("./Sync/Sync").GroupQueryType} config 
 */
export function getGroups(config) {
  let ids = sync.getGroupIds(config);
  let state = getState();
  let groups = state.dataState.groups;

  return ids.map(id => groups[id]).filter(g => !!g);
}
  