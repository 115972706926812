import { createTree, interleaveNodes } from '../snippet_processor/Parser';
import { domToStream } from '../snippet_processor/SnippetProcessor';
import { Environment } from '../snippet_processor/DataContainer';


/**
 * Creates a textual representation of a delta for export.
 * 
 * @param {import('quill/core').Delta | DeltaType} delta 
 * @param {'text'|'html'} [type]
 * 
 * @return {{ data: string, type: 'html'|'text' }}
 */
export function exportString(delta, type) {
  if (![undefined, 'html', 'text'].includes(type)) {
    throw new Error('"type" must be either undefined, "text" or "html"');
  }

  let dom = createTree(interleaveNodes(delta, []), new Environment(Object.create(null), { stage: 'preview' }));
  if (!type) {
    if (dom.isStyled) {
      type = 'html';
    } else {
      type = 'text';
    }
  }

  let data = domToStream(dom, type, true).join(''); // assumes no ParseNodes in stream
  return {
    data,
    type
  };
}