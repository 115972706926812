import React from 'react';
import { useTypedSelector } from '../../hooks';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { limitationsConfig } from '../../flags';
import { storage } from '../../utilities';
import { makeRef } from '../../firebase_utilities';


export default function LimitationsSelector() {
  let uid = useTypedSelector(store => store.userState.uid);
  let limitationsPlan = useTypedSelector(store => limitationsConfig(store).plan);

  return (
    <div style={{ marginTop: 40 }}>
      <FormControl variant="standard">
        <InputLabel>Capabilities plan (dev only)</InputLabel>
        <Select
          id="limitations-select"
          native
          // For compatibility we default users with no locale to en-us
          value={limitationsPlan}
          style={{
            width: 240
          }}
          onChange={(e) => {
            let v = /** @type {string} */ (e.target.value);
            storage.update(makeRef('users_settings', uid), {
              'options.capabilities_plan': v
            });
          }}
          variant="standard"
        >
          <option value="DEFAULT">DEFAULT</option>
          <option value="LEGACY">LEGACY</option>
          <option value="SNIPPET_2020">SNIPPET_2020</option>
          <option value="SNIPPET_2020_R1">SNIPPET_2020_R1</option>
          <option value="SNIPPET_2022">SNIPPET_2022</option>
        </Select>
      </FormControl>
    </div>
  );
}

