import { createDom } from '../../snippet_processor/SnippetProcessor';
import { Environment } from '../../snippet_processor/DataContainer';
import { validDomain } from '../../standalone_utilities';
import { featureUsage } from '../Version/limitations';
import { decompressDelta } from '../../delta_proto/DeltaProto';


/**
 * Full validation of addon spec for error checking during publishing.
 * 
 * @param {SnippetObjectType} data
 * @param {string[]=} formNames
 * 
 * @return {Promise<string>}
 */
export async function fullValidateAddonSpec(data, formNames = []) {
  let command = data.options.addon;
  if (!command) {
    command = /** @type {any} */ ({});
  }

  let quick = quickValidateAddonSpec(command, formNames);
  if (quick) {
    return quick;
  }

  if ((!command.description || !command.description.trim())) {
    return 'A description for the command is required.';
  }
    
  let positional = command.positional || [];
  if (!Array.isArray(positional)) {
    positional = [positional];
  }
  let named = command.named || [];

  let combined = positional.concat(named);

  for (let item of combined) {
    if (!item.description) {
      return `The setting "${item.name}" must have description.`;
    }
  }

  let validHosts = (command.display && command.display.valid_hosts) || [];
  for (let host of validHosts) {
    if (!validDomain(host)) {
      return `The domain "${host}" is not a valid domain.`;
    }
  }

  if (validHosts.length === 0) {
    let env = new Environment({}, { type: 'text' });
    let dom = await createDom(
      data.content.delta.ops ? data.content.delta : decompressDelta(data.content.delta.toUint8Array ? data.content.delta.toUint8Array() : data.content.delta),
      env
    );
    let usage = await featureUsage(dom, env);
    if (usage['AUTOPILOT']) {
      return 'You must specify a list of valid domains for a command when using {click} or {key}.';
    }
    if (usage['SITE']) {
      return 'You must specify a list of valid domains for a command when using {site}.';
    }
  }
}


/**
 * Quick validation of addon spec for error checking during editing.
 * 
 * @param {AddonConfigType} command
 * @param {string[]=} formNames
 * 
 * @return {string}
 */
export function quickValidateAddonSpec(command, formNames = []) {
  let positional = command.positional || [];

  if (!Array.isArray(positional)) {
    // for legacy purposes
    // TODO: remove
    positional = [positional];
  }

  let named = command.named || [];

  let combined = positional.concat(named);

  formNames = formNames.map(x => x.toLowerCase());
  let names = [];
  for (let item of combined) {
    if (!item.name.match(/^[a-z]+$/)) {
      return `The setting name "${item.name}" is not valid. Names may only contain lowercase letters and must have at least one letter.`;
    }

    if (names.includes(item.name.toLowerCase())) {
      return `The setting name "${item.name}" cannot be repeated.`;
    }

    names.push(item.name.toLowerCase());

    if (item.type === 'select' && (!item.config.options || item.config.options.length === 0)) {
      return `The setting "${item.name}" is not valid. Selection settings must have at least one option to select.`;
    }

    if (item.type === 'number') {
      let config = item.config;
      if (config) {
        if (config.minimum !== undefined && config.maximum !== undefined) {
          if (config.minimum > config.maximum) {
            return `The numeric setting "${item.name}" is not valid. Conflicting maximum and minimum values.`;
          }
        }
      }
    }
  
    
    if (formNames.includes(item.name)) {
      return `The setting name "${item.name}" conflicts with the command pack's settings page.`;
    }
  }

  let RESERVED_NAMES = ['trim', 'if', 'then', 'else', 'elseif', 'function', 'and', 'or', 'not', 'yes', 'no', 'in', 'begin', 'end', 'silent', 'mute', 'display', 'hidden', 'error', 'loading', 'style', 'repeat', 'for', 'of', 'class', 'while', 'loop', 'call', 'return', 'endif', 'endfor'];
  for (let name of RESERVED_NAMES) {
    if (names.includes(name)) {
      return `The setting name "${name}" is reserved and cannot be used.`;
    }
  }
}