import { log } from '../../logging/logging';
import {  toast } from '../../message';
// @ts-ignore
import { restartBilling, getBillingInformation } from '../../bapi';
import { useState } from 'react';


export async function restartOrgBilling(entity, email) {
  const queryParams = {
    type: 'restart-org',
    origin: window.location.pathname
  };
  if (entity?.orgId) {
    queryParams['org_id'] = entity.orgId;
  }
  return getStripeToken(email, {
    path: `${window.location.origin}/updatecard`,
    params: queryParams
  }, restartBilling)
    .then(() => restartedOrgBilling()).catch(restartedOrgBilling);
}

/**
 * 
 * @param {Error=} err 
 * @returns 
 */
export async function restartedOrgBilling(err) {
  if (!err) {
    log({
      category: 'Purchase',
      action: 'Restart org billing'
    });
    toast('Your billing details have been updated.', {
      duration: 8000,
      intent: 'success'
    });
  } else {
    log({
      category: 'Purchase',
      action: 'Failed restart org billing'
    });
    toast('Could not update billing details.' + ((err && err.message) ? (' ' + err.message) : '')  + ' Contact support@blaze.today for assistance.', {
      duration: 8000,
      intent: 'danger'
    });
  };
}

export async function updateCardDetails(
  entity,
  email,
  postChange = () => Promise.resolve(),
  getSecret
) {
  const queryParams = {
    type: 'update-card',
    origin: window.location.pathname
  };
  if (entity?.orgId) {
    queryParams['org_id'] = entity.orgId;
  }
  return getStripeToken(email, {
    path: `${window.location.origin}/updatecard`,
    params: queryParams
  }, getSecret)
    .then((token) => updatedCardDetails(!!token))
    .then(postChange);
}

/**
 * 
 * @param {boolean} success 
 * @returns 
 */
export async function updatedCardDetails(success) {
  if (success) {
    log({
      category: 'Purchase',
      action: 'Update card details'
    });

    toast('Your card details have been updated.', {
      duration: 8000,
      intent: 'success'
    });
  }
}

/**
 * @param {string} email 
 * @param {import('./PaymentDialog').PaymentDialogConfig['returnUrl']} returnUrl 
 * @param {import('./PaymentDialog').PaymentDialogConfig['getSecret']=} getSecret 
 */
async function getStripeToken(
  email,
  returnUrl,
  getSecret
) {
  try {
    const result = await showPayment({
      returnUrl,
      title: 'Text Blaze Billing Details',
      btnContents: 'Update',
      getSecret
    });
    return {
      ...result,
      email: email
    };
  } catch (err) {
    if (err !== 'Closed') {
      toast('Failed to update - ' + (err.messsage || err), {
        intent: 'danger'
      });
      console.error(err);
    }
    return null;
  }
}

export function useBillingInformation(data = {}) {
  const [billingDetails, setBillingDetails] = useState(null);

  const getBilling = () => {
    let failure = (err) => {
      log({
        category: 'Purchase',
        action: 'Failed fetch billing details'
      });

      toast('Could not fetch billing details.' + ((err && err.message) ? (' ' + err.message) : '')  + ' Contact support@blaze.today for assistance.', {
        duration: 8000,
        intent: 'danger'
      });
    };

    return getBillingInformation(data).then((res) => {
      setBillingDetails(res.data);
      return res.data;
    }).catch((err) => {
      failure(err);
    });
  };

  return [billingDetails, () => getBilling()];
}


export const paymentMessageCenter = {};

/**
 * @type {import('./PaymentDialog').PaymentDialogShow}
 */
export const showPayment = (config) => {
  return paymentMessageCenter.paymentDialog.show(config);
};