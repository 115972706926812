/**
 * Code in this file must run in both the service worker (non-window context)
 * and the offscreen page and the sandbox page (window context)
 */

import { init as Sentry_init, configureScope } from '@sentry/browser';

/**
 * release is the version dynamically reported by browser.manifest.getVersion()
 * reported_release is the version statically embedded/hard-coded into the code file itself
 * 
 * We are attempting this to resolve discrepancy where apparently older code is receiving messages on
 * Sentry while reporting itself as the newer version (coming from the manifest)
 * For example: https://blaze-today.sentry.io/issues/5548746527
 * 
 * @typedef {{ release: string, sentryLogger: string, id: string, extension_browser: 'chrome'|'edge', reported_release: string, }} ErrorLoggingDetailsType
 * 
 * This function sets up error logging in the extension
 * 
 * sendMessageFn is required for non-service worker contexts, 
 * because isDev doesn't work in the offscreen page,
 * because getManifest() is not available
 * 
 * For service worker context, 'details' should be directly passed in.
 * 
 * @param {{ sendMessageFn: Function } | { details: ErrorLoggingDetailsType }} config
 */
export async function setupErrorLogging(config) {
  /** @type {ErrorLoggingDetailsType} */
  let details = null;
  if ('sendMessageFn' in config) {
    details = await config.sendMessageFn({ request: 'errorLoggerDetails' });
  } else {
    details = config.details;
  }

  if (!details) {
    return;
  }

  // background page is the only context without access
  // to the window object
  let extensionContext = 'service_worker';

  if (typeof window !== 'undefined') {
    for (const [endsWith, context] of [
      ['form.html', 'form'],
      ['formLoader.html', 'form_sandbox'],
      ['offscreen.html', 'offscreen'],
      ['sandbox.html', 'sandbox']
    ]) {
      if (window.location.pathname.endsWith(`/html/${endsWith}`)) {
        extensionContext = context;
        break;
      }
    }
  }

  Sentry_init({
    dsn: 'https://cc8ab001f3a041a9a12b648032cebdf6@o233950.ingest.sentry.io/1397434',
    release: details.release,
    normalizeDepth: 5,
    beforeSend: (event, hint) => {
      if (hint?.originalException?.toString().includes('The browser is shutting down')) {
        // This error seems to happen when the service worker wakes up about the same time
        // when the user closes their browser. This error creates a lot of spam on Sentry
        // and doesn't come with any useful information (like user ID or any other errors)
        // And it seems to be inconsequential, so we're muting it
        return null;
      }
      return event;
    },
  });

  configureScope(scope => {
    scope.setTag('extension_context', extensionContext);
    scope.setTag('logger', details.sentryLogger);
    scope.setTag('extension_browser', details.extension_browser);
    scope.setTag('reported_release', details.reported_release);
    scope.setUser({ id: details.id });
  });
}

/**
 * @param {{ id: string }} arg 
 */
export function handleDetailsUpdate({ id }) {
  configureScope(scope => {
    scope.setUser({ id });
  });
}

/** 
 * @returns {chrome}
 */
export function browser() {
  // window is undefined in extension service worker
  // eslint-disable-next-line no-restricted-globals
  let global = typeof window === 'undefined' ? self : window;

  if (global['chrome']) {
    return global['chrome'];
  } else if (global['browser']) {
    return global['browser'];
  }
  return undefined;
}

let cachedPreferencesStore = null;
/**
 * @returns {{ getItem: (key: string) => Promise<string>, setItem: (key: string, value: string) => Promise<void> }}
 */
export function getPreferencesStore() {
  if (cachedPreferencesStore) {
    // Once calculated, the preferences storage will not change, so we cache it
    // Note that we do need to dynamically generate the preferences storage initially
    // because for the sandbox - its storage object is not setup until its script has loaded and ran
    return cachedPreferencesStore;
  }
  if (typeof window === 'undefined') {
    // extension service worker
    return {
      getItem: (key) => browser().runtime.sendMessage({ type: 'localStorage', subType: 'get', key, target: 'offscreen', }),
      setItem: (key, value) => browser().runtime.sendMessage({ type: 'localStorage', subType: 'set', key, value, target: 'offscreen', })
    };
  }
  const pageHref = window.location.href;
  if (pageHref.startsWith('chrome-extension://') && (pageHref.endsWith('/html/sandbox.html') || pageHref.endsWith('/html/formLoader.html'))) {
    // extension sandbox
    return window['TB_preferencesStore'];
  }
  return {
    getItem: (/** @type {string} */ key) => Promise.resolve(localStorage.getItem(key)),
    setItem: (/** @type {string} */ key, /** @type {string} */ value) => Promise.resolve(localStorage.setItem(key, value))
  };
}

/**
 * Returns true if we're in the extension.
 * 
 * @returns {boolean}
 */
export function isExtension() {
  // Use this global because chrome.* APIs are not
  // available in the sandbox code
  return !!browser()?.runtime?.id || (typeof window !== 'undefined' && !!window['TEXT_BLAZE_IS_EXTENSION_SANDBOX']);
}