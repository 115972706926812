import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { toast } from '../../message';
import { log } from '../../logging/logging';
import { useTypedSelector } from '../../hooks';
import T from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { SUPPORTED_IMAGE_EXTENSIONS, arrayBufferToString, uploadImage } from '../../image_utilities';


function ImageUploaderBase(props, ref) {
  let [uploading, setUploading] = useState(false);
  let [name, setName] = useState('');
  let cancelled = useRef(false);

  let uid = useTypedSelector(state => state.userState.uid);

  useImperativeHandle(ref, () => ({
    selectImage: () => {
      return new Promise((resolve, reject) => {
        let fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('value', null);
        fileInput.setAttribute('accept', '.png,.gif,.jpg,.jpeg'); // note if these are changed, the backend rules should be updated
        fileInput.addEventListener('change', () => {
          if (fileInput.files != null && fileInput.files[0] != null) {
            return ref.current.uploadFile(fileInput.files[0], resolve, reject);
          }
        });
        setTimeout(() => fileInput.click(), 25);
      });
    },
    /**
     * @param {File} file 
     * @param {function} resolve 
     * @param {function} reject 
     * @returns 
     */
    async uploadFile(file, resolve, reject) {
      if (file.size > 2 * 1024 * 1024) {
        log({ category: 'Image', action: 'Image too large', value: file.size });

        toast(`You can't upload a file larger than 2 MB. This file is ${(file.size / 1024 / 1024).toFixed(1)} MB`, {
          duration: 4000,
          intent: 'danger'
        });
        return reject();
      }

      let fileName = file.name;
      let fileParts = fileName.split('.');
      let extension = fileParts[fileParts.length - 1].toLowerCase();
      if (!SUPPORTED_IMAGE_EXTENSIONS.includes(extension)) {
        toast('You can only upload PNG, JPEG and GIF images.', {
          duration: 4000,
          intent: 'danger'
        });
        return reject();
      }

      setUploading(true);
      setName(fileName);


      cancelled.current = false;


      log({ category: 'Image', action: 'Uploading image' });


      let uploadResult;
      try {
        uploadResult = await uploadImage({
          name: 'file.' + extension.toLowerCase(),
          type: props.type,
          image: arrayBufferToString(await file.arrayBuffer())
        });
      } catch (err) {
        console.error(err);
        toast('An error occurred uploading the image.', {
          duration: 5000,
          intent: 'danger'
        });
        setUploading(false);
        return reject();
      }

      setUploading(false);

      if (cancelled.current) {
        return reject();
      }


      let url = uploadResult?.data.url;
      if (url) {
        return resolve({ url });
      } else {
        toast('An error occurred uploading the image.', {
          duration: 5000,
          intent: 'danger'
        });
        return reject();
      }
    }
  }));


  if (!uploading) {
    return null;
  }

  if (!uid) {
    return null;
  }

  return (
    <Dialog
      open
      disableEscapeKeyDown
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          cancelled.current = true;
          setUploading(false);
        }
      }}
      maxWidth="sm">
      <DialogTitle>Uploading image</DialogTitle>
      <DialogContent
        sx={{
          minWidth: {
            xs: 'min(calc(100% - 40px), 370px)',
            sm: '370px'
          }
        }}>
        <T variant="body1" paragraph sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>Uploading <b>{name}</b>...</T>
        <LinearProgress variant="indeterminate" style={{
          marginTop: 32,
          marginBottom: 16
        }}/>
      </DialogContent>

      <DialogActions style={{ marginRight: 10, marginBottom: 15 }}>
        <Button onClick={() => {
          cancelled.current = true;
          setUploading(false);
        }}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}


const ImageUploader = React.memo(forwardRef(ImageUploaderBase));
export default ImageUploader;