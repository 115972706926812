import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import T from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CottageIcon from '@mui/icons-material/Cottage';
import { log } from '../../logging/logging';
import { importGroupWithSnippetsFromGroupId } from '../Group/group_utils';
import CircularProgress from '@mui/material/CircularProgress';
import { useTypedSelector } from '../../hooks';
import { store } from '@store';
import { randomize } from '../../experiment/experiment';
import Paper from '@mui/material/Paper';
import { DialogBackgroundStyles, DialogPaperStyles } from '../Auth/auth_utilities';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { APP_TARGET } from '../../flags';
import { isAiBlaze } from '../../aiBlaze';

const lastStep = 2;
const ONBOARDING_LOG_CATEGORY = 'ongoarding_dialog';

const workTypeData = {
  'Customer Support, Operations':{
    groupId: 'Sb2ugNbJTRmVqT3AMGoQ',
  },
  'Engineering, Product, Design':{},
  'HR, L&D, Legal, Finance':{},
  'Healthcare Provider':{
    groupId: 'wXpDrsbDyqdWtCS8GqWr',
  },
  'Marketing':{},
  'Real Estate, Property Management':{},
  'Admin': {},
  'Recruiting': {},
  'Sales, Account Management':{},
  'Teaching, Education':{
    groupId: 'nF9wMW02rdk0JZSLxykX'
  },
  'Other / Personal Use': {},
};

const contentStyles = {
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  left: '50%',
  width: 'calc(100% - 50px)'
};
let app = APP_TARGET;

export default function OnboardingDialog() {
  const [step, setStep] = useState(0);
  const [usageType, setUsageType] = useState('');
  const [workType, setWorkType] = useState('');
  const [role, setRole] = useState('');
  let { newSignUp, didAutoImport } = useTypedSelector((store) => ({
    newSignUp: store.userState?.newSignUp,
    didAutoImport: store.userState?.didAutoImport
  }));

  // Avoid a flash of content while we wait for the useEffect and randomize to happen
  const [isLoading, setIsLoading] = useState(true);
  const onboardingDialogOpen = useTypedSelector((store) => store.uiState.onboardingDialogOpen);

  useEffect(() => {
    if (!newSignUp || didAutoImport) {
      return;
    }

    const openOnboardingFowNerUser = randomize('Show Onboarding Dialog', {
      type: 'WeightedChoice',
      choices: ['show', 'none'],
      weights: [0.1, 0.9]
    }) === 'show';

    if (openOnboardingFowNerUser) {
      log({ action: 'Showing onboarding for new user', category: ONBOARDING_LOG_CATEGORY });
    }

    setIsLoading(false);
    store.dispatch({
      type: 'ONBOARDING_DIALOG_OPEN',
      onboardingDialogOpen: openOnboardingFowNerUser
    });

  }, [newSignUp, didAutoImport]);

  if (app !== 'TEXT' || isAiBlaze) {
    return null;
  }

  if (!newSignUp || didAutoImport || onboardingDialogOpen === false) {
    return null;
  }

  /**
   *
   * @param {string} usageType
   * @param {string} workType
   * @param {string} role
   */
  function handleNextStep(usageType, workType, role) {
    if (step === 0) {
      log({ action: 'Usage type survey', category: ONBOARDING_LOG_CATEGORY, label: { usageType } });

      if (usageType === 'personal') {
        // skip work questions
        setStep(2);

        finishOnboarding(true);
      } else {
        setStep(1);
      }

      return;
    }

    if (step === 1) {
      log({ action: 'Work survey', category: ONBOARDING_LOG_CATEGORY, label: { workType, role } });

      setStep(2);

      const success = importSnippetsAndLog();
      finishOnboarding(!success);

      return;
    }
  }

  async function importSnippetsAndLog() {
    const success = await importGroupSnippetsBasedOnSelectedWorkType();
    const groupId = workTypeData[workType] && workTypeData[workType].groupId;

    if (success) {
      log({
        action: 'Imported group snippets',
        category: ONBOARDING_LOG_CATEGORY,
        label: {
          workType,
          groupId
        }
      });
    }

    return success;
  }

  function closeDialog() {
    store.dispatch({
      type: 'ONBOARDING_DIALOG_OPEN',
      onboardingDialogOpen: false
    });

    // clear all states, as logging our and signing up again without refreshing will keep this component rendered
    setStep(0);
    setIsLoading(true);
    setRole('');
    setUsageType('');
    setWorkType('');
  }

  function skipPersonalization() {
    log({ action: 'Skipped personalization', category: ONBOARDING_LOG_CATEGORY });

    closeDialog();
  }

  function isStepReady() {
    if (step === 0 && usageType) {
      return true;
    }

    if (step === 1 && workType && role) {
      return true;
    }

    return false;
  }

  /**
   * @param {boolean} delay
   */
  function finishOnboarding(delay) {
    // In case there's nothing to import, or is too fast, avoid flashing the last step for better ux
    setTimeout(closeDialog, delay ? 1500 : 250);
  }

  async function importGroupSnippetsBasedOnSelectedWorkType() {
    const groupId = workTypeData[workType] && workTypeData[workType].groupId;

    if (!groupId) {
      return false;
    }

    return importGroupWithSnippetsFromGroupId(groupId);
  }

  // This Box's css emulates a Dialog component, it's necessary to have a "Dialog" without any transition whatsoever
  // To avoid content flashing when this loads
  return (
    <Box
      sx={{
        ...DialogBackgroundStyles,
        position: 'absolute',
        width: '100%',
        height: '100%',
        // Important: same zIndex as the Dialog component
        zIndex: '1300',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {!isLoading && <Paper sx={{ ...DialogPaperStyles, height: 410 }} elevation={24}>
        <Card sx={{ padding: '25px',height: '100%', position: 'relative' }} elevation={0}>
          <CardContent sx={{ height: 'calc(100% - 52px)', textAlign: 'center', padding: '8px 0' }}>

            {step === 0 && <Step0
              onChange={(usage) => {
                setUsageType(usage);

                handleNextStep(usage, workType, role);
              }}
              usageType={usageType}
            /> }
            {step === 1 && <Step1 onRoleChange={setRole} role={role} onWorkTypeChange={setWorkType} workType={workType}/>}
            {step === 2 && <Step2 />}

          </CardContent>

          <DialogActions>
            {step === 0 && <Button
              variant="text" onClick={() => skipPersonalization()}
              sx={{ color: '#777777', fontWeight: 400 }}
            >
              Skip personalization
            </Button>}

            {step > 0 && step < lastStep &&
                <Button
                  disabled={!isStepReady()}
                  variant="contained"
                  onClick={() => handleNextStep(usageType, workType, role)}
                >
                  Continue
                </Button>
            }
          </DialogActions>
        </Card>
      </Paper>}
    </Box>
  );
}

/**
 *
 * @param {object} props
 * @param {Function} props.onClick
 * @param {React.ReactNode} props.children
 * @param {"outlined"|"contained"} props.variant
 */
function UsageButton({ onClick, children, variant }) {
  return (
    <Button variant={variant} onClick={() => onClick()} color="primary">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '6px',
          padding: '12px',
          lineHeight: 1.4
        }}
      >
        {children}
      </Box>
    </Button>
  );
}

/**
 *
 * @param {object} props
 * @param {Function} props.onChange
 * @param {string} props.usageType
 */
function Step0({ usageType, onChange }) {
  return (
    <>
      <T variant="h6" component="h1" data-testid="onboarding-dialog__welcome">Let's personalize your experience</T>

      <Box sx={contentStyles}>
        <T component="h3" mb="14px">You're looking to save time for</T>
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '16px'
        }}>
          <UsageButton
            variant={usageType === 'work' ? 'contained' : 'outlined'} onClick={() => onChange('work')}
          >
            <ApartmentIcon />
            Work
          </UsageButton>
          <UsageButton variant={usageType === 'personal' ? 'contained' : 'outlined'} onClick={() => onChange('personal')}>
            <CottageIcon />
            Personal
          </UsageButton>
        </Box>
      </Box>
    </>
  );
}

/**
 *
 * @param {object} props
 * @param {Function} props.onWorkTypeChange
 * @param {Function} props.onRoleChange
 * @param {string} props.workType
 * @param {string} props.role
 */
function Step1({ workType, onWorkTypeChange, role, onRoleChange }) {
  const roleOptions = [
    'Team member',
    'Manager',
    'Executive (C-level / VP)',
    'Freelancer / Consulting',
  ];

  return (
    <>
      <T variant="h6" component="h1" data-testid="onboading-dialog__about-you">Tell us about yourself</T>
      <T fontSize="14px" component="h2" gutterBottom>This will help us customize Text Blaze for you</T>

      <Stack spacing={3} sx={contentStyles} textAlign="left">
        <FormControl>
          <T variant="subtitle2" gutterBottom id="work-type-label">
            What kind of work do you do?
          </T>

          <Select
            value={workType}
            onChange={(e) => onWorkTypeChange(e.target.value)}
            labelId="work-type-label"
            MenuProps={{
              style: { maxHeight: '400px' }
            }}
          >
            {Object.keys(workTypeData).map((type) => (
              <MenuItem value={type} key={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <T variant="subtitle2" gutterBottom id="role-label">
            What is your role?
          </T>

          <Select
            value={role}
            onChange={(e) => onRoleChange(e.target.value)}
            labelId="role-label"
          >
            {roleOptions.map((r) => (
              <MenuItem value={r} key={r}>
                {r}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  );
}


function Step2() {
  return (
    <>
      <T variant="h6" component="h1" data-testid="onboarding-dialog_finished">Setting up Text Blaze</T>

      <Box sx={contentStyles}>
        <CircularProgress />
      </Box>
    </>
  );
}