import React, { Suspense } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { eagerlyLoadImport } from '../../utilities';
const SimpleSQLEditor = React.lazy(eagerlyLoadImport(() => import('./SimpleSQLEditor')));

/**
 * 
 * @param {Parameters<typeof SimpleSQLEditor>[0]} props 
 * @returns 
 */
export default function LazySimpleSQLEditor(props) {
  return <Suspense fallback={
    <LinearProgress variant="indeterminate" />
  }><SimpleSQLEditor {...props} /></Suspense>;
}