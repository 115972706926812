import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TrashedFirestore from './TrashedFirestore';
import { useTypedSelector } from '../../../hooks';
import { userPermission } from '../../../auth';
import { restoreGroup } from '../../../bapi';
import { enableGroup } from '../../../data';
import GroupIcon from '../../Group/GroupIcon';
import { TrashedDialogContext } from './TrashedTextBlazeDialog';
import { TRASH_LABELS } from './trashed_utils';

const SHOW_ALL_FOLDERS_LABEL = 'Show all';
const SHOW_ALL_FOLDERS_TOOLTIP = 'As an organization administrator you can view all the deleted folders in the organization.';
const label = TRASH_LABELS['groups'];

const TrashedGroups = () => {
  let {
    uid,
    email,
    emailVerified,
    orgId,
    isOrgOwner,
    userLoaded,
    teams,
    teamsCount
  } = useTypedSelector((store) => {
    let teamIds = [];
    if (!!store.orgState?.org && store.orgState.teams) {
      teamIds = Object.keys(store.orgState.teams);
    };
    return {
      uid: store.userState?.uid,
      email: store.userState?.email,
      emailVerified: store.userState?.emailVerified,
      userLoaded: store.userState && store.userState.isLoaded && (!!store.userState.uid),
      orgId: store.orgState?.org?.id,
      isOrgOwner: !!store.orgState?.org && ['owner'].includes(userPermission(store.orgState?.org)),
      teams: teamIds.length ?
        // Workaround to avoid rerenders.
        teamIds.join(',')
        : '',
      teamsCount: teamIds.length
    };
  });
  const [filters, setFilters] = useState(/** @type {Parameters<TrashedFirestore>['0']['filters']} */ ([]));
  const [showAll, setShowAll] = useState(false);
  const { setNavigateToOnClose } = useContext(TrashedDialogContext);
  const [hideTeamWarning, setHideTeamWarning] = useState(false);
  
  useEffect(() => {
    // Firebase supports 30 disjunctive normal form
    const FILTER_LIMIT = 30;
    let groupFilter = [
      `u:${uid}`
    ];
    if (emailVerified) {
      groupFilter.push(`e:${email}`);
    }
    if (orgId) {
      groupFilter.push(`o:${orgId}`);
    }
    if (isOrgOwner && showAll) {
      groupFilter.push(`oo:${orgId}`);
    }
    /**
     * Org owner should already be able to view the folder. So does not have to filter for teams.
     * Can a folder be shared to a team without it being a org folder.
     * May be x joined a org, shared to a team. Now quits the org and created a different org.
     * 
     * We support only teams which are of size limit 5. 
     * Anything more, firstore rules conditions wont match.
     */
    const teamIds = teams.split(',');
    if (teams && teamIds?.length <= 5) {
      for (let index = 0; index < teamIds.length; index++) {
        const teamId = teamIds[index];
        groupFilter.push(`t:${orgId}///${teamId}`);
      }
    }
    if (groupFilter.length >= FILTER_LIMIT) {
      groupFilter = groupFilter.splice(0, FILTER_LIMIT);
    }
    setFilters([
      ['owners_for_trash', 'array-contains-any', groupFilter]
    ]);
  }, [uid, email, emailVerified, isOrgOwner, orgId, teams, showAll]);
  if (!userLoaded) {
    return null;
  }
  const teamWarning = (teamsCount > 5 && !isOrgOwner) && !hideTeamWarning && (
    <Alert
      severity="warning"
      sx={{
        mb: 2,
        ml: 1,
        pt: 3,
        position: 'relative'
      }}
    >
      <IconButton
        size="small"
        sx={{
          position: 'absolute',
          right: 2,
          top: 2
        }}
        onClick={() => setHideTeamWarning(true)}
      ><CloseIcon fontSize="small" /></IconButton>
      {`Text Blaze supports a maximum of 5 teams for non-administrators. You are currently in ${teamsCount} teams. We won't show deleted folders from your teams until you are in 5 or fewer teams.`}
    </Alert>
  );
  return (
    <TrashedFirestore
      collection="groups"
      toolbarItems={isOrgOwner && (
        <Box
          sx={{
            display: 'flex',
            px: 2,
            py: 1
          }}
        >
          <Tooltip
            title={SHOW_ALL_FOLDERS_TOOLTIP}
          >
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  size="small"
                  checked={showAll}
                  onChange={(evt) => setShowAll(evt.target.checked)}
                />
              }
              label={SHOW_ALL_FOLDERS_LABEL}
              sx={{
                ml: 0,
                fontSize: 2,
                '*': {
                  fontSize: '0.8rem'
                }
              }}
            />
          </Tooltip>
        </Box>
      )}
      footer={teamWarning}
      labels={label}
      filters={filters}
      onRestore={async (group) => {
        const groupId = group.id;
        await restoreGroup(groupId);
        await enableGroup(groupId);
        setNavigateToOnClose(`/folder/${groupId}`);
      }}
      IconComponent={GroupIcon}
      IconComponentProps={{
        size: 28,
        iconStyle: {
          fontSize: 28
        }
      }}
    />
  );
};

export default TrashedGroups;