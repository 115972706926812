import { LinearProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { restartedOrgBilling, updatedCardDetails } from './billing_utils';

const UpdateCard = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const type = query.get('type');
  useEffect(() => {
    const origin = query.get('origin');
    const redirectStatus = query.get('redirect_status');
    if (redirectStatus !== 'succeeded') {
      return;
    }
    if (type === 'restart-org') {
      restartedOrgBilling().then(() => {
        history.replace(origin);
      });
    } else {
      updatedCardDetails(true).then(() => {
        history.replace(origin);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return <LinearProgress />;
};

export default UpdateCard;