import React from 'react';
import {
  DialogTitle, IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

/**
 * 
 * @param {object} props 
 * @param {React.ReactNode} props.children 
 * @param {() => any} props.onClose 
 * @param {boolean=} props.disabled - Disables the close button. 
 * @returns 
 */
const TrashedDialogTitle = ({
  children,
  onClose,
  disabled
}) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <DialogTitle
      sx={{
        backgroundColor: 'grey.100',
        pr: 6
      }}
    >
      {children}
      <IconButton
        onClick={handleClose}
        disabled={disabled}
        aria-label="Close"
        sx={{
          position: 'absolute',
          transform: 'translate(50%, -50%)',
          right: 28,
          top: 32
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};


export default TrashedDialogTitle;