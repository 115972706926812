import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import AsyncButton from '../AsyncButton/AsyncButton';
import DialogActions from '@mui/material/DialogActions';
import ReactDOM from 'react-dom';
import Tooltip from '@mui/material/Tooltip';
import InsertEmoticonIcon from '@mui/icons-material/AddReactionOutlined';
import IconButton from '@mui/material/IconButton';
import EmojiSelector from '../Messaging/LazyEmojiSelector';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

/**
 * @typedef {object} OnCreatedSpaceProps
 * @property {string} databaseId
 * @property {string} tableId
 */

/**
 * @typedef {object} CreateDatabaseDialogConfig
 * @property {boolean=} createDefaultTable
 * @property {number=} maxOrder
 * @property {(ids: OnCreatedSpaceProps) => void=} onCreated
 * @property {() => void=} onError
 */

/**
 * @typedef {object} CreateDatabaseDialogProps
 * @property {CreateDatabaseDialogConfig=} config
 * @property {string=} itemType
 * @property {(onClose: () => void, config: CreateDatabaseDialogConfig) => React.ReactElement=} renderMoreActions
 * @property {(config: CreateDatabaseDialogConfig, name?: string, icon?: string, onClose: () => void, done: () => void) => void=} createFn
 * @property {React.Ref<{ show: (config?: any) => void }>=} ref
 */

/**
 * @type {React.FC<CreateDatabaseDialogProps>}
 */
export const CreateDatabaseDialog = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [config, setConfig] = useState(null);
  const [name, setName] = useState('');
  const createButton = useRef(null);
  const emojiButtonRef = useRef(null);
  const [showEmoji, setShowEmoji] = useState(false);
  const [icon, setIcon] = useState(null);

  useImperativeHandle(ref, () => ({
    show: (config) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setName('');
        setConfig(config);
        setIsOpen(true);
      });
    }
  }));

  if (!isOpen) {
    return null;
  }

  const closeFn = () => setIsOpen(false);
  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="lg"
        onClose={closeFn}
        data-testid="create-new-space-dialog"
      >
        <DialogTitle>
          Create a new {props.itemType}
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: {
              xs: 'min(calc(100% - 40px), 370px)',
              sm: '370px'
            }
          }}>
          <span>Choose a name and an icon for the new {props.itemType}:</span>
          <div style={{
            marginTop: 24,
            marginBottom: 24,
            width: 442,
          }}>
            <OutlinedInput
              size="small"
              style={{
                width: '100%',
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  // call the button itself to show loading state
                  createButton.current.click();
                }
              }}
              autoFocus
              placeholder={`My ${props.itemType}`}
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              sx={{
                paddingLeft: 0,
              }}
              startAdornment={
                <InputAdornment position="end">
                  <IconButton
                    ref={emojiButtonRef}
                    sx={{
                      mr: 1,
                    }}
                    onClick={() => {
                      setShowEmoji(!showEmoji);
                    }}
                  >
                    <Tooltip title={`Click to change the ${props.itemType}'s icon.`}>
                      {icon ? <div
                        translate="no"
                        style={{
                          fontSize: '20px',
                          lineHeight: '20px',
                          width: 20,
                          height: 20,
                          verticalAlign: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'black',
                        }}>{icon}</div> : <InsertEmoticonIcon fontSize="small" style={{
                        opacity: .6
                      }} />}
                    </Tooltip>
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          {!!props.renderMoreActions && props.renderMoreActions(closeFn, config)}
          {showEmoji && <EmojiSelector
            target={emojiButtonRef.current}
            removeLabel="Remove icon"
            showRemove={!!icon}
            placement="auto"
            onClose={() => {
              setShowEmoji(false);
            }}
            onSelect={async (emoji) => {
              setShowEmoji(false);
              setIcon(emoji);
            }}
          />}
        </DialogContent>
        <DialogActions style={{ marginRight: 10, marginBottom: 15 }}>
          <Button
            onClick={closeFn}
            variant="outlined"
            style={{ marginRight: 12 }}
          >Cancel</Button>
          <AsyncButton
            ref={createButton}
            disabled={!name}
            color="primary"
            onClick={(done) => props.createFn(config, name, icon, closeFn, done)}
            variant="contained"
          >Create</AsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
});
