import React, { useLayoutEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ReactList from 'react-list';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Chip from '@mui/material/Chip';
import AIIcon from '@mui/icons-material/AutoFixHigh';

import Shortcut from '../Shortcut/Shortcut';
import { highlightSection } from '../Search/sort_snippets';
import { isAndroid } from '../../flags';
import { EmptyState } from '../EmptyState/EmptyState';
import { sendToAndroid } from '@store';
import { itemStyleFn } from '../SnippetList/shared';
import { EndButtonItem, GroupItem } from './FolderView';


/**
 * @param {object} props
 * @param {SnippetObjectType[]} props.snippets
 * @param {string[]} [props.groupIds]
 * @param {Object[]} props.searchResults
 * @param {Object<string, GroupObjectType>} props.groups
 * @param {Object} [props.user]
 * @param {Object<string, boolean>} [props.disabledGroups]
 * @param {string} props.search
 * @param {number} props.highlightedIndex
 * @param {object} props.listRef
 * @param {boolean} props.insertable
 * @param {string} [props.view]
 * @param {React.KeyboardEventHandler<HTMLUListElement>} props.handleArrows
 * @param {any} props.insert
 * @param {(arg: { id: string, globalIndex: number, groupId: string, }) => void} props.select
 * @param {any} props.conflicts
 * @param {Object} [props.collapsed]
 * @param {any} [props.setCollapsed]
 * @param {(snippet: SnippetObjectType|PageObjectType) => string} props.itemTextFn
 * @param {string} [props.noMatchDescription]
 */
function SearchResultsBase(props) {
  let snippets = props.snippets;
  const isAndroidFlag = isAndroid();

  useLayoutEffect(() => {
    let item = null;
    let items = document.getElementsByClassName(`list-item-${props.highlightedIndex}`);
    if (items.length === 1) {
      item = items[0];
    }
    if (!item) {
      return;
    }

    item.scrollIntoView({
      behavior: 'auto',
      block: 'nearest'
    });
  }, [props.highlightedIndex, props.view]);

  /**
   * @param {string} group_id 
   */
  function isDisabledGroup(group_id) {
    return props.disabledGroups && group_id && props.disabledGroups[group_id];
  }

  if (snippets.length === 0 && props.search) {
    return <div className="no-snippets">
      <EmptyState
        icon={ZoomOutIcon}
        title="Nothing matched your search"
        description={props.noMatchDescription || 'Try searching for something else...'}
      />
    </div>;
  }

  if (!props.search && props.view === 'folder') {
    let globalIndex = 0;
    return <List dense onKeyDown={props.handleArrows}>
      {props.groupIds.map((id) => {
        if (props.groups[id]) {
          const item = (<GroupItem 
            {...props.groups[id]} 
            key={id}
            globalIndex={globalIndex}
            insertable={props.insertable}
            insert={props.insert}
            select={props.select}
            handleArrows={props.handleArrows}
            conflicts={props.conflicts}
            highlightedIndex={props.highlightedIndex}
            collapsed={props.collapsed[id] || false}
            setCollapsed={props.setCollapsed}
          />);
          globalIndex += (props.collapsed[id] ? 0 : (props.groups[id].snippets || []).length) + 1;
          return item;
        }
        return null;
      })}
    </List>;
  }

  return <div>
    <List
      dense
      className="micro-list"
      onKeyDown={props.handleArrows}
    >
      <ReactList
        pageSize={20}
        threshold={600}
        useStaticSize
        ref={props.listRef}
        initialIndex={props.highlightedIndex - 5} // To avoid the view toggle from being hidden
        itemRenderer={(itemIndex) => {
          let snippet = snippets[itemIndex];
          let snipText = props.itemTextFn(snippet);

          let selected = props.highlightedIndex === itemIndex;
          let style = itemStyleFn({
            selected: selected,
            depth: 0
          });

          

          // we set border radius below so need to delete
          // this prop.
          delete style.borderRadius;

          let group = props.groups[snippet.group_id];
          let groupIsDisabled = isDisabledGroup(snippet.group_id);
          const groupName = group ? group.name : '';
          const [snipNameHighlight, textHighlight, groupNameHighlight, shortcutHighlight] =
            highlightSection(snippet.name, snipText, groupName, snippet.shortcut, props.searchResults && props.searchResults[itemIndex], props.search);

          let styleObj = Object.assign(style, {
            borderTopRightRadius: 100,
            borderBottomRightRadius: 100,
          });
          if (groupIsDisabled) {
            styleObj = Object.assign(styleObj, {
              opacity: 0.45,
            });
          }


          return <ListItem
            key={itemIndex}
            button
            style={styleObj}
            onClick={() => {
              if (isAndroidFlag) {
                sendToAndroid({
                  type: 'snippet_copy',
                  params: {
                    snippet_id: snippet.id,
                    group_id: snippet.group_id
                  }
                });
                return;
              }
              props.select({ id: snippet.id, globalIndex: itemIndex, groupId: snippet.group_id, });
            }}>
            <ListItemText
              primaryTypographyProps={{ 
                component: 'div'
              }}
              primary={<div style={{
                whiteSpace: 'nowrap',
                display: 'flex',
                height: 22,
                fontWeight: style.color ? 500 : undefined,
                // have shortcut name & group name left aligned
                // and shortcut chip right aligned
                // with space in between them
                justifyContent: 'space-between'
              }}>
                <div style={{
                  whiteSpace: 'nowrap',
                  fontSize: 16,
                  paddingRight: 16,
                  display: 'flex',
                  overflow: 'hidden'
                }}>
                  {/* Element needed so Google Translate doesn't crash React */}
                  <Box sx={{
                    flex: 1,
                    display: 'flex',
                    overflow: 'hidden',
                    alignItems: 'center',
                    maxWidth: 'fit-content'
                  }}>
                    {snippet.options?.is_ai ? <AIIcon
                      fontSize="small"
                      sx={{
                        opacity: .7,
                        zoom: .9
                      }}
                    /> : null}
                    <Box sx={{
                      marginLeft: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>{snipNameHighlight}</Box>
                  </Box>
                  
                  {props.search && group && <Box sx={{
                    flex: 1,
                    paddingLeft: 2.5,
                    overflow: 'hidden',
                    maxWidth: 'fit-content'
                  }}>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={(
                        <span style={{ whiteSpace: 'pre' }}>
                          {groupIsDisabled && <b>Disabled folder: </b>}
                          <span>{groupNameHighlight}</span>
                        </span>
                      )}
                      sx={{
                        color: 'rgba(0, 0, 0, .7)',
                        fontWeight: 400,
                        width: '100%',
                        border: 'none',
                        backgroundColor: 'rgba(0, 0, 0, .05)'
                      }}
                    />
                  </Box>}
                </div>
                <div style={{
                  verticalAlign: 'middle',
                  whiteSpace: 'nowrap',
                  display: 'inline-flex',
                  alignItems: 'center',
                  position: 'relative',
                  maxWidth: '50%'
                }}>
                  <EndButtonItem shouldShow={!isAndroidFlag && props.insertable} onClick={() => {
                    props.insert(snippet.id, snippet.group_id);
                  }} title={'Insert Snippet' + (selected ? ' (Enter)' : '')} />
        
                  <Shortcut
                    style={{
                      cursor: 'pointer',
                      color: style.color,

                      // using a slightly lighter color for the border looks better
                      borderColor: style.color && '#009faf' 
                    }}
                    shortcut={shortcutHighlight}
                    hasConflict={props.conflicts && props.conflicts.has(snippet.shortcut.toLocaleLowerCase())}
                  />
                </div>
              </div>}
              secondary={props.search && <span style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: 'block'
              }}>{textHighlight}</span>}
            />
          </ListItem>;
        }}
        length={snippets.length}
        type="uniform"
      />
    </List>
  </div>;
}


const SearchResults = React.memo(SearchResultsBase);
export default SearchResults;