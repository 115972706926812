import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const MAX_TOAST_VISIBLE_AT_ONCE = 3;
let messageId = 0;

/**
 * @typedef {(remove: () => void) => React.ReactNode} SnackbarActionType
 * 
 * @typedef {{ duration?: number, intent?: 'success'|'warning'|'info'|'danger', upgrade?: string, snackbarStyle?: React.CSSProperties, disableAutoHide?: boolean, action?: SnackbarActionType }} MessageConfigType
 * @typedef {{ severity: import('@mui/material/Alert').AlertProps['severity'], messageId: number, autoHideDuration: number, action?: SnackbarActionType, snackbarStyle?: React.CSSProperties, } } SnackbarConfigType
 * 
 * @typedef {{ isOrg?: () => boolean, store?: import('redux').Store, showUpgradeFn?: Function }} ToastPropsType
 * @typedef {{ show: (msg: string|React.ReactElement, config: MessageConfigType) => number, remove: (id: number) => number, removeAll: () => void, }} ToastRefType
 */

const MessageDisplayInner = (/** @type {ToastPropsType} */ props, /** @type {React.Ref<ToastRefType>} */ ref) => {
  let [messages, setMessages] = useState(/** @type {{ snackbarConfig: SnackbarConfigType, msg: string|React.ReactElement }[]} */ ([]));

  const removeMessageFn = useCallback((/** @type {number} */ messageIdToRemove) => {
    setMessages((m) => {
      let newMessages = m.slice();
      let i = newMessages.findIndex(x => x.snackbarConfig.messageId === messageIdToRemove);
      if (i > -1) {
        newMessages.splice(i, 1);
      }
      return newMessages;
    });
    return messageIdToRemove;
  }, []);

  useImperativeHandle(ref, () => ({
    show: (msg, config = { duration: 4000 }) => {
      messageId++;

      /** @type {SnackbarConfigType} */
      const snackbarConfig = {
        severity: config.intent ? { success: 'success', warning: 'warning', info: 'info', danger: 'error' }[config.intent.toLowerCase()] || 'info' : 'info',
        autoHideDuration: config.disableAutoHide ? null : (config.duration || 4000),
        messageId,
        snackbarStyle: config.snackbarStyle,
        action: config.action
      };

      if (config.upgrade && !props.isOrg()) {
        props.showUpgradeFn(msg, config);
        // return and don't show the toast
        return;
      }

      setMessages((m) => {
        let newMessages = m.concat({ msg, snackbarConfig });
        if (newMessages.length > MAX_TOAST_VISIBLE_AT_ONCE) {
          newMessages = newMessages.slice(-MAX_TOAST_VISIBLE_AT_ONCE);
        }
        return newMessages;
      });

      return messageId;
    },
    remove: removeMessageFn,
    removeAll: () => {
      messages.forEach(x => {
        removeMessageFn(x.snackbarConfig.messageId);
      });
    },
  }));

  return <>
    {messages.map((message, i) => {
      let { msg, snackbarConfig } = message;
      const remove = () => removeMessageFn(snackbarConfig.messageId);

      return <Snackbar
        open
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        key={snackbarConfig.messageId}
        autoHideDuration={snackbarConfig.autoHideDuration}
        onClose={(_e, reason) => {
          if (reason === 'timeout') {
            remove();
          }
        }}
        sx={{
          position: 'fixed',
          top: (80 * i + 24) + 'px !important',
          ...snackbarConfig.snackbarStyle,
        }}
      >
        <Alert
          severity={snackbarConfig.severity}
          onClose={() => {
            remove();
          }}
          action={snackbarConfig.action ? snackbarConfig.action(remove) : undefined}
          elevation={6} 
          variant="filled"
        >
          {msg}
        </Alert>
      </Snackbar>;
    })}
  </>;
};

export default forwardRef(MessageDisplayInner);