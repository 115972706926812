import React from 'react';
import { getData } from '../DataDialog/DataDialog';
import { storage } from '../../utilities';
import T from '@mui/material/Typography';
import { getDoc } from 'firebase/firestore';
import { makeRef } from '../../firebase_utilities';


/*
* @param {any[]} fields 
* @returns 
*/
export function editableFields(fields) {
  return fields && fields.filter(f => f.sharing !== 'master');
}


/**
 * @param {any[]} fields 
 * @returns 
 */
export function userEditableFields(fields) {
  return fields && fields.filter(f => f.sharing === 'editable');
}


/*
* @param {any[]} fields 
* @returns 
*/
export function hasEditableFields(fields) {
  return fields && editableFields(fields).length > 0;
}


/*
* @param {any[]} fields 
* @returns 
*/
export function hasUserEditableFields(fields) {
  return fields && userEditableFields(fields).length > 0;
}


export const userEditIcon = <svg aria-hidden="true" focusable="false" style={{ width: 18 }} viewBox="0 0 640 512"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h274.9c-2.4-6.8-3.4-14-2.6-21.3l6.8-60.9 1.2-11.1 7.9-7.9 77.3-77.3c-24.5-27.7-60-45.5-99.9-45.5zm45.3 145.3l-6.8 61c-1.1 10.2 7.5 18.8 17.6 17.6l60.9-6.8 137.9-137.9-71.7-71.7-137.9 137.8zM633 268.9L595.1 231c-9.3-9.3-24.5-9.3-33.8 0l-37.8 37.8-4.1 4.1 71.8 71.7 41.8-41.8c9.3-9.4 9.3-24.5 0-33.9z"></path></svg>;


/**
 * 
 * @param {import('firebase/firestore').DocumentReference} orgDb 
 */
export async function editUser(orgDb, type, email, fields, onFinish) {
  if (type === 'self') {
    fields = userEditableFields(fields);
  } else {
    fields = editableFields(fields);
  }

  let docRef = makeRef(orgDb, 'members', email);

  let fieldDoc = await getDoc(docRef);
  let defaults = {};
  for (let field of fields) {
    defaults[field.name] = field.default;
  }
  let exists = fieldDoc.exists();
  if (exists) {
    let docData = fieldDoc.data();
    if (docData && docData.member_fields_data) {
      Object.assign(defaults, docData.member_fields_data);
    }
  }

  getData({
    title: type === 'self' ? 'Edit my organization properties' : 'Edit properties for ' + email,
    applyButton: 'Save',
    defaults,
    footer: <T variant="caption" color="textSecondary" style={{ marginTop: 16, display: 'inline-block' }}>Changes may take up to a minute to be applied.</T>,
    onCancel: onFinish,
    fields: fields.map(field => {
      let f = Object.assign({}, field);
      f.nameLocked = true;
      f.required = false;
      
      return {
        id: field.name,
        attribute: f,
        // rawText means attribute escaping won't be used on text fields
        rawText: true
      };
    }),
    onSubmit: async res => {
      let newFields = {};
      for (let item of res) {
        if (item.value !== undefined) {
          newFields[(exists ? 'member_fields_data.' : '') + item.id] = item.value;
        }
      }
      if (Object.keys(newFields).length) {
        if (exists) {
          await storage.update(docRef, newFields, 'HIDE_AUTOSAVE');
        } else {
          await storage.set(docRef, {
            member_fields_data: newFields
          }, {
            merge: true
          }, 'HIDE_AUTOSAVE');
        }
      }
      onFinish();
    }
  });
}