import { onClarityLoaded } from '../raw_flags';
import Base from './Base';

/**
 * @param {string} event
 * @returns 
 */
function normalizeExperimentString(event) { 
  // Same as the normalization in `ps` backend function
  return event.toUpperCase().replace(/ /g, '_');
}

/**
 * Example url for overriding during testing:
 *   http://localhost:3000/?experimentOverride=Auto-Write&singleton=show
 * 
 * @param {string} experimentName - Name of the experiment
 * @param {object} parameters 
 * @param {'WeightedChoice'} parameters.type
 * @param {T[]} parameters.choices
 * @param {number[]} parameters.weights
 * @param {'user'|'org'} unit - Defaults: 'user'
 * @return {T} 
 * @template {string} T
 */
export function randomize(experimentName, parameters, unit = 'user') {
  if (!['user', 'org'].includes(unit)) {
    throw Error('"unit" must be one of "user" or "org"');
  }

  if (parameters.type) {
    // It's a single item, so we'll create the object with a 'singleton property to represent it
    parameters = {
      // @ts-ignore
      singleton: parameters
    };
  }

  if (!(experimentName in experiments)) {
    experiments[experimentName] = new Base(experimentName, parameters, unit === 'user' ? userId : orgId);
  }

  const values = experiments[experimentName].values();

  const result = ('singleton' in values) ? values.singleton : values;

  // When user is in a Dashboard window
  if (typeof window !== 'undefined') {
    onClarityLoaded(() => {
      const clarityKey = normalizeExperimentString('experiment ' + experimentName);
      window['clarity']('set', clarityKey, result);
    });
  }

  return result;
}


let experiments = {};
let orgId, userId;


export function setExperimentOrgId(id) {
  orgId = id;
  experiments = {};
}


export function setExperimentUserId(id) {
  userId = id;
  experiments = {};
}