import { logproto } from './Proto';
import base64 from '@protobufjs/base64';
const LogMessage = logproto.Log;

/**
 * Log POGO -> Proto
 * 
 * @param {object} pulses
 * 
 * @return {Array}
 */
export function compressLog(pulses) {
  let arr = LogMessage.encode(LogMessage.create({ events: pulses })).finish();
  return [arr.length, base64.encode(arr, 0, arr.length)];
}


/**
 * Log Proto -> POJO
 * 
 * @param {Array|Uint8Array} input
 * 
 * @return {object}
 */
export function decompressLog(input) {
  let array;
  if (Array.isArray(input)) {
    array = new Uint8Array(input[0]);
    base64.decode(input[1], array, 0);
  } else if (input instanceof Uint8Array) {
    array = input;
  } else {
    throw Error('Unknown log input: ' + (typeof input));
  }

  let message = LogMessage.decode(array);
  let obj =  LogMessage.toObject(message, {
    enums: String,  // enums as string names
    arrays: true   // populates empty arrays (repeated fields) even if defaults=false
  });

  return obj.events;
}