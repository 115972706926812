import { makeConfig, snippetPreviewRemoteFn } from '../SnippetPreview/preview_utilities';
import { getState } from '../../getState';
import { userType } from '../../flags';
import { toast } from '../../message';


/**
 * @return {import("../../snippet_processor/DataContainer").Config}
 */
export function makePageConfig(configOverride = {}) {
  let state = getState();

  let email = state.userState.email;
  let locale = state.userState.locale;
  let role = userType(state);

  return makeConfig({
    config: Object.assign({
      snippet: {},
      user: {
        email: email || 'user@example.com',
        role
      },
      locale,
      blockingErrorFn: (error) => {
        toast(error, {
          duration: 8000,
          intent: 'danger'
        });
      },
      commandWhitelistErrorFn: (command) => `You cannot use the {${command}} command in a page.`,
      commandWhitelist: [
        'FORMMENU',
        'FORMTEXT',
        'FORMPARAGRAPH',
        'FORMTOGGLE',
        'FORMDATE',
        'TIME',
        'USER',
        '=',
        'IF',
        'REPEAT',
        'ERROR',
        'SNIPPET',
        'URLLOAD',
        'NOTE'
      ],
      remoteFn: snippetPreviewRemoteFn,
    }, configOverride)
  });
}