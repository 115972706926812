import { useLocation } from 'react-router-dom';

const PUBLIC_URL_PATTERNS = [
  /^\/public\/grid\/[^/]+\/?$/,
  /^\/public\/form\/[^/]+\/?$/,
  /^\/public\/gallery\/[^/]+\/?$/,
  /^\/public\/embed\/space(\/)?$/,
  /^\/datablaze\/import(\/)?$/,
  /^\/upgrade_(business|pro)\/?$/,

  // SSRed pages:
  /^\/gallery\/[^/]+\/?$/,
  /^\/integration\/[^/]+\/?$/,
  /\/templates(.*)/,
];

/**
 * @return {boolean}
 */
export const useIsPublicUrl = () => {
  const location = useLocation();
  if (location.hash === '#signin') {
    // used to popup login screen over the page
    return false;
  }
  return PUBLIC_URL_PATTERNS.some(pattern => pattern.test(location.pathname));
};
