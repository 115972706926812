import { createDom } from '../../snippet_processor/SnippetProcessor';
import { decompressDelta } from '../../delta_proto/DeltaProto';
import { Environment } from '../../snippet_processor/DataContainer';
import { featureUsage } from '../Version/limitations';
import { makeConfig } from './../SnippetPreview/preview_utilities';
import { sync } from '../../Sync/syncer';
import { APP_TARGET } from '../../flags';

/** @typedef {{connectedAddons: string[], loadHosts: string[], databaseQueries: Object<string, *[]>, pingHosts: string[]}} ConnectedAttributes */

/**
 * @param {SnippetObjectType[]} snippets
 * @param {string} [onlyDatabaseId] if supplied, only database queries and only for this database id are returned
 * @returns {Promise<ConnectedAttributes>}
 */
export default async function getDatabaseQueries(snippets, onlyDatabaseId = null) {
  return getGroupOrSiteDatabaseQueries(
    snippets.map(snippet => ({ delta: decompressDelta(snippet.content.delta.toUint8Array()) })),
    (name) => {
      let snippets = sync.getSnippetsByShortcut(name.toLocaleLowerCase());
      if (snippets && snippets.length) {
        return {
          delta: snippets[0].data.content.delta.toUint8Array()
        };
      }
    },
    onlyDatabaseId,
  );
}

/**
 * @param {{delta: any}[]} snippets
 * @param {(name: string) => {delta: any}} findSnippet
 * @param {string} [onlyDatabaseId]
 * @returns {Promise<ConnectedAttributes>}
 */
export async function getGroupOrSiteDatabaseQueries(snippets, findSnippet, onlyDatabaseId = null) {
  const config = makeConfig({
    config: {
      stage: 'preview',
      doNotPullInAddons: true,
      addons: sync.activeAddons(),
      snippet: {},
      user: {},
      findSnippet,
    }
  });

  /** @type {Set<string>} */
  const pingHosts = new Set();
  /** @type {Set<string>} */
  const loadHosts = new Set();
  /** @type {Set<string>} */
  const connectedAddons = new Set();
  /** @type {Object<string, string[]>} */
  const databaseQueries = {};

  await Promise.all(snippets.map(async (snippet) => {
    const env = new Environment({}, config);
    const dom = await createDom(snippet.delta, env);
    const usage = await featureUsage(dom, env);

    if (usage.DATABASES) {
      for (const databaseId in usage.DATABASES) {
        if (onlyDatabaseId && databaseId !== onlyDatabaseId) {
          continue;
        }

        if (!databaseQueries[databaseId]) {
          databaseQueries[databaseId] = [];
        }
        for (const item of usage.DATABASES[databaseId]) {
          databaseQueries[databaseId].push(item);
        }
      }

      for (const id in databaseQueries) {
        databaseQueries[id] = [...new Set(databaseQueries[id])];
      }
    }

    if (!onlyDatabaseId && APP_TARGET !== 'PAGE') {
      if (usage.PING_HOSTS) {
        for (let host of usage.PING_HOSTS) {
          pingHosts.add(host);
        }
      }

      if (usage.LOAD_HOSTS) {
        for (let host of usage.LOAD_HOSTS) {
          loadHosts.add(host);
        }
      }

      if (usage.CONNECTED_ADDONS) {
        for (let addon of usage.CONNECTED_ADDONS) {
          connectedAddons.add(addon);
        }
      }
    }
  }));

  return { loadHosts: [...loadHosts], connectedAddons: [...connectedAddons], pingHosts: [...pingHosts], databaseQueries };
}