import React, { useState, useRef, useEffect, useContext } from 'react';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import '../SnippetEditor/quill.css';
import 'quill-better-table/src/assets/quill-better-table.scss';
import '../../../css/blueprint_lpt3.css';
import '../SnippetEditor/EmbeddedCommand/embedded_command.css';
import { importText } from '../../import_export/DeltaImport';
import { useIsMounted } from '../../hooks';
import {
  convertDeltaTypeToString,
  expandDeltaContents,
  getCollapsedData
} from '../SnippetEditor/editor_utilities';
import createQuillEditor from '../SnippetEditor/quill_editor';
import { Box } from '@mui/system';
import { SnippetEditorContext } from '../Snippet/SnippetWrapper';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Environment } from '../../snippet_processor/DataContainer';
import { tokenize } from '../../snippet_processor/Parser';
import './quill.css';
import { getCollapsedCommandBlot } from './highlighter_utilities';
import { highlightFormulaOps } from '../FormulaEditor/syntax_highlighters';
import { FUNCTIONS } from '../../snippet_processor/Equation';
import useOnMount from '../../hooks/useOnMount';
import { getPlainRegistry } from './quillConfig';
import { AttributeFormulaBlot } from './highlighter';

const functionsConfig = Object.values(FUNCTIONS);
/**
 * @param {DeltaType} delta
 * 
 * @return {string}
 */
function deltaToString(delta) {
  if (!delta) {
    return '';
  }
  return delta.ops.map(x => typeof x.insert === 'string' ? x.insert : '').join('');
}


/**
 * @param {object} props
 * @param {string} props.value
 * @param {boolean=} props.disabled
 * @param {(value: string) => any} props.onChange
 * @param {('text' | 'constant' | 'formula' | 'sql' | 'iterator'|'block')=} [props.attributeType='text']
 * @param {string} props.attributeName
 * @param {React.CSSProperties} props.style
 * @param {string} props.placeholder
 * @param {boolean=} props.error
 * @param {number=} props.startIndex
 */
function AttributeQuillEditorBase(props) {
  let attributeType = props.attributeType || 'text';
  
  const snippetEditorContext = useContext(SnippetEditorContext);

  let quillRef = useRef(
    /** @type {import('quill').default} */ (null)
  );
  let quillNodeRef = useRef(/** @type {HTMLDivElement} */ (null));
  let quillContainerRef = useRef(/** @type {HTMLDivElement} */ (null));
  let propsRef = useRef(props);
  propsRef.current = props;
  let highlighter = useRef(null);

  let isMounted = useIsMounted();
  const [currentValue, setCurrentValue] = useState('');
  const attributeSet = useRef(/** @type {import('../Snippet/SnippetWrapper').SnippetWrapperContext['attribute']} */ (null));

  useOnMount(() => {
    const quill = createQuillEditor({
      quillNodeRef: quillNodeRef.current,
      quillContainerNodeRef: quillContainerRef.current,
      quillModules: {
        snippetsyntax: true,
        toolbar: false
      },
      placeholder: props.placeholder,
      registry: getPlainRegistry('formula', [ AttributeFormulaBlot ])
    });
    quillRef.current = quill;
    highlighter.current = quill.getModule('snippetsyntax');
    highlighter.current.isCollapsedEnabled = true;
    highlighter.current.addons = snippetEditorContext.addons || {};
    highlighter.current.attributeType = attributeType;
    highlighter.current.highlight(true);
    highlighter.current.contextCallback = contextChanged;
    const scrollDomNode = quill.scroll.domNode;
    if (snippetEditorContext.setAttribute) {
      /**
       * We need to understand if focus and selection both exists.
       * In the case when we click a chip this editor, it triggers focus event.
       */
      let hasSelection = false,
        hasFocus = false;
      quill.on('selection-change', (range) => {
        const currentSelection = !!range;
        if (hasFocus && currentSelection && !hasSelection) {
          focusedInto();
        }
        hasSelection = currentSelection;
      });
      scrollDomNode.addEventListener('focus', (e) => {
        hasFocus = true;
        if (hasSelection) {
          focusedInto();
        }
      });
      scrollDomNode.addEventListener('blur', () => {
        hasFocus = false;
        focusedOut();
      });
      let focusedInto = () => {
        snippetEditorContext.setAttributeFocused(true);
        snippetEditorContext.setInsertIntoAttribute(insert);
        attributeSet.current = {
          type: attributeType,
          element: quillContainerRef.current
        };
        snippetEditorContext.setAttribute(attributeSet.current);
      };
      let focusedOut = () => {
        attributeSet.current = null;
        snippetEditorContext.setAttributeFocused(false);
      };
    }
    
    quill.on('text-change', (_newDelta, _oldDelta, source) => {
      if (source === 'api') {
        // pass
      } else if (source === 'user') {
        
        let newDelta = /** @type {DeltaType} */ (_oldDelta.compose(_newDelta));
  
        let newValue = deltaToString(expandDeltaContents(newDelta));
        // Remove last new line
        newValue = fixLastLine(newValue, props.attributeType === 'block');
        propsRef.current.onChange(newValue);
        setCurrentValue(newValue);
        highlightFormulaInQuill(quill, newValue, attributeType);
      }
    });

  }, () => {
    if (snippetEditorContext.setAttribute) {
      snippetEditorContext.setAttribute(null);
    }
  });

  useEffect(() => {
    const quill = quillRef.current;
    if (!quill) {
      return;
    }
    let value = props.value;
    if (currentValue === value) {
      return;
    }
    if (props.attributeType === 'block') {
      value = fixLastLine(value);
    }

    const range = quill.getSelection();
    let newDelta = /** @type {any} */ (importText(value));
    quill.setContents(newDelta, 'api');
    highlighter.current.highlight(true);
    setCurrentValue(value);
    if (range) {
      quill.setSelection(range.index, range.length);
    }
    highlightFormulaInQuill(quill, convertDeltaTypeToString(newDelta), props.attributeType);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  useEffect(() => {
    if (quillRef.current) {
      if (props.disabled) {
        quillRef.current.disable();
      } else {
        quillRef.current.enable();
      }
    }
  }, [props.disabled]);

  useDeepCompareEffect(() => {
    let newAddons = snippetEditorContext.addons || {};
    if (highlighter.current && Object.keys(newAddons).length !== Object.keys(highlighter.current.addons).length) {
      highlighter.current.addons = newAddons;
      // TODO: there appears to be a bug in quill where this will trigger
      // an update with 'user' rather than 'api'/'silent' from the highlighter.
      // Causing the snippet to be saved when the dashboard is opened on the snippet.
      //
      // You have highlight:
      //   normal commands -> loaded addons -> highlighted addons (trigger update)
      highlighter.current.highlight(true);
    }
  }, [!!highlighter.current, Object.keys(snippetEditorContext.addons || {}).sort()]);

  function contextChanged(newContext) {
    if (!isMounted.current) {
      return;
    }
    if (!snippetEditorContext.setSubAttributeSelected) {
      return;
    }

    if (!newContext.collapsedSelected) {
      return;
    }
    let attributesPath = [{
      name: props.attributeName,
      index: quillRef.current.getIndex(getCollapsedCommandBlot(newContext.collapsedSelected.node)),
      skip: props.startIndex,
      collapsed: true
    }];

    if (newContext.collapsedSelected.data.meta.attributesPath) {
      attributesPath = [
        ...attributesPath,
        ...newContext.collapsedSelected.data.meta.attributesPath
      ];
    }
    snippetEditorContext.setSubAttributeSelected({
      attributeName: newContext.attributeName,
      attributesPath
    });

  }

  /**
   * Inserts text into the current Quill instance.
   * 
   * @param {string} txt 
   */
  async function insert(txt) {
    const el = quillRef.current,
      currentLength = el.getLength(),
      val = el.getText(0, currentLength);
    let fnParts = functionsConfig.find(fn => fn.placeholder === txt)?.parts;

    let range = el.getSelection(true);
    if (range === null) {
      // When the snippet is initially viewed but the editor hasn't
      // been focused, the selection will be at the start of the doc.
      // But we want the insert to happen at the end.
      range = { index: currentLength - 1, length: 0 };
    }

    let loc = range.index;
    const isRemoteCommandInserted = ['dbinsert', 'dbselect', 'dbupdate', 'dbdelete', 'urlsend', 'urlload'].some(x => txt.startsWith(`{${x}:`));
    if (fnParts && range.length > 0) {
      let [part1, part2] = fnParts;
      el.insertText(range.index, part1, 'user');
      el.insertText(range.index + range.length + part1.length, part2, 'user');
      el.setSelection(range.index + range.length + part1.length + part2.length, 0);
      el.focus();
      return;
    } else if (range.length > 0) {
      el.deleteText(range.index, range.length, 'user');
    } else if (!!txt && ['formula', 'iterator', 'block'].includes(attributeType) && currentLength > 1) {
      if (isRemoteCommandInserted) {
        // These remote commands can only be inserted in code blocks
        // We need to handle newlines for them correctly
        const index = range.index;
        if (index === 0
          || val[index - 1] === '\n') {
          // Case 1: if caret is at the start of the current attribute, insert txt + '\n'
          // Case 3: if caret is at the start of a line in the middle of an attribute, insert txt + '\n'
          if (val[index] !== '\n') {
            txt = txt + '\n';
          }
        } else if (index + range.length === currentLength - 1
            || val[index] === '\n') {
          // Case 2: if caret is at the end of the current attribute, insert '\n' + txt
          // Case 4: if caret is at the end of a line in the middle of an attribute, insert '\n' + txt
          if (index > 0 && val[index - 1] !== '\n') {
            txt = '\n' + txt;
          }
        } else {
          // Case 5: if caret is at the center of a line, insert '\n' + txt + '\n'
          if (index > 0 && val[index - 1] !== '\n') {
            txt = '\n' + txt;
          }
          if (val[index] !== '\n') {
            txt = txt + '\n';
          }
        }
      } else {
        if (range.index + range.length === currentLength - 1) {
          // If caret is at the end of the whole attribute
          // and ends with "and" "or" "not" "in" "for" "if" "else" keywords (spaces around)
          // or ends with operands (><+\-/*&^=[,:
          let endsWithOperator = (!val.trim().length) || /((^|\s)(AND|OR|NOT|IN|FOR|IF|ELSE|ELSEIF|RETURN)|[(><+\-/*&^=[,:\]])\s*$/i.test(val);
          if (!endsWithOperator) {
            txt = ' + ' + txt;
          } else {
            txt = ' ' + txt;
          }
        }

        if (range.index === 0) {
          // If caret is at the start of the whole attribute
          txt += ' + ';
        }
      }
    }

    if (attributeType === 'sql' && /^[\w_]+$/.test(txt)) {
      txt = `@${txt}`;
    } else if (['text', 'sql'].includes(attributeType) && !/^\{.+\}?/.test(txt)) {
      txt = `{=${txt}}`;
    }
    
    const res = await tokenize({
      ops: [{
        insert: txt
      }]
    }, 'text', new Environment(null, {
      stage: 'tokenization',
      addons: highlighter.current.addons
    }));
    let txtPosition = 0;
    let rootTokenStartPosition = loc;
    let firstCommand;
    for (let partIndex = 0; partIndex < res.length; partIndex++) {
      const part = res[partIndex];
      if (part.startPosition > txtPosition) {
        el.insertText(loc, txt.slice(txtPosition, part.startPosition), 'user');
        loc += txt.slice(txtPosition, part.startPosition).length;
      }
      let collapsedData = getCollapsedData(Math.random().toString(32), part);
      if (!firstCommand) {
        firstCommand = {
          loc,
          collapsedData
        };
      }
      collapsedData.meta.rootTokenStartPosition = rootTokenStartPosition;

      el.insertEmbed(loc, 'collapsedCommand', collapsedData, 'user');
      txtPosition = part.endPosition;
      loc++;
    }
    if (txtPosition < txt.length) {
      el.insertText(loc, txt.slice(txtPosition, txt.length), 'user');
      loc += txt.slice(txtPosition, txt.length).length;
    }
    el.setSelection(loc, 0);
    el.focus();
  }
  const isFocused = (
    (snippetEditorContext.attributeFocused || snippetEditorContext.inCommandsPanel)
    && attributeSet.current === snippetEditorContext.attribute
  );
  return (
    <Box
      className="attribute-quill-editor"
      sx={[{
        border: '1px solid',
        borderColor: 'grey.400',
        borderRadius: 1,
        ':hover': {
          borderColor: 'grey.800'
        }
      }, isFocused && {
        outline: '2px solid',
        outlineOffset: -1
      }, isFocused && snippetEditorContext.attributeFocused && {
        outlineColor: (theme) => theme.palette.primary.main
      }, isFocused && snippetEditorContext.inCommandsPanel && {
        outlineColor: (theme) => theme.palette.primary.light
      }, props.error && {
        backgroundColor: 'rgb(255, 0, 0, .02)',
        transition: 'background-color .8s cubic-bezier(1,0.0,1,.64)',
        borderColor: 'error.main',
        outlineColor: (theme) => theme.palette.error.main,
        '&:hover': {
          borderColor: 'error.dark'
        }
      }]}
      style={props.style}
    >
      <div
        ref={quillContainerRef}
      >

        <div
          ref={quillNodeRef}
          spellCheck="false"
          style={{
            overflow: 'auto'
          }}
        />
      </div>
    </Box>
  );
}


const AttributeQuillEditor = React.memo(AttributeQuillEditorBase);
export default AttributeQuillEditor;

/**
 * Strings empty line which is generated by quill
 * @param {string} value 
 * @param {boolean=} needTrailingLine 
 */
const fixLastLine = (value, needTrailingLine) => {
  const isLastCharNewLine = value[value.length - 1] === '\n';
  if (needTrailingLine && !isLastCharNewLine) {
    return value + '\n';
  } else if (!needTrailingLine && isLastCharNewLine) {
    return value.substring(0, value.length - 1);
  }
  return value;
  
};

/**
 * @type {highlightFormulaInQuillAfterTimer}
 */
const highlightFormulaInQuill = (quill, content, attributeType) => {
  // @ts-ignore
  clearTimeout(quill.highlightTimer);
  // @ts-ignore
  quill.highlightTimer = setTimeout(() => {
    highlightFormulaInQuillAfterTimer(quill, content, attributeType);
  }, 10);
};

/**
 * 
 * @param {import('quill').default} quill 
 * @param {string} content 
 * @param {Parameters<typeof AttributeQuillEditor>['0']['attributeType']} attributeType 
 */
const highlightFormulaInQuillAfterTimer = (quill, content, attributeType) => {
  if (
    !['formula', 'sql', 'iterator', 'block'].includes(attributeType)
    || !quill.scroll.domNode.isConnected
  ) {
    return;
  }
  const generatedOps = highlightFormulaOps(content, {
    isSQL: attributeType === 'sql',
    isIterator: attributeType === 'iterator',
    isBlock: attributeType === 'block'
  });
  const currentOps = quill.getContents().ops;
  let generatedCursor = 0,
    currentCursor = 0,
    currentOpIndex = 0,
    currentOp = currentOps[currentOpIndex],
    currentLength = quill.getLength();
  for (let opTokenIndex = 0; opTokenIndex < generatedOps.length; opTokenIndex++) {
    while (
      generatedCursor > currentCursor + opLength(currentOp) - 1
      && currentOpIndex + 1 < currentOps.length
    ) {
      currentCursor += opLength(currentOp);
      // Move the index
      currentOp = currentOps[++currentOpIndex];
    }
    const generatedOp = generatedOps[opTokenIndex];
    if (generatedOp.attributes.token === 'command') {
      if (
        typeof currentOp.insert !== 'string'
        && 'collapsedCommand' in currentOp.insert
      ) {
        if (currentOp.attributes?.token) {
          quill.removeFormat(generatedCursor, 1, 'silent');
        }
        generatedCursor += 1;
        continue;
      } else if (
        currentOp.attributes?.replacement
      ) {
        if (currentOp.attributes?.token) {
          quill.removeFormat(generatedCursor, 1, 'silent');
        }
        generatedCursor += generatedOp.insert.length;
        continue;
      }
    }
    if (generatedCursor + generatedOp.insert.length > currentLength) {
      break;
    }

    if (
      // if token is not same
      currentOp.attributes?.token !== generatedOp.attributes.token
      // or mismatch of operations in start and length. And then force apply
      || generatedCursor + opLength(generatedOp) > currentCursor + opLength(currentOp)
    ) {
      quill.formatText(generatedCursor, opLength(generatedOp), generatedOp.attributes, 'silent');
    }
    generatedCursor += opLength(generatedOp);
  }
};

/**
 * 
 * @param {import('quill-delta').Op} op 
 */
const opLength = (op) => {
  if (typeof op.insert !== 'string') {
    return 1;
  } 

  return op.insert.length;
};