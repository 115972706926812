import Quill from 'quill';
import Clipboard from 'quill/modules/clipboard';

const Delta = Quill.import('delta');

const BLACKLISTED_ATTRIBUTES = [
  'header',
  'list'
];

function onOverridePaste(range, { html, text }) {
  // Process pasting in table-cell-line before
  const [thisLeaf] = this.quill.getLine(range.index);
  if (thisLeaf && thisLeaf.statics.blotName === 'tableCellLine') {
    this.onTableCellPaste(range, { html, text });
    return;
  }

  return this.originalOnPaste(range, { html, text });
}

function onTableCellPaste(range, { text, html }) {
  const [line] = this.quill.getLine(range.index);
  const lineFormats = line.formats();
  const formats = this.quill.getFormat(range.index);
  let pastedDelta = this.convert({ text, html }, formats);

  pastedDelta = pastedDelta.reduce((newDelta, op) => {
    const attributes = Object.assign({}, op.attributes);
    for (let index = 0; index < BLACKLISTED_ATTRIBUTES.length; index++) {
      const attribute = BLACKLISTED_ATTRIBUTES[index];
      if (attributes[attribute]) {
        delete attributes[attribute];
      }
    }
    if (op.insert && typeof op.insert === 'string') {
      const lines = [];
      let insertStr = op.insert;
      let start = 0;
      for (let i = 0; i < op.insert.length; i++) {
        if (insertStr.charAt(i) === '\n') {
          if (i === 0) {
            lines.push('\n');
          } else {
            lines.push(insertStr.substring(start, i));
            lines.push('\n');
          }
          start = i + 1;
        }
      }

      const tailStr = insertStr.substring(start);
      if (tailStr) {
        lines.push(tailStr);
      }

      lines.forEach(text => {
        text === '\n'
          ? newDelta.insert('\n', Object.assign(
            {},
            attributes,
            lineFormats
          ))
          : newDelta.insert(text, attributes);
      });
    } else {
      newDelta.insert(op.insert, attributes);
    }

    return newDelta;
  }, new Delta());

  const delta = new Delta()
    .retain(range.index)
    .delete(range.length)
    .concat(pastedDelta);
  this.quill.updateContents(delta, Quill.sources.USER);
  this.quill.setSelection(
    delta.length() - range.length,
    Quill.sources.SILENT,
  );
  this.quill.scrollSelectionIntoView();
}

export function overrideCliboard() {
  // @ts-ignore
  if (!Clipboard.prototype.originalOnPaste) {
    // @ts-ignore
    Clipboard.prototype.originalOnPaste = Clipboard.prototype.onPaste;
  }
  Clipboard.prototype.onPaste = onOverridePaste;
  // @ts-ignore
  Clipboard.prototype.onTableCellPaste = onTableCellPaste;
}