import React, { useEffect, useImperativeHandle, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle, 
  IconButton, 
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography as T
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  loadStripe
} from '@stripe/stripe-js';
import { STRIPE_KEY } from '../../config';
import { generateSetupIntent } from '../../bapi';
import { Elements } from '@stripe/react-stripe-js';
import SetupForm from './SetupForm';
import PaymentLoader from './PaymentLoader';
import { showConfirm, toast } from '../../message';
import PaymentForm from './PaymentForm';
import BusinessPlanSeatSelection from './BusinessPlanSeatSelection';
import { paymentMessageCenter } from './billing_utils';

/**
 * Generates expires at for 30minutes (Normally 1 hour. Extra 30 mins is buffer.)
 */
const generateIntentExpiresAt = () => {
  return new Date(Date.now() + 30 * 60 * 100).getTime();
};

/**
 * 
 * @typedef {{ startingBalance?: number, endingBalance?: number }} Balance
 * @typedef {{ amountDue?: number, total?: number }} Amount
 * 
 * @typedef {string | React.ReactNode | ((data: { totalPrice: string, quantity: number, setupIntent?: string  } & Balance) => React.ReactNode)} PaymentDialogConfigItem
 * 
 * @typedef PaymentDialogConfig
 * @property {object} returnUrl
 * @property {string} returnUrl.path
 * @property {{[key: string]: string}=} returnUrl.params
 * @property {string} title
 * @property {((quantity: number) => Promise<({setupId: string, setupSecret: string}|{paymentId: string, paymentSecret: string }) & Balance & Amount>)=} getSecret
 * @property {string} btnContents - If {@link PaymentDialogConfig["price"]} is provided, then this can be templated using `{totalprice}`.
 * @property {string=} setupBtnContents - If its a setup but not a payment.
 * @property {string=} continueToBtnContents
 * @property {React.ReactNode=} disclaimer
 * @property {PaymentDialogConfigItem=} header
 * @property {number=} price
 * @property {number=} defaultQuantity
 * @property {boolean=} showSeatSelection
 * @property {boolean=} instantUpgrade
 * @property {('monthly'|'annually')=} billing
 * 
 * @callback PaymentDialogShow
 * @param {PaymentDialogConfig} config 
 * @returns {Promise<import("./SetupForm").StripeSetupResult>}
 * 
 * 
 * @typedef {object} PaymentDialogRef
 * @property {PaymentDialogShow} show
 */


/**
 * @param {object} props
 * @param {React.Ref<PaymentDialogRef>} ref
 */
const PaymentDialogInner = (props, ref) => {
  const [quantity, setQuantity] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [callbacks, setCallbacks] = useState(
    /** @type {{ resolve: function, reject: (reason: string) => void }} */(null)
  );
  const [config, setConfig] = useState(
    /** @type {PaymentDialogConfig & { cancelled?: boolean }} */(null)
  );
  const [isReady, setIsReady] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecrets, setClientSecrets] = useState(
    /** @type {{ payment_intent?: string, setup_intent?: string, expires_at: number } & Balance & Amount} */
    (null)
  );

  useEffect(() => {
    if (config?.instantUpgrade && config?.showSeatSelection) {
      next();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config?.instantUpgrade, config?.showSeatSelection]);

  /**
   * Stateless closure
   * @param {config=} configParam
   */
  const doClose = (configParam) => {
    if (configParam) {
      configParam.cancelled = true;
    }
    setIsReady(false);
    setClientSecrets(null);
    setConfig(null);
  };

  useImperativeHandle(ref, () => ({
    /**
     * @type {PaymentDialogShow}
     */
    show: async (configParam) => {
      const currentQuantity = configParam.defaultQuantity || 1;
      let currentConfig = /** @type {config} */  (configParam);
      setConfig(currentConfig);
      setQuantity(currentQuantity);
      setActiveStep(0);

      // if already loaded, skip
      setStripePromise((currentPromise) => {
        if (currentPromise) {
          return currentPromise;
        }
        return loadStripe(STRIPE_KEY);
      });

      return new Promise((resolve, reject) => {
        const callbacks = {
          resolve,
          reject
        };
        if (configParam.getSecret) {
          if (!configParam.showSeatSelection) {
            generateSecret(configParam.getSecret, currentQuantity, callbacks).then(res => {
              if (!res) {
                doClose(currentConfig);
              }
            });
          }

        } else {
          generateSetupIntent({
            type: currentConfig.returnUrl.params?.org_id ? 'org' : undefined,
            orgId: currentConfig.returnUrl.params?.org_id
          }).then((intent) => {
            if (currentConfig.cancelled) {
              return;
            }
            setClientSecrets({
              setup_intent: intent.setupSecret,
              expires_at: generateIntentExpiresAt()
            });
          }).catch(err => {
            console.error(err);
            toast(`Failed to load payment information (${err?.message}). Please try again.`, {
              intent: 'danger'
            });
            reject('Failed');
            doClose(currentConfig);
          });

        }
        setCallbacks(callbacks);
      });
    }
  }), []);

  const handleClose = () => {
    callbacks.reject('Closed');
    close();
  };

  /**
   * Closes the dialog
   */
  const close = () => {
    doClose(config);
  };
  const next = () => {
    setActiveStep(1);
    setIsReady(false);
    generateSecret(config.getSecret, quantity, callbacks).then(res => {
      if (!res) {
        close();
      }
    });
  };
  /**
   * 
   * @param {PaymentDialogConfig['getSecret']} getSecret 
   * @param {number} quantity 
   * @param {callbacks} callbacks 
   */
  const generateSecret = async (getSecret, quantity, callbacks) => {
    if (!getSecret) {
      return false;
    }
    setClientSecrets(null);
    setIsReady(false);
    try {
      const data = await getSecret(quantity);
      if ('paymentSecret' in data) {
        setClientSecrets({
          payment_intent: data.paymentSecret,
          expires_at: generateIntentExpiresAt(),
          amountDue: data.amountDue,
          total: data.total,
          startingBalance: data.startingBalance,
          endingBalance: data.endingBalance
        });
        return true;
      }

      if ('setupSecret' in data) {
        setClientSecrets({
          setup_intent: data.setupSecret,
          expires_at: generateIntentExpiresAt(),
          startingBalance: data.startingBalance,
          endingBalance: data.endingBalance
        });
        return true;
      }

      callbacks.resolve(null);
      return false;
    } catch (err) {
      callbacks.reject(err);
      return false;
    }
  };
  let btnContents = config?.btnContents;
  let strTotalPrice = '';
  if (config?.price && typeof btnContents === 'string') {
    strTotalPrice = (config.price * quantity).toFixed(2);
    btnContents = btnContents.replaceAll('{totalprice}', strTotalPrice);
  }
  const beforeSubmit = () => {
    if (clientSecrets.expires_at < Date.now()) {
      showConfirm({
        contents: 'Payment seems to be expired. Please refresh the page and try again.',
        confirmButtonText: 'Reload',
        onConfirm: () => {
          window.location.reload();
        },
        intent: 'danger'
      });
      handleClose();
      return false;
    }

    return true;
  };
  return (
    <Dialog
      open={!!config}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: 450
        }
      }}
      sx={{ background: config?.instantUpgrade ? 'hsl(0 0% 99% / 1)' : undefined }}
      hideBackdrop={config?.instantUpgrade}
    >
      <DialogTitle
        sx={{
          position: 'relative',
          pr: 5
        }}
      >
        <span>{config?.title}</span>
        <IconButton
          sx={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            right: 0,
            opacity: .7
          }}
          title="Dismiss"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          mt: 2
        }}
      >
        {config?.showSeatSelection && (
          <Stepper
            nonLinear
            alternativeLabel
            sx={{
              mb: 5
            }}
            activeStep={activeStep}
          >
            <Step>
              <StepButton
                onClick={() => setActiveStep(0)}
              >
                <StepLabel >Seat selection</StepLabel>
              </StepButton>
            </Step>
            <Step>
              <StepButton
                onClick={next}
              >
                <StepLabel>Payment</StepLabel>
              </StepButton>
            </Step>
          </Stepper>
        )}
        {config?.showSeatSelection
          && activeStep === 0
          && <BusinessPlanSeatSelection
            quantity={quantity}
            onChange={setQuantity}
            totalPrice={strTotalPrice}
            billing={config.billing}
            continueText={config.continueToBtnContents}
            onNext={next}
          />
        }
        {(!config?.showSeatSelection
          || activeStep === 1)
          && (
            <>
              {config?.header && (
                typeof config.header === 'function'
                  ? config.header({
                    totalPrice: strTotalPrice,
                    quantity,
                    startingBalance: clientSecrets?.startingBalance,
                    endingBalance: clientSecrets?.endingBalance,
                    setupIntent: clientSecrets?.setup_intent
                  })
                  : config.header
              )}
              <Box
                sx={{
                  minHeight: !!config?.disclaimer ? 345 : 290,
                  position: 'relative'
                }}
              >
                {!!config && stripePromise && clientSecrets && (
                  <Elements
                    key={clientSecrets.payment_intent || clientSecrets.setup_intent}
                    stripe={stripePromise}
                    options={{
                      loader: 'never',
                      clientSecret: clientSecrets.payment_intent || clientSecrets.setup_intent,
                      appearance: {
                        theme: 'stripe',
                        variables: {
                          colorPrimary: '#00acc0',
                        },
                        rules: {
                          '.Input': {
                            boxShadow: 'none',
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                            borderWidth: '1px'

                          },
                          '.Input:hover': {
                            borderColor: 'rgba(0, 0, 0, 0.8)'
                          },
                          '.Input:focus': {
                            boxShadow: 'none',
                            borderColor: 'var(--colorPrimary)',
                            outline: '1px solid var(--colorPrimary)'
                          },
                          '.Input:hover:focus': {
                            borderColor: 'var(--colorPrimary)'
                          }
                        }
                      }
                    }}

                  >
                    {clientSecrets.setup_intent ?
                      <SetupForm
                        returnUrl={config.returnUrl}
                        onComplete={(result) => {
                          close();
                          callbacks.resolve({
                            ...result,
                            quantity: quantity
                          });
                        }}
                        btnContents={config.setupBtnContents || btnContents}
                        disclaimer={config.disclaimer}
                        style={{
                          height: isReady ? 'auto' : 100,
                          overflow: 'hidden'
                        }}
                        onReady={() => setIsReady(true)}
                        beforeSubmit={beforeSubmit}
                      />
                      :
                      <PaymentForm
                        returnUrl={config.returnUrl}
                        onComplete={(result) => {
                          close();
                          callbacks.resolve({
                            ...result,
                            quantity: quantity
                          });
                        }}
                        btnContents={btnContents}
                        disclaimer={config.disclaimer}
                        style={{
                          height: isReady ? 'auto' : 100,
                          overflow: 'hidden'
                        }}
                        onReady={() => setIsReady(true)}
                        beforeSubmit={beforeSubmit}
                      />
                    }
                  </Elements>
                )}
                <PaymentLoader
                  show={!isReady}
                  style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    left: 0,
                    top: 0,
                    right: 0,
                    zIndex: 100
                  }}
                  disclaimer={config?.disclaimer}
                />
              </Box>
              {typeof clientSecrets?.amountDue === 'number' && clientSecrets?.amountDue !== clientSecrets?.total  && (
                <T
                  variant="caption"
                  component="div"
                  textAlign="center"
                  fontSize={10}
                  mt={2}
                >
                  You will be charged ${clientSecrets?.amountDue} at this time.
                </T>
              )}
            </>
          )
        }
      </DialogContent>
    </Dialog>
  );

};
const DialogInner = React.memo(React.forwardRef(PaymentDialogInner));

const PaymentDialogBase = () => {
  return (
    <DialogInner ref={(comp) => {
      paymentMessageCenter.paymentDialog = comp;
    }} />
  );
};

const PaymentDialog = React.memo(PaymentDialogBase);
export default PaymentDialog;