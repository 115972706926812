// @ts-ignore
import BackgroundImage from '../../../images/background_512.png';

const emailRegex = new RegExp('^[a-z0-9_\\-.\']{1,}@[a-z0-9_\\-.]{1,}\\.[a-z]{2,}$', 'i');

/**
 * Validates and returns if the email provided is valid.
 * @param {string} email
 * @returns 
 */
export const isValidEmail = (email) => {
  return emailRegex.test(email);
};

export const DialogBackgroundStyles = {
  backgroundColor: 'white',
  backgroundImage: `url(${BackgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right bottom',
  backgroundAttachment: 'fixed',
  backgroundSize: 'contain'
};

export const DialogPaperStyles = {
  width: 360,
};




/**
 * Validates the new password
 * @param {string} input 
 * @returns 
 */
export const validateNewPassword = (input) => {
  if (!input) {
    return;
  }
  // check password length
  if (input.length < 8) {
    return 'Must be at least 8 characters.';
  }
  // check password has character
  if (!/[a-zA-Z]/.test(input)) {
    return 'Must contain at least one character.';
  }

  // check password has digit
  if (!/\d/.test(input)) {
    return 'Must contain at least one digit.';
  }
};