import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField
} from '@mui/material';
import AsyncButton from '../AsyncButton/AsyncButton';

const PromptDialog = forwardRef((_props, ref) => {
  let [open, setOpen] = useState(false);
  let [loading, setLoading] = useState(false);
  let [config, setConfig] = useState(null);
  let [text, setText] = useState('');
  let [textWasSelected, setTextWasSelected] = useState(false);

  useImperativeHandle(ref, () => ({
    show: (config) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setOpen(true);
        setConfig(config);
        setText(config.default || '');
        setTextWasSelected(false);
      });
    }
  }));

  const hide = () => {
    setOpen(false);
    setConfig(null);
    setText('');
    setLoading(false);
  };

  const cancel = () => {
    if (loading) {
      return;
    }
    if (config.onCancel) {
      config.onCancel();
    }
    hide();
  };

  const isInvalid = () => {
    return !text.trim();
  };

  const accept = async (done) => {
    if (isInvalid()) {
      done();
      return;
    }
    let confirming = config.onConfirm(text);
    if (confirming) {
      setLoading(true);
      await confirming;
    }
    hide();
    done();
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={cancel}
      maxWidth="sm"
    >
      <DialogContent
        sx={{
          minWidth: {
            xs: 'min(calc(100% - 40px), 370px)',
            sm: '370px'
          }
        }}>
        {config.contents}
        <TextField
          size="small"
          style={{
            width: '100%',
            marginTop: 24,
            marginBottom: 24
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              accept(() => {});
            }
          }}
          onFocus={event => {
            if (!textWasSelected) {
              event.target.select();
              setTextWasSelected(true);
            }
          }}
          autoFocus
          placeholder={config.placeholder}
          variant="outlined"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </DialogContent>

      <DialogActions style={{ marginRight: 10, marginBottom: 15 }}>
        <Button
          onClick={cancel}
          variant="outlined"
          style={{ marginRight: 12 }}
          disabled={loading}
        >{config.cancelButtonText || 'Cancel'}</Button>
        <AsyncButton
          color="primary"
          onClick={accept}
          variant="contained"
          disabled={isInvalid()}
          loading={loading}
        >{config.confirmButtonText || 'Done'}</AsyncButton>
      </DialogActions>
    </Dialog>
  );
});

export default PromptDialog;