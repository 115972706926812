import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { decompressDelta } from '../../delta_proto/DeltaProto';
import { exportString } from '../../import_export/DeltaExport';
import { userPermission } from '../../auth';
import { log } from '../../logging/logging';
import { memberRestricted } from '../../flags';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { useTypedSelector } from '../../hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { EmptyState } from '../EmptyState/EmptyState';
import AsyncButton from '../AsyncButton/AsyncButton';
import { toast } from '../../message';
import { getGroups } from '../../store_utilities';
import GroupIcon from '../Group/GroupIcon';


/** @type {Map<string, string>} */
let cachedUrls = new Map();


/**
 * @param {GroupObjectType[]} groups
 */
async function doExport(groups) {
  let folders = [];

  /** @type {string} */
  let couldNotGetImage = null;

  for (let group of groups) {
    let snippets = [];
    for (let snippet of group.snippets) {
      let d = decompressDelta(snippet.content.delta.toUint8Array());
  
      // we want image exports to be in base64 so they are independent of
      // server-side stored images
  
      /** @type {Set<string>} */
      let urls = new Set();
      for (let op of d.ops) {
        if ((typeof op.insert === 'object') && op.insert['insert-image']) {
          urls.add(op.insert['insert-image'].url);
        }
      }
  
      for (let url of urls) {
        if (!cachedUrls.has(url)) {
          let data;
          try {
            data = await (await fetch(url)).arrayBuffer();
          } catch (err) {
            // could not load image, we'll remove the delta op down below
            console.warn(err);
            continue;
          }
          let intArray = new Uint8Array(data);
          let s = '';
          for (let i = 0; i < intArray.byteLength; i++) {
            s += String.fromCharCode(intArray[i]);
          }
          cachedUrls.set(url, btoa(s));
        }
      }
  
      for (let i = 0; i < d.ops.length; i++) {
        let op = d.ops[i];
        if ((typeof op.insert === 'object') && op.insert['insert-image']) {
          /** @type {string} */
          let url = op.insert['insert-image'].url;
          if (cachedUrls.has(url)) {
            let parts = url.split('.');
            op.insert['insert-image'].url = 'data:image/' + parts[parts.length - 1] + ';base64,' + cachedUrls.get(url);
          } else {
            // deepscan-disable-next-line
            couldNotGetImage = url;
            d.ops.splice(i, 1);
            i--;
          }
        }
      }
  
      let ex = exportString(d);
      let s = {
        name: snippet.name,
        shortcut: snippet.shortcut,
        type: ex.type,
        text: exportString(d, 'text').data,
        html: exportString(d, 'html').data
      };
      if (snippet.options?.is_ai) {
        s.ai_metadata = snippet.options.ai_action;
        // TODO: remove on extension update
        if (snippet.options.polish_mode) {
          s.mode = 'AI_POLISH';
        } else {
          s.mode = 'AI_GENERATE';
        }
        if (snippet.options.include_page_context) {
          s.include_context = true;
        }
      }
      snippets.push(s);
    }

    folders.push({
      name: group.name,
      info: group.info,
      snippets: snippets,
      icon: group.options?.icon
    });
  }

  if (couldNotGetImage) {
    toast('Could not download image for export: ' + couldNotGetImage, {
      intent: 'warning',
      duration: 6000
    });
  }
  

  let data = {
    version: 6,
    folders
  };

  let element = document.createElement('a');
  let file = new Blob([JSON.stringify(data, null, ' ')], { type: 'text/json' });
  element.href = URL.createObjectURL(file);
  element.download = (groups.length === 1 ? groups[0].name : 'Text Blaze') + ' Export.json';
  element.click();
}


export default function ExportList(_props) {
  let {
    groups,
    disableShare,
    loaded
  } = useTypedSelector(state => {
    return {
      groups: getGroups({
        includeDisabled: true,
        addons: 'include',
        order: true
      }),
      disableShare: memberRestricted(state, 'share'),
      // note this is fully loaded, we really need to wait for the data state,
      // but we don't have a good thing to listen to
      loaded: state.userState.settingsLoaded 
    };
  });

  if (!loaded) {
    return <div style={{ marginTop: 40, textAlign: 'center' }}>
      <CircularProgress size={150}/>
    </div>;
  }

  let exportable = [];
  if (!disableShare) {
    for (const group of groups) {
      // Only export owner groups
      if (group.permissions) {
        if (userPermission(group) === 'owner') {
          exportable.push(group);
        }
      }
    }
    
  }

  if (exportable.length === 0) {
    return <EmptyState
      icon={ZoomOutIcon}
      title="Nothing to export"
      description="You don't have any folders you can export"
    />;
  }

  return <>
    <Table>
      <TableBody>
        {
          exportable.map(group => <TableRow key={group.id}>
            <TableCell>
              <span>
                <GroupIcon
                  groupId={group.id}
                  containerStyle={{ verticalAlign: 'middle', marginRight: 10 }}
                  iconStyle={{ color: '#888' }}
                />
                <big style={{ verticalAlign: 'middle' }}> {group.name}</big>
              </span>
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}><AsyncButton
              onClick={async (done) => {
                log({
                  category: 'Export',
                  action: 'Export group'
                }, {
                  group_id: group.id
                });

                await doExport([group]);
                done();
              }}
            >Export</AsyncButton></TableCell>
          </TableRow>)
        }
      </TableBody>
    </Table>

    {exportable.length > 1 && <div style={{ textAlign: 'right', marginTop: 16, marginRight: 16 }}>
      <AsyncButton
        onClick={async (done) => {
          log({
            category: 'Export',
            action: 'Export all groups'
          });

          await doExport(exportable);

          done();
        }}
      >
        Export all folders
      </AsyncButton>
    </div>}
  </>;
}
