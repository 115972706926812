import React from 'react';
import Fuse from 'fuse.js';

const MAX_RANK = 30,
  FUSE_OPTIONS = {
    isCaseSensitive: false,
    includeScore: true,    // return the score alongside the match (lower is better)
    includeMatches: true,  // return the match indices so that the matched portions can be highlighted
    findAllMatches: true,  // if multiple matches exist, then that snippet should be prioritized
    minMatchCharLength: 1, // will be edited based on shortcut length
    ignoreLocation: true,  // it should not matter where in the search string the pattern appears
    ignoreFieldNorm: true,
    threshold: 0.5,
    keys: [
      { name: 'snippet.shortcut', weight: 6 },
      { name: 'snippet.name', weight: 5 },
      { name: 'group', weight: 4 },
      { name: 'text', weight: 3 },
    ]
  };

let RANK_MULTIPLIER = new Array(MAX_RANK + 1);
{
  function score(rank) {
    // offset by 1 to prevent 0 value at x=1
    // -rank causes large difference near small x
    const sub = (MAX_RANK - rank + 1) ** 2;
    // want lower absolute values for smaller x
    let res = MAX_RANK ** 2 + 10 - sub;
    // scale down (lower is better)
    res /= 5000;
    return res;
  }
  for (let i = 1; i <= MAX_RANK; i++) {
    RANK_MULTIPLIER[i] = score(i);
  }
}

/**
 * @param {(snippet: SnippetObjectType) => string} snippetTextFn
 * @param {SnippetObjectType[]} snippets 
 * @param {Object<string, GroupObjectType>} [groups]
 */
export function processSnippetsFn(snippetTextFn, snippets, groups) {
  /**
   * @param {SnippetObjectType} snippet 
   */
  function processSnippet(snippet) {
    const group = groups[snippet.group_id];
    let groupName = '';
    if (group && group.name) {
      groupName = group.name.toLocaleLowerCase();
    }

    return {
      snippet,
      text: snippetTextFn(snippet),
      group: groupName
    };
  }
  return snippets.map(processSnippet);
}

/**
 * Search in snippets or pages.
 * @param {(SnippetObjectType|PageObjectType)[]} snippets List of all snippets that need to be searched
 * @param {string[]} priorities List of 30 most used snippet IDs
 * @param {(snippets:(SnippetObjectType|PageObjectType)[], groups?: Object<string, GroupObjectType|SiteObjectType>) => any[]} processSnippetsFn The function used to process snippets
 * @param {string} query User query that was typed
 * @param {Object<string, GroupObjectType>} [groups] Mapping from group id to group object
 * @returns 
 */
export function searchSnippetsOrPages(snippets, priorities, processSnippetsFn, query = '', groups = {}) {
  query = query.toLocaleLowerCase();

  FUSE_OPTIONS.minMatchCharLength = query.length >= 2 ? Math.max(2, query.length - 2) : 1;

  const processedSnippets = processSnippetsFn(snippets, groups);
  let fuseResults;
  if (query) {
    const fuse = new Fuse(processedSnippets, FUSE_OPTIONS);
    fuseResults = fuse.search(query);
  } else {
    fuseResults = processedSnippets.map(item => ({
      item, score: 1
    }));
  }

  // weight the fuse results by priority
  for (const result of fuseResults) {
    const snipId = result.item.snippet.id,
      rank = priorities.indexOf(snipId) + 1;
    if (rank !== 0) {
      result.score *= RANK_MULTIPLIER[rank];
    }
  }

  // sort after reweighting
  fuseResults.sort((a, b) => a.score - b.score);

  return fuseResults;
}

/**
 * @param {string} str string to higlight
 * @param {Number[][]} indices the list of indices to highlight
 * @param {string} queryString the query string
 */
function getHighlightedDOM(str, indices = [], queryString = '') {
  if (!indices || indices.length === 0) {
    return str;
  }
  const PREFIX = 25;

  let longestMatchIndex = -1, longestMatchLength = -1, indicesProcessed = [];
  for (const [iStart, iEnd] of indices) {
    const len = iEnd - iStart + 1;
    if (str.substr(iStart, queryString.length).toLowerCase() === queryString) {
      indicesProcessed.push([iStart, iStart + queryString.length - 1]);
    }
    if (len > longestMatchLength) {
      longestMatchLength = len;
      longestMatchIndex = iStart;
    }
  }
  if (indicesProcessed.length > 0) {
    indices = indicesProcessed;
    longestMatchIndex = indices[0][0];
  }

  let offset = 0;
  if (longestMatchIndex > PREFIX) {
    const ELLIPSIS = '...';
    str = ELLIPSIS + str.substring(longestMatchIndex - PREFIX);
    offset = ELLIPSIS.length - (longestMatchIndex - PREFIX);
  }

  let res = /** @type {JSX.Element[]} */ ([]),
    sectionPrev = 0, keyI = 0;

  // 100 characters is far more than what can be shown
  // in the search dialog box. This limit helps limit
  // the number of React children, which in effect
  // improves rendering time during searches
  // for users with very long snippets
  const MAX_VISIBLE_LENGTH = 100; 
  let currentlyVisibleLength = 0;

  /**
   * @param {number} start 
   * @param {number} end 
   * @param {boolean} inside 
   */
  function insertBlock(start, end, inside) {
    if (currentlyVisibleLength > MAX_VISIBLE_LENGTH) {
      // don't add this to the result as it exceeded the bounds
      return;
    }
    const sub = str.substring(start, end + 1);
    let elm = inside ? <b key={'query-' + keyI++}>{sub}</b> : <span key={'base-' + keyI++}>{sub}</span>;
    res.push(elm);
    currentlyVisibleLength += Math.max(end - start + 1, 0);
  }
  for (let [start, end] of indices) {
    start += offset;
    end += offset;
    if (sectionPrev !== start) {
      insertBlock(sectionPrev, start - 1, false);
    }
    insertBlock(start, end, true);
    sectionPrev = end + 1;
  }
  if (sectionPrev < str.length) {
    insertBlock(sectionPrev, str.length - 1, false);
  }

  return res;
}

/**
 * 
 * @param {string} snipName 
 * @param {string} snipText 
 * @param {string} groupName 
 * @param {string} shortcutText 
 * @param {*} searchResult list of results from fuse
 * @param {string} queryString the query string
 * @returns 
 */
export function highlightSection(snipName, snipText, groupName, shortcutText, searchResult = null, queryString = '') {
  if (searchResult && searchResult.matches) {
    let matches = Object.create(null);
    for (const match of searchResult.matches) {
      matches[match.key] = match.indices;
    }

    const snipNameDOM = getHighlightedDOM(snipName, matches['snippet.name'], queryString),
      shortcutTextDOM = getHighlightedDOM(shortcutText, matches['snippet.shortcut'], queryString),
      groupNameDOM = getHighlightedDOM(groupName, matches['group'], queryString),
      snipTextDOM = getHighlightedDOM(snipText, matches['text'], queryString);
    return [snipNameDOM, snipTextDOM, groupNameDOM, shortcutTextDOM];
  }

  return [snipName, snipText, groupName, shortcutText];
}
