import React, { useEffect, useState } from 'react';
// @ts-ignore
import TryMeImage from './try_me.png';
import Typer from '../Shortcut/Typer';
import { sendMessageToClient } from '../../desktop_utilities';

/**
 * @param {object} props 
 * @param {boolean=} props.hasInserted
 * @param {Function=} props.onTypingDone
 * @param {string=} props.typistKey
 * @param {string=} props.shortcut
 * @param {boolean=} props.autoRepeat
 * @param {string=} props.height
 * @param {((value: string) => void)=} props.onInput
 * @param {boolean=} props.noFooter
 * @param {React.CSSProperties=} props.style
 * @param {React.CSSProperties=} props.typerStyle
 */
function TryMeBase(props, ref) {
  let [hidePlaceholder, setHidePlaceholder] = useState(false);
  let [autoRepeatKey, setAutoRepeatKey] = useState(0);

  useEffect(() => {
    return () => sendMessageToClient({ type: 'try-me-box', data: false });
  }, []);
  function typingDoneHandler() {
    if (props.autoRepeat) {
      setAutoRepeatKey(key => key + 1);
      return;
    }
    if (props.onTypingDone) {
      props.onTypingDone();
    }
  }

  return <div
    className={'lpt3-input lpt3-large lpt3-fill allow-blaze ' + (hidePlaceholder ? ' placeholder-hidden' : '')}
    onFocus={() => {
      setHidePlaceholder(true);
      sendMessageToClient({
        type: 'try-me-box',
        data: true
      });
    }}
    onBlur={() => {
      sendMessageToClient({
        type: 'try-me-box',
        data: false
      });
    }}
    suppressContentEditableWarning
    id="try-it-now-home"
    data-enable-grammarly="false"
    contentEditable
    onInput={props.onInput ? (/** @type {any} */ event) => props.onInput(event.target.innerText) : null}
    ref={ref}
    style={{
      height: props.height || 170,
      minHeight: 60,
      overflow: 'auto',
      marginTop: 20,
      marginBottom: 20,
      wordBreak: 'break-word',
      resize: 'vertical',
      position: 'relative',
      ...props.style
    }}>
    {!props.noFooter && !props.hasInserted && !hidePlaceholder && <img
      src={TryMeImage}
      style={{
        position: 'absolute',
        left: 10,
        bottom: 10,
        width: 160,
        userSelect: 'none',
        pointerEvents: 'none'
      }}
      alt="Try me"
    />}
    {!hidePlaceholder && props.shortcut ? <div style={{ opacity: 0.45, pointerEvents: 'none' }}>
      <Typer
        key={autoRepeatKey || props.typistKey || props.shortcut}
        speed={160}
        delay={1000}
        onTypingDone={typingDoneHandler}
        style={props.typerStyle}
      >
        {props.shortcut}
      </Typer>
    </div> : null}
  </div>;
}

const TryMe = React.forwardRef(TryMeBase);
export default TryMe;