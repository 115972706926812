import React from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../hooks';
import SidebarResizer from './SidebarResizer';
// Minimum size of the remaining content


/**
 * 
 * @param {object} props 
 * @param {'snippetSidebarSize' | 'snippetAppSidebarSize'} props.type
 * @param {'left' | 'right'} props.align
 * @param {string} props.parentSelector
 * @param {number=} props.offsetX
 * @param {number=} props.offsetY
 * @param {number=} props.minSize - Minimum size for the element being resized.
 * @param {number=} props.maxSizeOff - Minimum size of the other element.
 * @param {(size: number) => any=} props.onResize - if not set, the valud will set to the state.
 * @returns 
 */
const SidebarResizerWithStateBase = ({
  type,
  ...otherProps
}) => {
  let dispatch = useDispatch();
  let sidebarSize = useTypedSelector(state => state.uiState?.[type] || 300);


  return (
    <SidebarResizer
      {...otherProps}
      size={sidebarSize}
      onResize={(newSize) => {
        dispatch({
          type: 'RESIZE_SIDEBAR',
          data: {
            type: type,
            size: newSize
          }
        });
      }}
    />
  );
};


const SidebarResizerWithState = React.memo(SidebarResizerWithStateBase);
export default SidebarResizerWithState;