/**
 * @typedef {object} InfoType
 * @property {string=} command
 * @property {string=} type
 * @property {(attrs: import('./ParserUtils').AttributesType, env: import('./DataContainer').Environment, noQuery: boolean = false) => Promise<object>=} process
 * @property {boolean=} processed
 * @property {import('./ParserUtils').AttributesType=} attributes
 * @property {object=} style
 * @property {object=} data
 * @property {ParseNode=} node
 * @property {ParseNode=} start
 * @property {ParseNode=} end
 * @property {ParseNode=} else
 * @property {ParseNode[]=} elseif
 * @property {string=} raw
 * @property {object=} formInfo
 * @property {object=} imgAttrs
 * @property {string=} message
 * @property {boolean=} insert
 * @property {boolean=} silent
 * @property {boolean=} blocking
 * @property {string=} show
 * @property {boolean=} shouldInsert
 * @property {string=} item
 * @property {string=} part
 * @property {string=} urlPart
 * @property {string=} locale
 * @property {string=} src
 * @property {number=} width
 * @property {number=} height
 * @property {string=} href
 * @property {string=} pattern
 * @property {string=} at
 * @property {string=} format
 * @property {object=} eqn
 * @property {object=} shift
 * @property {number=} debounceMs
 * @property {string=} url
 * @property {object=} specification
 * @property {object=} config
 * @property {object[]=} inputs
 * @property {object[]=} derived
 * @property {object=} done
 * @property {ParseNode=} completed
 * @property {object=} start
 * @property {string=} method
 * @property {string=} body
 * @property {object=} headers
 * @property {any=} error
 * @property {object=} visibility
 * @property {string=} storeId
 * @property {object=} addonConfigData
 * @property {GrantsType|'LOCAL_DEVELOPMENT'=} approvedGrants
 * @property {import("../Sync/Sync").Snippet=} addon
 * @property {object=} spec
 * @property {string=} name
 * @property {string=} window
 * @property {boolean=} multiple
 * @property {string=} isloading
 * @property {string=} haserror
 * @property {object=} default
 * @property {string=} chicklet -- for errors to show a chicklet in the preview
 * @property {string[]=} parameters
 * @property {string=} query
 * @property {string=} databaseId
 * @property {boolean=} isLastTextNodeInBlock
 * @property {boolean=} menu
 * @property {string=} folderid
 * @property {boolean=} autoaddfields
 * @property {boolean=} set
 * @property {string=} labelName
 * @property {string[]=} names
 * @property {string[]=} sqlBlurb
 * @property {object[]=} args
 * @property {object=} exp
 * @property {object[]=} statements
 * @property {number=} cols
 * @property {string=} key
 * @property {string} [page]
 * @property {'yes'|'ifneeded'|'no'} [select]
 * @property {import('./DownstreamProcess').ConfigDefType['editorData']} [editorData]
 * @property {string} [frame]
 * @property {string} [selector]
 * @property {string} [xpath]
 * @property {string=} buttonLabel
 * @property {boolean=} buttonDisabled
 * @property {object} [finish]
 * @property {object} [begin]
 * @property {boolean} [instant]
 * @property {number} [timeout]
 * // wait
 * @property {number} [delay]
 * // key
 * @property {string} [str]
 * @property {string} [key]
 * @property {number} [keyCode]
 * @property {boolean} [shift]
 * @property {boolean} [cmd]
 * @property {boolean} [ctrl]
 * @property {boolean} [alt]
 */

/**
 * @typedef {object} DisplayType
 * @property {(string|boolean)=} trim
 * @property {("left"|"right"|"both")=} trimDirection - used to modify the trim. This allow addon bookends to say we "should only trim right/left" regardless of what `trim` evaluates to.
 * @property {boolean=} hidden - not shown (only applies to previewing)
 * @property {boolean=} processed
 * @property {boolean=} isShownNote
 * @property {boolean=} isInteractive
 * @property {boolean=} isDynamicLink
 * @property {object=} formKeys
 */


export default class ParseNode {
  /**
   * @param {'el'|'expand'|'root'|'error'} type
   * @param {string} tag
   * @param {(string|InfoType)=} info
   * @param {ParseNode=} baseNode
   */
  constructor(type, tag, info, baseNode) {
    /** @type {'el'|'expand'|'root'|'error'} */
    this.type = type;
    /** @type {string} */
    this.tag = tag;
    /** @type {InfoType} */
    this.info = typeof info === 'string' ? { message: info } : info;

    /** @type {boolean} */
    this.isStyled = undefined;
    /** @type {boolean} */
    this.hadImport = false;
    /** @type {DisplayType} */
    this.display = {};
    /** @type {object} */
    this.quillAttributes = undefined;
    // Used to keep track of the position of the node, when the node is part of an {import}'ed snippet
    /** @type {string} */
    this.importPrefix = baseNode ? (baseNode.importPrefix + '_variant') : '';
    /** @type {number} */
    this.startPosition = baseNode ? baseNode.startPosition : -1;
    /** @type {number} */
    this.endPosition = undefined;
    // Items such as data loading after snippet is inserted that needs to be saved.
    /** @type {ParseNode[]} */
    this.sideChannel = undefined;
    // Scoped data for children (used in repeats which create a new scope)
    /** @type {String} */
    this.localData = baseNode ? baseNode.localData : undefined;
    // List of ids that are this node depends on and if changed should update the node
    /** @type {Set<string>} */
    this.dependencies = undefined;

    /** @type {ParseNode} */
    this.parent = null;

    if (this.type === 'el') {
      /** @type {any} */
      this.attrs = this.info;
    }
    if (this.type !== 'el' || !this.selfClosing()) {
      /** @type {ParseNode[]} */
      this.children = [];
    }

    /** @type {object} */
    this.domCache = null;
  }

  /**
   * Unique position of the node in the dom.
   * @return {string}
   */
  position() {
    if (this.startPosition === -1 && this.type !== 'root') {
      console.error('Undefined node start position - ' + this.type + '\n\n' + (new Error().stack));
    }
    return this.importPrefix + '/' + this.startPosition;
  }

  /**
   * @param {boolean=} includeChildren
   * @param {boolean=} cloneParent
   * @param {(boolean|function(string): string)=} cloneId - if a function mutates the cloned id
   * 
   * @return {ParseNode} A clone of the node
   */
  clone(includeChildren = true, cloneParent = false, cloneId = true) {
    let node = new ParseNode(this.type, this.tag, Object.assign({}, this.info));
    if (cloneId) {
      if (typeof cloneId === 'function') {
        // The following is for addon cloning
        if (this.info && this.info.storeId) {
          node.info.storeId = cloneId(this.info.storeId);
        }
      }

      if (this.info && this.info.attributes) {
        // need to make sure any dom's or ast's embedded in attrs are cleared out when cloning
        node.info.attributes = Object.assign({}, this.info.attributes);
        node.info.attributes.position = this.info.attributes.position.map(x => x.clone());
        node.info.attributes.keys = {};
        for (let key in this.info.attributes.keys) {
          let attr = this.info.attributes.keys[key];
          let pos = this.info.attributes.position.indexOf(attr);
          if (pos > -1) {
            node.info.attributes.keys[key] = node.info.attributes.position[pos];
          } else {
            node.info.attributes.keys[key] = this.info.attributes.keys[key].clone();
          }
        }
      }
    }
    if (includeChildren && this.children) {
      node.children = this.children.map(x => x.clone(true, cloneParent, cloneId));
      if (cloneParent) {
        node.children.forEach(child => child.parent = node);
      }
    }
    node.localData = this.localData;
    node.isStyled = this.isStyled;
    node.hadImport = this.hadImport;
    node.startPosition = this.startPosition;
    node.importPrefix = this.importPrefix;
    node.endPosition = this.endPosition;

    node.display = Object.assign({}, this.display);
    
    if (this.dependencies && this.dependencies.size) {
      if (typeof cloneId === 'function') {
        let newDeps = new Set();  
        this.dependencies.forEach((dep) => {
          if (dep.includes(' %% ')) {
            let parts = dep.split(' %% ');
            newDeps.add(cloneId(parts[0]) + ' %% ' + parts[1]);
          } else {
            newDeps.add(cloneId('root') + ' %% ' + dep);
          }
        });
        node.addDependencies(newDeps);
      } else {
        node.addDependencies(this.dependencies);
      }
    }
    return node;
  }

  /**
   * Creates a clone of node of the same type and tag with new info.
   * 
   * @param {object} newInfo 
   * 
   * @return {ParseNode}
   */
  infoClone(newInfo) {
    let node = new ParseNode(this.type, this.tag, newInfo, this);
    if (this.dependencies) {
      node.dependencies = this.dependencies;
    }
    return node;
  }

  /**
   * @return {boolean} true if this is a root node
   */
  isRoot() {
    return !this.parent;
  }

  /**
   * Removes nodes that does not match a condition
   * 
   * @param {function(ParseNode): boolean} fn
   */
  filter(fn) {
    if (!this.children) {
      return;
    }
    
    for (let i = this.children.length - 1; i >= 0; i--) {
      if (!fn(this.children[i])) {
        this.children.splice(i, 1);
      } else {
        this.children[i].filter(fn);
      }
    }
  }

  /**
   * @param {function(ParseNode, object[]=): void} fn applied to each node
   */
  each(fn) {
    fn(this);

    if (this.children) {
      this.children.forEach(x => x.each(fn));
    }
  }

  /**
   * @param {function(ParseNode, object[]=): Promise<void>} fn applied to each node
   */
  async asyncEach(fn, promises = null) {
    let isMain = false;
    if (!promises) {
      promises = [];
      isMain = true;
    }

    promises.push(fn(this));
    if (this.children) {
      for (let child of this.children) {
        child.asyncEach(fn, promises);
      }
    }

    if (isMain) {
      return Promise.all(promises);
    }
  }

  /**
   * Wait for each fn to finish before continuing with the next
   * Same as asyncEach but sequential instead of parallel
   * @param {function(ParseNode, object[]=): Promise<void>} fn applied to each node
   */
  async sequentialAsyncEach(fn) {
    await fn(this);

    if (this.children) {
      for (let child of this.children) {
        await child.sequentialAsyncEach(fn);
      }
    }
  }

  /**
   * Depth first version of each()
   * 
   * @param {function(ParseNode): void} fn applied to each node
   */
  eachDFS(fn) {
    if (this.children) {
      this.children.forEach(x => x.eachDFS(fn));
    }

    fn(this);
  }

  /**
   * Whether this is the first node in the dom (other than the root).
   * 
   * @return {boolean}
   */
  isFirstNode() {
    return this.parent.type === 'root' && this.parent.children.indexOf(this) === 0;
  }

  /**
   * Applies a mapping function across a tree.
   * 
   * @param {function(ParseNode, object[]=): ParseNode} fn applied to each node. Return null to remove the node.
   * @param {(function(ParseNode): object)=} payloadFn - optional function that
   *   accumulates a hierarchical payload
   * @param {object[]=} payload - optional function accumulated payload, generally not used directly
   * @param {ParseNode=} newParent optional new parent for the tree, generally not used directly
   */
  map(fn, payloadFn, payload = [], newParent = undefined) {
    function handleChildren(children) {
      let c = [];
      children.forEach(x => {
        if (x !== null) {
          if (Array.isArray(x)) {
            c.push(...x);
          } else {
            c.push(x);
          }
        }
      });
      return c;
    }

    if (payloadFn) {
      payload.push(...payloadFn(this));
    }

    let node = fn(this, payload);
    if (node === null) {
      return node;
    }

    node.parent = newParent;
    if (node.type === 'root' && newParent) {
      return handleChildren(node.children.map(x => x.map ? x.map(fn, payloadFn, payload, node) : x));
    } else if (this.children && this.children.length) {
      node.children = handleChildren(this.children.map(x => x.map ? x.map(fn, payloadFn, payload, node) : x));
      for (let i = 0; i < node.children.length; i++) {
        node.children[i].parent = node;
      }
    }

    return node;
  }


  /**
   * Applies a mapping function across a tree.
   * 
   * @param {function(ParseNode): Promise<ParseNode>} fn applied to each node. Return null to remove the node.
   * @param {ParseNode=} newParent
   */
  async asyncMap(fn, newParent = undefined) {
    function handleChildren(children) {
      let c = [];
      children.forEach(x => {
        if (x !== null) {
          if (Array.isArray(x)) {
            c.push(...x);
          } else {
            c.push(x);
          }
        }
      });
      return c;
    }


    let node = await fn(this);
    if (node === null) {
      return node;
    }

    node.parent = newParent;
    if (node.type === 'root' && newParent) {
      return handleChildren(await Promise.all(node.children.map(async x => x.asyncMap ? (await x.asyncMap(fn, node)) : x)));
    } else if (this.children && this.children.length) {
      node.children = handleChildren(await Promise.all(this.children.map(async x => x.asyncMap ? (await x.asyncMap(fn, node)) : x)));
      for (let i = 0; i < node.children.length; i++) {
        node.children[i].parent = node;
      }
    }

    return node;
  }

  /**
   * Adds a single child.
   * 
   * @param {ParseNode} node
   */
  addChild(node) {
    if (node.parent) {
      node.parent.removeChild(node);
    }
    this.children.push(node);
    if (!(typeof node === 'string' || typeof node === 'number')) {
      node.parent = this;
    }
  }

  /**
   * Inserts a single child at an index.
   * 
   * @param {number} index
   * @param {ParseNode} node
   */
  insertChild(index, node) {
    if (node.parent) {
      node.parent.removeChild(node);
    }
    this.children.splice(index, 0, node);
    node.parent = this;
  }

  /**
   * Adds multiple children.
   * 
   * @param {ParseNode[]} nodes
   */
  addChildren(nodes) {
    nodes.slice().forEach(node => this.addChild(node));
  }

  /**
   * Inserts multiple children.
   * 
   * @param {number} index
   * @param {ParseNode[]} nodes
   */
  insertChildren(index, nodes) {
    for (let i = nodes.length - 1; i >= 0; i--) {
      this.insertChild(index, nodes[i]);
    }
  }

  /**
   * Removes a single child.
   * 
   * @param {ParseNode} node
   */
  removeChild(node) {
    let ind = this.children.indexOf(node);
    if (ind !== -1) {
      this.children.splice(ind, 1);
    }
    node.parent = null;
  }

  /**
   * Replaces a single child with a new node.
   * 
   * @param {ParseNode} old
   * @param {ParseNode} newNode
   */
  replaceChild(old, newNode) {
    let index = this.children.indexOf(old);
    if (index === -1) {
      console.error(old, newNode);
      throw new Error('Child node not in parent');
    }
    this.children[index] = newNode;
    newNode.parent = this;
    old.parent = null;
  }

  /**
   * Replaces the node in the tree with another node.
   * 
   * @param {ParseNode} newNode
   */
  replace(newNode) {
    this.parent.replaceChild(this, newNode);
  }

  /**
   * Finds the leftmost child of the tree
   * 
   * @return {ParseNode}
   */
  leftmostLeaf() {
    if (!this.children || !this.children.length) {
      return this;
    }
    return this.children[0].leftmostLeaf();
  }

  /**
   * Finds the rightmost child of the tree
   * 
   * @return {ParseNode}
   */
  rightmostLeaf() {
    if (!this.children || !this.children.length) {
      return this;
    }
    return this.children[this.children.length - 1].rightmostLeaf();
  }

  /**
   * Finds the previous (left) item in the tree.
   * 
   * @return {ParseNode}
   */
  previous() {
    if (!this.parent) {
      return null;
    }
    let i = this.parent.children.indexOf(this);
    if (i > 0) {
      return this.parent.children[i - 1];
    }
    return this.parent.previous();
  }

  /**
   * Finds the next (right) item in the tree.
   * 
   * @return {ParseNode}
   */
  next() {
    if (!this.parent) {
      return null;
    }
    let i = this.parent.children.indexOf(this);
    if (i < this.parent.children.length - 1) {
      return this.parent.children[i + 1];
    }
    return this.parent.next();
  }

  /**
   * Finds the previous (left) leaf in the tree.
   * 
   * @return {ParseNode}
   */
  previousLeaf() {
    let p = this.previous();
    if (p) {
      return p.rightmostLeaf();
    }
  }

  /**
   * Finds the next (right) leaf in the tree.
   * 
   * @return {ParseNode}
   */
  nextLeaf() {
    let p = this.next();
    if (p) {
      return p.leftmostLeaf();
    }
  }

  /**
   * @param {function(ParseNode): boolean} fn - When true, node is returned.
   * @param {boolean} allowThis - Whether the current node can match.
   * 
   * @return {ParseNode} The found node.
   */
  find(fn, allowThis = true) {
    if (allowThis && fn(this)) {
      return this;
    } else if (this.children) {
      for (let child of this.children) {
        let x = child.find(fn);
        if (x) {
          return x;
        }
      }
    }
  }

  /**
   * @param {function(ParseNode): boolean} fn - When true, node is returned. Starts from right to left.
   * 
   * @return {ParseNode} The found node.
   */
  findRight(fn) {
    if (fn(this)) {
      return this;
    } else if (this.children) {
      for (let i = this.children.length - 1; i >= 0; i--) {
        let x = this.children[i].findRight(fn);
        if (x) {
          return x;
        }
      }
    }
  }

  /**
   * @param {function(ParseNode): boolean} fn - When true, node is returned.
   * @param {ParseNode[]=} collector
   * 
   * @return {Array.<ParseNode>} Array of matching nodes.
   */
  findAll(fn, collector) {
    if (!collector) {
      collector = [];
    }

    if (fn(this)) {
      collector.push(this);
    }

    if (this.children) {
      for (let child of this.children) {
        if (child.findAll) {
          child.findAll(fn, collector);
        }
      }
    }

    return collector;
  }

  /**
   * Finds a parent that matches a condition.
   * 
   * @param {function(ParseNode): boolean} fn
   * 
   * @return {ParseNode}
   */
  findParent(fn) {
    if (this.parent) {
      if (fn(this.parent)) {
        return this.parent;
      } else {
        return this.parent.findParent(fn);
      }
    }
  }

  /**
   * Whether this ParseNode contains another one.
   * 
   * @param {ParseNode} other - the node to check if we contain.
   * 
   * @return {boolean}
   */
  contains(other) {
    return !!other.findParent(n => n === this);
  }

  /**
   * Splits a tree at a given point. Does not re-parent the two new parts..
   * 
   * @param {string=} includeNode - undefined, 'left', 'right
   * @param {ParseNode=} splitPoint - if defined will split at the point, otherwise at the node itself. Be careful as not recursive. Should not generally be used directly.
   * 
   * @return {ParseNode[]} Two part array [left, right]
   */
  split(includeNode, splitPoint) {
    // console.log('split start', includeNode, splitPoint && splitPoint.toString());
    if ((!splitPoint) && this.children && this.children.length) {
      throw Error('Cannot split on node with children without splitPoint.');
    }

    let styled = this.root().isStyled;
    let hadImport = this.root().hadImport;

    let left = this.clone(false, false, true);
    let right = this.clone(false, false, true);

    let excludeAsIsLeftmostChild = false;
    let excludeAsIsRightmostChild = false;
    if (includeNode === undefined && splitPoint === undefined) {
      if (this === this.root().leftmostLeaf()) {
        excludeAsIsLeftmostChild = true;
      } else if (this === this.root().rightmostLeaf()) {
        excludeAsIsRightmostChild = true;
      }
    }

    let pLeft, pRight;
    let removeParent = false;
    if (this.parent) {
      if (this.parent.tag === 'span' && this.parent.children.length === 1) {
        removeParent = true;
      }
      [pLeft, pRight] = this.parent.split(includeNode, this);
    }
    if (this.children && this.children.length) {
      let splitIndex = this.children.indexOf(splitPoint);
      let leftAddIndex = 0;
      let rightAddIndex = 1;
      if (includeNode === 'left') {
        // if it has children we should have already added it
        // and we don't want to double add
        if (!this.children[splitIndex].children.length) {
          leftAddIndex = 1;
        }
      }
      if (includeNode === 'right') {
        // if it has children we should have already added it
        // and we don't want to double add
        if (!this.children[splitIndex].children.length) {
          rightAddIndex = 0;
        }
      }
      let leftAdd = this.children.slice(0, splitIndex + leftAddIndex);
      let rightAdd = this.children.slice(splitIndex + rightAddIndex);
      left.addChildren(leftAdd);
      right.addChildren(rightAdd);
      if (pLeft) {
        pLeft.addChild(left);
        pRight.insertChild(0, right);
      } else {
        pLeft = left;
        pRight = right;
      }
    }
    if (!pLeft && !(this.children && this.children.length)) {
      if (!includeNode) {
        left = new ParseNode('expand', 'text', '', this);
        left.importPrefix += '_l1split';
        right = new ParseNode('expand', 'text', '', this);
        right.importPrefix += '_r1split';
      } else if (includeNode === 'left') {
        right = new ParseNode('expand', 'text', '', this);
        right.importPrefix += '_r2split';
      } else if (includeNode === 'right') {
        left = new ParseNode('expand', 'text', '', this);
        left.importPrefix += '_l2split';
      }
    }

    let res;
    if (splitPoint) {
      res = [left, right];
    } else {
      res = pLeft ? [pLeft.root(), pRight.root()] : [left, right];
    }

    if (removeParent) {
      if (pLeft) {
        pLeft.parent.removeChild(pLeft);
        pRight.parent.removeChild(pRight);
      }
    }

    res[0].root().isStyled = styled;
    res[1].root().isStyled = styled;

    res[0].root().hadImport = hadImport;
    res[1].root().hadImport = hadImport;

    if (excludeAsIsLeftmostChild) {
      res[0] = null;
    }

    if (excludeAsIsRightmostChild) {
      res[1] = null;
    }

    return res;
  }

  /**
   * @return {ParseNode} The root of the tree.
   */
  root() {
    if (this.parent) {
      return this.parent.root();
    } else {
      return this;
    }
  }

  /**
   * The node in the hierarchy (including this node) that is a block.
   * 
   * @return {ParseNode}
   */
  blockParent() {
    if (this.block()) {
      return this;
    }
    if (!this.parent) {
      return null;
    }
    return this.parent.blockParent();
  }

  /**
   * Returns an array of all the parents of the node.
   * 
   * @return {ParseNode[]}
   */
  parents(collector = []) {
    if (this.parent) {
      collector.push(this.parent);
      return this.parent.parents(collector);
    } else {
      return collector;
    }
  }

  /**
   * @return {string}
   */
  findLocalData() {
    if (this.localData) {
      return this.localData;
    }

    if (this.parent) {
      return this.parent.findLocalData();
    }
  }

  /**
   * @return {string}
   */
  findRootAddonLocalData() {
    if (this.localData) {
      if (this.localData === 'root') {
        return null;
      }

      if (!this.parent) {
        return null;
      }
      
      let parentData = this.parent.findRootAddonLocalData();
      if (parentData) {
        return parentData;
      }
      
      if (this.localData.includes('addon[')) {
        return this.localData;
      }

      return null;
    }

    if (!this.parent) {
      return null;
    }

    return this.parent.findRootAddonLocalData();
  }

  /**
   * @return {any[]} chain
   */
  getLocalDataChain(chain = []) {
    if (this.localData) {
      chain.unshift(this.localData);
    }
    if (this.parent) {
      this.parent.getLocalDataChain(chain);
    }
    return chain;
  }
  
  /**
   * Removes another node and places its children under this node.
   * Can be used to merge sequential nodes.
   * 
   * @param {ParseNode} other
   */
  consume(other) {
    if (other.dependencies && other.dependencies.size) {
      this.addDependencies(other.dependencies);
    }

    let children = other.children.slice();
    let myLocal = this.findLocalData();
    children.forEach(c => {
      let cLocal = c.findLocalData();
      if (myLocal !== cLocal) {
        c.localData = c.findLocalData();
      }
    });

    this.addChildren(children);
    if (other.parent) {
      other.parent.removeChild(other);
    }
  }

  /**
   * Whether the html element node is self closing.
   * 
   * @return {boolean}
   */
  selfClosing() {
    if (this.selfClosingCached === undefined) {
      this.selfClosingCached = [
        'area',
        'base',
        'br',
        'col',
        'command',
        'embed',
        'hr',
        'img',
        'input',
        'keygen',
        'link',
        'meta',
        'param',
        'source',
        'track',
        'wbr'
      ].includes(this.tag);
    }
    return this.selfClosingCached;
  }

  /**
   * Does this node contain content that will be rendered if
   * the node is empty.
   * 
   * @return {boolean}
   **/
  isRenderedIfEmpty() {
    return this.type === 'expand' || ![
      'u',
      'b',
      'em',
      'span',
      'i',
      'strong',
      'code',
      's',
      'sub',
      'sup'
    ].includes(this.tag);
  }

  /**
   * Expand tag that doesn't include any inserted content.
   * 
   * Note we don't include {click}, {wait}, {key} as those are split in the
   * dom on insert.
   */
  isContentlessExpand() {
    return [
      'cursor'
    ].includes(this.tag);
  }

  /**
   * Whether the html element node is a block node.
   * 
   * @return {boolean}
   */
  block() {
    if (this.blockCached === undefined) {
      this.blockCached = this.type === 'el' && [
        'address',
        'article',
        'aside',
        'blockquote',
        'canvas',
        'dd',
        'div',
        'dl',
        'fieldset',
        'figcaption',
        'figure',
        'footer',
        'form',
        'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
        'header',
        'hgroup',
        'hr',
        'li',
        'main',
        'nav',
        'noscript',
        'ol',
        'output',
        'p',
        'pre',
        'section',
        'table',
        'tfoot',
        'ul',
        'video'
      ].includes(this.tag);
    }
    return this.blockCached;
  }

  /**
   * Finds the lowest shared parent between two nodes.
   * 
   * @param {ParseNode} other 
   * 
   * @return {ParseNode}
   */
  sharedParent(other) {
    let myParents = this.parents();
    let otherParents = other.parents();

    for (let i = 0; i < myParents.length; i++) {
      if (otherParents.includes(myParents[i])) {
        return myParents[i];
      }
    }
  }

  /**
   * Add dependencies to the nodes dependencies.
   * 
   * @param {Set<string>} deps
   */
  addDependencies(deps) {
    if (deps && deps.size) {
      if (this.dependencies) {
        for (let item of deps) {
          this.dependencies.add(item);
        }
      } else {
        this.dependencies = new Set(deps.values());
      }
    }
  }

  /**
   * Removes empty text nodes around the node.
   */
  clearSurroundingEmptyText() {
    let children = this.parent.children;
    let i = children.indexOf(this);
    while (i > 0 && (children[i - 1].tag === 'text' && children[i - 1].info.message === '')) {
      this.parent.removeChild(children[i - 1]);
      i = children.indexOf(this);
    }
    while (i < children.length - 1 && (children[i + 1].tag === 'text' && children[i + 1].info.message === '')) {
      this.parent.removeChild(children[i + 1]);
      i = children.indexOf(this);
    }
  }
  

  /**
   * Applies an operation to the envelope spanning two nodes.
   * 
   * @param {ParseNode} other
   * @param {function(ParseNode): void} fn
   * @param {boolean=} group - if true, group children into an array where possible when calling fn
   */
  encapsulateTo(other, fn, group = false) {
    let sharedParent = this.sharedParent(other);

    // Climb up the tree from this (start) node
    let p = this.parent;
    /** @type {ParseNode} */
    let c = this;
    let firstPass = true;
    while (sharedParent !== p) {
      let slice = p.children.slice(p.children.indexOf(c) + (firstPass ? 0 : 1));
      if (slice.length !== p.children.length) {
        firstPass = false;
        if (group) {
          if (slice.length) {
            // @ts-ignore
            fn(slice);
          }
        } else {
          slice.forEach(fn);
        }
      }
      c = p;
      p = c.parent;
    }

    // Climb up the tree from other (end) node
    let p2 = other.parent;
    let c2 = other;
    let firstPass2 = true;
    while (sharedParent !== p2) {
      let slice = p2.children.slice(0, p2.children.indexOf(c2) + (firstPass2 ? 1 : 0));
      if (slice.length !== p2.children.length) {
        firstPass2 = false;
        if (group) {
          if (slice.length) {
            // @ts-ignore
            fn(slice);
          }
        } else {
          slice.forEach(fn);
        }
      }
      c2 = p2;
      p2 = c2.parent;
    }

    let slice = sharedParent.children.slice(
      sharedParent.children.indexOf(c) + (firstPass ? 0 : 1),
      sharedParent.children.indexOf(c2) + (firstPass2 ? 1 : 0));
      
    if (group) {
      if (slice.length) {
        // @ts-ignore
        fn(slice);
      }
    } else {
      slice.forEach(fn);
    }
  }

  canConsume(other) {
    if ((!['tr', 'td', 'table'].includes(this.tag) && this.tag === other.tag
    ) && !(this === other
      || this.contains(other) // potentially heavy
      || other.contains(this) // potentially heavy
    )) {
      return true;
    }

    return false;
  }

  /**
   * Pretty prints the node's contents.
   * 
   * @param {object} config
   * @param {boolean=} config.includeDeps
   * 
   * @return {string}
   */
  toString(config = {}) {
    let includeDeps = config.includeDeps || false;

    if (this.type === 'expand') {
      if (this.tag === 'text') {
        return this.info.message;
      }
      return `{${this.info.command || this.info.type || this.tag}${includeDeps ? ` deps[${this.dependencies ? [...this.dependencies].join('; x') : ''}]` : ''}}`;
    }
    if (this.selfClosing()) {
      return `<${this.tag}${includeDeps ? ` deps[${this.dependencies ? [...this.dependencies].join('; ') : ''}]` : ''}/>`;
    }
    return `<${this.tag || this.type}>${this.children.map(n => n.toString(config)).join('')}</${this.tag || this.type}>`;
  }

  /**
   * Removes all nodes after this one to other (including other).
   * 
   * @param {ParseNode} other 
   * @param {boolean=} removeThis - remove this node
   * @param {boolean=} removeOther - remove the other node
   */
  removeTo(other, removeThis = true, removeOther = true) {
    let sharedParent = this.sharedParent(other); // closest common ancestor


    let startListItem = this.findParent(node => node.tag === 'li');
    let endListItem = other.findParent(node => node.tag === 'li');

    let startBlock = this.findParent(node => node.block());
    let endBlock = other.findParent(node => node.block());

    
    /**
     * Trim ParseNode Tree 
     * Removes all siblings from specified ParseNode 
     * Removes node if it stays empty as a result of the trim
     * 
     * @param {ParseNode} node 
     * @param {('END'|'START')} direction Specifies trim direction. Think: String.trim[End|Start]() but for "unneded" nodes
     * @param {boolean=} includeCurrentNode Defaults to false - specified node stays as [last|first] child in parent.
     */
    let trimNodeTree = function(node, direction, includeCurrentNode = false) {
      const parent = node.parent;
      let index = parent.children.indexOf(node);

      switch (direction) {
      case 'END':
        index += includeCurrentNode ? 0 : 1;
        parent.children
          .slice(index)
          .forEach(x => x.parent = null); // clear link with parent, for GC
        parent.children = parent.children.slice(0, index);
        break;
      case 'START':
        index += includeCurrentNode ? 1 : 0;
        parent.children
          .slice(0, index)
          .forEach(x => x.parent = null); // clear link with parent, for GC?
        parent.children = parent.children.slice(index);
        break;
      default:
        throw new Error(`Not sure how to trim the node Three. Direction given: ${direction}`);
      }

    };

    /**
     * Splice ParseNode Tree 
     * 
     * Removes ParseNodes between @startNode and @endNode
     * 
     * @param {ParseNode} startNode 
     * @param {ParseNode} endNode 
     * @param {boolean=} includeStart defaults to false
     * @param {boolean=} includeEnd defaults to false
     */
    let spliceNodeTree = function (startNode, endNode, includeStart = false, includeEnd = false) {
      let parent = startNode.parent; // also endNode.parent
      let startIndex = parent.children.indexOf(startNode) + 1; // find position just after startNode
      let length = sharedParent.children.indexOf(endNode) - startIndex; // find length excluding endNode 

      if (includeStart) {
        startIndex += -1; 
        length += 1;
      }

      if (includeEnd) {
        length += 1;
      }

      parent.children
        .splice(startIndex, length)
        .forEach(x => x.parent = null);
    };

    // Climb up the tree from this (start) node
    // and remove any nodes to the "right" of start
    /** @type {ParseNode} */
    let startNode = this;
    let startNodeParent = startNode.parent;

    while (sharedParent !== startNodeParent) {
      trimNodeTree(startNode, 'END', removeThis && startNode === this);

      startNode = startNodeParent;
      startNodeParent = startNode.parent;
    }

    // Climb up the tree from other (end) node
    // and remove any nodes to the "left" of end
    let endNode = other;
    let endNodeParent = endNode.parent;
    
    while (sharedParent !== endNodeParent) {
      trimNodeTree(endNode, 'START' , removeOther && endNode === other);

      endNode = endNodeParent;
      endNodeParent = endNode.parent;
    }

    // We're at the same level
    spliceNodeTree(startNode, endNode, removeThis && sharedParent === this.parent, removeOther && sharedParent === other.parent);

    // merge trees together
    while (startNode
      && endNode
      && startNode.tag !== 'expand'
      && startNode.tag !== 'li' // we handle list merging below
      && startNode.canConsume(endNode)
      && startNode.block()
    ) {
      let newCommonStart;
      let newCommonEnd;
      if (startNode.children && endNode.children) {
        newCommonStart = startNode.children[startNode.children.length - 1];
        newCommonEnd = endNode.children[0];
      }

      startNode.consume(endNode);
      startNode = newCommonStart;
      endNode = newCommonEnd;
    }


    if ((startListItem || endListItem) && startListItem !== endListItem) {

      // Handle consuming list items. The governing principle here is to do what a word
      // processor like Google Docs would do if the user selected and deleted the
      // contents between the start and end nodes

      /**
       * Remove an ul or ol if it is empty.
       * 
       * @param {ParseNode} list 
       */
      function checkEmptyList(list) {
        if (list.children.length === 0) {
          let parent = list.parent;

          parent.removeChild(list);

          // we do a recursive check as it may be a nested list
          if (['ul', 'ol'].includes(parent.tag)) {
            // Note, this relies on the list being a child of the parent list. This isn't correct syntax
            // and we plan to change it in the future. When that change is made, this logic will also
            // need to be updated.
            checkEmptyList(parent);
          }
        }
      }

    
      if (endListItem) {
        // The last node was in a list item

        // Pull end list item out of the list into the startnode's block
        startBlock.addChildren(endListItem.children);

        // If the parent list is now empty, remove it
        let list = endListItem.parent;
        list.removeChild(endListItem);
        checkEmptyList(list);
      } else if (startListItem) {
        // The first node was in a a list item.

        // Note in word processors, when the entire list item contents are
        // removed across a removal operation that spans a list item and subsequent
        // paragraph, it will remove the list item and keep the pagaraph, instead of
        // pulling the contents of the paragraph into the list item.
        //
        // We don't implement this behavior as it's unclear
        // what the analogous behavior would be in tb given the startnode may not
        // actually be removed.

        startListItem.addChildren(endBlock.children);

        // Remove the now empty end block
        endBlock.parent.removeChild(endBlock);
      }
    }
  }
}
