import React, { useState, useEffect, useRef, useCallback } from 'react';
import SnippetEditor from '../SnippetEditor/SnippetEditor';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import CircularProgress from '@mui/material/CircularProgress'; 
import { compressDelta, decompressDelta } from '../../delta_proto/DeltaProto';
import { usersSettingsRef } from '@store';
import { Bytes, arrayUnion } from 'firebase/firestore';
import {
  useTypedSelector,
  useConnectedSettings,
  useTypedSelectorShallowEquals,
  useIsMounted,
  useIsMedium,
} from '../../hooks';
import { log } from '../../logging/logging';
import { sync } from '../../Sync/syncer';
import { useDispatch } from 'react-redux';
import { userPermission } from '../../auth';
import { orgPref, isOrgOwner, } from '../../flags';
import SnippetMetadata from './SnippetMetadata';
import VerificationReminder from '../VerificationReminder/VerificationReminder';
import { storage } from '../../utilities';
import { EmptyState } from '../EmptyState/EmptyState';
import SnippetPreviewPanel from './SnippetPreviewPanel';
import AddonConfigPanel from './AddonConfigPanel';
import useFirestore from '../../FirestoreLink/FirestoreHook';
import { Button } from '@mui/material';
import { makeRef } from '../../firebase_utilities';
import { beforeEmbeddedCommandClose, commandsSVG } from '../SnippetEditor/editor_utilities';
import { messageCenter, toast } from '../../message';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import SnippetWrapper from './SnippetWrapper';
import SnippetToggle from './SnippetToggle';
import CommentOnlyToolbar from './CommentOnlyToolbar';
import { RemoteBottomStatusBar } from '../FormRenderer/FormRenderer';
import useOnMount from '../../hooks/useOnMount';

const AUTO_SAVE_KEY = '%APP_autosave_message';


/** @type {React.CSSProperties} */
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden'
};

/**
 * 
 * @param {object} props 
 * @param {string} props.snippetId 
 * @returns 
 */
function SnippetInner ({
  snippetId
}) {
  const { hash } = useLocation();

  let defaultToPreviewTab =  hash === '#preview';

  let [tab, setTab] = useState(defaultToPreviewTab ? 'preview' : 'edit');
  let [showingAutoSaveNotification, setShowingAutoSaveNotification] = useState(false);
  let isMounted = useIsMounted();
  let showingAutoSave = useRef(false);
  let [showCommands, setShowCommands] = useState(false);
  const history = useHistory();
  const hasEmbeddedCommandError = useRef(new Set());


  const snippetSeed = useTypedSelector(() => {
    let snippet = sync.getSnippetById(snippetId);
    return snippet && snippet.data;
  }, () => {
    // we never want this to trigger a re-render, as we only need it to initialize the
    // the Firestore link
    return true;
  });


  const sLink = useFirestore(makeRef('snippets', snippetId), snippetSeed);
  const snippetLoading = sLink.loading;
  const snippetError = sLink.error;
  const snippetExists = sLink.exists;
  /** @type {SnippetObjectType} */
  const snippet = sLink.data && Object.assign({ id: snippetId }, sLink.data);
  const updateFn = sLink.updateFn;


  let {
    uid,
    loadedGroup,
    connectedEditingBlocked,
    groupAvailableInAccount,
    showAutoSaveNotice,
    myPermission,
    isAddon,
    addonNamespace,
    isAssociatedToUs,
    viewersSourceHidden,
    emailVerified,
    xSharingDisabled,
    userAddonsEnabled,
    trigger
  } = useTypedSelectorShallowEquals((store) => {
    let group = snippet ? store.dataState.groups[snippet.group_id] : null;
    let createdAt = store.userState.firebaseMetadata && new Date(store.userState.firebaseMetadata.creationTime).getTime();
    let orgId = store.userState.org ? store.userState.org.id : null;
    let uid = store.userState.uid;

    let isConnected = group && group.connected && group.connected.is_connected;
    let isConnectedByUs = isConnected && (
      group.connected.connector === 'u:' + store.userState.uid || group.connected.connector === 'o:' + orgId);

    let myPermission = group && userPermission(group);
    let viewersSourceHidden = group && group.options && group.options.viewers_source_hidden;
    if (viewersSourceHidden && myPermission === 'viewer' && tab !== 'preview') {
      setTab('preview');
    }

    return {
      uid,
      groupAvailableInAccount: !!group,
      loadedGroup: !!group && !group.stub,
      connectedEditingBlocked: isConnected && !isConnectedByUs,
      showAutoSaveNotice: createdAt > 1578297732312 /** Don't show prior to feature added */ && (!store.userState.dismissed_notifications || !store.userState.dismissed_notifications.includes(AUTO_SAVE_KEY)),
      myPermission,
      isAddon: !!(group && group.options && group.options.addon),
      addonNamespace: group && group.options && group.options.addon && group.options.addon.namespace,
      isAssociatedToUs: group && !!orgId && group.associated_org_id === orgId,
      viewersSourceHidden,
      emailVerified: store.userState.emailVerified,
      xSharingDisabled: orgPref(store, 'xSharingDisabled'),
      userAddonsEnabled: orgPref(store, 'userAddonsEnabled', true, false) || isOrgOwner(store),
      trigger: group?.options?.trigger
    };
  });


  let isMedium = useIsMedium();
  
  const connectedSettings = useConnectedSettings({ groupId: snippet?.group_id, });

  const metadataRef = useRef(null);
  const [remoteItems, setRemoteItems] = useState([]);

  useOnMount(() => {
    const unblock = history.block((ref) => {

      const beforeCloseResponse = beforeEmbeddedCommandClose(hasEmbeddedCommandError.current);
      if (!beforeCloseResponse) {
        return;
      }
      beforeCloseResponse
        .then(() => {
          unblock();
          history.push(ref);
        })
        .catch(() => null);
      // stop proceeding
      return false;
    });
    return () => unblock();
  });

  let dispatch = useDispatch();
  useEffect(() => {
    // If we are opening a snippet in a group we haven't downloaded, let's peek it
    // to make sure it shows in the sidebar.
    if (snippetExists && !groupAvailableInAccount && snippet && snippet.group_id) {
      dispatch({
        type: 'PEEK_GROUP',
        data: {
          group_id: snippet.group_id
        }
      });
    }

    // eslint-disable-next-line
  }, [snippetExists, groupAvailableInAccount, snippet && snippet.group_id]);


  let views = useTypedSelectorShallowEquals((store) => store.userState.settingsLoaded ? (store.userState.views || {}) : null);

  let addonFormNames = useTypedSelectorShallowEquals((store) => {
    let group = snippet ? store.dataState.groups[snippet.group_id] : null;
    if (group && group.options && group.options.addon && group.options.addon.config) {
      return Object.keys(group.options.addon.config.form_names || {});
    }
    return [];
  });

  
  useEffect(() => {
    if (views === null || !snippet || !snippet.updated_at) {
      return;
    }

    // log the view if necessary
    let viewData = {};
    if ((
      // We haven't logged it yet so need to hide the created indicator
      !views[snippetId]
      // We have logged it, but it has been updated by someone else so we need to hide the updated indicator
      || (
        snippet.updated_at.toMillis() > views[snippetId] // It has been updated since last view 
        && snippet.updated_by !== uid  // And it was updated by someone else
      ))) {

      // We only log if it has been changed since the last view, and we weren't the one
      // updating it
      viewData[`views.${snippetId}`] = Date.now();
    }
    if (!views[snippet.group_id]) {
      // We only log the first view for groups, not the most recent
      viewData[`views.${snippet.group_id}`] = Date.now();
    }
    if (Object.keys(viewData).length) {
      storage.update(usersSettingsRef, viewData, 'HIDE_AUTOSAVE');
    }

    // note this should only run once. If you allow it to run multiple times
    // e.g. (by using snippet instead of snippet.id as the dep), it will
    // have issues as updated_at can be null
    //
    // eslint-disable-next-line
  }, [snippet && snippet.id, views === null]);

  function logFn(data) {
    log(data, {
      snippet_id: snippetId,
      group_id: snippet && snippet.group_id
    });
  }


  function showSaveNotification() {
    if (showAutoSaveNotice) {
      if (!showingAutoSave.current) {
        showingAutoSave.current = true;
        // Enable the notification after a short timeout
        setTimeout(() => {
          if (isMounted.current) {
            setShowingAutoSaveNotification(true);
            // Disable the notification after a timeout
            setTimeout(() => {
              if (isMounted.current) {
                setShowingAutoSaveNotification(false);
              }
            }, 24000);
            // We only want to show the auto save notification once ever
            storage.update(usersSettingsRef, {
              dismissed_notifications: arrayUnion(AUTO_SAVE_KEY)
            }, 'HIDE_AUTOSAVE');
          }
        }, 700);
      }
    }
  }
  const _internalFns = {
    logFn,
    showSaveNotification,
    updateFn
  };
  const internalFns = useRef(_internalFns);
  internalFns.current = _internalFns;

  const handleName = useCallback((name) => {
    internalFns.current.showSaveNotification();

    internalFns.current.updateFn({ name });
  }, []);


  const handleShortcut = useCallback((shortcut) => {
    internalFns.current.showSaveNotification();

    internalFns.current.updateFn({ shortcut });
  }, []);


  /**
   * @type {Parameters<(typeof SnippetEditor)>[0]['onChange']}
   */
  function handleEditor(type, value, id) {
    if (type === 'delta') {
      showSaveNotification();
      // 1 second debounce for this
      updateFn({
        content: Object.assign(snippet.content, {
          delta: Bytes.fromUint8Array(compressDelta(value)),
          id
        })
      }, 1000);
    } else if (type === 'quickentry') {
      updateFn({ options: Object.assign(snippet.options, { quick_entry: value }) });
    } else if (type === 'include_page_context') {
      updateFn({ options: Object.assign(snippet.options, { include_page_context: value }) });
    } else if (type === 'polish_mode') {
      // TODO: remove on extension update
      updateFn({ options: Object.assign(snippet.options, { polish_mode: value }) });
    } else if (type === 'ai_action_user') {
      /** @type {SnippetObjectType['options']} */
      const validObj = { ai_action: { updated_at: Date.now(), action: value, } };
      updateFn({ options: Object.assign(snippet.options, validObj) });
    }
  };

 
  /**
   * @return {boolean}
   */
  function editable() {
    return ['owner', 'editor'].includes(myPermission) && !connectedEditingBlocked;
  }

  /**
   * @return {boolean}
   */
  function owner() {
    return 'owner' === myPermission && !connectedEditingBlocked;
  }


  function getValue() {
    let val = {
      id: snippet.content.id,
      delta: decompressDelta(snippet.content.delta.toUint8Array())
    };

    return val;
  }


  function getTab() {
    let tabValue = tab;
    if ((tab === 'addon' && !isAddon) || (tab === 'preview' && isAddon)) {
      setTimeout(() => setTab('edit'), 50);
      return 'edit';
    }
    return tabValue;
  }

  const isAI = !!snippet?.options.is_ai;
  const isPolishMode = !!snippet?.options.polish_mode;
  const ai_action_user = snippet?.options.ai_action?.action;
  const finalAIAction = getUpdatedAiAction(ai_action_user, isPolishMode);


  function renderTabsInner() {
    /** @type {string[]} */
    let formNames = [];

    if (isAddon && snippet) {
      formNames = formNames.concat(addonFormNames);
      if (snippet.options && snippet.options.addon) {
        if (snippet.options.addon.positional) {
          formNames = formNames.concat(snippet.options.addon.positional.map(x => x.name));
        }
        if (snippet.options.addon.named) {
          formNames = formNames.concat(snippet.options.addon.named.map(x => x.name));
        }
      }
    }


    // Note we preserve the snippet editor when we are on the preview tab so highlighting and
    // undo/redo isn't lost when tabbing back and forth.
    return <>
      <div style={Object.assign({}, containerStyle, { display: tab !== 'edit' ? 'none' : 'contents' })}>
        <ErrorBoundary style={{ paddingTop: 30 }}>
          <SnippetEditor
            placeholder={isAI ? (finalAIAction === 'polish' ? 'Prompt to tell AI Blaze how to polish your writing in the text box...' : (finalAIAction === 'chat' ? 'Ask AI Blaze anything...' : 'Prompt to tell AI Blaze what to write for you...')) : 'Text that will be inserted when the shortcut is typed...'}
            value={getValue()}
            editable={editable()}
            owner={owner()}
            quickentry={snippet ? snippet.options.quick_entry || false : false}
            includePageContext={snippet ? snippet.options.include_page_context || false : false}
            aiAction={finalAIAction}
            isAI={isAI}
            createdAt={snippet ? snippet.created_at?.seconds : null}
            onChange={handleEditor}
            snippetId={snippetId}
            groupId={snippet ? snippet.group_id : undefined}
            xSharingDisabled={xSharingDisabled}
            userAddonsEnabled={userAddonsEnabled}
            isAssociatedToUs={isAssociatedToUs}
            connectedEditingBlocked={connectedEditingBlocked}
            isAddon={isAddon}
            formNames={formNames}
            showAutoSaveNotice={showingAutoSaveNotification}
            hidden={getTab() !== 'edit'}
            showCommands={showCommands}
            setShowCommands={setShowCommands}
            shortcut={snippet?.shortcut}
            openScratchPad={(...args) => metadataRef.current?.openScratchPad(...args)}
            onEmbeddedCommandError={(errors) => {
              hasEmbeddedCommandError.current = errors;
            }}
          />
        </ErrorBoundary>
      </div>
      {secondTab()}
    </>;
  }

  function renderCommentToolbar() {
    return <div style={{
      gridColumnStart: 'sidebar-start',
      gridRowStart: 'editor-start',
      gridRowEnd: 'editor-end',
      overflowY: 'hidden'
    }}>
      <CommentOnlyToolbar snippetId={snippetId}/>
    </div>;
  }

  function renderPreview() {
    return <div style={{
      border: 'solid 1px #ddd',
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      height: '100%',
      borderBottom: 'none',
      overflow: 'hidden',
      position: 'relative'
    }}
    >
      <div
        style={{
          overflow: 'auto',
          height: '100%'
        }}
      >
        <SnippetPreviewPanel
          snippet={snippet}
          snippetId={snippetId}
          delta={getValue().delta}
          connected={connectedSettings}
          onRemoteStatusUpdate={(items) => {
            setRemoteItems(items);
          }}
        />
      </div>
      {/* We have put the remote status bar in this component
        because this is the scroll container for the snippet preview.
        Only in this container, we can align to the bottom of the
        screen while also aligning to the left edge of the preview panel */}
      <RemoteBottomStatusBar items={remoteItems} containerStyle={{
        position: 'sticky',
        bottom: 0,
        left: 0,
      }} />
    </div>;
  }


  function secondTab() {
    if (getTab() === 'addon') {
      return <div style={Object.assign({}, containerStyle, { padding: 20, overflow: 'auto' })}>
        <AddonConfigPanel
          snippet={snippet}
          namespace={addonNamespace}
          updateFn={(data, debounce = 400) => updateFn(data, debounce)}
          editable={editable()}
        />
      </div>;
    } else if (getTab() === 'preview') {
      return <div style={Object.assign({}, containerStyle, { overflow: 'auto' })}>{renderPreview()}</div>;
    }
    return null;
  }

  useEffect(() => {
    if (snippet) {
      // if snippet is created from DataBlaze, check if triggering a click on the
      // first command chip is needed for initial user configuration
      const openCommandStorageKey = `soc-${snippet.id}`;
      const openCommandTriggeredTime = localStorage.getItem(openCommandStorageKey);
      let observer;
      if (openCommandTriggeredTime) {
        // make sure it just triggered at most three seconds ago, otherwise, it's most probably
        // opened explicitly by the user in a different context
        if (new Date().getTime() - new Date(openCommandTriggeredTime).getTime() < 3000) {
          observer = new MutationObserver((mutations) => {
            mutationsLoop:
            for (const mutation of mutations) {
              for (const node of mutation.addedNodes) {
                if (node instanceof HTMLElement && node.matches('span[class*="collapsed-command"]')) {
                  const dataValue = node.getAttribute('data-value');
                  if (dataValue) {
                    const value = JSON.parse(dataValue);
                    if (value.type && value.type.startsWith('remotedb')) {
                      node.click();
                      observer.disconnect();
                      break mutationsLoop;
                    }
                  }
                }
              }
            }
          });
          observer.observe(document.getElementById('root'), {
            childList: true,
            subtree: true,
            attributeFilter: ['class'],
            characterData: false,
          });
          toast('Welcome to your snippet, you can customize the Data Blaze integration in the sidebar.', {
            duration: 6000,
            intent: 'success'
          });
        }
        localStorage.removeItem(openCommandStorageKey);
        if (observer) {
          return () => observer.disconnect();
        }
      }
    }
    // eslint-disable-next-line
  }, [snippet && snippet.id]);

  let snippetLoadingIssue = !snippetExists || snippetError;

  if (snippetLoading || (!loadedGroup && !snippetLoadingIssue)) {
    return <div style={{ paddingTop: '15vh', marginBottom: 30, justifyContent: 'center', display: 'flex' }}><CircularProgress size={150} thickness={1.9} />
    </div>;
  } else if (snippetLoadingIssue) {
    return (<div style={{ paddingTop: emailVerified ? '30vh' : '10vh', marginBottom: 30 }}>
      <EmptyState
        icon="MISSING"
        title="Could not load snippet"
        description="That snippet does not exist or you do not have access to it."
      />

      {emailVerified ? null : (<div><br /><br /><br />
        <VerificationReminder />
      </div>)}
    </div>);
  }

  return (
    <div style={{ height: '100%', maxHeight: '100%', display: 'flex', marginLeft: isMedium ? 0 : 20, paddingTop: 8 }}>
      <SnippetWrapper style={{
        height: '100%',
        overflow: 'hidden'
      }}>
        <div style={{
          gridColumnStart: 'main-start',
          gridColumnEnd: 'sidebar-start',
          gridRowStart: 'main-start',
          gridRowEnd: 'editor-start',
          display: 'flex',
          alignItems: 'center'
        }}>
          <SnippetMetadata
            metadataRef={metadataRef}
            isAddon={isAddon}
            editable={editable()}
            snippetId={snippet.id}
            groupId={snippet.group_id}
            name={snippet && snippet.name}
            shortcut={snippet && snippet.shortcut}
            trigger={trigger}
            handleName={handleName}
            handleShortcut={handleShortcut}
            isAI={isAI}
            isPolishSnippet={finalAIAction === 'polish'}
          />
        </div>

        <div style={{
          gridColumnStart: 'sidebar-start',
          gridColumnEnd: 'sidebar-end',
          gridRowStart: 'main-start',
          gridRowEnd: 'editor-start',
          alignItems: 'center',
          display: 'flex',
          alignContent: 'center',
          flexDirection: isMedium ? 'row' : 'row-reverse',
          paddingBottom: isMedium ? 16 : 0
        }}>
          <div style={{ flex: isMedium ? 1 : undefined, visibility: isAI ? 'hidden' : undefined }}>
            <SnippetToggle
              value={tab}
              onChange={(tab) => {
                if (tab === 'edit') {
                  // reset remote items on switching to edit tab
                  setRemoteItems([]);
                } else {
                  // remove all toasts shown by the dashboard
                  // as now preview/form renderer will show its own toasts
                  messageCenter.snackbar.removeAll();
                }
                setTab(tab);
              }}
              isAddon={isAddon}
              isHidden={isAI}
              disableEdit={viewersSourceHidden && myPermission === 'viewer'}
            />
          </div>
          {isMedium && myPermission !== 'viewer' && tab === 'edit' && <Button
            variant="outlined"
            startIcon={commandsSVG({
              width: 18,
              marginRight: 12,
              opacity: 0.8,
              color: 'inherit',
              fill: 'inherit'
            })}
            style={{
              marginRight: 16
            }}
            onClick={() => {
              setShowCommands(!showCommands);
            }}
          >
            Commands
          </Button>}
        </div>
        
        { // Hide Tab for viewers when source is hidden
          (myPermission !== 'viewer' || !viewersSourceHidden) ? <>

            {renderTabsInner()}
          </>
            : <>
              {renderPreview()}
              {renderCommentToolbar()}
            </>}
      </SnippetWrapper>
    </div>
  );
}

const SnippetInnerMemorized = React.memo(SnippetInner);

const Snippet = () => {
  const { id } = /** @type {{ id: string }} */ (useParams());
  return <SnippetInnerMemorized key={id} snippetId={id} />;
};

export default Snippet;


/**
 * @param {'chat'|'write'|'polish'} aiAction
 * @param {boolean} polishMode
 * @returns {'chat'|'write'|'polish'}
 */
function getUpdatedAiAction(aiAction, polishMode) {
  return aiAction ? aiAction : (polishMode ? 'polish' : 'write');
}