import { getFunctions } from './firebase_shared_driver';

/**
 * Having a seperate file so extension
 * does not include any resource which not required.
 * Like @firebase/app
 * Seperation helped in ~20KB
 */



/**
 * @param {import('firebase/app').FirebaseApp} app 
 */
export const getApiPath_Base = (app) => {
  const isStage = app.options.projectId === 'blaze-today-stage';
  return !isStage ? 'https://api.blaze.today' : 'https://spark-api.blaze.today';
};



/**
 * @param {import('firebase/app').FirebaseApp} app 
 */
export const getTBFunctions_Base = (app) => {
  return getFunctions(app, getApiPath_Base(app));
};
