import React from 'react';
import CommentsButton from '../Comments/CommentsButton';
import ActionsToolbar from './ActionsToolbar';

/**
 * @param {object} props
 * @param {string} props.snippetId
 */
export default function CommentOnlyToolbar(props) {
  return <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
  >
    <div style={{ flexGrow: 1 }}></div>
    <ActionsToolbar>
      <CommentsButton entityType="snippet" entityId={props.snippetId} />
    </ActionsToolbar>
  </div>;
}
