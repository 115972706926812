/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots.deltaproto || ($protobuf.roots.deltaproto = {});

export const deltaproto = $root.deltaproto = (() => {

    /**
     * Namespace deltaproto.
     * @exports deltaproto
     * @namespace
     */
    const deltaproto = {};

    deltaproto.Delta = (function() {

        /**
         * Properties of a Delta.
         * @memberof deltaproto
         * @interface IDelta
         * @property {Array.<deltaproto.Delta.IOp>|null} [ops] Delta ops
         */

        /**
         * Constructs a new Delta.
         * @memberof deltaproto
         * @classdesc Represents a Delta.
         * @implements IDelta
         * @constructor
         * @param {deltaproto.IDelta=} [properties] Properties to set
         */
        function Delta(properties) {
            this.ops = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Delta ops.
         * @member {Array.<deltaproto.Delta.IOp>} ops
         * @memberof deltaproto.Delta
         * @instance
         */
        Delta.prototype.ops = $util.emptyArray;

        /**
         * Creates a new Delta instance using the specified properties.
         * @function create
         * @memberof deltaproto.Delta
         * @static
         * @param {deltaproto.IDelta=} [properties] Properties to set
         * @returns {deltaproto.Delta} Delta instance
         */
        Delta.create = function create(properties) {
            return new Delta(properties);
        };

        /**
         * Encodes the specified Delta message. Does not implicitly {@link deltaproto.Delta.verify|verify} messages.
         * @function encode
         * @memberof deltaproto.Delta
         * @static
         * @param {deltaproto.IDelta} message Delta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Delta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ops != null && message.ops.length)
                for (let i = 0; i < message.ops.length; ++i)
                    $root.deltaproto.Delta.Op.encode(message.ops[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Delta message, length delimited. Does not implicitly {@link deltaproto.Delta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof deltaproto.Delta
         * @static
         * @param {deltaproto.IDelta} message Delta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Delta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Delta message from the specified reader or buffer.
         * @function decode
         * @memberof deltaproto.Delta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {deltaproto.Delta} Delta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Delta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.deltaproto.Delta();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.ops && message.ops.length))
                        message.ops = [];
                    message.ops.push($root.deltaproto.Delta.Op.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Delta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof deltaproto.Delta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {deltaproto.Delta} Delta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Delta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Delta message.
         * @function verify
         * @memberof deltaproto.Delta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Delta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ops != null && message.hasOwnProperty("ops")) {
                if (!Array.isArray(message.ops))
                    return "ops: array expected";
                for (let i = 0; i < message.ops.length; ++i) {
                    let error = $root.deltaproto.Delta.Op.verify(message.ops[i]);
                    if (error)
                        return "ops." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Delta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof deltaproto.Delta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {deltaproto.Delta} Delta
         */
        Delta.fromObject = function fromObject(object) {
            if (object instanceof $root.deltaproto.Delta)
                return object;
            let message = new $root.deltaproto.Delta();
            if (object.ops) {
                if (!Array.isArray(object.ops))
                    throw TypeError(".deltaproto.Delta.ops: array expected");
                message.ops = [];
                for (let i = 0; i < object.ops.length; ++i) {
                    if (typeof object.ops[i] !== "object")
                        throw TypeError(".deltaproto.Delta.ops: object expected");
                    message.ops[i] = $root.deltaproto.Delta.Op.fromObject(object.ops[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Delta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof deltaproto.Delta
         * @static
         * @param {deltaproto.Delta} message Delta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Delta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.ops = [];
            if (message.ops && message.ops.length) {
                object.ops = [];
                for (let j = 0; j < message.ops.length; ++j)
                    object.ops[j] = $root.deltaproto.Delta.Op.toObject(message.ops[j], options);
            }
            return object;
        };

        /**
         * Converts this Delta to JSON.
         * @function toJSON
         * @memberof deltaproto.Delta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Delta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Delta.Op = (function() {

            /**
             * Properties of an Op.
             * @memberof deltaproto.Delta
             * @interface IOp
             * @property {deltaproto.Delta.Op.IAttributes|null} [attributes] Op attributes
             * @property {string|null} [insert] Op insert
             * @property {string|null} [insertObject] Op insertObject
             */

            /**
             * Constructs a new Op.
             * @memberof deltaproto.Delta
             * @classdesc Represents an Op.
             * @implements IOp
             * @constructor
             * @param {deltaproto.Delta.IOp=} [properties] Properties to set
             */
            function Op(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Op attributes.
             * @member {deltaproto.Delta.Op.IAttributes|null|undefined} attributes
             * @memberof deltaproto.Delta.Op
             * @instance
             */
            Op.prototype.attributes = null;

            /**
             * Op insert.
             * @member {string|null|undefined} insert
             * @memberof deltaproto.Delta.Op
             * @instance
             */
            Op.prototype.insert = null;

            /**
             * Op insertObject.
             * @member {string|null|undefined} insertObject
             * @memberof deltaproto.Delta.Op
             * @instance
             */
            Op.prototype.insertObject = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * Op insertion.
             * @member {"insert"|"insertObject"|undefined} insertion
             * @memberof deltaproto.Delta.Op
             * @instance
             */
            Object.defineProperty(Op.prototype, "insertion", {
                get: $util.oneOfGetter($oneOfFields = ["insert", "insertObject"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Op instance using the specified properties.
             * @function create
             * @memberof deltaproto.Delta.Op
             * @static
             * @param {deltaproto.Delta.IOp=} [properties] Properties to set
             * @returns {deltaproto.Delta.Op} Op instance
             */
            Op.create = function create(properties) {
                return new Op(properties);
            };

            /**
             * Encodes the specified Op message. Does not implicitly {@link deltaproto.Delta.Op.verify|verify} messages.
             * @function encode
             * @memberof deltaproto.Delta.Op
             * @static
             * @param {deltaproto.Delta.IOp} message Op message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Op.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.attributes != null && Object.hasOwnProperty.call(message, "attributes"))
                    $root.deltaproto.Delta.Op.Attributes.encode(message.attributes, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.insert != null && Object.hasOwnProperty.call(message, "insert"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.insert);
                if (message.insertObject != null && Object.hasOwnProperty.call(message, "insertObject"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.insertObject);
                return writer;
            };

            /**
             * Encodes the specified Op message, length delimited. Does not implicitly {@link deltaproto.Delta.Op.verify|verify} messages.
             * @function encodeDelimited
             * @memberof deltaproto.Delta.Op
             * @static
             * @param {deltaproto.Delta.IOp} message Op message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Op.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Op message from the specified reader or buffer.
             * @function decode
             * @memberof deltaproto.Delta.Op
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {deltaproto.Delta.Op} Op
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Op.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.deltaproto.Delta.Op();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.attributes = $root.deltaproto.Delta.Op.Attributes.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.insert = reader.string();
                        break;
                    case 3:
                        message.insertObject = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Op message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof deltaproto.Delta.Op
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {deltaproto.Delta.Op} Op
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Op.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Op message.
             * @function verify
             * @memberof deltaproto.Delta.Op
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Op.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.attributes != null && message.hasOwnProperty("attributes")) {
                    let error = $root.deltaproto.Delta.Op.Attributes.verify(message.attributes);
                    if (error)
                        return "attributes." + error;
                }
                if (message.insert != null && message.hasOwnProperty("insert")) {
                    properties.insertion = 1;
                    if (!$util.isString(message.insert))
                        return "insert: string expected";
                }
                if (message.insertObject != null && message.hasOwnProperty("insertObject")) {
                    if (properties.insertion === 1)
                        return "insertion: multiple values";
                    properties.insertion = 1;
                    if (!$util.isString(message.insertObject))
                        return "insertObject: string expected";
                }
                return null;
            };

            /**
             * Creates an Op message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof deltaproto.Delta.Op
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {deltaproto.Delta.Op} Op
             */
            Op.fromObject = function fromObject(object) {
                if (object instanceof $root.deltaproto.Delta.Op)
                    return object;
                let message = new $root.deltaproto.Delta.Op();
                if (object.attributes != null) {
                    if (typeof object.attributes !== "object")
                        throw TypeError(".deltaproto.Delta.Op.attributes: object expected");
                    message.attributes = $root.deltaproto.Delta.Op.Attributes.fromObject(object.attributes);
                }
                if (object.insert != null)
                    message.insert = String(object.insert);
                if (object.insertObject != null)
                    message.insertObject = String(object.insertObject);
                return message;
            };

            /**
             * Creates a plain object from an Op message. Also converts values to other types if specified.
             * @function toObject
             * @memberof deltaproto.Delta.Op
             * @static
             * @param {deltaproto.Delta.Op} message Op
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Op.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.attributes = null;
                if (message.attributes != null && message.hasOwnProperty("attributes"))
                    object.attributes = $root.deltaproto.Delta.Op.Attributes.toObject(message.attributes, options);
                if (message.insert != null && message.hasOwnProperty("insert")) {
                    object.insert = message.insert;
                    if (options.oneofs)
                        object.insertion = "insert";
                }
                if (message.insertObject != null && message.hasOwnProperty("insertObject")) {
                    object.insertObject = message.insertObject;
                    if (options.oneofs)
                        object.insertion = "insertObject";
                }
                return object;
            };

            /**
             * Converts this Op to JSON.
             * @function toJSON
             * @memberof deltaproto.Delta.Op
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Op.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Op.Attributes = (function() {

                /**
                 * Properties of an Attributes.
                 * @memberof deltaproto.Delta.Op
                 * @interface IAttributes
                 * @property {boolean|null} [bold] Attributes bold
                 * @property {boolean|null} [italic] Attributes italic
                 * @property {boolean|null} [underline] Attributes underline
                 * @property {boolean|null} [strike] Attributes strike
                 * @property {deltaproto.Delta.Op.Attributes.Align|null} [align] Attributes align
                 * @property {string|null} [background] Attributes background
                 * @property {string|null} [color] Attributes color
                 * @property {string|null} [font] Attributes font
                 * @property {string|null} [link] Attributes link
                 * @property {deltaproto.Delta.Op.Attributes.Script|null} [script] Attributes script
                 * @property {string|null} [size] Attributes size
                 * @property {deltaproto.Delta.Op.Attributes.List|null} [list] Attributes list
                 * @property {number|null} [indent] Attributes indent
                 * @property {number|null} [width] Attributes width
                 * @property {number|null} [height] Attributes height
                 * @property {boolean|null} [preserveAspect] Attributes preserveAspect
                 * @property {string|null} [url] Attributes url
                 * @property {string|null} [alt] Attributes alt
                 * @property {boolean|null} [blockquote] Attributes blockquote
                 * @property {deltaproto.Delta.Op.Attributes.Direction|null} [direction] Attributes direction
                 * @property {deltaproto.Delta.Op.Attributes.ITableCol|null} [tableCol] Attributes tableCol
                 * @property {string|null} [row] Attributes row
                 * @property {number|null} [rowspan] Attributes rowspan
                 * @property {number|null} [colspan] Attributes colspan
                 * @property {deltaproto.Delta.Op.Attributes.ITableCellLine|null} [tableCellLine] Attributes tableCellLine
                 */

                /**
                 * Constructs a new Attributes.
                 * @memberof deltaproto.Delta.Op
                 * @classdesc Represents an Attributes.
                 * @implements IAttributes
                 * @constructor
                 * @param {deltaproto.Delta.Op.IAttributes=} [properties] Properties to set
                 */
                function Attributes(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Attributes bold.
                 * @member {boolean} bold
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.bold = false;

                /**
                 * Attributes italic.
                 * @member {boolean} italic
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.italic = false;

                /**
                 * Attributes underline.
                 * @member {boolean} underline
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.underline = false;

                /**
                 * Attributes strike.
                 * @member {boolean} strike
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.strike = false;

                /**
                 * Attributes align.
                 * @member {deltaproto.Delta.Op.Attributes.Align} align
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.align = 0;

                /**
                 * Attributes background.
                 * @member {string} background
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.background = "";

                /**
                 * Attributes color.
                 * @member {string} color
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.color = "";

                /**
                 * Attributes font.
                 * @member {string} font
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.font = "";

                /**
                 * Attributes link.
                 * @member {string} link
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.link = "";

                /**
                 * Attributes script.
                 * @member {deltaproto.Delta.Op.Attributes.Script} script
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.script = 0;

                /**
                 * Attributes size.
                 * @member {string} size
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.size = "";

                /**
                 * Attributes list.
                 * @member {deltaproto.Delta.Op.Attributes.List} list
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.list = 0;

                /**
                 * Attributes indent.
                 * @member {number} indent
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.indent = 0;

                /**
                 * Attributes width.
                 * @member {number} width
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.width = 0;

                /**
                 * Attributes height.
                 * @member {number} height
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.height = 0;

                /**
                 * Attributes preserveAspect.
                 * @member {boolean} preserveAspect
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.preserveAspect = false;

                /**
                 * Attributes url.
                 * @member {string} url
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.url = "";

                /**
                 * Attributes alt.
                 * @member {string} alt
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.alt = "";

                /**
                 * Attributes blockquote.
                 * @member {boolean} blockquote
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.blockquote = false;

                /**
                 * Attributes direction.
                 * @member {deltaproto.Delta.Op.Attributes.Direction} direction
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.direction = 0;

                /**
                 * Attributes tableCol.
                 * @member {deltaproto.Delta.Op.Attributes.ITableCol|null|undefined} tableCol
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.tableCol = null;

                /**
                 * Attributes row.
                 * @member {string} row
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.row = "";

                /**
                 * Attributes rowspan.
                 * @member {number} rowspan
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.rowspan = 0;

                /**
                 * Attributes colspan.
                 * @member {number} colspan
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.colspan = 0;

                /**
                 * Attributes tableCellLine.
                 * @member {deltaproto.Delta.Op.Attributes.ITableCellLine|null|undefined} tableCellLine
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 */
                Attributes.prototype.tableCellLine = null;

                /**
                 * Creates a new Attributes instance using the specified properties.
                 * @function create
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @static
                 * @param {deltaproto.Delta.Op.IAttributes=} [properties] Properties to set
                 * @returns {deltaproto.Delta.Op.Attributes} Attributes instance
                 */
                Attributes.create = function create(properties) {
                    return new Attributes(properties);
                };

                /**
                 * Encodes the specified Attributes message. Does not implicitly {@link deltaproto.Delta.Op.Attributes.verify|verify} messages.
                 * @function encode
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @static
                 * @param {deltaproto.Delta.Op.IAttributes} message Attributes message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Attributes.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.bold != null && Object.hasOwnProperty.call(message, "bold"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.bold);
                    if (message.italic != null && Object.hasOwnProperty.call(message, "italic"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.italic);
                    if (message.underline != null && Object.hasOwnProperty.call(message, "underline"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.underline);
                    if (message.strike != null && Object.hasOwnProperty.call(message, "strike"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.strike);
                    if (message.align != null && Object.hasOwnProperty.call(message, "align"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.align);
                    if (message.background != null && Object.hasOwnProperty.call(message, "background"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.background);
                    if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.color);
                    if (message.font != null && Object.hasOwnProperty.call(message, "font"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.font);
                    if (message.link != null && Object.hasOwnProperty.call(message, "link"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.link);
                    if (message.script != null && Object.hasOwnProperty.call(message, "script"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.script);
                    if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.size);
                    if (message.list != null && Object.hasOwnProperty.call(message, "list"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int32(message.list);
                    if (message.indent != null && Object.hasOwnProperty.call(message, "indent"))
                        writer.uint32(/* id 13, wireType 0 =*/104).int32(message.indent);
                    if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                        writer.uint32(/* id 14, wireType 0 =*/112).int32(message.width);
                    if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                        writer.uint32(/* id 15, wireType 0 =*/120).int32(message.height);
                    if (message.preserveAspect != null && Object.hasOwnProperty.call(message, "preserveAspect"))
                        writer.uint32(/* id 16, wireType 0 =*/128).bool(message.preserveAspect);
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 17, wireType 2 =*/138).string(message.url);
                    if (message.alt != null && Object.hasOwnProperty.call(message, "alt"))
                        writer.uint32(/* id 18, wireType 2 =*/146).string(message.alt);
                    if (message.blockquote != null && Object.hasOwnProperty.call(message, "blockquote"))
                        writer.uint32(/* id 19, wireType 0 =*/152).bool(message.blockquote);
                    if (message.direction != null && Object.hasOwnProperty.call(message, "direction"))
                        writer.uint32(/* id 20, wireType 0 =*/160).int32(message.direction);
                    if (message.tableCol != null && Object.hasOwnProperty.call(message, "tableCol"))
                        $root.deltaproto.Delta.Op.Attributes.TableCol.encode(message.tableCol, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
                    if (message.row != null && Object.hasOwnProperty.call(message, "row"))
                        writer.uint32(/* id 41, wireType 2 =*/330).string(message.row);
                    if (message.rowspan != null && Object.hasOwnProperty.call(message, "rowspan"))
                        writer.uint32(/* id 42, wireType 0 =*/336).int32(message.rowspan);
                    if (message.colspan != null && Object.hasOwnProperty.call(message, "colspan"))
                        writer.uint32(/* id 43, wireType 0 =*/344).int32(message.colspan);
                    if (message.tableCellLine != null && Object.hasOwnProperty.call(message, "tableCellLine"))
                        $root.deltaproto.Delta.Op.Attributes.TableCellLine.encode(message.tableCellLine, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Attributes message, length delimited. Does not implicitly {@link deltaproto.Delta.Op.Attributes.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @static
                 * @param {deltaproto.Delta.Op.IAttributes} message Attributes message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Attributes.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Attributes message from the specified reader or buffer.
                 * @function decode
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {deltaproto.Delta.Op.Attributes} Attributes
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Attributes.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.deltaproto.Delta.Op.Attributes();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.bold = reader.bool();
                            break;
                        case 2:
                            message.italic = reader.bool();
                            break;
                        case 3:
                            message.underline = reader.bool();
                            break;
                        case 4:
                            message.strike = reader.bool();
                            break;
                        case 5:
                            message.align = reader.int32();
                            break;
                        case 6:
                            message.background = reader.string();
                            break;
                        case 7:
                            message.color = reader.string();
                            break;
                        case 8:
                            message.font = reader.string();
                            break;
                        case 9:
                            message.link = reader.string();
                            break;
                        case 10:
                            message.script = reader.int32();
                            break;
                        case 11:
                            message.size = reader.string();
                            break;
                        case 12:
                            message.list = reader.int32();
                            break;
                        case 13:
                            message.indent = reader.int32();
                            break;
                        case 14:
                            message.width = reader.int32();
                            break;
                        case 15:
                            message.height = reader.int32();
                            break;
                        case 16:
                            message.preserveAspect = reader.bool();
                            break;
                        case 17:
                            message.url = reader.string();
                            break;
                        case 18:
                            message.alt = reader.string();
                            break;
                        case 19:
                            message.blockquote = reader.bool();
                            break;
                        case 20:
                            message.direction = reader.int32();
                            break;
                        case 40:
                            message.tableCol = $root.deltaproto.Delta.Op.Attributes.TableCol.decode(reader, reader.uint32());
                            break;
                        case 41:
                            message.row = reader.string();
                            break;
                        case 42:
                            message.rowspan = reader.int32();
                            break;
                        case 43:
                            message.colspan = reader.int32();
                            break;
                        case 44:
                            message.tableCellLine = $root.deltaproto.Delta.Op.Attributes.TableCellLine.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Attributes message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {deltaproto.Delta.Op.Attributes} Attributes
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Attributes.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Attributes message.
                 * @function verify
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Attributes.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.bold != null && message.hasOwnProperty("bold"))
                        if (typeof message.bold !== "boolean")
                            return "bold: boolean expected";
                    if (message.italic != null && message.hasOwnProperty("italic"))
                        if (typeof message.italic !== "boolean")
                            return "italic: boolean expected";
                    if (message.underline != null && message.hasOwnProperty("underline"))
                        if (typeof message.underline !== "boolean")
                            return "underline: boolean expected";
                    if (message.strike != null && message.hasOwnProperty("strike"))
                        if (typeof message.strike !== "boolean")
                            return "strike: boolean expected";
                    if (message.align != null && message.hasOwnProperty("align"))
                        switch (message.align) {
                        default:
                            return "align: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.background != null && message.hasOwnProperty("background"))
                        if (!$util.isString(message.background))
                            return "background: string expected";
                    if (message.color != null && message.hasOwnProperty("color"))
                        if (!$util.isString(message.color))
                            return "color: string expected";
                    if (message.font != null && message.hasOwnProperty("font"))
                        if (!$util.isString(message.font))
                            return "font: string expected";
                    if (message.link != null && message.hasOwnProperty("link"))
                        if (!$util.isString(message.link))
                            return "link: string expected";
                    if (message.script != null && message.hasOwnProperty("script"))
                        switch (message.script) {
                        default:
                            return "script: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.size != null && message.hasOwnProperty("size"))
                        if (!$util.isString(message.size))
                            return "size: string expected";
                    if (message.list != null && message.hasOwnProperty("list"))
                        switch (message.list) {
                        default:
                            return "list: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.indent != null && message.hasOwnProperty("indent"))
                        if (!$util.isInteger(message.indent))
                            return "indent: integer expected";
                    if (message.width != null && message.hasOwnProperty("width"))
                        if (!$util.isInteger(message.width))
                            return "width: integer expected";
                    if (message.height != null && message.hasOwnProperty("height"))
                        if (!$util.isInteger(message.height))
                            return "height: integer expected";
                    if (message.preserveAspect != null && message.hasOwnProperty("preserveAspect"))
                        if (typeof message.preserveAspect !== "boolean")
                            return "preserveAspect: boolean expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.alt != null && message.hasOwnProperty("alt"))
                        if (!$util.isString(message.alt))
                            return "alt: string expected";
                    if (message.blockquote != null && message.hasOwnProperty("blockquote"))
                        if (typeof message.blockquote !== "boolean")
                            return "blockquote: boolean expected";
                    if (message.direction != null && message.hasOwnProperty("direction"))
                        switch (message.direction) {
                        default:
                            return "direction: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.tableCol != null && message.hasOwnProperty("tableCol")) {
                        let error = $root.deltaproto.Delta.Op.Attributes.TableCol.verify(message.tableCol);
                        if (error)
                            return "tableCol." + error;
                    }
                    if (message.row != null && message.hasOwnProperty("row"))
                        if (!$util.isString(message.row))
                            return "row: string expected";
                    if (message.rowspan != null && message.hasOwnProperty("rowspan"))
                        if (!$util.isInteger(message.rowspan))
                            return "rowspan: integer expected";
                    if (message.colspan != null && message.hasOwnProperty("colspan"))
                        if (!$util.isInteger(message.colspan))
                            return "colspan: integer expected";
                    if (message.tableCellLine != null && message.hasOwnProperty("tableCellLine")) {
                        let error = $root.deltaproto.Delta.Op.Attributes.TableCellLine.verify(message.tableCellLine);
                        if (error)
                            return "tableCellLine." + error;
                    }
                    return null;
                };

                /**
                 * Creates an Attributes message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {deltaproto.Delta.Op.Attributes} Attributes
                 */
                Attributes.fromObject = function fromObject(object) {
                    if (object instanceof $root.deltaproto.Delta.Op.Attributes)
                        return object;
                    let message = new $root.deltaproto.Delta.Op.Attributes();
                    if (object.bold != null)
                        message.bold = Boolean(object.bold);
                    if (object.italic != null)
                        message.italic = Boolean(object.italic);
                    if (object.underline != null)
                        message.underline = Boolean(object.underline);
                    if (object.strike != null)
                        message.strike = Boolean(object.strike);
                    switch (object.align) {
                    case "right":
                    case 0:
                        message.align = 0;
                        break;
                    case "center":
                    case 1:
                        message.align = 1;
                        break;
                    case "justify":
                    case 2:
                        message.align = 2;
                        break;
                    }
                    if (object.background != null)
                        message.background = String(object.background);
                    if (object.color != null)
                        message.color = String(object.color);
                    if (object.font != null)
                        message.font = String(object.font);
                    if (object.link != null)
                        message.link = String(object.link);
                    switch (object.script) {
                    case "sub":
                    case 0:
                        message.script = 0;
                        break;
                    case "super":
                    case 1:
                        message.script = 1;
                        break;
                    }
                    if (object.size != null)
                        message.size = String(object.size);
                    switch (object.list) {
                    case "ordered":
                    case 0:
                        message.list = 0;
                        break;
                    case "bullet":
                    case 1:
                        message.list = 1;
                        break;
                    }
                    if (object.indent != null)
                        message.indent = object.indent | 0;
                    if (object.width != null)
                        message.width = object.width | 0;
                    if (object.height != null)
                        message.height = object.height | 0;
                    if (object.preserveAspect != null)
                        message.preserveAspect = Boolean(object.preserveAspect);
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.alt != null)
                        message.alt = String(object.alt);
                    if (object.blockquote != null)
                        message.blockquote = Boolean(object.blockquote);
                    switch (object.direction) {
                    case "rtl":
                    case 0:
                        message.direction = 0;
                        break;
                    case "ltr":
                    case 1:
                        message.direction = 1;
                        break;
                    }
                    if (object.tableCol != null) {
                        if (typeof object.tableCol !== "object")
                            throw TypeError(".deltaproto.Delta.Op.Attributes.tableCol: object expected");
                        message.tableCol = $root.deltaproto.Delta.Op.Attributes.TableCol.fromObject(object.tableCol);
                    }
                    if (object.row != null)
                        message.row = String(object.row);
                    if (object.rowspan != null)
                        message.rowspan = object.rowspan | 0;
                    if (object.colspan != null)
                        message.colspan = object.colspan | 0;
                    if (object.tableCellLine != null) {
                        if (typeof object.tableCellLine !== "object")
                            throw TypeError(".deltaproto.Delta.Op.Attributes.tableCellLine: object expected");
                        message.tableCellLine = $root.deltaproto.Delta.Op.Attributes.TableCellLine.fromObject(object.tableCellLine);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an Attributes message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @static
                 * @param {deltaproto.Delta.Op.Attributes} message Attributes
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Attributes.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.bold = false;
                        object.italic = false;
                        object.underline = false;
                        object.strike = false;
                        object.align = options.enums === String ? "right" : 0;
                        object.background = "";
                        object.color = "";
                        object.font = "";
                        object.link = "";
                        object.script = options.enums === String ? "sub" : 0;
                        object.size = "";
                        object.list = options.enums === String ? "ordered" : 0;
                        object.indent = 0;
                        object.width = 0;
                        object.height = 0;
                        object.preserveAspect = false;
                        object.url = "";
                        object.alt = "";
                        object.blockquote = false;
                        object.direction = options.enums === String ? "rtl" : 0;
                        object.tableCol = null;
                        object.row = "";
                        object.rowspan = 0;
                        object.colspan = 0;
                        object.tableCellLine = null;
                    }
                    if (message.bold != null && message.hasOwnProperty("bold"))
                        object.bold = message.bold;
                    if (message.italic != null && message.hasOwnProperty("italic"))
                        object.italic = message.italic;
                    if (message.underline != null && message.hasOwnProperty("underline"))
                        object.underline = message.underline;
                    if (message.strike != null && message.hasOwnProperty("strike"))
                        object.strike = message.strike;
                    if (message.align != null && message.hasOwnProperty("align"))
                        object.align = options.enums === String ? $root.deltaproto.Delta.Op.Attributes.Align[message.align] : message.align;
                    if (message.background != null && message.hasOwnProperty("background"))
                        object.background = message.background;
                    if (message.color != null && message.hasOwnProperty("color"))
                        object.color = message.color;
                    if (message.font != null && message.hasOwnProperty("font"))
                        object.font = message.font;
                    if (message.link != null && message.hasOwnProperty("link"))
                        object.link = message.link;
                    if (message.script != null && message.hasOwnProperty("script"))
                        object.script = options.enums === String ? $root.deltaproto.Delta.Op.Attributes.Script[message.script] : message.script;
                    if (message.size != null && message.hasOwnProperty("size"))
                        object.size = message.size;
                    if (message.list != null && message.hasOwnProperty("list"))
                        object.list = options.enums === String ? $root.deltaproto.Delta.Op.Attributes.List[message.list] : message.list;
                    if (message.indent != null && message.hasOwnProperty("indent"))
                        object.indent = message.indent;
                    if (message.width != null && message.hasOwnProperty("width"))
                        object.width = message.width;
                    if (message.height != null && message.hasOwnProperty("height"))
                        object.height = message.height;
                    if (message.preserveAspect != null && message.hasOwnProperty("preserveAspect"))
                        object.preserveAspect = message.preserveAspect;
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.alt != null && message.hasOwnProperty("alt"))
                        object.alt = message.alt;
                    if (message.blockquote != null && message.hasOwnProperty("blockquote"))
                        object.blockquote = message.blockquote;
                    if (message.direction != null && message.hasOwnProperty("direction"))
                        object.direction = options.enums === String ? $root.deltaproto.Delta.Op.Attributes.Direction[message.direction] : message.direction;
                    if (message.tableCol != null && message.hasOwnProperty("tableCol"))
                        object.tableCol = $root.deltaproto.Delta.Op.Attributes.TableCol.toObject(message.tableCol, options);
                    if (message.row != null && message.hasOwnProperty("row"))
                        object.row = message.row;
                    if (message.rowspan != null && message.hasOwnProperty("rowspan"))
                        object.rowspan = message.rowspan;
                    if (message.colspan != null && message.hasOwnProperty("colspan"))
                        object.colspan = message.colspan;
                    if (message.tableCellLine != null && message.hasOwnProperty("tableCellLine"))
                        object.tableCellLine = $root.deltaproto.Delta.Op.Attributes.TableCellLine.toObject(message.tableCellLine, options);
                    return object;
                };

                /**
                 * Converts this Attributes to JSON.
                 * @function toJSON
                 * @memberof deltaproto.Delta.Op.Attributes
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Attributes.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Align enum.
                 * @name deltaproto.Delta.Op.Attributes.Align
                 * @enum {number}
                 * @property {number} right=0 right value
                 * @property {number} center=1 center value
                 * @property {number} justify=2 justify value
                 */
                Attributes.Align = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "right"] = 0;
                    values[valuesById[1] = "center"] = 1;
                    values[valuesById[2] = "justify"] = 2;
                    return values;
                })();

                /**
                 * Script enum.
                 * @name deltaproto.Delta.Op.Attributes.Script
                 * @enum {number}
                 * @property {number} sub=0 sub value
                 * @property {number} super=1 super value
                 */
                Attributes.Script = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "sub"] = 0;
                    values[valuesById[1] = "super"] = 1;
                    return values;
                })();

                /**
                 * List enum.
                 * @name deltaproto.Delta.Op.Attributes.List
                 * @enum {number}
                 * @property {number} ordered=0 ordered value
                 * @property {number} bullet=1 bullet value
                 */
                Attributes.List = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "ordered"] = 0;
                    values[valuesById[1] = "bullet"] = 1;
                    return values;
                })();

                /**
                 * Direction enum.
                 * @name deltaproto.Delta.Op.Attributes.Direction
                 * @enum {number}
                 * @property {number} rtl=0 rtl value
                 * @property {number} ltr=1 ltr value
                 */
                Attributes.Direction = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "rtl"] = 0;
                    values[valuesById[1] = "ltr"] = 1;
                    return values;
                })();

                Attributes.TableCol = (function() {

                    /**
                     * Properties of a TableCol.
                     * @memberof deltaproto.Delta.Op.Attributes
                     * @interface ITableCol
                     * @property {deltaproto.Delta.Op.Attributes.TableCol.TableAlign|null} [align] TableCol align
                     * @property {string|null} [width] TableCol width
                     */

                    /**
                     * Constructs a new TableCol.
                     * @memberof deltaproto.Delta.Op.Attributes
                     * @classdesc Represents a TableCol.
                     * @implements ITableCol
                     * @constructor
                     * @param {deltaproto.Delta.Op.Attributes.ITableCol=} [properties] Properties to set
                     */
                    function TableCol(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TableCol align.
                     * @member {deltaproto.Delta.Op.Attributes.TableCol.TableAlign} align
                     * @memberof deltaproto.Delta.Op.Attributes.TableCol
                     * @instance
                     */
                    TableCol.prototype.align = 0;

                    /**
                     * TableCol width.
                     * @member {string} width
                     * @memberof deltaproto.Delta.Op.Attributes.TableCol
                     * @instance
                     */
                    TableCol.prototype.width = "";

                    /**
                     * Creates a new TableCol instance using the specified properties.
                     * @function create
                     * @memberof deltaproto.Delta.Op.Attributes.TableCol
                     * @static
                     * @param {deltaproto.Delta.Op.Attributes.ITableCol=} [properties] Properties to set
                     * @returns {deltaproto.Delta.Op.Attributes.TableCol} TableCol instance
                     */
                    TableCol.create = function create(properties) {
                        return new TableCol(properties);
                    };

                    /**
                     * Encodes the specified TableCol message. Does not implicitly {@link deltaproto.Delta.Op.Attributes.TableCol.verify|verify} messages.
                     * @function encode
                     * @memberof deltaproto.Delta.Op.Attributes.TableCol
                     * @static
                     * @param {deltaproto.Delta.Op.Attributes.ITableCol} message TableCol message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TableCol.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.align != null && Object.hasOwnProperty.call(message, "align"))
                            writer.uint32(/* id 0, wireType 0 =*/0).int32(message.align);
                        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.width);
                        return writer;
                    };

                    /**
                     * Encodes the specified TableCol message, length delimited. Does not implicitly {@link deltaproto.Delta.Op.Attributes.TableCol.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof deltaproto.Delta.Op.Attributes.TableCol
                     * @static
                     * @param {deltaproto.Delta.Op.Attributes.ITableCol} message TableCol message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TableCol.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a TableCol message from the specified reader or buffer.
                     * @function decode
                     * @memberof deltaproto.Delta.Op.Attributes.TableCol
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {deltaproto.Delta.Op.Attributes.TableCol} TableCol
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TableCol.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.deltaproto.Delta.Op.Attributes.TableCol();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 0:
                                message.align = reader.int32();
                                break;
                            case 1:
                                message.width = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a TableCol message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof deltaproto.Delta.Op.Attributes.TableCol
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {deltaproto.Delta.Op.Attributes.TableCol} TableCol
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TableCol.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a TableCol message.
                     * @function verify
                     * @memberof deltaproto.Delta.Op.Attributes.TableCol
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TableCol.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.align != null && message.hasOwnProperty("align"))
                            switch (message.align) {
                            default:
                                return "align: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.width != null && message.hasOwnProperty("width"))
                            if (!$util.isString(message.width))
                                return "width: string expected";
                        return null;
                    };

                    /**
                     * Creates a TableCol message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof deltaproto.Delta.Op.Attributes.TableCol
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {deltaproto.Delta.Op.Attributes.TableCol} TableCol
                     */
                    TableCol.fromObject = function fromObject(object) {
                        if (object instanceof $root.deltaproto.Delta.Op.Attributes.TableCol)
                            return object;
                        let message = new $root.deltaproto.Delta.Op.Attributes.TableCol();
                        switch (object.align) {
                        case "left":
                        case 0:
                            message.align = 0;
                            break;
                        case "center":
                        case 1:
                            message.align = 1;
                            break;
                        case "right":
                        case 2:
                            message.align = 2;
                            break;
                        }
                        if (object.width != null)
                            message.width = String(object.width);
                        return message;
                    };

                    /**
                     * Creates a plain object from a TableCol message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof deltaproto.Delta.Op.Attributes.TableCol
                     * @static
                     * @param {deltaproto.Delta.Op.Attributes.TableCol} message TableCol
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TableCol.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.align = options.enums === String ? "left" : 0;
                            object.width = "";
                        }
                        if (message.align != null && message.hasOwnProperty("align"))
                            object.align = options.enums === String ? $root.deltaproto.Delta.Op.Attributes.TableCol.TableAlign[message.align] : message.align;
                        if (message.width != null && message.hasOwnProperty("width"))
                            object.width = message.width;
                        return object;
                    };

                    /**
                     * Converts this TableCol to JSON.
                     * @function toJSON
                     * @memberof deltaproto.Delta.Op.Attributes.TableCol
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TableCol.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * TableAlign enum.
                     * @name deltaproto.Delta.Op.Attributes.TableCol.TableAlign
                     * @enum {number}
                     * @property {number} left=0 left value
                     * @property {number} center=1 center value
                     * @property {number} right=2 right value
                     */
                    TableCol.TableAlign = (function() {
                        const valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "left"] = 0;
                        values[valuesById[1] = "center"] = 1;
                        values[valuesById[2] = "right"] = 2;
                        return values;
                    })();

                    return TableCol;
                })();

                /**
                 * VerticalAlign enum.
                 * @name deltaproto.Delta.Op.Attributes.VerticalAlign
                 * @enum {number}
                 * @property {number} top=0 top value
                 * @property {number} middle=1 middle value
                 * @property {number} bottom=2 bottom value
                 */
                Attributes.VerticalAlign = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "top"] = 0;
                    values[valuesById[1] = "middle"] = 1;
                    values[valuesById[2] = "bottom"] = 2;
                    return values;
                })();

                Attributes.TableCellLine = (function() {

                    /**
                     * Properties of a TableCellLine.
                     * @memberof deltaproto.Delta.Op.Attributes
                     * @interface ITableCellLine
                     * @property {string|null} [cell] TableCellLine cell
                     * @property {number|null} [colspan] TableCellLine colspan
                     * @property {string|null} [row] TableCellLine row
                     * @property {number|null} [rowspan] TableCellLine rowspan
                     * @property {string|null} [backgroundColor] TableCellLine backgroundColor
                     * @property {deltaproto.Delta.Op.Attributes.VerticalAlign|null} [verticalAlign] TableCellLine verticalAlign
                     * @property {string|null} [borderTopColor] TableCellLine borderTopColor
                     * @property {string|null} [borderTopWidth] TableCellLine borderTopWidth
                     * @property {string|null} [borderTopStyle] TableCellLine borderTopStyle
                     * @property {string|null} [borderRightColor] TableCellLine borderRightColor
                     * @property {string|null} [borderRightWidth] TableCellLine borderRightWidth
                     * @property {string|null} [borderRightStyle] TableCellLine borderRightStyle
                     * @property {string|null} [borderBottomColor] TableCellLine borderBottomColor
                     * @property {string|null} [borderBottomWidth] TableCellLine borderBottomWidth
                     * @property {string|null} [borderBottomStyle] TableCellLine borderBottomStyle
                     * @property {string|null} [borderLeftColor] TableCellLine borderLeftColor
                     * @property {string|null} [borderLeftWidth] TableCellLine borderLeftWidth
                     * @property {string|null} [borderLeftStyle] TableCellLine borderLeftStyle
                     */

                    /**
                     * Constructs a new TableCellLine.
                     * @memberof deltaproto.Delta.Op.Attributes
                     * @classdesc Represents a TableCellLine.
                     * @implements ITableCellLine
                     * @constructor
                     * @param {deltaproto.Delta.Op.Attributes.ITableCellLine=} [properties] Properties to set
                     */
                    function TableCellLine(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TableCellLine cell.
                     * @member {string} cell
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.cell = "";

                    /**
                     * TableCellLine colspan.
                     * @member {number} colspan
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.colspan = 0;

                    /**
                     * TableCellLine row.
                     * @member {string} row
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.row = "";

                    /**
                     * TableCellLine rowspan.
                     * @member {number} rowspan
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.rowspan = 0;

                    /**
                     * TableCellLine backgroundColor.
                     * @member {string} backgroundColor
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.backgroundColor = "";

                    /**
                     * TableCellLine verticalAlign.
                     * @member {deltaproto.Delta.Op.Attributes.VerticalAlign} verticalAlign
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.verticalAlign = 0;

                    /**
                     * TableCellLine borderTopColor.
                     * @member {string} borderTopColor
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.borderTopColor = "";

                    /**
                     * TableCellLine borderTopWidth.
                     * @member {string} borderTopWidth
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.borderTopWidth = "";

                    /**
                     * TableCellLine borderTopStyle.
                     * @member {string} borderTopStyle
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.borderTopStyle = "";

                    /**
                     * TableCellLine borderRightColor.
                     * @member {string} borderRightColor
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.borderRightColor = "";

                    /**
                     * TableCellLine borderRightWidth.
                     * @member {string} borderRightWidth
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.borderRightWidth = "";

                    /**
                     * TableCellLine borderRightStyle.
                     * @member {string} borderRightStyle
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.borderRightStyle = "";

                    /**
                     * TableCellLine borderBottomColor.
                     * @member {string} borderBottomColor
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.borderBottomColor = "";

                    /**
                     * TableCellLine borderBottomWidth.
                     * @member {string} borderBottomWidth
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.borderBottomWidth = "";

                    /**
                     * TableCellLine borderBottomStyle.
                     * @member {string} borderBottomStyle
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.borderBottomStyle = "";

                    /**
                     * TableCellLine borderLeftColor.
                     * @member {string} borderLeftColor
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.borderLeftColor = "";

                    /**
                     * TableCellLine borderLeftWidth.
                     * @member {string} borderLeftWidth
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.borderLeftWidth = "";

                    /**
                     * TableCellLine borderLeftStyle.
                     * @member {string} borderLeftStyle
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     */
                    TableCellLine.prototype.borderLeftStyle = "";

                    /**
                     * Creates a new TableCellLine instance using the specified properties.
                     * @function create
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @static
                     * @param {deltaproto.Delta.Op.Attributes.ITableCellLine=} [properties] Properties to set
                     * @returns {deltaproto.Delta.Op.Attributes.TableCellLine} TableCellLine instance
                     */
                    TableCellLine.create = function create(properties) {
                        return new TableCellLine(properties);
                    };

                    /**
                     * Encodes the specified TableCellLine message. Does not implicitly {@link deltaproto.Delta.Op.Attributes.TableCellLine.verify|verify} messages.
                     * @function encode
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @static
                     * @param {deltaproto.Delta.Op.Attributes.ITableCellLine} message TableCellLine message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TableCellLine.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cell != null && Object.hasOwnProperty.call(message, "cell"))
                            writer.uint32(/* id 0, wireType 2 =*/2).string(message.cell);
                        if (message.colspan != null && Object.hasOwnProperty.call(message, "colspan"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.colspan);
                        if (message.row != null && Object.hasOwnProperty.call(message, "row"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.row);
                        if (message.rowspan != null && Object.hasOwnProperty.call(message, "rowspan"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rowspan);
                        if (message.backgroundColor != null && Object.hasOwnProperty.call(message, "backgroundColor"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.backgroundColor);
                        if (message.verticalAlign != null && Object.hasOwnProperty.call(message, "verticalAlign"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.verticalAlign);
                        if (message.borderTopColor != null && Object.hasOwnProperty.call(message, "borderTopColor"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.borderTopColor);
                        if (message.borderTopWidth != null && Object.hasOwnProperty.call(message, "borderTopWidth"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.borderTopWidth);
                        if (message.borderTopStyle != null && Object.hasOwnProperty.call(message, "borderTopStyle"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.borderTopStyle);
                        if (message.borderRightColor != null && Object.hasOwnProperty.call(message, "borderRightColor"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.borderRightColor);
                        if (message.borderRightWidth != null && Object.hasOwnProperty.call(message, "borderRightWidth"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.borderRightWidth);
                        if (message.borderRightStyle != null && Object.hasOwnProperty.call(message, "borderRightStyle"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.borderRightStyle);
                        if (message.borderBottomColor != null && Object.hasOwnProperty.call(message, "borderBottomColor"))
                            writer.uint32(/* id 16, wireType 2 =*/130).string(message.borderBottomColor);
                        if (message.borderBottomWidth != null && Object.hasOwnProperty.call(message, "borderBottomWidth"))
                            writer.uint32(/* id 17, wireType 2 =*/138).string(message.borderBottomWidth);
                        if (message.borderBottomStyle != null && Object.hasOwnProperty.call(message, "borderBottomStyle"))
                            writer.uint32(/* id 18, wireType 2 =*/146).string(message.borderBottomStyle);
                        if (message.borderLeftColor != null && Object.hasOwnProperty.call(message, "borderLeftColor"))
                            writer.uint32(/* id 19, wireType 2 =*/154).string(message.borderLeftColor);
                        if (message.borderLeftWidth != null && Object.hasOwnProperty.call(message, "borderLeftWidth"))
                            writer.uint32(/* id 20, wireType 2 =*/162).string(message.borderLeftWidth);
                        if (message.borderLeftStyle != null && Object.hasOwnProperty.call(message, "borderLeftStyle"))
                            writer.uint32(/* id 21, wireType 2 =*/170).string(message.borderLeftStyle);
                        return writer;
                    };

                    /**
                     * Encodes the specified TableCellLine message, length delimited. Does not implicitly {@link deltaproto.Delta.Op.Attributes.TableCellLine.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @static
                     * @param {deltaproto.Delta.Op.Attributes.ITableCellLine} message TableCellLine message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TableCellLine.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a TableCellLine message from the specified reader or buffer.
                     * @function decode
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {deltaproto.Delta.Op.Attributes.TableCellLine} TableCellLine
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TableCellLine.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.deltaproto.Delta.Op.Attributes.TableCellLine();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 0:
                                message.cell = reader.string();
                                break;
                            case 1:
                                message.colspan = reader.int32();
                                break;
                            case 2:
                                message.row = reader.string();
                                break;
                            case 3:
                                message.rowspan = reader.int32();
                                break;
                            case 4:
                                message.backgroundColor = reader.string();
                                break;
                            case 5:
                                message.verticalAlign = reader.int32();
                                break;
                            case 10:
                                message.borderTopColor = reader.string();
                                break;
                            case 11:
                                message.borderTopWidth = reader.string();
                                break;
                            case 12:
                                message.borderTopStyle = reader.string();
                                break;
                            case 13:
                                message.borderRightColor = reader.string();
                                break;
                            case 14:
                                message.borderRightWidth = reader.string();
                                break;
                            case 15:
                                message.borderRightStyle = reader.string();
                                break;
                            case 16:
                                message.borderBottomColor = reader.string();
                                break;
                            case 17:
                                message.borderBottomWidth = reader.string();
                                break;
                            case 18:
                                message.borderBottomStyle = reader.string();
                                break;
                            case 19:
                                message.borderLeftColor = reader.string();
                                break;
                            case 20:
                                message.borderLeftWidth = reader.string();
                                break;
                            case 21:
                                message.borderLeftStyle = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a TableCellLine message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {deltaproto.Delta.Op.Attributes.TableCellLine} TableCellLine
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TableCellLine.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a TableCellLine message.
                     * @function verify
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TableCellLine.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cell != null && message.hasOwnProperty("cell"))
                            if (!$util.isString(message.cell))
                                return "cell: string expected";
                        if (message.colspan != null && message.hasOwnProperty("colspan"))
                            if (!$util.isInteger(message.colspan))
                                return "colspan: integer expected";
                        if (message.row != null && message.hasOwnProperty("row"))
                            if (!$util.isString(message.row))
                                return "row: string expected";
                        if (message.rowspan != null && message.hasOwnProperty("rowspan"))
                            if (!$util.isInteger(message.rowspan))
                                return "rowspan: integer expected";
                        if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
                            if (!$util.isString(message.backgroundColor))
                                return "backgroundColor: string expected";
                        if (message.verticalAlign != null && message.hasOwnProperty("verticalAlign"))
                            switch (message.verticalAlign) {
                            default:
                                return "verticalAlign: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.borderTopColor != null && message.hasOwnProperty("borderTopColor"))
                            if (!$util.isString(message.borderTopColor))
                                return "borderTopColor: string expected";
                        if (message.borderTopWidth != null && message.hasOwnProperty("borderTopWidth"))
                            if (!$util.isString(message.borderTopWidth))
                                return "borderTopWidth: string expected";
                        if (message.borderTopStyle != null && message.hasOwnProperty("borderTopStyle"))
                            if (!$util.isString(message.borderTopStyle))
                                return "borderTopStyle: string expected";
                        if (message.borderRightColor != null && message.hasOwnProperty("borderRightColor"))
                            if (!$util.isString(message.borderRightColor))
                                return "borderRightColor: string expected";
                        if (message.borderRightWidth != null && message.hasOwnProperty("borderRightWidth"))
                            if (!$util.isString(message.borderRightWidth))
                                return "borderRightWidth: string expected";
                        if (message.borderRightStyle != null && message.hasOwnProperty("borderRightStyle"))
                            if (!$util.isString(message.borderRightStyle))
                                return "borderRightStyle: string expected";
                        if (message.borderBottomColor != null && message.hasOwnProperty("borderBottomColor"))
                            if (!$util.isString(message.borderBottomColor))
                                return "borderBottomColor: string expected";
                        if (message.borderBottomWidth != null && message.hasOwnProperty("borderBottomWidth"))
                            if (!$util.isString(message.borderBottomWidth))
                                return "borderBottomWidth: string expected";
                        if (message.borderBottomStyle != null && message.hasOwnProperty("borderBottomStyle"))
                            if (!$util.isString(message.borderBottomStyle))
                                return "borderBottomStyle: string expected";
                        if (message.borderLeftColor != null && message.hasOwnProperty("borderLeftColor"))
                            if (!$util.isString(message.borderLeftColor))
                                return "borderLeftColor: string expected";
                        if (message.borderLeftWidth != null && message.hasOwnProperty("borderLeftWidth"))
                            if (!$util.isString(message.borderLeftWidth))
                                return "borderLeftWidth: string expected";
                        if (message.borderLeftStyle != null && message.hasOwnProperty("borderLeftStyle"))
                            if (!$util.isString(message.borderLeftStyle))
                                return "borderLeftStyle: string expected";
                        return null;
                    };

                    /**
                     * Creates a TableCellLine message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {deltaproto.Delta.Op.Attributes.TableCellLine} TableCellLine
                     */
                    TableCellLine.fromObject = function fromObject(object) {
                        if (object instanceof $root.deltaproto.Delta.Op.Attributes.TableCellLine)
                            return object;
                        let message = new $root.deltaproto.Delta.Op.Attributes.TableCellLine();
                        if (object.cell != null)
                            message.cell = String(object.cell);
                        if (object.colspan != null)
                            message.colspan = object.colspan | 0;
                        if (object.row != null)
                            message.row = String(object.row);
                        if (object.rowspan != null)
                            message.rowspan = object.rowspan | 0;
                        if (object.backgroundColor != null)
                            message.backgroundColor = String(object.backgroundColor);
                        switch (object.verticalAlign) {
                        case "top":
                        case 0:
                            message.verticalAlign = 0;
                            break;
                        case "middle":
                        case 1:
                            message.verticalAlign = 1;
                            break;
                        case "bottom":
                        case 2:
                            message.verticalAlign = 2;
                            break;
                        }
                        if (object.borderTopColor != null)
                            message.borderTopColor = String(object.borderTopColor);
                        if (object.borderTopWidth != null)
                            message.borderTopWidth = String(object.borderTopWidth);
                        if (object.borderTopStyle != null)
                            message.borderTopStyle = String(object.borderTopStyle);
                        if (object.borderRightColor != null)
                            message.borderRightColor = String(object.borderRightColor);
                        if (object.borderRightWidth != null)
                            message.borderRightWidth = String(object.borderRightWidth);
                        if (object.borderRightStyle != null)
                            message.borderRightStyle = String(object.borderRightStyle);
                        if (object.borderBottomColor != null)
                            message.borderBottomColor = String(object.borderBottomColor);
                        if (object.borderBottomWidth != null)
                            message.borderBottomWidth = String(object.borderBottomWidth);
                        if (object.borderBottomStyle != null)
                            message.borderBottomStyle = String(object.borderBottomStyle);
                        if (object.borderLeftColor != null)
                            message.borderLeftColor = String(object.borderLeftColor);
                        if (object.borderLeftWidth != null)
                            message.borderLeftWidth = String(object.borderLeftWidth);
                        if (object.borderLeftStyle != null)
                            message.borderLeftStyle = String(object.borderLeftStyle);
                        return message;
                    };

                    /**
                     * Creates a plain object from a TableCellLine message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @static
                     * @param {deltaproto.Delta.Op.Attributes.TableCellLine} message TableCellLine
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TableCellLine.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.cell = "";
                            object.colspan = 0;
                            object.row = "";
                            object.rowspan = 0;
                            object.backgroundColor = "";
                            object.verticalAlign = options.enums === String ? "top" : 0;
                            object.borderTopColor = "";
                            object.borderTopWidth = "";
                            object.borderTopStyle = "";
                            object.borderRightColor = "";
                            object.borderRightWidth = "";
                            object.borderRightStyle = "";
                            object.borderBottomColor = "";
                            object.borderBottomWidth = "";
                            object.borderBottomStyle = "";
                            object.borderLeftColor = "";
                            object.borderLeftWidth = "";
                            object.borderLeftStyle = "";
                        }
                        if (message.cell != null && message.hasOwnProperty("cell"))
                            object.cell = message.cell;
                        if (message.colspan != null && message.hasOwnProperty("colspan"))
                            object.colspan = message.colspan;
                        if (message.row != null && message.hasOwnProperty("row"))
                            object.row = message.row;
                        if (message.rowspan != null && message.hasOwnProperty("rowspan"))
                            object.rowspan = message.rowspan;
                        if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
                            object.backgroundColor = message.backgroundColor;
                        if (message.verticalAlign != null && message.hasOwnProperty("verticalAlign"))
                            object.verticalAlign = options.enums === String ? $root.deltaproto.Delta.Op.Attributes.VerticalAlign[message.verticalAlign] : message.verticalAlign;
                        if (message.borderTopColor != null && message.hasOwnProperty("borderTopColor"))
                            object.borderTopColor = message.borderTopColor;
                        if (message.borderTopWidth != null && message.hasOwnProperty("borderTopWidth"))
                            object.borderTopWidth = message.borderTopWidth;
                        if (message.borderTopStyle != null && message.hasOwnProperty("borderTopStyle"))
                            object.borderTopStyle = message.borderTopStyle;
                        if (message.borderRightColor != null && message.hasOwnProperty("borderRightColor"))
                            object.borderRightColor = message.borderRightColor;
                        if (message.borderRightWidth != null && message.hasOwnProperty("borderRightWidth"))
                            object.borderRightWidth = message.borderRightWidth;
                        if (message.borderRightStyle != null && message.hasOwnProperty("borderRightStyle"))
                            object.borderRightStyle = message.borderRightStyle;
                        if (message.borderBottomColor != null && message.hasOwnProperty("borderBottomColor"))
                            object.borderBottomColor = message.borderBottomColor;
                        if (message.borderBottomWidth != null && message.hasOwnProperty("borderBottomWidth"))
                            object.borderBottomWidth = message.borderBottomWidth;
                        if (message.borderBottomStyle != null && message.hasOwnProperty("borderBottomStyle"))
                            object.borderBottomStyle = message.borderBottomStyle;
                        if (message.borderLeftColor != null && message.hasOwnProperty("borderLeftColor"))
                            object.borderLeftColor = message.borderLeftColor;
                        if (message.borderLeftWidth != null && message.hasOwnProperty("borderLeftWidth"))
                            object.borderLeftWidth = message.borderLeftWidth;
                        if (message.borderLeftStyle != null && message.hasOwnProperty("borderLeftStyle"))
                            object.borderLeftStyle = message.borderLeftStyle;
                        return object;
                    };

                    /**
                     * Converts this TableCellLine to JSON.
                     * @function toJSON
                     * @memberof deltaproto.Delta.Op.Attributes.TableCellLine
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TableCellLine.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return TableCellLine;
                })();

                return Attributes;
            })();

            return Op;
        })();

        return Delta;
    })();

    return deltaproto;
})();

export { $root as default };
