import React, { useContext, useLayoutEffect, useMemo } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import ViewportContext from './ViewportContext';


/**
 * The viewport allows you to change the contents of the children components
 * depending on the position of the scroll.
 * Those components that have not yet been shown can be empty.
 * Those components that are in the displayed area will be fully.
 *
 * @param {object} props
 * @param {React.CSSProperties=} props.style
 * @param {any} props.children
 * @param {number=} props.oversize Extra buffer distance in pixels
 * @param {React.MutableRefObject<HTMLDivElement>} boxRef
 */
function ViewportBase(props, boxRef) {
  let parentController = useContext(ViewportContext);

  // We need to get the viewport controller with logic from the parent context or create a new one
  let controller = useMemo(() => (
    parentController || createViewportController(boxRef, props.oversize)
  ), [parentController, props.oversize, boxRef]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(controller.finishInitPhase, [controller.finishInitPhase]);

  return (
    <div
      style={Object.assign({ overflowY: 'auto' }, props.style)} // scrollable container
      onScroll={controller.scrollHandler}
      ref={boxRef}
    >
      <ViewportContext.Provider value={controller}>
        {props.children}
      </ViewportContext.Provider>
    </div>
  );
}

const Viewport = React.memo(React.forwardRef(ViewportBase));
export default Viewport;


/**
 * Creates a viewport controller
 * @param {React.MutableRefObject<HTMLDivElement>} boxRef
 * @param {number} [oversize]
 * @returns 
 */
function createViewportController(boxRef, oversize = 0) {
  let initPhase = true;
  let map = [];

  let items = [];

  // Connects React component to track dom node position and display inside the viewport. (used in useViewportItem)
  function itemFactory(appearFn) {
    let item = { node: null, appearFn, appeared: false };
    items.push(item);

    return {
      ref(node) {
        item.node = node;
      },
      refresh,
      destroy() {
        let index = items.indexOf(item);
        if (index >= 0) {
          items.splice(index, 1);
          refresh();
        }
      }
    };
  }

  let refreshTimerId;
  function refresh(immediately) {
    if (initPhase) {
      return;
    }

    clearTimeout(refreshTimerId);
    if (immediately) {
      refreshInner();
    } else {
      refreshTimerId = setTimeout(refreshInner);
    }
  }

  function refreshInner() {
    let box = boxRef.current;
    if (!box) {
      return;
    }

    let boxRect = box.getBoundingClientRect();
    let boxTop = boxRect.top - box.scrollTop;

    map = [];

    // Determine the top and bottom positions for elements relative to the scrolling container
    for (let item of items) {
      if (item.appeared || !item.node) {
        continue;
      }
      let rect = item.node.getBoundingClientRect();
      let top = rect.top - boxTop;
      let bottom = top + rect.bottom - rect.top;

      map.push({
        top,
        bottom,
        item
      });
    }
    map.sort((a, b) => a.top - b.top);

    checkAppeared();
  }

  // Call the function of appearing on all components that are in the displayed area.
  function checkAppeared() {
    let box = boxRef.current;
    if (!box) {
      return;
    }
    let { scrollHeight, scrollTop, clientHeight } = box;

    // Prevent Safari's elastic scrolling from causing visual shaking when scrolling past bounds.
    let scrollOffset = Math.max(
      0,
      Math.min(scrollTop, scrollHeight - clientHeight)
    );

    // More then client height of scroll container for buffering
    let from = Math.max(0, scrollOffset - oversize);
    let to = scrollOffset + clientHeight + oversize;

    // Determine the elements that are in the displayed area and a little more (oversize)
    let displayed = [];
    for (let { top, bottom, item } of map) {
      if (bottom <= from) {
        continue;
      }
      if (top >= to) {
        break; // map is sorted by top
      }
      displayed.push(item);
    }

    if (displayed.length > 0) {
      unstable_batchedUpdates(() => {
        // To set the value of appeared for the selected elements
        displayed.forEach(item => {
          item.appearFn(true);
          item.appeared = true;
        });
        // To remove displayed from map
        map = map.filter(({ item }) => !displayed.includes(item));
      });
    }
  }

  let checkedScrollTop;
  function scrollHandler(event) {
    let scrollTop = event.currentTarget.scrollTop;

    if (checkedScrollTop === scrollTop) {
      // Scroll position may have been updated by cDM/cDU,
      // In which case we don't need to trigger another render,
      // And we don't want to update state.isScrolling.
      return null;
    }
    checkedScrollTop = scrollTop;

    checkAppeared();
  }

  // We do the first recalculation after all the elements are rendered for the first time.
  function finishInitPhase() {
    initPhase = false;
    refresh(true);
  }


  return {
    scrollHandler,
    itemFactory,
    finishInitPhase
  };
}
