import React, { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Typography as T
} from '@mui/material';
import MessageDisplayInner from './MessageDisplayInner';
import CheckIcon from '@mui/icons-material/Check';
// @ts-ignore
import anim_form from '../../../images/anim_forms.webp';
import { log } from '../../logging/logging';
import { checkPro } from '../../flags';
import { showDialog } from '../../message';

const MessageDisplay = forwardRef((/** @type {import('./MessageDisplayInner').ToastPropsType} */ props, ref) => {
  const history = useHistory();
  /**
   * @type {(msg: string, config: import('./MessageDisplayInner').MessageConfigType) => void}
   */
  function showUpgrade(msg, config) {
    log({
      category: 'Purchase',
      action: 'Show toast with upgrade message'
    });

    let basicUpgradeBenefits = [
      <>Form fields <span style={{ opacity: .6 }}>(see animation)</span></>,
      'Up to 1,000 snippets',
      'Images in snippets',
      'Improved team collaboration'
    ];
    let proUpgradeBenefits = [
      'Up to 4,000 snippets',
      'Snippet change history',
      'Improved team collaboration',
      'Team analytics'
    ];

    let isPro = checkPro(props.store.getState());
    let benefits = isPro ? proUpgradeBenefits : basicUpgradeBenefits;

    function Li(props) {
      return <li style={{ marginBottom: 8 }}><CheckIcon style={{
        display: 'inline-block',
        color: 'darkgreen',
        verticalAlign: 'middle',
        marginRight: 12
      }} /> <T style={{
        display: 'inline-block',
        verticalAlign: 'middle'
      }}>{props.children}</T></li>;
    }

    showDialog({
      contents: (closeFn) => <div style={{
        maxWidth: 640
      }}>
        <Alert severity="warning" style={{
          marginBottom: 18,
        }}>
          <T>{msg}</T>
        </Alert>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'middle',
            marginBottom: 24
          }}
        >
          <div style={{ flex: 1 }}>
            <T><b>{config.upgrade}</b> When you do, you'll also get:</T>
            <ul style={{
              paddingInlineStart: 12,
              marginTop: 20,
              listStyleType: 'none'
            }}>
              {benefits.map((b, i) => <Li key={i}>{b}</Li>)}
              <Li>And much more!</Li>
            </ul>
          </div>
          <Box
            sx={{
              marginLeft: 2,
              display: {
                xs: 'none',
                sm: 'block'
              }
            }}
          >
            <img src={anim_form} alt="Forms" height={260} width={302} />
          </Box>
        </div>
        <div style={{
          textAlign: 'center',
          marginBottom: 24
        }}>
          <Button
            style={{
              marginRight: 24
            }}
            onClick={() => closeFn()}
          >
            Not now
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              history.push('/upgrade');
              closeFn();
            }}
          >
            Upgrade Text Blaze
          </Button>
        </div>
        <T
          style={{
            marginBottom: 18,
            paddingTop: 8,
            textAlign: 'center'
          }}
          sx={{
            color: '#666'
          }}
          variant="body2"
        >
          <a
            onClick={() => {
              closeFn();
            }}
            href="#refer"
            style={{
              color: 'inherit'
            }}
          >
            Or refer other users and get one month of Pro for free for each new user
          </a>
        </T>
      </div>
    });
  }

  return <MessageDisplayInner {...props} showUpgradeFn={showUpgrade} ref={ref} />;
});

export default MessageDisplay;