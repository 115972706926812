// @ts-nocheck
/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots.chatproto || ($protobuf.roots.chatproto = {});

export const chatproto = $root.chatproto = (() => {

    /**
     * Namespace chatproto.
     * @exports chatproto
     * @namespace
     */
    const chatproto = {};

    chatproto.Request = (function() {

        /**
         * Properties of a Request.
         * @memberof chatproto
         * @interface IRequest
         * @property {string|null} [action] Request action
         * @property {chatproto.Request.IDomainData|null} [domainData] Request domainData
         * @property {string|null} [extensionType] Request extensionType
         * @property {string|null} [idToken] Request idToken
         * @property {Array.<chatproto.Request.IMessage>|null} [messages] Request messages
         * @property {string|null} [modelName] Request modelName
         */

        /**
         * Constructs a new Request.
         * @memberof chatproto
         * @classdesc Represents a Request.
         * @implements IRequest
         * @constructor
         * @param {chatproto.IRequest=} [properties] Properties to set
         */
        function Request(properties) {
            this.messages = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Request action.
         * @member {string} action
         * @memberof chatproto.Request
         * @instance
         */
        Request.prototype.action = "";

        /**
         * Request domainData.
         * @member {chatproto.Request.IDomainData|null|undefined} domainData
         * @memberof chatproto.Request
         * @instance
         */
        Request.prototype.domainData = null;

        /**
         * Request extensionType.
         * @member {string} extensionType
         * @memberof chatproto.Request
         * @instance
         */
        Request.prototype.extensionType = "";

        /**
         * Request idToken.
         * @member {string} idToken
         * @memberof chatproto.Request
         * @instance
         */
        Request.prototype.idToken = "";

        /**
         * Request messages.
         * @member {Array.<chatproto.Request.IMessage>} messages
         * @memberof chatproto.Request
         * @instance
         */
        Request.prototype.messages = $util.emptyArray;

        /**
         * Request modelName.
         * @member {string} modelName
         * @memberof chatproto.Request
         * @instance
         */
        Request.prototype.modelName = "";

        /**
         * Creates a new Request instance using the specified properties.
         * @function create
         * @memberof chatproto.Request
         * @static
         * @param {chatproto.IRequest=} [properties] Properties to set
         * @returns {chatproto.Request} Request instance
         */
        Request.create = function create(properties) {
            return new Request(properties);
        };

        /**
         * Encodes the specified Request message. Does not implicitly {@link chatproto.Request.verify|verify} messages.
         * @function encode
         * @memberof chatproto.Request
         * @static
         * @param {chatproto.IRequest} message Request message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Request.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(/* id 0, wireType 2 =*/2).string(message.action);
            if (message.domainData != null && Object.hasOwnProperty.call(message, "domainData"))
                $root.chatproto.Request.DomainData.encode(message.domainData, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.extensionType != null && Object.hasOwnProperty.call(message, "extensionType"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.extensionType);
            if (message.idToken != null && Object.hasOwnProperty.call(message, "idToken"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.idToken);
            if (message.messages != null && message.messages.length)
                for (let i = 0; i < message.messages.length; ++i)
                    $root.chatproto.Request.Message.encode(message.messages[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.modelName != null && Object.hasOwnProperty.call(message, "modelName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.modelName);
            return writer;
        };

        /**
         * Encodes the specified Request message, length delimited. Does not implicitly {@link chatproto.Request.verify|verify} messages.
         * @function encodeDelimited
         * @memberof chatproto.Request
         * @static
         * @param {chatproto.IRequest} message Request message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Request.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Request message from the specified reader or buffer.
         * @function decode
         * @memberof chatproto.Request
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chatproto.Request} Request
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Request.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chatproto.Request();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 0: {
                        message.action = reader.string();
                        break;
                    }
                case 1: {
                        message.domainData = $root.chatproto.Request.DomainData.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.extensionType = reader.string();
                        break;
                    }
                case 3: {
                        message.idToken = reader.string();
                        break;
                    }
                case 4: {
                        if (!(message.messages && message.messages.length))
                            message.messages = [];
                        message.messages.push($root.chatproto.Request.Message.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        message.modelName = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Request message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof chatproto.Request
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {chatproto.Request} Request
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Request.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Request message.
         * @function verify
         * @memberof chatproto.Request
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Request.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.action != null && message.hasOwnProperty("action"))
                if (!$util.isString(message.action))
                    return "action: string expected";
            if (message.domainData != null && message.hasOwnProperty("domainData")) {
                let error = $root.chatproto.Request.DomainData.verify(message.domainData);
                if (error)
                    return "domainData." + error;
            }
            if (message.extensionType != null && message.hasOwnProperty("extensionType"))
                if (!$util.isString(message.extensionType))
                    return "extensionType: string expected";
            if (message.idToken != null && message.hasOwnProperty("idToken"))
                if (!$util.isString(message.idToken))
                    return "idToken: string expected";
            if (message.messages != null && message.hasOwnProperty("messages")) {
                if (!Array.isArray(message.messages))
                    return "messages: array expected";
                for (let i = 0; i < message.messages.length; ++i) {
                    let error = $root.chatproto.Request.Message.verify(message.messages[i]);
                    if (error)
                        return "messages." + error;
                }
            }
            if (message.modelName != null && message.hasOwnProperty("modelName"))
                if (!$util.isString(message.modelName))
                    return "modelName: string expected";
            return null;
        };

        /**
         * Creates a Request message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof chatproto.Request
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {chatproto.Request} Request
         */
        Request.fromObject = function fromObject(object) {
            if (object instanceof $root.chatproto.Request)
                return object;
            let message = new $root.chatproto.Request();
            if (object.action != null)
                message.action = String(object.action);
            if (object.domainData != null) {
                if (typeof object.domainData !== "object")
                    throw TypeError(".chatproto.Request.domainData: object expected");
                message.domainData = $root.chatproto.Request.DomainData.fromObject(object.domainData);
            }
            if (object.extensionType != null)
                message.extensionType = String(object.extensionType);
            if (object.idToken != null)
                message.idToken = String(object.idToken);
            if (object.messages) {
                if (!Array.isArray(object.messages))
                    throw TypeError(".chatproto.Request.messages: array expected");
                message.messages = [];
                for (let i = 0; i < object.messages.length; ++i) {
                    if (typeof object.messages[i] !== "object")
                        throw TypeError(".chatproto.Request.messages: object expected");
                    message.messages[i] = $root.chatproto.Request.Message.fromObject(object.messages[i]);
                }
            }
            if (object.modelName != null)
                message.modelName = String(object.modelName);
            return message;
        };

        /**
         * Creates a plain object from a Request message. Also converts values to other types if specified.
         * @function toObject
         * @memberof chatproto.Request
         * @static
         * @param {chatproto.Request} message Request
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Request.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.messages = [];
            if (options.defaults) {
                object.action = "";
                object.domainData = null;
                object.extensionType = "";
                object.idToken = "";
                object.modelName = "";
            }
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.domainData != null && message.hasOwnProperty("domainData"))
                object.domainData = $root.chatproto.Request.DomainData.toObject(message.domainData, options);
            if (message.extensionType != null && message.hasOwnProperty("extensionType"))
                object.extensionType = message.extensionType;
            if (message.idToken != null && message.hasOwnProperty("idToken"))
                object.idToken = message.idToken;
            if (message.messages && message.messages.length) {
                object.messages = [];
                for (let j = 0; j < message.messages.length; ++j)
                    object.messages[j] = $root.chatproto.Request.Message.toObject(message.messages[j], options);
            }
            if (message.modelName != null && message.hasOwnProperty("modelName"))
                object.modelName = message.modelName;
            return object;
        };

        /**
         * Converts this Request to JSON.
         * @function toJSON
         * @memberof chatproto.Request
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Request.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Request
         * @function getTypeUrl
         * @memberof chatproto.Request
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/chatproto.Request";
        };

        Request.DomainData = (function() {

            /**
             * Properties of a DomainData.
             * @memberof chatproto.Request
             * @interface IDomainData
             * @property {string|null} [hostname] DomainData hostname
             * @property {string|null} [pageContent] DomainData pageContent
             * @property {Uint8Array|null} [rawCapturedImage] DomainData rawCapturedImage
             * @property {string|null} [rawImageType] DomainData rawImageType
             * @property {string|null} [selectedContent] DomainData selectedContent
             */

            /**
             * Constructs a new DomainData.
             * @memberof chatproto.Request
             * @classdesc Represents a DomainData.
             * @implements IDomainData
             * @constructor
             * @param {chatproto.Request.IDomainData=} [properties] Properties to set
             */
            function DomainData(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DomainData hostname.
             * @member {string} hostname
             * @memberof chatproto.Request.DomainData
             * @instance
             */
            DomainData.prototype.hostname = "";

            /**
             * DomainData pageContent.
             * @member {string} pageContent
             * @memberof chatproto.Request.DomainData
             * @instance
             */
            DomainData.prototype.pageContent = "";

            /**
             * DomainData rawCapturedImage.
             * @member {Uint8Array} rawCapturedImage
             * @memberof chatproto.Request.DomainData
             * @instance
             */
            DomainData.prototype.rawCapturedImage = $util.newBuffer([]);

            /**
             * DomainData rawImageType.
             * @member {string} rawImageType
             * @memberof chatproto.Request.DomainData
             * @instance
             */
            DomainData.prototype.rawImageType = "";

            /**
             * DomainData selectedContent.
             * @member {string} selectedContent
             * @memberof chatproto.Request.DomainData
             * @instance
             */
            DomainData.prototype.selectedContent = "";

            /**
             * Creates a new DomainData instance using the specified properties.
             * @function create
             * @memberof chatproto.Request.DomainData
             * @static
             * @param {chatproto.Request.IDomainData=} [properties] Properties to set
             * @returns {chatproto.Request.DomainData} DomainData instance
             */
            DomainData.create = function create(properties) {
                return new DomainData(properties);
            };

            /**
             * Encodes the specified DomainData message. Does not implicitly {@link chatproto.Request.DomainData.verify|verify} messages.
             * @function encode
             * @memberof chatproto.Request.DomainData
             * @static
             * @param {chatproto.Request.IDomainData} message DomainData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.hostname != null && Object.hasOwnProperty.call(message, "hostname"))
                    writer.uint32(/* id 0, wireType 2 =*/2).string(message.hostname);
                if (message.pageContent != null && Object.hasOwnProperty.call(message, "pageContent"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.pageContent);
                if (message.rawCapturedImage != null && Object.hasOwnProperty.call(message, "rawCapturedImage"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.rawCapturedImage);
                if (message.rawImageType != null && Object.hasOwnProperty.call(message, "rawImageType"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.rawImageType);
                if (message.selectedContent != null && Object.hasOwnProperty.call(message, "selectedContent"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.selectedContent);
                return writer;
            };

            /**
             * Encodes the specified DomainData message, length delimited. Does not implicitly {@link chatproto.Request.DomainData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof chatproto.Request.DomainData
             * @static
             * @param {chatproto.Request.IDomainData} message DomainData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DomainData message from the specified reader or buffer.
             * @function decode
             * @memberof chatproto.Request.DomainData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {chatproto.Request.DomainData} DomainData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chatproto.Request.DomainData();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 0: {
                            message.hostname = reader.string();
                            break;
                        }
                    case 1: {
                            message.pageContent = reader.string();
                            break;
                        }
                    case 2: {
                            message.rawCapturedImage = reader.bytes();
                            break;
                        }
                    case 3: {
                            message.rawImageType = reader.string();
                            break;
                        }
                    case 4: {
                            message.selectedContent = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DomainData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof chatproto.Request.DomainData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {chatproto.Request.DomainData} DomainData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DomainData message.
             * @function verify
             * @memberof chatproto.Request.DomainData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DomainData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.hostname != null && message.hasOwnProperty("hostname"))
                    if (!$util.isString(message.hostname))
                        return "hostname: string expected";
                if (message.pageContent != null && message.hasOwnProperty("pageContent"))
                    if (!$util.isString(message.pageContent))
                        return "pageContent: string expected";
                if (message.rawCapturedImage != null && message.hasOwnProperty("rawCapturedImage"))
                    if (!(message.rawCapturedImage && typeof message.rawCapturedImage.length === "number" || $util.isString(message.rawCapturedImage)))
                        return "rawCapturedImage: buffer expected";
                if (message.rawImageType != null && message.hasOwnProperty("rawImageType"))
                    if (!$util.isString(message.rawImageType))
                        return "rawImageType: string expected";
                if (message.selectedContent != null && message.hasOwnProperty("selectedContent"))
                    if (!$util.isString(message.selectedContent))
                        return "selectedContent: string expected";
                return null;
            };

            /**
             * Creates a DomainData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof chatproto.Request.DomainData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {chatproto.Request.DomainData} DomainData
             */
            DomainData.fromObject = function fromObject(object) {
                if (object instanceof $root.chatproto.Request.DomainData)
                    return object;
                let message = new $root.chatproto.Request.DomainData();
                if (object.hostname != null)
                    message.hostname = String(object.hostname);
                if (object.pageContent != null)
                    message.pageContent = String(object.pageContent);
                if (object.rawCapturedImage != null)
                    if (typeof object.rawCapturedImage === "string")
                        $util.base64.decode(object.rawCapturedImage, message.rawCapturedImage = $util.newBuffer($util.base64.length(object.rawCapturedImage)), 0);
                    else if (object.rawCapturedImage.length >= 0)
                        message.rawCapturedImage = object.rawCapturedImage;
                if (object.rawImageType != null)
                    message.rawImageType = String(object.rawImageType);
                if (object.selectedContent != null)
                    message.selectedContent = String(object.selectedContent);
                return message;
            };

            /**
             * Creates a plain object from a DomainData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof chatproto.Request.DomainData
             * @static
             * @param {chatproto.Request.DomainData} message DomainData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DomainData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.hostname = "";
                    object.pageContent = "";
                    if (options.bytes === String)
                        object.rawCapturedImage = "";
                    else {
                        object.rawCapturedImage = [];
                        if (options.bytes !== Array)
                            object.rawCapturedImage = $util.newBuffer(object.rawCapturedImage);
                    }
                    object.rawImageType = "";
                    object.selectedContent = "";
                }
                if (message.hostname != null && message.hasOwnProperty("hostname"))
                    object.hostname = message.hostname;
                if (message.pageContent != null && message.hasOwnProperty("pageContent"))
                    object.pageContent = message.pageContent;
                if (message.rawCapturedImage != null && message.hasOwnProperty("rawCapturedImage"))
                    object.rawCapturedImage = options.bytes === String ? $util.base64.encode(message.rawCapturedImage, 0, message.rawCapturedImage.length) : options.bytes === Array ? Array.prototype.slice.call(message.rawCapturedImage) : message.rawCapturedImage;
                if (message.rawImageType != null && message.hasOwnProperty("rawImageType"))
                    object.rawImageType = message.rawImageType;
                if (message.selectedContent != null && message.hasOwnProperty("selectedContent"))
                    object.selectedContent = message.selectedContent;
                return object;
            };

            /**
             * Converts this DomainData to JSON.
             * @function toJSON
             * @memberof chatproto.Request.DomainData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DomainData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DomainData
             * @function getTypeUrl
             * @memberof chatproto.Request.DomainData
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DomainData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/chatproto.Request.DomainData";
            };

            return DomainData;
        })();

        Request.Message = (function() {

            /**
             * Properties of a Message.
             * @memberof chatproto.Request
             * @interface IMessage
             * @property {string|null} [content] Message content
             * @property {string|null} [role] Message role
             */

            /**
             * Constructs a new Message.
             * @memberof chatproto.Request
             * @classdesc Represents a Message.
             * @implements IMessage
             * @constructor
             * @param {chatproto.Request.IMessage=} [properties] Properties to set
             */
            function Message(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Message content.
             * @member {string} content
             * @memberof chatproto.Request.Message
             * @instance
             */
            Message.prototype.content = "";

            /**
             * Message role.
             * @member {string} role
             * @memberof chatproto.Request.Message
             * @instance
             */
            Message.prototype.role = "";

            /**
             * Creates a new Message instance using the specified properties.
             * @function create
             * @memberof chatproto.Request.Message
             * @static
             * @param {chatproto.Request.IMessage=} [properties] Properties to set
             * @returns {chatproto.Request.Message} Message instance
             */
            Message.create = function create(properties) {
                return new Message(properties);
            };

            /**
             * Encodes the specified Message message. Does not implicitly {@link chatproto.Request.Message.verify|verify} messages.
             * @function encode
             * @memberof chatproto.Request.Message
             * @static
             * @param {chatproto.Request.IMessage} message Message message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Message.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                    writer.uint32(/* id 0, wireType 2 =*/2).string(message.content);
                if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.role);
                return writer;
            };

            /**
             * Encodes the specified Message message, length delimited. Does not implicitly {@link chatproto.Request.Message.verify|verify} messages.
             * @function encodeDelimited
             * @memberof chatproto.Request.Message
             * @static
             * @param {chatproto.Request.IMessage} message Message message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Message.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Message message from the specified reader or buffer.
             * @function decode
             * @memberof chatproto.Request.Message
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {chatproto.Request.Message} Message
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Message.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chatproto.Request.Message();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 0: {
                            message.content = reader.string();
                            break;
                        }
                    case 1: {
                            message.role = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Message message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof chatproto.Request.Message
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {chatproto.Request.Message} Message
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Message.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Message message.
             * @function verify
             * @memberof chatproto.Request.Message
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Message.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.content != null && message.hasOwnProperty("content"))
                    if (!$util.isString(message.content))
                        return "content: string expected";
                if (message.role != null && message.hasOwnProperty("role"))
                    if (!$util.isString(message.role))
                        return "role: string expected";
                return null;
            };

            /**
             * Creates a Message message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof chatproto.Request.Message
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {chatproto.Request.Message} Message
             */
            Message.fromObject = function fromObject(object) {
                if (object instanceof $root.chatproto.Request.Message)
                    return object;
                let message = new $root.chatproto.Request.Message();
                if (object.content != null)
                    message.content = String(object.content);
                if (object.role != null)
                    message.role = String(object.role);
                return message;
            };

            /**
             * Creates a plain object from a Message message. Also converts values to other types if specified.
             * @function toObject
             * @memberof chatproto.Request.Message
             * @static
             * @param {chatproto.Request.Message} message Message
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Message.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.content = "";
                    object.role = "";
                }
                if (message.content != null && message.hasOwnProperty("content"))
                    object.content = message.content;
                if (message.role != null && message.hasOwnProperty("role"))
                    object.role = message.role;
                return object;
            };

            /**
             * Converts this Message to JSON.
             * @function toJSON
             * @memberof chatproto.Request.Message
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Message.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Message
             * @function getTypeUrl
             * @memberof chatproto.Request.Message
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Message.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/chatproto.Request.Message";
            };

            return Message;
        })();

        return Request;
    })();

    return chatproto;
})();

export { $root as default };
