import React from 'react';
// @ts-ignore
import _basic from './../../../images/panda/basic.jpeg';
// @ts-ignore
import _beach from './../../../images/panda/beach.jpeg';
// @ts-ignore
import _christmas from './../../../images/panda/christmas.jpeg';
// @ts-ignore
import _fall from './../../../images/panda/fall.jpeg';
// @ts-ignore
import _halloween from './../../../images/panda/halloween.jpeg';
// @ts-ignore
import _hi from './../../../images/panda/hi.jpeg';
// @ts-ignore
import _icecream from './../../../images/panda/icecream.jpeg';
// @ts-ignore
import _school from './../../../images/panda/back_to_school.jpeg';
// @ts-ignore
import _reading from './../../../images/panda/reading.jpeg';
// @ts-ignore
import _cleaning from './../../../images/panda/cleaning.jpeg';
// @ts-ignore
import _scooting from './../../../images/panda/scooting.jpeg';
// @ts-ignore
import _sleepy from './../../../images/panda/sleepy.jpeg';
// @ts-ignore
import _workout from './../../../images/panda/workout.jpeg';
// @ts-ignore
import _winter from './../../../images/panda/winter.jpeg';
// @ts-ignore
import _valentines from './../../../images/panda/valentines.jpeg';
// @ts-ignore
import _vacation from './../../../images/panda/vacation.jpeg';
// @ts-ignore
import _spring from './../../../images/panda/spring.jpeg';
import { safeLocalStorage } from '../../localstorage_utility';


const basic = [
  {
    img: _basic,
    weight: 8,
  },
  {
    img: _hi,
    weight: 1
  }
];


const activities = [
  {
    img: _vacation,
    weight: 2
  },
  {
    img: _workout,
    weight: 4
  },
  {
    img: _sleepy,
    weight: 3
  },
  {
    img: _icecream,
    weight: 5
  },
  {
    img: _reading,
    weight: 6
  },
  {
    img: _scooting,
    weight: 4
  },
  {
    img: _cleaning,
    weight: 3
  }
];


const holidays = {
  '2-14': _valentines,
  '9-1': _school,
  '10-31': _halloween,
  '12-24': _christmas,
  '12-25': _christmas
};


const seasons = {
  '12': _winter,
  '1': _winter,
  '2': _winter,
  '3':  _winter,
  '4':  _spring,
  '5':  _spring,
  '6': _spring,
  '7': _beach,
  '8': _beach,
  '9': _fall,
  '10': _fall,
  '11': _fall
};


/**
 * @param {object[]} arr 
 */
function pickWeighted(arr) {
  let sum = arr.reduce((acc, curr) => acc + curr.weight, 0);

  let v = Math.random() * sum;

  let tally = 0;
  for (let item of arr) {
    tally += item.weight;
    if (tally > v) {
      return item.img;
    }
  }
}


const PANDA_KEY = 'PANDA_STORAGE';


function getPanda() {
  let item;
  
  item = safeLocalStorage.getItem(PANDA_KEY);

  if (item) {
    let obj = JSON.parse(item);
    const PRESERVE_HOURS = 6;
    if (obj.timestamp > Date.now() - PRESERVE_HOURS * 60 * 60 * 1000) {
      return obj.panda;
    }
  }

  let newPanda = selectNewPanda();

  safeLocalStorage.setItem(PANDA_KEY, JSON.stringify({
    panda: newPanda,
    timestamp: Date.now()
  }));

  return newPanda;
}


function selectNewPanda() {
  let d = new Date();

  // If we have an exact match on holiday, return it
  let holiday = (d.getMonth() + 1) + '-' + (d.getDate());
  if (holidays[holiday]) {
    return holidays[holiday];
  }

  // Chance of showing a season
  if (Math.random() < 0.06) {
    return seasons[d.getMonth() + 1];
  }

  // Chance of showing an activity
  if (Math.random() < 0.10) {
    return pickWeighted(activities);
  }

  // Fall back to a basic panda
  return pickWeighted(basic);
}


export default function Hero() {
  return <div className="hero" style={{
    textAlign: 'center',
    marginTop: 24
  }}>
    <img src={getPanda()} style={{ height: 200, opacity: 0.85 }} alt="Panda"/>
  </div>;
}

