import React, { useCallback, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { usersSettingsRef } from '@store';
import { useTypedSelectorShallowEquals, useTypedSelectorDeepEquals } from '../../hooks';
import SnippetItem from './SnippetItem';
import GroupSharedIcon from '@mui/icons-material/FolderShared';
import EditIcon from '@mui/icons-material/Edit';
import ExpandIcon from '@mui/icons-material/KeyboardArrowRight';
import CollapseIcon from '@mui/icons-material/KeyboardArrowDown';
import { itemStyleFn, iconStyleFn, innerDivStyle, SNIPPET_HEIGHT, FOLDER_HEADER_HEIGHT } from './shared';
import BlockedChip from './BlockedChip';
import { limitationsState } from '../Version/limitations';
import { storage } from '../../utilities';
import { userPermission } from '../../auth';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import equals from 'fast-deep-equal';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { deleteGroup, createSnippet, enableGroup, disableGroup, updateGroupData, sortGroupByName, sortGroupByShortcut } from '../../data';
import { showPrompt, showTrashConfirmation } from '../../message';
import Divider from '@mui/material/Divider';
import NewIcon from '@mui/icons-material/AddCircle';
import { memberRestricted } from '../../flags';
import { disableFolderIcon, enableFolderIcon } from '../Group/group_utils';
import ConnectedIcon from '@mui/icons-material/CloudOutlined';
import { ClickAwayListener, Skeleton, Tooltip } from '@mui/material';
import T from '@mui/material/Typography';
import GroupIcon from '../Group/GroupIcon';
import { useViewportItem } from './viewport_utils';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import NestedMenuItem from './NestedMenuItem';
import { useHistory } from 'react-router-dom';
import { ConnectedAvatarGroup } from '../Database/DatabaseAvatarGroup';
import { isAiBlaze } from '../../aiBlaze';
import { isGroupPublic, isGroupPublicManageRestricted } from '../Group/group_public_rules.js';


/**
 * @param {object} props
 * @param {string} props.groupId
 * @param {number} props.depth
 * @param {boolean} props.active
 * @param {object=} props.violation
 * @param {number} props.index
 * @param {number=} props.limitExceedIndex
 * @param {boolean=} props.fixed
 * @param {boolean=} props.childrenFixed
 * @param {string=} props.local
 * @param {string} props.dragCategory
 */
function GroupItemBase(props) {
  let [localCollapse, setLocalCollapse] = useState(!props.local ? false : (props.local === 'expanded' ? false : true));
  let [contextClick, setContextClick] = useState(null);
  const { push: navigate } = useHistory();

  let {
    hasGroup,
    stub,
    selected,
    open,
    hasPrefs,
    permission,
    loading,
    enabled,
    enableCreate,
    enableShare,
    noPublicShareAccess,
    groupName,
    groupShared,
    isPublic,
    isConnected,
    connectedSpaces,
    connectedAddons,
    connectedDomains
  } = useTypedSelectorShallowEquals((store)=> {
    let group = store.dataState.groups[props.groupId];
    let snippetSelected = group && group.snippets && store.uiState.selected && group.snippets.find(x => x.id === store.uiState.selected);
    let prefs = props.local ? null : (store.userState.groups && store.userState.groups[props.groupId]);
    let hasPrefs = !!prefs;
    let open = null;
    if (snippetSelected) {
      open = true;
    } else {
      if (hasPrefs) {
        open = !(props.local ? false : store.userState.groups[props.groupId].collapsed);
      }
    }
  
    return {
      hasGroup: !!group,
      enabled: !(group && group.peekingOnly) && props.active,
      stub: (!group) || group.stub,
      selected: store.uiState.selected === props.groupId,
      hasPrefs,
      open,
      permission: group && userPermission(group),
      loading: (!group) || group.loading,
      enableCreate: !memberRestricted(store, 'create'),
      enableShare: !memberRestricted(store, 'share'),
      noPublicShareAccess: store.userState && group && isGroupPublicManageRestricted(store.userState, group),
      groupName: group && group.name,
      groupShared: group && group.shared,
      isPublic: group && isGroupPublic(group),
      isConnected: group && group.connected && group.connected.is_connected,
      connectedSpaces: group?.connected?.database_queries ? Object.keys(group?.connected?.database_queries).sort() : [],
      connectedDomains: [...new Set((group?.connected?.load_hosts || []).concat(group?.connected?.ping_hosts || []))].sort(),
      connectedAddons: [...new Set(group?.connected?.addons)].sort()
    };
  });

  let viewportItem = useViewportItem([loading || stub, isOpen()]);

  let snippets = useTypedSelectorDeepEquals(store => {
    let group = store.dataState.groups[props.groupId];

    return group ? group.snippets.map(x => ({
      order: x.order,
      id: x.id
    })) : null;
  });

  let limitations = useTypedSelectorDeepEquals(store => limitationsState(store));

  function isOwner() {
    if (!permission) {
      // If the group hasn't been loaded yet, prevent editing
      return false;
    }
    return 'owner' === permission;
  }

  function editable() {
    if (!permission) {
      // If the group hasn't been loaded yet, prevent editing
      return false;
    }
    return ['owner', 'editor'].includes(permission);
  }

  /**
   * @param {object} config
   * @param {boolean} config.isDraggingOver
   * @param {boolean} config.isUsingPlaceholder
   * @param {string=} config.draggingOverWith
   * 
   * return {object}
   */
  function children(config) {
    if (!snippets) {
      return [];
    }
    if (snippets.length === 0) {
      return config.isDraggingOver ? null :
        <ListItem
          dense
          key="empty"
          disabled
          style={{
            padding: 3,
            paddingBottom: 8
          }}
        >
          <ListItemText
            primary="0 snippets in the folder"
            style={{
              fontSize: 'small',
              color: '#777',
              textAlign: 'center'
            }}
          />
        </ListItem>;
    }

    function renderSnippets() {
      return snippets.map((x, index) => {
        let violation = props.violation;
        if (!violation && enabled) {
          if (limitations.MAX_SNIPPETS) {
            if (props.limitExceedIndex !== undefined && index >= props.limitExceedIndex) {
              violation = {
                error: `You cannot have more than ${limitations.MAX_SNIPPETS} enabled snippets.`,
                upgrade: 'Upgrade for more.'
              };
            }
          }
          if (limitations.MAX_SNIPPETS_PER_GROUP) {
            if (index >= limitations.MAX_SNIPPETS_PER_GROUP) {
              violation = {
                error: `You can only have ${limitations.MAX_SNIPPETS_PER_GROUP} active snippets in a folder.`,
                upgrade: (limitations.CAN_UPGRADE_MAX_GROUPS || limitations.CAN_UPGRADE_MAX_SNIPPETS_PER_GROUP) && 'Upgrade for more.'
              };
            }
          }
        }

        return <SnippetItem
          fixed={!editable() || props.childrenFixed}
          snippetId={x.id}
          key={x.id}
          index={index}
          depth={props.depth + 1}
          dragCategory={props.dragCategory}
          active={props.active}
          violation={props.violation || violation}
        />;
      });
    }

    let size = snippets.length;
    if (config.isDraggingOver) {

      let draggingOverId = (config.draggingOverWith || '').split('__')[0];
      if (!snippets.some(snippet => draggingOverId === snippet.id)) {
        size += 1;
      }
    }

    return <div style={{
      position: 'relative',
      height: size * SNIPPET_HEIGHT,
      transition: 'height 0.2s cubic-bezier(0.2, 0, 0, 1)'
    }}>
      {renderSnippets()}
    </div>;
  }

  /**
   * @return {boolean}
   */
  function isOpen() {
    if (open !== null) {
      return open;
    }
    return !localCollapse;
  }

  /**
   * @param {boolean} value
   */
  function setCollapsed(value) {
    if (hasPrefs) {
      storage.update(usersSettingsRef, {
        [`groups.${props.groupId}.collapsed`]: value
      }, 'HIDE_AUTOSAVE');
    } else {
      setLocalCollapse(value);
    }
  }

  const contextClickFn =  useCallback(event => {
    event.preventDefault();
    if (!selected) {
      navigate('/folder/' + props.groupId);
    }
    setContextClick({
      x: event.clientX - 2,
      y: event.clientY - 4
    });
    // eslint-disable-next-line
  }, [props.groupId, selected]);

  const contextClose = useCallback(() => {
    setContextClick(null);
  }, []);


  function renderContextMenu() {
    if (!contextClick) {
      return null;
    }

    let disabled = !editable();

    let iconStyle = {
      opacity: 0.5,
      marginRight: 14
    };

    return <ClickAwayListener onClickAway={contextClose} mouseEvent="onMouseDown">
      <Menu
        open
        onClose={contextClose}
        onContextMenu={(evt) => {
          evt.preventDefault();
          contextClose();
        }}
        sx={{
          pointerEvents: 'none'
        }}
        PaperProps={{
          sx: {
            pointerEvents: 'auto'
          }
        }}
        disableAutoFocusItem
        transitionDuration={0}
        keepMounted
        anchorReference="anchorPosition"
        anchorPosition={{ top: contextClick.y, left: contextClick.x }}
      >
        <MenuItem
          disabled={(!enableCreate) || disabled}
          onClick={() => {
            contextClose();
            const options = isAiBlaze
              ? {
                options: /** @type {SnippetObjectType['options']} */ ({
                  is_ai: true,
                  include_page_context: true,
                  ai_action: {
                    updated_at: Date.now(),
                    action: 'polish',
                  },
                  // TODO: remove polish_mode on extension update
                  polish_mode: true
                })
              }
              : undefined;

            createSnippet(options).then(data => {
              navigate('/snippet/' + data.snippet_id);
            }).catch(err => {
            //not regular errors
              if (!['MAX_SNIPPETS_EXCEEDED'].includes(err)) {
                throw err;
              }
            });
          }}><NewIcon style={iconStyle} /> New snippet</MenuItem>
        <Tooltip title={noPublicShareAccess ? 'This folder is public and can\'t be shared' : ''}>
          <span>
            <MenuItem
              disabled={noPublicShareAccess || !enableShare || !isOwner()}
              onClick={() => {
                contextClose();

                navigate('/folder/' + props.groupId + '/sharing');
              }}>
              <GroupSharedIcon style={iconStyle} /> Share folder...
            </MenuItem>
          </span>
        </Tooltip>
        <Divider />
        <MenuItem
          disabled={enabled}
          onClick={() => {
            contextClose();
            enableGroup(props.groupId);
          }}>
          <span style={Object.assign({}, iconStyle, { marginRight: 15, zoom: .94, marginLeft: 2  })} >{enableFolderIcon}</span> Enable folder{groupShared ? ' (for you only)' : ''}</MenuItem>
        <MenuItem
          disabled={!enabled}
          onClick={() => {
            contextClose();
            disableGroup(props.groupId);
          }}>
          <span style={Object.assign({}, iconStyle, { marginRight: 15, zoom: .94, marginLeft: 2  })} >{disableFolderIcon}</span> Disable folder{groupShared ? ' (for you only)' : ''}</MenuItem>
        <Divider />
        <MenuItem
          disabled={disabled}
          onClick={() => {
            contextClose();
            showPrompt({
              contents: <T>Folder name</T>,
              default: groupName,
              onConfirm: (newName) => updateGroupData(props.groupId, { name: newName }),
              confirmButtonText: 'Rename'
            });
          }}><EditIcon style={iconStyle} /> Rename…</MenuItem>
        <Divider />
        <NestedMenuItem
          disabled={disabled || loading}
          onClose={contextClose}
          item={<span style={{ display: 'flex' }}>
            <span style={Object.assign({}, iconStyle, { marginRight: 15, zoom: .94, marginLeft: 2  })} >
              <span style={{ width: 24, height: 24, verticalAlign: 'middle', display: 'inline-block' }}>
                <SortByAlphaIcon />
              </span>
            </span> Sort by
          </span>}
          itemStyle={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <MenuItem
            style={{ paddingLeft: 32, paddingRight: 32 }}
            onClick={() => {
              contextClose();
              sortGroupByName(props.groupId);
            }}>
          Name
          </MenuItem>
          <MenuItem
            style={{ paddingLeft: 32, paddingRight: 32 }}
            onClick={() => {
              contextClose();
              sortGroupByShortcut(props.groupId);
            }}>
          Shortcut
          </MenuItem>
        </NestedMenuItem>
        <Divider />
        <MenuItem
          disabled={!isOwner()}
          onClick={() => {
            contextClose();
            showTrashConfirmation({
              item: <span>folder <b>{groupName}</b> and its snippets</span>,
              extra: ((groupShared || isPublic) ? <span style={{ fontWeight: 'bold' }}>This is a {isPublic ? 'public' : 'shared'} folder and will be deleted for all users.</span> : null),
              confirm: (groupShared || isPublic)
                ? `I want to delete this ${isPublic ? 'public folder' : 'shared folder for all users'}.`
                : null,
              onDelete: async () => {
                // wait for the delete group message to be saved
                // so the backend can authenticate the group
                return deleteGroup(props.groupId, navigate);
              },
              pronoun: 'They'
            });
          }}><DeleteIcon style={iconStyle} /> Delete folder...</MenuItem>
      </Menu>
    </ClickAwayListener>;
  }

  function renderInner() {
    let iconStyle = iconStyleFn({
      selected
    });


    function renderHeader() {
      if (!viewportItem.appeared) {
        return (
          <div
            style={{ height: FOLDER_HEADER_HEIGHT }}
            className={'list-item-' + props.groupId} /* the list-item is for scrolling to */
          />
        );
      }

      return <>
        {renderContextMenu()}
        <ListItem
          onContextMenu={contextClickFn}
          data-testid="group-item"
          dense
          button
          disableGutters
          disableRipple
          disabled={!hasGroup}
          onClick={() => navigate('/folder/' + props.groupId)}
          style={Object.assign({
            opacity: (props.active && !props.violation) ? 1 : 0.7
          }, itemStyleFn({
            selected: selected,
            depth: props.depth
          }))}
          className={'list-selectable list-item-' + props.groupId} /* the list-item is for scrolling to */
          onDoubleClick={() => {
            setCollapsed(isOpen());
          }}
        >

          <ListItemIcon style={{
            minWidth: 44
          }}><GroupIcon
              inactive={!props.active}
              iconStyle={iconStyle}
              groupId={props.groupId}
              emojiContainerStyle={{
                marginLeft: 2
              }}
              size={28}
            /></ListItemIcon>
          <ListItemText
            style={{ paddingLeft: 0, margin: 0 }}
            primary={hasGroup ? 
              <div style={Object.assign({
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'space-between',
                alignItems: 'center'
              }, innerDivStyle(true))}>
                <div style={{
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 'bold'
                }}>
                  {stub ? <div style={{ width: '60%', margin: 0 }}><Skeleton /></div> : groupName}
                </div>
                {isConnected ? <>
                  {(connectedSpaces.length || connectedDomains.length || connectedAddons.length) ? <ConnectedAvatarGroup isOwner={isOwner()} editUrl={`/folder/${props.groupId}/connected`} spaces={connectedSpaces} domains={connectedDomains} addons={connectedAddons} /> : <Tooltip title="Connected snippet folder">
                    <ConnectedIcon style={{
                      opacity: .5,
                      verticalAlign: 'middle',
                      position: 'relative',
                      top: -1,
                      paddingLeft: 2,
                      marginLeft: 4
                    }} fontSize="small" />
                  </Tooltip>}
                </> : null}
                {
                  props.violation ? (<BlockedChip error={props.violation.error} upgrade={props.violation.upgrade} />) : null
                }
              </div> : '...'} />
          {isOpen() ?
            <IconButton onClick={(e) => {
              setCollapsed(true);
              navigate('/folder/' + props.groupId);
              e.stopPropagation();
            }} size="small" style={{ marginRight: 3, marginLeft: 3 }}>
              <CollapseIcon fontSize="small"/>
            </IconButton> : <IconButton onClick={(e) => {
              setCollapsed(false);
              navigate('/folder/' + props.groupId);
              e.stopPropagation();
            }} size="small" style={{ marginRight: 3, marginLeft: 3 }} >
              <ExpandIcon fontSize="small" />
            </IconButton>
          }
        </ListItem>
      </>;
    }

    return <>
      {renderHeader()}
      {isOpen() &&
        ((stub || loading) ?
          (
            <div style={{ marginTop: 5 }}>
              <div style={{ marginLeft: 34, width: '80%' }}><Skeleton /></div>
              <div style={{ marginLeft: 34, width: '70%' }}><Skeleton /></div>
            </div>) :
          <Droppable
            type="SNIPPET"
            droppableId={'GROUP__/__' + props.groupId + '__/__' + props.dragCategory}
            isDropDisabled={!editable()}
            mode="virtual"
            renderClone={(provided, _snapshot, rubric) => (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <SnippetItem
                  snippetId={snippets[rubric.source.index].id}
                  index={rubric.source.index}
                  depth={props.depth + 1}
                  dragCategory={props.dragCategory}
                  active={props.active}
                  violation={props.violation}
                  handlerMode
                />
              </div>)}
          >
            {(provided, snapshot) => 
              <div
                style={{
                  height: ((snippets || []).length === 0 && !loading) ? 32 : undefined
                }}
                ref={provided.innerRef} 
                {...provided.droppableProps}
              >
                {!(stub || loading) ? children(snapshot) : null}
              </div>}
          </Droppable>)
      }

    </>;
  }


  if (props.fixed) {
    return <div ref={viewportItem.ref} style={{
      opacity: (props.active && !props.violation) ? 1 : 0.7
    }}
    >
      {renderInner()}
    </div>;
  } else {
    return <Draggable
      draggableId={props.groupId}
      index={props.index}
      isDragDisabled={props.fixed}
    >
      {(provided) => (<div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={(node) => {
          provided.innerRef(node);
          viewportItem.ref(node);
        }}
        tabIndex={-1}
      >
        {renderInner()}
      </div>)}
    </Draggable>;
  }
}


const GroupItem = React.memo(GroupItemBase, (oldProps, newProps) => {
  return equals(oldProps, newProps);
});
export default GroupItem;
