/**
 * @typedef {object} DbJobDef
 * @property {string} id
 * @property {boolean} inProgress
 * @property {number} progress
 */

/**
 * @typedef {object} DbStateDef
 * @property {string=} webSocketId
 * @property {string=} clientSessionId
 * @property {Object<string, string>=} siteNamesMap
 * @property {Object<string, DbJobDef>=} jobs
 */

/**
 * The Data Blaze Reducer
 *
 * @param {DbStateDef} state
 *
 * @return {DbStateDef}
 */
export const dbReducer = function(state = {
  webSocketId: null,
  siteNamesMap: {},
  jobs: {},
}, action) {
  switch (action.type) {
  case 'SET_WEB_SOCKET_ID':
  {
    return Object.assign({}, state, { webSocketId: action.webSocketId });
  }
  case 'SET_CLIENT_SESSION_ID':
  {
    return Object.assign({}, state, { clientSessionId: action.clientSessionId });
  }
  case 'SET_SITE_NAMES':
  {
    const siteNamesMap = Object.assign({}, state.siteNamesMap, action.siteNamesMap);
    return Object.assign({}, state, { siteNamesMap });
  }
  case 'UPDATE_JOB':
  {
    const jobs = Object.assign({}, state.jobs, { [action.objectId]: action.job });
    return Object.assign({}, state, { jobs });
  }
  case 'DELETE_JOB':
  {
    const jobs = Object.assign({}, state.jobs);
    delete jobs[action.objectId];
    return Object.assign({}, state, { jobs });
  }
  default:
    return state;
  }
};
