import Quill from 'quill';
import { CELL_STYLES, CELL_STYLE_ATTRIBUTES } from '../formats/constants';
import { _omit, convertToHex } from './index';
import TableContainer from '../formats/tableContainer';

const Delta = Quill.import('delta');

// rebuild delta
export function matchTableCell (node, delta, scroll) {
  const row = node.parentNode;
  const table = row.parentNode.tagName === 'TABLE'
    ? row.parentNode
    : row.parentNode.parentNode;
  const rows = Array.from(table.querySelectorAll('tr'));
  const cells = Array.from(row.querySelectorAll('td'));
  const rowId = rows.indexOf(row) + 1;
  const cellId = cells.indexOf(node) + 1;
  const colspan = parseInt(node.getAttribute('colspan') || '', 10) || undefined;
  const rowspan = parseInt(node.getAttribute('rowspan') || '', 10) || undefined;
  const formats = {};
  CELL_STYLES.forEach(attrName => {
    if (node.style[attrName]) {
      formats[attrName] = node.style[attrName];
    } else if (node.parentElement.style[attrName]) {
      formats[attrName] = node.parentElement.style[attrName];
    }
  });
  for (const style in CELL_STYLE_ATTRIBUTES) {
    const attrName = CELL_STYLE_ATTRIBUTES[style];
    if (!formats[style] && node.hasAttribute(attrName)) {
      formats[style] = node.getAttribute(attrName);
    }
  }
  // bugfix: empty table cells copied from other place will be removed unexpectedly
  if (delta.length() === 0) {
    delta = new Delta().insert('\n', {
      'tableCellLine': {
        row: '' + rowId,
        cell: '' + cellId,
        rowspan,
        colspan
      }
    });
    return delta;
  }

  delta = delta.reduce((newDelta, op) => {
    if (op.insert && typeof op.insert === 'string') {
      const lines = [];
      let insertStr = op.insert;
      let start = 0;
      for (let i = 0; i < op.insert.length; i++) {
        if (insertStr.charAt(i) === '\n') {
          if (i === 0) {
            lines.push('\n');
          } else {
            lines.push(insertStr.substring(start, i));
            lines.push('\n');
          }
          start = i + 1;
        }
      }

      const tailStr = insertStr.substring(start);
      if (tailStr) {
        lines.push(tailStr);
      }

      lines.forEach(text => {
        text === '\n'
          ? newDelta.insert('\n', {
            table: '',
            ...op.attributes
          })
          : newDelta.insert(text, _omit(op.attributes, ['table', 'tableCellLine']));
      });
    } else {
      newDelta.insert(op.insert, op.attributes);
    }

    return newDelta;
  }, new Delta());
  const deltax = delta.reduce((newDelta, op) => {
    if (op.insert && typeof op.insert === 'string' &&
      op.insert.startsWith('\n')) {
      newDelta.insert(op.insert, Object.assign(
        {},
        Object.assign({}, { row: '' + rowId }, op.attributes && op.attributes.table),
        {
          'tableCellLine': Object.assign({
            row: '' + rowId,
            cell: '' + cellId,
            rowspan,
            colspan
          }, formats)
        },
        _omit(op.attributes, ['table'])
      ));
    } else {
      // bugfix: remove background attr from the delta of table cell
      //         to prevent unexcepted background attr append.
      if (op.attributes && op.attributes.background && op.attributes.background === convertToHex(formats.backgroundColor)) {
        newDelta.insert(op.insert, Object.assign(
          {},
          _omit(op.attributes, ['table', 'tableCellLine', 'background'])
        ));
      } else {
        newDelta.insert(op.insert, Object.assign(
          {},
          _omit(op.attributes, ['table', 'tableCellLine'])
        ));
      }
    }
    return newDelta;
  }, new Delta());
  return deltax;
}

// replace th tag with td tag
export function matchTableHeader (node, delta, scroll) {
  const row = node.parentNode;
  const table = row.parentNode.tagName === 'TABLE'
    ? row.parentNode
    : row.parentNode.parentNode;
  const rows = Array.from(table.querySelectorAll('tr'));
  const cells = Array.from(row.querySelectorAll('th'));
  const rowId = rows.indexOf(row) + 1;
  const cellId = cells.indexOf(node) + 1;
  const colspan = parseInt(node.getAttribute('colspan') || '', 10) || undefined;
  const rowspan = parseInt(node.getAttribute('rowspan') || '', 10) || undefined;
  const formats = {};
  CELL_STYLES.forEach(attrName => {
    if (node.style[attrName]) {
      formats[attrName] = node.style[attrName];
    } else if (node.parentElement.style[attrName]) {
      formats[attrName] = node.parentElement.style[attrName];
    }
  });
  for (const style in CELL_STYLE_ATTRIBUTES) {
    const attrName = CELL_STYLE_ATTRIBUTES[style];
    if (!formats[style] && node.hasAttribute(attrName)) {
      formats[style] = node.getAttribute(attrName);
    }
  }
  // bugfix: empty table cells copied from other place will be removed unexpectedly
  if (delta.length() === 0) {
    delta = new Delta().insert('\n', {
      'tableCellLine': {
        row: '' + rowId,
        cell: '' + cellId,
        rowspan,
        colspan
      }
    });
    return delta;
  }

  delta = delta.reduce((newDelta, op) => {
    if (op.insert && typeof op.insert === 'string') {
      const lines = [];
      let insertStr = op.insert;
      let start = 0;
      for (let i = 0; i < op.insert.length; i++) {
        if (insertStr.charAt(i) === '\n') {
          if (i === 0) {
            lines.push('\n');
          } else {
            lines.push(insertStr.substring(start, i));
            lines.push('\n');
          }
          start = i + 1;
        }
      }

      const tailStr = insertStr.substring(start);
      if (tailStr) {
        lines.push(tailStr);
      }

      // bugfix: no '\n' in op.insert, push a '\n' to lines
      if (lines.indexOf('\n') < 0) {
        lines.push('\n');
      }

      lines.forEach(text => {
        text === '\n'
          ? newDelta.insert('\n', {
            'tableCellLine': {
              row: '' + rowId,
              cell: '' + cellId,
              rowspan,
              colspan
            }
          })
          : newDelta.insert(text, op.attributes);
      });
    } else {
      newDelta.insert(op.insert, op.attributes);
    }
    
    return newDelta;
  }, new Delta());

  return delta.reduce((newDelta, op) => {
    if (op.insert && typeof op.insert === 'string' &&
      op.insert.startsWith('\n')) {
      newDelta.insert(op.insert, Object.assign(
        {},
        { 'tableCellLine': Object.assign({
          row: '' + rowId,
          cell: '' + cellId,
          rowspan,
          colspan
        }, formats) }
      ));
    } else {
      newDelta.insert(op.insert, Object.assign(
        {},
        _omit(op.attributes, ['table', 'tableCellLine'])
      ));
    }

    return newDelta;
  }, new Delta());
}

// supplement colgroup and col
export function matchTable (node, delta, scroll) {
  let newColDelta = new Delta();
  const topRow = node.querySelector('tr');

  // bugfix: empty table will return empty delta
  if (topRow === null) {
    return newColDelta;
  }
  const cellsInTopRow = Array.from(topRow.querySelectorAll('td'))
    .concat(Array.from(topRow.querySelectorAll('th')));
  const maxCellsNumber = cellsInTopRow.reduce((sum, cell) => {
    const cellColspan = cell.getAttribute('colspan') || 1;
    sum = sum + parseInt(cellColspan, 10);
    return sum;
  }, 0);
  const colsNumber = node.querySelectorAll('col').length;
  delta = delta.reduce((finalDelta, op) => {
    finalDelta.insert(op.insert, _omit(op.attributes, ['table']));
    return finalDelta;
  }, new Delta());
  // issue #2
  // bugfix: the table copied from Excel had some default col tags missing
  //         add missing col tags
  if (colsNumber === maxCellsNumber) {
    return delta;
  } else {
    const tableAttributes = TableContainer.getFormats(node);
    for (let i = 0; i < maxCellsNumber - colsNumber; i++) {
      newColDelta.insert('\n', { 'tableCol': { ...tableAttributes } });
    }
    
    if (colsNumber === 0) {
      return newColDelta.concat(delta);
    }

    let lastNumber = 0;
    return delta.reduce((finalDelta, op) => {
      finalDelta.insert(op.insert, op.attributes);
  
      if (op.attributes && op.attributes['tableCol']) {
        lastNumber += op.insert.length;
        if (lastNumber === colsNumber) {
          finalDelta = finalDelta.concat(newColDelta);
        }
      }
  
      return finalDelta;
    }, new Delta());
  }
}
