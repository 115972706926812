/**
 * Duplicated Loader DOM from Stripe.
 */

import React from 'react';
import {
  Typography as T
} from '@mui/material';
import AsyncButton from '../AsyncButton/AsyncButton';

/**
 * 
 * @param {object} props
 * @param {boolean} props.show
 * @param {React.CSSProperties=} props.style
 * @param {React.ReactNode=} props.disclaimer
 * @returns 
 */
const PaymentLoader = ({
  show,
  style,
  disclaimer
}) => {
  if (!show) {
    return null;
  }
  return (
    <div style={style}>
      <div className="Loader" style={{
        margin: '-4px 0px',
        padding: '0px',
        border: 'none',
        display: 'block',
        background: 'transparent',
        position: 'relative',
        opacity: '1',
        clear: 'both',
        transition: 'height 0.35s ease 0s',
        height: 229
      }}
      >
        <div
          style={{
            boxSizing: 'border-box',
            position: 'absolute',
            inset: '4px 0px 0px',
            opacity: 1,
            width: 'calc(100% + 8px)',
            transition: 'opacity 0.3s ease 0s',
            overflow: 'hidden',
            zIndex: '1',
            margin: '-4px',
            pointerEvents: 'none',
            height: 229
          }}
        >
          <div style={{ margin: '4px', position: 'relative' }}>
            <div>
              <div style={{ marginBottom: '12px' }}>
                <div style={{}}>
                  <div style={{ height: '12.8321px', backgroundColor: 'rgba(47, 48, 60, 0.04)', position: 'relative', overflow: 'hidden', borderRadius: '5px', width: '85.6797px', boxSizing: 'border-box', marginBottom: '8.27735px', boxShadow: 'rgba(0, 0, 0, 0.03) 0px 1px 1px 0px, rgba(0, 0, 0, 0.02) 0px 3px 6px 0px' }}>
                    <div style={{ position: 'absolute', top: '0px', left: '0px', height: '100%', width: '50%', transform: 'translateX(-100%)', willChange: 'transform', background: 'linear-gradient(to right, rgba(47, 48, 60, 0), rgba(47, 48, 60, 0.05) 50%, rgba(47, 48, 60, 0))' }}>
                    </div>
                  </div>
                  <div style={{ height: '44.3984px', padding: '14.1387px', boxSizing: 'border-box', backgroundColor: 'rgb(255, 255, 255)', position: 'relative', overflow: 'hidden', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.03) 0px 1px 1px 0px, rgba(0, 0, 0, 0.02) 0px 3px 6px 0px', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.23)', borderStyle: 'solid' }}>
                    <div style={{ height: '12.8321px', backgroundColor: 'rgba(47, 48, 60, 0.04)', position: 'relative', overflow: 'hidden', borderRadius: '5px', width: '85.6797px', boxSizing: 'border-box' }}>
                      <div style={{ position: 'absolute', top: '0px', left: '0px', height: '100%', width: '50%', transform: 'translateX(-100%)', willChange: 'transform', background: 'linear-gradient(to right, rgba(47, 48, 60, 0), rgba(47, 48, 60, 0.05) 50%, rgba(47, 48, 60, 0))' }}>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', marginBottom: '12px', marginLeft: '-12px' }}>
                <div style={{ boxSizing: 'border-box', width: '50%', paddingLeft: '12px' }}>
                  <div style={{}}>
                    <div style={{ height: '12.8321px', backgroundColor: 'rgba(47, 48, 60, 0.04)', position: 'relative', overflow: 'hidden', borderRadius: '5px', width: '46.7344px', boxSizing: 'border-box', marginBottom: '8.27735px', boxShadow: 'rgba(0, 0, 0, 0.03) 0px 1px 1px 0px, rgba(0, 0, 0, 0.02) 0px 3px 6px 0px' }}>
                      <div style={{ position: 'absolute', top: '0px', left: '0px', height: '100%', width: '50%', transform: 'translateX(-100%)', willChange: 'transform', background: 'linear-gradient(to right, rgba(47, 48, 60, 0), rgba(47, 48, 60, 0.05) 50%, rgba(47, 48, 60, 0))' }}>
                      </div>
                    </div>
                    <div style={{ height: '44.3984px', padding: '14.1387px', boxSizing: 'border-box', backgroundColor: 'rgb(255, 255, 255)', position: 'relative', overflow: 'hidden', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.03) 0px 1px 1px 0px, rgba(0, 0, 0, 0.02) 0px 3px 6px 0px', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.23)', borderStyle: 'solid' }}>
                      <div style={{ height: '12.8321px', backgroundColor: 'rgba(47, 48, 60, 0.04)', position: 'relative', overflow: 'hidden', borderRadius: '5px', width: '46.7344px', boxSizing: 'border-box' }}>
                        <div style={{ position: 'absolute', top: '0px', left: '0px', height: '100%', width: '50%', transform: 'translateX(-100%)', willChange: 'transform', background: 'linear-gradient(to right, rgba(47, 48, 60, 0), rgba(47, 48, 60, 0.05) 50%, rgba(47, 48, 60, 0))' }}>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ boxSizing: 'border-box', width: '50%', paddingLeft: '12px' }}>
                  <div style={{}}>
                    <div style={{ height: '12.8321px', backgroundColor: 'rgba(47, 48, 60, 0.04)', position: 'relative', overflow: 'hidden', borderRadius: '5px', width: '31.1562px', boxSizing: 'border-box', marginBottom: '8.27735px', boxShadow: 'rgba(0, 0, 0, 0.03) 0px 1px 1px 0px, rgba(0, 0, 0, 0.02) 0px 3px 6px 0px' }}>
                      <div style={{ position: 'absolute', top: '0px', left: '0px', height: '100%', width: '50%', transform: 'translateX(-100%)', willChange: 'transform', background: 'linear-gradient(to right, rgba(47, 48, 60, 0), rgba(47, 48, 60, 0.05) 50%, rgba(47, 48, 60, 0))' }}>
                      </div>
                    </div>
                    <div style={{ height: '44.3984px', padding: '14.1387px', boxSizing: 'border-box', backgroundColor: 'rgb(255, 255, 255)', position: 'relative', overflow: 'hidden', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.03) 0px 1px 1px 0px, rgba(0, 0, 0, 0.02) 0px 3px 6px 0px', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.23)', borderStyle: 'solid' }}>
                      <div style={{ height: '12.8321px', backgroundColor: 'rgba(47, 48, 60, 0.04)', position: 'relative', overflow: 'hidden', borderRadius: '5px', width: '31.1562px', boxSizing: 'border-box' }}>
                        <div style={{ position: 'absolute', top: '0px', left: '0px', height: '100%', width: '50%', transform: 'translateX(-100%)', willChange: 'transform', background: 'linear-gradient(to right, rgba(47, 48, 60, 0), rgba(47, 48, 60, 0.05) 50%, rgba(47, 48, 60, 0))' }}>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: '12px' }}>
                <div style={{}}>
                  <div style={{ height: '12.8321px', backgroundColor: 'rgba(47, 48, 60, 0.04)', position: 'relative', overflow: 'hidden', borderRadius: '5px', width: '85.6797px', boxSizing: 'border-box', marginBottom: '8.27735px', boxShadow: 'rgba(0, 0, 0, 0.03) 0px 1px 1px 0px, rgba(0, 0, 0, 0.02) 0px 3px 6px 0px' }}>
                    <div style={{ position: 'absolute', top: '0px', left: '0px', height: '100%', width: '50%', transform: 'translateX(-100%)', willChange: 'transform', background: 'linear-gradient(to right, rgba(47, 48, 60, 0), rgba(47, 48, 60, 0.05) 50%, rgba(47, 48, 60, 0))' }}>
                    </div>
                  </div>
                  <div style={{ height: '44.3984px', padding: '14.1387px', boxSizing: 'border-box', backgroundColor: 'rgb(255, 255, 255)', position: 'relative', overflow: 'hidden', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.03) 0px 1px 1px 0px, rgba(0, 0, 0, 0.02) 0px 3px 6px 0px', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.23)', borderStyle: 'solid' }}>
                    <div style={{ height: '12.8321px', backgroundColor: 'rgba(47, 48, 60, 0.04)', position: 'relative', overflow: 'hidden', borderRadius: '5px', width: '85.6797px', boxSizing: 'border-box' }}>
                      <div style={{ position: 'absolute', top: '0px', left: '0px', height: '100%', width: '50%', transform: 'translateX(-100%)', willChange: 'transform', background: 'linear-gradient(to right, rgba(47, 48, 60, 0), rgba(47, 48, 60, 0.05) 50%, rgba(47, 48, 60, 0))' }}>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** 
       * Does not have to delay to hide. 
       * As the SetupForm shows the button immediately
       */}
      <AsyncButton
        type="submit"
        color="primary"
        variant="contained"
        sx={{
          mt: 4
        }}
        fullWidth
        loading
      >
        Pay
      </AsyncButton>
      {disclaimer && <T
        sx={{
          mt: 2,
          opacity: 0.6
        }}
        variant="caption"
        component="div"
      >{disclaimer}</T>}
    </div>

  );

};
export default PaymentLoader;