import React from 'react';
import { useTypedSelector } from '../../hooks';
import IconButton from '@mui/material/IconButton';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { log } from '../../logging/logging';
import Slider from '@mui/material/Slider';
import useFirestore from '../../FirestoreLink/FirestoreHook';
// @ts-ignore 
import snippetSound from '../../../sounds/click.wav';
import Tooltip from '@mui/material/Tooltip';
import { makeRef } from '../../firebase_utilities';


export default function VolumeSlider() {
  let uid = useTypedSelector(store => store.userState.uid);

  let { data, updateFn } = useFirestore(makeRef('users_settings', uid));


  function playSnippetSound(override) {
    let snd = new Audio(snippetSound);
    snd.volume = override === undefined ? data.snippet_volume : override;
    snd.play();
  }

  function handleSnippetSound(newValue) {
    log({ action: 'Set volume', value: newValue, debounce: 10000 });
    updateFn({
      snippet_volume: newValue
    });
  }

  function valueDefined() {
    return (data && (typeof data.snippet_volume === 'number'));
  }

  return (
    <table style={{ width: '99%', marginBottom: 15 }}>
      <tbody>
        <tr>
          <td>
            <Tooltip
              title="Mute snippet sound"
            >
              <IconButton
                onClick={() => {
                  handleSnippetSound(0);
                  playSnippetSound(0);
                }}
                size="large">
                <VolumeMuteIcon />
              </IconButton>
            </Tooltip>
          </td>
          <td style={{ width: '100%' }}>
            <Slider
              disabled={!data}
              valueLabelDisplay="off"
              value={valueDefined() ? data.snippet_volume : 0.5}
              onChangeCommitted={() => playSnippetSound()}
              onChange={(_e, v) => handleSnippetSound(v)}
              max={1}
              min={0}
              step={0.01}
            />
          </td>
          <td>
            <Tooltip
              title="Full snippet sound"
            >
              <IconButton
                onClick={() => {
                  handleSnippetSound(1);
                  playSnippetSound(1);
                }}
                size="large">
                <VolumeUpIcon />
              </IconButton>
            </Tooltip>
          </td>
        </tr>
      </tbody>
    </table>
  );
}