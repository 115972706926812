import React, { useCallback, useContext, useRef, useState } from 'react';
import { saveOrgAddonData, saveUserAddonData } from '../../data';
import {
  ClickAwayListener,
  Popper,
  SwipeableDrawer
} from '@mui/material';
import AddonConfigDialog from '../Addons/AddonConfigDialog';
import { useIsMedium } from '../../hooks';
import { SnippetEditorContext } from '../Snippet/SnippetWrapper';
import CommandsList from './CommandsList';
import CommentOnlyToolbar from '../Snippet/CommentOnlyToolbar';
import SidebarResizerWithState from './SidebarResizerWithState';




/**
 * @param {object} props
 * @param {boolean} props.preview
 * @param {string} props.snippetId
 * @param {string} props.groupId
 * @param {function} props.insert
 * @param {function} props.insertTable
 * @param {function} props.dialog
 * @param {function=} props.onChange
 * @param {Function} props.onVariableNameChange
 * @param {boolean} props.xSharingDisabled
 * @param {boolean} props.userAddonsEnabled
 * @param {boolean} props.isAssociatedToUs
 * @param {boolean} props.isPage
 * @param {boolean} props.isAddon
 * @param {boolean} props.quickentry
 * @param {boolean} props.isAI
 * @param {function} props.getDelta
 * @param {Object<string, ActiveAddonType>=} props.addons
 * @param {boolean} props.showDynamicBadge
 * @param {boolean} props.showAddonHighlight
 * @param {boolean} props.isEditingToken
 * @param {string[]=} props.formNames
 * @param {boolean} props.isEditingAttribute
 * @param {import("./SnippetEditor").EditorContextType} props.context
 * @param {object} props.override
 * @param {boolean=} props.showCommands
 * @param {function=} props.setShowCommands
 * @param {boolean=} props.readonly
 * @param {boolean=} props.hideCommandsListToolbar
 */
export function SideBar(props) {

  const {
    attributeFocused,
    insertIntoAttribute,
    attribute,
    inCommandsPanel,
    setInCommandsPanel
  } = useContext(SnippetEditorContext);
  const alsoShowCommands = (attributeFocused || inCommandsPanel);
  const {
    dialog,
    onChange,
    getDelta,
    insert,
    insertTable,

    context,
    ...otherProps
  } = props;
  const newCallbacks = {
    dialog,
    onChange,
    getDelta,
    insert,
    insertTable,
    insertIntoAttribute
  };
  const callbacks = useRef(newCallbacks);
  callbacks.current = newCallbacks;
  /**
   * @type {newCallbacks}
   */
  let callBacksState = { ...newCallbacks };
  for (const callbackName in callBacksState) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    callBacksState[callbackName] = useCallback((...args) => {
      return callbacks.current[callbackName](...args);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  }

  return <SideBarInner
    {...otherProps}
    {...callBacksState}
    /*dialog={dialogCallback}
    onChange={onChangeCallback}
    getDelta={getDeltaCallback}
    insert={insertCallback}
    insertTable={insertTableCallback}*/
    names={context.names}
    activeTypes={context.activeTypes}
    alsoShowCommands={alsoShowCommands}
    attributeType={attribute?.type}
    attributeElement={attribute?.element}
    setInCommandsPanel={setInCommandsPanel}
  />;
}

/**
 * @param {object} props
 * @param {boolean} props.preview
 * @param {string} props.snippetId
 * @param {string} props.groupId
 * @param {function} props.insert
 * @param {function} props.insertTable
 * @param {function} props.dialog
 * @param {function=} props.onChange
 * @param {Function=} props.onVariableNameChange
 * @param {boolean} props.xSharingDisabled
 * @param {boolean} props.userAddonsEnabled
 * @param {boolean} props.isAssociatedToUs
 * @param {boolean} props.isPage
 * @param {boolean} props.isAddon
 * @param {boolean} props.quickentry
 * @param {boolean} props.isAI
 * @param {function} props.getDelta
 * @param {Object<string, ActiveAddonType>=} props.addons
 * @param {boolean} props.showDynamicBadge
 * @param {boolean} props.showAddonHighlight
 * @param {boolean} props.isEditingToken
 * @param {string[]=} props.formNames
 * @param {boolean} props.isEditingAttribute
 * @param {import("./SnippetEditor").EditorContextType['names']} props.names
 * @param {import("./SnippetEditor").EditorContextType['activeTypes']} props.activeTypes
 * @param {object} props.override
 * @param {boolean=} props.showCommands
 * @param {function=} props.setShowCommands
 * @param {boolean=} props.readonly
 * @param {boolean=} props.alsoShowCommands
 * @param {import('../Snippet/SnippetWrapper').SnippetWrapperContext['setInCommandsPanel']=} props.setInCommandsPanel
 * @param {import('../Snippet/SnippetWrapper').SnippetWrapperContext['attribute']['type']=} props.attributeType
 * @param {import('../Snippet/SnippetWrapper').SnippetWrapperContext['attribute']['element']=} props.attributeElement
 * @param {import('../Snippet/SnippetWrapper').SnippetWrapperContext['insertIntoAttribute']=} props.insertIntoAttribute
 * @param {boolean=} props.hideCommandsListToolbar
 */
const SideBarInner = function (props) {
  const {
    override,
    alsoShowCommands,
    attributeType,
    attributeElement,
    setInCommandsPanel,
    insertIntoAttribute,
    ...otherProps
  } = props;
  
  const overrideDomRef = useRef(/** @type {HTMLDivElement} */ (null));
  let isMedium = useIsMedium();
  const inCommandsTimer = useRef(null);

  

  let [configDialogAddon, setConfigDialogAddon] = useState(null);


  if (props.readonly && !override) {
    if (props.hideCommandsListToolbar) {
      return null;
    }
    return <CommentOnlyToolbar snippetId={props.snippetId}/>;
  }

  const attributeDialog = attributeElement?.closest('[role=dialog]');
  const domRef = attributeDialog || overrideDomRef.current;
  const setCommandsPanel = (v) => {
    
    if (inCommandsTimer.current) {
      clearTimeout(inCommandsTimer.current);
    }
    if (v) {
      setInCommandsPanel(true);
    } else {
      inCommandsTimer.current = setTimeout(() => {
        setInCommandsPanel(false);
      }, 300);
    }
  };
  let commandsWrapper = <div style={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden auto',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  }}>

    {configDialogAddon &&
  <AddonConfigDialog
    group={configDialogAddon}
    onSave={(data) => {
      saveOrgAddonData(data.id, data.orgData);
      saveUserAddonData(data.id, data.userData);
    }}
    onClose={() => setConfigDialogAddon(null)}
  />
    }
    <div style={{ display: 'contents' }}>
      {!override && (
        <CommandsList
          override={!!override}
          activeTypes={props.activeTypes}
          {...otherProps}
          onAddonClick={setConfigDialogAddon}
        />
      )}
      {!!override && <div ref={overrideDomRef} style={{ overflowY: 'scroll', flex: 1 }} id="sidePanel">{override}</div>}
    </div>

  </div>;
  let additionalCommands = alsoShowCommands && domRef?.isConnected && (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      onClickAway={() => {
        setCommandsPanel(false);
      }}
    >
      <Popper
        open
        anchorEl={domRef}
        placement={!attributeDialog ? 'left' : 'right'}
        sx={{
          width: 300,
          maxWidth: '50vw',
          height: domRef ? domRef.clientHeight * 0.95 : 100,
          zIndex: !attributeDialog ? 
            (!isMedium ? 1 : (theme) => theme.zIndex.drawer)
            : (theme) => theme.zIndex.modal,
          overflow: 'auto',
          border: '1px solid',
          borderColor: '#ccc',
          backgroundColor: 'white',
          pt: 2,
          borderRadius: overrideDomRef.current ? '10px 0px 0px 10px' : '0px 10px 10px 0px',
          boxShadow: 'none'
        }}
        
        onFocusCapture={() => {
          setCommandsPanel(true);
        }}
        onBlurCapture={() => {
          setCommandsPanel(false);
        }}
        onMouseMoveCapture={() => {
          setCommandsPanel(true);
        }}
        onMouseDownCapture={() => {
          setCommandsPanel(true);
        }}
        onTouchStartCapture={() => {
          setCommandsPanel(true);
        }}
      >
        <CommandsList
          inAttribute
          override={!!override}
          {...otherProps}
          onAddonClick={setConfigDialogAddon}
          duringAdd={!!attributeDialog}
        />
      </Popper>
    </ClickAwayListener>
  );
  return <>

    
    {isMedium ? <SwipeableDrawer
      anchor="right"
      open={props.showCommands}
      onClose={() => {
        if (props.setShowCommands) {
          props.setShowCommands(false);
        }
      }}
      onOpen={() => {
        if (props.setShowCommands) {
          props.setShowCommands(true);
        }
      }}
      PaperProps={{
        sx: {
          width: 300,
          maxWidth: '90vw'
        } }
      }
    >
      {additionalCommands}
      {commandsWrapper}
    </SwipeableDrawer> : <>
      <SidebarResizerWithState
        type="snippetSidebarSize"
        align="left"
        parentSelector=".snippet-wrapper"
        // Same as top row height in dashboard/src/js/components/Snippet/SnippetWrapper.js
        offsetY={-100}
      />
      {additionalCommands}
      {commandsWrapper}
    </>}
  </>;
};