import { Experiment, Ops } from 'planout';
import { log } from '../logging/logging';
import { safeLocalStorage } from '../localstorage_utility';

let nextExperimentName;

export default class BaseExperiment extends Experiment {
  constructor(experimentName, ops, id) { 
    nextExperimentName = experimentName;

    super({ id });

    this.experimentName = experimentName;

    this.ops = ops;
  }

  configureLogger() {
    // pass
  }

  log(event) {
    log({
      action: 'Experiment ' + this.experimentName,
      label: {
        type: event.event,
        value: event.params.singleton !== undefined ? event.params.singleton : event.params
      }
    });
  }

  previouslyLogged() {
    // Reduce logs. Note you can still get multiple logs (e.g. across browsers).

    // We are able to use safeLocalStorage safely here because running this multiple
    // times has no side effects apart from logging more than necessary
    let key = 'exp-' + this.experimentName + '-' + this.experimentId;
    if (safeLocalStorage.getItem(key)) {
      return true;
    }
    safeLocalStorage.setItem(key, 'true');

    // @ts-ignore
    return this._exposureLogged;
  }

  getParamNames() {
    return Object.keys(this.ops);
  }

  setup() {
    // @ts-ignore
    this.setName(nextExperimentName);
  }

  assign(params, args) {
    this.experimentId = args.id;

    for (let op in this.ops) {
      let data = this.ops[op];
      // @ts-ignore
      params.set(op, new Ops.Random[data.type](Object.assign({}, data, {
        unit: args.id
      })));
    }
  }

  values() {
    let res = {};
    for (let op in this.ops) {
      // @ts-ignore
      res[op] = this.get(op);
    }
    return res;
  }
}