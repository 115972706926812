
import React, { useContext, useEffect, useState } from 'react';
import { useTypedSelector, useTypedSelectorShallowEquals } from '../../../hooks';
import { userPermission } from '../../../auth';
import GroupSelector from './GroupSelector';
import { TrashedDialogContext } from './TrashedTextBlazeDialog';
import {
  TRASH_LABELS
} from './trashed_utils';
import { restoreSnippet } from '../../../bapi';
import TrashedAllSnippets, { SnippetIcon } from './TrashedAllSnippets';
import TrashedFirestore from './TrashedFirestore';
import { transformData } from './TrashedSnippetUtils';


const label = TRASH_LABELS['snippets'];

const TrashedSnippets = () => {
  const { setNavigateToOnClose } = useContext(TrashedDialogContext);
  const [selectedGroup, setSelectedGroup] = useState(/** @type {string} */ null);
  const [filters, setFilters] = useState(/** @type {Parameters<TrashedFirestore>['0']['filters']} */ ([]));
  const allGroupIds = useTypedSelectorShallowEquals((state) => {
    let deletedFrom = state.uiState?.deletedSnippetsFrom || new Set();
    const groups = state.dataState.groups;
    const userGroups = state.userState.groups;
    const ignoreGroups = state.dataState.ignoreGroups;
    const enabledGroupIds = [];
    const disabledGroupIds = [];
    for (const groupId in groups) {
      const group = groups[groupId];
      if (!groupId || ignoreGroups?.includes(groupId)) {
        continue;
      }
      const userGroup = userGroups[groupId];
      if (deletedFrom?.has(groupId)) {
        continue;
      }
      if (!['editor', 'owner'].includes(userPermission(group))) {
        continue;
      }
      if (!userGroup?.disabled) {
        enabledGroupIds.push(groupId);
      } else if (userGroup.disabled) {
        disabledGroupIds.push(groupId);
      }
    }

    const sortGroup = (a, b) => {
      let aOrder = userGroups[a]?.order || 1000;
      let bOrder = userGroups[b]?.order || 1000;

      if (aOrder === bOrder) {
        return a.localeCompare(b);
      }
      return aOrder - bOrder;
    };
    const sortedEnabledGroupIds = enabledGroupIds.sort(sortGroup),
      sortedDisabledGroupIds = disabledGroupIds.sort(sortGroup);
    return [
      ...deletedFrom,
      ...sortedEnabledGroupIds,
      ...sortedDisabledGroupIds
    ];
  });
  const groups = useTypedSelector(state => state.dataState.groups);

  const strAllGroupIds = JSON.stringify(allGroupIds);
  useEffect(() => {
    if (selectedGroup) {
      setFilters([
        ['group_id', '==', selectedGroup]
      ]);
      return;
    }
    const allGroupIds = JSON.parse(strAllGroupIds);
    if (!!allGroupIds.length && allGroupIds.length <= 10) {
      setFilters([
        ['group_id', 'in', allGroupIds]
      ]);
      return;
    }
    setFilters(f => !f.length ? f : []);
  }, [selectedGroup, strAllGroupIds]);
  /**
   * 
   * @type {Parameters<TrashedFirestore>['0']['onRestore']} 
   */
  const onRestore = async (snippet) => {
    const snippetId = snippet.id;
    await restoreSnippet(snippetId);
    setNavigateToOnClose(`/snippet/${snippetId}`);
  };
  const header = <GroupSelector
    value={selectedGroup}
    options={allGroupIds}
    onChange={(val) => {
      setSelectedGroup(val);
    }}
    sx={{
      m: 2
    }}
  />;

  if (filters?.length || !allGroupIds.length) {
    return <TrashedFirestore
      collection="snippets"
      labels={label}
      filters={filters}
      onRestore={onRestore}
      IconComponent={SnippetIcon}
      header={header}
      transformData={(data) => transformData(data, groups)}
    />;
  } else {
    return <TrashedAllSnippets
      groupIds={allGroupIds}
      onRestore={onRestore}
      header={header}
    />;
  }
};

export default TrashedSnippets;