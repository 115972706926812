import React from 'react';
import T from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/WarningRounded';
import UpdateIcon from '@mui/icons-material/Update';
import SuccessIcon from '@mui/icons-material/CheckCircle';


const ICON_COLOR = 'rgba(92, 112, 128, 0.6)';
const ICON_MARGIN_TOP = 20;
const ICON_MARGIN_BOTTOM = 8;


/**
 * @param {object} props
 * @param {string} props.title
 * @param {(string|React.ReactElement)=} props.description
 * @param {'MISSING'|'ERROR'|'WARNING'|'UPDATE'|'SUCCESS'|import("react").FunctionComponent=} props.icon
 * @param {object=} props.style
 */
export function EmptyState(props) {
  /**
   * @type {import("react").FunctionComponent}
   */
  let icon;
  if (props.icon === 'MISSING') {
    icon = () => <div style={{
      width: 120,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: ICON_MARGIN_TOP,
      marginBottom: ICON_MARGIN_BOTTOM + 12
    }}>
      <svg style={{ color: ICON_COLOR, fill: ICON_COLOR }} aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M603.32 473.39l-81.48-81.46a128 128 0 1 0-33.93 33.93l81.48 81.46a16 16 0 0 0 22.62 0L603.32 496a16 16 0 0 0 0-22.61zM416 400a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80zM80 464V48.09h160v104a23.93 23.93 0 0 0 24 24h83.29c20.89-10 44-16.06 68.71-16.06V132a48.23 48.23 0 0 0-14.1-34L318 14.1A48 48 0 0 0 284.1 0H80a48.16 48.16 0 0 0-48 48.09V464a48 48 0 0 0 48 48h288a47.86 47.86 0 0 0 45.15-32.29A158.48 158.48 0 0 1 347.43 464zM288 52l76.1 76.08H288z"></path></svg>
    </div>;
  } else if (props.icon === 'ERROR') {
    icon = ErrorIcon;
  } else if (props.icon === 'WARNING') {
    icon = WarningIcon;
  } else if (props.icon === 'UPDATE') {
    icon = UpdateIcon;
  } else if (props.icon === 'SUCCESS') {
    icon = SuccessIcon;
  } else {
    if (typeof props.icon === 'string') {
      console.error('Invalid icon for EmptyState: ' + props.icon);
      icon = WarningIcon;
    } else {
      icon = props.icon;
    }
  }

  return <div style={Object.assign({
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center'
  }, props.style)}>
    {icon && React.createElement(icon, {
      style: {
        fontSize: '86px',
        color: ICON_COLOR,
        marginTop: ICON_MARGIN_TOP,
        marginBottom: ICON_MARGIN_BOTTOM
      }
    })}
    <T
      variant="h6"
      style={{ marginBottom: 16,fontWeight: 'bold' }}
    >{props.title}</T>
    {props.description && <T
      variant="body1"
      color="textSecondary"
      style={{ maxWidth: 500, marginLeft: 'auto', marginRight: 'auto' }}
      component="div"
    >{props.description}</T>}
  </div>;
}