import React, { forwardRef, useState } from 'react';
import Popper from '@mui/material/Popper';
import { Box } from '@mui/material';


  
/**
 * 
 * @param {import("@mui/material").PopperProps & {arrowcolor?: string}} props 
 */
function ArrowPopperBase({
  children,
  modifiers,
  arrowcolor: arrowcolorProp,
  sx,
  ...subProps
}, ref) {
  const [arrowRef, setArrowRef] = useState(null);
  const newModifiers = modifiers ? modifiers.slice() : [];

  newModifiers.push({
    name: 'arrow',
    options: {
      enabled: true,
      element: arrowRef
    }
  });
  const arrowColor = arrowcolorProp || 'background.paper';
  
  return <Popper
    ref={ref}
    modifiers={newModifiers}
    {...subProps}
    sx={[(theme) => ({
      zIndex: theme.zIndex.modal + 1,
      '& > div': {
        position: 'relative'
      },
      '& .MuiPopper-arrow': {
        width: '3em',
        height: '1em',
        '&:before': {
          borderColor: arrowColor
        }
      },
      '&[data-popper-placement*="bottom"]': {
        '& > div': {
          marginTop: '2px',
        },
        '& .MuiPopper-arrow': {
          top: 0,
          left: 0,
          marginTop: '-0.9em',
          height: '1em',
          '&::before': {
            borderWidth: '0 1.3em 1.3em 1.3em',
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
            borderLeftColor: 'transparent'
          }
        }
      },
      '&[data-popper-placement*="top"]': {
        '& > div': {
          marginBottom: '2px',
        },
        '& .MuiPopper-arrow': {
          bottom: 0,
          left: 0,
          marginBottom: '-0.6em',
          height: '1em',
          '&::before': {
            borderWidth: '1.3em 1.3em 0 1.3em',
            borderRightColor: 'transparent',
            borderBottomColor: 'transparent',
            borderLeftColor: 'transparent'
          }
        }
      },
      '&[data-popper-placement*="right"]': {
        '& > div': {
          marginLeft: '2px',
        },
        '& .MuiPopper-arrow': {
          left: 0,
          marginLeft: '-0.9em',
          width: '1em',
          '&::before': {
            borderWidth: '1.3em 1.3em 1.3em 0',
            borderTopColor: 'transparent',
            borderBottomColor: 'transparent',
            borderLeftColor: 'transparent'
          }
        }
      },
      '&[data-popper-placement*="left"]': {
        '& > div': {
          marginRight: '2px',
        },
        '& .MuiPopper-arrow': {
          right: 0,
          marginRight: '-0.6em',
          width: '1em',
          '&::before': {
            borderWidth: '1.3em 0 1.3em 1.3em',
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
            borderBottomColor: 'transparent'
          }
        }
      }
    }), ...(Array.isArray(sx) ? sx : [sx])]}
  > 
    <>
      {children}
      <Box
        ref={setArrowRef}
        className="MuiPopper-arrow"
        sx={{
          position: 'absolute',
          fontSize: 7,
          width: '3em',
          height: '3em',
          '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
          }
        }}
      />
    </>
  </Popper>;
}

const ArrowPopper = forwardRef(ArrowPopperBase);
export default ArrowPopper;