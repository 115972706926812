import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  Drawer,
  Typography as T
} from '@mui/material';

const SidebarPanel = forwardRef((_props, ref) => {
  let [open, setOpen] = useState(false);
  let [config, setConfig] = useState(null);

  useImperativeHandle(ref, () => ({
    show: (config) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setOpen(true);
        setConfig(config);
      });
    }
  }));

  const hide = () => {
    setOpen(false);
    setConfig(null);
  };

  const close = () => {
    if (config && config.onClose) {
      config.onClose();
    }
    hide();
  };

  // note we don't return null if not open as that breaks the sliding-in animation

  return (
    <Drawer anchor="right" open={open} onClose={() => close()}>
      {open && <div style={{ overflow: 'auto', width: config.width || 400 }}>
        {config.title && <T variant="h5" component="div" paragraph style={{
          marginLeft: 24,
          marginTop: 16
        }}>{config.title}</T>}
        {config.contents}
      </div>}
    </Drawer>
  );
});

export default SidebarPanel;