import React, { useEffect, useState } from 'react';
import { useIsMounted, useTypedSelector } from '../../hooks';
import { CircularProgress } from '@mui/material';
import T from '@mui/material/Typography';
import { decompressDelta } from '../../delta_proto/DeltaProto';
import makeDiff from '../Diff/make_diff';
import equals from 'fast-deep-equal';
import { EmptyState } from '../EmptyState/EmptyState';
import { sync } from '../../Sync/syncer';
import { toast } from '../../message';
import SnippetChangeHistory from './SnippetChangeHistory';
import { getEarliestCreation } from './change_utilities';
import { getHistory as getHistoryInner } from '../../bapi';


const caption = <T
  variant="caption"
  color="textSecondary"
  style={{ marginTop: 20, display: 'block', textAlign: 'center', marginBottom: 24 }}
>Prior versions are created periodically and are available for up to 10 days.</T>;


// Cache histories from the server for 3 minutes
const CACHE_EXPIRATION_SECONDS = 180;
const HISTORY_CACHE = {};


async function getHistory(selector) {
  let key = JSON.stringify(selector);

  if (!HISTORY_CACHE[key] || (HISTORY_CACHE[key].timestamp + CACHE_EXPIRATION_SECONDS * 1000) < Date.now()) {
    HISTORY_CACHE[key] = {
      timestamp: Date.now(),
      value: getHistoryInner(selector)
    };
  }
  
  // we mutate this so we need to create a copy
  return JSON.parse(JSON.stringify(await HISTORY_CACHE[key].value));
}



/**
 * @param {object} props
 * @param {string} props.type
 * @param {string} props.groupId
 * @param {string} props.snippetId
 */
export default function ChangeHistory(props) {
  let type = props.type;

  let isMounted = useIsMounted();

  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(null);
  let [history, setHistory] = useState(null);

  let group = useTypedSelector(store => type === 'group' ? store.dataState.groups[props.groupId] : null);

  useEffect(() => {
    getHistory({
      selector: {
        type,
        snippet_id: props.snippetId,
        group_id: props.groupId
      }
    }).then(res => {
      // Async so we need to check if we're still mounted
      if (isMounted.current) {
        setLoading(false);

        if (res.data.history) {
          let history = res.data.history;

          if (type === 'snippet' && history.snippets.length === 0) {
            // when we have no history we can still use the current snippet
            history.snippets.push({
              id: props.snippetId,
              changes: []
            });
          } else if (type === 'group' && group) {
            for (let snippet of group.snippets) {
              if (!history.snippets.find(x => x.id === snippet.id)) {
                history.snippets.push({
                  id: snippet.id,
                  changes: [],
                });
              }
            }
          }

          history.snippets.forEach(snippet => {
            snippet.currentSnippet = sync.getSnippetById(snippet.id);
            snippet.currentName = snippet.currentSnippet ? snippet.currentSnippet.data.name : snippet.changes[0].name;
            snippet.currentShortcut = snippet.currentSnippet ? snippet.currentSnippet.data.shortcut : snippet.changes[0].shortcut;
            if (snippet.currentSnippet) {
              let currentDelta = decompressDelta(snippet.currentSnippet.data.content.delta.toUint8Array());
              if (snippet.changes.length === 0) {
                snippet.showCurrent = true;
              } else {
                let mostRecentDelta = decompressDelta(new Uint8Array(Object.values(snippet.changes[0].delta)));
                snippet.currentDiff = makeDiff(currentDelta, mostRecentDelta);
                snippet.showCurrent = !equals(currentDelta, mostRecentDelta);
              }
            }
          });

          if (type === 'group') {
            function getTimestamp(entry) {
              let val = entry.currentSnippet ? entry.currentSnippet.data.updated_at.toMillis() : (new Date(entry.changes[0].timestamp.value).getTime());
              return val;
            }
            // if their isn't a current snippet, it means it's deleted and e should use the timestamp
            history.snippets.sort((a, b) => getTimestamp(b) - getTimestamp(a));
            
            const EARLIEST_CREATION = getEarliestCreation();
            history.snippets = history.snippets.filter(snippet => {
              return /* we have changes to show */ snippet.changes.length > 1 ||
              /* current snippet is different - show it */ snippet.showCurrent ||
              /* current snippet is deleted - show it */ !snippet.currentSnippet ||
              /* snippet is new - show it */ (snippet.currentSnippet.data.created_at && snippet.currentSnippet.data.created_at.toMillis() > EARLIEST_CREATION);
            });
          } 
          
          setHistory(history);
        } else {
          setHistory('NONE');
        }
      }
    }).catch((err) => {

      if (isMounted.current) {
        toast('Could not load history.' + ((err && err.message) ? (' ' + err.message) : '')  + ' Contact support@blaze.today for assistance.', {
          duration: 8000,
          intent: 'danger'
        });

        setError(true);
      }

    });
  // eslint-disable-next-line
  }, [type, type === 'group' ? props.groupId : props.snippetId]);

  if (error) {
    return <EmptyState
      icon="ERROR"
      title="An error getting history occurred"
      style={{
        marginTop: 60
      }}
    />;
  }

  if (loading || !history) {
    return <div style={{
      paddingTop: 60,
      marginBottom: 30,
      justifyContent: 'center',
      display: 'flex'
    }}>
      <CircularProgress size={120} thickness={1.9} />
    </div>;
  }
  
  if (history.snippets.length === 0 && type === 'snippet') {
    return <>
      <EmptyState
        icon="MISSING"
        title="No snippet history available yet"
        style={{
          marginTop: 60
        }}
      />
      {caption}
    </>;
  }


  if (history.snippets.length === 0) {
    return <>
      <EmptyState
        icon="MISSING"
        title="No snippets with recent content changes"
        style={{
          marginTop: 60
        }}
      />
      {caption}
    </>;
  }

  return <>
    {
      type === 'snippet' ?
        <SnippetChangeHistory snippet={history.snippets[0]}/> :
        <div style={{ marginTop: 30, marginBottom: 30 }}>
          {history.snippets.map((snippet, i) => <SnippetChangeHistory key={i} last={i === history.snippets.length - 1} snippet={snippet} showFraming/>)}
        </div>
    }
    {caption}
  </>;
}
