import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTypedSelector } from '../../hooks';
import ReferOnboarding from '../ReferDialog/ReferOnboarding';


function GlobalReferDialog(props) {
  const location = useLocation();
  const history = useHistory();
  let { referralOpen } = useTypedSelector((store) => {
    return {
      referralOpen: store.userState && store.userState.isLoaded && (!!store.userState.uid) && location.hash === '#refer',
    };
  });
  

  if (!referralOpen) {
    return null;
  }
  return <ReferOnboarding
    open
    onClose={ () => history.push({ hash: '' }) }
  />;
};


export default GlobalReferDialog;
