
/**
 * @typedef ConfettiConfigArgument
 * @property {import('@store').UserStateDef} userState 
 */


const confettiStartTimestamp = new Date('2023-11-01').getTime();

/**
 * @param {ConfettiConfigArgument} arg0
 * @returns 
 */
export function isOldUserForConfetti({ userState }) {
  const userCreationTime = userState?.firebaseMetadata?.creationTime;
  if (userCreationTime) {
    const userCreatedAt = new Date(userCreationTime).getTime();

    if (userCreatedAt < confettiStartTimestamp) {
      return true;
    }
  }

  return false;
}

/**
 * Returns default value for "confetti" option.
 * @param {import('@store').RootState} _state
 *
 * @returns {boolean}
 */
export function getConfettiDefault({ userState }) {
  if (isOldUserForConfetti({ userState })) {
    // Don't enable for old users
    return false;
  }
  return true;
}
