import React, { useState, useCallback, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import AuthComponent from './AuthComponent';
import T from '@mui/material/Typography';
// @ts-ignore
import blazeIcon from './../../../images/icon_128.png';
// @ts-ignore
import aiBlazeIcon from './../../../images/ai_blaze.webp';
import { useIsMedium, useTypedSelector } from '../../hooks';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { APP_TARGET, isElectronApp } from '../../flags';
import { DesktopAuthComponent } from './DesktopAuthComponent';
import { sendMessageToClient } from '../../desktop_utilities';
import { DialogBackgroundStyles, DialogPaperStyles } from './auth_utilities';
import { isBusinessTrialOffer, signUpHashesTb, signUpHashesAi } from './hashes';

let shouldShowTextSignUpForTb = signUpHashesTb.includes(window.location.hash) && APP_TARGET === 'TEXT';
let shouldShowTextSignUpForAi = signUpHashesAi.includes(window.location.hash) && APP_TARGET === 'AI';

let isBusinessTrial = isBusinessTrialOffer();
let isElectronFlag = isElectronApp();

function AuthDialogBase() {
  const [arrowRef, setArrowRef] = useState(null);
  const [ref, setRef] = useState(null);
  const onRefSet = useCallback(ref => {
    setRef(ref);
  }, []);

  let show = useTypedSelector(store => store.userState.isLoaded && !store.userState.uid);

  let isMedium = useIsMedium();
  let [showProgress, setProgress] = useState(false);

  useEffect(() => {
    // If signing out don't ask for a token
    if (isElectronFlag && !window['signout']) {
      if (show) {
        setProgress(true);
        sendMessageToClient({ type: 'confirm-checker' });
        // main process will get the token from checker and 
        // send it to dashboard via 'authenticate-dashboard'
      } else {
        setProgress(false);
      }
    }
  }, [show]);

  if (showProgress) {
    return <DesktopAuthComponent />;
  }

  if (!show || isElectronFlag) {
    return null;
  }

  return <>
    <Dialog
      className="login-dialog"
      open
      PaperProps={{
        style: DialogPaperStyles
      }}
      BackdropProps={{ style: DialogBackgroundStyles }}
    >
      <div
        ref={onRefSet}
      >
        <AuthComponent />

        {(shouldShowTextSignUpForTb || shouldShowTextSignUpForAi) && ref && (!isMedium) &&
          <Popper
            style={{
              zIndex: 100000
            }}
            anchorEl={ref}
            open
            placement="left"
            modifiers={[
              {
                name: 'flip',
                enabled: false
              },
              {
                name: 'preventOverflow',
                enabled: false,
                options: {
                  padding: 8
                }
              },
              {
                name: 'offset',
                options: {
                  offset: [0, 14]
                }
              },
              {
                name: 'arrow',
                enabled: true,
                options: {
                  element: arrowRef
                }
              }
            ]}
          >
            <Paper elevation={5}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                padding: 14,
                width: 360
              }}>
                <div>
                  <img src={shouldShowTextSignUpForAi ? aiBlazeIcon : blazeIcon} alt="Text Blaze Logo" style={
                    shouldShowTextSignUpForAi ? {
                      width: 60,
                      height: 60,
                      marginRight: 18,
                      position: 'relative',
                      top: 4
                    }
                      : {
                        width: 64,
                        height: 64,
                        borderRadius: 100,
                        marginRight: 20,
                        opacity: .8,
                      }} />
                </div>
                {shouldShowTextSignUpForAi
                  ? <T color="textSecondary">Log in to AI Blaze to create prompts or sync them between computers.</T>
                  : <div>
                    {isBusinessTrial
                      ? <T color="textSecondary">Sign up for Text Blaze and get a 3 months Business plan trial.</T>
                      : <T color="textSecondary">Log in to Text Blaze to create templates or sync them between computers.</T>
                    }
                    <p style={{ textAlign: 'center', marginBottom: 0, paddingTop: 7, opacity: 0.8 }}><a
                      href="https://blaze.today" target="_blank" rel="noopener noreferrer">Learn more about Text
                      Blaze</a></p>
                  </div>}
              </div>
            </Paper>

            <Box
              sx={{
                position: 'absolute',
                fontSize: '7px',
                display: 'inline',
                right: 0,
                marginRight: '-1.8em',
                height: '3em',
                width: '2em',
                '&::before': {
                  content: '""',
                  margin: 'auto',
                  display: 'block',
                  width: '0px',
                  height: '0px',
                  borderStyle: 'solid',
                  borderWidth: '2em 0 2em 2em',
                  borderColor: 'background.paper',
                  borderTopColor: 'transparent',
                  borderRightColor: 'transparent',
                  borderBottomColor: 'transparent',
                },
              }}
              ref={setArrowRef}
            />
          </Popper>}
      </div>
    </Dialog>
  </>;
}

const AuthDialog = React.memo(AuthDialogBase);
export default AuthDialog;