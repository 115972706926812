import React, {  } from 'react';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import T from '@mui/material/Typography';

export function formatItem(item, isExtra, clickFn) {
  let chip = <Chip
    size="small"
    variant={item.variant || 'outlined'}
    key={item.label}
    label={item.label}
    icon={item.icon}
    className="quick-action-chip"
    style={{
      cursor: item.variant !== 'default' ? 'copy' : undefined,
      marginLeft: 4,
      marginRight: 4,
      maxWidth: 100,
      textOverflow: 'ellipsis'
    }}
    onClick={(e) => {
      if (clickFn) {
        clickFn(e);
      }
      
      item.onClick(e, isExtra);
    }}
    onDelete={item.onDelete && ((e) => {
      if (clickFn) {
        clickFn(e);
      }

      item.onDelete(e, isExtra);
    })}
  />;
  if (item.tooltip) {
    return <Tooltip key={item.label} title={item.tooltip} placement="bottom">
      {chip}
    </Tooltip>;
  } else {
    return chip;
  }
}


export const CATEGORIES = {
  number: 'Mathematical functions',
  string: 'Text functions',
  list: 'List functions',
  statistics: 'Statistical functions',
  other: 'Miscellaneous functions',
  date: 'Date functions',
  date_commands: 'Date commands',
  shift: 'Date shifting commands',
  context: 'Context commands',
  dynamic: 'Miscellaneous commands'
};


export function popoverList(items, setExpanded) {
  let res = [];
  let category;
  for (let item of items) {
    if (item.category !== category) {
      let first = category === undefined;
      category = item.category;
      res.push(<T key={item.category} variant="subtitle2" style={{ marginTop: first ?  0 : 16, marginBottom: 8 }}>{CATEGORIES[item.category]}</T>);
    }
    res.push(formatItem(item, true, setExpanded && (() => setExpanded(false))));
  }
  return res;
}