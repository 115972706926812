import React from 'react';
import MouseIcon from '@mui/icons-material/MouseOutlined';
import KeyboardIcon from '@mui/icons-material/KeyboardOutlined';
import ShortTextIcon from '@mui/icons-material/ShortText';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import LongTextIcon from '@mui/icons-material/SubjectOutlined';
import CalendarIcon from '@mui/icons-material/EventOutlined';
import UserIcon from '@mui/icons-material/PersonOutlineOutlined';
import ClipboardIcon from '@mui/icons-material/AssignmentOutlined';
import SiteIcon from '@mui/icons-material/InsertDriveFileOutlined';
import MenuIcon from '@mui/icons-material/MenuOpen';
import ToggleIcon from '@mui/icons-material/ToggleOnOutlined';
import WaitIcon from '@mui/icons-material/TimerOutlined';
import FormDateIcon from '@mui/icons-material/EventAvailableOutlined';
import URLSendIcon from '@mui/icons-material/CloudUploadOutlined';
import URLLoadIcon from '@mui/icons-material/CloudDownloadOutlined';
import LinkIcon from '@mui/icons-material/Link';
import AddonIcon from '@mui/icons-material/WidgetsOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import ImageIcon from '@mui/icons-material/ImageOutlined';
import ImportIcon from '@mui/icons-material/PostAdd';
import SnippetIcon from '@mui/icons-material/DescriptionOutlined';
import ReactDOMServer from 'react-dom/server';
import { getCommandsFromAttribute } from '../../../snippet_processor/ParserUtils';
import RepeatIcon from '@mui/icons-material/Repeat';
import DatabaseIcon from '@mui/icons-material/GridOnOutlined';
import NoteIcon from '@mui/icons-material/StickyNote2Outlined';
import IfIcon from '@mui/icons-material/AltRouteOutlined';
import FormulaIcon from '@mui/icons-material/CalculateOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import RunIcon from '@mui/icons-material/Terminal';
import ButtonIcon from '@mui/icons-material/SmartButton';

import CodeIcon from '@mui/icons-material/Code';
import ListIcon from '@mui/icons-material/List';
import TitleIcon from '@mui/icons-material/Title';
import CommandChip from './CommandChip';

export const ICON_MAPPING = {
  click: <MouseIcon className="embedded-chip-icon" />,
  clipboard: <ClipboardIcon className="embedded-chip-icon" />,
  cursor: <svg className="embedded-chip-icon fa-icon"
    aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 512">
    <path fill="currentColor" d="M128 41.522C91.867.049 43.399-.377 11.818.076 5.26.17 0 5.516 0 12.075v23.609c0 6.641 5.393 12.037 12.034 12C39.464 47.528 104 52.257 104 104v128H68c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h36v128c0 51.494-62.335 55.801-92.092 55.985C5.314 464.026 0 469.39 0 475.984v23.943c0 6.558 5.258 11.903 11.815 11.999 31.535.46 80.027.054 116.185-41.448 36.132 41.473 84.601 41.899 116.182 41.446 6.558-.094 11.818-5.44 11.818-11.999v-23.608c0-6.641-5.393-12.037-12.034-12C216.538 464.47 152 459.731 152 408V280h36c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12h-36V104c0-51.514 62.301-55.805 92.092-55.985C250.686 47.975 256 42.61 256 36.016V12.073C256 5.515 250.742.17 244.185.074 212.65-.386 164.157.02 128 41.522z"></path>
  </svg>,
  key: <KeyboardIcon className="embedded-chip-icon" />,
  focus: <CenterFocusStrongIcon className="embedded-chip-icon" />,
  site: <SiteIcon className="embedded-chip-icon" />,
  formsingle_line: <ShortTextIcon className="embedded-chip-icon" />,
  formmulti_line: <LongTextIcon className="embedded-chip-icon" />,
  formmenu: <MenuIcon className="embedded-chip-icon" />,
  formdate: <FormDateIcon className="embedded-chip-icon" />,
  formtoggle_start: <ToggleIcon className="embedded-chip-icon" />,
  datetime: <CalendarIcon className="embedded-chip-icon" />,
  user: <UserIcon className="embedded-chip-icon" />,
  snippet: <SnippetIcon className="embedded-chip-icon" />,
  wait: <WaitIcon className="embedded-chip-icon" />,
  import: <ImportIcon className="embedded-chip-icon" />,
  remoteurl_load: <URLLoadIcon className="embedded-chip-icon" />,
  remoteurl_ping: <URLSendIcon className="embedded-chip-icon" />,
  remotedbselect: <DatabaseIcon className="embedded-chip-icon" />,
  remotedbupdate: <DatabaseIcon className="embedded-chip-icon" />,
  remotedbdelete: <DatabaseIcon className="embedded-chip-icon" />,
  remotedbinsert: <DatabaseIcon className="embedded-chip-icon" />,
  linklink_start: <LinkIcon className="embedded-chip-icon" />,
  alert: <ErrorIcon className="embedded-chip-icon" />,
  image: <ImageIcon className="embedded-chip-icon" />,
  notenote_start: <NoteIcon className="embedded-chip-icon" />,
  formif_start: <div className="embedded-chip-icon" >if</div>,
  formif_elseif: <div className="embedded-chip-icon">elseif</div>,
  formif_else: <div className="embedded-chip-icon">else</div>,
  formrepeat_start: <div className="embedded-chip-icon">repeat</div>,
  calc: <div className="embedded-chip-icon"><b>=</b></div>,
  calc_icon: <FormulaIcon className="embedded-chip-icon" />,
  if_icon: <IfIcon className="embedded-chip-icon" />,
  repeat_icon: <RepeatIcon className="embedded-chip-icon" />,
  addon: <AddonIcon className="embedded-chip-icon" />,
  db: <DatabaseIcon className="embedded-chip-icon" />,
  date: <CalendarIcon className="embedded-chip-icon" />,
  other: <CodeIcon className="embedded-chip-icon" />,
  list: <ListIcon className="embedded-chip-icon" />,
  string: <TitleIcon className="embedded-chip-icon" />,
  number: <FormulaIcon className="embedded-chip-icon" />,
  statistics: <BarChartOutlinedIcon className="embedded-chip-icon" />,
  run: <RunIcon className="embedded-chip-icon" />,
  button: <ButtonIcon className="embedded-chip-icon" />
};


/**
 * @param {ReturnType<import('../editor_utilities').getCollapsedData>} data 
 */
export function createCommandChip(data) {
  return ReactDOMServer.renderToStaticMarkup(
    <CommandChip
      data={data}
    />
  );
}

/**
 * Generates the options attribute for formmenu and adds it to the {@link attributes}
 * @param {ReturnType<import('../editor_utilities').getCollapsedData>['value']['attributes']} attributes 
 */
export function addFormMenuOptionsAttribute(attributes) {
  let optionsStr = '',
    optionsRawStr = '',
    appendStr = '',
    /**
     * @type {ReturnType<import('../editor_utilities').getCollapsedData>[]}
     */
    nodes = [],
    attrCursor = 0;
  for (let attrIndex = 0; attrIndex < attributes.length; attrIndex++) {
    const attr = attributes[attrIndex];
    if (attr.name !== 'default' && !attr.positional) {
      continue;
    }
    optionsStr += appendStr + attr.value;
    optionsRawStr += appendStr + attr.raw;
    appendStr = ', ';
    const attrNodes = attr.nodes;
    for (let nodeIndex = 0; nodeIndex < attrNodes?.length; nodeIndex++) {
      /**
       * @type {nodes[0]}
       */
      const attrNode = attrNodes[nodeIndex],
        attrMeta = attrNode.meta;
      attrMeta.attributesPath[attrMeta.attributesPath.length - 1].skip = attrCursor;
      nodes.push({
        ...attrNode,
        meta: {
          ...attrMeta,
          startPosition: attrMeta.startPosition + attrCursor,
          endPosition: attrMeta.endPosition + attrCursor
        }
      });
    }
    attrCursor += attr.raw.length + appendStr.length;
  }
  if (appendStr === '') {
    return;
  }
  const isValues = attributes.some(a => a.name === 'values');
  for (let index = attributes.length - 1; index >= 0; index--) {
    const attribute = attributes[index];
    if (attribute.name !== 'default') {
      continue;
    }
    attributes.splice(index, 1);
  }
  /**
   * @type {typeof attributes[0]}
   */
  const attribute = {
    name: isValues ? 'default' : 'options',
    value: optionsStr,
    fillIn: false,
    raw: optionsRawStr,
    positional: !isValues,
    nodes: nodes
  };
  return isValues ? attributes.push(attribute) : attributes.unshift(attribute);
}


/**
 * Ensures the text is valid attribute and returns an invalid character
 * that needs escaping if it is not valid.
 * 
 * @param {string} str 
 * @param {object} options 
 * @param {boolean=} options.isListContext 
 * @param {boolean=} options.isLegacyMenu 
 * 
 * @return {{ position: number, message: string }}
 */
export function isInvalidAttribute(str, {
  isLegacyMenu,
  isListContext
} = {}) {
  let commands = getCommandsFromAttribute(str);

  let i;

  function response(char) {
    return {
      position: i,
      message: `"${char}" should be "\\${char}"`
    };
  }

  for (i = 0; i < str.length; i++) {
    let char = str[i];

    if (commands[0] && commands[0].end <= i) {
      commands.shift();
    }

    if (commands[0] && i >= commands[0].start) {
      // pass
      continue;
    }

    if (char === '\\') {
      if (i === str.length - 1) {
        return response('\\');
      } else {
        i++;
        // pass
      }
    } else {
      if (char === ';') {
        return response(';');
      } else if (char === '{') {
        return response('{');
      } else if (char === '}') {
        return response('}');
      } else if (isListContext && char === ',') {
        // We need to escape "," in lists
        return response(',');
      } else if (isLegacyMenu && char === '=') {
        // we need to escape '=' in formmemnu
        return response('=');
      } else {
        // pass
      }
    }
  }

  return null;
}

/**
 * Sets the value for a attribute
 * use data = null to delete
 * @param {string} name 
 * @param {import("../editor_utilities").CollapsedDataType} commandData 
 * @param {{ value?: string, raw?: string}} data 
 * @param {{ positional: Boolean, fillIn: Boolean }} extraFields
 * @param {{override: (string | import("../../../snippet_processor/ParseNode").InfoType)}} errorOverride
 * @param {number=} attributeSkip
 */
export function setEmbeddedAttribute(
  name,
  commandData,
  data,
  extraFields = {
    positional: false,
    fillIn: false
  },
  errorOverride,
  attributeSkip
) {
  const { value, raw } = data || {};

  let newData = Object.assign({}, commandData);
  newData.value = Object.assign({}, newData.value);
  newData.meta = Object.assign({}, newData.meta);
  newData.value.attributes = newData.value.attributes?.map(a => ({ ...a }));
  if (errorOverride) {
    newData.meta.error = errorOverride.override;
  }
  let index = findAttribute(newData, {
    name,
    skip: attributeSkip
  });
  let attribute;
  if (index > -1) {
    if (data === null) {
      newData.value.attributes.splice(index, 1);
    } else {
      newData.value.attributes[index].value = value;
      newData.value.attributes[index].raw = raw;
      for (let key in extraFields) {
        newData.value.attributes[index][key] = extraFields[key];
      }
      attribute = newData.value.attributes[index];
    }
  } else {
    if (data !== null) {
      attribute = Object.assign({
        name,
        value,
        raw,
        nodes: []
      }, extraFields);

      newData.value.attributes.push(attribute);
    }
  }
  return newData;
}

/**
 * 
 * @param {import("../editor_utilities").CollapsedDataType} data 
 * @param {{name: string, skip?: number}} attributePath 
 */
export const findAttribute = (data, attributePath) => {
  let index;
  let attributes = data.value.attributes;
  if (attributePath.name || !attributePath.skip) {
    index = attributes.findIndex(x => x.name === attributePath.name);
  } else {
    let valuesAtttributes = attributes.filter(attr => attr.name === 'default' || attr.positional);
    let valuesAttrIndex = 0;
    let valueCursor = 0;
    while (valueCursor < attributePath.skip) {
      valueCursor += valuesAtttributes[valuesAttrIndex].raw.length + 2;
      valuesAttrIndex++;
    }
    index = attributes.indexOf(valuesAtttributes[valuesAttrIndex]);
  }
  return index;
};