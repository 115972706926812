import React, { useRef, useState } from 'react';
import DatabaseFrame from '../Database/DatabaseFrame';
import { TABLES_FRONT_END_DOMAIN } from '../../hooks/useTables';
import AutoCompleteList from '../Database/AutoCompleteList';
import Box from '@mui/material/Box';
import VerificationReminder from '../VerificationReminder/VerificationReminder';
import { useTypedSelector } from '../../hooks';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useIFrameOrgUpdater from '../../hooks/useIFrameOrgUpdater';


/**
 * @typedef {object} CommentsSidebarProps
 * @property {'snippet'|'page'} entityType
 * @property {string} entityId
 * @property {function} closeSidebarFn
 * @property {function} onCommentsCountChanged
 */

/**
 * @param {CommentsSidebarProps} props
 */
function CommentsSidebarInner(props) {
  const iframeRef = useRef(null);
  const [iframeLoading, setIframeLoading] = useState(true);
  useIFrameOrgUpdater(iframeLoading, (type, data) => {
    iframeRef.current.contentWindow.postMessage({ type, data }, TABLES_FRONT_END_DOMAIN);
  });

  return <>
    <DatabaseFrame
      ref={iframeRef}
      url={`${TABLES_FRONT_END_DOMAIN}/data-blaze/entity/comments/${props.entityType}/${props.entityId}`}
      style={{
        height: '100vh',
      }}
      loading={iframeLoading}
      onLoaded={() => setIframeLoading(false)}
      listener={(event) => {
        const { type, data } = event.data;
        if (type === 'comments_count') {
          props.onCommentsCountChanged(data);
        } else if (type === 'comments_close') {
          props.closeSidebarFn();
        }
      }}
    />
    <AutoCompleteList
      frame={iframeRef}
    />
  </>;
}


/**
 * @param {CommentsSidebarProps} props
 */
function CommentsSidebar(props) {
  const emailVerified = useTypedSelector(store => store.userState.emailVerified);
  if (!emailVerified) {
    return <div style={{ padding: 12 }}>
      <Box sx={{ marginBottom: 2 }}>
        <Box
          sx={{
            textAlign: 'right',
            marginLeft: 1
          }}
        >
          <IconButton
            onClick={() => props.closeSidebarFn()}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <VerificationReminder
        reason="you will not be able to comment"
      />
    </div>;
  }

  return <CommentsSidebarInner
    entityType={props.entityType}
    entityId={props.entityId}
    closeSidebarFn={props.closeSidebarFn}
    onCommentsCountChanged={props.onCommentsCountChanged}
  />;
}


export default CommentsSidebar;