import React from 'react';
import {
  Typography as T
} from '@mui/material';
import { highlightAttribute, highlightFormula } from '../../FormulaEditor/syntax_highlighters';
import { escapedToIntermediate, intermediateToNormal } from '../../../snippet_processor/ParserUtils';

import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import { astSQL } from '../../../snippet_processor/SQL';
import { Environment } from '../../../snippet_processor/DataContainer';
import PositionalListIcon from '@mui/icons-material/FormatListNumbered';
import KeyedListIcon from '@mui/icons-material/DnsOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import URLAvatar from '../../Avatar/URLAvatar';
import { isCommunityDocsOrBundle } from '../../../flags';
import { ICON_MAPPING, addFormMenuOptionsAttribute } from './embedded_utilities';
import { escapeFormName } from '../editor_utilities';

/**
 * 
 * @param {object} props
 * @param {import("../editor_utilities").CollapsedDataType} props.data
 */
const CommandChip = ({
  data
}) => {
  let meta = data.meta;
  let type = data.value.type;
  let spec = data.value.spec;
  let attributes = data.value.attributes.slice();
  let error = meta.error;
  let baseClassNames = ['embedded-chip-base', 'embedded-chip'];
  let classNames = ['embedded-chip-base-top'];

  let trimLeft, trimRight, trimNone;

  let trim = attributes.find(a => a.name === 'trim' && !a.raw.includes('{'));
  let showTrim = !data.meta.attributesPath?.length;

  // Command is not nested inside other commands
  // Checking for rootTokenStartPosition !== startPosition does not work (sometimes they're equal even when the command is nested)
  const isRootCommand = !meta.id?.includes('-');

  if (trim && showTrim) {
    switch (trim.value) {
    case 'yes':
      classNames.push('trim-left', 'trim-right');
      trimLeft = true;
      trimRight = true;
      break;
    case 'right':
      trimRight = true;
      classNames.push('trim-right');
      break;
    case 'left':
      trimLeft = true;
      classNames.push('trim-left');
      break;
    case 'no':
      trimNone = true;
      classNames.push('trim-none');
      break;
    default:
      break;
    }
  }

  if (type === 'formmenu') {
    addFormMenuOptionsAttribute(attributes);
  }

  let label;
  let shownAttrs = attributes.filter(a => {
    if (a.fillIn) {
      return false;
    }
    if (a.name === 'trim' 
      && (
        (trimLeft || trimRight || trimNone)
        || !showTrim
      )
    ) {
      // we're already showing it with the borders
      return false;
    }
    if (type === 'formmenu') {
      if (a.positional && a.name !== 'options') {
        return false;
      }

      if (a.name === 'default' && !attributes.some(a => a.name === 'values')) {
        return false;
      }
    }
    return true;
  });

  // this sort order should be kept in sync with EmbeddedCommand.js
  function comparePriority(a, b) {
    if (a.positional) {
      return -1;
    }
    if (b.positional) {
      return 1;
    }

    if (spec.named[a.name].priority > spec.named[b.name].priority) {
      return -1;
    }
    if (spec.named[a.name].priority < spec.named[b.name].priority) {
      return 1;
    }
    
    return a.name.localeCompare(b.name);
  }
  shownAttrs.sort(comparePriority);

  if (type.includes('start')) {
    const isSoloFormtoggle = type === 'formtoggle_start' && !data.value.hasMatchingTokens;

    if (!isSoloFormtoggle) {
      classNames.push('embedded-chip-start');
    }
  }
  if (type.includes('else')) {
    classNames.push('embedded-chip-else');
  }
  if (type.includes('end')) {
    classNames.push('embedded-chip-end');
  }

  let parsedSQL = null;
  if (!shownAttrs.length) {
    if (!type.includes('end') && !type.includes('else')) {
      if (type === 'addon' && data.value.icon_url) {
        label = <span style={{ marginRight: 4 }}>{spec.commandName.split('-')[1]}</span>;
      } else {
        label = <span style={{ marginRight: 4 }}>{data.value.spec.commandName}</span>;
      }
    }
  } else {
    let mappedValue = <>{shownAttrs.map(a => {
      let name = a.name;
      let attrType;
      let isStatic;
      if (spec.named[name]) {
        attrType = spec.named[name].type;
        isStatic = spec.named[name].static;
      } else if (spec.positionalDef && spec.positionalDef.name === name) {
        attrType = spec.positionalDef.type;
        isStatic = spec.positionalDef.static;
      }

      /** @type {any} */
      let value;
      /** @type {string} */
      let titleString;
      let isBlock = false;
      if (attrType === 'equation' || attrType === 'lambda') {
        if (['code', 'finish', 'error', 'begin'].includes(name)) {
          isBlock = true;
        }
        value = <span style={{ fontWeight: 500 }}>{highlightFormula(a.raw, { isSQL: type === 'bsql', isBlock: isBlock }, a.nodes)}</span>;
      } else if (attrType === 'bsql') {
        let prefix = '';
        if (type === 'remotedbselect') {
          prefix = 'Read from ';
        } else if (type === 'remotedbinsert') {
          prefix = 'Insert row into ';
        } else if (type === 'remotedbupdate') {
          prefix = 'Update ';
        } else if (type === 'remotedbdelete') {
          prefix = 'Delete row from ';
        }
        try {
          parsedSQL = astSQL(data.value.attributes[0].value, new Environment());
          let tableName;
          if (type === 'remotedbselect') {
            tableName = parsedSQL.info.info.base.info.from.info;
          } else if (type === 'remotedbinsert') {
            tableName = parsedSQL.info.info.table.info;
          } else if (type === 'remotedbupdate') {
            tableName = parsedSQL.info.info.table.info;
          } else if (type === 'remotedbdelete') {
            tableName = parsedSQL.info.info.table.info;
          }
          value = <span>{prefix} <b>{tableName}</b></span>;
        } catch (_) {
          // Invalid SQL query fallback
          value = <span>{prefix} table</span>;
        }
      } else if (attrType === 'database') {
        return null;
      } else if (type === 'site' && name === 'page') {
        value = <URLAvatar page={a.value} size={16} />;
        titleString = a.value;
      } else if (isStatic) {
        value = <span style={{ fontWeight: 500 }}><b>{a.value}</b></span>;
      } else {
        value = <span style={{ fontWeight: 500 }}>{safeHighlightAttribute(a.raw, a.nodes)}</span>;
      }
      return <span key={name} className="list-attribute" data-positional={a.positional} data-attribute-name={name} title={titleString}>
        {!a.positional && <span className="list-attribute-name">{name}</span>}
        <span className="list-attribute-value">{value}</span>
      </span>;
    })}</>;
    if (type === 'addon') {
      if (data.value.icon_url) {
        label = <><span style={{ paddingRight: 8 }}>{spec.commandName.split('-')[1]}</span> {mappedValue}</>;
      } else {
        label = <><span style={{ paddingRight: 8 }}>{spec.commandName}</span> {mappedValue}</>;
      }
    } else {
      label = mappedValue;
    }
  }

  let icon = ICON_MAPPING[type];
  if (type === 'calc') {
    let name = attributes.find(v => v.name === 'name');
    icon = <div 
      className="embedded-chip-icon"
      style={{
        fontWeight: 'bold',
      }}>{name ? (name.raw + ' ') : ''}=</div>;
  } else if (type === 'addon') {
    if (data.value.icon_url) {
      icon = <img
        className="embedded-chip-addon-icon"
        src={data.value.icon_url}
        alt="Command pack icon"
      />;
    }
  }

  let attrCountClass = Math.max(0, Math.min(shownAttrs.length - 1, 3));
  if (attrCountClass) {
    classNames.push(`shown-attrs-gt-${attrCountClass}`);
  }

  if (error) {
    classNames.push('i-error');
  } else {
    classNames.push(`i-${type}`);
  }

  /** @type {{ type?: 'LIST'|'KLIST', name: string, insert: string }[]} */
  let inserts = [];
  if (isRootCommand) {
    // We only create inserts when the command is not nested
    // inside another command
    if (type === 'remotedbselect') {
      let nameAttr = data.value.attributes.find(x => x.name === 'name');
      let hasName = !!nameAttr;
      let multAttr = data.value.attributes.find(x => x.name === 'multiple');
      let isMultiple = multAttr && multAttr.value.toLowerCase() === 'yes';

      if (hasName && isMultiple) {
        inserts.push({
          type: 'LIST',
          name: nameAttr.value,
          insert: escapeFormName(nameAttr.value)
        });
      } else {
        let names = [];
        if (parsedSQL) {
          // @ts-ignore - TS thinks parsedSQL has type never
          if (parsedSQL.type === 'query') {
            // @ts-ignore - TS thinks parsedSQL has type never
            for (let name of parsedSQL.info.info.base.info.columns.map(col => col.info.alias.info)) {
              names.push(name.toLowerCase());
            }
          }
        }

        if (hasName) {
          inserts.push({
            type: isMultiple ? 'LIST' : 'KLIST',
            insert: escapeFormName(nameAttr.value),
            name: nameAttr.value
          });
        } else {
          inserts = names.map(x => ({
            type: isMultiple ? 'LIST' : null,
            name: x,
            insert: escapeFormName(x)
          }));
        }
      }
    }
  }

  return (
    <div className={baseClassNames.join(' ')}>
      <div className={classNames.join(' ')}>
        {trimLeft ? <FirstPage className="embedded-chip-trim-icon"/> : ''}
        <div className="embedded-chip-icon-wrapper">{icon}</div>
        <div className="embedded-chip-label">{label}</div>
        {trimRight ? <LastPage className="embedded-chip-trim-icon"/> : ''}
      </div>
      {(!isCommunityDocsOrBundle() || typeof vi !== 'undefined') && inserts.length ? <div className="embedded-chip-bottom">
        <T variant="caption" color="textSecondary">Use</T>
        {inserts.map(insert => <div
          style={{
            maxWidth: '150px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center'
          }}
          key={insert.insert}
          className="embedded-chip-bottom-chip insertion-chip"
          data-insert={insert.name}
          title={insert.name}
        >
          {insert.type === 'KLIST' ? <KeyedListIcon
            fontSize="small"
            style={{
              opacity: .7,
              width: 20,
              marginRight: 8
            }}
          /> : (insert.type === 'LIST' ? <PositionalListIcon
            fontSize="small"
            style={{
              opacity: .7,
              width: 20,
              marginRight: 8
            }}
          /> : null)} {insert.name} {insert.type && <MoreHorizIcon className="chip-type-more" fontSize="small" style={{
            width: 20,
            marginLeft: 8
          }}/>}
        </div>)}</div> : null}
    </div>
  );
};

export default CommandChip;



function safeHighlightAttribute(txt, commandsData) {
  if (!txt) {
    return txt;
  }
  
  return <b>{highlightAttribute(txt, {
    textPostprocessFunction: (t) => intermediateToNormal(escapedToIntermediate(t))
  }, commandsData)}</b>;
}