import { LinearProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import T from '@mui/material/Typography';
import { useIsMounted, useTypedSelector } from '../../hooks';
import { toast } from '../../message';


/**
 * @typedef {object} StateChangeConfig
 * @property {string} message
 * @property {function(import('@store').RootState): boolean} checkFn
 * @property {string=} onSuccessMessage - shown on success
 * @property {function=} onClose - calls on closed
 * @property {number=} timeout - seconds
 * @property {string=} onTimeoutMessage - shown on timeout
 */


/**
 * @param {object} _props
 */
function StateChangeBase(_props, ref) {
  let [open, setOpen] = useState(false);
  let [config, setConfig] = useState(/** @type {StateChangeConfig} */ (null));
  let isDone = useTypedSelector((state) => config ? config.checkFn(state) : false);
  let isMounted = useIsMounted();
  let timeoutRef = useRef(null);

  function closeWindow() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setOpen(false);
    if (config?.onClose) {
      config.onClose();
    }
    setConfig(null);
  }
  
  useImperativeHandle(ref, () => {
    /**
     * @param {object} config
     * @param {string} config.message
     * @param {function(import('@store').RootState): boolean} config.checkFn
     * @param {function} config.onClose
     * @param {string=} config.onSuccessMessage - shown on success
     * @param {number=} config.timeout - seconds
     * @param {string=} config.onTimeoutMessage - shown on timeout
     */
    function showFn(config) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (config.timeout) {
        timeoutRef.current = setTimeout(() => {
          if (isMounted.current) {
            if (config.onTimeoutMessage) {
              toast(config.onTimeoutMessage, {
                duration: 8000,
                intent: 'danger'
              });
            }
            closeWindow();
          }
        }, config.timeout * 1000);
      }

      setConfig(config);
      setOpen(true);

      return () => {
        closeWindow();
      };
    }

    return {
      show: showFn,
      cancel: () => {
        closeWindow();
      }
    };
  });


  useEffect(() =>{
    if (isDone) {
      if (isMounted.current) {
        if (config.onSuccessMessage) {
          toast(config.onSuccessMessage, {
            duration: 8000,
            intent: 'success'
          });
        }
        closeWindow();
      }
    }
    // eslint-disable-next-line
  }, [isDone]);

  if (!open) {
    return null;
  }
  
  return (
    <Dialog
      open
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <DialogContent style={{
        padding: 48
      }}>
        <LinearProgress
          style={{
            width: '100%',
            minWidth: 300,
            marginBottom: 38
          }}
        />
        <T variant="h6">
          {config.message}
        </T>
      </DialogContent>
    </Dialog>
  );
}


const StateChange = React.memo(forwardRef(StateChangeBase));
export default StateChange;