import React, { useState } from 'react';
import Rating from '@mui/material/Rating';
import { toast } from '../../messageToast';
import { log } from '../../logging/logging';
import T from '@mui/material/Typography';
import { Button, Paper, Slide, TextField } from '@mui/material';
import { sendMessageToClient } from '../../desktop_utilities';
import { getFeedbackLink } from '../../install_extension';
import { APP_TARGET, fullAppName, getIsWebpageEmbedded, isElectronApp } from '../../raw_flags';
import { CURRENT_PLATFORM, isMacApp } from '../../flags';
import { getStoreName } from '../../extension';
import useOnMount from '../../hooks/useOnMount';

let isDesktop = isElectronApp();

/**
 * @param {number} stamp1
 * @param {number} stamp2
 */
let days = (stamp1, stamp2) => (stamp2 - stamp1) / 1000 / (60 * 60 * 24);

/**
 * @param {{ onClose: () => void, onSubmit: (rating: number) => void, }} props
 */
function RatingPrompt({ onClose, onSubmit, }) {
  useOnMount(() => {
    log({ action: 'Show feedback', label: { isEmbed: getIsWebpageEmbedded(), app: APP_TARGET, }, });
  });

  let [rating, setRating] = useState(0);

  return (<div>
    <T paragraph>Please rate {fullAppName}. This will help us improve the product!</T>
    <div style={{ marginBottom: 20, marginTop: 10, textAlign: 'center' }}>
      <Rating
        name="star-Rating"
        size="large"
        value={rating}
        onChange={(_e, nextValue) => {
          if (nextValue === null) {
            return;
          }
          setRating(nextValue);
        }}
      />
    </div>
    <div style={{ textAlign: 'right' }}>
      <Button onClick={onClose} style={{ marginRight: 16, marginBottom: 8 }}>
        I'll do it later</Button>
      <Button disabled={rating === 0} onClick={() => onSubmit(rating)} variant="contained" color="primary" style={{ marginBottom: 8 }}>
        Submit rating
      </Button>
    </div>
  </div>);

}

/**
 * @param {{ hasWebstoreRating: boolean, hasMicrosoftStoreRating: boolean, uid: string, onClose: () => void, createdTimestamp: number, lastShownTimestamp: number, updateRating: (arg: ({ chrome_webstore_rated?: true } | { microsoft_store_rated?: true }) & { rating?: number, rating_timestamp?: number, shown_timestamp?: number, }) => void, }} param0
 * @returns
 */
function InlineFeedbackBase({ hasWebstoreRating, hasMicrosoftStoreRating, uid, onClose, createdTimestamp, lastShownTimestamp, updateRating, }) {
  let [rating, setRating] = useState(0);
  let [feedback, setFeedback] = useState('');
  let [starsSubmitted, setStarsSubmitted] = useState(false);

  /**
   * Using Google forms to log
   * @param {number} rating
   */
  function submitToForms(rating) {
    const source = CURRENT_PLATFORM;
    let url = `https://docs.google.com/forms/d/e/1FAIpQLScCIWp4KXV7wJWOettS9VrBBhOSQgfwTrFz_HRjLF07vLm65Q/formResponse?entry.576130718=${encodeURIComponent(uid)}&entry.1841280578=${rating}&entry.2100466733=${encodeURIComponent(feedback)}&entry.1889510012=${encodeURIComponent(source)}&entry.1277283680=${APP_TARGET}`;

    fetch(url, {
      // Getting CORS error without this in case of
      // AI chat in iframe on external webpage
      mode: 'no-cors',
    }).catch(() => { /** Ignore CORS error */});
  }


  /**
   * @param {number} rating
   */
  function submitStars(rating) {
    log({ category: 'Feedback', action: 'Star rating', label: rating });

    updateRating({
      rating,
      rating_timestamp: Date.now(),
      shown_timestamp: Date.now()
    });
    setRating(rating);
    submitToForms(rating);
    setStarsSubmitted(true);

    const hasRatedDesktop = isDesktop && hasMicrosoftStoreRating;
    const hasRatedWebstore = !isDesktop && hasWebstoreRating;
    if ((hasRatedDesktop || hasRatedWebstore) && rating === 5) {
      // Don't ask to rate in the webstore twice
      thankYou();
      close();
    }
  }

  function submitFeedback() {
    log({ category: 'Feedback', action: 'Star feedback' });

    // don't save blank feedback
    if (feedback.trim()) {
      submitToForms(rating);
    }

    close();
    thankYou();
  }

  function thankYou() {
    toast(`Thank you for your feedback! It will help us improve ${fullAppName}.`, {
      duration: 4000,
      intent: 'success'
    });
  }

  function close() {
    log({ category: 'Feedback', action: 'Close feedback' });

    updateRating({
      shown_timestamp: Date.now()
    });

    onClose();
  }

  function openChromeStore() {
    log({ category: 'Feedback', action: 'Open Chrome Web Store', label: { store: getStoreName() } });

    const feedbackLink = getFeedbackLink();

    window.open(feedbackLink, '_blank');
    close();

    updateRating({
      chrome_webstore_rated: true
    });
  }

  function openMicrosoftStore() {
    log({ category: 'Feedback', action: 'Open Microsoft Store' });

    sendMessageToClient({
      type: 'rate-ms-store'
    });
    close();

    updateRating({
      microsoft_store_rated: true
    });
  }

  function renderFeedback() {
    if (rating < 5) {
      return (<div>
        <T paragraph>Thank you! Please tell us how we can get to five stars:</T>
        <TextField
          value={feedback}
          fullWidth
          multiline
          minRows={2}
          onChange={(event) => setFeedback(event.target.value)}
        />
        <p style={{ textAlign: 'right', marginTop: 10 }}>
          <Button onClick={close} style={{ marginRight: 8 }}>I'll do it later</Button>&nbsp;&nbsp;
          <Button disabled={rating === 0} onClick={submitFeedback} variant="contained" color="primary">Submit feedback</Button>
        </p>
      </div>);
    } else {
      if (isMacApp()) {
        return (<div>
          <T paragraph>Thank you for your feedback!</T>
          <p style={{ textAlign: 'right', marginTop: 20 }}>
            <Button variant="contained" onClick={close} style={{ marginRight: 8 }}>Close</Button>&nbsp;&nbsp;
          </p>
        </div>);
      }
      return (<div>
        <T paragraph>Thank you for your feedback! Please also take 10 seconds to rate us on the {getStoreName()}:</T>
        <p style={{ textAlign: 'right', marginTop: 20 }}>
          <Button onClick={close} style={{ marginRight: 8 }}>Close</Button>&nbsp;&nbsp;
          <Button disabled={rating === 0} onClick={() => {
            if (isDesktop) {
              openMicrosoftStore();
            } else {
              openChromeStore();
            }
          }} variant="contained" color="primary">Rate in {getStoreName()}</Button>
        </p>
      </div>);
    }
  }

  if (!uid) {
    return null;
  }

  // don't show to users in the first 12 days
  if (!createdTimestamp || days(createdTimestamp, Date.now()) < 12) {
    return null;
  }

  // don't show again if we've shown it to them in the past year or so
  if (!starsSubmitted && (lastShownTimestamp && days(lastShownTimestamp, Date.now()) < 480)) {
    return null;
  }

  return <Slide direction="up" in>
    <Paper
      elevation={6}
      style={{
        position: 'fixed',
        right: 10,
        bottom: 10,
        width: 350,
        padding: '18px 24px 12px 24px',
        // higher zIndex to avoid overlap with 
        // ListSubheader component in the dynamic
        // commands list sidebar
        zIndex: 10,
      }}
    >
      {starsSubmitted ? renderFeedback() : <RatingPrompt onClose={close} onSubmit={submitStars} />}
    </Paper>
  </Slide>;
}


const StatelessFeedback = React.memo(InlineFeedbackBase);
export default StatelessFeedback;