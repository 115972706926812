import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ConflictShortcutLink from './ConflictShortcutLink';
import T from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { sync } from '../../Sync/syncer';
import { createSelector } from 'reselect';
import { useTypedSelector } from '../../hooks';

import './conflict_shortcut_tooltip.css';


const conflictShortcutsSelector = createSelector(
  (state) => state.uiState.conflicts,
  (_, shortcut) => shortcut,
  (conflicts, shortcut) => {
    if (conflicts) {
      let items;
      if (shortcut) {
        items = [...(conflicts.get(shortcut.toLocaleLowerCase()) || [])];
      } else {
        items = [...conflicts.keys()];
      }
      items.sort((a,b) => a.length - b.length);

      return items;
    }
  }
);

/**
 * @param {object} props
 * @param {(string|React.ReactElement)=} props.label
 * @param {string=} props.snippetId
 * @param {boolean=} props.inactive
 * @param {import("@mui/material/Tooltip").TooltipProps["placement"]=} props.placement
 * @param {boolean=} props.shadow
 * @param {boolean=} props.forcedOpened
 * @param {(() => void)=} props.onForcedClose
 * @param {any=} props.children
 * @param {number=} props.delay delay to show tooltip in milleseconds
 */
function ConflictShortcutTooltipBase(props) {
  const [moreShowed, setMoreShow] = useState(false);
  const [opened, setOpen] = useState(false);

  const snippetShortcut = useTypedSelector(_store => {
    const snippet = !props.inactive && props.snippetId && sync.getSnippetById(props.snippetId);
    return snippet && snippet.shortcut;
  });

  const shortcuts = useTypedSelector(state => {
    if (props.inactive || (props.snippetId && !snippetShortcut)) {
      return;
    }
    // @ts-ignore
    return conflictShortcutsSelector(state, snippetShortcut);
  });
  const hasShortcuts = shortcuts && shortcuts.length;

  const close = () => setOpen(false);
  const open = () => {
    if (!hasShortcuts) {
      return;
    }
    setOpen(true);
    setMoreShow(false);
  };
  const clickMore = (event) => {
    event.stopPropagation();
    setMoreShow(true);
  };

  const forcedClose = () => {
    props.onForcedClose?.();
  };

  function renderMore() {
    if (shortcuts.length <= 4) {
      return '';
    }
    if (!moreShowed) {
      return (
        <span style={{ cursor: 'pointer' }} onClick={clickMore}>{' '}and more...</span>
      );
    }
    return shortcuts.slice(4).map((shortcut, index) => {
      return (
        <React.Fragment key={index}>
          <ConflictShortcutLink shortcut={shortcut} snippetId={props.snippetId} onClick={close} />
          {(index + 4 < shortcuts.length - 1) && <span>,&nbsp;</span>}
        </React.Fragment>
      );
    });
  }

  return (
    <Tooltip
      placement={props.placement}
      classes={{
        tooltip: 'conflict-shortcut-tooltip',
        popper: 'conflict-shortcut-tooltip-popper',
        arrow: 'conflict-shortcut-tooltip-arrow'
      }}
      open={props.forcedOpened || opened}
      onClose={close}
      onOpen={open}
      enterDelay={props.delay}
      title={
        hasShortcuts ? (
          <>
            <T variant="body2">
              {props.label ? props.label : ''}
              {props.label ? ' ' : ''}
              {shortcuts.slice(0, 4).map((shortcut, index) => {
                return (
                  <React.Fragment key={index}>
                    <ConflictShortcutLink shortcut={shortcut} snippetId={props.snippetId} onClick={close} />
                    {(index < shortcuts.length - 1) && <span>,&nbsp;</span>}
                  </React.Fragment>
                );
              })}
              {renderMore()}
            </T>
            {props.forcedOpened ? (
              <IconButton
                className="conflict-shortcut-tooltip-close"
                size="small"
                onClick={forcedClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ) : null}
          </>
        ) : ''
      }
      arrow
      PopperProps={{
        popperOptions: {
          modifiers: [{
            name: 'offset',
            options: {
              offset: [0, -6]
            }
          }],
        },
      }}
    >
      {props.children}
    </Tooltip>
  );

};


const ConflictShortcutTooltip = React.memo(ConflictShortcutTooltipBase);
export default ConflictShortcutTooltip;

