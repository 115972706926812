import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import React, { useState } from 'react';
import CommandChip from './CommandChip';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

/**
 * 
 * @param {object} props
 * @param {import("../editor_utilities").CollapsedDataType} props.commandData
 * @param {import("../editor_utilities").CollapsedDataType[]} props.dataList
 * @param {(data: import("../editor_utilities").CollapsedDataType) => any} props.onSelect
 */
const CommandNavigator = ({
  commandData,
  dataList,
  onSelect
}) => {
  const [menuAnchor, setMenuAnchor] = useState(/** @type {ELement} */ null);
  const listLength = dataList.length;
  if (listLength <= 1) {
    return null;
  }
  const currentIndex = dataList.indexOf(commandData);
  const goTo = (index) => {
    onSelect(dataList[index]);
  };

  return (
    <>
      {/* Spacer to take up space in parent window */}
      <Box sx={{
        height: 40
      }}></Box>
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,

          border: '1px solid rgb(204, 204, 204)',
          my: 1,
          mx: 2,
          borderRadius: 8,
          backgroundColor: 'white',
          padding: 0.5
        }}
      >
        {listLength > 2 && (
          <NavigationButton
            label="Start command"
            disabled={currentIndex === 0}
            onClick={() => goTo(0)}
            IconComponent={KeyboardDoubleArrowLeftIcon}
          />
        )}
        <NavigationButton
          label="Previous command"
          disabled={currentIndex === 0}
          onClick={() => goTo(currentIndex - 1)}
          IconComponent={KeyboardArrowLeftIcon}
        />
        <Button
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          size="small"
          color="inherit"
          aria-label="Select command"
          endIcon={<ArrowDropDownIcon />}
          onClick={(evt) => setMenuAnchor(evt.currentTarget)}
        >
          {commandData.value.spec.commandName}
        </Button>
        <NavigationButton
          label="Next command"
          disabled={currentIndex === listLength - 1}
          onClick={() => goTo(currentIndex + 1)}
          IconComponent={KeyboardArrowRightIcon}
        />
        {listLength > 2 && (
          <NavigationButton
            label="Last command"
            disabled={currentIndex === listLength - 1}
            onClick={() => goTo(listLength - 1)}
            IconComponent={KeyboardDoubleArrowRightIcon}
          />
        )}
      </Box>
      {menuAnchor && (
        <CommandTreeMenuInnerMemorized
          anchor={menuAnchor}
          commandData={commandData}
          dataList={dataList}
          onSelect={onSelect}
          onClose={setMenuAnchor}
        />
      )}
    </>
  );
};

export default CommandNavigator;


/**
 * 
 * @param {object} props
 * @param {Element} props.anchor
 * @param {import('../editor_utilities').CollapsedDataType} props.commandData
 * @param {import("../editor_utilities").CollapsedDataType[]} props.dataList
 * @param {(data: import("../editor_utilities").CollapsedDataType) => any} props.onSelect
 * @param {Function} props.onClose
 */
const CommandTreeMenuInner = ({
  anchor,
  commandData,
  dataList,
  onSelect,
  onClose
}) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <Menu
      open
      anchorEl={anchor}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={handleClose}
    >
      {dataList.map((d, i) => (
        <MenuItem
          key={i}
          sx={[commandData === d && {
            backgroundColor: 'rgba(0, 188, 212, 0.12)'
          }]}
          onClick={() => {
            onSelect(d);
            handleClose();
          }}
        >
          <Box sx={{
            maxWidth: 150,
            '& > .embedded-chip': {
              width: '100%'
            }
          }}>
            <CommandChip
              data={d}
            />
          </Box>
        </MenuItem>
      ))}
    </Menu>
  );
};
const CommandTreeMenuInnerMemorized = React.memo(CommandTreeMenuInner);

/**
 * 
 * @param {object} props 
 * @param {string} props.label
 * @param {boolean=} props.disabled
 * @param {() => any} props.onClick
 * @param {import('@mui/icons-material').SvgIconComponent} props.IconComponent
 * @returns 
 */
const NavigationButton = ({
  label,
  disabled,
  onClick,
  IconComponent
}) => {
  return (
    <IconButton
      size="small"
      disabled={disabled}
      onClick={onClick}
      aria-label={label}
    >
      <IconComponent fontSize="small" />
    </IconButton>
  );
};