import React, { useEffect, useState, useContext, useRef, useCallback, forwardRef } from 'react';
import { dynamicStyle } from './shared';
import { ShowError, ActionContext } from './SharedComponents';
import { ParseError, AUTO_PREFIX } from '../../snippet_processor/ParserUtils';
import { callFn, toStr, toBool, toLst, equals, createListFromArray } from '../../snippet_processor/Equation';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import moment from 'moment';
import { useIsMounted } from '../../hooks';
import { isAndroid } from '../../flags';
import { getDateTimeConfig } from '../../snippet_processor/Commands';


const formats = {
  'datetime-local': 'YYYY-MM-DD HH:mm',
  'date': 'YYYY-MM-DD',
  'time': 'HH:mm'
};

function validateTimeWithinRange(time, minTime, maxTime, placeholder) {
  const timeMoment = moment(time, 'HH:mm');
  const minTimeMoment = moment(minTime, 'HH:mm');
  const maxTimeMoment = moment(maxTime, 'HH:mm');

  // localized format
  if (minTime) {
    minTime = minTimeMoment.format('LT');
  }
  if (maxTime) {
    maxTime = maxTimeMoment.format('LT');
  }

  if (minTime && maxTime) {
    // By setting a min attribute greater than the max attribute, the valid time range will wrap around midnight to produce a valid time range which crosses midnight
    if (maxTimeMoment.isBefore(minTimeMoment)) {
      if (timeMoment.isBefore(minTimeMoment) && timeMoment.isAfter(maxTimeMoment)) {
        return `Time${placeholder ? ' in ' + placeholder : ''} must be between ${minTime} and ${maxTime}`;
      }
    } else if (timeMoment.isBefore(minTimeMoment) || timeMoment.isAfter(maxTimeMoment)) {
      return `Time${placeholder ? ' in ' + placeholder : ''} must be between ${minTime} and ${maxTime}`;
    }
  } else if (minTime && timeMoment.isBefore(minTimeMoment)) {
    return `Time${placeholder ? ' in ' + placeholder : ''} must be later than ${minTime}`;
  } else if (maxTime && timeMoment.isAfter(maxTimeMoment)) {
    return `Time${placeholder ? ' in ' + placeholder : ''} must be earlier than ${maxTime}`;
  }
}

/** @typedef {Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'|'value'> & { config: ReturnType<typeof getDateTimeConfig>, minDate: any, maxDate: any, onChange: (e: any, raw?: boolean, allowDelay?: boolean, format?: string) => void, value: string, error: string, showToast: import('./SharedComponents').ActionContextType['showToast'], }} DateTimePickerPropsType */

const BrowserDateTimePicker = forwardRef((/** @type {DateTimePickerPropsType} */ props, ref) => {
  const {
    config,
    maxDate,
    minDate,
    value,
    error,
    showToast,
    ...other
  } =  props;
  const currentErrorRemoveFn = useRef(/** @type {() => void} */ (null));

  useEffect(() => {
    if (!showToast) {
      // Not available in quick entry context
      // Form field from the form body will show the notification instead
      return;
    }
    if (config.time) {
      if (currentErrorRemoveFn.current !== null) {
        // Remove the pre-existing error
        currentErrorRemoveFn.current();
        currentErrorRemoveFn.current = null;
      }
      if (error) {
        currentErrorRemoveFn.current = showToast(error, {
          intent: 'danger',
          disableAutoHide: true,
        });
      }
    }
  // If the user closes the toast and selects another invalid time (value change), the toast should be shown again
  }, [config.time, error, showToast, value]);

  if (config.date && config.time) {
    return (
      <input
        type="datetime-local"
        min={minDate ? minDate + ' 00:00' : minDate}
        max={maxDate ? maxDate + ' 23:59' : maxDate}
        value={value}
        // step of a minute
        step={60}
        ref={ref}
        {...other}
      />
    );
  } else if (config.date) {
    return (
      <input
        type="date"
        min={minDate}
        max={maxDate}
        value={value ? value.split(' ')[0] : ''}
        ref={ref}
        {...other}
      />
    );
  } else {
    return (
      <input
        type="time"
        value={value ? value.split(' ')[1] : ''}
        step={60}
        ref={ref}
        {...other}
      />
    );
  }
});


function checkIfShouldShiftFocus(el) {
  if (document.activeElement !== el && !el.closest('.form-sidebar')) {
    if (!document.activeElement || !document.activeElement.closest('.form-sidebar')) {
      return true;
    }
  }
}

/**
 * @param {object} props
 * @param {import("../../snippet_processor/ParseNode").default} props.node
 * @param {object=} props.style
 * @param {import("../../snippet_processor/DataContainer").Environment} props.env
 * @param {boolean=} props.small
 * @param {string=} props.id
 */
function FieldRendererBase(props) {
  let [formatted, setFormatted] = useState({});
  let [valueOverride, setValueOverride] = useState(undefined);
  let isMounted = useIsMounted();

  let info = props.node.info;
  let formInfo = info.formInfo;

  const keys = props.node.display.formKeys || {};
  const isQuickEntry = !!props.small;

  let overrideClearTimer = useRef(null);
  let formattedProcessing = useRef({});

  useEffect(() => {
    formattedProcessing.current = {};
    if (valueOverride !== undefined) {
      // The value override is to prevent the cursor jumping to the end
      // on edit in inputs
      setValueOverride(undefined);
      if (overrideClearTimer.current) {
        clearTimeout(overrideClearTimer.current);
      }
    }
    // eslint-disable-next-line
  }, [props.env]);


  let { onSubmit, onChange, showToast: showToastInner } = useContext(ActionContext);
  const showToast = isQuickEntry ? null : showToastInner;


  let handleKey = useCallback((e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  }, [onSubmit]);


  let value = useCallback(() => {
    if (valueOverride !== undefined) {
      return valueOverride;
    }

    if (!formInfo.name) {
      return undefined;
    }
    let v = props.env.data.get(formInfo.name);
    if (info.type === 'toggle_start') {
      try {
        return toBool(v);
      } catch (_) {
        return !!v; // fallback to js truthiness
      }
    } else {
      if (typeof v === 'boolean') {
        return v ? 'yes' : 'no';
      } else if (v && v.type === 'error') {
        return v.error;
      } else {
        return v || '';
      }
    }
  }, [info.type, formInfo.name, props.env.data, valueOverride]);

  function getFormattedValue(formatType, value, other = null) {
    let formatFn;
    let defFn;
    if (formatType === 'item') {
      defFn = x => x;
      if (!keys.itemformatter) {
        return defFn(value);
      }
      formatFn = itemFormatter;
    } else if (formatType === 'wholemenu') {
      defFn = (items) => {
        return items.map(val => {
          let item = other.find(item => item.source === val);
          if (item) {
            return item.display;
          }
          return getFormattedValue('item', val, other.length + 1);
        }).join(', ');
      };
      if (!keys.formatter) {
        return defFn(value);
      }
      formatFn = menuFormatter;
    } 

    if (formatType + value in formatted) {
      return formatted[formatType + value];
    }
    if (!formattedProcessing.current[formatType + value]) {
      formattedProcessing.current[formatType + value] = true;
      formatFn(value, other).then(x => {
        if (isMounted.current) {
          setFormatted(Object.assign({}, formatted, { [formatType + value]: x }));
        }
      }).catch(err => {
        if (isMounted.current) {
          setFormatted(Object.assign({}, formatted, { [formatType + value]: err.message }));
        }
      });
    }
    return defFn(value);
  }

  let itemFormatter = async (value, index) => {
    if (!keys.itemformatter) {
      return undefined;
    }
    let formatted = await callFn(keys.itemformatter, [value, index + 1], locatedEnv().derivedLocation('itemformatter - ' + index));
    return toStr(formatted);
  };

  let menuFormatter = (items, menuitems) => {
    items = items.map(val => {
      let item = menuitems.find(item => item.source === val);
      if (item) {
        return item.display;
      }
      return getFormattedValue('item', val, menuitems.length + 1);
    });

    return callFn(keys.formatter, [createListFromArray(items)], locatedEnv().derivedLocation('formatter'));
  };


  function locatedEnv() {
    return props.env.derivedLocation('s_command - ' + props.node.position());
  }

  let label = formInfo.label;

  let placeholder = label;
  if (props.small) {
    placeholder = undefined;
  }

  let setValue = useCallback((e, raw = false, allowDelay = false) => {
    let v;
    if (raw) {
      v = e;
    } else if (info.type === 'date') {
      if (e.target.value.trim() === '') {
        v = '';
      } else {
        const format = formats[e.target.type];
        const val = e.target.value.replace('T', ' ');
        let d = moment(val, format);
        if (props.env.config.locale) {
          d = d.locale(props.env.config.locale);
        }
        v = d.format(info.format);
        const updatedVal = moment(v, info.format).locale('en').format(format);
        if (val !== updatedVal) {
          if (!isQuickEntry) {
            showToast(`Value${placeholder ? ' in ' + placeholder : ''} is ${v} using "${info.format}" as format`, {
              intent: 'info',
            });
          }
        }
      } 
    } else if (info.type === 'menu' && (formInfo.multiple !== undefined && toBool(formInfo.multiple))) {
      v = createListFromArray(e.target.value);
    } else if (info.type === 'toggle_start') {
      v = e.target.checked;
    } else {
      v = e.target.value;
    }
    if (formInfo.name) {
      if (info.type === 'single_line' || info.type === 'multi_line' || info.type === 'date') {
        setValueOverride(v);
        if (overrideClearTimer.current) {
          clearTimeout(overrideClearTimer.current);
        }
        overrideClearTimer.current = setTimeout(() => {
          if (isMounted.current) {
            if (valueOverride !== undefined) {
              setValueOverride(undefined);
            }
          }
        }, 20);
      }
      // raw === true is used when setting defaults on single select form field
      const isUserInput = raw === false;
      props.env.updateData(formInfo.name, v, {
        callback: () => onChange(allowDelay, isUserInput),
        onError: null
      });
    }
  }, [formInfo.multiple, formInfo.name, info.format, info.type, isMounted, onChange, placeholder, props.env, showToast, valueOverride, isQuickEntry]);

  let commandStyle = {},
    fieldStyle = Object.assign({ border: 'solid 1px #bbb' }, dynamicStyle);

  function getStyleObject() {
    const MAX_COLUMN_WIDTH = 'min(100ch, 95vw)',
      // keys.width is for backwards compatibility with older form commands
      keysWidth = info.type === 'date' ? undefined : keys.width,
      colWidth = keys.cols !== undefined ? keys.cols + 'ch' : (keysWidth !== undefined ? keysWidth + 'ch' : undefined),
      // Without this limit, the formmenu width becomes horizontally unbounded
      // and unusable when you have hundreds of characters.
      shouldSetMaxWidth = info.type === 'menu',
      // We don't set a max width if user has manually overridden it
      colMaxWidth = shouldSetMaxWidth ? (colWidth !== undefined ? undefined : MAX_COLUMN_WIDTH) : undefined;

    let style = Object.assign({
      width: colWidth,
      maxWidth: colMaxWidth
    }, fieldStyle, { outline: 'none' }, commandStyle);

    if (props.small) {
      style = Object.assign(style, {
        width: '100%'
      });
    } else {
      style = Object.assign(style, {
        boxSizing: 'content-box'
      });
    }
    return style;
  }

  for (let key in keys) {
    if (keys[key].message) {
      return <ShowError msg={keys[key].message} blocking={false} nodeOrAddonId={props.node} />;
    }
  }

  if (!props.small && info.style) {
    commandStyle = info.style;
    if (commandStyle.backgroundColor === 'transparent') {
      delete commandStyle.backgroundColor;
    }
    if (commandStyle.fontSize && info.type !== 'toggle_start') {
      // Otherwise it will multiply by the wrapper
      commandStyle = Object.assign({}, commandStyle, { fontSize: '100%' });
    }
  }

  try {
    let locationString = locatedEnv().locationString();

    if (info.type === 'single_line') {
      const style = getStyleObject();

      return (<input
        placeholder={placeholder}
        style={style}
        value={toStr(value())}
        id={props.id}
        onKeyPress={handleKey}
        onChange={(e) => {
          if (document.activeElement === e.target) {
            if (props.env.config.focusing?.location === locationString) {
              props.env.config.focusing.position = {
                start: e.target.selectionStart,
                end: e.target.selectionEnd
              };
            }
          }

          setValue(e);
        }}
        /**
         * This restores focus if the React `key` prop hierachy changes leding to element
         * creation and destruction . E.g. when changing the contents of this field
         * to `1`:
         * 
         * {if: a > 0}a
         * {endif} {formtext: name=a; default=0}
         *
         */
        ref={(el) => {
          if (el) {
            if (props.env.config.focusing?.location === locationString) {
              if (checkIfShouldShiftFocus(el)) {
                el.focus();
                if (props.env.config.focusing.position) {
                  el.setSelectionRange(props.env.config.focusing.position.start, props.env.config.focusing.position.end);
                }
              }
            }
          }
        }}
        onFocus={() => {
          if (props.env.config.focusing?.location !== locationString) {
            props.env.config.focusing = { location: locationString };
          }
        }}
        onBlur={() => {
          if (props.env.config.focusing?.location === locationString) {
            props.env.config.focusing = {};
          }
        }}
      />);
    } else if (info.type === 'multi_line') {
      const style = getStyleObject();

      return (<textarea
        placeholder={placeholder}
        style={style}
        value={toStr(value())}
        id={props.id}
        rows={keys.rows === undefined ? keys.height : keys.rows}
        onChange={(e) => {
          if (document.activeElement === e.target) {
            if (props.env.config.focusing?.location === locationString) {
              props.env.config.focusing.position = {
                start: e.target.selectionStart,
                end: e.target.selectionEnd
              };
            }
          }
          setValue(e);
        }}
        ref={(el) => {
          if (el) {
            if (props.env.config.focusing?.location === locationString) {
              if (checkIfShouldShiftFocus(el)) {
                el.focus();
                if (props.env.config.focusing.position) {
                  el.setSelectionRange(props.env.config.focusing.position.start, props.env.config.focusing.position.end);
                }
              }
            }
          }
        }}
        onFocus={() => {
          if (props.env.config.focusing?.location !== locationString) {
            props.env.config.focusing = { location: locationString };
          }
        }}
        onBlur={() => {
          if (props.env.config.focusing?.location === locationString) {
            props.env.config.focusing = {};
          }
        }}
      />);
    } else if (info.type === 'date') {
      // Get the formatted date if we have a valid date, otherwise value should be ""
      let val = '';
      let error = '';
      let config = getDateTimeConfig(info.format,  props.env.config.locale);
      if (value()) {
        const valueStr = toStr(value());
        let m = moment(valueStr, [info.format, moment.ISO_8601], props.env.config.locale);
        const errorPrefix = '[Error - ';
        if (valueStr.startsWith(errorPrefix) && !m.isValid()) {
          return <ShowError msg={valueStr.slice(errorPrefix.length, -1)} blocking={false} nodeOrAddonId={props.node} />;
        }
        if (m.isValid()) {
          val = m.locale('en').format('YYYY-MM-DD HH:mm');
          error = validateTimeWithinRange(m.format('HH:mm'), keys.start && keys.start.split(' ')[1], keys.end && keys.end.split(' ')[1], placeholder);
        }
      }

      if (config.date && keys.start && keys.end && moment(keys.start, 'YYYY-MM-DD').isAfter(moment(keys.end, 'YYYY-MM-DD'))) {
        return <ShowError msg="start date should not be after the end date" blocking={false} nodeOrAddonId={props.node} />;
      }

      const style = getStyleObject();
      return <BrowserDateTimePicker
        id={props.id}
        config={config}
        minDate={keys.start && keys.start.split(' ')[0]}
        maxDate={keys.end && keys.end.split(' ')[0]}
        value={val}
        ref={(el) => {
          if (el) {
            if (props.env.config.focusing?.location === locationString) {
              if (checkIfShouldShiftFocus(el)) {
                el.focus();
                // can't set selection for date inputs
              }
            }
          }
        }}
        error={error}
        showToast={showToast}
        onKeyPress={handleKey}
        onChange={setValue}
        placeholder={placeholder}
        style={style}
        onFocus={() => {
          if (props.env.config.focusing?.location !== locationString) {
            props.env.config.focusing = { location: locationString };
          }
        }}
        onBlur={() => {
          if (props.env.config.focusing?.location === locationString) {
            props.env.config.focusing = {};
          }
        }}
      />;
    } else if (info.type === 'menu') {
      let multiple = formInfo.multiple !== undefined && toBool(formInfo.multiple);

      if (info.attributes.keys.itemformatter && !keys.itemformatter) {
        return null;
      }

      const menuitems = keys.menuitems || [];
      let items = menuitems.map(x => x.source);

      let v = value();
      if (!multiple) {
        let hasValue = false;
        for (let i = 0; i < menuitems.length; i++) {
          if (items[i] === v || equals(items[i], v)) {
            v = items[i]; // make sure we have the canonical value matching
            hasValue = true;
            break;
          }
        }
        if (v && !hasValue) {
          items.push(toStr(v));
          hasValue = true;
        }
        if (items.length && !hasValue) {
          // we allow a delay on setting the default value as
          // if you have multiple formmenu's without defaults it can
          // force unnecessary rerenders as they all set their
          // defaults at once
          setValue(items[0], true, true);
        }
      } else {
        if (!Array.isArray(v)) {
          if (v) {
            v = toLst(v).positional;
          } else {
            v = [];
          }
        }
        for (let j = 0; j < v.length; j++) {
          let hasValue = false;
          for (let i = 0; i < items.length; i++) {
            if (items[i] === v[j] || equals(items[i], v[j])) {
              v[j] = items[i];
              hasValue = true;
              break;
            }
          }
          if (!hasValue) {
            items.push(toStr(v[j]));
          }
        }
      };
        
      // on Android the form window displays above the system wide menu option
      // so we want to use the HTML based on in those cases
      if (multiple || isAndroid()) {
        let style = Object.assign(getStyleObject(), {
          borderBottom: 'none',
          fontFamily: 'inherit',
          fontSize: 'inherit',
          borderRadius: '5px',
          outline: undefined
        });

        return (
          <Select
            multiple={multiple}
            displayEmpty
            /* need to render something like "nothing selected" */
            value={v}
            style={style}
            classes={{
              select: 'small-select'
            }}
            id={props.id}
            onChange={e => setValue(e)}
            renderValue={(selected) => {
              let x = multiple ? toStr(getFormattedValue('wholemenu', selected, menuitems)) : selected;
              if (!x || !x.trim()) {
                return <span style={{ opacity:0 }}>_</span>; // placeholder to prevent the menu collapsing
              } else {
                return x;
              }
            }}
            /**
             * TODO: figure out how to persist focus in multi-select across React `key` prop changes (
             * e.g. when the paragraph changes causing a new key and the element to be
             * recreated:
             * 
             * {if: count(z) > 0}z
             * {endif}{formmenu: a; b; c; multiple=yes; name=z}
             *
             * ).
             *
             * Doesn't seem a way to be able to imperatively open the Material UI select
             * which means the way we handle the other components won't work. Even if we
             * could, it would cause animation issues as it would animate opening so we
             * would need to disable that some way so it openned immediately on focus.
             */
            variant="standard"
            MenuProps={{
              // Align the left border of the dropdown with the left border of the menu box
              // This prevents horizontal jumping of formmenu with multiple=yes as the user
              // is selecting items one by one from the dropdown
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'left' }
            }}
          >
            {items.map((val, i) => (
              <MenuItem key={i} value={val} dense disableGutters={multiple} style={{
                paddingRight: 14,
                paddingTop: 0,
                paddingBottom: 0
              }}>
                {multiple && <Checkbox
                  checked={v.indexOf(val) > -1}
                  color="primary"
                />}
                <ListItemText primary={menuitems[i] ? menuitems[i].display : getFormattedValue('item', val, i)} />
              </MenuItem>
            ))}
          </Select>
        );
      } else {
        const style = Object.assign(getStyleObject(), { outline: undefined });

        return (<select
          value={toStr(v)}
          onChange={e => setValue(e)}
          style={style}
          id={props.id}
          onKeyPress={handleKey}
          ref={(el) => {
            if (el) {
              if (props.env.config.focusing?.location === locationString) {
                if (checkIfShouldShiftFocus(el)) {
                  el.focus();
                }
              }
            }
          }}
          onFocus={() => {
            if (props.env.config.focusing?.location !== locationString) {
              props.env.config.focusing = { location: locationString };
            }
          }}
          onBlur={() => {
            if (props.env.config.focusing?.location === locationString) {
              props.env.config.focusing = {};
            }
          }}
        >
          {items.map((v, i) => <option value={v} key={i}>{menuitems[i] ? menuitems[i].display : getFormattedValue('item', v, i)}</option>)}
        </select>);
      }
    } else if (info.type === 'toggle_start') {
      let active = value();
      let style = {};
      if (!props.small) {
        style = Object.assign({}, commandStyle);
      }
        
      let checkbox = <>
        <input
          onKeyPress={handleKey}
          type="checkbox"
          checked={active}
          id={props.id}
          onChange={e => setValue(e)}
          ref={(el) => {
            if (el) {
              if (props.env.config.focusing?.location === locationString) {
                if (checkIfShouldShiftFocus(el)) {
                  el.focus();
                }
              }
            }
          }}
          onFocus={() => {
            if (props.env.config.focusing?.location !== locationString) {
              props.env.config.focusing = { location: locationString };
            }
          }}
          onBlur={() => {
            if (props.env.config.focusing?.location === locationString) {
              props.env.config.focusing = {};
            }
          }}
        />
        
        <span className="virtual-checkbox"></span>
      </>;

      let toggleStyle = {
        display: 'flex',
        alignItems: 'center'
      };

      return (<span className="form-switch" style={style}>
        {(props.small || (formInfo.name && formInfo.name.startsWith(AUTO_PREFIX))) ? <span style={toggleStyle}><label>{checkbox}</label></span> : <label style={toggleStyle}>{checkbox} <span className="formtoggle-name">{label}</span></label>}
      </span>);
    } else {
      return <span>[Not yet implemented]</span>;
    }
  } catch (error) {
    if (error instanceof ParseError) {
      return <ShowError msg={error.message} blocking={false} nodeOrAddonId={props.node} />;
    }
    throw error;
  }
}


const FieldRenderer = React.memo(FieldRendererBase);
export default FieldRenderer;