/**
 * https://github.com/quilljs/quill/issues/3300
 * 
 * 
 * Google input tools does trigger save/change in Quill.
 * Which also corrupts table operations.
 * 
 * copied from https://github.com/quilljs/quill/pull/3301/files
 */

import ScrollBlot from 'quill/blots/scroll';

function batchEndOverride() {
  if (!this.batch) {
    return;
  }
  const mutations = /** @type {MutationRecord[]} */ (this.batch);
  this.batch = false;

  // Fix for Google Input Tools.
  // Had to check for event type, as backspace on start of the line reposition selection.
  // @ts-ignore `this` does not actually refer to scroll. So types fail.
  this.update(window.event?.type === 'compositionend' && !mutations.length ? undefined : mutations);
}

export function overrideScroll() {
  if (!ScrollBlot.prototype.batchEnd) {
    // @ts-expect-error originalBatchEnd does not exist
    ScrollBlot.prototype.originalBatchEnd = ScrollBlot.prototype.batchEnd;
  }
  ScrollBlot.prototype.batchEnd = batchEndOverride;
}