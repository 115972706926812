import {
  CircularProgress,
  MenuItem,
  Typography as T
} from '@mui/material';
import React, { useState } from 'react';
import { showConfirm, toast } from '../../message';
import { changeBillingFrequency, checkChangeBillingFrequency } from '../../bapi';
import { log } from '../../logging/logging';

/**
 * 
 * @param {object} props 
 * @param {object} props.entity
 * @param {() => any} props.onUpdate
 * @param {() => any} props.onClose
 * @param {React.Ref<any>} ref 
 * @returns 
 */
const MonthlyBillingSwitchButtonBase = ({
  entity,
  onUpdate,
  onClose
}, ref) => {
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    onClose();
    setLoading(false);
  };
  const data = {
    ...(entity || {}),
    blaze_billing: 'monthly'  
  };
  const onClick = async () => {
    try {
      setLoading(true);
      const { data: invoice } = await checkChangeBillingFrequency(data);
      showConfirm({
        contents: <>
          <T paragraph variant="h5">Change billing to monthly</T>
          {invoice.balance > 0 && (<>
            <T paragraph>Upon changing your billing period, you will receive a non-refundable account credit in the amount of ${invoice.balance}. This credit will be applied to future invoices.</T>
            <T paragraph>New charges will be incurred once these credits have been exhausted.</T>
          </>)}
          {invoice.due > 0 && (
            <T paragraph>Upon changing your billing period, you will be charged the amount of ${invoice.due} immediately.</T>
          )}
        </>,
        confirm: 'I understand',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Switch to monthly',
        onConfirm: changeBilling,
        onCancel: handleClose
      });
    } catch (err) {
      toast('Could not change billing to monthly. ' + ((err && err.message) || ''), {
        duration: 8000,
        intent: 'danger'
      });
      log({
        category: 'Billing',
        action: 'Could not change to monthly'
      });
      handleClose();
    }
  };

  const changeBilling = async () => {
    try {
      await changeBillingFrequency(data);
      await onUpdate();
      toast('You have been changed to monthly billing.', {
        duration: 4000,
        intent: 'success'
      });
      log({
        category: 'Billing',
        action: 'Changed to monthly'
      });
    } catch (err) {
      toast('Could not change billing to monthly. ' + ((err && err.message) || ''), {
        duration: 8000,
        intent: 'danger'
      });
      log({
        category: 'Billing',
        action: 'Could not change to monthly'
      });

    } finally {
      handleClose();
    }
  };

  return (
    <MenuItem
      ref={ref}
      onClick={onClick}
    >
      <span>Change to monthly billing</span>
      {loading &&  (
        <CircularProgress
          size={20}
          sx={{
            ml: 2
          }}
        />
      )}
    </MenuItem>
  );
};
const MonthlyBillingSwitchButton = React.forwardRef(MonthlyBillingSwitchButtonBase);
export default MonthlyBillingSwitchButton;