import React, { useEffect, useState } from 'react';
import { Box, Button, Card, DialogTitle, Step, StepButton, StepLabel, Stepper, Typography as T } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import AuthComponent from '../Auth/AuthComponent';
import { StatelessTopBar } from '../App/TopBar';
import { useQuery, useTypedSelector } from '../../hooks';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import { log } from '../../logging/logging';
import { PRICES } from '../Version/version_utilities';
import BillingCycleSelect from './BillingCycleSelect';
import BusinessPlanSeatSelection from './BusinessPlanSeatSelection';

export function NewUserUpgrade() {
  const query = useQuery();
  let { pathname } = useLocation();
  const { upgrade } = /** @type {{upgrade: "upgrade_business"|"upgrade_pro"}} */ (useParams());

  let [quantity, setQuantity] = useState(query.get('seats') ? Number(query.get('seats')) : 1);
  let [billing, setBilling] = useState(/** @type {"monthly"|"annually"} */ (query.get('billing') || 'annually'));
  let [currentView, setCurrentView] = useState('seats');
  let isAuthenticated = useTypedSelector(store => store.userState.isLoaded ? !!store.userState.uid : null);
  const { replace } = useHistory();

  let businessTotalPrice =  (PRICES['business'][billing] * quantity).toFixed(2);
  let proTotalPrice =  billing === 'annually' ? PRICES['pro']['annually']['regular'] : PRICES['pro']['monthly'];

  const isBusinessUpgrade = upgrade === 'upgrade_business';
  const isProUpgrade = upgrade === 'upgrade_pro';

  const proAnnualPriceDelta = (PRICES['pro']['deltaMonthly'] * 12).toFixed(2);

  useEffect(() => {
    if (isAuthenticated) {
      if (isBusinessUpgrade) {
        replace(`/pro/${billing}/upgrade-business?seats=${quantity}`);
      } else {
        replace(`/pro/${billing}/upgrade-pro`);
      }
    } else if (isAuthenticated === false) {
      log({ category: 'New User Account Upgrade', action: 'Started flow' });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (isBusinessUpgrade) {
      replace(`${pathname}?billing=${billing}&seats=${quantity}`);
    } else {
      replace(`${pathname}?billing=${billing}`);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billing, quantity]);


  // Mimic a Dialog using absolute + card. Don't use a Dialog directly because we want to add stuff outside of it too
  return <Box
    sx={{
      background: 'hsl(0 0% 99% / 1)',
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      top: 0,
      left: 0
    }}
  >
    <StatelessTopBar appType="TEXT" hideLinks />

    {/* Prevents flashing if a logged in user arrives here */}
    {isAuthenticated === null &&
      <div style={{ paddingTop: '15vh', marginBottom: 30, justifyContent: 'center', display: 'flex' }}>
        <CircularProgress size={150} thickness={1.9}/>
      </div>}

    {isAuthenticated === false &&
      <Box sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // minus topbar
        marginTop: '-48px',
        padding: 1
      }}>
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '0px min(100%, 450px) 0px',
            md: '100px 450px 450px',
            lg: 'repeat(3, 450px)',
          },
          justifyContent: 'center',
          gap: '44px',
          '& .auth-component': {
            padding: '24px 36px !important'
          }
        }}>

          {/*Hold the grid column space */}
          <div></div>

          <Card elevation={8} sx={{ width: '100%', }}>
            <DialogTitle
              sx={{
                position: 'relative',
                pr: 5,
              }}
            >
              <span>Upgrade to Text Blaze {isBusinessUpgrade ? 'Business' : 'Pro'}</span>
            </DialogTitle>

            <DialogContent
              sx={{
                mt: 2,
                pb: currentView === 'auth' ? 0 : undefined
              }}
            >
              <Stepper
                nonLinear
                alternativeLabel
                sx={{
                  mb: currentView === 'auth' ? 3 : 5,
                }}
                activeStep={currentView === 'seats' ? 0 : 1}
              >
                <Step>
                  <StepButton
                    onClick={() => {
                      setCurrentView('seats');
                    }}
                  >
                    <StepLabel >{isBusinessUpgrade ? 'Seat selection' : 'Billing options'}</StepLabel>
                  </StepButton>
                </Step>
                <Step>
                  <StepButton
                    onClick={() => {
                      setCurrentView('auth');
                    }}
                  >
                    <StepLabel >Account creation</StepLabel>
                  </StepButton>
                </Step>
                <Step disabled>
                  <StepButton
                    onClick={() => {}}
                  >
                    <StepLabel>Payment</StepLabel>
                  </StepButton>
                </Step>
              </Stepper>

              {currentView === 'seats' && isBusinessUpgrade && <BusinessPlanSeatSelection
                quantity={quantity}
                onChange={setQuantity}
                totalPrice={businessTotalPrice}
                billing={billing}
                continueText="Continue to account creation"
                onNext={() => {
                  setCurrentView('auth');

                }}
                onBillingChange={setBilling}
              />}

              {currentView === 'seats' && isProUpgrade && <div>
                <T fontSize={18} mb={5}>
                  Text Blaze Pro is <b>${proTotalPrice} USD</b>
                  {billing === 'annually' ? ' billed annually' : ' per month'}.
                </T>

                <div>
                  <BillingCycleSelect onBillingChange={setBilling} annualPriceDelta={proAnnualPriceDelta} billing={billing} />

                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => setCurrentView('auth')}
                    sx={{
                      mt: billing === 'annually' ? 5 : 2,
                      mb: 1
                    }}
                  >
                    Continue to account creation
                  </Button>
                </div>
              </div>}

            </DialogContent>

            {currentView === 'auth' && <AuthComponent signUpStart />}

          </Card>

          {isBusinessUpgrade && <Box
            mt="16px"
            sx={{ display: {
              xs: 'none',
              md: 'block'
            }
            }}>
            <T fontSize={16} fontWeight={500} pb={1}>The Business plan includes:</T>

            <Box component="ul"
              sx={{
                listStyle: 'none',
                padding: '0',
                fontSize: 14,
                color: '#363636',

                '& li': {
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mb: '18px',
                },

                '& svg': {
                  color: 'green',
                  width: '20px',
                  height: '20px',
                }
              }}
            >
              <li style={{ fontWeight: '500' }}><CheckIcon/> Everything included in Pro</li>
              <li><CheckIcon/> User management and roles</li>
              <li><CheckIcon/> Collaboration features</li>
              <li><CheckIcon/> Teams and default folders</li>
              <li><CheckIcon/> Unlimited snippet sharing</li>
              <li><CheckIcon/> Org-wide usage reporting and analytics</li>
              <li><CheckIcon/> User properties</li>
            </Box>

            <T fontSize={16} fontWeight={500} pb={2} pt={2}>Questions before you buy?</T>
            <a href="mailto:sales@blaze.today?subject=Upgrading to Text Blaze Business">
              <Button sx={{ mr: 1, ml: '-8px' }}>Contact sales</Button>
            </a>
            <a
              href="https://calendar.app.google/Jw7crSMyAsVeNark8"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Schedule a demo</Button>
            </a>
          </Box>}

          {isProUpgrade && <Box
            mt="16px"
            sx={{ display: {
              xs: 'none',
              md: 'block'
            }
            }}>
            <T fontSize={16} fontWeight={500} pb={1}>The Pro plan includes:</T>

            <Box component="ul"
              sx={{
                listStyle: 'none',
                padding: '0',
                fontSize: 14,
                color: '#363636',

                '& li': {
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mb: '18px',
                },

                '& svg': {
                  color: 'green',
                  width: '20px',
                  height: '20px',
                }
              }}
            >
              <li style={{ fontWeight: '500' }}><CheckIcon/> Everything included in Free</li>
              <li><CheckIcon/> Double the snippet sharing</li>
              <li><CheckIcon/> Full access to forms</li>
              <li><CheckIcon/> Personal usage reporting</li>
              <li><CheckIcon/> Images and tables in snippets</li>
              <li><CheckIcon/> Dynamic rules (if/then) in snippets </li>
              <li><CheckIcon/> Use and update Data Blaze data in snippets</li>
            </Box>

            <T fontSize={16} fontWeight={500} pb={2} pt={2}>Questions before you buy?</T>
            <a href="mailto:support@blaze.today?subject=Upgrading to Text Blaze Pro">
              <Button sx={{ mr: 1, ml: '-8px' }}>Contact support</Button>
            </a>
          </Box>}
        </Box>
      </Box>}
  </Box>;
}