/** @type {Partial<{ snackbar: import('./components/Dialog/MessageDisplayInner').ToastRefType, deleteDialog: any, confirmDialog: any, promptDialog: any, destructiveDialog: any, simpleDialog: any, sidebarPanel: any, stateChange: any, callout: any }>} */
export let messageCenter = {};

/**
 * Display a toast message.
 * 
 * @param {string|React.ReactElement} msg 
 * @param {import('./components/Dialog/MessageDisplayInner').MessageConfigType} [config]
 * @returns {number}
 */
export function toast(msg, config) {
  return messageCenter.snackbar.show(msg, config);
}