import { getIdToken, getAuth, } from '../../firebase_shared_driver';
import { sendToExtension } from '../../extension';
import { getDataFromClient } from '../../desktop_shared_utils';
import { isElectronApp } from '../../raw_flags';

/**
 * @typedef {{ data: string, provider: 'JWT', }} JWTProviderTokenResponse
 * 
 * @type {Promise<JWTProviderTokenResponse>} 
 */
let tokenPromise = null;

/**
 * promise is a sendToExtension call with JWT provider token request
 * It is a parameter here because it is either set by extension_embedded_two.js
 * or by this script. The first script cannot import from this file, which is why it is set on the window
 * 
 * @param {Promise<JWTProviderTokenResponse>} promise 
 * @returns {void}
 */
export function setTokenPromise(promise) {
  tokenPromise = promise;
}

async function getCredentialsDashboard() {
  // Used for AI Write in dashboard
  const user = getAuth()?.currentUser;
  if (user) {
    return await getIdToken(user);
  }
  throw new Error('No user found');
}

/**
 * Gets the object payload from a JWT token string
 * without validating its signature
 * https://stackoverflow.com/a/38552302/2181238
 * @param {string} token 
 * @returns {object}
 */
function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}

/**
 * This function is guaranteed to return a non-expired
 * token that can be immediately sent to the backend
 * 
 * @returns {Promise<string>}
 */
export async function getCredentials() {
  // Dashboard is connected to live Firestore
  // which guarantees token does not expire in next five minutes
  // So we can return its token directly
  if (!tokenPromise) {
    return getCredentialsDashboard();
  }

  // Otherwise, in case of extension AI snippets, the same token is persisted for the entire duration
  // of the session. So before every backend request, check if the token is about to expire
  let extensionToken = await getExtensionToken();
  const tokenObj = parseJwt(extensionToken);
  const timeNowSeconds = Math.floor(Date.now() / 1000);
  const timeExpirySeconds = tokenObj.exp;
  const minutesBeforeExpiry = (timeExpirySeconds - timeNowSeconds) / 60;

  if (minutesBeforeExpiry <= 1) {
    // If the token has already expired or is about to expire in next one minute,
    // fetch a new token from the extension and use it
    refreshToken();
    extensionToken = await getExtensionToken();
  } else if (minutesBeforeExpiry <= 5) {
    // Token will expire within next five minutes but after a minute
    // Fetch a new token in parallel,
    // and use the existing token for this request
    refreshToken();
  }

  return extensionToken;
}

function refreshToken() {
  setTokenPromise(isElectronApp() ? getDataFromClient({ type: 'get_credentials', data: 'JWT', }) : sendToExtension({ type: 'get_credentials', data: 'JWT', }));
}

/**
 * @returns {Promise<string>}
 */
export async function getExtensionToken() {
  const credentialsResponse = await tokenPromise;
  if (!credentialsResponse) {
    throw new Error('No response from extension for get credentials');
  }
  const { provider, data: credentials } = credentialsResponse;
  if (!credentials) {
    throw new Error('Empty token from extension');
  }
  if (provider === 'JWT') {
    // new extension version
    return credentials;
  }
  throw new Error('Invalid provider from extension: ' + provider);
}