import { httpsCallable } from 'firebase/functions';
import { getTBFunctions } from './firebase_utilities';
import { toast } from './message.jsx';

export const SUPPORTED_IMAGE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'gif'];

/**
 * @type {import('firebase/functions').HttpsCallable<{ name: string, type: string, image: string }, { url: string }>}
 */
export const uploadImage = httpsCallable(getTBFunctions(), 'uploadImage');

export function arrayBufferToString(buffer) {
  let arr = new Uint8Array(buffer);
  let str = '';
  for (let i = 0; i < arr.length; ++i) {
    str += String.fromCharCode(arr[i]);
  }
  return str;
}

export function imageMimeTypeToExtension(mime) {
  let extension = mime.split(';')[0].trim().toLowerCase();
  extension = extension.substring(extension.indexOf('/') + 1);
  if (extension === 'jpeg') {
    return 'jpg';
  }
  if (extension === 'svg+xml') {
    return 'svg';
  }
  return extension;
}

/**
 * Gets the image dimensions. 
 * @param {string} imageUrl 
 * @returns {Promise<{ width: number, height: number, image: HTMLImageElement }>}
 */
function getImageDimensions (imageUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      resolve({
        height: img.naturalHeight,
        width: img.naturalWidth,
        image: img
      });
    };
    img.onerror = (err) => {
      reject(err);
    };
  });
};

/**
 * Restrict the image if the image is not within it.
 * @param {File} imageFile 
 * @param {number} restrictedWidth 
 * @param {number} restrictedHeight 
 */
export async function restrictImage (imageFile, restrictedWidth, restrictedHeight) {
  const imageUrl = await getBase64(imageFile);
  const { width, height, image: imageEl } = await getImageDimensions(imageUrl);
  let exceeded = false;
  if (width > restrictedWidth) {
    exceeded = true;
  } else if (height > restrictedHeight) {
    exceeded = true;
  }
  if (!exceeded) {
    return {
      url: imageUrl,
      width,
      height
    };
  }
  let newWidth = 0,
    newHeight = 0;
  if (width > height) {
    newWidth = restrictedWidth;
    newHeight = newWidth * (height / width);
  } else {
    newHeight = restrictedHeight;
    newWidth = newHeight * (width / height);
  }


  // Dynamically create a canvas element
  const canvas = document.createElement('canvas');

  // Set the canvas dimensions to the restricted dimensions
  canvas.width = newWidth;
  canvas.height = newHeight;

  const ctx = canvas.getContext('2d');

  // Actual resizing
  ctx.drawImage(imageEl, 0, 0, newWidth, newHeight);

  // Show resized image in preview element
  return {
    url: canvas.toDataURL(imageFile.type),
    width: newWidth,
    height: newHeight
  };
};


/**
 * Converts file to base64 image url
 * @param {File} file 
 */
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};

/**
 * Converts Base64 Data URL to Array Buffer to upload.
 * @param {string} dataURL 
 * @returns 
 */
export function dataURLToArrayBuffer(dataURL) {
  // Decode the Data URL to a byte string
  const byteString = atob(dataURL.split(',')[1]);
  
  // Create an ArrayBuffer with the same length as the byte string
  const arrayBuffer = new ArrayBuffer(byteString.length);
  
  // Create a typed array view (Uint8Array) on the ArrayBuffer
  const uint8Array = new Uint8Array(arrayBuffer);
  
  // Copy the byte string to the Uint8Array
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  
  return arrayBuffer;
};

/**
 *
 * @param {File} file
 * @param {string} type
 */
export async function uploadSavedImage(file, type) {
  let imageUrl = '';

  try {
    const uploadResult = await uploadImage({
      name: file.name,
      type,
      image: arrayBufferToString(await file.arrayBuffer()),
    });

    imageUrl = uploadResult?.data?.url;

    if (!imageUrl) {
      throw new Error('An error occurred while uploading the image');
    }
  } catch (err) {
    toast(err.message, {
      intent: 'danger'
    });
  }

  return imageUrl;
}

/**
 *
 * @param {import("react").DragEvent} ev
 */
export const getFiles = (ev) => {
  /**
   * @type {File[]}
   */
  let files = [];
  if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    [...ev.dataTransfer.items].forEach((item, i) => {
      // If dropped items aren't files, reject them
      if (item.kind === 'file') {
        const file = item.getAsFile();
        files.push(file);
      }
    });
  } else {
    // Use DataTransfer interface to access the file(s)
    files = [...ev.dataTransfer.files].filter(file => isImage(file,  ['image/jpeg', 'image/png', 'image/gif']));
  }
  return files;
};

/**
 * @param {File} file
 * @param {string[]=} accepts
 */
export const isImage = (file, accepts = ['image/jpeg', 'image/png']) => {
  if (!file) {
    return false;
  }
  return accepts.includes(file.type);
};
