import React from 'react';
import { Environment } from '../../snippet_processor/DataContainer';
import { lex, lexSQLWithCommands, pushBlockTokens, pushRepeatTokens } from '../../snippet_processor/Lexer';
import { ast } from '../../snippet_processor/Equation';
import { getCommandsFromAttribute } from '../../snippet_processor/ParserUtils';
import { isInvalidAttribute } from '../SnippetEditor/EmbeddedCommand/embedded_utilities';
import './syntax_highlighters.css';
import { astSQL } from '../../snippet_processor/SQL';
import CommandChip from '../SnippetEditor/EmbeddedCommand/CommandChip';


/**
 * @param {string} code 
 * @param {{isIterator?: boolean, isBlock?:boolean, isSQL?: boolean}=} config 
 * @param {ReturnType<import('../SnippetEditor/editor_utilities').getCollapsedData>[]} commandsData 
 * 
 * @return {React.ReactElement}
 */
export function highlightFormula(code, config = {}, commandsData = null) {
  let { isIterator, isBlock, isSQL } = config;
  let lexed;
  if (isSQL) {
    lexed = lexSQLWithCommands(code, new Environment({}, { stage: 'preview' }), { perserveStartWS: true });
  } else {
    lexed = lex(code, new Environment({}, { stage: 'preview' }), { perserveStartWS: true, lexComment: isBlock, skipCleanTokens: true });
  }
  let { tokens, termination, position } = lexed;

  let error = null;
  
  let invalidToken = tokens.find(t => t.type === 'INVALID');
  if (termination !== 'END_STRING') {  
    // can happen if you have a random semi-colon in the formula
    error = {
      position,
      message: 'invalid character'
    };
  } else if (invalidToken) {
    error = {
      position: invalidToken.position,
      message: 'invalid character'
    };
  } else { 
    try {
      if (isBlock) {
        pushBlockTokens(tokens);
      }
      if (isIterator) {
        pushRepeatTokens(tokens);
      }
      if (isSQL) {
        astSQL(tokens, new Environment());
      } else {
        ast(tokens, new Environment());
      }
    } catch (err) {
      error = {
        position: err.errorPosition,
        message: err.message
      };
    }
  }

  if (isBlock) {
    // remove first and last parts block/endblock which was added as part of pushBlockTokens
    tokens = tokens.filter(x => x.position !==  -100);
  }

  let parts = [],
    partsInWrapper = [];
  const streamWrapperParts = () => {
    if (partsInWrapper.length) {
      parts.push(<span className="tokens-wrapper" key={parts.length}>
        {partsInWrapper}
      </span>);

      partsInWrapper = [];
    }
  };
  let textPosition = 0;
  for (let i = 0; i < tokens.length; i++) {
    let {
      type,
      position,
      source,
      isSQLKeyword
    } = tokens[i];
    let className = 'default';
    if (type === 'NUMBER' || type === 'yes' || type === 'no') {
      className = 'constant';
    } else if (isSQLKeyword) {
      className = 'sql-keyword';
    } else if (type === 'IDENTIFIER') {
      className = 'identifier';
    } else if (type === 'STRING') {
      className = 'string';
    } else if (type === 'COMMAND') {
      className = 'command';
    } else if (type === 'WS_B') {
      // Don't show for last token
      if (i === tokens.length - 1) {
        continue;
      }
      className = 'linebreaks';
      source = '↩';
    }

    if (position > textPosition) {
      partsInWrapper.push(<span key={'comment-' + i} className="token-comment">{tagError(code.substring(textPosition, position), textPosition, error)}</span>);
    }
    if (className === 'command' && commandsData && commandsData.some(c => c.meta.startPosition === position)) {
      const commandData = commandsData.find(c => c.meta.startPosition === position);
      streamWrapperParts();
      parts.push(<span
        key={i}
        data-token-id={commandData.meta.id}
        data-value={JSON.stringify(commandData)}
        className="small-chip"
      >
        <CommandChip
          data={commandData}
        />
      </span>);
    } else {
      partsInWrapper.push(<span key={i} className={'token-' + className}>{tagError(source, position, error)}</span>);
    }
    textPosition = position + source.length;
  }
  if (textPosition < code.length) {
    partsInWrapper.push(<span key="comment-last" className="token-comment">{tagError(code.substring(textPosition), textPosition, error)}</span>);
  }
  streamWrapperParts();
  if (termination !== 'END_STRING') {
    parts.push(<span key="remainder">{tagError(code.slice(position - 1), position, error)}</span>);
  } 

  return <span
    style={!!commandsData?.length ? {
      display: 'flex',
      alignItems: 'center',
      gap: 3
    } : null}
  >{parts}</span>;
}


/**
 * @param {string} code 
 * @param {{isIterator?: boolean, isBlock?: boolean, isSQL?: boolean}=} config 
 */
export function highlightFormulaOps(code, config = {}) {
  let { isIterator, isBlock, isSQL } = config;
  let lexed;
  if (isSQL) {
    lexed = lexSQLWithCommands(code, new Environment({}, { stage: 'preview' }), { perserveStartWS: true });
  } else {
    lexed = lex(code, new Environment({}, { stage: 'preview' }), { perserveStartWS: true, lexComment: isBlock, skipCleanTokens: true });
  }
  
  let { tokens, termination, position } = lexed;

  let error = null;
  
  let invalidToken = tokens.find(t => t.type === 'INVALID');
  if (termination !== 'END_STRING') {  
    // can happen if you have a random semi-colon in the formula
    error = {
      position,
      message: 'invalid character'
    };
  } else if (invalidToken) {
    error = {
      position: invalidToken.position,
      message: 'invalid character'
    };
  } else { 
    try {
      if (isBlock) {
        pushBlockTokens(tokens);
      }
      if (isIterator) {
        pushRepeatTokens(tokens);
      }
      if (isSQL) {
        astSQL(tokens, new Environment());
      } else {
        ast(tokens, new Environment());
      }
    } catch (err) {
      error = {
        position: err.errorPosition,
        message: err.message
      };
    }
  }

  if (isBlock) {
    // remove first and last parts block/endblock which was added as part of pushBlockTokens
    tokens = tokens.filter(x => x.position !==  -100);
  }

  /**
   * @type {DeltaType['ops']}
   */
  let ops = [];
  let textPosition = 0;
  for (let i = 0; i < tokens.length; i++) {
    const {
      type,
      position,
      source,
      isSQLKeyword
    } = tokens[i];
    let className = 'default';
    if (type === 'NUMBER' || type === 'yes' || type === 'no') {
      className = 'constant';
    } else if (isSQLKeyword) {
      className = 'sql-keyword';
    } else if (type === 'IDENTIFIER') {
      className = 'identifier';
    } else if (type === 'STRING') {
      className = 'string';
    } else if (type === 'COMMAND') {
      className = 'command';
    }
    if (position > textPosition) {
      const newOps = tagErrorOps(code.substring(textPosition, position), textPosition, 'comment', error);
      ops = ops.concat(newOps);
    }
    const newOps = tagErrorOps(source, position, className, error);
    ops = ops.concat(newOps);
    textPosition = position + source.length;
  }

  if (textPosition < code.length) {
    const newOps = tagErrorOps(code.substring(textPosition), textPosition, 'comment', error);
    ops = ops.concat(newOps);
  }
  
  if (termination !== 'END_STRING') {
    const newOps = tagErrorOps(code.slice(position - 1), position, undefined, error);
    ops = ops.concat(newOps);
  }

  return ops;
}


/**
 * @param {string} txt 
 * @param {number} txtStart 
 * @param {{position: number, message: string}} error
 * @param {function} postProcess
 * 
 * @return {React.ReactElement|string}
 */
function tagError(txt, txtStart, error, postProcess = (t) => t) {
  if (error === null || error === undefined) {
    return postProcess(txt);
  }

  if (txtStart <= error.position && error.position <= txtStart + txt.length) {
    return <>{postProcess(txt.slice(0, error.position - txtStart))}<span
      className="syntax-error"
    >{postProcess(txt.slice(error.position - txtStart, error.position - txtStart + 1))}</span>{postProcess(txt.slice(error.position - txtStart + 1))}</>;
  }

  return txt;
}

/**
 * @param {string} txt 
 * @param {number} txtStart 
 * @param {string} tokenType 
 * @param {{position: number, message: string}} error
 * 
 * @return {DeltaType['ops']}
 */
function tagErrorOps(txt, txtStart, tokenType, error) {
  let commonOp = {
    attributes: {
      token: tokenType
    }
  };
  if (error === null || error === undefined) {
    return [{
      ...commonOp,
      insert: txt
    }];
  }

  if (txtStart <= error.position && error.position <= txtStart + txt.length) {
    return [{
      ...commonOp,
      insert: txt.slice(0, error.position - txtStart)
    }, {
      attributes: {
        token: 'error'
      },
      insert: txt.slice(error.position - txtStart, error.position - txtStart + 1)
    }, {
      ...commonOp,
      insert: txt.slice(error.position - txtStart + 1)
    }];
  }

  return [{
    ...commonOp,
    insert: txt
  }];
}


/**
 * @param {string} txt 
 * @param {{isListContext?: boolean, textPostprocessFunction?: function}=} config 
 * 
 * @return {React.ReactElement}
 */
export function highlightAttribute(txt, config = {}, commandsData = []) {
  let commandPositions = getCommandsFromAttribute(txt);

  let isInvalid = isInvalidAttribute(txt, {
    isListContext: config.isListContext
  });

  let postProcess = config.textPostprocessFunction || ((t) => t);

  let parts = [];
  let partsInWrapper = [];
  let cursor = 0;
  const streamWrapperParts = () => {
    if (partsInWrapper.length) {
      parts.push(<span className="tokens-wrapper" key={parts.length}>
        {partsInWrapper}
      </span>);

      partsInWrapper = [];
    }
  };

  for (let i = 0; i < commandPositions.length; i++) {
    let position = commandPositions[i];
    if (cursor < position.start) {
      partsInWrapper.push(<span key={'part' + position.start}>{tagError(txt.slice(cursor, position.start), cursor, isInvalid, postProcess)}</span>);
    }
    const commandData = commandsData?.find(c => 
      c.meta.startPosition === position.start
      && c.meta.endPosition === position.end
    );
    if (commandData) {
      streamWrapperParts();
      parts.push(<span
        key={i}
        data-token-id={commandData.meta.id}
        data-value={JSON.stringify(commandData)}
        className="small-chip"
      >
        <CommandChip
          data={commandData}
        />
      </span>);
    } else {
      partsInWrapper.push(<span className="token-command" key={i}>{postProcess(txt.slice(position.start, position.end))}</span>);
    }
    cursor = position.end;
  }
  streamWrapperParts();

  if (cursor < txt.length) {
    parts.push(<span key="end">{tagError(txt.slice(cursor), cursor, isInvalid, postProcess)}</span>);
  }

  return <span
    style={!!commandsData?.length ? {
      display: 'flex',
      alignItems: 'center',
      gap: 3
    } : {}}
  >{parts}</span>;
}


/**
 * @param {string} str 
 * 
 * @return {string}
 */
export function getStartWhitespace(str) {
  let match = str.match(/^\s+/);
  if (match) {
    return match[0];
  }
  return '';
}


/**
 * @param {string} str 
 * 
 * @return {string}
 */
export function removeStartWhitespace(str) {
  let startWhitespace = getStartWhitespace(str);
  return str.slice(startWhitespace.length);
}
