import React, { useRef } from 'react';
import {
  Box
} from '@mui/material';
import { addBreadcrumb } from '@sentry/browser';
import DragHandleIcon from '@mui/icons-material/DragHandle';
const DEFAULT_MIN_SIZE = 240;
// Minimum size of the remaining content
const DEFAULT_MAX_SIZE_OFF = 340;
const RESIZING_BODY_CLS = 'resizing';
const STOP_DRAGGING_TIMER = 15 * 1000;


/**
 * 
 * @param {object} props 
 * @param {'left' | 'right'} props.align
 * @param {string} props.parentSelector
 * @param {number} props.size
 * @param {(size: number) => any} props.onResize
 * @param {number=} props.offsetX
 * @param {number=} props.offsetY
 * @param {number=} props.minSize - Minimum size for the element being resized.
 * @param {number=} props.maxSizeOff - Minimum size of the other element.
 * @returns 
 */
const SidebarResizerBase = ({
  align,
  parentSelector,
  size,
  onResize,
  offsetX,
  offsetY,
  minSize,
  maxSizeOff
}) => {
  minSize = minSize || DEFAULT_MIN_SIZE;
  maxSizeOff = maxSizeOff || DEFAULT_MAX_SIZE_OFF;

  const ref = useRef(/** @type {HTMLElement} */(null));

  /**
   * @template {'mouse' | 'touch'} eventType
   * @param {eventType} type
   * @returns {eventType extends 'mouse' ? import('react').MouseEventHandler<HTMLDivElement> : import('react').TouchEventHandler<HTMLDivElement>}
   */
  const generateResizeHandler = (type) => {
    const MOVE_EVENT = type === 'mouse' ? 'mousemove' : 'touchmove';
    const STOP_EVENT = type === 'mouse' ? 'mouseup' : 'touchend';
    /**
     * @param {MouseEvent | TouchEvent} evt
     */
    function getX(evt) {
      if (type === 'mouse') {
        return (/** @type {MouseEvent} */ (evt)).clientX;
      }
      return (/** @type {TouchEvent} */ (evt)).touches.item(0).clientX;
    }

    /**
     * @param {MouseEvent | TouchEvent} startEvt
     */
    function handler(startEvt) {
      let lastSize = null;
      /**
       * @param {MouseEvent|TouchEvent} moveEvt
       */
      function resize(moveEvt) {
        //https://www.uriports.com/blog/easy-fix-for-unable-to-preventdefault-inside-passive-event-listener
        if (type === 'mouse') {
          moveEvt.preventDefault();
          moveEvt.stopImmediatePropagation();
        }
        clearTimeout(timer);
        timer = setTimeout(stopDragging, STOP_DRAGGING_TIMER);
        const multipler = align === 'right' ? +1 : -1;
        const diff = getX(moveEvt) - start.x;
        let newSize = Math.round(start.size + multipler * diff);
        if (newSize < minSize) {
          newSize = minSize;
        }
        if (newSize > parentWidth - maxSizeOff) {
          newSize = parentWidth - maxSizeOff;
        }
        if (lastSize === newSize) {
          return;
        }
        setSize(newSize);
        lastSize = newSize;
      };
      const stopDragging = () => {
        document.removeEventListener(MOVE_EVENT, resize, false);
        document.removeEventListener(STOP_EVENT, stopDragging, {
          capture: false,
        });
        start = null;

        document.body.classList.remove(RESIZING_BODY_CLS);
        clearTimeout(timer);
      };


      const parentWidth = (/** @type {HTMLElement} */ (startEvt.currentTarget)).closest(parentSelector).clientWidth;
      let start = {
        size,
        x: getX(startEvt)
      };
      document.addEventListener(MOVE_EVENT, resize, false);
      document.addEventListener(STOP_EVENT, stopDragging, {
        capture: false,
        once: true
      });
      document.body.classList.add(RESIZING_BODY_CLS);
      // just in case, lets clear after timeout
      let timer = setTimeout(stopDragging, STOP_DRAGGING_TIMER);
    };

    // @ts-ignore
    return handler;
  };

  /**
   * @param {number=} newSize 
   */
  const setSize = (newSize) => {
    onResize(newSize);
  };

  return (
    <Box
      ref={ref}
      onMouseDownCapture={generateResizeHandler('mouse')}
      onTouchStartCapture={generateResizeHandler('touch')}
      onDoubleClick={() => {
        addBreadcrumb({
          message: 'SidebarResize: Double clicked to reset.'
        });
        setSize();
      }}
      sx={{
        position: 'absolute',
        [align]: offsetX ? offsetX : 0,
        top: offsetY ? offsetY : 0,
        bottom: 0,
        cursor: 'col-resize',
        zIndex: 2,
        width: 16,
        opacity: 0.8
      }}
    >
      <DragHandleIcon
        sx={{
          transform: 'translate(0%, -50%) rotate(-90deg)',
          position: 'absolute',
          top: '50%',
          left: '0%',
          color: 'grey.500',
          width: '100%'
        }}
      />
    </Box>
  );
};


const SidebarResizer = React.memo(SidebarResizerBase);
export default SidebarResizer;