import {
  Alert,
  Typography as T
} from '@mui/material';
import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import AsyncButton from '../AsyncButton/AsyncButton';

/**
 * @typedef StripePaymentResult
 * @property {string=} setup_intent
 * @property {string} payment_method
 * @property {number=} quantity
 * 
 * @param {object} props
 * @param {import('./PaymentDialog').PaymentDialogConfig['returnUrl']} props.returnUrl
 * @param {React.ReactNode=} props.btnContents
 * @param {React.ReactNode=} props.disclaimer
 * @param {React.CSSProperties=} props.style
 * @param {(() => any)=} props.onReady
 * @param {(() => boolean)} props.beforeSubmit
 * @param {(result: StripePaymentResult) => any} props.onComplete
 * 
 */
const SetupFormBase = ({
  returnUrl,
  btnContents,
  disclaimer,
  style,
  onReady,
  beforeSubmit,
  onComplete
}) => {
  const stripe = useStripe();
  const elements = useElements();
  
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [elementLoading, setElementLoading] = useState(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!beforeSubmit()) {
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    const params = new URLSearchParams(returnUrl.params);
    const delimiter = returnUrl.path.includes('?') ? '&' : '?';
    params.toString();
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${returnUrl.path}${delimiter}${params.toString()}`,
      },
      redirect: 'if_required'
    });
    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      success({
        payment_method: typeof paymentIntent.payment_method === 'string' ?
          paymentIntent.payment_method
          : paymentIntent.payment_method.id
      });
    } else {
      setMessage('An unexpected error occured.');
    }

    setIsProcessing(false);
  };
  /**
   * 
   * @param {import('./SetupForm').StripeSetupResult} result 
   */
  const success = (result) => {
    onComplete(result);
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      style={style}
    >
      {message && (
        <Alert
          severity="error"
          sx={{
            mb: 2
          }}
        >
          {message}
        </Alert>
      )}
      <PaymentElement
        onReady={(element) => {
          element.focus();
          setElementLoading(false);
          onReady?.();
        }}
        id="payment-element"

        options={{
          terms: {
            card: 'never'
          },
          wallets: {
            applePay: 'auto',
            googlePay: 'auto'
          }
        }}
      />
      <AsyncButton
        type="submit"
        color="primary"
        variant="contained"
        isLoading={isProcessing || elementLoading}
        sx={{
          mt: 4
        }}
        fullWidth
      >
        {btnContents}
      </AsyncButton>
      {disclaimer && <T
        sx={{
          mt: 2,
          opacity: 0.6
        }}
        variant="caption"
        component="div"
      >{disclaimer}</T>}
    </form>
  );
};

const SetupForm = React.memo(SetupFormBase);
export default SetupForm;