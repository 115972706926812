import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { TABLES_FRONT_END_DOMAIN } from '../../hooks/useTables';
import DatabaseFrame from './DatabaseFrame';
import ReactDOM from 'react-dom';

/**
 * @param {object} props
 * @param {object} props.config
 * @param {function=} props.config.onCreated
 * @returns
 */
export const DatabaseAirtableImportDialog = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [config, setConfig] = useState(null);

  useImperativeHandle(ref, () => ({
    show: (config) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setConfig(config);
        setIsOpen(true);
      });
    }
  }));

  if (!isOpen) {
    return null;
  }

  function iframeQueryParams() {
    if (config.openImportedButtonLabel) {
      return `?openButtonLabel=${encodeURIComponent(config.openImportedButtonLabel)}`;
    }
    return '';
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      onClose={() => setIsOpen(false)}
    >
      <DialogTitle>
        Import from Airtable (beta)
      </DialogTitle>
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: {
          xs: '300px',
          sm: '500px',
          md: '820px'
        },
        height: '360px',
      }}>
        <DatabaseFrame
          url={`${TABLES_FRONT_END_DOMAIN}/data-blaze/airtable-import${iframeQueryParams()}`}
          listener={(event) => {
            const type = event.data.type;
            if (type === 'open_imported_space') {
              setIsOpen(false);
              if (config.onCreated) {
                config.onCreated({ databaseId: event.data.data });
              }
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
});