import { useEffect } from 'react';

/**
 * This is a hook for calling a function on mount. 
 * You can return or use second paramter to call unmount
 * onUnmount parameter takes the priority if both used.
 * @param {() => any} [onMount] 
 * @param {() => any} [onUnmount] 
 * @returns 
 */
const useOnMount = (onMount, onUnmount) => {
  return useEffect(() => {
    const res = onMount?.();
    if (typeof onUnmount === 'function') {
      return onUnmount;
    } else if (typeof res === 'function') {
      return res;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useOnMount;