import React from 'react';

/**
 * @param {object} props
 * @param {React.ReactElement|React.ReactElement[]=} props.children
 */
export default function ActionsToolbar(props) {
  return <div style={{
    display: 'flex',
    border: 'solid 1px #ccc',
    margin: '12px 12px 12px',
    borderRadius: 8,
    backgroundColor: 'white',
    padding: 4
  }}>
    {props.children}
  </div>;
}
