import React from 'react';
import Paper from '@mui/material/Paper';
// @ts-ignore 
import BackgroundImage from '../../../images/background_512.png';


export default function DetailsCard(props) {
  return <Paper elevation={'elevation' in props ? props.elevation : 3} style={Object.assign({
    height: '100%',
    padding: 20,
    position: 'relative',
    overflow: 'auto',
    background: props.hideBackground ? undefined : `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
    backgroundAttachment: 'fixed',
    backgroundSize: 'contain'
  }, props.style || {})}>
    {props.children}
  </Paper>
  ;
}

