/**
 * @typedef {{_uncommitted: Record<string, any>}} UncommittedChangesObject
 */

/**
 * @typedef {object} SiteSelection
 * @property {string} kind
 * @property {string} id
 */

/**
 * @typedef {object} SitesStateDef
 * @property {SiteObjectType & UncommittedChangesObject} site
 * @property {{[pageId: string]:PageObjectType & UncommittedChangesObject}} pagesMap
 * @property {SiteSelection} selection
 */

/**
 * The Sites Reducer
 * @param {SitesStateDef} state
 * @param {Object & {type: string, uncommitted?: boolean}} action
 * @return {SitesStateDef}
 */
export const sitesReducer = function(state = {
  site: null,
  pagesMap: null,
  selection: null,
}, action) {
  switch (action.type) {
  case 'CLEAR_SITE':
  {
    return Object.assign({}, state, { site: null, pagesMap: null });
  }
  case 'INIT_SITE':
  {
    const site = action.data;
    const pagesMap = {};
    for (const page of site.pages) {
      pagesMap[page.id] = page;
    }
    return Object.assign({}, state, { site, pagesMap });
  }
  case 'LOCATION_CHANGE':
  {
    const pathname = action.location.pathname;
    // /site/:site
    const pathParts = pathname.split('/');
    let kind = pathParts[1];
    let id;
    if (kind === 'site') {
      if (pathParts.length > 3 && pathParts[4]) {
        kind = 'page';
        id = pathParts[4];
      } else {
        id = pathParts[2];
      }
    } else {
      kind = null;
      id = null;
    }

    return Object.assign({}, state,{ selection: { kind, id } });
  }
  case 'CREATE_PAGE':
  {
    const { data: newPage } = action;
    const site = Object.assign({}, state.site, { ...state.site, pages: [...state.site.pages, newPage] });
    const pagesMap = Object.assign({}, state.pagesMap, { [newPage.id]:  newPage });
    return Object.assign({}, state, { site, pagesMap });
  }
  case 'UPDATE_SITE':
  {
    const { data: siteUpdateBatch, uncommitted } = action;
    let site;
    if (uncommitted) {
      const siteUncommitted = Object.assign({}, state.site._uncommitted, siteUpdateBatch);
      site = Object.assign({}, state.site, { _uncommitted: siteUncommitted });
    } else {
      site = Object.assign({}, state.site, siteUpdateBatch);
    }
    return Object.assign({}, state, { site });
  }
  case 'UPDATE_PAGE':
  {

    const { data: pageUpdateBatch, uncommitted, pageId } = action;
    let page;
    if (uncommitted) {
      const pageUncommitted = Object.assign({}, state.site._uncommitted, pageUpdateBatch);
      page = Object.assign({}, state.pagesMap[pageId], { _uncommitted: pageUncommitted });
    } else {
      page = Object.assign({}, state.pagesMap[pageId], pageUpdateBatch);
    }

    const pageIndex = state.site.pages.findIndex(page => page.id === pageId);
    const updatedSite = Object.assign({}, state.site, { pages: [
      ...state.site.pages.slice(0, pageIndex),
      page,
      ...state.site.pages.slice(pageIndex + 1),
    ] });
    const updatedPagesMap = Object.assign({}, state.pagesMap, { [pageId]: page });
    return Object.assign({}, state, { site: updatedSite, pagesMap: updatedPagesMap });
  }
  case 'SORT_PAGES':
  {
    const site = Object.assign({}, state.site, { pages: state.site.pages.slice() });
    site.pages.sort((a, b) => a.order - b.order);
    return Object.assign({}, state, { site });
  }
  case 'DELETE_PAGE':
  {
    const { pageId } = action;
    const updatedPagesMap = Object.assign({}, state.pagesMap);
    delete updatedPagesMap[pageId];
    const pageIndex = state.site.pages.findIndex(page => page.id === pageId);
    const updatedSite = Object.assign({}, state.site, { pages: [
      ...state.site.pages.slice(0, pageIndex),
      ...state.site.pages.slice(pageIndex + 1),
    ] });
    return Object.assign({}, state, { site: updatedSite, pagesMap: updatedPagesMap });
  }
  case 'UPDATE_PAGE_REVISION':
  {
    const { data: revisionUpdateBatch, pageId } = action;
    const updatedRevision = Object.assign({}, state.pagesMap[pageId].current_revision, revisionUpdateBatch);
    const updatedPage = Object.assign({}, state.pagesMap[pageId], { current_revision: updatedRevision });
    const pageIndex = state.site.pages.findIndex(page => page.id === pageId);
    const updatedSite = Object.assign({}, state.site, { pages: [
      ...state.site.pages.slice(0, pageIndex),
      updatedPage,
      ...state.site.pages.slice(pageIndex + 1),
    ] });
    const updatedPagesMap = Object.assign({}, state.pagesMap, { [pageId]: updatedPage });
    return Object.assign({}, state, { site: updatedSite, pagesMap: updatedPagesMap });
  }
  default:
    return state;
  }
};
