import { closePopup, openPopup, onPopupClose } from '../../browserPopup';
import {
  isSamlUser as apiIsSamlUser
} from '../../cpapi';
import { getApiPath } from '../../firebase_utilities';
import { isAndroid } from '../../flags';
const isSamlUserResponses = {};
let currentEmailCheck = null;

/**
 * Checks if the user is saml. Catches the response to stop triggering API multiple times.
 * @param {string} email 
 * @return {Promise<boolean?>} Returns null if a newer isSamlUser has been triggered or api request failed
 */
export const isSamlUser = async (email) => {
  if (isSamlUserResponses[email]) {
    return isSamlUserResponses[email];
  }
  if (toBeBypassed(email)) {  
    isSamlUserResponses[email] = false;
    return false;
  }
  
  currentEmailCheck = email;
  const promise = doIsSamlUser(email);
  const result = await promise;
  if (result === null) {
    return null;
  }
  isSamlUserResponses[email] = promise;
  //To make sure earlier promises does not resolve late
  if (currentEmailCheck !== email) {
    return null;
  }
  return result;
};

/**
 * Checks if SAML check to be turned off
 * @param {string} email 
 * @returns 
 */
const toBeBypassed = (email) => {
  // We don't have to worry about other emails
  if (!email.endsWith('@blaze.today')) {
    return false;
  }
  
  return document.location.search.includes('nosaml=true');
};

/**
 * Trigger the api with the try catch wrapper
 * @param {string} email 
 * @returns {Promise<boolean?>} Returns null if request has been failed
 */
const doIsSamlUser = async (email) => {
  try {
    const result = await apiIsSamlUser(email);
    return result;
  } catch (ex) {
    // We don't want to trouble regular users. Suppressing the error.
    console.error(ex);
    return null;
  }
};

/**
 * Logs the SAML user
 * @param {string} email 
 * @returns {Promise<string>}
 */
export const loginSamlUser = async (email) => {
  if (isAndroid()) {
    return loginOnAndroid(email);
  } else {
    return loginOnWeb(email);
  }
};

/**
 * Logs the SAML user
 * @param {string} email 
 * @returns {Promise<string>}
 */
const loginOnWeb = async (email) => {
  const ref = openPopup({
    url: `${getApiPath()}/saml-login?email=${encodeURIComponent(email)}`,
    name: 'SAML'
  });

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      closePopup(ref);
      reject('timeout');
    }, 120000);
    onPopupClose(ref, () => {
      reject('Login page is closed');
      closePopup(ref);
    });
    listenToMessage()
      .then(token => {
        resolve(token);
      })
      .catch(message => reject(message))
      .finally(() => {
        closePopup(ref);
      });
  });
};

/**
 * Logs the SAML user
 * @param {string} email 
 * @returns {Promise<string>}
 */
const loginOnAndroid = async (email) => {
  const ref = openPopup({
    url: `${getApiPath()}/saml-login?email=${encodeURIComponent(email)}`,
    name: 'SAML'
  });

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject('timeout');
    }, 120000);

    listenToMessage()
      .then(token => {
        resolve(token);
      })
      .catch(message => reject(message))
      .finally(() => {
        closePopup(ref);
      });
  });
};

/**
 * 
 * @returns {Promise<string>}
 */
const listenToMessage = () => {
  return new Promise((resolve, reject) => {
    let listenerRef = isAndroid() ? document : window;
    const listener = generateMessageListener((data) => {
      const { token, message } = data;
      if (token) {
        resolve(token);
      } else {
        reject(message);
      }
      listenerRef.removeEventListener('message', listener);
    });
    listenerRef.addEventListener('message', listener);
  });
  
};

/**
 * Generates listener for message event and watches for SAML token or message
 * @param {(data: ({token?: string, message?: string}))=> any} callback 
 */
const generateMessageListener = (callback) => {
  return (evt) => {
    // evt.origin is "" on Android App
    if (!isAndroid() && (!evt.origin || !getApiPath().startsWith(evt.origin))) {
      // not a message from backend
      return;
    }
    const eventDataStr = evt.data;
    let eventData;
    try {
      eventData = JSON.parse(eventDataStr);
      if (!eventData || eventData.type !== 'tb-saml') {
        return;
      }
    } catch {
      //ignore if JSON parse is failed
      return;
    }
    if (eventData.token) {
      callback({
        token: eventData.token
      });
    } else {
      callback({
        message: eventData.message
      });
    }
  };
};