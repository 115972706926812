
/**
 * @param {import('@store').UserStateDef} user
 * @param {GroupObjectType} group 
 */
export function canGroupPublicManage(user, group) {
  let uid = user.uid;
  let permissions = group.permissions || {};

  return (permissions['u:' + uid] || permissions['e:' + user.email])?.type === 'owner'
    && user.emailVerified
    // TODO templates library: remove on full release
    && (
      (user.email && user.email.endsWith('@blaze.today'))
      || user.mvp
    );
}

/**
 * @param {GroupObjectType} group 
 */
export function isGroupPublic(group) {
  return group.permissions?.['_:all']?.type === 'viewer';
}

/**
 * @param {import('@store').UserStateDef} user
 * @param {GroupObjectType} group 
 */
export function isGroupPublicManageRestricted(user, group) {
  return isGroupPublic(group) && !canGroupPublicManage(user, group);
}

