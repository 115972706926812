import { DataRequiredError, ParseError } from './ParserUtils';
import { parse } from './Parser';
import { fillDom, domToStream, trimDom, trimNotes } from './SnippetProcessor';
import nearley from 'nearley';
import equationGrammar from '../equation_parser/equation_grammar';
import jsdocGrammar from '../jsdoc_parser/jsdoc_grammar';
import { DataContainer, Environment } from './DataContainer';
import { md5 } from 'js-md5';
import { grantsSatisfied } from '../components/Version/limitations';
import { getLocale } from '../locales';
import { BASIC_TOKENS, lex, lexSQL, SQL_KEYWORD_TOKENS, SQL_PLACEHOLDER_REGEX } from './Lexer';
import { isAddonCommand } from '../utilities';
import moment from 'moment';
import { applyTimeShift } from './time_shift';
import { normal, studentt, stddev, arrmean, variance } from './StatisticalFunctions';


/**
 * Utility function to filter an array asynchronously.
 * 
 * @param {*[]} arr 
 * @param {Function} callback 
 */
async function filterAsync(arr, callback) {
  const fail = Symbol();
  return (await Promise.all(arr.map(async (item, i) => (await callback(item, i)) ? item : fail))).filter(i => i !== fail);
}


/**
 * Utility function to find an element in an array asynchronously.
 * 
 * @param {*[]} arr 
 * @param {Function} callback 
 */
async function findAsync(arr, callback) {
  for (let i = 0; i < arr.length; i++) {
    let item = arr[i];
    if (await callback(item, i)) {
      return item;
    }
  }
  return null;
}


/**
 * Return the mid value among x, y, and z
 * 
 * @param {number} x
 * @param {number} y
 * @param {number} z
 * @param {Function} compare
 * @returns {Promise.<*>}
 */
async function getPivot(x, y, z, compare) {
  if ((await compare(x, y)) < 0) {
    if ((await compare(y, z)) < 0) {
      return y;
    } else if ((await compare(z, x)) < 0) {
      return x;
    } else {
      return z;
    }
  } else if ((await compare(y, z)) > 0) {
    return y;
  } else if ((await compare(z, x)) > 0) {
    return x;
  } else {
    return z;
  }
}


/**
 * Asynchronous quick sort
 * 
 * @param {Array} arr array to sort
 * @param {function} compare asynchronous comparing function
 * @param {number=} left index where the range of elements to be sorted starts
 * @param {number=} right index where the range of elements to be sorted ends
 * @returns {Promise.<*>}
 */
async function quickSort(arr, compare, left = 0, right = arr.length - 1) {
  if (left < right) {
    let i = left, j = right, tmp;
    const pivot = await getPivot(arr[i], arr[i + Math.floor((j - i) / 2)], arr[j], compare);
    while (true) {
      while ((await compare(arr[i],  pivot)) < 0) {
        i++;
      }
      while ((await compare(pivot, arr[j])) < 0) {
        j--;
      }
      if (i >= j) {
        break;
      }
      tmp = arr[i];
      arr[i] = arr[j];
      arr[j] = tmp;

      i++;
      j--;
    }
    await quickSort(arr, compare, left, i - 1);
    await quickSort(arr, compare, j + 1, right);
  }
  return arr;
}


/**
 * @param {object} eqn - root equation node
 * @param {Environment} env
 * @param {boolean=} dontForce - if false, always returns a string
 */
export async function evaluateEquation(eqn, env, dontForce = false) {
  if (eqn === undefined) {
    throw new ParseError('Invalid formula');
  }
  let res = await eEqn(eqn, env);
  if (typeof res === 'string') {
    return res;
  } else if (typeof res === 'boolean') {
    if (dontForce) {
      return res;
    }
    return res ? 'yes' : 'no';
  } else if (typeof res === 'number') {
    if (dontForce) {
      return res;
    }
    return toStr(res);
  } else if (res.keys) {
    if (dontForce) {
      return res;
    }
    return listString(res);
  } else if (res.type === 'lambda') {
    if (dontForce) {
      return res;
    }
    return sEqn(res);
  } else if (res.type === 'error') {
    throw new ParseError(res.error);
  } else {
    throw new Error('Unknown formula result type: ' + (typeof res));
  }
}


/**
 * Whether two items are strictly equal. Will not convert types.
 * 
 * @param {*} a
 * @param {*} b
 * 
 * @return {boolean}
 */
export function strictEquals(a, b) {
  // Doesn't do any type conversion
  if (a && b && a.type === 'lambda' && b.type === 'lambda') {
    if (a.signature !== b.signature) {
      return false;
    }
    return equals(a, b, true);
  } else if (a && b && a.keys && b.keys) {
    return equals(a, b, true);
  }
  return a === b;
}


/**
 * Whether two items are loosely equal. Will convert types
 * 
 * @param {*} a
 * @param {*} b
 * @param {boolean=} strictLists - If true and a and b are lists, strict comparison will be used for the children.
 * 
 * @return {boolean}
 */
export function equals(a, b, strictLists = false) {
  if (a !== undefined && b === undefined) {
    return false;
  }
  if (b !== undefined && a === undefined) {
    return false;
  }
  if (a !== null && b === null) {
    return false;
  }
  if (b !== null && a === null) {
    return false;
  }

  // equals if can be converted to the same thing (unless 'strict' is true)
  // num, string, bool, lambda, list
  if (['number', 'boolean'].includes(typeof a) && typeof a === typeof b) {
    return a === b;
  } else if ((typeof a === 'number') || (typeof b === 'number')) {
    try {
      return toNum(a) === toNum(b);
    } catch (error) {
      return false;
    }
  } else if ((typeof a === 'boolean') || (typeof b === 'boolean')) {
    try {
      return toBool(a) === toBool(b);
    } catch (error) {
      return false;
    }
  } else if (a.type === 'lambda' || b.type === 'lambda') {
    return toStr(a) === toStr(b);
  } else if (a.type === 'error' || b.type === 'error') {
    return a.error === b.error;
  } else if (a.keys || b.keys) {
    // it's a list
    try {
      a = toLst(a);
      b = toLst(b);
    } catch (err) {
      // one of them isn't a list
      return false;
    }
    if (Object.keys(a.keys).length !== Object.keys(b.keys).length) {
      return false;
    }
    if (a.positional.length !== b.positional.length) {
      return false;
    }
    for (let key in a.keys) {
      if (!(key in b.keys)) {
        return false;
      }
      if (!(strictLists ? strictEquals(a.keys[key], b.keys[key]) : equals(a.keys[key], b.keys[key]))) {
        return false;
      }
    }
    for (let i = 0; i < a.positional.length; i++) {
      if (!(strictLists ? strictEquals(a.positional[i], b.positional[i]) : equals(a.positional[i], b.positional[i]))) {
        return false;
      } 
    }
    return true;
  }
  if (typeof a === 'string' || typeof b === 'string') {
    if (toStr(a) === toStr(b)) {
      return true;
    }
  }
  if (couldBeBool(a) && couldBeBool(b)) {
    try {
      return equals(toBool(a), b);
    } catch (_) {}
  }
  if (couldBeNum(a) && couldBeNum(b)) {
    try {
      return equals(toNum(a), b);
    } catch (_) {}
  }
  if (couldBeLst(a) && couldBeLst(b)) {
    try {
      return equals(toLst(a), b);
    } catch (_) {}
  }
  return false;
}


function bufferToHex(buffer) {
  let hexCodes = [];
  let view = new DataView(buffer);
  for (let i = 0; i < view.byteLength; i += 4) {
    let value = view.getUint32(i);
    let stringValue = value.toString(16);
    let padding = '00000000';
    let paddedValue = (padding + stringValue).slice(-padding.length);
    hexCodes.push(paddedValue);
  }

  return hexCodes.join('');
}


function bufferToBase64(buffer) {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}


function convertISOtoSQLstandard(date) {
  date = date.replace('T', ' ');
  date = date.substring(0, 19) + date.substring(23);
  if (date.length > 22 && date.substring(22) === ':00') {
    date = date.substring(0, 22);
  }
  return date;
}

/**
 * @type {Object<string, {placeholder: string, output: string, description: string, alias?: string, category: string, validate?: function, args?: function|function[], fn?: (args: any, env: Environment) => any, parts: [string, string]}>}
 */
export const FUNCTIONS = {
  
  'upper': {
    placeholder: 'upper("Test")',
    parts: ['upper(', ')'],
    output: 'string',
    description: 'Uppercase a string',
    category: 'string',
    args: [toStr],
    fn: (args) => args[0].toLocaleUpperCase()
  },
  'lower': {
    placeholder: 'lower("Test")',
    parts: ['lower(', ')'],
    output: 'string',
    description: 'Lowercase a string',
    category: 'string',
    args: [toStr],
    fn: (args) => args[0].toLocaleLowerCase()
  },
  'proper': {
    placeholder: 'proper("Test text")',
    parts: ['proper(', ')'],
    output: 'string',
    alias: 'capitalize',
    description: 'Uppercase the first letter of each word',
    category: 'string',
    args: [toStr],
    // \b doesn't handle unicode character boundaries correctly
    // So we use a lookbehind+lookahead zero-width assertion
    // https://stackoverflow.com/a/57290540/18903720
    fn: (args) => {
      // Put this in a RegExp constructor as Safari <16.4 can't handle `(?<`
      // Note the proper() function won't work in these versions of Safari
      let r = new RegExp('(?<=\\p{L})(?=\\P{L})|(?<=\\P{L})(?=\\p{L})', 'ug');
      return args[0].split(r)
        .map(txt => txt.charAt(0).toLocaleUpperCase() + txt.slice(1).toLocaleLowerCase())
        .join('');
    }
  },
  'replace': {
    placeholder: 'replace("aabbcc", "a", "x")',
    parts: ['replace(', ', "a", "x")'],
    output: 'string',
    description: 'Replace all occurrences of a string',
    category: 'string',
    args: [toStr, toStr, toStr],
    fn: (args) => {
      let base = args[0];
      let fromStr = args[1];
      let toStr = args[2];
      // eslint-disable-next-line
      fromStr = fromStr.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
      return base.replace(new RegExp(fromStr, 'g'), toStr);
    }
  },
  'trim': {
    placeholder: 'trim("  Test ")',
    parts: ['trim(', ')'],
    output: 'string',
    description: 'Remove whitespace around a string',
    category: 'string',
    args: [toStr],
    fn: (args) => args[0].trim()
  },
  'len': {
    placeholder: 'len("Test")',
    parts: ['len(', ')'],
    output: 'number',
    description: 'The length of a string',
    category: 'string',
    alias: 'count',
    args: [toStr],
    fn: (args) => args[0].length
  },
  'reverse': {
    placeholder: 'reverse("Test")',
    parts: ['reverse(', ')'],
    output: 'string',
    description: 'Reverse a string',
    category: 'string',
    args: [toStr],
    fn: (args) => args[0].split('').reverse().join('')
  },
  'left': {
    placeholder: 'left("Mr Smith", 2)',
    parts: ['left(', ', 2)'],
    output: 'string',
    description: 'Get part of the left side of the text',
    category: 'string',
    args: [toStr, toNum],
    fn: (args) => {
      if (args[1] < 0) {
        throw new ParseError(`Length argument cannot be less than 0, got ${args[1]}`);
      }
      if (Math.round(args[1]) !== args[1]) {
        throw new ParseError(`Length argument must be an integer, got ${args[1]}`);
      }

      return args[0].slice(0, args[1]);
    }
  },
  'right': {
    placeholder: 'right("Mr Smith", 5)',
    parts: ['right(', ', 5)'],
    output: 'string',
    description: 'Get part of the right side of the text',
    category: 'string',
    args: [toStr, toNum],
    fn: (args) => {
      if (args[1] < 0) {
        throw new ParseError(`Length argument cannot be less than 0, got ${args[1]}`);
      }
      if (Math.round(args[1]) !== args[1]) {
        throw new ParseError(`Length argument must be an integer, got ${args[1]}`);
      }
      return args[0].slice(args[0].length - args[1]);
    }
  },
  'substring': {
    placeholder: 'substring("aabbcc", 1, 3)',
    parts: ['substring(', ', 1, 3)'],
    output: 'string',
    description: 'Extract part of a string',
    category: 'string',
    args:  (args) => {
      if (![2, 3].includes(args.length)) {
        return '"substring" takes either 2 or 3 arguments';
      }
      let base = toStr(args[0]);
      let start = toNum(args[1]);
      let length;
      if (args.length === 3) {
        length = toNum(args[2]);
      }
      if (Math.round(start) !== start || start === 0) {
        return 'Start position must be a non-zero integer';
      }
      if (args.length === 3) {
        if (Math.round(length) !== length || length < 1) {
          return 'Length must be a positive integer';
        }
      }
      return [base, start, length];
    },
    fn: (args) => {
      if (args[1] < 0) {
        return args[0].substr(args[0].length + args[1], args[2]);
      } else {
        return args[0].substr(args[1] - 1, args[2]);
      }
    }
  },
  'concat': {
    description: 'Concatenate two or more strings',
    output: 'string',
    placeholder: 'concat("a", "bc")',
    parts: ['concat(', ', "bc")'],
    category: 'string',
    alias: 'join merge',
    args: (args) => args.length ? args.map(toStr) : '"concat" requires at least one argument',
    fn: (args) => args.join('')
  },
  'contains': {
    description: 'Whether one string contains another',
    output: 'boolean',
    placeholder: 'contains("Good morning", "morning")',
    parts: ['contains(', ', "morning")'],
    category: 'string',
    alias: 'includes',
    args: [toStr, toStr],
    fn: (args) => args[0].includes(args[1])
  },
  'search': {
    description: 'The position of one string in another',
    output: 'number',
    placeholder: 'search("Good morning", "morning")',
    parts: ['search(', ', "morning")'],
    category: 'string',
    alias: 'location find',
    args: [toStr, toStr],
    fn: (args) => {
      if (args[0].includes(args[1])) {
        return args[0].indexOf(args[1]) + 1;
      }
      throw new ParseError(`Could not find "${args[1]}"`);
    }
  },
  'startswith': {
    description: 'Whether a string starts with another string',
    alias: 'begins',
    output: 'string',
    placeholder: 'startswith("Count: 123", "Count")',
    parts: ['startswith(', ', "Count")'],
    category: 'string',
    args: [toStr, toStr],
    fn: (args) => {
      return args[0].startsWith(args[1]);
    }
  },
  'endswith': {
    description: 'Whether a string ends with another string',
    output: 'string',
    placeholder: 'endswith("Count: 123", "Count")',
    parts: ['endswith(', ', "Count")'],
    category: 'string',
    args: [toStr, toStr],
    fn: (args) => {
      return args[0].endsWith(args[1]);
    }
  },
  'testregex': {
    description: 'Test whether a regular expression matches a string',
    output: 'boolean',
    placeholder: 'testregex("ID: 82931", "\\d+")',
    parts: ['testregex(', ', "\\d+")'],
    category: 'string',
    args: (args) => {
      if (args.length !== 2 && args.length !== 3) {
        return '"testregex" requires 2 or 3 arguments';
      }
      return args.map(toStr);
    },
    fn: (args) => {
      let haystack = args[0];
      let flags = 'u';
      if (args[2]) {
        for (let flag of args[2].split('')) {
          if (['i', 's'].includes(flag)) {
            flags += flag;
          } else {
            throw new ParseError(`Unknown regex flag: "${flag}"`);
          }
        }
      }
      let regex = new RegExp(args[1], flags);
      let match = haystack.match(regex);
      return !!match;
    }
  },
  'extractregex': {
    description: 'Extract the regular expression match',
    category: 'string',
    output: 'string',
    placeholder: 'extractregex("ID: 82931", "(\\d+)")',
    parts: ['extractregex(', ', "(\\d+)")'],
    // no named capture:
    //   - first matching group as string
    // named capture:
    //   - list with names/values

    args: (args) => {
      if (args.length !== 2 && args.length !== 3) {
        return '"extractregex" requires 2 or 3 arguments';
      }
      return args.map(toStr);
    },
    fn: (args) => {
      let haystack = args[0];
      let flags = 'u';
      if (args[2]) {
        for (let flag of args[2].split('')) {
          if (['i', 's'].includes(flag)) {
            flags += flag;
          } else {
            throw new ParseError(`Unknown regex flag: "${flag}"`);
          }
        }
      }

      let regex = new RegExp(args[1], flags);
      let match = haystack.match(regex);

      if (match) {
        // Return first capture group if it exists, otherwise the entire match
        if (match.groups) {
          return createListFromObject(match.groups);
        } else if (match.length > 1) {
          return match[1] === undefined ? '' : match[1];
        } else {
          return match[0];
        }
      } else {
        throw new ParseError('No match found.');
      }
    },
  },

  'extractregexall': {
    description: 'Extract all regular expression matches',
    category: 'string',
    output: 'list',
    placeholder: 'extractregexall("ID: 82931, ID: 1234", "(\\d+)")',
    parts: ['extractregexall(', ', ID: 1234", "(\\d+)")'],
    // no named capture:
    //   - first matching group as string
    // named capture:
    //   - list with names/values

    args: (args) => {
      if (args.length !== 2 && args.length !== 3) {
        return '"extractregexall" requires 2 or 3 arguments';
      }
      return args.map(toStr);
    },
    fn: (args) => {
      let haystack = args[0];
      let flags = 'ug';
      if (args[2]) {
        for (let flag of args[2].split('')) {
          if (['i', 's'].includes(flag)) {
            flags += flag;
          } else {
            throw new ParseError(`Unknown regex flag: "${flag}"`);
          }
        }
      }

      let regex = new RegExp(args[1], flags);
      let matches = [...haystack.matchAll(regex)];

      return createListFromArray(matches.map((match) => {
        // Return first capture group if it exists, otherwise the entire match
        if (match.groups) {
          return createListFromObject(match.groups);
        } else if (match.length > 1) {
          return match[1] === undefined ? '' : match[1];
        } else {
          return match[0];
        }
      }));
    }
  },
  'splitregex': {
    description: 'Splits a string on a regular expression',
    output: 'list',
    placeholder: 'splitregex("abc90ast12acn", "\\d+")',
    parts: ['splitregex(', ', "\\d+")'],
    category: 'string',
    alias: 'split',
    args: (args) => {
      if (args.length !== 2 && args.length !== 3) {
        return '"splitregex" requires 2 or 3 arguments';
      }
      return args.map(toStr);
    },
    fn: (args) => {
      let haystack = args[0];
      let flags = 'u';
      if (args[2]) {
        for (let flag of args[2].split('')) {
          if (['i', 's'].includes(flag)) {
            flags += flag;
          } else {
            throw new ParseError(`Unknown regex flag: "${flag}"`);
          }
        }
      }
      let regex = new RegExp(args[1], flags);
      return createListFromArray(haystack.split(regex).map(x => x === undefined ? '' : x));
    }
  },
  'replaceregex': {
    description: 'Replaces matches of a regular expression in a string',
    output: 'string',
    placeholder: 'replaceregex("ID: 1234", "\\d", "X")',
    parts: ['replaceregex(', ', "\\d", "X")'],
    category: 'string',
    args: (args) => {
      if (args.length !== 3 && args.length !== 4) {
        return '"replaceregex" requires 3 or 4 arguments';
      }
      return args.map(toStr);
    },
    fn: async (args) => {
      let haystack = args[0];
      let flags = 'u';
      if (args[3]) {
        for (let flag of args[3].split('')) {
          if (['i', 'g', 's'].includes(flag)) {
            flags += flag;
          } else {
            throw new ParseError(`Unknown regex flag: "${flag}"`);
          }
        }
      }
      
      let replacer = args[2];
      let regex = new RegExp(args[1], flags);
      return haystack.replace(regex, replacer);
    }
  },
  'comparestrings': {
    placeholder: 'comparestrings("dog", "cat")',
    parts: ['comparestrings(', ', "cat")'],
    category: 'string',
    output: 'number',
    description: 'Compare two strings and return a number indicating which is greater',
    args: [toStr, toStr],
    fn: (args) => {
      return args[0].localeCompare(args[1]);
    }
  },

  /** DATE/TIME FUNCTIONS */

  'datetimeparse': {
    description: 'Parse a date to the standard format',
    output: 'string',
    placeholder: 'datetimeparse("Jan 23, 2020", "MMM D, YYYY")',
    parts: ['datetimeparse(', ', "MMM D, YYYY")'],
    category: 'date',
    args: [toStr, toStr],
    fn: (args, env) => {
      let date = moment(args[0], args[1], env.config.locale);

      if (!date.isValid()) {
        throw new ParseError(`Cannot directly convert ${args[0]} to a date with the format ${args[1]}.`);
      }

      return convertISOtoSQLstandard(date.toISOString(true));
    }
  },
  'datetimeformat': {
    description: 'Format a date that is in the standard format',
    output: 'string',
    placeholder: 'datetimeformat("2020-01-23", "MMM D, YYYY")',
    parts: ['datetimeformat(', ', "MMM D, YYYY")'],
    category: 'date',
    args: (args) => {
      if (args.length !== 2 && args.length !== 3) {
        return '"datetimeformat" requires 2 or 3 arguments';
      }
      if (args.length === 2) {
        return [toDate(args[0]), toStr(args[1])];
      }
      const getOffset = (timeZone, date) => {
        try {
          const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
          const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
          let offsetMin = (tzDate.getTime() - utcDate.getTime()) / 6e4;
          return offsetMin;
        } catch (e) {
          if (e instanceof RangeError) {
            if (/^[+-]\d{2}:\d{2}$/.test(timeZone)) {
              // User passed timezone in numeric format (e.g. +02:30)
              return timeZone;
            }
          }
          throw e;
        }
      };
      try {
        return [toDate(args[0]), toStr(args[1]), getOffset(toStr(args[2]), moment(toDate(args[0]), [moment.ISO_8601]).toDate())];
      } catch (e) {
        throw new ParseError(`Invalid time zone specified: ${args[2]}`);
      }
    },
    fn: (args) => {
      if (args.length === 2) {
        return moment(args[0], [moment.ISO_8601]).format(args[1]);
      }
      return moment(args[0], [moment.ISO_8601]).utcOffset(args[2]).format(args[1]);
    }
  },
  'datetimeadd': {
    description: 'Add a period to a date that is in the standard format',
    output: 'string',
    placeholder: 'datetimeadd("2020-01-23", 9, "D")',
    parts: ['datetimeadd(', ', 9, "D")'],
    category: 'date',
    args: [toDate, toNum, toStr],
    fn: (args) => {
      let units = toDateShiftUnits(args[2]);
  
      let date = moment(args[0], [moment.ISO_8601]);
      applyTimeShift(date, [{
        shift: args[1],
        units
      }]);

      return convertISOtoSQLstandard(date.toISOString(true));
    }
  },
  'datetimediff': {
    description: 'Find the difference between two dates in the standard format',
    output: 'number',
    placeholder: 'datetimediff("2020-01-23", "2020-02-01", "D")',
    parts: ['datetimediff(', ', "2020-02-01", "D")'],
    category: 'date',
    args: [toDate, toDate, toStr],
    fn: (args) => {
      let units = toDateShiftUnits(args[2]);

      // @ts-ignore
      return moment(args[1], [moment.ISO_8601]).diff(moment(args[0], [moment.ISO_8601]), units);
    }
  },
  'today': {
    description: 'Get today\'s date',
    output: 'date',
    placeholder: 'today()',
    parts: null,
    category: 'date',
    args: [],
    fn: (_args, env) => {
      if (env.config.useRealtimeDates) {
        return moment(new Date()).utc().format('YYYY-MM-DD');
      } else {
        return moment(env.config.date).utc().format('YYYY-MM-DD');
      }
    }
  },
  'now': {
    description: 'Get the current date and time',
    output: 'string',
    placeholder: 'now()',
    parts: null,
    category: 'date',
    args: [],
    fn: () => {
      return moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS+00');
    }
  },

  /** MATH FUNCTIONS */


  'round': {
    description: 'Round a number',
    category: 'number',
    output: 'number',
    placeholder: 'round(1.3)',
    parts: ['round(', ')'],
    args: (args) => {
      args = args.map(toNum);

      if (args.length !== 1 && args.length !== 2) {
        return '"round" requires 1 or 2 arguments';
      }

      if (args.length === 2) {
        if (args[1] < 0) {
          return 'Second argument to "round" cannot be less than 0';
        }
        if (args[1] !== Math.round(args[1])) {
          return 'Second argument to "round" must be an integer';

        }
      }

      return args;
    },
    fn: (args) => {
      if (args.length === 1) {
        return Math.round(args[0]);
      } else {
        return args[0].toFixed(args[1]);
      }
    }
  },
  'ceil': {
    description: 'Round a number up',
    category: 'number',
    output: 'number',
    placeholder: 'ceil(1.3)',
    parts: ['ceil(', ')'],
    args: [toNum],
    fn: (args) => Math.ceil(args[0])
  },
  'floor': {
    description: 'Round a number down',
    category: 'number',
    output: 'number',
    placeholder: 'floor(1.3)',
    parts: ['floor(', ')'],
    args: [toNum],
    fn: (args) => Math.floor(args[0])
  },
  'sqrt': {
    description: 'Square root of a number',
    category: 'number',
    output: 'number',
    placeholder: 'sqrt(1.3)',
    parts: ['sqrt(', ')'],
    args: [toNum],
    validate: (args) => {
      if (args[0] < 0) {
        return 'x must be >= 0 for sqrt(x).';
      }
    },
    fn: (args) => Math.sqrt(args[0])
  },
  'abs': {
    description: 'Absolute value of a number',
    category: 'number',
    output: 'number',
    placeholder: 'abs(1.3)',
    parts: ['abs(', ')'],
    args: [toNum],
    fn: (args) => Math.abs(args[0])
  },
  'isodd': {
    description: 'Whether a number is odd',
    category: 'number',
    output: 'number',
    placeholder: 'isodd(3)',
    parts: ['isodd(', ')'],
    args: [toNum],
    fn: (args) => args[0] % 2 === 1 || args[0] % 2 === -1
  },
  'iseven': {
    description: 'Whether a number is even',
    category: 'number',
    output: 'number',
    placeholder: 'iseven(3)',
    parts: ['iseven(', ')'],
    args: [toNum],
    fn: (args) => args[0] % 2 === 0
  },
  'remainder': {
    description: 'The remainder of a division',
    category: 'number',
    output: 'number',
    alias: 'modulo',
    placeholder: 'remainder(13, 4)',
    parts: ['remainder(', ', 4)'],
    args: [toNum, toNum],
    fn: (args) => args[0] % args[1]
  },
  'max': {
    description: 'Maximum of two or more numbers',
    category: 'number',
    output: 'number',
    placeholder: 'max(7, 5)',
    parts: ['max(', ', 5)'],
    args: (args) => {
      if (!args.length) {
        return '"max" requires at least one argument';
      }
      if (args.length === 1) {
        let lst;
        try {
          lst = toLst(args[0]);
        } catch (_) {
          // pass
        }
        if (lst) {
          if (isKeyedList(lst)) {
            throw new ParseError('You cannot take the max of a list with keyed elements.');
          }
          return lst;
        }
      }
      return args.map(toNum);
    },
    fn: (args) => {
      if (Array.isArray(args)) {
        return Math.max(...args);
      } else {
        return Math.max(...args.positional.map(x => toNum(x)));
      }
    }
  },
  'min': {
    description: 'Minimum of two or more numbers',
    category: 'number',
    output: 'number',
    placeholder: 'min(7, 5)',
    parts: ['min(', ', 5)'],
    args: (args) => {
      if (!args.length) {
        return '"min" requires at least one argument';
      }
      if (args.length === 1) {
        let lst;
        try {
          lst = toLst(args[0]);
        } catch (_) {
          // pass
        }
        if (lst) {
          if (isKeyedList(lst)) {
            throw new ParseError('You cannot take the min of a list with keyed elements.');
          }
          return lst;
        }
      }
      return args.map(toNum);
    },
    fn: (args) => {
      if (Array.isArray(args)) {
        return Math.min(...args);
      } else {
        return Math.min(...args.positional.map(x => toNum(x)));
      }
    }
  },
  'random': {
    placeholder: 'random()',
    parts: null,
    output: 'number',
    description: 'A random number between 0 and 1',
    category: 'number',
    args: args => {
      if (args.length > 1) {
        return '"random" must have 0 or 1 arguments';
      }
      return args.map(toStr);
    },
    fn: (args, env) => {
      let str = env.config.randomSeed + ' ::: ' + (args.length ? args[0] : env.locationString());

      return parseInt(md5(str).slice(0, 8), 16) / (2 ** 32 - 1);
    }
  },
  'ln': {
    description: 'Natural logarithm of a number',
    category: 'number',
    output: 'number',
    placeholder: 'ln(1.3)',
    parts: ['ln(', ')'],
    args: [toNum],
    validate: (args) => {
      if (args[0] <= 0) {
        return 'x must be > 0 for ln(x)';
      }
    },
    fn: (args) => Math.log(args[0])
  },
  'log': {
    description: 'Base-10 logarithm of a number',
    category: 'number',
    output: 'number',
    placeholder: 'log(1.3)',
    parts: ['log(', ')'],
    args: [toNum],
    validate: (args) => {
      if (args[0] <= 0) {
        return 'x must be > 0 for log(x)';
      }
    },
    fn: (args) => Math.log(args[0]) / Math.log(10)
  },
  'sin': {
    description: 'Sine of a number',
    alias: 'trigonometry',
    category: 'number',
    output: 'number',
    placeholder: 'sin(1.3)',
    parts: ['sin(', ')'],
    args: [toNum],
    fn: (args) => Math.sin(args[0] * (Math.PI / 180))
  },
  'cos': {
    description: 'Cosine of a number',
    alias: 'trigonometry',
    category: 'number',
    output: 'number',
    placeholder: 'cos(1.3)',
    parts: ['cos(', ')'],
    args: [toNum],
    fn: (args) => Math.cos(args[0] * (Math.PI / 180))
  },
  'tan': {
    description: 'Tangent of a number',
    alias: 'trigonometry',
    category: 'number',
    output: 'number',
    placeholder: 'tan(1.3)',
    parts: ['tan(', ')'],
    args: [toNum],
    fn: (args) => Math.tan(args[0] * (Math.PI / 180))
  },
  'asin': {
    description: 'Arcsine of a number',
    alias: 'trigonometry',
    category: 'number',
    output: 'number',
    placeholder: 'asin(1.3)',
    parts: ['asin(', ')'],
    args: [toNum],
    validate: (args) => {
      if (args[0] < -1 || args[0] > 1) {
        return 'x be between -1 and 1 for asin(x)';
      }
    },
    fn: (args) => Math.asin(args[0]) / (Math.PI / 180)
  },
  'acos': {
    description: 'Arccosine of a number',
    alias: 'trigonometry',
    category: 'number',
    output: 'number',
    placeholder: 'acos(1.3)',
    parts: ['acos(', ')'],
    args: [toNum],
    validate: (args) => {
      if (args[0] < -1 || args[0] > 1) {
        return 'x be between -1 and 1 for acos(x)';
      }
    },
    fn: (args) => Math.acos(args[0]) / (Math.PI / 180)
  },
  'atan': {
    description: 'Arctangent of a number',
    alias: 'trigonometry',
    category: 'number',
    output: 'number',
    placeholder: 'atan(1.3)',
    parts: ['atan(', ')'],
    args: [toNum],
    fn: (args) => Math.atan(args[0]) / (Math.PI / 180)
  },
  'base': {
    description: 'Express numbers in different numeric bases (e.g. hexadecimal)',
    category: 'number',
    output: 'string',
    placeholder: 'base(123, 16)',
    parts: ['base(', ', 16)'],
    args: (args) => {
      args = args.map(toNum);

      if (args.length !== 2 && args.length !== 3) {
        return '"base" requires 2 or 3 arguments';
      }
      let min = args[2];

      if (args[1] < 2 || args[1] > 36) {
        return 'The base in base(num, base) must be between 2 and 36';
      }
      if (Math.floor(args[0]) !== args[0] || Math.floor(args[1]) !== args[1] || (min !== undefined &&  Math.floor(min) !== min)) {
        return 'The arguments to base(num, base, min) must be integers';
      }

      if (min < 0 || args[0] < 0) {
        return 'The num and min in base(num, base, min) must be greater than 0';
      }

      return args;
    },
    fn: (args) => {
      let res = args[0].toString(args[1]);
      if (args[2] !== undefined) {
        res = res.padStart(args[2], '0');
      }
      return res;
    }
  },

  /* LIST FUNCTIONS */

  'count': {
    description: 'Count the elements in a list',
    category: 'list',
    alias: 'length',
    output: 'number',
    placeholder: 'count([1, 2, 3])',
    parts: ['count(', ')'],
    args: [toLstArg],
    fn: (args) => {
      let base = args[0];
      return base.positional.length + Object.keys(base.keys).length;
    }
  },
  'split': {
    description: 'Convert a string to a list by splitting on a substring',
    category: 'list',
    alias: 'splitregex',
    output: 'list',
    placeholder: 'split("a,b,c", ",")',
    parts: ['split(', ', ",")'],
    args: [toStr, toStr],
    fn: (args) => {
      let base = args[0];
      return createListFromArray(base.split(args[1]));
    }
  },
  'join': {
    description: 'Join the elements of a list together into a string',
    category: 'list',
    alias: 'concat merge',
    output: 'string',
    placeholder: 'join([1, 2, 3], "-")',
    parts: ['join(', ', "-")'],
    args: [toLstArg, toStr],
    fn: (args, env) => {
      let base = args[0];
      let type = args[1];

      if (isKeyedList(base)) {
        throw new ParseError('You cannot join a list with keyed elements');
      }

      let items = base.positional.map(toStr);


      let formatter;
      if (type === 'BLAZE_AND') {
        if (!env.config.andFormatter) {
          // @ts-ignore
          env.config.andFormatter = new Intl.ListFormat(getLocale(env.config.locale).code, { style: 'long', type: 'conjunction' });
        }
        formatter = env.config.andFormatter;
      } else if (type === 'BLAZE_OR') {
        if (!env.config.orFormatter) {
          // @ts-ignore
          env.config.orFormatter = new Intl.ListFormat(getLocale(env.config.locale).code, { style: 'long', type: 'disjunction' });
        }
        formatter = env.config.orFormatter;
      }

      if (formatter) {
        return formatter.format(items);
      }

      return items.join(type);
    }
  },
  'sum': {
    description: 'Sum the elements of a list',
    category: 'list',
    output: 'number',
    placeholder: 'sum([1, 4, 9])',
    parts: ['sum(', ')'],
    args: [toLstArg],
    fn: (args) => {
      let base = args[0];
      if (isKeyedList(base)) {
        throw new ParseError('You cannot sum a list with keyed elements.');
      }
      let sum = 0;
      for (let i = 0; i < base.positional.length; i++) {
        sum += toNum(base.positional[i]);
      }
      return sum;
    }
  },
  'keys': {
    description: 'Get the keys of a named list',
    category: 'list',
    output: 'list',
    placeholder: 'keys(["x": 1, "y": 2])',
    parts: ['keys(', ')'],
    args: [toLstArg],
    fn: (args) => {
      return createListFromArray(Object.keys(args[0].keys));
    }
  },
  'includes': {
    description: 'Check if a list contains an element',
    category: 'list',
    alias: 'contains',
    output: 'boolean',
    placeholder: 'includes(["x", "y", "z"], "y")',
    parts: ['includes(', ', "y")'],
    args: [toLstArg, (x) => x],
    fn: async (args) => {
      async function arrIncludes(haystack, needle) {
        for (let i = 0; i < haystack.length; i++) {
          try {
            if (equals(haystack[i], needle)) {
              return true;
            }
          } catch (_) {}
        }
        return false;
      }
      return (await arrIncludes(args[0].positional, args[1])) || (await arrIncludes(Object.values(args[0].keys), args[1]));
    }
  },
  'seq': {
    description: 'Generate a list of sequential numbers',
    category: 'list',
    output: 'list',
    placeholder: 'seq(1, 5, 0.5)',
    parts: ['seq(', ', 5, 0.5)'],
    args: (args) => {
      if (![2, 3].includes(args.length)) {
        return '"seq" takes either 2 or 3 arguments';
      }
      let startN = toNum(args[0]);
      let endN = toNum(args[1]);
      let stepN = startN < endN ? 1 : -1;
      if (args.length === 3) {
        stepN = toNum(args[2]);
      }
      if (args.length === 3) {
        if (startN < endN) {
          if (stepN <= 0) {
            return 'The step must be a positive number';
          }
        } else if (startN > endN) {
          if (stepN >= 0) {
            return 'The step must be a negative number';
          }
        }
      }
      return [startN, endN, stepN];
    },
    fn: (args) => {
      let arr = [];

      let steps = Math.floor((args[1] - args[0]) / args[2]);
      arr.push(+args[0]);
      
      for (let i = 1; i <= steps; i++) {
        arr.push(+(arr[arr.length - 1] + args[2]).toPrecision(15));
      }
      return createListFromArray(arr);
    }
  },
  'slice': {
    description: 'Get part of a list',
    category: 'list',
    output: 'list',
    placeholder: 'slice(["x", "y", "z"], 1, 2)',
    parts: ['slice(', ', 1, 2)'],
    args: async (args, env) => {
      if (![2, 3].includes(args.length)) {
        return '"slice" takes either 2 or 3 arguments';
      }
      let base = await toLstArg(args[0], env);
      if (isKeyedList(base)) {
        return '"slice" cannot operate on keyed lists';
      }
      let start = toNum(args[1]);
      let last;
      if (args.length === 3) {
        last = toNum(args[2]);
      }
      if (Math.round(start) !== start || start < 1) {
        return 'Start position must be a positive integer';
      }
      if (args.length === 3) {
        if (Math.round(last) !== last || last < 0) {
          return 'Last must be a non-zero integer';
        }
      }
      return [base, start, last];
    },
    fn: (args) => {
      return createListFromArray(args[0].positional.slice(args[1] - 1, args[2]));
    }
  },
  'location': {
    description: 'Find the location of an element in a list',
    category: 'list',
    alias: 'search find',
    output: 'number',
    placeholder: 'location(["x", "y", "z"], "y")',
    parts: ['location(', ', "y")'],
    args: [toLstArg, (x) => x],
    fn: async (args) => {
      async function arrIncludes(haystack, needle) {
        for (let i = 0; i < haystack.length; i++) {
          try {
            if (equals(haystack[i], needle)) {
              return i + 1;
            }
          } catch (_) {}
        }
        return false;
      }
      let pLoc = await arrIncludes(args[0].positional, args[1]);
      if (pLoc) {
        return pLoc;
      }
      let kLoc = await arrIncludes(Object.values(args[0].keys), args[1]);
      if (kLoc) {
        return Object.keys(args[0].keys)[kLoc - 1];
      }
      throw new ParseError('Item not found in the list.');
    }
  },
  'find': {
    description: 'Find the first list element matching a condition',
    category: 'list',
    output: 'object',
    alias: 'search find',
    placeholder: 'find([1, 4, 9], x -> x > 3)',
    parts: ['find(', ', x -> x > 3)'],
    args: [toLstArg, toFn],
    fn: async (args, env) => {
      if (Object.keys(args[0].keys).length) {
        throw new ParseError('Cannot use find() on a keyed list.');
      }

      let res = await findAsync(args[0].positional, async (x, i) => await callFn(args[1], [x, i + 1], env.derivedLocation('find - ' + i)));

      if (res !== null) {
        return res;
      } else {
        throw new ParseError('Item not found in the list.');
      }
    }
  },
  'merge': {
    description: 'Merge two or more lists together',
    category: 'list',
    output: 'list',
    alias: 'join concat',
    placeholder: 'merge(["x"], ["y", "z"])',
    parts: ['merge(', ', ["y", "z"])'],
    args: async (args, env) => args.length ? (await Promise.all(args.map(x => toLstArg(x, env)))) : '"merge" requires at least one argument',
    fn: (args) => {
      let res = new List([].concat(...args.map(x => x.positional)), Object.assign(Object.create(null), ...args.map(x => x.keys)));

      if (res.positional.length && Object.keys(res.keys).length) {
        throw new ParseError('Cannot merge an ordered list with a keyed list.');
      }

      return res;
    }
  },
  'filter': {
    description: 'Filter the elements of a list',
    category: 'list',
    output: 'list',
    placeholder: 'filter([1, 4, 9], (x) -> x > 3)',
    parts: ['filter(', ', (x) -> x > 3)'],
    args: [toLstArg, toFn],
    fn: async (args, env) => {
      let newLst = new List();
      newLst.positional = await filterAsync(args[0].positional, async (x, i) => await callFn(args[1], [x, i + 1], env.derivedLocation('filter - ' + i)));

      for (let key in args[0].keys) {
        if (await callFn(args[1], [args[0].keys[key], key], env.derivedLocation('filter - ' + key))) {
          newLst.keys[key] = args[0].keys[key];
        }
      }
      return newLst;
    }
  },
  'map': {
    description: 'Transform elements of a list',
    category: 'list',
    output: 'list',
    placeholder: 'map([1, 4, 9], (x) -> sqrt(x))',
    parts: ['map(', ', (x) -> sqrt(x))'],
    args: [toLstArg, toFn],
    fn: async (args, env) => {
      let newLst = new List();
      newLst.positional = await Promise.all(args[0].positional.map(async (x, i) => {
        return await callFn(args[1], [x, i + 1], env.derivedLocation('map - ' + i));
      }));
      for (let key in args[0].keys) {
        newLst.keys[key] = await callFn(args[1], [args[0].keys[key], key], env.derivedLocation('map - ' + key));
      }
      return newLst;
    }
  },
  'reduce': {
    description: 'Reduce a list',
    category: 'list',
    output: 'any',
    placeholder: 'reduce([1, 4, 9], 0, (acc, x) -> acc + x)',
    parts: ['reduce(', ', 0, (acc, x) -> acc + x)'],
    args: [toLstArg, (x) => x, toFn],
    fn: async (args, env) => {
      let lst = args[0];
      let accumulator = args[1];
      let fn = args[2];
      for (let i = 0; i < lst.positional.length; i++) {
        accumulator = await callFn(fn, [accumulator, lst.positional[i], i + 1], env.derivedLocation('reduce - ' + i));
      }
      for (let key in args[0].keys) {
        accumulator = await callFn(fn, [accumulator, lst.keys[key], key], env.derivedLocation('reduce - ' + key));
      }
      return accumulator;
    }
  },
  'sort': {
    description: 'Sort a list',
    output: 'list',
    placeholder: 'sort([1, 9, 4], (a, b) -> b - a)',
    parts: ['sort(', ', (a, b) -> b - a)'],
    category: 'list',
    args: [toLstArg, toFn],
    fn: async (args, env) => {
      let lst = args[0];
      if (isKeyedList(lst)) {
        throw new ParseError('You cannot sort a list with keyed elements.');
      }

      lst = lst.positional.slice();

      let counter = 0;

      lst = await quickSort(lst, (a, b) => callFn(args[1], [a, b], env.derivedLocation('sort - ' + counter++)));

      return new List(lst);
    }
  },
  'unique': {
    description: 'Return only unique values from a list',
    output: 'list',
    category: 'list',
    placeholder: 'unique(["a", "b", "a"])',
    parts: ['unique(', ')'],
    args: [toLstArg],
    fn: async (args) => {
      let lst = args[0];
      if (isKeyedList(lst)) {
        throw new ParseError('You cannot find unique elements in a list with keyed elements.');
      }
      // Return only unique values from a list, picks only the first item among duplicates
      // To avoid complexity, we will reuse the `equals` function

      const res = [];
      // The below loop is O(n^2), which will run fast for n<=1000 in practice
      // There is no clear way to optimize this because of the dynamic nature of `equals`
      // One way would be to coerce all seen values to a string and store in a hashmap
      // But we'll avoid added complexity if it's not required
      for (const item of lst.positional) {
        let exists = false;
        for (const existingItem of res) {
          if (equals(item, existingItem)) {
            exists = true;
            break;
          }
        }
        if (!exists) {
          res.push(item);
        }
      }

      return createListFromArray(res);
    }
  },

  /* STATISTICAL FUNCTIONS */

  'average': {
    description: 'The average (mean) of the elements in a list',
    category: 'statistics',
    alias: 'median',
    output: 'number',
    placeholder: 'average([1, 4, 9])',
    parts: ['average(', ')'],
    args: [toLstArg],
    fn: (args) => {
      let base = args[0];
      if (isKeyedList(base)) {
        throw new ParseError('You cannot average a list with keyed elements.');
      }
      let sum = 0;
      for (let i = 0; i < base.positional.length; i++) {
        sum += toNum(base.positional[i]);
      }
      return sum / base.positional.length;
    }
  },
  'median': {
    description: 'The median of the elements in a list',
    category: 'statistics',
    alias: 'average',
    output: 'number',
    placeholder: 'median([1, 4, 9])',
    parts: ['median(', ')'],
    args: [toLstArg],
    fn: (args) => {
      let base = args[0];
      if (isKeyedList(base)) {
        throw new ParseError('You cannot find the median of a list with keyed elements.');
      }
      let values = base.positional.map(x => toNum(x));

      if (values.length === 0) {
        return 0;
      }

      values.sort((a, b) => a - b);

      let half = Math.floor(values.length / 2);

      if (values.length % 2) {
        return values[half];
      }

      return (values[half - 1] + values[half]) / 2;
    }
  },
  'stddevpop': {
    description: 'The population standard deviation of the elements in a list',
    category: 'statistics',
    alias: 'variance population',
    output: 'number',
    placeholder: 'stddevpop([1, 4, 9])',
    parts: ['stddevpop(', ')'],
    args: [toLstArg],
    fn: (args) => {
      let base = args[0];
      if (isKeyedList(base)) {
        throw new ParseError('You cannot calculate the population standard deviation of a list with keyed elements.');
      }
      let values = base.positional.map(x => toNum(x));
      return stddev(values, false);
    }
  },
  'variancepop': {
    description: 'The population variance of the elements in a list',
    category: 'statistics',
    alias: 'standard deviation population',
    output: 'number',
    placeholder: 'variancepop([1, 4, 9])',
    parts: ['variancepop(', ')'],
    args: [toLstArg],
    fn: (args) => {
      let base = args[0];
      if (isKeyedList(base)) {
        throw new ParseError('You cannot calculate the population variance of a list with keyed elements.');
      }
      let values = base.positional.map(x => toNum(x));
      return variance(values, false);
    }
  },
  'stddevsamp': {
    description: 'The sample standard deviation of the elements in a list',
    category: 'statistics',
    alias: 'variance sample',
    output: 'number',
    placeholder: 'stddevsamp([1, 4, 9])',
    parts: ['stddevsamp(', ')'],
    args: [toLstArg],
    fn: (args) => {
      let base = args[0];
      if (isKeyedList(base)) {
        throw new ParseError('You cannot calculate the sample standard deviation of a list with keyed elements.');
      }
      let values = base.positional.map(x => toNum(x));
      return stddev(values, true);
    }
  },
  'variancesamp': {
    description: 'The sample variance of the elements in a list',
    category: 'statistics',
    alias: 'standard deviation sample',
    output: 'number',
    placeholder: 'variancesamp([1, 4, 9])',
    parts: ['variancesamp(', ')'],
    args: [toLstArg],
    fn: (args) => {
      let base = args[0];
      if (isKeyedList(base)) {
        throw new ParseError('You cannot calculate the sample variance of a list with keyed elements.');
      }
      let values = base.positional.map(x => toNum(x));
      return variance(values, true);
    }
  },
  'normdist': {
    description: 'Normal distribution function for a number given mean and standard deviation',
    alias: 'tdist normal standard standardnormal gaussian normdistribution norminverse distribution inverse',
    category: 'statistics',
    output: 'number',
    placeholder: 'normdist(0.5, 0, 1, yes)',
    parts: ['normdist(', ', 0, 1, yes)'],
    args: (args) => {
      if (args.length !== 4) {
        return '"normdist" requires three arguments (num, mean, standard_deviation, cumulative)';
      }
      args[0] = toNum(args[0]);
      args[1] = toNum(args[1]);
      args[2] = toNum(args[2]);
      args[3] = toBool(args[3]);
      return args;
    },
    fn: (args) => args[3] ? normal.cdf(args[0], args[1], args[2]) : normal.pdf(args[0], args[1], args[2])
  },
  'norminv': {
    description: 'Inverse normal distribution function for a number given mean and standard deviation',
    alias: 'tinv normal standard standardnormal gaussian normdistribution norminverse distribution inverse',
    category: 'statistics',
    output: 'number',
    placeholder: 'norminv(0.5, 0, 1)',
    parts: ['norminv(', ', 0, 1)'],
    args: (args) => {
      args = args.map(toNum);
      if (args.length !== 3) {
        return '"norminv" requires three arguments (num, mean, standard_deviation)';
      }
      if (args[0] <= 0 || args[0] >= 1) {
        return '"norminv" requires num to be between 0 and 1 exclusive';
      }
      return args;
    },
    fn: (args) => normal.inv(args[0], args[1], args[2])
  },
  'tdist': {
    description: 'Left-tailed Student\'s t-distribution function for a number given degrees of freedom',
    alias: 'normdist students studentt t-distribution t-inverse tdistribution tinverse distribution inverse',
    category: 'statistics',
    output: 'number',
    placeholder: 'tdist(0.5, 1, yes)',
    parts: ['tdist(', ', 1, yes)'],
    args: (args) => {
      if (args.length !== 3) {
        return '"tdist" requires three arguments (num, degrees_freedom, cumulative)';
      }
      args[0] = toNum(args[0]);
      args[1] = toNum(args[1]);
      args[2] = toBool(args[2]);
      if (args[1] <= 0) {
        return '"tdist" requires the degrees_freedom to be greater than 0';
      }
      return args;
    },
    fn: (args) => args[2] ? studentt.cdf(args[0], args[1]) : studentt.pdf(args[0], args[1])
  },
  'tinv': {
    description: 'Left-tailed inverse Student\'s t-distribution function for a number given degrees of freedom',
    alias: 'norminv students studentt t-distribution t-inverse tdistribution tinverse distribution inverse',
    category: 'statistics',
    output: 'number',
    placeholder: 'tinv(0.5, 1)',
    parts: ['tinv(', ', 1)'],
    args: (args) => {
      args = args.map(toNum);
      if (args.length !== 2) {
        return '"tinv" requires two arguments (num, degrees_freedom)';
      }
      if (args[0] <= 0 || args[0] >= 1) {
        return '"tinv" requires the num to be between 0 and 1 exclusive';
      }
      if (args[1] <= 0) {
        return '"tinv" requires the degrees_freedom to be greater than 0';
      }
      return args;
    },
    fn: (args) => studentt.inv(args[0], args[1])
  },
  'ttest': {
    description: 'The probability associated with a Student\'s t-test',
    category: 'statistics',
    alias: 'ztest t-test students studentt studenttest t-distribution tdistribution tinverse p-value pvalue',
    output: 'number',
    placeholder: 'ttest([1, 2, 3], [0, 0, 0], 2, "HOMOSCEDASTIC")',
    parts: ['ttest(', ', [0, 0, 0], 2, "HOMOSCEDASTIC")'],
    args: async (args, env) => {
      if (args.length !== 4) {
        return '"ttest" requires three arguments (list1, list2, tails, type ["PAIRED", "HOMOSCEDASTIC", "HETEROSCEDASTIC"])';
      }
      args[0] = await toLstArg(args[0], env);
      args[1] = await toLstArg(args[1], env);
      args[2] = toNum(args[2]);
      args[3] = toStr(args[3]);
      if (isKeyedList(args[0]) || isKeyedList(args[1])) {
        throw new ParseError('You cannot run t-test on a list with keyed elements.');
      }
      if (![1, 2].includes(args[2])) {
        return '"ttest" requires the tails to be either 1 or 2';
      }
      if (!['PAIRED', 'HOMOSCEDASTIC', 'HETEROSCEDASTIC'].includes(args[3])) {
        return '"ttest" requires the type to be either "PAIRED", "HOMOSCEDASTIC", or "HETEROSCEDASTIC"';
      }
      return args;
    },
    fn: (args) => {
      /*
        Sources:
          1. https://www.investopedia.com/terms/t/t-test.asp
          2. https://academic.oup.com/beheco/article/17/4/688/215960
      */
      const values1 = args[0].positional.map(x => toNum(x));
      const values2 = args[1].positional.map(x => toNum(x));
      const len1 = values1.length;
      const len2 = values2.length;
      const mean1 = arrmean(values1);
      const mean2 = arrmean(values2);
      const variance1 = variance(values1);
      const variance2 = variance(values2);
      const tails = args[2];
      const type = args[3];
      let dof, tscore;
      if (type === 'PAIRED') {
        if (len1 !== len2) {
          throw new ParseError('"ttest" of type "PAIRED" requires the length of list1 and list2 to be equal');
        }
        dof = len1 - 1;
        const diff = values1.map((x, index) => x - values2[index]);
        tscore = (mean1 - mean2) / (stddev(diff) / Math.sqrt(len1 - 1));
      } else if (type === 'HOMOSCEDASTIC') {
        dof = len1 + len2 - 2;
        tscore = (mean1 - mean2) / Math.sqrt(((len1 * variance1 + len2 * variance2) / (len1 + len2 - 2)) * (1 / len1 + 1 / len2));
      } else if (type === 'HETEROSCEDASTIC') {
        dof = Math.pow(variance1 / (len1 - 1) + variance2 / (len2 - 1), 2) / ((Math.pow(variance1 / (len1 - 1), 2) / (len1 - 1) + Math.pow(variance2 / (len2 - 1), 2) / (len2 - 1)));
        tscore = (mean1 - mean2) / Math.sqrt(variance1 / (len1 - 1) + variance2 / (len2 - 1));
      }
      return tails * (1 - studentt.cdf(Math.abs(tscore), dof));
    }
  },
  'ztest': {
    /*
      Sources:
        1. https://builtin.com/data-science/z-test-statistics
    */
    description: 'The one-tailed p-value of a z-test with standard distribution',
    category: 'statistics',
    alias: 'ttest p-value pvalue standardnormal gaussian normdistribution norminverse',
    output: 'number',
    placeholder: 'ztest([1, -2, 3], 0, 1)',
    parts: ['ztest(', ', 0, 1)'],
    args: async (args, env) => {
      if (![2, 3].includes(args.length)) {
        return '"ztest" requires two or three arguments (list, num, standard_deviation (optional))';
      }
      args[0] = await toLstArg(args[0], env);
      args[1] = toNum(args[1]);
      if (args.length === 3) {
        args[2] = toNum(args[2]);
      }
      if (isKeyedList(args[0])) {
        throw new ParseError('You cannot run z-test on a list with keyed elements.');
      }
      return args;
    },
    fn: (args) => {
      let values = args[0].positional.map(x => toNum(x));
      const mean = arrmean(values);
      const sigma = (args.length === 3 ? args[2] : stddev(values, true));
      return 1 - normal.cdf(mean, args[1], sigma / Math.sqrt(values.length));
    }
  },

  /** MISC FUNCTIONS */

  'isnumber': {
    description: 'Whether a value is a number',
    category: 'other',
    output: 'boolean',
    placeholder: 'isnumber("1.2")',
    parts: ['isnumber(', ')'],
    args: [toStr],
    fn: (args) => {
      return couldBeNum(args[0]);
    }
  },
  'isboolean': {
    description: 'Whether a value is a boolean',
    category: 'other',
    output: 'boolean',
    placeholder: 'isboolean("yes")',
    parts: ['isboolean(', ')'],
    args: [toStr],
    fn: (args) => {
      return couldBeBool(args[0]);
    }
  },
  'islist': {
    description: 'Whether a value is a list',
    category: 'other',
    output: 'boolean',
    placeholder: 'islist("[1,2,3]")',
    parts: ['islist(', ')'],
    args: [toStr],
    fn: (args) => {
      return couldBeLst(args[0], true);
    }
  },
  'iserror': {
    description: 'Whether a value is an error',
    category: 'other',
    output: 'boolean',
    placeholder: 'iserror("abc" + 123)',
    parts: ['iserror(', ')'],
  },
  'urlencode': {
    description: 'Format a string for inclusion in a URL',
    category: 'other',
    output: 'string',
    placeholder: 'urlencode("a value")',
    parts: ['urlencode(', ')'],
    args: [toStr],
    fn: (args) => {
      return encodeURIComponent(args[0]);
    }
  },
  'urldecode': {
    description: 'Converts a URL formatted string to plain text',
    category: 'other',
    output: 'string',
    placeholder: 'urldecode("a%20value")',
    parts: ['urldecode(', ')'],
    args: [toStr],
    fn: (args) => {
      return decodeURIComponent(args[0]);
    }
  },
  'base64encode': {
    description: 'Base 64 encode a string',
    category: 'other',
    output: 'string',
    placeholder: 'base64encode("hello")',
    parts: ['base64encode(', ')'],
    args: [toStr],
    fn: (args) => {
      if (import.meta.env.VITE_APP_BUILD_TYPE === 'api') {
        // node
        return Buffer.from(args[0], 'binary').toString('base64');
      } else {
        // browser
        return btoa(args[0]);
      }
    }
  },
  'base64decode': {
    description: 'Base 64 decode a string',
    category: 'other',
    output: 'string',
    placeholder: 'base64decode("aGVsbG8=")',
    parts: ['base64decode(', ')'],
    args: [toStr],
    fn: (args) => {
      try {
        if (import.meta.env.VITE_APP_BUILD_TYPE === 'api') {
          // node
          return Buffer.from(args[0], 'base64').toString('binary'); 
        } else {
          // browser
          return atob(args[0]);
        }
      } catch (err) {
        throw new ParseError('Not a valid Base-64 string');
      }
    }
  },
  
  'sign': {
    description: '', // 'Create digital signature',
    category: 'other',
    output: 'string',
    placeholder: 'sign(["name": "HMAC"], ["format": "raw", "data": "XYZ123ABC", "algorithm": ["name": "HMAC", "hash": "SHA-256"]], "data", "hex")',
    parts: ['sign(', ', ["format": "raw", "data": "XYZ123ABC", "algorithm": ["name": "HMAC", "hash": "SHA-256"]], "data", "hex")'],
    args: [toLst, toLst, toStr, toStr],
    fn: async (args) => {
      let algorithm = args[0].keys.name;
      let key = args[1].keys;

      let output = args[3];
      if (!['base64', 'hex'].includes(output)) {
        throw new ParseError('Unknown output type: ' + output);
      }

      if (import.meta.env.VITE_APP_BUILD_TYPE === 'api') {
        // Only support subset of functionality on node.
        if (algorithm !== 'HMAC') {
          throw new ParseError('Only HMAC supported on Node.');
        }
        if (key.format !== 'raw') {
          throw new ParseError('Only raw key supported on Node.');
        }
        if (key.algorithm.keys.name !== 'HMAC') {
          throw new ParseError('Only HMAC key supported on Node.');
        }
        if (key.algorithm.keys.hash !== 'SHA-256') {
          throw new ParseError('Only SHA-256 key supported on Node.');
        }
        
        // The BUILD-API stuff is to make sure webpack doesn't see
        // the items when compiling for web. Otherwise it gets very confused
        // and bundles a bunch of polyfills.
        //
        // These lines will be enabled when building the API.
        /** @type {any} */
        let crypto;
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        crypto = require('crypto');
        return crypto // deepscan-disable-line
          .createHmac('sha256', key.data)
          .update(args[2], 'utf8')
          .digest(output);
      } else {
        const encoder = new TextEncoder();

        let keyObj = await crypto.subtle.importKey(key.format, new Uint8Array(key.data.split('').map(s => s.charCodeAt(0))), key.algorithm.keys, false, ['sign']);

        const data = encoder.encode(args[2]);

        let signed = await crypto.subtle.sign(algorithm, keyObj, data);
    
        if (output === 'hex') {
          return bufferToHex(signed);
        } else {
          return bufferToBase64(signed);
        }
      }
    }
  },
  'hash': {
    description: '', //'Create a hash of a string',
    category: 'other',
    output: 'string',
    placeholder: 'hash(["name": "SHA-256"], "data", "hex")',
    parts: ['hash(', ', "data", "hex")'],
    args: [toLst, toStr, toStr],
    fn: async (args) => {
      let algorithm = args[0].keys.name;
    
      if (!['SHA-1', 'SHA-256', 'SHA-384', 'SHA-512'].includes(algorithm)) {
        throw new ParseError('Unknown algorithm: ' + algorithm);
      }

      let output = args[2];
      if (!['base64', 'hex'].includes(output)) {
        throw new ParseError('Unknown output type: ' + output);
      }

      const encoder = new TextEncoder();
      const data = encoder.encode(args[1]);

      let hashed = await crypto.subtle.digest(algorithm, data);
  
      if (output === 'hex') {
        return bufferToHex(hashed);
      } else {
        return bufferToBase64(hashed);
      }
    }
  },
  'error': {
    description: 'Raise an error',
    alias: 'throw',
    category: 'other',
    output: 'error',
    placeholder: 'error("Failed calculation")',
    parts: ['error(', ')'],
    args: [toStr],
    fn: (args) => {
      throw new ParseError(args[0]);
    }
  },
  'catch': {
    description: 'Catches an error',
    alias: 'try',
    category: 'other',
    output: 'object',
    placeholder: 'catch(count(x), "x is not a list")',
    parts: ['catch(', ', "x is not a list")']
  },
  'notify': {
    description: '', // 'Shows a notification with the given string message',
    alias: 'notification alert message',
    category: 'other',
    output: 'boolean',
    placeholder: 'notify("My function was successful")',
    parts: ['notify(', ')'],
    args: (args) => {
      let message = '', title = '';
      try {
        if (args.length < 1 || args.length > 3) {
          throw new ParseError('Please provide 1 or 2 arguments');
        }
        message = toStr(args[0]);
        if (!message) {
          throw new ParseError('Provided message is empty');
        }
        if (args.length === 2) {
          title = toStr(args[1]);
          if (!title) {
            throw new ParseError('Provided title is empty');
          }
        }
        return [message, title];
      } catch (e) {
        throw e;
      }
    },
    fn: (args, env) => {
      const [message, titleGiven] = args;
      const title = titleGiven || 'Notification';
      if (!env.config.isOneoffFormula) {
        throw new ParseError('Cannot call notify() outside of a code block');
      }
      if (!env.config.showNotification) {
        // Community/blog, for example
        throw new ParseError('Cannot show a notification in this context');
      }
      env.config.showNotification({ message, title });
    }
  },

  'focus': {
    description: '', // 'Focuses a form field',
    alias: 'highlight select',
    category: 'other',
    output: 'boolean',
    placeholder: 'focus("form field name")',
    parts: ['focus("', '")'],
    args: [toStr],
    fn: (args, env) => {
      const [fieldName] = args;
      if (!env.config.isOneoffFormula) {
        throw new ParseError('Cannot call focus() outside of a code block');
      }
      
      let el = document.querySelector('#field_renderer_' + CSS.escape(fieldName.toLowerCase()));

      if (el) {
        // @ts-ignore
        el.focus();
      } else {
        env.config.showNotification({ title: 'Could not focus', message: 'Could not find field named: "' + fieldName + '"' });
      }
    }
  },
  'fromjson': {
    description: 'Convert JSON to a list',
    category: 'other',
    output: 'list',
    placeholder: 'fromjson("[1, 2, 3]")',
    parts: ['fromjson(', ', 2, 3]")'],
    args: [toStr],
    fn: (args) => {
      let json = JSON.parse(args[0]);
      return objectToList(json);
    }
  },
  'tojson': {
    description: '',//'Convert a variable to JSON',
    category: 'other',
    output: 'object',
    placeholder: 'tojson(x, "string")',
    parts: ['tojson(', ', "string")'],
    args: [toStr, toStr],
    fn: (args) => {
      let data = args[0];
      let jsdoc = jsdocAst(args[1].trim());

      let toJson = (val, type) => {
        if (val === null || val === undefined) {
          if (type.nullable) {
            return null;
          } else {
            throw new ParseError('Missing the value for non-nullable property.');
          }
        }

        if (type.kind === 'primitive') {
          if (type.type === 'boolean') {
            return toBool(val);
          } else if (type.type === 'number') {
            return toNum(val);
          } else if (type.type === 'string') {
            return toStr(val);
          }
        } else if (type.kind === 'array') {
          let lst = toLst(val);
          if (isKeyedList(lst)) {
            throw new ParseError('Cannot convert a keyed list to a JSON array.');
          }
          
          let res = [];
          for (let item of lst.positional) {
            res.push(toJson(item, type.type));
          }
          return res;
        } else if (type.kind === 'object') {
          let lst = toLst(val);
          if (lst.positional.length) {
            throw new ParseError('Cannot convert a positional list to a JSON object.');
          }
          
          let res = Object.create(null);
          for (let key in type.type) {
            res[key] = toJson(lst.keys[key], type.type[key]);
          }
          return res;
        } else if (type.kind === 'map') {
          let lst = toLst(val);
          if (lst.positional.length) {
            throw new ParseError('Cannot convert a positional list to a JSON object.');
          }
          
          let res = Object.create(null);
          for (let key in lst.keys) {
            res[key] = toJson(lst.keys[key], type.type);
          }
          return res;
        }
      };

      return JSON.stringify(toJson(data, jsdoc));
    }
  }
};

/**
 * @param {List} lst 
 * @returns {boolean} true iff the given List is a keyed list
 */
function isKeyedList(lst) {
  return lst.keys && Object.keys(lst.keys).length > 0;
}

/**
 * Recursively converts a JavaScript object to a list.
 * 
 * @param {object} obj
 * 
 * @return {object} obj
 */
export function objectToList(obj) {
  if (typeof obj === 'string') {
    return obj;
  } else if (typeof obj === 'number') {
    return obj;
  } else if (typeof obj === 'boolean') {
    return obj;
  } else if (Array.isArray(obj)) {
    return createListFromArray(obj.map(x => objectToList(x)));
  } else if (obj === null || obj === undefined) {
    return '';
  } else {
    let res = Object.create(null);
    for (let key in obj) {
      res[key] = objectToList(obj[key]);
    }
    return createListFromObject(res);
  }
}


/**
 * Whether a given value could be a number.
 * 
 * @param {*} val
 * 
 * @return {boolean}
 */
function couldBeNum(val) {
  if (typeof val === 'number') {
    return true;
  } else if ((typeof val === 'string') && val.trim().length) {
    let res = Number(val);
    if (!isNaN(res)) {
      return true;
    }
  }
  return false;
}


/**
 * Converts a value to a number.
 * 
 * @param {*} val
 * 
 * @return {number}
 */
export function toNum(val) {
  if (typeof val === 'number') {
    return val;
  } else if (typeof val === 'string') {
    // Google Sheets will convert "" to 0, but not " " (or other amount of whitespace)
    if (val === '') {
      return 0;
    } else if (val.trim().length) {
      let res = Number(val);
      if (!isNaN(res)) {
        return res;
      }
    }
  }
  if (val === true) {
    val = '"yes"';
  } else if (val === false) {
    val = '"no"';
  } else if (val.keys) {
    val = 'a list';
  } else if (val.type === 'lambda') {
    val = 'a function';
  } else if (val.type === 'error') {
    throw new ParseError(val.error);
  } else {
    val = `"${val}"`;
  }
  throw new ParseError(`Cannot convert ${val} to a number.`);
}


/**
 * Converts a value to a date.
 * 
 * @param {*} val
 * 
 * @return {string}
 */
export function toDate(val) {
  if (typeof val === 'string') {
    let date = moment(val, [moment.ISO_8601]);
    if (!date.isValid()) {
      throw new ParseError(`Cannot directly convert ${val} to a date, try datetimeparse().`);
    }
    return date.toISOString(true);
  }

  if (typeof val === 'number') {
    val = `${val}`;
  } else if (val === true) {
    val = '"yes"';
  } else if (val === false) {
    val = '"no"';
  } else if (val.keys) {
    val = 'a list';
  } else if (val.type === 'lambda') {
    val = 'a function';
  } else if (val.type === 'error') {
    throw new ParseError(val.error);
  } else {
    val = `"${val}"`;
  }
  throw new ParseError(`Cannot convert ${val} to a date.`);
}


/**
 * @param {string} str
 * 
 * @return {string}
 */

function toDateShiftUnits(str) {
  if (['Y', 'M', 'W', 'D', 'h', 'm', 's'].includes(str)) {
    return {
      'Y': 'years',
      'Q': 'quarters',
      'M': 'months',
      'W': 'weeks',
      'D': 'days',
      'h': 'hours',
      'm': 'minutes',
      's': 'seconds'
    }[str];
  }
  throw new ParseError(`"${str}" is not a valid datetime unit. You can use "Y" (years), "M" (months), "W" (weeks), "D" (days), "h" (hours), "m" (minutes), "s" (seconds).`);
}


/**
 * Converts a value to a string.
 * 
 * @param {*} val
 * 
 * @return {string}
 */
export function toStr(val) {
  if (val === undefined) {
    return '';
  } else if (typeof val === 'boolean') {
    return val ? 'yes' : 'no';
  } else if (val.keys && val.positional) {
    return listString(val);
  } else if (val.type === 'lambda') {
    return sEqn(val);
  } else if (val.type === 'error') {
    return '"Error: ' + quoteString(val.error) + '"';
  } else if (typeof val === 'number') {
    // We use toPrecision to address issues like ".1+.2" or
    // "100*1.1".
    //
    // We use "+" to trim trailing 0's.
    //
    // '15' comes from:
    //   https://stackoverflow.com/questions/28045787/how-many-decimal-places-does-the-primitive-float-and-double-support
    return '' + (+val.toPrecision(15));
  }
  if (typeof val === 'object') {
    console.warn('Invalid object for toStr', val);
    console.log(new Error().stack);
    throw new ParseError('Invalid object for toStr');
  }
  return '' + val;
}


/**
 * Whether a given value could be a boolean.
 * 
 * @param {*} val
 * 
 * @return {boolean}
 */
function couldBeBool(val) {
  if (typeof val === 'boolean') {
    return true;
  } else if (typeof val === 'string') {
    let t = val.toLowerCase().trim();
    if (t === 'yes') {
      return true;
    } else if (t === 'no') {
      return true;
    }
  }
  return false;
}


/**
 * Converts a value to a boolean.
 * 
 * @param {*} val
 * 
 * @return {boolean}
 */
export function toBool(val) {
  if (typeof val === 'boolean') {
    return val;
  } else if (typeof val === 'string') {
    let t = val.toLowerCase().trim();
    if (t === 'yes') {
      return true;
    } else if (t === 'no') {
      return false;
    } else {
      val = `"${val}"`;
    }
  } else if (val.type === 'lambda') {
    val = 'a function';
  } else if (val.keys) {
    val = 'a list';
  } else if (val.type === 'error') {
    throw new ParseError(val.error);
  } else {
    val = `"${val}"`;
  }

  throw new ParseError(`Cannot convert ${val} to yes or no.`);
}


// If there are multiple addons in the path, get the last one
const ADDON_TEST_REGEX = /.*addon\[((.+?)-.+?)\]/;


/**
 * Converts a string or other data type to a lambda function.
 * 
 * @param {any} val 
 * @param {Environment} env 
 * 
 * @return {Promise<object>}
 */
export async function toFn(val, env) {
  let origVal = val;
  let error = () => `Cannot convert "${Array.isArray(origVal) ? /** it's an AST */ origVal.map(x => x.source).join('') : toStr(origVal)}" to a function.`;
  while (typeof val === 'string' || Array.isArray(val) || ['lambda', 'command'].includes(val.type)) {
    if (val.type === 'lambda') {
      return val;
    } else if (val.type === 'command') {
      val = await eEqn(val, env);
    } else if (typeof val === 'string') {
      try {
        let strVal = val;
        val = ast(preprocessEquation(val), env);
        if (findType(val, 'command').length > 0) {
          let usedLambdaWhitelist = env.config.usedLambdaWhitelist;
          for (let i = env.locations.length - 1; i >= 0; i--) {
            let loc = env.locations[i];
            if (loc.includes('addon[')) {
              let match = loc.match(/.*addon\[(.+?)\]/);
              usedLambdaWhitelist = env.config.addons[match[1]].addon.addonOptions.usedLambdaWhitelist;
              break;
            }
          }
          if (!usedLambdaWhitelist.includes(strVal)) {
            throw new ParseError('Invalid dynamic formula.');
          }
        }
        // If we're converting from a string we need to check for any parent addons 
        // to ensure grants are satisfied as they can't be statically checked on 
        // the initial parse.
        let addonLoc = env.locations.find(x => x.includes('addon['));
        if (addonLoc) {
          let match = addonLoc.match(ADDON_TEST_REGEX);
          if (match && match[1]) {
            let command = match[1];
            let namespace = match[2];
            // ensure namespace is respected (addons can't include addons from other namespaces)
            let approvedGrants = env.config.addons[command].approvedGrants;
            let valid = await grantsSatisfied(namespace, approvedGrants, val, env);
            if (!valid) {
              throw new ParseError('Invalid command usage.');
            }
          }
        }
      
      } catch (err) {
        throw new ParseError(error() + ' – ' + err.message);
      }
    } else if (Array.isArray(val)) {
      try {
        val = ast(val, env); // don't need to preprocess the tokenized equation
      } catch (err) {
        throw new ParseError(error() + ' – ' + err.message);
      }
    }
  }
  throw new ParseError(error());
}


/**
 * Whether a given value could be a list.
 * 
 * @param {*} val
 * @param {boolean=} strict - if true, val must be a string and actually tries the conversion
 * 
 * @return {boolean}
 */
function couldBeLst(val, strict = false) {
  if (!strict) {
    if (val.keys) {
      return true;
    }
    if (typeof val === 'string') {
      val = val.trim();
      return val[0] === '[' && val[val.length - 1] === ']';
    }
    return false;
  } else {
    try {
      let lst = ast(preprocessEquation(val), null);
      if (lst.type !== 'list') {
        return false;
      }
    } catch (_) {
      return false;
    }
    return true;
  }
}


export class List {
  /**
   * @param {Array=} positional
   * @param {object=} keys
   */
  constructor(positional, keys) {
    if (positional && positional.length && keys && Object.keys(keys).length) {
      throw new ParseError('A list cannot have both positional and keyed values.');
    }
    this.positional = positional || [];
    this.keys = Object.assign(Object.create(null), keys);
  }
}


/**
 * @param {any} val
 * 
 * @return {List}
 */
export function toLst(val) {
  let origVal = val;
  if (val.keys) {
    return val;
  }
  function throwError() {
    throw new ParseError(`Cannot convert "${toStr(origVal)}" to a list.`);
  }

  while (typeof val === 'string' || ['list'].includes(val.type)) {
    if (val.type === 'list') {
      return ePrimitive(val);
    } else if (typeof val === 'string') {
      val = astList(val, new Environment(), throwError);
    } else {
      throw new ParseError('Invalid toLst');
    }
  }
  if (val.keys) {
    return val;
  }
  throwError();
}


/**
 * @param {any} val
 * @param {Environment} env
 * 
 * @return {Promise<List>}
 */
export function toLstArg(val, env) {
  let origVal = val;
  if (val.keys) {
    return val;
  }
  function throwError() {
    throw new ParseError(`Cannot convert "${toStr(origVal)}" to a list.`);
  }
  while (val) {
    if (typeof val === 'string') {
      val = astList(val, env, throwError);
    } else if ('type' in val) {
      if (val.type === 'list') {
        return eEqn(val, env);
      } else {
        throwError();
      }
    } else {
      throw new ParseError('Invalid toLst');
    }
  }
  if (val.keys) {
    return val;
  }
  throwError();
}

/** @typedef {{ type: 'list', info: { keys: object[], positional: object[], }, startPosition: number }} ListASTObject */

/**
 * 
 * @param {import('./Lexer').TokenType[]} tokens 
 * @param {Function} throwError 
 * @param {number} index 
 * @returns {[ListASTObject, number]}
 */
function astOneList(tokens, throwError, index) {
  let hasEnded = false;

  function skipWhitespace() {
    while (index < tokens.length && (tokens[index].type === 'WS' || tokens[index].type === 'WS_B')) {
      index++;
    }
  }

  /**
   * Gets the next token with bounds check and whitespace skipping
   */
  function getNextToken() {
    skipWhitespace();
    if (index === tokens.length) {
      throwError();
    }
    return tokens[index++];
  }
  /**
   * Gets the next token with bounds check and whitespace skipping
   */
  function peekNextToken() {
    skipWhitespace();
    if (index === tokens.length) {
      throwError();
    }
    return tokens[index];
  }

  /**
   * These value tokens need to be handled separately, so we test for them
   * @param {import('./Lexer').TokenType} token 
   */
  function isTokenValue(token) {
    return token.type === 'STRING' || token.type === 'NUMBER' || token.type === 'BOOLEAN';
  }

  /**
   * Converts a TokenType object to another object that matches our AST output format
   * @param {import('./Lexer').TokenType} token 
   */
  function getTokenValueObj(token) {
    let typePart;
    if (token.type === 'STRING') {
      typePart = token.string;
    } else if (token.type === 'NUMBER') {
      typePart = token.number;
    } else if (token.type === 'BOOLEAN') {
      typePart = token.boolean;
    }

    let result = { type: token.type.toLowerCase(), startPosition: token.position, info: typePart };
    return result;
  }

  /**
   * Gets exactly one list element from the current token stream
   * Examples:
   * [ "a": "b", "c": "d" ]
   *   ^^^^^^^^^ - consumes one list element and also the next comma
   * [ "a": "b", "c": "d" ]
   *             ^^^^^^^^^^ - consumes one list element and also the rbracket
   * 
   * @param {boolean} allowListAssign used to handle processing the value part of a keyed list assignment
   */
  function astOneListElement(allowListAssign) {
    /**
     * Gets exactly one value from the current token stream
     * 
     * Examples:
     * [ "a": "b", "c": "d" ]
     *   ^^^  - consumes just the string
     * [ "a": "b", "c": "d" ]
     *        ^^^  - can consume the other side of keyed list also
     * [ ["a": "c"]: "b" ]
     *   ^^^^^^^^^^   - can consume another list as the value
     */
    function getNextValue() {
      const token = getNextToken(); 

      if (token.type === 'LBRACKET') {
        const [result, newIndex] = astOneList(tokens, throwError, index - 1);
        index = newIndex;
        return result;
      } else if (token.type === 'MINUS' || token.type === 'PLUS') {
        const nextValue = getNextValue();
        if (nextValue.type !== 'number') {
          throwError();
        }
        if (token.type === 'MINUS') {
          nextValue.info = -nextValue.info;
        }
        return nextValue;
      } else if (isTokenValue(token)) {
        return getTokenValueObj(token);
      } else {
        throwError();
      }
    }

    const value = getNextValue();
    const nextToken = getNextToken();
    if (allowListAssign && nextToken.type === 'LISTASSIGNS') {
      const value2 = astOneListElement(false);
      return { 
        type: BASIC_TOKENS[':'],
        key: value,
        value: value2,
      };
    } else if (nextToken.type === 'COMMA') {
      return value;
    } else if (nextToken.type === 'RBRACKET') {
      hasEnded = true;
      return value;
    } else {
      throwError();
    }
  }

  // Here we get the first valid token and setup the list
  const initialToken = getNextToken();
  if (initialToken.type !== 'LBRACKET') {
    throwError();
  }

  /** @type {ListASTObject} */
  const list = { type: 'list', info: { keys: [], positional: [] }, startPosition: initialToken.position };

  // Do not run the loop in case of empty list
  // Because the loop is for non-empty lists
  if (peekNextToken().type !== 'RBRACKET') {
    // This loops gets list elements one by one, and pushes
    // them to the keys/positional array, depending on the
    // type of the list element received.
    // This loop automatically consumes the rbracket when necessary
    while (!hasEnded && index < tokens.length) {
      const elm = astOneListElement(true);
      const info = list.info;
      if (elm.type === 'LISTASSIGNS') {
        info.keys.push(elm);
      } else {
        info.positional.push(elm);
      }
      if (info.positional.length > 0 && info.keys.length > 0) {
        throwError();
      }
    }
  } else {
    // consume rbracket
    getNextToken();
    hasEnded = true;
  }

  // run till whitespace remains, as it is considered part of the current list
  // token stream
  skipWhitespace();

  // Since this function tries to do the ast of exactly one 
  // list, that list must ended in this token stream
  if (!hasEnded) {
    throwError();
  }

  return [list, index];
}

/**
 * Parses a string to an equation ast.
 * 
 * @param {string} val
 * @param {Environment} env - environment -- needed to get attribute defs for parsing - can be `null` if in a context with no embedded commands (e.g. a selector)
 * @param {function(): void} throwError
 * 
 * @return {object}
 */
export function astList(val, env, throwError) {
  let lexed = lex(val, env);

  if (lexed.termination !== 'END_STRING') {
    throwError();
  }

  const tokens = lexed.tokens;

  const [result, newIndex] = astOneList(tokens, throwError, 0);

  if (!result || newIndex !== tokens.length) {
    throwError();
  }

  return result; 
}

/**
 * Parses a string to an equation ast.
 * 
 * @param {string|any[]} val
 * @param {Environment} env - environment -- needed to get attribute defs for parsing - can be `null` if in a context with no embedded commands (e.g. a selector)
 * @param {{ startRule?: string }} [config]
 * 
 * @return {object}
 */
export function ast(val, env, config = null) {
  const startRule = config?.startRule || equationGrammar.ParserStart;
  if (Array.isArray(val)) {
    // This is an optimized path for simple tokenized trees. Note that this block
    // should be fully removable without any impact on results. Performance is the
    // only thing that may be impacted.
    //
    // We do this to minimize relatively expensive nearley calls for common simple
    // use cases.
    //
    // TODO - It may make sense to add support for additional simple cases 
    let v = val.filter(x => x.type !== 'WS');
    let primitives = {
      'IDENTIFIER': 'identifier',
      'BOOLEAN': 'boolean',
      'STRING': 'string',
      'NUMBER': 'number'
    };
    let ops = {
      'EQUALS': 'equals',
      'LT': 'lt',
      'GT': 'gt',
      'LTE': 'lt_eq',
      'GTE': 'gt_eq',
      'STRICT_EQUALS': 'strict_equals',
      'NOT_EQUALS': 'not_equals'
    };

    // if it's simple, we can short circuit the parsing
    if (v.length === 1) {
      // For: single primitive
      if (v[0].type in primitives) {
        return { type: primitives[v[0].type], info: v[0][v[0].type.toLowerCase()], startPosition: v[0].position };
      }
    } else if (v.length === 3 && (v[0].type in primitives) && (v[1].type in ops) && (v[2].type in primitives)) {
      // For: primitive-operator-primitive
      // e.g. `1 == abc`
      return {
        type: ops[v[1].type],
        startPosition: v[1].position,
        info: [
          { type: primitives[v[0].type], info: v[0][v[0].type.toLowerCase()], startPosition: v[0].position },
          { type: primitives[v[2].type], info: v[2][v[2].type.toLowerCase()], startPosition: v[2].position }
        ]
      };
    }
  }

  let tokens;
  if (typeof val === 'string') {
    let lexed = lex(val, env);
    if (lexed.termination !== 'END_STRING') {
      throw new ParseError(val);
    }
    tokens = lexed.tokens;
  } else {
    tokens = val;
  }

  // @ts-ignore
  let parser = new nearley.Parser(equationGrammar.ParserRules, startRule);
  try {
    // @ts-ignore
    parser.feed(tokens);
  } catch (err) {
    let str = tokens.map(x => x.source || '').join('');

    let tokenIndex = err.offset;
    let errorPosition;
    let customMessage;
      

    let token = tokens[tokenIndex];
    if (typeof errorPosition === 'undefined') {
      errorPosition = tokens[tokenIndex].position;
    }
    let char = token.source.slice(0, 1);
    let snippet = str.slice(Math.max(errorPosition - 2, 0), errorPosition + 3);
    if (errorPosition > 2) {
      snippet = '…' + snippet;
    }
    if (errorPosition < str.length - 3) {
      snippet = snippet + '…';
    }

    if (!customMessage) {
      if (token.source === '\'') {
        customMessage = 'double-quote "text" instead of \'text\'';
      }
    }

    if (!customMessage) {
      if (token.source === 'x') {
        customMessage = 'use * instead of x for multiplication';
      }
    }

    if (!customMessage) {
      if (token.isKeyword) {
        customMessage = `"${token.source}" cannot be used as a name`;
      }
    }

    if (!customMessage && err.message.startsWith('TB: ')) {
      customMessage = err.message.substr(4);
    }
      
    let errObj = new ParseError((customMessage || `unexpected “${char}”`) + ` in “${snippet}”`);
    errObj.errorPosition = errorPosition;
    throw errObj;
  }

  if (parser.results !== undefined && parser.results[0] !== undefined) {
    if (parser.results.length > 1) {
      console.warn(`Formula grammar had ${parser.results.length} results`, parser.results);
    }
    return parser.results[0];
  } else {
    let err = new ParseError('unexpected end of formula');
    let str = tokens.reduce((count, t) => count + t.source, '');
    err.errorPosition = str.trimRight().length - 1;
    throw err;
  }
}


/**
 * Parses a string to a JSDOC ast
 * 
 * @param {string} val
 * 
 * @return {object}
 */
export function jsdocAst(val) {
  // @ts-ignore
  let parser = new nearley.Parser(jsdocGrammar.ParserRules, jsdocGrammar.ParserStart);
  try {
    parser.feed(val + '');
  } catch (err) {
    let str = val.slice(Math.max(err.offset - 2, 0), err.offset + 3);
    if (err.offset > 2) {
      str = '…' + str;
    }
    if (err.offset < val.length - 3) {
      str = str + '…';
    }
    
    throw new ParseError(`unexpected “${err.token.value}” in “${str}”`);
  }

  if (parser.results !== undefined && parser.results[0] !== undefined) {
    if (parser.results.length > 1) {
      console.warn(`JSDoc had ${parser.results.length} results`, parser.results);
    }
    return parser.results[0];
  } else {
    throw new ParseError('unexpected end of JSDoc');
  }
}


/**
 * Finds the nodes of give type in a tree.
 * 
 * @param {object} tree
 * @param {string} type
 * @param {any[]=} res - running tally of items (much faster than creating multiple arrays)
 * 
 * @return {object[]}
 */
export function findType(tree, type, res = []) {
  if (!tree) {
    return;
  }

  if (tree.type) {
    if (tree.type === type) {
      res.push(tree);
    }
  }

  if (tree.info) {
    if (tree.type === 'list') {
      for (let item of tree.info.positional) {
        findType(item, type, res);
      }

      for (let item of tree.info.keys) {
        findType(item.key, type, res);
        findType(item.value, type, res);
      }
    } else if (tree.type === 'function_call') {
      findType(tree.info.name, type, res);
      for (let item of tree.info.args) {
        findType(item, type, res);
      }
    } else if (tree.type === 'for') {
      findType(tree.info.base, type, res);
      for (let item of tree.info.args) {
        findType(item, type, res);
      }
      if (tree.info.if) {
        findType(tree.info.if, type, res);
      }
    } else if (tree.type === 'query') {
      findType(tree.info, type, res);
    } else if (tree.type === 'not' || tree.type === 'negate') {
      findType(tree.info, type, res);
    } else if (tree.type === 'string') {
      // pass
    } else if (tree.type === 'identifier') {
      // pass
    } else if (tree.type === 'command') {
      // pass
    } else {
      for (let child of Object.keys(tree.info)) {
        if (tree.info[child]) {
          if (Array.isArray(tree.info[child])) {
            tree.info[child].map(c => findType(c, type, res));
          } else {
            findType(tree.info[child], type, res);
          }
        }
      }
    }
  } else if (tree.positional) {
    findType(tree.positional, type, res);
  } else if (tree.key && tree.value) {
    findType(tree.key, type, res);
    findType(tree.value, type, res);
  }

  return res;
}


/**
 * Converts an AST to a string.
 * 
 * @param {object} node
 * 
 * @return {string}
 */
export function sEqn(node) {
  let p = (x) => '(' + x + ')';
  const info = node.info;
  switch (node.type) {
  case 'number':
    return '' + info;
  case 'string':
    return '"' + quoteString(info) + '"';
  case 'boolean':
    return info ? 'yes' : 'no';
  case 'lambda':
    let argNames = info.args;
    if (info.exp) {
      let expr = info.exp;
      return `(${argNames.map(sEqn).join(', ')}) -> ${sEqn(expr)}`;
    } else {
      let statements = info.statements;
      return `(${argNames.map(sEqn).join(', ')}) -> ${sEqn(statements)}`;
    }
  case 'block':
    return `block\n${sEqn(info)}\nendblock`;
  case 'statement_list':
    return info.map(sEqn).join('\n');
  case 'assign_statement':
    return sEqn(info[0]) + ' = ' + sEqn(info[1]);
  case 'initialize_local_statement':
    return 'var ' + sEqn(info[0]) + ' = ' + sEqn(info[1]);
  case 'return_statement':
    if (info[0]) {
      return 'return ' + sEqn(info[0]);
    } else {
      return 'return';
    }
  case 'for_statement':
    let forPart = info[0];
    let forArgDefs = forPart.info.args;
    let forLcBase = forPart.info.base;
    let forFilter = forPart.info.if;
    let forStatements = info[1];
    return  `for (${forArgDefs.map(sEqn).join(', ')}) in ` + sEqn(forLcBase) + (forFilter ? (' if ' + sEqn(forFilter.info)) : '') + '\n' + sEqn(forStatements) + '\nendfor';
  case 'if_statement':
    let [ifCondition, ifStatements, elseIfStatements, elseStatement] = info;
    
    return `if ${sEqn(ifCondition)}\n${sEqn(ifStatements)}${elseIfStatements ? elseIfStatements.info.map(elseIf => `\nelseif ${sEqn(elseIf.info[0])}\n${sEqn(elseIf.info[1])}`).join('') : ''}${elseStatement ? `\nelse\n${sEqn(elseStatement.info)}` : ''}\nendif`;
  case 'try_statement':
    let [tryStatements, catchStatement] = info;

    return `try\n${sEqn(tryStatements)}${catchStatement ? `\ncatcherror\n${sEqn(catchStatement.info[0])}` : ''}\nendtry`;
  case 'list_comprehension':
    let element = info.element;
    let forStmt = info.for;
    let argDefs = forStmt.info.args;
    let lcBase = forStmt.info.base;
    let filter = forStmt.info.if;
    let assignEl = ':';

    return '[' + (element.positional ? sEqn(element.positional) : `${sEqn(element.key)}${assignEl}${sEqn(element.value)}`) + ` for (${argDefs.map(sEqn).join(', ')}) in ` + sEqn(lcBase) + (filter ? (' if ' + sEqn(filter.info)) : '') + ']';
  case 'list':
    if (info.positional.length) {
      return '[' + info.positional.map(x => sEqn(x)).join(', ') + ']';
    }
    
    let assign = ':';
    return '[' + info.keys.map(x => `${sEqn(x.key)}${assign}${sEqn(x.value)}`).join(', ') + ']';
  case 'select':
    return sEqn(info.base) + '[' + sEqn(info.selector) + ']';
  case 'plus':
    return p(sEqn(info[0]) + ' + ' + sEqn(info[1]));
  case 'ternary':
    return p(sEqn(info[1]) + ' if ' + sEqn(info[0]) + ' else ' + sEqn(info[2]));
  case 'concat':
    return p(sEqn(info[0]) + ' & ' + sEqn(info[1]));
  case 'negate':
    return p('-' + sEqn(info));
  case 'minus':
    return p(sEqn(info[0]) + ' - ' + sEqn(info[1]));
  case 'mult':
    return p(sEqn(info[0]) + ' * ' + sEqn(info[1]));
  case 'div':
    return p(sEqn(info[0]) + ' / ' + sEqn(info[1]));
  case 'pow':
    return p(sEqn(info[0]) + '^' + sEqn(info[1]));
  case 'equals':
    return p(sEqn(info[0]) + ' == ' + sEqn(info[1]));
  case 'strict_equals':
    return p(sEqn(info[0]) + ' === ' + sEqn(info[1]));
  case 'not_equals':
    return p(sEqn(info[0]) + ' <> ' + sEqn(info[1]));
  case 'gt':
    return p(sEqn(info[0]) + ' > ' + sEqn(info[1]));
  case 'gt_eq':
    return p(sEqn(info[0]) + ' >= ' + sEqn(info[1]));
  case 'lt':
    return p(sEqn(info[0]) + ' < ' + sEqn(info[1]));
  case 'lt_eq':
    return p(sEqn(info[0]) + ' <= ' + sEqn(info[1]));
  case 'not':
    return p('not ' + sEqn(info));
  case 'and':
    return p(sEqn(info[0]) + ' and ' + sEqn(info[1]));
  case 'or':
    return p(sEqn(info[0]) + ' or ' + sEqn(info[1]));
  case 'function_call':
    return sEqn(info.name) + '(' + info.args.map(sEqn).join(', ') + ')';
  case 'identifier':
    if (/^[A-Za-z][A-Za-z_0-9]*$|^rowid\(\)$/.test(info) && !SQL_KEYWORD_TOKENS.includes(info.toUpperCase())) {
      return info;
    } else {
      return '`' + info.replace(/\\/g, '\\\\').replace(/`/g, '\\`') + '`';
    }
  case 'command':
    return info;
  default:
    throw new Error('Unknown sEqn node type: ' + node.type + '\n\n' + JSON.stringify(node));
  }
}
  

function ePrimitive(node) {
  const info = node.info;
  switch (node.type) {
  case 'number':
    return info;
  case 'negate':
    if (info.type === 'number') {
      return -info.info;
    } else {
      throw new ParseError('Invalid primitive');
    }
  case 'string':
    return info;
  case 'boolean':
    return info;
  case 'lambda':
    return node;
  case 'list':
    let nInfo = {
      positional: info.positional.map(x => ePrimitive(x)),
      keys: Object.create(null)
    };
    for (let item of info.keys) {
      let k = toStr(ePrimitive(item.key));
      if (k in nInfo.keys) {
        throw new ParseError('Cannot have repeated names');
      }
      nInfo.keys[k] = ePrimitive(item.value);
    }
    if (nInfo.positional.length && Object.keys(nInfo.keys).length) {
      throw new ParseError('Lists cannot have both positional and keyed elements.');
    }
    return new List(nInfo.positional, nInfo.keys);
  default:
    throw new ParseError('Invalid primitive');
  }

}

/**
 * @param {import('./ParseNode').default} block
 * @param {Environment} env
 */
export async function runBlock(block, env) {
  await evaluateEquation(block, env.derivedConfig({
    mode: 'attribute',
    isOneoffFormula: true,
    
    // in one-off formula's dates shouldn't be fixed
    useRealtimeDates: true,
    
    // generate a new random seed each time a one-off formula is executed
    randomSeed: Math.random(),
  }, 'block - ' + block.startPosition), true);
}

/**
 * @param {string} command 
 * @param {'begin'|'finish'|'error'} blockName 
 * @param {import('./ParseNode').default} block
 * @param {() => Promise<import('./DataContainer').Environment>} getEnv
 */
async function runBlockWithErrorHandleWrapper(command, blockName, block, getEnv) {
  const env = await getEnv();
  const env2 = env.derivedLocation(`${command}_${blockName} - -100`);

  try {
    await runBlock(block, env2);
  } catch (e) {
    const commands = env2.locationString().match(/(urlload|urlsend|dbinsert|dbselect|dbupdate|dbdelete)_(begin|finish|error)/g).map(x => {
      return '{' + x.replace('_', '} (handler ') + ')';
    }).join(' -> ');
    throw new Error(`Failed to run ${commands}, error: ${e.message}`);
  }
}

/**
 * @param {string} command 
 * @param {import('./ParseNode').default} beginBlock 
 * @param {import('./DataContainer').Environment} env 
 */
export async function runBeginBlock(command, beginBlock, env) {
  await runBlockWithErrorHandleWrapper(command, 'begin', beginBlock, () => Promise.resolve(env));
}

/**
 * @param {string} command
 * @param {import('./ParseNode').default} finishBlock
 * @param {import('./DataContainer').Environment} env 
 * @param {object} data
 * @param {object} status
 */
export async function runFinishBlockInner(command, finishBlock, env, data, status) {
  await runBlockWithErrorHandleWrapper(command, 'finish', finishBlock, () => env.derivedData({
    data,
    status,
  }));
}

/**
 * @param {string} command
 * @param {import('./ParseNode').default} errorBlock
 * @param {import('./DataContainer').Environment} env 
 * @param {object} errorMessage
 * @param {'error'|number} status
 * @returns 
 */
export async function runErrorBlock(command, errorBlock, env, errorMessage, status) {
  await runBlockWithErrorHandleWrapper(command, 'error', errorBlock, () => env.derivedData({
    error: errorMessage,
    status,
  }));
}

/**
 * @param {import('./DataContainer').Environment} env 
 * @param {string} title
 * @param {string} message
 * @param {'finish'|'error'|'begin'} [block]
 */
export function showErrorNotificationForInvalidCommand(env, title, message, block) {
  env.config?.showNotification?.({ title, message: message || (block ? `Invalid ${block} block` : 'Invalid code') });
}

/**
 * @param {string} command
 * @param {import('./ParseNode').default} finishBlock
 * @param {import('./DataContainer').Environment} env 
 * @param {{ data: any, status: string|number }} data
 */
export async function runFinishBlock(command, finishBlock, env, data) {
  await runFinishBlockInner(command, finishBlock, env, data.data, data.status);
}

/**
 * We need to get a string representation of the input string
 * @param {string} str 
 */
function escapeStringForParametrization(str) {
  // Handles the inner quotes and extra backslashes
  return JSON.stringify(str);
}

/**
 * @param {Pick<import('./ParseNode').default, 'info'>} node 
 */
export function getBaseUpdateForDBNode(node) {
  let labelForUI = node.info.query;
  const parameters = node.info.parameters;
  if (parameters?.length) {
    const lexResult = lexSQL(labelForUI);
    let newResult = '';
    for (const token of lexResult.tokens) {
      if (token.type === 'IDENTIFIER' && SQL_PLACEHOLDER_REGEX.test(token.identifier)) {
        const index = +token.identifier.substring(1) - 1;
        const replaceValue = typeof parameters[index] === 'number' ? parameters[index] : escapeStringForParametrization(parameters[index]);
        newResult += replaceValue;
      } else {
        newResult += token.source;
      }
    }
    labelForUI = newResult;
  }
  const databaseId = node.info.databaseId;
  const displayText = node.info.sqlBlurb.join('');
  /** @type {Pick<import('../components/FormRenderer/FormRenderer').RemoteProgressItemType, 'spaceId'|'label'|'displayText'|'id'|'triggerTimestamp'>} */
  const baseUpdate = { spaceId: databaseId, label: labelForUI, displayText, id: labelForUI, triggerTimestamp: Date.now() };
  return baseUpdate;
}

/**
 * @param {Pick<import('./ParseNode').default, 'info'>} node 
 * @param {boolean} isLoader
 */
export function getBaseUpdateForRemoteNode(node, isLoader) {
  const nodeURL = new URL(node.info.url);
  const label = nodeURL.href;
  // remove https://, remove trailing /, in the display text
  const displayText = nodeURL.href.substring(nodeURL.protocol.length + 2).replace(/\/$/, '');
  /** @type {Pick<import('../components/FormRenderer/FormRenderer').RemoteProgressItemType, 'label'|'type'|'displayText'|'id'|'triggerTimestamp'>} */
  const baseUpdate = { type: /** @type {'urlload'|'urlsend'} */ (isLoader ? 'urlload' : 'urlsend'), label, displayText, id: label, triggerTimestamp: Date.now() };
  return baseUpdate;
}

export function getStatusForDBCommand(res) {
  /** @type {'error'|'success'} */
  let status;
  if (res === undefined) {
    status = 'error';
  } else if (typeof res.status === 'number') {
    status = res.status < 400 ? 'success' : 'error';
  } else {
    status = res.status === 'success' ? 'success' : 'error';
  }
  return { status };
}

export function getResponseAndCodeForRemoteCommand(res) {
  const responseCode = typeof res?.status === 'number' ? res.status : undefined;
  /** @type {'success'|'error'} */
  const status = responseCode !== undefined && responseCode < 400 ? 'success' : 'error';
  return { responseCode, status };
}

/**
 * @param {Pick<import('./ParseNode').default, 'info'>} command 
 */
export function getNotificationTitleForRemoteCommand(command) {
  const commandName = command.info.command;
  return 'Error in command {' + commandName + '}';
}

/**
 * @param {Pick<import('./ParseNode').default, 'info'>} command 
 * @param {Environment} env
 * @returns {Promise<any>}
 */
export async function runRemoteCommandInsideBlock(command, env) {
  const isDBCommand = !!command.info.query;
  const commandName = command.info.command;
  const isSidechannel = isDBCommand ? commandName !== 'dbselect' : commandName !== 'urlload';
  const isInstant = !!command.info.instant;
  const notificationTitle = getNotificationTitleForRemoteCommand(command);
  const notificationPrefix = notificationTitle + ': ';

  if (!isInstant && isSidechannel) {
    return { status: 'error', error: notificationPrefix + 'Cannot run ' + commandName + ' without instant=yes in a code block' };
  }

  return runRemoteCommandInsideBlockInner(command, env, { isDBCommand, isSidechannel, notificationPrefix, });
}

/**
 * @param {Pick<import('./ParseNode').default, 'info'>} node 
 * @returns {boolean}
 */
export function isSynchronousRemoteCommand(node) {
  return !(node.info.begin || node.info.finish || node.info.error);
}

/**
 * @param {Pick<import('./ParseNode').default, 'info'>} command 
 * @param {Environment} env
 * @param {{ isDBCommand: boolean, isSidechannel: boolean, notificationPrefix: string }} meta
 * @returns {Promise<any>}
 */
async function runRemoteCommandInsideBlockInner(command, env, { isDBCommand, isSidechannel, notificationPrefix, }) {
  // remote commands are allowed in one off formulas
  // we need to handle them separately
  
  const baseUpdate = isDBCommand ? getBaseUpdateForDBNode(command) : getBaseUpdateForRemoteNode(command, !isSidechannel);
  const onChange = env.config.callbacks?.onChange;

  // inside block contexts, every new run is brand new, so assign it a new label
  baseUpdate.id = Math.random().toString();
  const commandName = command.info.command;

  // env.config.callbacks is undefined when extension is running
  // sidechannel commands after snippet has been submitted
  env.config.callbacks?.onRemoteUpdate({ ...baseUpdate, status: 'pending' });
  /** @type {(key: string, value: any) => void} */
  function updateTopLevelValue(key, value) {
    if (command.info[key]) {
      /** @type {import('./DataContainer').DataUpdateChangeType} */
      const assignUpdateItem = {
        name: command.info[key],
        value,
        create_in_root_if_needed: true
      }; 
      env.data.bulkUpdate([assignUpdateItem], env);
      onChange?.();
    }
  }
  /** @type {(value: boolean) => void} */
  function updateIsLoading(value) {
    updateTopLevelValue('isloading', value);
  }
  /** @type {(value: boolean) => void} */
  function updateHasError(value) {
    updateTopLevelValue('haserror', value);
  }
  updateIsLoading(true);
  updateHasError(false);
  if (command.info.begin) {
    await runBeginBlock(commandName, command.info.begin, env);
    // re-render any global variables that were assigned inside the callback
    onChange?.();
  }

  async function handleError(err, status, responseCode) { 
    if (status === undefined && isDBCommand) {
      status = 'error';
    }
    updateIsLoading(false);
    updateHasError(true);
    /** @type {Partial<Pick<import('../components/FormRenderer/FormRenderer').RemoteProgressItemType, 'data'|'responseCode'>>} */
    const extraData = isDBCommand ? { data: err, } : { data: err, responseCode };
    env.config.callbacks?.onRemoteUpdate({ ...baseUpdate, status: 'error', ...extraData });
    const errorBlock = command.info.error;
    if (errorBlock) {
      await runErrorBlock(commandName, errorBlock, env, err, status);
    } else {
      return { status: 'error', error: notificationPrefix + err, };
    }
    // re-render any global variables that were assigned inside the callback
    onChange?.();
  }

  let res;
  // TODO: Report errors to sentry when all the users are shifted to extension and app handling network error in fetch API call itself
  try {
    // We don't use store.request because it is not available
    // when running nested sidechannel requests from the extension background page
    // This request is not debounced or cached,
    // because it runs exactly once when the callback handler finishes
    res = await env.config.remoteFn({
      info: command.info,
    });
  } catch (e) {
    console.error('RemoteStore in Equation.js fetch error caught', e);
    return await handleError('Network error', 'error');
  }

  const relevantData = isDBCommand ? getStatusForDBCommand(res) : getResponseAndCodeForRemoteCommand(res);
  if (isDBCommand) {
    if (relevantData.status === 'error') {
      // Dashboard preview functions return res.message,
      // but the extension returns res.text
      return await handleError(res.message || res.text);
    }
  } else {
    if (relevantData.status === 'error') {
      return await handleError(res.data, res.status, relevantData.responseCode);
    }
  }
  /** @type {Partial<Pick<import('../components/FormRenderer/FormRenderer').RemoteProgressItemType, 'data'|'responseCode'>>} */
  const extraData = isDBCommand ? {} : { data: res?.data, responseCode: relevantData.responseCode };
  env.config.callbacks?.onRemoteUpdate({ ...baseUpdate, status: 'success', ...extraData });
  updateIsLoading(false);
  updateHasError(false);
  const finishBlock = command.info.finish;
  let data = res;
  let result = isDBCommand ? res.results || res.text : data.data;
  if (typeof result === 'object' && !isKeyedList(result)) {
    result = objectToList(result);
  }
  if (isDBCommand) {
    // res.results is for dbselect/res.text is for other sidechannel
    data = { status: res.status, data: result, };
  } else {
    data.data = result;
  }
  if (finishBlock) {
    await runFinishBlock(commandName, finishBlock, env, data);
    // re-render any global variables that were assigned inside the callback
    onChange?.();
  }

  return data;
}

/**
 * Evaluates an equation.
 * 
 * @param {object} node
 * @param {Environment} env
 * 
 * @return {Promise<object>}
 */
export async function eEqn(node, env) {
  const info = node.info;
  switch (node.type) {
  case 'number':
    return info;
  case 'string':
    return info;
  case 'boolean':
    return info;
  case 'lambda':
    return node;
  case 'list_comprehension':
    let element = info.element;
    let argDefs = info.for.info.args;

    let processed = (await processForStatement(info.for, env)).values;
    let getLocalEnvironment = getLocalEnvironmentGenerator(argDefs, env);

    if (element.positional) {
      if (Array.isArray(processed)) {
        return createListFromArray(await Promise.all(processed.map(async (x) => await eEqn(element.positional, await getLocalEnvironment(x[0], x[1])))));
      } else {
        return createListFromArray(await Promise.all(Object.entries(processed).map(async (x) => await eEqn(element.positional, await env.derivedData(await getLocalEnvironment(x[1], x[0]))))));
      }
    } else {
      let res = Object.create(null);
      let keys, values;
      if (Array.isArray(processed)) {
        keys = await Promise.all(processed.map(async (x) => eEqn(element.key, await getLocalEnvironment(x[0], x[1]))));
        values = await Promise.all(processed.map(async (x) => eEqn(element.value, await getLocalEnvironment(x[0], x[1]))));
      } else {
        keys = await Promise.all(Object.entries(processed).map(async (x) => eEqn(element.key, await getLocalEnvironment(x[1], x[0]))));
        values = await Promise.all(Object.entries(processed).map(async (x) => eEqn(element.value, await getLocalEnvironment(x[1], x[0]))));
      }
      for (let i = 0; i < keys.length; i++) {
        res[toStr(keys[i])] = values[i];
      }
      return createListFromObject(res);
    }
  case 'list':
    let nInfo = {
      positional: await Promise.all(info.positional.map(x => eEqn(x, env))),
      keys: Object.create(null)
    };
    for (let item of info.keys) {
      let k = toStr(await eEqn(item.key, env));
      if (k in nInfo.keys) {
        throw new ParseError('Cannot have repeated names');
      }
      nInfo.keys[k] = await eEqn(item.value, env);
    }
    if (nInfo.positional.length && Object.keys(nInfo.keys).length) {
      throw new ParseError('Lists cannot have both positional and keyed elements.');
    }
    return new List(nInfo.positional, nInfo.keys);
  case 'select':
    let name;
    if (info.base.type === 'identifier') {
      name = info.base.info;
    }

    let baseSelect = await eEqn(info.base, env);
    let selectors = [await eEqn(info.selector, env)];
    let resSelect = baseSelect;
    for (let selector of selectors) {
      selector = toStr(selector);
      
      if (!resSelect.keys) {
        if (typeof resSelect === 'string') {
          try {
            resSelect = await toLstArg(resSelect, env);
          } catch (_) {}
        }
        if (!resSelect.keys) {
          throw new ParseError('Cannot use a list selector on a non-list');
        }
      }

      let found = false;
      if (selector in resSelect.keys) {
        found = true;
        resSelect = resSelect.keys[selector];
      } else if (resSelect.positional.length) {
        let givenIndex = Number(selector);
        if (Number.isInteger(givenIndex)) {
          let index = givenIndex < 0 ? resSelect.positional.length + givenIndex : givenIndex - 1;
          if (givenIndex === 0) {
            throw new ParseError(`Position ${selector} is not in the list${name ? (' "' + name + '"') : ''} – the first item has position 1`);
          } else if (index < 0 || index >= resSelect.positional.length) {
            throw new ParseError(`Position ${selector} is not in the list${name ? (' "' + name + '"') : ''} – it only has ${resSelect.positional.length} items`);
          }
          found = true;
          resSelect = resSelect.positional[index];
        }
      }
      if (!found) {
        throw new ParseError(`"${selector}" is not in the list${name ? (' "' + name + '"') : ''}`);
      }
    }
    return resSelect;
  case 'block':
    let newBlockEnv = await getLocalEnvironmentGenerator([], env)();
    try {
      let statements = info;
      await eEqn(statements, newBlockEnv);
    } catch (err) {
      if (err.message === 'BLOCK_RETURN') {
        return err.returnData;
      }
      throw err;
    }
    // Blocks return an empty string by default
    return '';
  
  case 'statement_list':
    for (const statement of info) {
      await eEqn(statement, env);
    }
    break;
  case 'assign_statement': {
    let selector = info[0];
    let isListAssign = selector.type === 'select';
    /** @type {import('./DataContainer').DataUpdateChangeType} */
    let assignUpdateItem = {
      name: isListAssign ? selector.info.base.info : selector.info,
      value: await eEqn(info[1], env),
      create_in_root_if_needed: true
    }; 
    if (assignUpdateItem.value === undefined && info[1].type === 'function_call') {
      throw new ParseError('Cannot assign void function result into a variable');
    }


    let selectors = [];
    if (isListAssign) {
      while (selector.info.base.type === 'select') {
        selectors.unshift(await eEqn(selector.info.selector, env));
        selector = selector.info.base;
      }
      selectors.unshift(await eEqn(selector.info.selector, env));
      assignUpdateItem.name = selector.info.base.info;
    }

    if (!env.data.definedLocal(assignUpdateItem.name)) {
      if (!env.config.isOneoffFormula) {
        throw new ParseError(
          'Cannot assign to non-locals in a formula outside of a {run} or a handler. Tried to assign to "' + (assignUpdateItem.name) + '"'
        );
      }
      // env.config.currentFixedAssignedGlobals will be undefined in the callbacks after snippet insertion
      // e.g. in {dbinsert: ... finish=...}
      if (env.config.currentFixedAssignedGlobals && env.config.currentFixedAssignedGlobals.has(assignUpdateItem.name.toLocaleLowerCase())) {
        throw new ParseError(
          `Cannot assign to the global variable "${assignUpdateItem.name}" as it is set permanently by a formula. If you need to set it, you can initialize it with a {run} command instead of a formula.`
        );
      }
    }

    if (isListAssign) {
      
      let baseSelect = await eEqn(selector.info.base, env);
      let base = baseSelect;
      let updateParent = (newChild) => baseSelect = newChild;
      let resSelect = baseSelect;
      for (let i = 0; i < selectors.length; i++) {
        let selector = selectors[i];
        let isLastSelector = i === selectors.length - 1;

        selector = toStr(selector);
    
        if (!resSelect.keys) {
          if (typeof resSelect === 'string') {
            try {
              resSelect = await toLstArg(resSelect, env);
              updateParent(resSelect);
            } catch (_) {}
          }
          if (!resSelect.keys) {
            throw new ParseError('Cannot use a list selector on a non-list');
          }
        }

        let found = false;
        if ((selector in resSelect.keys) || (isLastSelector && !resSelect.positional.length)) {
          found = true;
          let o = resSelect;
          resSelect = resSelect.keys[selector];
          updateParent = (newChild) => o.keys[selector] = newChild;
        } else if (resSelect.positional.length) {
          let givenIndex = Number(selector);
          if (Number.isInteger(givenIndex)) {
            let index = givenIndex < 0 ? resSelect.positional.length + givenIndex : givenIndex - 1;
            if (givenIndex === 0) {
              throw new ParseError(`Position ${selector} is not in the list – the first item has position 1`);
            } else if (index < 0 || index >= resSelect.positional.length) {
              throw new ParseError(`Position ${selector} is not in the list – it only has ${resSelect.positional.length} items`);
            }
            found = true;
            let o = resSelect;
            resSelect = resSelect.positional[index];
            updateParent = (newChild) => o.positional[index] = newChild;
          } else {
            throw new ParseError(`Cannot set "${selector}" in a positional list`);
          }
        }
        if (!found) {
          throw new ParseError(`"${selector}" is not in the list`);
        }
      }
      updateParent(assignUpdateItem.value);
      assignUpdateItem.value = base;
    }

    await env.data.bulkUpdate([assignUpdateItem], env);
    break;
  } case 'initialize_local_statement': {
    /** @type {import('./DataContainer').DataUpdateChangeType} */
    let initUpdateItem = null; 
    let obj = {
      value: await eEqn(info[1], env),
      is_local: true
    };
    initUpdateItem = { name: info[0].info, ...obj };
    let baseName = initUpdateItem.name;
    if (env.data.has(baseName)) {
      throw new ParseError('Local variable "' + baseName + '" already defined');
    } 
    await env.data.bulkUpdate([initUpdateItem], env);
    break;
  } case 'return_statement':
    let returnError = new Error('BLOCK_RETURN');
    if (info[0]) {

      if (!env.locationString().includes('FUNCTION')) {
        throw new ParseError('You can only return a value inside a function');
      }

      let returnRes =  await eEqn(info[0], env);
      // @ts-ignore
      returnError.returnData = returnRes;
    } else {
      // @ts-ignore
      returnError.returnData = '';
    }
    throw returnError;
  case 'for_statement':

    let forPart = info[0];
    let forArgDefs = forPart.info.args;


    let forProcessed = (await processForStatement(forPart, env)).values;
    let forGetLocalEnvironment = getLocalEnvironmentGenerator(forArgDefs, env);

    if (Array.isArray(forProcessed)) {
      for (let item of forProcessed) {
        await eEqn(info[1], await forGetLocalEnvironment(item[0], item[1]));
      }
    } else {
      
      for (let item of Object.entries(forProcessed)) {
        await eEqn(info[1], await env.derivedData(await forGetLocalEnvironment(item[1], item[0])));
      } 
    }

    break;
  case 'if_statement':
    let [ifCondition, ifStatements, elseIfStatements, elseStatement] = info;
  
    if (toBool(await eEqn(ifCondition, env))) {
      await eEqn(ifStatements, env);
    } else {
      let evaluated = false;
      if (elseIfStatements) {
        for (let elseIf of elseIfStatements.info) {
          if (toBool(await eEqn(elseIf.info[0], env))) {
            await eEqn(elseIf.info[1], env);
            evaluated = true;
            break;
          }
        }
      }
      if (elseStatement && !evaluated) {
        await eEqn(elseStatement.info, env);
      }
    }

    break;
  
  case 'try_statement':
    let [tryStatements, catchStatement] = info;
    try {
      await eEqn(tryStatements, env);
    } catch (err) {
      if (err.message === 'BLOCK_RETURN') {
        throw err;
      }
      if (catchStatement) {
        await eEqn(catchStatement.info[0], env);
      }
    }
    break;
  case 'plus':
    return toNum(await eEqn(info[0], env)) + toNum(await eEqn(info[1], env));
  case 'ternary':
    if (toBool(await eEqn(info[0], env))) {
      return await eEqn(info[1], env);
    } else {
      return await eEqn(info[2], env);
    }
  case 'concat':
    return toStr(await eEqn(info[0], env)) + toStr(await eEqn(info[1], env));
  case 'negate':
    return -toNum(await eEqn(info, env));
  case 'minus':
    return toNum(await eEqn(info[0], env)) - toNum(await eEqn(info[1], env));
  case 'mult':
    return toNum(await eEqn(info[0], env)) * toNum(await eEqn(info[1], env));
  case 'div':
    return toNum(await eEqn(info[0], env)) / toNum(await eEqn(info[1], env));
  case 'pow':
    let base = toNum(await eEqn(info[0], env));
    let exp = toNum(await eEqn(info[1], env));
    if (base < 0 && Math.round(exp) !== exp) {
      throw new ParseError('Can\'t raise a negative base to a fractional power.');
    }
    return Math.pow(base, exp);
  case 'equals':
    return equals(await eEqn(info[0], env), await eEqn(info[1], env));
  case 'strict_equals':
    return strictEquals(await eEqn(info[0], env), await eEqn(info[1], env));
  case 'not_equals':
    return !equals(await eEqn(info[0], env), await eEqn(info[1], env));
  case 'gt':
    return toNum(await eEqn(info[0], env)) > toNum(await eEqn(info[1], env));
  case 'gt_eq':
    return toNum(await eEqn(info[0], env)) >= toNum(await eEqn(info[1], env));
  case 'lt':
    return toNum(await eEqn(info[0], env)) < toNum(await eEqn(info[1], env));
  case 'lt_eq':
    return toNum(await eEqn(info[0], env)) <= toNum(await eEqn(info[1], env));
  case 'not':
    return !toBool(await eEqn(info, env));
  case 'and':
    return toBool(await eEqn(info[0], env)) && toBool(await eEqn(info[1], env));
  case 'or':
    return toBool(await eEqn(info[0], env)) || toBool(await eEqn(info[1], env));
  case 'function_call':
    if (info.name.type === 'identifier') {
      let fnName = info.name.info.toLowerCase();

      // Function resolution rules:
      //
      // 1. If user has defined a variable AND it is a valid function
      //    - use it
      // 2. If a built-in exists
      //    - use it
      // 3. Throw an error that it is not a function
      //
      // We allow the user to override the function names, because
      // if we didn't and launched a new function it could clobber existing
      // user functions.
      //
      // However we ensure the function is valid so user form variables don't
      // clobber global values.

      // data.data will be null when tokenizing but it shouldn't cause an error here
      let userFn = env.data.data ? env.data.get(fnName) : null;
      if (userFn) {
        let converted;
        try {
          converted = await toFn(userFn, env);
        } catch (_) {}
        if (converted) {
          return callFn(converted, await Promise.all(info.args.map(x => eEqn(x, env))), env.derivedLocation('function call - ' + node.startPosition));
        }
      }

      // 'catch' and 'iserror' needs special handling to try-catch errors
      if (fnName === 'catch') {
        if (info.args.length <= 1) {
          // In sync with catch() from BSQL in backend repo
          throw new ParseError('"catch" requires at least 2 arguments');
        }

        let errStr;
        for (let i = 0; i < info.args.length - 1; i++) {
          // Return the first argument that evaluates without an error
          try {
            let x = await eEqn(info.args[i], env);
            if ((typeof x === 'object') && x.type === 'error') {
              errStr = x.info;
            } else {
              return x;
            }
          } catch (e) {
            errStr = e.message;
          }
        }
        let v = await eEqn(info.args[info.args.length - 1], env);
        if (v.type === 'lambda') {
          return callFn(v, [errStr], env.derivedLocation('catch handler - ' + node.startPosition));
        } else {
          return v;
        }
      }

      if (fnName === 'iserror') {
        if (info.args.length !== 1) {
          throw new ParseError('"iserror" requires 1 parameter');
        }
        try {
          let v = await eEqn(info.args[0], env);
          return (typeof v === 'object') && v.type === 'error';
        } catch (_err) {
          return true;
        }
      }

      let fn = FUNCTIONS.hasOwnProperty(fnName) && FUNCTIONS[fnName];
      if (!fn) {
        throw new ParseError(`"${fnName}" is not a function`);
      }

      // console.log('e', env);
      /** @type {object[]} */
      let args = await Promise.all(info.args.map(x => eEqn(x, env)));
      if (Array.isArray(fn.args)) {
        if (args.length !== fn.args.length) {
          throw new ParseError(`"${fnName}" requires ${fn.args.length} parameter${fn.args.length === 1 ? '' : 's'}`);
        }
        args = await Promise.all(args.map((x, i) => fn.args[i](x, env)));
      } else {
        args = await fn.args(args, env);
        if (typeof args === 'string') {
          throw new ParseError(args);
        }
      }
      if (fn.validate) {
        let err = fn.validate(args, env);
        if (err) {
          throw new ParseError(err);
        }
      }

      return fn.fn(args, env.derivedLocation('index - ' + node.startPosition));
    } else {
      let fn = await toFn(await eEqn(info.name, env), env);
      return callFn(fn, await Promise.all(info.args.map(x => eEqn(x, env))), env.derivedLocation('function call - ' + node.startPosition));
    }
  case 'identifier':
    if ((!env.data) || env.data.data == null || env.config.noData) {
      throw new DataRequiredError();
    }
    if (env.data.defined(info)) {
      return env.data.get(info);
    } else {
      throw new ParseError(`Unknown name "${info}"`);
    }
  case 'command':
    let isAddon = isAddonCommand(info);

    let myEnv = env.derivedConfig({ 
      mode: isAddon ? 'addon' : 'attribute'
    }, 'embedded_command - ' + node.startPosition);

    // The command cache has to be keyed on location path so
    // repeats don't use the same cache
    if (!node.domCache) {
      node.domCache = {};
    }
    let location = env.locations.join(';');
    if (!node.domCache[location]) {
      node.domCache[location] = await parse({ ops: [{ insert: info }] }, myEnv);
    }
    
    const isImport = info.startsWith('{import:');
    if (!isImport
      && (
        node.domCache[location].children[0].tag === 'text' // the broken command is on one line
        || (
          node.domCache[location].children[0].tag === 'p'
          && node.domCache[location].children[0].children[0]?.tag === 'text'
        ) // the broken command is split across multiple lines  in which case it will be returned in paragraphs
      )
    ) {
      // We would text nodes for imported
      // Failed to parse the command (possibly because it is invalidIn attributes)
      throw new ParseError('Invalid command in formula: ' + info);
    }

    if (isAddon) {
      // parent noData settings shouldn't propagate to addons, as the addon will be evaluated in a separate parse()
      delete myEnv.config.noData;
    }

    let invalidRemoteCommand = !env.config.isOneoffFormula && node.domCache[location].find(x => x.type === 'expand' && x.tag === 'remote');
    if (invalidRemoteCommand) {
      const commandName = invalidRemoteCommand.info.command;
      throw new ParseError(`Cannot include {${commandName}} in a formula.`);
    }

    const evaluated = trimDom(await fillDom(node.domCache[location].clone(), myEnv));
    trimNotes(evaluated);

    let invalidCommand = evaluated.find(x => x.type === 'expand' && !['text', 'html'].includes(x.tag));
    if (invalidCommand) {
      console.log(invalidCommand);
      throw new ParseError('Cannot include this command in a formula.');
    }
    let error = evaluated.find(x => x.type === 'error');
    if (error) {
      throw new ParseError(error.info.message);
    }
    return domToStream(evaluated, 'text').join('');
  default:
    throw new Error('Unknown eEqn node type: ' + node.type + '\n\n' + JSON.stringify(node));
  }
}


/**
 * Creates a list from an array.
 * 
 * @param {*[]} array
 * 
 * @return {List}
 */
export function createListFromArray(array) {
  return new List(array);
}


/**
 * Creates a list from an object.
 * 
 * @param {object} obj
 * 
 * @return {List}
 */
export function createListFromObject(obj) {
  return new List(null, obj);
}

/**
 * Creates an object from an object.
 * 
 * @param {object} list
 * 
 * @return {object}
 */
export function listToObject(list) {
  if (!list.keys) {
    if (list === true) {
      return 'yes';
    } else if (list === false) {
      return 'no';
    } else {
      let newList;
      try {
        newList = toLst(list);
        return listToObject(newList);
      } catch (_) {}
      return list;
    }
  }

  if (Object.keys(list.keys).length) {
    let res = {};
    for (let key in list.keys) {
      res[key] = listToObject(list.keys[key]);
    }
    return res;
  } else {
    return list.positional.map(elem => listToObject(elem));
  }
}


/**
 * Quotes special characters in a string.
 * 
 * @param {string} str
 * 
 * @return {string}
 */
function quoteString(str) {
  return str.replace(/\\/g, '\\\\').replace(/"/g, '\\"');
}


/**
 * Converts a list to a string.
 * 
 * @param {object} obj
 * 
 * @return {string}
 */
function listString(obj) {
  let pieces = obj.positional.map(x => {
    if (typeof x === 'string') {
      return '"' + quoteString(x) + '"';
    }
    return toStr(x);
  });
  for (let key in obj.keys) {
    let strForm = toStr(obj.keys[key]);
    if (typeof obj.keys[key] === 'string') {
      strForm = '"' + quoteString(strForm) + '"';
    }
    pieces.push(`"${quoteString(key)}": ${strForm}`);
  }
  return '[' + pieces.join(', ') + ']';
}


/**
 * Calls a lambda function (generally user defined).
 * 
 * @param {import('./ParseNode').default} lambda - Eqn node representing the lambda function
 * @param {object[]} args
 * @param {Environment} env
 * 
 * @return {Promise<object>}
 */
export async function callFn(lambda, args, env) {
  let argNames = lambda.info.args;
  let exp = lambda.info.exp;
  let statements = lambda.info.statements;

  // We allow additional arguments to be passed as some may be optional arguments
  // (e.g. the done() callback for urlload or an itemformatter() callback).
  // Additional args will be ignored.
  if (argNames.length > args.length) {
    throw new ParseError(`Only ${args.length} arguments are provided to the function; ${argNames.length} were defined.`);
  }

  let newData = Object.create(null);
  for (let i = 0; i < argNames.length; i++) {
    newData[argNames[i].info] = args[i];
  }

  let newEnv = env.derivedLocation('FUNCTION');
  newEnv.data = new DataContainer(newData);
  newEnv.data.parent = env.data;

  await newEnv.data.ready;

  return eEqn(exp || statements, newEnv);
}



/**
 * @param {object} forStmt
 * @param {Environment} env
 * 
 * @return {Promise<{ values: any[], sourceShape: {type: string, indexes: any[]} }>}
 */
export async function processForStatement(forStmt, env) {
  let argDefs = forStmt.info.args;
  let lcBase = await toLstArg(await eEqn(forStmt.info.base, env), env);
  let filter = forStmt.info.if;

  let processed;
  let getLocalEnvironment = getLocalEnvironmentGenerator(argDefs, env);

  let type, indexes;
  if (lcBase.positional.length) {
    type = 'position';
    processed = lcBase.positional.map((x,i) => [x, i + 1]);
    if (filter) {
      processed = await filterAsync(processed, async x => toBool(await eEqn(filter.info, await getLocalEnvironment(x[0], x[1]))));
    }
    indexes = (new Array(processed.length)).fill(0).map((_, i) => i + 1); 
  } else {
    type = 'key';
    if (filter) {
      processed = {};
      await Promise.all(Object.keys(lcBase.keys).map(async (k) => {
        if (toBool(await eEqn(filter.info, await getLocalEnvironment(k, lcBase.keys[k])))) {
          processed[k] = lcBase.keys[k];
        }
      }));
    } else {
      processed = lcBase.keys;
    }
    let keys = Object.keys(processed);
    keys.sort();
    indexes = keys.slice();
    processed = keys.map((x) => [x, processed[x]]);
  }

  return { values: processed,  sourceShape: { type, indexes } };
}


/**
 * @param {Array} argDefs
 * @param {Environment} env
 * @param {boolean=} onlyLocalData - if true, only the new data will be returned
 * 
 * @return {function}
 */
export function getLocalEnvironmentGenerator(argDefs, env, onlyLocalData = false) {
  /** @return {Promise<DataContainer|Environment>} */
  return async (...args) => {
    if (argDefs.length > args.length) {
      throw new ParseError('Too many arguments specified in list comprehension.');
    }
    let def = Object.create(null);
    for (let i = 0; i < argDefs.length; i++) {
      def[argDefs[i].info.toLowerCase()] = args[i];
    }
    let container = new DataContainer(def);
    container.parent = env.data;

    await container.ready;

    if (!onlyLocalData) {
      return env.derivedData(container);
    }
    
    return container;
  };
}

/**
 * @param {string} str
 * 
 * @return {string}
 */
function preprocessEquation(str) {
  let current = '';
  let currentMode = 'equation';
  let position = 0;
  while (position < str.length) {
    let char = str[position];
    position++;
    if (char === '\\') {
      if (position === str.length) {
        current += '\\';
      } else {
        let char = str[position];
        position++;
        if (currentMode !== 'quote') {
          // no escaping in equations (other than quote mode)
          current += '\\' + char;
        } else {
          // Escapes, \n, \t, \\ and \r, otherwise pass the '\' through
          // Will be parsed as a JSON string later
          if (char === 'n') {
            current += '\n';
          } else if (char === 't') {
            current += '\t';
          } else if (char === 'r') {
            current += '\r';
          } else if (['"', '\\'].includes(char)) {
            current += '\\' + char;
          } else {
            current += '\\\\' + char;
          }
        }
      }
    } else {
      if (char === '"') {
        if (currentMode === 'quote') {
          currentMode = 'equation';
        } else {
          currentMode = 'quote';
        }
      }
      current += char;
    }
  }

  return current;
}


/**
 * 
 * @param {any[]} tokens 
 * @param {number} errorTokenIndex 
 */
/*
function findListErrorPosition(tokens, errorTokenIndex) {
  let token = tokens[errorTokenIndex];
  const LISTASSIGNS = 'LISTASSIGNS',
    ASSIGNS = 'ASSIGNS',
    LBRACKET = 'LBRACKET',
    RBRACKET = 'RBRACKET';

  // If the error source is neither of the elements, we don't need to bother.
  if (token.type !== ASSIGNS
    && token.type !== LISTASSIGNS) {
    return;
  }
  let foundRBRACKET = false,
    foundLBRACKET = false,
    indexASSIGNS = -1,
    indexLISTASSIGNS = -1;

  // It should now be ASSIGNS or LISTASSIGNS
  if (token.type === ASSIGNS) {
    indexASSIGNS = errorTokenIndex;
  } else {
    indexLISTASSIGNS = errorTokenIndex;
  }

  // lets keep a track of nested lists.
  let skip = 0;

  // lets find to the right side if we find a list close bracket
  for (let index = errorTokenIndex + 1; index < tokens.length; index++) {
    token = tokens[index];
    if (token.type === LBRACKET) {
      skip++;
      continue;
    }
    if (skip !== 0) {
      if (token.type === RBRACKET) {
        skip--;
      }
      continue;
    }
    if (indexASSIGNS === -1 && token.type === ASSIGNS) {
      indexASSIGNS = index;
      continue;
    }
    if (indexLISTASSIGNS === -1 && token.type === LISTASSIGNS) {
      indexLISTASSIGNS = index;
      continue;
    }
    if (token.type === RBRACKET) {
      foundRBRACKET = true;
      break;
    }
  }

  // Not a list. Ignore.
  if (!foundRBRACKET) {
    return;
  }

  for (let index = errorTokenIndex - 1; index >= 0; index--) {
    token = tokens[index];
    if (token.type === RBRACKET) {
      skip++;
      continue;
    }
    if (skip !== 0) {
      if (token.type === LBRACKET) {
        skip--;
      }
      continue;
    }
    if (indexASSIGNS === -1 && token.type === ASSIGNS) {
      indexASSIGNS = index;
      continue;
    }
    if (indexLISTASSIGNS === -1 && token.type === LISTASSIGNS) {
      indexLISTASSIGNS = index;
      continue;
    }
    if (token.type === LBRACKET) {
      foundLBRACKET = true;
      break;
    }
  }
  // could not find 
  if (indexLISTASSIGNS === -1
    || indexASSIGNS === -1
    // Not a list
    || !foundLBRACKET) {
    return;
  }

  return {
    position: tokens[indexASSIGNS].position
  };
}*/