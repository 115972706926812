import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { Box, TextField } from '@mui/material';
import T from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import ImageUploader from '../ImageUploader/ImageUploader';
import CircularProgress from '@mui/material/CircularProgress'; 

import ConfigureIcon from '@mui/icons-material/Settings';
import InsertEmoticonIcon from '@mui/icons-material/FolderOutlined';

import Callout from '../Callout/Callout';
import useFirestore from '../../FirestoreLink/FirestoreHook';
import BufferedEditableText from '../BufferedEditableText/BufferedEditableText';
import { Bytes, deleteField } from 'firebase/firestore';
import { compressDelta, decompressDelta } from '../../delta_proto/DeltaProto';
import { isDev } from '../../flags';
import { useTypedSelectorShallowEquals, useTypedSelector } from '../../hooks';
import DeleteButton from '../DeleteButton/DeleteButton';
import { enableGroup } from '../../data';
import { userPermission } from '../../auth';
import VerificationReminder from '../VerificationReminder/VerificationReminder';
import EmailMismatchReminder from '../EmailMismatch/EmailMismatch';
import { toast } from '../../message';
import PageEditor from '../Pages/PageEditor';
import { EmptyState } from '../EmptyState/EmptyState';
import { enableFolderIcon } from './group_utils';

import { isGroupPublicManageRestricted } from './group_public_rules';
import EmojiSelector from '../Messaging/LazyEmojiSelector';

// @ts-ignore
import BackgroundImage from '../../../images/background_512.png';
import GroupSidebar from './GroupSidebar';
import { makeRef } from '../../firebase_utilities';
import { useParams, useLocation } from 'react-router-dom';


function GroupBase() {
  const { id: groupId } = /** @type {{ id: string }} */ (useParams());
  let [openPageEditor, setOpenPageEditor] = useState(false);
  const { search: urlSearch } = useLocation();

  let uploaderRef = useRef(null);
  let dispatch = useDispatch();

  let [showEmoji, setShowEmoji] = useState(false);
  let emojiButtonRef = useRef(null);

  const {
    user,
    isAddon,
    isPublished,
    isSharedGroup,
    emailVerified,
    peekingOnly,
    enabled,
    isConnected,
    isConnectedByUs
  } = useTypedSelectorShallowEquals((state) => {
    let uid = state.userState.uid;
    let prefs = state.userState.groups ? state.userState.groups[groupId] : null;
    let group = state.dataState.groups[groupId];
  
    let orgId = state.userState.org ? state.userState.org.id : null;
    let isConnected = group && group.connected && group.connected.is_connected;
    let isConnectedByUs = isConnected && (
      group.connected.connector === 'u:' + uid || group.connected.connector === 'o:' + orgId);


    return {
      user: state.userState,
      isAddon: !!(group && group.options && group.options.addon),
      isPublished: !!(group && group.associated_addon_id),
      isSharedGroup: group && group.shared, // Needed rather then .group for the 'shared' flag on the group object
      emailVerified: state.userState.emailVerified,
      peekingOnly: group && group.peekingOnly,
      enabled: !(group && group.peekingOnly) && (/* something like a team group */ (!prefs && group) || /* your own group */ (prefs && !prefs.disabled)), // Want to make sure shared groups that haven't been added are disabled if loaded directly
      isConnected,
      isConnectedByUs
    };
  });

  const groupSeed = useTypedSelector((state) => state.dataState.groups[groupId], () => {
    // we never want this to trigger a re-render, as we only need it to initialize the
    // the Firestore link
    return true;
  });


  const link = useFirestore(makeRef('groups', groupId), groupSeed);
  /** @type {GroupObjectType} */
  const group = link.data && Object.assign({ id: groupId }, link.data);
  const groupUpdateFn = link.updateFn;

  let options = (group && group.options) || {};
  let addon = options.addon || {};
  let isPublicRestricted = user && group && isGroupPublicManageRestricted(user, group);

  const intendedRecipient = (new URLSearchParams(urlSearch)).get('recipient');

  useEffect(() => {
    // If we are opening a group we haven't downloaded, let's peek it
    // to make sure it shows in the sidebar.
    if (link.exists && !groupSeed) {
      dispatch({
        type: 'PEEK_GROUP',
        data: {
          group_id: groupId
        }
      });
    }
    
    // this should only happen once, so
    // eslint-disable-next-line
  }, [link.exists, !!groupSeed]);


  function handleInfoChange(value) {
    groupUpdateFn({ info: value });
  };


  function handleNameChange(value) {
    groupUpdateFn({ name: value });
  };
  

  function permission() {
    if (!group) {
      return null;
    }
    return userPermission(group);
  }


  function editable() {
    return isEditor() && (!isConnected || isConnectedByUs);
  }


  function isEditor() {
    return ['owner', 'editor'].includes(permission());
  }


  function enable() {
    enableGroup(groupId);
  }




  if (link.loading) {
    return <div style={{ paddingTop: '15vh', marginBottom: 30, justifyContent: 'center', display: 'flex' }}><CircularProgress size={150} thickness={1.9} /></div>;
  } else if (!link.exists || link.error) {
    let contents;
    if (!emailVerified) {
      contents = <VerificationReminder />;
    } else if (intendedRecipient && intendedRecipient !== user.email) {
      contents = <EmailMismatchReminder expectedEmail={intendedRecipient} actualEmail={user.email} type="folder" />;
    }
    return (<div style={{ paddingTop: emailVerified ? '30vh' : '10vh', marginBottom: 30 }}>
      <EmptyState
        icon="MISSING"
        title="Could not load folder"
        description="That folder does not exist or you do not have access to it."
      />
      {contents && <div><br /><br /><br />{contents}</div>}
    </div>);
  }

  return (<Paper elevation={3} style={{ height: '100%', maxHeight: '100%', overflow: 'auto' }}>
    <Box
      sx={{
        display: {
          md: 'flex',
          xs: 'flex'
        },
        flexDirection: {
          md: 'row',
          xs: 'column'
        },
        minHeight: '100%'
      }}>
      <div style={{
        position: 'relative',
        overflow: 'auto',
        backgroundImage: isAddon ? undefined : `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        backgroundAttachment: 'fixed',
        backgroundSize: 'contain',
        padding: 30,
        paddingBottom: 15,
        paddingTop: 27,
        display: 'flex',
        flexDirection: 'column',
        flex: 'min-content'
      }}>
        <T variant="h3" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <IconButton
            ref={emojiButtonRef}
            sx={{
              mr: 2
            }}
            onClick={() => {
              setShowEmoji(!showEmoji);
            }}
            disabled={!editable()}
          >
            <Tooltip title="Folder icon">
              {(options.icon && typeof options.icon === 'string') ? <div
                translate="no"
                style={{
                  fontSize: '34px',
                  lineHeight: '34px',
                  width: 38,
                  height: 38,
                  color: 'initial',
                  verticalAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>{options.icon}</div> : <InsertEmoticonIcon fontSize="large" />}
            </Tooltip>
          </IconButton>
          <BufferedEditableText
            disabled={!editable()}
            placeholder="Edit name..."
            selectAllOnFocus
            minWidth={280}
            value={group ? group.name : ''}
            onChange={handleNameChange}
            style={{
              overflow: 'hidden'
            }}
            validate={(value) => {
              if (value.trim() === '') {
                toast('The folder name cannot be blank.', {
                  duration: 4000,
                  intent: 'danger'
                });
                return false;
              }
              return true;
            }}
          />
        </T>
        <br/>
        <BufferedEditableText
          disabled={!editable()}
          maxLines={12}
          minLines={4}
          multiline
          placeholder={editable() ? 'Edit description...' : ''}
          selectAllOnFocus={false}
          value={group ? group.info : ''}
          onChange={handleInfoChange}
        />

        {(isConnected && !isConnectedByUs) && <Alert severity="info" style={{ marginBottom: 18 }}>This is a Connected Folder. You can only edit shared Connected Folders within the same Text Blaze Business organization.</Alert>}

        {(!group || enabled || isPublicRestricted) ? null : <Callout
          style={{ marginTop: 20 }}
          title={'Folder is disabled' + (isSharedGroup ? ' for you' : '')}
        >
          <T>This folder is currently {peekingOnly ? 'not enabled' : 'disabled'} for your account meaning snippets in it will not be triggered by you. You can enable it to activate it and its snippets.</T>
          <div style={{ textAlign: 'right', marginTop: 8 }}>
            <Button
              onClick={() => enable()}
              variant="outlined"
              startIcon={enableFolderIcon}
            >Enable folder</Button>
          </div>
        </Callout>}

        {(isAddon && permission() === 'owner' && !isPublicRestricted) ?
          <>
            <div style={{ display: 'flex', width: '100%', marginTop: 12 }}>
              <div style={{ width: '80%' }}>
                <TextField
                  sx={{
                    my: 0.8
                  }}
                  label="Command prefix"
                  variant="filled"
                  fullWidth
                  size="small"
                  disabled={isPublished}
                  value={addon.namespace || ''}
                  onChange={(e) => {
                    groupUpdateFn({ 'options.addon.namespace': e.target.value });
                  }}
                  helperText="The prefix must be unique across all published command packs and should be lowercase and 3 to 12 letters long."
                />

                <TextField
                  sx={{
                    my: 0.8
                  }}
                  label="Author name"
                  variant="filled"
                  fullWidth
                  size="small"
                  value={addon.author_name || ''}
                  onChange={(e) => {
                    groupUpdateFn({ 'options.addon.author_name': e.target.value });
                  }}
                  helperText="Your own name or the name of your organization. This will be public to Text Blaze users."
                />

                <TextField
                  sx={{
                    my: 0.8
                  }}
                  label="Author email"
                  variant="filled"
                  fullWidth
                  size="small"
                  value={addon.author_email || ''}
                  onChange={(e) => {
                    groupUpdateFn({ 'options.addon.author_email': e.target.value });
                  }}
                  helperText="Where people can contact you. This will be public to Text Blaze users."
                />
                {/** TODO: enable pages */ isDev() ? <>
                  <Button
                    startIcon={<ConfigureIcon/>}
                    style={{
                      marginTop: 14,
                      marginBottom: 8
                    }}
                    variant="outlined"
                    onClick={ () =>
                      setOpenPageEditor(true)
                    }
                  >Edit settings page</Button>
                  <DeleteButton
                    item="the settings page"
                    onDelete={() => {
                      groupUpdateFn({
                        'options.addon.config': deleteField()
                      });
                      toast('Settings page deleted.', {
                        duration: 6000,
                        intent: 'success'
                      });
                    }}
                  />
                  <T variant="caption" color="textSecondary" paragraph>This optional settings page allows users to configure the command pack. Form values from the settings page will be available in every command.</T>
                </> : null}

                {openPageEditor && <PageEditor 
                  title="Edit command pack settings page"
                  content={addon.config && addon.config.content && {
                    delta: decompressDelta(Bytes.prototype.toUint8Array.apply(addon.config.content.delta)),
                    id: addon.config.content.id
                  }}
                  formNames={addon.config && addon.config.form_names}
                  onClose={() => setOpenPageEditor(false)}
                  onDone={(data) => {
                    let { content, formNames } = data;
                    groupUpdateFn({
                      'options.addon.config': {
                        content: {
                          delta: Bytes.fromUint8Array(compressDelta(content.delta)),
                          id: content.id,
                          syntax: 1,
                          format: 'quill'
                        },
                        form_names: formNames
                      }
                    });
                    setOpenPageEditor(false);
                  }}
                />}

                <ImageUploader
                  ref={uploaderRef}
                  type="a"
                />

                <Paper variant="outlined" style={{ padding: 12 }}>
                  {addon.banner_image_url && <img src={addon.banner_image_url} alt="Command Pack Banner" style={{
                    backgroundColor: 'white',
                    padding: 8,
                    marginTop: 16,
                    marginBottom: 16,
                    border: 'solid 1px #777',
                    display: 'block',
                    maxWidth: '100%',
                    borderRadius: 5
                  }} />}

                  <Button
                    variant="outlined"
                    onClick={ () =>
                      uploaderRef.current.selectImage().then((image) => {
                        groupUpdateFn({ 'options.addon.banner_image_url': image.url });
                      }).catch(() => {})
                    }
                  >Choose command pack banner image...</Button>

                  <T variant="caption" color="textSecondary" style={{
                    marginTop: 12,
                    display: 'block'
                  }}>Banner image for the command pack.</T>
                </Paper>

                <Paper variant="outlined" style={{ padding: 12, marginTop: 18 }}>
                  {addon.icon_image_url && <img src={addon.icon_image_url} alt="Command Pack Icon" style={{
                    backgroundColor: 'white',
                    padding: 8,
                    marginTop: 16,
                    marginBottom: 16,
                    border: 'solid 1px #777',
                    display: 'block',
                    maxWidth: '100%',
                    width: 128,
                    height: 128,
                    borderRadius: 5
                  }} />}

                  <Button
                    variant="outlined"
                    onClick={ () =>
                      uploaderRef.current.selectImage().then((image) => {
                        groupUpdateFn({ 'options.addon.icon_image_url': image.url });
                      }).catch(() => {})
                    }
                  >Choose command pack icon...</Button>

                  <T variant="caption" color="textSecondary" style={{
                    marginTop: 12,
                    display: 'block'
                  }}>Icon for the command pack (should be 256x256px).</T>
                </Paper>

                <br/><br/><br/>
              </div>
            </div>
          </> : <><div style={{ flex: 4 }}></div></>}

        <div style={{ flex: 1 }}></div>
      </div>
      <GroupSidebar groupId={groupId} />
    </Box>
    {showEmoji && <EmojiSelector
      target={emojiButtonRef.current}
      showRemove={!!group?.options?.icon}
      removeLabel="Remove icon"
      onClose={() => {
        setShowEmoji(false);
      }}
      onSelect={(emoji) => {
        setShowEmoji(false);

        if (emoji === null) {
          groupUpdateFn({
            'options.icon': deleteField()
          });
        } else {
          groupUpdateFn({
            'options.icon': emoji 
          });
        }
      }}
    />}
  </Paper>);
}

const Group = React.memo(GroupBase);
export default Group;