import { IconButton, Paper, Slide, Stack, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { APP_PLATFORM_NAME, DESKTOP_APP_NAME, isElectronApp, CURRENT_PLATFORM } from '../../flags';
import { usersSettingsRef } from '@store';
import { storage } from '../../utilities';
import T from '@mui/material/Typography';
import { checkExtensionInstalled } from '../../extension';
import { disableDesktopAppInBrowser, dismissNotification, enableDesktopAppInBrowser } from './conflict_utilities';
import { useTypedSelectorShallowEquals } from '../../hooks';

import DesktopWindowsIcon from '@mui/icons-material/DesktopWindowsOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from '../../message';
import { useDesktopClientSupportsFeature } from '../../desktop_hooks';

const DESKTOP_EXTENSION_CONFLICT = `%APP_${CURRENT_PLATFORM.toUpperCase()}_EXTENSION_CONFLICT`;
let desktopAppDisabledOnce = false;

function AppConflictBase() {
  const isDesktop = isElectronApp();
  const [extensionInstalledLocally, setExtensionInstalledLocally] = useState(false);
  const isAutoDetectApp = useDesktopClientSupportsFeature('extension-detection', true);
  let showDialog = false;
  let { hasUsedDesktop, hasUsedExtension, isOnboardingCompleteOnDashboard, isOnboardingCompletedOnDesktop, settingsLoaded, isConflictDialogDismissed, isDesktopAppDisabledInChromeBrowsers } = useTypedSelectorShallowEquals((store) => {
    return {
      hasUsedDesktop: store.userState?.options?.[`has_used_${CURRENT_PLATFORM}`],
      settingsLoaded: store.userState?.settingsLoaded,
      isDesktopAppDisabledInChromeBrowsers: store.userState?.options?.[`is_${CURRENT_PLATFORM}_app_disabled_in_chrome_browsers`],
      isOnboardingCompletedOnDesktop: store.userState?.quest?.[`${CURRENT_PLATFORM}_completed`],
      isOnboardingCompleteOnDashboard: store.userState?.quest?.completed || store.userState?.quest?.dismissed,
      isConflictDialogDismissed: store.userState?.dismissed_notifications && store.userState.dismissed_notifications.includes(DESKTOP_EXTENSION_CONFLICT),
      hasUsedExtension: store.userState?.options?.has_used_extension,
    };
  });

  function updateInstallState() {
    return checkExtensionInstalled().then((isInstalled) => {
      setExtensionInstalledLocally(isInstalled);
    });
  }

  useEffect(() => {
    if (!isDesktop) {
      updateInstallState();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAutoDetectApp && settingsLoaded && !isConflictDialogDismissed) {
    if (isDesktop) {
      if (isOnboardingCompletedOnDesktop) {
        // this is first time user has opened the dashboard in desktop app
        if (hasUsedExtension) {
          // has used extension earlier
          // ask if they want to enable it
          if (isDesktopAppDisabledInChromeBrowsers === undefined && !desktopAppDisabledOnce) {
            disableDesktopAppInBrowser();
            desktopAppDisabledOnce = true;
          }
          showDialog = true;
        }
      }
      if (!hasUsedDesktop) {
        // this is the first time user has opened, update the setting
        updateAppSetting();
      }
    } else {
      // is on browser
      if (extensionInstalledLocally) {
        if (isOnboardingCompleteOnDashboard && hasUsedDesktop) {
          // disable if not already disabled and show dialog
          if (isDesktopAppDisabledInChromeBrowsers === undefined && !desktopAppDisabledOnce) {
            disableDesktopAppInBrowser();
            desktopAppDisabledOnce = true;
          }
          showDialog = true; 
        }
        if (!hasUsedExtension) {
          updateAppSetting();
        }
      }
    }
  }
  
  function updateAppSetting() {
    // update the settings
    if (isDesktop) {
      storage.update(usersSettingsRef, {
        [`options.has_used_${CURRENT_PLATFORM}`]: true,
      }, 'HIDE_AUTOSAVE');
    } else {
      storage.update(usersSettingsRef, {
        'options.has_used_extension': true
      }, 'HIDE_AUTOSAVE');
    }
    
  }
  
  function renderDialog() {
    if (showDialog) {
      return (
        <Slide direction="up" in data-testid="conflict-dialog">
          <Paper
            elevation={6}
            sx={{
              position: 'fixed',
              right: '10px',
              bottom: '10px',
              width: {
                xs: '300px',
                sm: '425px',
              },
              padding: '12px 24px',
              zIndex: 2
            }}
          >
            <T variant="h5" paragraph style={{ marginTop: 10 }}><DesktopWindowsIcon fontSize="large" style={{
              opacity: 0.5,
              marginRight: 4,
              verticalAlign: 'middle'
            }}/> {isDesktop ? DESKTOP_APP_NAME : 'Desktop App'} in Chrome
            <IconButton
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                opacity: .7
              }}
              size="small"
              title="dismiss"
              onClick={() => {
                dismissNotification(DESKTOP_EXTENSION_CONFLICT);
              }}
            > <CloseIcon fontSize="small" /> </IconButton>
            </T>
            <T paragraph>{isDesktop ? 'You also have the Text Blaze Chrome extension' : 'You also have the Text Blaze for desktop app'}{desktopAppDisabledOnce && ', so we disabled the app in Chrome'}.</T>
            <Stack direction="row" spacing={1} alignItems="center" sx={{
              justifyContent: 'center',
              mb: 1
            }}>
              <Switch
                size="medium"
                checked={!isDesktopAppDisabledInChromeBrowsers}
                onChange={(e) => {
                  if (e.target.checked) {
                    enableDesktopAppInBrowser();
                    toast(`The ${isDesktop ? APP_PLATFORM_NAME : 'desktop'} app is enabled in Chrome-based browsers. Your snippet may trigger twice in Chrome-based browsers if you also have the extension installed.`, {
                      duration: 6000,
                      intent: 'info'
                    });
                  } else {
                    disableDesktopAppInBrowser();
                  }
                }}
              />
              <T>Enable {isDesktop ? DESKTOP_APP_NAME : 'Desktop App'} in Chrome</T>
            </Stack>

            <T paragraph color="textSecondary">You can change this setting anytime on your Options page. Chrome includes Brave, Edge, etc...</T>
          </Paper>
        </Slide>
      );
    }
    return null;
  }
  return renderDialog();
}

const AppConflict = React.memo(AppConflictBase);
export default AppConflict;
