import TypedContainer from './typedContainer';

/**
 * @extends TypedContainer<import('./tableCol').default, import('./tableContainer').default>
 */
class TableColGroup extends TypedContainer {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('content-editable', 'true');
    return node;
  }


  optimize (context) {
    super.optimize(context);
  }
}
TableColGroup.blotName = 'tableColGroup';
TableColGroup.tagName = 'colgroup';

export default TableColGroup;