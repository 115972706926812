import React, { useState, useRef } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { createNotifications } from '../Notifications/notifications';
import Zoom from '@mui/material/Zoom';
import { NO_NOTIFICATIONS } from './notifications_helper';
import Tooltip from '@mui/material/Tooltip';
import { useTypedSelector } from '../../hooks';
import { createSelector } from 'reselect';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import { APP_TARGET } from '../../flags';
import { useAnyAppInstalled } from '../../desktop_hooks';


// So equality comparisons don't change
const EMPTY_NOTIFICATIONS = [];


// We memoize the selector so the expensive createNotifications won't run
// unless userState or orgState updates
let selector = createSelector(
  [
    state => state.userState,
    state => state.orgState, // needed as createNotifications uses orgState
    _state => APP_TARGET // notifications whould update when changing apps
  ],
  (userState, _orgState, _app) => {
    if (userState && userState.settingsLoaded && userState.readonlyLoaded) {
      return createNotifications(false);
    }

    return {
      showCount: 0,
      notifications: EMPTY_NOTIFICATIONS
    };
  }
);


function NotificationsBase() {
  let [open, setOpen] = useState(false);
  let ref = useRef();

  // reduce mental overhead for users without the extension. We want them to focus on installing it.
  const { hasApp, loaded } = useAnyAppInstalled();
  const hasAnyAppInstalled = hasApp && loaded;


  /** @type {any} */
  let notifications = useTypedSelector(selector);
  const nNotif = notifications.showCount;
  if (notifications.notifications.length === 0) {
    notifications = {
      showCount: 0,
      notifications: NO_NOTIFICATIONS
    };
  }
  
  return <>
    <Tooltip
      title="Notifications"
    >
      <IconButton
        onClick={() => {
          let shouldUpdate = !open;
          
          setOpen(!open);

          if (shouldUpdate) {
            storage.update(usersSettingsRef, { 
              'options.last_viewed_notifications_at': Date.now()
            }, 'HIDE_AUTOSAVE');
          }
        }}
        color="inherit"
        size="small"
        sx={{
          marginRight: {
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2
          }
        }}
        ref={ref}
      >
        {nNotif && hasAnyAppInstalled ?
          <Zoom in><Badge badgeContent={nNotif} color="primary"><NotificationsIcon/></Badge></Zoom> : <NotificationsNoneIcon/>}
      </IconButton>
    </Tooltip>
    {open &&
      <Popover
        open
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: 15, maxHeight: '80vh', overflowY: 'auto' }}>
          {notifications.notifications}
        </div>
      </Popover>}
  </>;
}


const Notifications = React.memo(NotificationsBase);
export default Notifications;