import { initializeApp, getApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import { APP_CONFIG } from './config';
import { setFirebaseDriver } from './firebase_shared_driver';
import { firebaseDriver } from './firebase_shared_driver_impl';


initializeApp(APP_CONFIG);

if (typeof vi === 'undefined') {
  // this solves issues with some VPN's.
  // only run when not testing
  initializeFirestore(getApp(), {
    experimentalForceLongPolling: true
  });
}

// Configure firebase driver for shared code (extension, android)
setFirebaseDriver(firebaseDriver);
