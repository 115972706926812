import React, { Suspense } from 'react';
import VersionComparison from '../Version/VersionComparison';
import { Route, Switch } from 'react-router-dom';
import JoinOrg from '../Org/JoinOrg';
import SSO from '../SSO/SSO';
import Configure from '../Configure/Configure';
import PlaceholderOrg from '../PlaceholderOrg/PlaceholderOrg';
import NotFound from '../NotFound/NotFound';
import ImportExportPanel from '../Configure/ImportExportPanel';
import LoadingProgressIndicator from '../Utilities/LoadingProgressIndicator';
import { eagerlyLoadImport } from '../../utilities';
const Org = React.lazy(eagerlyLoadImport(() => import('../Org/Org')));
const Templates = React.lazy(eagerlyLoadImport(() => import('../Templates/Templates')));

/**
 * 
 * @param {object} props
 * @param {React.ReactNode} props.children 
 * @returns 
 */
function CommonMainPanel({
  children
}) {
  
  return (<Switch>
    <Route exact path="/company/:domain?" component={PlaceholderOrg}/>
    <Route exact path="/community" component={SSO}/>
    <Route exact path="/business/:tab?" component={() => <Suspense fallback={<LoadingProgressIndicator />}>
      <Org />
    </Suspense>} />
    <Route exact path="/pro/:billing(monthly|annually)?/:upgrade(upgrade-pro|upgrade-business)?" component={VersionComparison}/>
    <Route exact path="/upgrade/:billing(monthly|annually)?/:upgrade(upgrade-pro|upgrade-business)?" component={VersionComparison}/>
    <Route exact path="/configure/importexport" component={ImportExportPanel}/>
    <Route exact path="/configure/:tab?" component={Configure}/>
    <Route exact path="/join/business/:id" render={() => <JoinOrg />}/>
    <Route exact path="/template/:edit(profile|list|folder|space)/:collectionId?/" component={() => <Suspense fallback={<LoadingProgressIndicator />}>
      <Templates />
    </Suspense>} />
    {children}
    <Route component={NotFound}/>
  </Switch>);
}



// @ts-ignore
export default CommonMainPanel;
