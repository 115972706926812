import React from 'react';
import './diff.css';
import T from '@mui/material/Typography';


/**
 * @param {object} props
 * @param {any[]} props.changes
 * @param {any=} props.variant
 * @param {any=} props.style
 * @param {any=} props.textStyle
 */
export default function Diff(props) {
  let changes = props.changes;

  return <div className="diff-container" style={props.style}><T style={props.textStyle} variant={props.variant || 'body1'}>{changes.map((c, i) => {
    let className = 'diff-existing';
    if (c[0] === 'R') {
      className = 'diff-remove';
    } else  if (c[0] === 'I') {
      className = 'diff-add';
    }
    return <span key={i} className={className}>{c[1]}</span>;
  })}</T></div>;
}