import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  DialogActions,
  TablePagination
} from '@mui/material';
import {
  limit,
  startAfter,
  orderBy,
  startAt
} from 'firebase/firestore';
import { makeRef } from '../../../firebase_utilities';
import { toast } from '../../../message';
import { storage } from '../../../utilities';
import TrashedDialogContent from '../../TrashedDialog/TrashedDialogContent';
import TrashedList from '../../TrashedDialog/TrashedList';
import TrashedInfo from '../../TrashedDialog/TrashedInfo';
import TrashedEmptyState from '../../TrashedDialog/TrashedEmptyState';
import { TrashedDialogContext } from './TrashedTextBlazeDialog';
import { TRASH_EXPIRATION_LABEL, generateQuery, getFirebaseQuery, mapData } from './trashed_utils';

const PAGE_SIZE = 20;


/**
 * 
 * @template {{ icon: string }} TIconProps
 * @param {object} props
 * @param {'snippets'|'groups'} props.collection
 * @param {import("../../TrashedDialog/TrashedList").TrashedDialogLabels} props.labels
 * @param {[string, import("firebase/firestore").WhereFilterOp, any][]} props.filters - Filters to use to filter the data
 * @param {(item: ReturnType<mapData>) => Promise} props.onRestore
 * @param {React.ComponentType<TIconProps>=} props.IconComponent
 * @param {Omit<TIconProps, "icon">=} props.IconComponentProps
 * @param {React.ReactNode=} props.footer
 * @param {((data: ReturnType<mapData>) => ReturnType<mapData>)=} props.transformData
 * @param {React.ReactElement=} props.header
 * @param {React.ReactElement=} props.additionalEmptyMessage
 * @param {React.ReactElement=} props.toolbarItems
 * @returns 
 */
const TrashedFirestore = ({
  collection,
  labels,
  filters: filtersProp,
  onRestore,
  IconComponent,
  IconComponentProps,
  footer,
  transformData,
  header,
  additionalEmptyMessage,
  toolbarItems
}) => {
  const { loading, setLoading } = useContext(TrashedDialogContext);
  const [pageNumber, setPageNumber] = useState(0);
  let [data, setData] = useState(/** @type {ReturnType<mapData>[]} */ ([]));
  let [hasMore, setHasMore] = useState(false);
  let [error, setError] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const totalDataRef = useRef([]);
  const constants = {
    collection,
    transformData,
    setLoading
  };
  const constantRef = useRef(constants);
  constantRef.current = constants;

  /**
   * 
   * @param {Parameters<typeof TrashedFirestore>['0']['filters']} filters
   * @param {Parameters<typeof TrashedFirestore>['0']['transformData']} [transformData]
   * @param {string} collection
   * @param {object} [start]
   * @param {import('firebase/firestore').Timestamp=} start.at
   * @param {import('firebase/firestore').Timestamp=} start.after
   * @param {{ canceled: boolean }} [cancelationRef]
   */
  const getPage = async (collection, filters, transformData, start, cancelationRef) => {
    /**
     * @param {boolean} val
     */
    const setLoading = (val) => {
      constantRef.current.setLoading(val);
    };
    try {
      setError(false);
      setLoading(true);
      /** @type {import('firebase/firestore').QueryConstraint[]} */
      const queries = [
        ...generateQuery(filters),
        orderBy('deleted_at', 'desc'),
        limit(PAGE_SIZE + 1),
      ];
      if (start?.at) {
        queries.push(startAt(start.at));
      } else if (start?.after) {
        queries.push(startAfter(start.after));
      }
      const ref = getFirebaseQuery(collection, queries);

      const snapshot = await storage.getQuery(ref);
      if (cancelationRef?.canceled) {
        return;
      }
      
      let gotAdditional = snapshot.docs.length > PAGE_SIZE;
      setHasMore(gotAdditional);
      let docs = snapshot.docs;
      if (gotAdditional) {
        docs = docs.slice(0, docs.length - 1);
      }
      /**
       * @type {mapData}
       */
      const mapDataWrapper = (id, data) => {
        let res = mapData(id, data);
        if (transformData) {
          res = transformData(res);
        }
        return res;
      };
      const newData = docs.map(x => mapDataWrapper(x.id, x.data()));
      setData(
        newData
      );
      totalDataRef.current = [
        ...totalDataRef.current,
        ...newData
      ];
    } catch (err) {
      if (!cancelationRef?.canceled) {
        toast(`Error fetching trashed items - ${err.message}`, {
          intent: 'danger'
        });
        setError(true);
      }
    } finally {
      if (!cancelationRef?.canceled) {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (!filtersProp.length) {
      return;
    }
    totalDataRef.current = [];
    setPageNumber(0);
    const cancelationRef = {
      canceled: false
    };
    getPage(constantRef.current.collection, filtersProp, constantRef.current.transformData, null, cancelationRef);
    return () => {
      cancelationRef.canceled = true;
    };
  }, [filtersProp]);
  const reload = () => {
    setPageNumber(0);
    totalDataRef.current = [];
    getPage(collection, filtersProp);
  };

  const getPreviousPage = (page) => {
    const totalData = totalDataRef.current;
    if (!totalData.length) {
      return false;
    }
    let start = page * PAGE_SIZE;
    let end = start + PAGE_SIZE;
    if (end > totalData.length) {
      end = totalData.length;
    }
    if (start >= totalData.length) {
      return false;
    }
    const pageData = totalData.slice(start, end);
    setData(pageData);
  };


  const removeItem = (item) => {
    let startOfPage = data[0];
    let removeFrom = data[0];
    if (item === data[0]) {
      startOfPage = data[1];
    }

    if (!startOfPage) {
      reload();
      return;
    }

    totalDataRef.current.splice(totalDataRef.current.indexOf(removeFrom));
    getPage(collection, filtersProp, transformData, {
      at: startOfPage.deleted_at
    });
  };
  
  return (
    <>
      <TrashedDialogContent
        loading={loading}
      >
        <TrashedInfo
          label={labels.groupTypePlural}
          expiryLabel={TRASH_EXPIRATION_LABEL}
        />
        {header}
        {!loading &&  !data.length && <TrashedEmptyState
          label={labels.groupTypePlural}
          expiryLabel={TRASH_EXPIRATION_LABEL}
        >
          {additionalEmptyMessage}
        </TrashedEmptyState>}
        <TrashedList
          data={data}
          labels={labels}
          error={!!error}
          onDelete={async (item) => {
            await storage.delete(makeRef('trashed_' + collection, item.id));
            removeItem(item);
          }}
          onRestore={async (item) => {
            try {
              setIsUpdating(true);
              await onRestore(item);
              removeItem(item);
            } finally {
              setIsUpdating(false);
            }
          }}
          IconComponent={IconComponent}
          IconComponentProps={IconComponentProps}
        />
      </TrashedDialogContent>
      <DialogActions
        sx={{
          justifyContent: 'stretch',
          flexWrap: 'wrap'
        }}
      >
        {footer}
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
          flexWrap: 'wrap-reverse'
        }}>
          {toolbarItems}
          {!!totalDataRef.current.length && (
            <TablePagination
              component="div"
              sx={{
                '.MuiTablePagination-displayedRows': {
                  m: 0
                },
                flex: 1,
                minWidth: 200
              }}
              nextIconButtonProps={{
                disabled: (
                  // If loading
                  loading 
                  // If updating
                  || isUpdating
                  || (
                    // If all data is loaded
                    !hasMore 
                    // and the page is the last 
                    && totalDataRef.current.length <= (pageNumber + 1) * PAGE_SIZE
                  )
                )
              }}
              backIconButtonProps={{
                disabled: loading
                  // If updating
                  || isUpdating
                  // If loading did not start yet (at the start)
                  || !pageNumber
              }}
              count={hasMore ? -1 : totalDataRef.current.length}
              page={pageNumber}

              onPageChange={(_, newPageNum) => {
                let item;
                if (newPageNum < pageNumber) {
                  getPreviousPage(newPageNum);
                } else {
                  if (getPreviousPage(newPageNum) === false) {
                    item = data?.[data.length - 1];
                    getPage(collection, filtersProp, transformData, {
                      after: item?.deleted_at
                    });
                  }
                }
                setPageNumber(newPageNum);
              }}
              rowsPerPage={PAGE_SIZE}
              rowsPerPageOptions={[]}
            />
          )}
        </div>
      </DialogActions>
    </>
  );
};

export default TrashedFirestore;