import React from 'react';
import { Avatar, CircularProgress } from '@mui/material';
import { useTables } from '../../hooks/useTables';
import DatabaseIcon from '@mui/icons-material/TableChart';


/**
 * @param {object} props 
 * @param {object=} props.sx
 * @param {string} props.id
 */
export function DatabaseAvatar(props) {
    
  // we use a broader query than necessary to take advantage of broader caching
  /** @type {{loading: boolean, data: SpaceSummaryObjectType[], error: boolean}} */
  let { loading, data, error } = useTables('applications/summary/', {
    cache_seconds: 12 * 60 * 60
  });

  if (loading) {
    return <Avatar sx={props.sx}>
      <CircularProgress size={16} />
    </Avatar>;
  }
  // data.find may not be mocked in tests
  let db = (error || !data?.find) ? null : data.find(x => x.id === props.id);

  if (!db) {
    return <Avatar sx={props.sx}>
      <DatabaseIcon fontSize="small" />
    </Avatar>;
  }

  return <Avatar
    sx={props.sx}
  >
    {db.icon ? <span style={{ fontSize: '140%', position: 'relative', top: 1 }}>{db.icon}</span> : <DatabaseIcon fontSize="small" />}
  </Avatar>;
}