import Quill from 'quill';
import { registerWithQuill } from '../components/SnippetEditor/fontConfig';
import { registerTableWithQuill } from '../components/SnippetEditor/quillConfig';
import { Delta } from 'quill/core';
import { globalRegistry } from 'quill/core/quill';
import Clipboard from 'quill/modules/clipboard';
import ScrollBlot from 'quill/blots/scroll';
import { importText } from './DeltaImportText';

registerWithQuill(Quill);
const QuillBetterTable = registerTableWithQuill();

let scroll = new ScrollBlot(globalRegistry, document.createElement('div'), {
  // @ts-ignore
  emitter: {
    emit: () => null,
    on: () => null
  }
});
let quillClipboard = new Clipboard({
  // @ts-ignore
  root: { addEventListener() { } },
  scroll: scroll

}, { matchers: QuillBetterTable.clipboardMatchers.map(m => [m.tag, m.fn]) });



/**
 * Converts an html string to a delta.
 * 
 * @param {string} html
 */
export function importHTML(html) {
  let delta = quillClipboard.convert({ html });
  
  return convertImageToInsertImage(delta);
}


/**
 * @param {InstanceType<import('quill/core')['Delta']>} delta
 * @param {boolean} pasted
 */
export function convertImageToInsertImage(delta, pasted = false) {
  // copy the delta
  delta = JSON.parse(JSON.stringify(delta));
  
  // change any `image` inserts to `insert-image` embed
  for (let op of delta.ops) {
    if (typeof op.insert !== 'string') {
      if (op.insert.image) {
        let insertImage = {
          pasted,
          url: op.insert.image,
        };
        op.insert['insert-image'] = insertImage;
        if (!op.attributes) {
          op.attributes = {};
        }

        if (op.attributes.width) {
          insertImage.width = +op.attributes.width;
        }
        if (op.attributes.height) {
          insertImage.height = +op.attributes.height;
        }

        if (op.attributes.width || op.attributes.height) {
          // assume it has been modified from its regular dimensions
          op.attributes.preserveAspect = false;
        } else {
          op.attributes.preserveAspect = true;
        }

        delete op.insert.image;
      }
    }
  }

  return new Delta(delta);
}

export { importText };