import React from 'react';
import './Pricing.css';
import { Button, tooltipClasses } from '@mui/material';
import AsyncButton from '../AsyncButton/AsyncButton';
import { isAISnippetsEnabled, isElectronApp } from '../../flags';
import { sendMessageToClient } from '../../desktop_utilities';
import { format } from 'd3-format';
import { useTypedSelector } from '../../hooks';
import T from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import CheckMark from '@mui/icons-material/CheckCircleOutline';

import featuresData from './plans.json';

let f = format(',');

/**
 *
 * @param {import('./VersionComparison').PricingProps & {isAnnually: boolean, onBuy: function(object):void}} props
 */
export function Pricing(props) {
  const aiSnippetsEnabled = useTypedSelector(state => isAISnippetsEnabled(state));

  function onBuy(done, evt, type) {
    if (isElectronApp()) {
      // send a message to app to open upgrade page in browser
      sendMessageToClient({
        type: 'upgrade'
      });
      done();
    } else {
      props.onBuy({ type, done, source: evt.currentTarget });
    }
  }

  /**
   *
   * @param {"pro"|"business"} plan
   */
  function getFeaturesPerPlan(plan) {
    return [
      props.snippetsUpgradeable ? `Up to ${f(props.skus[plan].MAX_SNIPPETS)} snippets` : null,
      props.groupsUpgradeable ? `Up to ${f(props.skus[plan].MAX_GROUPS)} folders` : null,
      props.groupSizeUpgradeable ? `Up to ${f(props.skus[plan].MAX_SNIPPETS_PER_GROUP)} snippets per folders` : null,
      props.snippetSizeUpgradeable ? `Up to ${f(props.skus[plan].MAX_SNIPPET_SIZE)} characters in a snippet` : null,
      plan === 'pro' ? `Share snippets with up to ${f(props.skus[plan].MAX_USERS_PER_GROUP)} users` : null,
    ];
  }

  const proFeatures = [
    ...getFeaturesPerPlan('pro'),
    ...featuresData.overview.pro,
    aiSnippetsEnabled ? 'AI writing and editing' : null,
  ];

  const businessFeatures = [
    ...getFeaturesPerPlan('business'),
    ...featuresData.overview.business,
  ];

  const enterpriseFeatures = featuresData.overview.enterprise;

  return (
    <div className="plans-page-container">
      <div className="plans-page">
        <section className="plans__box">
          <div className="plans__card">
            <div className="plans__card-title">Pro</div>
            <div className="plans__card-subtitle">
              Great for individuals. Become more productive with powerful
              features.
            </div>
            <div className="plans__card-price">
              <div className="plans__card-price-value">
                {props.isAnnually ? '$2.99' : '$3.49'}
                <span className="plans__card-currency">USD</span>
                <div className="plans__card-price-info">
                  {props.isAnnually ? 'per month, billed yearly' : 'per month, billed monthly'}
                </div>
              </div>
            </div>

            <AsyncButton disabled={props.isPayingPro} variant={props.isPayingPro ? 'outlined' : 'contained'} fullWidth
              size="large"
              onClick={(done, e) => onBuy(done, e, 'pro')}>
              {props.isPayingPro ? 'Already got it' : 'Upgrade to Pro'}
            </AsyncButton>

            <ul className="plans-features">
              {proFeatures.map(feature => feature
                ? <li key={feature}>
                  <span className="four-point-star"></span>

                  {feature}
                </li>
                : null
              )}
            </ul>
          </div>

          <div className="plans__card">
            <div className="plans__card-title">Business</div>
            <div className="plans__card-subtitle">
              Great for teams and small businesses. Align your team
              communication.
            </div>
            <div className="plans__card-price">
              <div className="plans__card-price-value">
                {props.isAnnually ? '$6.99' : '$8.39'}
                <span className="plans__card-currency">USD</span>
                <div className="plans__card-price-info">
                  {props.isAnnually ? 'per user per month, billed yearly' : 'per user per month, billed monthly'}
                </div>
              </div>
            </div>

            <AsyncButton disabled={props.isOrg} variant={props.isOrg ? 'outlined' : 'contained'} fullWidth
              size="large"
              onClick={(done, e) => onBuy(done, e, 'business')}>
              {props.isOrg ? 'Already got it' : 'Upgrade to Business'}
            </AsyncButton>

            <ul className="plans-features">
              <li>
                <span className="four-point-star"></span>
                <b>Everything included in Pro</b>
              </li>

              {businessFeatures.map(feature => feature
                ? <li key={feature}>
                  <span className="four-point-star"></span>

                  {feature}
                </li>
                : null
              )}
            </ul>
          </div>

          <div className="plans__card">
            <div className="plans__card-title">Enterprise</div>
            <div className="plans__card-subtitle">
              Great for larger organizations. Increase your team's productivity.
            </div>
            <div className="plans__card-price">
              <div
                className="plans__card-price-value plans__card-price-value-enterprise plans__card-price-info"
              >
                Contact us for customized pricing at sales@blaze.today
              </div>
            </div>

            <a href="mailto:sales@blaze.today?subject=Text Blaze Enterprise">
              <Button variant="contained" fullWidth size="large">
                Contact sales
              </Button>
            </a>

            <ul className="plans-features">
              <li>
                <span className="four-point-star"></span>
                <b>Everything included in Business</b>
              </li>

              {enterpriseFeatures.map(feature => feature
                ? <li key={feature}>
                  <span className="four-point-star"></span>

                  {feature}
                </li>
                : null
              )}
            </ul>
          </div>
        </section>

        <section className="section-all section-box plans-features-comparison-section">
          <div className="container">
            <div className="row justify-center">
              <div className="col-md-12">
                <T variant="h4" sx={{ margin: '70px 0 30px' }} fontWeight="500">Compare plans</T>

                <div className="plans-compare-wrapper">
                  <div className="plans-sticky-tiers-info plans-compare-grid">
                    <div className="empty-grid-element"></div>

                    <div className="plans-sticky-info">
                      <div className="plans-sticky-info-title">
                      Pro

                        <div className="plans__card-price-info">
                          {props.isAnnually
                            ? '$2.99 / month, billed yearly'
                            : '$3.49 / month, billed monthly'}
                        </div>
                      </div>


                      <AsyncButton
                        disabled={props.isPayingPro}
                        variant={props.isPayingPro ? 'outlined' : 'contained'}
                        size="small"
                        sx={{ marginTop: '12px', width: '130px' }}
                        onClick={(done, e) => onBuy(done, e, 'pro')}>
                        {props.isPayingPro ? 'Already got it' : 'Get Pro'}
                      </AsyncButton>
                    </div>
                    <div className="plans-sticky-info">
                      <div className="plans-sticky-info-title">
                      Business

                        <div className="plans__card-price-info">
                          {props.isAnnually
                            ? '$6.99 / user / month, billed yearly'
                            : '$8.39 / user / month, billed monthly'}
                        </div>
                      </div>
                      <AsyncButton
                        disabled={props.isPayingPro}
                        variant={props.isPayingPro ? 'outlined' : 'contained'}
                        size="small"
                        sx={{ marginTop: '12px', width: '130px' }}
                        onClick={(done, e) => onBuy(done, e, 'pro')}>
                        {props.isPayingPro ? 'Already got it' : 'Get Business'}
                      </AsyncButton>
                    </div>
                    <div className="plans-sticky-info">
                      <div className="plans-sticky-info-title">
                      Enterprise
                        <div className="plans__card-price-info">Get in touch for customized pricing</div>

                      </div>

                      <a href="mailto:sales@blaze.today?subject=Text Blaze Enterprise">
                        <Button variant="contained" fullWidth size="small" sx={{ width: '130px' }}>
                        Contact sales
                        </Button>
                      </a>
                    </div>
                  </div>

                  <div className="plans-compare-wrapper-info">
                    {featuresData.featuresList.map(features =>
                      <React.Fragment key={features.sectionTitle}>
                        <div className="plans-compare__section-title">
                          {features.sectionTitle}
                        </div>
                        <div className="plans-compare-info-wrapper">
                          <div className="plans-compare__info-title">
                            {features.features.map(feature => {
                              let pro = feature.pro;
                              let business = feature.business;
                              let enterprise = feature.enterprise;

                              if (feature.featureName === 'max_snippets') {
                                if (!props.snippetsUpgradeable) {
                                  return null;
                                }

                                pro = f(props.skus.pro.MAX_SNIPPETS);
                                business = f(props.skus.business.MAX_SNIPPETS);
                                enterprise = f(props.skus.business.MAX_SNIPPETS);
                              }

                              if (feature.featureName === 'max_snippet_size') {
                                if (!props.snippetSizeUpgradeable) {
                                  return null;
                                }
                                
                                pro = f(props.skus.pro.MAX_SNIPPET_SIZE);
                                business = f(props.skus.business.MAX_SNIPPET_SIZE);
                                enterprise = f(props.skus.business.MAX_SNIPPET_SIZE);
                              }

                              if (feature.featureName === 'max_groups') {
                                if (!props.groupsUpgradeable) {
                                  return null;
                                }
                                
                                pro = f(props.skus.pro.MAX_GROUPS);
                                business = f(props.skus.business.MAX_GROUPS);
                                enterprise = f(props.skus.business.MAX_GROUPS);
                              }

                              if (feature.featureName === 'max_users_per_group') {
                                pro = `Up to ${f(props.skus.pro.MAX_USERS_PER_GROUP)}`;
                              }

                              if (feature.featureName === 'ai_snippets' && !aiSnippetsEnabled) {
                                return null;
                              }

                              return <FeatureInfo
                                key={feature.title}
                                title={feature.title}
                                info={feature.info}
                                img={feature.imgUrl}
                                imgAlt={feature.imgAlt}
                                pro={pro}
                                business={business}
                                enterprise={enterprise}
                                titleUrl={feature.titleUrl}
                              />;
                            })}
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );

}

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string=} props.titleUrl
 * @param {any=} props.img
 * @param {string=} props.info
 * @param {string=} props.imgAlt
 * @param {boolean|string} props.pro
 * @param {boolean|string} props.business
 * @param {boolean|string} props.enterprise
 */
function FeatureInfo({ title, img, info, imgAlt, pro, business, enterprise, titleUrl }) {
  return (
    <div className="plans-compare-grid">
      <div className="plans-compare__title">
        {info
          ? <HtmlTooltip
            placement="right"
            title={
              <>
                <T fontSize="1rem">{info}</T>

                {/* adding the domain here to simplify testing locally */}
                {img && <img
                  src={`https://blaze.today${img}`}
                  className="plans-img-fluid"
                  alt={imgAlt}
                />}
              </>
            }>

            <div className="feature-title-info">{title}</div>
          </HtmlTooltip>

          : titleUrl
            ? <a href={titleUrl} target="_blank" className="feature-title-info-no-info" rel="noreferrer">{title}</a>
            : <div className="feature-title-info-no-info">{title}</div>
        }
      </div>

      <div className="plans-compare__info">
        <FeatureInfoAvailability feature={pro} />
      </div>

      <div className="plans-compare__info">
        <FeatureInfoAvailability feature={business} />
      </div>

      <div className="plans-compare__info">
        <FeatureInfoAvailability feature={enterprise} />
      </div>
    </div>
  );
}

/**
 * @param {Object} props
 * @param {boolean|string} props.feature
 */
function FeatureInfoAvailability({ feature }) {
  if (feature === true) {
    return <CheckMark/>;
  }

  if (typeof feature === 'string') {
    return <>{feature}</>;
  }

  return <div className="plans-compare-empty">—</div>;
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}/>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#d54236',
    color: 'white',
    maxWidth: 450,
    fontWeight: 500,
    border: '1px solid rgba(0, 0, 0, 0.10)',
    padding: 12,
    // Paper elevation 3
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
  },
}));