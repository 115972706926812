import React from 'react';
import T from '@mui/material/Typography';
import { useTypedSelector, useTypedSelectorDeepEquals, } from '../../hooks';
import { Alert, Button } from '@mui/material';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import { toast } from '../../messageToast';
import SiteIcon from '@mui/icons-material/Public';
import { Box } from '@mui/system';

export function AIBlazeBlacklistDomain() {
  const METADATA_KEY = 'sidebar_metadata';

  const domainData = useTypedSelectorDeepEquals(store => {
    return store.userState?.options?.[METADATA_KEY]?.domains || {};
  });

  const disabledOnAllSites = useTypedSelector(store => {
    return !!store.userState?.options?.[METADATA_KEY]?.tab_disabled;
  });

  const blacklistDomains = Object.keys(domainData).filter(x => !!domainData[x].no_tab);

  function updater(key, value, message) {
    storage.update(usersSettingsRef, { [`options.${METADATA_KEY}.${key}`]: value, }, 'HIDE_AUTOSAVE').then(() => {
      if (message) {
        toast(message, {
          intent: 'success',
          duration: 3000,
        });
      }
    });
  }

 

  return <>
    <T
      gutterBottom
      variant="h6"
      sx={{
        mt: 4
      }}
      style={{
        display: 'flex',
        alignItems: 'center'
      }}>AI Blaze in-page tab</T>
    <T paragraph color="textSecondary" style={{ marginBottom: 20 }}>You can disable the in-page tab for a single domain by hovering over it in the page and clicking the "x" icon. You can also {disabledOnAllSites ? 'disable it on all domains' : <><span onClick={() => {
      updater('tab_disabled', true, 'Disabled on all websites successfully');
    }} style={{
      textDecoration: 'underline',
      cursor: 'pointer',
    }}>disable it on all domains</span></>}. When the tab is disabled, shortcuts will continue to work and you can continue to open the sidebar by clicking the AI Blaze icon in your Chrome toolbar.</T>
    {disabledOnAllSites ? <Alert severity="warning">
      <T>You have disabled the tab on all domains. To improve your AI Blaze experience, you can re-enable it.</T>

      <Button
        sx={{
          mt: 2
        }}
        onClick={() => {
          updater('tab_disabled', false, 'Enabled on all websites successfully');
        }}
      >Re-enable tab</Button>
    </Alert> : <Box
      sx={{
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        mt: 4
      }}>
      {blacklistDomains.length ? <>
        <T variant="subtitle2" paragraph>Domains where the AI Blaze tab is disabled</T>
        {blacklistDomains.map((domain, i) => {
          return <div key={i} style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: '10px',
            gap: 20
          }}>
            <SiteIcon sx={{
              opacity: .7
            }} />
            <T sx={{
              display: 'inline-block',
              textOverflow: 'ellipsis',
              whiteSpace: 'pre-wrap',
              overflow: 'hidden'
            }}>
              {domain}
            </T>
            <div style={{ flex: 1 }}></div>
            <Button
              sx={{
                marginLeft: '10px',
              }}
              onClick={() => {
                const assignObject = Object.assign({}, domainData);
                assignObject[domain] = Object.assign({}, assignObject[domain], { no_tab: false });
                updater('domains', assignObject, `Re-enabled ${domain} successfully`);
              }}
              variant="outlined"
              size="small"
            >
          Re-enable
            </Button>
          </div>;
        })}
      </> : null}
    </Box>}
  </>;
}