import React from 'react';
import { Avatar } from '@mui/material';
import T from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { stringToHslColor } from './avatar_utilities';


/**
 * @param {object} props
 * @param {import("../PermissionList/utilities").UserInfo} props.info
 * @param {{avatarSize?: number, variant?: any, color?: any, labelStyle?: React.CSSProperties, avatarStyle?: React.CSSProperties}=} props.config
 * @param {any=} props.secondLine
 * @param {boolean} [props.disableTextSkeleton]
 * @param {React.CSSProperties} [props.containerStyle]
 */
export default function UserAvatar(props) {
  let config = props.config || {};
  let avatarSize = config.avatarSize || 24;

  if (!props.info) {
    return (
      <div style={{
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center'
      }}>
        <div style={{
          display: 'inline-block',
          verticalAlign: 'middle'
        }}>
          <Skeleton variant="circular" width={avatarSize} height={avatarSize} />
        </div>
        {!props.disableTextSkeleton && <Skeleton variant="text" width={80} style={{ marginLeft: 12 }} />}
      </div>
    );
  }

  let { email, emailVerified, name, imageUrl } = props.info;

  let variant = config.variant || 'body1';
  let color = config.color || 'textPrimary';
  let labelStyle = config.labelStyle || {};
  let avatarStyle = Object.assign({
    width: avatarSize,
    height: avatarSize,
    marginRight: 8,
    verticalAlign: 'middle'
  }, config.avatarStyle || {});
  
  
  let hasLastUpdatedBy = !!(email && email.includes('@'));

  if (!hasLastUpdatedBy) {
    return <T style={{ color: 'secondary' }}>[Unknown]</T>;
  }

  let label;
  
  let emailLabel = email;
  if (!emailVerified) {
    emailLabel += ' (unverified)';
  }
  if (name) {
    label = name;
  } else {
    label = emailLabel;
  }

  let labelEl = <T 
    variant={variant}
    color={color}
    style={Object.assign({
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flex: 1
    }, labelStyle)}
    title={emailLabel}
  >{label}{props.secondLine || null}</T>;

  const containerStyle = Object.assign({
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center'
  }, props.containerStyle);

  if (imageUrl) {
    return <div style={containerStyle}>
      <div style={{
        display: 'inline-block',
        verticalAlign: 'middle',
      }}>
        <Avatar src={imageUrl} style={avatarStyle} />
      </div>
      {labelEl}
    </div>;
  } else {
    return <div style={containerStyle}>
      <div style={{
        display: 'inline-block',
        verticalAlign: 'middle'
      }}>
        <Avatar style={Object.assign({
          backgroundColor: stringToHslColor(email)
        }, avatarStyle)}>{label[0].toUpperCase()}</Avatar>
      </div>
      {labelEl}
    </div>;
  }
}
