import React from 'react';

/**
 * @typedef {object} ConnectedGroupButtonLabels
 * @property {string} groupType
 * @property {string} itemType
 * @property {string} titleCapitalized
 * @property {string} titlePluralCapitalized
 * @property {string} itemTypePlural
 * @property {string} itemTypePluralCapitalized
 * @property {string} groupTypeCapitalized
 * @property {string} title
 * @property {'g:'|'s:'} databasePermissionPrefix
 * @property {'group'|'site'} databasePermissionGroupType
 */

const ConnectedSettingsContext = React.createContext(/** @type {ConnectedGroupButtonLabels} */ (null));

export default ConnectedSettingsContext;