import Quill from 'quill';
import TableBody from './tableBody';
import TableColGroup from './tableColGroup';
import TypedContainer from './typedContainer';
import Break from 'quill/blots/break';

/**
 * @extends TypedContainer<TableBody, import('quill/blots/scroll').default>
 */
class TableViewWrapper extends TypedContainer {
  checkMerge() {
    if (super.checkMerge() && this.next.children.head) {
      return !(
        this.children.head.children.tail.statics.blotName === TableBody.blotName
        && this.next.children.head.children.head.statics.blotName === TableColGroup.blotName
      );
    }
    return false;
  }

  /**
   * 
   * @param {import('quill/blots/scroll').default} scroll 
   * @param {HTMLElement} domNode 
   */
  constructor (scroll, domNode) {
    super(scroll, domNode);
    const quill = /** @type {Quill} */ (Quill.find(scroll.domNode.parentNode));
    
    const getTableModule = () => {
      return /** @type {import('quill-better-table').default} */ (quill.getModule('better-table'));
    };
    
    domNode.addEventListener('scroll', (e) => {
      const tableModule = getTableModule();

      if (tableModule.tableSelection &&
        tableModule.tableSelection.selectedTds.length > 0) {
        tableModule.tableSelection.repositionHelpLines();
      }
      if (tableModule.columnTool) {
        tableModule.columnTool.repositionColTools();
      }

    }, false);
    const scrollListener = (e) => {
      if (!domNode.parentElement) {
        scroll.domNode.removeEventListener('scroll', scrollListener, false);
        return;
      }
      const tableModule = getTableModule();
      if (tableModule.columnTool) {
        tableModule.columnTool.repositionColTools();
      }

      if (tableModule.tableSelection &&
        tableModule.tableSelection.selectedTds.length > 0) {
        tableModule.tableSelection.refreshHelpLinesPosition();
      }
    };
    scroll.domNode.addEventListener('scroll', scrollListener, false);

    domNode.addEventListener('click', (evt) => {
      const currentTarget = /** @type {HTMLElement} */ (evt.currentTarget);
      if (evt.target !== currentTarget) {
        return;
      }
      let next = /** @type {import('parchment').Blot} */ (this.next);
      // Add a new line if it does not exist
      if (!next) {
        next = this.scroll.create(Break.blotName);
        this.parent.appendChild(next);
      }
      // Focus the next line
      quill.setSelection(quill.getIndex(next));
    });
  }

  table () {
    return this.children.head;
  }
}
TableViewWrapper.blotName = 'table-view';
TableViewWrapper.className = 'quill-better-table-wrapper';
TableViewWrapper.tagName = 'DIV';


export default TableViewWrapper;