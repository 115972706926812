import React, { useRef, useState, useEffect, useContext } from 'react';
import { loopIcon } from './shared';
import { ShowError, Chicklet, ActionContext } from './SharedComponents';
import { createListFromArray, createListFromObject, getBaseUpdateForDBNode, getStatusForDBCommand, List, objectToList, runBeginBlock, runErrorBlock, runFinishBlockInner, showErrorNotificationForInvalidCommand, toStr } from '../../snippet_processor/Equation';
import { ParseError } from '../../snippet_processor/ParserUtils';
import { useIsMounted } from '../../hooks';
import SQLStore from './SQLStore';
import { Autocomplete, createFilterOptions, LinearProgress, ListItem, ListItemText, ListSubheader, Select, TextField } from '@mui/material';
import T from '@mui/material/Typography';
import { VariableSizeList } from 'react-window';
import Popper from '@mui/material/Popper';
import { useTheme } from '@mui/material/styles';


let niceFormat = (x) => {
  if (x === null || x === undefined) {
    return '';
  } else if (Array.isArray(x)) {
    return toStr(createListFromArray(adjustTypes(x)));
  } else if (!(x instanceof List) && (typeof x === 'object')) {
    return toStr(createListFromObject(adjustTypes(x)));
  }
  return toStr(x);
};


export const TableIcon = () => <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="TableChartIcon"><path d="M10 10.02h5V21h-5zM17 21h3c1.1 0 2-.9 2-2v-9h-5v11zm3-18H5c-1.1 0-2 .9-2 2v3h19V5c0-1.1-.9-2-2-2zM3 19c0 1.1.9 2 2 2h3V10H3v9z"></path></svg>;


const filterOptions = createFilterOptions({
  stringify: (option) => Object.values(option).map(x => niceFormat(x)).join(' ')
});


const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}


const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(
  /**
   * @param {object} props 
   * @param {React.ReactNode} props.children
   * @param {*} ref 
   */
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 54;

    const getChildSize = (child) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 54;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);




let adjustTypes = (object) => {
  object = Array.isArray(object) ? object.slice() : Object.assign({}, object);
  for (let key in object) {
    if (key.startsWith('__blaze_internal__')) {
      delete object[key];
      continue;
    }
    // TB doesn't handle null's or undefined
    if (object[key] === null || object[key] === undefined) {
      object[key] = '';
    } else if (Array.isArray(object[key])) {
      object[key] = createListFromArray(adjustTypes(object[key]));
    } else if (!(object[key] instanceof List) && (typeof object[key] === 'object')) {
      object[key] = createListFromObject(adjustTypes(object[key]));
    }
  }
  return object;
};

/**
 * @typedef {object} SQLRendererProps
 * @property {import("../../snippet_processor/ParseNode").default} node
 * @property {import("../../snippet_processor/DataContainer").Environment} env
 * @property {string} addonId
 */

/**
 * @param {SQLRendererProps} props 
 */
function SQLRendererBase(props) {
  let requestCount = useRef(0);
  let isMounted = useIsMounted();
  let rawKey = props.node.info.labelName + '%%raw';

  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(/** @type {ParseError|import('../../snippet_processor/ParseNode').default} */(null));
  let [data, setData] = useState(/** @type {object[]} */([]));
  let [selectedOption, setSelectedOption] = useState(props.env.data.has(rawKey) ? JSON.parse(props.env.data.get(rawKey)) : (props.node.info.multiple ? [] : /** @type {any} */ (null)));

  let { onChange, onRemoteUpdate, } = useContext(ActionContext);

  let debounceId = useRef(Math.random().toString(16));
  let debounceTime = props.node.info.debounceMs;

  const theme = useTheme();
  const node = props.node;
  const command = node.info.command;
  const isSidechannel = ['dbupdate', 'dbinsert', 'dbdelete'].includes(command);
  const baseUpdate = getBaseUpdateForDBNode(node);



  /**
   * @param {'begin'|'error'|'finish'} blockName
   * @param {import('../../snippet_processor/ParseNode').default} block 
   * @param {() => Promise<void>} runner 
   */
  async function handlerWrapper(blockName, block, runner) {
    if (node.type !== 'error') {
      if (block) {
        try {
          await runner();
        } catch (e) {
          showErrorNotificationForInvalidCommand(props.env, '', e.message, blockName);
        }
        onChange();
      }
    }
  }
  async function handleBegin() {
    const beginBlock = node.info.begin;
    await handlerWrapper('begin', beginBlock, () => runBeginBlock(command, beginBlock, props.env));
  }
  /**
   * @param {any[]} resultsOuter 
   */
  async function handleFinish(resultsOuter) {
    const finishBlock = node.info.finish;
    const runner = () => {
      /** @type {typeof resultsOuter} */
      let results;
      if (Array.isArray(resultsOuter)) {
        // Shallow copy to avoid overwriting the blaze internal ID property
        results = resultsOuter.map(x => ({ ...x }));
        for (const result of results) {
          delete result.__blaze_internal__id;
        }
      } else {
        results = resultsOuter;
      }
      const argument = objectToList(results);
      return runFinishBlockInner(command, finishBlock, props.env, argument, 'success');
    };
    await handlerWrapper('finish', finishBlock, runner);
  }
  async function handleError(data) {
    const errorBlock = node.info.error;
    await handlerWrapper('error', errorBlock, () => runErrorBlock(command, errorBlock, props.env, data, 'error'));
  }

  useEffect(() => {
    let hasDefault = props.env.data.has(rawKey);
    let hasResult = props.env.config.sqlStore.hasCached(node.info, SQLStore);

    if (node.type !== 'error') {
      if (!isSidechannel) {
        setError(null);

        if (!hasResult || !hasDefault || !node.info.menu) {
          if (node.info.isloading) {
            props.env.updateData(node.info.isloading, true, { callback: onChange });
          }

          if (node.info.haserror) {
            props.env.updateData(node.info.haserror, false, { callback: onChange });
          }

          let received = doRequest();
          if (received) {
            // We have the data immediately, let's bail
            return;
          }
        } else {
          onRemoteUpdate({ ...baseUpdate, status: 'pending' });
          handleBegin();
          props.env.config.sqlStore.request(node.info, async (res) => {
            onRemoteUpdate({ ...baseUpdate, status: 'success' });
            if (hasDefault) {
              setSelectedOption(JSON.parse(props.env.data.get(rawKey)));

              if (Array.isArray(res.results)) {
                res.results.forEach((x, i) => x.__blaze_internal__id = i);
              }


              // restore the selected option if it's in the new results list
              // otherwise clear the selection
              function menuOptionExists(option) {
                let optionKeys = Object.keys(option);
                for (let r of res.results) {
                  let resultKeys = Object.keys(r);
                  if (optionKeys.length !== resultKeys.length) {
                    continue;
                  }
                  let isEqual = true;
                  for (let k of optionKeys) {
                    if (k === '__blaze_internal__id') {
                      continue;
                    }
                    if (niceFormat(option[k]) !== niceFormat(r[k])) {
                      isEqual = false;
                      break;
                    }
                  }
                  if (isEqual) {
                    return r;
                  }
                }
                return false;
              }

              if (node.info.menu) {
                if (node.info.multiple) {
                  let newValues = selectedOption.map(option => menuOptionExists(option)).filter(x => !!x);
                  setSelectedOption(newValues);
                  handleFinalSelection(newValues);
                } else {
                  if (!res.results.length) {
                    setSelectedOption('');
                  } else {
                    let newValue = menuOptionExists(selectedOption) || res.results[0];
                    setSelectedOption(newValue);
                    handleFinalSelection(newValue);
                  }
                }
              }
              setData(res.results);
            } else {
              handleFinalSelection(res);
            }
          }, (err) => {
            onRemoteUpdate({ ...baseUpdate, status: 'error' });
            const error = err || 'Error running query';
            handleError(error);
            setError(new ParseError(error));
          }, debounceTime ? { ms: debounceTime, id: debounceId.current } : null);
        }
      }
    }
    // eslint-disable-next-line
  }, [props.node && SQLStore.getFingerprint(props.node.info)])

  /**
   * @returns {boolean}
   */
  function doRequest() {
    requestCount.current++;
    let myCount = requestCount.current;

    setLoading(true);
    onRemoteUpdate({ ...baseUpdate, status: 'pending' });
    handleBegin();

    return props.env.config.sqlStore.request(props.node.info, async (/** @type {Parameters<typeof handleDone>[0]} */ res) => {
      const { status } = getStatusForDBCommand(res);
      onRemoteUpdate({ ...baseUpdate, status });
      if (requestCount.current === myCount) {
        if (isMounted.current) {
          if (Array.isArray(res.results)) {
            res.results.forEach((x, i) => x.__blaze_internal__id = i);
          }
          await handleDone(res);
          setLoading(false);
        }
      }
    }, (err) => {
      onRemoteUpdate({ ...baseUpdate, status: 'error' });
      if (requestCount.current === myCount) {
        if (isMounted.current) {
          setLoading(false);
          if (node.info.isloading) {
            props.env.updateData(node.info.isloading, false, { callback: onChange });
          }
          if (node.info.haserror) {
            props.env.updateData(node.info.haserror, true, { callback: onChange });
          }
          const error = err || 'Error running query';
          handleError(error);
          setError(new ParseError(error));
        }
      }
    }, debounceTime ? { ms: debounceTime, id: debounceId.current } : null);
  }

  /**
   * @param {{ results: typeof data, status: 'success' }} data 
   * @returns {Promise<void>}
   */
  async function handleDone(data) {
    let info = node.info;

    // TODO: update integration here
    if (node.type !== 'error') {
      let results = data.results;

      if (!results) {
        if (info.haserror) {
          props.env.updateData(info.haserror, true, { callback: onChange });
        }

        if (info.isloading) {
          props.env.updateData(info.isloading, false, { callback: onChange });
        }

        setError(new ParseError('No results found'));
        return;
      }

      if (info.isloading) {
        props.env.updateData(info.isloading, false, { callback: onChange });
      }

      if (info.menu) {
        if (info.multiple) {
          setSelectedOption([]);
          handleFinalSelection([]);
        } else {
          if (!results.length) {
            setSelectedOption('');
          } else {
            setSelectedOption(results[0]);
            handleFinalSelection(results[0]);
          }
        }
        setData(results);
      } else {
        handleFinalSelection(results);
      }

      await handleFinish(results);
    } else {
      if (isMounted.current) {
        if (node.info.haserror) {
          props.env.updateData(node.info.haserror, true, { callback: onChange });
        }
        setError(node);
      }
    }
  }

  /**
   * @param {(typeof data)[0]} results 
   * @returns {void}
   */
  function handleFinalSelection(results) {
    let info = node.info;

    let primaryName = info.names[0].toLocaleLowerCase();
    let menuName = info.labelName;
    let label;
    if (info.multiple) {
      label = results.map(x => niceFormat(x[primaryName])).join(', ');
    } else {
      label = niceFormat(results[primaryName]);
    }

    if (info.name) {
      let data;

      let entryToList = (object) => {
        return createListFromObject(adjustTypes(object));
      };

      if (info.multiple) {
        data = createListFromArray(results.map(r => entryToList(r)));
      } else {
        data = entryToList(results);
      }

      props.env.updateData([{ name: menuName, value: label }, { name: info.name, value: data }, { name: rawKey, value: JSON.stringify(results) }], null, {
        callback: onChange,
        onError: (err) => {
          if (isMounted.current) {
            if (node.info.haserror) {
              props.env.updateData(node.info.haserror, true, { callback: onChange });
            }
            setError(new ParseError(err.message));
          }
        }
      });
    } else {
      let data = {};
      for (let name of info.names) {
        data[name.toLowerCase()] = [];
      }

      if (info.multiple) {
        for (let row of results) {
          row = adjustTypes(row);
          for (let item in row) {
            data[item].push(row[item]);
          }
        }
        for (let item in data) {
          data[item] = createListFromArray(data[item]);
        }
      } else {
        data = adjustTypes(results);
      }

      props.env.updateData([{ name: menuName, value: label }, { name: rawKey, value: JSON.stringify(results) }].concat(Object.entries(data).map(x => ({ name: x[0], value: x[1] }))), null, {
        callback: onChange,
        onError: (err) => {
          if (isMounted.current) {
            if (node.info.haserror) {
              props.env.updateData(node.info.haserror, true, { callback: onChange });
            }
            setError(new ParseError(err.message));
          }
        }
      });
    }
  }

  if (node.type === 'error' || error) {
    let err = error || node;
    return <ShowError msg={(err instanceof ParseError || err instanceof Error) ? err.message : node.info.message} blocking={false} nodeOrAddonId={props.addonId} />;
  } else {
    if (node.info.silent) {
      return null;
    }
    if (node.info.menu) {
      const colWidth = node.info.cols !== undefined ? node.info.cols + 'ch' : '300px';

      if (loading || selectedOption === null) {
        return <Select
          disabled
          displayEmpty
          size="small"
          sx={{
            width: colWidth,
            mt: .7,
            mb: .5,
            mx: .3,
            height: '40px'
          }}
          value=""
          renderValue={() => {
            return <>
              <T variant="caption" paragraph>{node.info.sqlBlurb[0]}{node.info.sqlBlurb.length === 2 ? <b>{node.info.sqlBlurb[1]}</b> : null}...</T>
              <LinearProgress variant="indeterminate" />
            </>;
          }}
        ></Select>;
      }

      let names = node.info.names;

      return <Autocomplete
        autoHighlight
        options={data}
        size="small"
        sx={{
          width: colWidth,
          display: 'inline-block',
          verticalAlign: 'middle',
          mt: .7,
          mb: .5,
          mx: .3,
          minHeight: '40px'
        }}
        isOptionEqualToValue={(option, value) => {
          // references may change if the component is unmounted and remounted
          // we need a stable ID as the row itself may not contain one
          return option.__blaze_internal__id === value.__blaze_internal__id;
        }}
        renderInput={(params) => <TextField {...params} label={node.info.name || 'Data Blaze Select'} />}
        getOptionLabel={(option) => option === '' ? '' : niceFormat(option[names[0].toLocaleLowerCase()])}
        filterOptions={filterOptions}
        multiple={node.info.multiple}
        disableClearable
        disableCloseOnSelect={node.info.multiple}
        PopperComponent={({ style, ...props }) => (
          <Popper
            {...props}
            style={{ ...style, zIndex: theme.zIndex.tooltip + 1 }}
          />
        )}
        value={selectedOption}
        filterSelectedOptions={node.info.multiple}
        /** @ts-ignore */
        ListboxComponent={ListboxComponent}
        onChange={(_e, v) => {
          setSelectedOption(v);
          handleFinalSelection(v);
        }}
        renderOption={(props, x) => <ListItem {...props}>
          <ListItemText
            primaryTypographyProps={{
              component: 'div'
            }}
            secondaryTypographyProps={{
              component: 'div'
            }}
            primary={<div style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>{niceFormat(x[names[0].toLowerCase()])}</div>}
            secondary={<div style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {names.slice(1).map((n, i) => <span key={i} style={{ paddingRight: 8 }}>{n} <b>{niceFormat(x[n.toLocaleLowerCase()])}</b></span>)}
            </div>}
          />
        </ListItem>}
      />;

    }

    // TODO: fix this
    // the chicklets rendered in DBINSERT/DBSELECT have slightly inconsistent padding
    return Chicklet(
      <span><TableIcon />
        <span className="label can-load">{node.info.sqlBlurb[0]}{node.info.sqlBlurb.length === 2 ? <b>{node.info.sqlBlurb[1]}</b> : null}</span>
        {loading && <span className="chick-loader blaze-loading" data-storeid={props.addonId}>{loopIcon}</span>}
      </span>, {
        title: 'Full data'
      });
  }
}

const SQLRenderer = React.memo(SQLRendererBase);
export default SQLRenderer;
