import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { showDelete } from '../../message';


/**
 * @param {object} props
 * @param {object=} props.style
 * @param {function} props.onDelete
 * @param {boolean=} props.small
 * @param {(string|React.ReactElement)=} props.item
 * @param {(string|React.ReactElement)=} props.extra
 * @param {(string|React.ReactElement)=} props.confirm
 * @param {React.ReactElement=} props.icon
 * @param {boolean=} props.noTooltip
 */
function DeleteButton(props) {
  return <Tooltip
    title={props.noTooltip ? '' : <span>Delete {props.item}</span>}
  >
    <IconButton
      onClick={() => showDelete({
        item: props.item,
        extra: props.extra,
        confirm: props.confirm,
        onDelete: () => props.onDelete()
      })}
      style={Object.assign({ marginTop: 0, marginLeft: 8 }, props.style)}
      size={props.small ? 'small' : 'large'}
    >
      {props.icon ?
        props.icon
        : <DeleteIcon  fontSize={props.small ? 'small' : undefined}/>}
    </IconButton>
  </Tooltip>
  ;
}


export default DeleteButton;
