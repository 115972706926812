import React from 'react';
import { doc, getDoc, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { groupsRef, snippetsRef } from '@store';
import { createGroup, createSnippet } from '../../data';

export const disableFolderIcon = <div style={{ width: 24, height: 24, verticalAlign: 'middle' }}><svg aria-hidden="true" focusable="false"role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 128H272l-54.63-54.63c-6-6-14.14-9.37-22.63-9.37H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48zm0 272H48V112h140.12l54.63 54.63c6 6 14.14 9.37 22.63 9.37H464v224zM176 280v16c0 8.84 7.16 16 16 16h128c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16H192c-8.84 0-16 7.16-16 16z"></path></svg></div>;

export const enableFolderIcon = <div style={{ width: 24, height: 24, verticalAlign: 'middle' }}><svg aria-hidden="true" focusable="false"  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464,128H272L217.37,73.37A32,32,0,0,0,194.74,64H48A48,48,0,0,0,0,112V400a48,48,0,0,0,48,48H464a48,48,0,0,0,48-48V176A48,48,0,0,0,464,128Zm0,272H48V112H188.12l54.63,54.63A32,32,0,0,0,265.38,176H464ZM247.5,208a16,16,0,0,0-16,16v40H192a16,16,0,0,0-16,16v16a16,16,0,0,0,16,16h39.5v40a16,16,0,0,0,16,16h16a16,16,0,0,0,16-16V312H320a16,16,0,0,0,16-16V280a16,16,0,0,0-16-16H279.5V224a16,16,0,0,0-16-16Z"></path></svg></div>;


/**
 * @param {string} groupId
 */
export async function getGroup(groupId) {
  const res = await getDoc(doc(groupsRef, groupId));
  return res.exists()
    ? Object.assign({ id: res.id }, res.data())
    : null;
}

/**
 * @param {string} groupId
 */
export async function getGroupSnippets(groupId) {
  const res = await getDocs(query(snippetsRef,
    where('group_id', '==', groupId),
    orderBy('order'),
    limit(300)
  ));
  return res.docs.map(doc => (
    Object.assign({ id: doc.id }, doc.data())
  ));
}


/**
 *
 * @param {GroupObjectType} group
 * @param {SnippetObjectType[]} snippets
 * @returns {Promise<boolean>}
 */
export async function importGroupSnippets(group , snippets) {
  try {
    const copiedGroupId = await createGroup({
      name: group.name,
      info: group.info,
      options: group.options
    }, true);

    await Promise.all(
      snippets.map(snippet => createSnippet({
        group_id: copiedGroupId,
        name: snippet.name,
        shortcut: snippet.shortcut,
        // Firebase handles buffers differently in node and in the browser
        // So we check the type before using it
        delta: snippet.content.delta instanceof Uint8Array ? snippet.content.delta : snippet.content.delta.toUint8Array(),
        order: snippet.order
      }, true))
    );

    return true;
  } catch (err) {
    //not regular errors
    if (!['MAX_SNIPPETS_EXCEEDED'].includes(err)) {
      console.error(err);
    }

    return false;
  }
}

/**
 *
 * @param {string} groupId
 * @return {Promise<boolean>}
 */
export async function importGroupWithSnippetsFromGroupId(groupId) {
  const group = await getGroup(groupId);

  if (!group) {
    return false;
  }

  const snippets = await getGroupSnippets(groupId);

  if (!snippets) {
    return false;
  }

  const success = await importGroupSnippets(group, snippets);

  return success;
}


/** @type {import('./ConnectedSettingsContext').ConnectedGroupButtonLabels} **/
export const connectedGroupContextValue = {
  groupType: 'folder',
  itemType: 'snippet',
  title: 'snippet folder',
  titleCapitalized: 'Snippet Folder',
  titlePluralCapitalized: 'Snippet Folders',
  itemTypePlural: 'snippets',
  itemTypePluralCapitalized: 'Snippets',
  groupTypeCapitalized: 'Folder',
  databasePermissionPrefix: 'g:',
  databasePermissionGroupType: 'group',
};