import React from 'react';
import { log } from '../../logging/logging';
import { useTypedSelector } from '../../hooks';
import { getLocales, setLocale } from '../../locales';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import moment from 'moment';
import { format } from 'd3-format';
import T from '@mui/material/Typography';
import useFirestore from '../../FirestoreLink/FirestoreHook';
import Box from '@mui/material/Box';
import { makeRef } from '../../firebase_utilities';


export default function Locale() {
  let uid = useTypedSelector(store => store.userState.uid);
  
  let { data, updateFn } = useFirestore(makeRef('users_settings', uid));

  function valueDefined() {
    return data && (typeof data.locale === 'string');
  }

  let locales = getLocales();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row'
        },
        width: '100%'
      }}
    >
      <FormControl variant="standard">
        <InputLabel>Locale code</InputLabel>
        <Select
          id="locale-select"
          native
          // For compatibility we default users with no locale to en-us
          value={valueDefined() ? data.locale : 'en-us'}
          style={{
            width: 160
          }}
          onChange={(e) => {
            let v = /** @type {string} */ (e.target.value);
            log({ action: 'Set locale', label: v, debounce: 10000 });
            updateFn({
              locale: v
            });
            setLocale(v);
          }}
          variant="standard"
        >
          {['Auto'].concat(locales).map(l => <option key={l} value={l.toLowerCase()}>{l}</option>)}
        </Select>
      </FormControl>
  
      <Box
        sx={{
          paddingLeft: {
            xs: 0,
            sm: 2
          },
          paddingTop: {
            xs: 2,
            sm: 0
          },
          flex: 1,
          textAlign: 'right'
        }}>
        <T variant="body2" color="textSecondary" style={{ display: 'block' }}>Example date: <b>{moment(Date.now()).format('LLLL')}</b></T>
        <T variant="body2" color="textSecondary">Example currency: <b>{format('$,.2f')(43123.27)}</b></T>
      </Box>
    </Box>
  );
}
