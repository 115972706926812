import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Typography as T,
  Tooltip,
  Paper,
  Select,
  MenuItem
} from '@mui/material';

import LinkIcon from '@mui/icons-material/Link';
import ReadonlyIcon from '@mui/icons-material/RemoveRedEye';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import InfoChicklet from '../InfoChicklet/InfoChicklet';
import useFirestore from '../../FirestoreLink/FirestoreHook';
import AsyncButton from '../AsyncButton/AsyncButton';
import { httpsCallable } from 'firebase/functions';
import { useTypedSelectorShallowEquals, useTypedSelector, useIsMounted } from '../../hooks';
import { deleteGroup, enableGroup, disableGroup } from '../../data';
import { userPermission } from '../../auth';
import { sync } from '../../Sync/syncer';
import VerificationReminder from '../VerificationReminder/VerificationReminder';
import { fullValidateAddonSpec } from '../Snippet/snippet_utilities';
import { showTrashConfirmation, toast } from '../../message';
import { EmptyState } from '../EmptyState/EmptyState';
import { disableFolderIcon, enableFolderIcon } from './group_utils';
import ConnectedGroupButton from './ConnectedGroupButton';
import { storage } from '../../utilities';
import { isGroupPublic, isGroupPublicManageRestricted } from './group_public_rules';
import ShareGroup from './ShareGroup';
import { getTBFunctions, makeRef } from '../../firebase_utilities';
import { useHistory } from 'react-router-dom';
import SideBarButton from './SideBarButton';


let publishAddon = httpsCallable(getTBFunctions(), 'publishAddonGroup');


/**
 * @param {object} props
 * @param {string} props.groupId
 */
function GroupSidebarBase(props) {
  let [associatedAddonObject, setAssociatedAddonObject] = useState(/** @type {AddonObjectType} */ (null));
  let isMounted = useIsMounted();

  let dispatch = useDispatch();
  const { push: navigate } = useHistory();


  const {
    user,
    isAddon,
    isPublished,
    associatedAddonId,
    isSharedGroup,
    emailVerified,
    enabled,
    isConnected,
    isConnectedByUs
  } = useTypedSelectorShallowEquals((state) => {
    let uid = state.userState.uid;
    let prefs = state.userState.groups ? state.userState.groups[props.groupId] : null;
    let group = state.dataState.groups[props.groupId];
  
    let orgId = state.userState.org ? state.userState.org.id : null;
    let isConnected = group && group.connected && group.connected.is_connected;
    let isConnectedByUs = isConnected && (
      group.connected.connector === 'u:' + uid || group.connected.connector === 'o:' + orgId);

    let isAssociatedToUs = group && !!orgId && group.associated_org_id === orgId;

    return {
      user: state.userState,
      isAddon: !!(group && group.options && group.options.addon),
      isPublished: !!(group && group.associated_addon_id),
      associatedAddonId: (group && group.associated_addon_id),
      isAssociatedToUs,
      isSharedGroup: group && group.shared, // Needed rather then .group for the 'shared' flag on the group object
      emailVerified: state.userState.emailVerified,
      enabled: !(group && group.peekingOnly) && (/* something like a team group */ (!prefs && group) || /* your own group */ (prefs && !prefs.disabled)), // Want to make sure shared groups that haven't been added are disabled if loaded directly
      isConnected,
      isConnectedByUs
    };
  });

  const groupSeed = useTypedSelector((state) => state.dataState.groups[props.groupId], () => {
    // we never want this to trigger a re-render, as we only need it to initialize the
    // the Firestore link
    return true;
  });


  const link = useFirestore(makeRef('groups', props.groupId), groupSeed);
  /** @type {GroupObjectType} */
  const group = link.data && Object.assign({ id: props.groupId }, link.data);
  const groupUpdateFn = link.updateFn;

  let isPublic = group && isGroupPublic(group);
  let options = (group && group.options) || {};
  let isPublicRestricted = user && group && isGroupPublicManageRestricted(user, group);


  useEffect(() => {
    // If we are opening a group we haven't downloaded, let's peek it
    // to make sure it shows in the sidebar.
    if (link.exists && !groupSeed) {
      dispatch({
        type: 'PEEK_GROUP',
        data: {
          group_id: props.groupId
        }
      });
    }
    
    // this should only happen once, so
    // eslint-disable-next-line
  }, [link.exists, !!groupSeed]);


  async function publish(done) {
    if (!group.info) {
      done();
      return toast('You must enter a description for the command pack before publishing.', {
        duration: 6000,
        intent: 'danger'
      });
    }
    if (group.info.length < 20) {
      done();
      return toast('The description for the command pack is too short.', {
        duration: 6000,
        intent: 'danger',
      });
    }
    if (!group.options.addon.namespace) {
      done();
      return toast('You must enter a prefix for the command pack before publishing.', {
        duration: 6000,
        intent: 'danger'
      });
    }
    if (!(/^[a-z]{3,12}$/).test(group.options.addon.namespace)) {
      done();
      return toast('The prefix should be lowercase and 3-12 letters long.', {
        duration: 6000,
        intent: 'danger'
      });
    }
    if (!group.options.addon.author_name) {
      done();
      return toast('You must enter an author name for the command pack before publishing.', {
        duration: 6000,
        intent: 'danger'
      });
    }
    if (!group.options.addon.author_email || !/.+@.+/.test(group.options.addon.author_email)) {
      done();
      return toast('You must enter an author email for the command pack before publishing.', {
        duration: 6000,
        intent: 'danger'
      });
    }
    if (!group.options.addon.banner_image_url) {
      done();
      return toast('You must enter a banner image URL for the command pack before publishing.', {
        duration: 6000,
        intent: 'danger'
      });
    }
    if (!group.options.addon.icon_image_url) {
      done();
      return toast('You must enter an icon image URL for the command pack before publishing.', {
        duration: 6000,
        intent: 'danger'
      });
    }
    let snippets = sync.groups[props.groupId].snippets;
    if (!snippets.length) {
      done();
      return toast('No commands in the command pack. You must have at least one command.', {
        duration: 6000,
        intent: 'danger'
      });
    }

    /** @type {string[]} */
    let formNames = [];
    if (group.options.addon.config && group.options.addon.config.form_names) {
      formNames = Object.keys(group.options.addon.config.form_names);
    }
    for (let snippet of snippets) {
      let errors = await fullValidateAddonSpec(snippet.data, formNames);
      if (errors) {
        done();
        return toast(`There are errors with the command "${snippet.data.name}" that must be resolved before publishing. ${errors}`, {
          duration: 10000,
          intent: 'danger'
        });
      }
    }

    publishAddon({
      group_id: props.groupId
    }).then(() => {
      toast('Command pack submitted. It will be reviewed by the Text Blaze team before it is published.', {
        duration: 4000,
        intent: 'success'
      });
      done();
    }).catch((err) => {
      toast('Error publishing pack. ' + ((err && err.message) || ''), {
        duration: 6000,
        intent: 'danger'
      });
      done();
    });
  };

  function permission() {
    if (!group) {
      return null;
    }
    return userPermission(group);
  }


  function editable() {
    return isEditor() && (!isConnected || isConnectedByUs);
  }


  function isEditor() {
    return ['owner', 'editor'].includes(permission());
  }


  function enable() {
    enableGroup(props.groupId);
  }


  function disable() {
    disableGroup(props.groupId);
  }



  if (link.loading) {
    return <div style={{ paddingTop: '15vh', marginBottom: 30, justifyContent: 'center', display: 'flex' }}><CircularProgress size={150} thickness={1.9} /></div>;
  } else if (!link.exists || link.error) {
    return (<div style={{ paddingTop: emailVerified ? '30vh' : '10vh', marginBottom: 30 }}>
      <EmptyState
        icon="MISSING"
        title="Could not load folder"
        description="That folder does not exist or you do not have access to it."
      />

      {emailVerified ? null : (<div><br /><br /><br />
        <VerificationReminder />
      </div>)}
    </div>);
  }



  const deleteTitle = <span>folder <b>{group.name}</b> and its snippets</span>;
  return (
    <Paper
      elevation={1}
      square
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'grey.50',
        flex: {
          md: '0 0 315px',
          xs: 'auto'
        },
        paddingBottom: 1.875,
        paddingTop: 3.4,
        overflow: 'auto'
      }}
    >
      <Box
        sx={{
          flex: 1,
          px: 4.5,
        }}
      >
        {!isPublicRestricted && (
          <ShareGroup
            group={group}
            update={groupUpdateFn}
          />
        )}
        {(isAddon && permission() === 'owner' && !isPublicRestricted) && (
          <div style={{ border: 'solid 1px #ddd', borderRadius: 10, padding: 16, textAlign: 'center' }}>
            {isPublished ? <>
              <T paragraph color="textSecondary">
                <Tooltip onOpen={async () => {
                  if (!associatedAddonObject) {
                    let addonDoc = await storage.get(makeRef('addons', associatedAddonId));
                    if (isMounted.current) {
                      setAssociatedAddonObject(Object.assign(/** @type {any} */ ({ id: addonDoc.id }), addonDoc.data()));
                    }
                  }
                }
                } title={!associatedAddonObject ? '...' : associatedAddonObject.updated_at.toDate().toLocaleString()} >
                  <span>Published</span>
                </Tooltip>
                <Tooltip title="View published">
                  <IconButton
                    size="small"
                    target="_blank"
                    href={'/packs/' + associatedAddonId}
                    style={{
                      marginLeft: 12
                    }}
                  >
                    <LinkIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </T>
              <AsyncButton startIcon={<PublishIcon/>} variant="outlined" onClick={(done) => publish(done)}>Publish update</AsyncButton>
            </>
              : <>
                <T paragraph>Unpublished. You may publish the pack to add it to the public library.</T>
                <AsyncButton startIcon={<PublishIcon/>} variant="outlined" onClick={(done) => publish(done)}>Publish</AsyncButton>
              </>
            }
          </div>
        )}

        {(!isEditor()) ?
          <div style={{ opacity: 0.7, marginTop: 12 }}><ReadonlyIcon style={{ verticalAlign: 'middle' }} /> <span>
                  You are a viewer of this {isPublic ? 'public ' : ''} shared folder and cannot edit it
          </span></div>
          : null}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          px: 2
        }}
      >
        {!(isAddon && permission() === 'owner' && !isPublicRestricted) && (
          <div style={{ padding: 16 }}>

            <T variant="h6" paragraph>Folder options</T>
            <div>
              <T variant="subtitle2" style={{ display: 'inline-block' }}>Snippets trigger</T>
              <InfoChicklet large style={{ verticalAlign: 'middle' }}>
                <T variant="body2" paragraph><b>Started by Word Break</b>: Shortcuts are triggered if they are preceded by a word break.</T>
                <T variant="body2" paragraph><b>Started and ended by Word Break</b>: Shortcuts are triggered when they are preceded and ended by a word break (like a space).</T>
                <T variant="body2"><b>Anywhere</b>: Shortcuts can be triggered anywhere including in the middle of a word.</T>
              </InfoChicklet>
              <Select
                fullWidth
                variant="standard"
                value={options.trigger || 'word'}
                sx={{
                  '&:before': {
                    borderBottomWidth: 0
                  },
                  '&:hover:not(.Mui-disabled):before': {
                    borderBottomWidth: 0
                  }
                }}
                onChange={(event) => {
                  groupUpdateFn({
                    options: Object.assign(options, {
                      trigger: event.target.value
                    })
                  });
                }}
                disabled={!editable()}
              >
                <MenuItem value="word">Started by Word Break</MenuItem>
                <MenuItem value="standalone">Started and ended by Word Break</MenuItem>
                <MenuItem value="anywhere">Anywhere</MenuItem>
              </Select>
            </div>
            <div style={{ marginTop: 18 }}>
              {isEditor() && <ConnectedGroupButton
                isOwner={permission() === 'owner'}
                groupId={props.groupId}
                shareBlocksConnect={!group.associated_org_id && isSharedGroup}
                groupUpdateFn={groupUpdateFn}
              />}
            </div>
          </div>
        )}


        {enabled ?
          <Tooltip
            title={'Disable folder' + (isSharedGroup ? ' (applies to you only)' : '')}
            placement="left"
          >
            <SideBarButton
              startIcon={disableFolderIcon}
              onClick={() => disable()}
            >
                Disable folder
            </SideBarButton>
          </Tooltip>
          : (
            isPublicRestricted ? null : (
              <Tooltip title={'Enable folder' + (isSharedGroup ? ' (applies to you only)' : '')}>
                <SideBarButton
                  startIcon={enableFolderIcon}
                  onClick={() => enable()}
                >
                  Enable folder
                </SideBarButton>
              </Tooltip>
            )
          )
        }


        {permission() === 'owner' && (
          <>
            <Divider />

            <Tooltip
              title={<>Delete the {deleteTitle}</>}
              placement="left"
            >
              <SideBarButton
                startIcon={<DeleteIcon style={{ fontSize: 24 }} />}
                onClick={() => {
                  showTrashConfirmation({
                    item: (deleteTitle),
                    extra: ((isSharedGroup || isPublic) ? <span style={{ fontWeight: 'bold' }}>This is a {isPublic ? 'public' : 'shared'} folder and will be deleted for all users.</span> : null),
                    confirm: (
                      (isSharedGroup || isPublic)
                        ? `I want to delete this ${isPublic ? 'public folder' : 'shared folder for all users'}`
                        : null
                    ),
                    onDelete: async () => {
                      return deleteGroup(props.groupId, navigate);
                    },
                    pronoun: 'They'
                  });
                }}
              >
              Delete folder...
              </SideBarButton>
            </Tooltip>
          </>
        )}
      </Box>
    </Paper>
  );
}

const GroupSidebar = React.memo(GroupSidebarBase);
export default GroupSidebar;
