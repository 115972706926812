import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './inner_main.css';
import InnerModuleSnippets from './InnerModuleSnippets';
import UpdateCard from '../BillingDetails/UpdateCard';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import { isElectronApp } from '../../flags';
import { ExtensionIssueAlert } from '../ExtensionIssue/ExtensionIssue';
import { useRegisterGlobalHotkeys } from '../../hotkeys_hook';
import { KeyboardShortcutsDialog } from '../KeyboardShortcuts/KeyboardShortcutsDialog';


function InnerMain() {
  useRegisterGlobalHotkeys();

  return (
    <AuthenticatedRoutes>
      {!isElectronApp() && <ExtensionIssueAlert />}
      <KeyboardShortcutsDialog />

      <Switch>
        <Route
          exact
          path="/snippets"
          render={({ location: { hash } }) => {
            // Backward compatability.
            // Probably remove this after couple of releases.
            return <Redirect to={`/${hash || ''}`} />;
          }}
        />
        <Route path="/updatecard" component={UpdateCard} />
        <Route component={InnerModuleSnippets} />
      </Switch>
    </AuthenticatedRoutes>
  );
}


export default InnerMain;


