import React from 'react';
import DatabaseIcon from '@mui/icons-material/TableChart';
import Avatar from '@mui/material/Avatar';
import DatabaseShareIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PublicIcon from '@mui/icons-material/Public';


/**
 * @param {object} props
 * @param {string} props.icon
 * @param {boolean=} props.simple
 * @param {boolean=} props.isShared
 * @param {boolean=} props.isPublished
 * @param {React.ComponentType=} props.defaultIcon
 * @returns 
 */
export default function DatabaseChipIcon(props) {
  return <div translate="no" style={{ position: 'relative' }}>{props.icon ? <div style={{
    backgroundColor: '#f1f3f4',
    width: props.simple ? 44 : 90,
    height: props.simple ? 44 : 90,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'initial',
    paddingTop: props.simple ? undefined : 1,
    paddingLeft: 0,
    fontSize: props.simple ? '32px' : '68px',
    lineHeight: props.simple ?  '32px' : '68px',
    marginLeft: props.simple ? undefined : 'auto',
    marginRight: props.simple ? undefined : 'auto',
    userSelect: 'none'
  }}>
    {props.icon}
  </div> : <Avatar sx={{
    zoom: props.simple ? undefined : 2.2,
    backgroundColor: 'secondary.light',
    marginLeft: props.simple ? undefined : 'auto',
    marginRight: props.simple ? undefined : 'auto',
    userSelect: 'none'
  }}>
    {!!props.defaultIcon ? <props.defaultIcon /> : <DatabaseIcon />}
  </Avatar>}

  {(props.isShared || props.isPublished) && <div style={{
    backgroundColor: '#aaa',
    borderRadius: 100,
    color: 'white',
    width: 28,
    height: 28,
    position: 'absolute',
    right: 0,
    bottom: 0
  }}>{props.isPublished ? <PublicIcon style={{ position: 'relative', top: 2 }} /> : <DatabaseShareIcon style={{ position: 'relative', top: 2 }} />}</div>}
  </div>;
}