import React, { useContext } from 'react';
import ParseNode from '../../snippet_processor/ParseNode';
import { blockingImg, nonBlockingImg } from './shared';

/**
 * @typedef {object} ActionContextType
 * @property {(config?: import('../../snippet_processor/DownstreamProcess').FormRestoreConfig) => Promise<void>} onSubmit
 * @property {(allowDelay?: boolean, isUserInput?: boolean) => void} onChange
 * @property {Function} connectedConfigureFn
 * @property {Function} addonConfigureFn
 * @property {(msg: string, config: import('../Dialog/MessageDisplayInner').MessageConfigType) => (() => void)} showToast
 * @property {(update: import('./FormRenderer').RemoteProgressItemType) => void} onRemoteUpdate
 */

export const ActionContext = React.createContext(/** @type {ActionContextType} */ ({
  onSubmit: null,
  onChange: null,
  connectedConfigureFn: null,
  addonConfigureFn: null,
  showToast: null,
  onRemoteUpdate: null,
}));


export const AddonStatusContext = React.createContext({});


export const AttemptedInsertContext = React.createContext(false);


/**
 * @param {object} props
 * @param {React.ReactElement|string} props.msg
 * @param {boolean=} props.blocking
 * @param {(import('../../snippet_processor/ParseNode').default|string)=} props.nodeOrAddonId
 */
export function ShowError(props) {
  let msg = props.msg;
  let blocking = props.blocking || false;
  let nodeOrAddonId = props.nodeOrAddonId || undefined;

  let { connectedConfigureFn, addonConfigureFn } = useContext(ActionContext);

  if (typeof msg === 'string') {
    // @ts-ignore
    if (connectedConfigureFn && ['is not connected', 'Snippet not connected', 'in a Connected Snippet', 'with this query'].some(keyword => msg.includes(keyword))) {
      // eslint-disable-next-line
      msg = <>{msg} <a onClick={() => connectedConfigureFn()} style={{ fontSize: 'small', paddingLeft: 6, color: '#555' }}>Configure folder</a></>;
    } else if (addonConfigureFn && msg.includes('requires new permissions which you will need to approve')) {
      let match = msg.match(/{(\w+)-/);
      if (match) {
        let namespace = match[1];
        // eslint-disable-next-line
        msg = <>{msg} <a onClick={() => addonConfigureFn(namespace)} style={{ fontSize: 'small', paddingLeft: 6, color: '#555' }}>Review permissions</a></>;
      }
    }
    
  }
  
  return <span className="blaze-error" data-error={msg} data-storeid={nodeOrAddonId instanceof ParseNode ? (nodeOrAddonId.findRootAddonLocalData()) : (nodeOrAddonId || undefined)}>
    <svg viewBox="0 0 24 24" style={{ display: 'inline-block', color: 'rgba(0, 0, 0, 0.87)', fill: 'currentcolor', height: '24px', width: '24px', userSelect: 'none' }}>{blocking ? blockingImg : nonBlockingImg}</svg> {msg}
  </span>;
}

/**
 * @param {React.ReactElement} contents 
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>} [props] 
 */
export function Chicklet(contents, props) {
  return <span style={{
    display: 'inline-block',
    padding: '3px 5px 3px 3px',
    fontVariant: 'small-caps',
    direction: 'ltr'
  }}
  {...props}
  className="chicklet light">
    {contents}
  </span>;
}

export function OuterInstantError({ command }) {
  return <ShowError msg={`${command} cannot run instantly outside a code block. Put it inside run command or button command.`} blocking={false} />;
}