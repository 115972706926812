import React, { useEffect, useState } from 'react';
import T from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import { Button, Paper, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import Zoom from '@mui/material/Zoom';
import GoodIcon from '@mui/icons-material/ThumbUpAlt';
import BadIcon from '@mui/icons-material/ThumbDownAlt';
import { uidsToUserInfo } from '../PermissionList/utilities';
import { useIsMounted, useTypedSelector } from '../../hooks';
import UserAvatar from '../UserAvatar/UserAvatar';
import { EmptyState } from '../EmptyState/EmptyState';
import CircularProgress from '@mui/material/CircularProgress';
import TeamIcon from '@mui/icons-material/GroupWork';
import TodayIcon from '@mui/icons-material/Today';
import PeopleIcon from '@mui/icons-material/People';
// @ts-ignore
import headshot from './dan.png';
import { Link, useParams } from 'react-router-dom';
import { log } from '../../logging/logging';
import moment from 'moment';
import { commas, percent, timeSaved, usePlaceholderOrgData, useUserDomain } from './placeholder_utilities';
import BlazeOnly from '../BlazeOnly/BlazeOnly';
import PlaceholderOrganizationBanner from './PlaceholderOrgBanner';


const MAX_ROWS = 5;
const MAX_ADMINS = 3;



let morePeople = (count) => <T style={{ textAlign: 'center' }} color="textSecondary">
  <PeopleIcon style={{ verticalAlign: 'middle', marginRight: 8 }} /> and {commas(count)} more...
</T>;


let userCard = (info, config = {}) => {
  return <UserAvatar
    info={info}
    config={Object.assign({
      labelStyle: /** @type {React.CSSProperties} */ ({
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 200
      })
    }, config)}
    secondLine={(info && info.name) ? <T variant="caption" color="textSecondary" style={{
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 200
    }}>
      <a
        target="_blank"
        rel="noreferrer"
        href={'mailto:' + info.email}
        style={{
          color: 'inherit'
        }}
      >{info.email}</a>
    </T> : null}
  />;
};


export default function PlaceholderOrg() {
  const { domain: domainParam } = /** @type {{ domain: string }}  */ (useParams());
  let userDomain = useUserDomain();

  // don't let users specify domains directly
  if (userDomain !== 'blaze.today' && domainParam !== undefined) {
    return <EmptyState
      icon="ERROR"
      title="Invalid page"
      style={{
        marginTop: 60
      }}
    />;
  }

  let domain = domainParam || userDomain;

  if (!domain) {
    return <EmptyState
      icon="ERROR"
      title="Your email is not verified"
      style={{
        marginTop: 60
      }}
    />;
  }

  return <PlaceholderOrgInner domain={domain} />;
}


/**
 * @param {object} props
 * @param {string} props.domain
 */
function PlaceholderOrgInner(props) {
  let [data, setData] = useState(null);
  let userDomain = useUserDomain();
  let isMounted = useIsMounted();
  let [userInfos, setUserInfos] = useState(/** @type {Object<string, import('../PermissionList/utilities').UserInfo>} */ (null));


  let uid = useTypedSelector((store) => store.userState.uid);


  useEffect(() => {
    log({ action: 'View Company Page' });
  }, []);
  

  let orgData = usePlaceholderOrgData(props.domain);
  let rawData = orgData.data;
  let error = orgData.error;


  useEffect(() => {
    if (!rawData) {
      return;
    }
    
    (async () => {
      let tmpData = JSON.parse(JSON.stringify(rawData));
      tmpData.past_week.users.sort((a, b) => b.c - a.c);

      let uids = tmpData.past_week.users.slice(0, MAX_ROWS).map(x => x.id);
      if (tmpData.orgs) {
        tmpData.orgs.sort((a, b) => b.members - a.members);
        for (let org of tmpData.orgs) {
          let extraAdmins = Math.max(org.contacts.length - MAX_ADMINS, 0);
          org.contacts = org.contacts.slice(0, MAX_ADMINS);
          uids = uids.concat(org.contacts);
          if (extraAdmins > 0) {
            org.contacts.push(extraAdmins);
          }
        }
      }

      setData(tmpData);
      
      uids = [...new Set(uids)];

      let userInfoArray = await uidsToUserInfo(uids);
      /** @type {Object<string, import('../PermissionList/utilities').UserInfo>} */
      let userInfos = {};
      for (let i = 0; i < userInfoArray.length; i++) {
        userInfos[uids[i]] = userInfoArray[i];
      }
      if (isMounted.current) {
        setUserInfos(userInfos);
      }
    })();
    // eslint-disable-next-line
  }, [rawData]);

  if (error) {
    return <EmptyState
      icon="ERROR"
      title="Invalid page"
      style={{
        marginTop: 60
      }}
    />;
  }


  if (data === null) {
    return <div style={{
      paddingTop: 60,
      marginBottom: 30,
      justifyContent: 'center',
      display: 'flex'
    }}>
      <CircularProgress size={120} thickness={1.9} />
    </div>;
  }


  let {
    name,
    domains,
    past_week,
    all_time
  } = data;


  let allUsers = past_week.users;
  let hiddenCounter = 0;
  let users = [];
  for (let i = 0; i < allUsers.length; i++) {
    let user = allUsers[i];
    if (i < MAX_ROWS) {
      user.index = i;
      users.push(user);
    } else if (user.id === uid) {
      if (hiddenCounter) {
        users.push(hiddenCounter);
        hiddenCounter = 0;
      }
      user.index = i;
      users.push(user);
    } else {
      hiddenCounter++;
    }
  }

  if (hiddenCounter) {
    users.push(hiddenCounter);
  }


  const hasOrgs = !!(data.orgs && data.orgs.length);

  if (userDomain !== 'blaze.today' && !data.is_live) {
    return <EmptyState
      icon="ERROR"
      title="Invalid page"
      style={{
        marginTop: 60
      }}
    />;
  }

  return <div style={{
    height: '100%',
    position: 'relative',
    overflow: 'auto',
    display: 'flex', 
    alignItems: 'flex-start', 
  }}>
    <div style={{
      flex: 1,
      paddingTop: 30,
      paddingLeft: 20,
      overflow: 'auto',
      maxHeight: 'calc(100vh - 48px)',
      paddingRight: 32,
      paddingBottom: 48
    }}>
      <BlazeOnly>
        {(data.is_live ? <Alert severity="info" style={{ marginBottom: 24 }}>
          <b>[Blaze Internal Only]</b> This page is live and being shown to users.
        </Alert> : <Alert severity="warning" style={{ marginBottom: 24 }}>
          <b>[Blaze Internal Only]</b> This page is not live and is not being shown to users.
        </Alert>)}
      </BlazeOnly>

      <BlazeOnly>
        <Paper variant="outlined" style={{
          marginTop: 20,
          marginBottom: 20,
          padding: '12px 12px 0px'
        }}>
          <T paragraph><b>[Blaze Internal Only]</b> Banner Preview</T>
          <div style={{
            width: 360
          }}>
            <PlaceholderOrganizationBanner previewDomain={props.domain}/>
          </div>
        </Paper>
      </BlazeOnly>

      <div style={{
        marginBottom: 72,
        display: 'flex'
      }}>
        <div
          style={{
            flex: 1,
            marginRight: 20
          }}
        >

          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 34
          }}>
            <img
              src={'//logo.clearbit.com/' + domains[0]}
              alt="Logo" style={{
                height: 34,
                marginRight: 20
              }}
              // @ts-ignore
              referrerPolicy="no-referrer"
            />

            <Zoom in timeout={600}>
              <span style={{
                fontSize: 50
              }}>❤️</span>
            </Zoom>

            <img
              src="https://blaze.today/images/logo_standalone.png"
              style={{
                height: 38,
                marginLeft: 24
              }}
              alt="TB Logo"
            />
          </div>
      
          <T variant="h5">
            <GoodIcon style={{ color: 'darkgreen', verticalAlign: 'middle', opacity: .7 }} fontSize="large" /> {commas(all_time.active_users)} users at {name} have saved a total of {timeSaved(all_time.total_characters)} with Text Blaze.<br/><br/>
            {past_week.summary.fraction_forms > .7 ? <GoodIcon style={{ color: 'darkgreen', verticalAlign: 'middle', opacity: .7 }}  fontSize="large" /> : <BadIcon style={{ color: 'darkred', verticalAlign: 'middle', opacity: .8 }}  fontSize="large" />} {past_week.summary.fraction_forms > 0 && past_week.summary.fraction_forms < .7 && 'Only'} {percent(past_week.summary.fraction_forms)} of {name} users use <a href="https://blaze.today/guides/forms" target="_blank" rel="noopener noreferrer">Text Blaze forms</a>. People who use forms save 70% more time on average than those who don't.
          </T>
          <div style={{
            flex: 1,
            marginTop: 50,
            paddingRight: 20,
            maxWidth: 500
          }}>
            <img
              width="100%"
              src="https://charts.blaze.tools/r?c=e3R5cGU6J2hvcml6b250YWxCYXInLGRhdGE6e2xhYmVsczpbJ05vbi1mb3JtIFVzZXJzJywnRm9ybSBVc2VycyddLGRhdGFzZXRzOlt7YmFja2dyb3VuZENvbG9yOlsnI2NjYycsICcjZjA1MDI2J10sbGFiZWw6J1VzZXJzJyxkYXRhOlsxMDAsMTc0XX1dfSxvcHRpb25zOntsZWdlbmQ6e2Rpc3BsYXk6ZmFsc2V9LHNjYWxlczp7eUF4ZXM6W3tncmlkTGluZXM6e2Rpc3BsYXk6ZmFsc2V9LHRpY2tzOntmb250U2l6ZToxOH19XSx4QXhlczpbe3NjYWxlTGFiZWw6e2Rpc3BsYXk6dHJ1ZSxsYWJlbFN0cmluZzonVGltZSBTYXZlZCcsZm9udFNpemU6MTh9LGdyaWRMaW5lczp7ZGlzcGxheTpmYWxzZX0sdGlja3M6e21pbjowLGRpc3BsYXk6ZmFsc2V9fV0sfSxsYXlvdXQ6e3BhZGRpbmc6e2xlZnQ6MCxyaWdodDoxMCx0b3A6MCxib3R0b206MH19LHBsdWdpbnM6e2RhdGFsYWJlbHM6e2Rpc3BsYXk6dHJ1ZSxhbmNob3I6J2VuZCcsZm9ybWF0dGVyOmZ1bmN0aW9uKHZhbHVlLGNvbnRleHQpe3JldHVybih2YWx1ZS8xMDApLnRvUHJlY2lzaW9uKDIpKydYJzt9LGFsaWduOidyaWdodCcsY29sb3I6WycjOTk5JywnI2U2NTE0MiddLGZvbnQ6e3N0eWxlOidib2xkJyxzaXplOjE2fX19fX0%3D&i=tbc&sig=2e12d721aaa02d07936efdb7da12ddc2c1ecc0d3a811458e6f71bd976f38d2a4&height=200"
              alt="Chart"
            />
          </div>
        </div>

        {users.length > 0 && <div style={{ 
          flex: 1,
          paddingLeft: 50
        }}>
          <T variant="h6" style={{ marginBottom: 14, marginTop: 28 }}>Top {name} users this week</T>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 40 }}></TableCell>

                <TableCell>
                User
                </TableCell>
            
                <TableCell style={{
                  whiteSpace: 'nowrap'
                }}>
                Time saved this week
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, i) => {
                if (typeof user === 'number') {
                  return <TableRow key={'fill_' + i}>
                    <TableCell colSpan={3}>
                      {morePeople(user)}
                    </TableCell>
                  </TableRow>;
                }

                return <TableRow key={user.id}>
                  <TableCell>
                    <T>{user.index + 1}.</T>
                  </TableCell>

                  <TableCell>
                    {user.id === uid ? <T><b>You</b></T> : userCard(userInfos && userInfos[user.id], { avatarSize: 32 })}
                  </TableCell>

                  <TableCell style={{
                    textAlign: 'right'
                  }}>
                    <T>{timeSaved(user.c)}</T>
                  </TableCell>
                </TableRow>;
              })}
            </TableBody>
          </Table>
        </div>}
      </div>


      <Alert severity="warning" icon={false} style={{
        marginBottom: 32,
        marginTop: 24,
        position: 'relative'
      }}>
        <div style={{ display: 'flex' }}>
          <WarningIcon
            fontSize="large"
            style={{
              color: '#ff9800',
              verticalAlign: 'middle',
              marginRight: 20
            }}
          />
          <T>This account is not managed. Sign up for <Link to="/upgrade">Text Blaze Business or Enterprise</Link> to get additional features and organizational management capabilities.</T>
        </div>

        {hasOrgs ? <div>
          <T variant="body1" style={{
            marginBottom: 24,
            marginTop: 12
          }}>
            {data.orgs.length > 1 ? `There are existing organizations for ${name}. If you see one suitable for you, request to join it.` : `There is an existing organization for ${name}. If it is suitable for you, just request to join it.`}
          </T>

          <div style={{
            display: 'flex',
            gap: 24,
            marginBottom: 32,
            flexWrap: 'wrap',
            alignItems: 'stretch'
          }}>
            {data.orgs.map(org => <Paper key={org.id} elevation={3} style={{
              padding: 12,
              width: 320
            }}>
              <T variant="h6" style={{
                marginBottom: 8
              }}><TeamIcon fontSize="large" style={{
                  verticalAlign: 'middle',
                  opacity: .5,
                  position: 'relative',
                  top: -2,
                  marginRight: 10,
                }}/> {org.name}</T>
              <T
                variant="subtitle2"
                style={{
                  marginBottom: 14
                }}
              >{commas(org.members)} Members</T>
              
              {org.info ? <T variant="body1" style={{ marginBottom: 14 }}>{org.info}</T> : null}

              <T variant="subtitle1">Administrators</T>
              <div
                style={{
                  overflowX: 'hidden'
                }}
              >
                {org.contacts.map(uid => typeof uid === 'number' ? <div
                  style={{
                    marginTop: 12,
                    marginBottom: 12
                  }}
                  key={uid}
                >{morePeople(uid)}</div> : <div
                  style={{ display: 'inline-block', padding: 6 }}
                  key={uid}
                >
                  {userCard(userInfos && userInfos[uid])}
                </div>)}
              </div>
            </Paper>)}
          </div>
        </div> : null}
      
        <Paper variant="outlined" style={{
          padding: 18,
          marginTop: 18,
          marginBottom: 12,
          display: 'flex',
          backgroundColor: 'rgba(255,255,255,.7)',
          border: 'none'
        }}>
          <img
            src={headshot}
            style={{
              width: 80,
              height: 80,
              borderRadius: 2000
            }}
            alt="Dan"
          />
          <div style={{
            paddingLeft: 24
          }}>
            <T>
              {hasOrgs && 'If you do not see a existing organization for you, create a new one.'} Dan is here to discuss how {name} can get the most out of Text Blaze. Schedule a meeting or email him at <a href="mailto: dan@blaze.today">dan@blaze.today</a> to get started today.
            </T>
            <div style={{
              textAlign: 'right',
              marginTop: 12
            }}>
              <Button
                variant="outlined"
                href="https://calendar.app.google/xxSiSfmWdcejTrv99"
                target="_blank"
                rel="noopener noreferrer"
                startIcon={<TodayIcon />}
              >
                Schedule a meeting with Dan
              </Button>
            </div>
          </div>
        </Paper>
      </Alert>

      
      <div style={{
        textAlign: 'right',
        marginTop: 48
      }}>
        <T color="textSecondary" paragraph>Last updated {moment(data.updated_at * 1000).format('LLL')}.</T>
        <T variant="body2" color="textSecondary">{name} logo from <a href="https://clearbit.com" style={{ color: 'inherit' }} target="_blank" rel="noreferrer">Clearbit</a>. User profile images from <a href="https://google.com" style={{ color: 'inherit' }} target="_blank" rel="noreferrer">Google</a>.</T>
      </div>
    </div>
  </div>;
}