import React, { useEffect, useState } from 'react';
import FormRenderer from '../FormRenderer/FormRenderer';
import { Environment } from '../../snippet_processor/DataContainer';
import { createDom } from '../../snippet_processor/SnippetProcessor';


/**
 * @param {object} props
 * @param {object} props.config
 * @param {object} props.data
 * @param {DeltaType} props.delta
 * @param {any} props.onReject
 * @param {any} props.onAccept
 * @param {object|boolean} props.controls
 */
export default function Page(props) {
  const [dom, setDom] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    /** @type {DeltaType} */
    let newDelta = { ops: props.delta.ops.slice() };
    let d = props.data ? Object.assign({}, props.data) : null;
    let env = new Environment(Object.assign({}, d), props.config || undefined);
    createDom(newDelta, env).then(dom => {
      setData(d);
      setDom(dom);
    });
    // we only want this to execute once, otherwise the doc would jump around
    // when snippets were updated.
    // eslint-disable-next-line
  }, []);


  if (!dom || !props.config) {
    return null;
  }
  
  return <div className="page" style={{ padding: 0, flex: 1, overflow: 'auto' }}>
    <FormRenderer
      isPro
      isOrg
      dom={dom}
      config={props.config}
      countUsage={false}
      controls={props.controls}
      onAccept={async (store) => {
        return props.onAccept(store, dom);
      }}
      onReject={props.onReject}
      initialData={data}
    />
  </div>;
}