import React, { forwardRef } from 'react';
import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography as T
} from '@mui/material';
// @ts-ignore
import borderAllIcon from './icons/table-all.svg';
// @ts-ignore
import borderCornersIcon from './icons/table-corners.svg';
// @ts-ignore
import borderInsideIcon from './icons/table-inside.svg';
// @ts-ignore
import borderTopIcon from './icons/table-top.svg';
// @ts-ignore
import borderRightIcon from './icons/table-right.svg';
// @ts-ignore
import borderBottomIcon from './icons/table-bottom.svg';
// @ts-ignore
import borderLeftIcon from './icons/table-left.svg';
/**
 * 
 * @param {object} props
 * @param {string[]} props.value
 * @param {(value: string[]) => any} props.onChange
 */
const BorderSidePicker = ({
  value,
  onChange
}) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: 16
    }}>
      <T variant="button">Border side to be applied to?</T>
      <ToggleButtonGroup
        value={value}
        onChange={(evt, side) => {
          onChange([side]);
        }}
        exclusive
      >
        <BorderButton
          value="all"
          label="All"
          icon={borderAllIcon}
        />
        <BorderButton
          value="corners"
          label="Corners"
          icon={borderCornersIcon}
        />
        <BorderButton
          value="inside"
          label="Inside"
          icon={borderInsideIcon}
        />
      </ToggleButtonGroup>


      <ToggleButtonGroup
        value={value}
        onChange={(evt, newSides) => {
          if (newSides.length === 2
            && ['all', 'corners', 'inside'].includes(newSides[0])
          ) {
            onChange([newSides[1]]);
          } else {
            onChange(newSides);
          }
        }}
      >
        <BorderButton
          value="top"
          label="Top"
          icon={borderTopIcon}
        />
        <BorderButton
          value="right"
          label="Right"
          icon={borderRightIcon}
        />
        <BorderButton
          value="bottom"
          label="Bottom"
          icon={borderBottomIcon}
        />
        <BorderButton
          value="left"
          label="Left"
          icon={borderLeftIcon}
        />
      </ToggleButtonGroup>
    </div>
  );
};

export default BorderSidePicker;

/**
 * @type {React.ForwardRefExoticComponent<import("@mui/material").ToggleButtonProps&{label: string, icon: string}>}
 */
const BorderButton = forwardRef(({
  label,
  icon,
  ...otherProps
}, ref) => {
  return (
    <Tooltip
      title={label}
    >
      <ToggleButton
        ref={ref}
        {...otherProps}
      >
        {icon ? <img
          src={icon}
          style={{
            width: 24,
            height: 24,
            opacity: .6,
            verticalAlign: 'middle'
          }}
          alt={`Border side ${label}`}
        /> : label}
      </ToggleButton>
    </Tooltip>
  );
});