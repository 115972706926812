import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography as T
} from '@mui/material';
import React, { useEffect, useId, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

/**
 * 
 * @param {object} props
 * @param {string=} props.id
 * @param {number=} props.inputWidth
 * @param {number} props.value
 * @param {number=} props.minimum
 * @param {number=} props.maximum
 * @param {string=} props.label
 * @param {(value: number) => any} props.onChange
 * @param {import("@mui/system").SxProps<import("@mui/material").Theme>=} props.sx
 * @param {React.ReactNode=} props.helperText
 */
const Incrementor = ({
  id,
  inputWidth,
  value,
  onChange,
  minimum,
  maximum,
  label,
  sx,
  helperText
}) => {
  const fieldId = useId();
  /**
   * @param {number} val 
   */
  const processValue = (val) => {
    if (val < minimum) {
      val = minimum;
    }

    if (val > maximum) {
      val = maximum;
    }
    if (isNaN(val)) {
      val = 0;
    }
    onChange(val);
  };
  return (
    <Box
      sx={[
        {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          gap: 1
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {label && (
        <T
          component="label"
          htmlFor={fieldId}
          sx={{
            height: 47,
            // make it to center align only to text field,
            // but not with including helpertext
            lineHeight: '47px'
          }}
        >
          {label}
        </T>
      )}
      <Box>
        <OutlinedInput
          id={fieldId}
          value={value}
          sx={{
            p: 0,
            width: inputWidth
          }}
          inputProps={{
            pattern: '[0-9]+',
            min: minimum,
            max: maximum,
            style: {
              'textAlign': 'center',
              padding: '12px 0'
            }
          }}
          startAdornment={
            <InputAdornment position="start">
              <PressHoldButton
                aria-label="Reduce"
                onPressed={() => processValue(value - 1)}
                disabled={minimum && value <= minimum}
              >
                <RemoveIcon />
              </PressHoldButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <PressHoldButton
                aria-label="Increase"
                onPressed={() => processValue(value + 1)}
                disabled={maximum && value >= maximum}
              >
                <AddIcon />
              </PressHoldButton>
            </InputAdornment>
          }
          onChange={(evt) => {
            const val = evt.target.value;
            processValue(val ? parseInt(val, 10) : 0);
          }}
        />
        {helperText && (
          <Box
            sx={{
              mt: 2
            }}
          >
            {helperText}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Incrementor;


const START_TIMER = 300,
  END_TIMER = 15;
/**
 * 
 * @typedef {object} PressHoldButtonProps
 * @property {() => any} onPressed
 * @property {React.ReactNode} children
 * @property {boolean=} disabled
 * @property {string=} aria-label
 * 
 * @param {PressHoldButtonProps} props
 */
const PressHoldButton = ({
  onPressed,
  children,
  disabled,
  'aria-label': ariaLabel
}) => {
  const [pressed, setPressed] = useState(false);
  const [runInterval, setRunInterval] = useState(0);
  const startPress = () => {
    onPressed();
    setPressed(true);
    return false;
  };
  const stopPress = () => {
    setPressed(false);
  };
  useEffect(() => {
    // If the button is disabled while it is in pressed state
    if (disabled) {
      stopPress();
    }
  }, [disabled]);
  useEffect(() => {
    if (!pressed) {
      return;
    }
    let timeout = START_TIMER,
      timer;
    const triggerTicker = () => {
      timer = setTimeout(() => {
        triggerTicker();
      }, timeout);
      timeout -=  (timeout - END_TIMER) / 4;
      if (timeout <= END_TIMER) {
        timeout = END_TIMER;
      }
      setRunInterval(timeout);
    };

    setRunInterval(1);
    triggerTicker();
    return () => {
      clearTimeout(timer);
      setRunInterval(0);
    };
  }, [pressed, disabled]);
  useEffect(() => {
    if (!runInterval) {
      return;
    }
    const interval = setTimeout(() => {
      onPressed();
    }, runInterval);
    return () => clearTimeout(interval);

  }, [runInterval, onPressed]);
  return (
    <IconButton
      size="small"
      sx={{
        px: 2,
        minWidth: 'auto',
        width: 40,
        height: 40,
        mx: 0.5
      }}
      onMouseDown={startPress}
      onMouseUp={stopPress}
      onMouseOut={stopPress}
      onTouchStart={startPress}
      onTouchEnd={stopPress}
      // For mobile 
      onContextMenu={(evt) => evt.preventDefault()}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {children}
    </IconButton>
  );
};

