import { useEffect } from 'react';
import hotkeys from './hotkeys';
import { createGroup } from './data';
import { useHistory } from 'react-router-dom';
import { storage } from './utilities';

/**
 * 
 * @param {string} pattern Example: "cmd+KeyK, ctrl+KeyK"
 * @param {(event: KeyboardEvent) => void} fn 
 * @param {any[]=} deps
 */
export default function useHotkey(pattern, fn, deps) {
  useEffect(() => (
    hotkeys.listen(pattern, fn)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [pattern].concat(deps || []));
}


export function useRegisterGlobalHotkeys() {
  const history = useHistory();

  useHotkey('n+f', () => {
    createGroup()
      .then(newGroupId => {
        history.push(`/folder/${newGroupId}`);
      })
      .catch(() => {}); // pass on error;
  });

  // Switches to preview on snippets, but on Windows it opens a browser print window. Disable it everywhere for better ux
  useHotkey('ctrl+shift+p', (e) => {
    e.preventDefault();
  });

  // override the tendency of users to try to save, since we auto-save
  useHotkey('cmd+s, ctrl+s', (e) => {
    e.preventDefault();

    storage.showSavedNotification();
  });
}
