import React from 'react';
import AsyncButton from '../AsyncButton/AsyncButton';
import Button from '@mui/material/Button';
import { isElectronApp } from '../../flags';
import { sendMessageToClient } from '../../desktop_utilities';

/**
 * @param {object} params
 * @param {object} params.plan
 * @param {boolean} params.isOrg
 * @param {boolean} params.isPayingPro
 * @param {function(object):void} params.onBuy
 */
export default function VersionPurchaseButton({ plan, isOrg, isPayingPro, onBuy }) {
  if (plan.id === 'enterprise') {
    return <Button variant="contained" color="primary" href="mailto:sales@blaze.today?subject=Text Blaze Enterprise" target="_blank">Contact sales</Button>;
  } else if (plan.id === 'pro') {
    return isPayingPro ?
      <Button variant="outlined" disabled>Already got it</Button>
      : <AsyncButton variant="contained" color="primary" onClick={(done, evt) => {
        if (isElectronApp()) {
          // send a message to app to open upgrade page in browser
          sendMessageToClient({
            type: 'upgrade'
          });
          done();
        } else {
          onBuy({ type: 'pro', done, source: evt.currentTarget });
        }
        
      }}>Buy it</AsyncButton>;
  } else if (plan.id === 'business') {
    return isOrg ?
      <Button variant="outlined" disabled>Already got it</Button>
      : <AsyncButton variant="contained" color="primary" onClick={(done, evt) => {
        if (isElectronApp()) {
          // send a message to app to open upgrade page in browser
          sendMessageToClient({
            type: 'upgrade'
          });
          done();
        } else {
          onBuy({ type: 'business', done, source: evt.currentTarget });
        }
      }}>Buy it</AsyncButton>;
  }
}