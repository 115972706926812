import React, { useRef, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import T from '@mui/material/Typography';
import TagInput from '../TagInput/TagInput';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AttributeList, { AttributeChip } from './AttributeList';
import { validDomain } from '../../standalone_utilities';
import { DragDropContext } from 'react-beautiful-dnd';
import ConfigureAttribute from '../DataDialog/ConfigureAttribute';
import { quickValidateAddonSpec } from './snippet_utilities';
import Paper from '@mui/material/Paper';


/**
 * @param {object} props
 * @param {SnippetObjectType} props.snippet
 * @param {string} props.namespace
 * @param {boolean} props.editable
 * @param {function} props.updateFn
 */
export default function AddonConfigPanel(props) {
  let [selectedAttribute, setSelectedAttribute] = useState(null);
  let [pendingProposedDomains, setPendingProposedDomains] = useState('');

  let options = props.snippet.options || {};
  let addon = options.addon;
  if (!addon) {
    addon = /** @type {any} */ ({});
  }
  let display = addon.display;
  if (!display) {
    display = /** @type {any} */ ({});
  }

  let bottomRef = useRef(null);

  let attributeErrors = null;
  attributeErrors = quickValidateAddonSpec(addon);


  function selectAttribute(data) {
    if (data !== null &&  bottomRef.current) { 
      setTimeout(() => bottomRef.current.scrollIntoView({ behavior: 'smooth' }), 40);
    }
    setSelectedAttribute(data);
  }


  return <>
    <T gutterBottom variant="h6">
    General
    </T>

    <TextField
      label="Description"
      disabled={!props.editable}
      style={{
        width: '100%'
      }}
      multiline
      minRows={2}
      maxRows={8}
      value={addon.description || ''}
      onChange={(e) => props.updateFn({ 'options.addon.description': e.target.value }, 1200)}
      margin="normal"
      helperText="The description of the command."
      variant="outlined"
    />
    <br/>

    {props.editable && (<>
      <T gutterBottom variant="h6">
        Command display
      </T>

      <T gutterBottom variant="subtitle1">
        During form/preview
      </T>

      <Select
        input={
          <OutlinedInput
            size="small"
          />
        }
        value={display.preview || 'contents'}
        onChange={(e) => {
          props.updateFn({ 'options.addon.display.preview': e.target.value });
        }}
        fullWidth
        variant="standard"
      >
        <MenuItem value="contents">
          <ListItemText primary="Show contents"  secondary="Command contents is displayed in the preview"/>
        </MenuItem>

        <MenuItem value="chicklet">
          <ListItemText primary="Show chicklet" secondary="Displays a chicklet in the preview" />
        </MenuItem>
      </Select>
      <T color="textSecondary" paragraph variant="caption">
        Controls how the command is displayed in the snippet preview or in the form entry window. 
      </T>

      <T gutterBottom variant="subtitle1">
        During snippet insertion
      </T>

      <Select
        input={
          <OutlinedInput
            size="small"
          />
        }
        value={display.insertion || 'contents'}
        onChange={(e) => {
          props.updateFn({ 'options.addon.display.insertion': e.target.value });
        }}
        fullWidth
        variant="standard"
      >
        <MenuItem value="contents">
          <ListItemText primary="Insert contents"  secondary="Command contents is included during insertion"/>
        </MenuItem>

        <MenuItem value="none">
          <ListItemText primary="Insert nothing" secondary="Do not include the command during insertion" />
        </MenuItem>
      </Select>
      <T color="textSecondary" paragraph variant="caption">
        Controls how the command is displayed when inserted as part of a snippet.
      </T>

      <T gutterBottom variant="subtitle1">
        Domains on which the command can be inserted
      </T>

      <TagInput
        placeholder="example.com"
        values={display.valid_hosts || []}
        onInputChange={(evt) => setPendingProposedDomains(evt.target.value)}
        inputValue={pendingProposedDomains}
        onRemove={(_val, index) => {
          let domains = (display.valid_hosts || []).slice();
          domains.splice(index, 1);
          props.updateFn({ 'options.addon.display.valid_hosts': domains });
        }}
        onAdd={(values) => {
          let domains = (display.valid_hosts || []).slice();
          props.updateFn({ 'options.addon.display.valid_hosts': domains.concat(values) });
          setPendingProposedDomains('');
        }}
        tagProps={(value) => validDomain(value) ? {} : { color: 'secondary', variant: 'default' }}
        startIcon={<VerifiedUserIcon />}
      />

      <T color="textSecondary" paragraph variant="caption">
        May be left blank for the command to be usable on all domains. Must be specified for commands containing {'{key}, {click} or {site}'} commands. All subdomains of the listed domains are supported.
      </T>


      <Paper
        variant="outlined"
        style={{
          fontSize: '120%',
          padding: 12,
          marginBottom: 4
        }}
      >

        <T gutterBottom variant="h6">
          Settings
        </T>

        {attributeErrors ? <T color="error" variant="body2" paragraph style={{
          marginTop: 10
        }}>You must resolve an error before you can use this command. {attributeErrors}</T> : null}

        <DragDropContext onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }

          let positional = (addon.positional || []).slice();
          let named = (addon.named || []).slice();

          if (result.destination.droppableId.endsWith('positional') && positional.length) {
            // Can't have more than one positional
            return;
          }


          let item;
          if (result.source.droppableId.endsWith('positional')) {
            item = positional.splice(result.source.index, 1)[0];
          } else {
            if (result.destination.droppableId.endsWith('positional') && named[result.source.index].type === 'identifier') {
            // Can't have identifier positional attributes
              return;
            }
            item = named.splice(result.source.index, 1)[0];
          }

          if (result.destination.droppableId.endsWith('positional')) {
            positional.splice(result.destination.index, 0, item);
          } else {
            named.splice(result.destination.index, 0, item);
          }

          props.updateFn({
            'options.addon.positional': positional,
            'options.addon.named': named
          });

          selectAttribute([result.destination.droppableId.endsWith('positional') ? 'positional' : 'named', result.destination.index]);
        }}>
          <T style={{ display: 'inline-block' }}>{'{' + (props.namespace || '[Needs prefix]') + '-' + props.snippet.shortcut + ': '}</T>
          <AttributeList
            type="positional"
            selected={selectedAttribute}
            attributes={addon.positional || []}
            onAdd={() => {
              let items = addon.positional || [];
              items.push({
                name: 'setting',
                type: 'string',
                required: false,
                visibility: 'default',
                default: '',
                config: {}
              });
              props.updateFn({
                'options.addon.positional': items
              });
              selectAttribute(['positional', items.length - 1]);
            }}
            onDelete={(i) => {
              let items = addon.positional;
              items.splice(i, 1);
              props.updateFn({
                'options.addon.positional': items
              });
              selectAttribute(null);
            }}
            onSelect={(i) => {
              selectAttribute(['positional', i]);
            }}
          />
          <AttributeList
            type="named"
            selected={selectedAttribute}
            attributes={addon.named || []}
            onAdd={() => {
              let items = addon.named || [];
              items.push({
                name: 'setting',
                type: 'string',
                required: false,
                visibility: 'default',
                default: '',
                config: {}
              });
              props.updateFn({
                'options.addon.named': items
              });
              selectAttribute(['named', items.length - 1]);
            }}
            onDelete={(i) => {
              let items = addon.named;
              items.splice(i, 1);
              props.updateFn({
                'options.addon.named': items
              });
              selectAttribute(null);
            }}
            onSelect={(i) => {
              selectAttribute(['named', i]);
            }}
          />
          <AttributeChip
            attr={{
              name: 'trim',
              type: 'boolean'
            }}
            type="standard"
            i="trim"
            onSelect={() => selectAttribute(['standard', 'trim'])}
            selected={selectedAttribute}
          />
          <T style={{ display: 'inline-block' }}>{'}'}</T>
        </DragDropContext>

        {(selectedAttribute && selectedAttribute[1] !== 'trim') ? <div style={{
          paddingTop: 15,
          marginTop: 10
        }}>
          <ConfigureAttribute
            type={selectedAttribute[0]}
            attribute={addon[selectedAttribute[0]][selectedAttribute[1]]}
            onChange={(newAttr) => {
              let items = addon[selectedAttribute[0]].slice();
              items[selectedAttribute[1]] = newAttr;
              props.updateFn({
                ['options.addon.' + selectedAttribute[0]]: items
              });
            }}
          />
        </div> : null }
      </Paper>
      <div ref={bottomRef}></div>
    </>)}
  </>;
}