import React, { useState } from 'react';
import Button from '@mui/material/Button';
import SnippetAdd from '@mui/icons-material/Add';
import FolderAdd from '@mui/icons-material/CreateNewFolder';
import { log } from '../../logging/logging';
import Zoom from '@mui/material/Zoom';
import { memberRestricted, checkOrgRestrictionsLoaded, isAISnippetsEnabled } from '../../flags';
import { createGroup } from '../../data';
import NewSnippet from '../GroupSelectors/NewSnippet';
import { useIsXSmall, useTypedSelector, useTypedSelectorShallowEquals } from '../../hooks';
import Tooltip from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom';
import AISnippet from '@mui/icons-material/AutoFixHigh';
import T from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { isAiBlaze } from '../../aiBlaze';
import { AI_SNIPPET_CALLOUT_NOTIF_KEY, clearAICallout } from '../GroupSelectors/newsnippet_utilities';
import { usePushAISidebar } from '../../hooks/usePushAISidebar';

/**
 * @param {{ dummy?: boolean, snippetTooltip?: React.ReactNode, groupTooltip?: React.ReactNode, hasBottomShadow?: boolean }} props
 */
function AddButtonBase(props) {
  const enableAISnippets = useTypedSelector(state => isAISnippetsEnabled(state));
  let [zoomed, setZoomed] = useState(false);
  const { push } = useHistory();
  let isXSmall = useIsXSmall();
  let pushAISidebar = usePushAISidebar();

  let disableAdd = useTypedSelector(store => memberRestricted(store, 'create'));
  let restrictionsLoaded = useTypedSelector(store => checkOrgRestrictionsLoaded(store));
  let { dismissedAISnippetCallout } = useTypedSelectorShallowEquals(store => ({
    dismissedAISnippetCallout: !!store.userState.dismissed_notifications?.includes(AI_SNIPPET_CALLOUT_NOTIF_KEY),
  }));

  if (!props.dummy && (!restrictionsLoaded || disableAdd)) {
    return null;
  }

  if (pushAISidebar) {
    return null;
  }

  const selectGroupOrSnippet = (/** @type {string} */ kind, /** @type {string} */ id) => push('/' + kind + '/' + id);

  /**
   * @param {{ createSnippetFn: Function, args: Parameters<import('../../data').createSnippet>[0], isAISnippet: boolean }} param0 
   * @returns 
   */
  function handleNewSnippet({ createSnippetFn, args, isAISnippet }) {
    if (props.dummy) {
      return;
    }

    createSnippetFn(args).then(data => {
      selectGroupOrSnippet('snippet', data.snippet_id);
      return data;
    }).then(({ group_id, snippet_id }) => {
      log({ action: 'New snippet created from button', label: { isAISnippet, } }, { snippet_id, group_id });
      if (isAISnippet) {
        log({ action: 'New AI snippet created' }, { snippet_id, group_id });
      }
    }).catch(() => { }); // pass on error;
  }

  const isPrimaryFabNotRound = isXSmall;

  /** @type {React.CSSProperties} */
  const commonStyles = {
    // remove all interactions with the button for dummy (hover, click) to prevent confusion
    pointerEvents: props.dummy ? 'none' : undefined,
    boxShadow:'rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px',
  };

  /** @type {React.CSSProperties} */
  const fabStyles = {
    ...commonStyles,
    aspectRatio: '1 / 1',
    borderRadius: '100%',
    width: '40px',
    minWidth: 'initial',
    marginTop: isPrimaryFabNotRound ? undefined : '30px',
  };

  /** @type {React.CSSProperties} */
  const primaryFabStyles = {
    ...commonStyles,
    aspectRatio: isPrimaryFabNotRound ? undefined : '1 / 1',
    borderRadius: isPrimaryFabNotRound ? '100px' : '100%',
    width: isPrimaryFabNotRound ? undefined : '56px',
    minWidth: isPrimaryFabNotRound ? undefined : 'initial',
  };

  const gapPx = '13px'; 
  const tooltipPlacement = props.dummy ? 'top' : 'bottom';
  let primaryFabTooltip = props.snippetTooltip || 'Create a new snippet';
  let aiFabTooltip = props.snippetTooltip || 'Create a new AI prompt';
  let groupFabTooltip = props.groupTooltip || 'Create a ' + (isAiBlaze ? 'prompt folder' : 'snippet folder');


  return <div className="fab-add" style={{
    height: 'fit-content',
    position: props.dummy ? 'absolute' : 'fixed',
    left: isXSmall ? '8px' : '30px',
    top: isPrimaryFabNotRound ? undefined : '-5px',
    zIndex: '15',
    display: 'flex',
    gap: gapPx,
    flexDirection: 'row',
    alignItems: isXSmall ? 'center' : 'flex-end',
    padding: '10px',
    paddingLeft: '0px',
    flexWrap: props.dummy && isXSmall ? 'nowrap' : 'wrap',
    transition: '0.25s',
  }}>
    {!isAiBlaze && <Zoom in onEntered={() => setZoomed(true)}>
      {/* Need to use these divs because of this issue
      https://github.com/mui/material-ui/issues/27154 */}
      <div>
        <NewSnippet
          addHotkey
          component={(ref, createSnippetFn) => {
            return <Tooltip
              title={primaryFabTooltip}
              placement={tooltipPlacement}
              open={props.dummy ? zoomed : undefined}
              arrow
            >
              <Button
                sx={primaryFabStyles}
                ref={ref}
                onClick={() => {
                  handleNewSnippet({ createSnippetFn, isAISnippet: false, args: { type: 'text' } });
                }}
                color="primary"
                variant="contained"
                startIcon={
                  isPrimaryFabNotRound ? <SnippetAdd fontSize="large" /> : null
                }
              >
                {isPrimaryFabNotRound ? <T variant={isXSmall ? 'body1' : 'h6'}>New snippet</T> : <SnippetAdd fontSize="large" />}
              </Button>
            </Tooltip>;
          }} /></div>
    </Zoom>}

    <Box style={{
      display: 'flex',
      flexDirection: isAiBlaze ? 'row-reverse' : 'row',
      flexWrap: props.dummy && isXSmall ? 'nowrap' : 'wrap',
      gap: gapPx,
      alignItems: 'flex-end',
    }}>
      <Zoom in onEntered={() => setZoomed(true)}>
        <div className="fab-add-extra">
          <Tooltip
            title={groupFabTooltip}
            placement={props.dummy ? 'bottom' : tooltipPlacement}
            open={props.dummy ? zoomed : undefined}
            arrow
            PopperProps={{
              modifiers: props.dummy ? [
                {
                  name: 'offset',
                  enabled: true,
                  options: {
                    offset: [0, 6]
                  }
                }
              ] : undefined
            }}
          >
            <Button
              style={fabStyles}
              onClick={() => {
                if (props.dummy) {
                  return;
                }

                createGroup().then(newGroupId => {
                  selectGroupOrSnippet('folder', newGroupId);
                  return newGroupId;
                }).catch(() => { }); // pass on error;
              }}
              color="primary"
              size="small"
              variant="contained"
            >
              <FolderAdd fontSize="medium" />
            </Button>
          </Tooltip>
        </div>
      </Zoom>

      {enableAISnippets && <Zoom in onEntered={() => setZoomed(true)}>
        <div>
          <NewSnippet
            addHotkey
            isAI
            component={(ref, createSnippetFn) => {
              return <Tooltip
                title={aiFabTooltip}
                placement={tooltipPlacement}
                open={props.dummy ? zoomed : undefined}
                arrow
              >
                <Button
                  style={isAiBlaze ? primaryFabStyles : fabStyles}
                  ref={ref}
                  size="small"
                  onClick={() => {
                    if (!dismissedAISnippetCallout) {
                      clearAICallout();
                    }
                    handleNewSnippet({ createSnippetFn, isAISnippet: true, args: { type: 'text', name: 'New AI prompt', options: { quick_entry: false, is_ai: true, include_page_context: true, polish_mode: false, ai_action: {
                      updated_at: Date.now(),
                      action: 'write',
                    } } } });
                    // TODO: remove polish_mode on extension update
                  }}
                  color="primary"
                  variant="contained"
                >
                  {isAiBlaze
                    ? <SnippetAdd fontSize="large" />
                    : <AISnippet fontSize="medium" />
                  }
                </Button>
              </Tooltip>;
            }} />
        </div>
      </Zoom>}
    </Box>
  </div>;
}

const AddButton = React.memo(AddButtonBase);
export default AddButton;