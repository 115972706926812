import { Avatar, AvatarGroup, ClickAwayListener, IconButton, Paper, Popper, Tooltip, useMediaQuery } from '@mui/material';
import React, { useState, useRef } from 'react';
import { DatabaseAvatar } from './DatabaseAvatar';
import { DatabaseLabel } from './DatabaseLabel';
import T from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/EditOutlined';
import { useHistory } from 'react-router-dom';
import { useTypedSelectorDeepEquals } from '../../hooks';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


// @ts-ignore
let listFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
  

/**
 * @param {object} props 
 * @param {string[]} props.spaces
 * @param {string[]=} props.domains
 * @param {string[]=} props.addons
 * @param {string} props.editUrl
 * @param {boolean} props.isOwner
 * @returns 
 */
export function ConnectedAvatarGroup(props) {
  const { push: navigate } = useHistory();
  let anchorEl = useRef(null);
  let [showList, setShowList] = useState(false);

  let addonData = useTypedSelectorDeepEquals((state) => {
    if (!props.addons) {
      // addons are not used in Page Blaze
      return [];
    }

    let addonData = [];
    for (let addon of props.addons) {
      let command = state.dataState.addons.commands[addon];
      let addonId = command?.group_id;
      if (addonId && !addonData.find(x => x.id === addonId)) {
        let group = state.dataState.groups[addonId];
        if (group) {
          let addonName = group.name;
          let addonIconUrl = group.options?.addon?.icon_image_url;
          addonData.push({
            id: group.associated_addon_id,
            name: addonName,
            iconUrl: addonIconUrl
          });
        }
      }
    }
    return addonData;
  });


  let types = [];
  if (props.spaces.length) {
    types.push('spaces');
  }
  // no domains in Page Blaze
  if (props.domains?.length) {
    types.push('domains');
  }
  // no addons in Page Blaze
  if (props.addons?.length) {
    types.push('command packs');
  }
  let shouldCompress = useMediaQuery('(max-width:1050px)');

  return <>
    <Tooltip title={'Connected ' + listFormatter.format(types)}>
      <AvatarGroup
        max={shouldCompress ? 2 : 3}
        ref={anchorEl}
        sx={{
          '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 12, bgcolor: '#ddd', color: '#777' },
        }}
        onClick={(_e) => {
          setShowList(true);
        }}
      >
        {props.spaces.map(spaceId => <DatabaseAvatar
          key={spaceId}
          sx={{ width: 24, height: 24, bgcolor: '#ddd' }}
          id={spaceId}
        />)}
        {props.domains?.map(domain => <Avatar
          key={`domain-${domain}`}
          style={{ width: 22, height: 22 }}
          src={`https://www.google.com/s2/favicons?domain=${domain}&sz=64`}
        />)}
        {addonData.map(addon => <Avatar
          key={`addon-${addon.id}`}
          style={{ width: 22, height: 22 }}
          src={addon.iconUrl}
        />)}
      </AvatarGroup>
    </Tooltip>
    {showList &&
    <Popper
      open
      anchorEl={anchorEl.current}
      placement="bottom"
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 10,
      }}
      modifiers={[
        {
          name: 'flip',
          enabled: true
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            padding: 8
          }
        }
      ]}
    >
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={(evt) => {
          if (anchorEl.current.contains(evt.target)) {
            return;
          }
          setShowList(false);
        }}>
        <Paper elevation={1} sx={{
          py: 1.2,
          px: 1.2,
          pb: 2,
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
          minWidth: 240,
          maxHeight: 360,
          overflowY: 'auto'
        }}>
          {props.isOwner && <Tooltip title="Edit connected spaces">
            <IconButton
              size="small"
              onClick={(e) => {
                navigate(props.editUrl);
                setShowList(false);
                e.stopPropagation();
              }}
              style={{
                position: 'absolute',
                right: 18,
                top: 12
              }}
            >
              <EditIcon fontSize="small"/>
            </IconButton>
          </Tooltip>}

          {props.spaces.length ? <>
            <T variant="subtitle2" sx={{ mt: 1, ml: 1.2 }}>Connected spaces</T>
            {props.spaces.map(id => {
              return <div key={id}><DatabaseLabel id={id} /></div>;
            })}
          </> : null}

          {props.domains?.length ? <>
            <T variant="subtitle2" sx={{ mt: 1, ml: 1.2 }}>Connected domains</T>
            {props.domains.map(domain => {
              return <div key={domain} style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
                <img alt="Domain icon" src={`https://www.google.com/s2/favicons?domain=${domain}&sz=${64}`} width={32} height={32} style={{ marginLeft: 12 }} />
                <T style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 180 }}>{domain}</T>
              </div>;
            })}
          </> : null}


          {addonData.length ? <>
            <T variant="subtitle2" sx={{ mt: 1, ml: 1.2 }}>Connected command packs</T>
            {addonData.map(addon => {
              return <div key={addon.id} style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
                <img alt="Domain icon" src={addon.iconUrl} width={32} height={32} style={{ marginLeft: 12 }} />
                <T style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 180, flex: 1 }}>{addon.name}</T>
                <IconButton
                  onClick={() => {
                    window.open('/packs/' + addon.id);
                  }}
                >
                  <Tooltip title="View command pack">
                    <OpenInNewIcon />
                  </Tooltip>
                </IconButton>
              </div>;
            })}
          </> : null}
        </Paper>
      </ClickAwayListener>
    </Popper>}
  </>;
}